import { AppContext } from 'context/AppContext';
import dateFormat from 'dateformat';
import useAxios from 'hooks/useAxios';
import useLangv2, { formatterText } from 'hooks/useLangv2';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import endpoints from 'services/api';
import paths from 'services/paths';
import 'styles/colorPicker.css';
import hours from '../hours';
import hoursWithValue from '../hoursWithValue';

export default function SearchTechniciansAuxViewModel() {
	// Manage axios requests
	const { COOKIE_USER, RequestUseCase } = useAxios();
	// useLanguage
	const { updatedItem, errorProcess, customSB } = useLangv2();
	const titlesTableAssignTech = [
		formatterText('table.title.full.name'),
		formatterText('table.title.advisor.id'),
		formatterText('title.service.search.manual.table.tech.type'),
		formatterText('p.label.date.appointment'),
		formatterText('p.label.hour.appointment')
	];

	const titlesTableCostProduct = [
		formatterText('title.service.search.manual.table.techniciansForService'),
		formatterText('title.service.search.manual.table.startOfPreviousService'),
		formatterText('title.service.search.manual.table.riskOfNonCompliancePreviousService'),
		formatterText('title.service.search.manual.table.riskOfNonComplianceNewService'),
		formatterText('title.service.search.manual.table.details'),
		formatterText('title.service.search.manual.table.actions')
	];

	const [id, setId] = useState(0);
	const navigate = useNavigate();
	// SETEOS DE STATES Y CONTEXT
	const [technicians, setTechnicians] = useState([]);
	const [technicians2, setTechnicians2] = useState([]);
	const [technicians3, setTechnicians3] = useState([]);
	const [open, setOpen] = useState(false);
	const [selectTech, setSelectTech] = useState(false);
	const [seeDetails, setSeeDetails] = useState(false);
	const [techToAssign, setTechToAssign] = useState({});
	const [currentDetail, setCurrentDetail] = useState({});
	const [loadingTech, setLoadingTech] = useState(false);
	// set data services
	const { servicesData, setServicesData } = useContext(AppContext);
	// setPrincipal tech
	const [techAssignService, setTechAssignService] = useState([]);

	const handleClose = (fun) => {
		fun(false);
	};

	const date = (dateTimeZone) => {
		return dateFormat(dateTimeZone, 'isoDate');
	};
	// GetAll Technicals by id Services
	useEffect(() => {
		getAllServicesByid();
		setTimeout(() => {
			getManualtechnicalData();
		}, 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Get All service info
	const getAllServicesByid = () => {
		const idService = parseInt(localStorage.getItem('idService'));
		setId(idService);
		RequestUseCase.get(endpoints.services.getServiceById(idService))
			.then((res) => {
				const dataJson = res.servicio;
				const dataServiceJson = {
					canalComunicacion: dataJson.idCanalComunicacion.nombre,
					idCanalComunicacion: dataJson.idCanalComunicacion.idCanalComunicacion,
					categoriaServicio: dataJson.idTipoServicio.nombre,
					idDireccion: dataJson.idDireccion.idDireccion,
					direccion: dataJson.idDireccion.direccion,
					estado: dataJson.estado,
					estadoServicio: dataJson.idEstadoServicio.nombre,
					expediente: dataJson.expediente,
					fechaCita: date(dataJson.fechaCita),
					fechaCitaFin: date(dataJson.fechaCitaFin),
					fechaCreacion: dataJson.fechaCreacion,
					fechaModificacion: dataJson.fechaModificacion,
					horaCita: dataJson.idHoraCita.hora,
					horaCitaFin: dataJson.idHoraCitaFin.hora,
					idCategoriaServicio: dataJson.idTipoServicio.idCategoriaServicio.idCategoriaServicio,
					idGeneradorUsuario: null,
					idServicio: id || dataJson.idServicio,
					idTipoServicio: dataJson.idTipoServicio.idTipoServicio,
					idUsuario: dataJson.idUsuario?.idUsuarioServicio || null,
					idHoraCita: dataJson.idHoraCita.idHora,
					idHoraCitaFin: dataJson.idHoraCitaFin.idHora,
					medioComunicacion: dataJson.idMedioComunicacion.nombre,
					idMedioComunicacion: dataJson.idMedioComunicacion.idMedioComunicacion,
					ticket: dataJson.ticket,
					tipoServicio: dataJson.idTipoServicio.nombre,
					usuarioCreacion: dataJson.usuarioCreacion,
					usuarioModificacion: dataJson.usuarioModificacion,
					dataStartMeet: dataJson.idHoraCita,
					dataEndMeet: dataJson.idHoraCitaFin,
					dateStartDate: dataJson.fechaCita,
					dateEndDate: dataJson.fechaCitaFin
				};
				// set data
				console.log(dataServiceJson);
				// setData service
				setServicesData(dataServiceJson);

				getAllTechnicalsServices(idService);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllTechnicalsServices = (idService) => {
		RequestUseCase.get(endpoints.services.getAllTechnicalsByService(idService))
			.then((response) => {
				let techsitos = [];

				if (response.length !== 0) {
					response.forEach((item) => {
						console.log(item);
						let idtech = -1;
						let label = '';
						let isPrincipal = false;
						let docId = '';

						let dateAndTime = '';

						const serviceStatus = item.idServicio.idEstadoServicio
							? item.idServicio.idEstadoServicio.idEstadoServicio
							: 6;

						// verify is the principal tech is assigned
						if (item.principal === 1) {
							// TODO: change this logic
							if (
								serviceStatus === 2 ||
								serviceStatus === 3 ||
								serviceStatus === 4 ||
								serviceStatus === 6
							) {
								if (item.idTecnico.idEmpleado !== null) {
									label = `${item.idTecnico.idEmpleado.primerNombre} - ${item.idTecnico.idEmpleado.primerApellido}`;
									idtech = item.idTecnico.idTecnico;
									docId = item.idTecnico.idEmpleado.identificacion;
									dateAndTime = {
										d: item.fechaInicio,
										h: item.idHoraInicio.hora
									};
								} else {
									idtech = item.idTecnico.idTecnico;
									label = `${item.idTecnico.idTercero.nombre} - ${item.idTecnico.idTercero.identificacion}`;
									docId = item.idTecnico.idTercero.identificacion;
									dateAndTime = {
										d: item.fechaInicio,
										h: item.idHoraInicio.hora
									};
								}
							}
							isPrincipal = true;

							techsitos.push({
								idtech,
								label,
								isPrincipal,
								docId,
								dateAndTime
								// idFin: item.idServicioTecnico,
								// idtech,
								// isPrincipal,
								// label: label,
								// date: item.fechaAsignacion,
							});
						} else {
							if (item.idTecnico.idEmpleado !== null) {
								label = `${item.idTecnico.idEmpleado.primerNombre} - ${item.idTecnico.idEmpleado.primerApellido}`;
								idtech = item.idTecnico.idTecnico;
								docId = item.idTecnico.idEmpleado.identificacion;
								dateAndTime = {
									d: item.fechaInicio,
									h: item.idHoraInicio.hora
								};
							} else {
								idtech = item.idTecnico.idTecnico;
								label = `${item.idTecnico.idTercero.nombre} - ${item.idTecnico.idTercero.identificacion}`;
								docId = item.idTecnico.idTercero.identificacion;
								dateAndTime = {
									d: item.fechaInicio,
									h: item.idHoraInicio.hora
								};
							}
							const idFin = item.idServicioTecnico;

							const newAuxTech = {
								idFin,
								idtech,
								isPrincipal,
								label,
								docId,
								dateAndTime,
								date: date(item.idServicio.fechaCita),
								hour: item.idServicio.idHoraCita.hora
							};
							techsitos.push(newAuxTech);
						}
					});
				}
				//delete the idtech repeateds in the array
				//only allow 1 idtech in the array, not repeated
				techsitos = techsitos.filter(
					(thing, index, self) => index === self.findIndex((t) => t.idtech === thing.idtech)
				);
				setTechAssignService(techsitos);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// get All Manual Technical Data
	const getManualtechnicalData = () => {
		const idService = parseInt(localStorage.getItem('idService'));
		const hashTechnicians = {
			0: (data) => setTechnicians(data),
			1: (data) => setTechnicians2((prev) => ({ ...prev, data })),
			2: (data) => setTechnicians3(data)
		};
		RequestUseCase.get(endpoints.services.getManualSearchTechnicals(idService))
			.then((res) => {
				res.forEach((element, index) => {
					const element2 = element.map((item) => {
						//return All Array if dont hace techAssigned
						console.log(index);
						if (techAssignService.some((tech) => tech.idtech === item.idTecnico)) {
							return null;
						} else {
							return item;
						}
					});

					hashTechnicians[index](element2);
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const consultaStart = () => {
		setTechnicians([]);
		setTechnicians2([]);
		setTechnicians3([]);
		getManualtechnicalData();
	};

	const clickOnRow = (tech) => {
		console.log('tech', tech);
		setTechToAssign(tech.idTecnico);
		setSelectTech(true);
	};

	// Assign technical to the service
	const clickFun = (dataToAssign) => {
		setLoadingTech(true);
		const formatData = {
			idServicioTecnico: null,
			idTecnico: {
				idTecnico: dataToAssign
			},
			idServicio: {
				idServicio: id
			},
			principal: 0
		};

		// Get id of the services
		RequestUseCase.get(endpoints.services.getServiceById(id))
			.then((serviceInfo) => {
				if (
					servicesData.fechaCita === date(serviceInfo.servicio.fechaCita) &&
					servicesData.horaCita === serviceInfo.servicio.idHoraCita.hora
				) {
					console.log('son la misma fecha');
					selectServiceToTech(serviceInfo, formatData);
				} else {
					console.log('son diferentes fechas');
					selectServiceToTech(serviceInfo, formatData);
				}
				setLoadingTech(false);
			})
			.catch((error) => {
				console.log('error of get service info: ', error);
				setLoadingTech(false);
			});
	};

	const selectServiceToTech = (data, data2) => {
		const idService = parseInt(localStorage.getItem('idService'));
		const formatData = {
			idServicio: idService,
			idTipoServicio: {
				idTipoServicio: data.servicio.idTipoServicio.idTipoServicio
			},
			fechaCita: `${servicesData.fechaCita}T${servicesData.dataStartMeet.codigo}.000+00:00`,
			idHoraCita: {
				idHora: servicesData.idHoraCita
			}
		};
		RequestUseCase.post(endpoints.services.generateEndDate, formatData).then((resp) => {
			const formatDataB = {
				idServicioTecnico: null,
				idServicio: {
					idServicio: idService
				},
				idTecnico: {
					idTecnico: data2.idTecnico.idTecnico
				},
				estado: 1,
				principal: 0,
				asignaAsesor: 1,
				fechaInicio: `${servicesData.fechaCita}T${servicesData.dataStartMeet.codigo}.000+00:00`,
				fechaFin: `${resp.fechaCitaFin}`,
				idHoraInicio: {
					idHora: servicesData.idHoraCita
				},
				idHoraFin: {
					idHora: resp.idHoraCitaFin.idHora
				},
				usuarioCreacion: COOKIE_USER
			};

			RequestUseCase.post(endpoints.services.assignServiceAux, formatDataB)
				.then(() => {
					setSelectTech(false);
					navigate(paths.updateService.replace(':id', idService));
				})
				.catch(() =>
					customSB(
						'error',
						'snackbar.error.duplicate.aux.tech',
						'El técnico ya se encuentra asignado al servicio.'
					)
				);
		});
	};

	//Un estilo centrado de color verde
	const styleCenter = {
		textAlign: 'center',
		color: '#0AA48A',
		fontSize: '20px',
		fontWeight: 'bold',
		marginTop: '20px',
		//centrar el div
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: '25px'
	};

	//Un estilo a la izquierda de color verde
	const styleLeft = {
		textAlign: 'left',
		color: '#0AA48A',
		fontSize: '20px',
		fontWeight: 'bold',
		marginLeft: '20px',
		marginRight: '20px',
		//centrar el div
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'left'
	};

	//wrap de dos items en linea
	const styleWrap = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		marginBottom: '20px',
		width: '100%'
	};

	const verifyTechAvailable = (FC, HC) => {
		// Get id of the services
		RequestUseCase.get(endpoints.services.getServiceById(id))
			.then((serviceInfo) => {
				let data = {};
				const serviceData = serviceInfo.servicio;
				const clientSelected = serviceData.idGeneradorCliente;
				const userSelected = serviceData.idGeneradorUsuario;
				const horaID = hours.find((hour) => {
					return hour.hora === HC;
				});
				const TYPESERVICE = serviceData.idTipoServicio.idTipoServicio;
				data = {
					idServicio: null,
					fechaCita: `${servicesData.fechaCita}T10:00:00`,
					idHoraCita: {
						idHora: horaID.idHora
					},
					idDireccion: {
						idDireccion: serviceData.idDireccion.idDireccion
					},
					idTipoServicio: {
						idTipoServicio: TYPESERVICE
					},
					descripcion: serviceData.descripcion,
					idMedioComunicacion: {
						idMedioComunicacion: serviceData.idMedioComunicacion.idMedioComunicacion
					},
					idCanalComunicacion: {
						idCanalComunicacion: serviceData.idCanalComunicacion.idCanalComunicacion
					},
					expediente: serviceData.expediente,
					especial: serviceData.especial,
					idEstadoServicio: {
						idEstadoServicio: serviceData.idEstadoServicio.idEstadoServicio
					},
					usuarioCreacion: serviceData.usuarioCreacion,
					usuarioModificacion: COOKIE_USER,
					idServicioPadre: serviceData.idServicioPadre
				};

				// OPTION 1: GUSER where idGeneradorCliente are null
				if (userSelected !== null) {
					// OPTION 2:HAVE USER SYSTEM Too
					if (serviceData.idUsuario !== null) {
						// Select genUser and Externarl User
						data = {
							...data,
							idGeneradorCliente: null,
							idGeneradorUsuario: serviceData.idGeneradorUsuario.idUsuarioServicio,
							idUsuario: null
						};
					} else {
						// Only select gen system user Option 1 only
						data = {
							...data,
							idGeneradorCliente: null,
							idGeneradorUsuario: serviceData.idGeneradorUsuario.idUsuarioServicio,
							idUsuario: null
						};
					}
				}
				// OPTION 3: GCLIENT + GUSER where idGeneradorUsuario is null
				else if (clientSelected !== null) {
					// OPTION 4:HAVE USER SYSTEM Too
					if (serviceData.idUsuario !== null) {
						// Select geClient and Externarl User
						data = {
							...data,
							idGeneradorCliente: serviceData.idGeneradorCliente,
							idGeneradorUsuario: null,
							idUsuario: null
						};
					} else {
						// Option 3: Only GenClient
						data = {
							...data,
							idGeneradorCliente: serviceData.idGeneradorCliente,
							idGeneradorUsuario: null,
							idUsuario: null
						};
					}
				}

				const DATA_DEFINITIVE = {
					...serviceData,
					fechaCita: `${FC}T10:00:00`,
					idHoraCita: {
						idHora: horaID.idHora
					},
					usuarioModificacion: COOKIE_USER
				};

				generateDateEnd(data, serviceData, DATA_DEFINITIVE);
			})
			.catch((error) => {
				console.log('error of get service info: ', error);
			});
	};

	// generate the end date of the service for validate the agenda
	const generateDateEnd = (DATAUPDATE, COMPLETE_DATA, DU) => {
		customSB(
			'warning',
			'snackbar.warning.validate.tech.exist',
			'Se esta validando que el servicio cumpla con los requisitos para ser creado, por favor espere...'
		);

		RequestUseCase.post(endpoints.services.generateEndDate, DATAUPDATE)
			.then((response) => {
				const ADDRESS = COMPLETE_DATA.idDireccion.idDireccion;
				const SECTOR = COMPLETE_DATA.idDireccion.idSector.idSector;
				const MUNICIPALITY = COMPLETE_DATA.idDireccion.idSector.idMunicipio.idMunicipio;
				const TYPE_SERVICE = COMPLETE_DATA.idTipoServicio.idTipoServicio;
				const CATEGORY_SERVICE =
					COMPLETE_DATA.idTipoServicio.idCategoriaServicio.idCategoriaServicio;
				const idHora = hoursWithValue.find(
					(item) => item.idHora === COMPLETE_DATA.idHoraCita.idHora
				);

				const DATA_VALIDATED = {
					...COMPLETE_DATA,
					idDireccion: {
						idDireccion: ADDRESS,
						idSector: {
							idSector: SECTOR,
							idMunicipio: {
								idMunicipio: MUNICIPALITY
							}
						}
					},
					idTipoServicio: {
						idTipoServicio: TYPE_SERVICE,
						idCategoriaServicio: {
							idCategoriaServicio: CATEGORY_SERVICE
						}
					},
					idHoraCita: {
						idHora: COMPLETE_DATA.idHoraCita.idHora,
						valor: idHora.valor
					},
					fechaCitaFin: response.fechaCitaFin,
					idHoraCitaFin: {
						idHora: response.idHoraCitaFin.idHora,
						valor: response.idHoraCitaFin.valor
					}
				};

				validateServiceAgendaEvent(DATA_VALIDATED, DU);
			})
			.catch((E) => {
				console.log(E);
				errorProcess();
			});
	};

	// Validate if the agenda have horary available
	const validateServiceAgendaEvent = (DATA, DU) => {
		RequestUseCase.post(endpoints.services.validateCreateService, DATA)
			.then((response) => {
				if (response) {
					customSB(
						'success',
						'snackbar.success.validate.tech.exist',
						'Se encontró al menos un técnico disponible para este servicio, puede continuar con el proceso.'
					);
					RequestUseCase.put(endpoints.services.updateService, DU)
						.then(() => {
							console.log('se actualizo la hora y fecha del servicio');
							updatedItem();
							consultaStart();
						})
						.catch((err) => {
							console.log(err);
						});
				} else {
					customSB(
						'error',
						'snackbar.error.validate.tech.exist',
						'No se encontró un técnico disponible para este servicio, seleccione otro horario o fecha.'
					);
				}
			})
			.catch(() => {
				errorProcess();
			});
	};

	const seeModalDetails = (data) => {
		setSeeDetails(true);
		setCurrentDetail(data);
	};

	console.log(techAssignService);

	return {
		styleCenter,
		styleWrap,
		styleLeft,
		setOpen,
		handleClose,
		technicians,
		technicians2,
		technicians3,
		titlesTableCostProduct,
		verifyTechAvailable,
		techToAssign,
		selectTech,
		setSelectTech,
		clickFun,
		consultaStart,
		clickOnRow,
		seeModalDetails,
		seeDetails,
		setSeeDetails,
		currentDetail,
		loadingTech,
		techAssignService,
		titlesTableAssignTech,
		servicesData,
		open
	};
}
