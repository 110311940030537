import React from 'react';
// Import Contexts
import { SearchWrapper } from 'context/SearchContext';
import { PermissionWrapper } from 'context/PermissionContext';

// Import Components
import { ContractComponent } from './ContractType.component';

const ContractType = () => {
	return (
		<SearchWrapper>
			<PermissionWrapper>
				<ContractComponent />
			</PermissionWrapper>
		</SearchWrapper>
	);
};

export default ContractType;
