import React from 'react';
import { SearchWrapper } from 'context/SearchContext';
import CreateServiceCategoryComponent from './CreateServiceCategory.component';

export default function CreateServiceCategory() {
	return (
		<SearchWrapper>
			<CreateServiceCategoryComponent />
		</SearchWrapper>
	);
}
