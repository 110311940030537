import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UpdateSubCategoryProps } from './UpdateSubCategory.props';
import UpdateSubCategoryViewModel from './UpdateSubCategory.viewModel';

export default function UpdateSubCategory({ onClose, getFun, update }) {
	const {
		currentSubProductCategory,
		formatterText,
		handleChangeSubCategoryCode,
		handleChangeSubCategoryDescription,
		handleNameTextSubCategory,
		handleUpdateProductCategory
	} = UpdateSubCategoryViewModel({ onClose, getFun, update });

	return (
		<form className="form-responsive" onSubmit={handleUpdateProductCategory}>
			<section className="form-responsive-container-information">
				<label className="form-responsive-information__option">
					<h3>{formatterText('p.subcategory.name', 'Nombre subcategoría')}</h3>
					<input
						className="input-primary width-50"
						type="text"
						name="nombre"
						value={currentSubProductCategory.nombre}
						onChange={handleNameTextSubCategory}
						placeholder="Nombre subcategoría"
						maxLength="100"
					/>
				</label>
				<label className="form-responsive-information__option">
					<h3>
						<FormattedMessage id="p.code" defaultMessage="Código" />
					</h3>
					<input
						className="input-primary width-50"
						type="text"
						name="codigo"
						value={currentSubProductCategory.codigo}
						onChange={handleChangeSubCategoryCode}
						placeholder={formatterText(
							'input.placeholder.enter.up.characters',
							'Ingresa hasta 45 caracteres'
						)}
						maxLength="45"
					/>
				</label>
				<label className="form-responsive-information__option">
					<h3>
						<FormattedMessage id="table.title.description" defaultMessage="Descripción" />
					</h3>
					<textarea
						className="input-primary-textarea width-50"
						type="text"
						name="descripcion"
						value={currentSubProductCategory.descripcion}
						onChange={handleChangeSubCategoryDescription}
						placeholder={formatterText('table.title.description', 'Descripción')}
						maxLength="200"
					/>
				</label>
				<input
					type="submit"
					className="width-100-fix"
					value={formatterText('p.subcategory.update', 'Actualizar subcategoría')}
				/>
				<input
					type="button"
					className="width-100-fix input-cancel"
					value={formatterText('btn.cancel', 'Cancelar')}
					onClick={onClose}
				/>
			</section>
		</form>
	);
}
UpdateSubCategory.prototype = UpdateSubCategoryProps;
