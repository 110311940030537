import React, { useState } from 'react';
// Import Hooks
import { useField } from '@formiz/core';
// Import Libs
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
// Import Styles
import customStyles from 'common/selects/default.select.style';

const InputSelectorResponsive = (props) => {
	const { errorMessage, id, isValid, isSubmitted, setValue } = useField(props);
	const { type, name, labelText, required, placeholder, defaultValue, isLoading, data } = props;
	const [isTouched, setIsTouched] = useState(false);
	const showError = !isValid && (isTouched || isSubmitted);

	const [selectedOption, setSelectedOption] = useState(null);

	return (
		<label className="d-flex">
			<span className="text-inline">{labelText}</span>
			<section className="w100-container">
				<Select
					styles={customStyles}
					defaultValue={defaultValue}
					onChange={(selectedOption) => {
						setSelectedOption(selectedOption.label);
						setValue(selectedOption.value);
					}}
					options={data}
					placeholder={
						isLoading ? (
							<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
						) : (
							placeholder
						)
					}
				/>
				<input
					className="input-required"
					id={id}
					type={type || 'text'}
					value={selectedOption || ''}
					name={name}
					onBlur={() => setIsTouched(true)}
					placeholder={placeholder}
					aria-invalid={showError}
					autoComplete="off"
					aria-required={!!required}
					aria-describedby={showError ? `${id}-error` : null}
				/>
				{showError && (
					<span id={`${id}-error`} className="error-msg">
						{errorMessage}
					</span>
				)}
			</section>
		</label>
	);
};

export default InputSelectorResponsive;
