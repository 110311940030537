import React from 'react';
// Import Context
// Import Hooks
// Import Components
import DynamicTable from 'common/DynamicTable/DynamicHead';
// Import libs
import { FormattedMessage } from 'react-intl';
// Import services
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';
import paths from 'services/paths';
import ServiceStageTableViewModel from './ServiceStageTable.viewModel';
import { ServiceStageTableProps } from './ServiceStageTable.props';

const ServiceStageTable = ({ permisos }) => {
	const {
		loading,
		error,
		displayMessage,
		displayLoading,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		titles
	} = ServiceStageTableViewModel();

	if (loading) {
		if (error) {
			return displayMessage(
				'error',
				'Ha ocurrido un error, intentalo más tarde.',
				'toast.error.general'
			);
		}
		return displayLoading();
	}
	return (
		<FormattedMessage id="table.name.search.productCategory" defaultMessage="Categoría de producto">
			{() => (
				<DynamicTable
					titles={titles}
					pageName={PAGE_NAMES.Etapas}
					getData={getDataTable}
					handleDeleteItem={handleDeleteItem}
					handleEditStateItem={handleEditStateItem}
					routeToEdit={paths.updateStageService}
					canDeleted={permisos.eliminar}
					canModify={permisos.editar}
				/>
			)}
		</FormattedMessage>
	);
};

ServiceStageTable.propTypes = ServiceStageTableProps;

export default ServiceStageTable;
