import profileDefault from 'assets/profile-default.jpg';
import MIME_TYPE from 'common/utils/MimeType';
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';
import { AppContext } from 'context/AppContext';
import { langContext } from 'context/langContext';
import useAxios from 'hooks/useAxios';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import Cookie from 'js-cookie';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateLenguage } from 'services/api/auth';
import { getProfileImage } from 'services/api/users';
import 'styles/editProfile.css';

export default function EditProfileViewModel() {
	const navigate = useNavigate();
	// Llamo a la variable global de avatar
	const { setImgUrlValue, userInfo } = useContext(AppContext);
	const [file, setFile] = useState(null);
	// manage petitions
	const { fetchData, COOKIE_USER, endpoints, RequestUseCase } = useAxios();
	const [userDataValue] = userInfo;
	const idioma = useContext(langContext);
	const { formatterText } = useLangv2();
	const [formData, setFormData] = useState(userDataValue);
	const [selectedSearchCountries, setSearchSelectedCountries] = useState([]);
	const { loading, toggleLoading } = useGetData();
	const [selectValues, setSelectValues] = useState([]);
	const [labelUpload, setLabelUpload] = useState(formatterText('file.upload.not.choose.file'));

	const uploadRef = useRef();

	useEffect(() => {
		getInitData();
		getDataNotifi();
		getNotiByUser(Cookie.get('idUsuario'));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getDataNotifi = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.users.notifyGetAll)
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idCategoriaServicio,
							label: item.nombre,
							description: item.descripcion,
							code: item.code,
							status: item.estado,
							dateRegister: item.fechaRegistro,
							dateModify: item.fechaModificacion,
							userCreation: item.usuarioCreacion,
							userModify: item.usuarioModificacion,
							isFixed: true
						});
					}
				});
				setSearchSelectedCountries(newArray);
				toggleLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toggleLoading(false);
			});
	};

	const getNotiByUser = (id) => {
		RequestUseCase.get(endpoints.users.notifiGetUser(id))
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					newArray.push({
						value: item.idCategoriaServicio,
						label: item.nombre,
						description: item.descripcion,
						code: item.codigo,
						status: item.estado,
						dateRegister: item.fechaRegistro,
						dateModify: item.fechaModificacion,
						userCreation: item.usuarioCreacion,
						userModify: item.usuarioModificacion,
						isFixed: true
					});
				});
				setSelectValues(newArray);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const getInitData = () => {
		RequestUseCase.get(endpoints.users.getUserById(Cookie.get('idUsuario')))
			.then((res) => {
				setFormData(res);
				getDataAttachedFiles();
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value.replaceAll(/\s{2,}/gi, ' ')
		});
	};

	const handleChangeLang = (e) => {
		idioma.establecerLenguaje(e.target.value.trim());
		let lenguage;

		if (e.target.value.trim() == 'en-US') {
			lenguage = 'en';
		} else if (e.target.value.trim() == 'en-ES') {
			lenguage = 'es';
		}

		const jsonData = {
			idUsuario: Cookie.get('idUsuario'),
			language: lenguage
		};

		updateLenguage(jsonData)
			.then((res) => {
				if (res) {
					CustomAlert('short_msg', {
						icon: 'success',
						title: 'Idioma cambiado',
						text: 'Su idioma ha sido cambiado con éxito',
						confirmButtonColor: '#FFC954'
					});
					idioma.establecerLenguaje(e.target.value.trim());
				}
			})
			.catch(() => {
				CustomAlert('short_msg', {
					icon: 'error',
					title: 'Error',
					text: 'Verifique el enlace de restablecer de contraseña.'
				});
			});
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		updateUserData();
	};

	const updateUserData = () => {
		RequestUseCase.put(endpoints.users.updateUser, formData)
			.then((res) => {
				if (res) {
					onSuccess(formatterText('p.data.update', 'Datos actualizados'));
					createAndUpdateNotify();
					getInitData();
				} else {
					HandleOnError(formatterText('p.data.noupdate', 'No se pudo actualizar los datos'));
				}
			})
			.catch((err) => {
				if (err.response.status === 412) {
					HandleOnError(formatterText('alert.error.create.existing', 'El correo ya esta en uso'));
				} else {
					HandleOnError(formatterText('p.data.noupdate', 'No se pudo actualizar los datos'));
				}
			});
	};

	const createAndUpdateNotify = () => {
		const requestData = {
			idCategoriaUsuario: Cookie.get('idUsuario'),
			categoriaServicio: selectValues.map((item) => ({
				idCategoriaServicio: item.value,
				nombre: item.label,
				descripcion: item.description, // Agrega la descripción del item si está disponible
				codigo: item.code, // Agrega el código del item si está disponible
				estado: item.status, // Agrega el estado del item si está disponible
				fechaRegistro: item.dateRegister, // Agrega la fecha de registro del item si está disponible
				fechaModificacion: item.dateModify, // Agrega la fecha de modificación del item si está disponible
				usuarioCreacion: item.userCreation, // Agrega el usuario de creación del item si está disponible
				usuarioModificacion: item.userModify // Agrega el usuario de modificación del item si está disponible
			}))
		};
		RequestUseCase.post(endpoints.users.addNotify, requestData)
			.then((res) => {
				if (res) {
					onSuccess(formatterText('p.data.update', 'Datos actualizados'));
				} else {
					HandleOnError(formatterText('p.data.noupdate', 'No se pudo actualizar los datos'));
				}
			})
			.catch((err) => {
				console.error(err);
				HandleOnError('Error al actualizar los datos');
			});
	};

	const onSuccess = (sucess) => {
		CustomAlert('short_msg', {
			icon: 'success',
			title: formatterText('alert.action.confirm.general', 'Acción realizada correctamente'),
			text: sucess
		});
	};

	function uploadImage(e) {
		const TYPE_IMAGE = {
			'image/png': true,
			'image/jpg': true,
			'image/jpeg': true
		};

		// try catch para que no se cargue la imagen si no se selecciona ninguna
		try {
			const imageSize = e.target?.files[0]?.size;
			// limita el tamaño de la imagen a 2MB
			const archivo = uploadRef.current.files;
			const nombreArchivo =
				archivo?.length > 0 ? archivo[0]?.name : formatterText('file.upload.not.choose.file');
			setLabelUpload(nombreArchivo);
			if (imageSize < 2000000) {
				const typeImg = e.target.files[0].type;
				if (TYPE_IMAGE[typeImg]) {
					uploadNewFileFn(e);
				} else {
					HandleOnError('El tipo de imagen no es válido (Solo se permite PNG y JPG)');
				}
			} else if (imageSize) {
				HandleOnError('El archivo supera 2MB');
			}
		} catch (error) {
			console.error('error', error);
		}
	}

	const uploadProfileImage = (file) => {
		fetchData({
			url: endpoints.UploadFiles.save,
			method: 'post',
			body: {
				idOrigen: COOKIE_USER,
				idTipoOrigenArchivo: 4,
				archivos: [file]
			}
		}).then(() => {
			CustomAlert('confirm_msg', {
				icon: 'success',
				title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
				text: formatterText('alert.title.confirm.add.files'),
				confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
				allowOutsideClick: false,
				executeFunction: () => {
					getInitData();
					setImgUrlValue(file);
				}
			});
		});
	};

	const uploadNewFileFn = (e) => {
		const selectedFile = e.target.files[0];
		const reader = new FileReader();

		reader.onload = (event) => {
			const archivo = event.target.result.split('base64,')[1];
			const extensionArchivo =
				selectedFile.name.lastIndexOf('.') > -1
					? selectedFile.name.substring(
							selectedFile.name.lastIndexOf('.') + 1,
							selectedFile.name.length
					  )
					: '';

			const name = selectedFile.name.substring(0, selectedFile.name.lastIndexOf('.'));
			setFile(selectedFile);
			uploadProfileImage({
				archivo,
				extensionArchivo: `.${extensionArchivo}`,
				name
			});
		};

		reader.readAsDataURL(selectedFile);
	};

	const getDataAttachedFiles = () => {
		getProfileImage().then((resp) => {
			const img =
				Object.keys(resp).length !== 0
					? `data:${MIME_TYPE[resp.ext]};base64,${resp.image}`
					: profileDefault;
			setImgUrlValue(img);
		});
	};

	const handleChangeMulti = (val) => {
		setSelectValues(val);
	};

	return {
		handleChange,
		handleChangeMulti,
		handleChangeLang,
		uploadImage,
		handleSubmit,
		formData,
		file,
		userDataValue,
		selectedSearchCountries,
		loading,
		selectValues,
		uploadRef,
		labelUpload,
		formatterText,
		navigate
	};
}
