import useAxios from 'hooks/useAxios';
import { useContext, useEffect, useState } from 'react';
// Import Contexts
import { AppContext } from 'context/AppContext';
import { useSeachContext } from 'context/SearchContext';
// Import Hooks
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import useProgress from 'hooks/useProgress';
// Import Components
import DynamicTable from 'common/DynamicTable/DynamicHead';
import SortDataByDate from 'components/utils/SortDataByDate';
// Import Libs
import dateFormat from 'dateformat';

// Import Models
import ServiceType from 'models/service/ServiceType';
// Import Services
import endpoints from 'services/api';
// Import Styles
import { MODULES_NAME } from 'common/validators/PermissionsConstants';
import { ACTIVITYSTATUS } from 'constants/activityStatus';
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';
import { usePermissionContext } from 'context/PermissionContext';
import { sortBy } from 'lodash';

function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

export default function ServiceTableViewModel() {
	const { RequestUseCase } = useAxios();

	// Manage axios requests
	const [open] = useState(false);
	const [options, setOptions] = useState([]);
	const loading2 = open && options.length === 0;
	const [selectValue, setSelectValue] = useState({
		id: null,
		label: ''
	});
	const [val] = useState({
		id: null,
		label: ''
	});

	// Estado del id de la entidad (Empleado o Tercero)
	const [filterBill, setFilterBill] = useState({
		billId: null,
		label: ''
	});

	const [allStateService, setAllStateService] = useState([]);
	useEffect(() => {
		let active = true;

		if (!loading2) {
			return undefined;
		}

		(async () => {
			if (active && selectValue.length >= 3) {
				const response = await RequestUseCase.get(
					endpoints.services.getAllExternalUsersByNumber(selectValue)
				);
				await sleep(1e3); // For demo purposes.
				const users = await response;
				const newArray = [];
				Object.keys(users).forEach((key) => {
					const formatData = `${users[key].primerNombre} ${users[key].primerApellido} - ${users[key].telefono}`;
					newArray.push({ id: users[key].id, label: formatData });
				});
				setOptions(newArray);
			}
		})();

		return () => {
			active = false;
		};
	}, [loading2, selectValue]);

	useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	// State of the labels and ids of the selectors
	const [selectValues, setSelectValues] = useState({
		nombre_cliente: {
			id: null,
			label: ''
		},
		idDepartamento: {
			id: null,
			label: ''
		},
		nombre_usuario: {
			id: null,
			label: ''
		},
		idTecnico: {
			id: null,
			label: ''
		},
		idTipoServicio: {
			id: null,
			label: ''
		},
		idEstadoServicio: {
			id: null,
			label: ''
		}
	});

	const [formData, setFormData] = useState(new ServiceType());

	const [dates, setDates] = useState({
		rangoFechaCitaInicio: null,
		rangoFechaCitaFin: null,
		rangoFechaCreacionInicial: null,
		rangoFechaCreacionFinal: null,
		rangoValorHoraInicial: null,
		rangoValorHoraFinal: null
	});

	// hook to loading data
	const { loading, error, displayMessage } = useGetData();
	// useContext de búsqueda
	const { dataTable, setDataTable, searchResults = [] } = useSeachContext();
	// Helps to loading data table
	const { loadingProgress, setLoadingProgress, DisplayProgress } = useProgress();
	const [services, setServices] = useState(null);

	const [show, setShow] = useState(false);

	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();
	const permissionsAccessGaranted = () => {
		permissionsAccess(MODULES_NAME.service.principal);
	};

	useEffect(() => {
		permissionsAccessGaranted();
	}, [permissions]);

	// getData from the client or exteralUser select
	const { setExternalUserSelected, setBillingUserSelected, setClientSelected, setUserSelected } =
		useContext(AppContext);

	const getAllStatesService = () => {
		RequestUseCase.get(endpoints.services.getAllStates).then((data) => {
			const resp = data.map((item) => ({
				id: item.idEstadoServicio,
				label: item.nombre
			}));
			const sorted = sortBy(resp, 'id');
			setAllStateService(sorted);
		});
	};

	useEffect(() => {
		setClientSelected({
			nombre: 'Seleccione un cliente',
			id: null
		});

		setExternalUserSelected({
			nombre: 'Seleccione un usuario',
			id: null
		});

		setUserSelected({
			nombre: 'Seleccione un usuario generador',
			id: null,
			esVip: 2
		});
		setBillingUserSelected({ nombre: 'Seleccione usuario facturacion', id: null });

		getDataTable();
		getAllStatesService();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// use Hook of language v2
	const { formatterText, fillAtLeastOne } = useLangv2();

	// titles table
	const titlesTable = [
		formatterText('table.title.service.ticket.number', '# Ticket del servicio'),
		formatterText('table.title.expedient', 'Expediente'),
		formatterText('table.title.princial.client.name', 'Nombre del cliente principal'),
		formatterText('table.title.client.id', 'Identificación'),
		formatterText('table.title.princial.client.phone', 'Telefono del cliente principal'),
		formatterText('table.title.user.name', 'Nombre de usuario'),
		formatterText('text.phone', 'Teléfono del usuario'),
		formatterText('table.title.date.hour.meet', 'Fecha y hora de la cita'),
		formatterText('tech.assigned', 'Técnico asignado'),
		formatterText('p.activities.associated', 'Actividades asociadas'),
		formatterText('table.title.city', 'Ciudad'),
		formatterText('table.title.address', 'Dirección'),
		formatterText('table.title.category.service', 'Categoría servicio'),
		formatterText('table.title.type.service', 'Tipo de servicio'),
		formatterText('table.title.state.service', 'Estado del servicio'),
		formatterText('table.title.special.service', 'Servicio especial'),
		formatterText('text.shedule.create.date', 'Fecha y hora de creación del servicio'),
		formatterText('table.actions', 'Acciones')
	];

	// get data from the table
	const getDataTable = () => {
		let idEmpleado = JSON.parse(localStorage.getItem('userData'))?.idEmpleado?.idEmpleado || '';
		let idAsesorCliente =
			JSON.parse(localStorage.getItem('userData'))?.idAsesorCliente?.idAsesor || '';
		if (idEmpleado !== '') {
			idEmpleado = parseInt(idEmpleado);
		}
		if (idAsesorCliente !== '') {
			idAsesorCliente = parseInt(idAsesorCliente);
		}
		const QUERY = `idEmpleado=${idEmpleado}&idAsesorCliente=${idAsesorCliente}`;
		setLoadingProgress(true);

		RequestUseCase.get(endpoints.technicalServices.getAll24Hrs(QUERY)).then((data) => {
			const newArray = [];

			data.forEach((item) => handleStructureItems(newArray, item));

			const sortedArray = SortDataByDate(newArray, 'desc');
			setDataTable(sortedArray);
			// show loading
			setLoadingProgress(false);

			setServices(sortedArray);
		});
	};

	// this function structure the items of the table
	const handleStructureItems = (newArray, item) => {
		const timeZone = 'America/Bogota';

		const fechaCita = new Date(item.fechaCita);
		const fechaCreacion = new Date(item.fechaCreacion);

		try {
			// client phone
			let clientName = 'No asignado';
			let clientPhone = 'No asignado';
			// user phone
			let userName = 'No asignado';
			let userPhone = 'No asignado';

			let documentClient = 'No asignado';

			if (item.idGeneradorCliente) {
				clientName = item.idGeneradorCliente.nombreRazonSocial;
				clientPhone = item.idGeneradorCliente.telefono;
				documentClient = item.idGeneradorCliente.identificacion;
			}

			if (item.idUsuario) {
				const name = `${item.idUsuario.primerNombre}${
					item.idUsuario.segundoNombre.length > 0 ? item.idUsuario.segundoNombre : ''
				}`;
				const lastName = `${item.idUsuario.primerApellido}${
					item.idUsuario.segundoApellido.length > 0 ? item.idUsuario.segundoApellido : ''
				}`;
				userName = `${name} ${lastName}`;
				userPhone = item.idUsuario.telefono;
			}

			// show technical assign
			let techAssing = 'Ninguno';
			let actividadProceso = 'N/A';
			if (item.idActividad.length != 0) {
				const actividad = item.idActividad.find(
					(actividad) => actividad.estado == ACTIVITYSTATUS.PROCESO
				);
				actividadProceso = actividad?.nombre ?? 'N/A';
			}

			if (item?.idTecnico !== null && item?.idTecnico !== undefined) {
				if (item.idTecnico.idTercero !== null) {
					techAssing = 'Avisar a greg codigo: ST';
				} else if (item.idTecnico.idEmpleado !== null) {
					const TECH = item.idTecnico.idEmpleado;
					techAssing = `${TECH.primerNombre} ${TECH.primerApellido} - ${TECH.identificacion}`;
				}
			}

			newArray.push({
				id: item.idServicio,
				nombre: item.ticket,
				expediente: item.expediente,
				nombreCliente: clientName,
				documentClient,
				telefonoCliente: clientPhone,
				userName,
				userPhone: userPhone.toString(),
				fechaHoraCita: dateFormat(fechaCita, 'yyyy/mm/dd ' + item.idHoraCita.hora + ':00 TT', {
					timeZone
				}),
				techAssing,
				activities: actividadProceso,
				city: item.idDireccion.idSector.idMunicipio.nombre,
				address: item.idDireccion.direccion,
				categoryService: item.idTipoServicio.idCategoriaServicio.nombre,
				typeService: item.idTipoServicio.nombre,
				stateService: item.idEstadoServicio.nombre,
				special: item.especial === 1 ? 'Si' : 'No',
				datetimeCreation: dateFormat(fechaCreacion, 'yyyy/mm/dd - HH:MM TT', { timeZone }),
				objeto: { ...item }
			});
		} catch (error) {
			console.log('Structure items: ', error);
		}
	};

	const handleChange = (e) => {
		const value = e.target.value;
		if (value.match('^[a-zA-Z0-9 ñáéíóúÁÉÍÓÚÑ]*$') != null) {
			setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
			setInputValue(value);

			if (value.length >= 3) {
				setShowAutocomplete(true);
			} else {
				setShowAutocomplete(false);
			}
		}
	};
	const handleAutocompleteSelect = (option) => {
		setFormData({ ...formData, identificacion: option });
		setInputValue(option);
		setShowAutocomplete(false);
	};

	// Handle to clear the select
	const handlerClear = (e) => {
		e.preventDefault();
		setShow(!show);

		// Clear selects
		setSelectValues({
			nombre_cliente: {
				id: null,
				label: ''
			},
			idDepartamento: {
				id: null,
				label: ''
			},
			nombre_usuario: {
				id: null,
				label: ''
			},
			idTecnico: {
				id: null,
				label: ''
			},
			idTipoServicio: {
				id: null,
				label: ''
			},
			idEstadoServicio: {
				id: null,
				label: ''
			}
		});
		// clear Dates
		setDates({
			rangoFechaCitaInicio: null,
			rangoFechaCitaFin: null,
			rangoFechaCreacionInicial: null,
			rangoFechaCreacionFinal: null,
			rangoValorHoraInicial: null,
			rangoValorHoraFinal: null
		});
		setFilterBill({
			billId: null,
			label: ''
		});

		// Clear inputs
		setFormData(new ServiceType());
		// fill the table
		getDataTable();
		// clear to suggestions selects
		setSelectValue({
			id: null,
			label: ''
		});
	};

	const handleFechaCreacionInicial = (date) => {
		setDates((prevState) => ({
			...prevState,
			...date
		}));
	};

	const handleFechaCreacionFinal = (date) => {
		setDates((prevState) => ({
			...prevState,
			...date
		}));
	};

	const handleFechaCitaInicioChange = (date) => {
		setDates((prevState) => ({
			...prevState,
			...date
		}));
	};
	const handleFechaCitaFinChange = (date) => {
		setDates((prevState) => ({
			...prevState,
			...date
		}));
	};

	// data of the table
	const [selectedSearch, setSearchSelected] = useState([
		{
			typeService: [],
			techinicals: [],
			clientsAssociate: [],
			users: []
		}
	]);

	useEffect(() => {
		getAllClientsToAssociate();
		getAllUsers();
		getAllTypeServices();
		getAllTechs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// get all type services to the select
	const getAllTypeServices = () => {
		RequestUseCase.get(endpoints.typeService.getAllTypeService).then((data) => {
			// create new array
			const newArray = [];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						id: item.idTipoServicio,
						label: `${item.nombre} - ${item.codigo}`
					});
				}
			});
			// set the values of the select
			setSearchSelected((prev) => ({
				...prev,
				typeService: newArray
			}));
		});
	};

	const [listIdentification, setListIdentification] = useState([]);
	const [showAutocomplete, setShowAutocomplete] = useState(false);
	const [inputValue, setInputValue] = useState('');

	// get all techs to the select
	const getAllTechs = () => {
		RequestUseCase.get(endpoints.services.getAllTechActives).then((data) => {
			// create new array
			const newArray = [];
			const identifications = [];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				newArray.push({
					id: item.idTecnico,
					label: `${item.nombre} ${item.identificacion}`
				});
				identifications.push(item.identificacion);
			});
			// set the values of the select
			setSearchSelected((prev) => ({
				...prev,
				techinicals: newArray
			}));
			setListIdentification((prev) => ({
				...prev,
				identification: identifications
			}));
		});
	};

	// get all clients to the select
	const getAllClientsToAssociate = () => {
		RequestUseCase.get(endpoints.clients.getAllClients).then((data) => {
			// create new array
			const newArray = [
				{
					id: 0,
					label: 'Ninguno'
				}
			];
			const identifications = [];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						id: item.idCliente,
						label: `${item.nombreRazonSocial}`
					});
					identifications.push(item.identificacion);
				}
			});
			// set the values of the select
			setSearchSelected((prev) => ({
				...prev,
				clientsAssociate: newArray
			}));
			setListIdentification((prev) => ({
				...prev,
				identification: identifications
			}));
		});
	};

	// get all users to the select
	const getAllUsers = () => {
		RequestUseCase.get(endpoints.userServices.getAll)
			.then((data) => {
				// console.log("get all users::: ", res);
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				data.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							id: item.idUsuarioServicio,
							label: `${item.primerNombre} ${item.segundoNombre} ${item.primerApellido}`
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					users: newArray
				}));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const dateFormatWithoutSeconds = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');

		return `${year}-${month}-${day} ${hours}:${minutes}`;
	};

	const searchAdvance = (e) => {
		e.preventDefault();
		let idEmpleado = JSON.parse(localStorage.getItem('userData'))?.idEmpleado?.idEmpleado || '';
		let idAsesorCliente =
			JSON.parse(localStorage.getItem('userData'))?.idAsesorCliente?.idAsesor || '';
		if (idEmpleado !== '') {
			idEmpleado = parseInt(idEmpleado);
		}
		if (idAsesorCliente !== '') {
			idAsesorCliente = parseInt(idAsesorCliente);
		}
		const QUERY = `idEmpleado=${idEmpleado}&idAsesorCliente=${idAsesorCliente}`;

		let RFCF =
			dates.rangoFechaCitaInicio && dates.rangoFechaCitaInicio.rangoFechaCitaInicio instanceof Date
				? dateFormatWithoutSeconds(dates.rangoFechaCitaInicio.rangoFechaCitaInicio)
				: null;

		let RFCI =
			dates.rangoFechaCitaFin && dates.rangoFechaCitaFin.rangoFechaCitaFin instanceof Date
				? dateFormatWithoutSeconds(dates.rangoFechaCitaFin.rangoFechaCitaFin)
				: null;

		// If RFCF and RFCI are instances of Date, convert them to "hh:mm" format
		if (RFCF instanceof Date) {
			RFCF = dateFormat(RFCF, 'HH:MM');
		} else {
			RFCF = null;
		}

		if (RFCI instanceof Date) {
			RFCI = dateFormat(RFCI, 'HH:MM');
		} else {
			RFCI = null;
		}

		let findRangoFechaCitaFin;
		let findRangoFechaCitaInicio;
		if (RFCF && RFCI) {
			// Combine the date part ("yyyy-mm-dd") with the time part ("hh:mm")
			findRangoFechaCitaFin = `${dateFormatWithoutSeconds(
				dates.rangoFechaCitaFin.rangoFechaCitaFin
			)} ${RFCF}`;
			findRangoFechaCitaInicio = `${dateFormatWithoutSeconds(
				dates.rangoFechaCitaInicio.rangoFechaCitaInicio
			)} ${RFCI}`;
		}

		const HOURS = JSON.parse(localStorage.getItem('hours'));

		// find the valor of the hour with the key hora
		const findValorHoraInicial = HOURS?.find((item) => item.hora === findRangoFechaCitaInicio);

		const findValorHoraFinal = HOURS?.find((item) => item.hora === findRangoFechaCitaFin);

		const rangoFechaCitaFin = dates.rangoFechaCitaFin
			? dateFormatWithoutSeconds(dates.rangoFechaCitaFin)
			: null;
		const rangoFechaCitaInicio = dates.rangoFechaCitaInicio
			? dateFormatWithoutSeconds(dates.rangoFechaCitaInicio)
			: null;

		const rangoFechaCreacionInicial = dates.rangoFechaCreacionInicial
			? dateFormatWithoutSeconds(dates.rangoFechaCreacionInicial)
			: null;
		const rangoFechaCreacionFinal = dates.rangoFechaCreacionFinal
			? dateFormatWithoutSeconds(dates.rangoFechaCreacionFinal)
			: null;

		let DATA = {
			ticket: formData.ticket === '' ? null : formData.ticket,
			expediente: formData.expediente === '' ? null : formData.expediente,
			idCliente: selectValues.nombre_cliente.id,
			identificacionCliente: formData.identificacion === '' ? null : formData.identificacion,
			// idUsuario: selectValues.nombre_usuario.id,
			idUsuario: selectValues.nombre_usuario ? selectValues.nombre_usuario.id : null,
			telefonoUsuario: formData.telefono_usuario === '' ? null : formData.telefono_usuario,
			rangoFechaCitaInicio,
			rangoFechaCitaFin,
			rangoValorHoraInicial: findValorHoraInicial ? findValorHoraInicial.valor : null,
			rangoValorHoraFinal: findValorHoraFinal ? findValorHoraFinal.valor : null,
			rangoFechaCreacionInicial: rangoFechaCreacionInicial,
			rangoFechaCreacionFinal: rangoFechaCreacionFinal,
			idTecnico: selectValues.idTecnico.id,
			idTipoServicio: selectValues.idTipoServicio.id,
			idEstadoServicio: selectValues.idEstadoServicio.id,
			facturaAsociada: filterBill.value || filterBill.value === 0 ? filterBill.value : null
		};

		// iterate each value of the object and check if it is null
		if (Object.values(DATA).every((item) => item === null)) {
			fillAtLeastOne();
		} else {
			setLoadingProgress(true);
			DATA = {
				...DATA,
				estado: 1
			};

			RequestUseCase.post(endpoints.technicalServices.advancedSearchWithTech(QUERY), DATA).then(
				(data) => {
					const newArray = [];
					data.forEach((item) => {
						handleStructureItems(newArray, item);
					});
					setDataTable(newArray);
					setLoadingProgress(false);
				}
			);
		}
	};

	return {
		permittedActions,
		services,
		loading,
		searchResults,
		show,
		setShow,
		formData,
		handleChange,
		selectedSearch,
		selectValues,
		setSelectValues,
		val,
		allStateService,
		filterBill,
		setFilterBill,
		loadingProgress,
		searchAdvance,
		handlerClear,
		handleFechaCitaInicioChange,
		handleFechaCitaFinChange,
		handleFechaCreacionInicial,
		handleFechaCreacionFinal,
		listIdentification,
		showAutocomplete,
		inputValue,
		handleAutocompleteSelect,
		formatterText,
		dataTable,
		DynamicTable,
		titlesTable,
		PAGE_NAMES,
		getDataTable,
		error,
		displayMessage,
		DisplayProgress
	};
}
