import { useIntl } from 'react-intl';

/**
 * @description Custom hook that return the string of a specific id language
 * @returns {object}
 */
const useLang = () => {
	// get the intl object
	const intl = useIntl();

	// return the string of the language by the id
	const formatterText = (idLang) => {
		const plainText = intl.formatMessage({ id: idLang });
		return plainText;
	};

	//   return the function to format the text
	return { formatterText };
};

export default useLang;
