import React from 'react';
// Import Contexts
// Import Hooks
// Import Components
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Search from 'common/Search';
import ExportJsonFile from 'components/utils/ExportJsonFile';
import UploadFile from 'components/utils/UploadFile';
// Import Libs
import { Link } from 'react-router-dom';
import 'styles/tab.css';
import ZonesViewModel from './Zones.viewModel';

const ZonesComponent = () => {
	const {
		tabs,
		setSelectedTab,
		selectedTab,
		indexTabSectors,
		searchResults,
		formatterText,
		permittedActions
	} = ZonesViewModel();
	return (
		<section className="table-container">
			<section className="userOptions">
				{permittedActions.consultar && (
					<Search
						placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
						width="50%"
					/>
				)}
				{permittedActions.exportar && (
					<ExportJsonFile
						moduleName={selectedTab.names}
						userName={JSON.parse(localStorage.getItem('userData')).usuario || 'ADMIN'}
						dataTable={searchResults}
					/>
				)}
				{permittedActions.importar && (
					<UploadFile currentZone={formatterText(selectedTab.nombre.props.id)} />
				)}
				{permittedActions.crear && selectedTab.create && (
					<Link style={{ textDecoration: 'none', alignSelf: 'center' }} to={selectedTab.route}>
						<button className="btn-add">{selectedTab.nombreLink}</button>
					</Link>
				)}
			</section>
			<Tabs
				defaultIndex={indexTabSectors}
				onSelect={(index) => localStorage.setItem('Zones', index)}
				selectedTabClassName="tab-option--selected"
			>
				<TabList className="tab-list">
					{tabs.map((tab, index) => (
						<Tab
							onClick={() => {
								setSelectedTab(tab);
								localStorage.setItem('indexTabSectors', index.toString());
								//permissionsAccess(tab.idPermiso);
							}}
							key={`tab_${index + 1}`}
							className="tab-option"
						>
							{tab.nombre}
						</Tab>
					))}
				</TabList>
				{permittedActions.consultar &&
					tabs.map((tab, index) => (
						<TabPanel key={`tab_panel_${index + 1}`}>{tab.componente}</TabPanel>
					))}
			</Tabs>
		</section>
	);
};

export default ZonesComponent;
