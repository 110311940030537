import { React, useEffect } from 'react';
import PropTypes from 'prop-types';
// Import Styles
import { useSeachContext } from 'context/SearchContext';
import { isEmpty } from 'lodash';
import 'styles/searchBar.css';

const Search = ({ placeholder, width, value, collection }) => {
	const { setDataTable, search, setSearch, handleSearch } = useSeachContext();

	const handleSearchClick = (event) => {
		const { preventDefault } = event;
		if (preventDefault) preventDefault();
		handleSearch(event);
	};

	useEffect(() => {
		if (!isEmpty(collection) && !!collection) setDataTable(collection);
	}, [collection]);

	useEffect(() => {
		if (!isEmpty(value) && !!value) setSearch(value);
	}, [value]);

	return (
		<div style={{ width }} className="wrap">
			<div className="search">
				<input
					type="text"
					className="searchTerm"
					placeholder={placeholder}
					onChange={handleSearch}
					value={search}
				/>
				<button disabled type="" className="searchButton">
					<img
						src={require('assets/search.png')}
						style={{ height: '20px' }}
						alt="search"
						onClick={handleSearchClick}
					/>
				</button>
			</div>
		</div>
	);
};

Search.propTypes = {
	placeholder: PropTypes.string,
	width: PropTypes.string,
	value: PropTypes.string,
	collection: PropTypes.array
};

export default Search;
