import React from 'react';
// Import Libs
import { Formiz } from '@formiz/core';
import { FormattedMessage } from 'react-intl';
// Import Components
import { InputFieldResponsive } from 'common/inputs/InputFieldResponsive';
import Table from 'common/minimalTables/TableMinimal';
import { Modal } from 'react-responsive-modal';
// Import Paths
import paths from 'services/paths';
import UpdateSubCategory from '../UpdateSubCategory';
//Import ViewModel
import CreateProductCategoryViewModel from './CreateProductCategory.viewModel';

export default function CreateProductCategoryComponent() {
	const {
		currentDataTable,
		currentProductCategory,
		formatterText,
		formData,
		handleAddproductCategory,
		handleClose,
		handleFormChange,
		handleOpen,
		handlerTextDescription,
		handlerTextDescriptionSubCategory,
		handleSubmit,
		myForm,
		navigate,
		open,
		titlesTableSubCategories
	} = CreateProductCategoryViewModel();

	return (
		<>
			<Formiz onChange={handleFormChange} connect={myForm}>
				<form onSubmit={handleSubmit}>
					<section className="form-responsive container-border-wrapForm">
						{/* Formulario del servicio principal */}
						<p className="wrap-form-title">
							<FormattedMessage id="p.category.product" defaultMessage="Categoría Producto" />
						</p>
						<section className="form-responsive-container-information">
							<section className="form-responsive-information__option">
								<InputFieldResponsive
									type="text"
									name="nombre"
									labelText={formatterText('p.name.category', 'Nombre categoría producto')}
									placeholder={formatterText('p.name.category', 'Nombre categoría de producto')}
									maxLength="100"
									required={formatterText('p.name.category.is.required')}
									validateInput="namestage"
								/>
							</section>
							<section className="form-responsive-information__option">
								<h3 className="p-styles">
									<FormattedMessage id="table.title.description" defaultMessage="Descripción" />
								</h3>
								<textarea
									className="input-primary-textarea"
									name="descripcion"
									value={formData.descripcion}
									onChange={handlerTextDescription}
									placeholder={formatterText('table.title.description', 'Descripción')}
									maxLength="200"
								/>
							</section>
							<section className="form-responsive-information__option">
								<InputFieldResponsive
									type="text"
									name="codigo"
									labelText={formatterText('p.code', 'codigo')}
									placeholder={formatterText(
										'input.placeholder.max.45.chars',
										'Ingrese hasta 45 caracteres'
									)}
									validateInput="code"
									maxLength="45"
									required={formatterText('table.title.code.required')}
								/>
							</section>
						</section>
						<p className="wrap-form-title">
							{formatterText('p.subcategory.product', 'Subcategorías del producto')}
						</p>
						<section className="form-responsive-container-information">
							<Table
								titles={titlesTableSubCategories}
								data={currentDataTable}
								type="subCategorias"
								handleOpen={handleOpen}
								labelTable={formatterText('p.subcategory.product', 'Subcategorías del producto')}
								canSearch={true}
							/>
						</section>
						<p className="wrap-form-title">
							<FormattedMessage
								id="p.add.subcatgory.product"
								defaultMessage="Agregar subcategoría producto"
							/>
						</p>
						<section
							className="grid-container-2c sm-gap"
							style={{
								width: '95%',
								margin: '0 auto'
							}}
						>
							<label className="d-flex">
								<InputFieldResponsive
									type="text"
									name="nombre"
									labelText={formatterText('p.subcategory.name', 'Nombre subcategoría')}
									placeholder={formatterText('p.subcategory.name', 'Nombre subcategoría')}
									maxLength="100"
									required={formatterText('p.subcategory.name.is.requerid')}
									validateInput="namestage"
								/>
							</label>
							<label className="d-flex">
								<InputFieldResponsive
									type="text"
									name="codigo"
									labelText={formatterText('p.code', 'codigo')}
									placeholder={formatterText(
										'input.placeholder.max.45.chars',
										'Ingrese hasta 45 caracteres'
									)}
									validateInput="code"
									maxLength="45"
									required={formatterText('table.title.code.required')}
								/>
							</label>
							<label className="d-flex" htmlFor="descripcion">
								<h3 className="text-inline">
									<FormattedMessage id="table.title.description" defaultMessage="Descripción" />
								</h3>
								<textarea
									className="input-primary-textarea width-80"
									type="text"
									name="descripcion"
									value={currentProductCategory.descripcion}
									onChange={handlerTextDescriptionSubCategory}
									placeholder={formatterText(
										'p.subcategory.desc',
										'Descripción de la subcategoría'
									)}
									maxLength="200"
								/>
							</label>
						</section>
						<section
							className="form-responsive-container-buttons"
							style={{
								height: '5.5rem'
							}}
						>
							<input
								onClick={handleAddproductCategory}
								type="button"
								className="btn-primary margin-auto"
								value={formatterText('p.add.subcategory', 'Agregar subcategoría')}
							/>
						</section>
					</section>
					<section className="form-responsive-container-buttons">
						<button type="submit" className="btn-primary">
							<FormattedMessage
								id="header.title.product.category.create"
								defaultMessage="Crear categoría de producto"
							/>
						</button>
						<button className="input-cancel" onClick={() => navigate(paths.products)}>
							<FormattedMessage id="btn.cancel" defaultMessage="Cancelar" />
						</button>
					</section>
				</form>
			</Formiz>
			<Modal
				open={open}
				onClose={handleClose}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<UpdateSubCategory onClose={handleClose} />
			</Modal>
		</>
	);
}
