import Cookie from 'js-cookie';

export const parseDataToFormik = ({
	formikInitialValues,
	dataByServer: {
		descripcion,
		estado = '',
		fechaCreacion,
		fechaModificacion,
		idRol,
		nombre,
		permissions,
		usuarioCreacion
	}
}) => {
	const newFormikData = {};

	if (idRol) {
		newFormikData.idRol = idRol;
		newFormikData.usuarioModificacion = Number(Cookie.get('idUsuario'));
	}

	if (nombre) {
		newFormikData.nombre = nombre;
	}

	if (descripcion) {
		newFormikData.description = descripcion;
	}
	if (typeof estado === 'number') {
		newFormikData.active = Boolean(estado);
	}

	if (fechaCreacion) {
		newFormikData.fechaCreacion = fechaCreacion;
	}

	if (fechaModificacion) {
		newFormikData.fechaModificacion = fechaModificacion;
	}

	if (usuarioCreacion) {
		newFormikData.usuarioCreacion = Number(usuarioCreacion);
	}

	if (permissions) {
		newFormikData.permissions = permissions;
	}

	return {
		...formikInitialValues,
		...newFormikData
	};
};
