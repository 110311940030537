import useAxios from 'hooks/useAxios';
import { AppContext } from 'context/AppContext';
import dateFormat from 'dateformat';
import { formatterText } from 'hooks/useLangv2';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import endpoints from 'services/api';

export default function SearchTechniciansViewModel() {
	const { RequestUseCase } = useAxios();

	const navigate = useNavigate();
	// SETEOS DE STATES Y CONTEXT
	const { selectNotificacion: context } = useContext(AppContext);
	const [selectNotificacion, setSelectNotificacion] = useState(context);
	const [technicians, setTechnicians] = useState([]);
	const [technicians2, setTechnicians2] = useState([]);
	const [technicians3, setTechnicians3] = useState([]);
	const [open, setOpen] = useState(false);
	const [selectTech, setSelectTech] = useState(false);
	const [techToAssign, setTechToAssign] = useState({});

	const titlesTableCostProduct = [
		formatterText('title.service.search.manual.table.techniciansForService'),
		formatterText('title.service.search.manual.table.startOfPreviousService'),
		formatterText('title.service.search.manual.table.riskOfNonCompliancePreviousService'),
		formatterText('title.service.search.manual.table.riskOfNonComplianceNewService'),
		formatterText('title.service.search.manual.table.details'),
		formatterText('title.service.search.manual.table.actions')
	];

	const textRisk = {
		1: 'Bajo',
		2: 'Medio',
		3: 'Alto'
	};
	const handleClose = (fun) => {
		fun(false);
	};
	const calcularDiferenciaFecha = (fecha1, fecha2) => {
		const date1 = new Date(fecha1);
		const date2 = new Date(fecha2);
		const diffTime = Math.abs(date2 - date1);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		return diffDays;
	};

	const calculardiferencia = (h1, h2) => {
		// const date1 = new Date(h1);
		// const date2 = new Date(h2);
		// const diffTime = Math.abs(date2 - date1);
		// const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
		// return diffHours;

		// Calcula los minutos de cada hora
		const minutos_inicio = h1.split(':').reduce((p, c) => parseInt(p) * 60 + parseInt(c));
		const minutos_final = h2.split(':').reduce((p, c) => parseInt(p) * 60 + parseInt(c));

		// Diferencia de minutos
		var diferencia = minutos_final - minutos_inicio;

		// Cálculo de horas y minutos de la diferencia
		var horas = Math.floor(diferencia / 60);

		var minutos = diferencia % 60;
		var str = '' + horas;
		var pad = '00';
		// Ans is the total of hours
		var ans = pad.substring(0, pad.length - str.length) + str;

		return ans + ':' + (minutos < 10 ? '0' : '') + minutos;
	};

	// DECLARAR LA TRAIDA DE DATOS DE AUTO BUSCADO
	useEffect(() => {
		if (Object.keys(selectNotificacion).length === 0) {
			navigate('/');
		} else {
			agendasInformation();
		}
	}, [selectNotificacion]);

	useEffect(() => {
		setSelectNotificacion(context);
	}, [context]);

	const agendasInformation = () => {
		RequestUseCase.get(endpoints.agenda.getAllAgenda)
			.then(consultaStart)
			.catch((err) => {
				console.log(err);
			});
	};

	const consultaStart = () => {
		// SETEAR LA TABLA 1
		setTechnicians([]);
		// SETEAR LA TABLA 2
		setTechnicians2([]);
		// SETEAR LA TABLA 3
		setTechnicians3([]);
		console.log('Data of the context notification: ', selectNotificacion);
		// TECNICOS ASOCIADOS AL SERVICIO POR LA DIRRECCION, en este caso evalua si dicho servicio tiene tecnicos asociados en la zona
		RequestUseCase.get(endpoints.services.getSearchByDireccion(selectNotificacion.idDireccion))
			.then((res) => {
				console.log('First call 1:', res);
				searchData(res);
			})
			.catch((err) => {
				console.log('Error 1:', err);
			});
	};

	// Verify which table is the category is relation with
	const searchData = (data) => {
		const arrayCategoryTechnical = [];
		const promise = data.map(
			(tech) =>
				new Promise((resolve) => {
					RequestUseCase.get(endpoints.associateDataTechnical.getCategoryById(tech.idTecnico)).then(
						(res) => {
							if (res.length > 0) {
								res.forEach((cat) => {
									// if the category Service to define is the same that the category of the technical
									if (cat.idCategoriaServicio === selectNotificacion.idCategoriaServicio) {
										arrayCategoryTechnical.push(tech);
									}
								});
							}
							resolve(true);
						}
					);
				})
		);

		Promise.all(promise)
			.then(() => {
				console.log('Array of techs categ: ', arrayCategoryTechnical);
				getUnityBusiness(arrayCategoryTechnical);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// Verify data if the categoryService
	const getUnityBusiness = async (data) => {
		const arrayTables = [];
		// Promise to decide if the technical is in the same unity business and select in which table goes
		const dataToIterate = data;

		const promise = dataToIterate.map(
			(tech) =>
				new Promise((resolve) => {
					RequestUseCase.get(endpoints.associateDataTechnical.getSucursalById(tech.idUsuario)).then(
						(TechClient) => {
							// console.log("tech", tech.idUsuario);
							if (TechClient.length > 0) {
								TechClient.forEach((client) => {
									const conditionalSameClient =
										client.idCliente === selectNotificacion.idGeneradorCliente;
									if (conditionalSameClient) {
										// console.log("tech", tech);
										// remove this tech from the array dataToIterate
										// dataToIterate = dataToIterate.filter(
										//   (item) => item.idTecnico !== tech.idTecnico
										// );
										const dataExist = {
											tech,
											clienteAssociate: true,
											unidadNegocio: false,
											noAssociate: false
										};
										arrayTables.push(dataExist);
									}
								});
							}

							resolve(true);
						}
					);
				})
		);

		// Promise to define if the userId have Sucursales associated
		const promise2 = dataToIterate.map((tech) => {
			new Promise((resolve) => {
				RequestUseCase.get(
					endpoints.associateDataTechnical.getUnityBusinessById(tech.idUsuario)
				).then((UnityBussines) => {
					// console.log("unityBussines", unityBussines);

					if (UnityBussines.length > 0) {
						UnityBussines.forEach((ub) => {
							const conditionalSameUnityBusiness =
								ub.idUnidadNegocio === selectNotificacion.idUnidadNegocio;
							if (conditionalSameUnityBusiness) {
								// console.log("tech", tech);
								const dataExist = {
									tech,
									unidadNegocio: true
								};

								arrayTables.push(dataExist);
							}
						});
					} else {
						// console.log("entre 3");
						// If the technical dont have unity business, the data is not associate. Its goes to the table 3
						const dataDontExist = {
							tech,
							noAssociate: true
						};
						arrayTables.push(dataDontExist);
					}
					resolve(true);
				});
			});
		});

		Promise.all([promise, promise2])
			.then(() => {
				setTimeout(() => {
					// check if a tech is repeated in the array
					const arrayTables2 = [];
					arrayTables.forEach((item) => {
						const exist = arrayTables2.find(
							(item2) => item2.tech.idTecnico === item.tech.idTecnico
						);
						if (!exist) {
							arrayTables2.push(item);
						}
					});

					console.log('filter repeated techs: ', arrayTables);
					// Get schedule from all technical
					getHorary(arrayTables2);
				}, 1000);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// Get schedule from the technical
	const getHorary = (data) => {
		const arrayAssociateHorary = [];
		const promise = data.map(
			(tech) =>
				new Promise((resolve) => {
					RequestUseCase.get(endpoints.agenda.getAgendaByid(tech.tech.idUsuario)).then((res) => {
						console.log('res ', res);
						// buscar en agendasData si el res.idAgenda existe y si existe, saber si agendasData.idTipoAgenda.idTipoAgenda === 1

						// res.forEach((item) => {
						//   agendasData.forEach((agenda) => {
						//     if (agenda.idAgenda === item.idAgenda) {
						//       if (agenda.idTipoAgenda.idTipoAgenda === 1) {
						//         console.log("es disponible");
						//         // Save important data from the date
						//       } else {
						//         console.log("no es disponible");
						//       }
						//     }
						//   });
						// });

						arrayAssociateHorary.push({
							id: tech.tech.idUsuario,
							name: tech.tech.nombre,
							tech: tech.tech,
							unidadNegocio: tech.unidadNegocio,
							noAssociate: tech.noAssociate,
							clienteAssociate: tech.clienteAssociate,
							data: res
						});
						// console.log("Array of horary: ", arrayAssociateHorary);
						resolve();
					});
				})
		);

		Promise.all(promise).then(() => {
			comprobarDisponibilidad(arrayAssociateHorary);
		});
	};

	/**
	 * [1] Verify the if exist a schedule
	 * [2] Slit the date to get the hour, min and sec
	 * [3] Verify if the date is the same that the date of the context
	 */
	const comprobarDisponibilidad = (techs) => {
		techs.forEach((tech, primaryIndex) => {
			const horarios = tech.data;
			// [1]
			if (horarios.length > 0) {
				horarios.forEach((horario, index) => {
					// [2]
					const date = dateFormat(horario.fechaFin, 'yyyy-mm-dd');
					const hora = horario.fechaFin.split('T')[1].split(':')[0];
					const min = horario.fechaFin.split('T')[1].split(':')[1];
					const hora1 = hora + ':' + min;
					// [3]
					if (date === selectNotificacion.fechaCita) {
						// 5pm SERV - 5pm TECH
						const hora = calculardiferencia(hora1, selectNotificacion.horaCitaFin);
						// console.log("calc: ", hora);
						// conditional if the services is running
						if (hora.charAt(0) === '-') {
							techs[primaryIndex].data[index].riesgo = 'alto';
							techs[primaryIndex].data[index].idRisk = '2';
						} else if (hora.charAt(0) !== '0') {
							techs[primaryIndex].data[index].riesgo = 'bajo';
							techs[primaryIndex].data[index].idRisk = '0';
						} else if (hora.charAt(0) === '0' && hora.charAt(1) !== '0') {
							techs[primaryIndex].data[index].riesgo = 'bajo';
							techs[primaryIndex].data[index].idRisk = '0';
						} else if (hora.charAt(0) === '0' && hora.charAt(1) === '0' && hora.charAt(3) >= '5') {
							techs[primaryIndex].data[index].riesgo = 'bajo';
							techs[primaryIndex].data[index].idRisk = '0';
						} else if (
							hora.charAt(0) === '0' &&
							hora.charAt(1) === '0' &&
							hora.charAt(3) <= '4' &&
							hora.charAt(3) >= '2'
						) {
							techs[primaryIndex].data[index].riesgo = 'medio';
							techs[primaryIndex].data[index].idRisk = '1';
						} else if (hora.charAt(0) === '0' && hora.charAt(1) === '0' && hora.charAt(3) < '2') {
							techs[primaryIndex].data[index].riesgo = 'alto';
							techs[primaryIndex].data[index].idRisk = '2';
						}
					} else {
						// If the date is not the same, the risk is low
						techs[primaryIndex].data[index].riesgo = 'bajo';
						techs[primaryIndex].data[index].idRisk = '0';
					}
				});
			}
		});
		declararTabla(techs);
	};

	const declararTabla = (techs) => {
		let array = [];
		let minimo = {};
		const array2Min = [];
		console.log('techss data: ', techs);
		techs.forEach((tech) => {
			tech.data.forEach((horario) => {
				const date = dateFormat(horario.fechaInicio, 'yyyy-mm-dd');
				const dif = calcularDiferenciaFecha(date, selectNotificacion.fechaCita);
				const data = {
					dif: dif ? dif : 0,
					horario: horario,
					tech: tech
				};
				array.push(data);
			});
			minimo = array.find((item) => {
				return (
					item.dif ===
					Math.min.apply(
						Math,
						array.map((item) => item.dif)
					)
				);
			});
			array2Min.push(minimo);
			array = [];
		});
		formatearTabla(array2Min);
	};

	const formatFecha = (fecha) => {
		// console.log("fecha", dateFormat(fecha, "yyyy-mm-dd"));
		if (fecha !== undefined) {
			// console.log("fechatecnico ", dateFormat(fecha, "yyyy-mm-dd"));
			// console.log("fechaServicio ", selectNotificacion.fechaCita);
			// console.log(
			//   dateFormat(fecha, "yyyy-mm-dd") === selectNotificacion.fechaCita
			// );
			if (dateFormat(fecha, 'yyyy-mm-dd') === selectNotificacion.fechaCita) {
				const date = dateFormat(fecha, 'yyyy-mm-dd');
				const hora = fecha.split('T')[1].split(':')[0];
				const min = fecha.split('T')[1].split(':')[1];
				const hora1 = hora + ':' + min;
				return date + ' ' + hora1;
			} else {
				return 'No disponible';
				// const date = dateFormat(fecha, "yyyy-mm-dd");
				// const hora = fecha.split("T")[1].split(":")[0];
				// const min = fecha.split("T")[1].split(":")[1];
				// const hora1 = hora + ":" + min;
				// return date + " " + hora1;
			}
		} else {
			return 'No tiene agendas asignadas';
		}
	};

	const formatearTabla = (techs) => {
		//eliminar los undefined
		const techsFiltrados = techs.filter((tech) => {
			return tech !== undefined;
		});

		//eliminar tecnicos con el mismo tech.id
		const busqueda = techsFiltrados.reduce((acc, i) => {
			acc[i.tech.id] = ++acc[i.tech.id] || 0;
			return acc;
		}, {});
		// console.log("busqueda", busqueda);
		const duplicados = techsFiltrados.filter((tech) => {
			return busqueda[tech.tech.id];
		});

		// console.log("duplicados", duplicados);
		if (duplicados.length > 0) {
			//escoger el duplicado con menor diferencia en horas
			let minimo = {};
			const array2Min = [];
			duplicados.forEach((tech) => {
				tech.tech.data.forEach((horario) => {
					const date = dateFormat(horario.fechaInicio, 'yyyy-mm-dd');
					const dif = calcularDiferenciaFecha(date, selectNotificacion.fechaCita);
					const data = {
						dif: dif ? dif : 0,
						horario: horario,
						tech: tech
					};
					array2Min.push(data);
				});
			});
			minimo = array2Min.find((item) => {
				return (
					item.dif ===
					Math.min.apply(
						Math,
						array2Min.map((item) => item.dif)
					)
				);
			});
			const techsFiltrados2 = techsFiltrados.filter((tech) => {
				return tech.tech.id !== minimo.tech.tech.id;
			});
			techsFiltrados2.push(minimo.tech);
			// eliminar los duplicados
			// console.log("techsFiltrados2 ", techsFiltrados2);
			const finalTechs = [];
			const indexTechs = [];
			techsFiltrados2.forEach((et) => {
				// console.log("et ", et.tech);
				if (!indexTechs.includes(et.tech.id)) {
					finalTechs.push(et);
					indexTechs.push(et.tech.id);
				}
			});
			console.log('finalTechs 1', finalTechs);
			setAllTables(finalTechs);
		} else {
			console.log('finalTechs 2', techs);
			setAllTables(techs);
		}
	};

	const setAllTables = (techs) => {
		const array = [];
		console.log('before set techs', techs);
		techs.forEach((tech) => {
			// if (formatFecha(tech.horario.fechaFin) !== "No disponible") {
			const obj = {
				id: tech.tech.id,
				name: tech.tech.name,
				prevServ: formatFecha(tech.horario.fechaFin),
				prevRisk: 'Alto',
				newRisk: textRisk[tech.horario.idRisk],
				tech: tech.tech
			};
			array.push(obj);
			// }
		});
		const arrayUnidad = [];
		const arrayNoAssociate = [];
		const arrayClient = [];

		array.forEach((tech) => {
			if (tech.tech.unidadNegocio) {
				console.log('hay un tech con UN');
				arrayUnidad.push(tech);
			} else if (tech.tech.noAssociate) {
				console.log('hay un tech con no esta asociadod');
				arrayNoAssociate.push(tech);
			} else {
				console.log('hay un tech con asociadosClient');
				arrayClient.push(tech);
			}
		});

		console.log('arrayClient', arrayClient);
		console.log('arrayUnidad', arrayUnidad);
		console.log('arrayNoAssociate', arrayNoAssociate);
		setTechnicians(arrayClient);
		setTechnicians2(arrayUnidad);
		setTechnicians3(arrayNoAssociate);
	};

	const clickOnRow = (tech) => {
		setTechToAssign(tech);
		setSelectTech(true);
	};

	// Assign technical to the service
	const clickFun = (dataToAssign) => {
		console.log(dataToAssign);
		// console.log("Data del servicio", selectNotificacion);
		const formatData = {
			idServicioTecnico: null,
			idTecnico: {
				idTecnico: dataToAssign.tech.tech.idTecnico
			},
			idServicio: {
				idServicio: selectNotificacion.idServicio
			},
			principal: 1,
			asignaAsesor: 1
		};
		// console.log(formatData);

		// Get id of the services
		RequestUseCase.get(endpoints.services.getServiceById(selectNotificacion.idServicio))
			.then((serviceInfo) => {
				if (
					selectNotificacion.fechaCita === serviceInfo.servicio.fechaCita.split('T')[0] &&
					selectNotificacion.horaCita === serviceInfo.servicio.idHoraCita.hora
				) {
					console.log('son la misma fecha');
					selectServiceToTech(formatData);
				} else {
					console.log('son diferentes fechas');
					// // TODO UPDATE services
					const serviceData = serviceInfo.servicio;

					console.log('dataToUpdate', serviceData);

					//   let dataSend = {
					//     "servicio": {
					//         "idServicio": serviceData.idServicio,
					//         "idGeneradorCliente": {
					//             "idCliente": serviceData.idGeneradorCliente
					//         },
					//         "idGeneradorUsuario": serviceData.idGeneradorUsuario,
					//         "idUsuario": {
					//             "idUsuarioServicio": serviceData.
					//         },
					//         "idDireccion": {
					//             "idDireccion": serviceData.
					//         },
					//         "idTipoServicio": {
					//             "idTipoServicio": serviceData.
					//         },
					//         "descripcion": serviceData.,
					//         "idMedioComunicacion": {
					//             "idMedioComunicacion": serviceData.
					//         },
					//         "idCanalComunicacion": {
					//             "idCanalComunicacion": serviceData.
					//         },
					//         "fechaCita": serviceData.,
					//         "idHoraCita":{
					//             "idHora": hours.find((hour) => {
					//               return hour.hora === selectNotificacion.horaCita;
					//             })
					//         },
					//         "expediente": serviceData. ,
					//         "especial" : serviceData.,
					//         "idEstadoServicio": {
					//             "idEstadoServicio": serviceData.
					//         },
					//         "usuarioModificacion": serviceData.,
					//         "usuarioCreacion": serviceData.,
					//         "ticket": serviceData.,
					//         "fechaCreacion": serviceData.,
					//         "fechaModificacion": serviceData.,
					//         "estado": serviceData.
					//     },
					//     "tecnico": {
					//          "idTecnico": serviceData.
					//     }
					// }
					// updateItem(endpoints.services.updateService, dataToUpdate)
					//   .then((res) => {
					//     console.log("se actualizo la hora y fecha del servicio");
					//     selectServiceToTech(formatData);
					//   })
					//   .catch((err) => {
					//     console.log(err);
					//   });
					// addItem(endpoints.services.saveAutoSearchTechnical, formatData).then(() => {});
				}
			})
			.catch((error) => {
				console.log('error of get service info: ', error);
			});

		// update service
	};

	const selectServiceToTech = (formatData) => {
		console.log('formatData', formatData);
		RequestUseCase.post(endpoints.services.assignService, formatData).then(() => {
			console.log('asignado');
			setSelectTech(false);
			navigate('/');
		});
	};

	//Un estilo centrado de color verde
	const styleCenter = {
		textAlign: 'center',
		color: '#0AA48A',
		fontSize: '20px',
		fontWeight: 'bold',
		marginTop: '20px',
		//centrar el div
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: '25px'
	};

	//Un estilo a la izquierda de color verde
	const styleLeft = {
		textAlign: 'left',
		color: '#0AA48A',
		fontSize: '20px',
		fontWeight: 'bold',
		marginLeft: '20px',
		marginRight: '20px',
		//centrar el div
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'left'
	};

	//wrap de dos items en linea
	const styleWrap = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		marginBottom: '20px',
		width: '100%'
	};
	return {
		styleCenter,
		styleWrap,
		styleLeft,
		selectNotificacion,
		setOpen,
		handleClose,
		technicians,
		technicians2,
		technicians3,
		titlesTableCostProduct,
		setSelectNotificacion,
		techToAssign,
		selectTech,
		setSelectTech,
		clickFun,
		consultaStart,
		clickOnRow,
		open
	};
}
