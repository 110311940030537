import Search from 'common/Search';
import React from 'react';
// Import Hooks
import { Link } from 'react-router-dom';
import paths from 'services/paths';
// paths to routes
// hook para cargar los datos
// Llamar a la API
// Llamar a la tabla dinamica
import DynamicTable from 'common/DynamicTable/DynamicHead';
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';
import { FormattedMessage } from 'react-intl';
import FileViewModel from './File.viewModel';

function FileComponent() {
	const {
		file,
		loading,
		getDataTable,
		handleEditStateItem,
		handleDeleteItem,
		displayLoading,
		permittedActions,
		formatterText,
		titles
	} = FileViewModel();

	return (
		<>
			{!loading && file !== null ? (
				<section className="table-container">
					<section className="userOptions">
						{permittedActions.consultar && (
							<Search
								placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
								width="50%"
							/>
						)}
						{permittedActions.crear && (
							<Link to={paths.createFile}>
								<button className="btn-add">
									<FormattedMessage id="btn.createfile" defaultMessage="Crear archivo adjunto" />
								</button>
							</Link>
						)}
					</section>
					{permittedActions.consultar && (
						<DynamicTable
							titles={titles}
							data={getDataTable}
							pageName={PAGE_NAMES.Archivos}
							routeToEdit={paths.updateFile}
							canModify={permittedActions.editar}
							handleEditStateItem={handleEditStateItem}
							handleDeleteItem={handleDeleteItem}
							canDeleted={permittedActions.eliminar}
						/>
					)}
				</section>
			) : (
				displayLoading()
			)}
		</>
	);
}

export default FileComponent;
