import paths from 'services/paths';

const Items = [
	{
		idMaster: 'nav.parent.title.1',
		msgMaster: 'Configuración del sistema',
		pathToGo: paths.users,
		lang: 'nav.parent.1.child.option.1',
		msg: 'Usuarios',
		permissionVerify: 'userModules'
	},
	{
		idMaster: 'nav.parent.title.1',
		msgMaster: 'Configuración del sistema',
		pathToGo: paths.roles,
		lang: 'nav.parent.1.child.option.2',
		msg: 'Permisos y roles',
		permissionVerify: 'roleModules'
	},
	{
		idMaster: 'nav.parent.title.1',
		msgMaster: 'Configuración del sistema',
		pathToGo: paths.createMail,
		lang: 'nav.parent.1.child.option.3',
		msg: 'Configuración de correos',
		permissionVerify: 'mailModules'
	},
	{
		idMaster: 'nav.parent.title.1',
		msgMaster: 'Configuración del sistema',
		pathToGo: paths.zones,
		lang: 'nav.parent.1.child.option.4',
		msg: 'Catálogo de zonas',
		permissionVerify: 'zoneModules'
	},
	{
		idMaster: 'nav.parent.title.1',
		msgMaster: 'Configuración del sistema',
		pathToGo: paths.audits,
		lang: 'nav.parent.1.child.option.5',
		msg: 'Auditoría de registros',
		permissionVerify: 'auditModules'
	},
	{
		idMaster: 'nav.parent.title.1',
		msgMaster: 'Configuración del sistema',
		pathToGo: paths.files,
		lang: 'nav.parent.1.child.option.6',
		msg: 'Tipos archivos adjuntos',
		permissionVerify: null
	},
	{
		idMaster: 'nav.parent.title.1',
		msgMaster: 'Configuración del sistema',
		pathToGo: paths.parameters,
		lang: 'nav.parent.2.child.option.4',
		msg: 'Parámetros de cliente',
		permissionVerify: null
	},
	{
		idMaster: 'nav.parent.title.1',
		msgMaster: 'Configuración del sistema',
		pathToGo: paths.services,
		lang: 'nav.parent.3.child.option.5',
		msg: 'Parámetros de servicios',
		permissionVerify: null
	},
	{
		idMaster: 'nav.parent.title.2',
		msgMaster: 'Empleados y técnicos',
		pathToGo: paths.positions,
		lang: 'nav.parent.2.child.option.1',
		msg: 'Cargos',
		permissionVerify: 'positionsModules'
	},
	{
		idMaster: 'nav.parent.title.2',
		msgMaster: 'Empleados y técnicos',
		pathToGo: paths.contracts,
		lang: 'nav.parent.2.child.option.2',
		msg: 'Tipo de contrato',
		permissionVerify: null
	},
	{
		idMaster: 'nav.parent.title.2',
		msgMaster: 'Empleados y técnicos',
		pathToGo: paths.employees,
		lang: 'nav.parent.2.child.option.3',
		msg: 'Empleados',
		permissionVerify: null
	},
	{
		idMaster: 'nav.parent.title.2',
		msgMaster: 'Empleados y técnicos',
		pathToGo: paths.technical,
		lang: 'nav.parent.2.child.option.5',
		msg: 'Técnicos',
		permissionVerify: null
	},
	{
		idMaster: 'nav.parent.title.3',
		msgMaster: 'Clientes y terceros',
		pathToGo: paths.thirdParties,
		lang: 'nav.parent.3.child.option.1',
		msg: 'Terceros',
		permissionVerify: 'thirdModules'
	},
	{
		idMaster: 'nav.parent.title.3',
		msgMaster: 'Clientes y terceros',
		pathToGo: paths.clients,
		lang: 'nav.parent.3.child.option.2',
		msg: 'Clientes',
		permissionVerify: 'clientModules'
	},
	{
		idMaster: 'nav.parent.title.3',
		msgMaster: 'Clientes y terceros',
		pathToGo: paths.providers,
		lang: 'nav.parent.3.child.option.4',
		msg: 'Proveedores',
		permissionVerify: null
	},
	{
		idMaster: 'nav.parent.3.child.option.7',
		msgMaster: 'Servicios',
		pathToGo: paths.products,
		lang: 'nav.parent.3.child.option.3',
		msg: 'Productos',
		permissionVerify: null
	},
	{
		idMaster: 'nav.parent.3.child.option.7',
		msgMaster: 'Servicios',
		pathToGo: paths.schedule,
		lang: 'nav.parent.3.child.option.6',
		msg: 'Agendas',
		permissionVerify: null
	},
	{
		idMaster: 'nav.parent.3.child.option.7',
		msgMaster: 'Servicios',
		pathToGo: paths.serviceModule,
		lang: 'nav.parent.3.child.option.7',
		msg: 'Servicios',
		permissionVerify: null
	},
	{
		idMaster: 'nav.parent.3.child.option.7',
		msgMaster: 'Servicios',
		pathToGo: paths.ganttServices,
		lang: 'nav.parent.3.child.option.8',
		msg: 'Gestión de Homeview',
		permissionVerify: null
	},
	{
		idMaster: 'nav.parent.3.child.option.7',
		msgMaster: 'Servicios',
		pathToGo: paths.panelNotifications,
		lang: 'nav.parent.3.child.option.9',
		msg: 'Notificaciones',
		permissionVerify: null
	},

	{
		idMaster: 'nav.parent.3.child.option.7',
		msgMaster: 'Servicios',
		pathToGo: paths.invoice,
		lang: 'nav.parent.3.child.option.10',
		msg: 'Conciliaciones',
		permissionVerify: null
	}
];

export default Items;
