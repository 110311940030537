import React from 'react';
// Import Contexts
// Import Hooks
// Import Components
import DynamicTable from 'components/zones/DynamicTable';
// Import Libs
// Import Services
import { PAGES } from 'constants/lang/services/services/pages';
import paths from 'services/paths';
import { SectorsProps } from './Sectors.props';
import SectorsViewModel from './Sectors.viewModel';

const Sectors = ({ canDeleted, canModify }) => {
	const { loading, error, displayMessage, displayLoading, searchResults, getData, titles } =
		SectorsViewModel();
	return (
		<>
			{!loading ? (
				<div className="tab-container">
					{!loading && (
						<DynamicTable
							titles={titles}
							data={searchResults}
							getData={getData}
							pageName={PAGES.Sectores}
							routeToEdit={paths.updateSector}
							canDeleted={canDeleted}
							canModify={canModify}
						/>
					)}
				</div>
			) : error ? (
				displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			) : (
				displayLoading()
			)}
		</>
	);
};

Sectors.propTypes = SectorsProps;

export default Sectors;
