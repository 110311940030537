import React from 'react';

// Import libs
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

// Import Components
import Search from 'common/Search';

//Import ViewModel
import ClientParametersTableViewModel from './ClientParametersTable.viewModel';

const ClientParametersTableComponent = () => {
	const {
		formatterText,
		indexTabClient,
		permissionsAccess,
		permittedActions,
		selectedTab,
		setSearch,
		setSelectedTab,
		tabs
	} = ClientParametersTableViewModel();

	return (
		<section className="table-container">
			<section className="userOptions">
				{permittedActions.consultar && (
					<Search
						placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
						width="50%"
					/>
				)}
				{permittedActions.crear && (
					<Link style={{ textDecoration: 'none', alignSelf: 'center' }} to={selectedTab.route}>
						<button className="btn-add">{selectedTab.nombreLink}</button>
					</Link>
				)}
			</section>
			{
				<Tabs
					defaultIndex={indexTabClient}
					onSelect={(index) => localStorage.setItem('ClientParametersTable', index)}
					selectedTabClassName="tab-option--selected"
				>
					<TabList className="tab-list">
						{tabs.map((tab, index) => (
							<Tab
								onClick={() => {
									setSearch('');
									setSelectedTab(tab);
									localStorage.setItem('indexTabClient', index.toString());
									permissionsAccess(tab.idPermiso);
								}}
								key={index}
								className="tab-option"
							>
								{tab.nombre}
							</Tab>
						))}
					</TabList>
					{tabs.map((tab, index) => (
						<TabPanel key={index}>
							{permittedActions.consultar ? (
								tab.componente
							) : (
								<div>No permission to view this content</div>
							)}
						</TabPanel>
					))}
				</Tabs>
			}
		</section>
	);
};

export default ClientParametersTableComponent;
