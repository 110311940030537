/**
 * @returns {object}
 */
export const getFormikInitialValues = () => {
	return {
		area: '',
		host: '',
		usuario: '',
		clave: '',
		puerto: null,
		smtpSecure: false,
		smtpAuth: false
	};
};
