import React, { useEffect, useRef, useState } from 'react';
// Import Context
// Import Hooks
import useAxios from 'hooks/useAxios';
import useLangv2, { formatterText } from 'hooks/useLangv2';
import { useParams } from 'react-router';
// Import Components
import SelectorGrid from 'common/selects/SelectorGrid';
import HandlerNumbers from 'common/validators/HandlerNumbers';
// Import libs
import { FormattedMessage } from 'react-intl';
// Import Services
import { useSeachContext } from 'context/SearchContext';
import endpoints from 'services/api';

export default function CreateProductAssociate({ getDataTable }) {
	// Manage axios requests
	const { COOKIE_USER, RequestUseCase } = useAxios();
	//make a ref to the form
	const selectorRef = useRef(null);
	const amountRef = useRef(null);
	const { errorProcess, noFilledContent, newItemCreated, updatedItem, customSB } = useLangv2();

	// useContext of data table
	const { dataTable } = useSeachContext();
	//   state of loading
	const [loading, setLoading] = useState(false);
	// get id url
	const { id } = useParams();
	// State to save the data of the form
	const [formItem, setFormItem] = useState({
		cantidad: ''
	});
	// Value that select use
	const [selectedSearch, setSearchSelected] = useState([
		{
			product: []
		}
	]);
	// Helps to save the ids and labels of the selects data
	const [selectIds, setSelectIds] = useState({
		idProducto: {
			value: 0,
			label: ''
		}
	});

	//   fill the selects
	useEffect(() => {
		getAllProductByIdService();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Get all the products
	const getAllProductByIdService = () => {
		RequestUseCase.get(endpoints.serviceProduct.getAllProductsByIdService(id)).then((data) => {
			// create new array
			const newArray = [];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				newArray.push({
					value: item,
					label: `${item.nombreProducto} - ${item.sku} - ${item.abreviaturaUnidadMedida}`
				});
			});
			// set the values of the select
			setSearchSelected((prev) => ({
				...prev,
				product: newArray
			}));
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		if (selectIds.idProducto.value !== 0 && formItem.cantidad !== '') {
			const CURRENT_AMOUNT = parseInt(formItem.cantidad);
			const NEW_DATA = {
				idProducto: selectIds.idProducto.value.idProducto,
				nombreProducto: selectIds.idProducto.value.nombreProducto,
				idCategoriaProducto: selectIds.idProducto.value.idCategoriaProducto,
				sku: selectIds.idProducto.value.sku,
				nombreCategoriaProducto: selectIds.idProducto.value.nombreCategoriaProducto,
				idServicio: parseInt(id),
				cantidadSolicitada: CURRENT_AMOUNT,
				idUnidadMedida: selectIds.idProducto.value.idUnidadMedida,
				usuarioCreacion: COOKIE_USER
			};

			//  verify if the product is already in the table
			const FOUND = dataTable.find((item) => item.objeto.idProducto === NEW_DATA.idProducto);

			if (FOUND) {
				customSB(
					'warning',
					'snackbar.warning.update.amount.product',
					'El producto ya se encuentra en la tabla, se actualiza la cantidad solicitada.'
				);

				const {
					idServicioProducto,
					idProducto,
					idProveedor,
					costo,
					idTipoEntrega,
					idUnidadmedida,
					gestionado,
					cantidadSolicitada,
					cantidadAprobada,
					observacion,
					fechaRegistro,
					usuarioCreacion,
					nombreProducto,
					idCategoriaProducto,
					sku,
					nombreCategoriaProducto
				} = FOUND.objeto;

				// Get the old amount
				const OLD_AMOUNT = parseInt(cantidadSolicitada);
				//  Generate the new data to update
				const JSON_UPDATE = {
					idServicioProducto,
					idProducto,
					idServicio: parseInt(id),
					idProveedor,
					costo,
					idTipoEntrega,
					idUnidadMedida: idUnidadmedida,
					gestionado,
					cantidadSolicitada: CURRENT_AMOUNT + OLD_AMOUNT,
					cantidadAprobada,
					observacion,
					fechaRegistro,
					fechaModificacion: null,
					usuarioCreacion,
					usuarioModificacion: COOKIE_USER,
					nombreProducto,
					idCategoriaProducto,
					sku,
					nombreCategoriaProducto,
					iva: 19,
					idMoneda: {
						idMoneda: 1
					}
				};

				RequestUseCase.put(endpoints.serviceProduct.updateServiceProduct, JSON_UPDATE).then(
					(data) => {
						const { error } = data;
						if (error === '200') {
							updatedItem();
							getDataTable();
						}
						//TODO:
						amountRef.current.value = '';
						setFormItem((prev) => ({
							...prev,
							cantidad: ''
						}));
					}
				);
			} else {
				postItem(NEW_DATA);
			}
		} else {
			noFilledContent();
		}
		setLoading(false);
	};

	const postItem = (data) => {
		amountRef.current.value = '';
		setFormItem((prev) => ({
			...prev,
			cantidad: ''
		}));
		setSelectIds((prev) => ({
			...prev,
			idProducto: {
				value: 0,
				label: ''
			}
		}));

		RequestUseCase.post(endpoints.serviceProduct.addServiceProduct, data)
			.then(() => {
				newItemCreated();
				getDataTable();
				setLoading(false);
			})
			.catch((err) => {
				if (err.response.status === 400) {
					customSB(
						'warning',
						'snackbar.warning.restriction.status.service.product',
						'Solo se pueden agregar productos a servicios en estado \'En curso\''
					);
				} else {
					errorProcess();
				}
				setLoading(false);
			});
	};

	// Update a number to set into the form
	const handleNumber = (e) => {
		HandlerNumbers(e, formItem, setFormItem);
	};

	return (
		<>
			<div className="wrapForm-create-w100">
				<section className="container-select-cpa">
					<SelectorGrid
						name="idProducto"
						ref={selectorRef}
						data={selectedSearch.product}
						placeholder={
							<FormattedMessage
								id="input.placeholder.select"
								defaultMessage="Selecciona una opción"
							/>
						}
						dataValue={selectIds.idProducto}
						setterFunction={setSelectIds}
					/>
				</section>
				<input
					type="text"
					name="cantidad"
					ref={amountRef}
					value={formItem.cantidad}
					onChange={(e) => handleNumber(e)}
					style={{ width: '18rem' }}
					className="input-default"
					placeholder={formatterText('title.service.create.product.requestedQuantity')}
					maxLength="20"
				/>
			</div>

			<div className="demo-form__footer">
				<section className="form-responsive-container-buttons">
					<button className="btn-primary" onClick={(e) => handleSubmit(e)} disabled={loading}>
						{loading ? 'Cargando...' : formatterText('btn.save')}
					</button>
				</section>
			</div>
		</>
	);
}
