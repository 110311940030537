import useAxios from 'hooks/useAxios';
import { useSeachContext } from 'context/SearchContext';
import dateFormat from 'dateformat';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import { useEffect } from 'react';

export default function DepartmentsViewModel() {
	const { endpoints, RequestUseCase } = useAxios();
	const { setDataTable, searchResults = [], setSearchResults } = useSeachContext();

	const {
		loading,
		error,
		toggleLoading,
		toggleError,
		handleClick,
		displayMessage,
		displayLoading
	} = useGetData();

	const getData = () => {
		RequestUseCase.get(endpoints.zones.getAllDepartments)
			.then((data) => {
				const newArray = [];
				data.forEach((item) =>
					newArray.push({
						codigoDepartamento: item.codigoDepartamento,
						nombre: item.nombre,
						codigoPais: item.idPais.codigoPais,
						estado: item.estado,
						fechaCreacion: dateFormat(item.fechaCreacion, 'yyyy/mm/dd - h:MM:ss TT'),
						fechaModificacion:
							item.fechaModificacion != null
								? dateFormat(item.fechaModificacion, 'yyyy/mm/dd - h:MM:ss TT')
								: dateFormat(item.fechaCreacion, 'yyyy/mm/dd - h:MM:ss TT'),
						id: item.idDepartamento,
						objeto: item.idPais,
						usuarioCreacion: item.usuarioCreacion,
						fc: item.fechaCreacion,
						uc: item.usuarioCreacion
					})
				);

				setDataTable(newArray);
				setSearchResults(newArray);
			})
			.catch((err) => {
				toggleError(!error);
				console.log(err);
				handleClick();
			});
	};

	// use Hook of language v2
	const { formatterText } = useLangv2();

	const titles = [
		formatterText('table.title.departmentCode', 'Código departamento'),
		formatterText('table.title.departmentName', 'Nombre departamento'),
		formatterText('table.title.countryCode', 'Código país'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.title.creationD', 'Fecha creación'),
		formatterText('table.title.modificationDate', 'Fecha modificación')
	];

	useEffect(() => {
		(async () => {
			toggleLoading(true);
			await getData();
			toggleLoading(false);
		})();
	}, []);
	return { loading, error, displayMessage, displayLoading, searchResults, getData, titles };
}
