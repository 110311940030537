import React, { useContext } from 'react';
// Import Contexts
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import Hooks
import useAxios from 'hooks/useAxios';
import { useParams } from 'react-router-dom';
// Import Components
import { MdDelete, MdEdit } from 'react-icons/md';
// Import Services
import { useSeachContext } from 'context/SearchContext';
import useLangv2 from 'hooks/useLangv2';
import endpoints from 'services/api';

const TableBranchOffice = ({
	data,
	setDialog,
	loading,
	closeDialog,
	handleOpen,
	isEdit = false,
	getSucursales,
	getSucursalesMap
}) => {
	const { searchResults = [], setDataTable } = useSeachContext();
	const { COOKIE_USER, RequestUseCase } = useAxios();
	const { setModalData, setAuxId, setCurrentDataTable } = useContext(TableMinimalContext);
	const { handleRequestError, successRemoveItem, formatterText } = useLangv2();
	// get id from url
	const { id } = useParams();

	// DELETE a TableBranchOffice
	const handleDelete = (column, index) => {
		if (column.idSucursal !== undefined && isEdit) {
			RequestUseCase.delete(endpoints.branchOffices.deleteBranchOffice(column.idSucursal))
				.then(() => {
					successRemoveItem();
					getSucursales(id).then((resp) => {
						getSucursalesMap(resp);
					});
					setDataTable(
						data.filter((_, i) => {
							return i !== index;
						})
					);
				})
				.catch((err) => {
					handleRequestError(err);
				});
		} else {
			// filter in the array data the index selected and slice it
			setCurrentDataTable(
				data.filter((_, i) => {
					return i !== index;
				})
			);
		}
		closeDialog();
	};

	const handleEdit = (row, index) => {
		handleOpen();
		const CONDITION = typeof row.idSucursal === 'number' ? row.idSucursal : null;
		setAuxId(CONDITION);
		setModalData({ ...row, index: index });
	};

	const handleToggle = (rowState, item) => {
		const estado = rowState === 1 ? 0 : 1;
		closeDialog();
		if (typeof item.idSucursal === 'number') {
			console.log(item);
			const BODY = {
				idSucursal: item.idSucursal,
				idCliente: {
					idCliente: parseInt(id)
				},
				idDireccion: {
					idDireccion: item.objeto.idDireccion.idDireccion,
					idSector: {
						idSector: item.objeto.idDireccion.idSector.idSector
					},
					direccion: item.objeto.idDireccion.direccion,
					datosAdicionales: item.objeto.idDireccion.datosAdicionales,
					observacion: item.objeto.idDireccion.observacion,
					estado: item.objeto.idDireccion.estado,
					fechaCreacion: item.objeto.idDireccion.fechaCreacion,
					fechaModificacion: null,
					usuarioCreacion: item.objeto.idDireccion.usuarioCreacion,
					usuarioModificacion: null
				},
				telefono: item.objeto.telefono,
				sitioWeb: item.objeto.sitioWeb,
				estado,
				fechaCreacion: item.objeto.fechaCreacion,
				fechaModificacion: null,
				usuarioCreacion: item.objeto.usuarioCreacion,
				usuarioModificacion: COOKIE_USER
			};

			RequestUseCase.put(endpoints.branchOffices.updateBranchOffice, BODY).then((data) => {
				const { error } = data;
				if (error === '200') {
					setDataTable(
						searchResults.map((et) => {
							if (et.idSucursal === item.idSucursal) {
								return { ...et, estado };
							}
							return et;
						})
					);
				}
			});
		} else {
			// alert("No se puede cambiar el estado de un registro nuevo");
		}
	};

	return data.map((item, index) => (
		<tr key={index}>
			<td>{item.direccion}</td>
			<td>{item.datosAdicionales}</td>
			<td>{item.observaciones}</td>
			<td className="textCenter">
				{item.estado === 1 ? (
					<div
						className="btn-state pointer"
						onClick={() =>
							setDialog({
								text: `${formatterText('dialog.title.delete.mid.first')} ${
									item.nombre
								} ${formatterText('dialog.title.delete.mid.end.inactive')}`,
								active: true,
								funcion: () => handleToggle(item.estado, item)
							})
						}
					>
						{formatterText('btn.active')}
					</div>
				) : (
					<div
						className="btn-inactive_state pointer"
						onClick={() => {
							setDialog({
								text: `${formatterText('dialog.title.delete.mid.first')} ${
									item.nombre
								} ${formatterText('dialog.title.delete.mid.end.active')}`,
								active: true,
								action: 'active',
								funcion: () => handleToggle(item.estado, item)
							});
						}}
					>
						{formatterText('btn.inactive')}
					</div>
				)}
			</td>
			<td className="minimal-table__actions">
				<MdEdit
					size={25}
					color="gray"
					cursor="pointer"
					disabled={loading}
					onClick={() => handleEdit(item, index)}
				/>
				<MdDelete
					size={25}
					color="gray"
					cursor="pointer"
					onClick={() =>
						setDialog({
							text: `${formatterText('title.service.confirm.delete.row.category')}`,
							active: true,
							action: 'delete',
							funcion: () => handleDelete(item, index)
						})
					}
					disabled={loading}
				/>
			</td>
		</tr>
	));
};

export default TableBranchOffice;
