import React from 'react';
// Mui table styles
import MuiAlert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { formatterText } from 'hooks/useLangv2';
// Pagination actions
import TablePagination from '@mui/material/TablePagination';
import UserPaginateActions from 'components/tableOptions/UserPaginateActions';
// Dynamic cell
import { ALERT } from 'constants/alerts';
import DynamicRowServices from '../DynamicRowServices';
import { DynamicHeadServicesProps } from './DynamicHeadServices.props';
import DynamicHeadServicesViewModel from './DynamicHeadServices.viewModel';
// Search context

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DynamicHeadServices = ({
	titles,
	pageName,
	getData,
	handleDownloadItem,
	handleDeleteItem,
	handleEditStateItem,
	noHandleEditStateItem,
	routeToEdit,
	canDeleted,
	canModify,
	canDownload,
	canViewDetails,
	canChangeData,
	canSelectEU,
	canSelectSC,
	onClose,
	statusActivity,
	sendMail,
	setSendMail,
	setCounterActivities,
	form,
	getDataToUpdate,
	setIsDeleteTechMain,
	isDeleteTechMain,
	getAllTechnicalsServices
}) => {
	const {
		searchResults,
		rowsPerPage,
		page,
		handleChangePage,
		handleChangeRowsPerPage,
		handleClose,
		open
	} = DynamicHeadServicesViewModel();

	return (
		<>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 250 }} aria-label="Tabla de informacion">
					<TableHead>
						<TableRow>
							{titles.map((title, index) => (
								<TableCell
									key={index}
									sx={{
										fontWeight: 'bold'
									}}
									align="left"
								>
									{formatterText(title)}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0
							? searchResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							: searchResults
						).map((item, index) => (
							<DynamicRowServices
								key={`${index + 1}`}
								titles={titles}
								columns={item}
								handleDownloadItem={handleDownloadItem}
								handleDeleteItem={handleDeleteItem}
								handleEditStateItem={handleEditStateItem}
								noHandleEditStateItem={noHandleEditStateItem}
								routeToEdit={routeToEdit}
								canDeleted={canDeleted}
								canModify={canModify}
								canDownload={canDownload}
								getData={getData}
								canViewDetails={canViewDetails}
								canChangeData={canChangeData}
								canSelectEU={canSelectEU}
								canSelectSC={canSelectSC}
								onClose={onClose}
								statusActivity={statusActivity}
								sendMail={sendMail}
								setSendMail={setSendMail}
								setCounterActivities={setCounterActivities}
								form={form}
								getDataToUpdate={getDataToUpdate}
								setIsDeleteTechMain={setIsDeleteTechMain}
								isDeleteTechMain={isDeleteTechMain}
								getAllTechnicalsServices={getAllTechnicalsServices}
							/>
						))}
					</TableBody>

					<TableFooter
						sx={{
							display: 'flex',
							justifyItems: 'center',
							justifyContent: 'right',
							mt: 2,
							mb: 2
						}}
					>
						<TablePagination
							sx={{
								boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
								marginTop: '25px',
								borderRadius: '10px'
							}}
							rowsPerPageOptions={[
								25,
								50,
								100,
								200,
								{ label: formatterText('input.placeholder.all.bill'), value: searchResults.length }
							]}
							colSpan={3}
							count={searchResults.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={UserPaginateActions}
							labelRowsPerPage={formatterText(pageName) + formatterText('title.per.page')}
							align="center"
						/>
					</TableFooter>
				</Table>
			</TableContainer>
			<Stack spacing={2} sx={{ width: '100%' }}>
				<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
					<Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
						{formatterText(ALERT.update)}
					</Alert>
				</Snackbar>
			</Stack>
		</>
	);
};

DynamicHeadServices.propTypes = DynamicHeadServicesProps;

export default DynamicHeadServices;
