import React from 'react';

// Import services
import SelectorMulti from 'common/selects/SelectorMultiCreate';

import { UpdateModalItemProps } from './UpdateModalItem.props';
import UpdateModalItemViewModel from './UpdateModalItem.viewModel';

export default function UpdateModalItem({
	onClose,
	productCategories,
	loadingCategories,
	getDataFromMinimalTable,
	id
}) {
	const {
		currentItemMinimal,
		loading,
		products,
		setProducts,
		handleChangeInput,
		handleChangeInputCode,
		handlerTextDescription,
		handleUpdateMinimalModal
	} = UpdateModalItemViewModel({
		onClose,
		productCategories,
		loadingCategories,
		getDataFromMinimalTable,
		id
	});
	return (
		<form className="form-responsive" onSubmit={handleUpdateMinimalModal}>
			<section className="form-responsive-container-information">
				<label className="form-responsive-information__option">
					<h3>Nombre tipo de servicio</h3>
					<input
						className="input-primary width-50"
						type="text"
						name="nombre"
						value={currentItemMinimal.nombre}
						onChange={handleChangeInput}
						placeholder="Nombre tipo de servicio"
						maxLength="100"
						required
					/>
				</label>
				<label className="form-responsive-information__option">
					<h3>Código</h3>
					<input
						className="input-primary width-50"
						type="text"
						name="codigo"
						value={currentItemMinimal.codigo}
						onChange={handleChangeInputCode}
						placeholder="Ingresa hasta 45 caracteres"
						maxLength="45"
						required
					/>
				</label>
				<label className="form-responsive-information__option">
					<h3>Descripción</h3>
					<textarea
						className="input-primary-textarea width-50"
						type="text"
						name="descripcion"
						value={currentItemMinimal.descripcion}
						onChange={handlerTextDescription}
						placeholder="Descripción del tipo de servicio"
						maxLength="200"
					/>
				</label>
				<label className="form-responsive-information__option">
					<h3>Categoria de productos</h3>
					<SelectorMulti
						name="categoryType"
						id="categoryType"
						data={productCategories}
						isLoading={loading}
						dataValue={products}
						setterFunction={setProducts}
					/>
				</label>
				<input type="submit" className="width-100-fix" value="Actualizar tipo de servicio" />
				<input
					type="button"
					className="width-100-fix input-cancel"
					value="Cancelar"
					onClick={onClose}
				/>
			</section>
		</form>
	);
}

UpdateModalItem.propTypes = UpdateModalItemProps;
