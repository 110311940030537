import Axios from 'axios';
import Cookie from 'js-cookie';
import endpoints from './index';

const markAllAsRead = async () => {
	const url = endpoints.notificationsTable.markAllAsRead(Cookie.get('idUsuario'));
	const response = await Axios.get(url);
	return response.data;
};

const getAllNotifications = async () => {
	const url = endpoints.notificationsTable.getAllNotificationsUserIcon({
		idUser: Cookie.get('idUsuario'),
		currentPage: 0,
		registersPerPage: 25
	});
	const response = await Axios.get(url);
	return response.data;
};

const getCountNotificationsIcon = async () => {
	const url = endpoints.notificationsTable.getCountNotificationsIcon({
		idUser: Cookie.get('idUsuario')
	});
	const response = await Axios.get(url);
	return response.data;
};

const getTiempoNotificacionActivities = async () => {
	const url = endpoints.notificationsTable.getTiempoNotificacion();
	const response = await Axios.get(url);
	return response.data;
};

/**
 * ********************************
 * ENDPOINTS PARA LA TABLA ********
 * ********************************
 */

/**
 *
 * @param {*} params --idUser, currentPage, registersPerPage
 */
const notificationsByUser = async (params) => {
	const url = endpoints.notificationsTable.getAllNotificationsUser(params);
	const response = await Axios.get(url);
	return response.data;
};

const countNotificationsByUser = async () => {
	const url = endpoints.notificationsTable.getCountNotificationsPanel({
		idUser: Cookie.get('idUsuario')
	});
	const response = await Axios.get(url);
	return response.data;
};

export {
	markAllAsRead,
	getAllNotifications,
	notificationsByUser,
	countNotificationsByUser,
	getCountNotificationsIcon,
	getTiempoNotificacionActivities
};
