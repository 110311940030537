import { useIntl } from 'react-intl';
// Import Libs
import paths from 'services/paths';

/**
 * @description Determinate the title of the header
 * @param PATH_TO_FIND The location.pathname to find in the PATH object
 * @returns string of the language
 */
function HeaderList(PATH_TO_FIND) {
	const PATH_VALUE = PATH[PATH_TO_FIND];
	const idLang = PATH_VALUE[0];
	const defaultMessage = PATH_VALUE[1];
	// get the intl object
	const intl = useIntl();
	// return plaint text using idLang and a default message
	const PLAIN_TEXT = intl.formatMessage({ id: idLang }, { defaultMessage });

	return PLAIN_TEXT || 'Not Found';
}

// Every path in the app
const PATH = {
	[`${paths.home}`]: ['header.title.home.principal', 'Pantalla principal'],
	[`${paths.login}`]: ['header.title.roles.permission.update', 'Editar rol'],
	[`${paths.updateProfile}`]: ['header.title.edit.profile', 'Editar Perfil'],
	[`${paths.users}`]: ['header.title.user.principal', 'Usuarios'],
	[`${paths.createUser}`]: ['header.title.user.create', 'Crear usuario'],
	[`${paths.updateUser}`]: ['header.title.user.update', 'Editar usuario'],
	[`${paths.roles}`]: ['header.title.roles.permission.principal', 'Gestión de permisos y roles'],
	[`${paths.createRol}`]: ['header.title.roles.permission.create', 'Crear rol'],
	[`${paths.updateRol}`]: ['header.title.roles.permission.update', 'Editar rol'],
	[`${paths.createMail}`]: ['header.title.mail.principal', 'Gestión de correos'],
	[`${paths.zones}`]: ['header.title.zones.principal', 'Gestión de catálogo de zonas'],
	[`${paths.createSector}`]: ['header.title.zones.sector.create', 'Crear sector'],
	[`${paths.updateSector}`]: ['header.title.zones.sector.update', 'Editar sector'],
	[`${paths.audits}`]: ['header.title.audits.principal', 'Auditorías de registros'],
	[`${paths.changePassword}`]: ['header.title.change.password.principal', 'Cambiar contraseña'],
	[`${paths.files}`]: ['header.title.attachment.file.principal', 'Tipos archivos adjuntos'],
	[`${paths.createFile}`]: [
		'header.title.attachment.file.principal.create',
		'Crear tipo archivo adjunto'
	],
	[`${paths.updateFile}`]: [
		'header.title.attachment.file.principal.update',
		'Editar tipo archivo adjunto'
	],
	[`${paths.parameters}`]: ['header.title.client.parameter.principal', 'Parámetros de cliente'],
	[`${paths.createMarket}`]: ['header.title.client.parameter.market.create', 'Crear mercado'],
	[`${paths.updateMarket}`]: ['header.title.client.parameter.market.update', 'Editar mercados'],
	[`${paths.createBusinessUnit}`]: [
		'header.title.client.parameter.unit.business.create',
		'Crear Unidades de Negocio'
	],
	[`${paths.updateBusinessUnit}`]: [
		'header.title.client.parameter.unit.business.update',
		'Editar unidades de negocio'
	],
	[`${paths.createProviderType}`]: [
		'header.title.client.parameter.provider.type.create',
		'Crear tipo proveedor'
	],
	[`${paths.updateProviderType}`]: [
		'header.title.client.parameter.provider.type.update',
		'Editar tipo proveedor'
	],
	[`${paths.createClientType}`]: [
		'header.title.client.parameter.client.type.create',
		'Crear tipo de cliente'
	],
	[`${paths.updateClientType}`]: [
		'header.title.client.parameter.client.type.update',
		'Editar tipo de cliente'
	],
	[`${paths.createBillingType}`]: [
		'header.title.client.parameter.client.billing.type.create',
		'Crear tipo de facturación'
	],
	[`${paths.updateBillingType}`]: [
		'header.title.client.parameter.client.billing.type.update',
		'Editar tipo de facturación'
	],
	[`${paths.createParameter}`]: [
		'header.title.client.parameter.client.third.party.types.create',
		'Crear tipos de terceros'
	],
	[`${paths.updateParameter}`]: [
		'header.title.client.parameter.client.third.party.types.update',
		'Editar tipos de terceros'
	],
	[`${paths.positions}`]: ['header.title.position.principal', 'Cargos'],
	[`${paths.createPositions}`]: ['header.title.position.create', 'Crear cargo'],
	[`${paths.updatePositions}`]: ['header.title.position.update', 'Editar cargo'],
	[`${paths.contracts}`]: ['header.title.contract.type.principal', 'Tipos de contratos'],
	[`${paths.createContract}`]: ['header.title.contract.type.create', 'Crear tipo de contrato'],
	[`${paths.updateContract}`]: ['header.title.contract.type.update', 'Editar tipo de contrato'],
	[`${paths.employees}`]: ['header.title.employee.principal', 'Empleados'],
	[`${paths.createEmployee}`]: ['header.title.employee.create', 'Crear empleado'],
	[`${paths.updateEmployee}`]: ['header.title.employee.update', 'Editar empleado'],
	[`${paths.thirdParties}`]: ['header.title.third.party.principal', 'Terceros'],
	[`${paths.createThirdParty}`]: ['header.title.third.party.create', 'Crear terceros'],
	[`${paths.updateThirdParty}`]: ['header.title.third.party.update', 'Editar terceros'],
	[`${paths.technical}`]: ['header.title.technical.principal', 'Técnicos'],
	[`${paths.createTechnical}`]: ['header.title.technical.create', 'Crear de técnicos'],
	[`${paths.updateTechnical}`]: ['header.title.technical.update', 'Editar técnicos'],
	[`${paths.clients}`]: ['header.title.client.principal', 'Clientes'],
	[`${paths.createClient}`]: ['header.title.client.create', 'Crear cliente'],
	[`${paths.updateClient}`]: ['header.title.client.update', 'Editar cliente'],

	[`${paths.providers}`]: ['header.title.provider.principal', 'Proveedores'],
	[`${paths.createProvider}`]: ['header.title.provider.create', 'Crear provedores'],
	[`${paths.updateProvider}`]: ['header.title.provider.update', 'Crear provedores'],
	[`${paths.createProvider}`]: ['header.title.provider.create', 'Crear provedores'],
	[`${paths.products}`]: ['header.title.product.principal', 'Productos'],
	[`${paths.createProductCategory}`]: [
		'header.title.product.category.create',
		'Crear categoría de producto'
	],
	[`${paths.updateProductCategory}`]: ['header.title.product.category.update', 'Crear Categoría'],
	[`${paths.createProduct}`]: ['header.title.product.create.product', 'Crear Producto'],
	[`${paths.updateProduct}`]: ['header.title.product.update.product', 'Editar producto'],
	[`${paths.services}`]: ['header.title.service.parameter.principal', 'Parámetros de servicios'],
	[`${paths.createCategoryService}`]: [
		'header.title.service.parameter.service.category.create',
		'Crear categoría de servicio'
	],
	[`${paths.updateCategoryService}`]: [
		'header.title.service.parameter.service.category.update',
		'Editar categoría de servicio'
	],
	[`${paths.createStageService}`]: [
		'header.title.service.parameter.service.stage.create',
		'Crear etapa de servicio'
	],
	[`${paths.updateStageService}`]: [
		'header.title.service.parameter.service.stage.update',
		'Editar etapa de servicio'
	],
	[`${paths.createActivityService}`]: [
		'header.title.service.parameter.activity.create',
		'Gestionar actividades'
	],
	[`${paths.createListPriceService}`]: [
		'header.title.service.parameter.price.list.create',
		'Crear lista de precios'
	],
	[`${paths.updateListPriceService}`]: [
		'header.title.service.parameter.price.list.update',
		'Editar lista de precios'
	],
	[`${paths.createFormService}`]: [
		'header.title.service.parameter.form.create',
		'Crear formulario'
	],
	[`${paths.updateFormService}`]: [
		'header.title.service.parameter.form.update',
		'Editar formulario'
	],
	[`${paths.schedule}`]: ['header.title.schedule.principal', 'Agendas'],
	[`${paths.createSchedule}`]: ['header.title.schedule.create', 'Crear agenda'],
	[`${paths.updateSchedule}`]: ['header.title.schedule.update', 'Editar agenda'],
	[`${paths.duplicateSchedule}`]: ['header.title.schedule.clone', 'Clonar agenda'],
	[`${paths.serviceModule}`]: ['header.title.service.principal', 'Servicios'],
	[`${paths.searchTechnicians}`]: ['header.title.service.principal', 'Servicios'],
	[`${paths.auxTech}`]: ['header.title.service.principal', 'Servicios'],
	[`${paths.searchPrincialTechnical}`]: ['header.title.service.principal', 'Servicios'],
	[`${paths.createExternalUser}`]: ['header.title.service.principal', 'Servicios'],
	[`${paths.createService}`]: ['header.title.service.create', 'Crear servicio'],
	[`${paths.updateService}`]: ['header.title.service.update', 'Editar servicio'],
	[`${paths.duplicateService}`]: ['header.title.service.clone', 'Clonar servicio'],
	[`${paths.ganttServices}`]: ['nav.parent.3.child.option.8', 'Gestión de homeview'],
	[`${paths.panelNotifications}`]: ['header.title.panel.notifications', 'Panel de notificaciones'],
	[`${paths.reviewMap}`]: ['Revisión de mapa', 'Revisión de mapa'],
	[`${paths.invoice}`]: ['header.title.panel.invoices', 'conciliaciones']
};

export default HeaderList;
