import { Formiz, FormizStep } from '@formiz/core';
import { isEmail, isMaxLength, isNotEmptyString, isPattern } from '@formiz/validations';
import { InputFieldResponsive } from 'common/inputs/InputFieldResponsive';
import InputSelectorResponsive from 'common/inputs/InputSelectorResponsive';
import { ADDRESSREGEX, NUMBERREGEX, URLREGEX } from 'common/validators/Regex';
import { TIPOS_ARCHIVOS_PERMITIDOS_USUARIO } from 'constants/archivosAdjuntosUsuario';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import paths from 'services/paths';
import Attachments from '../../../components/AttachedFiles/Attachments';

import CreateProviderViewModel from './CreateProvider.viewModel';

const CreateProvider = () => {
	const {
		formatterText,
		myForm,
		handleSubmit,
		setCurrentFiles,
		archivos,
		isLoading,
		loadingData,
		selectData,
		navigate
	} = CreateProviderViewModel();
	return (
		<div className="centered-form">
			<Formiz onValidSubmit={handleSubmit} connect={myForm}>
				<form
					noValidate
					onSubmit={myForm.submit}
					className="container-wrapForm"
					style={{ minHeight: '16rem' }}
				>
					<div className="new-container-wrapForm__tabs">
						{myForm.steps.map((step) => (
							<button
								key={step.name}
								className={`new-tab-option ${
									step.name === myForm.currentStep.name ? 'is-active' : ''
								}`}
								type="button"
								onClick={() => myForm.goToStep(step.name)}
							>
								{formatterText(step.label)}
								{!step.isValid && step.isSubmitted && <small className="mr-2">⚠️</small>}
							</button>
						))}
					</div>

					<div className="container-wrapForm-content">
						<FormizStep name="step1" label="tab.title.provider.general.info">
							<div className="title-section">
								<span className="circle-form">
									<span>1</span>
								</span>
								<h2>
									<FormattedMessage
										id="p.provider.general.data"
										defaultMessage="Datos generales del proveedor"
									/>
								</h2>
							</div>
							<section className="grid-container-3c">
								<InputSelectorResponsive
									type="text"
									name="idTipoDocumento"
									placeholder={formatterText('p.document.type', 'Tipo de documento')}
									labelText={formatterText('p.document.type', 'Tipo de documento')}
									required={formatterText(
										'p.document.type.is.required',
										'El tipo de documento es requerido'
									)}
									data={
										!!selectData.documentTypes &&
										selectData.documentTypes.map((item) => {
											const docLabel = `${item.nombre} - ${item.abreviatura}`;
											return {
												label: docLabel,
												value: item.idTipoDocumento
											};
										})
									}
									isLoading={loadingData}
								/>

								<InputFieldResponsive
									type="text"
									name="identificacion"
									labelText={`${formatterText('text.ID', 'Identificación')}*`}
									placeholder={formatterText('text.ID', 'Identificación')}
									validateInput="integer"
									required={formatterText('p.id.is.required', 'La identificación es requerida')}
									validations={[
										{
											rule: isMaxLength(15),
											message: formatterText(
												'p.id.max.chars',
												'La identificacion debe tener como máximo 15 caracteres'
											)
										},
										{
											rule: isPattern(NUMBERREGEX),
											message: formatterText('p.only.numbers.allowed', 'Solo se admiten números')
										}
									]}
								/>

								<InputFieldResponsive
									type="text"
									name="nombreRazonSocial"
									labelText={`${formatterText('p.name.or.comp.name', 'Nombres o razón social')}*`}
									placeholder={formatterText('p.name.or.comp.name', 'Nombres o razón social')}
									validateInput="text"
									required={formatterText(
										'p.name.or.compName.is.required',
										'Nombre o razón social es requerido'
									)}
								/>

								<InputFieldResponsive
									type="text"
									name="sitioWeb"
									labelText={`${formatterText('table.title.website', 'Sitio web')}*`}
									placeholder={formatterText('table.title.website', 'Sitio web')}
									required={formatterText('p.website.is.required', 'El sitio web es requerido')}
									validations={[
										{
											rule: isPattern(URLREGEX),
											message: 'La url no es válida'
										}
									]}
								/>

								<InputFieldResponsive
									type="text"
									name="telefono"
									labelText={`${formatterText('table.title.phone', 'Teléfono')}*`}
									placeholder={formatterText('table.title.phone', 'Teléfono')}
									validateInput="integer"
									required={formatterText('p.phone.is.required', 'El teléfono es requerido')}
									validations={[
										{
											rule: isMaxLength(12),
											message: formatterText(
												'p.phone.max.chars',
												'El teléfono debe tener máximo 12 caracteres'
											)
										},
										{
											rule: isPattern(NUMBERREGEX),
											message: formatterText('p.only.numbers.allowed', 'Solo se admiten números')
										}
									]}
								/>

								<InputFieldResponsive
									type="text"
									name="telefonoAlterno"
									labelText={formatterText('p.alt.phone', 'Teléfono alterno')}
									placeholder={formatterText(
										'input.placeholder.alt.phone',
										'Ingrese el teléfono alterno'
									)}
									validateInput="integer"
									validations={[
										{
											rule: isMaxLength(12),
											message: formatterText(
												'p.alt.phone.max.chars',
												'El teléfono alterno debe tener máximo 12 caracteres'
											)
										},
										{
											rule: isPattern(NUMBERREGEX),
											message: formatterText('p.only.numbers.allowed', 'Solo se admiten números')
										}
									]}
								/>

								<InputFieldResponsive
									type="email"
									name="correo"
									labelText={`${formatterText('table.title.email', 'Correo electrónico')}`}
									placeholder={formatterText(
										'input.placeholder.email',
										'Ingrese el correo electrónico'
									)}
									required={formatterText('p.email.is.required', 'El correo es requerido')}
									validateInput="email"
									validations={[
										{
											rule: isEmail(),
											message: formatterText('p.email.invalid', 'El correo no es válido')
										}
									]}
								/>

								<InputSelectorResponsive
									type="text"
									name="idTipoProveedor"
									labelText={`${formatterText('p.provider.type', 'Tipo de proveedor')}*`}
									placeholder={formatterText(
										'input.placeholder.selec.provider.type',
										'Selecciona el tipo de proveedor'
									)}
									required={formatterText(
										'p.provider.type.is.required',
										'El tipo de proveedor es requerido'
									)}
									data={
										!!selectData.genres &&
										selectData.genres.map((item) => ({
											label: `${item.nombre}`,
											value: item.idTipoProveedor
										}))
									}
									isLoading={loadingData}
								/>
							</section>

							<div className="title-section">
								<span className="circle-form">
									<span>1.1</span>
								</span>
								<h2>
									<FormattedMessage
										id="p.provider.location.data"
										defaultMessage="Datos de ubicación del proveedor"
									/>
								</h2>
							</div>

							<section
								className="grid-container-2c"
								style={{
									width: '100%'
								}}
							>
								<InputSelectorResponsive
									type="text"
									name="idMunicipio"
									labelText={`${formatterText('p.municipality.or.city', 'Ciudad o municipio')}*`}
									placeholder={formatterText('p.municipality.or.city', 'Ciudad o municipio')}
									required={formatterText(
										'p.city.or.municipality.is.required',
										'La ciudad o municipio de residencia es requerida'
									)}
									data={
										!!selectData.municipalities &&
										selectData.municipalities.map((item) => ({
											label: `${item.nombre} - ${item.idDepartamento.nombre} - ${item.idDepartamento.idPais.nombrePais} `,
											value: item.idMunicipio
										}))
									}
									isLoading={loadingData}
								/>

								<InputFieldResponsive
									type="text"
									name="direccion"
									labelText={`${formatterText('table.title.address', 'Dirección')}*`}
									placeholder={formatterText(
										'input.placeholder.complete.adress',
										'Dirección completa'
									)}
									validateInput="address"
									required={formatterText('p.adress.is.required', 'Dirección es requerida')}
									styleName="input-primary"
									validations={[
										{
											rule: isNotEmptyString(),
											message: formatterText(
												'p.adress.not.empty',
												'La dirección no puede estar vacía'
											)
										},
										{
											rule: isPattern(ADDRESSREGEX),
											message: formatterText(
												'p.adress.not.especial.chars',
												'La dirección no permite caracteres especiales'
											)
										}
									]}
								/>
							</section>

							<h5 style={{ marginLeft: '3rem' }}>
								<FormattedMessage
									id="p.required.fields"
									defaultMessage="Todos los campos con * son obligatorios"
								/>
							</h5>
						</FormizStep>
						<FormizStep name="step2" label="tab.title.attached.data">
							<Attachments
								currentFiles={archivos}
								setCurrentFiles={setCurrentFiles}
								isEdited={false}
								type={TIPOS_ARCHIVOS_PERMITIDOS_USUARIO.PROVEEDOR}
								showParameters={true}
							/>
						</FormizStep>
						<input type="submit" id="submit-form" style={{ visibility: 'hidden' }} />
					</div>
				</form>
			</Formiz>

			<div className="demo-form__footer">
				<section className="form-responsive-container-buttons">
					<button style={{ padding: '0px' }} className="btn-primary">
						<label className="btn-wrap-add" htmlFor="submit-form" tabIndex="0">
							{isLoading
								? 'Loading...'
								: formatterText('header.title.provider.create', 'Crear proveedor')}
						</label>
					</button>

					<button className="input-cancel" onClick={() => navigate(paths.providers)}>
						<FormattedMessage id="btn.cancel" defaultMessage="Cancelar" />
					</button>
				</section>
			</div>
		</div>
	);
};

export default CreateProvider;
