import MultiTableMinimal from 'common/minimalTables/MultiTableMinimal';
import Selector from 'common/selects/Selector';
import React, { useEffect, useState } from 'react';
// // Import services
import { useSeachContext } from 'context/SearchContext';
import useLangv2 from 'hooks/useLangv2';
import { FormattedMessage } from 'react-intl';
import useAxios from 'hooks/useAxios';
const customStyles = {
	control: (base) => ({
		...base,
		width: '19em',
		margin: '15px 0 0 0',
		'border-radius': '70px',
		'box-shadow': '0px 4px 8px rgba(0, 174, 142, 0.2)',
		border: '0px !important',
		padding: '0 6px',
		'max-width': '20rem',
		'min-width': '225px'
	}),
	menu: (styles) => ({
		...styles,
		width: '19em'
	})
};
const ClienteSucursal = ({ data, setterFunction }) => {
	const { RequestUseCase, endpoints } = useAxios();
	const [auxData, setAuxData] = useState();
	const [selectedSearch, setSearchSelected] = useState();
	const { handleSearchClientSucursal } = useSeachContext();
	useEffect(() => {
		getDataTable();
	}, []);
	const { formatterText, customSB } = useLangv2();
	const titlesTableUnityBusiness = [
		formatterText('text.client.name'),
		formatterText('table.title.advisor.document'),
		formatterText('table.title.advisor.id'),
		formatterText('title.table.customer.and.branches-city.sucursal'),
		formatterText('title.table.customer.and.branches-city.state'),
		formatterText('p.country'),
		formatterText('table.actions')
	];
	const getDataTable = () => {
		RequestUseCase.get(endpoints.branchOffices.getAllBranchOffices)
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					if (item.idCliente.estado === 1) {
						const data = {
							...item,
							value: item.idSucursal,
							label: `${item.idCliente.nombreRazonSocial} - ${item.idCliente.identificacion} - ${item.idDireccion.idSector.nombre} - ${item.idDireccion.direccion} `,
							isFixed: true
						};
						newArray.push(data);
					}
				});
				setSearchSelected(newArray);
				setInitialSearch(newArray);
			})
			.catch(() => {});
	};
	const handleDelete = (row) => {
		const newMinimalRows = data.filter((et) => {
			return et.id !== row.id;
		});
		closeDialog();
		setterFunction(newMinimalRows);
	};

	const [dialog, setDialog] = useState({
		text: '',
		active: false,
		function: null
	});

	const closeDialog = () => {
		setDialog({ ...dialog, active: false });
	};
	const resetSelector = () => {
		const print = getSelects();
		setSearchSelected(print);
	};

	const clickTable = () => {
		if (auxData !== undefined) {
			const comprobarExistencia = data.find((item) => item.id === auxData.idUnityBusiness.value);
			if (comprobarExistencia !== undefined) {
				resetSelector();
				customSB('warning', 'snack.warning.sucursal.already', 'Ya existe la sucursal seleccionada');
			} else {
				const data = auxData.idUnityBusiness;
				const newData = {
					id: data.idSucursal,
					nombreCliente: data.idCliente.nombreRazonSocial,
					tipoId: {
						value: data.idCliente.idTipoDocumento.idTipoDocumento,
						label: data.idCliente.idTipoDocumento.abreviatura
					},
					numberId: data.idCliente.identificacion,
					citySucursal: {
						value: data.idDireccion.idSector.idMunicipio.idMunicipio,
						label: data.idDireccion.idSector.idMunicipio.nombre
					},
					departamento: {
						value: data.idDireccion.idSector.idMunicipio.idDepartamento.idDepartamento,
						label: data.idDireccion.idSector.idMunicipio.idDepartamento.nombre
					},
					pais: {
						value: data.idDireccion.idSector.idMunicipio.idDepartamento.idPais.idPais,
						label: data.idDireccion.idSector.idMunicipio.idDepartamento.idPais.nombrePais
					}
				};
				setterFunction((prev) => [...prev, newData]);
			}
		} else {
			customSB('warning', 'snack.warning.branch.blank', 'Por favor, selecciona una sucursal');
		}
	};

	const [initialSearch, setInitialSearch] = useState();
	useEffect(() => {
		if (data.length > 0) {
			const print = getSelects();
			setSearchSelected(print);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const getSelects = () => {
		const newArray = [];
		if (initialSearch) {
			initialSearch.forEach((item) => {
				let flag = true;
				data.forEach((item2) => {
					if (item.idSucursal === item2.id) {
						flag = false;
					}
				});
				if (flag) {
					newArray.push(item);
				}
			});
		}
		return newArray;
	};

	return (
		<>
			<div className="title-section">
				<span className="circle-form">
					<span>4</span>
				</span>
				<h2>
					{' '}
					<FormattedMessage id="p.client.sucur" defaultMessage="Clientes y sucursales asociadas" />
				</h2>
			</div>
			<div className="container-technical-association">
				<span>
					<FormattedMessage id="p.branch.customer" defaultMessage="Sucursales de clientes" />
				</span>
				<Selector
					customStyles={customStyles}
					name="idUnityBusiness"
					data={selectedSearch}
					placeholder={formatterText('p.select.option', 'Selecione una opción')}
					dataValue={auxData}
					setterFunction={setAuxData}
					isRequired={false}
					fullWidth={true}
				/>
				<button
					onClick={(e) => {
						e.preventDefault();
						clickTable();
					}}
					className="btn-secondary"
				>
					<FormattedMessage id="p.add" defaultMessage="Agregar" />
				</button>
			</div>
			{data.length > 0 && (
				<section className="form-responsive-container-information">
					<MultiTableMinimal
						titles={titlesTableUnityBusiness}
						data={data}
						type="clientSucursal"
						handleDelete={handleDelete}
						dialog={dialog}
						setDialog={setDialog}
						closeDialog={closeDialog}
						canSearch={true}
						handleSearch={handleSearchClientSucursal}
					/>
				</section>
			)}
		</>
	);
};

export default ClienteSucursal;
