import React from 'react';
// import Hooks
// Import Components
import SelectorGrid from 'common/selects/SelectorGrid';
// Import Libs
import { FormattedMessage } from 'react-intl';
// Import Services
// Import styles
import styles from './CreateTimeZone.module.css';

import { CreateTimeZoneProps } from './CreateTimeZone.props';
import CreateTimeZoneViewModel from './CreateTimeZone.viewModel';

export default function CreateTimeZone({ getAllTimeZone, hideComponent }) {
	const {
		handleText,
		createTimeZone,
		formData,
		selectedSearch,
		selectIds,
		setSelectIds,
		formatterText
	} = CreateTimeZoneViewModel({ getAllTimeZone, hideComponent });
	return (
		<section>
			<span className="title-table">
				<FormattedMessage
					id="text.shedule.create.time.zone"
					defaultMessage="Crear franja horaria"
				/>
			</span>
			<section className="wrapForm w100-container-complete">
				<label className="wrapForm__label">
					<span className="warpForm-text">
						<FormattedMessage
							id="text.shedule.name.time.zone"
							defaultMessage="Nombre de la franja"
						/>
					</span>
					<input
						type="text"
						className="input-default"
						name="nombre"
						placeholder={formatterText('text.shedule.name.time.zone', 'Nombre de la franja')}
						onChange={handleText}
						value={formData.nombre}
						maxLength="100"
					/>
				</label>

				<label className="wrapForm__label">
					<span className="warpForm-text">
						<FormattedMessage
							id="text.shedule.day.week.time.zone"
							defaultMessage="Día de la semana"
						/>
					</span>
					<SelectorGrid
						name="idDiaSemana"
						data={selectedSearch.dayWeek}
						placeholder={
							<FormattedMessage
								id="input.placeholder.select"
								defaultMessage="Selecciona una opción"
							/>
						}
						dataValue={selectIds.idDiaSemana}
						setterFunction={setSelectIds}
					/>
				</label>
				<label className="wrapForm__label">
					<span className="warpForm-text">
						<FormattedMessage id="text.shedule.start.time.zone" defaultMessage="Franja de inicio" />
					</span>
					<SelectorGrid
						name="idHoraInicio"
						data={selectedSearch.startHour}
						placeholder={
							<FormattedMessage
								id="input.placeholder.select"
								defaultMessage="Selecciona una opción"
							/>
						}
						dataValue={selectIds.idHoraInicio}
						setterFunction={setSelectIds}
					/>
				</label>
				<label className="wrapForm__label">
					<span className="warpForm-text">
						<FormattedMessage id="text.shedule.end.time.zone" defaultMessage="Franja fin" />
					</span>
					<SelectorGrid
						name="idHoraFin"
						data={selectedSearch.endHour}
						placeholder={
							<FormattedMessage
								id="input.placeholder.select"
								defaultMessage="Selecciona una opción"
							/>
						}
						dataValue={selectIds.idHoraFin}
						setterFunction={setSelectIds}
					/>
				</label>
			</section>
			<section className={styles['form-responsive-container-buttons']}>
				<button className="btn-action-primary" onClick={(e) => createTimeZone(e)}>
					<FormattedMessage id="btn.create" defaultMessage="Crear" />
				</button>
			</section>
		</section>
	);
}

CreateTimeZone.propTypes = CreateTimeZoneProps;
