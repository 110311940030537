import React from 'react';
// Import Styles
import 'styles/App.css';
import HomeViewModel from './Home.viewModel';

function Home() {
	HomeViewModel();
	return (
		<div
			style={{
				padding: '1rem'
			}}
		>
			<section style={{ width: '100%', height: '25vw' }}>
				<section
					className="mx-5"
					style={{
						border: '1px solid var(--color-primary)',
						borderRadius: '1rem',
						padding: '1rem'
					}}
				>
					<h3 className="text-center">Bienvenid@ a los servicios de gestión de SOS asistencia</h3>
					<p className="mt-4 pt-4">Hola, bienvenido</p>
				</section>
			</section>
		</div>
	);
}

export default Home;
