import useAxios from 'hooks/useAxios';
import React, { useContext, useEffect, useState } from 'react';
// Import Contexts
import { langContext } from 'context/langContext';
// Import Hooks
import { useLocation } from 'react-router';
// Import Components
import SideNavBtn from './SideNavBtn';
import SideNavButton from './SideNavButton';
// Import Libs
import { FormattedMessage } from 'react-intl';
// Import Services
import Cookie from 'js-cookie';
import paths from 'services/paths';
// Import Styles
import 'styles/sideNavStyle.css';
import useLangv2 from '../hooks/useLangv2';
import Items from './itemsNav';
import { updateLenguage } from 'services/api/auth';
import CustomAlert from 'components/CustomAlert';

const SideNav = () => {
	const { endpoints, RequestUseCase } = useAxios();
	// Estados de los permisosModulos
	const [allNavItems, setAllNavItems] = useState([]);
	const [filterNavItems, setFilterNavItems] = useState([]);
	const [userModules, setUserModules] = useState(true);
	const [roleModules, setRoleModules] = useState(true);
	const [mailModules, setMailModules] = useState(true);
	const [zoneModules, setZoneModules] = useState(true);
	const [auditModules, setAuditModules] = useState(true);
	const [thirdModules, setThirdModules] = useState(true);
	const [clientModules, setClientModules] = useState(true);
	const [positionsModules, setPositionsModules] = useState(true);
	const [data, setData] = useState([]);
	const [searching, setSearching] = useState('');
	const location = useLocation();
	const idioma = useContext(langContext);
	const { formatterText } = useLangv2();

	useEffect(() => {
		if (location.pathname !== paths.login && sessionStorage.getItem('token')) {
			permissions();
			if (data !== '') {
				validationData();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data !== '']);

	useEffect(() => {
		//agrupar Items and separate when idMaster, and msgMaster is equual
		const items = Items.reduce((acc, item) => {
			const { idMaster, msgMaster } = item;
			if (!acc[idMaster]) {
				acc[idMaster] = { idMaster, msgMaster, children: [] };
			}
			acc[idMaster].children.push(item);
			return acc;
		}, {});
		//convertir items a un array
		const itemsArray = Object.values(items);
		//ordenar el array por idMaster
		itemsArray.sort((a, b) => a.idMaster - b.idMaster);
		setAllNavItems(itemsArray);
		setFilterNavItems(itemsArray);
	}, []);

	useEffect(() => {
		if (searching.length < 3) {
			allNavItems.length > 0 && setFilterNavItems(allNavItems);
			return;
		}

		//filtrar para devolver solo los items que contengan en children el texto buscado
		const filter = allNavItems.filter((item) => {
			return item.children.some((child) => {
				return formatterText(child.lang, child.msg).toLowerCase().includes(searching.toLowerCase());
			});
		});
		// filtrar para obtener solo los child que contengan el texto buscado
		const filterChildren = filter.map((item) => {
			return {
				...item,
				children: item.children.filter((child) => {
					return formatterText(child.lang, child.msg)
						.toLowerCase()
						.includes(searching.toLowerCase());
				})
			};
		});

		setFilterNavItems(filterChildren);
	}, [searching]);

	const permissions = () => {
		RequestUseCase.get(endpoints.roles.getAllRolPermisosModulosByUser(Cookie.get('idUsuario')))
			.then((response) => {
				setData(response);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const validationData = () => {
		data.forEach((element) => {
			switch (element.nombreModulo) {
				case 'Usuario':
					setUserModules(true);
					break;
				case 'Rol':
					setRoleModules(true);
					break;
				case 'GESTION DE CORREO':
					setMailModules(true);
					break;
				case 'CATALOGO ZONA':
					setZoneModules(true);
					break;
				case 'AUDITORIA':
					setAuditModules(true);
					break;
				case 'TERCERO':
					setThirdModules(true);
					break;
				case 'CLIENTE':
					setClientModules(true);
					break;
				case 'CARGOS':
					setPositionsModules(true);
					break;
				default:
					break;
			}
		});
	};

	const handleLang = (e) => {
		idioma.establecerLenguaje(e.target.value.trim());
		let lenguage;

		if (e.target.value.trim() == 'en-US') {
			lenguage = 'en';
		} else if (e.target.value.trim() == 'en-ES') {
			lenguage = 'es';
		}

		const jsonData = {
			idUsuario: Cookie.get('idUsuario'),
			language: lenguage
		};

		updateLenguage(jsonData)
			.then((res) => {
				if (res) {
					CustomAlert('short_msg', {
						icon: 'success',
						title: 'Idioma cambiado',
						text: 'Su idioma ha sido cambiado con éxito',
						confirmButtonColor: '#FFC954'
					});
					idioma.establecerLenguaje(e.target.value.trim());
				}
			})
			.catch(() => {
				CustomAlert('short_msg', {
					icon: 'error',
					title: 'Error',
					text: 'Verifique el enlace de restablecer de contraseña.'
				});
			});
	};

	const handleChange = (e) => {
		setSearching(e.target.value);
	};

	const hashPermissionsVerify = {
		userModules: userModules,
		roleModules: roleModules,
		mailModules: mailModules,
		zoneModules: zoneModules,
		auditModules: auditModules,
		thirdModules: thirdModules,
		clientModules: clientModules,
		positionsModules: positionsModules,
		null: true
	};

	return (
		<div id="mySidenav" className="sidenav disable-scrollbars">
			<form>
				<input
					type="search"
					className="search-modules"
					placeholder={formatterText('btn.search.side.nav')}
					value={searching}
					onChange={handleChange}
				/>
				<button
					type="submit"
					className="search-modules-btn"
					style={{
						cursor: 'default'
					}}
				>
					Buscar módulos
				</button>
			</form>

			<div className="side-nav-header">
				<p>
					<FormattedMessage id="nav.text.language" defaultMessage="Cambiar idioma:" />
				</p>
				<select
					name="lang"
					id="lang"
					value={localStorage.getItem('lang')}
					onChange={handleLang}
					className="global-select"
				>
					<option value="en-ES">
						<FormattedMessage id="nav.text.language.spanish" defaultMessage="Español" />
					</option>
					<option value="en-US">
						<FormattedMessage id="nav.text.language.english" defaultMessage="Ingles" />
					</option>
				</select>
			</div>
			{filterNavItems.map((item) => {
				return (
					<SideNavButton
						title={<FormattedMessage id={item.idMaster} defaultMessage={item.msgMaster} />}
						key={item.idMaster}
					>
						{item.children.map((child, index) => {
							return (
								hashPermissionsVerify[child.permissionVerify] && (
									<SideNavBtn
										pathToGo={child.pathToGo}
										idLang={child.lang}
										defaultMessage={child.msg}
										key={index}
									/>
								)
							);
						})}
					</SideNavButton>
				);
			})}
		</div>
	);
};

export default SideNav;
