import React, { useEffect, useState } from 'react';
// import Hooks
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';
import useProgress from 'hooks/useProgress';
import { useParams } from 'react-router';
// import Components
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import CustomAlert from 'components/CustomAlert';
// import Libs
import HandleOnError from 'common/validators/HandleOnError';
import HandlerNumbers from 'common/validators/HandlerNumbers';
import HandlerText from 'common/validators/HandlerText';
import dateformat from 'dateformat';
import Swal from 'sweetalert2';
// import Services
import { MdDelete } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import endpoints from 'services/api';
// import Styles
import styles from 'screens/services/ExtraTabs/Tab.module.css';

const CreatePrecaution = ({ onClose, exeFun, income, refund }) => {
	const { id } = useParams();
	const { formatterText, noFilledContent, customSB } = useLangv2();
	const fileInput = React.useRef(null);
	const { fetchData, RequestUseCase, COOKIE_USER } = useAxios();
	const reader = new FileReader();
	const [selectValues, setSelectValues] = useState([]);
	const [filesAttached, setFilesAttached] = useState([]);
	const [selectPayment, setSelectPayment] = useState({});
	const [formData, setFormData] = useState({
		idEstadoRecaudo: '',
		idMedioPago: '',
		valor: '',
		descripcion: '',
		idRecaudoServicio: null,
		archivoAdjunto: 'url',
		fechaModificacion: null,
		usuarioCreacion: COOKIE_USER,
		usuarioModificacion: null
	});
	const [show, setShow] = useState(false);
	// Helps to loading data table
	const { loadingProgress, setLoadingProgress, DisplayProgress } = useProgress();

	useEffect(() => {
		getSelectData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getSelectData = () => {
		const promise1 = RequestUseCase.get(endpoints.precautionsServices.getAllPrecautionsState)
			.then((response) => {
				setSelectValues(response);
			})
			.catch((err) => {
				console.log(err);
			});

		const promise2 = RequestUseCase.get(endpoints.precautionsServices.getAllPaymentMethods)
			.then((response) => {
				setSelectPayment(response);
			})
			.catch((err) => {
				console.log(err);
			});

		Promise.all([promise1, promise2])
			.then(() => {
				setLoadingProgress(true);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		const verify = parseFloat(formData.valor) + parseFloat(refund) > parseFloat(income);
		if (formData.idEstadoRecaudo !== '' && formData.idMedioPago !== '' && formData.valor !== '') {
			// verify refund money
			if (formData.idEstadoRecaudo === '2') {
				if (
					(formData.valor > income || verify) &&
					(formData.idMedioPago === '1' ||
						formData.idMedioPago === '2' ||
						formData.idMedioPago === '3')
				) {
					customSB(
						'warning',
						'snackbar.warning.refund.greater.income',
						'El valor de la devolución no puede ser mayor al valor de ingresos.'
					);
				} else {
					structData();
				}
			} else if (formData.idEstadoRecaudo === '1') {
				structData();
			}
		} else {
			noFilledContent();
		}
	};

	const structData = () => {
		onClose();

		let data = {};
		if (show) {
			data = {
				idRecaudoServicio: null,
				idMedioPago: {
					idMedioPago: formData.idMedioPago
				},
				idServicio: {
					idServicio: id
				},
				idEstadoRecaudo: {
					idEstadoRecaudo: formData.idEstadoRecaudo
				},
				valor: formData.valor,
				descripcion: formData.descripcion,
				archivoAdjunto: 'url',
				fechaCreacion: dateformat(new Date(), 'yyyy-mm-dd'),
				fechaModificacion: null,
				usuarioCreacion: COOKIE_USER,
				usuarioModificacion: null
			};

			postItem(data, endpoints.precautionsServices.savePrecautionsServices);
		} else {
			console.log('No tengo ese endpoint ');
		}
	};

	const postItem = (data, route) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					RequestUseCase.post(route, data)
						.then((res) => {
							//TODO : FUNCTION UPLOAD FILES
							if (filesAttached.length > 0) {
								fetchData({
									url: endpoints.UploadFiles.save,
									method: 'post',
									body: {
										idOrigen: res.idRecaudoServicio,
										idTipoOrigenArchivo: 11,
										archivos: filesAttached,
										usuarioCreacion: COOKIE_USER
									}
								}).then(() => {
									//clear files
									setFilesAttached([]);
								});
							}
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.created.general',
										'El registro se ha creado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => exeFun()
								})
							);
						})
						.catch((err) => {
							if (err.response.status === 400 || err.response.status === 420) {
								reject(
									HandleOnError(
										formatterText(
											'alert.message.code.error.general',
											'El código ya existe, por favor ingrese uno distinto'
										)
									)
								);
							} else {
								reject(
									HandleOnError(
										formatterText(
											'alert.message.failed.general',
											'Error al crear el registro, por favor intente nuevamente.'
										)
									)
								);
							}
						});
				});
			}
		});
	};

	// Update a string to set into the form
	const handleText = (e) => {
		HandlerText(e, formData, setFormData);
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	const handleChangeSelect = (e) => {
		if (e.target.value === 2) {
			setShow(false);
		} else {
			setShow(true);
		}

		HandlerText(e, formData, setFormData);
	};

	// Update a number to set into the form
	const handleNumber = (e) => {
		HandlerNumbers(e, formData, setFormData);
	};

	//todo : logica del upload
	const uploadFile = (e) => {
		e.preventDefault();
		const selectedFile = e.target.files[0];
		if (selectedFile.size > 5000000) {
			HandleOnError(formatterText('alert.error.fileLarge'));
			return;
		} //pdf, png, jpg, tiff
		if (
			selectedFile.type !== 'image/jpg' &&
			selectedFile.type !== 'image/png' &&
			selectedFile.type !== 'image/jpeg' &&
			selectedFile.type !== 'image/tiff' &&
			selectedFile.type !== 'application/pdf'
		) {
			HandleOnError(formatterText('alert.error.fileNotValidMediaDdfPngJpgTiff'));
			return;
		}

		reader.readAsDataURL(selectedFile);
		reader.onload = () => {
			const archivo = reader.result.split('base64,')[1];
			//obtener el tipo del archivo
			const extensionArchivo =
				selectedFile.name.lastIndexOf('.') > -1
					? selectedFile.name.substring(
							selectedFile.name.lastIndexOf('.') + 1,
							selectedFile.name.length
					  )
					: '';
			const name = selectedFile.name.substring(0, selectedFile.name.lastIndexOf('.'));
			setFilesAttached((filesAttached) => [...filesAttached, { name, extensionArchivo, archivo }]);
		};
	};
	const deleteFile = (e, item) => {
		e.preventDefault();
		if (filesAttached.length === 1) {
			setFilesAttached([]);
		} else {
			const index = filesAttached.indexOf(item);
			filesAttached.splice(index, 1);
			setFilesAttached([...filesAttached]);
		}
	};

	return (
		<>
			{!loadingProgress ? (
				<DisplayProgress />
			) : (
				<form className={styles['container-ps-modal-ud']}>
					<p className="title-modal">{formatterText('title.service.create.recaudo.key1')}</p>
					<label>
						<span>{formatterText('title.service.create.recaudo.key2')}</span>
						<select
							className="input-label-style"
							style={{ margin: '1rem 0rem' }}
							name="idEstadoRecaudo"
							value={formData.idEstadoRecaudo}
							onChange={handleChangeSelect}
							required
						>
							<option value="">{formatterText('title.service.create.recaudo.key3')}</option>
							{selectValues?.map((item) => {
								return (
									<option key={item.idEstadoRecaudo} value={item.idEstadoRecaudo}>
										{item.nombre}
									</option>
								);
							})}
						</select>
					</label>

					{show && (
						<label>
							<span>{formatterText('title.service.create.recaudo.key4')}</span>
							<select
								className="input-label-style"
								style={{ margin: '1rem 0rem' }}
								name="idMedioPago"
								value={formData.idMedioPago}
								onChange={handleText}
								required
							>
								<option value="">{formatterText('title.service.create.recaudo.key5')}</option>
								{selectPayment.map((item) => {
									return (
										<option key={item.idMedioPago} value={item.idMedioPago}>
											{item.nombre}
										</option>
									);
								})}
							</select>
						</label>
					)}
					<label>
						<span>{formatterText('title.service.create.recaudo.key6')}</span>
						<input
							type="text"
							value={formData.valor}
							name="valor"
							onChange={handleNumber}
							placeholder={formatterText('title.precaution.placeholder.value')}
							className="input-default w100-container"
							required
						/>
					</label>
					<label>
						<span>{formatterText('title.service.create.recaudo.key7')}</span>
						<input
							type="text"
							value={formData.descripcion}
							name="descripcion"
							onChange={handlerTextDescription}
							placeholder={formatterText('title.precaution.placeholder.observations')}
							className="input-default w100-container"
							required
						/>
					</label>

					<div
						className="btnAdjuntarContainer"
						style={{
							display: 'flex',
							width: '100%'
						}}
					>
						<label
							style={{
								width: '80%',
								background: '#EFEEEE',
								boder: '1px',
								borderStyle: 'dashed',
								borderColor: '#747474',
								margin: '15px auto',
								minHeight: '150px',
								borderRadius: '10px'
							}}
							className="custom-file-upload"
						>
							<input
								id="file-input"
								style={{ display: 'none' }}
								accept=".pdf , .png , .jpg , .tiff"
								type="file"
								name="files"
								value=""
								onChange={uploadFile}
								ref={fileInput}
							/>
							<div
								style={{
									display: 'flex',
									flexFlow: 'row wrap'
								}}
							>
								{filesAttached.map((item, index) => (
									<div
										key={`${item.name} ${index}`}
										style={{
											backgroundColor: '#FFFFFF',
											borderRadius: '10px',
											boder: '1px',
											borderStyle: 'solid',
											borderColor: '#D9D9D9',
											padding: '5px',
											paddingRight: '10px',
											marginRight: '20px',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between'
										}}
									>
										<p
											style={{
												cursor: 'pointer'
											}}
											onClick={(e) => {
												e.preventDefault();
											}}
										>
											{item.name}
										</p>
										<MdDelete
											size={25}
											color="red"
											style={{
												marginLeft: '10px',
												zIndex: '999'
											}}
											cursor="pointer"
											onClick={(e) => {
												e.preventDefault();
												deleteFile(e, item);
											}}
										/>
									</div>
								))}
							</div>
						</label>
					</div>
					<div
						style={{
							width: '80%',
							display: 'flex',
							margin: '0 auto',
							justifyContent: 'flex-end',
							marginTop: '25px',
							marginBottom: '25px'
						}}
					>
						<button
							onClick={(e) => {
								e.preventDefault();
								fileInput.current.click();
							}}
							style={{ width: 'fit-content' }}
							className="btn-primary"
						>
							<FormattedMessage id="btn.upload.files" defaultMessage="Subir archivos" />
						</button>
					</div>

					<section className="footer-grid-buttons">
						<input
							type="submit"
							className="btn-action-primary"
							value={formatterText('title.service.create.recaudo.key8')}
							onClick={(e) => handleSubmit(e)}
						/>
						<button
							className="btn-action-cancel"
							onClick={(e) => {
								e.preventDefault();
								onClose();
							}}
						>
							{formatterText('btn.cancel')}
						</button>
					</section>
				</form>
			)}
		</>
	);
};

export default CreatePrecaution;
