import React from 'react';
import { SearchWrapper } from 'context/SearchContext';
import CreateScheduleComponent from './CreateSchedule.component';

const CreateSchedule = () => {
	return (
		<SearchWrapper>
			<CreateScheduleComponent />
		</SearchWrapper>
	);
};

export default function CreateScheduleWithSearch(props) {
	return (
		<SearchWrapper>
			<CreateSchedule {...props} />
		</SearchWrapper>
	);
}
