import React from 'react';
// Import libs
import { Formiz } from '@formiz/core';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import InputSelectorResponsive from 'common/inputs/InputSelectorResponsive';
import { FormattedMessage } from 'react-intl';
// Import Components
import Table from 'common/minimalTables/TableMinimal';
import Selector from 'common/selects/Selector';
import SelectorMultiCreate from 'common/selects/SelectorMultiCreate';
import UpdateCostAssociateProduct from '../UpdateCostAssociateProduct';
// Import Paths
import paths from 'services/paths';
//Import ViewModel
import UpdateProductViewModel from './UpdateProduct.viewModel';

export default function UpdateProduct() {
	const {
		active,
		auxData,
		costo,
		currentDataTable,
		formatCurrency,
		formatIvaField,
		formatterText,
		formCostProductProviderData,
		formData,
		getDataMiniTable,
		handleAddCostProduct,
		handleChangeMulti,
		handleClose,
		handleCostoChange,
		handleIvaChange,
		handleOpen,
		handlerTextDescription,
		handleSubmit,
		handleText,
		iva,
		loading,
		myForm,
		navigate,
		open,
		selectedSearch,
		selectorMultiValues,
		selectorValues,
		selectValues,
		setActive,
		setAuxData,
		setCurrentDataTable,
		style,
		titlesTableCostProduct,
		formatSelectorMultiValues,
		handleSku
	} = UpdateProductViewModel();

	return (
		<>
			<div className="centered-form">
				<Formiz onValidSubmit={handleSubmit} connect={myForm}>
					<form
						noValidate
						className="container-wrapForm"
						onSubmit={handleSubmit}
						style={{ minHeight: '16rem' }}
					>
						<div className="container-wrap-formB">
							<div className="title-section">
								<p className="wrap-form-title">{formatterText('p.product', 'Producto')}</p>
							</div>
							<section className="grid-container-2c">
								<h3 className="p-styles">
									{formatterText('p.product.name', 'Nombre del producto*')}
								</h3>
								<input
									className="input-primary"
									type="text"
									name="nombre"
									label={formatterText('p.product.name', 'Nombre del producto*')}
									value={formData.nombre}
									placeholder={formData.nombre}
									onChange={handleText}
									maxLength="100"
								/>
								<h3 className="p-styles">SKU*</h3>
								<input
									className="input-primary"
									type="text"
									name="sku"
									value={formData.sku}
									placeholder={formData.sku}
									label="SKU*"
									onChange={handleSku}
								/>
							</section>
							<section className="form-responsive-information__option">
								<h3 className="p-styles">
									{formatterText('table.title.barCode.product', 'Código de barras*')}
								</h3>
								<section className="w100-container">
									<SelectorMultiCreate
										isLoading={loading}
										dataValue={formatSelectorMultiValues(selectorMultiValues)}
										setterFunction={handleChangeMulti}
										required
									/>
								</section>
							</section>
							<section className="form-responsive-information__option">
								<h3 className="p-styles">
									{formatterText('p.subcategory.of.product', 'Subcategoría del producto')}
								</h3>
								<InputSelectorResponsive
									newForm={true}
									name="idSubCategoriaProducto"
									data={selectedSearch.subCategoriasProducto}
									placeholder={selectValues.label}
									dataValue={selectValues.label}
									onChange={setAuxData}
									isLoading={loading}
									selectValue={selectorValues}
									required={formatterText(
										'p.subcategory.required.product',
										'La subcategoría es requerida'
									)}
								/>
							</section>
							<section className="form-responsive-information__option">
								<h3 className="p-styles">
									{formatterText('table.title.description', 'Descripción')}
								</h3>
								<textarea
									className="input-primary-textarea"
									name="descripcion"
									value={formData.descripcion}
									onChange={handlerTextDescription}
									placeholder={formatterText(
										'table.title.description.product',
										'Descripción del producto'
									)}
									maxLength="200"
									label={formatterText('table.title.description', 'Descripción')}
								/>
							</section>
							<section className="form-responsive-information__option">
								<h3 className="p-styles">{formatterText('table.title.state', 'Estado')}</h3>
								<label className="form-responsive-label">
									<p className="form-responsive-toggle">
										{active
											? formatterText('btn.active', 'Activo')
											: formatterText('p.unActive', 'Inactivo')}
									</p>
									<label className="switch">
										<input
											checked={!!active}
											onChange={() => {
												setActive(!active);
											}}
											type="checkbox"
										/>
										<span className="slider round"></span>
									</label>
								</label>
							</section>
							{currentDataTable.length > 0 && (
								<>
									<p className="wrap-form-title">
										{formatterText('p.costs.asociated.products', 'Costos asociados al producto')}{' '}
									</p>
									<section className="form-responsive-container-information">
										<Table
											titles={titlesTableCostProduct}
											data={currentDataTable}
											type="costoProductoProveedor"
											handleOpen={handleOpen}
											labelTable={formatterText(
												'p.costs.asociated.products.label.footer',
												'Costos asociados'
											)}
											canSearch={true}
											getDataMiniTable={getDataMiniTable}
										/>
									</section>
								</>
							)}
							<div className="title-section">
								<p className="wrap-form-title">
									{formatterText('p.costs.assign', '+ Asignar costo')}
								</p>
							</div>
							<section className="wrapForm">
								<label className="wrapForm__label">
									<h3 className="p-styles spacing-r1">
										{formatterText('p.product.unit.meassurement', 'Unidad de medida')}
									</h3>
									<Selector
										name="idUnidadMedida"
										data={selectedSearch.unidadMedida}
										placeholder={
											<FormattedMessage
												id="input.placeholder.select"
												defaultMessage="Selecione una opción"
											/>
										}
										dataValue={auxData}
										setterFunction={setAuxData}
										isLoading={loading}
										selectValue={auxData.idUnidadMedida}
										isRequired={false}
									/>
								</label>
								<label className="wrapForm__label">
									<h3 className="p-styles spacing-r1">
										<FormattedMessage id="table.title.provider" defaultMessage="Proveedor" />
									</h3>
									<Selector
										name="idProveedor"
										data={selectedSearch.proveedor}
										placeholder={
											<FormattedMessage
												id="input.placeholder.select"
												defaultMessage="Selecione una opción"
											/>
										}
										dataValue={auxData}
										setterFunction={setAuxData}
										isLoading={loading}
										selectValue={auxData.idProveedor}
										isRequired={false}
									/>
								</label>
								<label className="wrapForm__label">
									<h3 className="p-styles spacing-r1">
										<FormattedMessage id="table.title.currency" defaultMessage="Moneda" />
									</h3>
									<Selector
										name="idMoneda"
										data={selectedSearch.tipoMonedas}
										placeholder={
											<FormattedMessage
												id="input.placeholder.select"
												defaultMessage="Selecione una opción"
											/>
										}
										dataValue={auxData.idMoneda}
										setterFunction={setAuxData}
										isLoading={loading}
										selectValue={auxData.idMoneda}
										isRequired={true}
										required="Seleccione tipo de moneda"
									/>
								</label>
								<label className="wrapForm__label">
									<h3 className="p-styles spacing-r1">IVA %</h3>
									<input
										className="input-primary"
										type="text"
										name="iva"
										value={formatIvaField(iva)}
										onChange={handleIvaChange}
										placeholder="IVA"
										maxLength="6"
									/>
								</label>
								<label className="wrapForm__label">
									<h3 className="p-styles spacing-r1">
										<FormattedMessage id="table.title.cost" defaultMessage="Costo" />
									</h3>
									<input
										className="input-primary"
										type="text"
										name="costo"
										value={formatCurrency(costo)}
										onChange={handleCostoChange}
										placeholder="$0.00"
										maxLength="20"
									/>
								</label>
							</section>
							<input
								onClick={handleAddCostProduct}
								type="button"
								className="btn-primary btn-primary-center"
								value={formatterText('p.costs.assign.button', 'Asignar costo')}
							/>
						</div>
						<section className="form-responsive-container-buttons">
							<button type="submit" onClick={handleSubmit} className="btn-primary">
								{formatterText('p.product.save.button', 'Guardar producto')}
							</button>
							<button className="input-cancel" onClick={() => navigate(paths.products)}>
								{formatterText('btn.cancel', 'Cancelar')}
							</button>
						</section>
					</form>
				</Formiz>
			</div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box sx={{ ...style, width: '90%' }}>
					<UpdateCostAssociateProduct
						dataUnits={selectedSearch.unidadMedida}
						dataProviders={selectedSearch.proveedor}
						onClose={handleClose}
						formCostProductProviderData={formCostProductProviderData}
						handleCostoChange={handleCostoChange}
						formatCurrency={formatCurrency}
						handleIvaChange={handleIvaChange}
						formatIvaField={formatIvaField}
						costoTabla={costo}
						currentDataTable={currentDataTable}
						setCurrentDataTable={setCurrentDataTable}
						getDataMiniTable={getDataMiniTable}
					/>
				</Box>
			</Modal>
		</>
	);
}
