import Axios from 'axios';
import Cookie from 'js-cookie';
import endpoints from './index';

const getAllAlerts = async (data) => {
	const url = endpoints.notificationsTable.getAllAlertsUser(data);
	const response = await Axios.get(url);
	return response.data;
};

const markAllAlertsAsRead = async () => {
	const url = endpoints.notificationsTable.markAllAlertsAsRead(Cookie.get('idUsuario'));
	const response = await Axios.get(url);
	return response.data;
};

/**
 * ********************************
 * ENDPOINTS PARA LA TABLA ********
 * ********************************
 */

/**
 *
 * @param {*} params  -- idUser, currentPage, registersPerPage, list
 * @returns
 */
const alertsByUser = async (params) => {
	const url = endpoints.notificationsTable.getAllAlertsUser(params);
	const response = await Axios.get(url);
	return response.data;
};

const alertsByUserIcon = async (params) => {
	const url = endpoints.notificationsTable.getAllAlertsUserIcon(params);
	const response = await Axios.get(url);
	return response.data;
};

const alertsOptional = async (params, body) => {
	const url = endpoints.notificationsTable.getAllAlertByUserOptional(params);
	const response = await Axios.post(url, body);
	return response.data;
};

const getCountAlertPanel = async () => {
	const url = endpoints.notificationsTable.getCountAlertsPanel({ idUser: Cookie.get('idUsuario') });
	const response = await Axios.get(url);
	return response.data;
};
const getCountAlertIcon = async () => {
	const url = endpoints.notificationsTable.getCountAlertIcon({ idUser: Cookie.get('idUsuario') });
	const response = await Axios.get(url);
	return response.data;
};

export {
	alertsByUser,
	alertsByUserIcon,
	alertsOptional,
	getAllAlerts,
	getCountAlertIcon,
	getCountAlertPanel,
	markAllAlertsAsRead
};
