import React from 'react';
// Import Components
import { Formiz, FormizStep } from '@formiz/core';
import CreateExternalUser from '../CreateExternalUser';
import UserExternalInfoViewModel from './UserExternalInfo.viewModel';

const UserExternalInfo = () => {
	const { myForm, handleSubmit } = UserExternalInfoViewModel();
	return (
		<div className="centered-form">
			<Formiz onValidSubmit={handleSubmit} connect={myForm}>
				<form
					noValidate
					onSubmit={myForm.submit}
					className="container-wrapForm"
					style={{ minHeight: '16rem' }}
				>
					<div className="new-container-wrapForm__tabs">
						{myForm.steps.map((step) => (
							<button
								key={step.name}
								className={`new-tab-option ${
									step.name === myForm.currentStep.name ? 'is-active' : ''
								}`}
								type="button"
								onClick={() => myForm.goToStep(step.name)}
							>
								{step.label}
								{!step.isValid && step.isSubmitted && <small className="mr-2">⚠️</small>}
							</button>
						))}
					</div>

					<div className="container-wrapForm-content">
						<FormizStep name="step1" label="Datos del usuario">
							<CreateExternalUser />
						</FormizStep>
						{/* <FormizStep name="step2" label="Extra">
              <p>Pendiente si es necesario</p>
            </FormizStep> */}
					</div>
				</form>
			</Formiz>
		</div>
	);
};

export default UserExternalInfo;
