import React from 'react';
// Import Contexts
// Import Components
import WarningDialog from 'components/tableOptions/WarningDialog';
// Mui Cell row style
import TableRow from '@mui/material/TableRow';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md';
// Icons for cell
import TableCell from '@mui/material/TableCell';

import DynamicRowNotificationsViewModel from './DynamicRowNotifications.viewModel';
import useAxios from 'hooks/useAxios';

import { DynamicRowNotificationsProps } from './DynamicRowNotifications.props';

const DynamicRowNotifications = ({ columns, setUpdated }) => {
	const { changeState, handleMarkAsRead, dialog, setDialog, loading, formatterText } =
		DynamicRowNotificationsViewModel({ columns, setUpdated });
	const { RequestUseCase, endpoints } = useAxios();
	return (
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			{Object.keys(columns).map((column, index) => {
				if (column === 'idAgendaNotificacion' || column === 'idServicio' || column === 'leido') {
					return null;
				}
				return (
					<TableCell
						key={`${index + 1}`}
						component="th"
						scope="row"
						className="enabled"
						style={{ cursor: 'pointer' }}
						onClick={() => {
							//open a new window with a param
							window.open(`#/servicios/editar/${columns.idServicio.idServicio}`, '_blank');
							RequestUseCase.get(
								endpoints.notificationsTable.markAsRead(columns.idAgendaNotificacion)
							);
						}}
					>
						{columns[column]}
					</TableCell>
				);
			})}
			<TableCell className={loading ? 'disabled' : 'enabled'} align="center">
				{changeState === null ? (
					<MdOutlineCheckBoxOutlineBlank
						className="icon"
						onClick={() => {
							setDialog({
								text: formatterText('alert.modal.mark.as.read.notifications'),
								active: true,
								funcion: () => handleMarkAsRead(columns)
							});
						}}
					/>
				) : (
					<MdOutlineCheckBox className="icon" />
				)}
			</TableCell>
			<WarningDialog dialog={dialog} setDialog={setDialog} />
		</TableRow>
	);
};

DynamicRowNotifications.propTypes = DynamicRowNotificationsProps;

export default DynamicRowNotifications;
