import { ONLY_NUMBERS_REGEX, STRINGREGEX, TEXTONLYONESPACEREGEX } from 'common/validators/Regex';
import * as yup from 'yup';

export const getFormikValidationSchema = () => {
	return yup.object().shape({
		nombre: yup
			.string()
			.required('p.form.error.required')
			.matches(STRINGREGEX, 'alert.title.file.name.invalid')
			.matches(TEXTONLYONESPACEREGEX, 'alert.title.file.name.invalid')
			.max(100, 'p.label.title.ingresa100Caracteres'),
		extension: yup.string().required('p.form.error.required'),
		pesoMaximoArchivo: yup
			.string()
			.required('p.form.error.required')
			.matches(ONLY_NUMBERS_REGEX, 'alert.title.file.weight.invalid'),
		cantidadMaxima: yup
			.string()
			.required('p.form.error.required')
			.matches(ONLY_NUMBERS_REGEX, 'alert.title.file.weight.invalid')
	});
};
