import React, { useContext } from 'react';
// Import Libs
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
// Import Contexts
import { AppContext } from 'context/AppContext';
// Import styles
import styles from './Btn.actions.module.css';

const BtnSchedule = ({ data, onOpen }) => {
	const { setMoreInfoTimeZone } = useContext(AppContext);

	return (
		<section
			style={{
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-evenly'
			}}
		>
			<button
				className={styles.btnDetail}
				onClick={() => {
					const { franjaHorariaDefinitiva } = data;
					setMoreInfoTimeZone(franjaHorariaDefinitiva);
					onOpen();
				}}
			>
				<FormattedMessage id="text.details" defaultMessage="Ver detalle" />
			</button>
		</section>
	);
};

BtnSchedule.propTypes = {
	data: PropTypes.object,
	onOpen: PropTypes.func
};

export default BtnSchedule;
