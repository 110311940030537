import { AREA_IDS } from 'constants/mails';

export const parseDataToSubmit = (values) => {
	const dataToSend = {
		area: AREA_IDS[values.area],
		destinatario: values.destinatario.trim()
	};

	return dataToSend;
};
