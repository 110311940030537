import useAxios from 'hooks/useAxios';
import { useContext, useState } from 'react';
// Import libs
import { useForm } from '@formiz/core';
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
// Import Components
import CustomAlert from 'components/CustomAlert';
// Import Context
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import Hooks
import useLang from 'hooks/useLang';
import { useNavigate } from 'react-router-dom';
//Import Utils
import HandleOnError from 'common/validators/HandleOnError';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import { validateDuplicateCode } from 'common/validators/ValidateDuplicates';
// Import Paths
import paths from 'services/paths';
// Import services
import { useSeachContext } from 'context/SearchContext';
import endpoints from 'services/api';
// Import Models
import ProductCategory from 'models/ProductCategory';

export default function CreateProductCategoryViewModel() {
	const { RequestUseCase } = useAxios();

	// Configuraciones del modal
	const [open, setOpen] = useState(false);
	const toggleOpen = () => setOpen(!open);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	// Call context TableMinimalContext
	const { currentDataTable, setCurrentDataTable } = useContext(TableMinimalContext);

	const { dataTable, setDataTable, searchResults = [], setSearchResults } = useSeachContext();

	//  Call hook of language
	const { formatterText } = useLang();
	// hook para la navegacion
	const navigate = useNavigate();
	// Example of form data
	const [formData, setFormData] = useState(new ProductCategory());
	// Información principal de la tabla intermedia
	const [currentProductCategory, setCurrentProductCategory] = useState({
		idSubCategoriaProducto: uuidv4(),
		nombre: '',
		codigo: '',
		estado: 1,
		descripcion: ''
	});

	const titlesTableSubCategories = [
		formatterText('table.title.subCategory', 'Subcategoría'),
		formatterText('table.title.description', 'Descripción'),
		formatterText('table.title.code', 'Código'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];

	const myForm = useForm();

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		if (formData.nombre === null || formData.codigo === null) {
			HandleOnError(
				formatterText(
					'p.label.title.debesCompletarTodosLosCampos',
					'Debes completar todos los campos'
				)
			);
		} else {
			if (
				validateDuplicateCode(
					formData.codigo,
					formatterText('alert.message.code.error.general'),
					() => RequestUseCase.get(endpoints.productCategory.getAllProductCategory)
				)
			) {
				createProductCategory(formData);
			}
		}
	};

	const handleFormChange = (values) => {
		setFormData((prevData) => ({ ...prevData, ...values }));
		setCurrentProductCategory((prevData) => ({ ...prevData, ...values }));
	};

	// Call the service POST
	const createProductCategory = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general'),
			text: formatterText('alert.description.create.general'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					RequestUseCase.post(endpoints.productCategory.addProductCategory, data)
						.then((res) => {
							// LLamo el servicio de asociar subcategorias, pasandole el id,
							// y el resolve y el reject por si falla en algun momento la promesa
							associateSubProductCategory(res.idCategoriaProducto, resolve, reject);
						})
						.catch((err) => {
							if (err.response?.data?.message) {
								HandleOnError(formatterText(err.response?.data?.message));
							} else {
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
						});
				});
			}
		});
	};

	// Call the service POST to associate subproduct categories
	const associateSubProductCategory = (idM, resolve, reject) => {
		// Revisa si el array de subcategorias tiene datos
		console.log('dataTable', dataTable);
		console.log('currentData', currentDataTable);
		if (currentDataTable.length > 0) {
			// Recorre el array de subcategorias y alimenta la constante promesas
			const promesas = currentDataTable.map(
				(item) =>
					new Promise((resl, rej) => {
						const data = {
							idSubCategoriaProducto: null,
							nombre: item.nombre,
							descripcion: item.descripcion,
							codigo: item.codigo,
							idCategoriaProducto: {
								idCategoriaProducto: idM // id de la categoria principal
							},
							fechaRegistro: null,
							fechaModificacion: null,
							usuarioCreacion: Cookie.get('idUsuario'),
							usuarioModificacion: null
						};
						// Llamo el servicio de asociar subcategorias
						RequestUseCase.post(endpoints.subProductCategory.addSubProductCategory, data)
							.then((response) => {
								console.log(response);
								resl(response);
							})
							.catch((err) => {
								rej(err);
							});
					})
			);
			// Reviso que todas las promesas hallan terminado y muestro el mensaje de exito de las asociaciones
			Promise.all(promesas)
				.then(() => {
					resolve(
						CustomAlert('confirm_msg', {
							icon: 'success',
							title: formatterText('alert.title.confirm.general'),
							text: formatterText('alert.message.associations.general'),
							confirmButtonText: formatterText('alert.button.continue'),
							allowOutsideClick: false,
							executeFunction: () => navigate(paths.products)
						})
					);
				})
				.catch((err) => {
					if (err.response.status === 400) {
						reject(HandleOnError(formatterText('alert.message.failed.associations.general')));
					} else {
						reject(HandleOnError(formatterText('alert.message.failed.general')));
					}
					console.log(err);
				});
		} else {
			// Si no tiene datos el array de subcategorias, muestro el mensaje de exito y devuelvo a la pantalla principal
			CustomAlert('confirm_msg', {
				icon: 'success',
				title: formatterText('alert.title.confirm.general'),
				text: formatterText('alert.message.confirm.created.general'),
				confirmButtonText: formatterText('alert.button.continue'),
				allowOutsideClick: false,
				executeFunction: () => navigate(paths.products)
			});
		}
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	// Add a product in the dynamic minimal table
	const handleAddproductCategory = () => {
		const { nombre, codigo } = currentProductCategory;
		// Verifica si los campos están vacíos
		if (!nombre || !codigo) {
			HandleOnError(
				formatterText(
					'p.label.title.debesCompletarTodosLosCampos',
					'Debes completar todos los campos'
				)
			);
			return;
		}

		// Verifica si el código ya existe en la tabla de datos
		if (currentDataTable.some((item) => item.codigo === codigo)) {
			// HandleOnError('alert.subcategory.exists.general');
			HandleOnError(
				formatterText(
					'alert.subcategory.exists.general',
					'El código ya existe en alguna subcategoría, por favor ingrese uno distinto'
				)
			);
			return;
		}

		// Agrega el nuevo elemento al contexto
		const newCategory = {
			...currentProductCategory,
			estado: 1,
			idSubCategoriaProducto: uuidv4()
		};

		const updatedDataTable = [...dataTable, newCategory];
		const updatedSearchResults = [...searchResults, newCategory];

		setCurrentDataTable(updatedDataTable);
		setDataTable(updatedDataTable);
		setSearchResults(updatedSearchResults);

		// Limpia el estado del formulario de subcategorías
		setCurrentProductCategory({
			nombre: '',
			codigo: '',
			descripcion: '',
			estado: 1,
			idSubCategoriaProducto: uuidv4()
		});
	};

	const handlerTextDescriptionSubCategory = (e) => {
		HandlerTextDescription(e, currentProductCategory, setCurrentProductCategory);
	};
	return {
		currentDataTable,
		currentProductCategory,
		formatterText,
		formData,
		handleAddproductCategory,
		handleClose,
		handleFormChange,
		handleOpen,
		handlerTextDescription,
		handlerTextDescriptionSubCategory,
		handleSubmit,
		myForm,
		navigate,
		titlesTableSubCategories,
		open,
		setOpen,
		toggleOpen
	};
}
