import useLang from 'hooks/useLang';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select/creatable';
import customStyles2 from './default.select.style';

export default function SelectorMulti({ data, isLoading, dataValue, setterFunction, isRequired }) {
	const [selectedOption, setSelectedOption] = useState(null);
	const [isError, setIsError] = useState(false);

	const { formatterText } = useLang();

	useEffect(() => {
		setSelectedOption(dataValue);
	}, [dataValue]);

	const handleChangeMulti = (selectedOptions) => {
		setterFunction(selectedOptions);

		// Validar si se ingresó algún valor
		const hasValue = Array.isArray(selectedOptions) && selectedOptions.length > 0;

		setIsError(!hasValue);
	};

	return (
		<>
			<Select
				styles={customStyles2}
				defaultValue={dataValue}
				value={dataValue}
				onChange={(selectedOption) => {
					handleChangeMulti(selectedOption);
				}}
				isMulti
				formatCreateLabel={(inputValue) =>
					`${formatterText('select.placeholder.new.option')} ${inputValue}`
				}
				noOptionsMessage={() => formatterText('select.placeholder.no.options.create')}
				name="multiple-select"
				options={data}
				className="basic-multi-select"
				classNamePrefix="select"
				placeholder={
					isLoading ? (
						<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
					) : (
						<FormattedMessage
							id="input.placeholder.select"
							defaultMessage="Selecciona una opción"
						/>
					)
				}
			/>
			<input
				className={`input-required${isError ? ' input-error' : ''}`}
				type="text"
				defaultValue={selectedOption || ''}
				value={selectedOption || ''}
				autoComplete="off"
				required={isRequired}
			/>
		</>
	);
}
