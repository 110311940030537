import React from 'react';
// Mui Cell row style
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import BtnActions from 'common/minimalTables/BtnActions';

const RowTypeServiceActivity = ({ item, i, setDialog, handleDelete }) => {
	return (
		<TableRow key={i}>
			<TableCell>{item?.name}</TableCell>
			<TableCell>{item?.description}</TableCell>
			<TableCell>{item?.code}</TableCell>
			<TableCell>{item?.client}</TableCell>
			<TableCell>{item?.clienteId}</TableCell>
			<BtnActions item={item} setDialog={setDialog} handleDelete={handleDelete} />
		</TableRow>
	);
};

export default RowTypeServiceActivity;
