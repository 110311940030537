import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { formatterText } from 'hooks/useLangv2';
import * as React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const WarningDialog = ({ dialog, setDialog }) => {
	const handleClose = () => {
		setDialog({ ...dialog, active: false });
	};

	return (
		<Dialog
			open={dialog.active}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => setDialog({ ...dialog, active: false })}
			aria-describedby="alert-dialog-slide-description"
		>
			<DialogTitle>{`${formatterText('dialog.title.delete')}`}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-slide-description">{dialog.text}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>{formatterText('dialog.title.delete.cancel')}</Button>
				<Button onClick={dialog.funcion}>{formatterText('dialog.title.delete.confirm')}</Button>
			</DialogActions>
		</Dialog>
	);
};

export default React.memo(WarningDialog);
