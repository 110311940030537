import { useEffect } from 'react';
// Import Context
import { downloadTable } from 'components/utils/ExportJsonFile';
import useLangv2 from 'hooks/useLangv2';
// Import Hooks
import useGetData from 'hooks/useGetData';
// Import Components
import { useSeachContext } from 'context/SearchContext';
import endpoints from 'services/api';
import useAxios from 'hooks/useAxios';

export default function FormTableViewModel() {
	const { RequestUseCase } = useAxios();
	// hook to loading data
	const { formatterText } = useLangv2();
	const { loading, error, toggleLoading, displayMessage, displayLoading } = useGetData();

	const { setDataTable } = useSeachContext();

	useEffect(() => {
		toggleLoading(true);
		promiseGetDataTable();
		toggleLoading(false);
	}, []);

	//Promesa para esperar los datos de la tabla
	const promiseGetDataTable = () => {
		return new Promise((resolve) => {
			RequestUseCase.get(endpoints.formServices.getAllFormServices).then((data) => {
				data.sort((a, b) => (a.nombre.toLowerCase() > b.nombre.toLowerCase() ? 1 : -1));
				getDataTable(data, resolve);
			});
		});
	};
	// función para cargar datos
	const getDataTable = (data, resolve) => {
		const newArray = [];
		const promises = data.map((item) => {
			return RequestUseCase.get(
				endpoints.formServices.getAllFormWithQuestionsById(item.idFormulario)
			)
				.then((res) => {
					const form = res[0];
					handleStructureItems(newArray, form);
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
				});
		});
		Promise.all(promises)
			.then(() => {
				setDataTable(newArray);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleDeleteItem = (rowId) => {
		const deleteItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.formServices.deleteFormService(rowId))
				.then((res) => {
					console.log(res);
					const newArray = [];
					res.map((item) => {
						handlePostDeleteStructureItems(newArray, item);
					});
					setDataTable(newArray);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return deleteItemPromise;
	};

	const handleEditStateItem = (body) => {
		const data = sendPutState(body);
		const updateItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.formServices.updateFormService, data)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return updateItemPromise;
	};
	const sendPutState = (data) => {
		const body = {
			idFormulario: data.idFormulario,
			nombre: data.nombreFormulario,
			descripcion: data.descripcionFormulario,
			codigo: data.codigoFormulario,
			estado: data.estado,
			fechaRegistro: data.fechaRegistroFormulario,
			fechaModificacion: null,
			usuarioCreacion: null,
			usuarioModificacion: null
		};
		return body;
	};
	const handleStructureItems = (newArray, item) => {
		newArray.push({
			id: parseInt(item.idFormulario),
			nombre: item.nombreFormulario,
			descripcion: item.descripcionFormulario,
			codigo: item.codigoFormulario,
			estado: parseInt(item.estadoFormulario),
			objeto: { ...item }
		});
	};
	const handlePostDeleteStructureItems = (newArray, item) => {
		newArray.push({
			id: parseInt(item.idFormulario),
			nombre: item.nombre,
			descripcion: item.descripcion,
			codigo: item.codigo,
			estado: parseInt(item.estado),
			objeto: { ...item }
		});
		newArray.sort((a, b) => (a.nombre.toLowerCase() > b.nombre.toLowerCase() ? 1 : -1));
	};
	const { searchResults = [] } = useSeachContext();
	const handleDownloadItem = (rowId) => {
		const userName = JSON.parse(localStorage.getItem('userData')).usuario
			? JSON.parse(localStorage.getItem('userData')).usuario
			: 'ADMIN';
		const moduleName = 'FORMULARIOS';
		const data = searchResults.filter((item) => item.id === rowId);
		const print = data[0];
		const printQuestions = print.objeto.preguntasFormulario;
		const newArray = [];
		printQuestions.forEach((item) => {
			let options = '';
			item.respuestasEncuesta.forEach((item2, index) => {
				options += `${String.fromCharCode(10)}${index + 1}) ` + item2.descripcionRespuesta + ' ';
			});
			newArray.push({
				nombre: item.tituloPregunta,
				requerida: item.obligatorioPregunta,
				tipoPregunta:
					translatedOptions[
						item?.respuestasEncuesta[0]?.tipoObjetoFormulario?.nombreTipoObjetoFormulario
					] || 'Respuesta larga',
				opciones: options
			});
		});
		const dataTable = {
			'Formulario Id': print.id,
			'Nombre Formulario': print.nombre,
			Descripcion: print.descripcion,
			Codigo: print.codigo,
			Estado: print.estado === 1 ? 'Activo' : 'Inactivo'
		};
		newArray.forEach((valor, index) => {
			dataTable[`Pregunta ${index + 1}${valor.requerida === 1 ? '*' : ''}`] =
				valor.nombre.split('<p>').join('').split('</p>').join('') +
				' - ' +
				String.fromCharCode(10) +
				valor.tipoPregunta;

			dataTable[`Opciones de la pregunta # ${index + 1}`] = valor.opciones;
		});
		downloadTable({ moduleName, userName, dataTable });
	};

	const translatedOptions = {
		Select: 'Selector',
		Checkboxes: 'Seleccion Multiple',
		RadioButtons: 'Seleccion Unica',
		MultiLineInput: 'Pregunta Abierta',
		DatePicker: 'Selector de Fecha'
	};

	const titles = [
		formatterText('header.title.service.parameter.form.name'),
		formatterText('header.title.service.parameter.form.description'),
		formatterText('header.title.service.parameter.form.code'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];
	return {
		loading,
		error,
		promiseGetDataTable,
		handleDownloadItem,
		handleDeleteItem,
		handleEditStateItem,
		displayMessage,
		displayLoading,
		titles
	};
}
