import useAxios from 'hooks/useAxios';
import React, { useContext, useEffect, useState } from 'react';
// Mui Cell row style
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import BtnActions from 'common/minimalTables/BtnActions';
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import services
import { useSeachContext } from 'context/SearchContext';
import useLangv2 from 'hooks/useLangv2';
import endpoints from 'services/api';

const RowAssociatePrices = ({
	item,
	i, // index
	setDialog,
	closeDialog,
	handleOpen,
	isEdit
}) => {
	const { RequestUseCase } = useAxios();
	const [rowState, setRowState] = useState(item.estado);
	const { setDataTable, searchResults = [] } = useSeachContext();
	const { currentDataTable, setCurrentDataTable, setResultsTableSearch, setModalData } =
		useContext(TableMinimalContext);

	const { successRemoveItem } = useLangv2();

	// Helps to re-render the component when the state changes
	useEffect(() => {
		setRowState(item.estado);
	}, [searchResults]);

	const handleDelete = (row) => {
		if (typeof row.idPrecioAsociado === 'number') {
			RequestUseCase.delete(endpoints.associatePrices.deleteAssociate(row.idPrecioAsociado))
				.then(() => {
					// filter in the array data the id selected and slice it
					const newMinimalRows = currentDataTable.filter((et) => {
						return et.idPrecioAsociado !== row.idPrecioAsociado;
					});
					setCurrentDataTable(newMinimalRows);
					setResultsTableSearch(newMinimalRows);
					successRemoveItem();
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			// filter in the array data the id selected and slice it
			const newMinimalRows = currentDataTable.filter((et) => {
				return et.idActividadAsociada.value !== row.idActividadAsociada.value;
			});
			setDataTable(newMinimalRows);
			setCurrentDataTable(newMinimalRows);
			setResultsTableSearch(newMinimalRows);
			successRemoveItem();
		}
		closeDialog();
	};

	const handleEdit = (row) => {
		handleOpen();
		setModalData(row);
	};

	return (
		<TableRow key={i}>
			<TableCell>{item?.idTipoServicio?.label}</TableCell>
			<TableCell>{item?.idActividadAsociada?.label}</TableCell>
			<TableCell>{item?.idUnidadMedida?.label}</TableCell>
			<TableCell>{item?.valor}</TableCell>
			<TableCell>{item?.impuesto}</TableCell>

			<BtnActions
				item={item}
				rowState={rowState}
				setDialog={setDialog}
				handleEdit={isEdit ? handleEdit : undefined}
				handleDelete={handleDelete}
			/>
		</TableRow>
	);
};

export default RowAssociatePrices;
