import useAxios from 'hooks/useAxios';
import React, { useRef, useState } from 'react';
// Import Contexts
// Import Hooks
import useLangv2 from 'hooks/useLangv2';
// Import Components
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import HandleOnError from 'common/validators/HandleOnError';
// Import Libs
import dateFormat from 'dateformat';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-responsive-modal';
import Swal from 'sweetalert2';
// Import Services
import { useSeachContext } from 'context/SearchContext';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UploadFile = ({ currentZone }) => {
	const { endpoints, RequestUseCase } = useAxios();
	// Modal config
	const [errorList, setErrorList] = useState(false);
	const onOpenErrorList = () => setErrorList(true);
	const onCloseErrorList = () => setErrorList(false);
	// load error list data
	const [errorData, setErrorData] = useState([]);

	const inputFile = useRef(null);
	const { setDataTable = [], setSearchResults } = useSeachContext();
	const { formatterText, errorDataRecovery } = useLangv2();

	const showRestrictions = () => {
		let lastRestrict = '';
		switch (currentZone) {
			case formatterText('tab.title.countries'):
				lastRestrict = formatterText('file.upload.structure.1');
				break;
			case formatterText('titles.page.name.dinamic.table.departamentos'):
				lastRestrict = formatterText('file.upload.structure.2');
				break;
			case formatterText('tab.title.municipalities'):
				lastRestrict = formatterText('file.upload.structure.3');
				break;
			case formatterText('tab.title.sectors'):
				lastRestrict = formatterText('file.upload.structure.4');
				break;
			case 'precios':
				lastRestrict = formatterText('file.upload.structure.5');
				break;
			default:
				break;
		}

		const restrictions = `
    ${formatterText('file.upload.system.update.info')}
    ${formatterText('file.upload.restriction.1')}
    ${formatterText('file.upload.restriction.2')}
    3). ${lastRestrict}
    `;

		Swal.fire({
			title: formatterText('file.upload.attention'),
			icon: 'question',
			showCancelButton: true,
			html: `
        <p>${restrictions}</p>
        <br/>
        <button onclick="document.getElementById('file-product').click();" >${formatterText(
					'file.upload.choose.file'
				)}</button>
        <label id="lbl-file-product" for="file-product">
          ${formatterText('file.upload.not.choose.file')}
        </label>
        <br/>
        <input type="file" 
              onchange="document.getElementById('lbl-file-product').innerHTML = this.files.length > 0 ? this.files[0]?.name : '${formatterText(
								'file.upload.not.choose.file'
							)}';"
              style="display: none" 
              id="file-product" 
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
      `,
			confirmButtonText: formatterText('file.upload.upload.file'),
			cancelButtonText: formatterText('file.upload.cancel'),
			allowOutsideClick: false
		}).then((result) => {
			if (result.value) {
				const fileSelected = document.getElementById('file-product').files[0];
				uploadExcel(fileSelected);
			} else {
				OnMessage('warning', formatterText('file.upload.cancel.upload'));
			}
		});
	};

	const uploadExcel = (file) => {
		const TYPE_FILE = {
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': true
		};
		const fileSize = file.size;
		// limita el tamaño del archivo a 5MB
		if (fileSize < 5000000) {
			const typeFile = file.type;
			if (TYPE_FILE[typeFile]) {
				// send the file to the upload function
				const formData = new FormData();
				formData.append('file', file);
				setIsLoading(true);
				OnMessage('info', formatterText('file.upload.uploading'));
				switch (currentZone) {
					case formatterText('tab.title.countries'):
						postContriesExcel(formData);
						break;
					case formatterText('titles.page.name.dinamic.table.departamentos'):
						postDepartmentsExcel(formData);
						break;
					case formatterText('tab.title.municipalities'):
						postMunicipalitiesExcel(formData);
						break;
					case formatterText('tab.title.sectors'):
						postSectorsExcel(formData);
						break;
					default:
						console.log('No corresponde a ninguna zona');
						break;
				}
			} else {
				HandleOnError(formatterText('file.upload.invalid.file.type'));
			}
		} else {
			HandleOnError(formatterText('file.upload.file.too.large'));
		}
	};

	const postContriesExcel = (body) => {
		RequestUseCase.postFormData(endpoints.zones.uploadPaises, body)
			.then((res) => {
				if (res.length === 0) {
					OnMessage('success', formatterText('file.upload.success'));
					refreshCountryTable();
				} else {
					showErrorList(res);
				}

				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				showError(err.request);
			});
	};

	const postDepartmentsExcel = (body) => {
		RequestUseCase.postFormData(endpoints.zones.uploadDepartments, body)
			.then((res) => {
				if (res.length === 0) {
					OnMessage('success', formatterText('file.upload.success'));
					refreshDeparmentTable();
				} else {
					showErrorList(res);
				}

				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				showError(err.request);
			});
	};

	const postMunicipalitiesExcel = (body) => {
		RequestUseCase.postFormData(endpoints.zones.uploadMunicipalities, body)
			.then((res) => {
				if (res.length === 0) {
					OnMessage('success', formatterText('file.upload.success'));
					refreshMunicipalitiesTable();
				} else {
					showErrorList(res);
				}

				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				showError(err.request);
			});
	};

	const postSectorsExcel = (body) => {
		RequestUseCase.postFormData(endpoints.zones.uploadSectors, body)
			.then((res) => {
				if (res.length === 0) {
					OnMessage('success', formatterText('file.upload.success'));
					refreshSectorsTable();
				} else {
					showErrorList(res);
				}

				setIsLoading(false);
			})
			.catch((response) => {
				setIsLoading(false);
				showError(response.request);
			});
	};

	// get all countries
	const refreshCountryTable = () => {
		RequestUseCase.get(endpoints.zones.getAllCountries)
			.then((data) => {
				const newArray = [];
				data.forEach((item) =>
					newArray.push({
						codigoPais: item.codigoPais,
						nombre: item.nombrePais,
						prefijo: item.prefijo,
						estado: item.estado,
						fechaCreacion: dateFormat(item.fechaCreacion, 'yyyy/mm/dd - h:MM:ss TT'),
						fechaModificacion: dateFormat(item.fechaModificacion, 'yyyy/mm/dd - h:MM:ss TT'),
						id: item.idPais,
						fc: item.fechaCreacion,
						uc: item.usuarioCreacion
					})
				);
				setDataTable(newArray);
				setSearchResults(newArray);
			})
			.catch(() => errorDataRecovery());
	};

	// get all deparments
	const refreshDeparmentTable = () => {
		RequestUseCase.get(endpoints.zones.getAllDepartments)
			.then((data) => {
				const newArray = [];
				data.forEach((item) =>
					newArray.push({
						codigoDepartamento: item.codigoDepartamento,
						nombre: item.nombre,
						codigoPais: item.idPais.codigoPais,
						estado: item.estado,
						fechaCreacion: dateFormat(item.fechaCreacion, 'yyyy/mm/dd - h:MM:ss TT'),
						fechaModificacion: dateFormat(item.fechaModificacion, 'yyyy/mm/dd - h:MM:ss TT'),
						id: item.idDepartamento,
						objeto: item.idPais,
						usuarioCreacion: item.usuarioCreacion,
						fc: item.fechaCreacion,
						uc: item.usuarioCreacion
					})
				);
				setDataTable(newArray);
				setSearchResults(newArray);
			})
			.catch(() => errorDataRecovery());
	};

	// get all municipalities
	const refreshMunicipalitiesTable = () => {
		RequestUseCase.get(endpoints.zones.getAllMunicipalities)
			.then((data) => {
				console.log(data);
				const newArray = [];
				data.forEach((item) =>
					newArray.push({
						codigoMunicipio: item.codigoMunicipio,
						nombre: item.nombre,
						prefijo: item.prefijo,
						estado: item.estado,
						kilometros: item.kilometros,
						codigoDepartamento: item.idDepartamento.codigoDepartamento,
						nombrePais: item.idDepartamento.idPais.nombrePais,
						fechaCreacion: dateFormat(item.fechaCreacion, 'yyyy/mm/dd - h:MM:ss TT'),
						fechaModificacion: dateFormat(item.fechaModificacion, 'yyyy/mm/dd - h:MM:ss TT'),
						zonaHorariaGmt: item.zonaHorariaGmt,
						id: item.idMunicipio,
						objeto: item.idDepartamento,
						usuarioCreacion: item.usuarioCreacion,
						fc: item.fechaCreacion,
						uc: item.usuarioCreacion
					})
				);
				setDataTable(newArray);
				setSearchResults(newArray);
			})
			.catch(() => errorDataRecovery());
	};

	// get all sectors
	const refreshSectorsTable = () => {
		RequestUseCase.get(endpoints.zones.getAllSectors)
			.then((data) => {
				const newArray = [];
				data.forEach((item) =>
					newArray.push({
						codigoSector: item.codigoSector,
						nombre: item.nombre,
						codigoMunicipio: item.idMunicipio.codigoMunicipio,
						nombreDepartamento: item.idMunicipio.idDepartamento.nombre,
						nombrePais: item.idMunicipio.idDepartamento.idPais.nombrePais,
						estado: item.estado,
						kilometros: item.kilometros,
						fechaCreacion: dateFormat(item.fechaCreacion, 'yyyy/mm/dd - h:MM:ss TT'),
						fechaModificacion: dateFormat(item.fechaModificacion, 'yyyy/mm/dd - h:MM:ss TT'),
						id: item.idSector,
						objeto: item.idMunicipio,
						usuarioCreacion: item.usuarioCreacion,
						fc: item.fechaCreacion,
						uc: item.usuarioCreacion
					})
				);

				setDataTable(newArray);
				setSearchResults(newArray);
			})
			.catch(() => errorDataRecovery());
	};

	const showError = (response) => {
		const msg = formatterText('file.upload.error');
		if (response.status === 409) {
			OnMessage(
				'error',
				msg.concat(response.responseText)
				// `No se pudo subir el archivo: ${response.responseText}`
			);
		} else {
			OnMessage(
				'error',
				// "No se pudo subir el archivo, revisa que la información de las columnas estén correctas"
				msg.concat(formatterText('file.upload.column.info'))
			);
			console.log('err', response);
		}
	};

	const OnMessage = (typeAlert, messageAlert) => {
		setType(typeAlert);
		setMessage(messageAlert);
		handleClick();
	};

	/* Snackbar status */
	const [open, setOpen] = useState(false);
	const [type, setType] = useState('success');
	const [message, setMessage] = useState('Default');
	const [isLoading, setIsLoading] = useState(false);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const showErrorList = (response) => {
		OnMessage('error', formatterText('file.upload.error'));
		setErrorData(response);
		onOpenErrorList();
	};

	const LIST_ERROR = {
		1: formatterText('error.alert.excel.case.1', 'Caracteres especiales en el nombre'),
		2: formatterText('error.alert.excel.case.2', 'Daño general en el archivo'),
		3: formatterText('error.alert.excel.case.3', 'No existe el País'),
		4: formatterText('error.alert.excel.case.4', 'No existe el Departamento'),
		5: formatterText('error.alert.excel.case.5', 'No existe el Municipio')
	};

	return (
		<>
			<button
				onClick={showRestrictions}
				className={isLoading ? 'btn-upload btn-loading' : 'btn-upload'}
			>
				<FormattedMessage id={isLoading ? 'text.uploading' : 'text.uploadexcel'} />
				{!isLoading && <FormattedMessage id={currentZone} />}
				<input
					type="file"
					title=" "
					className="disabledInput"
					onChange={uploadExcel}
					ref={inputFile}
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					id="file"
				/>
			</button>

			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
					{message}
				</Alert>
			</Snackbar>
			{/* Error list modal */}
			<Modal
				open={errorList}
				onClose={onCloseErrorList}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				{errorData.map((el, i) => {
					return (
						<section key={i} className="d-flex">
							<span className="text-inline primary-green">
								<FormattedMessage id="text.row.zone" defaultMessage="Fila" />:{el.fila}
							</span>
							<p>{LIST_ERROR[el.idError] || 'NOT FOUND'}</p>
							<span>{`: ${el.error}`}</span>
						</section>
					);
				})}
			</Modal>
		</>
	);
};

export default UploadFile;
