import { MODULES_NAME } from 'common/validators/PermissionsConstants';
import { usePermissionContext } from 'context/PermissionContext';
import { useSeachContext } from 'context/SearchContext';
import useLangv2 from 'hooks/useLangv2';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import paths from 'services/paths';
import 'styles/tab.css';
import Countries from '../tabs/Countries';
import Departments from '../tabs/Departments';
import Municipalities from '../tabs/Municipalities';
import Sectors from '../tabs/Sectors';

export default function ZonesViewModel() {
	// use Hook of language v2

	const { searchResults = [], setSearch } = useSeachContext();
	const { formatterText } = useLangv2();
	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();

	const permissionsAccessGaranted = () => {
		permissionsAccess(MODULES_NAME.zoneCatalog.principal);
	};

	const tabs = useMemo(
		() => [
			{
				id: 0,
				nombre: <FormattedMessage id="tab.title.countries" defaultMessage="Países" />,
				names: 'Países',
				route: null,
				componente: <Countries />,
				exportExcel: permittedActions.exportar,
				uploadExcel: permittedActions.importar,
				idPermiso: MODULES_NAME.zoneCatalog.countries
			},
			{
				id: 1,
				nombre: (
					<FormattedMessage
						id="titles.page.name.dinamic.table.departamentos"
						defaultMessage="Departamentos"
					/>
				),
				names: 'Departamentos',
				route: null,
				componente: <Departments />,
				exportExcel: permittedActions.exportar,
				uploadExcel: permittedActions.importar,
				idPermiso: MODULES_NAME.zoneCatalog.states
			},
			{
				id: 2,
				nombre: <FormattedMessage id="tab.title.municipalities" defaultMessage="Municipios" />,
				names: 'Municipios',
				route: null,
				componente: <Municipalities />,
				exportExcel: permittedActions.exportar,
				uploadExcel: permittedActions.importar,
				idPermiso: MODULES_NAME.zoneCatalog.cities
			},
			{
				id: 3,
				nombre: <FormattedMessage id="tab.title.sectors" defaultMessage="Sectores" />,
				names: 'Sectores',
				route: paths.createSector,
				nombreLink: <FormattedMessage id="btn.create.sector" defaultMessage="Crear Sector" />,
				componente: (
					<Sectors canDeleted={permittedActions.eliminar} canModify={permittedActions.editar} />
				),
				create: permittedActions.crear,
				exportExcel: permittedActions.exportar,
				uploadExcel: permittedActions.importar,
				idPermiso: MODULES_NAME.zoneCatalog.sectors
			}
		],
		[permittedActions]
	);

	// Select tab
	const [selectedTab, setSelectedTab] = useState(tabs[Number(localStorage.getItem('Zones')) || 0]);

	// Index Tab
	const [indexTabSectors] = useState(Number(localStorage.getItem('Zones')) || 0);

	useEffect(() => {
		setSearch('');
	}, [selectedTab]);

	useEffect(() => {
		permissionsAccessGaranted();
	}, [permissions]);

	useEffect(() => {
		const selectedTab = tabs[parseInt(JSON.parse(localStorage.getItem('indexTabSectors'))) || 0];
		setSelectedTab(selectedTab);
	}, [permittedActions]);
	return {
		tabs,
		setSelectedTab,
		selectedTab,
		indexTabSectors,
		searchResults,
		formatterText,
		permittedActions
	};
}
