import React from 'react';
import { SearchWrapper } from 'context/SearchContext';
import { PermissionWrapper } from 'context/PermissionContext';
import UsersTableComponent from './UsersTable.component';

const UsersTable = () => {
	return (
		<SearchWrapper>
			<PermissionWrapper>
				<UsersTableComponent />
			</PermissionWrapper>
		</SearchWrapper>
	);
};

export default UsersTable;
