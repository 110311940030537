import React from 'react';
import PropTypes from 'prop-types';
import { useSeachContext } from 'context/SearchContext';
import 'styles/rolSelector.css';
import Search from './Search';

const PermissionSelector = ({ handleAdd, sector = false }) => {
	const { searchResults = [] } = useSeachContext();

	return (
		<div className="searchRol">
			<Search width="100%" />
			<table className="scroll">
				<tbody>
					{searchResults.map((rol, index) => (
						<tr key={index}>
							<button
								onClick={(e) => {
									e.preventDefault();
									handleAdd(rol);
								}}
							>
								{sector
									? rol.codigoMunicipio + ' - ' + rol.nombre + ' - ' + rol.idDepartamento.nombre
									: rol.idModulo.nombre + ' - ' + rol.idPermiso.nombre}
							</button>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

PermissionSelector.propTypes = {
	handleAdd: PropTypes.func,
	sector: PropTypes.bool
};

export default PermissionSelector;
