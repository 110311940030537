import React from 'react';
// Mui Cell row style
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
// Import Libs
import { useSeachContext } from 'context/SearchContext';
import useGetData from 'hooks/useGetData';
import { MdDelete } from 'react-icons/md';

const RowBusinessUnit = ({ item, key }) => {
	const { toggleLoading } = useGetData();
	const { dataTableBusinessUnit, setDataTableBusinessUnit } = useSeachContext();

	const handleDelete = (item) => {
		toggleLoading(true);
		const newData = dataTableBusinessUnit.filter((unit) => {
			return unit !== item;
		});
		setDataTableBusinessUnit(newData);
		toggleLoading(false);
	};

	return item.nombre ? (
		<TableRow key={key}>
			<TableCell>{item.nombre}</TableCell>
			<TableCell>
				<MdDelete size={25} color="gray" cursor="pointer" onClick={() => handleDelete(item)} />
			</TableCell>
		</TableRow>
	) : (
		''
	);
};

export default RowBusinessUnit;
