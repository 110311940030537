import useAxios from 'hooks/useAxios';
import React from 'react';
// Import Contexts
// Import Hooks
import useLangv2 from 'hooks/useLangv2';
import useProgress from 'hooks/useProgress';
import { useNavigate } from 'react-router-dom';
// Import Libs
import { FormattedMessage } from 'react-intl';
// Import Services
import endpoints from 'services/api';
import paths from 'services/paths';

export default function ModalSearch({ onClose, serviceId, permissions, auxTechnical = false }) {
	const { RequestUseCase } = useAxios();

	const navigate = useNavigate();

	// Helps to loading data
	const { loadingProgress, setLoadingProgress, DisplayProgress } = useProgress();

	// use Hook of language v2
	const { formatterText, errorProcess, showSB } = useLangv2();

	// const { setSelectNotificacion } = useContext(AppContext);

	const searchManual = () => {
		if (permissions.manual) {
			localStorage.setItem('idService', serviceId);
			// setSelectNotificacion(notificacion);
			if (auxTechnical) location.href = `#${paths.auxTech}`;
			else navigate(paths.searchPrincialTechnical.replace(':id', serviceId));
		} else {
			showSB('warning', 'No tiene permisos para realizar esta acción');
		}

		/*  getAll(endpoints.notifications.getAllNotifications).then((res) => {
      let data = [];
      if (serviceId) {
        data = res.filter((item) => item.idServicio === serviceId);
        onClose();
        // crear una promesa para que espere a que se ejecute la función
        // y luego redirija a la página de notificaciones
        Promise.resolve(setSelectNotificacion(data[0])).then(() => {
          navigate(`/buscar-tecnicos/${serviceId}`);
        });
      } else {
        console.log("No hay servicio seleccionado");
      }
    }); */
	};

	const searchAutomatic = () => {
		if (permissions.automatic) {
			setLoadingProgress(true);
			RequestUseCase.get(endpoints.services.getAutoSearchServices(serviceId))
				.then(() => {
					/*    const TECH_NUM = res.data.listaTecnicos.length; */
					const PART1 = formatterText('snackbar.automatic.tech.service.p1', 'Se notificaron los ');
					const PART2 = formatterText('snackbar.automatic.tech.service.p2', 'tecnico(s)');
					const MESSAGE = `${PART1}${PART2}`;
					localStorage.setItem('idService', serviceId);
					showSB('success', MESSAGE);
					onClose();
					if (auxTechnical) navigate(paths.auxTech);
					else navigate(paths.searchPrincialTechnical.replace(':id', serviceId));
					setLoadingProgress(false);
				})
				.catch(() => {
					setLoadingProgress(false);
					errorProcess();
				});
		} else {
			showSB('warning', 'No tiene permisos para realizar esta acción');
		}
	};

	return (
		<>
			<form className="w100-container">
				<section className="">
					<section className="container-search-modals">
						<h3
							className="p-text-primary"
							style={{
								fontSize: '1.5rem',
								padding: '2rem'
							}}
						>
							<FormattedMessage
								id="title.action.question.service"
								defaultMessage="¿Desea realizar la búsqueda del técnico de manera automática o manual?"
							/>
						</h3>
					</section>

					<section className="footer-grid-buttons">
						{loadingProgress ? (
							<DisplayProgress />
						) : (
							<>
								<button
									className="btn-action-primary"
									onClick={(e) => {
										e.preventDefault();
										searchAutomatic();
									}}
								>
									<FormattedMessage id="btn.automatic" defaultMessage="Automática" />
								</button>
								<button
									className="btn-action-primary"
									onClick={(e) => {
										e.preventDefault();
										searchManual();
									}}
								>
									<FormattedMessage id="btn.manual" defaultMessage="Manual" />
								</button>
							</>
						)}
					</section>
				</section>
			</form>
		</>
	);
}
