import useLangv2 from 'hooks/useLangv2';
import React from 'react';

const BtnAssignSearch = ({ handleSelect }) => {
	const { formatterText } = useLangv2();
	return (
		<td className="minimal-table__actions" align="center">
			<div className="btn-action-select-tech" onClick={(item) => handleSelect(item)}>
				<p>{formatterText('title.service.create.select')}</p>
			</div>
		</td>
	);
};

export default BtnAssignSearch;
