import React from 'react';
import { SearchWrapper } from 'context/SearchContext';
import { PermissionWrapper } from 'context/PermissionContext';
import RolsTableComponent from './RolsTable.component';

export default function RolsTable(props = {}) {
	return (
		<SearchWrapper>
			<PermissionWrapper>
				<RolsTableComponent {...props} />
			</PermissionWrapper>
		</SearchWrapper>
	);
}
