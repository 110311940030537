import React from 'react';
// Import Hooks
import { formatterText } from 'hooks/useLangv2';
import { useField } from '@formiz/core';
// Import Components
import currencyFormat from 'common/utils/currencyFormat';
import rawCurrency from 'common/utils/rawCurrency';
import {
	ADDRESSREGEX,
	ALPHANUMERICREGEX,
	CODEREGEX,
	IDREGEX,
	NUMBERREGEX,
	PRICEREGEX,
	SKUREGEX,
	TEXTREGEX,
	TEXTREGEXWITHPOINTS,
	URLREGEX,
	SPACING,
	TEXTREGEXMIN,
	TEXTONLYONESPACEREGEX
} from 'common/validators/Regex';

export const InputFieldResponsive = (props) => {
	const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(props);
	const { type, name, labelText, placeholder, validateInput, disabled, required, styleName } =
		props;
	const [isTouched, setIsTouched] = React.useState(false);
	const showError = !isValid && (isTouched || isSubmitted);

	const validateRegex = (value, regex, e = null) => {
		const isMatch = value.match(regex);
		if (isMatch) {
			setValue(value);
			onChangeLocalValue(e);
		}
	};

	const handleChange = (e) => {
		const target = e;
		const { name = '', value = '' } = e.target;
		const valueWithoutSpaces = (value || '').replaceAll(/\s{2,}/gi, ' ');

		switch (validateInput) {
			case 'number':
				validateRegex(valueWithoutSpaces, PRICEREGEX, target);
				break;
			case 'identificacion':
				validateRegex(valueWithoutSpaces, IDREGEX, target);
				break;
			case 'text':
				if (/sku/gi.test(name)) validateRegex(valueWithoutSpaces?.toLowerCase(), SKUREGEX, target);
				else if (/descripcion/gi.test(name)) {
					setValue(e.target.value);
					onChangeLocalValue(target);
				}
				else validateRegex(valueWithoutSpaces, TEXTREGEX, target);
				break;
			case 'web':
				validateRegex(valueWithoutSpaces, URLREGEX, target);
				break;
			case 'integer':
				validateRegex(valueWithoutSpaces, NUMBERREGEX, target);
				break;
			case 'address':
				validateRegex(valueWithoutSpaces, ADDRESSREGEX, target);
				break;
			case 'alphanumber':
				validateRegex(valueWithoutSpaces, ALPHANUMERICREGEX, target);
				break;
			case 'user':
				validateRegex(valueWithoutSpaces, TEXTREGEXWITHPOINTS, target);
				break;
			case 'code':
				validateRegex(valueWithoutSpaces?.toLowerCase(), CODEREGEX, target);
				break;
			case 'namestage':
				validateRegex(e.target.value, SPACING, target);
				break;
			case 'codestage':
				validateRegex(e.target.value, TEXTREGEXMIN, target);
				break;
			case 'nombreAsesor':
				validateRegex(e.target.value, TEXTONLYONESPACEREGEX, target);
				break;
			case 'sku':
				validateRegex(valueWithoutSpaces?.toLowerCase(), SKUREGEX, target);
				break;
			default:
				onChangeLocalValue(target);
				setValue(e.target.value);
				break;
		}
	};


	const onChangeLocalValue = (e) => {
		if(props?.onChangeLocalValue){
			props?.onChangeLocalValue(e);
		}
	};

	return (
		<label className="d-flex">
			<span className="text-inline">{labelText}</span>
			<section className="w100-container">
				{props.area === undefined ? (
					<input
						id={id}
						type={type || 'text'}
						value={value || ''}
						name={name}
						onChange={handleChange}
						onBlur={() => {
							setIsTouched(true);
							if (validateInput === 'number') {
								setValue(currencyFormat(value));
							}
						}}
						onFocus={() => {
							if (validateInput === 'number') {
								if (value) {
									setValue(rawCurrency(value));
								}
							}
						}}
						placeholder={placeholder}
						className={styleName || 'input-primary'}
						aria-invalid={showError}
						aria-required={!!required}
						aria-describedby={showError ? `${id}-error` : null}
						disabled={disabled}
						min={props.min}
						maxLength={props.maxLength}
						onPaste={(e) => {
							e.preventDefault();
							return false;
						}}
						autoComplete={props.active}
					/>
				) : (
					<textarea
						id={id}
						//type={type || "text"}
						value={value || ''}
						name={name}
						onChange={handleChange}
						onBlur={() => setIsTouched(true)}
						placeholder={placeholder}
						className={styleName || 'input-textarea-sm'}
						aria-invalid={showError}
						aria-required={!!required}
						aria-describedby={showError ? `${id}-error` : null}
						disabled={disabled}
						rows={props.row}
						cols={props.cols}
						style={{
							resize: 'none',
							borderRadius: '5px',
							boxShadow: '0px 4px 8px rgba(0, 174, 142, 0.2)',
							padding: '1rem',
							display: 'block',
							marginTop: '10px'
						}}
					/>
				)}
				{(showError)&& (
					<div style={{ marginTop: '3px' }}>
						<span id={`${id}-error`} className="error-msg">
							{formatterText(errorMessage)}
						</span>
					</div>
				)}
			</section>
		</label>
	);
};
