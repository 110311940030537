import Cookie from 'js-cookie';
import endpoints from './index';
import RequestUseCase from './useCase/RequestUseCase';

const getProfileImage = async () => {
	const bodyFind = {
		idOrigen: parseInt(Cookie.get('idUsuario')),
		idTipoOrigenArchivo: 4
	};
	let files = [];
	let bodyDownload = {};
	let extensionArchivo = '';
	let data = {};
	try {
		const response = await RequestUseCase.get(endpoints.UploadFiles.findArchivosById(bodyFind));
		files = response.map((file) => {
			return {
				name: file,
				url: file
			};
		});
		bodyDownload = { file: files[files.length - 1]?.name || 'file' };
		extensionArchivo = (files[files.length - 1]?.name || 'file').substring(
			(files[files.length - 1]?.name || 'file').lastIndexOf('.')
		);
		const responseImageDownload = await RequestUseCase.post(
			endpoints.UploadFiles.download,
			bodyDownload
		);
		data = {
			name: files[files.length - 1].name,
			ext: extensionArchivo.replace('.', ''),
			image: responseImageDownload
		};
	} catch (error) {
		console.log('error', error);
	}
	return data;
};

export { getProfileImage };

