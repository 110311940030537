import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import User from 'models/User';
import profileDefault from 'assets/profile-default.jpg';

export const AppContext = createContext();

export function AppWrapper({ children }) {
	const [userDataValue, setUserData] = useState(new User());
	const [notificacionesValue, setNotificacionesValue] = useState(43);
	const [selectNotificacion, setSelectNotificacion] = useState({});
	const [alertasValue, setAlertasValue] = useState(12);
	const [imagUrlValue, setImgUrlValue] = useState(userDataValue?.avatarUrl || profileDefault);
	const [hours, setHours] = useState([]);
	const [date, setDate] = useState([]);
	const [readyUpdate, setReadyUpdate] = useState(false);

	// this state is for externalUser in Create and Update Services (CreateServices.jsx) user generator
	const [externalUserSelected, setExternalUserSelected] = useState({
		nombre: 'placeholder.select.user',
		id: null
	});
	const [billingUserSelected, setBillingUserSelected] = useState({
		nombre: 'placeholder.select.user.opt',
		id: null
	});
	// this state is for the client in Create and Update Services (CreateServices.jsx) client generator
	const [clientSelected, setClientSelected] = useState({
		nombre: 'input.placeholder.select.client',
		id: null,
		esVip: 2
	});

	// this is for the user in Create and Update Services (CreateServices.jsx) user system
	const [userSelected, setUserSelected] = useState({
		nombre: 'input.placeholder.select.generator.user',
		id: null,
		esVip: 2
	});
	// state of the time zone selected in Create and Update Schedule
	// const [timeZoneSelected, setTimeZoneSelected] = useState([]);
	// state to create new TimeZoneAgendas
	const [createNewTimeZoneAgendas, setCreateNewTimeZoneAgendas] = useState([]);
	// state of open modal technicak to assign
	const [openModalTechnicalToAssign, setOpenModalTechnicalToAssign] = useState(false);
	// state of open modal technicak to review
	const [openModalTechnicalToReview, setOpenModalTechnicalToReview] = useState(false);
	// this state is for the screen RowTechnicalNoAssign.jsx to save the data of the technicals to assign, this help to knows the status of the technicals (this is the modal data of no tech selected)
	const [technicalsToAssing, setTechnicalsToAssing] = useState([]);
	// state to save the tehcnicals to assign in the time zone selected
	const [techSelectToAssign, setTechSelectToAssign] = useState([]);
	// state of the time Zone
	const [selectTimeZoneId, setSelectTimeZoneId] = useState(0);
	// update the technicals to wants to assign
	const [dataTableTechnicalAssigned, setDataTableTechnicalAssigned] = useState([]);
	const [objectPrecaution, setObjectPrecaution] = useState({});
	const [openModalEditPrecaution, setOpenModalEditPrecaution] = useState(false);

	const [MoreInfoTimeZone, setMoreInfoTimeZone] = useState([
		'68N;;68;;68;;68;;68',
		'68A;;68;;68;;68;;68',
		'68A;;68;;68;;68;;68',
		'68I;;68;;68;;68;;68'
	]);

	const [idBillingService, setIdBillingService] = useState(0);

	const [agendaCloneData, setAgendaCloneData] = useState({
		agendaName: 'DEFAULT'
	});

	const [serviceCloneData, setServiceCloneData] = useState({
		idServicio: null,
		idGeneradorCliente: null,
		idGeneradorUsuario: null,
		idUsuario: null,
		idDireccion: {
			idDireccion: 1
		},
		idTipoServicio: {
			idTipoServicio: 6
		},
		descripcion: 'Default',
		idMedioComunicacion: {
			idMedioComunicacion: 1
		},
		idCanalComunicacion: {
			idCanalComunicacion: 1
		},
		fechaCita: '',
		idHoraCita: {
			idHora: 20
		},
		expediente: null,
		especial: 0,
		idEstadoServicio: {
			idEstadoServicio: 1
		},
		usuarioCreacion: 3,
		idServicioPadre: null
	});
	// state of services data
	const [servicesData, setServicesData] = useState([]);
	// state of init activity
	const [initActivity, setInitActivity] = useState(false);
	// id service state status
	const [idEstadoServicio, setIdEstadoServicio] = useState(0);
	// state of activities services
	const [listActivities, setListActivities] = useState([]);

	return (
		<AppContext.Provider
			value={{
				userInfo: [userDataValue, setUserData],
				notificaciones: [notificacionesValue, setNotificacionesValue],
				selectNotificacion,
				setSelectNotificacion,
				alertas: [alertasValue, setAlertasValue],
				imagUrlValue,
				setImgUrlValue,
				externalUserSelected,
				setExternalUserSelected,
				billingUserSelected,
				setBillingUserSelected,
				clientSelected,
				setClientSelected,
				userSelected,
				setUserSelected,
				// timeZoneSelected,
				// setTimeZoneSelected,
				createNewTimeZoneAgendas,
				setCreateNewTimeZoneAgendas,
				openModalTechnicalToAssign,
				setOpenModalTechnicalToAssign,
				openModalTechnicalToReview,
				setOpenModalTechnicalToReview,
				technicalsToAssing,
				setTechnicalsToAssing,
				selectTimeZoneId,
				setSelectTimeZoneId,
				techSelectToAssign,
				setTechSelectToAssign,
				dataTableTechnicalAssigned,
				setDataTableTechnicalAssigned,
				setObjectPrecaution,
				objectPrecaution,
				openModalEditPrecaution,
				setOpenModalEditPrecaution,
				MoreInfoTimeZone,
				setMoreInfoTimeZone,
				agendaCloneData,
				setAgendaCloneData,
				serviceCloneData,
				setServiceCloneData,
				servicesData,
				setServicesData,
				initActivity,
				setInitActivity,
				idEstadoServicio,
				setIdEstadoServicio,
				listActivities,
				setListActivities,
				hours,
				setHours,
				idBillingService,
				setIdBillingService,
				date,
				setDate,
				readyUpdate,
				setReadyUpdate
			}}
		>
			{children}
		</AppContext.Provider>
	);
}

AppWrapper.propTypes = {
	children: PropTypes.node
};
