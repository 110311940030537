// Inicio de sesión y recuperación
import ChangeFirstPassword from 'screens/auth/ChangeFirstPassword';
import Login from 'screens/auth/Login';
import RecoverPassword from 'screens/auth/RecoverPassword';
import RestablishPassword from 'screens/auth/RestablishPassword';
import Home from 'screens/home/Home';
// Layout
// Usuarios
import CreateUser from 'screens/admin/users/createUser';
import Edit from 'screens/admin/users/editUser';
import Users from 'screens/user/UsersTable';
/* Administrar Roles */
import CreateRol from 'screens/rols/CreateRol';
import EditRol from 'screens/rols/EditRol';
import Rol from 'screens/rols/RolsTable';
// Perfil de usuario
import ChangePassword from 'screens/user/ChangePassword';
import EditProfile from 'screens/user/EditProfile';
// Administrar correo
import CreateMail from 'screens/mail/CreateMail';
import SendMail from 'screens/mail/SendMail';
// Paths de la aplicación
import paths from 'services/paths';
// Contexto de la app
//Gestion de catalogo de zonas
import CreateSector from 'screens/zones/sectors/CreateSector';
import EditSector from 'screens/zones/sectors/EditSector';
import Zones from 'screens/zones/Zones';
// Gestión de auditorias
import Audits from 'screens/audits/Audits';
// Tipos archivos adjuntos
import CreateFile from 'screens/file/CreateFile';
import EditFile from 'screens/file/EditFile';
import File from 'screens/file/File';
// Cargos
import CreatePosition from 'screens/position/CreatePosition';
import Positions from 'screens/position/Position';
import UpdatePosition from 'screens/position/UpdatePosition';
// Gestión de tipo de contratos
import ContractType from 'screens/contract/ContractType';
import CreateContract from 'screens/contract/CreateContract';
import UpdateContract from 'screens/contract/UpdateContract';
// Empleados
import CreateEmployee from 'screens/employee/CreateEmployee';
import EditEmployee from 'screens/employee/EditEmployee';
import Employee from 'screens/employee/Employee';
// Terceros
import CreateThirdParty from 'screens/thirdParties/CreateThirdParties';
import ThirdParties from 'screens/thirdParties/ThirdParties';
import UpdateThirdParty from 'screens/thirdParties/UpdateThirdParties';
// Gestión de tecnicos
import CreateTechnical from 'screens/technical/technicals/CreateTechnical';
import UpdateTechnical from 'screens/technical/technicals/UpdateTechnical';
import TechnicalTable from 'screens/technical/TechnicalTable';
// Parámetros de cliente
//import Parameters from "screens/parameters/Parameters";
import ClientParametersTable from 'screens/parameters/ClientParametersTable';
// Market
import CreateMarket from 'screens/parameters/market/CreateMarket';
import UpdateMarket from 'screens/parameters/market/UpdateMarket';
// Business Unit
import CreateBusinessUnit from 'screens/parameters/businessUnits/CreateBusinessUnit';
import UpdateBusinessUnit from 'screens/parameters/businessUnits/UpdateBusinessUnit';
// Patameter
import CreateParameter from 'screens/parameters/thirdType/CreateParameter';
import UpdateParameter from 'screens/parameters/thirdType/UpdateParameter';
// Client Type
import CreateClientType from 'screens/parameters/clientType/CreateClientType';
import UpdateClientType from 'screens/parameters/clientType/UpdateClientType';
// Provider Type
import CreateProviderType from 'screens/parameters/providerType/CreateProviderType';
import UpdateProviderType from 'screens/parameters/providerType/UpdateProviderType';
//Billing Type
import CreateBillingType from 'screens/parameters/billingTypes/CreateBillingType';
import UpdateBillingType from 'screens/parameters/billingTypes/UpdateBillingType';
// Client
import Client from 'screens/client/Client';
import CreateClient from 'screens/client/CreateClient';
import UpdateClient from 'screens/client/UpdateClient';
// Products
import CreateProductCategory from 'screens/product/productCategory/CreateProductCategory';
import UpdateProductCategory from 'screens/product/productCategory/UpdateProductCategory';
import CreatreProduct from 'screens/product/products/CreateProduct';
import UpdateProduct from 'screens/product/products/UpdateProduct';
import ProductsTable from 'screens/product/ProductsTable';
// Providers
import CreateProvider from 'screens/providers/CreateProvider';
import ProviderPage from 'screens/providers/ProviderPage';
import UpdateProvider from 'screens/providers/UpdateProvider';
// Parameters services Services
import CreateActivity from 'screens/parameterServices/activities/CreateActivity';
import UpdateActivity from 'screens/parameterServices/activities/UpdateActivity';
import CreateCommunication from 'screens/parameterServices/communications/CreateCommunication';
import CreateForm from 'screens/parameterServices/form/CreateForm';
// deprecated by unused import FormDiligence from 'screens/parameterServices/form/FormDiligence';
import UpdateForm from 'screens/parameterServices/form/UpdateForm';
import CreateListPrice from 'screens/parameterServices/listPrices/CreateListPrice';
import UpdateListPrice from 'screens/parameterServices/listPrices/UpdateListPrice';
import ServicesTable from 'screens/parameterServices/ParametersServicesTable';
import CreateServiceCategory from 'screens/parameterServices/serviceCategory/CreateServiceCategory';
import UpdateServiceCategory from 'screens/parameterServices/serviceCategory/UpdateServiceCategory';
import CreateStage from 'screens/parameterServices/stageService/CreateStage';
import UpdateStage from 'screens/parameterServices/stageService/UpdateStage';
import CloneSchedule from 'screens/schedule/CloneSchedule';
import CreateSchedule from 'screens/schedule/CreateSchedule';
import TabScheduleSelect from 'screens/schedule/TabScheduleSelect';
import UpdateSchedule from 'screens/schedule/UpdateSchedule';
// Service screens
import CloneService from 'screens/services/CloneService';
import CreateService from 'screens/services/CreateService';
import UpdateService from 'screens/services/EditService';
import SearchPrincipalTechnical from 'screens/services/SearchPrincipalTechnical';
import SearchTechnicians from 'screens/services/SearchTechnicians';
import ServiceTable from 'screens/services/ServiceTable';

// External users
import CreateExternalUser from 'screens/externalUser/UserExternalInfo';
// Review Map
// Aux tech
import AuxTech from 'screens/services/SearchTechniciansAux';
// Panel Notifications
import invoice from 'screens/invoiceReconciliation/Invoice.component';
import PanelNotifications from 'screens/notificationsTable/container';
import GanttService from 'screens/services/GanttService';

export const ROUTER_LIST = [
	{
		path: paths.login,
		element: Login,
		private: false
	},
	{
		path: paths.recover,
		element: RecoverPassword,
		private: false
	},
	{
		path: paths.restablishPassword,
		element: RestablishPassword,
		private: false
	},
	{
		path: paths.changeFirstPassword,
		element: ChangeFirstPassword,
		private: false
	},
	{
		path: paths.home,
		element: Home,
		private: true
	},
	{
		path: paths.users,
		element: Users,
		private: true
	},
	{
		path: paths.updateUser,
		element: Edit,
		private: true
	},
	{
		path: paths.createUser,
		element: CreateUser,
		private: true
	},

	{
		path: paths.roles,
		element: Rol,
		private: true
	},
	{
		path: paths.createRol,
		element: CreateRol,
		private: true
	},
	{
		path: paths.updateRol,
		element: EditRol,
		private: true
	},
	{
		path: paths.updateProfile,
		element: EditProfile,
		private: true
	},
	{
		path: paths.changePassword,
		element: ChangePassword,
		private: true
	},
	{
		path: paths.createMail,
		element: CreateMail,
		private: true
	},
	{
		path: paths.sendMail,
		element: SendMail,
		private: true
	},
	{
		path: paths.zones,
		element: Zones,
		private: true
	},
	{
		path: paths.createSector,
		element: CreateSector,
		private: true
	},
	{
		path: paths.updateSector,
		element: EditSector,
		private: true
	},
	{
		path: paths.audits,
		element: Audits,
		private: true
	},
	{
		path: paths.files,
		element: File,
		private: true
	},
	{
		path: paths.createFile,
		element: CreateFile,
		private: true
	},
	{
		path: paths.updateFile,
		element: EditFile,
		private: true
	},
	{
		path: paths.positions,
		element: Positions,
		private: true
	},
	{
		path: paths.createPositions,
		element: CreatePosition,
		private: true
	},
	{
		path: paths.updatePositions,
		element: UpdatePosition,
		private: true
	},
	{
		path: paths.contracts,
		element: ContractType,
		private: true
	},
	{
		path: paths.createContract,
		element: CreateContract,
		private: true
	},
	{
		path: paths.updateContract,
		element: UpdateContract,
		private: true
	},
	{
		path: paths.updateContract,
		element: UpdateContract,
		private: true
	},
	{
		path: paths.employees,
		element: Employee,
		private: true
	},
	{
		path: paths.createEmployee,
		element: CreateEmployee,
		private: true
	},
	{
		path: paths.updateEmployee,
		element: EditEmployee,
		private: true
	},
	{
		path: paths.thirdParties,
		element: ThirdParties,
		private: true
	},

	{
		path: paths.createThirdParty,
		element: CreateThirdParty,
		private: true
	},
	{
		path: paths.updateThirdParty,
		element: UpdateThirdParty,
		private: true
	},
	{
		path: paths.technical,
		element: TechnicalTable,
		private: true
	},
	{
		path: paths.duplicateService,
		element: CloneService,
		private: true
	},
	{
		path: paths.schedule,
		element: TabScheduleSelect,
		private: true
	},
	{
		path: paths.createTechnical,
		element: CreateTechnical,
		private: true
	},
	{
		path: paths.updateTechnical,
		element: UpdateTechnical,
		private: true
	},
	{
		path: paths.parameters,
		element: ClientParametersTable,
		private: true
	},
	{
		path: paths.createMarket,
		element: CreateMarket,
		private: true
	},
	{
		path: paths.updateMarket,
		element: UpdateMarket,
		private: true
	},
	{
		path: paths.createBusinessUnit,
		element: CreateBusinessUnit,
		private: true
	},
	{
		path: paths.updateBusinessUnit,
		element: UpdateBusinessUnit,
		private: true
	},
	{
		path: paths.createProviderType,
		element: CreateProviderType,
		private: true
	},
	{
		path: paths.updateProviderType,
		element: UpdateProviderType,
		private: true
	},
	{
		path: paths.updateParameter,
		element: UpdateParameter,
		private: true
	},
	{
		path: paths.createParameter,
		element: CreateParameter,
		private: true
	},
	{
		path: paths.createClientType,
		element: CreateClientType,
		private: true
	},
	{
		path: paths.updateClientType,
		element: UpdateClientType,
		private: true
	},
	{
		path: paths.createBillingType,
		element: CreateBillingType,
		private: true
	},
	{
		path: paths.updateBillingType,
		element: UpdateBillingType,
		private: true
	},
	{
		path: paths.ganttServices,
		element: GanttService,
		private: true
	},
	{
		path: paths.clients,
		element: Client,
		private: true
	},
	{
		path: paths.createClient,
		element: CreateClient,
		private: true
	},
	{
		path: paths.products,
		element: ProductsTable,
		private: true
	},
	{
		path: paths.createProductCategory,
		element: CreateProductCategory,
		private: true
	},
	{
		path: paths.updateProductCategory,
		element: UpdateProductCategory,
		private: true
	},
	{
		path: paths.createProduct,
		element: CreatreProduct,
		private: true
	},
	{
		path: paths.updateProduct,
		element: UpdateProduct,
		private: true
	},
	{
		path: paths.providers,
		element: ProviderPage,
		private: true
	},
	{
		path: paths.createProvider,
		element: CreateProvider,
		private: true
	},
	{
		path: paths.updateProvider,
		element: UpdateProvider,
		private: true
	},
	{
		path: paths.services,
		element: ServicesTable,
		private: true
	},
	{
		path: paths.createCategoryService,
		element: CreateServiceCategory,
		private: true
	},
	{
		path: paths.updateCategoryService,
		element: UpdateServiceCategory,
		private: true
	},
	{
		path: paths.createStageService,
		element: CreateStage,
		private: true
	},
	{
		path: paths.updateStageService,
		element: UpdateStage,
		private: true
	},
	{
		path: paths.createActivityService,
		element: CreateActivity,
		private: true
	},
	{
		path: paths.updateActivityService,
		element: UpdateActivity,
		private: true
	},
	{
		path: paths.createListPriceService,
		element: CreateListPrice,
		private: true
	},
	{
		path: paths.updateListPriceService,
		element: UpdateListPrice,
		private: true
	},
	{
		path: paths.createCommunicationService,
		element: CreateCommunication,
		private: true
	},
	{
		path: paths.createFormService,
		element: CreateForm,
		private: true
	},
	{
		path: paths.updateFormService,
		element: UpdateForm,
		private: true
	},
	{
		path: paths.createSchedule,
		element: CreateSchedule,
		private: true
	},
	{
		path: paths.duplicateSchedule,
		element: CloneSchedule,
		private: true
	},
	{
		path: paths.updateSchedule,
		element: UpdateSchedule,
		private: true
	},
	{
		path: paths.updateClient,
		element: UpdateClient,
		private: true
	},
	{
		path: paths.serviceModule,
		element: ServiceTable,
		private: true
	},
	{
		path: paths.searchTechnicians,
		element: SearchTechnicians,
		private: true
	},
	{
		path: paths.searchPrincialTechnical,
		element: SearchPrincipalTechnical,
		private: true
	},
	{
		path: paths.createService,
		element: CreateService,
		private: true
	},
	{
		path: paths.updateService,
		element: UpdateService,
		private: true
	},
	{
		path: paths.createExternalUser,
		element: CreateExternalUser,
		private: true
	},
	{
		path: paths.auxTech,
		element: AuxTech,
		private: true
	},
	{
		path: paths.panelNotifications,
		element: PanelNotifications,
		private: true
	},
	{
		path: paths.invoice,
		element: invoice,
		private: true
	}
];
