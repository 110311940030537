import React from 'react';
// Import Services
import SelectorSearch from 'common/selects/SelectorSearch';
import { FormattedMessage } from 'react-intl';
import SelectorMulti from 'common/selects/SelectorMultiCreate';
import CreateExternalUserViewModel from './CreateExternalUser.viewModel';
import { CreateExternalUserProps } from './CreateExternalUser.props';

export default function CreateExternalUser({ setIsOpenExternalUser }) {
	const {
		formData,
		handleText,
		handleNumber,
		handleEmail,
		handleSubmit,
		units,
		loading,
		selectUnits,
		handleChangeUnits,
		listPrices,
		secondaryPrices,
		selectValues,
		selectorValues,
		auxData,
		setAuxData,
		handleChangeMulti,
		closeModal
	} = CreateExternalUserViewModel({ setIsOpenExternalUser });

	return (
		<form className="w100-container">
			<section className="padding-all-1">
				<p className="title-modal">Crear usuarios</p>
				<section className="grid-container-2c">
					<section className="d-flex">
						<h3 className="text-inline">Primer nombre</h3>
						<input
							className="input-default custom-margin-0010"
							type="text"
							name="primerNombre"
							value={formData.primerNombre}
							onChange={handleText}
							placeholder="Primer Nombre"
							maxLength="45"
							required
						/>
					</section>
					<section className="d-flex">
						<h3 className="text-inline">Segundo nombre</h3>
						<input
							className="input-default custom-margin-0010"
							type="text"
							name="segundoNombre"
							value={formData.segundoNombre}
							onChange={handleText}
							placeholder="Segundo Nombre"
							maxLength="45"
						/>
					</section>
					<section className="d-flex">
						<h3 className="text-inline">Primer apellido</h3>
						<input
							className="input-default custom-margin-0010"
							type="text"
							name="primerApellido"
							value={formData.primerApellido}
							onChange={handleText}
							placeholder="Primer Apellido"
							maxLength="45"
							required
						/>
					</section>
					<section className="d-flex">
						<h3 className="text-inline">Segundo apellido</h3>
						<input
							className="input-default custom-margin-0010"
							type="text"
							name="segundoApellido"
							value={formData.segundoApellido}
							onChange={handleText}
							placeholder="Segundo Apellido"
							maxLength="45"
						/>
					</section>
					<section className="d-flex">
						<h3 className="text-inline">Género</h3>
						<select
							className="input-default custom-margin-0010"
							name="idGenero"
							value={formData.idGenero}
							onChange={handleText}
							required
						>
							<option value="">Seleccione una opción</option>
							<option value="1">Masculino</option>
							<option value="2">Femenino</option>
						</select>
					</section>
					{/* aqui van las unidades */}
					<section className="d-flex">
						<h3 className="text-inline">Unidades de negocio</h3>
						<SelectorMulti
							data={units}
							isLoading={loading}
							dataValue={selectUnits}
							setterFunction={handleChangeUnits}
							required="La unidad de negocio es requerida"
						/>
					</section>
					<section className="d-flex">
						<h3 className="text-inline">Teléfono</h3>
						<input
							className="input-default custom-margin-0010"
							type="text"
							name="telefono"
							value={formData.telefono}
							onChange={handleNumber}
							placeholder="Ingresa  un teléfono válido"
							maxLength="15"
							required
						/>
					</section>
					<section className="d-flex">
						<h3 className="text-inline">Correo</h3>
						<input
							className="input-default custom-margin-0010"
							type="email"
							name="correo"
							value={formData.correo}
							onChange={handleEmail}
							placeholder="Ingresa  un correo válido"
							maxLength="100"
							required
						/>
					</section>
					<section className="d-flex">
						<h3 className="text-inline">Usuario especial</h3>
						<select
							className="input-default custom-margin-0010"
							name="usuarioEspecial"
							value={formData.usuarioEspecial}
							onChange={handleText}
							required
						>
							<option value="">Seleccione una opción</option>
							<option value="1">Si</option>
							<option value="0">No</option>
						</select>
					</section>

					<section className="d-flex">
						<h3 className="text-inline">Lista de precios primaria</h3>
						<SelectorSearch
							className="input-default custom-margin-0010"
							name="idListaPrecios"
							data={listPrices}
							placeholder={
								<FormattedMessage
									id="input.placeholder.select"
									defaultMessage="Selecione una opción"
								/>
							}
							dataValue={auxData.idListaPrecios}
							setterFunction={setAuxData}
							isLoading={loading}
							selectValue={selectorValues}
							isRequired={false}
						/>
					</section>

					<section className="d-flex">
						<h3 className="text-inline">Lista de precios secundaria</h3>
						<SelectorMulti
							data={secondaryPrices}
							isLoading={loading}
							dataValue={selectValues}
							setterFunction={handleChangeMulti}
						/>
					</section>
				</section>

				<section className="footer-grid-buttons">
					<button
						className="btn-action-primary"
						onClick={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
					>
						Crear usuario
					</button>
					<button
						type="button"
						className="btn-action-cancel"
						onClick={() => {
							// e.preventDefault();
							// window.close();
							closeModal();
							window.close();
						}}
					>
						Cerrar pestaña
					</button>
				</section>
			</section>

			{/* <button
        onClick={(e) => {
          e.preventDefault();
          getAllBusinessUnits3();
        }}
      >
        Llamar otra vez
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          cancelRequest();
        }}
      >
        Cancelar
      </button>
      {loading ? (
        <p>Cargando</p>
      ) : (
        <div>
          {data && data.map((i) => <p key={i.idUnidadNegocio}>{i.nombre}</p>)}
        </div>
      )} */}
		</form>
	);
}

CreateExternalUser.propTypes = CreateExternalUserProps;
