import DynamicTable from 'common/DynamicTable/DynamicHead';
import Search from 'common/Search';
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import paths from 'services/paths';
import PositionViewModel from './Position.viewModel';

function PositionComponent() {
	const {
		displayLoading,
		displayMessage,
		error,
		formatterText,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		loading,
		permittedActions,
		titles
	} = PositionViewModel();

	return (
		<>
			{!loading ? (
				<section className="table-container">
					<section className="userOptions">
						{permittedActions.consultar && (
							<Search
								placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
								width="50%"
							/>
						)}
						{permittedActions.crear && (
							<Link to={paths.createPositions}>
								<button className="btn-add">
									<FormattedMessage
										id="header.title.position.creates"
										defaultMessage="Crear Cargos"
									/>
								</button>
							</Link>
						)}
					</section>
					{permittedActions.consultar && (
						<FormattedMessage id="table.name.search.positions" defaultMessage="Cargos">
							{() => (
								<DynamicTable
									titles={titles}
									pageName={PAGE_NAMES.Cargos}
									getData={getDataTable}
									handleDeleteItem={handleDeleteItem}
									handleEditStateItem={handleEditStateItem}
									routeToEdit={paths.updatePositions}
									canDeleted={permittedActions.eliminar}
									canModify={permittedActions.editar}
								/>
							)}
						</FormattedMessage>
					)}
				</section>
			) : error ? (
				displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			) : (
				displayLoading()
			)}
		</>
	);
}

export default PositionComponent;
