import { Formiz } from '@formiz/core';
import { InputFieldResponsive } from 'common/inputs/InputFieldResponsive';
import hexRgb from 'hex-rgb';
import React from 'react';
import { SketchPicker } from 'react-color';
import paths from 'services/paths';
import 'styles/colorPicker.css';
// Import libs
import { FormattedMessage } from 'react-intl';

import CreateStageViewModel from './CreateStage.viewModel';

export default function CreateStage() {
	const {
		formatterText,
		handleFormChange,
		handleSubmit,
		myForm,
		colorHexCode,
		colorRGB,
		handleClick,
		displayColorPicker,
		handleClose,
		setColorHexCode,
		setColorRGB,
		navigate
	} = CreateStageViewModel();
	return (
		<Formiz onChange={handleFormChange} connect={myForm} onValidSubmit={handleSubmit}>
			<form className="form-responsive" onSubmit={myForm.submit}>
				<section className="form-responsive-container-information">
					<section className="form-responsive-information__option">
						{/* <h3 className="p-styles">
            <FormattedMessage
              id="table.title.service.stage"
              defaultMessage="Nombre etapa de servicio"
            />
          </h3>
          <input
            className="input-primary"
            type="text"
            name="nombre"
            value={formData.nombre}
            onChange={handleInputCaracter}
            placeholder={formatterText(
              "table.title.service.stage",
              "Nombre etapa de servicio",
            )}
            maxLength="45"
            required
          /> */}
						<InputFieldResponsive
							type="text"
							name="nombre"
							labelText={formatterText('table.title.service.stage', 'Nombre etapa de servicio')}
							placeholder={formatterText('table.title.service.stage', 'Nombre etapa de servicio')}
							maxLength="100"
							required={formatterText('p.stage.is.required')}
						/>
					</section>
					<section className="form-responsive-information__option">
						{/*  <h3 className="p-styles">
            <FormattedMessage
              id="table.title.description"
              defaultMessage="Descripción"
            />
          </h3> */}
						{/* <textarea
            className="input-primary-textarea"
            styleName="input-primary-textarea"
            name="descripcion"
            value={formData.descripcion}
            onChange={handlerTextDescription}
            placeholder={formatterText(
              "table.title.description",
              "Descripción",
            )}
            maxLength="200"
          /> */}
						<InputFieldResponsive
							type="text"
							area={true}
							name="descripcion"
							styleName="input-primary-textarea"
							labelText={formatterText('table.title.description', 'Descripción')}
							placeholder={formatterText('table.title.description', 'Descripción')}
							validateInput="text"
							maxLength="200"
							// required={formatterText('p.description.is.required')}
						/>
					</section>
					<section className="form-responsive-information__option">
						{/* <h3 className="p-styles">
            <FormattedMessage id="table.title.code" defaultMessage="Código" />
          </h3>
          <input
            className="input-primary"
            type="text"
            name="codigo"
            value={formData.codigo}
            onChange={handlerTextCode}
            placeholder={formatterText(
              "input.placeholder.max.45.chars",
              "Ingrese hasta 45 caracteres",
            )}
            maxLength="45"
            required
          /> */}
						<InputFieldResponsive
							type="text"
							name="codigo"
							labelText={formatterText('table.title.code', 'Código')}
							placeholder={formatterText(
								'input.placeholder.max.45.chars',
								'Ingrese hasta 45 caracteres'
							)}
							maxLength="45"
							required={formatterText('table.title.code.required')}
							validateInput="code"
						/>
					</section>
					<section className="form-responsive-information__option">
						<h3 className="p-styles">
							<FormattedMessage id="table.title.color" defaultMessage="Color" />
						</h3>
						<div className="color-picker-container">
							<h3 className="color-picker-text">{colorHexCode}</h3>
							<button className="btn-container-container" onClick={handleClick}>
								<div
									style={{
										width: '36px',
										height: '14px',
										borderRadius: '2px',
										background: `rgba(${colorRGB.red}, ${colorRGB.green}, ${colorRGB.blue}, ${colorRGB.alpha})`
									}}
								/>
							</button>
							{displayColorPicker ? (
								<div className="picker-position">
									<div className="picker-position-cover" onClick={handleClose} />
									<SketchPicker
										color={colorHexCode}
										onChange={(e) => {
											setColorHexCode(e.hex);
											setColorRGB(hexRgb(e.hex));
										}}
									/>
								</div>
							) : null}
						</div>
					</section>
				</section>
				<section className="form-responsive-container-buttons">
					<button type="submit" className="btn-primary">
						<FormattedMessage
							id="header.title.service.create.stage.service"
							defaultMessage="Crear etapa de servicio"
						/>
					</button>
					<button className="input-cancel" onClick={() => navigate(paths.services)}>
						<FormattedMessage id="btn.cancel" defaultMessage="Cancelar" />
					</button>
				</section>
			</form>
		</Formiz>
	);
}
