import useAxios from 'hooks/useAxios';
import React, { useContext } from 'react';
// Import Context
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import libs
import { MdDelete, MdEdit } from 'react-icons/md';
// Import services
import { useSeachContext } from 'context/SearchContext';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import endpoints from 'services/api';

const TableCostProductProvider = ({ data, setDialog, closeDialog, handleOpen }) => {
	const { RequestUseCase } = useAxios();
	const { setDataTable } = useSeachContext();
	const { setModalData } = useContext(TableMinimalContext);
	const { formatterText } = useLangv2();
	const { loading, error, displayMessage, displayLoading } = useGetData();
	// DELETE a CostProductProvider
	const handleDelete = (row, index) => {
		if (typeof row.idPrecioProductoProveedor === 'number') {
			console.log('Se elimnar del servicio de costoproduct', row.idPrecioProductoProveedor);
			RequestUseCase.delete(
				endpoints.costProductProvider.deleteCostProductProvider(row.idPrecioProductoProveedor)
			)
				.then(() => {
					setDataTable(data.filter((_, i) => i !== index));
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			// filter in the array data the index selected and slice it
			setDataTable(
				data.filter((_, i) => {
					return i !== index;
				})
			);
		}
		closeDialog();
	};

	const handleEdit = (row) => {
		handleOpen();
		setModalData(row);
	};

	return data.map((item, index) => (
		<>
			{!loading ? (
				<>
					<tr key={index}>
						<td>{item?.idProveedor?.label}</td>
						<td>{item?.idUnidadMedida?.label}</td>
						<td>{item?.costo}</td>
						<td>{item?.iva}</td>
						<td>{item?.idMoneda?.label}</td>
						<td className="minimal-table__actions">
							<MdEdit
								size={25}
								color="gray"
								cursor="pointer"
								disabled={loading}
								onClick={() => handleEdit(item)}
							/>
							<MdDelete
								size={25}
								color="gray"
								cursor="pointer"
								onClick={() =>
									setDialog({
										text: `${formatterText('title.service.confirm.delete.row.category')}`,
										active: true,
										action: 'delete',
										funcion: () => handleDelete(item, index)
									})
								}
								disabled={loading}
							/>
						</td>
					</tr>
				</>
			) : error ? (
				displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			) : (
				displayLoading()
			)}
		</>
	));
};

export default TableCostProductProvider;
