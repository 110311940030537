export const parseDataToFormik = ({
	formikInitialValues,
	dataByServer: {
		idCorreoUsuario,
		host,
		smtpAuth,
		usuario,
		clave,
		smtpSecure,
		puerto,
		avatarUrl,
		area
	}
}) => {
	const newFormikData = {};

	if (idCorreoUsuario) {
		newFormikData.idCorreoUsuario = idCorreoUsuario;
	}

	if (host) {
		newFormikData.host = host;
	}

	if (smtpAuth) {
		newFormikData.smtpAuth = smtpAuth;
	}

	if (usuario) {
		newFormikData.usuario = usuario;
	}

	if (clave) {
		newFormikData.clave = clave;
	}

	if (smtpSecure) {
		newFormikData.smtpSecure = smtpSecure;
	}

	if (puerto) {
		newFormikData.puerto = puerto;
	}

	if (avatarUrl) {
		newFormikData.avatarUrl = avatarUrl;
	}

	if (area) {
		newFormikData.area = area;
	}

	if (smtpSecure) {
		newFormikData.smtpSecure = Boolean(smtpSecure);
	}

	if (smtpAuth) {
		newFormikData.smtpAuth = Boolean(smtpAuth);
	}

	return {
		...formikInitialValues,
		...newFormikData
	};
};
