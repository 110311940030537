import React from 'react';

// Import Contexts
import { SearchWrapper } from 'context/SearchContext';

// Import Components
import UpdateTechnicalComponent from './UpdateTechnical.component';

export default function UpdateTechnical() {
	return (
		<SearchWrapper>
			<UpdateTechnicalComponent />
		</SearchWrapper>
	);
}
