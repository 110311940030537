import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
// Import Context
import { useSeachContext } from 'context/SearchContext';
import { usePermissionContext } from 'context/PermissionContext';
// Import Hooks
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import { downloadTable } from 'components/utils/ExportJsonFile';
// Import libs
import SortData from 'components/utils/SortData';

// Import services
import { MODULES_NAME } from 'common/validators/PermissionsConstants';

export default function ClientViewModel() {
	const { endpoints, RequestUseCase } = useAxios();
	// use Hook of language v2
	const { formatterText } = useLangv2();
	// titulos de la tabla
	const titles = [
		formatterText('table.title.clientname', 'Nombre cliente'),
		formatterText('p.document.type', 'Tipo de documento'),
		formatterText('p.email', 'Correo'),
		formatterText('table.title.document', 'Identificación'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];

	// hook to loading data
	const {
		loading,
		error,
		toggleLoading,
		toggleError,
		handleClick,
		displayMessage,
		displayLoading
	} = useGetData();
	// useContext de búsqueda
	const { setDataTable, searchResults, sortedAscResults, setSortedAscResults } = useSeachContext();

	const [clients, setClients] = useState(null);
	// nuevo contexto
	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();

	const permissionsAccessClient = () => {
		permissionsAccess(MODULES_NAME.clients);
	};

	// función para cargar datos
	const getDataTable = () => {
		// show loading
		toggleLoading(true);

		RequestUseCase.get(endpoints.clients.getAllClients)
			.then((data) => {
				const newArray = [];
				data.forEach((item) => handleStructureItems(newArray, item));
				const sortedArray = SortData(newArray, 'asc');
				setDataTable(sortedArray);
				// show loading
				toggleLoading(false);
				setClients(sortedArray);
			})
			.catch(() => {
				// mostrar error
				toggleError(!error);
				handleClick();
			});
	};

	const handleDeleteItem = (rowId) => {
		const deletePromise = new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.clients.deleteClient(rowId))
				.then((res) => {
					const newArray = [];
					res.forEach((item) => handleStructureItems(newArray, item));
					const sortedArray = SortData(newArray, 'asc');
					setClients(sortedArray);
					setDataTable(sortedArray);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return deletePromise;
	};

	const handleEditStateItem = (body) => {
		const updateItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.clients.updateClient, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return updateItemPromise;
	};

	const handleStructureItems = (newArray, item) => {
		const docType = `${item.idTipoDocumento.nombre} - ${item.idTipoDocumento.abreviatura}`;
		newArray.push({
			id: item.idCliente,
			nombre: item.nombreRazonSocial,
			tipo_documento: docType,
			correo: item.correo,
			documento: item.identificacion,
			estado: item.estado,
			objeto: { ...item }
		});
	};

	const handleDownloadItem = () => {
		RequestUseCase.get(endpoints.clients.getAllClients)
			.then((data) => {
				const newArray = [];
				const moduleName = 'Cliente';
				const userName = JSON.parse(localStorage.getItem('userData')).usuario || 'ADMIN';
				const sortedArray = SortData(data, 'asc');
				sortedArray.forEach((item) => {
					const docType = `${item.idTipoDocumento.nombre} - ${item.idTipoDocumento.abreviatura}`;
					newArray.push({
						'Tipo de documento': docType,
						'Número de documento': item.identificacion,
						'Nombre o razón social': item.nombreRazonSocial,
						Celular: item.celular,
						Teléfono: item.telefono,
						'Sitio web': item.sitioWeb,
						Género: item.idGenero.nombre,
						'Tipo de cliente': item.idTipoCliente.nombre,
						Mercado: item.idMercado.nombre,
						// "Unidad de negocio": item.idUnidadNegocio.nombre,
						'Tipo de facturación': item.idTipoFacturacion.nombre,
						'Cliente especial': item.clienteEspecial ? 'Si' : ' ',
						Estado: item.estado ? 'Activo' : 'Inactivo',
						'Fecha de creación': item.fechaCreacion,
						'Fecha de modificación': item.fechaModificacion ? item.fechaModificacion : ' '
					});
					// }
				});
				downloadTable({ moduleName, userName, dataTable: searchResults });
			})
			.catch(() => {
				// mostrar error
				toggleError(!error);
				handleClick();
			});
	};

	useEffect(() => {
		getDataTable();
	}, []);

	useEffect(() => {
		permissionsAccessClient();
	}, [permissions]);

	useEffect(() => {
		if (!sortedAscResults) setSortedAscResults('asc');
	}, [searchResults]);

	const render = () =>
		error
			? displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			: displayLoading();

	return {
		titles,
		loading,
		clients,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		handleDownloadItem,
		permittedActions,
		formatterText,
		render
	};
}
