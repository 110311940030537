import React from 'react';
// Import Contexts
// Import Components
import Table from 'common/minimalTables/TableMinimal';
import Selector from 'common/selects/Selector';
import defaultSelect from 'common/selects/default.select.style';
import { Modal } from 'react-responsive-modal';

import UpdateModalItem from '../UpdateModalItem';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
// Import Services
import { Formiz } from '@formiz/core';
import { InputFieldResponsive } from 'common/inputs/InputFieldResponsive';
import paths from 'services/paths';

import CreateListPriceViewModel from './CreateListPrice.viewModel';

export default function CreateProductComponent() {
	const {
		myForm,
		handleFormChange,
		handleSubmit,
		formatterText,
		handleOpen,
		handleClose,
		handleAddItemMinimal,
		handleCostoChange,
		handleIvaChange,
		selectedSearch,
		auxData,
		loading,
		setAuxData,
		formData,
		handlerTextDescription,
		dataTable,
		titlesTableCostProduct,
		error,
		displayLoading,
		displayMessage,
		setSelectedOption,
		selectedOption,
		formatCurrency,
		valueMinimalPrice,
		formatIvaField,
		valueTax,
		setResultsTableSearch,
		service,
		navigate,
		open
	} = CreateListPriceViewModel();
	return (
		<>
			<Formiz onChange={handleFormChange} connect={myForm}>
				<form onSubmit={handleSubmit}>
					<section className="form-responsive container-border-wrapForm">
						<p className="wrap-form-title">{formatterText('p.label.title.price.list')}</p>
						<section className="form-responsive-container-information">
							<section className="form-responsive-information__option">
								<InputFieldResponsive
									type="text"
									name="nombre"
									labelText={formatterText(
										'p.label.title.price.list.name',
										'Nombre de la lista de precios'
									)}
									placeholder={formatterText('p.label.title.price.list.name')}
									maxLength="100"
									required={formatterText('p.price.list.name.is.requeride')}
									validateInput="namestage"
								/>
								{/* <input
                className="input-primary"
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleInputCarac}
                placeholder={formatterText('p.label.title.price.list.name')}
                maxLength="45"
                required
              /> */}
							</section>

							<section className="form-responsive-information__option">
								<h3 className="p-styles">{formatterText('table.title.currency')}</h3>
								<Selector
									name="idMoneda"
									data={selectedSearch.tipoMonedas}
									placeholder={
										<FormattedMessage
											id="input.placeholder.select"
											defaultMessage="Selecione una opción"
										/>
									}
									dataValue={auxData.idMoneda}
									setterFunction={setAuxData}
									isLoading={loading}
									selectValue={auxData.idMoneda}
									isRequired={true}
								/>
							</section>
							<section className="form-responsive-information__option">
								<h3 className="p-styles">{formatterText('p.label.title.descripcion')}</h3>
								<textarea
									className="input-primary-textarea"
									name="descripcion"
									value={formData.descripcion}
									onChange={handlerTextDescription}
									placeholder={formatterText('p.label.title.descripcionProducto')}
									maxLength="200"
								/>
							</section>
							<section className="form-responsive-information__option">
								<InputFieldResponsive
									type="text"
									name="codigo"
									labelText={formatterText('p.code', 'codigo')}
									placeholder={formatterText(
										'input.placeholder.max.45.chars',
										'Ingrese hasta 45 caracteres'
									)}
									validateInput="code"
									maxLength="45"
									required={formatterText('table.title.code.required')}
								/>
								{/*  <input
                className="input-primary"
                type="text"
                name="codigo"
                value={formData.codigo}
                onChange={handleCode}
                placeholder={formatterText('p.label.title.ingresaCaracteres')}
                maxLength="45"
                required={formatterText('p.label.title.ingresaCaracteres')}
              /> */}
							</section>
						</section>
						{!loading
							? dataTable.length > 0 && (
									<>
										<p className="wrap-form-title">
											{formatterText('p.label.title.preciosAsociados')}aaaa
										</p>
										<section className="form-responsive-container-information">
											<Table
												titles={titlesTableCostProduct}
												data={dataTable}
												type="associatePrices"
												labelTable={formatterText('p.label.title.listaPrecios')}
												handleOpen={handleOpen}
												canSearch={true}
												isEdit={true}
											/>
										</section>
									</>
							  )
							: error
							? displayMessage(
									'error',
									'Ha ocurrido un error, intentalo más tarde.',
									'toast.error.general'
							  )
							: displayLoading()}
						<p className="wrap-form-title">{formatterText('p.label.title.asociarPrecio')}</p>

						<section
							className="grid-container-2c"
							style={{
								width: '95%',
								margin: '0 auto'
							}}
						>
							<label className="d-flex">
								<span className="text-inline">{formatterText('p.label.title.tipoServicio')}</span>
								<section className="w100-container">
									<Select
										styles={defaultSelect}
										onChange={(selectedOption) => {
											setSelectedOption((prev) => ({
												...prev,
												idTipoServicio: selectedOption
											}));
											setAuxData((prev) => ({
												...prev,
												idTipoServicio: selectedOption
											}));
										}}
										value={selectedOption.idTipoServicio}
										noOptionsMessage={() => formatterText('select.placeholder.no.options')}
										options={selectedSearch.tipoServicio}
										placeholder={
											loading ? (
												<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
											) : (
												<FormattedMessage
													id="input.placeholder.select"
													defaultMessage="Selecione una opción"
												/>
											)
										}
									/>
								</section>
							</label>
							<label className="d-flex">
								<span className="text-inline">
									{formatterText('p.label.title.actividadAsociada')}
								</span>
								<section className="w100-container">
									<Select
										styles={defaultSelect}
										onChange={(selectedOption) => {
											setSelectedOption((prev) => ({
												...prev,
												idActividadAsociada: selectedOption
											}));
											setAuxData((prev) => ({
												...prev,
												idActividadAsociada: selectedOption
											}));
										}}
										value={selectedOption.idActividadAsociada}
										noOptionsMessage={() => formatterText('select.placeholder.no.options')}
										options={selectedSearch.actividadesServicios}
										placeholder={
											loading ? (
												<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
											) : (
												<FormattedMessage
													id="input.placeholder.select"
													defaultMessage="Selecione una opción"
												/>
											)
										}
									/>
								</section>
							</label>
							<label className="d-flex">
								<span className="text-inline">{formatterText('p.label.title.unidadMedida')}</span>
								<section className="w100-container">
									<Select
										styles={defaultSelect}
										onChange={(selectedOption) => {
											setSelectedOption((prev) => ({
												...prev,
												idUnidadMedida: selectedOption
											}));
											setAuxData((prev) => ({
												...prev,
												idUnidadMedida: selectedOption
											}));
										}}
										value={selectedOption.idUnidadMedida}
										noOptionsMessage={() => formatterText('select.placeholder.no.options')}
										options={selectedSearch.unidadMedida}
										placeholder={
											loading ? (
												<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
											) : (
												<FormattedMessage
													id="input.placeholder.select"
													defaultMessage="Selecione una opción"
												/>
											)
										}
									/>
								</section>
							</label>
							<label className="d-flex">
								<span className="text-inline">{formatterText('p.label.title.valor')}</span>
								<section className="w100-container">
									<input
										name="valor"
										// defaultValue={valueMinimalPrice}
										className="input-default-3c"
										type="text"
										value={formatCurrency(valueMinimalPrice)}
										onChange={handleCostoChange}
										placeholder={formatterText('p.label.title.valor')}
										maxLength="20"
									/>
								</section>
							</label>
							<label className="d-flex">
								<span className="text-inline">{formatterText('p.label.title.impuesto')}</span>
								<section className="w100-container">
									<input
										name="impuesto"
										// defaultValue={valueTax}
										className="input-default-3c"
										type="text"
										value={formatIvaField(valueTax)}
										onChange={handleIvaChange}
										placeholder={formatterText('p.label.title.impuesto')}
										maxLength="20"
									/>
								</section>
							</label>
						</section>
						<section className="form-responsive-container-buttons">
							<input
								onClick={handleAddItemMinimal}
								type="button"
								className="btn-primary btn-primary-center"
								value={formatterText('p.label.button.asociarPrecio')}
							/>
						</section>
					</section>
					<section className="form-responsive-container-buttons">
						<button type="submit" className="btn-primary">
							{formatterText('p.label.title.crearListaPrecios')}
						</button>
						<button className="input-cancel" onClick={() => navigate(paths.services)}>
							{formatterText('p.label.title.cancelar')}
						</button>
					</section>
				</form>
			</Formiz>
			<Modal
				open={open}
				onClose={handleClose}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<UpdateModalItem
					dataTypeService={selectedSearch.tipoServicio}
					typeService={service}
					dataUnits={selectedSearch.unidadMedida}
					onClose={handleClose}
					valueTax={valueTax}
					setResultsTableSearch={setResultsTableSearch}
					auxData={auxData}
					setAuxData={setAuxData}
				/>
			</Modal>
		</>
	);
}
