import React, { useState } from 'react';
// import Hooks
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';
import { useParams } from 'react-router';
// import Libs
import dateformat from 'dateformat';
// import Services
import endpoints from 'services/api';
// import Styles
import Typography from '@mui/material/Typography';
import HandleOnError from 'common/validators/HandleOnError';
import styles from 'screens/services/ExtraTabs/Tab.module.css';

const Quotation = ({ onClose }) => {
	const { id } = useParams();
	const { showDownloadFile, formatterText } = useLangv2();

	const { fetchData } = useAxios();
	const [formData, setFormData] = useState({
		valor: '',
		valor2: ''
	});

	const [valueOption, setValueOption] = useState('valor');

	// Update a number to set into the form
	const handleNumber = (e) => {
		const value = e.target.value;
		// regex to validate float numbers
		if (value.match(/^[0-9]+(\.[0-9]{1,2})?$/) || value === '') {
			const result = /\s{2}/.test(value);
			if (result) {
				console.log('No se permiten espacios en blanco');
			} else {
				setFormData({
					...formData,
					[e.target.name]: value
				});
			}
		}
	};
	const handleNumber2 = (e) => {
		const value = e.target.value;
		// regex to validate float numbers
		if (value.match(/^[0-9]+(\.[0-9]{1,2})?$/) || value === '') {
			const result = /\s{2}/.test(value);
			if (result) {
				console.log('No se permiten espacios en blanco');
			} else {
				setFormData({
					...formData,
					[e.target.name]: value
				});
			}
		}
	};
	//   download excel or pdf from the quotation
	const handleDownload = (type) => {
		// get the value to set into the query
		const VALUE = formData.valor === '' ? 0.0 : parseFloat(formData.valor);
		const VALUE2 = formData.valor2 === '' ? 0.0 : parseFloat(formData.valor2);
		// set the query
		const query = `${id}/${VALUE}/${VALUE2}`;

		// hash to get the URI
		const hashType = {
			pdf: endpoints.services.downloadQuotationPDFService(query),
			excel: endpoints.services.downloadQuotationExcelService(query)
		};
		if (VALUE && VALUE2) {
			HandleOnError(formatterText('choose.value.or.percentage'));
			return;
		}
		// show the message to download the file
		showDownloadFile();
		// generate the URI to download the file
		const URI = hashType[type];

		fetchData({ url: URI })
			.then((res) => {
				const extensionArchivo = type === 'pdf' ? 'pdf' : 'xlsx';
				const MimeType =
					type === 'pdf' ? 'pdf' : 'vnd.openxmlformats-officedocument.spreadsheetml.sheet';

				const decoded = decodeBase64(res.response);
				functionToDownload(
					decoded,
					`quotation.${dateformat(new Date(), 'dd-mm-yyyy-HH.MM.ss')}.${extensionArchivo}`,
					MimeType
				);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	//   decode the base64 to download the file
	const decodeBase64 = (base64) => {
		const byteCharacters = atob(base64);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		return byteArray;
	};

	//   download the file
	const functionToDownload = (decoded, fileName, ext) => {
		const blob = new Blob([decoded], { type: `aplication/${ext}` });
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = fileName;
		link.click();
	};

	const resetInputValue = () => {
		if (valueOption === 'valor') {
			setFormData({ ...formData, valor: '' });
		} else if (valueOption === 'Porcentaje') {
			setFormData({ ...formData, valor2: '' });
		}
	};

	return (
		<form className={styles['container-ps-modal-ud']}>
			<p className="title-modal">{formatterText('title.service.downloadQuote')}</p>
			<h4
				style={{
					color: 'var(--text-dark)'
				}}
			>
				{formatterText('title.service.addAdditionalValue')}{' '}
			</h4>
			<section style={{ textAlign: 'center' }}>
				<label className="form-responsive-label">
					<Typography
						variant="h1"
						sx={{
							fontSize: '1rem',
							fontWeight: 'bold',
							color: '#8B909C',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							width: '220px',
							margin: '0 auto'
						}}
					>
						<span
							className={`btn-upload ${valueOption === 'valor' ? 'active' : ''}`}
							onClick={() => {
								if (valueOption !== 'valor') {
									setValueOption('valor');
									resetInputValue();
								}
							}}
							style={{
								color: valueOption === 'valor' ? '#fff' : 'var(--text-dark)',
								borderRadius: '12px 0 0 12px',
								backgroundColor: valueOption === 'valor' ? 'var(--color-primary)' : 'white',
								boxShadow: valueOption === 'valor' ? '' : '0px 4px 8px var(--text-dark)'
							}}
						>
							{formatterText('title.service.netValue')}
						</span>
						<span
							className={`btn-upload ${valueOption === 'porcentaje' ? 'active' : ''}`}
							onClick={() => {
								if (valueOption !== 'porcentaje') {
									setValueOption('porcentaje');
									resetInputValue();
								}
							}}
							style={{
								color: valueOption === 'porcentaje' ? '#fff' : 'var(--text-dark)',
								borderRadius: '0 12px 12px 0',
								backgroundColor: valueOption === 'porcentaje' ? 'var(--color-primary)' : 'white',
								boxShadow: valueOption === 'valor' ? '0px 4px 8px var(--text-dark)' : ''
							}}
						>
							{formatterText('title.service.percentage')}
						</span>
					</Typography>
				</label>
			</section>
			{valueOption === 'valor' && (
				<label>
					<span>{formatterText('title.service.additionalValue')}</span>
					<input
						type="text"
						value={formData.valor}
						name="valor"
						onChange={handleNumber}
						placeholder={formatterText('title.service.value')}
						className="input-default w100-container"
						required
					/>
				</label>
			)}
			{valueOption === 'porcentaje' && (
				<label>
					<span>{formatterText('title.service.additionalPercentage')}</span>
					<input
						type="text"
						value={formData.valor2}
						name="valor2"
						onChange={handleNumber2}
						placeholder={formatterText('title.service.valuePercentage')}
						className="input-default w100-container"
						required
					/>
				</label>
			)}

			<section className="footer-grid-buttons">
				<button
					className={`btn-add ${valueOption === 'valor' ? 'active' : ''}`}
					onClick={(e) => {
						e.preventDefault();
						handleDownload('excel');
					}}
				>
					{formatterText('title.service.downloadExcel')}
				</button>
				<button
					className={`btn-add ${valueOption === 'porcentaje' ? 'active' : ''}`}
					onClick={(e) => {
						e.preventDefault();
						handleDownload('pdf');
					}}
				>
					{formatterText('title.service.downloadPdf')}
				</button>
				<button
					className="btn-action-cancel"
					onClick={(e) => {
						e.preventDefault();
						onClose();
					}}
				>
					{formatterText('title.service.cancel')}
				</button>
			</section>
		</form>
	);
};

export default Quotation;
