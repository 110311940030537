import useAxios from 'hooks/useAxios';
import React, { useContext } from 'react';
// Import Context
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import libs
import { MdDelete, MdEdit } from 'react-icons/md';
// Import services
import HandleOnError from 'common/validators/HandleOnError';
import { useSeachContext } from 'context/SearchContext';
import useLangv2 from 'hooks/useLangv2';
import endpoints from 'services/api';

// FIXME: delete this when is dont need it
const TableSubCategories = ({ setDialog, loading, closeDialog, handleOpen }) => {
	const { RequestUseCase } = useAxios();
	const { searchResults = [], setDataTable } = useSeachContext();
	const { setModalData } = useContext(TableMinimalContext);
	const { successRemoveItem, formatterText } = useLangv2();

	const filter = (index) => {
		return searchResults.filter((_, i) => i !== index);
	};
	// DELETE a sub product category
	const handleDelete = (row, index) => {
		if (row.idSubCategoriaProducto !== '') {
			RequestUseCase.delete(
				endpoints.subProductCategory.deleteSubProductCategory(row.idSubCategoriaProducto)
			)
				.then((resp) => {
					const newData = filter(index);
					setDataTable(newData);
					successRemoveItem();
				})
				.catch(() => {
					HandleOnError(
						'La Subcategoria de producto no se pudo eliminar, se encuentra relacionada con otros registros'
					);
				});
		} else {
			// filter in the array searchResults the index selected and slice it
			setDataTable(filter(index));
		}
		closeDialog();
	};

	const handleEdit = (row) => {
		handleOpen();
		setModalData(row);
	};

	return searchResults.map((item, index) => (
		<tr key={index}>
			<td>{item.nombre}</td>
			<td>{item.descripcion}</td>
			<td>{item.codigo}</td>
			<td>{item.estado} ya no entras aqui</td>
			<td className="minimal-table__actions">
				<MdEdit
					size={25}
					color="gray"
					cursor="pointer"
					disabled={loading}
					onClick={() => handleEdit(item)}
				/>
				<MdDelete
					size={25}
					color="gray"
					cursor="pointer"
					onClick={() =>
						setDialog({
							text: `${formatterText('title.service.confirm.delete.row.category')}`,
							active: true,
							action: 'delete',
							funcion: () => handleDelete(item, index)
						})
					}
					disabled={loading}
				/>
			</td>
		</tr>
	));
};

export default TableSubCategories;
