import React from 'react';
import { SearchWrapper } from 'context/SearchContext';
import CloneScheduleComponent from './CloneSchedule.component';

const CloneSchedule = () => (
	<SearchWrapper>
		<CloneScheduleComponent />
	</SearchWrapper>
);

export default CloneSchedule;
