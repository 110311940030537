/**
 * Validator to avoid double spaces in text,
 * but in the POST need to do a trim()
 */
const HandlerText = (e, currentValue, setCurrentValue) => {
	if (e.target.value.match('^[a-zA-Z0-9]*$') !== null) {
		const result = /\s{2}/.test(e.target.value);
		if (result) {
			console.log('No se permiten espacios en blanco');
		} else {
			setCurrentValue((prevValue) => ({
				...prevValue,
				[e.target.name]: e.target.value
			}));
		}
	}
};

export default HandlerText;
