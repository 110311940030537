import { useForm } from '@formiz/core';
import HandleOnError from 'common/validators/HandleOnError';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import CustomAlert from 'components/CustomAlert';
import { AppContext } from 'context/AppContext';
import { TableMinimalContext } from 'context/TableMinimalContext';
import dateFormat from 'dateformat';
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';
import usePermission from 'hooks/usePermission';
import React, { useContext, useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import { useNavigate } from 'react-router-dom';
import endpoints from 'services/api';
import paths from 'services/paths';
import Swal from 'sweetalert2';
import hours from '../hours';
import hoursWithValue from '../hoursWithValue';

export default function CloneServiceViewModel() {
	// Manage axios requests
	const { fetchData, COOKIE_USER, RequestUseCase } = useAxios();

	const navigate = useNavigate();
	// getData from the client or exteralUser select
	const {
		clientSelected,
		userSelected,
		externalUserSelected,
		setExternalUserSelected,
		billingUserSelected,
		setClientSelected,
		setBillingUserSelected,
		setUserSelected,
		serviceCloneData
	} = useContext(AppContext);
	// useLanguage
	const { formatterText, errorProcess, customSB } = useLangv2();
	// Value that select use
	const [selectedSearch, setSearchSelected] = useState([
		{
			clients: [],
			users: [],
			typeService: [],
			communicationMedia: [],
			timeMeeting: [],
			communicationChannel: []
		}
	]);

	// manage the state of datetime
	const [eventData, setEventData] = useState({
		fechaCita: '',
		idHoraCita: ''
	});

	const [idService, setIdService] = useState(71);
	// show generations
	const [show, setShow] = useState(false);
	const [formItem, setFormItem] = useState({
		descripcion: ''
	});

	// permissions access
	const [access, setAccess] = useState([]);
	// permissions
	const [searchAutomatic, setSearchAutomatic] = useState(false);
	const [manualSearch, setManualSearch] = useState(false);

	const [addressAux, setAddressAux] = useState([
		{
			value: 0,
			label: 'Nonthing'
		}
	]);

	const [listPriceAux, setListAux] = useState([
		{
			value: 0,
			label: 'Nonthing'
		}
	]);

	const fileInput = React.useRef(null);
	const reader = new FileReader();
	const [typeGenerate, setTypeGenerate] = useState(true);
	const [filesOnCreate, setFilesOnCreate] = useState([]);

	// save the state of the address ID

	const myForm = useForm();

	// checkouts states
	const [checkoutValidations, setCheckoutValidations] = useState({
		equalsExp: false,
		specialService: false
	});

	// Stare of date picker
	const [time, setTime] = useState(new Date());

	// State loader
	const [loadingValidation, setLoadingValidation] = useState(false);

	// Modal config
	const [openClient, setOpenClient] = useState(false);
	const [openExternalUser, setOpenExternalUser] = useState(false);
	const [openAddress, setOpenAddress] = useState(false);
	const [openSearch, setOpenSearch] = useState(false);
	const [openSystemUser, setOpenSystemUser] = useState(false);

	const onOpenClient = () => setOpenClient(true);
	const onCloseClient = () => setOpenClient(false);

	const onOpenExternalUser = () => setOpenExternalUser(true);
	const onCloseExternalUser = () => setOpenExternalUser(false);
	const onOpenSystemUser = () => setOpenSystemUser(true);
	const onCloseSystemUser = () => setOpenSystemUser(false);

	const { setSelectedTable } = useContext(TableMinimalContext);

	const onOpenAddress = () => setOpenAddress(true);
	const onCloseAddress = () => setOpenAddress(false);

	const onOpenSearch = () => setOpenSearch(true);
	const onCloseSearch = () => setOpenSearch(false);
	const [openBillingUser, setOpenBillingUser] = useState(false);
	const onToggleBillingUser = () => setOpenBillingUser(!openBillingUser);

	const now = new Date();
	// Helps to save the ids and labels of the selects data
	const [selectIds, setSelectIds] = useState({
		idGeneradorCliente: {
			value: 0,
			label: ''
		},
		idUsuario: {
			value: 0,
			label: ''
		},
		idDireccion: '',
		idListaPrecios: '',
		idTipoServicio: {
			value: 0,
			label: ''
		},
		idMedioComunicacion: {
			value: serviceCloneData.idMedioComunicacion.idMedioComunicacion,
			label: ''
		},
		idHoraCita: {
			value: 0,
			label: ''
		},
		idCanalComunicacion: {
			value: serviceCloneData.idCanalComunicacion.idCanalComunicacion,
			label: ''
		}
	});

	const { permissionPerModule, permissions } = usePermission();

	useEffect(() => {
		validationData();
	}, [access]);

	const validationData = () => {
		access.forEach((element) => {
			switch (element.nombrePermiso) {
				case 'BUSQUEDA_MANUAL':
					setManualSearch(true);
					break;
				case 'BUSQUEDA_AUTOMATICA':
					setSearchAutomatic(true);
					break;
				default:
					break;
			}
		});
	};

	useEffect(() => {
		permissionsAccess();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [permissions]);

	// Call services to fill in the selects
	useEffect(() => {
		// Call services
		getAllClientsServices();
		getAllUsersServices();
		getAllTypeServicesServices();
		getAllCommunicationMediaServices();
		getAllCommunicationChannelServices();
		// set data into the fields
	}, []);

	useEffect(() => {
		// Check if serviceCloneData has data
		if (serviceCloneData) {
			const {
				idServicioPadre: serviceRoot = {},
				fechaCita: dateAppointment = '',
				idDireccion: direction = {},
				idTipoServicio: serviceType = {},
				idMedioComunicacion: communicationTool = {}
			} = serviceCloneData;

			const { idDireccion: directionId = '', idDSector: sector = {} } = direction;
			const {
				idTipoServicio: serviceTypeId,
				idCategoriaServicio: serviceCategory,
				nombre: serviceTypeName = ''
			} = serviceType;

			// Verificar si serviceType tiene datos y serviceCategory no es undefined
			if (serviceType && serviceCategory) {
				const { idDSector: sectorId = '', idMunicipio: townId = '' } = sector;
				const { nombre: categoryName = '' } = serviceCategory;
				const { code: communicationToolCode = '', nombre: communicationToolLabel = '' } =
					communicationTool;

				const select = {
					...selectIds,
					idDireccion: `${directionId}-${sectorId}-${townId}`,
					idTipoServicio: {
						value: `${serviceTypeId}-${categoryName}`,
						label: `${serviceTypeName} - ${categoryName}`
					}
				};

				if (!!serviceRoot && !!dateAppointment) {
					// control the input of generator and determinate which one is selected
					setTypeGenerate(!!clientSelected?.id);

					setFormItem(serviceCloneData);
					setSelectIds(select);

					// set address id
				} else {
					navigate(paths.serviceModule);
				}
			}
		} else {
			navigate(paths.serviceModule);
		}
	}, [serviceCloneData]);

	const getListPrice = () => {
		// OPTION 1: GUSER where idGeneradorCliente are null
		if (userSelected.id !== null) {
			// OPTION 2:HAVE USER SYSTEM Too
			if (externalUserSelected.id !== null) {
				// Select genUser and Externarl User
				getListPriceExternalUser();
			} else {
				// Only select gen system user Option 1 only
				getListPriceSystemUser();
			}
		}
		// OPTION 3: GCLIENT + GUSER where idGeneradorUsuario is null
		else if (clientSelected.id !== null) {
			// OPTION 4:HAVE USER SYSTEM Too
			if (externalUserSelected.id !== null) {
				// Select geClient and Externarl User
				getListPriceExternalUser();
			} else {
				// Option 3: Only GenClient

				getListPriceClient();
			}
		} else {
			setListAux([]);
		}
	};

	const getListPriceSystemUser = () => {
		RequestUseCase.get(
			endpoints.listPriceClient.getListPriceByIdUsuarioServicio(userSelected.id)
		).then((data) => {
			// create new array
			const newArray = [];
			let principalId = '';
			// iterate response and get only the values that are active
			data.forEach((item) => {
				newArray.push({
					value: item.idListaPreciosActividad.idListaPreciosActividad, //idListaPreciosActividad
					label: item.idListaPreciosActividad.nombre
				});
				//if item.principal === 1 save the id
				if (item.principal === 1) {
					principalId = item.idListaPreciosActividad.idListaPreciosActividad;
				}
			});
			setListAux(newArray);
			setSelectIds({
				...selectIds,
				idListaPrecios: principalId
			});
		});
	};

	const getListPriceExternalUser = () => {
		RequestUseCase.get(
			endpoints.listPriceClient.getListPriceByIdUsuarioServicio(externalUserSelected.id)
		).then((data) => {
			// create new array
			const newArray = [];
			let principalId = '';
			// iterate response and get only the values that are active
			data.forEach((item) => {
				newArray.push({
					value: item.idListaPreciosActividad.idListaPreciosActividad, //idListaPreciosActividad
					label: item.idListaPreciosActividad.nombre
				});
				//if item.principal === 1 save the id
				if (item.principal === 1) {
					principalId = item.idListaPreciosActividad.idListaPreciosActividad;
				}
			});
			setListAux(newArray);
			setSelectIds({
				...selectIds,
				idListaPrecios: principalId
			});
		});
	};

	const getListPriceClient = () => {
		RequestUseCase.get(endpoints.listPriceClient.getListPriceById(clientSelected.id)).then(
			(data) => {
				// create new array
				const newArray = [];
				let principalId = '';
				// iterate response and get only the values that are active
				data.forEach((item) => {
					newArray.push({
						value: item.idListaPreciosActividad.idListaPreciosActividad, //idListaPreciosActividad
						label: item.idListaPreciosActividad.nombre
					});
					//if item.principal === 1 save the id
					if (item.principal === 1) {
						principalId = item.idListaPreciosActividad.idListaPreciosActividad;
					}
				});
				setListAux(newArray);
				setSelectIds({
					...selectIds,
					idListaPrecios: principalId
				});
			}
		);
	};

	const permissionsAccess = () => {
		const filterAccess = permissionPerModule(17);
		setAccess(filterAccess);
	};

	useEffect(() => {
		// display the address of the client, gen user or external user
		showAddress();
		getListPrice();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientSelected.id, externalUserSelected.id, userSelected.id]);

	// render address
	const showAddress = (addressCreated = null) => {
		// OPTION 1: GUSER where idGeneradorCliente are null
		if (userSelected.id !== null) {
			// OPTION 2:HAVE USER SYSTEM Too
			if (externalUserSelected.id !== null) {
				// Select genUser and Externarl User
				getAddressExternalUser(addressCreated);
			} else {
				// Only select gen system user Option 1 only
				getAddressSystemUser(addressCreated);
			}
		}
		// OPTION 3: GCLIENT + GUSER where idGeneradorUsuario is null
		else if (clientSelected.id !== null) {
			// OPTION 4:HAVE USER SYSTEM Too
			if (externalUserSelected.id !== null) {
				// Select geClient and Externarl User
				getAddressExternalUser(addressCreated);
			} else {
				// Option 3: Only GenClient
				getAddressesClient(addressCreated);
			}
		} else {
			setAddressAux([]);
		}
	};

	// getAllExternalUser
	const getAddressExternalUser = (address = null) => {
		RequestUseCase.get(
			endpoints.services.getAllAddressExternalUsersById(externalUserSelected.id)
		).then((response) => {
			// create new array
			const newArray = [];
			// iterate response and get only the values that are active
			response.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						value: `${item.idDireccion.idDireccion}-${item.idDireccion.idSector.idSector}-${item.idDireccion.idSector.idMunicipio}`,
						label: `${item.idDireccion.direccion} - ${item.idDireccion.datosAdicionales}`
					});
				}
			});
			setAddressAux(newArray);
			// set the values of the select
			if (address !== null) {
				setSelectIds({
					...selectIds,
					idDireccion: `${address.idDireccion.idDireccion}-${address.idDireccion.idSector.idSector}-${address.idDireccion.idSector.idMunicipio}`
				});
			}
		});
	};

	// get All Address System User
	const getAddressSystemUser = (address = null) => {
		RequestUseCase.get(endpoints.services.getAllAddressExternalUsersById(userSelected.id)).then(
			(data) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				data.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: `${item.idDireccion.idDireccion}-${item.idDireccion.idSector.idSector}-${item.idDireccion.idSector.idMunicipio}`,
							label: `${item.idDireccion.direccion} - ${item.idDireccion.datosAdicionales}`
						});
					}
				});
				setAddressAux(newArray);
				// set the values of the select
				if (address !== null) {
					setSelectIds({
						...selectIds,
						idDireccion: `${address.idDireccion.idDireccion}-${address.idDireccion.idSector.idSector}-${address.idDireccion.idSector.idMunicipio}`
					});
				}
			}
		);
	};

	// Get the address for the SelectorGrid
	const getAddressesClient = (address = null) => {
		RequestUseCase.get(endpoints.branchOffices.getSucursalClientById(clientSelected.id)).then(
			(data) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				data.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: `${item.idDireccion.idDireccion}-${item.idDireccion.idSector.idSector}-${item.idDireccion.idSector.idMunicipio.idMunicipio}`,
							label: `${item.idDireccion.direccion} - ${item.idDireccion.datosAdicionales}`
						});
					}
				});
				// set the values of the select
				setAddressAux(newArray);
				if (address !== null) {
					setSelectIds({
						...selectIds,
						idDireccion: `${address.idDireccion.idDireccion}-${address.idDireccion.idSector.idSector}-${address.idDireccion.idSector.idMunicipio}`
					});
				}
			}
		);
	};

	const saveBillingUser = (idServ) => {
		const data = {
			idServicio: {
				idServicio: idServ
			},
			idCliente: {
				idCliente: billingUserSelected.id || userSelected.id || externalUserSelected.id
			},
			usuarioCreacion: COOKIE_USER
		};
		RequestUseCase.post(endpoints.services.saveBillingUser, data).then(() => {
			setBillingUserSelected({
				nombre: 'Seleccione un usuario (Opcional)',
				id: null
			});
		});
	};

	console.log('selectIds.idMedioComunicacion.value',selectIds.idMedioComunicacion.value);
	console.log('selectIds.idCanalComunicacion.value',selectIds.idCanalComunicacion.value);
	console.log('serviceCloneData', serviceCloneData);

	// clone service
	const handleSubmit = () => {
		if (eventData.fechaCita !== '' && eventData.idHoraCita !== '') {
			let especial = 0;

			if (clientSelected.esVip === 1) {
				especial = clientSelected.esVip === 1 ? 1 : 0;
			} else if (userSelected.esVip === 1) {
				especial = userSelected.esVip === 1 ? 1 : 0;
			}

			let data = {
				idServicio: null,
				idDireccion: {
					idDireccion:
						selectIds.idDireccion !== ''
							? parseInt(selectIds.idDireccion.split('-')[0])
							: serviceCloneData.idDireccion.idDireccion
				},
				idTipoServicio: {
					idTipoServicio: serviceCloneData.idTipoServicio.idTipoServicio
				},
				descripcion: formItem.descripcion ? formItem.descripcion : '',
				idMedioComunicacion: {
					idMedioComunicacion: selectIds.idMedioComunicacion.value
				},
				idCanalComunicacion: {
					idCanalComunicacion: selectIds.idCanalComunicacion.value
				},
				fechaCita: `${eventData.fechaCita}T10:00:00.000Z`,
				idHoraCita: {
					idHora: eventData.idHoraCita
				},
				expediente: checkoutValidations.equalsExp ? null : formItem.expediente,
				especial,
				idEstadoServicio: {
					idEstadoServicio: 1
				},
				usuarioCreacion: COOKIE_USER,
				idServicioPadre: formItem.idServicioPadre
			};

			// OPTION 1: GUSER where idGeneradorCliente are null
			if (userSelected.id !== null) {
				// OPTION 2:HAVE USER SYSTEM Too
				if (externalUserSelected.id !== null) {
					// Select genUser and Externarl User
					data = {
						...data,
						idGeneradorCliente: null,
						idGeneradorUsuario: {
							idUsuarioServicio: Number(userSelected.id)
						},
						idUsuario: {
							idUsuarioServicio: parseInt(externalUserSelected.id)
						}
					};
				} else {
					// Only select gen system user Option 1 only
					data = {
						...data,
						idGeneradorCliente: null,
						idGeneradorUsuario: {
							idUsuarioServicio: parseInt(userSelected.id)
						},
						idUsuario: null
					};
				}
			}
			// OPTION 3: GCLIENT + GUSER where idGeneradorUsuario is null
			else if (clientSelected.id !== null) {
				// OPTION 4:HAVE USER SYSTEM Too
				if (externalUserSelected.id !== null) {
					// Select geClient and Externarl User
					data = {
						...data,
						idGeneradorCliente: {
							idCliente: parseInt(clientSelected.id)
						},
						idGeneradorUsuario: null,
						idUsuario: {
							idUsuarioServicio: parseInt(externalUserSelected.id)
						}
					};
				} else {
					// Option 3: Only GenClient
					data = {
						...data,
						idGeneradorCliente: {
							idCliente: clientSelected.id
						},
						idGeneradorUsuario: null,
						idUsuario: null
					};
				}
			} else {
				alert('Error to create service: notify admin');
			}
			generateDateEnd(data);
		} else {
			customSB(
				'warning',
				'snackbar.error.validate.service.clone.address.date.hour',
				'Debe seleccionar una fecha y una hora para poder crear el servicio.'
			);
		}
	};

	// generate the end date of the service for validate the agenda
	const generateDateEnd = (data) => {
		setLoadingValidation(true);
		customSB(
			'warning',
			'snackbar.warning.validate.tech.exist',
			'Se esta validando que el servicio cumpla con los requisitos para ser creado, por favor espere...'
		);
		RequestUseCase.post(endpoints.services.generateEndDate, data)
			.then((response) => {
				const ADDRESS =
					parseInt(selectIds.idDireccion.split('-')[0]) || serviceCloneData.idDireccion.idDireccion;
				const SECTOR =
					parseInt(selectIds.idDireccion.split('-')[1]) ||
					serviceCloneData.idDireccion.idSector.idSector;
				const MUNICIPALITY =
					parseInt(selectIds.idDireccion.split('-')[2]) ||
					serviceCloneData.idDireccion.idSector.idMunicipio.idMunicipio;
				const TYPE_SERVICE = parseInt((selectIds?.idTipoServicio?.value || '').split('-')[0]);
				const CATEGORY_SERVICE = parseInt(
					serviceCloneData?.idTipoServicio?.idCategoriaServicio.idCategoriaServicio
				);

				const idHora = hoursWithValue.find((item) => item.idHora === eventData.idHoraCita);

				const DATA_ORIGINAL = data;

				const DATA_VALIDATED = {
					...data,
					idDireccion: {
						idDireccion: ADDRESS,
						idSector: {
							idSector: SECTOR,
							idMunicipio: {
								idMunicipio: MUNICIPALITY
							}
						}
					},
					idTipoServicio: {
						idTipoServicio: TYPE_SERVICE,
						idCategoriaServicio: {
							idCategoriaServicio: CATEGORY_SERVICE
						}
					},
					idHoraCita: {
						idHora: eventData.idHoraCita,
						valor: idHora.valor
					},
					fechaCitaFin: response.fechaCitaFin.split('T')[0],
					idHoraCitaFin: {
						idHora: response.idHoraCitaFin.idHora,
						valor: response.idHoraCitaFin.valor
					}
				};

				validateServiceAgendaEvent(DATA_VALIDATED, DATA_ORIGINAL);
			})
			.catch(() => {
				errorProcess();
				setLoadingValidation(false);
			});
	};

	// Validate if the agenda have horary available
	const validateServiceAgendaEvent = (DATA, DATA_ORIGINAL) => {
		RequestUseCase.post(endpoints.services.validateCreateService, DATA)
			.then((response) => {
				if (response) {
					createItem(DATA_ORIGINAL);
					setLoadingValidation(false);
					customSB(
						'success',
						'snackbar.success.validate.tech.exist',
						'Se encontró al menos un técnico disponible para este servicio, puede continuar con el proceso.'
					);
				} else {
					setLoadingValidation(false);
					customSB(
						'error',
						'snackbar.error.validate.tech.exist',
						'No se encontró un técnico disponible para este servicio, seleccione otro horario o fecha.'
					);
				}
			})
			.catch((error) => {
				if (error == 500) {
					customSB(
						'warning',
						'snackbar.error.validate.service.clone.address.date.hour',
						'Debe seleccionar una fecha y una hora para poder crear el servicio.'
					);
					setLoadingValidation(false);
				} else {
					errorProcess();
					setLoadingValidation(false);
				}
			});
	};

	// Create new service
	const createItem = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					// this service create the item
					RequestUseCase.post(endpoints.services.addService, {
						...data,
						idListaPreciosActividad: parseInt(selectIds.idListaPrecios)
					})
						.then((response) => {
							uploadReports(response.idServicio);
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.created.general',
										'El registro se ha creado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => {
										setIdService(response.idServicio);
										saveBillingUser(response.idServicio);
										onOpenSearch();
										setExternalUserSelected({
											nombre: 'Seleccione un usuario (Opcional)',
											id: null
										});

										setClientSelected({
											nombre: 'Seleccione un cliente',
											id: null,
											esVip: 2
										});

										setUserSelected({
											nombre: 'Seleccione un usuario generador',
											id: null,
											esVip: 2
										});
									}
								})
							);
						})
						.catch((error) => {
							reject(HandleOnError(formatterText(error)));
							setLoadingValidation(false);
						});
				});
			}
		});
	};

	// Get the clients for the SelectorGrid
	const getAllClientsServices = () => {
		RequestUseCase.get(endpoints.clients.getAllClients).then((data) => {
			// create new array
			const newArray = [
				{
					value: -1,
					label: 'Ninguno'
				}
			];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						value: item.idCliente,
						label: `${item.nombreRazonSocial} - ${item.identificacion}`
					});
				}
			});
			// set the values of the select
			setSearchSelected((prev) => ({
				...prev,
				clients: newArray
			}));
		});
	};

	// Get the users for the SelectorGrid
	const getAllUsersServices = () => {
		RequestUseCase.get(endpoints.services.getAllExternalUsers).then((data) => {
			// create new array
			const newArray = [
				{
					value: -1,
					label: 'Ninguno'
				}
			];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						value: item.idUsuarioServicio,
						label: `${item.primerNombre} ${item.primerApellido} - ${item.telefono}`
					});
				}
			});
			// set the values of the select
			setSearchSelected((prev) => ({
				...prev,
				users: newArray
			}));
		});
	};

	// Get the typeServices for the SelectorGrid
	const getAllTypeServicesServices = () => {
		RequestUseCase.get(endpoints.typeService.getAllTypeService).then((data) => {
			// create new array
			const newArray = [];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						value: `${item.idTipoServicio}-${item.idCategoriaServicio.idCategoriaServicio}`,
						label: `${item.nombre} - ${item.idCategoriaServicio.nombre}`
					});
				}
			});

			// set the values of the select
			setSearchSelected((prev) => ({
				...prev,
				typeService: newArray
			}));
		});
	};

	// Get the communicationMediaServices for the SelectorGrid
	const getAllCommunicationMediaServices = () => {
		RequestUseCase.get(endpoints.services.getAllMedia).then((data) => {
			// create new array
			const newArray = [];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						value: item.idMedioComunicacion,
						label: `${item.nombre} ${item.codigo}`
					});
				}
			});
			// set the values of the select
			setSearchSelected((prev) => ({
				...prev,
				communicationMedia: newArray
			}));
		});
	};

	// Get the communicationChannel for the SelectorGrid
	const getAllCommunicationChannelServices = () => {
		RequestUseCase.get(endpoints.services.getAllChannels).then((data) => {
			// create new array
			const newArray = [];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						value: item.idCanalComunicacion,
						label: `${item.nombre} - ${item.codigo}`
					});
				}
			});
			// set the values of the select
			setSearchSelected((prev) => ({
				...prev,
				communicationChannel: newArray
			}));
		});
	};

	// set time and date
	const handleDate = (e) => {
		if (e.target) {
			setEventData({
				...eventData,
				[e.target.name]: e.target.value
			});
		} else {
			setTime(e);
			const HOUR_ID = searchHour(dateFormat(e, 'HH:MM'));
			const NAME_VALUE = 'idHoraCita';
			setEventData({
				...eventData,
				[NAME_VALUE]: HOUR_ID
			});
		}
	};

	// search in hours array the idHora that matches with the hour
	const searchHour = (hour) => {
		// search in hours array the idHora that matches with the hour
		const idHora = hours.find((item) => item.hora === hour);
		return idHora.idHora;
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formItem, setFormItem);
	};

	const uploadFromCreate = (e) => {
		e.preventDefault();
		const selectedFile = e.target.files[0];
		if (selectedFile.size > 5000000) {
			HandleOnError(formatterText('id.error.file.large'));
			return;
		} // png, jpg, tiff
		if (
			selectedFile.type !== 'image/jpg' &&
			selectedFile.type !== 'image/png' &&
			selectedFile.type !== 'image/jpeg' &&
			selectedFile.type !== 'image/tiff' &&
			selectedFile.type !== 'video/mp4'
		) {
			HandleOnError(formatterText('id.error.file.no.valid'));
			return;
		}

		if (filesOnCreate.length >= 3) {
			HandleOnError(formatterText('id.error.three.files'));
			return;
		}

		reader.readAsDataURL(selectedFile);
		reader.onload = () => {
			const archivo = reader.result.split('base64,')[1];
			//obtener el tipo del archivo
			const extensionArchivo =
				selectedFile.name.lastIndexOf('.') > -1
					? selectedFile.name.substring(
							selectedFile.name.lastIndexOf('.') + 1,
							selectedFile.name.length
					  )
					: '';
			const name = selectedFile.name;
			setFilesOnCreate((filesOnCreate) => [
				...filesOnCreate,
				{ name, extensionArchivo: `.${extensionArchivo}`, archivo }
			]);
		};
	};

	const deleteFile = (e, item) => {
		e.preventDefault();
		if (filesOnCreate.length === 1) {
			setFilesOnCreate([]);
		} else {
			const index = filesOnCreate.indexOf(item);
			filesOnCreate.splice(index, 1);
			setFilesOnCreate([...filesOnCreate]);
		}
	};

	const uploadReports = (id) => {
		const data = {
			idServicioReporte: null,
			idServicio: id,
			idTipoReporte: parseInt(4),
			descripcion: 'Archivos Adjuntos del servicio',
			notificacionAsesor: 0,
			publico: parseInt(1),
			fechaCreacion: new Date().toISOString(),
			usuarioCreacion: parseInt(COOKIE_USER),
			urlsArchivos: null
		};

		fetchData({ url: endpoints.reports.create, method: 'post', body: data })
			.then(async (res) => {
				await fetchData({
					url: endpoints.UploadFiles.save,
					method: 'post',
					body: {
						idOrigen: res.response.idServicioReporte,
						idTipoOrigenArchivo: 2,
						archivos: filesOnCreate
					}
				});
			})
			.catch((error) => {
				console.error('Error en la carga de archivos:', error);
				return Promise.reject(error);
			});
	};

	return {
		formItem,
		setFormItem,
		selectedSearch,
		setSelectIds,
		selectIds,
		serviceCloneData,
		filesOnCreate,
		uploadFromCreate,
		deleteFile,
		handlerTextDescription,
		handleDate,
		time,
		show,
		setShow,
		myForm,
		setSelectedTable,
		formatterText,
		onOpenClient,
		onCloseClient,
		openClient,
		onOpenSystemUser,
		onCloseSystemUser,
		openSystemUser,
		onOpenExternalUser,
		onCloseExternalUser,
		openExternalUser,
		onOpenAddress,
		onCloseAddress,
		openAddress,
		onCloseSearch,
		openSearch,
		onToggleBillingUser,
		openBillingUser,
		billingUserSelected,
		setBillingUserSelected,
		clientSelected,
		setClientSelected,
		userSelected,
		setUserSelected,
		externalUserSelected,
		setExternalUserSelected,
		addressAux,
		listPriceAux,
		eventData,
		searchAutomatic,
		manualSearch,
		idService,
		loadingValidation,
		handleSubmit,
		getAllUsersServices,
		showAddress,
		setTypeGenerate,
		typeGenerate,
		checkoutValidations,
		setCheckoutValidations,
		now,
		fileInput,
		navigate
	};
}
