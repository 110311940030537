import useAxios from 'hooks/useAxios';
import { downloadTable } from 'components/utils/ExportJsonFile';
import SortDataByDate from 'components/utils/SortDataByDate';
import { usePermissionContext } from 'context/PermissionContext';
import { useSeachContext } from 'context/SearchContext';
import dateFormat from 'dateformat';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import { useEffect, useState } from 'react';
import endpoints from 'services/api';

export default function TableAgendaViewModel() {
	const { RequestUseCase } = useAxios();

	// use Hook of language v2
	const { formatterText } = useLangv2();
	const userName = JSON.parse(localStorage.getItem('userData')).usuario || 'ADMIN';

	// hook to loading data
	const {
		loading,
		error,
		toggleLoading,
		toggleError,
		handleClick,
		displayMessage,
		displayLoading
	} = useGetData();

	// useContext de búsqueda
	const { setDataTable } = useSeachContext();

	const [agendas, setAgendas] = useState(null);

	const [dataReady, setDataReady] = useState(false);

	// titulos de la tabla
	const titles = [
		formatterText('text.shedule.create.name', 'Nombre Agenda'),
		formatterText('text.shedule.create.startdate', 'Fecha Inicio'),
		formatterText('text.shedule.create.enddate', 'Fecha Fin'),
		formatterText('text.shedule.create.createdate', 'Fecha Creación'),
		formatterText('table.actions', 'Acciones')
	];

	const [dataExcel, setDataExcel] = useState([]);

	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();
	const permissionsAccessGaranted = () => {
		permissionsAccess(18);
	};

	useEffect(() => {
		permissionsAccessGaranted();
	}, [permissions]);

	useEffect(() => {
		getDataTable();
	}, []);
	// get data from the table
	const getDataTable = () => {
		// show loading
		toggleLoading(true);
		RequestUseCase.get(endpoints.agenda.getAllAgenda)
			.then((data) => {
				const newArray = [];
				data.forEach((item) => handleStructureItems(newArray, item));
				const sortedArray = SortDataByDate(newArray, 'desc');
				setDataTable(sortedArray);
				// show loading
				toggleLoading(false);
				setAgendas(sortedArray);
			})
			.catch((err) => {
				// mostrar error
				toggleError(!err);
				handleClick();
			});
	};

	// this function structure the items of the table
	const handleStructureItems = (newArray, item) => {
		newArray.push({
			id: item.idAgenda,
			nombre: item.nombre,
			fechaInicio: item.fechaInicio.split('T')[0],
			fechaFin: item.fechaFin.split('T')[0],
			fechaCreacion:
				dateFormat(item.fechaCreacion, 'yyyy/mm/dd - h:MM:ss TT') || 'No tiene un valor',
			objeto: item
		});
	};

	const handleDownloadItem = async (id) => {
		await dataTableExcel(id);
	};

	const constructDataExcel = async (url, item) => {
		try {
			const resp = await RequestUseCase.get(url);
			const newData = [];

			resp.forEach((elem) => {
				const technical = `${elem.idTecnico?.idEmpleado?.primerNombre} ${elem.idTecnico?.idEmpleado?.primerApellido}`;
				const typeSchedule = elem.idTipoAgenda?.nombre;
				newData.push({
					fechaInicio: item.idFranjaHoraria.idHoraInicio.hora,
					fechaFin: item.idFranjaHoraria.idHoraFin.hora,
					nombreFranja: item.idFranjaHoraria.nombre,
					diaSemana: item.idFranjaHoraria.idDiaSemana.dia,
					tipoAgenda: typeSchedule || 'Sin datos',
					tecnico: technical || 'Sin datos'
				});
			});
			setDataExcel((prev) => [...prev, newData]);
			console.log('Aquí creamos data', dataExcel);
		} catch (error) {
			console.log('constructExcel', error);
		}
	};

	const downloadDataExcel = async () => {
		try {
			await downloadTable({ moduleName: 'Agendas', userName: userName, dataTable: dataExcel });
		} catch (error) {
			console.log('descargamos ', error);
		} finally {
			console.log('aqui limpiamos', dataExcel);
		}
	};

	const dataTableExcel = async (id) => {
		try {
			const data = await RequestUseCase.get(endpoints.agenda.getAllTimezoneByidAgenda(id));
			const promises = data.map(async (item) => {
				const url = endpoints.agenda.getAllFranjaHorariaByIdAgenda(
					item.idFranjaHoraria.idFranjaHoraria,
					item.idAgenda.idAgenda
				);
				return constructDataExcel(url, item);
			});
			const responses = await Promise.all(promises);
			if (responses) setDataReady(true);
			if (dataReady) {
				await downloadDataExcel();
			}
		} catch (error) {
			console.error('Error en dataTableExcel:', error);
		}
	};

	useEffect(() => {
		if (dataReady) {
			downloadDataExcel();
			setDataExcel([]);
			setDataReady(false);
		}
	}, [dataReady]);

	// this function delete an item
	const handleDeleteItem = (rowId) => {
		return new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.agenda.deleteAgenda(rowId))
				.then((res) => {
					const newArray = [];
					res.forEach((item) => {
						handleStructureItems(newArray, item);
					});
					const sortedArray = SortDataByDate(newArray, 'desc');
					setDataTable(sortedArray);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const renderMessage = () => {
		return error
			? displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			: displayLoading();
	};

	return {
		loading,
		agendas,
		getDataTable,
		handleDeleteItem,
		handleDownloadItem,
		permittedActions,
		formatterText,
		titles,
		renderMessage
	};
}
