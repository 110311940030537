import { useField } from '@formiz/core';
import customStyles2 from 'common/selects/generic.style.select';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';

export const FieldMultiSelect = (props) => {
	const { id, isValid, isSubmitted, resetKey, setValue, value, otherProps } = useField(props);
	const { setterFunction, isLoading, data, disabled, noOptionsMessage, options = [] } = otherProps;
	const [isTouched, setIsTouched] = useState(false);
	const showError = !isValid && (isTouched || isSubmitted);

	useEffect(() => {
		setIsTouched(false);
	}, [resetKey]);

	useEffect(() => {
		setValue(data);
	}, [data]);

	return (
		<Select
			id={id}
			value={value}
			isMulti
			name="multiple-select"
			isDisabled={disabled}
			onBlur={() => setIsTouched(true)}
			aria-invalid={showError}
			aria-describedby={!isValid ? `${id}-error` : undefined}
			placeholder={
				isLoading ? (
					<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
				) : (
					<FormattedMessage id="input.placeholder.select" defaultMessage="Selecciona una opción" />
				)
			}
			onChange={(e) => {
				setValue(e);
				setterFunction(e);
			}}
			styles={customStyles2}
			noOptionsMessage={noOptionsMessage}
			// isDisabled={!!disabled}
			options={options}
		/>
	);
};

{
	/* <FieldMultiSelect
    name={'idListaPrecioSecundaria'}
    label={'idListaPrecioSecundaria'}
    required="Required"
    options={secondaryPrices}
    isLoading={loading}
    data={selectValues}
    setterFunction={handleChangeMulti}
/> */
}
