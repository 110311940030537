import * as yup from 'yup';
import { EMAIL_REGEX } from 'common/validators/Regex';

export const getFormikValidationSchema = () => {
	return yup.object().shape({
		area: yup.string().required('p.form.error.required'),
		asunto: yup.string().max(50, 'p.form.error.max.50.chars'),
		destinatario: yup
			.string()
			.required('p.form.error.required')
			.email('p.form.error.email.invalid')
			.matches(EMAIL_REGEX, 'p.form.error.email.invalid')
			.max(40, 'p.form.error.max.40.chars'),
		contenido: yup.string().max(50, 'p.form.error.max.50.chars')
	});
};
