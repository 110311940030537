import useAxios from 'hooks/useAxios';
// Import Context
// Import Hooks
import useLangv2 from 'hooks/useLangv2';
import useProgress from 'hooks/useProgress';
// Import Components
// Import libs
import SortData from 'components/utils/SortData';
// Import Services
import { useSeachContext } from 'context/SearchContext';
import endpoints from 'services/api';

export default function ServiceActivityTableViewModel() {
	const { RequestUseCase } = useAxios();

	const { getDataTable, setDataTable, loading, error, displayMessage, handleStructureItems } =
		useSeachContext();
	// use Hook of language v2
	const { formatterText } = useLangv2();

	// Helps to loading data table
	const { DisplayProgress } = useProgress();

	const handleDeleteItem = (rowId) => {
		return new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.activitiesServices.deleteActivitiesBasic(rowId))
				.then((res) => {
					const newArray = [];
					res.forEach((item) => {
						handleStructureItems(newArray, item);
					});
					SortData(newArray, 'asc');
					setDataTable(newArray);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const handleEditStateItem = (body) => {
		return new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.activitiesServices.updateActivitiesService, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const titles = [
		formatterText('label.text.nameActivity', 'Nombre de la actividad'),
		formatterText('table.title.description', 'Descripción'),
		formatterText('table.title.sku', 'SKU'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];

	return {
		DisplayProgress,
		formatterText,
		handleDeleteItem,
		handleEditStateItem,
		titles,
		getDataTable,
		loading,
		displayMessage,
		error
	};
}
