import React from 'react';
// Import libs
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
// Import Components
import Search from 'common/Search';
import ExportJsonFile from 'components/utils/ExportJsonFile';
import UploadExcel from 'components/utils/UploadExcel';
// Import Context
//Import ViewModel
import ProductsTableViewModel from './ProductsTable.viewModel';

const ProductsTableComponent = () => {
	const {
		auxemaple,
		formatterText,
		indexTabProduct,
		permissionsAccess,
		permittedActions,
		searchResults,
		selectedTab,
		setSearch,
		setSelectedTab,
		tabs
	} = ProductsTableViewModel();

	return (
		<section className="table-container">
			<section className="userOptions">
				{permittedActions.consultar && (
					<Search
						placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
						width="50%"
					/>
				)}
				{permittedActions.crear && (
					<Link style={{ textDecoration: 'none', alignSelf: 'center' }} to={selectedTab.route}>
						<button className="btn-add">{selectedTab.nombreLink}</button>
					</Link>
				)}
				{selectedTab.exportExcel && (
					<ExportJsonFile
						moduleName={selectedTab.id.replace('/', '')}
						userName={JSON.parse(localStorage.getItem('userData')).usuario || 'ADMIN'}
						dataTable={searchResults}
					/>
				)}
				{selectedTab.uploadExcel && (
					<UploadExcel ActionFunction={auxemaple} currentText={selectedTab.id.replace('/', '')} />
				)}
			</section>
			<Tabs defaultIndex={indexTabProduct} selectedTabClassName="tab-option--selected">
				<TabList className="tab-list">
					{tabs.map((tab, index) => (
						<Tab
							onClick={() => {
								setSearch('');
								setSelectedTab(tab);
								permissionsAccess(tab.idPermiso);
								localStorage.setItem('indexTabProduct', index.toString());
							}}
							key={index}
							className="new-tab-option"
						>
							{tab.nombre}
						</Tab>
					))}
				</TabList>
				{permittedActions.consultar &&
					tabs.map((tab, index) => <TabPanel key={index}>{tab.componente}</TabPanel>)}
			</Tabs>
		</section>
	);
};

export default ProductsTableComponent;
