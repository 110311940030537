import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './NotFound.module.css';

export default function NotFound() {
	return (
		<section className={styles.container__error}>
			<section className={styles.container__error__details}>
				<h2>No tienes acceso</h2>
				<NavLink to="/" className={({ isActive }) => (isActive ? 'active' : 'notActive')}>
					<p>Ir a la sección principal</p>
				</NavLink>
			</section>
		</section>
	);
}
