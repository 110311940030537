import React from 'react';

// Import Libs
import { FormattedMessage } from 'react-intl';

// Import Paths
import paths from 'services/paths';

//Import ViewModel
import CreateClientTypeViewModel from './CreateClientType.viewModel';

export default function CreateClientType() {
	const {
		codeError,
		errorName,
		formatterText,
		formData,
		handleErrorCode,
		handleErrorName,
		handleNumber,
		handlerTextDescription,
		handleSubmit,
		handleText,
		navigate
	} = CreateClientTypeViewModel();

	return (
		<form className="form-responsive" onSubmit={handleSubmit}>
			<section className="form-responsive-container-information">
				<section className="form-responsive-information__option">
					<span className="warpForm-text">
						<FormattedMessage id="table.title.client.type" defaultMessage="Nombre tipo cliente" />
					</span>
					<input
						className="input-primary"
						type="text"
						name="nombre"
						value={formData.nombre || ''}
						onChange={handleText}
						placeholder={formatterText('table.title.client.type', 'Nombre tipo cliente')}
						maxLength="100"
						onBlur={handleErrorName}
					/>
				</section>
				{errorName && (
					<div
						style={{ fontSize: '0.7rem', color: 'red', marginTop: '-20px', marginRight: '500px' }}
					>
						{errorName}
					</div>
				)}
				<section className="form-responsive-information__option">
					<span className="p-styles">
						<FormattedMessage id="table.title.description" defaultMessage="Descripción" />
					</span>
					<textarea
						className="input-primary-textarea"
						type="text"
						name="descripcion"
						value={formData.descripcion || ''}
						onChange={handlerTextDescription}
						placeholder={formatterText('table.title.description', 'Descripción')}
						maxLength="200"
					/>
				</section>
				<section className="form-responsive-information__option">
					<span className="p-styles">
						<FormattedMessage id="table.title.code" defaultMessage="Código" />
					</span>
					<input
						className="input-primary"
						type="text"
						name="codigo"
						// pattern="^\S+(?: \S+)*$"
						value={formData.codigo || ''}
						onChange={handleNumber}
						placeholder={formatterText(
							'input.placeholder.max.45.chars',
							'Ingrese hasta 45 caracteres'
						)}
						onBlur={handleErrorCode}
						maxLength="45"
					/>
				</section>
				{codeError && (
					<div
						style={{ fontSize: '0.7rem', color: 'red', marginTop: '-20px', marginRight: '500px' }}
					>
						{codeError}
					</div>
				)}
			</section>
			<section className="form-responsive-container-buttons">
				<button type="submit" className="btn-primary">
					<FormattedMessage id="btn.create.cliente.type" defaultMessage="Crear tipo de cliente" />
				</button>
				<button className="input-cancel" onClick={() => navigate(paths.parameters)}>
					<FormattedMessage id="btn.cancel" defaultMessage="Cancelar" />
				</button>
			</section>
		</form>
	);
}
