import React, { useEffect, useRef, useState } from 'react';
// Import context
import WarningDialog from 'components/tableOptions/WarningDialog';
// Import Hooks
import useLangv2 from 'hooks/useLangv2';
// Import Components
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import UserPaginateActions from 'components/tableOptions/UserPaginateActions';
import 'styles/minimalTable.css';
// Services module
import RowSearchServices from './searchServices/RowSearchServices';
import RowAssociatePrices from './serviceModule/RowAssociatePrices';
import RowCostProductProvider from './serviceModule/RowCostProductProvider';
import RowTypeService from './serviceModule/RowTypeService';
// Product module
import { TableCell } from '@mui/material';
import { useSeachContext } from 'context/SearchContext';
import RowSubcategoryProduct from './product/RowSubcategoryProduct';

export default function TableMinimal({
	titles,
	data,
	type,
	max,
	handleOpen,
	labelTable,
	canSearch,
	getDataMiniTable,
	isEdit,
	update
}) {
	const searchRef = useRef();
	// use Hook of language v2
	const { formatterText } = useLangv2();

	// Call context TableMinimalContext
	const { handleSearch, searchResults = [], setSearchResults } = useSeachContext();

	// Pagination state
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const [dialog, setDialog] = useState({
		text: '',
		active: false,
		function: null
	});

	const closeDialog = () => {
		setDialog({ ...dialog, active: false });
	};

	useEffect(() => {
		if (data) {
			setSearchResults(data);
		}
	}, [data]);

	// Choose a row
	const ChooseRow = (item, i) => {
		switch (type) {
			case 'typeCategoryService':
				return (
					<RowTypeService
						item={item}
						key={i}
						setDialog={setDialog}
						closeDialog={closeDialog}
						handleOpen={handleOpen}
						getDataMiniTable={getDataMiniTable}
					/>
				);
			case 'costoProductoProveedor':
				return (
					<RowCostProductProvider
						item={item}
						key={i}
						setDialog={setDialog}
						closeDialog={closeDialog}
						handleOpen={handleOpen}
						getDataMiniTable={getDataMiniTable}
					/>
				);
			case 'associatePrices':
				return (
					<RowAssociatePrices
						item={item}
						key={i}
						setDialog={setDialog}
						closeDialog={closeDialog}
						handleOpen={handleOpen}
						isEdit={isEdit}
					/>
				);
			case 'searchServices':
				return (
					<RowSearchServices
						item={item}
						key={i}
						setDialog={setDialog}
						closeDialog={closeDialog}
						handleOpen={handleOpen}
					/>
				);
			case 'subCategorias':
				return (
					<RowSubcategoryProduct
						item={item}
						key={i}
						setDialog={setDialog}
						closeDialog={closeDialog}
						handleOpen={handleOpen}
						update={update}
					/>
				);
			default:
				console.log('No se encontro el tipo de tabla');
				break;
		}
	};

	return (
		<>
			<div className={max ? 'table-minimal-container width-100' : 'table-minimal-container'}>
				{canSearch && (
					<div style={{ width: '50%' }} className="wrap">
						<div className="search">
							<input
								ref={searchRef}
								type="text"
								className="searchTerm spacing-b1"
								placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
								onChange={handleSearch}
							/>
							<button
								type="button"
								onClick={() => handleSearch({ target: searchRef.current })}
								className="searchButton"
							>
								<img src={require('../../assets/search.png')} style={{ height: '20px' }} />
							</button>
						</div>
					</div>
				)}
				<TableContainer component={Paper}>
					<Table className="table-minimal">
						<TableHead>
							<TableRow className="infoo">
								{titles.map((title, index) => (
									<TableCell key={index}>{title}</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{(rowsPerPage > 0 && searchResults?.length
								? searchResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								: searchResults ?? []
							).map((item, i) => ChooseRow(item, i))}
						</TableBody>

						{canSearch && (
							<TableFooter
								sx={{
									display: 'flex',
									justifyItems: 'center',
									justifyContent: 'right',
									mt: 2,
									mb: 2
								}}
							>
								<TablePagination
									sx={{
										boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
										marginTop: '25px',
										borderRadius: '10px'
									}}
									rowsPerPageOptions={[
										7,
										25,
										50,
										100,
										{
											label: formatterText('input.placeholder.all.bill'),
											value: searchResults.length ?? 0
										}
									]}
									colSpan={3}
									count={searchResults.length ?? 0}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
									ActionsComponent={UserPaginateActions}
									labelRowsPerPage={formatterText(labelTable) + formatterText('title.per.page')}
									align="center"
								/>
							</TableFooter>
						)}
					</Table>
				</TableContainer>
			</div>
			<WarningDialog dialog={dialog} setDialog={setDialog} />
		</>
	);
}
