import MuiAlert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import UserPaginateActions from 'components/tableOptions/UserPaginateActions';
import { PAGES } from 'constants/lang/services/services/pages';
import { useSeachContext } from 'context/SearchContext';
import { formatterText } from 'hooks/useLangv2';
import React, { useEffect, useState } from 'react';
import DynamicCell from './DynamicCell';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DynamicTable = ({ titles, pageName, getData, routeToEdit, canDeleted, canModify }) => {
	const [open, setOpen] = useState(false);
	const {
		searchResults = [],
		rowsPerPage,
		setRowsPerPage,
		page,
		setPage,
		countriesPage,
		setCountriesPage,
		departmentsPages,
		setDepartmentsPages,
		municipalitisPages,
		setMnicipalitisPages,
		sectorsPages,
		setSectorsPages,
		countriesSheet,
		setCountriesSheet,
		departmentSheet,
		setdepartmentSheet,
		municipalitiesSheet,
		setMunicipalitiesSheet,
		sectorsSheet,
		setSectorsSheet,
		setLeakedData
	} = useSeachContext();

	const handleChangePage = (event, newPage) => {
		switch (pageName) {
			case PAGES.Paises:
				setCountriesSheet(newPage);
				break;
			case PAGES.Departamentos:
				setdepartmentSheet(newPage);
				break;
			case PAGES.Municipalidades:
				setMunicipalitiesSheet(newPage);
				break;
			case PAGES.Sectores:
				setSectorsSheet(newPage);
				break;
			case PAGES.Auditorías:
				setPage(newPage);
				break;
			default:
				setPage(newPage);
				break;
		}
	};

	const handleChangeRowsPerPage = (event) => {
		switch (pageName) {
			case PAGES.Paises:
				setCountriesPage(event.target.value, 10);
				setPage(0);
				break;
			case PAGES.Departamentos:
				setDepartmentsPages(event.target.value, 10);
				setPage(0);
				break;
			case PAGES.Municipalidades:
				setMnicipalitisPages(event.target.value, 10);
				setPage(0);
				break;
			case PAGES.Sectores:
				setSectorsPages(event.target.value, 10);
				setPage(0);
				break;
			case PAGES.Auditorías:
				setRowsPerPage(event.target.value, 10);
				setPage(0);
				break;
		}
	};

	const numPages = (type) => {
		const typeToDataMap = {
			[PAGES.Paises]: countriesSheet,
			[PAGES.Departamentos]: departmentSheet,
			[PAGES.Municipalidades]: municipalitiesSheet,
			[PAGES.Sectores]: sectorsSheet,
			[PAGES.Auditorías]: page
		};

		const dataToRender = typeToDataMap[type];
		return dataToRender;
	};

	const rowsPages = (type) => {
		const typeToDataMap = {
			[PAGES.Paises]: countriesPage,
			[PAGES.Departamentos]: departmentsPages,
			[PAGES.Municipalidades]: municipalitisPages,
			[PAGES.Sectores]: sectorsPages,
			[PAGES.Auditorías]: rowsPerPage
		};

		const dataToRender = typeToDataMap[type];
		return dataToRender;
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	useEffect(() => {
		setLeakedData(searchResults);
	}, [searchResults]);

	return (
		<>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 250 }} aria-label="Tabla de informacion">
					<TableHead>
						<TableRow>
							{titles.map((title, index) => (
								<TableCell
									key={index}
									sx={{
										fontWeight: 'bold'
									}}
									align="left"
								>
									{title}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPages(pageName) > 0
							? searchResults.slice(
									numPages(pageName) * rowsPages(pageName),
									numPages(pageName) * rowsPages(pageName) + rowsPages(pageName)
							  )
							: searchResults
						).map((item, index) => (
							<DynamicCell
								titles={titles}
								key={index}
								columns={item}
								page={pageName}
								getData={getData}
								routeToEdit={routeToEdit}
								canDeleted={canDeleted}
								canModify={canModify}
							/>
						))}
					</TableBody>
					<TableFooter
						sx={{
							display: 'flex',
							justifyItems: 'center',
							justifyContent: 'right',
							mt: 2,
							mb: 2,
							minWidth: 250,
							borderRadius: '10px'
						}}
					>
						<TablePagination
							sx={{
								boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
								marginTop: '25px',
								borderRadius: '10px'
							}}
							rowsPerPageOptions={[
								25,
								50,
								100,
								200,
								{ label: formatterText('input.placeholder.all.bill'), value: searchResults.length }
							]}
							colSpan={3}
							count={searchResults.length}
							rowsPerPage={rowsPages(pageName) || rowsPerPage}
							page={numPages(pageName) || page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={UserPaginateActions}
							labelRowsPerPage={formatterText(pageName) + formatterText('title.per.page')}
							align="center"
							pageName={pageName}
						/>
					</TableFooter>
				</Table>
			</TableContainer>
			<Stack spacing={2} sx={{ width: '100%' }}>
				<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
					<Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
						Se esta actualizando el registro
					</Alert>
				</Snackbar>
			</Stack>
		</>
	);
};

export default DynamicTable;
