import Cookie from 'js-cookie';

class Market {
	constructor(
		nombre = '',
		descripcion = '',
		codigo = '',
		prefijo = '',
		estado = 1,
		fecha_registro = '',
		fecha_modificacion = '',
		usuario_creacion = Cookie.get('idUsuario'),
		usuario_modificacion = Cookie.get('idUsuario')
	) {
		this.nombre = nombre;
		this.descripcion = descripcion;
		this.codigo = codigo;
		this.prefijo = prefijo;
		this.estado = estado;
		this.fecha_creacion = fecha_registro;
		this.fecha_modificacion = fecha_modificacion;
		this.usuario_creacion = usuario_creacion;
		this.usuario_modificacion = usuario_modificacion;
	}
}

export default Market;
