import React from 'react';

// Import Libs
import { FormattedMessage } from 'react-intl';

// Import Paths
import paths from 'services/paths';

// Import ViewModel
import UpdateProviderTypeViewModel from './UpdateProviderType.viewModel';

export default function UpdateProviderType() {
	const {
		active,
		codeError,
		errorName,
		formatterText,
		formData,
		handleChange,
		handleErrorCode,
		handleErrorName,
		handlerTextDescription,
		handleSubmit,
		handleText,
		navigate,
		setActive
	} = UpdateProviderTypeViewModel();

	return (
		<form className="form-responsive" onSubmit={handleSubmit}>
			<section className="waprFom">
				<label className="form-responsive-information__option">
					<span className="warpForm-text">
						<FormattedMessage
							id="table.title.provider.type.name"
							defaultMessage="Nombre tipo proveedor"
						/>
					</span>
					<input
						className={(formData.nombre ? '' : 'input-error ') + 'input-primary'}
						type="text"
						name="nombre"
						value={formData.nombre}
						onChange={handleText}
						placeholder="Nombre tipo proveedor"
						maxLength="100"
						onBlur={handleErrorName}
					/>
				</label>
				{errorName && (
					<div
						style={{
							fontSize: '0.7rem',
							color: 'red',
							marginTop: '-25px',
							marginRight: '600px',
							marginLeft: '210px'
						}}
					>
						{errorName}
					</div>
				)}
				<label className="form-responsive-information__option">
					<span className="p-styles">
						<FormattedMessage id="table.title.description" defaultMessage="Descripción" />
					</span>
					<textarea
						className="input-primary-textarea"
						type="text"
						name="descripcion"
						value={formData.descripcion}
						onChange={handlerTextDescription}
						placeholder="Descripción"
						maxLength="200"
					/>
				</label>
				<label className="form-responsive-information__option">
					<span className="p-styles">
						<FormattedMessage id="table.title.code" defaultMessage="Código" />
					</span>
					<input
						className={(formData.codigo ? '' : 'input-error ') + 'input-primary'}
						type="text"
						//pattern="^\S+(?: \S+)*$"
						name="codigo"
						value={formData.codigo}
						onChange={handleChange}
						placeholder="Ingresa hasta 45 caracteres"
						onBlur={handleErrorCode}
						maxLength="45"
					/>
				</label>
				{codeError && (
					<div
						style={{
							fontSize: '0.7rem',
							color: 'red',
							marginTop: '-25px',
							marginRight: '600px',
							marginLeft: '210px'
						}}
					>
						{codeError}
					</div>
				)}
				<label className="form-responsive-information__option">
					<span className="p-styles">
						<FormattedMessage id="table.title.state" defaultMessage="Estado" />
					</span>
					<label className="form-responsive-label">
						<p className="form-responsive-toggle">
							{active
								? formatterText('p.active', 'Activo')
								: formatterText('p.unActive', 'No activo')}
						</p>
						<label className="switch">
							<input
								checked={!!active}
								onChange={() => {
									setActive(!active);
								}}
								type="checkbox"
							/>
							<span className="slider round"></span>
						</label>
					</label>
				</label>
			</section>
			<section className="form-responsive-container-buttons">
				<button type="submit" className="btn-primary">
					<FormattedMessage id="btn.save.changes" defaultMessage="Guardar cambios" />
				</button>
				<button className="input-cancel" onClick={() => navigate(paths.parameters)}>
					<FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
				</button>
			</section>
		</form>
	);
}
