import useAxios from 'hooks/useAxios';
import { MODULES_NAME } from 'common/validators/PermissionsConstants';
import SortData from 'components/utils/SortData';
import { usePermissionContext } from 'context/PermissionContext';
import { useSeachContext } from 'context/SearchContext';
import dateFormat from 'dateformat';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import { useEffect } from 'react';
import endpoints from 'services/api';

let usersJson = {};
export default function RolsTableViewModel() {
	const { RequestUseCase } = useAxios();

	// hook to loading data
	const {
		loading,
		error,
		toggleLoading,
		toggleError,
		handleClick,
		displayMessage,
		displayLoading
	} = useGetData();
	// useContext de búsqueda
	const { searchResults = [], setSearchResults, setDataTable } = useSeachContext();
	// use Hook of language v2
	const { formatterText } = useLangv2();

	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();
	const permissionsAccessGaranted = () => {
		permissionsAccess(MODULES_NAME.rol);
	};

	useEffect(() => {
		permissionsAccessGaranted();
	}, [permissions]);

	useEffect(() => {
		getDataTable();
	}, []);

	// función para cargar datos
	const getDataTable = () => {
		// show loading
		toggleLoading(true);

		return RequestUseCase.get(endpoints.users.getAllUsers)
			.then((res) => {
				usersJson = res;
				RequestUseCase.get(endpoints.roles.getAllRoles)
					.then((data) => {
						const newArray = [];
						data.forEach((item) => {
							if (item.nombre) {
								handleStructureItems(newArray, item);
							}
						});
						const sortedArray = SortData(newArray, 'asc');
						setDataTable(sortedArray);
						// show loading
						toggleLoading(false);
					})
					.catch(() => {
						// mostrar error
						toggleError(!error);
						handleClick();
					});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleDeleteItem = (rowId) => {
		const deleteItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.roles.deleteRole(rowId))
				.then(async () => {
					await getDataTable();
					setSearchResults([]);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return deleteItemPromise;
	};

	const handleStructureItems = (newArray, item) => {
		const arrayCreated = usersJson.find((user) => {
			return user.idUsuario === item.usuarioCreacion;
		});

		const arrayUpdated = usersJson.find((user) => {
			return user.idUsuario === item.usuarioModificacion;
		});

		let created_by = '';
		let updated_by = '';

		if (arrayCreated) {
			created_by = arrayCreated.usuario;
		} else {
			created_by = 'No esta asociado a ningún usuario';
		}

		if (arrayUpdated) {
			updated_by = arrayUpdated.usuario;
		} else {
			updated_by = 'No esta asociado a ningún usuario';
		}

		const created_at = dateFormat(item.fechaCreacion, 'yyyy/mm/dd - h:MM:ss TT');
		const updated_at = dateFormat(item.fechaModificacion, 'yyyy/mm/dd - h:MM:ss TT');

		newArray.push({
			id: item.idRol,
			nombre: item.nombre || 'No tiene nombre',
			descripcion: item.descripcion,
			created_at,
			updated_at,
			updated_by,
			created_by,
			estado: item.estado,
			objeto: item
		});
	};

	const handleEditStateItem = (body) => {
		const updateItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.roles.updateRole, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return updateItemPromise;
	};

	// titulos de la tabla
	const titles = [
		formatterText('table.title.role', 'Rol'),
		formatterText('table.title.description', 'Descripción'),
		formatterText('table.title.date.creation', 'Fecha de creación'),
		formatterText('table.title.date.modification', 'Fecha de modificación'),
		formatterText('table.title.modified.by', 'Modificado por'),
		formatterText('table.title.created.by', 'Creado por'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];

	return {
		loading,
		error,
		displayMessage,
		displayLoading,
		getDataTable,
		searchResults,
		handleDeleteItem,
		handleEditStateItem,
		permittedActions,
		formatterText,
		titles
	};
}
