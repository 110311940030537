class Sector {
	constructor(
		idSector,
		codigoSector,
		nombre,
		estado,
		kilometros,
		idMunicipio,
		fechaCreacion,
		usuarioCreacion,
		usuarioModificacion
	) {
		this.idSector = idSector;
		this.codigoSector = codigoSector;
		this.nombre = nombre;
		this.estado = estado;
		this.kilometros = kilometros;
		this.idMunicipio = idMunicipio;
		this.fechaCreacion = fechaCreacion;
		this.usuarioCreacion = usuarioCreacion;
		this.usuarioModificacion = usuarioModificacion;
	}
}

export default Sector;
