import { useContext, useEffect, useState } from 'react';
// Import Contexts
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import Hooks
import { useForm } from '@formiz/core';
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';
import { useParams } from 'react-router-dom';
// Import Components
// Import Libs
// Import Services
import { FilterActive } from 'common/validators/FilterActives';
import HandleOnError from 'common/validators/HandleOnError';

export default function AddAdviserViewModel({ onClose, reloadData }) {
	const [selectData, setSelectData] = useState({});

	const { modalData, auxId, setAuxId, otherCurrentDataTable, setOtherCurrentDataTable } =
		useContext(TableMinimalContext);

	const MODEL = {
		emailAsesor: modalData.emailAsesor,
		correoAlternoAsesor: modalData.correoAlternoAsesor
	};

	// manage the petitions
	const { COOKIE_USER, RequestUseCase, endpoints } = useAxios();
	const { updatedItem, noFilledContent, customSB, formatterText } = useLangv2();
	// get id from url
	const { id } = useParams();
	const myForm = useForm();

	const [currentAsesor, setCurrentAsesor] = useState(MODEL);
	const [error, setError] = useState('');
	const [altErr, setAltErr] = useState('');
	const [loadingData, setLoadingData] = useState(true);

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getData = () => {
		setLoadingData(true);

		const promises = [
			RequestUseCase.get(endpoints.zones.getAllMunicipalities),
			RequestUseCase.get(endpoints.institutions.getAllTypeDocument),
			RequestUseCase.get(endpoints.institutions.getAllGenres),
			RequestUseCase.get(endpoints.cargos.getAllCargos)
		];

		Promise.all(promises).then((values) => {
			setLoadingData(false);
			setSelectData({
				...selectData,
				municipalities: FilterActive(values[0]),
				documentTypes: FilterActive(values[1]),
				genres: FilterActive(values[2]),
				cargos: FilterActive(values[3])
			});
		});
	};

	const handleBlurMailAsesor = (e) => {
		if (!error && e.target.value.trim() === '') {
			setError('');
		}
	};

	const handleBlurMailAltAsesor = (e) => {
		if (!altErr && e.target.value.trim() === '') {
			setAltErr('');
		}
	};

	const handleSubmit = (values) => {
		if (
			altErr ||
			error ||
			values.identificacion.trim() === '' ||
			values.nombreAsesor.trim() === '' ||
			values.telefonoAsesor.trim() === '' ||
			values.telefonoAlternoAsesor.trim() === ''
		) {
			noFilledContent();
		} else {
			if (currentAsesor.emailAsesor === currentAsesor.correoAlternoAsesor) {
				customSB('warning', 'warning.alert.same.mails');
				return;
			}
			if (values.telefonoAsesor === values.telefonoAlternoAsesor) {
				customSB('warning', 'warning.alert.same.tlf');
				return;
			}

			let BODY = {
				idTipoDocumentoAsesor: values.idTipoDocumentoAsesor,
				identificacion: values.identificacion,
				genero: values.genero,
				nombreAsesor: values.nombreAsesor,
				idCargo: values.idCargo,
				emailAsesor: currentAsesor.emailAsesor,
				correoAlternoAsesor: currentAsesor.correoAlternoAsesor,
				telefonoAsesor: values.telefonoAsesor,
				telefonoAlternoAsesor: values.telefonoAlternoAsesor,
				idMunicipio: values.idMunicipio,
				estado: 1
			};

			if (auxId !== null) {
				BODY = {
					idAsesor: auxId,
					idCliente: {
						idCliente: parseInt(id)
					},
					idTipoDocumento: {
						idTipoDocumento:
							typeof BODY.idTipoDocumentoAsesor === 'string'
								? JSON.parse(JSON.parse(BODY.idTipoDocumentoAsesor).value).value
								: BODY.idTipoDocumentoAsesor.value
					},
					identificacion: BODY.identificacion,
					nombres: BODY.nombreAsesor,
					idGenero: {
						idGenero:
							typeof BODY.genero === 'string'
								? JSON.parse(JSON.parse(BODY.genero).value).value
								: BODY.genero.value
					},
					idCargo: {
						idCargo:
							typeof BODY.idCargo === 'string'
								? JSON.parse(JSON.parse(BODY.idCargo).value).value
								: BODY.idCargo.value
					},
					idMunicipio: {
						idMunicipio:
							typeof BODY.idMunicipio === 'string'
								? JSON.parse(JSON.parse(BODY.idMunicipio).value).value
								: BODY.idMunicipio.value
					},
					telefono: BODY.telefonoAsesor,
					telefonoAlterno: BODY.telefonoAlternoAsesor,
					correo: BODY.emailAsesor,
					correoAlterno: BODY.correoAlternoAsesor,
					estado: BODY.estado,
					fechaCreacion: modalData.fechaCreacion,
					fechaModificacion: null,
					usuarioCreacion: modalData.usuarioCreacion,
					usuarioModificacion: COOKIE_USER
				};
				RequestUseCase.put(endpoints.advisers.updateAdviser(BODY?.idAsesor, BODY))
					.then(() => {
						onClose();
						updatedItem();
						RequestUseCase.get(endpoints.advisers.getAsesorsByClient(id)).then((resp) =>
							reloadData(resp)
						);
						setAuxId(null);
					})
					.catch((err) => {
						if (err.response?.data?.message) {
							HandleOnError(formatterText(err.response?.data?.message));
						} else {
							HandleOnError(
								formatterText(
									'snackbar.error.process.failed.general',
									'Error al realizar el proceso. Intentalo en otro momento.'
								)
							);
						}
					});
			} else {
				otherCurrentDataTable.forEach((item, index) => {
					if (index === modalData.index) {
						const dataLeft = otherCurrentDataTable.filter((_, i) => {
							return i !== otherCurrentDataTable.indexOf(item);
						});
						setOtherCurrentDataTable([...dataLeft, BODY]);
						onClose();
					}
				});
			}
		}
	};

	return {
		selectData,
		modalData,
		myForm,
		currentAsesor,
		setCurrentAsesor,
		error,
		setError,
		altErr,
		setAltErr,
		loadingData,
		handleBlurMailAsesor,
		handleBlurMailAltAsesor,
		handleSubmit,
		formatterText
	};
}
