import useAxios from 'hooks/useAxios';
import React, { useContext, useEffect, useState } from 'react';
//Material
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import BtnActions from 'common/minimalTables/BtnActions';
import { useSeachContext } from 'context/SearchContext';
import { TableMinimalContext } from 'context/TableMinimalContext';
import useLangv2 from 'hooks/useLangv2';
import { useParams } from 'react-router-dom';
import endpoints from 'services/api';

const RowCostProductProvider = ({
	item,
	i, // index
	setDialog,
	closeDialog,
	handleOpen,
	getDataMiniTable
}) => {
	const { RequestUseCase } = useAxios();
	const [rowState, setRowState] = useState(item.estado);
	const { setDataTable, searchResults = [] } = useSeachContext();
	const { currentDataTable, setCurrentDataTable, setResultsTableSearch, setModalData } =
		useContext(TableMinimalContext);

	const { successRemoveItem } = useLangv2();

	const { id } = useParams();

	// Helps to re-render the component when the state changes
	useEffect(() => {
		setRowState(item.estado);
	}, [searchResults]);

	const handleDelete = (row) => {
		if (typeof row.idPrecioProductoProveedor === 'number') {
			RequestUseCase.delete(
				endpoints.costProductProvider.deleteCostProductProvider(row.idPrecioProductoProveedor)
			)
				.then(() => {
					successRemoveItem();
					getDataMiniTable(id);
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			const newMinimalRows = currentDataTable.filter((et) => {
				return et.idPrecioProductoProveedor !== row.idPrecioProductoProveedor;
			});
			setDataTable(newMinimalRows);
			setCurrentDataTable(newMinimalRows);
			setResultsTableSearch(newMinimalRows);
			successRemoveItem();
		}
		closeDialog();
	};

	const handleEdit = (row) => {
		handleOpen();
		setModalData(row);
	};

	return (
		<TableRow key={i}>
			<TableCell>{item?.idProveedor?.label}</TableCell>
			<TableCell>{item?.idUnidadMedida?.label}</TableCell>
			<TableCell>{item?.costo}</TableCell>
			<TableCell>{item?.iva}</TableCell>
			<TableCell>{item?.idMoneda?.label}</TableCell>

			<BtnActions
				item={item}
				rowState={rowState}
				setDialog={setDialog}
				handleEdit={handleEdit}
				handleDelete={handleDelete}
			/>
		</TableRow>
	);
};
export default RowCostProductProvider;
