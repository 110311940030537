import React, { useEffect, useState } from 'react';
// Import Hooks
import useLangv2 from 'hooks/useLangv2';
// import Comonents
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import customStyles2 from './generic.style.select';
import customStylesSmall from './generic.style.select.s';

const SelectorGrid = ({
	name,
	data,
	placeholder,
	isLoading,
	dataValue,
	setterFunction,
	isRequired,
	disabled,
	newForm,
	shortWidth,
	selectStyle,
	permission = false,
	managePermission = false
}) => {
	// use Hook of language v2
	const { formatterText, showPermissionDenied } = useLangv2();
	const [selectedOption, setSelectedOption] = useState(null);

	useEffect(() => {
		if (newForm) {
			if (dataValue.label !== '') {
				setSelectedOption(dataValue);
			}
		} else {
			setSelectedOption(dataValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataValue]);

	const chooseStyle = () => {
		const SELECT_OPTIUONS = {
			normal: customStyles2,
			small: customStylesSmall
		};

		return SELECT_OPTIUONS[selectStyle] || customStyles2;
	};

	return (
		<div
			className={shortWidth ? 'select-select-responsive w32-container' : 'select-select-responsive'}
		>
			{newForm ? (
				<Select
					styles={chooseStyle}
					onChange={(selectedOption) => {
						setSelectedOption(selectedOption);
						setterFunction((prev) => ({
							...prev,
							[name]: selectedOption
						}));
					}}
					value={selectedOption}
					noOptionsMessage={() =>
						formatterText('select.placeholder.no.options', 'No se encontraron opciones')
					}
					options={data}
					placeholder={
						isLoading ? (
							<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
						) : (
							placeholder
						)
					}
					isDisabled={disabled ? true : false}
				/>
			) : (
				<Select
					styles={customStyles2}
					onChange={(selectedOption) => {
						if (managePermission) {
							if (permission) {
								setSelectedOption(selectedOption);
								setterFunction((prev) => ({
									...prev,
									[name]: selectedOption
								}));
							} else {
								showPermissionDenied();
							}
						} else {
							setSelectedOption(selectedOption);
							setterFunction((prev) => ({
								...prev,
								[name]: selectedOption
							}));
						}
					}}
					noOptionsMessage={() =>
						formatterText('select.placeholder.no.options', 'No se encontraron opciones')
					}
					value={dataValue.id}
					options={data}
					placeholder={
						isLoading ? (
							<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
						) : (
							placeholder
						)
					}
					isDisabled={disabled ? true : false}
				/>
			)}
			<input
				className="input-required"
				type="text"
				value={selectedOption || ''}
				autoComplete="off"
				required={isRequired === true ? true : false}
				style={{ visibility: 'hidden' }}
			/>
			{/* <button onClick={clearValue}>clear</button> */}
		</div>
	);
};

export default SelectorGrid;
