class NotificationPlus {
	constructor(
		ticket = '',
		expediente = '',
		idCliente = '',
		fechaNotifiacion = '',
		mensaje = '',
		idCategoria = '',
		idMunicipio = ''
	) {
		this.ticket = ticket;
		this.expediente = expediente;
		this.idCliente = idCliente;
		this.fechaNotifiacion = fechaNotifiacion;
		this.mensaje = mensaje;
		this.idCategoria = idCategoria;
		this.idMunicipio = idMunicipio;
	}
}

export default NotificationPlus;
