import { Formiz } from '@formiz/core';
import { InputFieldResponsive } from 'common/inputs/InputFieldResponsive';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import paths from 'services/paths';
import CreatePositionViewModel from './CreatePosition.viewModel';

export default function CreatePosition() {
	const { formatterText, handleFormChange, handleSubmit, myForm, navigate } =
		CreatePositionViewModel();

	return (
		<Formiz onChange={handleFormChange} connect={myForm}>
			<form className="form-responsive" onSubmit={handleSubmit}>
				<section className="form-responsive-container-information">
					<section className="form-responsive-information__option">
						<InputFieldResponsive
							type="text"
							name="nombre"
							labelText={formatterText('table.title.position.name', 'Nombre cargo')}
							placeholder={formatterText('input.placeholder.position.name', 'Nombre del cargo')}
							validateInput="namestage"
							maxLength="100"
							required={formatterText('p.position.is.required')}
						/>
					</section>
					<section className="form-responsive-information__option">
						<InputFieldResponsive
							type="text"
							area={true}
							name="descripcion"
							styleName="input-primary-textarea"
							labelText={formatterText('table.title.description', 'Descripción')}
							placeholder={formatterText('table.title.description', 'Descripción')}
							validateInput="text"
							maxLength="200"
							required={formatterText('p.description.is.required')}
						/>
					</section>
					<section className="form-responsive-information__option">
						<InputFieldResponsive
							type="text"
							name="codigo"
							labelText={formatterText('p.code', 'codigo')}
							placeholder={formatterText('input.placeholder.position.code', 'Código del cargo')}
							validateInput="code"
							maxLength="45"
							required={formatterText('table.title.code.required')}
						/>
					</section>
				</section>

				<section className="form-responsive-container-buttons">
					<button type="submit" className="btn-primary">
						<FormattedMessage id="btn.save.changes" defaultMessage="Guardar cambios" />
					</button>
					<button className="input-cancel" onClick={() => navigate(paths.positions)}>
						<FormattedMessage id="btn.cancel" defaultMessage="Cancelar" />
					</button>
				</section>
			</form>
		</Formiz>
	);
}
