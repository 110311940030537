import { useEffect, useState } from 'react';

// Import Libs
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Cookie from 'js-cookie';

// Import Components
import CustomAlert from 'components/CustomAlert';

// Import Contexts
import { useSeachContext } from 'context/SearchContext';

// Import Hooks
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';

// Import Utils
import HandleOnError from 'common/validators/HandleOnError';
import { validateDuplicateSectorCode } from 'common/validators/ValidateDuplicates';

// Import Paths
import paths from 'services/paths';

// Import ViewModel
import { UpsertSectorFormViewModel } from '../UpsertSector/components';

export default function CreateSectorViewModel() {
	const { endpoints, RequestUseCase } = useAxios();
	const { setDataTable } = useSeachContext();
	const navigate = useNavigate();

	const [toggleSelector, setToggleSelector] = useState(false);
	const [selectedPermisosModulos, setSelectedPermisosModulos] = useState([]);
	const [permisosModulos, setPermisosModulos] = useState([]);

	// use Hook of language v2
	const { formatterText } = useLangv2();

	const handleSubmit = async (values, actions) => {
		actions.setSubmitting(true);
		const data = parseDataToSubmit({ ...values, usuarioCreacion: Cookie.get('idUsuario') });
		const responseDuplicateCodeSector = await validateDuplicateSectorCode(
			values.codigoSector,
			'El código del sector ya existe',
			() => RequestUseCase.get(endpoints.zones.getAllSectors)
		);

		if (!responseDuplicateCodeSector) {
			Swal.fire({
				title: formatterText(
					'alert.title.general',
					'Atención, estás seguro de realizar esta acción'
				),
				text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
				icon: 'question',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				showLoaderOnConfirm: true,
				cancelButtonColor: '#d33',
				confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
				allowOutsideClick: false,
				cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
				preConfirm: () => {
					return new Promise((resolve) => {
						RequestUseCase.post(endpoints.zones.addSector, data)
							.then(() => {
								resolve(
									CustomAlert('confirm_msg', {
										icon: 'success',
										title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
										text: formatterText(
											'alert.message.confirm.created.general',
											'El registro se ha creado correctamente'
										),
										confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
										allowOutsideClick: false,
										executeFunction: () => navigate(paths.sector)
									})
								);
							})
							.catch((err) => {
								if (err.response?.data?.message) {
									HandleOnError(formatterText(err.response?.data?.message));
								} else {
									HandleOnError(
										formatterText(
											'snackbar.error.process.failed.general',
											'Error al realizar el proceso. Intentalo en otro momento.'
										)
									);
								}
							})
							.finally(() => actions.setSubmitting(false));
					});
				}
			}).then((result) => {
				if (result.isDismissed) {
					actions.setSubmitting(false);
				}
			});
		} else {
			actions.setSubmitting(false);
		}
	};

	const { formik, parseDataToSubmit } = UpsertSectorFormViewModel({
		onSubmit: handleSubmit
	});

	const getMunicipalities = () => {
		RequestUseCase.get(endpoints.zones.getAllMunicipalities)
			.then((municipalities) => {
				const newMunicipalities = [];
				municipalities.forEach((municipality) => {
					if (municipality.estado === 1) {
						newMunicipalities.push(municipality);
					}
				});
				setPermisosModulos(newMunicipalities);
				setDataTable(newMunicipalities);
			})
			.catch(console.error);
	};

	const handleRemoveRole = (e, permisosModulo) => {
		e.preventDefault();
		const selected = selectedPermisosModulos.filter(
			(currentPermisosModulo) => currentPermisosModulo.idMunicipio !== permisosModulo.idMunicipio
		);
		setSelectedPermisosModulos(selected);
		setPermisosModulos([...permisosModulos, permisosModulo]);
		setDataTable([...permisosModulos, permisosModulo]);
	};

	const handleAddRole = (permiso) => {
		const newPermisosModulos = permisosModulos.filter(
			(currentRol) => currentRol.idMunicipio !== permiso.idMunicipio
		);
		setDataTable(newPermisosModulos);
		setPermisosModulos(newPermisosModulos);
		const selected = [...selectedPermisosModulos, permiso];
		setSelectedPermisosModulos(selected);
		setToggleSelector(!toggleSelector);
	};

	useEffect(() => {
		getMunicipalities();
	}, []);

	return {
		formatterText,
		formik,
		handleAddRole,
		handleRemoveRole,
		navigate,
		setToggleSelector,
		toggleSelector
	};
}
