class ContractType {
	constructor(
		idTipoContrato = '',
		nombre = '',
		descripcion = '',
		codigo = '',
		estado = '',
		fechaCreacion = '',
		fechaModificacion = '',
		usuarioCreacion = '',
		usuarioModificacion = ''
	) {
		this.idTipoContrato = idTipoContrato;
		this.nombre = nombre;
		this.descripcion = descripcion;
		this.codigo = codigo;
		this.estado = estado;
		this.fechaCreacion = fechaCreacion;
		this.fechaModificacion = fechaModificacion;
		this.usuarioCreacion = usuarioCreacion;
		this.usuarioModificacion = usuarioModificacion;
	}
}

export default ContractType;
