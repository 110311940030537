/**
 * Convierte un formato moneda a numero
 * @param {*} value
 * @return value formatted
 *
 * @author rfarfan-qvision
 */

const formatCurrencyFromModalToTable = (value) => {
	const parts = value.toString().split('.');
	let formattedValue = `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '')}`;

	if (parts.length > 1) {
		formattedValue += `.${parts[1].slice(0, 2)}`;
	}

	return formattedValue;
};
export default formatCurrencyFromModalToTable;
