import { useFormik } from 'formik';
import { useState } from 'react';
import { getFormikInitialValues, getFormikValidationSchema, parseDataToSubmit } from '../../utils';

export const FormVIewModel = ({
	onSubmit,
	formatterText,
	handleChangeMulti,
	idCategory,
	selectorData,
	selectValues,
	setSelectValues
}) => {
	const [formikInitialValues, setFormikInitialValues] = useState(getFormikInitialValues());
	const formik = useFormik({
		initialValues: formikInitialValues,
		validationSchema: getFormikValidationSchema(formatterText),
		enableReinitialize: false,
		onSubmit
	});
	return {
		formik,
		formikInitialValues,
		handleChangeMulti,
		idCategory,
		parseDataToSubmit,
		selectorData,
		selectValues,
		setFormikInitialValues,
		setSelectValues
	};
};
