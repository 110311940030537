import useAxios from 'hooks/useAxios';
import { useForm } from '@formiz/core';
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';
import useLang from 'hooks/useLang';
import useLangv2 from 'hooks/useLangv2';
import { useEffect, useState } from 'react';
import 'react-quizz-spanish/lib/assets/antd.css';
import { defaultMessages } from 'react-quizz-spanish/lib/translations/TranslatedText';
import { useNavigate } from 'react-router-dom';
import endpoints from 'services/api';
import paths from 'services/paths';
import Swal from 'sweetalert2';
import en_text from '../utils/en-US.json';
import es_text from '../utils/es-ES.json';

export default function CreateFormViewModel() {
	const { RequestUseCase } = useAxios();

	// Formiz object
	const myForm = useForm();
	// Modal config
	const navigate = useNavigate();
	// useLanguage
	const { formatterText } = useLang();
	const { noAnswerRequired, atLeastOneQuestion } = useLangv2();

	let lang = localStorage.getItem('lang').slice(-2);
	const language = {
		ES: es_text,
		US: en_text
	};
	const outAux = language[lang] || language['ES'];
	const [selectLang, setSelectLang] = useState(outAux);
	const [loading, setLoading] = useState(false);
	defaultMessages['es'] = selectLang;
	const [form, setForm] = useState([]);
	const [isRequired, setIsRequired] = useState(false);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			lang = localStorage.getItem('lang').slice(-2);
			setSelectLang(outAux);
			setLoading(false);
		}, 0);
	}, [lang]);

	useEffect(() => {
		if (form.length > 0) {
			const atLeastOne = form.some((question) => question.required === true);
			setIsRequired(atLeastOne);
		} else {
			setIsRequired(false);
		}
	}, [form]);

	// This function is executed when the create button is clicked
	const handleSubmit = (values) => {
		const data = {
			nombre: values.nombre,
			descripcion: values.descripcion || '',
			codigo: values.code.toLowerCase()
		};
		createForm(data);
	};

	const createForm = (data) => {
		if (form.length === 0) {
			atLeastOneQuestion();
			return;
		}
		if (form.length > 0 && !isRequired) {
			noAnswerRequired();
		} else {
			Swal.fire({
				title: formatterText('alert.title.general'),
				text: formatterText('alert.description.create.general'),
				icon: 'question',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				showLoaderOnConfirm: true,
				cancelButtonColor: '#d33',
				confirmButtonText: formatterText('alert.button.confirm.general'),
				allowOutsideClick: false,
				cancelButtonText: formatterText('alert.button.cancel.general'),
				preConfirm: () => {
					return new Promise((resolve, reject) => {
						RequestUseCase.post(endpoints.formServices.addFormService, data)
							.then((res) => {
								if (form.length > 0) {
									createQuestion(res.idFormulario, resolve, reject);
								} else {
									CustomAlert('confirm_msg', {
										icon: 'success',
										title: formatterText('alert.title.confirm.general'),
										text: formatterText('alert.message.confirm.created.general'),
										confirmButtonText: formatterText('dialog.title.delete.confirm'),
										allowOutsideClick: false,
										executeFunction: () => navigate(paths.services)
										//
									});
								}
							})
							.catch((err) => {
								if (err.response?.data?.message) {
									HandleOnError(formatterText(err.response?.data?.message));
								} else {
									HandleOnError(formatterText('DRF'));
								}
							});
					});
				}
			});
		}
	};

	const getIdByElement = (element) => {
		switch (element) {
			case 'Select':
				return 1;
			case 'Checkboxes':
				return 2;
			case 'RadioButtons':
				return 3;
			case 'MultiLineInput':
				return 4;
			case 'DatePicker':
				return 6;
			default:
				return 5;
		}
	};

	const createQuestion = (id, resolve, reject) => {
		const promises = form.map((item, index) => {
			const question = {
				idFormulario: id,
				orden: index + 1,
				titulo: item.questions.es,
				descripcion: item.questions.es,
				obligatoria: item.required === true ? 1 : 0
			};

			RequestUseCase.post(endpoints.formServices.addQuestionForm, question)
				.then((res) => {
					if (item.options !== undefined) {
						item.options.map((options) => {
							if (Object.keys(options).length > 0) {
								const optionsQuestion = {
									idTipoObjetoFormulario: getIdByElement(item.element),
									idPreguntaFormulario: res.idPreguntaFormulario,
									orden: index + 1,
									descripcion: options.text.es
								};
								return RequestUseCase.post(
									endpoints.formServices.addOptionsQuestion,
									optionsQuestion
								).then((resl) => {
									resolve(resl);
								});
							}
						});
					} else {
						const optionsQuestion = {
							idTipoObjetoFormulario: getIdByElement(item.element),
							idPreguntaFormulario: res.idPreguntaFormulario,
							orden: index + 1,
							descripcion: 'W/O OPTIONS'
						};
						return RequestUseCase.post(
							endpoints.formServices.addOptionsQuestion,
							optionsQuestion
						).then((resl) => {
							resolve(resl);
						});
					}
				})
				.catch((err) => {
					console.error(err);
				});
		});
		Promise.all(promises)
			.then(() => {
				CustomAlert('confirm_msg', {
					icon: 'success',
					title: formatterText('alert.title.confirm.general'),
					text: formatterText('alert.message.confirm.created.general'),
					confirmButtonText: formatterText('alert.button.continue'),
					allowOutsideClick: false,
					executeFunction: () => navigate(paths.services)
					//
				});
			})
			.catch((err) => {
				if (err.response.status === 400 || err.response.status === 420) {
					reject(HandleOnError(formatterText('alert.message.code.error.general')));
				} else {
					reject(HandleOnError(formatterText('ABC')));
				}
				console.error(err);
			});
	};

	return { myForm, handleSubmit, setForm, loading, formatterText, navigate };
}
