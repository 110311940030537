import useAxios from 'hooks/useAxios';

import { useEffect, useState } from 'react';

// Import Contexts
import { useSeachContext } from 'context/SearchContext';
// Import Hooks
import useGetData from 'hooks/useGetData';

import useLangv2 from 'hooks/useLangv2';
// Import services

export default function MarketTableViewModel({ permisos }) {
	const { RequestUseCase, endpoints } = useAxios();

	const { loading, error, toggleLoading, displayMessage, displayLoading } = useGetData();

	const { setDataTable } = useSeachContext();

	const [parameters, setParameters] = useState(null);

	// use Hook of language v2
	const { formatterText } = useLangv2();

	useEffect(() => {
		toggleLoading(true);
		getDataTable();
		toggleLoading(false); //Agregar el loading
	}, [permisos]);

	// función para cargar datos
	const getDataTable = () => {
		RequestUseCase.get(endpoints.markets.getAllMarkets).then((data) => {
			const newArray = [];
			data.forEach((item) => handleStructureItems(newArray, item)); //poner el response
			setParameters(newArray);
			setDataTable(newArray);
		});
	};

	const handleDeleteItem = (rowId) => {
		const deleteItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.markets.deleteMarket(rowId))
				.then((res) => {
					const newArray = [];
					res.forEach((item) => handleStructureItems(newArray, item));
					setParameters(newArray);
					setDataTable(newArray);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return deleteItemPromise;
	};

	const handleEditStateItem = (body) => {
		const updateItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.markets.updateMarket(body?.idMercado), body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return updateItemPromise;
	};

	const handleStructureItems = (newArray, item) => {
		const paises =
			item.paises === null
				? 'No hay paises'
				: item.paises.map((pais, index) =>
						index === item.paises.length - 1 ? pais.nombrePais + ' ' : pais.nombrePais + ', '
				  );

		newArray.push({
			id: item.idMercado,
			nombre: item.nombre,
			descripcion: item.descripcion,
			paises: paises,
			codigo: item.codigo,
			estado: item.estado,
			objeto: { ...item }
		});
	};

	const titles = [
		formatterText('table.title.market.name', 'Nombre mercado'),
		formatterText('table.title.description', 'Descripción'),
		formatterText('tab.title.countries', 'Países'),
		formatterText('table.title.code', 'Código'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];

	return {
		displayLoading,
		displayMessage,
		error,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		loading,
		parameters,
		titles
	};
}
