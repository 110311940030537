import useAxios from 'hooks/useAxios';
import { useForm } from '@formiz/core';
import { TableMinimalContext } from 'context/TableMinimalContext';
import { useContext, useEffect, useState } from 'react';

export default function AddBranchOfficeViewModel({ onClose }) {
	const { endpoints, RequestUseCase } = useAxios();

	const [selectData, setSelectData] = useState({});

	const { modalData, currentDataTable, setCurrentDataTable } = useContext(TableMinimalContext);

	const myForm = useForm();

	const [loadingData, setLoadingData] = useState(true);

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getData = () => {
		setLoadingData(true);

		const promesas = [
			RequestUseCase.get(endpoints.zones.getAllMunicipalities),
			RequestUseCase.get(endpoints.zones.getAllSectors)
		];

		Promise.all(promesas).then((values) => {
			setLoadingData(false);
			setSelectData({
				...selectData,
				municipalities: values[0],
				sectors: values[1]
			});
		});
	};

	const handleSubmit = (values) => {
		const BODY = {
			direccion: values.direccion,
			datosAdicionales: values.datosAdicionales,
			observaciones: values.observaciones,
			estado: 1,
			idCiudadMunicipio: values.idCiudadMunicipio,
			idSector: values.idSector
		};

		currentDataTable.forEach((item, index) => {
			if (index === modalData.index) {
				const dataLeft = currentDataTable.filter((_, i) => {
					return i !== currentDataTable.indexOf(item);
				});
				setCurrentDataTable([...dataLeft, BODY]);
				onClose();
			}
		});
	};

	return {
		selectData,
		loadingData,
		myForm,
		modalData,
		handleSubmit
	};
}
