import useAxios from 'hooks/useAxios';

import { useEffect, useState } from 'react';

// Import Contexts
import { useSeachContext } from 'context/SearchContext';
// Import Hooks
import useGetData from 'hooks/useGetData';

import useLangv2 from 'hooks/useLangv2';
// Import Services

export default function ProviderTypeTableViewModel() {
	const { RequestUseCase, endpoints } = useAxios();

	const { error, toggleLoading, toggleError, handleClick, displayMessage, displayLoading } =
		useGetData();

	const { setDataTable } = useSeachContext();

	const [parameters, setParameters] = useState(null);

	// use Hook of language v2
	const { formatterText } = useLangv2();

	useEffect(() => {
		toggleLoading(true);
		getDataTable();
		toggleLoading(false);
	}, []);

	// función para cargar datos
	const getDataTable = () => {
		RequestUseCase.get(endpoints.providerType.getAllProviderTypes)
			.then((data) => {
				const newArray = [];
				data.forEach((item) => handleStructureItems(newArray, item));
				setParameters(newArray);
				setDataTable(newArray);
			})
			.catch(() => {
				// mostrar error
				toggleError(!error);
				handleClick();
			});
	};

	const handleDeleteItem = (rowId) => {
		const deleteItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.providerType.deleteProviderType(rowId))
				.then((res) => {
					const newArray = [];
					res.forEach((item) => handleStructureItems(newArray, item));
					setParameters(newArray);
					setDataTable(newArray);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return deleteItemPromise;
	};

	const handleEditStateItem = (body) => {
		const updateItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.providerType.updateProviderType, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return updateItemPromise;
	};

	const handleStructureItems = (newArray, item) => {
		newArray.push({
			id: item.idTipoProveedor,
			nombre: item.nombre,
			descripcion: item.descripcion,
			codigo: item.codigo,
			estado: item.estado,
			objeto: { ...item }
		});
	};

	const titles = [
		formatterText('table.title.provider.type.name', 'Nombre tipo proveedor'),
		formatterText('table.title.description', 'Descripción'),
		formatterText('table.title.code', 'Código'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];

	return {
		displayLoading,
		displayMessage,
		error,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		parameters,
		titles
	};
}
