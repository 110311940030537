import useAxios from 'hooks/useAxios';
import { useSeachContext } from 'context/SearchContext';
import dateFormat from 'dateformat';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import { useEffect } from 'react';

export default function SectorsViewModel() {
	const { endpoints, RequestUseCase } = useAxios();
	const { setDataTable, searchResults = [], setSearchResults } = useSeachContext();
	const {
		loading,
		error,
		toggleLoading,
		toggleError,
		handleClick,
		displayMessage,
		displayLoading
	} = useGetData();

	useEffect(() => {
		(async () => {
			toggleLoading(true);
			await getData();
			toggleLoading(false);
		})();
	}, []);

	const getData = () => {
		RequestUseCase.get(endpoints.zones.getAllSectors)
			.then((data) => {
				const newArray = data.map((item) => ({
					codigoSector: item.codigoSector,
					nombre: item.nombre,
					codigoMunicipio: item.idMunicipio.codigoMunicipio,
					nombreDepartamento: item.idMunicipio.idDepartamento.nombre,
					nombrePais: item.idMunicipio.idDepartamento.idPais.nombrePais,
					estado: item.estado,
					kilometros: item.kilometros,
					fechaCreacion: dateFormat(item.fechaCreacion, 'yyyy/mm/dd - h:MM:ss TT'),
					fechaModificacion:
						item.fechaModificacion !== null
							? dateFormat(item.fechaModificacion, 'yyyy/mm/dd - h:MM:ss TT')
							: dateFormat(item.fechaCreacion, 'yyyy/mm/dd - h:MM:ss TT'),
					id: item.idSector,
					objeto: item.idMunicipio,
					usuarioCreacion: item.usuarioCreacion,
					fc: item.fechaCreacion,
					uc: item.usuarioCreacion
				}));

				setDataTable(newArray);
				setSearchResults(newArray);
			})
			.catch(() => {
				toggleError(!error);
				handleClick();
			});
	};

	// use Hook of language v2
	const { formatterText } = useLangv2();

	const titles = [
		formatterText('table.title.sectorCode', 'Código sector'),
		formatterText('table.title.sectorName', 'Nombre sector'),
		formatterText('table.title.muniCode', 'Código municipio'),
		formatterText('table.title.departmentName', 'Nombre departamento'),
		formatterText('table.title.countryName', 'Nombre país'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.title.km', 'Kilómetros'),
		formatterText('table.title.creationD', 'Fecha creación'),
		formatterText('table.title.modificationDate', 'Fecha modificación'),
		formatterText('table.actions', 'Acciones')
	];
	return { loading, error, displayMessage, displayLoading, searchResults, getData, titles };
}
