import React from 'react';

// Import Components
import ProductsTableComponent from './TechnicalTable.component';

// Import Contexts
import { SearchWrapper } from 'context/SearchContext';
import { PermissionWrapper } from 'context/PermissionContext';

const ProductsTable = () => {
	return (
		<SearchWrapper>
			<PermissionWrapper>
				<ProductsTableComponent />
			</PermissionWrapper>
		</SearchWrapper>
	);
};
export default ProductsTable;
