import React from 'react';
import paths from 'services/paths';
import SimpleHeader from 'common/SimpleHeader';
import { FormattedMessage } from 'react-intl';
import RecoverPasswordViewModel from './RecoverPassword.viewModel';
import 'styles/loginRecover.css';

export default function RecuperarPassword() {
	const { isLoading, setUsername, sendRecoverPassword, formatterText, navigate } =
		RecoverPasswordViewModel();
	return (
		<>
			<SimpleHeader />
			<section className="recover-password">
				<form className="recover-password-container" onSubmit={sendRecoverPassword}>
					<h4 className="recover-password-title">
						<FormattedMessage id="menu.recover" defaultMessage="Recupera tu contraseña" />
					</h4>
					<input
						className="input-primary spacing"
						type="text"
						placeholder={formatterText('input.placeholder.user.name')}
						name="username"
						id="username"
						onChange={(e) => setUsername(e.target.value)}
						maxLength="25"
						autoComplete="off"
						required
					/>
					<button type="submit" className="btn-primary" disabled={isLoading}>
						<FormattedMessage id="menu.validate" defaultMessage="validar" />
					</button>
					<p className="back" onClick={() => navigate(paths.login)}>
						<FormattedMessage id="menu.goback" defaultMessage="Regresar al inicio de sesión" />
					</p>
				</form>
			</section>
		</>
	);
}
