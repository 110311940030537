import useAxios from 'hooks/useAxios';
import { useContext, useEffect, useState } from 'react';
import { TableMinimalContext } from 'context/TableMinimalContext';
import useLang from 'hooks/useLang';
import { useNavigate } from 'react-router-dom';
import CustomAlert from 'components/CustomAlert';
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import CategoryService from 'models/CategoryService';
import axios from 'axios';
import { FilterActive } from 'common/validators/FilterActives';
import HandleInput from 'common/validators/HandleInput';
import HandleOnError from 'common/validators/HandleOnError';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import { CODEREGEX, TEXTREGEX } from 'common/validators/Regex';
import { useSeachContext } from 'context/SearchContext';
import useLangv2 from 'hooks/useLangv2';
import endpoints from 'services/api';
import paths from 'services/paths';
import { useForm } from '@formiz/core';

export default function CreateServiceCategoryViewModel() {
	const { RequestUseCase } = useAxios();

	// Modal config
	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	// Call context TableMinimalContext
	const { currentDataTable, setCurrentDataTable } = useContext(TableMinimalContext);

	// Set contextMinimalTable empty
	useEffect(() => {
		getCategories();
		getProductCategories();
	}, []);

	const { setDataTable, dataTable } = useSeachContext();

	//  Call hook of language
	const { formatterText } = useLang();

	const { handleRequestError } = useLangv2();

	// Formiz object
	const myForm = useForm();

	// title of minimal table
	const titlesTableSubCategories = [
		formatterText('p.name.type.service', 'Nombre tipo de servicio'),
		formatterText('table.title.description', 'Descripción'),
		formatterText('table.title.code', 'Código'),
		formatterText('table.title.categoryProduct', 'Categorías de productos'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];
	const navigate = useNavigate();
	// Example of form data
	const [formData, setFormData] = useState(new CategoryService());
	const [currentItemMinimal, setCurrentItemMinimal] = useState({
		idTipoServicio: uuidv4(),
		nombre: '',
		codigo: '',
		categorias: [],
		estado: 1,
		descripcion: ''
	});
	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		createItem(formData);
	};

	// Obtener las categorias
	const getCategories = () => {
		RequestUseCase.get(endpoints.productCategoryService.getAll);
	};

	// Call the service POST
	const createItem = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general'),
			text: formatterText('alert.description.create.general'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					RequestUseCase.post(endpoints.serviceCategory.addServiceCategory, data)
						.then((res) => {
							associateTypeService(res.idCategoriaServicio, resolve);
						})
						.catch((err) => {
							handleRequestError(err);
							reject();
							Swal.close();
						});
				});
			}
		});
	};
	// Call the service POST to associate Type Service
	const associateTypeService = (idM, resolve) => {
		if (dataTable.length > 0) {
			const bodyarray = [];
			const promesas = dataTable.map((item) => {
				const data = {
					nombre: item.nombre,
					idCategoriaServicio: {
						idCategoriaServicio: idM
					},
					descripcion: item.descripcion,
					codigo: item.codigo
				};
				return RequestUseCase.post(endpoints.typeService.addTypeService, data)
					.then((response) => {
						if (item.categorias.length > 0) {
							const categorias = item.categorias[0].split(', ');
							const catId = categorias.map((cat) => {
								const partes = cat.split(' - ');
								return { label: partes[0], value: partes[1] };
							});
							catId.map((id) => {
								bodyarray.push({
									idCategoriaProductoTipoServicio: null,
									idCategoriaProducto: id.value,
									idTipoServicio: response.idTipoServicio,
									usuarioCreacion: Cookie.get('idUsuario')
								});
							});
						}
					})
					.catch((err) => {
						handleRequestError(err);
					});
			});
			Promise.all(promesas)
				.then(() => {
					if (bodyarray.length > 0) {
						RequestUseCase.post(endpoints.productCategoryService.saveMultiple, bodyarray)
							.then(() => {
								resolve(
									CustomAlert('confirm_msg', {
										icon: 'success',
										title: formatterText('alert.title.confirm.general'),
										text: formatterText('alert.message.associations.general'),
										confirmButtonText: formatterText('alert.button.continue'),
										allowOutsideClick: false,
										executeFunction: () => navigate(paths.services)
									})
								);
							})
							.catch((err) => {
								handleRequestError(err);
								Swal.close();
							});
					} else {
						resolve(
							CustomAlert('confirm_msg', {
								icon: 'success',
								title: formatterText('alert.title.confirm.general'),
								text: formatterText('alert.message.associations.general'),
								confirmButtonText: formatterText('alert.button.continue'),
								allowOutsideClick: false,
								executeFunction: () => navigate(paths.services)
							})
						);
					}
				})
				.catch((err) => {
					handleRequestError(err);
				});
		} else {
			resolve(
				CustomAlert('confirm_msg', {
					icon: 'success',
					title: formatterText('alert.title.confirm.general'),
					text: formatterText('alert.message.confirm.created.general'),
					confirmButtonText: formatterText('alert.button.continue'),
					allowOutsideClick: false,
					executeFunction: () => navigate(paths.services)
				})
			);
		}
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	const [cat, setCat] = useState([]);

	// Add a product in the dynamic minimal table
	const handleAddItemMinimal = () => {
		if (
			!!currentItemMinimal.nombre &&
			!!currentItemMinimal.codigo &&
			currentItemMinimal.categorias.length > 0
		) {
			const filter = currentDataTable.filter(
				(item) =>
					item.codigo.toString().toLowerCase() ===
					currentItemMinimal.codigo.toString().toLowerCase()
			);

			if (filter.length > 0) {
				HandleOnError(formatterText('p.label.title.codigoExistenteElemento'));
			} else {
				const categorias = currentItemMinimal.categorias.map((cat) => ({
					label: cat.label.toString(),
					value: cat.value.toString()
				}));

				const categoriasArray = categorias.map(
					({ label, value }) => `${label} - ${value.toString()}`
				);
				const categoriasString = categoriasArray.join(', ');

				const newItems = [
					{
						idTipoServicio: currentItemMinimal.idTipoServicio,
						nombre: currentItemMinimal.nombre,
						codigo: currentItemMinimal.codigo,
						categorias: [categoriasString],
						estado: currentItemMinimal.estado,
						descripcion: currentItemMinimal.descripcion
					}
				];

				setDataTable((prevData) => [...prevData, ...newItems]);
				setCat([categorias]);
				setCurrentDataTable((prevData) => [...prevData, ...newItems]);
				setCurrentItemMinimal({
					idTipoServicio: uuidv4(),
					nombre: '',
					codigo: '',
					categorias: [],
					estado: 1,
					descripcion: ''
				});
				setSelectValues([]);
			}
		} else {
			HandleOnError(
				formatterText(
					'p.label.title.debesCompletarTodosLosCampos',
					'Debes completar todos los campos'
				)
			);
		}
	};

	const validateRegex = (value, regex) => {
		const isMatch = value.match(regex);
		if (isMatch) {
			setCurrentItemMinimal({ ...currentItemMinimal, codigo: value });
		}
	};

	const handleChangeSubCategory = (e) => {
		const { value = '' } = e.target;

		const valueWithoutSpaces = (value || '').replaceAll(/\s{2,}/gi, ' ');
		switch (e.target.name) {
			case 'codigo':
				validateRegex(valueWithoutSpaces?.toLowerCase(), CODEREGEX);
				break;
			case 'descripcion':
				HandlerTextDescription(e, currentItemMinimal, setCurrentItemMinimal);
				break;
			default:
				HandleInput(e, TEXTREGEX, currentItemMinimal, setCurrentItemMinimal, true);
				break;
		}
	};

	const handleSelectChange = (e) => {
		setSelectValues(e);
		setCurrentItemMinimal({
			...currentItemMinimal,
			categorias: e
		});
	};

	// State of the labels and ids of the selectors
	const [selectValues, setSelectValues] = useState([]);

	const [productCategories, setProductCategories] = useState([]);

	const getProductCategories = () => {
		axios(endpoints.productCategory.getAllProductCategory).then((response) => {
			setProductCategories(
				FilterActive(response.data).map((obj) => ({
					label: obj.nombre,
					value: obj.idCategoriaProducto
				}))
			);
		});
	};

	const handleFormChange = (values) => {
		setFormData((prevData) => ({ ...prevData, ...values }));
	};

	console.log('categorias:', currentItemMinimal.categorias);
	return {
		myForm,
		handleFormChange,
		handleSubmit,
		formData,
		handlerTextDescription,
		handleAddItemMinimal,
		handleChangeSubCategory,
		handleSelectChange,
		selectValues,
		productCategories,
		currentItemMinimal,
		currentDataTable,
		titlesTableSubCategories,
		handleOpen,
		handleClose,
		open,
		cat,
		navigate,
		formatterText
	};
}
