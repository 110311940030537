import React from 'react';
import { SearchWrapper } from 'context/SearchContext';
import { PermissionWrapper } from 'context/PermissionContext';
import ServiceTableComponent from './ServiceTable.component';

const ServiceTable = () => {
	return (
		<SearchWrapper>
			<PermissionWrapper>
				<ServiceTableComponent />
			</PermissionWrapper>
		</SearchWrapper>
	);
};

export default ServiceTable;
