import React from 'react';
import { FormattedMessage } from 'react-intl';
///Imports para la tabla dinamica
// Llamar a la tabla dinamica
import DynamicTable from 'common/DynamicTable/DynamicHead';
// hook para cargar los datos
// // Import services
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';
import paths from 'services/paths';
import { ListPricesTableProps } from './ListPricesTable.props';
import ListPricesTableViewModel from './ListPricesTable.viewModel';

const ListPricesTable = ({ permisos }) => {
	const {
		displayLoading,
		displayMessage,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		loading,
		error,
		titles
	} = ListPricesTableViewModel();
	if (loading) {
		if (error) {
			return displayMessage(
				'error',
				'Ha ocurrido un error, intentalo más tarde.',
				'toast.error.general'
			);
		}
		return displayLoading();
	}
	return (
		<FormattedMessage id="table.name.search.product" defaultMessage="Categoría de producto">
			{() => (
				<DynamicTable
					titles={titles}
					pageName={PAGE_NAMES.Precios}
					getData={getDataTable}
					handleDeleteItem={handleDeleteItem}
					handleEditStateItem={handleEditStateItem}
					routeToEdit={paths.updateListPriceService}
					canDeleted={permisos.eliminar}
					canModify={permisos.editar}
				/>
			)}
		</FormattedMessage>
	);
};

ListPricesTable.propTypes = ListPricesTableProps;

export default ListPricesTable;
