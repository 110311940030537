import React, { useState } from 'react';
// Mui Cell row style
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import BtnSchedule from 'common/minimalTables/actions/BtnSchedule';
import MoreTimeZoneInformation from 'common/minimalTables/modals/MoreTimeZoneInformation';
import { Modal } from 'react-responsive-modal';
// Import Libs
import moment from 'moment';
import PropTypes from 'prop-types';
// Import Services
import paths from 'services/paths';

const RowTechnical = ({ item, i }) => {
	const hours = Array.from(Array(24).keys());

	const hoursStructure = () => {
		return hours.map((hour) => (
			<TableCell align="center" key={hour} className="tooltip">
				<p
					style={{
						backgroundColor: ColorField(hour),
						borderRadius: '50%',
						width: '30px',
						height: '30px',
						alignItems: 'center',
						display: 'flex',
						justifyContent: 'center'
					}}
				>
					{hour.toString().padStart(2, '0')}
				</p>
			</TableCell>
		));
	};

	// return the color of the cell, donde le paso el valor de la hora
	// esto permite que al seprar cada uno de los arreglos por la disponibilidad
	// se pueda determinar el color de cada celda, viendo en cual esta incluida la hora
	const ColorField = (hour) => {
		// return "white";
		// [1] - use the hour variable to used like a index of each array in item.franjaHorariaDefinitiva
		const specficDATA = item?.franjaHorariaDefinitiva[hour];

		// [2] - slit the data of the variable to know which color is
		const splitData = specficDATA.split(';;')[0];
		const D_T = {
			N: 'white',
			I: '#F1684E',
			A: '#85EB4E'
		};
		// [4] - return the color
		if (splitData.includes('N')) {
			return D_T['N'];
		} else if (splitData.includes('I')) {
			// setTotalHours(totalHours + 1);
			return D_T['I'];
		} else if (splitData.includes('A')) {
			// setTotalHours(totalHours + 1);
			return D_T['A'];
		}
	};

	const reviewInfo = (idAgenda) => {
		window.open(
			`#${paths.updateSchedule.replace(':id', idAgenda)}`,
			'_blank',
			'noopener,noreferrer'
		);
	};

	// Modal config
	const [openMoreInfo, setOpenMoreInfo] = useState(false);
	const onOpenMoreInfo = () => setOpenMoreInfo(true);
	const onCloseMoreInfo = () => setOpenMoreInfo(false);

	return (
		<TableRow key={i}>
			<TableCell>{item.nombreTecnico}</TableCell>
			<TableCell>{item.identificacion} </TableCell>
			{hoursStructure()}
			<TableCell align="center">{item.totalHours}</TableCell>
			<TableCell align="center">{moment(item.itemDate).format('DD/MM/YYYY')}</TableCell>
			<BtnSchedule data={item} onOpen={onOpenMoreInfo} />
			{/* More info modal */}
			<Modal
				open={openMoreInfo}
				onClose={onCloseMoreInfo}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<MoreTimeZoneInformation onClose={onCloseMoreInfo} exeFun={reviewInfo} />
			</Modal>
		</TableRow>
	);
};

RowTechnical.propTypes = {
	item: PropTypes.object,
	i: PropTypes.number
};

export default RowTechnical;
