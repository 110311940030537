import { useState } from 'react';
import { useFormik } from 'formik';
import {
	getFormikInitialValues,
	getFormikValidationSchema,
	parseDataToFormik,
	parseDataToSubmit
} from '../../utils';

export const CreateMailFormViewModel = ({ onSubmit, formatterText }) => {
	const [formikInitialValues, setFormikInitialValues] = useState(getFormikInitialValues());
	const formik = useFormik({
		initialValues: formikInitialValues,
		validationSchema: getFormikValidationSchema(formatterText),
		enableReinitialize: false,
		onSubmit
	});

	return {
		formik,
		formikInitialValues,
		parseDataToFormik,
		setFormikInitialValues,
		parseDataToSubmit
	};
};
