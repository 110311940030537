/**
 * Validator to avoid double spaces in email,
 * but in the POST need to do a trim()
 */
const HandlerEmail = (e, currentValue, setCurrentValue) => {
	if (e.target.value.match('^[a-zA-Z0-9 .@ñáéíóúÁÉÍÓÚÑ]*$') !== null) {
		const result = /\s{2}/.test(e.target.value);
		if (result) {
			console.log('No se permiten espacios en blanco');
		} else {
			setCurrentValue({
				...currentValue,
				[e.target.name]: e.target.value
			});
		}
	} else {
		console.log('Correo no válido');
	}
};

export default HandlerEmail;
