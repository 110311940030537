import useLangv2 from 'hooks/useLangv2';
import { nanoid } from 'nanoid';
import React, { Fragment, useMemo, useState } from 'react';
import {
	diffInHours,
	formatDate,
	formatDateString,
	getDatesOfWeek,
	getHoursOfDay
} from 'screens/services/utils/date';
import DayEvent from './DayEvent';
import styles from './styles.module.css';

const Day = ({ day, dateEnd, technicians, today }) => {
	const [isTooltipVisible, setTooltipVisible] = useState(null);

	const { formatterText } = useLangv2();

	const getDataOfToday = (today, services) => {
		const hoy = new Date(today);
		const nextHour = new Date(today);
		nextHour.setHours(nextHour.getHours() + 1);

		return services.filter((service) => {
			const dateService = new Date(service.service_date_start);
			const endService = new Date(service.service_date_end);

			return (
				dateService.getTime() >= hoy.getTime() &&
				dateService.getTime() < nextHour.getTime() &&
				dateService.getTime() < endService
			);
		});
	};

	const getDataOfDay = (day, services) => {
		const nextHour = new Date(day);
		nextHour.setHours(nextHour.getHours() + 1);

		return services.filter((service) => {
			const dateService = new Date(service.service_date_start);
			const endService = new Date(service.service_date_end);

			return (
				dateService.getTime() >= day.getTime() &&
				dateService.getTime() < nextHour.getTime() &&
				dateService.getTime() < endService
			);
		});
	};

	const isHour = (date) => {
		const now = new Date();
		const nextHour = new Date(now);
		nextHour.setHours(now.getHours() + 1);
		const diff = diffInHours(now, date);
		return now.getTime() >= date.getTime() && date.getTime() < nextHour.getTime() && diff === 0;
	};

	const hours = useMemo(() => {
		return getHoursOfDay();
	}, []);

	const dates = useMemo(() => {
		const initialRange = day || today;
		const endRange = dateEnd || day;
		return getDatesOfWeek(initialRange, endRange);
	}, [day, dateEnd, today]);

	return (
		<div className={styles.gantt__container}>
			{dates.map((date) => (
				<div key={date.getTime()} className={styles.day__container}>
					{/* HEADER DATES */}
					<div className={styles.day__header}>
						{/* {dates[0] === date && ( */}
						<div className={styles.day__technician_header}>
							<span className={styles.tech__header_text}>
								{formatterText('nav.parent.2.child.option.5', 'Técnicos')}
							</span>
						</div>
						{/* )} */}

						<div key={date.getTime()} className={styles.day__date_cell}>
							<span className={styles.date__header}>{`${formatDateString(date)}`}</span>
							{/* HEADER HOURS */}
							<div className={styles.day__header}>
								{hours.map((hour) => {
									const todayHour = isHour(new Date(`${formatDate(day)} ${hour}`));

									return (
										<div key={hour} className={`${styles.hour} ${todayHour ? styles.today : ''}`}>
											<span className={styles.day__header_text}>{hour}</span>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					{/* Content */}
					<div className={styles.day__content}>
						{technicians?.map((tech) => {
							return (
								<Fragment key={`${tech.idServicio}-${nanoid()}`}>
									{/* {dates[0] === date && ( */}
									<div className={styles.day__technician_content}>
										<img
											src="https://www.vhv.rs/dpng/d/276-2761771_transparent-avatar-png-vector-avatar-icon-png-png.png"
											alt="Avatar img"
										/>
										<div className={styles.name__techs}>
											<span className={styles.day__tech_name}>{tech.technician_name}</span>
											<span className={styles.day__tech_charge}>{tech.technician_charge}</span>
										</div>
									</div>
									{/* )} */}
									{hours.map((hour) => {
										const dataOfHour = getDataOfDay(
											new Date(`${formatDate(date)} ${hour}`),
											tech?.services
										);
										const dataOfHourToday = getDataOfToday(
											new Date(`${formatDate(today)} ${hour}`),
											tech?.services
										);
										return (
											<div
												key={hour}
												className={styles.day__container_card}
												onClick={() =>
													!dataOfHour.length && !dataOfHourToday.length
														? setTooltipVisible(null)
														: null
												}
											>
												{date !== null && hour !== null
													? dataOfHour.map((service) => (
															<DayEvent
																key={service.serviceId}
																service={service}
																color={service.color}
																height={100 / dataOfHour.length}
																isTooltipVisible={isTooltipVisible}
																setTooltipVisible={setTooltipVisible}
															/>
													  ))
													: dataOfHourToday.map((service) => (
															<DayEvent
																key={service.serviceId}
																service={service}
																color={service.color}
																height={100 / dataOfHourToday.length}
																isTooltipVisible={isTooltipVisible}
																setTooltipVisible={setTooltipVisible}
															/>
													  ))}
											</div>
										);
									})}
								</Fragment>
							);
						})}
					</div>
				</div>
			))}
		</div>
	);
};
export default Day;
