import React, { useContext } from 'react';
// Import Libs
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
// Mui Cell row style
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import BtnSelectAgendaInfo from 'common/minimalTables/actions/BtnSelectAgendaInfo';
// Import Contexts
import { useSeachContext } from 'context/SearchContext';
import { AppContext } from 'context/AppContext';
// Import Hooks
import useLangv2 from 'hooks/useLangv2';
// Import styles
import styles from './RowTimeZoneSchedule.module.css';

const RowTimeZoneSchedule = ({ item, i }) => {
	useSeachContext();
	const { setOpenModalTechnicalToReview, setDataTableTechnicalAssigned, setSelectTimeZoneId } =
		useContext(AppContext);
	// use Hook of language v2
	const { customSB } = useLangv2();
	const { id } = useParams();

	const openClick = (e) => {
		e.preventDefault();
		const ELEMENT_NO_CREATED = typeof item.idFranjaHorariaAgenda === 'string';
		if (ELEMENT_NO_CREATED || id === undefined || id === null) {
			customSB(
				'warning',
				'snackbar.warning.time.zone.need.create',
				'Es necesario crear la franja horaria para poder asignar los técnicos.'
			);
		} else {
			setDataTableTechnicalAssigned([]);
			setSelectTimeZoneId(item.idFranjaHoraria);
			setOpenModalTechnicalToReview(true);
		}
	};

	return (
		<TableRow key={i}>
			<TableCell>{item.timeZone}</TableCell>
			<TableCell>
				<button className={styles.btnDetailTechnical} onClick={(e) => openClick(e)}>
					<FormattedMessage id="text.details" defaultMessage="Ver detalle" />
				</button>
			</TableCell>
			<BtnSelectAgendaInfo item={item} />
		</TableRow>
	);
};

RowTimeZoneSchedule.propTypes = {
	item: PropTypes.object,
	i: PropTypes.number
};

export default RowTimeZoneSchedule;
