import React from 'react';
import { SearchWrapper } from 'context/SearchContext';
import UpdateClientComponents from './UpdateClient.component';

const UpdateClient = () => {
	return (
		<SearchWrapper>
			<UpdateClientComponents />
		</SearchWrapper>
	);
};

export default UpdateClient;
