import Cookie from 'js-cookie';

class Agenda {
	constructor(
		idAgenda = null,
		nombre = '',
		fechaInicio = null,
		fechaFin = null,
		concepto = null,
		estado = 1,
		usuarioCreacion = parseInt(Cookie.get('idUsuario')),
		idTipoAgenda = {
			idTipoAgenda: 0
		},
		idCoordinadorCreador = {
			idUsuario: 0
		}
	) {
		this.idAgenda = idAgenda;
		this.nombre = nombre;
		this.fechaInicio = fechaInicio;
		this.fechaFin = fechaFin;
		this.concepto = concepto;
		this.estado = estado;
		this.usuarioCreacion = usuarioCreacion;
		this.idTipoAgenda = idTipoAgenda;
		this.idCoordinadorCreador = idCoordinadorCreador;
	}
}

export default Agenda;
