import { useEffect, useState } from 'react';

// Import Libs
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

// Import Components
import CustomAlert from 'components/CustomAlert';

// Import Contexts
import { useSeachContext } from 'context/SearchContext';

// Import Hooks
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';

// Import Utils
import HandleOnError from 'common/validators/HandleOnError';
import { FilterActive } from 'common/validators/FilterActives';
import { validateDuplicateSectorCode } from 'common/validators/ValidateDuplicates';

// Import Paths
import paths from 'services/paths';

// Import ViewModel
import { UpsertSectorFormViewModel } from '../UpsertSector/components';

export default function EditSectorViewModel() {
	const { endpoints, RequestUseCase } = useAxios();
	const { setDataTable } = useSeachContext();
	const { id } = useParams();
	const navigate = useNavigate();

	// use Hook of language v2
	const { formatterText } = useLangv2();
	const [toggleSelector, setToggleSelector] = useState(false);
	const [selectedPermisosModulos, setSelectedPermisosModulos] = useState([]);
	const [permisosModulos, setPermisosModulos] = useState([]);

	const handleSubmit = async (values, actions) => {
		actions.setSubmitting(true);

		const fechaAct = new Date();
		const data = parseDataToSubmit({
			...values,
			fechaModificacion: fechaAct,
			usuarioModificacion: Cookie.get('idUsuario')
		});
		const responseDuplicateCodeSector = await validateDuplicateSectorCode(
			values.codigoSector,
			'El código del sector ya existe',
			() => RequestUseCase.get(endpoints.zones.getAllSectors),
			true,
			values.idSector
		);

		if (!responseDuplicateCodeSector) {
			Swal.fire({
				title: formatterText(
					'alert.title.general',
					'Atención, estás seguro de realizar esta acción'
				),
				text: formatterText('alert.description.update.general', 'Se va a editar el registro'),
				icon: 'question',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				showLoaderOnConfirm: true,
				cancelButtonColor: '#d33',
				confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
				allowOutsideClick: false,
				cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
				preConfirm: () => {
					return new Promise((resolve) => {
						RequestUseCase.put(endpoints.zones.updateSectors, data)
							.then(() => {
								resolve(
									CustomAlert('confirm_msg', {
										icon: 'success',
										title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
										text: formatterText(
											'alert.message.confirm.updated.general',
											'El registro se ha actualizado correctamente'
										),
										confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
										allowOutsideClick: false,
										executeFunction: () => navigate(paths.sector)
									})
								);
							})
							.catch((err) => {
								if (err.response?.data?.message) {
									HandleOnError(formatterText(err.response?.data?.message));
								} else {
									HandleOnError(
										formatterText(
											'snackbar.error.process.failed.general',
											'Error al realizar el proceso. Intentalo en otro momento.'
										)
									);
								}
							})
							.finally(() => actions.setSubmitting(false));
					});
				}
			}).then((result) => {
				if (result.isDismissed) {
					actions.setSubmitting(false);
				}
			});
		} else {
			actions.setSubmitting(false);
		}
	};

	const {
		formik,
		parseDataToSubmit,
		formikInitialValues,
		setFormikInitialValues,
		parseDataToFormik
	} = UpsertSectorFormViewModel({
		onSubmit: handleSubmit
	});

	const getMunicipalities = () => {
		RequestUseCase.get(endpoints.zones.getAllMunicipalities)
			.then((resp) => {
				const municipalities = FilterActive(resp);
				getSectorData(municipalities);
			})
			.catch(console.error);
	};

	const getSectorData = (municipalities) => {
		RequestUseCase.get(endpoints.zones.getSectorById(id))
			.then((sector) => {
				const selectedMunicipalities = municipalities.filter(
					(permiso) => sector.idMunicipio.idMunicipio === permiso.idMunicipio
				);

				const noselectedMunicipalities = municipalities.filter(
					(permiso) => sector.idMunicipio.idMunicipio !== permiso.idMunicipio
				);

				setSelectedPermisosModulos(selectedMunicipalities);
				setPermisosModulos(noselectedMunicipalities);
				setDataTable(noselectedMunicipalities);

				const newFormikInitialValues = parseDataToFormik({
					formikInitialValues,
					dataByServer: { ...sector, permissions: selectedMunicipalities }
				});

				setFormikInitialValues(newFormikInitialValues);
				formik.resetForm({
					values: newFormikInitialValues
				});
			})
			.catch(console.error);
	};

	const handleRemoveRole = (e, permisosModulo) => {
		e.preventDefault();
		const selected = selectedPermisosModulos.filter(
			(currentPermisosModulo) => currentPermisosModulo.idMunicipio !== permisosModulo.idMunicipio
		);
		setSelectedPermisosModulos(selected);
		setPermisosModulos([...permisosModulos, permisosModulo]);
		setDataTable([...permisosModulos, permisosModulo]);
	};

	const handleAddRole = (permiso) => {
		const newPermisosModulos = permisosModulos.filter(
			(currentRol) => currentRol.idMunicipio !== permiso.idMunicipio
		);
		setDataTable(newPermisosModulos);
		setPermisosModulos(newPermisosModulos);
		const selected = [...selectedPermisosModulos, permiso];
		setSelectedPermisosModulos(selected);
		setToggleSelector(!toggleSelector);
	};

	useEffect(() => {
		getMunicipalities();
	}, []);

	return {
		formatterText,
		formik,
		handleAddRole,
		handleRemoveRole,
		navigate,
		setToggleSelector,
		toggleSelector
	};
}
