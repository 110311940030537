import Selector from 'common/selects/Selector';
import formatCurrencyFromModal from 'common/utils/formatCurrencyFromModal';
import formatCurrencyFromModalOc from 'common/utils/formatCurrencyFromModalOc';
import React from 'react';

import { UpdateModalItemProps } from './UpdateModalItem.props';
import UpdateModalItemViewModel from './UpdateModalItem.viewModel';

export default function UpdateModalItem({
	dataUnits,
	onClose,
	refetch = null,
	typeService,
	setResultsTableSearch
}) {
	const {
		handleIvaChange,
		handleNumber,
		formatIvaField,
		handleUpdateModalData,
		setMinimalPriceModal,
		valueMinimalPriceModal,
		valueTaxModal,
		currentItemMinimal,
		modalData,
		selectedSearch,
		setAuxData,
		formatterText,
		auxData
	} = UpdateModalItemViewModel({
		dataUnits,
		onClose,
		refetch,
		typeService,
		setResultsTableSearch
	});
	return (
		<form className="form-responsive" onSubmit={handleUpdateModalData}>
			{console.log(' modalData.idPrecioAsociado >> ', typeof modalData.idPrecioAsociado)}
			<section className="form-responsive-container-information">
				<label className="form-responsive-information__option">
					<h3>{formatterText('p.label.title.tipoServicio')}</h3>
					<Selector
						name="idTipoServicio"
						onChange={(selectedOption) => {
							setAuxData((prev) => ({
								...prev,
								idTipoServicio: selectedOption
							}));
						}}
						data={typeService?.tipoServicio}
						placeholder="Seleccione otro tipo de servicio"
						dataValue={auxData}
						setterFunction={setAuxData}
						selectValue={auxData.idTipoServicio}
						isRequired={false}
					/>
				</label>
				<label className="form-responsive-information__option">
					<h3>{formatterText('p.label.title.actividadAsociada')}</h3>
					<Selector
						name="idActividadAsociada"
						data={selectedSearch.actividadesServicios}
						placeholder={currentItemMinimal.label_idActividadAsociada}
						dataValue={auxData}
						onChange={(selectedOption) => {
							setAuxData((prev) => ({
								...prev,
								idActividadAsociada: selectedOption
							}));
						}}
						setterFunction={setAuxData}
						selectValue={auxData.idActividadAsociada}
						isRequired={false}
					/>
				</label>
				<label className="form-responsive-information__option">
					<h3>{formatterText('p.label.title.unidadMedida')}</h3>
					<Selector
						name="idUnidadMedida"
						onChange={(selectedOption) => {
							setAuxData((prev) => ({
								...prev,
								idUnidadMedida: selectedOption
							}));
						}}
						data={dataUnits}
						placeholder={currentItemMinimal.label_idUnidadMedida}
						dataValue={auxData}
						setterFunction={setAuxData}
						selectValue={auxData.idUnidadMedida}
						isRequired={false}
					/>
				</label>
				<label className="form-responsive-information__option">
					<h3>{formatterText('p.label.title.valor')}ssss</h3>
					<input
						className="input-primary"
						type="text"
						name="valor"
						placeholder="Valor"
						maxLength="20"
						value={
							typeof modalData.idPrecioAsociado === 'number'
								? formatCurrencyFromModal(valueMinimalPriceModal)
										.replace(/\./g, ',')
										.replace('$', '')
										.replace(',', '.')
								: formatCurrencyFromModalOc(valueMinimalPriceModal)
						}
						onChange={(e) => setMinimalPriceModal(handleNumber(e))}
					/>
				</label>
				<label className="form-responsive-information__option">
					<h3>{formatterText('p.label.title.impuesto')}ssss</h3>
					<input
						className="input-primary"
						type="text"
						name="iva"
						onChange={handleIvaChange}
						maxLength="6"
						onBlur={formatIvaField}
						value={valueTaxModal}
					/>
				</label>
				<input
					type="submit"
					className="width-100-fix"
					value={formatterText('p.label.title.updateList')}
					onClick={handleUpdateModalData}
				/>
				<input
					type="button"
					className="width-100-fix input-cancel"
					value={formatterText('p.label.title.cancelar')}
					onClick={onClose}
				/>
			</section>
		</form>
	);
}

UpdateModalItem.propTypes = UpdateModalItemProps;
