import HandleOnError from './HandleOnError';

/**
 * Utilidad para contar la cantidad de archivos
 * de una misma extension
 *
 * @param {Array} currentFiles
 * @param {string} ext -- extension
 *
 * @returns {int} amount -- Cantidad de archivos con la extension
 *
 * @author Luis Fernandez -- QVision
 */
const fileExtAmount = (currentFiles, ext) => {
	const amount = currentFiles.filter((file) => file.extensionArchivo === ext).length;
	return amount;
};

/**
 * Determina el maximo peso de los archivos
 *
 * @param {File} file
 * @param {string} msg
 * @returns
 */
const maxSize = (file, msg) => {
	if (file.size > 5000000) {
		HandleOnError(msg);
	}
};

/**
 * Valida que los archivos sean de una extension permitida
 *
 * @param {object} filesPerExtension objeto que contiene extensiones (key) y su cantidad(value)
 * @param {string} ext - extension valida/permitida
 * @param {string} msg - mensaje de error
 * @returns {boolean} isNotExtValidate
 */
const validExt = (filesPerExtension, ext, msg) => {
	const isNotExtValidate = !Object.keys(filesPerExtension).includes(ext);
	if (isNotExtValidate) {
		HandleOnError(msg);
	}
	return !isNotExtValidate;
};

export { fileExtAmount, maxSize, validExt };
