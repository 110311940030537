import React from 'react';
// Import Context
// Import Hooks
// Import Components
import DynamicTable from 'common/DynamicTable/DynamicHead';
import Search from 'common/Search';
// Import libs
import { FormattedMessage } from 'react-intl';
// Import Services
import paths from 'services/paths';
import { ServiceActivityTableProps } from './ServiceActivityTable.props';

import ServiceActivityTableViewModel from './ServiceActivityTable.viewModel';

const ServiceActivityTable = ({
	setActive,
	setCurrentDataForm,
	myForm,
	currentDataForm,
	setActionToggle,
	edit
}) => {
	const {
		DisplayProgress,
		formatterText,
		handleDeleteItem,
		handleEditStateItem,
		titles,
		getDataTable,
		loading,
		displayMessage,
		error
	} = ServiceActivityTableViewModel();
	if (loading) {
		if (error) {
			return displayMessage(
				'error',
				'Ha ocurrido un error, intentalo más tarde.',
				'toast.error.general'
			);
		}
		return <DisplayProgress />;
	}

	return (
		<>
			<section className="userOptions">
				<Search
					placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
					width="50%"
				/>
			</section>
			<FormattedMessage id="table.name.search.product" defaultMessage="Actividades">
				{(placeholder) => (
					<DynamicTable
						titles={titles}
						pageName={placeholder}
						getData={getDataTable} //
						handleDeleteItem={handleDeleteItem}
						handleEditStateItem={handleEditStateItem}
						routeToEdit={paths.updateActivityService}
						canDeleted={true}
						canModify={true}
						functions={{
							setActive,
							setCurrentDataForm,
							myForm,
							currentDataForm,
							setActionToggle,
							edit
						}}
					/>
				)}
			</FormattedMessage>
		</>
	);
};

ServiceActivityTable.propTypes = ServiceActivityTableProps;

export default ServiceActivityTable;
