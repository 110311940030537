import { CODEREGEX, NUMBERREGEX, TEXTREGEX } from 'common/validators/Regex';
import * as yup from 'yup';

export const getFormikValidationSchema = () => {
	return yup.object().shape({
		nombre: yup
			.string()
			.required('p.alert.sectorName.required')
			.matches(TEXTREGEX, 'p.form.error.not.especial.chars')
			.max(100, 'p.label.title.ingresa100Caracteres'),
		codigoSector: yup
			.string()
			.required('table.title.code.required')
			.matches(CODEREGEX, 'p.form.error.not.especial.chars.and.spaces')
			.max(50, 'p.form.error.max.50.chars'),
		permissions: yup
			.array()
			.required('message.municipality.required')
			.min(1, 'message.municipality.required')
			.max(1, 'Seleccione maximo un item'),
		kilometros: yup
			.string()
			.required('p.alert.km.cHToSector.required')
			.matches(NUMBERREGEX, 'p.form.error.only.number')
			.max(40, 'p.form.error.max.40.chars'),
		observaciones: yup
			.string()
			.matches(TEXTREGEX, 'p.form.error.not.especial.chars')
			.required('p.alert.observation.required')
	});
};
