import React from 'react';
// Mui Cell row style
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import BtnAssignAgenda from 'common/minimalTables/actions/BtnAssignAgenda';

// Import Libs
import PropTypes from 'prop-types';

const RowTechnicalAssigned = ({ item, i, type, getAllT }) => {
	return (
		<TableRow key={i}>
			<TableCell>{item.tech}</TableCell>
			<TableCell>
				<BtnAssignAgenda item={item} type={type} getAllT={getAllT} />
			</TableCell>
		</TableRow>
	);
};

RowTechnicalAssigned.propTypes = {
	item: PropTypes.object,
	i: PropTypes.number,
	type: PropTypes.string,
	getAllT: PropTypes.any
};

export default RowTechnicalAssigned;
