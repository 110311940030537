import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useLang from 'hooks/useLang';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import customStyles from './selects/default.select.style';

export default function SelectorMulti({
	data,
	dataValue,
	disable,
	isLoading,
	isRequired,
	setterFunction
}) {
	const [selectedOption, setSelectedOption] = useState(null);
	const { formatterText } = useLang();

	useEffect(() => {
		setSelectedOption(dataValue);
	}, [dataValue]);

	return (
		<>
			<Select
				styles={customStyles}
				value={dataValue}
				onChange={(selectedOption) => {
					setterFunction(selectedOption);
				}}
				isDisabled={disable}
				isMulti
				noOptionsMessage={() => formatterText('select.placeholder.no.options')}
				name="multiple-select"
				options={data}
				className="basic-multi-select"
				classNamePrefix="select"
				required={isRequired}
				placeholder={
					isLoading ? (
						<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
					) : (
						<FormattedMessage
							id="input.placeholder.select"
							defaultMessage="Selecciona una opción"
						/>
					)
				}
			/>
			<input
				className="input-required"
				type="text"
				defaultValue={selectedOption || ''}
				value={selectedOption || ''}
				autoComplete="off"
				required={isRequired}
			/>
		</>
	);
}

SelectorMulti.propTypes = {
	data: PropTypes.array,
	dataValue: PropTypes.string,
	disable: PropTypes.bool,
	isLoading: PropTypes.bool,
	isRequired: PropTypes.bool,
	setterFunction: PropTypes.func
};
