import { isArray, isBoolean, isEmpty, isNumber, isObject, isString } from 'lodash';

export const deepMatch = (data, query) => {
	if (data === undefined || data === null) return false;

	const evaluate = ($data) => new RegExp(query, 'gi').test($data);

	if (isNumber(data)) {
		return evaluate(data);
	} else if (isEmpty(data)) {
		return false;
	} else if (isString(data)) {
		return evaluate(data);
	} else if (isBoolean(data)) {
		return evaluate(data ? 'true' : 'false');
	} else if (isArray(data)) {
		return data.some((item) => deepMatch(item, query));
	} else if (isObject(data)) {
		return Object.values(data).some((value) => deepMatch(value, query));
	}
};

export const deepMatchNoRecursive = (data, query) => {
	if (data === undefined || data === null) return false;

	const evaluate = ($data) => new RegExp(query, 'gi').test($data);

	if (isNumber(data)) {
		return evaluate(data);
	} else if (isEmpty(data)) {
		return false;
	} else if (isString(data)) {
		return evaluate(data);
	} else if (isBoolean(data)) {
		return evaluate(data ? 'true' : 'false');
	} else if (isArray(data)) {
		return data.some((item) => deepMatch(item, query));
	} else if (isObject(data)) {
		return Object.values(data).some((value) =>
			!isObject(value) ? deepMatch(value, query) : false
		);
	}
};
