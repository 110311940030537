import { useContext, useEffect, useState } from 'react';
import { TableMinimalContext } from 'context/TableMinimalContext';
import useLang from 'hooks/useLang';
import useLangv2 from 'hooks/useLangv2';
import { useNavigate, useParams } from 'react-router-dom';
import CustomAlert from 'components/CustomAlert';
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import CategoryService from 'models/CategoryService';
import axios from 'axios';
import HandleInput from 'common/validators/HandleInput';
import HandleOnError from 'common/validators/HandleOnError';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import { CODEREGEX, SPACING, TEXTREGEXMIN } from 'common/validators/Regex';
import { useSeachContext } from 'context/SearchContext';
import useAxios from 'hooks/useAxios';
import endpoints from 'services/api';
import paths from 'services/paths';

export default function UpdateServiceCategoryViewModel() {
	// Modal config
	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	// Formiz object

	// Manage axios requests
	const { COOKIE_USER, RequestUseCase } = useAxios();

	const { dataTable, setDataTable } = useSeachContext();

	// Hooks to get the params
	const { id } = useParams();

	const [loading, setLoading] = useState(true);
	// toggle state
	const [active, setActive] = useState(true);
	const [categories, setCategories] = useState([]);
	const [product, setProduct] = useState({
		idTipoCategoria: '',
		nombre: '',
		codigo: '',
		categorias: [],
		estado: 1,
		descripcion: '',
		idCategoriaServicio: '',
		idTipoServicio: ''
	});

	// Call context TableMinimalContext
	const { currentDataTable, setResultsTableSearch, setCurrentDataTable } =
		useContext(TableMinimalContext);

	//  Call hook of language
	const { formatterText } = useLang();
	const { newItemCreated, resourceNotFound, handleRequestError, noFilledContent } = useLangv2();

	const titlesTableSubCategories = [
		formatterText('p.name.type.service', 'Nombre tipo de servicio'),
		formatterText('table.title.description', 'Descripción'),
		formatterText('table.title.code', 'Código'),
		formatterText('table.title.categoryProduct', 'Categorías de productos'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];

	const navigate = useNavigate();
	// Example of form data
	const [errorName, setErrorName] = useState('');
	const [codeError, setCodeError] = useState('');
	const [formData, setFormData] = useState(new CategoryService());
	const [currentItemMinimal, setCurrentItemMinimal] = useState({
		idTipoServicio: uuidv4(),
		nombre: '',
		codigo: '',
		categorias: [],
		estado: 1,
		descripcion: ''
	});

	// Set contextMinimalTable empty
	useEffect(() => {
		// Fill the data to update in the fields
		getProductCategories();
		getCategories();
	}, []);

	useEffect(() => {
		getCategoryById(id);
	}, [id]);

	useEffect(() => {
		getDataFromMinimalTable(id);
	}, [id]);

	const getCategoryById = (id) => {
		RequestUseCase.get(endpoints.serviceCategory.getCategoryServiceById(id))
			.then((res) => {
				if (res === null) {
					setFormData({
						nombre: '',
						descripcion: '',
						codigo: ''
					});
					resourceNotFound();
				} else {
					setFormData(res);
					setActive(res.estado);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const getDataFromMinimalTable = (id) => {
		RequestUseCase.get(endpoints.typeService.newGetTypeServiceByCategory(id))
			.then((res) => {
				const newArray = [];
				if (res.tipoServicio !== null) {
					res?.forEach((item) => {
						handleStructureItems(newArray, item);
					});
					setDataTable(newArray);
					setCurrentDataTable(newArray);
					setResultsTableSearch(newArray);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const handleStructureItems = (newArray, item) => {
		const categorias = item?.categoriaProducto?.map((categoria) => categoria?.nombre);

		if (categorias && categorias.length > 0) {
			const categoriasString = categorias.join(', ');

			newArray.push({
				idTipoServicio: item.tipoServicio.idTipoServicio || null,
				nombre: item.tipoServicio.nombre,
				descripcion: item.tipoServicio.descripcion,
				codigo: item.tipoServicio.codigo,
				estado: parseInt(item.tipoServicio.estado),
				categorias: [categoriasString],
				objeto: {
					nombre: item.tipoServicio.nombre,
					descripcion: item.tipoServicio.descripcion,
					codigo: item.tipoServicio.codigo,
					estado: item.tipoServicio.estado,
					categorias: [categoriasString]
				}
			});
		}
	};

	const getCategories = () => {
		RequestUseCase.get(endpoints.productCategoryService.getAll).then((resp) => {
			const cat = resp.map((elem) => ({
				label: elem.idCategoriaProductoTipoServicio,
				value: {
					idCatProd: elem.idCategoriaProducto,
					idServ: elem.idTipoServicio
				}
			}));
			setCategories(cat);
		});
	};

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		if (errorName !== '' || codeError !== '' || formData.nombre === '' || formData.codigo === '') {
			noFilledContent();
		} else {
			const data = {
				...formData,
				estado: active ? 1 : 0,
				usuarioModificacion: Cookie.get('idUsuario')
			};
			putItem(data);
		}
	};

	// Call the service POST
	const putItem = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general'),
			text: formatterText('alert.description.update.general'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					RequestUseCase.put(endpoints.serviceCategory.updateServiceCategory, data)
						.then(() => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general'),
									text: formatterText('alert.message.confirm.updated.general'),
									confirmButtonText: formatterText('alert.button.continue'),
									allowOutsideClick: false,
									executeFunction: () => navigate(paths.services)
								})
							);
						})
						.catch((err) => {
							if (err.response.status === 500 || err.response.status === 400) {
								reject(HandleOnError(formatterText('alert.message.code.error.general')));
							} else {
								reject(HandleOnError(formatterText('alert.message.failed.general')));
							}
							console.error(err);
						});
				});
			}
		});
	};

	// Update a string to set into the form
	const handleText = (e) => {
		HandleInput(e, SPACING, formData, setFormData);
	};

	// Add a product in the dynamic minimal table
	const handleAddItemMinimal = async () => {
		if (!!currentItemMinimal.nombre && !!currentItemMinimal.codigo && !!product.categorias.length) {
			const filter = currentDataTable.filter(
				(item) =>
					item.codigo.toString().toLowerCase() ===
					currentItemMinimal.codigo.toString().toLowerCase()
			);

			if (filter.length > 0) {
				HandleOnError(formatterText('p.label.title.codigoExistenteElemento'));
			} else {
				try {
					const body = {
						nombre: currentItemMinimal.nombre,
						idCategoriaServicio: {
							idCategoriaServicio: parseInt(id)
						},
						descripcion: currentItemMinimal.descripcion,
						codigo: currentItemMinimal.codigo
					};
					let tipoServRes = 0;
					await RequestUseCase.post(endpoints.typeService.addTypeService, body)
						.then((res) => {
							tipoServRes = res.idTipoServicio;
							newItemCreated();
						})
						.catch((err) => {
							handleRequestError(err);
						});
					const promises = currentItemMinimal.categorias.map((item) => {
						const body2 = [
							{
								idCategoriaProductoTipoServicio: null,
								idCategoriaProducto: item.value,
								idTipoServicio: tipoServRes,
								usuarioCreacion: COOKIE_USER
							}
						];
						const promise = RequestUseCase.post(
							endpoints.productCategoryService.saveMultiple,
							body2
						).catch((error) => {
							throw error;
						});
						return promise;
					});

					const results = await Promise.all(promises);
					const errors = results.filter((result) => result.error !== '200');
					if (errors) {
						console.error(errors);
					}
					getDataFromMinimalTable(id);
					setCurrentItemMinimal({
						nombre: '',
						codigo: '',
						categorias: [],
						descripcion: '',
						estado: 1,
						idTipoServicio: uuidv4()
					});
					setSelectValues([]);
				} catch (error) {
					handleRequestError(error);
				}
			}
		} else {
			HandleOnError(
				formatterText(
					'p.label.title.debesCompletarTodosLosCampos',
					'Debes completar todos los campos'
				)
			);
		}
	};
	// handle to subCategoryProduct
	const handleChangeSubCategory = (e) => {
		HandleInput(e, SPACING, currentItemMinimal, setCurrentItemMinimal);
	};

	const handlerTextDescriptionSubcategory = (e) => {
		HandlerTextDescription(e, currentItemMinimal, setCurrentItemMinimal);
	};

	const handleChangeSubCategoryCode = (e) => {
		HandleInput(e, CODEREGEX, currentItemMinimal, setCurrentItemMinimal);
	};

	const handlerTextCode = (e) => {
		const example = e;
		example.target.value = e.target.value.toLowerCase();
		HandleInput(example, TEXTREGEXMIN, formData, setFormData);
	};

	const handleSelectChange = (e) => {
		setSelectValues(e);
		setProduct((prevItem) => ({
			...prevItem,
			categorias: e
		}));

		setCurrentItemMinimal({
			...currentItemMinimal,
			categorias: e
		});
	};
	// State of the labels and ids of the selectors
	const [selectValues, setSelectValues] = useState([]);

	const [productCategories, setProductCategories] = useState([]);

	const getProductCategories = () => {
		axios(endpoints.productCategory.getAllProductCategory).then((response) => {
			setProductCategories(
				response.data
					.filter((item) => item.estado === 1)
					.map((obj) => ({
						label: obj.nombre,
						value: obj.idCategoriaProducto
					}))
			);
			setLoading(false);
		});
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	const handleErrorName = () => {
		if (formData.nombre.trim() === '') {
			setErrorName(formatterText('p.label.title.name.is.required'));
		} else {
			setErrorName('');
		}
	};

	const handleErrorCode = () => {
		if (formData.codigo.trim() === '') {
			setCodeError(formatterText('table.title.code.required'));
		} else {
			setCodeError('');
		}
	};
	return {
		formatterText,
		handleSubmit,
		formData,
		handleText,
		handleErrorName,
		errorName,
		codeError,
		active,
		setActive,
		dataTable,
		titlesTableSubCategories,
		handleOpen,
		handleClose,
		open,
		currentItemMinimal,
		handleChangeSubCategory,
		handleChangeSubCategoryCode,
		handlerTextDescriptionSubcategory,
		handleSelectChange,
		handleAddItemMinimal,
		getDataFromMinimalTable,
		productCategories,
		loading,
		setLoading,
		selectValues,
		setSelectValues,
		id,
		categories,
		navigate,
		handlerTextDescription,
		handlerTextCode,
		handleErrorCode
	};
}
