import React, { useEffect } from 'react';
// Import Context
import { useSeachContext } from 'context/SearchContext';
import { formatterText } from 'hooks/useLangv2';
import DynamicTableServices from '../DynamicTableServices/DynamicHeadServices';

function SeguimientoNqr({ getDataTable }) {
	const tableTitles = [
		formatterText('nqr.id'),
		formatterText('descripcion'),
		formatterText('usuario.modificacion'),
		formatterText('estado.nqr'),
		formatterText('fecha.modificacion')
	];
	const { setDataTable } = useSeachContext();

	useEffect(() => {
		setDataTable([]);
	}, []);

	return (
		<div>
			{getDataTable && (
				<div className="tdR thR table-container">
					<DynamicTableServices
						titles={tableTitles}
						pageName={formatterText('title.nqr.traceability.page.name')}
						getData={getDataTable}
					/>
				</div>
			)}
		</div>
	);
}

export default SeguimientoNqr;
