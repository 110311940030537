import useAxios from 'hooks/useAxios';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CustomAlert from 'components/CustomAlert';
import CustomToast from 'components/CustomToast';
import WarningDialog from 'components/tableOptions/WarningDialog';
import { PAGES } from 'constants/lang/services/services/pages';
import { useSeachContext } from 'context/SearchContext';
import dateFormat from 'dateformat';
import useLangv2 from 'hooks/useLangv2';
import Cookie from 'js-cookie';
import Sector from 'models/Sector';
import React, { useEffect, useMemo, useState } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const DynamicCell = ({ titles, columns, page, getData, routeToEdit, canDeleted, canModify }) => {
	const { endpoints, RequestUseCase } = useAxios();
	const { dataTable, setSearch, setDataTable, searchResults = [] } = useSeachContext();
	const navigate = useNavigate();
	const { formatterText, updatedItem } = useLangv2();
	const [stateAux, setStateAux] = useState(columns.estado);
	const [dateAux, setDateAux] = useState(columns.fechaModificacion);
	const [loading, setLoading] = useState(false);
	const [dialog, setDialog] = useState({
		text: '',
		active: false,
		function: null
	});
	const { OnMessage, CustomComponentToast } = CustomToast();
	useEffect(() => {
		setStateAux(columns.estado);
		setDateAux(columns.fechaModificacion);
	}, [dataTable, columns.estado, searchResults]);

	const handleEdit = (id) => {
		navigate(routeToEdit.replace(':id', parseInt(id)));
	};

	const handleDelete = (columns) => {
		if (page === PAGES.Sectores) {
			RequestUseCase.delete(endpoints.zones.deleteSector(columns.id))
				.then(() => {
					closeDialog();
					CustomAlert('short_msg', {
						icon: 'success',
						title: `${formatterText('message.sector.deleted')} ${columns.nombre}`,
						text: formatterText('message.action.completed')
					});
					setSearch('');
					getData();
				})
				.catch(() => {
					closeDialog();
					CustomAlert('short_msg', {
						icon: 'error',
						title: `${formatterText('message.sector.delete.failed')} ${columns.nombre}`,
						text: formatterText('snackbar.error.relation.failed.general')
					});
				});
		}
	};

	const closeDialog = () => {
		setLoading(false);
		setDialog({ ...dialog, active: false });
	};

	const response = (estado, formatDate) => {
		setStateAux(estado);
		setDateAux(formatDate);
		setLoading(false);
		setSearch('');
		getData();
		updatedItem();
	};

	const handleToggle = () => {
		switch (page) {
			case PAGES.Paises:
				togglePais();
				break;
			case PAGES.Departamentos:
				toggleDepartamento();
				break;
			case PAGES.Municipalidades:
				toggleMunicipio();
				break;
			case PAGES.Sectores:
				toggleSector();
				break;

			default:
				console.log('default');
				break;
		}
	};

	const togglePais = () => {
		const estado = stateAux === 1 ? 0 : 1;
		const currentDate = new Date();
		const isoDate = dateFormat(currentDate, 'isoDateTime');

		closeDialog();
		const data = {
			idPais: columns.id,
			codigoPais: columns.codigoPais,
			nombrePais: columns.nombre,
			prefijo: columns.prefijo,
			estado: estado,
			fechaCreacion: columns.fc || isoDate,
			fechaModificacion: isoDate,
			usuarioCreacion: columns.uc || Cookie.get('idUsuario'),
			usuarioModificacion: Cookie.get('idUsuario')
		};

		RequestUseCase.put(endpoints.zones.updateCountry, data)
			.then((resp) => {
				const NEW_ARRAY_TABLE = searchResults.map((item) => {
					const dateModification = dateFormat(resp.fechaModificacion, 'yyyy/mm/dd - h:MM:ss TT');
					if (item.id === columns.id) {
						item.fechaModificacion = dateModification;
						return { ...item, estado };
					}
					return item;
				});
				setDataTable(NEW_ARRAY_TABLE);
				setSearch('');
				getData();
			})
			.catch(() => {
				OnMessage('error', formatterText('message.country.update.failed'));
			});
	};

	const toggleDepartamento = () => {
		const estado = stateAux === 1 ? 0 : 1;
		const currentDate = new Date();
		const isoDate = dateFormat(currentDate, 'isoDateTime');
		closeDialog();

		const data = {
			idDepartamento: columns.id,
			codigoDepartamento: columns.codigoDepartamento,
			nombre: columns.nombre,
			estado: estado,
			fechaCreacion: columns.fc || isoDate,
			fechaModificacion: isoDate,
			idPais: columns.objeto,
			usuarioCreacion: columns.uc || Cookie.get('idUsuario'),
			usuarioModificacion: Cookie.get('idUsuario')
		};

		RequestUseCase.put(endpoints.zones.updateDepartment, data)
			.then((resp) => {
				const NEW_ARRAY_TABLE = searchResults.map((item) => {
					const dateModification = dateFormat(resp.fechaModificacion, 'yyyy/mm/dd - h:MM:ss TT');
					if (item.id === columns.id) {
						item.fechaModificacion = dateModification;
						return { ...item, estado };
					}
					return item;
				});
				setDataTable(NEW_ARRAY_TABLE);
				setSearch('');
				getData();
			})
			.catch((err) => {
				OnMessage('error', formatterText('message.department.update.failed'));
				console.log(err);
			});
	};

	const toggleMunicipio = () => {
		const estado = stateAux === 1 ? 0 : 1;
		const currentDate = new Date();
		const isoDate = dateFormat(currentDate, 'isoDateTime');
		closeDialog();

		const data = {
			idMunicipio: columns.id,
			codigoMunicipio: columns.codigoMunicipio,
			nombre: columns.nombre,
			prefijo: columns.prefijo,
			estado: estado,
			kilometros: columns.kilometros,
			idDepartamento: columns.objeto,
			fechaCreacion: columns.fc || isoDate,
			fechaModificacion: isoDate,
			usuarioCreacion: columns.uc || Cookie.get('idUsuario'),
			usuarioModificacion: Cookie.get('idUsuario'),
			zonaHorariaGmt: columns.zonaHorariaGmt
		};

		RequestUseCase.put(endpoints.zones.updateMunicipality, data)
			.then((resp) => {
				const NEW_ARRAY_TABLE = searchResults.map((item) => {
					const dateModification = dateFormat(resp.fechaModificacion, 'yyyy/mm/dd - h:MM:ss TT');
					if (item.id === columns.id) {
						item.fechaModificacion = dateModification;
						return { ...item, estado };
					}
					return item;
				});
				setDataTable(NEW_ARRAY_TABLE);
				setSearch('');
				getData();
			})
			.catch((err) => {
				OnMessage('error', formatterText('message.municipality.update.failed'));
				console.log(err);
			});
	};

	const toggleSector = () => {
		const estado = stateAux === 1 ? 0 : 1;
		const currentDate = new Date();
		const formatDate = dateFormat(currentDate, 'yyyy/mm/dd - h:MM:ss TT');
		const isoDate = dateFormat(currentDate, 'isoDateTime');
		closeDialog();
		const data = new Sector(
			columns.id,
			columns.codigoSector,
			columns.nombre,
			estado,
			columns.kilometros,
			columns.objeto,
			columns.fc || isoDate,
			columns.uc || Cookie.get('idUsuario'),
			Cookie.get('idUsuario')
		);

		RequestUseCase.put(endpoints.zones.updateSectors, data)
			.then(() => {
				// setLoading(true);
				response(estado, formatDate);
			})
			.catch(() => {
				OnMessage('error', formatterText('message.sector.update.failed'));
			});
	};

	const hasActions = useMemo(
		() => titles.some((title) => /^((accion)es?|(action)s?)$/gi.test(title)),
		[titles]
	);

	return (
		<>
			<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
				{Object.entries(columns).map(([column, state], index) => {
					switch (column) {
						case 'id':
							return null;

						case 'objeto':
							return null;

						case 'usuarioCreacion':
							return null;

						case 'fc':
							return null;

						case 'uc':
							return null;

						case 'estado': {
							const activeBtn = (
								<button
									className="btn-state"
									disabled={loading}
									onClick={() =>
										setDialog({
											text: `${formatterText('dialog.title.delete.mid.first')} ${
												columns.nombre
											} ${formatterText('dialog.title.delete.mid.end.inactive')}`,
											active: true,
											funcion: () => handleToggle()
										})
									}
								>
									{formatterText('btn.active')}
								</button>
							);

							const inactiveBtn = (
								<button
									className="btn-inactive_state"
									disabled={loading}
									onClick={() => {
										setDialog({
											text: `${formatterText('dialog.title.delete.mid.first')} ${
												columns.nombre
											} ${formatterText('dialog.title.delete.mid.end.active')}`,
											active: true,
											action: 'active',
											funcion: () => handleToggle()
										});
									}}
								>
									{formatterText('btn.inactive')}
								</button>
							);

							return (
								<TableCell className={loading ? 'disabled' : 'enabled'} align="center" key={index}>
									{state === 1 ? activeBtn : inactiveBtn}
								</TableCell>
							);
						}
						case 'fechaModificacion':
							return (
								<TableCell align="left" key={`${index + 1}}`}>
									{dateAux}
								</TableCell>
							);

						default:
							return (
								<TableCell key={`${index + 1}}`} component="th" scope="row" className="enabled">
									{columns[column]}
								</TableCell>
							);
					}
				})}

				{hasActions && (
					<TableCell className={loading ? 'disabled' : 'enabled'} align="center">
						{canModify && (
							<MdEdit
								size={25}
								color="gray"
								cursor="pointer"
								disabled={loading}
								onClick={() => handleEdit(columns.id)}
							/>
						)}
						{canDeleted && (
							<MdDelete
								size={25}
								color="gray"
								cursor="pointer"
								onClick={() =>
									setDialog({
										text: `${formatterText('title.service.confirm.delete')} ${columns.nombre}`,
										active: true,
										action: 'delete',
										funcion: () => handleDelete(columns)
									})
								}
								disabled={loading}
							/>
						)}
					</TableCell>
				)}

				<WarningDialog dialog={dialog} setDialog={setDialog} />
			</TableRow>
			<CustomComponentToast />
		</>
	);
};

export default DynamicCell;
