import useAxios from 'hooks/useAxios';
import { AppContext } from 'context/AppContext';
import { useSeachContext } from 'context/SearchContext';
import useLangv2 from 'hooks/useLangv2';
import useProgress from 'hooks/useProgress';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import endpoints from 'services/api';

export default function ModalTechnicalTZViewModel() {
	const { RequestUseCase } = useAxios();
	const {
		setDataTableTechnicalAssigned,
		selectTimeZoneId //selectTimeZoneId: id de franja horaria
	} = useContext(AppContext);
	// get the id from the url
	const { id } = useParams();

	// use Hook of language v2
	const { formatterText, errorDataRecovery } = useLangv2();
	// Helps to loading data table
	const { loadingProgress, setLoadingProgress, DisplayProgress } = useProgress();
	// titles of table
	const titlesTableTimeZone = [
		formatterText('table.shedule.view.technical', 'Técnicos asociados'),
		formatterText('table.actions', 'Acciones')
	];

	useEffect(() => {
		getAllTechiniansByTimeZone();
	}, []);

	const { dataTableTechnical, setDataTableTechnical, setDataTable } = useSeachContext();

	// get all technicals by time zone
	const getAllTechiniansByTimeZone = () => {
		setLoadingProgress(true);
		RequestUseCase.get(endpoints.agenda.getAllFranjaHorariaByIdAgenda(selectTimeZoneId, id))
			.then((response) => {
				// variable empty to save the data
				const data = [];

				response.forEach((element) => {
					const IS_TECH = element.idTecnico.idTercero === null;
					if (IS_TECH) {
						data.push({
							id: element.idFranjaHorariaAgendaTecnico,
							tech: `${element.idTecnico.idEmpleado.primerNombre.trim()} ${element.idTecnico.idEmpleado.primerApellido.trim()}-${element.idTecnico.idEmpleado.identificacion.trim()}`,
							status: element.idTipoAgenda.idTipoAgenda,
							idTecnico: element.idTecnico.idTecnico,
							idFranjaHoraria: element.idFranjaHoraria.idFranjaHoraria,
							idFranjaHorariaAgendaTecnico: element.idFranjaHorariaAgendaTecnico,
							fechaCreacion: element.fechaCreacion,
							usuarioCreacion: element.usuarioCreacion
						});
					} else {
						data.push({
							id: element.idFranjaHorariaAgendaTecnico,
							tech: `${element.idTecnico.idTercero.nombre.trim()}-${element.idTecnico.idTercero.correo.trim()}-${element.idTecnico.idTercero.identificacion.trim()}`,
							status: element.idTipoAgenda.idTipoAgenda,
							idTecnico: element.idTecnico.idTercero.idTercero,
							idFranjaHoraria: element.idFranjaHoraria.idFranjaHoraria,
							idFranjaHorariaAgendaTecnico: element.idFranjaHorariaAgendaTecnico,
							fechaCreacion: element.fechaCreacion,
							usuarioCreacion: element.usuarioCreacion
						});
					}
				});

				// set the data to the table
				setDataTableTechnical(data);
				setDataTableTechnicalAssigned(data);
				setDataTable(data);
				setLoadingProgress(false);
			})
			.catch(() => {
				errorDataRecovery();
				setLoadingProgress(false);
			});
	};

	const [dialog, setDialog] = useState({
		text: '',
		active: false,
		function: null
	});

	const closeDialog = () => {
		setDialog({ ...dialog, active: false });
	};

	return {
		dataTableTechnical,
		titlesTableTimeZone,
		dialog,
		closeDialog,
		loadingProgress,
		DisplayProgress,
		getAllTechiniansByTimeZone
	};
}
