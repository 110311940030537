import React from 'react';

// Import Libs
import { FormattedMessage } from 'react-intl';

// Import Components
import DynamicTable from 'common/DynamicTable/DynamicHead';

// Import Paths
import paths from 'services/paths';

// Import Constants
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';

// Import ViewModel
import TechnicalsTableViewModel from './TechnicalsTable.viewModel';

const TechnicalsTable = () => {
	const {
		displayLoading,
		displayMessage,
		error,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		loading,
		titles
	} = TechnicalsTableViewModel();

	return (
		<>
			{!loading ? (
				<>
					<FormattedMessage id="table.name.search.technical" defaultMessage="Técnico">
						{() => (
							<DynamicTable
								titles={titles}
								pageName={PAGE_NAMES.Técnicos}
								getData={getDataTable}
								handleDeleteItem={handleDeleteItem}
								handleEditStateItem={handleEditStateItem}
								routeToEdit={paths.updateTechnical}
								canDeleted={true}
								canModify={true}
							/>
						)}
					</FormattedMessage>
				</>
			) : error ? (
				displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			) : (
				displayLoading()
			)}
		</>
	);
};

export default TechnicalsTable;
