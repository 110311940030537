import useAxios from 'hooks/useAxios';
import HandleInput from 'common/validators/HandleInput';
import { IDREGEX } from 'common/validators/Regex';
import dateFormat from 'dateformat';
import useAvailabilityTechnicians from 'hooks/useAvailabilityTechnicians';
import useLangv2 from 'hooks/useLangv2';
import useListSchedule from 'hooks/useListSchedule';
import useToggle from 'hooks/useToggle';
import _, { isEmpty } from 'lodash';
import { SelectedFieldModel } from 'models/Schedule/ListSchedule';
import { useEffect, useState } from 'react';
import endpoints from 'services/api';

export default function ListScheduleViewModel() {
	const { RequestUseCase } = useAxios();

	//hooks
	const { fieldValues, getAllStatesByCountry } = useListSchedule();
	const { getDaysInRange, separateDays, segmentTechsByDayOfWeek, processDayOfWeek } =
		useAvailabilityTechnicians();
	const { formatterText, handleRequestError } = useLangv2();
	const [value] = useToggle();

	//variables
	const [selectedField, setSelectedField] = useState(new SelectedFieldModel());
	const [formData, setFormData] = useState({
		numeroDocumento: '',
		fechaInicio: dateFormat(new Date(), 'isoDateTime'),
		fechaFin: dateFormat(new Date(), 'isoDateTime')
	});
	const [isEnable, setIsEnable] = useState(true);
	const [technicalsDataTable, setTechnicalsDataTable] = useState([]);

	const handleTextFilters = (e) => {
		HandleInput(e, IDREGEX, formData, setFormData);
	};

	const handlerClear = () => {
		const now = new Date();
		setFormData({
			numeroDocumento: '',
			fechaIncio: dateFormat(now, 'isoDateTime'),
			fechaFin: dateFormat(now, 'isoDateTime')
		});
		setSelectedField(new SelectedFieldModel());
	};

	const handlerFilters = (e) => {
		e.preventDefault();
		const searchDataRequest = _searchData();
		//consumo de endpoint
		handleSearch(searchDataRequest);
	};

	const handleSearch = (request) => {
		RequestUseCase.post(endpoints.agenda.getAllTecnicalByAdvancedSearch, request)
			.then((resp) => {
				console.log(resp);
				_rangeDays(resp);
			})
			.catch((error) => handleRequestError(error));
	};

	const _rangeDays = (response) => {
		const days = getDaysInRange(formData.fechaInicio, formData.fechaFin);
		const sortTechnicians = _.sortBy(response, 'idTecnico');
		const allData = _daysOfWeek(days, sortTechnicians);
		const sortAllData = _.sortBy(allData, (obj) => obj.itemDate).filter((elem) => !isEmpty(elem));
		setTechnicalsDataTable(sortAllData.flat());
		console.log(sortAllData.flat());
	};

	// funciones auxiliares
	const _titlesTableAgenda = () => {
		// Crea un array de títulos para las horas (de 00 a 23)
		const hourTitles = Array.from({ length: 24 }, (_, i) => `${i.toString().padStart(2, '0')}`);
		const titles = [
			formatterText('table.title.technician.name', 'Nombre técnico'),
			formatterText('table.title.id', 'Identificación'),
			...hourTitles,
			formatterText('table.hours'),
			formatterText('table.date'),
			formatterText('table.actions')
		];

		return titles;
	};

	const _searchData = () => {
		const fieldsSearch = {
			idTecnico: [],
			idCargo: [],
			idPais: [],
			idDepartamento: [],
			idMunicipio: [],
			idTipoDocumento: [],
			identificacion: [],
			fechaIncio: '',
			fechaFin: ''
		};

		for (const key in selectedField) {
			if (
				Object.getOwnPropertyDescriptor(selectedField, key) &&
				Object.getOwnPropertyDescriptor(selectedField[key], 'id')
			) {
				if (selectedField[key].id !== 0) {
					fieldsSearch[key] = [selectedField[key].id];
				}
			}
		}
		if (!isEmpty(formData.numeroDocumento)) {
			fieldsSearch.identificacion = [formData.numeroDocumento];
		}
		if (formData.fechaInicio) {
			fieldsSearch.fechaIncio = _dates(formData.fechaInicio);
		}
		if (formData.fechaFin) {
			fieldsSearch.fechaFin = _dates(formData.fechaFin);
		}

		return fieldsSearch;
	};

	const _dates = (date) => {
		return dateFormat(date, 'isoDateTime');
	};

	const _daysOfWeek = (days, sortedTechnicians) => {
		const { domingo, lunes, martes, miercoles, jueves, viernes, sabado } = separateDays(days);
		const {
			techTimeZoneMonday,
			techTimeZoneTuesday,
			techTimeZoneWednesday,
			techTimeZoneThursday,
			techTimeZoneFriday,
			techTimeZoneSaturday,
			techTimeZoneSunday
		} = segmentTechsByDayOfWeek(sortedTechnicians);
		// Procesar datos para cada día de la semana
		const processedMondayData = processDayOfWeek(lunes, techTimeZoneMonday, 'techTimeZoneMonday');
		const processedTuesdayData = processDayOfWeek(
			martes,
			techTimeZoneTuesday,
			'techTimeZoneTuesday'
		);
		const processedWednesdayData = processDayOfWeek(
			miercoles,
			techTimeZoneWednesday,
			'techTimeZoneWednesday'
		);
		const processThursdayData = processDayOfWeek(
			jueves,
			techTimeZoneThursday,
			'techTimeZoneThursday'
		);
		const processFridayData = processDayOfWeek(viernes, techTimeZoneFriday, 'techTimeZoneFriday');
		const processSaturdayData = processDayOfWeek(
			sabado,
			techTimeZoneSaturday,
			'techTimeZoneSaturday'
		);
		const porcessSundayData = processDayOfWeek(domingo, techTimeZoneSunday, 'techTimeZoneSunday');

		const allData = {
			...processedMondayData,
			...processedTuesdayData,
			...processedWednesdayData,
			...processThursdayData,
			...processFridayData,
			...processSaturdayData,
			...porcessSundayData
		};

		return allData;
	};

	useEffect(() => {
		getAllStatesByCountry(selectedField.idPais.id);
	}, [selectedField.idPais.id]);

	useEffect(() => {
		if (selectedField.idTipoDocumento.id !== 0) {
			setIsEnable(false);
		} else {
			setFormData({ ...formData, numeroDocumento: '' });
			setIsEnable(true);
		}
	}, [selectedField.idTipoDocumento.id]);

	return {
		fieldValues,
		selectedField,
		setSelectedField,
		formData,
		setFormData,
		handleTextFilters,
		handlerFilters,
		handlerClear,
		technicalsDataTable,
		isEnable,
		value,
		formatterText,
		_titlesTableAgenda
	};
}
