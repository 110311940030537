import { MODULES_NAME } from 'common/validators/PermissionsConstants';
import { usePermissionContext } from 'context/PermissionContext';
import { useEffect } from 'react';

export const InvoiceViewModels = () => {
	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();

	const permissionsAccessGaranted = () => {
		permissionsAccess(MODULES_NAME.reconciliation);
	};

	useEffect(() => {
		permissionsAccessGaranted();
	}, [permissions]);

	return {
		permissionsAccess,
		permittedActions,
		permissions
	};
};
