import React from 'react';
// Mui Cell row style
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
// Import services

const RowAssignTech = ({
	item,
	i // index
}) => {
	return (
		<TableRow key={i}>
			<TableCell>{item.label}</TableCell>
			<TableCell>{item.docId}</TableCell>
			<TableCell>{item.isPrincipal ? 'Principal' : 'Auxiliar'}</TableCell>
			<TableCell>{new Date(item.dateAndTime?.d).toISOString().split('T')[0]}</TableCell>
			<TableCell>{item.dateAndTime?.h}</TableCell>
			{/* <TableCell>{handleColor(item.tiempoDisponibilidadInicio)}</TableCell> */}
			{/* <TableCell>{handleColor(item.tiempoDisponibilidadFin)}</TableCell> */}
			{/* <BtnSeeDetails item={item} handleDetails={handleDetails} /> */}
			{/* <BtnAssignSearch item={item} handleSelect={() => handleSelect(item)} /> */}
		</TableRow>
	);
};

export default RowAssignTech;
