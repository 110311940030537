import React, { useState, useEffect } from 'react';
// Import Hooks
import useLangv2 from 'hooks/useLangv2';
// Import Components
import Select from 'react-select';
// Import Libs
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
// Import Styles
import customStyles from 'common/selects/default.select.style';

const Selector = ({
	name,
	data,
	selectValue,
	placeholder,
	isLoading,
	dataValue,
	setterFunction,
	isRequired,
	disabled,
	newForm,
	fullWidth
}) => {
	const { formatterText } = useLangv2();
	const [selectedOption, setSelectedOption] = useState(null);

	useEffect(() => {
		if (newForm) {
			if (dataValue?.label !== '') {
				setSelectedOption(dataValue);
			}
		} else {
			setSelectedOption(dataValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataValue]);

	return (
		<div className={fullWidth ? 'select-wrapper-selects' : 'select-wrapper-container'}>
			{newForm ? (
				<Select
					styles={customStyles}
					onChange={(selectedOption) => {
						setSelectedOption(selectedOption);
						setterFunction((prev) => ({
							...prev,
							[name]: selectedOption
						}));
					}}
					value={selectedOption}
					noOptionsMessage={() =>
						formatterText('select.placeholder.no.options', 'No se encontraron opciones')
					}
					options={data}
					placeholder={
						isLoading ? (
							<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
						) : (
							placeholder
						)
					}
					isDisabled={disabled ? true : false}
				/>
			) : (
				<Select
					styles={customStyles}
					onChange={(selectedOption) => {
						setSelectedOption(selectedOption);
						setterFunction((prev) => ({
							...prev,
							[name]: selectedOption
						}));
					}}
					noOptionsMessage={() =>
						formatterText('select.placeholder.no.options', 'No se encontraron opciones')
					}
					value={selectValue}
					options={data}
					placeholder={
						isLoading ? (
							<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
						) : (
							placeholder
						)
					}
					isDisabled={disabled ? true : false}
				/>
			)}
			<input
				className="input-required"
				type="text"
				value={selectedOption || ''}
				autoComplete="off"
				required={isRequired === true ? true : false}
				style={{ visibility: 'hidden' }}
			/>
		</div>
	);
};

Selector.propTypes = {
	name: PropTypes.string,
	data: PropTypes.array,
	selectValue: PropTypes.object,
	placeholder: PropTypes.string,
	isLoading: PropTypes.bool,
	dataValue: PropTypes.object,
	setterFunction: PropTypes.func,
	isRequired: PropTypes.bool,
	disabled: PropTypes.bool,
	newForm: PropTypes.bool,
	fullWidth: PropTypes.bool
};

export default Selector;
