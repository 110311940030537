import SelectComboBox from 'common/selects/SelectComboBox';
import React from 'react';
import DynamicScheduleTable from '../DynamicScheduleTable/DynamicScheduleTable';

import DatePicker from 'components/pickers/DatePicker';
import { FormattedMessage } from 'react-intl';
import styles from '../Schedule.module.css';
import ListScheduleViewModel from './ListSchedule.viewModel';

const ListScheduleComponent = () => {
	const {
		fieldValues,
		selectedField,
		setSelectedField,
		formData,
		setFormData,
		handleTextFilters,
		handlerFilters,
		handlerClear,
		technicalsDataTable,
		isEnable,
		value,
		formatterText,
		_titlesTableAgenda
	} = ListScheduleViewModel();
	return (
		<>
			<section className="wrapForm">
				<section className="wrapForm w-100">
					<label className={styles['selects-advanced-space']}>
						<SelectComboBox
							name="idTecnico"
							selectValue={selectedField.idTecnico}
							setterFunction={(idTecnico) => setSelectedField({ ...selectedField, idTecnico })}
							data={fieldValues.technicals}
							placeholder={
								<FormattedMessage
									id="input.placeholder.agenda.select.technical"
									defaultMessage="Filtrar por técnico"
								/>
							}
						/>
					</label>
					<label className={styles['selects-advanced-space']}>
						<SelectComboBox
							name="idCargo"
							selectValue={selectedField.idCargo}
							setterFunction={(idCargo) => setSelectedField({ ...selectedField, idCargo })}
							data={fieldValues.positions}
							placeholder={
								<FormattedMessage
									id="input.placeholder.agenda.select.position"
									defaultMessage="Filtrar por cargo"
								/>
							}
						/>
					</label>
					<label className={styles['selects-advanced-space']}>
						<SelectComboBox
							name="idPais"
							selectValue={selectedField.idPais}
							setterFunction={(idPais) => setSelectedField({ ...selectedField, idPais })}
							data={fieldValues.countries}
							placeholder={
								<FormattedMessage
									id="input.placeholder.agenda.select.country"
									defaultMessage="Filtrar por país"
								/>
							}
						/>
					</label>
					<label className={styles['selects-advanced-space']}>
						<SelectComboBox
							name="idDepartamento"
							selectValue={selectedField.idDepartamento}
							setterFunction={(idDepartamento) =>
								setSelectedField({ ...selectedField, idDepartamento })
							}
							data={fieldValues.states}
							placeholder={
								<FormattedMessage
									id="input.placeholder.agenda.select.deparment"
									defaultMessage="Filtrar por departamento"
								/>
							}
						/>
					</label>
					<label className={styles['selects-advanced-space']}>
						<SelectComboBox
							name="idMunicipio"
							selectValue={selectedField.idMunicipio}
							setterFunction={(idMunicipio) => setSelectedField({ ...selectedField, idMunicipio })}
							data={fieldValues.municipalities}
							placeholder={
								<FormattedMessage
									id="input.placeholder.agenda.select.municipality"
									defaultMessage="Filtrar por municipio"
								/>
							}
						/>
					</label>
					<label className={styles['selects-advanced-space']}>
						<SelectComboBox
							name="idTipoDocumento"
							selectValue={selectedField.idTipoDocumento}
							setterFunction={(idTipoDocumento) =>
								setSelectedField({ ...selectedField, idTipoDocumento })
							}
							data={fieldValues.typeDocument}
							placeholder={
								<FormattedMessage
									id="input.placeholder.agenda.select.typeDocument"
									defaultMessage="Filtrar por tipo de documento"
								/>
							}
						/>
					</label>
					<label className="wrapForm__label">
						<input
							className="input-primary"
							type="text"
							name="numeroDocumento"
							placeholder={formatterText(
								'text.filter.identification',
								'Filtrar por identificación'
							)}
							onChange={handleTextFilters}
							disabled={isEnable}
							value={formData.numeroDocumento}
							maxLength="15"
							required
						/>
					</label>

					<label className="wrapForm__label">
						<DatePicker
							name="fechaInicio"
							selectValue={formData.fechaInicio}
							setterFunction={setFormData}
							placeholder={formatterText('text.shedule.create.startdate')}
							noIcon={true}
						/>
					</label>
					<label className="wrapForm__label">
						<DatePicker
							name="fechaFin"
							selectValue={formData.fechaFin}
							setterFunction={setFormData}
							placeholder={formatterText('text.shedule.create.enddate')}
							noIcon={true}
						/>
					</label>
				</section>

				<section className="schedule-avanced-search">
					<button
						className={value ? 'input-cancel' : 'btn-search'}
						onClick={handlerFilters}
						disabled={value}
					>
						{value ? 'Cargando...' : formatterText('btn.search', 'Buscar')}
					</button>
					<button className="input-cancel" onClick={handlerClear}>
						<FormattedMessage id="btn.clean" defaultMessage="Limpiar" />
					</button>
				</section>

				<section className={styles.containerData}>
					<label className={styles['selects-advanced-space']}>
						<p
							style={{
								backgroundColor: '#85EB4E',
								padding: '0.5rem 1rem',
								borderRadius: '0.5rem'
							}}
						>
							<FormattedMessage
								id="text.conversion.schedule.available"
								defaultMessage={'Disponible'}
							/>
						</p>
						<p
							style={{
								backgroundColor: '#F1684E',
								padding: '0.5rem 1rem',
								borderRadius: '0.5rem'
							}}
						>
							<FormattedMessage
								id="text.conversion.schedule.unavailable"
								defaultMessage={'No disponible'}
							/>
						</p>
					</label>
				</section>
			</section>

			<DynamicScheduleTable
				titles={_titlesTableAgenda()}
				data={technicalsDataTable}
				type="agendaData" // the row
				canSearch={true}
				max={true}
			/>
		</>
	);
};
export default ListScheduleComponent;
