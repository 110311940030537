import useAxios from 'hooks/useAxios';

import { useEffect, useState } from 'react';
// Import Contexts
import { useSeachContext } from 'context/SearchContext';

import { usePermissionContext } from 'context/PermissionContext';
// Import Hooks
import useGetData from 'hooks/useGetData';

import useLangv2 from 'hooks/useLangv2';
// Import Services

// Import Constants
import { MODULES_NAME } from 'common/validators/PermissionsConstants';

const ContractTypeViewModel = () => {
	const { RequestUseCase, endpoints } = useAxios();
	// hook to loading data
	const { loading, error, toggleLoading, displayMessage, displayLoading } = useGetData();
	// useContext de búsqueda
	const { setDataTable } = useSeachContext();

	// use Hook of language v2
	const { formatterText } = useLangv2();

	const [cargos, setCargos] = useState(null);

	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();

	const permissionsAccessGaranted = () => {
		permissionsAccess(MODULES_NAME.typeContract);
	};

	useEffect(() => {
		getDataTable();
	}, []);

	useEffect(() => {
		permissionsAccessGaranted();
	}, [permissions]);

	// función para cargar datos
	const getDataTable = () => {
		// show loading
		toggleLoading(true);
		RequestUseCase.get(endpoints.contracts.getAllContracts).then((data) => {
			const newArray = [];
			data.forEach((item) => handleStructureItems(newArray, item));
			setDataTable(newArray);
			// show loading
			setCargos(newArray);
			toggleLoading(false);
		});
	};

	const handleDeleteItem = (id) => {
		return new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.contracts.deleteContract(id))
				.then((res) => {
					const newArray = [];
					res.forEach((item) => {
						handleStructureItems(newArray, item);
					});

					setDataTable(newArray);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const handleEditStateItem = (body) => {
		return new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.contracts.updateContract, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const handleStructureItems = (newArray, item) => {
		newArray.push({
			id: item.idTipoContrato,
			nombre: item.nombre,
			descripcion: item.descripcion,
			codigo: item.codigo,
			estado: item.estado,
			objeto: item
		});
	};

	// titulos de la tabla
	const titles = [
		formatterText('table.title.contract.type.name', 'Nombre del tipo de contrato'),
		formatterText('table.title.description', 'Descripción'),
		formatterText('table.title.code', 'Código'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];

	return {
		loading,
		error,
		displayMessage,
		displayLoading,
		cargos,
		permittedActions,
		handleDeleteItem,
		handleEditStateItem,
		titles,
		formatterText,
		getDataTable
	};
};

export default ContractTypeViewModel;
