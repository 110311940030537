import { useEffect, useState } from 'react';
// Import Libs
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
// Import Components
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';
//Import hooks
import { useForm } from '@formiz/core';
import useAxios from 'hooks/useAxios';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import { useNavigate } from 'react-router-dom';
import paths from 'services/paths';
export default function CreateThirdPartiesViewModel() {
	// hook to loading data
	const { loading, toggleLoading } = useGetData();
	const { fetchData, endpoints, RequestUseCase } = useAxios();
	const navigate = useNavigate();
	const [searchResults, setSearchResults] = useState([]);
	const [archivos, setCurrentFiles] = useState([]);
	const [typeThirdParties, setTypeThirdParties] = useState([]);
	const [typeDocument, setTypeDocument] = useState([]);
	const { formatterText } = useLangv2();

	const regimen = [
		{
			label: 'Simplificado',
			value: '1'
		},
		{
			label: 'Común',
			value: '2'
		}
	];

	useEffect(() => {
		getAllTypeThirdParties();
		getAllMunicipios();
		getAllTypeDocuments();
	}, []);

	const getAllTypeThirdParties = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.parameters.getAllThirdTypes)
			.then((response) => {
				const ArrayMunicipios = [];
				response.map((item) => {
					if (item.estado === 1) {
						ArrayMunicipios.push({
							label: item.nombre,
							value: item.idTipoTercero
						});
					}
				});
				setTypeThirdParties(ArrayMunicipios);
				toggleLoading(false);
			})
			.catch((error) => {
				console.log(error);
				toggleLoading(false);
			});
	};

	const getAllMunicipios = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.zones.getAllMunicipalities)
			.then((response) => {
				const ArrayMunicipios = [];
				console.log(response);

				response.forEach((municipio) => {
					if (municipio.estado === 1) {
						ArrayMunicipios.push({
							label: `${municipio.nombre} - ${municipio.idDepartamento.nombre} - ${municipio.idDepartamento.idPais.nombrePais}`,
							value: municipio.idMunicipio
						});
					}
				});

				setSearchResults(ArrayMunicipios);
				toggleLoading(false);
			})
			.catch((error) => {
				console.log(error);
				toggleLoading(false);
			});
	};

	const getAllTypeDocuments = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.institutions.getAllTypeDocument)
			.then((response) => {
				const ArrayMunicipios = [];
				response.map((item) =>
					ArrayMunicipios.push({
						label: `${item.nombre} - ${item.abreviatura}`,
						value: item.idTipoDocumento
					})
				);
				setTypeDocument(ArrayMunicipios);
				toggleLoading(false);
			})
			.catch((error) => {
				console.log(error);
				toggleLoading(false);
			});
	};

	const myForm = useForm();

	const handleSubmit = (values) => {
		const data = {
			idTercero: null,
			nombre: values.nombre,
			idTipoDocumento: {
				idTipoDocumento: values.tipoDocumento
			},
			regimen: values.regimen,
			identificacion: values.identificacion,
			idMunicipio: {
				idMunicipio: values.idMunicipio
			},
			direccion: values.direccion,
			telefono: values.telefono,
			telefonoAlterno: values.telefonoAlterno,
			sitioWeb: values.sitioWeb,
			correo: values.correo,
			idTipoTercero: {
				idTipoTercero: values.idTipoTercero
			},
			estado: 1,
			fechaRegistro: null,
			fechaModificacion: null,
			usuarioCreacion: Cookie.get('idUsuario'),
			usuarioModificacion: null
		};

		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return RequestUseCase.post(endpoints.thirdParties.addThirdParty, data)
					.then((response) => {
						uploadFiles(response.idTercero);
					})
					.catch((err) => {
						if (err.response?.data?.message) {
							HandleOnError(formatterText(err.response?.data?.message));
						} else {
							HandleOnError(
								formatterText(
									'snackbar.error.process.failed.general',
									'Error al realizar el proceso. Intentalo en otro momento.'
								)
							);
						}
					});
			}
		});
	};

	const uploadFiles = (id) => {
		return new Promise((resolve, reject) => {
			if (archivos.length > 0) {
				fetchData({
					url: endpoints.UploadFiles.save,
					method: 'post',
					body: {
						idOrigen: id,
						idTipoOrigenArchivo: 8, // 8 = Tercero
						archivos
					}
				})
					.then(() => {
						resolve(
							CustomAlert('confirm_msg', {
								icon: 'success',
								title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
								text: formatterText(
									'alert.message.confirm.created.general',
									'El registro se ha creado correctamente'
								),
								confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
								allowOutsideClick: false,
								executeFunction: () => navigate(paths.thirdParties)
							})
						);
					})
					.catch((err) => {
						reject(err);
					});
			} else {
				resolve(
					CustomAlert('confirm_msg', {
						icon: 'success',
						title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
						text: formatterText(
							'alert.message.confirm.created.general',
							'El registro se ha creado correctamente'
						),
						confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
						allowOutsideClick: false,
						executeFunction: () => navigate(paths.thirdParties)
					})
				);
			}
		});
	};
	return {
		archivos,
		formatterText,
		handleSubmit,
		loading,
		myForm,
		navigate,
		regimen,
		searchResults,
		setCurrentFiles,
		typeDocument,
		typeThirdParties
	};
}
