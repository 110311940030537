import Cookie from 'js-cookie';

class Product {
	constructor(
		idProducto = null,
		nombre = '',
		sku = '',
		descripcion = '',
		usuarioCreacion = Cookie.get('idUsuario'),
		usuarioModificacion = Cookie.get('idUsuario')
	) {
		this.idProducto = idProducto;
		this.nombre = nombre;
		this.sku = sku;
		this.descripcion = descripcion;
		this.usuarioCreacion = usuarioCreacion;
		this.usuarioModificacion = usuarioModificacion;
	}
}

export default Product;
