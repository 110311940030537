export const PERMISSION_NAMES = [
	'EDITAR',
	'ELIMINAR',
	'EXPORTAR',
	'CONSULTAR',
	'CREAR',
	'IMPORTAR',
	'ANULAR',
	'CAMBIAR_TIPO_SERVICIO',
	'CAMBIAR ESTADO',
	'ELIMINAR ADJUNTO',
	'REABRIR',
	'BUSQUEDA MANUAL',
	'BUSQUEDA AUTOMATICA',
	'ELIMINAR RESPUESTA',
	'ELIMINAR TEC AUX',
	'MODIFICACION FECHA CITA'
];

export const MODULES_NAME = {
	user: 1,
	rol: 2,
	mailManagement: 3,
	zoneCatalog: {
		principal: 4,
		countries: 5,
		states: 6,
		cities: 7,
		sectors: 39
	},
	audit: 5,
	attachmentType: 44,
	clientParameters: {
		principal: 11,
		market: 31,
		unitBusiness: 28,
		providerType: 12,
		clientType: 13,
		invoiceType: 34,
		thirdPartiesType: 15
	},
	serviceParameters: {
		principal: 40,
		serviceCategory: 41,
		serviceStages: 42,
		manageActivities: 26,
		listPrices: 43,
		forms: 25
	},
	positions: 8,
	typeContract: 45,
	employee: 10,
	technicians: {
		principal: 12,
		vehicules: 46
	},
	thirdParties: 13,
	clients: 7,
	providers: 16,
	products: {
		principal: 15,
		categories: 15,
		products: 15
	},
	schedule: {
		principal: 18,
		schedule: 18,
		availability: 47
	},
	service: {
		principal: 17,
		nqr: 21,
		report: 20,
		invoice: 19,
		products: 22
	},
	homeView: 48,
	notificationPanel: 49,
	reconciliation: 50
};
