import useAxios from 'hooks/useAxios';
import { useEffect } from 'react';
import { useSeachContext } from 'context/SearchContext';
import useGetData from 'hooks/useGetData';
import SortData from 'components/utils/SortData';
import useLang from 'hooks/useLang';
import useLangv2 from 'hooks/useLangv2';
import endpoints from 'services/api';

export default function ServiceCategoryTableViewModel() {
	const { RequestUseCase } = useAxios();

	// hook to loading data
	const {
		loading,
		error,
		toggleLoading,
		toggleError,
		handleClick,
		displayMessage,
		displayLoading
	} = useGetData();

	const { setSearchResults, setDataTable } = useSeachContext();
	//  Call hook of language
	const { formatterText } = useLang();
	const { handleRequestError } = useLangv2();

	useEffect(() => {
		toggleLoading(true);
		getDataTable();
		toggleLoading(false);
	}, []);

	// función para cargar datos
	const getDataTable = () => {
		RequestUseCase.get(endpoints.serviceCategory.getAllServiceCategory)
			.then((data) => {
				const newArray = [];
				data.forEach((item) => {
					handleStructureItems(newArray, item);
				});
				const sortedArray = SortData(newArray, 'asc');
				setSearchResults(sortedArray);
				setDataTable(sortedArray);
			})
			.catch(() => {
				// mostrar error
				toggleError(!error);
				handleClick();
			});
	};

	const handleDeleteItem = (rowId) => {
		const deleteItemPromise = new Promise((resolve) => {
			RequestUseCase.delete(endpoints.serviceCategory.deleteServiceCategory(rowId))
				.then((res) => {
					const newArray = [];
					res.forEach((item) => {
						handleStructureItems(newArray, item);
					});
					const sortedArray = SortData(newArray, 'asc');
					setSearchResults(sortedArray);
					setDataTable(sortedArray);
					getDataTable();

					resolve(true);
				})
				.catch((err) => {
					handleRequestError(err);
				});
		});
		return deleteItemPromise;
	};

	const handleEditStateItem = (body) => {
		const updateItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.serviceCategory.updateServiceCategory, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return updateItemPromise;
	};

	const handleStructureItems = (newArray, item) => {
		newArray.push({
			id: item.idCategoriaServicio,
			nombre: item.nombre,
			descripcion: item.descripcion,
			codigo: item.codigo,
			estado: item.estado,
			objeto: { ...item }
		});
	};

	const titles = [
		formatterText('table.name.CategoryService', 'Nombre Categoría de servicio'),
		formatterText('table.title.description', 'Descripción'),
		formatterText('table.title.code', 'Código'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];
	return {
		loading,
		error,
		displayMessage,
		displayLoading,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		titles
	};
}
