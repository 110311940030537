import useGetData from 'hooks/useGetData';
import React from 'react';
import { MdDelete } from 'react-icons/md';

const TableBusinessByClient = ({ data, handleDelete }) => {
	const { loading, error, displayMessage, displayLoading } = useGetData();

	const displayError = () => {
		if (error) {
			return displayMessage(
				'error',
				'Ha ocurrido un error, intentalo más tarde.',
				'toast.error.general'
			);
		} else {
			return displayLoading();
		}
	};

	/*const handleDelete = (item, index) => {
    console.log(dataTableUnits);
    const filterBusinessUnit = dataTableUnits.filter(businessUnit => businessUnit.idUnidadNegocio != item.idUnidadNegocio);
    setDataTableUnits(filterBusinessUnit);
    setDataTable(filterBusinessUnit);
    //setDataTableBusinessUnit
    toggleLoading(true);
    console.log('entre en el delete');
    if (typeof column.idUnidadNegocio !== 'number') {
      updateItem(endpoints.businessUnit.updateBusinessUnitByClient, column.idUnidadNegocio)
        .then((res) => {
          setDataTable(
            data.filter((_, i) => {
              return i !== index;
            }),
          );
          toggleLoading(false);
        })
        .catch((err) => {
          console.log(err);
          toggleLoading(false);
        });
    } else {
      // filter in the array data the index selected and slice it
      const t = data.filter((_, i) => {      
        return i !== index;
      });
      setDataTableUnits(t);
      toggleLoading(false);
    }
    closeDialog();
  };*/

	return data.map((item, index) => (
		<>
			{!loading ? (
				<tr key={`${item?.idUnidadNegocio || index}`}>
					{/* <td>{item.codigo}</td> */}
					<td>{item?.nombre || ''}</td>
					<td>
						<MdDelete
							size={25}
							color="gray"
							cursor="pointer"
							onClick={() => handleDelete(item, index)}
						/>
					</td>
				</tr>
			) : (
				displayError()
			)}
		</>
	));
};

export default TableBusinessByClient;
