import React, { useContext, useEffect, useState } from 'react';
// Import Hooks
import { useParams } from 'react-router-dom';
// Mui Cell row style
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import BtnActions from 'common/minimalTables/BtnActions';
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import libs
import PropTypes from 'prop-types';
// Import Services
import { useSeachContext } from 'context/SearchContext';
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';
import endpoints from 'services/api';

const RowSubcategoryProduct = ({
	item,
	i, // index
	setDialog,
	closeDialog,
	handleOpen,
	update
}) => {
	const [rowState, setRowState] = useState(item.estado);
	const { searchResults = [] } = useSeachContext();
	const { currentDataTable, setCurrentDataTable, setResultsTableSearch, setModalData } =
		useContext(TableMinimalContext);
	const { successRemoveItem, handleRequestError, formatterText } = useLangv2();
	const { id } = useParams();

	// Manage axios requests
	const { COOKIE_USER, RequestUseCase } = useAxios();

	// Helps to re-render the component when the state changes
	useEffect(() => {
		setRowState(item.estado);
	}, [searchResults]);

	const updateTable = (row) => {
		const newMinimalRows = currentDataTable.filter(
			(et) => et.idSubCategoriaProducto !== row.idSubCategoriaProducto
		);
		setCurrentDataTable(newMinimalRows);
		setResultsTableSearch(newMinimalRows);
	};

	const handleDelete = async (row) => {
		try {
			if (typeof row.idSubCategoriaProducto === 'number') {
				await RequestUseCase.delete(
					endpoints.subProductCategory.deleteSubProductCategory(row.idSubCategoriaProducto)
				);
				updateTable(row);
			} else {
				updateTable(row);
			}
			successRemoveItem();
		} catch (error) {
			const message = { response: { data: { message: 'toast.error.general' } } };
			const resp = error.response.data.message ? error : message;
			handleRequestError(resp);
		} finally {
			closeDialog();
		}
	};

	const handleEdit = (row) => {
		handleOpen();
		setModalData(row);
	};

	const handleToggle = () => {
		const estado = rowState === 1 ? 0 : 1;
		closeDialog();
		if (typeof item.idSubCategoriaProducto === 'number') {
			const BODY = {
				idSubCategoriaProducto: item.idSubCategoriaProducto,
				idCategoriaProducto: {
					idCategoriaProducto: item.idCategoriaProducto.idCategoriaProducto
				},
				nombre: item.nombre,
				descripcion: item.descripcion,
				codigo: item.codigo,
				estado,
				fechaRegistro: item.fechaRegistro,
				fechaModificacion: null,
				usuarioCreacion: item.usuarioCreacion,
				usuarioModificacion: COOKIE_USER
			};

			RequestUseCase.put(endpoints.subProductCategory.updateSubProductCategory, BODY).then(() => {
				setRowState(estado);
				setCurrentDataTable(
					currentDataTable.map((et) => {
						if (et.idSubCategoriaProducto === item.idSubCategoriaProducto) {
							return { ...et, estado };
						}
						return et;
					})
				);
				update(id);
			});
		} else {
			// alert("No se puede cambiar el estado de un registro nuevo");
		}
	};

	return (
		<TableRow key={i}>
			<TableCell>{item.nombre}</TableCell>
			<TableCell>{item.descripcion}</TableCell>
			<TableCell>{item.codigo}</TableCell>
			<TableCell className="enabled" align="center">
				{rowState === 1 ? (
					<div
						className="btn-state pointer"
						onClick={() =>
							setDialog({
								text: `${formatterText('dialog.title.delete.mid.first')} ${
									item.nombre
								} ${formatterText('dialog.title.delete.mid.end.inactive')}`,
								active: true,
								funcion: () => handleToggle()
							})
						}
					>
						{formatterText('btn.active')}
					</div>
				) : (
					<div
						className="btn-inactive_state pointer"
						onClick={() => {
							setDialog({
								text: `${formatterText('dialog.title.delete.mid.first')} ${
									item.nombre
								} ${formatterText('dialog.title.delete.mid.end.active')}`,
								active: true,
								action: 'active',
								funcion: () => handleToggle()
							});
						}}
					>
						{formatterText('btn.inactive')}
					</div>
				)}
			</TableCell>
			<BtnActions
				item={item}
				rowState={rowState}
				setDialog={setDialog}
				handleEdit={handleEdit}
				handleDelete={handleDelete}
			/>
		</TableRow>
	);
};

export default RowSubcategoryProduct;

RowSubcategoryProduct.propTypes = {
	item: PropTypes.object,
	i: PropTypes.number,
	setDialog: PropTypes.func,
	closeDialog: PropTypes.func,
	handleOpen: PropTypes.func
};
