import DynamicTable from 'common/DynamicTable/DynamicHead';
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import paths from 'services/paths';
import { ProductTableProps } from './ProductTable.props';
import ProductTableViewModel from './ProductTable.viewModel';

const ProductTable = ({ permisos }) => {
	const {
		displayLoading,
		displayMessage,
		error,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		loading,
		product,
		titles
	} = ProductTableViewModel();

	return (
		<>
			{!loading && product !== null ? (
				<>
					<FormattedMessage id="table.name.search.product" defaultMessage="Categoría de producto">
						{() => (
							<DynamicTable
								titles={titles}
								pageName={PAGE_NAMES.Productos}
								getData={getDataTable}
								handleDeleteItem={handleDeleteItem}
								handleEditStateItem={handleEditStateItem}
								routeToEdit={paths.updateProduct}
								canDeleted={permisos.eliminar}
								canModify={permisos.editar}
							/>
						)}
					</FormattedMessage>
				</>
			) : error ? (
				displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			) : (
				displayLoading()
			)}
		</>
	);
};
ProductTable.prototype = ProductTableProps;
export default ProductTable;
