import React from 'react';
// Import Components
import DynamicHeadNotifications from '../DynamicTableNotificacion/DynamicHeadNotifications';
// Import libs
import { FormattedMessage } from 'react-intl';
// Import services
import { ALERTS_PAGENAME } from 'constants/alertsPageName';
import NotificationsViewModel from './Notifications.viewModel';

const titles = [
	<FormattedMessage key={'tab.title.dates'} id="tab.title.dates" defaultMessage="Fecha / Hora" />,
	<FormattedMessage key="title.service.file" id="title.service.file" defaultMessage="Expediente" />,
	<FormattedMessage
		key={'tab.title.category'}
		id="tab.title.category"
		defaultMessage="Categoria"
	/>,
	<FormattedMessage key={'tab.title.message'} id="tab.title.message" defaultMessage="Mensaje" />,
	<FormattedMessage
		key={'table.name.search'}
		id="table.name.search.client"
		defaultMessage="Cliente"
	/>,
	<FormattedMessage key={'table.title.city'} id="table.title.city" defaultMessage="Ciudad" />,
	<FormattedMessage
		key={'tab.title.date'}
		id="tab.title.date.markasread"
		defaultMessage="Leído el"
	/>,
	<FormattedMessage
		key={'tab.title.markasread'}
		id="tab.title.markasread"
		defaultMessage="Marcar como leida"
	/>
];
const Notifications = () => {
	const {
		loading,
		error,
		displayMessage,
		displayLoading,
		setCurrentPage,
		setRowsPerPage,
		setUpdated,
		state,
		DeterminateTypeUser
	} = NotificationsViewModel();

	if (loading) {
		if (error) {
			return displayMessage(
				'error',
				'Ha ocurrido un error, intentalo más tarde.',
				'toast.error.general'
			);
		}
		return displayLoading();
	}

	return (
		<FormattedMessage id="table.name.search.product" defaultMessage="Categoría de producto">
			{() => (
				<DynamicHeadNotifications
					titles={titles}
					getData={DeterminateTypeUser}
					page={state.currentPageNotification}
					rowsPerPage={state.notificationPerPage}
					setPage={setCurrentPage}
					setRowsPerPage={setRowsPerPage}
					data={state.filteredNotification}
					totalData={state.currentCountNotification}
					updated={setUpdated}
					pageName={ALERTS_PAGENAME.notificaciones}
				/>
			)}
		</FormattedMessage>
	);
};

export default Notifications;
