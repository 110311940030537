import { useEffect, useState } from 'react';
// Import Libs
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
// Import Components
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';
// Import Hooks
import { useForm } from '@formiz/core';
import useAxios from 'hooks/useAxios';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import { useNavigate, useParams } from 'react-router-dom';
// Import Paths
import paths from 'services/paths';
export default function UpdateThirdPartiesViewModel() {
	// hook to loading data
	const { loading, toggleLoading } = useGetData();
	const { fetchData, RequestUseCase, endpoints } = useAxios();
	const navigate = useNavigate();
	const [searchResults, setSearchResults] = useState([]);
	const [archivos, setCurrentFiles] = useState([]);
	const [typeThirdParties, setTypeThirdParties] = useState([]);
	const [typeDocument, setTypeDocument] = useState([]);
	const [currentThird, setCurrentThird] = useState(null);
	const regimen = [
		{
			label: 'Simplificado',
			value: 'simplificado'
		},
		{
			label: 'Común',
			value: 'comun'
		}
	];

	// get url id
	const { id } = useParams();
	// use Hook of language v2
	const { formatterText, resourceNotFound } = useLangv2();

	useEffect(() => {
		getAllTypeThirdParties();
		getAllMunicipios();
		getAllTypeDocuments();
		getThirdById(id);
	}, []);

	const getAllTypeThirdParties = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.parameters.getAllThirdTypes)
			.then((response) => {
				const ArrayMunicipios = [];
				response.map((item) => {
					if (item.estado === 1) {
						ArrayMunicipios.push({
							label: item.nombre,
							value: {
								value: item.idTipoTercero
							}
						});
					}
				});
				setTypeThirdParties(ArrayMunicipios);
				toggleLoading(false);
			})
			.catch(() => {
				toggleLoading(false);
			});
	};

	const getAllMunicipios = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.zones.getAllMunicipalities)
			.then((response) => {
				const ArrayMunicipios = [];
				response.forEach((municipio) => {
					if (municipio.estado === 1) {
						ArrayMunicipios.push({
							label: `${municipio.nombre} - ${municipio.idDepartamento.nombre} - ${municipio.idDepartamento.idPais.nombrePais}`,
							value: municipio.idMunicipio
						});
					}
				});
				setSearchResults(ArrayMunicipios);
				toggleLoading(false);
			})
			.catch(() => {
				toggleLoading(false);
			});
	};

	const getAllTypeDocuments = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.institutions.getAllTypeDocument)
			.then((response) => {
				const ArrayMunicipios = [];
				response.map((item) => {
					ArrayMunicipios.push({
						label: `${item.nombre} - ${item.abreviatura}`,
						value: {
							value: item.idTipoDocumento
						}
					});
				});
				setTypeDocument(ArrayMunicipios);
				toggleLoading(false);
			})
			.catch(() => {
				toggleLoading(false);
			});
	};
	//get data from api
	const getThirdById = (id) => {
		RequestUseCase.get(endpoints.thirdParties.getThirdPartyById(id)).then((res) => {
			if (res !== null) {
				setCurrentThird(res);
				getDataAttachedFiles(res.idTercero);
			} else {
				resourceNotFound();
			}
		});
	};

	const myForm = useForm();

	const handleSubmit = (values) => {
		console.log(values);
		const data = {
			idTercero: currentThird.idTercero,
			nombre: values.nombre,
			idTipoDocumento: {
				idTipoDocumento: values.tipoDocumento.value
			},
			regimen: values.regimen.label ? values.regimen.label : values.regimen,
			identificacion: values.identificacion,
			idMunicipio: {
				idMunicipio: values.idMunicipio.label ? values.idMunicipio.value : values.idMunicipio
			},
			direccion: values.direccion,
			telefono: values.telefono,
			telefonoAlterno: values.telefonoAlterno,
			sitioWeb: values.sitioWeb,
			correo: values.correo,
			idTipoTercero: {
				idTipoTercero: values.idTipoTercero.value
			},
			estado: 1,
			fechaRegistro: currentThird.fechaRegistro,
			fechaModificacion: null,
			usuarioCreacion: Cookie.get('idUsuario'),
			usuarioModificacion: Cookie.get('idUsuario')
		};

		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.update.general', 'Se va a editar el registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return RequestUseCase.put(endpoints.thirdParties.updateThirdParty, data)
					.then(() => {
						CustomAlert('confirm_msg', {
							icon: 'success',
							title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
							text: formatterText(
								'alert.message.confirm.updated.general',
								'El registro se ha actualizado correctamente'
							),
							confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
							allowOutsideClick: false,
							executeFunction: () => navigate(paths.thirdParties)
						});
					})
					.catch((err) => {
						if (err.response.status === 412) {
							HandleOnError(formatterText('p.document.already.exists'));
						} else {
							HandleOnError(formatterText('alert.message.failed.general'));
						}
					});
			}
		});
	};

	const uploadNewFile = async (file) => {
		fetchData({
			url: endpoints.UploadFiles.save,
			method: 'post',
			body: {
				idOrigen: currentThird.idTercero,
				idTipoOrigenArchivo: 8, // 8 = tercero
				archivos: [file]
			}
		}).then(() => {
			CustomAlert('confirm_msg', {
				icon: 'success',
				title: 'Operación exitosa',
				text: 'Se agregó el adjunto correctamente',
				confirmButtonText: 'Continuar',
				allowOutsideClick: false,
				executeFunction: () => getDataAttachedFiles(id)
			});
		});
	};

	const getDataAttachedFiles = async (id) => {
		RequestUseCase.get(endpoints.UploadFiles.findArchivosById({
				idOrigen: id,
				idTipoOrigenArchivo: 8
			})
		).then((response) => {
			const files = response.map((file) => {
				return {
					name: file,
					url: file
				};
			});
			setCurrentFiles(files);
		});
	};
	return {
		archivos,
		currentThird,
		formatterText,
		handleSubmit,
		loading,
		myForm,
		navigate,
		regimen,
		searchResults,
		setCurrentFiles,
		typeDocument,
		typeThirdParties,
		uploadNewFile
	};
}
