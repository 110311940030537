import React from 'react';
import PropTypes from 'prop-types';

// Import Libs
import { FormikProvider } from 'formik';

// Import Components
import { SelectMultiTag } from 'common/selects/SelectMultiTag';
import { InputCheckbox } from 'common/inputs/InputCheckbox';
import { Input } from 'common/inputs/Input';
import { Button } from 'common/Button';

// Import Paths
import paths from 'services/paths';

// Import ViewModel

export const UpsertForm = ({
	edit = false,
	formik,
	formatterText,
	handleAddRole,
	handleRemoveRole,
	navigate,
	setToggleSelector,
	toggleSelector
}) => {
	const {
		errors,
		handleBlur,
		handleChange,
		handleSubmit,
		isSubmitting,
		isValid,
		setFieldValue,
		touched,
		values
	} = formik;

	return (
		<FormikProvider value={formik}>
			<form className="form-responsive margin-top" noValidate onSubmit={handleSubmit}>
				<Input
					error={errors.nombre}
					labelText={formatterText('p.name.general', 'Nombre')}
					name={'nombre'}
					onBlur={handleBlur}
					placeholder={formatterText('input.placeholder.role.name', 'Nombre del rol')}
					onChange={handleChange}
					touched={touched.nombre}
					type={'text'}
					value={values.nombre}
				/>
				<Input
					area
					error={errors.description}
					labelText={formatterText('table.title.description', 'Descripción')}
					name={'description'}
					onBlur={handleBlur}
					placeholder={formatterText('input.placeholder.rol.description', 'Descripción del rol')}
					setFieldValue={setFieldValue}
					touched={touched.description}
					type={'text'}
					onChange={handleChange}
					value={values.description}
				/>
				{edit && (
					<InputCheckbox
						activeText={formatterText('p.active', 'Activo')}
						labelText={formatterText('table.title.role', 'Rol')}
						name={'active'}
						value={values.active}
						onChange={handleChange}
						unActiveText={formatterText('p.unActive', 'No activo')}
					/>
				)}
				<SelectMultiTag
					error={errors.permissions}
					handleAdd={handleAddRole}
					handleRemove={handleRemoveRole}
					labelText={formatterText('p.permissions.modules', 'Permisos y mósdsdulos')}
					name={'permissions'}
					setToggleSelector={setToggleSelector}
					textButtonAdd={formatterText('btn.add.permission', 'Añadir permiso +')}
					toggleSelector={toggleSelector}
					value={values.permissions}
				/>
				<section className="form-responsive-container-buttons">
					<Button
						type="submit"
						className="btn-primary"
						text={formatterText('alert.button.confirm.general', 'Guardar cambios')}
						disabled={!isValid || isSubmitting}
					/>
					<Button
						className="input-cancel"
						text={formatterText('alert.button.cancel.general', 'Cancelar')}
						onClick={() => navigate(paths.roles)}
					/>
				</section>
			</form>
		</FormikProvider>
	);
};

UpsertForm.propTypes = {
	edit: PropTypes.bool,
	formatterText: PropTypes.func,
	handleAddRole: PropTypes.func,
	handleRemoveRole: PropTypes.func,
	navigate: PropTypes.func,
	selectedPermisosModulos: PropTypes.array,
	setToggleSelector: PropTypes.func,
	toggleSelector: PropTypes.bool
};
