import useAxios from 'hooks/useAxios';
import { useEffect } from 'react';

// Import Libs
import dateFormat from 'dateformat';

// Import Contexts
import { useSeachContext } from 'context/SearchContext';

// Import Hooks
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';

export default function TechnicalsTableViewModel() {
	const { endpoints, RequestUseCase } = useAxios();
	// hook to loading data
	const { formatterText } = useLangv2();
	const {
		loading,
		error,
		toggleLoading,
		toggleError,
		handleClick,
		displayMessage,
		displayLoading
	} = useGetData();

	const { setDataTable } = useSeachContext();

	useEffect(() => {
		getDataTable();
	}, []);

	// función para cargar datos
	const getDataTable = () => {
		// show loading
		toggleLoading(true);

		RequestUseCase.get(endpoints.technical.getAllTechnical)
			.then((data) => {
				const newArray = [];
				data.forEach((item) => {
					handleStructureItems(newArray, item);
				});

				setDataTable(newArray);
				// show loading
				toggleLoading(false);
			})
			.catch((err) => {
				console.log(err);
				// mostrar error
				toggleError(!error);
				handleClick();
			});
	};

	const handleDeleteItem = (column) => {
		const deleteItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.technical.deleteTechnical(column))
				.then((res) => {
					const newArray = [];
					res.forEach((item) => {
						handleStructureItems(newArray, item);
					});

					setDataTable(newArray);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return deleteItemPromise;
	};

	const handleEditStateItem = (body) => {
		const updateItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.technical.updateTechnical, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return updateItemPromise;
	};

	const handleStructureItems = (newArray, item) => {
		let completeName;
		let document;
		let position;
		let typeOfContract;

		if (item.idEmpleado === null) {
			completeName = `${item.idTercero.nombre}`;
			document = item.idTercero ? item.idTercero.identificacion : 'No tiene un valor';
			position = item.idTercero.cargo ? item.idTercero.cargo.nombre : 'No tiene un valor asignado';
			typeOfContract = item.idTercero.tipoContrato
				? item.idTercero.tipoContrato.nombre
				: 'No tiene un valor asignado';
		} else {
			completeName = `${item.idEmpleado.primerNombre} ${item.idEmpleado.segundoNombre} ${item.idEmpleado.primerApellido} ${item.idEmpleado.segundoApellido}`;
			document = item.idEmpleado ? item.idEmpleado.identificacion : 'No tiene un valor';
			position = item.idEmpleado ? item.idEmpleado.cargo.nombre : 'No tiene un valor';
			typeOfContract = item.idEmpleado ? item.idEmpleado.tipoContrato.nombre : 'No tiene un valor';
		}
		newArray.push({
			id: item.idTecnico,
			documento: document,
			nombre: completeName,
			cargo: position,
			tipoContrato: typeOfContract,
			fechaCreacion:
				dateFormat(item.fechaCreacion, 'yyyy/mm/dd - h:MM:ss TT') || 'No tiene un valor',
			estado: item.estado,
			objeto: { ...item }
		});
	};

	// titulos de la tabla
	const titles = [
		formatterText('table.title.document', 'Documento'),
		formatterText('table.title.technician.name', 'Nombre de técnico'),
		formatterText('table.title.position', 'Cargo'),
		formatterText('table.title.contract.type', 'Tipo de contrato'),
		formatterText('table.title.date.creation', 'Fecha de creación'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];

	return {
		displayLoading,
		displayMessage,
		error,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		loading,
		titles
	};
}
