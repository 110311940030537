import { useState } from 'react';
// Import Libs
import axios from 'axios';
import Cookie from 'js-cookie';
import { lowerCase } from 'lodash';
import { nanoid } from 'nanoid';
import RequestUseCase, { endpoints } from 'services/api/useCase/RequestUseCase';

// get the cookie user
const COOKIE_USER = parseInt(Cookie.get('idUsuario'));
// get token
const TOKEN = sessionStorage.getItem('token');

const DATE_CREATED_AT = '2022-12-11T05:00:00.000+00:00';

// the config to send the token in the headers
const COMMON_CONFIG = {
	headers: {
		// 'Cache-Control': 'no-cache',
		// Pragma: 'no-cache',
		// Expires: '0',
		// 'Access-Control-Allow-Origin': '*',
		// 'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,OPTIONS,PATCH',
		// 'Access-Control-Allow-Headers':
		//   'X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version, Authorization',
		// 'Access-Control-Allow-Credentials': true,
		accept: '*/*',
		Authorization: TOKEN
	}
};
const CONFIG = {
	...COMMON_CONFIG,
	headers: {
		...COMMON_CONFIG.headers,
		'Content-Type': 'application/json'
	}
};

const CONFIG_FORM = {
	...COMMON_CONFIG,
	headers: {
		...COMMON_CONFIG.headers,
		'Content-Type': 'multipart/form-data'
	}
};

const CONFIG_DOWNLOAD = {
	...COMMON_CONFIG,
	headers: {
		...COMMON_CONFIG.headers,
		'Content-Type': 'multipart/form-data',
		responseType: 'blob'
	}
};

const CONFIG_XML = {
	...COMMON_CONFIG,
	headers: {
		...COMMON_CONFIG.headers,
		'Content-Type': 'text/xml'
	}
};

/**
 * Manage axios requests
 *
 * @description this hook is to manage different types of requests
 * @returns {object} the function to fetch data, the function to cancel the request and the cookie user
 */
const useAxios = () => {
	const [results] = useState();
	const [error, setError] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const hashConfig = {
		json: CONFIG,
		form: CONFIG_FORM,
		download: CONFIG_DOWNLOAD,
		xml: CONFIG_XML
	};
	/**
	 * @param url
	 * @param withSignal
	 * @param withHeaders
	 * @param method
	 * @param body
	 * @param typeConfig
	 * @param params
	 * @param headers
	 * @returns {Promise<{response, raw: *, id: string, loading: boolean, error: string}>}
	 */
	const fetchData = async ({
		url,
		withHeaders = true,
		method = 'get',
		body = null,
		typeConfig = 'json',
		params,
		headers = {}
	} = {}) => {
		/**
		 * @type {{response: {}, raw: {}, id: string, loading: boolean, error: string}}
		 */
		const base = { id: nanoid(), raw: {}, response: {}, loading: true, error: '200' };

		try {
			setIsLoading(true);

			const customHeaders = withHeaders
				? {
						...(hashConfig[typeConfig.toLowerCase()]?.headers ?? {}),
						...headers
				  }
				: {};

			const config = {
				...hashConfig[typeConfig.toLowerCase()],
				headers: customHeaders,
				params,
				signal: undefined
			};

			const formattedMethod = lowerCase(method);
			const raw = await axios[formattedMethod](url, body, config);
			const { data: response } = raw;
			const composedData = { ...base, raw, response, loading: false };

			setIsLoading(false);
			return composedData;
		} catch (error) {
			setError(error);
			setIsLoading(false);
			// return { ...base, loading: false, error };
			throw error;
		}
	};

	/**
	 * Function to cancel the request
	 *
	 * @description first, the controller is aborted, then the controller is set to a new controller
	 * because the controller is a reference, if the controller is not set to a new controller, the controller will be
	 * aborted again
	 * and the request will never be executed
	 */

	return {
		fetchData,
		RequestUseCase,
		results,
		error,
		isLoading,
		COOKIE_USER,
		TOKEN,
		endpoints,
		DATE_CREATED_AT
	};
};

export default useAxios;
