import React from 'react';
import { SearchWrapper } from 'context/SearchContext';
import ModalTechnicalTZComponent from './ModalTechnicalTZ.component';

export default function ModalTechnicalTZ() {
	return (
		<SearchWrapper>
			<ModalTechnicalTZComponent />
		</SearchWrapper>
	);
}
