import React from 'react';
// Import Components
import MultiTableMinimal from 'common/minimalTables/MultiTableMinimal';
// Import libs
import { FormattedMessage } from 'react-intl';
// Import Services
import { SelectorSchedule } from 'common/selects/SelectorSchedule';
import 'react-tabs/style/react-tabs.css';
import ModalAssignTechnicalViewModel from './ModalAssignTechnical.viewModel';
// Import Styles

export default function ModalAssignTechnical() {
	const {
		clearFilters,
		closeDialog,
		dataTableTechToAssign,
		dialog,
		DisplayProgress,
		documentForm,
		formatterText,
		formFilters,
		handlerDocumentFilter,
		handlerFilters,
		handleSaveTechnicalsToTimeZone,
		handleTextFilters,
		loadingProgress,
		selectedSearch,
		selectValues,
		setSelectValues,
		titlesTableTimeZone,
		toggle,
		value
	} = ModalAssignTechnicalViewModel();

	return (
		<div>
			<div className="link-search-container">
				<p
					className="link-search"
					onClick={() => {
						toggle(!value);
					}}
				>
					{formatterText('text.action.show.advanced.search', 'Búsqueda avanzada')}
				</p>
			</div>
			{value && (
				<section className="wrapForm w-100">
					<label className="wrapForm__label">
						<input
							className="input-primary"
							type="text"
							name="nombreTecnico"
							placeholder={formatterText(
								'input.placeholder.filter.by.tech',
								'Filtrar por nombre técnico'
							)}
							onChange={handleTextFilters}
							value={formFilters.nombreTecnico}
							maxLength="45"
							required
						/>
					</label>
					<SelectorSchedule
						name="idCategory"
						data={selectedSearch.categories}
						placeholder={
							<FormattedMessage
								id="input.placeholder.agenda.select.categories"
								defaultMessage="Filtrar por categoría"
							/>
						}
						dataValue={selectValues.idCategory}
						setterFunction={setSelectValues}
					/>

					<SelectorSchedule
						name="idPais"
						data={selectedSearch.countries}
						placeholder={
							<FormattedMessage
								id="input.placeholder.agenda.select.country"
								defaultMessage="Filtrar por país"
							/>
						}
						dataValue={selectValues.idPais}
						setterFunction={setSelectValues}
					/>
					<SelectorSchedule
						name="idDepartamento"
						data={selectedSearch.departments}
						placeholder={
							<FormattedMessage
								id="input.placeholder.agenda.select.deparment"
								defaultMessage="Filtrar por departamento"
							/>
						}
						dataValue={selectValues.idDepartamento}
						setterFunction={setSelectValues}
					/>
					<SelectorSchedule
						name="idMunicipio"
						data={selectedSearch.municipalities}
						placeholder={
							<FormattedMessage
								id="input.placeholder.agenda.select.municipality"
								defaultMessage="Filtrar por municipio"
							/>
						}
						dataValue={selectValues.idMunicipio}
						setterFunction={setSelectValues}
					/>
					<label className="wrapForm__label">
						<input
							className="input-primary"
							type="text"
							name="numeroDocumento"
							placeholder={formatterText(
								'text.filter.identification',
								'Filtrar por identificación'
							)}
							onChange={handlerDocumentFilter}
							value={documentForm.numeroDocumento}
							maxLength="15"
							required
						/>
					</label>
					<button className="btn-search" onClick={(e) => handlerFilters(e)}>
						{formatterText('btn.search', 'Buscar')}
					</button>
					<button
						className="btn-search"
						onClick={(e) => {
							clearFilters(e);
						}}
					>
						{formatterText('btn.clean', 'Limpiar')}
					</button>
				</section>
			)}
			<section
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '100%'
				}}
			>
				<button
					type="button"
					className="btn btn-primary"
					onClick={(e) => handleSaveTechnicalsToTimeZone(e)}
				>
					{formatterText('btn.end', 'Terminar')}
				</button>
			</section>
			<section className="form-responsive-container-information">
				<span className="title-table">
					<FormattedMessage
						id="text.shedule.title.technical.found"
						defaultMessage="Técnicos encontrados"
					/>
				</span>
				{loadingProgress ? (
					<DisplayProgress />
				) : (
					<MultiTableMinimal
						titles={titlesTableTimeZone}
						data={dataTableTechToAssign}
						type="technicalNoAssign"
						dialog={dialog}
						closeDialog={closeDialog}
						canSearch={true}
					/>
				)}
			</section>
		</div>
	);
}
