import React from 'react';

// Import Components
import { UpsertForm } from '../UpsetRol/components';

// Import ViewModel
import CreateRolViewModel from './CreateRol.viewModel';

// Import Styles
import '../rol.css';

export default function CreateRolComponent() {
	const {
		formatterText,
		formik,
		handleAddRole,
		handleRemoveRole,
		navigate,
		setToggleSelector,
		toggleSelector
	} = CreateRolViewModel();
	return (
		<UpsertForm
			formatterText={formatterText}
			formik={formik}
			handleAddRole={handleAddRole}
			handleRemoveRole={handleRemoveRole}
			navigate={navigate}
			setToggleSelector={setToggleSelector}
			toggleSelector={toggleSelector}
		/>
	);
}
