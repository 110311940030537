class Parameter {
	constructor(
		idTipoTercero = null,
		nombre = '',
		descripcion = '',
		codigo = '',
		estado = '',
		fechaRegistro = '',
		fechaModificacion = ''
	) {
		this.idTipoTercero = idTipoTercero;
		this.nombre = nombre;
		this.descripcion = descripcion;
		this.codigo = codigo;
		this.estado = estado;
		this.fechaRegistro = fechaRegistro;
		this.fechaModificacion = fechaModificacion;
	}
}

export default Parameter;
