import React from 'react';

// Import Contexts
import { SearchWrapper } from 'context/SearchContext';
import { PermissionWrapper } from 'context/PermissionContext';

// Import Components
import ClientParametersTableComponent from './ClientParametersTable.component';

const ClientParametersTable = () => {
	return (
		<SearchWrapper>
			<PermissionWrapper>
				<ClientParametersTableComponent />
			</PermissionWrapper>
		</SearchWrapper>
	);
};

export default ClientParametersTable;
