import React from 'react';
// Import Hooks
import paths from 'services/paths';
//import Components
import { Formiz, FormizStep } from '@formiz/core';
import { isMaxLength } from '@formiz/validations';
import { InputField } from 'common/InputField';
//import libs
import { QuizzBuilder } from 'react-quizz-spanish';
import 'react-quizz-spanish/lib/assets/antd.css';

import UpdateFormViewModel from './UpdateForm.viewModel';

export default function UpdateForm() {
	const {
		myForm,
		handleSubmit,
		formatterText,
		navigate,
		printable,
		active,
		setActive,
		loadingProgress,
		prevForm,
		setPrevForm,
		DisplayProgress,
		form
	} = UpdateFormViewModel();

	return (
		<div className="centered-form">
			<Formiz onValidSubmit={handleSubmit} connect={myForm}>
				<form
					noValidate
					onSubmit={myForm.submit}
					className="container-wrapForm"
					style={{ minHeight: '16rem' }}
				>
					<div className="new-container-wrapForm__tabs">
						{myForm.steps.map((step) => (
							<button
								key={step.name}
								className={`new-tab-option ${
									step.name === myForm.currentStep.name ? 'is-active' : ''
								}`}
								type="button"
								onClick={() => myForm.goToStep(step.name)}
							>
								{formatterText(step.label)}
								{!step.isValid && step.isSubmitted && <small className="mr-2">⚠️</small>}
							</button>
						))}
					</div>

					<div className="container-wrapForm-content">
						<FormizStep name="step1" label="column.name.form.title.step">
							<section className="wrapForm">
								<InputField
									type="text"
									name="nombre"
									labelText={formatterText('column.name.form.name')}
									placeholder={formatterText('column.name.form.name')}
									defaultValue={printable.nombreFormulario || ''}
									validateInput="text"
									required={formatterText('column.name.form.required.name.form')}
									validations={[
										{
											rule: isMaxLength(45),
											message: formatterText('p.label.title.ingresa100Caracteres')
										}
									]}
									styleName="input-primary"
								/>
								<InputField
									type="text"
									name="descripcion"
									defaultValue={printable.descripcionFormulario || ''}
									labelText={formatterText('table.title.description')}
									placeholder={formatterText('table.title.description')}
									validateInput="text"
									validations={[
										{
											rule: isMaxLength(200),
											message: 'La descripción debe tener como máximo 200 caracteres'
										}
									]}
									styleName="input-textarea"
									asArea={true}
								/>

								<InputField
									type="text"
									name="code"
									labelText={formatterText('table.title.code')}
									placeholder={formatterText('table.title.code')}
									defaultValue={printable.codigoFormulario || ''}
									validateInput="code-lower-case"
									styleName="input-primary"
								/>

								<section className="form-responsive-information__option">
									<h3>{formatterText('table.title.state')}</h3>
									<label className="form-responsive-label">
										<p className="form-responsive-toggle">
											{active ? formatterText('p.active') : formatterText('p.unActive')}
										</p>
										<label className="switch">
											<input
												checked={!!active}
												onChange={() => {
													setActive(!active ? 1 : 0);
												}}
												type="checkbox"
											/>
											<span className="slider round"></span>
										</label>
									</label>
								</section>
							</section>
						</FormizStep>
						<FormizStep name="step2" label="column.name.form.title.step2">
							<p className="wrap-form-title">{formatterText('column.name.form')}</p>

							{!loadingProgress ? (
								prevForm.length > 0 ? (
									<QuizzBuilder initialValue={prevForm} language="es" onChange={setPrevForm} />
								) : (
									<QuizzBuilder initialValue={form} language="es" onChange={setPrevForm} />
								)
							) : (
								<DisplayProgress />
							)}
						</FormizStep>
					</div>
					<div className="demo-form__footer">
						<section className="form-responsive-container-buttons">
							<button type="submit" className="btn-primary" id="submit-form">
								{formatterText('column.name.form.update')}
							</button>

							<button className="input-cancel" onClick={() => navigate(paths.services)}>
								{formatterText('question.swal.title.edit.cancel')}
							</button>
						</section>
					</div>
				</form>
			</Formiz>
		</div>
	);
}
