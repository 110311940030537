import useAxios from 'hooks/useAxios';
import React, { useContext, useEffect, useState } from 'react';
// Import Context
import DynamicTable from 'common/DynamicTable/DynamicHead';
import { AppContext } from 'context/AppContext';
import { SearchWrapper, useSeachContext } from 'context/SearchContext';
import { FormattedMessage } from 'react-intl';
// Import Hooks
import useGetData from 'hooks/useGetData';
import { useParams } from 'react-router';
// Import Components
import SortDataByDate from 'components/utils/SortDataByDate';
// Import Services
import dateFormat from 'dateformat';
import CreatePrecaution from 'screens/services/Modals/CreatePrecaution';
import endpoints from 'services/api';
import paths from 'services/paths';
//Modal
import { COLLECTIONS } from 'constants/lang/services/services/collections';
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';
import { formatterText } from 'hooks/useLangv2';
import { Modal } from 'react-responsive-modal';
import EditPrecaution from 'screens/services/Modals/EditPrecaution';

const Precautions = () => {
	return (
		<SearchWrapper>
			<PrecautionsComponent />
		</SearchWrapper>
	);
};

function PrecautionsComponent() {
	const { RequestUseCase } = useAxios();

	// get id url
	const { id } = useParams();
	// hook to loading data
	const {
		// loading,
		error,
		toggleLoading,
		toggleError,
		handleClick
		// displayMessage,
		// displayLoading,
	} = useGetData();

	const { objectPrecaution, openModalEditPrecaution, setOpenModalEditPrecaution } =
		useContext(AppContext);

	const onCloseEditPrecautions = () => setOpenModalEditPrecaution(false);

	// useContext de búsqueda
	const { setDataTable } = useSeachContext();

	useEffect(() => {
		getDataTable();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getDataTable();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	// títulos de la tabla de Recaudos
	const titlesR = [
		formatterText(COLLECTIONS.tableTitles.halfPayment),
		formatterText(COLLECTIONS.tableTitles.collectionStatus),
		formatterText(COLLECTIONS.tableTitles.cost),
		formatterText(COLLECTIONS.tableTitles.description),
		formatterText(COLLECTIONS.tableTitles.user),
		formatterText(COLLECTIONS.tableTitles.creationDate)
		// "Acciones",
	];

	// función para cargar datos
	const getDataTable = () => {
		// show loading
		toggleLoading(true);

		RequestUseCase.get(endpoints.precautionsServices.getAllPrecautionsByIdService(id))
			.then((data) => {
				const newArray = [];

				const i = [];
				const r = [];
				data.forEach((item) => {
					handleStructureItems(newArray, item, i, r);
				});
				// sort array by date desc
				const sortedArray = SortDataByDate(newArray, 'desc');

				// sumar todos los enteros de i
				const RI = i.reduce((a, b) => a + b, 0);

				// sumar todos los enteros de r
				const RR = r.reduce((a, b) => a + b, 0);
				setIncome(RI);
				setRefund(RR);
				setCollection(RI - RR);
				setDataTable(sortedArray);
				// show loading
				toggleLoading(false);
			})
			.catch((err) => {
				console.log(err);
				// mostrar error
				toggleError(!error);
				handleClick();
			});
	};

	const handleStructureItems = (newArray, item, i, r) => {
		const timeZone = 'America/Bogota';
		if (item.idEstadoRecaudo.nombre === 'procesado') {
			i.push(item.valor);
		} else if (item.idEstadoRecaudo.nombre === 'devolución') {
			r.push(item.valor);
		}
		newArray.push({
			id: item.idRecaudoServicio,
			nombre: item.idMedioPago.nombre,
			estadoRecaudo: item.idEstadoRecaudo.nombre,
			costo: item.valor,
			descripcion: item.descripcion,
			usuario: item.nombreUsuario,
			FechaCreación: dateFormat(item.fechaCreacion, 'yyyy/mm/dd - HH:MM TT', { timeZone }),
			objeto: { ...item }
		});
	};

	const handleDeleteItem = (rowId) => {
		const deleteItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.precautionsServices.deletePrecaution(rowId))
				.then(() => {
					getDataTable();
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return deleteItemPromise;
	};

	const handleEditStateItem = (body) => {
		const updateItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.users.updateUser, body)
				.then((res) => {
					// console.log(res);
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return updateItemPromise;
	};

	const [income, setIncome] = useState(0);
	const [refund, setRefund] = useState(0);
	const [collection, setCollection] = useState(0);

	// Modal config
	const [openPrecautions, setOpenPrecautions] = useState(false);
	const onOpenPrecautions = () => setOpenPrecautions(true);
	const onClosePrecautions = () => setOpenPrecautions(false);

	return (
		<>
			<div className="externalElementsContainer">
				<label className="wrapForm__label">
					<h3
						onClick={(e) => {
							e.preventDefault();
							onOpenPrecautions();
						}}
						className="p-styles primary-green spacing-l1"
					>
						+ {formatterText(COLLECTIONS.registerCollection)}
					</h3>
				</label>
				<label className="wrapForm__label">
					<h3 className="p-styles spacing-l1 dark-gray">
						{formatterText(COLLECTIONS.income)}: ${income}
					</h3>
				</label>
				<label className="wrapForm__label">
					<h3 className="p-styles spacing-l1 dark-gray">
						{formatterText(COLLECTIONS.returns)}: ${refund}
					</h3>
				</label>
				<label className="wrapForm__label">
					<h3 className="p-styles spacing-l1 dark-gray">
						{formatterText(COLLECTIONS.totalValueCollected)}: ${collection}
					</h3>
				</label>
			</div>

			<section className="tdR thR table-container">
				<FormattedMessage id="table.name.search.users" defaultMessage="Recaudos">
					{() => (
						<DynamicTable
							titles={titlesR}
							pageName={PAGE_NAMES.Recaudos}
							getData={getDataTable}
							handleDeleteItem={handleDeleteItem}
							handleEditStateItem={handleEditStateItem}
							routeToEdit={paths.updateUser}
							// canDeleted={true}
							// canEditPrecaution={true}
						/>
					)}
				</FormattedMessage>
			</section>

			{/* Precaution modal */}
			<Modal
				open={openPrecautions}
				onClose={onClosePrecautions}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<CreatePrecaution
					onClose={onClosePrecautions}
					exeFun={getDataTable}
					income={income}
					refund={refund}
				/>
			</Modal>

			<Modal
				open={openModalEditPrecaution}
				onClose={onCloseEditPrecautions}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal-90'
				}}
			>
				<EditPrecaution
					onClose={onCloseEditPrecautions}
					exeFun={getDataTable}
					dataFromContext={objectPrecaution}
				/>
			</Modal>
		</>
	);
}

export default Precautions;
