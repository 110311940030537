import React from 'react';
// Import Contexts
import { SearchWrapper } from 'context/SearchContext';
import { ContextAlert } from '../ContextAlert';
import PanelNotificationsComponent from './container.component';
// Import Hooks

const PanelNotifications = () => {
	return (
		<SearchWrapper>
			<ContextAlert>
				<PanelNotificationsComponent />
			</ContextAlert>
		</SearchWrapper>
	);
};

export default PanelNotifications;
