import useAxios from 'hooks/useAxios';

import { useEffect, useState } from 'react';

// Import Contexts
import { useSeachContext } from 'context/SearchContext';
import useLangv2 from 'hooks/useLangv2'; // Import Hooks

// hook para cargar los datos
import useGetData from 'hooks/useGetData';
// Import Services

export default function BillingTypesTableViewModel() {
	const { RequestUseCase, endpoints } = useAxios();

	const { loading, error, toggleLoading, displayMessage, displayLoading } = useGetData();

	const { setDataTable } = useSeachContext();

	const [parameters, setParameters] = useState(null);

	useEffect(() => {
		toggleLoading(true);
		getDataTable();
		toggleLoading(false);
	}, []);

	// función para cargar datos
	const getDataTable = () => {
		RequestUseCase.get(endpoints.billingTypes.getAllBillingTypes).then((data) => {
			const newArray = [];
			data.forEach((item) => handleStructureItems(newArray, item));
			setParameters(newArray);
			setDataTable(newArray);
		});
	};

	const handleDeleteItem = (rowId) => {
		const deleteItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.billingTypes.deleteBillingType(rowId))
				.then((res) => {
					const newArray = [];
					res.forEach((item) => handleStructureItems(newArray, item));
					setParameters(newArray);
					setDataTable(newArray);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return deleteItemPromise;
	};

	const handleEditStateItem = (body) => {
		const updateItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.billingTypes.updateBillingType, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return updateItemPromise;
	};

	const handleStructureItems = (newArray, item) => {
		newArray.push({
			id: item.idTipoFacturacion,
			nombre: item.nombre,
			descripcion: item.descripcion,
			codigo: item.codigo,
			estado: item.estado,
			objeto: { ...item }
		});
	};

	const { formatterText } = useLangv2();

	const titles = [
		formatterText('table.title.billing.type', 'Nombre tipo facturación'),
		formatterText('table.title.description', 'Descripción'),
		formatterText('table.title.code', 'Código'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];

	return {
		displayLoading,
		displayMessage,
		error,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		loading,
		parameters,
		titles
	};
}
