export const ACTIVITIES = {
	title: 'service.services.editService.activities',
	minutes: 'service.services.editService.activities.minutes',
	hours: 'service.services.editService.activities.hours',
	totalActivities: 'service.services.editService.activities.totalActivities',
	costService: 'service.services.editService.activities.costService',
	endService: 'service.services.editService.activities.endService',
	tableTitles: {
		nameActivity: 'service.services.editService.activities.nameActivity',
		serviceStage: 'service.services.editService.activities.serviceStage',
		developmentMinutes: 'service.services.editService.activities.developmentMinutes',
		activityStatus: 'service.services.editService.activities.activityStatus',
		technicalAspect: 'service.services.editService.activities.technicalAspect',
		activityCost: 'service.services.editService.activities.activityCost',
		formCompleted: 'tab.title.form.completed'
	}
};
