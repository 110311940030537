import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Axios from 'axios';
import CustomAlert from 'components/CustomAlert';
import Cookie from 'js-cookie';
import endpoints from 'services/api';

const AuthContext = createContext();

export function ProviderAuth({ children }) {
	const auth = useProviderAuth();
	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

ProviderAuth.propTypes = {
	children: PropTypes.node
};

export const useAuth = () => {
	return useContext(AuthContext);
};

function useProviderAuth() {
	const [userAuth, setUser] = useState(Cookie.get('status'));

	const signIn = async (user, password) => {
		let value = false;

		const options = {
			headers: {
				accept: '*/*',
				'Content-Type': 'multipart/form-data'
			}
		};

		await Axios.post(endpoints.auth.login, { user, password }, options)
			.then((response) => {
				const access_token = response?.data.token;
				if (access_token) {
					value = true;
					const token = access_token;
					sessionStorage.setItem('token', token);
					setUser(token);
					// send to axios define the value of the token
					Axios.defaults.headers.Authorization = token;
					localStorage.setItem('lang', 'en-ES');
				}
			})
			.catch(() => {
				value = false;
				CustomAlert('short_msg', {
					icon: 'error',
					title: 'Error en el inicio de sesión',
					text: 'La contraseña o el usuario son incorrectos'
				});
			});

		return value;
	};

	const logout = () => {
		//eliminacion cookies
		const cookies = Cookie.get(); // Obtiene un objeto con todas las cookies
		for (const cookieName in cookies) {
			Cookie.remove(cookieName);
		}
		sessionStorage.removeItem('token');
		localStorage.clear();
		setUser(false);
		delete Axios.defaults.headers.Authorization;
		window.location.href = '#/login';
	};

	const login = () => {
		setUser(true);
		Cookie.set('status', true, {
			sameSite: 'None',
			secure: true
		});
		localStorage.setItem('lang', 'en-ES');
	};

	return {
		userAuth,
		signIn,
		logout,
		login
	};
}
