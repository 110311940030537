import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';

// Import Libs
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

// Import Components
import HandleInput from 'common/validators/HandleInput';
import HandleOnError from 'common/validators/HandleOnError';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import CustomAlert from 'components/CustomAlert';

// Import Hooks
import useLangv2 from 'hooks/useLangv2';

//Import Models
import ContractType from 'models/Contracts/ContractType';

//Import Regex
import { TEXTREGEX, TEXTREGEXMIN } from 'common/validators/Regex';

// Import Services
import paths from 'services/paths';

const UpdateContractViewModal = () => {
	const { endpoints, RequestUseCase } = useAxios();
	const { id } = useParams();
	const navigate = useNavigate();
	// Datos de un formulario
	const [formData, setFormData] = useState(new ContractType());
	// toggle state
	const [active, setActive] = useState(false);
	// use Hook of language v2
	const { formatterText, noFilledContent } = useLangv2();

	const [errorName, setErrorName] = useState('');
	const [codeError, setCodeError] = useState('');
	const [descriptionError, setdescriptionError] = useState('');

	useEffect(() => {
		getDataContractById();
	}, []);

	const getDataContractById = () => {
		RequestUseCase.get(endpoints.contracts.getContractById(id))
			.then((res) => {
				setFormData(res);
				setActive(res.estado === 1);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// Update a string to set into the form
	const handleText = (e) => {
		if (e.target.value.match(TEXTREGEX)) {
			setFormData({ ...formData, [e.target.name]: e.target.value });
		}
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	// This function sets the form value by the onChange of the input
	const handleChange = (e) => {
		const example = e;
		example.target.value = e.target.value.toLowerCase();
		HandleInput(example, TEXTREGEXMIN, formData, setFormData);
	};

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		if (
			errorName !== '' ||
			codeError !== '' ||
			descriptionError !== '' ||
			formData.nombre === '' ||
			formData.codigo === '' ||
			formData.descripcion === ''
		) {
			noFilledContent();
		} else {
			// Primera opción para crear
			const data = {
				...formData,
				estado: active ? '1' : '0',
				usuarioModificacion: Cookie.get('idUsuario')
			};

			Swal.fire({
				title: formatterText(
					'alert.title.general',
					'Atención, estás seguro de realizar esta acción'
				),
				text: formatterText('alert.description.update.general', 'Se va a editar el registro'),
				icon: 'question',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				showLoaderOnConfirm: true,
				cancelButtonColor: '#d33',
				confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
				allowOutsideClick: false,
				cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
				preConfirm: () => {
					return new Promise(() => {
						RequestUseCase.put(endpoints.contracts.updateContract, data)
							.then(() => {
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.updated.general',
										'El registro se ha actualizado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => navigate(paths.contracts)
								});
							})
							.catch((err) => {
								if (err.response.status === 412) {
									HandleOnError(formatterText('alert.message.code.error.general'));
								} else {
									HandleOnError(formatterText('alert.title.contract.type.creation.error'));
								}
							});
					});
				}
			});
		}
	};

	const handleErrorName = () => {
		if (formData.nombre.trim() === '') {
			setErrorName(formatterText('p.name.contract.type.is.required'));
		} else {
			setErrorName('');
		}
	};

	const handleErrorCode = () => {
		if (formData.codigo.trim() === '') {
			setCodeError(formatterText('table.title.code.required'));
		} else {
			setCodeError('');
		}
	};

	const handleErrordescripcion = () => {
		if (formData.descripcion.trim() === '') {
			setdescriptionError(formatterText('p.description.is.required'));
		} else {
			setdescriptionError('');
		}
	};

	return {
		active,
		codeError,
		descriptionError,
		errorName,
		formatterText,
		formData,
		handleChange,
		handleErrorCode,
		handleErrordescripcion,
		handleErrorName,
		handlerTextDescription,
		handleSubmit,
		handleText,
		navigate,
		setActive
	};
};

export default UpdateContractViewModal;
