import RowTechnical from 'common/minimalTables/schedule/RowTechnical';
import { useSeachContext } from 'context/SearchContext';
import useLangv2 from 'hooks/useLangv2';
import React, { useEffect, useRef } from 'react';
import 'styles/minimalTable.css';

export default function DynamicScheduleTableViewModel({ data, type }) {
	const { handleSearch, searchResults = [], setDataTable } = useSeachContext();
	// use Hook of language v2
	const { formatterText } = useLangv2();
	// Choose a row
	const ChooseRow = (item, i) => {
		const TABLE_TYPE = {
			agendaData: <RowTechnical item={item} key={i} />
		};

		return TABLE_TYPE[type];
	};
	const searchRef = useRef(null);

	useEffect(() => {
		setDataTable(data);
	}, [data]);
	return { handleSearch, searchResults, formatterText, searchRef, ChooseRow };
}
