import useAxios from 'hooks/useAxios';
import { useState } from 'react';

// Import Libs
import { useNavigate } from 'react-router-dom';
import { useForm } from '@formiz/core';
import Swal from 'sweetalert2';

// Import Components
import HandleOnError from 'common/validators/HandleOnError';
import { validateDuplicateName } from 'common/validators/ValidateDuplicates';
import CustomAlert from 'components/CustomAlert';

// Import Hooks
import useLangv2 from 'hooks/useLangv2';

// Import Services
import paths from 'services/paths';

// Import Models
import ContractType from 'models/Contracts/ContractType';

const CreateContractViewModel = () => {
	const { endpoints, RequestUseCase } = useAxios();

	const navigate = useNavigate();

	// Formiz object
	const myForm = useForm();

	const [formData, setFormData] = useState(new ContractType());

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		if (
			validateDuplicateName(
				formData.nombre,
				formatterText('alert.title.contract.name.already'),
				() => RequestUseCase.get(endpoints.contracts.getAllContracts)
			)
		) {
			Swal.fire({
				title: formatterText(
					'alert.title.general',
					'Atención, estás seguro de realizar esta acción'
				),
				text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
				icon: 'question',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				showLoaderOnConfirm: true,
				cancelButtonColor: '#d33',
				confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
				allowOutsideClick: false,
				cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
				preConfirm: () => {
					return new Promise(() => {
						RequestUseCase.post(endpoints.contracts.addContract, formData)
							.then(() => {
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.created.general',
										'El registro se ha creado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => navigate(paths.contracts)
								});
							})
							.catch((err) => {
								if (err.response.status === 412) {
									HandleOnError(formatterText('alert.message.code.error.general'));
								} else {
									console.log('error');
									HandleOnError(formatterText('alert.title.contract.type.creation.error'));
								}

								console.log(err);
							});
					});
				}
			});
		}
	};

	const handleFormChange = (values) => {
		setFormData((prevData) => ({ ...prevData, ...values }));
	};

	// use Hook of language v2
	const { formatterText } = useLangv2();

	return {
		myForm,
		handleSubmit,
		handleFormChange,
		formatterText,
		navigate
	};
};

export default CreateContractViewModel;
