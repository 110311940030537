import { useEffect, useState } from 'react';

// Import libs
import dateFormat from 'dateformat';
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useForm } from '@formiz/core';

// Import Hooks
import useLangv2 from 'hooks/useLangv2';
import useAxios from 'hooks/useAxios';

// Import Components
import CustomAlert from 'components/CustomAlert';

// Import Utils
import rawCurrency from 'common/utils/rawCurrency';
import { FilterActive } from 'common/validators/FilterActives';
import HandleOnError from 'common/validators/HandleOnError';
import { COMPOUNDNAMEREGEX, NUMBERREGEX } from 'common/validators/Regex';

// Import Paths
import paths from 'services/paths';

export default function CreateEmployeeViewModel() {
	const navigate = useNavigate();
	const { fetchData, endpoints, RequestUseCase } = useAxios();
	const myForm = useForm();
	const [archivos, setCurrentFiles] = useState([]);
	const [showError, setShowError] = useState(false);
	const [isLoading] = useState(false);
	const [loadingData, setLoadingData] = useState(false);
	const [currentRelative, setCurrentRelative] = useState({
		idParentesco: '',
		idGenero: '',
		edad: '',
		nombreCompleto: ''
	});
	const [familiares, setFamiliares] = useState([]);
	const [selectData, setSelectData] = useState({});
	const now = new Date();
	const { formatterText, noFilledContent } = useLangv2();

	useEffect(() => {
		getData();
	}, []);

	const getData = () => {
		setLoadingData(true);

		const promesas = [
			RequestUseCase.get(endpoints.institutions.getAllKin),
			RequestUseCase.get(endpoints.institutions.getAllTypeDocument),
			RequestUseCase.get(endpoints.institutions.getAllGenres),
			RequestUseCase.get(endpoints.institutions.getAllHalthEntities),
			RequestUseCase.get(endpoints.institutions.getAllbanks),
			RequestUseCase.get(endpoints.institutions.getAllTypeAccounts),
			RequestUseCase.get(endpoints.zones.getAllMunicipalities),
			RequestUseCase.get(endpoints.cargos.getAllCargos),
			RequestUseCase.get(endpoints.contracts.getAllContracts)
		];

		Promise.all(promesas).then((values) => {
			setLoadingData(false);
			setSelectData({
				...selectData,
				documentTypes: values[1],
				genres: values[2],
				municipalities: values[6],
				contracts: FilterActive(values[8]),
				positions: FilterActive(values[7]),
				healthEntities: values[3],
				banks: values[4],
				typeAccount: values[5],
				kins: values[0]
			});
		});
	};

	const handleSubmit = (values) => {
		// verify if values.correo is a valid email
		if (showError) {
			return;
		}
		const data = {
			idEmpleado: null,
			idTipoDocumento: { idTipoDocumento: values.idTipoDocumento },
			primerNombre: values.primerNombre,
			segundoNombre: values.segundoNombre || '',
			primerApellido: values.primerApellido,
			segundoApellido: values.segundoApellido || '',
			identificacion: values.identificacion,
			fechaNacimiento: dateFormat(values.fechaNacimiento, 'isoDateTime'),
			idMunicipio: { idMunicipio: values.idMunicipio },
			direccion: values.direccion,
			telefono: values.telefono,
			correo: values.correo,
			genero: { idGenero: values.genero },
			fechaIngreso: dateFormat(values.fechaIngreso, 'isoDateTime'),
			fechaAfiliacionArl: dateFormat(values.fechaAfiliacionArl, 'isoDateTime'),
			observacion: values.observacion,
			cargo: { idCargo: values.cargo },
			tipoContrato: { idTipoContrato: values.tipoContrato },
			entidadSalud: { idEntidadSalud: values.entidadSalud },
			banco: { idBanco: values.banco },
			numeroCuenta: values.numeroCuenta,
			tipoCuenta: { idTipoCuenta: values.tipoCuenta },
			estado: 1,
			nombreFamiliar: values.nombreFamiliar,
			telefonoFamiliar: values.telefonoFamiliar,
			salario: rawCurrency(values.salario),
			rodamiento: rawCurrency(values.rodamiento),
			fechaRegistro: null,
			fechaModificacion: null,
			usuarioCreacion: Cookie.get('idUsuario'),
			usuarioModificacion: null
		};

		handleSaveEmployee(data);
	};

	useEffect(() => {
		if (
			myForm?.values?.correo !== '' &&
			myForm?.values?.correo !== undefined &&
			myForm?.values?.correo !== null
		) {
			if (!myForm.values?.correo.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
				setShowError(true);
			} else {
				setShowError(false);
			}
		}
	}, [myForm?.values?.correo]);

	const handleAddFamily = () => {
		if (
			currentRelative.nombreCompleto !== '' &&
			currentRelative.edad !== '' &&
			currentRelative.idParentesco !== '' &&
			currentRelative.idGenero !== ''
		) {
			setFamiliares([...familiares, currentRelative]);
		} else {
			noFilledContent();
		}
	};

	const handleChangeRelative = (e) => {
		const CONDITION = e.target.value.match(COMPOUNDNAMEREGEX);

		if (CONDITION) {
			setCurrentRelative({
				...currentRelative,
				[e.target.name]: e.target.value
			});
		}
	};

	const handleChangeNumberRelative = (e) => {
		const CONDITION = e.target.value.match(NUMBERREGEX);
		if (CONDITION) {
			setCurrentRelative({
				...currentRelative,
				[e.target.name]: e.target.value
			});
		}
	};

	const handleChangeSelect = (selectedOption) => {
		setCurrentRelative({
			...currentRelative,
			[selectedOption.target.name]:
				selectedOption.target.value === ''
					? selectedOption.target.value
					: JSON.parse(selectedOption.target.value)
		});
	};

	const handleSaveEmployee = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					RequestUseCase.post(endpoints.employees.addEmployee, data)
						.then((response) => {
							console.log(response.idEmpleado);
							hanldeSaveFamily(response.idEmpleado, resolve, reject);
						})
						.catch((err) => {
							if (err.response?.data?.message) {
								HandleOnError(formatterText(err.response?.data?.message));
							} else {
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
						});
				});
			}
		});
	};

	const hanldeSaveFamily = (id, resolve, reject) => {
		const promesas = familiares.map(
			(item) =>
				new Promise((resolve, reject) => {
					const data = {
						idFamiliares: null,
						genero: {
							idGenero: item.idGenero.idGenero
						},
						empleado: {
							idEmpleado: id
						},
						parentesco: {
							idParentesco: item.idParentesco.idParentesco
						},
						nombre: item.nombreCompleto,
						edad: item.edad
					};

					RequestUseCase.post(endpoints.employees.addFamily, data)
						.then((response) => {
							resolve(response);
						})
						.catch((err) => {
							reject(err);
						});
				})
		);
		Promise.all(promesas)
			.then(() => {
				resolve(handleSaveAttachedFiles(id));
			})
			.catch((err) => {
				if (err.response.status === 412) {
					reject(HandleOnError(err.response.data));
					console.log(err.response.data);
				} else {
					console.log('error');
					reject(HandleOnError(formatterText('alert.title.employee.creation.error')));
				}
				console.log(err);
			});
	};

	const handleSaveAttachedFiles = (id) => {
		return new Promise((resolve, reject) => {
			if (archivos.length > 0) {
				fetchData({
					url: endpoints.UploadFiles.save,
					method: 'post',
					body: {
						idOrigen: id,
						idTipoOrigenArchivo: 5, // 5 = Empleado
						archivos
					}
				})
					.then(() => {
						resolve(
							CustomAlert('confirm_msg', {
								icon: 'success',
								title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
								text: formatterText(
									'alert.message.confirm.created.general',
									'El registro se ha creado correctamente'
								),
								confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
								allowOutsideClick: false,
								executeFunction: () => navigate(paths.employees)
							})
						);
					})
					.catch((err) => {
						reject(err);
					});
			} else {
				resolve(
					CustomAlert('confirm_msg', {
						icon: 'success',
						title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
						text: formatterText(
							'alert.message.confirm.created.general',
							'El registro se ha creado correctamente'
						),
						confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
						allowOutsideClick: false,
						executeFunction: () => navigate(paths.employees)
					})
				);
			}
		});
	};

	return {
		archivos,
		currentRelative,
		familiares,
		formatterText,
		handleAddFamily,
		handleChangeNumberRelative,
		handleChangeRelative,
		handleChangeSelect,
		handleSubmit,
		isLoading,
		loadingData,
		myForm,
		navigate,
		now,
		selectData,
		setCurrentFiles,
		setFamiliares,
		showError
	};
}
