import { useState } from 'react';
// Impport Hooks
import { useNavigate, useParams } from 'react-router-dom';
// Impport Components
import CustomAlert from 'components/CustomAlert';
// Impport Services
import paths from 'services/paths';
// Impport Styles
import useLangv2 from 'hooks/useLangv2';
import { validateEmail } from 'services/api/auth';

export default function ChangeFirstPasswordViewModel() {
	const navigate = useNavigate();
	const { secret } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [newPassword, setNewPassword] = useState('');
	const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
	const { formatterText } = useLangv2();

	const [passwordShown, setPasswordShown] = useState(false);
	const togglePasswordVisiblity = () => {
		setPasswordShown(passwordShown ? false : true);
	};

	const restablishPassword = (e) => {
		e.preventDefault();
		setErrorMessage(false);

		if (newPassword === newPasswordConfirm) {
			//  create an expression to validate if the password have at least 1 number, at least 1 letter uppercase
			// at special character like !"#$%&/()=?¡¿+{-_-:.;,|*
			const regex =
				/^(?=.*\d)(?=.*[A-Z])(?=.*[!\"#$%&\/\(\)=?¡¿+\{\}\[\]\-_:\.;,\|*\^~])(?=.*[a-zA-Z]).{8,}$/;

			if (regex.test(newPassword)) {
				console.log('password correct');

				const jsonData = {
					secrect: secret,
					password: newPassword
				};
				setIsLoading(true);

				validateEmail(jsonData)
					.then((res) => {
						setIsLoading(false);
						if (res) {
							CustomAlert('confirm_msg', {
								icon: 'success',
								title: formatterText('title.user.passwordChanged.success'),
								text: formatterText('title.user.passwordChanged'),
								confirmButtonText: formatterText('title.user.passwordstMatch.log.in'),
								allowOutsideClick: false,
								executeFunction: () => navigate(paths.login)
							});
						}
					})
					.catch(() => {
						setIsLoading(false);
						CustomAlert('short_msg', {
							icon: 'error',
							title: 'Error',
							text: formatterText('title.user.verifyPasswordChangeLink')
						});
					});
				setErrorMessage(false);
			} else {
				setErrorMessage(true);
			}
		} else {
			CustomAlert('short_msg', {
				icon: 'error',
				title: 'Error',
				text: formatterText('title.user.passwordsDoNotMatch')
			});
			setIsLoading(false);
		}
	};

	return {
		isLoading,
		errorMessage,
		passwordShown,
		setNewPassword,
		setNewPasswordConfirm,
		togglePasswordVisiblity,
		restablishPassword,
		formatterText
	};
}
