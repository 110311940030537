import { formatterText } from 'hooks/useLangv2';

export const getHoursOfDay = () => {
	const now = new Date();
	now.setHours(0, 0, 0, 0);
	const hours = Array(24)
		.fill(null)
		.map((_, idx) => {
			now.setHours(idx);
			return formatHour(now, '24hours');
		});

	return hours;
};

export const getHoursOfRange = (date, endDate) => {
	const today = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours());
	const end = new Date(
		endDate.getFullYear(),
		endDate.getMonth(),
		endDate.getDate(),
		endDate.getHours()
	);
	const differenceInDays = Math.floor((end - today) / (24 * 60 * 60 * 1000)) + 1;
	const n = differenceInDays * 24;
	const now = new Date();
	now.setHours(0, 0, 0, 0);
	const hours = Array(n)
		.fill(null)
		.map((_, idx) => {
			now.setHours(idx);
			return formatHour(now, '12hours');
		});

	return hours;
};

export const getHoursOfDayForNDays = (startDate, endDate) => {
	const daysInRange = [];
	const currentDay = new Date(startDate);
	const endDay = new Date(endDate);

	while (currentDay <= endDay) {
		const hoursOfDay = Array(24)
			.fill(null)
			.map((_, idx) => {
				const currentHour = new Date(currentDay);
				currentHour.setHours(idx);
				return currentHour.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
			});

		daysInRange.push(hoursOfDay);
		currentDay.setDate(currentDay.getDate() + 1); // Avanzar al siguiente día
	}

	return daysInRange;
};

export const getHoursOfDayB = () => {
	const now = new Date();
	const datesWithHours = Array(24)
		.fill(null)
		.map((_, idx) => {
			const dateWithHour = new Date(now);
			dateWithHour.setHours(idx);
			return dateWithHour.toISOString(); // Retorna la fecha completa en formato ISO
		});

	return datesWithHours;
};

export const getDatesOfWeek = (date, endDate) => {
	if (!endDate || date.getTime() === endDate.getTime()) {
		return [new Date(date)];
	}

	const daysInRange = [];
	const today = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours());
	const end = new Date(
		endDate.getFullYear(),
		endDate.getMonth(),
		endDate.getDate(),
		endDate.getHours()
	);
	const differenceInDays = Math.floor((end - today) / (24 * 60 * 60 * 1000)) + 1;

	for (let i = 0; i < differenceInDays; i++) {
		const makeDate = new Date(today);
		makeDate.setDate(today.getDate() + i);
		daysInRange.push(makeDate);
	}

	return daysInRange;
};

export function formatHour(d, format) {
	const date = d ? new Date(d) : new Date();
	return date
		.toLocaleTimeString('en-US', {
			hour12: format === '12hours',
			hour: 'numeric',
			minute: 'numeric'
		})
		.toLowerCase();
}

export function getDaysOfWeek(firstDate) {
	if (!firstDate) {
		return [new Date(date)];
	}
	const date = new Date(firstDate);
	const days = Array(7)
		.fill(null)
		.map(function (_, idx) {
			if (idx === 0) return date;
			const d = new Date(date);
			d.setDate(date.getDate() + idx);
			return d;
		});

	return days;
}

export const today = () => new Date();

export const isToday = (date) => {
	const now = today();
	date.setHours(0, 0, 0, 0);
	now.setHours(0, 0, 0, 0);
	return date.getTime() === now.getTime();
};

export function formatDate(d) {
	const date = d ? new Date(d) : new Date();
	const year = date.getFullYear();
	let day = `${date.getDate()}`;
	let month = `${date.getMonth() + 1}`;
	day = day.length < 2 ? `0${day}` : day;
	month = month.length < 2 ? `0${month}` : month;
	return [year, month, day].join('/');
}

export function formatDateString(d) {
	const date = d ? new Date(d) : new Date();
	const year = date.getFullYear();
	let day = `${date.getDate()}`;
	const monthNames = [
		formatterText('header.gantt.month.jaunary'),
		formatterText('header.gantt.month.february'),
		formatterText('header.gantt.month.march'),
		formatterText('header.gantt.month.april'),
		formatterText('header.gantt.month.may'),
		formatterText('header.gantt.month.june'),
		formatterText('header.gantt.month.july'),
		formatterText('header.gantt.month.august'),
		formatterText('header.gantt.month.september'),
		formatterText('header.gantt.month.october'),
		formatterText('header.gantt.month.november'),
		formatterText('header.gantt.month.december')
	];
	const monthString = monthNames[`${date.getMonth()}`];
	const dayNames = [
		formatterText('header.gantt.week.sunday'),
		formatterText('header.gantt.week.monday'),
		formatterText('header.gantt.week.tuesday'),
		formatterText('header.gantt.week.wednesday'),
		formatterText('header.gantt.week.thursday'),
		formatterText('header.gantt.week.friday'),
		formatterText('header.gantt.week.saturday')
	];

	const dayName = dayNames[date.getDay()];
	day = day.length < 2 ? `0${day}` : day;

	return [dayName, '|', monthString, day, formatterText('header.gantt.day.conector.of'), year].join(
		' '
	);
}

export function diffInHours(date1, date2) {
	const hourNow = (date1.getTime() - date2.getTime()) / 1000;
	const diff = Math.abs(Math.floor(hourNow / (60 * 60)));
	return diff;
}
