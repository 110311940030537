import { FilterActive } from 'common/validators/FilterActives';
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';
import { useSeachContext } from 'context/SearchContext';
import useAxios from 'hooks/useAxios';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import paths from 'services/paths';
import Swal from 'sweetalert2';
import { FormViewMdel } from './components/form/form.viewModel';

export const CreateUserViewModels = () => {
	const { RequestUseCase, endpoints } = useAxios();
	const [roles, setRoles] = useState([]);
	const { setSearch, setRolSearch } = useSeachContext();
	const [listUsers, setListUsers] = useState([]);
	const { loading, toggleLoading } = useGetData();
	const [selectorData, setSelectorData] = useState([]);
	const { formatterText } = useLangv2();
	const [entity, setEntity] = useState({
		entidad: null,
		entidadId: 0
	});
	const navigate = useNavigate();
	const [selectedRoles, setSelectedRoles] = useState([]);
	const [toggleSelector, setToggleSelector] = useState(false);
	const [acces, setAcces] = useState({
		mobile: false,
		web: false
	});

	const [selectValue, setSelectValue] = useState('');

	const handleSubmit = () => {
		let tipoAcceso;

		if (acces.mobile && acces.web) {
			tipoAcceso = 3;
		} else if (acces.mobile && acces.web === false) {
			tipoAcceso = 2;
		} else if (acces.web && acces.mobile === false) {
			tipoAcceso = 1;
		} else {
			tipoAcceso = null;
		}

		let data = parseDataToSubmit(formik.values);

		switch (formik.values.entidad) {
			case 'tercero':
				data = {
					...data,
					tipoAcceso: tipoAcceso,

					idTercero: {
						idTercero: formik.values.entidadId
					}
				};
				break;
			case 'empleado':
				data = {
					...data,
					tipoAcceso: tipoAcceso,
					idEmpleado: {
						idEmpleado: formik.values.entidadId
					}
				};
				break;
			case 'cliente':
				data = {
					...data,

					tipoAcceso: tipoAcceso,
					idAsesorCliente: {
						idAsesor: formik.values.entidadId
					}
				};
				break;
			default:
				break;
		}

		if (selectedRoles.length === 0) {
			CustomAlert('short_msg', {
				icon: 'warning',
				title: 'Error',
				text: formatterText('alert.title.select.role'),
				confirmButtonColor: '#FFC954'
			});
		} else if (tipoAcceso === null) {
			CustomAlert('short_msg', {
				icon: 'warning',
				title: 'Error',
				text: formatterText('alert.title.select.access.type'),
				confirmButtonColor: '#FFC954'
			});
		} else {
			postUser(data);
		}
	};

	const { formik, parseDataToSubmit, formikInitialValues, setFormikInitialValues } = FormViewMdel({
		onSubmit: handleSubmit,
		formatterText
	});

	const getRoles = () => {
		RequestUseCase.get(endpoints.roles.getAllRolesActivos)
			.then((data) => {
				setRoles(FilterActive(data));
				setRolSearch(FilterActive(data));
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const getListUsers = () => {
		RequestUseCase.get(endpoints.users.getAllUsers)
			.then((ans) => {
				const res = ans.response;
				const newArray = [];
				res.map((item) => {
					return newArray.push({
						idUser: item.idUsuario,
						idEmpleado: item.idEmpleado?.idEmpleado,
						idAsesorCliente: item.idAsesorCliente?.idAsesor,
						idTercero: item.idTercero?.idTercero
					});
				});
				setListUsers(newArray);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const getThirdParties = () => {
		toggleLoading(true);
		// getAllThirdParties() replaced by tercerosGetByUserAsing
		RequestUseCase.get(endpoints.users.tercerosGetByUserAsing)
			.then((res) => {
				const newArray = [];
				res.map((item) => {
					if (item.estado === 1) {
						//verify if the user is already assigned to a third party or not
						const newLabel = `${item.nombre} - ${item.identificacion}`;
						return newArray.push({
							value: item.idTercero,
							label: newLabel
						});
					}
				});
				setSelectorData(newArray);
				toggleLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toggleLoading(false);
			});
	};

	const getEmployees = () => {
		toggleLoading(true);
		// getAllEmployees() replaced by empleadoGetByUserAsing
		RequestUseCase.get(endpoints.users.empleadoGetByUserAsing)
			.then((res) => {
				const newArray = [];
				res.map((item) => {
					//verify if the user is already assigned to an employee or not
					const newLabel = `${item.primerNombre} ${item.primerApellido} - ${item.identificacion}`;
					return newArray.push({
						value: item.idEmpleado,
						label: newLabel
					});
				});
				setSelectorData(newArray);
				toggleLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toggleLoading(false);
			});
	};

	const getAsesors = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.advisers.getAllAdvisers)
			.then((ans) => {
				const res = ans;
				const newArray = [];
				res.map((item) => {
					//verify if the user is already assigned to an adviser or not
					const isAssigned = listUsers.find((user) => user.idAsesorCliente === item.idAsesor);
					if (isAssigned) {
						return;
					}
					const newLabel = `${item.nombres}`;
					return newArray.push({
						value: item.idAsesor,
						label: newLabel
					});
				});
				setSelectorData(newArray);
				toggleLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toggleLoading(false);
			});
	};

	useEffect(() => {
		getRoles();
		getListUsers();
		setSearch([]);
	}, []);

	useEffect(() => {
		setSelectorData([]);
		setSelectValue(formatterText('p.select.option'));
		switch (formik.values.entidad) {
			case 'tercero':
				getThirdParties();
				break;
			case 'empleado':
				getEmployees();
				break;
			case 'cliente':
				getAsesors();
				break;
			default:
				break;
		}
	}, [formik.values.entidad]);

	const statusMessages = (status) => {
		let msg = formatterText('alert.error.create.user');

		if (status === 412) {
			msg = formatterText('alert.error.create.user.existing');
		}
		if (status === 428) {
			msg = formatterText('alert.error.create.user.invalid');
		}

		HandleOnError(msg);
	};

	const postUser = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text:
				selectedRoles.length === 0
					? formatterText('alert.title.users.without.roles')
					: formatterText('alert.title.users.creation'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('btn.create.user'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: async () => {
				RequestUseCase.post(endpoints.users.addUser, data)
					.then((response) => {
						asociateRole(response.idUsuario);
					})
					.catch((err) => {
						statusMessages(err.response.status);
					});
			}
		});
	};

	const asociateRole = (id) => {
		const roles = selectedRoles.map((rol) => {
			return { idRol: rol.idRol };
		});

		const data = {
			idUsuario: id,
			roles: roles
		};

		RequestUseCase.post(endpoints.users.insertRolesByUser, JSON.stringify(data))
			.then((response) => {
				if (response) {
					CustomAlert('confirm_msg', {
						icon: 'success',
						title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
						text: formatterText('alert.title.user.created'),
						confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
						allowOutsideClick: false,
						executeFunction: () => navigate(paths.users)
					});
				} else {
					HandleOnError(formatterText('alert.title.role.not.associated'));
				}
			})
			.catch((err) => {
				console.error(err);
				HandleOnError(formatterText('alert.title.role.not.associated'));
			});
	};

	const sortData = (selected) => {
		// sort the selected roles by name ASC
		selected.sort((a, b) => {
			if (a.nombre < b.nombre) {
				return -1;
			}
			if (a.nombre > b.nombre) {
				return 1;
			}
			return 0;
		});
	};

	const handleAddRole = (rol) => {
		const newRoles = roles.filter((currentRol) => currentRol.nombre !== rol.nombre);
		setRoles(newRoles);
		setRolSearch(newRoles);
		const selected = [...selectedRoles, rol];
		sortData(selected);
		setSelectedRoles(selected);
		setToggleSelector(!toggleSelector);
	};

	const handleRemoveRole = (e, rol) => {
		e.preventDefault();
		const selected = selectedRoles.filter((currentRol) => currentRol.nombre !== rol.nombre);
		sortData(selected);
		setSelectedRoles(selected);
		setRoles([...roles, rol]);
		setRolSearch([...roles, rol]);
	};

	return {
		formatterText,
		formik,
		roles,
		setRoles,
		listUsers,
		setSearch,
		setRolSearch,
		loading,
		selectorData,
		setSelectorData,
		entity,
		setEntity,
		selectValue,
		setSelectValue,
		selectedRoles,
		setSelectedRoles,
		acces,
		setAcces,
		toggleSelector,
		setToggleSelector,
		handleAddRole,
		handleRemoveRole,
		parseDataToSubmit,
		formikInitialValues,
		setFormikInitialValues,
		navigate
	};
};
