import SortData from 'components/utils/SortData';
import { useSeachContext } from 'context/SearchContext';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import { useEffect } from 'react';
import endpoints from 'services/api';
import useAxios from 'hooks/useAxios';

export default function ServiceStageTableViewModel() {
	const { RequestUseCase } = useAxios();
	// hook to loading data
	const { loading, error, toggleLoading, displayMessage, displayLoading } = useGetData();

	const { setDataTable } = useSeachContext();

	useEffect(() => {
		toggleLoading(true);
		getDataTable();
		toggleLoading(false);
	}, []);

	// función para cargar datos
	const getDataTable = () => {
		RequestUseCase.get(endpoints.stageServices.getAllStageServices).then((data) => {
			const newArray = [];
			data.forEach((item) => {
				handleStructureItems(newArray, item);
			});
			const sortedArray = SortData(newArray, 'asc');
			setDataTable(sortedArray);
		});
	};

	const handleDeleteItem = (rowId) => {
		const deleteItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.stageServices.deleteStageService(rowId))
				.then((res) => {
					const newArray = [];
					res.forEach((item) => {
						handleStructureItems(newArray, item);
					});
					const sortedArray = SortData(newArray, 'asc');
					setDataTable(sortedArray);

					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return deleteItemPromise;
	};

	const handleEditStateItem = (body) => {
		const updateItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.stageServices.updateStageService, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return updateItemPromise;
	};

	const handleStructureItems = (newArray, item) => {
		newArray.push({
			id: parseInt(item.idEtapaServicio),
			nombre: item.nombre,
			descripcion: item.descripcion,
			codigo: item.codigo,
			color: item.color,
			estado: parseInt(item.estado),
			objeto: { ...item }
		});
	};

	// use Hook of language v2
	const { formatterText } = useLangv2();

	const titles = [
		formatterText('table.title.service.stage', 'Nombre etapa de servicio'),
		formatterText('table.title.description', 'Descripción'),
		formatterText('table.title.code', 'Código'),
		formatterText('table.title.color', 'Color'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];

	return {
		loading,
		error,
		displayMessage,
		displayLoading,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		titles
	};
}
