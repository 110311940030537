import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import paths from 'services/paths';
import { restablishPasswordWithSecret } from 'services/api/auth';
import CustomAlert from 'components/CustomAlert';

export default function RestablishPasswordViewModel() {
	const navigate = useNavigate();
	const { secret } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [newPassword, setNewPassword] = useState('');
	const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
	const [passwordShown, setPasswordShown] = useState(false);
	const togglePasswordVisiblity = () => {
		setPasswordShown(passwordShown ? false : true);
	};

	const restablishPassword = (e) => {
		e.preventDefault();
		setErrorMessage(false);

		if (newPassword === newPasswordConfirm) {
			//  create an expression to validate if the password have at least 1 number, at least 1 letter uppercase and at special character
			const regex =
				/^(?=.*\d)(?=.*[A-Z])(?=.*[!\"#$%&\/\(\)=?¡¿+\{\}\[\]\-_:\.;,\|*\^~])(?=.*[a-zA-Z]).{8,}$/;

			if (regex.test(newPassword)) {
				console.log('password correct');

				const jsonData = {
					secrect: secret,
					password: newPassword
				};
				setIsLoading(true);

				restablishPasswordWithSecret(jsonData)
					.then((res) => {
						setIsLoading(false);
						if (res) {
							CustomAlert('confirm_msg', {
								icon: 'success',
								title: 'Contraseña cambiada',
								text: 'Su contraseña ha sido cambiada con éxito, vuelva a iniciar sesión.',
								confirmButtonText: 'Iniciar sesión',
								allowOutsideClick: false,
								executeFunction: () => navigate(paths.login)
							});
						}
					})
					.catch(() => {
						setIsLoading(false);
						CustomAlert('short_msg', {
							icon: 'error',
							title: 'Error',
							text: 'Verifique el enlace de restablecer de contraseña.'
						});
					});
				setErrorMessage(false);
			} else {
				setErrorMessage(true);
			}
		} else {
			CustomAlert('short_msg', {
				icon: 'error',
				title: 'Error',
				text: 'Las contraseñas no coinciden'
			});
			setIsLoading(false);
		}
	};

	return {
		isLoading,
		restablishPassword,
		setNewPassword,
		setNewPasswordConfirm,
		passwordShown,
		togglePasswordVisiblity,
		errorMessage,
		navigate
	};
}
