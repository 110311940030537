class ServiceType {
	constructor(
		serviceCategory = '',
		ticket = '',
		expediente = '',
		nombre_cliente = '',
		nombre_usuario = '',
		telefono_usuario = '',
		identificacion = '',
		idDepartamento = ''
	) {
		this.serviceCategory = serviceCategory;
		this.expediente = expediente;
		this.ticket = ticket;
		this.nombre_cliente = nombre_cliente;
		this.nombre_usuario = nombre_usuario;
		this.telefono_usuario = telefono_usuario;
		this.identificacion = identificacion;
		this.idDepartamento = idDepartamento;
	}
}

export default ServiceType;
