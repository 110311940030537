import useAxios from 'hooks/useAxios';
import React, { useState } from 'react';
// Import Hooks
import useLangv2 from 'hooks/useLangv2';
// Import Components
import DynamicTable from 'common/DynamicTable/DynamicHead';
import Search from 'common/Search';
import HandleOnError from 'common/validators/HandleOnError';
import HandlerNumbers from 'common/validators/HandlerNumbers';
import HandlerText from 'common/validators/HandlerText';
// Import de services
import SortData from 'components/utils/SortData';
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';
import { SearchWrapper, useSeachContext } from 'context/SearchContext';
import useGetData from 'hooks/useGetData';
import paths from 'services/paths';

const ModalBillingUser = ({ onClose, execFun }) => {
	return (
		<SearchWrapper>
			<ModalBillingUserComponent onClose={onClose} execFun={execFun} />
		</SearchWrapper>
	);
};

function ModalBillingUserComponent({ onClose }) {
	const { RequestUseCase, endpoints } = useAxios();

	// use Hook of language v2
	const { formatterText } = useLangv2();
	const titlesSearch = [
		formatterText('title.service.name.table.search'),
		formatterText('title.service.identification.table.search'),
		formatterText('title.service.phone'),
		formatterText('title.service.email'),
		formatterText('title.service.actions.table.search')
	];
	// hook to loading data
	const { error, toggleLoading, toggleError, handleClick } = useGetData();
	// useContext de búsqueda
	const { setDataTable } = useSeachContext();

	const handleEditStateItem = (body) => {
		return new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.clients.updateClient, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const handleStructureItems = (newArray, item) => {
		if (item.estado === 1) {
			newArray.push({
				id: item.idCliente,
				nombre: item.nombreRazonSocial,
				documento: item.identificacion,
				telefono: item.telefono,
				correo: item.correo,
				objeto: { ...item }
			});
		}
	};

	const [systemClientSearch, setSystemClientSearch] = useState({
		nombreRazonSocial: null,
		identificacion: null
	});

	const findSystemClients = () => {
		const jsonToSend = {
			nombreRazonSocial: systemClientSearch.nombreRazonSocial,
			identificacion: systemClientSearch.identificacion
		};
		RequestUseCase.post(endpoints.services.getAllClientsByQuery, jsonToSend)
			.then((data) => {
				const newArray = [];
				data.forEach((item) => handleStructureItems(newArray, item));
				const sortedArray = SortData(newArray, 'asc');
				setDataTable(sortedArray);
				if (sortedArray.length === 0) {
					HandleOnError('No hay datos para la busqueda');
				}
				// show loading
				toggleLoading(false);
			})
			.catch((err) => {
				console.log(err);
				// mostrar error
				toggleError(!error);
				handleClick();
			});
	};

	// Update a string to set into the form
	const handleText = (e) => {
		HandlerText(e, systemClientSearch, setSystemClientSearch);
	};

	// Update a number to set into the form
	const handleNumber = (e) => {
		HandlerNumbers(e, systemClientSearch, setSystemClientSearch);
	};

	return (
		<>
			<section className="container-search-modals">
				<section className="box-40p">
					<p className="p-text-primary">{formatterText('title.service.name.search')}</p>
					<input
						type="text"
						name="nombreRazonSocial"
						placeholder={formatterText('title.service.enterLegalEntityName')}
						value={systemClientSearch.nombreRazonSocial}
						onChange={(e) => handleText(e)}
						className="input-default"
					/>
				</section>
				<section className="box-40p">
					<p className="p-text-primary">{formatterText('title.service.identification')}</p>
					<input
						type="text"
						name="identificacion"
						placeholder={formatterText('title.service.enterIdentificationNumber')}
						value={systemClientSearch.identificacion}
						onChange={(e) => handleNumber(e)}
						className="input-default"
					/>
				</section>
				<button
					className="btn-search"
					onClick={(e) => {
						e.preventDefault();
						findSystemClients();
					}}
				>
					{formatterText('btn.search')}
				</button>
			</section>
			<section className="w100-container">
				<section className="table-container">
					<p className="p-text-primary">{formatterText('title.service.foundClients')}</p>
					<section className="boxTable">
						<Search
							placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
							width="50%"
						/>
						<button
							onClick={() => window.open(`#${paths.createClient}`, '_blank')}
							className="btn-add"
						>
							{formatterText('title.service.createClient')}
						</button>
					</section>
					<DynamicTable
						titles={titlesSearch}
						pageName={PAGE_NAMES.Clientes}
						getData={findSystemClients}
						handleDeleteItem={handleEditStateItem}
						handleEditStateItem={handleEditStateItem}
						routeToEdit={paths.updateClient}
						canDeleted={false}
						canModify={false}
						canSelectSBU={true}
						onClose={onClose}
					/>
				</section>
			</section>
		</>
	);
}

export default ModalBillingUser;
