import React from 'react';
import paths from 'services/paths';
import SimpleHeader from 'common/SimpleHeader';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import 'styles/loginRecover.css';
import { FormattedMessage } from 'react-intl';
import RestablishPasswordViewModel from './RestablishPassword.viewModel';

export default function RestablishPassword() {
	const {
		isLoading,
		restablishPassword,
		setNewPassword,
		setNewPasswordConfirm,
		passwordShown,
		togglePasswordVisiblity,
		errorMessage,
		navigate
	} = RestablishPasswordViewModel();

	return (
		<>
			<SimpleHeader />
			<section className="recover-password">
				<form
					className="recover-password-container"
					onSubmit={restablishPassword}
					autoComplete="off"
				>
					<h4 className="recover-password-title">
						<FormattedMessage id="menu.recover" defaultMessage="Recupera tu contraseña" />
					</h4>

					<div className="pass-wrapper">
						<input
							className="input-primary"
							type={passwordShown ? 'text' : 'password'}
							placeholder="Contraseña nueva"
							name="newPassword"
							onChange={(e) => setNewPassword(e.target.value)}
							maxLength="25"
							required
						/>
						{passwordShown ? (
							<IoMdEye size={30} className="eye-icon" onClick={togglePasswordVisiblity} />
						) : (
							<IoMdEyeOff size={30} className="eye-icon" onClick={togglePasswordVisiblity} />
						)}
					</div>

					<div className="pass-wrapper">
						<input
							className="input-primary"
							type={passwordShown ? 'text' : 'password'}
							placeholder="Confirmar contraseña nueva"
							name="newPasswordConfirm"
							onChange={(e) => setNewPasswordConfirm(e.target.value)}
							maxLength="25"
							required
						/>
						{passwordShown ? (
							<IoMdEye size={30} className="eye-icon" onClick={togglePasswordVisiblity} />
						) : (
							<IoMdEyeOff size={30} className="eye-icon" onClick={togglePasswordVisiblity} />
						)}
					</div>
					<button type="submit" className="btn-primary spacing" disabled={isLoading}>
						{isLoading ? 'Cargando...' : 'Cambiar contraseña'}
					</button>
					{errorMessage && (
						<ul className="error-message">
							<li>La contraseña debe tener:</li>
							<li>*Mínimo 8 caracteres.</li>
							<li>*Mínimo un número.</li>
							<li>*Mínimo una mayúscula.</li>
							<li>*Mínimo un carácter especial.</li>
						</ul>
					)}
					<p className="back" onClick={() => navigate(paths.login)}>
						<FormattedMessage id="menu.goback" defaultMessage="Regresar al inicio de sesion" />
					</p>
				</form>
			</section>
		</>
	);
}
