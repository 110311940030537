import Table from 'common/minimalTables/TableMinimal';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-responsive-modal';
import paths from 'services/paths';
import UpdateSubCategory from '../UpdateSubCategory';
import UpdateProductCategoryViewModel from './UpdateProductCategory.viewModel';

export default function UpdateProductCategoryComponent() {
	const {
		active,
		codeError,
		currentDataTable,
		currentProductCategory,
		errorName,
		formatterText,
		formData,
		getSubCategoriesProductById,
		handleAddproductCategory,
		handleChangeSubCategoryCode,
		handleClose,
		handleCode,
		handleErrorCode,
		handleErrorName,
		handleNameText,
		handleNameTextSubCategory,
		handleOpen,
		handlerTextDescription,
		handlerTextDescriptionSubCategory,
		handleSubmit,
		navigate,
		setActive,
		titlesTableSubCategories,
		open
	} = UpdateProductCategoryViewModel();

	return (
		<>
			<form onSubmit={handleSubmit}>
				<section className="form-responsive container-border-wrapForm">
					<p className="wrap-form-title">
						<FormattedMessage id="p.category.product" defaultMessage="Categoría Producto" />
					</p>
					<section className="form-responsive-container-information">
						<section className="form-responsive-information__option">
							<h3 className="p-styles">
								<FormattedMessage id="p.name.category" defaultMessage="Nombre categoría producto" />
							</h3>
							<input
								className="input-primary"
								type="text"
								name="nombre"
								value={formData.nombre}
								onChange={handleNameText}
								onBlur={handleErrorName}
								placeholder={formatterText('p.name.category', 'Nombre categoría de producto')}
								maxLength="100"
							/>
						</section>
						{errorName && (
							<div
								style={{
									fontSize: '0.7rem',
									color: 'red',
									marginTop: '-25px',
									marginRight: '760px',
									marginLeft: '150px'
								}}
							>
								{errorName}
							</div>
						)}
						<section className="form-responsive-information__option">
							<h3 className="p-styles">
								<FormattedMessage id="table.title.description" defaultMessage="Descripción" />
							</h3>
							<textarea
								className="input-primary-textarea"
								name="descripcion"
								value={formData.descripcion}
								onChange={handlerTextDescription}
								placeholder={formatterText('table.title.description', 'Descripción')}
								maxLength="200"
							/>
						</section>

						<section className="form-responsive-information__option">
							<h3 className="p-styles">
								<FormattedMessage id="p.code" defaultMessage="Código" />
							</h3>
							<input
								className="input-primary"
								type="text"
								name="codigo"
								value={formData.codigo}
								onChange={handleCode}
								placeholder={formatterText(
									'input.placeholder.enter.up.characters',
									'Ingresa hasta 45 caracteres'
								)}
								maxLength="45"
								onBlur={handleErrorCode}
							/>
						</section>
						{codeError && (
							<div
								style={{
									fontSize: '0.7rem',
									color: 'red',
									marginTop: '-25px',
									marginRight: '760px',
									marginLeft: '150px'
								}}
							>
								{codeError}
							</div>
						)}
						<section className="form-responsive-information__option">
							<h3 className="p-styles">{formatterText('table.title.state', 'Estado')}</h3>
							<label className="form-responsive-label">
								<p className="form-responsive-toggle">
									{active
										? formatterText('btn.active', 'Activo')
										: formatterText('p.unActive', 'No activo')}
								</p>
								<label className="switch">
									<input
										checked={!!active}
										onChange={() => {
											setActive(!active);
										}}
										type="checkbox"
									/>
									<span className="slider round"></span>
								</label>
							</label>
						</section>
					</section>
					{currentDataTable.length > 0 && (
						<>
							<p className="wrap-form-title">
								{formatterText('p.subcategory.product', 'Subcategorías del producto')}
							</p>
							<section className="form-responsive-container-information">
								<Table
									titles={titlesTableSubCategories}
									data={currentDataTable}
									type="subCategorias"
									handleOpen={handleOpen}
									labelTable={formatterText('p.subcategory.product', 'Subcategorías del producto')}
									canSearch={true}
									update={getSubCategoriesProductById}
								/>
							</section>
						</>
					)}
					<p className="wrap-form-title">
						<FormattedMessage
							id="p.add.subcatgory.product"
							defaultMessage="+ Agregar subcategoría producto"
						/>
					</p>
					<section
						className="grid-container-2c sm-gap"
						style={{
							width: '95%',
							margin: '0 auto'
						}}
					>
						<label className="d-flex">
							<h3 className="text-inline">
								<FormattedMessage id="p.subcategory.name" defaultMessage="Nombre subcategoría" />
							</h3>
							<input
								className="input-primary"
								type="text"
								name="nombre"
								value={currentProductCategory.nombre}
								onChange={handleNameTextSubCategory}
								placeholder={formatterText('p.subcategory.name', 'Nombre subcategoría')}
								maxLength="100"
							/>
						</label>
						<label className="d-flex">
							<h3 className="text-inline">
								<FormattedMessage id="p.code" defaultMessage="Código" />
							</h3>
							<input
								className="input-primary"
								type="text"
								name="codigo"
								value={currentProductCategory.codigo}
								onChange={handleChangeSubCategoryCode}
								placeholder={formatterText(
									'input.placeholder.enter.up.characters',
									'Ingresa hasta 45 caracteres'
								)}
								maxLength="45"
							/>
						</label>
						<label className="d-flex">
							<h3 className="text-inline">
								<FormattedMessage id="table.title.description" defaultMessage="Descripción" />
							</h3>
							<textarea
								className="input-primary-textarea width-80"
								type="text"
								name="descripcion"
								value={currentProductCategory.descripcion}
								onChange={handlerTextDescriptionSubCategory}
								placeholder={formatterText('p.subcategory.desc', 'Descripción de la subcategoría')}
								maxLength="200"
							/>
						</label>
					</section>
					<section
						className="form-responsive-container-buttons"
						style={{
							height: '5.5rem'
						}}
					>
						<input
							onClick={handleAddproductCategory}
							type="button"
							className="btn-primary margin-auto"
							value={formatterText('p.add.subcategory', 'Agregar subcategoría')}
						/>
					</section>
				</section>
				<section className="form-responsive-container-buttons">
					<button type="submit" className="btn-primary">
						<FormattedMessage
							id="header.title.product.category.update"
							defaultMessage="Editar categoría de producto"
						/>
					</button>
					<button className="input-cancel" onClick={() => navigate(paths.products)}>
						<FormattedMessage id="btn.cancel" defaultMessage="Cancelar" />
					</button>
				</section>
			</form>
			<Modal
				open={open}
				onClose={handleClose}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<UpdateSubCategory onClose={handleClose} getFun={getSubCategoriesProductById} />
			</Modal>
		</>
	);
}
