import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
// Import Context
import { SearchWrapper, useSeachContext } from 'context/SearchContext';
// Import Hooks
import useGetData from 'hooks/useGetData';
import useLangv2, { formatterText } from 'hooks/useLangv2';
import { useParams } from 'react-router';
// Import Components
import DynamicTableServices from '../../DynamicTableServices/DynamicHeadServices';
import CreateProductAssociate from '../actions/CreateProductAssociate';
// Import Libs
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
// Import Services
import endpoints from 'services/api';
// Import Styles
import { MODULES_NAME } from 'common/validators/PermissionsConstants';
import { PermissionWrapper, usePermissionContext } from 'context/PermissionContext';
import Modal from 'react-responsive-modal';
import styles from 'screens/services/ExtraTabs/Tab.module.css';
import ModalSendMail from 'screens/services/Modals/ModalSenMail';

const ProductServices = () => {
	return (
		<SearchWrapper>
			<PermissionWrapper>
				<ProductServicesComponent />
			</PermissionWrapper>
		</SearchWrapper>
	);
};

function ProductServicesComponent() {
	const { RequestUseCase } = useAxios();

	const { showDownloadFile, handleRequestError } = useLangv2();

	const titlesPS = [
		formatterText('title.service.create.product.name'),
		formatterText('title.service.create.product.category'),
		formatterText('title.service.create.product.subcategory'),
		formatterText('title.service.create.product.requestedQuantity'),
		formatterText('title.service.create.product.unitOfMeasure'),
		formatterText('title.service.create.product.approvedQuantity'),
		formatterText('title.service.create.product.approvedCost'),
		formatterText('title.service.create.product.approvedProvider'),
		formatterText('title.service.create.product.comments'),
		formatterText('title.service.create.product.delivery'),
		formatterText('title.service.create.product.productStatus'),
		formatterText('title.service.create.product.actions')
	];
	// get id url
	const { id } = useParams();
	// useContext of data table
	const { setDataTable } = useSeachContext();
	// state of show create product associate
	const [showCreateProductAssociate, setShowCreateProductAssociate] = useState(false);
	// hook useLocation
	// permissions access

	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();

	const permissionsAccessGaranted = () => {
		permissionsAccess(MODULES_NAME.service.products);
	};
	// state total
	const [total, setTotal] = useState(0);
	const [sendMail, setSentMail] = useState(false);

	useEffect(() => {
		getDataTable();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getDataTable();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		permissionsAccessGaranted();
	}, [permissions]);

	const {
		// loading,
		error,
		toggleLoading,
		toggleError,
		handleClick
	} = useGetData();
	const [openModalMail, setOpenModalMail] = useState(false);
	const onToggleModalMail = () => setOpenModalMail(!openModalMail);
	// función para cargar datos
	const getDataTable = () => {
		// show loading
		toggleLoading(true);

		RequestUseCase.get(endpoints.serviceProduct.getAllServiceProductByIdService(id))
			.then((data) => {
				const newArray = [];
				data.forEach((item) => {
					handleStructureItems(newArray, item);
				});
				setDataTable(newArray);
				getTotalPrices();
				// show loading
				toggleLoading(false);
			})
			.catch(() => {
				// mostrar error
				toggleError(!error);
				handleClick();
			});
	};

	const getTotalPrices = () => {
		RequestUseCase.get(endpoints.serviceProduct.getTotalPricesProductByIdService(id)).then(
			(data) => {
				setTotal(data);
			}
		);
	};

	const formatCurrency = (value) => {
		if (value) {
			const parts = value.toString().split('.');
			let formattedValue = `$${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
			if (parts.length > 1) {
				formattedValue += `,${parts[1]}`;
			}
			return formattedValue;
		} else {
			return value;
		}
	};
	//  delete item
	const handleDeleteItem = (rowId) => {
		const deleteItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.serviceProduct.deleteServiceProduct(rowId))
				.then(() => {
					getDataTable();
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return deleteItemPromise;
	};

	// structure data
	const handleStructureItems = (newArray, item) => {
		newArray.push({
			id: item.idServicioProducto,
			nombre: item.nombreProducto,
			catProducto: item.nombreCategoriaProducto,
			subCategoria: item.nombreSubcategoriaProducto,
			cantProducto: item.cantidadSolicitada,
			unidadM: item.nombreUnidadmedida,
			cantAprob: item.cantidadAprobada,
			costoAprob: formatCurrency(item.costo),
			proveeAProb:
				item.idProveedor !== null
					? `${item.nombreRazonSocialProveedor} - ${item.direccionProveedor}`
					: 'N/A',
			observaciones: item.observacion,
			deliveryType: item.idTipoEntrega,
			productState: item.gestionado,
			objeto: item
		});
		if (item.gestionado === 1) {
			setSentMail(true);
		}
	};

	const handleEditStateItem = (body) => {
		const updateItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.serviceProduct.updateServiceProduct, {
				...body,
				iva: 19,
				idMoneda: {
					idMoneda: 1
				}
			})
				.then((res) => {
					resolve(res);
					getDataTable();
				})
				.catch((err) => {
					reject(err);
				});
		});
		return updateItemPromise;
	};

	const handleDownload = (type) => {
		// show the message to download the file
		showDownloadFile();
		// hash to get the URI
		const hashType = {
			pdf: endpoints.serviceProduct.downloadProductPDF(id),
			excel: endpoints.serviceProduct.downloadProductXLSX(id)
		};

		// generate the URI to download the file
		const URI = hashType[type];

		RequestUseCase.get(URI)
			.then((res) => {
				const extensionArchivo = type === 'pdf' ? 'pdf' : 'xlsx';
				const MimeType =
					type === 'pdf' ? 'pdf' : 'vnd.openxmlformats-officedocument.spreadsheetml.sheet';
				res.response.forEach((item, index) => {
					const decoded = decodeBase64(item);
					functionToDownload(decoded, `${index + 1}product.${extensionArchivo}`, MimeType);
				});
			})
			.catch((err) => {
				handleRequestError(err);
			});
	};

	// function to decode the base64
	const decodeBase64 = (base64) => {
		const byteCharacters = atob(base64);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		return byteArray;
	};

	// function to download the file
	const functionToDownload = (decoded, fileName, ext) => {
		const blob = new Blob([decoded], { type: `aplication/${ext}` });
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = fileName;
		link.click();
	};

	return (
		<>
			<div>
				<div className={styles['container-ps']}>
					<label className="display-flex">
						{showCreateProductAssociate ? (
							<MdKeyboardArrowDown size={25} color="var(--color-primary)" cursor="pointer" />
						) : (
							<MdKeyboardArrowRight size={25} color="var(--color-primary)" cursor="pointer" />
						)}
						<p
							className="p-styles primary-green"
							style={{ cursor: 'pointer' }}
							onClick={(e) => {
								e.preventDefault();
								setShowCreateProductAssociate(!showCreateProductAssociate);
							}}
						>
							{formatterText('title.service.create.addProducts')}
						</p>
					</label>
					<label>
						<span className="p-styles spacing-l1 dark-gray">
							{formatterText('title.service.create.total')}
							{formatCurrency(total)}
						</span>
						<button
							className="btn-add"
							onClick={(e) => {
								e.preventDefault();
								handleDownload('excel');
							}}
						>
							{formatterText('title.service.create.downloadExcel')}
						</button>
						<button
							className="btn-add"
							onClick={(e) => {
								e.preventDefault();
								handleDownload('pdf');
							}}
						>
							{formatterText('title.service.create.downloadPdf')}
						</button>
					</label>
				</div>
				{showCreateProductAssociate && <CreateProductAssociate getDataTable={getDataTable} />}
				<div className="tdR thR table-container">
					<DynamicTableServices
						titles={titlesPS}
						pageName={formatterText('title.service.create.productsAndServices')}
						getData={getDataTable}
						handleDeleteItem={handleDeleteItem}
						handleEditStateItem={handleEditStateItem}
						noHandleEditStateItem={false}
						canViewDetails={permittedActions.consultar}
						canChangeData={true}
						canDeleted={permittedActions.eliminar}
						sendMail={sendMail}
						setSendMail={setSentMail}
					/>
				</div>
			</div>
			{/* mail modal */}
			<Modal
				Modal
				open={openModalMail}
				onClose={onToggleModalMail}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<ModalSendMail onClose={onToggleModalMail} idService={id} />
			</Modal>
		</>
	);
}

export default ProductServices;
