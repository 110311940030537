import useAxios from 'hooks/useAxios';
import { useEffect } from 'react';
// Import Context
import { useSeachContext } from 'context/SearchContext';
// Import Hooks
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
// Import Components
// Import libs
import SortData from 'components/utils/SortData';
// Import services
import { MODULES_NAME } from 'common/validators/PermissionsConstants';
import { usePermissionContext } from 'context/PermissionContext';
import endpoints from 'services/api';

export default function PositionViewModel() {
	const { RequestUseCase } = useAxios();

	const { formatterText } = useLangv2();
	// titulos de la tabla
	const titles = [
		formatterText('table.title.position.name', 'Nombre cargo'),
		formatterText('table.title.description', 'Descripción'),
		formatterText('table.title.code', 'Código'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];

	// hook to loading data
	const {
		loading,
		error,
		toggleLoading,
		toggleError,
		handleClick,
		displayMessage,
		displayLoading
	} = useGetData();
	// useContext de búsqueda
	const { setDataTable } = useSeachContext();
	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();

	const permissionsAccessPosition = () => {
		permissionsAccess(MODULES_NAME.positions);
	};

	// función para cargar datos
	const getDataTable = async () => {
		// show loading
		toggleLoading(true);
		return RequestUseCase.get(endpoints.cargos.getAllCargos)
			.then(async (data) => {
				const newArray = [];
				// En dado de poner fechaModificacion formatear la fecha
				data.forEach((item) => handleStructureItems(newArray, item));
				const sortedArray = SortData(newArray, 'asc');
				setDataTable(sortedArray);
				// show loading
				toggleLoading(false);
			})
			.catch(() => {
				// mostrar error
				toggleError(!error);
				handleClick();
			});
	};

	const handleDeleteItem = (rowId) => {
		return new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.cargos.deleteCargo(rowId))
				.then((res) => {
					const newArray = [];
					res.forEach((item) => handleStructureItems(newArray, item));
					const sortedArray = SortData(newArray, 'asc');
					setDataTable(sortedArray);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const handleEditStateItem = (body) => {
		return new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.cargos.updateCargo, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const handleStructureItems = (newArray, item) => {
		newArray.push({
			id: item.idCargo,
			nombre: item.nombre,
			descripcion: item.descripcion,
			codigo: item.codigo,
			estado: item.estado,
			objeto: { ...item }
		});
	};

	useEffect(() => {
		permissionsAccessPosition();
	}, [permissions]);

	useEffect(() => {
		getDataTable();
	}, []);

	return {
		displayLoading,
		displayMessage,
		error,
		formatterText,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		loading,
		permittedActions,
		titles
	};
}
