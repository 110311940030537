export class CurrentTechModel {
	constructor(technician, timeZone) {
		this.idAgenda = technician.idAgenda;
		this.nombreAgenda = technician.nombreAgenda;
		this.idTecnico = technician.idTecnico;
		this.idUsuario = technician.idUsuario;
		this.nombreTecnico = technician.nombreTecnico;
		this.identificacion = technician.identificacionTecnico;
		this.fechaInicio = technician.fechaInicio;
		this.fechaFin = technician.fechaFin;
		this.idDiaSemana = timeZone.idDiaSemana;
		this.idHoraInicio = timeZone.idHoraInicio;
		this.idHoraFin = timeZone.idHoraFin;
		this.idTipoAgenda = timeZone.idTipoAgenda;
		this.nombreFranjaHoraria = timeZone.nombre;
	}
}
