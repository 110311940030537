import { useFormik } from 'formik';
import { useState } from 'react';
import {
	getFormikInitialValues,
	getFormikValidationSchema,
	parseDataToFormik,
	parseDataToSubmit
} from '../../utils';

export const EditFileFormViewModel = ({ onSubmit }) => {
	const [formikInitialValues, setFormikInitialValues] = useState(getFormikInitialValues());

	const formik = useFormik({
		initialValues: formikInitialValues,
		validationSchema: getFormikValidationSchema(),
		enableReinitialize: false,
		onSubmit
	});

	return {
		formik,
		formikInitialValues,
		parseDataToFormik,
		parseDataToSubmit,
		setFormikInitialValues
	};
};
