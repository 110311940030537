import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import paths from 'services/paths';
import { sendEmailRecoveryPassword } from 'services/api/auth';
import CustomAlert from 'components/CustomAlert';
import useLangv2 from 'hooks/useLangv2';

export default function RecoverPasswordViewModel() {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [username, setUsername] = useState('');
	const { formatterText } = useLangv2();

	const sendRecoverPassword = (e) => {
		e.preventDefault();
		setIsLoading(true);
		sendEmailRecoveryPassword(username)
			.then((res) => {
				if (res) {
					CustomAlert('confirm_msg', {
						icon: 'success',
						title: 'Usuario verificado',
						text: 'Se ha enviado un correo electrónico al correo asociado al nombre de usuario, sigue los pasos e inicia sesión.',
						confirmButtonText: 'Iniciar sesión',
						allowOutsideClick: false,
						executeFunction: () => navigate(paths.login)
					});
				}
			})
			.catch(() => {
				CustomAlert('short_msg', {
					icon: 'error',
					title: 'Error',
					text: `No existe un usuario con el nombre de usuario ${username}, no se encuentra activo o no han trascurrido 15 minutos desde la última vez que se ha solicitado un cambio de contraseña.`
				});
			});
		setIsLoading(false);
	};

	return { isLoading, setUsername, sendRecoverPassword, formatterText, navigate };
}
