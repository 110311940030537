import useLangv2 from 'hooks/useLangv2';
import React from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';

const BtnActions = ({ item, loading, setDialog, handleEdit, handleDelete }) => {
	const { formatterText } = useLangv2();
	return (
		<td className="minimal-table__actions" align="center">
			{handleEdit && (
				<MdEdit
					size={25}
					color="gray"
					cursor="pointer"
					disabled={loading}
					onClick={() => handleEdit(item)}
				/>
			)}
			<MdDelete
				size={25}
				color="gray"
				cursor="pointer"
				onClick={() =>
					setDialog({
						text: `${formatterText('title.service.confirm.delete.row.category')}`,
						active: true,
						action: 'delete',
						funcion: () => handleDelete(item)
					})
				}
				disabled={loading}
			/>
		</td>
	);
};

export default BtnActions;
