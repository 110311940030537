import ProductsTableComponent from './ProductsTable.component';
import { SearchWrapper } from 'context/SearchContext';

const ProductsTable = () => {
	return (
		<SearchWrapper>
			<ProductsTableComponent />
		</SearchWrapper>
	);
};

export default ProductsTable;
