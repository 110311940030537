import useAxios from 'hooks/useAxios';
import HandleOnError from 'common/validators/HandleOnError';
import { TEXTREGEXWITHPOINTS } from 'common/validators/Regex';
import CustomAlert from 'components/CustomAlert';
import useLangv2 from 'hooks/useLangv2';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import endpoints from 'services/api';
import paths from 'services/paths';
import Swal from 'sweetalert2';
import { editUserViewModels } from '../../editUser.viewModels';

export const formViewModel = () => {
	const { RequestUseCase } = useAxios();
	const { formatterText, customSB } = useLangv2();
	const navigate = useNavigate();
	const [toggleSelector, setToggleSelector] = useState(false);
	const [selectValue, setSelectValue] = useState('');

	const {
		loading,
		selectorData,
		roles,
		setRoles,
		setSearch,
		setRolSearch,
		listUsers,
		originalUsername,
		auxPlaceholder,
		entityPlaceholder,
		selectedRoles,
		setSelectedRoles,
		originalRoles,
		acces,
		setAcces,
		entity,
		setEntity,
		formData,
		setFormData,
		getRoles
	} = editUserViewModels();

	const asociateRole = (id) => {
		const roles = selectedRoles.map((rol) => {
			return { idRol: rol.idRol };
		});

		const data = {
			idUsuario: id,
			roles: roles
		};

		RequestUseCase.post(endpoints.users.insertRolesByUser, JSON.stringify(data))
			.then((response) => {
				if (response) {
					CustomAlert('confirm_msg', {
						icon: 'success',
						title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
						text: formatterText('alert.title.user.updated.successfully'),
						confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
						allowOutsideClick: false,
						executeFunction: () => navigate(paths.users)
					});
				} else {
					HandleOnError(formatterText('alert.title.role.not.associated'));
				}
			})
			.catch((err) => {
				console.error(err);
				HandleOnError(formatterText('alert.title.role.not.associated'));
			});
	};

	const removeRole = (id, removedRoles) => {
		const roles = removedRoles.map((rol) => {
			return { idRol: rol.idRol };
		});

		const data = {
			idUsuario: id,
			roles: roles
		};

		RequestUseCase.post(endpoints.users.deleteRolesByUser, JSON.stringify(data))
			.then((response) => {
				if (response) {
					asociateRole(id);
				} else {
					HandleOnError(formatterText('alert.title.roles.not.removed'));
				}
			})
			.catch((err) => {
				console.error(err);
				HandleOnError(formatterText('alert.title.roles.not.removed'));
			});
	};

	const checkUsername = async (username) => {
		const lowerCaseUsername = username.toLowerCase();
		if (lowerCaseUsername === originalUsername.toLowerCase()) {
			return false;
		} else {
			for (const user of listUsers) {
				if (user.usuario.toLowerCase() === lowerCaseUsername) {
					return true;
				}
			}
			return false;
		}
	};

	const sortData = (selected) => {
		// sort the selected roles by name ASC
		selected.sort((a, b) => {
			if (a.nombre < b.nombre) {
				return -1;
			}
			if (a.nombre > b.nombre) {
				return 1;
			}
			return 0;
		});
	};

	const handleRemoveRole = (e, rol) => {
		e.preventDefault();
		const selected = selectedRoles.filter((currentRol) => currentRol.nombre !== rol.nombre);
		console.log(roles);
		sortData(selected);
		setSelectedRoles(selected);
		setRoles([...roles, rol]);
		setRolSearch([...roles, rol]);
		getRoles();
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		checkUsername(formData.usuario)
			.then((usernameExists) => {
				if (usernameExists) {
					HandleOnError(formatterText('alert.title.username.in.use'));
				} else {
					let tipoAcceso;

					if (acces.mobile && acces.web) {
						tipoAcceso = 3;
					} else if (acces.mobile && acces.web === false) {
						tipoAcceso = 2;
					} else if (acces.web && acces.mobile === false) {
						tipoAcceso = 1;
					} else {
						tipoAcceso = null;
					}

					let data = {};

					const conditionalSwitch = entity.entidad;

					switch (conditionalSwitch) {
						case 'tercero':
							data = {
								...formData,
								tipoAcceso: tipoAcceso,

								idTercero: {
									idTercero: entity.entidadId
								}
							};
							break;
						case 'empleado':
							data = {
								...formData,
								tipoAcceso: tipoAcceso,
								idEmpleado: {
									idEmpleado: entity.entidadId
								}
							};
							break;
						case 'cliente':
							data = {
								...formData,
								tipoAcceso: tipoAcceso,
								idAsesorCliente: {
									idAsesor: entity.entidadId
								}
							};
							break;
						default:
							console.log(formatterText('alert.title.no.entity.selected'));
							break;
					}

					if (tipoAcceso === null) {
						CustomAlert('short_msg', {
							icon: 'warning',
							title: 'Error',
							text: formatterText('alert.title.select.access.type'),
							confirmButtonColor: '#FFC954'
						});
					} else if (selectedRoles.length === 0) {
						CustomAlert('short_msg', {
							icon: 'warning',
							title: 'Error',
							text: formatterText('alert.title.select.role')
						});
					} else {
						postUser(data);
					}
				}
			})
			.catch(() => {
				HandleOnError(formatterText('alert.title.username.verification.error'));
			});
	};

	const postUser = (data) => {
		Swal.fire({
			title: `${formatterText('question.swal.title.edit')}`,
			text:
				selectedRoles.length === 0
					? `${formatterText('question.swal.title.edit.user.without.roles')}`
					: `${formatterText('question.swal.title.edit.user')}`,
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: `${formatterText('question.swal.title.edit.confirm')}`,
			allowOutsideClick: false,
			cancelButtonText: `${formatterText('question.swal.title.edit.cancel')}`,
			preConfirm: async () => {
				await RequestUseCase.put(endpoints.users.updateUser, JSON.stringify(data))
					.then((response) => {
						if (response) {
							const removedRoles = originalRoles.filter(
								(rol) => !selectedRoles.some((current) => rol.idRol === current.idRol)
							);
							if (removedRoles.length > 0) {
								removeRole(data.idUsuario, removedRoles);
							} else {
								asociateRole(data.idUsuario);
							}
						} else {
							HandleOnError(formatterText('alert.title.username.or.email.in.use'));
						}
					})
					.catch((err) => {
						console.error(err);
						HandleOnError(formatterText('alert.title.username.or.email.in.use'));
					});
			}
		});
	};

	const handleAddRole = (rol) => {
		const rolNombre = rol.nombre;
		const newRoles = [];
		for (let i = 0; i < selectedRoles.length; i++) {
			if (selectedRoles[i].nombre === rolNombre) {
				newRoles.push(selectedRoles[i]);
			}
		}
		if (newRoles.length === 0) {
			setRolSearch(newRoles);
			setRoles(newRoles);
			const selected = [...selectedRoles, rol];
			sortData(selected);
			setSelectedRoles(selected);
			setToggleSelector(false);
		} else {
			customSB('error', 'snackbar.rol.already.selected', 'EL codigo ya fue seleccionado');
		}
		getRoles();
	};

	const handleChange = (e) => {
		if (e.target.value.match(TEXTREGEXWITHPOINTS)) {
			setFormData({
				...formData,
				[e.target.name]: e.target.value.replaceAll(/\s{2,}/gi, ' ')
			});
		}
	};

	const handleChangeMail = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	return {
		loading,
		selectorData,
		roles,
		setRoles,
		setSearch,
		setRolSearch,
		listUsers,
		originalUsername,
		auxPlaceholder,
		entityPlaceholder,
		selectedRoles,
		setSelectedRoles,
		originalRoles,
		acces,
		setAcces,
		entity,
		setEntity,
		formData,
		setFormData,
		getRoles,
		formatterText,
		customSB,
		navigate,
		toggleSelector,
		setToggleSelector,
		selectValue,
		setSelectValue,
		handleRemoveRole,
		handleSubmit,
		handleAddRole,
		handleChange,
		handleChangeMail
	};
};
