import useAxios from 'hooks/useAxios';
import HandleInput from 'common/validators/HandleInput';
import HandleOnError from 'common/validators/HandleOnError';
import { TEXTREGEX } from 'common/validators/Regex';
import CustomAlert from 'components/CustomAlert';
import { AppContext } from 'context/AppContext';
import { useSeachContext } from 'context/SearchContext';
import dateFormat from 'dateformat';
import useLangv2 from 'hooks/useLangv2';
import useProgress from 'hooks/useProgress';
import Agenda from 'models/Agenda';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import endpoints from 'services/api';
import paths from 'services/paths';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';

export default function UpdateScheduleViewModel() {
	const { RequestUseCase, COOKIE_USER } = useAxios();

	const {
		timeZoneSelected,
		setTimeZoneSelected,
		setSearchResults,
		searchResults = [],
		setDataTable
	} = useSeachContext();
	const {
		openModalTechnicalToAssign,
		openModalTechnicalToReview,
		setOpenModalTechnicalToAssign,
		setOpenModalTechnicalToReview,
		setTechnicalsToAssing,
		setAgendaCloneData,
		techSelectToAssign
	} = useContext(AppContext);
	// get the id from the url
	const { id } = useParams();
	// Get data from the API and fill the data
	useEffect(() => {
		getDataAgenda();
		getTimeZoneByIdAgenda();
	}, []);

	useEffect(() => {
		getDataAgenda();
		getTimeZoneByIdAgenda();
	}, [id]);

	const [duplicateTimeZone, setDuplicateTimeZone] = useState([]);
	// Helps to loading data table
	const { loadingProgress, setLoadingProgress, DisplayProgress } = useProgress();
	// get all data agenda
	const getDataAgenda = () => {
		// show loading
		setLoadingProgress(false);
		RequestUseCase.get(endpoints.agenda.getAgendaDataByid(id))
			.then((res) => {
				const fechaFinal = new Date(res.fechaFin);
				fechaFinal.setHours(fechaFinal.getHours() + 5);
				setFormData({
					idAgenda: res.idAgenda,
					idUsuario: res.idCoordinadorCreador.idUsuario,
					nombre: res.nombre,
					fechaInicio: res.fechaInicio,
					fechaFin: fechaFinal.toISOString(),
					concepto: res.concepto,
					estado: res.estado,
					fechaCreacion: res.fechaCreacion,
					fechaModificacion: res.fechaModificacion,
					usuarioCreacion: res.usuarioCreacion,
					usuarioModificacion: res.usuarioModificacion
				});
				// show loading
				setLoadingProgress(true);
			})
			.catch(() => {
				errorDataRecovery();
			});
	};

	// get all timezone by id agenda
	const getTimeZoneByIdAgenda = () => {
		RequestUseCase.get(endpoints.agenda.getAllTimezoneByidAgenda(id))
			.then((response) => {
				const data = [];
				response.forEach((element) => {
					const timeZone = `${element.idFranjaHoraria.nombre} ${element.idFranjaHoraria.idDiaSemana.dia} - ${element.idFranjaHoraria.idHoraInicio.hora} - ${element.idFranjaHoraria.idHoraFin.hora}`;
					data.push({
						idFranjaHorariaAgenda: element.idFranjaHorariaAgenda,
						idFranjaHoraria: element.idFranjaHoraria.idFranjaHoraria,
						timeZone
					});
				});
				// create a new array with the timezones but set idFranjaHorariaAgenda to null
				const timeZone = data.map((item) => {
					return {
						idFranjaHorariaAgenda: uuidv4(),
						idFranjaHoraria: item.idFranjaHoraria,
						timeZone: item.timeZone
					};
				});
				// Save the time zones data in localStorage
				localStorage.setItem('timeZones', JSON.stringify(timeZone));
				setDataTable(data);
				setTimeZoneSelected(data);
				setSearchResults(data);
				setDuplicateTimeZone(timeZone);
			})
			.catch(() => {
				errorDataRecovery();
			});
	};

	// use Hook of language v2
	const {
		formatterText,
		noFilledContent,
		errorDataRecovery,
		newItemCreated,
		errorProcess,
		customSB
	} = useLangv2();

	const titlesTableTimeZone = [
		formatterText('text.shedule.time.zone', 'Franjas horarias'),
		formatterText('table.shedule.view.technical', 'Técnicos asociados'),
		formatterText('table.actions', 'Acciones')
	];

	// Modal config
	const onCloseTechnicalToAssign = () => setOpenModalTechnicalToAssign(false);
	const onCloseTechnicalToReview = () => setOpenModalTechnicalToReview(false);
	// useNavigate
	const navigate = useNavigate();
	// useState for the form
	const [formData, setFormData] = useState(new Agenda());

	// Update a string to set into the form
	const handleText = (e) => {
		HandleInput(e, TEXTREGEX, formData, setFormData);
	};

	// Create an agenda
	const handleSubmit = (e) => {
		e.preventDefault();

		const conditionalCreateAgenda =
			formData.nombre === '' || formData.fechaInicio === '' || formData.fechaFin === '';

		if (conditionalCreateAgenda) {
			noFilledContent();
		} else {
			const DATA = {
				idAgenda: formData.idAgenda,
				nombre: formData.nombre,
				fechaInicio: dateFormat(formData.fechaInicio, 'isoDateTime'),
				fechaFin: dateFormat(formData.fechaFin, 'isoDateTime'),
				idCoordinadorCreador: {
					idUsuario: formData.idUsuario
				},
				concepto: formData.concepto,
				estado: formData.estado,
				fechaCreacion: formData.fechaCreacion,
				usuarioCreacion: formData.usuarioCreacion,
				usuarioModificacion: COOKIE_USER
			};
			// is possible to searchResults have new time zones so we need to validate
			if (searchResults.length > 0) {
				createItem(DATA);
			} else {
				customSB(
					'warning',
					'snackbar.error.time.zone.required',
					'Es necesario asociar al menos una franja horaria a la agenda.'
				);
			}
		}
	};

	// Associate a time zone to an agenda
	const associateAgendaToTimeZone = async (idAgenda) => {
		await searchResults.forEach(async (franja) => {
			const TYPE_ID = typeof franja.idFranjaHorariaAgenda === 'string';
			if (TYPE_ID) {
				const DATA = {
					idFranjaHorariaAgenda: null,
					idAgenda: {
						idAgenda: parseInt(idAgenda)
					},
					idFranjaHoraria: {
						idFranjaHoraria: franja.idFranjaHoraria
					},
					estado: 1,
					// "fechaCreacion": DATE_CREATED_AT,
					usuarioCreacion: COOKIE_USER
				};
				await RequestUseCase.post(endpoints.agenda.addAssociationTimezoneSchedule, DATA)
					.then(() => {
						newItemCreated();
					})
					.catch(() => {
						errorProcess();
					});
			}
		});

		getDataAgenda();
	};

	// Update agenda
	const createItem = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.update.general', 'Se va a editar el registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					//  Here we call the service to create the item
					RequestUseCase.put(endpoints.agenda.updateAgenda, data)
						.then((res) => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.updated.general',
										'El registro se ha actualizado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => associateAgendaToTimeZone(res.idAgenda)
								})
							);
						})
						.catch((err) => {
							if (err.response?.data?.message) {
								HandleOnError(formatterText(err.response?.data?.message));
							} else {
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
						});
				});
			}
		});
	};

	const [dialog, setDialog] = useState({
		text: '',
		active: false,
		function: null
	});

	const closeDialog = () => {
		setDialog({ ...dialog, active: false });
	};

	const cloneShedule = () => {
		setAgendaCloneData({
			agendaData: formData,
			timeZones: duplicateTimeZone,
			technicals: techSelectToAssign
		});
		navigate(paths.duplicateSchedule);
	};
	return {
		loadingProgress,
		formData,
		setFormData,
		handleText,
		handleSubmit,
		cloneShedule,
		timeZoneSelected,
		titlesTableTimeZone,
		getTimeZoneByIdAgenda,
		dialog,
		closeDialog,
		openModalTechnicalToAssign,
		onCloseTechnicalToAssign,
		openModalTechnicalToReview,
		onCloseTechnicalToReview,
		setTechnicalsToAssing,
		formatterText,
		navigate,
		DisplayProgress
	};
}
