import { useForm } from '@formiz/core';
import { FilterActive } from 'common/validators/FilterActives';
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import paths from 'services/paths';
import Swal from 'sweetalert2';

export default function CreateProviderViewModel() {
	// use Hook of language v2
	const { formatterText } = useLangv2();
	const { fetchData, endpoints, RequestUseCase } = useAxios();

	const navigate = useNavigate();

	const myForm = useForm();
	const [isLoading] = useState(false);
	const [loadingData, setLoadingData] = useState(false);
	const [archivos, setCurrentFiles] = useState([]);

	const [selectData, setSelectData] = useState({});

	useEffect(() => {
		getData();
	}, []);

	const getData = () => {
		setLoadingData(true);

		const promesas = [
			RequestUseCase.get(endpoints.institutions.getAllTypeDocument),
			RequestUseCase.get(endpoints.providerType.getAllProviderTypes),
			RequestUseCase.get(endpoints.zones.getAllMunicipalities)
		];

		Promise.all(promesas).then((values) => {
			setLoadingData(false);
			setSelectData({
				...selectData,
				documentTypes: values[0],
				genres: FilterActive(values[1]),
				municipalities: FilterActive(values[2])
			});
		});
	};

	const handleSubmit = (values) => {
		const data = {
			idProveedor: null,
			idTipoProveedor: {
				idTipoProveedor: values.idTipoProveedor
			},
			idTipoDocumento: {
				idTipoDocumento: values.idTipoDocumento
			},
			identificacion: values.identificacion,
			nombreRazonSocial: values.nombreRazonSocial,
			idMunicipio: {
				idMunicipio: values.idMunicipio
			},
			direccion: values.direccion,
			telefono: values.telefono,
			telefonoAlterno: values.telefonoAlterno ? values.telefonoAlterno : '',
			sitioWeb: values.sitioWeb,
			correo: values.correo
		};

		handleSaveProvider(data);
	};

	const handleSaveProvider = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve) => {
					RequestUseCase.post(endpoints.providers.addProvider, data)
						.then((response) => {
							resolve(uploadFiles(response.idProveedor));
						})
						.catch((err) => {
							if (err.response?.data?.message) {
								HandleOnError(formatterText(err.response?.data?.message));
							} else {
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
						});
				});
			}
		});
	};

	const uploadFiles = (id) => {
		return new Promise((resolve, reject) => {
			if (archivos.length > 0) {
				fetchData({
					url: endpoints.UploadFiles.save,
					method: 'post',
					body: {
						idOrigen: id,
						idTipoOrigenArchivo: 9, // 9 = Proveedores
						archivos
					}
				})
					.then(() => {
						resolve(
							CustomAlert('confirm_msg', {
								icon: 'success',
								title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
								text: formatterText(
									'alert.message.confirm.created.general',
									'El registro se ha creado correctamente'
								),
								confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
								allowOutsideClick: false,
								executeFunction: () => navigate(paths.providers)
							})
						);
					})
					.catch((err) => {
						reject(err);
					});
			} else {
				resolve(
					CustomAlert('confirm_msg', {
						icon: 'success',
						title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
						text: formatterText(
							'alert.message.confirm.created.general',
							'El registro se ha creado correctamente'
						),
						confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
						allowOutsideClick: false,
						executeFunction: () => navigate(paths.providers)
					})
				);
			}
		});
	};
	return {
		formatterText,
		myForm,
		handleSubmit,
		setCurrentFiles,
		archivos,
		isLoading,
		loadingData,
		selectData,
		navigate
	};
}
