import React from 'react';
// Import Context
import DynamicTable from 'common/DynamicTable/DynamicHead';
import MultiTableMinimal from 'common/minimalTables/MultiTableMinimal';
// Import Components
import { Formiz, FormizStep } from '@formiz/core';
import { isMaxLength } from '@formiz/validations';
import Search from 'common/Search';
import { InputFieldResponsive } from 'common/inputs/InputFieldResponsive';
import Table from 'common/minimalTables/Table';
import Selector from 'common/selects/Selector';
import SelectorGrid from 'common/selects/SelectorGrid';
import Select from 'react-select';
// Import Libs
import { FormattedMessage } from 'react-intl';
import paths from 'services/paths';
import customStyles2 from './generic.style.select';

import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';

import CreateActivityViewModel from './CreateActivity.viewModel';

export default function CreateActivityComponent() {
	const {
		formatterText,
		myForm,
		auxData,
		setAuxData,
		setCurrentItemMinimal,
		currentItemMinimal,
		selectedSearch,
		loading,
		loadingProgress,
		currentDataTable,
		setCurrentDataForm,
		currentDataForm,
		setActionToggle,
		actionToggle,
		active,
		setActive,
		show,
		handleAddItemMinimal,
		handleAddActivityFormClient,
		activitiesResults,
		handleDelete,
		dialog,
		setDialog,
		closeDialog,
		handleNumber,
		handlerTextDescription,
		handleDeleteItem,
		handleEditStateItem,
		getDataTable,
		navigate,
		error,
		displayMessage,
		handleSubmit,
		titlesTableActivites,
		titlesTableCostProduct,
		DisplayProgress,
		titlesTableTypeServiceActivity
	} = CreateActivityViewModel();

	return (
		<div className="centered-form">
			<Formiz onValidSubmit={handleSubmit} connect={myForm}>
				<form
					noValidate
					onSubmit={myForm.submit}
					className="container-wrapForm"
					style={{ minHeight: '16rem' }}
				>
					<div className="new-container-wrapForm__tabs">
						{myForm.steps.map((step) => (
							<button
								key={step.name}
								className={`new-tab-option ${
									step.name === myForm?.currentStep?.name ? 'is-active' : ''
								}`}
								type="button"
								onClick={() => myForm.goToStep(step.name)}
							>
								{formatterText(step.label)}
								{!step.isValid && step.isSubmitted && <small className="mr-2">⚠️</small>}
							</button>
						))}
					</div>

					<div className="container-wrapForm-content">
						<FormizStep name="step1" label="text.title.activity.information">
							<section
								className="grid-container-1c zero-gap"
								style={{
									width: '90%',
									margin: '0 auto'
								}}
							>
								<InputFieldResponsive
									type="text"
									name="nombre"
									labelText={formatterText('label.text.nameActivity', 'Nombre de la actividad')}
									placeholder={formatterText('label.text.activity', 'Actividad')}
									validateInput="namestage"
									required={formatterText(
										'p.nameActivity.is.required',
										'El nombre de la actividad es requerida'
									)}
									defaultValue={currentDataForm ? currentDataForm.nombre : ''}
									validations={[
										{
											rule: isMaxLength(45),
											message: formatterText(
												'input.placeholder.max.45.chars',
												'Ingrese hasta 45 caracteres'
											)
										}
									]}
								/>
								<InputFieldResponsive
									type="text"
									name="descripcion"
									labelText={formatterText('table.title.description', 'Descripción')}
									placeholder={formatterText(
										'input.placeholder.max.200.chars',
										'Ingrese hasta 200 caracteres'
									)}
									validateInput="text"
									defaultValue={currentDataForm ? currentDataForm.descripcion : ''}
									validations={[
										{
											rule: isMaxLength(200),
											message: formatterText(
												'input.placeholder.max.200.chars',
												'Ingrese hasta 200 caracteres'
											)
										}
									]}
									styleName="input-textarea"
									asArea={true}
								/>
								<InputFieldResponsive
									type="text"
									name="sku"
									labelText="Sku"
									placeholder="Sku"
									validateInput="text"
									required={formatterText('p.sku.is.required', 'El Sku es requerido')}
									defaultValue={currentDataForm ? currentDataForm.sku : ''}
								/>

								{actionToggle && (
									<label className="d-flex">
										<span className="text-inline">
											{formatterText('table.title.state', 'Estado')}
										</span>
										<section className="w100-container">
											<label className="container-toggle-label">
												<p className="form-responsive-toggle">
													{active
														? formatterText('p.active', 'Activo')
														: formatterText('p.unActive', 'No activo')}
												</p>
												<label className="switch">
													<input
														checked={!!active}
														onChange={() => {
															setActive(!active);
														}}
														type="checkbox"
													/>
													<span className="slider round"></span>
												</label>
											</label>
										</section>
									</label>
								)}
							</section>
							<div className="demo-form__footer">
								<section className="form-responsive-container-buttons">
									{!actionToggle ? (
										<button type="submit" style={{ padding: '0px' }} className="btn-primary">
											{formatterText('text.create.activity', 'Crear Actividad')}
										</button>
									) : (
										<button type="submit" style={{ padding: '0px' }} className="btn-primary">
											{formatterText('btn.save.changes', 'Guardar cambios')}
										</button>
									)}

									{actionToggle && (
										<button
											className="input-cancel"
											onClick={() => {
												setActionToggle(!actionToggle);
												setCurrentDataForm(null);
											}}
										>
											{formatterText('btn.cancel', 'Cancelar')}
										</button>
									)}

									<button className="input-cancel" onClick={() => navigate(paths.services)}>
										{formatterText('btn.exit', 'Salir')}
									</button>
								</section>
							</div>
							<section className="form-responsive-container-information">
								<div style={{ width: '90%', marginTop: '15px', marginBottom: '15px' }}>
									{!loading ? (
										<>
											<section className="userOptions">
												<Search
													placeholder={formatterText(
														'placeholder.search.multi.items',
														'Buscar por palabra'
													)}
													width="50%"
												/>
											</section>
											<FormattedMessage id="table.name.search.product" defaultMessage="Actividades">
												{() => (
													<DynamicTable
														titles={titlesTableActivites}
														pageName={PAGE_NAMES.Actividad}
														getData={getDataTable} //
														handleDeleteItem={handleDeleteItem}
														handleEditStateItem={handleEditStateItem}
														routeToEdit={paths.updateActivityService}
														canDeleted={true}
														canModify={true}
														functions={{
															setActive,
															setCurrentDataForm,
															myForm,
															currentDataForm,
															setActionToggle
														}}
													/>
												)}
											</FormattedMessage>
										</>
									) : error ? (
										displayMessage(
											'error',
											'Ha ocurrido un error, intentalo más tarde.',
											'toast.error.general'
										)
									) : (
										<DisplayProgress />
									)}
								</div>
							</section>
						</FormizStep>
						<FormizStep name="step2" label="text.title.activity.assign">
							<form>
								<p className="wrap-form-title">
									{formatterText('label.text.activity', 'Actividad')}
								</p>
								<section
									className="grid-container-1c"
									style={{
										width: '95%',
										margin: '0 auto'
									}}
								>
									<label className="d-flex">
										<span className="text-inline-large">
											{formatterText('table.title.category.service', 'Categoría de servicio')}
										</span>
										<section className="w100-container">
											<Selector
												name="idCategoriaServicio"
												data={selectedSearch.categoriasServicios}
												placeholder={
													<FormattedMessage
														id="input.placeholder.select"
														defaultMessage="Selecione una opción"
													/>
												}
												dataValue={auxData.idCategoriaServicio}
												setterFunction={setAuxData}
												isLoading={loading}
												isRequired={true}
											/>
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline-large">
											{formatterText('table.title.type.service', 'Tipo de servicio')}
										</span>
										<section className="w100-container">
											<Selector
												name="idTipoServicio"
												data={selectedSearch.tipoServicio}
												placeholder={
													<FormattedMessage
														id="input.placeholder.select"
														defaultMessage="Selecione una opción"
													/>
												}
												dataValue={auxData.idTipoServicio}
												setterFunction={setAuxData}
												isLoading={loading}
												isRequired={true}
											/>
										</section>
									</label>
								</section>
								{currentDataTable.length > 0 ? (
									<>
										<p className="wrap-form-title">
											{formatterText('p.activities.associated', 'Actividades asociadas')}
										</p>
										<section className="form-responsive-container-information">
											<Table
												data={currentDataTable}
												titles={titlesTableCostProduct}
												type="associationActivities"
												showTH={true}
											/>
										</section>
									</>
								) : (
									loadingProgress && <DisplayProgress />
								)}
								<p className="wrap-form-title">{formatterText('column.name.assign.activity')}</p>
								<section
									className="grid-container-2c"
									style={{
										width: '95%',
										margin: '0 auto'
									}}
								>
									{show && (
										<>
											<label className="d-flex">
												<span className="text-inline">
													{formatterText('column.name.related.activity.label')}
												</span>
												<section className="w100-container">
													<Select
														styles={customStyles2}
														onChange={(selectedOption) => {
															setAuxData({
																...auxData,
																idActividad: selectedOption
															});
															setCurrentItemMinimal({
																...currentItemMinimal,
																idActividad: selectedOption
															});
														}}
														value={auxData.idActividad}
														noOptionsMessage={() =>
															formatterText(
																'select.placeholder.no.options',
																'No se encontraron opciones'
															)
														}
														options={selectedSearch.actividadesServicios}
														placeholder={
															loading ? (
																<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
															) : (
																<FormattedMessage
																	id="input.placeholder.select"
																	defaultMessage="Selecione una opción"
																/>
															)
														}
													/>
												</section>
											</label>
											<label className="d-flex">
												<span className="text-inline">
													{formatterText('column.name.service.stage.label')}
												</span>
												<section className="w100-container">
													<Select
														styles={customStyles2}
														onChange={(selectedOption) => {
															setAuxData({
																...auxData,
																idEtapaServicio: selectedOption
															});
															setCurrentItemMinimal({
																...currentItemMinimal,
																idEtapaServicio: selectedOption
															});
														}}
														value={auxData.idEtapaServicio}
														noOptionsMessage={() =>
															formatterText(
																'select.placeholder.no.options',
																'No se encontraron opciones'
															)
														}
														options={selectedSearch.etapaServicios}
														placeholder={
															loading ? (
																<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
															) : (
																<FormattedMessage
																	id="input.placeholder.select"
																	defaultMessage="Selecione una opción"
																/>
															)
														}
													/>
												</section>
											</label>
											<label className="d-flex">
												<span className="text-inline">
													{formatterText('column.name.manage.by.technician.label')}
												</span>
												<section className="w100-container">
													<Select
														styles={customStyles2}
														onChange={(selectedOption) => {
															setAuxData({
																...auxData,
																actividadTecnica: selectedOption
															});
															setCurrentItemMinimal({
																...currentItemMinimal,
																actividadTecnica: selectedOption
															});
														}}
														value={auxData.actividadTecnica}
														noOptionsMessage={() =>
															formatterText(
																'select.placeholder.no.options',
																'No se encontraron opciones'
															)
														}
														options={[
															{
																label: 'No',
																value: 0
															},
															{
																label: 'Sí',
																value: 1
															}
														]}
														placeholder={
															loading ? (
																<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
															) : (
																<FormattedMessage
																	id="input.placeholder.select"
																	defaultMessage="Selecione una opción"
																/>
															)
														}
													/>
												</section>
											</label>
											<label className="d-flex">
												<span className="text-inline">
													{formatterText('column.name.activity.time.label')}
												</span>
												<section className="w100-container">
													<input
														className="input-default-3c"
														type="text"
														name="tiempoActividad"
														value={currentItemMinimal.tiempoActividad}
														onChange={handleNumber}
														placeholder={formatterText('column.name.activity.time.label')}
														maxLength="20"
													/>
												</section>
											</label>
											<label className="d-flex">
												<span className="text-inline">
													{formatterText('column.name.description.label')}
												</span>
												<section className="w100-container">
													<textarea
														className="input-textarea"
														name="descripcion"
														value={currentItemMinimal.descripcion}
														onChange={handlerTextDescription}
														placeholder={formatterText('table.title.description', 'Descripción')}
														maxLength="200"
													/>
												</section>
											</label>
										</>
									)}
								</section>
								<section
									className="form-responsive-container-buttons"
									style={{
										margin: '1rem 0rem'
									}}
								>
									<input
										onClick={handleAddItemMinimal}
										type="button"
										className="btn-action-primary"
										value={formatterText('column.name.add.activity')}
									/>
									<button
										className="btn-action-cancel"
										onClick={() => navigate(paths.services)}
										style={{
											margin: '0px'
										}}
									>
										{formatterText('column.name.cancel')}
									</button>
								</section>
							</form>
						</FormizStep>
						<FormizStep name="step3" label="column.name.related.forms">
							<form>
								{activitiesResults.length > 0 ? (
									<section className="form-responsive-container-information">
										<span className="title-table">
											{formatterText('column.name.related.forms')}
										</span>
										<MultiTableMinimal
											titles={titlesTableTypeServiceActivity}
											data={activitiesResults}
											type="typeServiceActivity"
											handleDelete={handleDelete}
											dialog={dialog}
											setDialog={setDialog}
											closeDialog={closeDialog}
											canSearch={true}
										/>
									</section>
								) : (
									loadingProgress && <DisplayProgress />
								)}

								<p className="wrap-form-title">{formatterText('column.name.assign.form')}</p>
								<section className="grid-container-1c">
									<section className="form-responsive-container-information">
										<section className="form-responsive-information__option">
											<h3>{formatterText('column.name.activity.service.type')}</h3>
											<SelectorGrid
												name="idTypeServiceActivity"
												data={selectedSearch.typeServicesActivity}
												placeholder={
													<FormattedMessage
														id="input.placeholder.select"
														defaultMessage="Selecione una opción"
													/>
												}
												shortWidth={true}
												dataValue={auxData}
												setterFunction={setAuxData}
												isLoading={loading}
												isRequired={false}
											/>
										</section>
									</section>

									<section className="d-flex d-ajc">
										<h3 className="text-inline">{formatterText('column.name.form')}</h3>
										<SelectorGrid
											name="idFormulario"
											data={selectedSearch.formsAssociate}
											placeholder={
												<FormattedMessage
													id="input.placeholder.select"
													defaultMessage="Selecione una opción"
												/>
											}
											shortWidth={true}
											dataValue={auxData}
											setterFunction={setAuxData}
											isLoading={loading}
											isRequired={false}
										/>
									</section>
									<section className="d-flex d-ajc">
										<h3 className="text-inline">
											{formatterText('column.name.client.associated')}
										</h3>
										<SelectorGrid
											name="idCliente"
											data={selectedSearch.clientsAssociate}
											placeholder={
												<FormattedMessage
													id="input.placeholder.select"
													defaultMessage="Selecione una opción"
												/>
											}
											shortWidth={true}
											dataValue={auxData}
											setterFunction={setAuxData}
											isLoading={loading}
											isRequired={false}
										/>
									</section>
								</section>
								<section
									className="form-responsive-container-buttons"
									style={{
										margin: '1rem 0rem'
									}}
								>
									<input
										onClick={handleAddActivityFormClient}
										type="button"
										className="btn-action-primary"
										value={formatterText('column.name.add.form')}
									/>
									<button
										className="btn-action-cancel"
										onClick={() => navigate(paths.services)}
										style={{
											margin: '0px'
										}}
									>
										{formatterText('column.name.cancel')}
									</button>
								</section>
							</form>
						</FormizStep>
					</div>
				</form>
			</Formiz>
		</div>
	);
}
