import Axios from 'axios';
import endpoints from './index';

const loginSession = async (user, password) => {
	const options = {
		headers: {
			accept: '*/*',
			'Content-Type': 'multipart/form-data'
		}
	};

	const response = await Axios.post(
		endpoints.auth.login,
		{
			user,
			password
		},
		options
	);
	return response.data;
};

/* Confirma step 1 */
const confirmEmail = async (body) => {
	const options = {
		headers: {
			accept: '*/*',
			'Content-Type': 'multipart/form-data'
		}
	};

	const response = await Axios.post(endpoints.auth.login, body, options);

	return response.data;
};

/* Confirma step 2 */
const confirmSession = async (body) => {
	const options = {
		headers: {
			accept: '*/*',
			'Content-Type': 'multipart/form-data'
		}
	};

	const response = await Axios.post(endpoints.auth.login, body, options);

	return response.data;
};

const sendEmailRecoveryPassword = async (username) => {
	const options = {
		headers: {
			accept: '*/*'
		}
	};

	const response = await Axios.get(endpoints.auth.sendRecoveryPassword(username), options);

	return response.data;
};

const restablishPasswordWithSecret = async (body) => {
	const config = {
		headers: {
			accept: '*/*',
			'Content-Type': 'application/json'
		}
	};

	const response = await Axios.put(endpoints.auth.validateEmailUserPassword, body, config);
	return response.data;
};

const validateEmail = async (body) => {
	const config = {
		headers: {
			accept: '*/*',
			'Content-Type': 'application/json'
		}
	};

	const response = await Axios.put(endpoints.auth.validateEmail, body, config);
	return response.data;
};

const updateLenguage = async (body) => {
	const config = {
		headers: {
			accept: '*/*',
			'Content-Type': 'application/json'
		}
	};

	const response = await Axios.put(endpoints.auth.updateLenguage, body, config);
	return response.data;
};

export {
	confirmEmail,
	confirmSession,
	loginSession,
	restablishPasswordWithSecret,
	sendEmailRecoveryPassword,
	updateLenguage,
	validateEmail
};
