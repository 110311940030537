import React from 'react';

// Import Libs
import { FormattedMessage } from 'react-intl';

// Import Components
import DynamicTable from 'common/DynamicTable/DynamicHead';

// Import Paths
import paths from 'services/paths';

// Import Constants
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';

// Import PropTypes
import { ProviderTypeTableProps } from './ProviderTypeTable.props';

// Import ViewModel
import ProviderTypeTableViewModel from './ProviderTypeTable.viewModel';

const ProviderTypeTable = ({ permisos }) => {
	const {
		loading,
		displayLoading,
		displayMessage,
		error,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		parameters,
		titles
	} = ProviderTypeTableViewModel();

	return (
		<>
			{!loading && parameters !== null ? (
				<FormattedMessage id="table.name.search.providerType" defaultMessage="Tipos de proveedor">
					{() => (
						<DynamicTable
							titles={titles}
							pageName={PAGE_NAMES.ProveedorTipo}
							data={getDataTable}
							handleDeleteItem={handleDeleteItem}
							handleEditStateItem={handleEditStateItem}
							routeToEdit={paths.updateProviderType}
							canDeleted={permisos.eliminar}
							canModify={permisos.editar}
						/>
					)}
				</FormattedMessage>
			) : error ? (
				displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			) : (
				displayLoading()
			)}
		</>
	);
};

ProviderTypeTable.propTypes = ProviderTypeTableProps;

export default ProviderTypeTable;
