import { useForm } from '@formiz/core';
import axios from 'axios';
import HandleOnError from 'common/validators/HandleOnError';
import useLangv2 from 'hooks/useLangv2';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { InvoiceViewModels } from 'screens/invoiceReconciliation/Invoice.viewModels';
import CustomAlert from 'components/CustomAlert';
import endpoints from 'services/api';

export const FormViewModels = () => {
	const formData = useForm();
	const [date, setDate] = useState('');
	const [value, setValue] = useState('ticket');
	const [archivos, setCurrentFiles] = useState([]);
	const [option, setOption] = useState(true);
	const navigate = useNavigate();
	const { formatterText } = useLangv2();
	const [isLoading, setIsLoading] = useState(false);

	const { permissionsAccess, permittedActions, permissions } = InvoiceViewModels();

	const handleChange = (e) => {
		setOption(!option);
		setValue(e.target.value);
	};

	const handleSaveAttachedFiles = (isTicket) => {
		if (archivos.length > 0) {
			const binary = atob(archivos[0].archivo);
			// convertir el objeto binario en un arreglo de bytes
			const bytes = new Uint8Array(binary.length);
			for (let i = 0; i < binary.length; i++) {
				bytes[i] = binary.charCodeAt(i);
			}

			// crear un objeto de archivo a partir del arreglo de bytes
			const fileData = new File([bytes.buffer], archivos[0].name + archivos[0].extensionArchivo, {
				type: '.xlsx'
			});

			const form = new FormData();

			form.append('file', fileData);
			form.append('ticket', isTicket);
			setIsLoading(true);
			axios
				.post(endpoints.services.saveInvoiceReconciliation, form, {
					headers: {
						accept: '*/*',
						'Content-Type': 'multipart/form-data',
						Authorization: sessionStorage.getItem('token')
					}
				})
				.then((resp) => {
					const response = resp.data;
					if (response.length > 0) {
						let message = '';
						response.map((item) => {
							message += `fila-${item.fila}: ${item.error}\n`;
						});
						HandleOnError(message);
					} else {
						CustomAlert('confirm_msg', {
							icon: 'success',
							title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
							text: formatterText('file.success.attached'),
							confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
							allowOutsideClick: false,
							executeFunction: () => setCurrentFiles([])
						});
					}
				})
				.catch(() => HandleOnError(formatterText('error.alert.excel.case.12')))
				.finally(() => setIsLoading(false));
		}
	};

	const handleSubmit = () => {
		handleSaveAttachedFiles(option);
	};

	return {
		permissionsAccess,
		permittedActions,
		permissions,
		date,
		setDate,
		value,
		setValue,
		archivos,
		setCurrentFiles,
		option,
		setOption,
		navigate,
		formatterText,
		isLoading,
		setIsLoading,
		handleChange,
		handleSaveAttachedFiles,
		handleSubmit,
		formData
	};
};
