import React, { memo, useState } from 'react';
// Mui table styles
import MuiAlert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// Pagination actions
import TablePagination from '@mui/material/TablePagination';
import UserPaginateActions from 'components/tableOptions/UserPaginateActions';
// Dynamic cell
import { TableFooter } from '@mui/material';
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';
import { useSeachContext } from 'context/SearchContext';
import useLangv2 from 'hooks/useLangv2';
import DynamicRow from './DynamicRow';
// Search context

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DynamicHead = ({
	titles,
	pageName,
	getData,
	data,
	handleDownloadItem,
	handleDeleteItem,
	handleEditStateItem,
	noHandleEditStateItem,
	routeToEdit,
	canDeleted,
	canDeletedInvoice,
	canModify,
	canDownload,
	canSelectEU,
	canSelectSC,
	canSelectSSU,
	canSelectSBU,
	canEditPrecaution,
	onClose,
	functions
}) => {
	// context de búsqueda
	const {
		rowsPerPage,
		setRowsPerPage,
		rowMarket,
		setRowMarket,
		rowParams,
		setRowParams,
		rowBilling,
		setRowBilling,
		rowClient,
		setRowClient,
		rowProvider,
		setRowProvider,
		rowBussinesUnit,
		setRowBussinesUnit,
		rowCategories,
		setRowCategories,
		rowStages,
		setRowStages,
		rowActivities,
		setRowActivities,
		rowPrices,
		setRowPrices,
		rowForms,
		setRowForms,
		rowtechs,
		setRowTechs,
		rowVehicles,
		setRowVehicles,
		RowcategoriesProduct,
		setRowscategoriesProduct,
		RowproductsPage,
		setRowproductsPage,

		searchResults,

		page,
		setPage,
		thirdPage,
		setThirdPage,
		billingPage,
		setBillingPage,
		clientPage,
		setClientPage,
		providerPage,
		setProviderPage,
		unitBussinesPage,
		setUnitBussinesPage,
		marketPage,
		setMarketPage,
		serviceCatPage,
		setServiceCatPage,
		serviceStagetPage,
		setServiceStagetPage,
		activitiesPage,
		setActivitiesPage,
		priceListPage,
		setPriceListPage,
		formPage,
		setFormPage,
		techsPage,
		setTechsPage,
		vehiclesPage,
		setVehiclesPage,
		categoriesProductPage,
		setcategoriesProductPage,
		productsPage,
		setProductsPage
	} = useSeachContext();

	const { formatterText } = useLangv2();

	const [open, setOpen] = useState(false);

	const handleChangePage = (event, newPage) => {
		const typeToSetMap = {
			[PAGE_NAMES.Mercados]: setMarketPage,
			[PAGE_NAMES.UnidadNegocio]: setUnitBussinesPage,
			[PAGE_NAMES.ProveedorTipo]: setProviderPage,
			[PAGE_NAMES.ClienteTipo]: setClientPage,
			[PAGE_NAMES.Facturacion]: setBillingPage,
			[PAGE_NAMES.Terceros]: setThirdPage,
			[PAGE_NAMES.Categorías]: setServiceCatPage,
			[PAGE_NAMES.Etapas]: setServiceStagetPage,
			[PAGE_NAMES.Actividades]: setActivitiesPage,
			[PAGE_NAMES.Precios]: setPriceListPage,
			[PAGE_NAMES.Formularios]: setFormPage,
			[PAGE_NAMES.Técnicos]: setTechsPage,
			[PAGE_NAMES.Vehículos]: setVehiclesPage,
			[PAGE_NAMES.CategoríasProductos]: setcategoriesProductPage,
			[PAGE_NAMES.Productos]: setProductsPage
		};
		const dataToSet = typeToSetMap[pageName] || setPage;
		dataToSet(newPage);
	};

	const pages = (type) => {
		const typeToDataMap = {
			[PAGE_NAMES.Mercados]: marketPage,
			[PAGE_NAMES.UnidadNegocio]: unitBussinesPage,
			[PAGE_NAMES.ProveedorTipo]: providerPage,
			[PAGE_NAMES.ClienteTipo]: clientPage,
			[PAGE_NAMES.Facturacion]: billingPage,
			[PAGE_NAMES.Terceros]: thirdPage,
			[PAGE_NAMES.Categorías]: serviceCatPage,
			[PAGE_NAMES.Etapas]: serviceStagetPage,
			[PAGE_NAMES.Actividades]: activitiesPage,
			[PAGE_NAMES.Precios]: priceListPage,
			[PAGE_NAMES.Formularios]: formPage,
			[PAGE_NAMES.Técnicos]: techsPage,
			[PAGE_NAMES.Vehículos]: vehiclesPage,
			[PAGE_NAMES.CategoríasProductos]: categoriesProductPage,
			[PAGE_NAMES.Productos]: productsPage
		};

		const dataToRender = typeToDataMap[type] || page;
		return dataToRender;
	};

	const handleChangeRowsPerPage = (event) => {
		const typeToSetMap = {
			[PAGE_NAMES.Mercados]: setRowMarket,
			[PAGE_NAMES.UnidadNegocio]: setRowBussinesUnit,
			[PAGE_NAMES.ProveedorTipo]: setRowProvider,
			[PAGE_NAMES.ClienteTipo]: setRowClient,
			[PAGE_NAMES.Facturacion]: setRowBilling,
			[PAGE_NAMES.Terceros]: setRowParams,
			[PAGE_NAMES.Categorías]: setRowCategories,
			[PAGE_NAMES.Etapas]: setRowStages,
			[PAGE_NAMES.Actividades]: setRowActivities,
			[PAGE_NAMES.Precios]: setRowPrices,
			[PAGE_NAMES.Formularios]: setRowForms,
			[PAGE_NAMES.Técnicos]: setRowTechs,
			[PAGE_NAMES.Vehículos]: setRowVehicles,
			[PAGE_NAMES.CategoríasProductos]: setRowscategoriesProduct,
			[PAGE_NAMES.Productos]: setRowproductsPage
		};
		const dataToSet = typeToSetMap[pageName] || setRowsPerPage;
		dataToSet(event.target.value, 10);
	};

	const rowPages = (type) => {
		const typeToDataMap = {
			[PAGE_NAMES.Mercados]: rowMarket,
			[PAGE_NAMES.UnidadNegocio]: rowBussinesUnit,
			[PAGE_NAMES.ProveedorTipo]: rowProvider,
			[PAGE_NAMES.ClienteTipo]: rowClient,
			[PAGE_NAMES.Facturacion]: rowBilling,
			[PAGE_NAMES.Terceros]: rowParams,
			[PAGE_NAMES.Categorías]: rowCategories,
			[PAGE_NAMES.Etapas]: rowStages,
			[PAGE_NAMES.Actividades]: rowActivities,
			[PAGE_NAMES.Precios]: rowPrices,
			[PAGE_NAMES.Formularios]: rowForms,
			[PAGE_NAMES.Técnicos]: rowtechs,
			[PAGE_NAMES.Vehículos]: rowVehicles,
			[PAGE_NAMES.CategoríasProductos]: RowcategoriesProduct,
			[PAGE_NAMES.Productos]: RowproductsPage
		};

		const dataToRender = typeToDataMap[type] || rowsPerPage;
		return dataToRender;
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	return (
		<>
			{/* Contenedor de la tabla */}
			<TableContainer component={Paper}>
				{/* Configuración y estilo de la tabla */}
				<Table sx={{ minWidth: 250 }} aria-label="Tabla de informacion">
					{/* Cabecera de la tabla */}
					<TableHead>
						<TableRow>
							{/* Generación dinámica de títulos de columnas */}
							{titles.map((title, index) => (
								<TableCell key={`title_${index + 1}`} sx={{ fontWeight: 'bold' }} align="left">
									{title}
								</TableCell>
							))}
						</TableRow>
					</TableHead>

					{/* Cuerpo de la tabla */}
					<TableBody>
            {(rowPages(pageName) > 0
              ? searchResults.slice(pages(pageName) * rowPages(pageName), pages(pageName) * rowPages(pageName) + rowPages(pageName))
              : searchResults
            ).map((item, index) => (
              <DynamicRow
                key={`row_${index + 1}`}
                titles={titles}
                columns={item}
                getData={getData}
                handleDownloadItem={handleDownloadItem}
                handleDeleteItem={handleDeleteItem}
                handleEditStateItem={handleEditStateItem}
                noHandleEditStateItem={noHandleEditStateItem}
                routeToEdit={routeToEdit}
                canDeleted={canDeleted}
                canDeletedInvoice={canDeletedInvoice}
                canModify={canModify}
                canDownload={canDownload}
                canSelectEU={canSelectEU}
                canSelectSBU={canSelectSBU}
                canSelectSC={canSelectSC}
                canSelectSSU={canSelectSSU}
                canEditPrecaution={canEditPrecaution}
                onClose={onClose}
                functions={functions}
                data={data}
              />
            ))}
          </TableBody>

					{/* Pie de página para paginación */}
					<TableFooter
						sx={{
							display: 'inherit',
							justifyItems: 'center',
							justifyContent: 'right',
							mt: 2,
							mb: 2,
							minWidth: 250,
							borderRadius: '10px'
						}}
					>
						<TablePagination
							sx={{
								boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
								marginTop: '25px',
								borderRadius: '10px'
							}}
							rowsPerPageOptions={[
								25,
								50,
								100,
								200,
								{ label: formatterText('input.placeholder.all.bill'), value: searchResults.length }
							]}
							colSpan={3}
							count={searchResults.length}
							rowsPerPage={rowPages(pageName) || rowsPerPage}
							page={pages(pageName) || page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={UserPaginateActions}
							labelRowsPerPage={formatterText(pageName) + formatterText('title.per.page')}
							align="center"
						/>
					</TableFooter>
				</Table>
			</TableContainer>

			{/* Snackbar para alertas */}
			<Stack spacing={2} sx={{ width: '100%' }}>
				<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
					<Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
						{formatterText('alert.update')}
					</Alert>
				</Snackbar>
			</Stack>
		</>
	);
};

export default memo(DynamicHead);
