import useAxios from 'hooks/useAxios';
import { useState } from 'react';

// Import Libs
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

// Import Components
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';

// Import Hooks
import useLangv2 from 'hooks/useLangv2';

// Import Utils
import HandleInput from 'common/validators/HandleInput';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import { validateDuplicateCode } from 'common/validators/ValidateDuplicates';

// Import Paths
import paths from 'services/paths';

// Import Models
import Parameter from 'models/Parameters/Parameter';

// Import Constants
import { TEXTREGEX, TEXTREGEXMIN } from 'common/validators/Regex';

export default function CreateParameterViewModel() {
	const { endpoints, RequestUseCase } = useAxios();
	const navigate = useNavigate();
	// Datos de un formulario
	const [formData, setFormData] = useState(new Parameter());
	const { formatterText, noFilledContent } = useLangv2();
	const [errorName, setErrorName] = useState('');
	const [codeError, setCodeError] = useState('');
	// Update a string to set into the form
	const handleText = (e) => {
		HandleInput(e, TEXTREGEX, formData, setFormData, true);
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	const handlerTextCode = (e) => {
		const example = e;
		example.target.value = e.target.value.toLowerCase();
		HandleInput(example, TEXTREGEXMIN, formData, setFormData);
	};

	const validateCode = () => {
		const errorMsg = formatterText('p.label.title.codigoExistenteElemento');
		const validate = validateDuplicateCode(formData.codigo, errorMsg, () =>
			RequestUseCase.get(endpoints.parameters.getAllThirdTypes)
		);
		return validate;
	};
	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		if (errorName !== '' || codeError !== '' || formData.nombre === '' || formData.codigo === '') {
			noFilledContent();
		} else {
			if (validateCode()) {
				Swal.fire({
					title: formatterText(
						'alert.title.general',
						'Atención, estás seguro de realizar esta acción'
					),
					text: formatterText(
						'alert.description.create.general',
						'Se va a crear un nuevo registro'
					),
					icon: 'question',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					showLoaderOnConfirm: true,
					cancelButtonColor: '#d33',
					confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
					allowOutsideClick: false,
					cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
					preConfirm: () => {
						return RequestUseCase.post(endpoints.parameters.addThirdType, {
							nombre: formData.nombre.replaceAll(/\s{2,}/gi, ' '),
							descripcion: formData.descripcion,
							codigo: formData.codigo,
							estado: 1
						})
							.then(() => {
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.created.general',
										'El registro se ha creado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => navigate(paths.parameters)
								});
							})
							.catch((err) => {
								if (err.response?.data?.message) {
									HandleOnError(formatterText(err.response?.data?.message));
								} else {
									HandleOnError(
										formatterText(
											'snackbar.error.process.failed.general',
											'Error al realizar el proceso. Intentalo en otro momento.'
										)
									);
								}
							});
					}
				});
			}
		}
	};

	const handleErrorName = () => {
		if (formData.nombre.trim() === '') {
			setErrorName(formatterText('p.label.title.name.is.required'));
		} else {
			setErrorName('');
		}
	};

	const handleErrorCode = () => {
		if (formData.codigo.trim() === '') {
			setCodeError(formatterText('table.title.code.required'));
		} else {
			setCodeError('');
		}
	};

	return {
		codeError,
		errorName,
		formatterText,
		formData,
		handleErrorCode,
		handleErrorName,
		handlerTextCode,
		handlerTextDescription,
		handleSubmit,
		handleText,
		navigate
	};
}
