const API = process.env.REACT_APP_API;
const API_LOCAL = process.env.REACT_APP_API_LOCAL;
const API_NOTIFICATIONS = process.env.REACT_APP_API_NOTIS;

const endpoints = {
	users: {
		getAllUsers: `${API}/usuarios/getAll`,
		addUser: `${API}/usuarios/save`,
		updateUser: `${API}/usuarios/update`,
		deleteUser: (id) => `${API}/usuarios/delete/${id}`,
		getUserById: (id) => `${API}/usuarios/getById/${id}`,
		findByUsername: (username) => `${API}/usuarios/findByUsuario/${username}`,
		findByMail: (mail) => `${API}/usuarios/findByCorreo/${mail}`,
		insertRolesByUser: `${API}/usuarios/insertRolesByUser`,
		deleteRolesByUser: `${API}/usuarios/deleteRolesByUser`,
		tercerosGetByUserAsing: `${API}/terceros/getByUserAsing/`,
		empleadoGetByUserAsing: `${API}/empleado/getByUserAsing/`,
		notifyGetAll: `${API_LOCAL}/categoriaservicio/getAll/`,
		addNotify: `${API_LOCAL}/categoriaservicio/saveCategoriaUsuario/`,
		notifiGetUser: (id) => `${API_LOCAL}/categoriaservicio/getCategoriasByUsuario/${id}`
	},
	roles: {
		getAllRoles: `${API}/roles/getAll`,
		getAllRolesActivos: `${API}/roles/getAllActivos`,
		getAllPermisosModulos: `${API}/rolPermisoModulo/getAllPermisosModulos`,
		getAllRolPermisosModulosByRol: (id) =>
			`${API}/rolPermisoModulo/getAllRolPermisosModulosByRol/${id}`,
		addRole: `${API}/roles/save`,
		addRolePermisoModulo: `${API}/rolPermisoModulo/save`,
		getRoleById: (id) => `${API}/roles/getById/${id}`,
		deleteRole: (id) => `${API}/roles/delete/${id}`,
		updateRole: `${API}/roles/update`,
		getAllRolPermisosModulosByUser: (id) =>
			`${API}/rolPermisoModulo/getAllRolPermisosModulosByUser/${id}`,
		deletePermisoRol: (id) => `${API}/rolPermisoModulo/delete/${id}`
	},
	auth: {
		login: `${API}/login/`,
		validateEmail: `${API}/login/validateEmail`,
		sendRecoveryPassword: (username) => `${API}/login/sendEmailRecoveryPassword/${username}`,
		validateEmailUserPassword: `${API}/login/validateEmailUserPassword`,
		changePassword: `${API}/login/verifyNewPassword`,
		updateLenguage: `${API}/usuarios/updateLenguage`
	},
	mail: {
		getAllMails: `${API}/correos/getAll`,
		getMailById: (id) => `${API}/correos/getById/${id}`,
		addMail: `${API}/correos/save`,
		updateMail: `${API}/correos/update`,
		sendMail: `${API}/correos/sendEmail`,
		sendMailTest: `${API}/correos/sendEmailTest`
	},
	zones: {
		getAllCountries: `${API}/zonas/getAllPaises`,
		getAllDepartments: `${API}/zonas/getAllDepartamentos`,
		getAllMunicipalities: `${API}/zonas/getAllMunicipios`,
		getAllSectors: `${API}/zonas/getAllSectores`,
		getSectorById: (id) => `${API}/zonas/getByIdSector/${id}`,
		uploadPaises: `${API}/zonas/uploadPaises`,
		uploadDepartments: `${API}/zonas/uploadDepartamentos`,
		uploadMunicipalities: `${API}/zonas/uploadMunicipios`,
		uploadSectors: `${API}/zonas/uploadSectores`,
		deleteSector: (id) => `${API}/zonas/deleteSector/${id}`,
		addSector: `${API}/zonas/saveSectores`,
		updateMunicipality: `${API}/zonas/updateMunicipios`,
		updateDepartment: `${API}/zonas/updateDepartamentos`,
		updateCountry: `${API}/zonas/updatePaises`,
		updateSectors: `${API}/zonas/updateSectores`,
		getAllDepartmentsByIdCountry: (id) => `${API}/zonas/getDepartamentosByIdPais/${id}`
	},
	audit: {
		getAllAudit: `${API}/auditorias/getAll`,
		getAuditsQuery: `${API}/auditorias/findByOptions`,
		getAuditsCount: `${API}/auditorias/countFindByOptions`,
		getPermissions: `${API}/auditorias/permisos`,
		getModules: `${API}/auditorias/modulos`,
		saveAudits: `${API_LOCAL}/utilidades/saveAuditoria`
	},
	cargos: {
		getAllCargos: `${API}/cargos/getAll`,
		getCargoById: (id) => `${API}/cargos/getById/${id}`,
		addCargo: `${API}/cargos/save`,
		updateCargo: `${API}/cargos/update`,
		updateState: `${API}/cargos/updateEstado`,
		deleteCargo: (id) => `${API}/cargos/delete/${id}`
	},
	parameters: {
		getAllThirdTypes: `${API}/tiposterceros/getAll`,
		addThirdType: `${API}/tiposterceros/save`,
		deleteThirdType: (id) => `${API}/tiposterceros/delete/${id}`,
		updateThirdType: `${API}/tiposterceros/update`
	},
	contracts: {
		getAllContracts: `${API}/tiposContratos/getAll`,
		addContract: `${API}/tiposContratos/save`,
		getContractById: (id) => `${API}/tiposContratos/getById/${id}`,
		updateContract: `${API}/tiposContratos/update`,
		deleteContract: (id) => `${API}/tiposContratos/delete/${id}`,
		updateState: `${API}/tiposContratos/updateEstado`
	},
	thirdParties: {
		getAllThirdParties: `${API}/terceros/getAll`,
		addThirdParty: `${API}/terceros/save`,
		updateThirdParty: `${API}/terceros/update`,
		deleteThirdParty: (id) => `${API}/terceros/delete/${id}`,
		getThirdPartyById: (id) => `${API}/terceros/getById/${id}`
	},
	technical: {
		getAllTechnical: `${API}/tecnico/getAll`,
		addTechnical: `${API}/tecnico/save`,
		getVehiclesByTechnical: `${API}/vehiculo/getAll`,
		updateTechnical: `${API}/tecnico/update`,
		deleteTechnical: (id) => `${API}/tecnico/delete/${id}`,
		deleteVehicleFromTechnical: (id) => `${API}/vehiculo/delete/${id}`,
		getEmployeesThirdPartiesNA: `${API}/tecnico/getEntidadesNoTecnicos`,
		getTechicalById: (id) => `${API}/tecnico/getById/${id}`,
		getTechniciansByOptions: `${API}/tecnico/findByOptions`
	},
	employees: {
		getAllEmployees: `${API}/empleado/getAll`,
		getAllSelectEmployees: `${API}/empleado/getAllSelect`,
		getFamiltybyEmployee: (id) => `${API}/familiares/getEmpleado/${id}`,
		addEmployee: `${API}/empleado/save`,
		addFamily: `${API}/familiares/save`,
		updateEmployee: (id) => `${API}/empleado/update/${id}`,
		updateFamily: `${API}/familiares/update`,
		deleteEmployee: (id) => `${API}/empleado/delete/${id}`,
		deleteFamilyByEmployee: (id) => `${API}/familiares/delete/${id}`,
		getEmployeeById: (id) => `${API}/empleado/getById/${id}`
	},
	institutions: {
		getAllTechnical: `${API}/tecnico/getAll`,
		getVehicles: `${API}/vehiculo/getAll`,
		technicalVehicles: `${API}/tecnicoVehiculo/getAll`,
		brandVehicles: `${API}/marcaVehiculo/getAll`,
		categoryLicenses: `${API}/categoriaLicenciaConduccion/getAll`,
		getAllbanks: `${API}/bancos/getAll`,
		getAllHalthEntities: `${API}/entidadSalud/getAll`,
		getAllGenres: `${API}/genero/getAll`,
		getAllTypeAccounts: `${API}/tipoCuenta/getAll`,
		getAllTypeDocument: `${API}/tipoDocumentos/getAll`,
		getAllKin: `${API}/parentesco/getAll`,
		getAllFamilies: `${API}/familiares/getAll`,
		saveVehicle: `${API}/vehiculo/save`,
		getTechnicalVehiclesById: (id) => `${API}/vehiculo/getVehiculosTecnicos/${id}`,
		deleteVehicle: (id) => `${API}/vehiculo/delete/${id}`,
		getUnidadMedida: `${API_LOCAL}/listaPreciosActividades/getAllUnidadMedida`
	},
	markets: {
		getAllMarkets: `${API}/mercados/getAll`,
		addMarket: `${API}/mercados/save`,
		deleteMarket: (id) => `${API}/mercados/delete/${id}`,
		updateMarket: (id) => `${API}/mercados/update/${id}`,
		addMarketCountry: `${API}/mercados/savePais`,
		getMarketAssociateByCountry: (id) => `${API}/mercados/getByMercadoPais/${id}`, // retorna pais segun mercado
		getMarketByCountry: (idCountry) => `${API}/mercados/getMercadoByPais/${idCountry}`, // retorna mercados segun pais
		deleteMarketFromCountry: (id) => `${API}/mercados/deletePais/${id}`,
		findMercadoByIdMercado: (id) => `${API}/mercados/findMercadoByIdMercado/${id}`
	},
	clientTypes: {
		getAllClientTypes: `${API}/tipoClientes/getAll`,
		addClientType: `${API}/tipoClientes/save`,
		updateClientType: `${API}/tipoClientes/update`,
		deleteClientType: (id) => `${API}/tipoClientes/delete/${id}`,
		getClientTypeById: (id) => `${API}/tipoClientes/getById/${id}`
	},
	billingTypes: {
		getAllBillingTypes: `${API}/tipoFacturacion/getAll`,
		addBillingType: `${API}/tipoFacturacion/save`,
		updateBillingType: `${API}/tipoFacturacion/update`,
		deleteBillingType: (id) => `${API}/tipoFacturacion/delete/${id}`,
		getBillingTypeById: (id) => `${API}/tipoFacturacion/getById/${id}`
	},
	businessUnit: {
		getAllBusinessUnits: `${API}/unidadNegocio/getAllUnidadNegocio`,
		getBusinessUnitByMarket: (idMarket) => `${API}/unidadNegocio/getByIdMercado/${idMarket}`,
		addBusinessUnit: `${API}/unidadNegocio/save`,
		updateBusinessUnit: `${API}/unidadNegocio/update`,
		deleteBusinessUnit: (id) => `${API}/unidadNegocio/delete/${id}`,
		getMarketCountryByUnit: (id) =>
			`${API}/mercadoPaisUnidaNegocio/getMercadoPaisByUnidadNegocio/${id}`,
		getAllMarketCountry: `${API}/mercadoPaisUnidaNegocio/getAllMercadoPais`,
		getAllData: `${API}/mercadoPaisUnidaNegocio/getAll`,
		addMarketCountryToUnit: `${API}/mercadoPaisUnidaNegocio/save`,
		updateMarketCountryToUnit: `${API}/mercadoPaisUnidaNegocio/update`,
		updateBusinessUnitByClient: `${API}/unidadNegocioCliente/update`,
		getUnitBusinessByClient: (clientId) => `${API}/unidadNegocioCliente/getByIdCliente/${clientId}`,
		deleteMarketCountryFromUnit: (id) => `${API}/mercadoPaisUnidaNegocio/delete/${id}`
	},
	providerType: {
		getAllProviderTypes: `${API}/tipoProveedores/getAll`,
		addProviderType: `${API}/tipoProveedores/save`,
		updateProviderType: `${API}/tipoProveedores/update`,
		deleteProviderType: (id) => `${API}/tipoProveedores/delete/${id}`,
		getProviderTypeById: (id) => `${API}/tipoProveedores/getById/${id}`
	},
	clients: {
		getAllClients: `${API}/clientes/getAll`,
		addClient: `${API}/clientes/save`,
		updateClient: `${API}/clientes/update`,
		deleteClient: (id) => `${API}/clientes/delete/${id}`,
		getAllClientByName: (name) => `${API}/clientes/getClienteByNombre/${name}`,
		getClienteUnidadNegocioByIdCliente: (id) =>
			`${API}/clientes/getClienteUnidadNegocioByIdCliente/${id}`,
		getClientById: (id) => `${API}/clientes/getById/${id}`
	},
	branchOffices: {
		getAllBranchOffices: `${API}/sucursales/getAll`,
		addBranchOffice: `${API}/sucursales/save`,
		updateBranchOffice: `${API}/sucursales/update`,
		deleteBranchOffice: (id) => `${API}/sucursales/delete/${id}`,
		getSucursalClientById: (id) => `${API}/sucursales/getByIdCliente/${id}`
	},
	advisers: {
		getAllAdvisers: `${API}/asesores/getAll`,
		addAdviser: `${API}/asesores/save`,
		updateAdviser: (id) => `${API}/asesores/update/${id}`,
		deleteAdviser: (id) => `${API}/asesores/delete/${id}`,
		getAllAdvisersByClient: (id) => `${API}/asesores/getByIdCliente/${id}`,
		getAsesorsByClient: (id) => `${API}/asesores/getAsesoresByIdCliente/${id}`
	},
	productCategory: {
		getAllProductCategory: `${API}/categoriaproducto/getAll`,
		addProductCategory: `${API}/categoriaproducto/save`,
		updateProductCategory: `${API}/categoriaproducto/update`,
		deleteProductCategory: (id) => `${API}/categoriaproducto/delete/${id}`,
		getCategoryById: (id) => `${API}/categoriaproducto/getById/${id}`
	},
	products: {
		exportJson: `${API}/costosProductoProveedor/exportCostos`,
		getAllProduct: `${API}/productos/getAll`,
		addProduct: `${API}/productos/save`,
		updateProduct: `${API}/productos/update`,
		deleteProduct: (id) => `${API}/productos/delete/${id}`,
		getProductById: (id) => `${API}/productos/getById/${id}`
	},
	subProductCategory: {
		getAllSubProductCategory: `${API}/subCategoriaProducto/getAll`,
		addSubProductCategory: `${API}/subCategoriaProducto/save`,
		updateSubProductCategory: `${API}/subCategoriaProducto/update`,
		deleteSubProductCategory: (id) => `${API}/subCategoriaProducto/delete/${id}`,
		getAllSubProductCategoryByCategory: (id) => `${API}/subCategoriaProducto/getByCategoria/${id}`
	},
	providers: {
		getAllProviders: `${API}/proveedores/getAll`,
		updateProvider: `${API}/proveedores/update`,
		deleteProvider: (id) => `${API}/proveedores/delete/${id}`,
		addProvider: `${API}/proveedores/save`,
		getProviderById: (id) => `${API}/proveedores/getById/${id}`
	},
	barcodes: {
		getAllBarcodes: `${API}/codigobarras/getAll`,
		addBarcode: `${API}/codigobarras/save`,
		updateBarcode: `${API}/codigobarras/update`,
		deleteBarcode: (id) => `${API}/codigobarras/delete/${id}`
	},
	costProductProvider: {
		getAllCostProductProvider: `${API}/costosProductoProveedor/getAll`,
		addCostProductProvider: `${API}/costosProductoProveedor/save`,
		updateCostProductProvider: `${API}/costosProductoProveedor/update`,
		deleteCostProductProvider: (id) => `${API}/costosProductoProveedor/delete/${id}`,
		uploadFileCostProvider: `${API}/costosProductoProveedor/importCostos`,
		getCostProductProviderByProduct: (id) => `${API}/costosProductoProveedor/getByIdProducto/${id}`
	},
	serviceCategory: {
		getAllServiceCategory: `${API_LOCAL}/categoriaservicio/getAll`,
		addServiceCategory: `${API_LOCAL}/categoriaservicio/save`,
		updateServiceCategory: `${API_LOCAL}/categoriaservicio/update`,
		deleteServiceCategory: (id) => `${API_LOCAL}/categoriaservicio/delete/${id}`,
		getAllWithTipoServicio: `${API_LOCAL}/categoriaservicio/getAllWithTipoServicio`,
		getCategoryServiceById: (id) => `${API_LOCAL}/categoriaservicio/getById/${id}`
	},
	productCategoryService: {
		getAll: `${API_LOCAL}/categoriaproductotiposervicio/getAll`,
		save: `${API_LOCAL}/CategoriaProductoTipoServicio/save`,
		delete: (id) => `${API_LOCAL}/CategoriaProductoTipoServicio/delete/${id}`,
		saveMultiple: `${API_LOCAL}/categoriaproductotiposervicio/saveMultiple`
		//saveMultiple: `${API_LOCAL}/CategoriaProductoTipoServicio/saveMultiple`,
	},
	typeService: {
		getAllTypeService: `${API_LOCAL}/tiposervicio/getAll`,
		getAllTypeServiceByTech: (id) => `${API_LOCAL}/tiposervicio/findByIdTecnico/${id}`,
		addTypeService: `${API_LOCAL}/tiposervicio/save`,
		updateTypeService: `${API_LOCAL}/tiposervicio/update`,
		updateTypeServiceProductCategory: `${API_LOCAL}/tiposervicio/updateTipoServicioCategoriaProducto`,
		deleteTypeService: (id) => `${API_LOCAL}/tiposervicio/delete/${id}`,
		getTypeServiceByCategory: (id) => `${API_LOCAL}/tiposervicio/getByCategoriaServicio/${id}`,
		newGetTypeServiceByCategory: (id) =>
			`${API_LOCAL}/tiposervicio/getCategoriaProductoByCategoriaServicio/${id}`
	},
	stageServices: {
		getAllStageServices: `${API_LOCAL}/etapaServicios/getAll`,
		addStageService: `${API_LOCAL}/etapaServicios/save`,
		updateStageService: `${API_LOCAL}/etapaServicios/update`,
		deleteStageService: (id) => `${API_LOCAL}/etapaServicios/delete/${id}`,
		getStageServiceById: (id) => `${API_LOCAL}/etapaServicios/getById/${id}`
	},
	activitiesServices: {
		getAllActivitiesServices: `${API_LOCAL}/actividades/getAll`,
		addActivitiesService: `${API_LOCAL}/actividades/save`,
		updateActivitiesService: `${API_LOCAL}/actividades/update`,
		deleteActivitiesService: (id) => `${API_LOCAL}/actividades/delete/${id}`,
		deleteActivitiesBasic: (id) => `${API_LOCAL}/actividades/deleteBasic/${id}`,
		getAllActivitiesServicesBasic: `${API_LOCAL}/actividades/getAllBasic`
	},
	typoServiceActivities: {
		getAllTypoServiceActivities: `${API_LOCAL}/tipoServiciosActividades/getAll`,
		addTypeServiceActivities: `${API_LOCAL}/tipoServiciosActividades/save`,
		updateTypeServiceActivities: `${API_LOCAL}/tipoServiciosActividades/update`,
		deleteTypoServiceActivities: (id) => `${API_LOCAL}/tipoServiciosActividades/delete/${id}`,
		getTypeServiceActivitiesByTypeService: (id) =>
			`${API_LOCAL}/tipoServiciosActividades/getByIdTipoServicio/${id}`,
		getTypeServiceByActivity: (id) => `${API_LOCAL}/tipoServiciosActividades/getByIdActividad/${id}`
	},
	listPrices: {
		getAllListPrices: `${API_LOCAL}/listaPreciosActividades/getAll`,
		addListPrice: `${API_LOCAL}/listaPreciosActividades/save`,
		updateListPrice: `${API_LOCAL}/listaPreciosActividades/update`,
		deleteListPrice: (id) => `${API_LOCAL}/listaPreciosActividades/delete/${id}`,
		getAllMonedas: `${API_LOCAL}/listaPreciosActividades/getAllMonedas`,
		uploadFileListPrice: `${API_LOCAL}/listaPreciosActividades/importLPAD`,
		getListPriceById: (id) => `${API_LOCAL}/listaPreciosActividades/getById/${id}`
	},
	associatePrices: {
		addAssociate: `${API_LOCAL}/listaPreciosActividades/saveLPAD`,
		getAssociatePricesByListPrice: (id) =>
			`${API_LOCAL}/listaPreciosActividades/findLPADByIdListaPrecios/${id}`,
		updateAssociate: `${API_LOCAL}/listaPreciosActividades/updateLPAD`,
		deleteAssociate: (id) => `${API_LOCAL}/listaPreciosActividades/deleteLPAD/${id}`
	},
	formServices: {
		getAllFormServices: `${API_LOCAL}/formulario/getAll`,
		getAllFormWithQuestionsById: (id) => `${API_LOCAL}/formulario/formularioCompleteById/${id}`,
		updateFormService: `${API_LOCAL}/formulario/update`,
		deleteFormService: (id) => `${API_LOCAL}/formulario/delete/${id}`,
		addFormService: `${API_LOCAL}/formulario/save`,
		addQuestionForm: `${API_LOCAL}/formulario/savePregunta`,
		updateQuestionForm: `${API_LOCAL}/formulario/updatePregunta`,
		deleteQuestionForm: (id) => `${API_LOCAL}/formulario/deletePregunta/${id}`,
		addOptionsQuestion: `${API_LOCAL}/formulario/saveRespuestaEncuesta`,
		updateOptionsQuestion: `${API_LOCAL}/formulario/updateRespuestaEncuesta`,
		deleteOptionsQuestion: (id) => `${API_LOCAL}/formulario/deleteRespuestaEncuesta/${id}`,
		downloadFormByActivity: `${API_LOCAL}/respuestaFormulario/getRespuestasPDF`,
		getFormByIdAnswer: (id) => `${API_LOCAL}/formulario/findRespuestasPDF/${id}`
	},
	listPriceClient: {
		getAllListPriceClient: `${API_LOCAL}/listaPreciosActividadClientes/getAll`,
		downloadExcelListPriceClient: `${API_LOCAL}/listaPreciosActividades/getAllLPAD`,
		addListPriceClient: `${API_LOCAL}/listaPreciosActividades/saveLPC`,
		getListPriceById: (id) => `${API_LOCAL}/listaPreciosActividades/getByIdCliente/${id}`,
		getListPriceByIdUsuarioServicio: (id) =>
			`${API_LOCAL}/listaPreciosActividades/getByIdUsuarioServicio/${id}`,
		updateClient: `${API}/clientes/update`,
		updateListPriceClient: `${API_LOCAL}/listaPreciosActividades/saveLPC`,
		deleteListPriceClient: (id) => `${API_LOCAL}/listaPreciosActividades/deleteLPC/${id}`
	},
	associateDataTechnical: {
		addCategoryService: `${API_LOCAL}/tecnicocategoriaservicio/save`,
		getCategoryById: (id) => `${API_LOCAL}/tecnicocategoriaservicio/getCategoriaByTecnico/${id}`,
		deleteCategoryByid: (id) => `${API_LOCAL}/tecnicocategoriaservicio/delete/${id}`,
		getUserIdbyThird: (id) => `${API_LOCAL}/utilidades/getUsuarioByTercero/${id}`,
		getUserIdbyEmployee: (id) => `${API_LOCAL}/utilidades/getUsuarioByEmpleado/${id}`,
		addUnityBusiness: `${API_LOCAL}/usuariounidadnegocio/save`,
		getUnityBusinessById: (id) =>
			`${API_LOCAL}/usuariounidadnegocio/getUnidadNegocioByUsuario/${id}`,
		deleteUnityBusiness: (id) => `${API_LOCAL}/usuariounidadnegocio/delete/${id}`,
		addSucursal: `${API_LOCAL}/usuarioSucursal/save`,
		getSucursalById: (id) => `${API_LOCAL}/usuarioSucursal/getClienteDetalleByUsuario/${id}`,
		deleteSucursal: (id) => `${API_LOCAL}/usuarioSucursal/delete/${id}`,
		addCoverage: `${API_LOCAL}/coberturatecnico/save`,
		getCoverageById: (id) => `${API_LOCAL}/coberturatecnico/getAllMunicipiosByTecnico/${id}`,
		deleteCoverage: (id) => `${API_LOCAL}/coberturatecnico/delete/${id}`,
		getUserIdbyIdEmployee: (id) => `${API}/usuarios/findByIdEmpleado/${id}`,
		getUserIdbyIdTercero: (id) => `${API}/usuarios/findByIdTercero/${id}`,
		getUserIdbyIdTecnico: (id) => `${API}/usuarios/findByIdTecnico/${id}`
	},
	agenda: {
		getAllAgenda: `${API_LOCAL}/agenda/getAll`,
		getAllDiaSemanaAgenda: `${API_LOCAL}/agenda/getAllDiaSemana`,
		getAllFranjaHoraria: `${API_LOCAL}/agenda/getAllFranja`,
		getAllTimezone: `${API_LOCAL}/franjaHoraria/getAll`,
		getAllFranjaHorariaAgenda: `${API_LOCAL}/agenda/getAllFHA`,
		getAllTechinicalInTimezone: `${API_LOCAL}/franjaHorariaTecnico/getAll`,
		getTechnicalsNoAssignToTimezone: (idAgenda, idFranjaHoraria) =>
			`${API_LOCAL}/agenda/getAllTecnicosNoAsignadosByFranjaHoraria/${idAgenda}/${idFranjaHoraria}`,
		updateTechnicalInTimezone: `${API_LOCAL}/agenda/franjaHorariaTecnico/update`,
		deleteTimeZoneSchedule: (id) => `${API_LOCAL}/agenda/deleteFHA/${id}`,
		getAgendaDataByid: (id) => `${API_LOCAL}/agenda/getAgendaById/${id}`,
		getAllTimezoneByidAgenda: (id) => `${API_LOCAL}/agenda/getFranjaHorarioAgendaByIdAgenda/${id}`,
		getAllTechnicalByidTimezone: (id) =>
			`${API_LOCAL}/franjaHorariaTecnico/getTecnicoByFranjaHoraria/${id}`,
		// getAllTechnicalByidTimezone: (id) =>
		//   `${API_LOCAL_AGENDA}/franjaHorariaTecnico/getTecnicoByFranjaHoraria/${id}`,
		getAllUsersAgenda: `${API_LOCAL}/agenda/getUsuariosParaAgenda`,
		addAgenda: `${API_LOCAL}/agenda/save`,
		updateAgenda: `${API_LOCAL}/agenda/update`,
		deleteAgenda: (id) => `${API_LOCAL}/agenda/delete/${id}`,
		getAllTechnicalNoAssign: `${API_LOCAL}/agenda/getUsuariosParaAgenda`,
		getAllTechnicalAssigned: `${API_LOCAL}/agenda/getAllUA`,
		getAgendaByid: (id) => `${API_LOCAL}/usuarioagenda/getAgendaByUsuario/${id}`,
		deleteTechnicalAssigned: (id) => `${API_LOCAL}/usuarioagenda/delete/${id}`,
		addTechnicalToAgenda: `${API_LOCAL}/agenda/saveUA`,
		getAllTechnicalByFilter: `${API_LOCAL}/tecnico/getAllFilter`,
		getAlltecnicalByAgenda: `${API_LOCAL}/agenda/getAllFindByOptions`,
		getAllTecnicalByAdvancedSearch: `${API_LOCAL}/agenda/getAllFindByOptionsOfTecnico`,
		getAllFullAgenda: `${API_LOCAL}/agenda/getAllAgendasFull`,
		getAllFranjasHorarias: `${API_LOCAL}/agenda/getAllFranja`,
		getAllHoraDiaAgenda: `${API_LOCAL}/agenda/getAllHoraDia`,
		addfranjaHorariaDSemana: `${API_LOCAL}/agenda/franjaHorariaDSemana/save`,
		addAssociationTimezoneSchedule: `${API_LOCAL}/agenda/saveFHA`,
		addTimeZone: `${API_LOCAL}/franjaHoraria/save`,
		// addTechnicalTimeZone: `${API_LOCAL_AGENDA}/franjaHorariaTecnico/save`,
		addTechnicalTimeZone: `${API_LOCAL}/franjaHorariaTecnico/save`,
		deleteTechnicalTimeZone: (id) => `${API_LOCAL}/agenda/franjaHorariaTecnico/delete/${id}`,
		getAllAgendaTechnicalAssociated: `${API_LOCAL}/agenda/getAllAgendasTecnicos`,
		getTechnicalsBetweenDates: (start, end) =>
			`${API_LOCAL}/agenda/getAllAgendasTecnicos/${start}/${end}`,
		//Endpoints de franjaHoraiaAgendaTecnico
		// getAllFranjaHorariaAgendaTechnical: `${API_LOCAL}/franjaHorariaAgendaTecnico/getAll`,
		addFranjaHorariaAgendaTechnical: `${API_LOCAL}/franjaHoraria/AgendaTecnico/save`,
		getAllFranjaHorariaByIdAgenda: (idFranjaHoraria, idAgenda) =>
			`${API_LOCAL}/franjaHoraria/AgendaTecnico/getByFranjaHorariaNAgenda/${idFranjaHoraria}/${idAgenda}`,
		getFranjaHorariaAgendaByTechnical: (id) =>
			`${API_LOCAL}/franjaHoraria/AgendaTecnico/getAll/${id}`,

		deleteFranjaHorariaAgendaTechnical: (id) =>
			`${API_LOCAL}/franjaHoraria/AgendaTecnico/delete/${id}`,
		addFranjas: `${API_LOCAL}/franjaHoraria/AgendaTecnico/saveFranjas`,
		updateFranjaHorariaAgendaTecnico: `${API_LOCAL}/franjaHoraria/AgendaTecnico/update`,
		franjaHorariaAgendaTecnicoByTecnico: (id) =>
			`${API_LOCAL}/franjaHoraria/AgendaTecnico/getByTecnico/${id}`,
		getTecnicosByFranjaHorariaAgenda: `${API_LOCAL}/franjaHoraria/AgendaTecnico/getTecnicosByFranjaNAgenda`

		// getAllTechnicalByFranjaHorariaByAgenda: (idFranjaHoraria, idAgenda)=>
		// `${API_LOCAL_AGENDA}/franjaHorariaAgendaTecnico/getTecnicosByFranjaNAgenda/${idFranjaHoraria}/${idAgenda}`
	},
	activityformClient: {
		addActivityFormClient: `${API_LOCAL}/actividadformulariocliente/save`,
		deleteActivityFormClient: (id) => `${API_LOCAL}/actividadformulariocliente/delete/${id}`,
		getFormClientByActivity: (idActividad, idCliente) =>
			`${API_LOCAL}/actividadformulariocliente/getFormularioClienteByActividadAndCliente/${idActividad}/${idCliente}`,
		getAllFormWithTrueState: `${API_LOCAL}/actividadformulariocliente/getFormularioByEstadoTrue`
	},
	services: {
		getAllServicesData: `${API_LOCAL}/servicios/getAll`,
		getAllStates: `${API_LOCAL}/servicios/getAllEstadoServicio`,
		getAutoSearchServices: (id) => `${API_LOCAL}/serviciosTecnico/getAutoSearch/${id}`,
		validatePrincipalTech: `${API_LOCAL}/serviciosTecnico/validarViabilidadParaServicioByTecnicoPrincipal`,
		updateDate: `${API_LOCAL}/servicios/updateCitaNueva`,
		updateTypeService: `${API_LOCAL}/servicios/updateTipoServicio`,
		updateServices: `${API_LOCAL}/servicios/update/getAll`,
		searchServices: `${API_LOCAL}/serviciosTecnico/getServiciosFinByOptions`,
		getAllTechActives: `${API_LOCAL}/serviciosTecnico/getAllTecnicosActivos`,
		getAllServices: `${API_LOCAL}/serviciosTecnico/getAll`,
		getCalendarServices: `${API_LOCAL}/serviciosTecnico/getCalendarioServicioByOptions`,
		getFirstCalendarServices: `${API_LOCAL}/serviciosTecnico/getFirstCalendarioServicioByOptions`,
		getSearchByDireccion: (id) =>
			`${API_LOCAL}/serviciosTecnico/getAllTecnicosActivosByIdDireccion/${id}`,
		assignService: `${API_LOCAL}/serviciosTecnico/save`,
		assignServiceAux: `${API_LOCAL}/serviciosTecnico/save`,
		notifyServiceAssigned: (idTecnico, idServicio) =>
			`${API_LOCAL}/serviciosTecnico/sentPushServicioAsignado/${idTecnico}/${idServicio}`,
		addService: `${API_LOCAL}/servicios/save`,
		validateService: `${API_LOCAL}/servicios/validaAgendaParaServicio`,
		updateService: `${API_LOCAL}/servicios/update`,
		deleteService: (id) => `${API_LOCAL}/servicios/delete/${id}`,
		saveAutoSearchTechnical: `${API_LOCAL}/servicios/saveBusquedaManual`,
		getAllTechnicalsByService: (id) =>
			`${API_LOCAL}/servicios/getCitaServicioTecnicoByServicio/${id}`,
		getAllChannels: `${API_LOCAL}/servicios/getAllCanal`,
		getAllMedia: `${API_LOCAL}/servicios/getAllMedio`,
		getAllExternalUsers: `${API_LOCAL}/usuarioservicios/getAll`,
		createExternalUser: `${API_LOCAL}/usuarioservicios/save`,
		getAllAddressExternalUsersById: (id) =>
			`${API_LOCAL}/usuarioservicios/getDirByIdUsuarioServicio/${id}`,
		saveAddressExternalUsers: `${API_LOCAL}/usuarioservicios/saveDir`,
		getServiceById: (id) => `${API_LOCAL}/servicios/getServicioById/${id}`,
		deleteAuxTechnician: (id) => `${API_LOCAL}/serviciosTecnico/delete/${id}`,
		getAllExternalUsersByQuery: `${API_LOCAL}/servicios/getAllUsuarioServicioByNombreOrTelefono`,
		getAllClientsByQuery: `${API_LOCAL}/servicios/getAllClientesByNombreOrIdentificacion`,
		getAllActivitiesStateByIdService: (id) =>
			`${API_LOCAL}/tipoServiciosEstadosActividades/getAllEstadosActividadByIdServicioWeb/${id}`,
		updateStateActivityService: `${API_LOCAL}/tipoServiciosEstadosActividades/updateEstadoActividad`,
		updateServiceState: `${API_LOCAL}/serviciosTecnico/updateEstadoServicio`,
		getGoogleMapsNearLocation: 'https://maps.googleapis.com/maps/api/place/nearbysearch/json',
		getGoogleMapsLocation: ({ address = '', latlng = { lat: 0, lng: 0 } } = {}) =>
			`https://maps.googleapis.com/maps/api/geocode/json?${
				!address ? `?address=${encodeURI(address)}` : ''
			}${
				latlng && latlng.lat !== 0.0 && latlng.lng !== 0.0
					? `&latlng=${latlng.lat},${latlng.lng}`
					: ''
			}&key=${process.env.REACT_APP_GMAPS_API_KEY}`,
		getManualSearchTechnicals: (id) => `${API_LOCAL}/serviciosTecnico/getBusquedaManual/${id}`,
		generateEndDate: `${API_LOCAL}/servicios/generateFechaHoraFin`,
		validateCreateService: `${API_LOCAL}/serviciosTecnico/validaViabilidadParaServicio`,
		updateCitaAuxTech: `${API_LOCAL}/serviciosTecnico/updateCitaTecnicoAuxiliar`,
		calculateServiceRisk: `${API_LOCAL}/serviciosTecnico/calcularRiesgoIncumplimientoTecnicoByServicio`,
		getAllHours: `${API_LOCAL}/servicios/getAllHora`,
		getAllExternalUsersByNumber: (id) =>
			`${API_LOCAL}/usuarioservicios/getUsuarioServicioByNombre/${id}`,
		getAllTechByName: (id) => `${API_LOCAL}/serviciosTecnico/getTecnicosPrincipalByNombre/${id}`,
		getActivitiesActiveByService: (id) =>
			`${API_LOCAL}/tipoServiciosEstadosActividades/validateEstadoActividadByServicio/${id} `,
		getActivitiesNoTechsByService: (id) =>
			`${API_LOCAL}/tipoServiciosEstadosActividades/validateEstadoActividadNoTecnicaByServicio/${id} `,
		downloadQuotationExcelService: (query) =>
			`${API_LOCAL}/listaPreciosActividades/getArchivoExcel/${query}`,
		downloadQuotationPDFService: (query) =>
			`${API_LOCAL}/listaPreciosActividades/getArchivoPDF/${query}`,
		getStatusAux: `${API_LOCAL}/estadoTecnicoAuxiliarServicio/getEstadoServicioTecnicoAuxiliar`,
		updateDateAux: `${API_LOCAL}/serviciosTecnico/updateCita`,
		getTrazabilidad: (id) => `${API_LOCAL}/servicios/getAllTrazaByIdServicio/${id}`,
		getTrazabilidadActividades: (id) =>
			`${API_LOCAL}/tipoServiciosEstadosActividades/getAllTrazaByIdServicio/${id}`,
		getTrazabilidadCitas: (id) => `${API_LOCAL}/servicios/getTrazabilidadCitaByidServicio/${id}`,
		downloadResume: (id) => `${API_LOCAL}/servicios/getResumenServicioPDF/${id}`,
		getBillingByService: (params) =>
			`${API_LOCAL}/servicios/getFacturasByServicio/${params.numExpedient}/${params.numTicket}`,
		saveInvoiceReconciliation: `${API_LOCAL}/servicios/conciliaiconFacturas`,
		deleteInvoiceReconciliation: (id) => `${API_LOCAL}/servicios/deleteFactura/${id}`,
		saveBillingUser: `${API_LOCAL}/servicios/saveServicioFactura`,
		updateBillingUserByService: `${API_LOCAL}/servicios/updateServicioFactura`,
		deleteBillingUserByService: (id) => `${API_LOCAL}/servicios/deleteServicioFactura/${id}`,
		getBillingUserByService: (idService) =>
			`${API_LOCAL}/servicios/getFacturacionByIdServicio/${idService}`,
		getTrazabilidadNqrByIdService: (id) =>
			`${API_LOCAL}/nqrServicio/findTrazabilidadByIdServicio/${id}`,
		getLastUpdateDateService: (idService, technicalType) =>
			`${API_LOCAL}/servicios/getTrazaEnCursoByIdServicio/${idService}/${technicalType}`
	},
	serviceProduct: {
		getAllServiceProductByIdService: (id) =>
			`${API_LOCAL}/ServicioProducto/getProductosByIdServicio/${id}`,
		updateServiceProduct: `${API_LOCAL}/ServicioProducto/update`,
		addServiceProduct: `${API_LOCAL}/ServicioProducto/save`,
		deleteServiceProduct: (id) => `${API_LOCAL}/ServicioProducto/delete/${id}`,
		getAllProvidersByIdProduct: (id) =>
			`${API_LOCAL}/ServicioProducto/getProveedoresByIdProducto/${id}`,
		getAlllProviderByIdServiceProduct: (id) =>
			`${API_LOCAL}/ServicioProducto/getProveedoresByIdServicioProducto/${id}`,
		getAllProductsByIdService: (id) =>
			`${API_LOCAL}/ServicioProducto/getProductoByServicioTipoServicio/${id}`,
		downloadProductXLSX: (id) => `${API_LOCAL}/ServicioProducto/getArchivoExcel/${id}`,
		downloadProductPDF: (id) => `${API_LOCAL}/ServicioProducto/getArchivoPDF/${id}`,
		getTotalPricesProductByIdService: (id) =>
			`${API_LOCAL}/ServicioProducto/getPrecioProductosByIdServicio/${id}`,
		sendMail: `${API_LOCAL}/ServicioProducto/sendCorreo`,
		getTrazabilidadProductsByIdService: (id) =>
			`${API_LOCAL}/ServicioProducto/findTrazabilidadByIdServicio/${id}`
	},
	technicalServices: {
		getAll: `${API_LOCAL}/serviciosTecnico/getAllServicios`,
		getAll24Hrs: (query) => `${API_LOCAL}/servicios/getAll24H?${query}`,
		getAll24HrsByUser: (query) => `${API}/servicios/getAll24HByIdUsuario?${query}`,
		search: `${API_LOCAL}/serviciosTecnico/getServiciosFinByOptions`,
		advanceSearch: `${API_LOCAL}/servicios/findServicios`,
		advancedSearchWithTech: (query) =>
			`${API_LOCAL}/servicios/findServiciosPlusTecnicoAsignado?${query}`,
		getTechnicalsAssignedByService: (idServ) =>
			`${API_LOCAL}/serviciosTecnico/findByIdServicio/${idServ}`
	},
	precautionsServices: {
		getAllPrecautionsByIdService: (id) =>
			`${API_LOCAL}/serviciosRecaudo/getAllRecuadoServicioByIdServicio/${id}`,
		getAllPrecautionsState: `${API_LOCAL}/serviciosRecaudo/getallEstadoRecaudo`,
		getAllPaymentMethods: `${API_LOCAL}/serviciosRecaudo/getallMedioPago`,
		savePrecautionsServices: `${API_LOCAL}/serviciosRecaudo/save`,
		deletePrecaution: (id) => `${API_LOCAL}/serviciosRecaudo/delete/${id}`,
		updatePrecaution: (id) => `${API_LOCAL}/serviciosRecaudo/update/${id}`
	},
	userServices: {
		getAll: `${API_LOCAL}/usuarioservicios/getAll`
	},
	formulariosDiligenciados: {
		getAll: `${API_LOCAL}/respuestaencuesta/getAll`
	},
	nqrService: {
		getAll: `${API_LOCAL}/nqrServicio/findAll`,
		getAllEstadoNqr: `${API_LOCAL}/nqrServicio/findAllEstadoNqr`,
		getAllTipoNQR: `${API_LOCAL}/nqrServicio/findAllTipoNqr`,
		getAllCategoriaNQR: `${API_LOCAL}/nqrServicio/findAllCategoriaNqr`,
		saveNqrService: `${API_LOCAL}/nqrServicio/saveNqrServicio`,
		updateNqrService: `${API_LOCAL}/nqrServicio/updateNqrServicio`,
		getDataNQR: (id) => `${API_LOCAL}/nqrServicio/findById/${id}`,
		finNqrByService: (id) => `${API_LOCAL}/nqrServicio/findNqrByServicio/${id}`,
		findAnswerNqrByNqr: (id) => `${API_LOCAL}/respuestaNqr/findRespuestaNqrByIdNqrServicio/${id}`,
		deleteNqrService: (id) => `${API_LOCAL}/nqrServicio/delete/${id}`,
		deleteAnswerNqr: (id) => `${API_LOCAL}/respuestaNqr/delete/${id}`,
		saveAnswerNqr: `${API_LOCAL}/respuestaNqr/saveRespuestaNqr`,
		sendEmailNqr: `${API_LOCAL}/nqrServicio/sendEmailNqr`
	},
	map: {
		getAll: `${API_LOCAL}/geolocalizacion/findAll`
	},
	attachedFiles: {
		getAllAttachedFiles: `${API}/tipoArchivoAdjunto/getAll`,
		getById: (id) => `${API}/tipoArchivoAdjunto/getById/${id}`,
		updateAttachedFile: `${API}/tipoArchivoAdjunto/update`,
		saveAttachedFile: `${API}/tipoArchivoAdjunto/save`,
		relatedUsers: `${API}/tipoArchivoAdjunto/saveAdjuntoUsuario`,
		deleteAttachedFile: (id) => `${API}/tipoArchivoAdjunto/delete/${id}`,
		getDatosToUpdate: (id) => `${API}/tipoArchivoAdjunto/getByTipoUsuario/${id}`,
		deleteAssociates: (id) => `${API}/tipoArchivoAdjunto/deleteAdjuntoUsuario/${id}`,
		getAssociates: (id) => `${API}/tipoArchivoAdjunto/getTipoUsuarioByidTipoArchivo2/${id}`
	},
	UploadFiles: {
		save: `${API_LOCAL}/utilidades/saveArchivo`,
		download: `${API_LOCAL}/utilidades/downloadArchivo`,
		findArchivosById: (OBJ) =>
			`${API_LOCAL}/utilidades/findArchivosById/${OBJ.idOrigen}/${OBJ.idTipoOrigenArchivo}`,
		delete: `${API_LOCAL}/utilidades/deleteArchivo`
	},
	reports: {
		getReportsByService: (id) => `${API_LOCAL}/serviciosReporte/getAllByIdServicio/${id}`,
		getAllTypes: `${API_LOCAL}/serviciosReporte/getAllTipoReporte`,
		create: `${API_LOCAL}/serviciosReporte/save`,
		delete: (id) => `${API_LOCAL}/serviciosReporte/delete/${id}`
	},
	mapTech: {
		findAllTechs: `${API_LOCAL}/geolocalizacion/findAllTecnicos`,
		findSomeTechs: `${API_LOCAL}/geolocalizacion/findAllByIdTecnicos`
	},
	notificationsTable: {
		getAllNotificationsEmployee: (OBJ) =>
			`${API_NOTIFICATIONS}/notificaciones/getAllNotificacionesByAsesorEmpleado/${OBJ.idAsesor}/${OBJ.page}/${OBJ.rowsPerPage}/${OBJ.type}`,
		getAllNotificationsUser: (OBJ) =>
			`${API_NOTIFICATIONS}/notificaciones/getAllNotificacionesPanelByUsuario/${OBJ.idUser}/${OBJ.currentPage}/${OBJ.registersPerPage}`,
		getAllNotificationsUserIcon: (OBJ) =>
			`${API_NOTIFICATIONS}/notificaciones/getAllNotificacionesByUsuario/${OBJ.idUser}/${OBJ.currentPage}/${OBJ.registersPerPage}`,
		getAllAlertsUser: (OBJ) =>
			`${API_NOTIFICATIONS}/notificaciones/getAllAlertasPanelByUsuario/${OBJ.idUser}/${OBJ.currentPage}/${OBJ.registersPerPage}`,
		getAllAlertsUserIcon: (OBJ) =>
			`${API_NOTIFICATIONS}/notificaciones/getAllAlertasByUsuario/${OBJ.idUser}/${OBJ.currentPage}/${OBJ.registersPerPage}`,

		getCountNotificationsPanel: (OBJ) =>
			`${API_NOTIFICATIONS}/notificaciones/getCountNotificacionesPanelByUsuario/${OBJ.idUser}`,
		getCountNotificationsIcon: (OBJ) =>
			`${API_NOTIFICATIONS}/notificaciones/getCountNotificacionesByUsuario/${OBJ.idUser}`,
		getCountAlertsPanel: (OBJ) =>
			`${API_NOTIFICATIONS}/notificaciones/getCountAlertasPanelByUsuario/${OBJ.idUser}`,
		getCountAlertIcon: (OBJ) =>
			`${API_NOTIFICATIONS}/notificaciones/getCountAlertasByUsuario/${OBJ.idUser}`,
		markAsRead: (id) => `${API_NOTIFICATIONS}/notificaciones/marcarNotificacionLeida/${id}`,
		getTiempoNotificacion: () => `${API_NOTIFICATIONS}/notificaciones/getTiempoNotificacion`,

		markAllAsRead: (idUser) =>
			`${API_NOTIFICATIONS}/notificaciones/marcarAllNotificacionesLeidasByUsuario/${idUser}`,
		markAllAlertsAsRead: (idUser) =>
			`${API_NOTIFICATIONS}/notificaciones/marcarAllAlertaLeidasByUsuario/${idUser}`,
		getTimeToAutoRefresh: `${API_NOTIFICATIONS}/notificaciones/getTiempoRefresh`,
		findNotificationPlus: `${API_NOTIFICATIONS}/notification/findNotificationPlus`,
		getAllTypeNotification: `${API_NOTIFICATIONS}/notificaciones/getTipoNotificacion`,
		getAllAlertByUserOptional: (OBJ) =>
			`${API_NOTIFICATIONS}/notificaciones/getAllAlertasPanelByUsuarioOpcional/${OBJ.idUser}/${OBJ.currentPage}/${OBJ.registersPerPage}`
	}
};

export default endpoints;
