import Cookie from 'js-cookie';

export const parseDataToSubmit = (values, formatterText) => {
	const dataToSend = {
		usuarioCreacion: Cookie.get('idUsuario'),
		...values,
		descripcion: values.description?.length
			? values.description
			: formatterText('alert.title.no.description.added'),
		estado: Number(values.active),
		nombre: values.nombre.trim()
	};

	return dataToSend;
};
