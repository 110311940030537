const sortFunction = (newArray, type) => {
	let sortedArray;
	if (type === 'asc') {
		// sort new array by nombre asc
		sortedArray = newArray.sort((a, b) => {
			if (a.nombre) {
				if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) {
					return -1;
				}
				if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) {
					return 1;
				}
			}
			if (a.nombreRazonSocial) {
				if (a.nombreRazonSocial.toLowerCase() < b.nombreRazonSocial.toLowerCase()) {
					return -1;
				}
				if (a.nombreRazonSocial.toLowerCase() > b.nombreRazonSocial.toLowerCase()) {
					return 1;
				}
			}
			return 0;
		});
	} else if (type === 'desc') {
		// sort new array by nombre desc
		sortedArray = newArray.sort((a, b) => {
			if (a.nombre) {
				if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) {
					return -1;
				}
				if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) {
					return 1;
				}
			}
			if (a.nombreRazonSocial) {
				if (a.nombreRazonSocial.toLowerCase() > b.nombreRazonSocial.toLowerCase()) {
					return -1;
				}
				if (a.nombreRazonSocial.toLowerCase() < b.nombreRazonSocial.toLowerCase()) {
					return 1;
				}
			}
			return 0;
		});
	} else if (type === 'ActivitySpecial') {
		//order first by categoriaAsociada, then by tipoServicios and finally by orden on "nombre"
		sortedArray = newArray.sort((a, b) => {
			if (a.categoriaAsociada < b.categoriaAsociada) {
				return -1;
			}
			if (a.categoriaAsociada > b.categoriaAsociada) {
				return 1;
			}
			if (a.categoriaAsociada === b.categoriaAsociada) {
				if (a.tipoServicios < b.tipoServicios) {
					return -1;
				}
				if (a.tipoServicios > b.tipoServicios) {
					return 1;
				}
				if (a.tipoServicios === b.tipoServicios) {
					if (parseInt(a.objeto.orden) < parseInt(b.objeto.orden)) {
						return -1;
					}
					if (parseInt(a.objeto.orden) > parseInt(b.objeto.orden)) {
						return 1;
					}
				}
			}
			return 0;
		});
	}
	return sortedArray;
};

export default sortFunction;
