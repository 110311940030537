import Cookie from 'js-cookie';

class ClientType {
	constructor(
		idTipoCliente = null,
		nombre = '',
		codigo = '',
		descripcion = '',
		estado = 1,
		fechaCreacion = '',
		fechaModificacion = '',
		usuarioCreacion = Cookie.get('idUsuario'),
		usuarioModificacion = Cookie.get('idUsuario')
	) {
		this.idTipoCliente = idTipoCliente;
		this.nombre = nombre;
		this.codigo = codigo;
		this.descripcion = descripcion;
		this.estado = estado;
		this.fechaCreacion = fechaCreacion;
		this.fechaModificacion = fechaModificacion;
		this.usuarioCreacion = usuarioCreacion;
		this.usuarioModificacion = usuarioModificacion;
	}
}

export default ClientType;
