import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';
import { useStorage } from 'hooks/useStorage';
import { isEmpty, isString } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import endpoints from 'services/api';
import { getRandomColor } from '../utils/string';
import useProgress from 'hooks/useProgress';

export default function GanttServiceViewModel() {
	const [calendarServices, setCalendarServices] = useState([]);
	const [tecnicos, setTecnicos] = useState(null);
	const { getItem } = useStorage();
	const userData = getItem('userData');
	const [filters, setFilters] = useState({
		technicianName: null,
		cargo: null
	});

	const [hasFiltered, setHasFiltered] = useState(false);
	const [technicianName, setTechnicianName] = useState(null);
	const [rangoFechaServicioInicial, setRangoFechaCitaInicio] = useState(new Date(''));
	const [rangoFechaServicioFinal, setRangoFechaServicioFinal] = useState(new Date(''));
	const [cargos, setCargos] = useState(null);
	const [filteredServices, setFilteredServices] = useState([]);
	const [week, setWeek] = useState([]);
	const { formatterText } = useLangv2();
	const { loadingProgress, setLoadingProgress, DisplayFullProgress } = useProgress(true);


	// Manage axios requests
	const { fetchData } = useAxios();

	const clientId = useMemo(
		() =>
			!userData || !userData?.idAsesorCliente || !userData?.idAsesorCliente?.idAsesor
				? null
				: userData.idAsesorCliente.idAsesor,
		[userData]
	);

	const employeeId = useMemo(
		() =>
			!userData || !userData?.idEmpleado || !userData?.idEmpleado?.idEmpleado
				? null
				: userData.idEmpleado.idEmpleado,
		[userData]
	);

	const startDate = useMemo(
		() =>
			rangoFechaServicioInicial === ''
				? moment()
				: moment(rangoFechaServicioInicial.rangoFechaServicioInicial),
		[rangoFechaServicioInicial]
	);

	const endDate = useMemo(
		() =>
			rangoFechaServicioFinal === '' ||
			rangoFechaServicioFinal === null ||
			rangoFechaServicioFinal === undefined
				? null
				: moment(rangoFechaServicioFinal.rangoFechaServicioFinal),
		[rangoFechaServicioFinal]
	);

	const jobId = useMemo(
		() => (!filters || !filters?.cargo || !filters?.cargo?.id ? null : filters.cargo.id),
		[filters]
	);

	const serviceRequestBody = useMemo(
		() => ({
			idAsesorCliente: clientId,
			idEmpleado: employeeId,
			fechaInicio: !startDate ? new Date() : startDate.format('YYYY-MM-DD'),
			fechaFin: !endDate ? startDate.format('YYYY-MM-DD') : endDate.format('YYYY-MM-DD'),
			nombreTecnico: technicianName?.label.split(' ').slice(0, -1).join(' ').trim(),
			idCargo: jobId
		}),
		[clientId, employeeId, startDate, endDate, technicianName, jobId]
	);

	const serviceRequestBodyToWeek = useMemo(
		() => ({
			idAsesorCliente: clientId,
			idEmpleado: employeeId,
			fechaInicio: !startDate ? new Date() : startDate.format('YYYY-MM-DD'),
			fechaFin: null,
			nombreTecnico: technicianName?.label.split(' ').slice(0, -1).join(' ').trim(),
			idCargo: jobId
		}),
		[clientId, employeeId, startDate, endDate, technicianName, jobId]
	);

	const handleButtonRefresh = async () => {
		serviceRequestBody.fechaInicio = moment(new Date()).format('YYYY-MM-DD');
		serviceRequestBody.fechaFin = moment(new Date()).format('YYYY-MM-DD');
		serviceRequestBody.nombreTecnico = null;
		setTechnicianName(null);
		serviceRequestBody.idCargo = null;
		setRangoFechaServicioFinal('');
		setRangoFechaCitaInicio('');
		setFilters({
			technicianName: null,
			cargo: null
		});
		await Promise.all([getServices()]);
		setHasFiltered(false);
	};

	const handleButtonFilter = async () => {
		setLoadingProgress(true);
		await Promise.all([loadData()]);
		setHasFiltered(true);
	};

	const getJobs = useCallback(async () => {
		const jobs = await fetchData({ url: endpoints.cargos.getAllCargos });
		const formattedJobs = jobs.response.map(({ idCargo, nombre }) => ({
			id: idCargo,
			label: nombre
		}));

		setCargos(formattedJobs);
	}, []);

	const formatServiceData = (services) => {
		return services.flatMap(({ idTecnico, nombre, cargo, servicios }) => {
			return servicios.map((servicio) => {
				const durationService = moment(servicio.fechaFin).diff(
					moment(servicio.fechaInicio),
					'minutes'
				);
				const timeExecution = moment.duration(servicio.tiempoEjecucion).asMinutes();
				const percentage = (timeExecution / durationService) * 100;
				const formattedService = {
					idOnly: `${idTecnico}-${servicio.idServicio}`,
					id: idTecnico,
					technician_name: `${nombre}`,
					technician_id: idTecnico,
					technician_charge: cargo,
					technician_picture: null,
					ticket: servicio.ticket,
					expediente: servicio.expediente,
					percentage: percentage,
					serviceId: servicio.idServicio,
					notifications: servicio.notificaciones,
					service_type: servicio.tipoServicio,
					service_status: servicio.estadoServicio,
					service_date_start: moment.utc(servicio.fechaInicio, 'YYYY-MM-DD h:mm').local(),
					service_date_end: moment.utc(servicio.fechaFin, 'YYYY-MM-DD h:mm').local(),
					service_time: servicio.horaInicio,
					service_duration: durationService,
					service_real_duration: servicio.tiempoEjecucion
				};

				return formattedService;
			});
		});
	};

	const loadData = async () => {
		try {
			const reqBody = serviceRequestBody;
			const calendarServicePromise = await fetchData({
				url: endpoints.services.getCalendarServices,
				method: 'post',
				body: reqBody
			});
			const techs = calendarServicePromise.response.map(({ idTecnico, nombre }) => ({
				id: idTecnico,
				label: `${nombre}`
			}));
			setTecnicos(techs);

			const Formattedtechn = formatServiceData(calendarServicePromise.response);
			setCalendarServices(Formattedtechn);

			setWeek(Formattedtechn);
			setTimeout(() => {
				setLoadingProgress(false);
			}, 2000);
		} catch (error) {
			console.log('Error: ', error);
		}
	};

	const getCargos = async () => { 
		const cargosPromise = await fetchData({
			url: endpoints.cargos.getAllCargos,
			method: 'get'
		});

		setCargos(
			cargosPromise.response.map((cargo) => ({
				id: cargo.idCargo,
				label: cargo.nombre
			}))
		);
	 };

	const getServices = useCallback(async () => {
		const services = await fetchData({
			url: endpoints.services.getFirstCalendarServices,
			method: 'post',
			body: serviceRequestBody
		});

		if (isString(services.response)) {
			setCalendarServices([]);
		}

		const techs = services.response.map(({ idTecnico, nombre }) => ({
			id: idTecnico,
			label: `${nombre}`
		}));
		setTecnicos(techs);

		const Formattedtechn = formatServiceData(services.response);

		setCalendarServices(Formattedtechn);

		setLoadingProgress(false);
	}, [serviceRequestBody]);

	const getServicesWeek = async () => {
		const services = await fetchData({
			url: endpoints.services.getFirstCalendarServices,
			method: 'post',
			body: serviceRequestBodyToWeek
		});

		const Formattedtechn = formatServiceData(services.response);
		setWeek(Formattedtechn);
	};

	useEffect(() => {
		(async () => {
			await getCargos();
			await getJobs();
			await getServicesWeek();
			await getServices();
		})();
	}, []);

	const getDayPropStart = () => startDate.toDate();
	const getDayPropEnd = () => (endDate ? endDate.toDate() : startDate.toDate());

	useEffect(() => {
		const filteredResults = calendarServices.filter(
			({ technician_name, technician_charge, servicios }) => {
				let matches = true;

				if (!isEmpty(filters.technicianName)) {
					matches = matches && new RegExp(filters.technicianName.label, 'gi').test(technician_name);
				}
				if (!isEmpty(filters.cargo) && !isEmpty(filters.cargo.label)) {
					matches = matches && new RegExp(filters.cargo.label, 'gi').test(technician_charge);
				}

				if (
					!isEmpty(rangoFechaServicioInicial) &&
					!isEmpty(rangoFechaServicioInicial.rangoFechaServicioInicial)
				) {
					matches =
						matches &&
						servicios.some(() => {
							const compareFrom = moment(rangoFechaServicioInicial);

							const results = results.filter(({ servicios }) =>
								servicios.some((servicio) => {
									const [hours = 0, minutes = 0, , seconds = 0] = (servicio?.horaInicio || '')
										.split(':')
										.map(Number);

									const compareTo = moment(servicio.fechaInicio).set({
										hours,
										minutes,
										seconds
									});
									return compareFrom.isSame(compareTo);
								})
							);
						});
				}

				if (
					!isEmpty(rangoFechaServicioFinal) &&
					!isEmpty(rangoFechaServicioFinal.rangoFechaServicioFinal)
				) {
					matches =
						matches &&
						servicios.some(() => {
							const compareFrom = moment(rangoFechaServicioFinal.rangoFechaServicioFinal);

							const results = results.filter(({ servicios }) =>
								servicios.some((servicio) => {
									const [hours = 0, minutes = 0, , seconds = 0] = (servicio?.horaFin || '')
										.split(':')
										.map(Number);

									const compareTo = moment(servicio.fechaFin).set({
										hours,
										minutes,
										seconds
									});
									return compareFrom.isSame(compareTo);
								})
							);
						});
				}
				return matches;
			}).map((service) => ({ ...service, color: getRandomColor() }));

		const arrayGroupByTechnician = filteredResults.reduce(function(map, obj) {
			const technician = map[obj.technician_id] = map[obj.technician_id] || {
				technician_name: obj.technician_name,
				technician_id: obj.technician_id,
				technician_charge: obj.technician_charge,
				technician_picture: obj.technician_picture,
				serviceId: obj.serviceId,
				notifications: obj.notifications,
				services: []
			};
			technician['services'] = [...technician['services'], {...obj, color: getRandomColor()}];
			return map;
		}, Object.create(null));

		const arrayResult = Object.keys(arrayGroupByTechnician).map((key) => {
			return arrayGroupByTechnician[key];
		});

		setFilteredServices(arrayResult);
	}, [calendarServices, filters, rangoFechaServicioFinal, rangoFechaServicioInicial]);

	useEffect(() => {
		let intervalId;

		if (!hasFiltered) {
			intervalId = setInterval(getServices, 60000);
		} else {
			intervalId = setInterval(loadData, 60000);
		}

		return () => {
			clearInterval(intervalId);
		};
	}, [hasFiltered]);

	return {
		cargos,
		filters,
		rangoFechaServicioInicial,
		rangoFechaServicioFinal,
		setFilters,
		setRangoFechaCitaInicio,
		setRangoFechaServicioFinal,
		setTechnicianName,
		handleButtonFilter,
		handleButtonRefresh,
		getDayPropStart,
		getDayPropEnd,
		tecnicos,
		week,
		filteredServices,
		formatterText,
		loadingProgress,
		DisplayFullProgress
	};
}
