import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';

export default function usePermission() {
	const [permissions, setPermissions] = useState([]);
	const { COOKIE_USER, endpoints, RequestUseCase } = useAxios();

	// Obtiene todos los permisos al cargar la pagina
	const _allPermission = () => {
		RequestUseCase.get(endpoints.roles.getAllRolPermisosModulosByUser(COOKIE_USER)).then((resp) => {
			setPermissions(resp);
		});
	};

	// Filtra los permisos correspondientes a cada modulo
	const permissionPerModule = (module) => {
		const filterModule = permissions.filter((item) => item.idModulo === module);
		return filterModule;
	};

	// permissions access
	useEffect(() => {
		const currentURL = window.location.href;
		if (!currentURL.endsWith('/#/login')) {
			_allPermission();
		}
	}, []);

	return { permissions, permissionPerModule, _allPermission };
}
