import useAxios from 'hooks/useAxios';
import { useEffect } from 'react';
// Import Context
import { useSeachContext } from 'context/SearchContext';
// Import Hooks
import useGetData from 'hooks/useGetData';
// Import Components
// Import libs
import SortData from 'components/utils/SortData';
// Import services
import endpoints from 'services/api';

export default function ActivityTableViewModel() {
	const { RequestUseCase } = useAxios();

	// hook to loading data
	const {
		loading,
		error,
		toggleLoading,
		toggleError,
		handleClick,
		displayMessage,
		displayLoading
	} = useGetData();

	const { setDataTable } = useSeachContext();

	const handleDeleteItem = (rowId) => {
		const deleteItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.activitiesServices.deleteActivitiesService(rowId))
				.then((res) => {
					const newArray = [];
					res.forEach((item) => {
						handleStructureItems(newArray, item);
					});
					SortData(newArray, 'ActivitySpecial');
					setDataTable(newArray);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return deleteItemPromise;
	};

	const handleEditStateItem = (body) => {
		const updateItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.activitiesServices.updateActivitiesService, body)
				.then((res) => {
					console.log(res);
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return updateItemPromise;
	};

	const handleStructureItems = (newArray, item) => {
		newArray.push({
			id: item.idActividad,
			categoriaAsociada: item.categoriaAsociada,
			tipoServicios: item.tipoServicios,
			etapaServicios: item.etapaServicios,
			nombre: item.nombre,
			descripcion: item.descripcion,
			sku: item.sku,
			/*    iva: item.iva ? item.iva : "", */
			tiempoDesarrollo: item.tiempoDesarrollo,
			actividadTecnica: item.actividadTecnica === 1 ? 'Si' : 'No',
			estado: item.estado,
			objeto: { ...item }
		});
	};

	// función para cargar datos
	const getDataTable = () => {
		RequestUseCase.get(endpoints.activitiesServices.getAllActivitiesServices)
			.then((data) => {
				const newArray = [];
				data.forEach((item) => {
					handleStructureItems(newArray, item);
				});
				// const sortedArray = SortData(newArray, 'ActivitySpecial');
				setDataTable(newArray);
			})
			.catch((err) => {
				console.log(err);
				// mostrar error
				toggleError(!error);
				handleClick();
			});
	};

	useEffect(() => {
		toggleLoading(true);
		getDataTable();
		toggleLoading(false);
	}, []);

	return {
		loading,
		error,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		displayMessage,
		displayLoading
	};
}
