import React, { useContext, useEffect, useState } from 'react';
// import Contexts
import { AppContext } from 'context/AppContext';
// import Libs
import dateFormat from 'dateformat';
import propTypes from 'prop-types';
// import Services
import { formatterText } from 'hooks/useLangv2';
import paths from 'services/paths';
export default function ModalConfirm({ onClose, clickFun, dataToAssign, loadingTech }) {
	// validate to show date
	const [validateAssign, setValidateAssign] = useState(false);
	// date state
	const [formData, setFormData] = useState([]);
	// context data
	const { servicesData, setServicesData } = useContext(AppContext);
	// setter function
	const handleDate = (e) => {
		setFormData({ ...formData, [e.target.name]: new Date(e.target.value) });
		const HOURS = JSON.parse(localStorage.getItem('hours'));
		const CURRENT_HOUR = dateFormat(e.target.value, 'HH:MM');
		const CURRENT_DATE = dateFormat(e.target.value, 'yyyy-mm-dd');
		// find the valor of the hour with the key hora
		const findValorHoraInicial = HOURS.find((item) => item.hora === CURRENT_HOUR);
		// seet the new date in the state
		setServicesData({
			...servicesData,
			fechaCita: CURRENT_DATE,
			idHoraCita: findValorHoraInicial.idHora
		});
	};

	console.log(servicesData);
	// set the min range of datetime
	const [minDateTime, setMinDateTime] = useState('');
	// set the max range of datetime
	const [maxDateTime, setMaxDateTime] = useState('');

	// every time this component is render it will check if the url has the path to assign a tech
	useEffect(() => {
		// verify if the url has the path to assign a tech
		const isLocate = new URL(window.location.href).hash.includes(paths.auxTech);

		// validate if the url has the path to assign a tech and set the state to show the date
		if (isLocate) {
			const hourStart = servicesData.dataStartMeet.hora;
			const hourEnd = servicesData.dataEndMeet.hora;

			const startDate = servicesData.dateStartDate.split('T')[0];
			const endDate = servicesData.dateEndDate.split('T')[0];

			setMinDateTime(`${startDate}T${hourStart}`);

			setMaxDateTime(`${endDate}T${hourEnd}`);

			setValidateAssign(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataToAssign]);

	return (
		<>
			<label className="center-container-modal">
				<span
					style={{
						textAlign: 'center'
					}}
					className="title-table"
				>
					{formatterText('title.service.search.manual.modal.assignServiceToTechnician')}
				</span>
			</label>
			{validateAssign && (
				<>
					<p>{formatterText('title.service.search.manual.modal.currentAppointmentDate')}</p>
					<p>
						{servicesData.dateStartDate.split('T')[0]} {servicesData.dataStartMeet.hora}
					</p>
					<input
						className="input-primary-wrap"
						name="fechaFin"
						label="Fecha de Servicio"
						type="dateTime-local"
						min={minDateTime}
						max={maxDateTime}
						onChange={handleDate}
						required
					/>
				</>
			)}
			<section className="form-responsive-container-buttons">
				<button
					disabled={loadingTech}
					className="btn-action-primary"
					onClick={(e) => {
						e.preventDefault();
						clickFun(dataToAssign);
					}}
				>
					{formatterText('btn.confirm')}
				</button>
				<button className="btn-action-cancel" onClick={onClose}>
					{formatterText('btn.cancel')}
				</button>
			</section>
		</>
	);
}

ModalConfirm.propTypes = {
	onClose: propTypes.func,
	clickFun: propTypes.func,
	dataToAssign: propTypes.object
};
