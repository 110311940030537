import React, { useEffect, useState } from 'react';
// Import Hooks
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import Hooks
import WarningDialog from 'components/tableOptions/WarningDialog';
import useLangv2 from 'hooks/useLangv2';
import 'styles/minimalTable.css';
import TableBranchOffice from './TableBranchOffice';
import TableSubCategories from './TableSubCategories';

import TableAdviser from './TableAdviser';

import TableAssociationActivities from 'common/minimalTables/serviceModule/TableAssociationActivities';
import { useSeachContext } from 'context/SearchContext';
import TableAssociatePrices from './TableAssociatePrices';
import TableBusinessByClient from './TableBusinessByClient';
import TableCostProductProvider from './TableCostProductProvider';

export default function Table({
	titles,
	data,
	type,
	max,
	handleOpen,
	canSearch = false,
	isEdit = false,
	showTH = false,
	getAsesors,
	getAsesorsMap,
	getSucursales,
	getSucursalesMap,
	handleDelete
}) {
	const { handleSearch, searchResults = [] } = useSeachContext();
	// use Hook of language v2
	const { formatterText } = useLangv2();
	const [dialog, setDialog] = useState({
		text: '',
		active: false,
		function: null
	});
	const searchRef = React.useRef(null);
	React.useContext(TableMinimalContext);

	const [loading] = useState(false);

	const closeDialog = () => {
		setDialog({ ...dialog, active: false });
	};

	useEffect(() => {
		if (showTH) {
			let min = 0;
			data.forEach((item) => {
				min += parseInt(item.tiempoActividad);
			});
			// set the counter
			setCounterActivities(min);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	// manage the counter
	const [counterActivities, setCounterActivities] = useState(0);

	const convertMinutesToHours = (minutes) => {
		const hours = Math.floor(minutes / 60);
		const minutesLeft = minutes % 60;
		return `${hours} ${formatterText('column.name.hours')} ${minutesLeft} ${formatterText(
			'column.name.minutes'
		)}`;
	};

	// Seccion de los modelos de datos
	const ChooseTableData = () => {
		switch (type) {
			case 'sucursales':
				return (
					<TableBranchOffice
						data={data}
						setDialog={setDialog}
						loading={loading}
						closeDialog={closeDialog}
						handleOpen={handleOpen}
						isEdit={isEdit}
						getSucursales={getSucursales}
						getSucursalesMap={getSucursalesMap}
					/>
				);
			case 'asesores':
				return (
					<TableAdviser
						data={data}
						setDialog={setDialog}
						loading={loading}
						closeDialog={closeDialog}
						handleOpen={handleOpen}
						getAsesors={getAsesors}
						getAsesorsMap={getAsesorsMap}
					/>
				);
			case 'subCategorias':
				return (
					<TableSubCategories
						data={searchResults}
						setDialog={setDialog}
						loading={loading}
						closeDialog={closeDialog}
						handleOpen={handleOpen}
					/>
				);
			case 'costoProductoProveedor':
				return (
					<TableCostProductProvider
						data={data}
						setDialog={setDialog}
						loading={loading}
						closeDialog={closeDialog}
						handleOpen={handleOpen}
					/>
				);
			case 'associatePrices':
				return (
					<TableAssociatePrices
						data={data}
						setDialog={setDialog}
						loading={loading}
						closeDialog={closeDialog}
						handleOpen={handleOpen}
					/>
				);
			case 'associationActivities':
				return (
					<TableAssociationActivities
						data={data}
						setDialog={setDialog}
						loading={loading}
						closeDialog={closeDialog}
						handleOpen={handleOpen}
					/>
				);
			case 'businessByClient':
				return (
					<TableBusinessByClient
						data={data}
						setDialog={setDialog}
						loading={loading}
						closeDialog={closeDialog}
						handleOpen={handleOpen}
						handleDelete={handleDelete}
					/>
				);
			default:
				console.log('No se encontro el tipo de tabla');
				break;
		}
	};

	return (
		<>
			<div className={max ? 'table-minimal-container w100-container' : 'table-minimal-container'}>
				{canSearch && (
					<div style={{ width: '50%' }} className="wrap">
						<div className="search">
							<input
								type="text"
								className="searchTerm spacing-b1"
								placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
								ref={searchRef}
								onChange={handleSearch}
							/>
							<button
								type="button"
								onClick={() => handleSearch({ target: searchRef.current })}
								className="searchButton"
							>
								<img
									src={require('../../assets/search.png')}
									style={{ height: '20px' }}
									alt="search"
								/>
							</button>
						</div>
					</div>
				)}
				<table className="table-minimal">
					<thead>
						<tr className="infoo">
							{titles.map((title, index) => (
								<th scope="col" key={`${title}_${index + 1}`}>
									{title}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						<ChooseTableData />
					</tbody>
				</table>
			</div>
			{showTH && (
				<h4 className="p-styles primary-green spacing-l1">
					{formatterText('column.name.total.activities')} : {counterActivities} (
					{formatterText('column.name.minutes')}) - {formatterText('column.name.hours')}:{' '}
					{convertMinutesToHours(counterActivities)}
				</h4>
			)}
			<WarningDialog dialog={dialog} setDialog={setDialog} />
		</>
	);
}
