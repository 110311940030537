import { AppContext } from 'context/AppContext';
import useAxios from 'hooks/useAxios';
import useLangv2, { formatterText } from 'hooks/useLangv2';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import endpoints from 'services/api';
import paths from 'services/paths';
import 'styles/colorPicker.css';
import hours from '../hours';
import hoursWithValue from '../hoursWithValue';

export default function SearchPrincipalTechnicalViewModel() {
	// Manage axios requests
	const { COOKIE_USER, RequestUseCase } = useAxios();
	// useLanguage
	const { updatedItem, errorProcess, customSB, handleRequestError } = useLangv2();
	const titlesTableCostProduct = [
		formatterText('title.service.search.manual.table.techniciansForService'),
		formatterText('title.service.search.manual.table.startOfPreviousService'),
		formatterText('title.service.search.manual.table.riskOfNonCompliancePreviousService'),
		formatterText('title.service.search.manual.table.riskOfNonComplianceNewService'),
		formatterText('title.service.search.manual.table.details'),
		formatterText('title.service.search.manual.table.actions')
	];

	const { id } = useParams();
	const navigate = useNavigate();
	// SETEOS DE STATES Y CONTEXT
	const { selectNotificacion: context } = useContext(AppContext);
	const [selectNotificacion, setSelectNotificacion] = useState(context);
	const [technicians, setTechnicians] = useState([]);
	const [technicians2, setTechnicians2] = useState([]);
	const [technicians3, setTechnicians3] = useState([]);
	const [open, setOpen] = useState(false);
	const [selectTech, setSelectTech] = useState(false);
	const [seeDetails, setSeeDetails] = useState(false);
	const [techToAssign, setTechToAssign] = useState({});
	const [currentDetail, setCurrentDetail] = useState({});

	const handleClose = (fun) => {
		fun(false);
	};

	// GetAll Technicals by id Services
	useEffect(() => {
		getAllServicesByid();
		setTimeout(() => {
			getManualtechnicalData();
		}, 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setSelectNotificacion(context);
	}, [context]);

	// Get All service info
	const getAllServicesByid = () => {
		const idService = parseInt(localStorage.getItem('idService'));
		RequestUseCase.get(endpoints.services.getServiceById(idService))
			.then((res) => {
				const dataJson = res.servicio;

				// set data
				setSelectNotificacion({
					canalComunicacion: dataJson.canalComunicacion,
					categoriaServicio: dataJson.idTipoServicio.nombre,
					celularGenerador: '234234',
					ciudadServicio: 'Popayan',
					descripcion: 'some description',
					direccion: 's',
					estado: 1,
					estadoServicio: dataJson.idEstadoServicio.nombre,
					expediente: dataJson.expediente,
					fechaCita: dataJson.fechaCita.split('T')[0],
					fechaCitaFin: dataJson.fechaCitaFin.split('T')[0],
					fechaCreacion: dataJson.fechaCreacion,
					fechaModificacion: dataJson.fechaModificacion,
					horaCita: dataJson.idHoraCita.hora,
					horaCitaFin: dataJson.idHoraCitaFin.hora,
					idCategoriaServicio: dataJson.idTipoServicio.idCategoriaServicio.idCategoriaServicio,
					idDireccion: 34,
					// idGeneradorCliente: 17,
					idGeneradorUsuario: null,
					idServicio: idService,
					idTipoServicio: dataJson.idTipoServicio.idTipoServicio,
					// idUnidadNegocio: dataJson.idGeneradorCliente.idUnidadNegocio,
					idUsuario: dataJson.idUsuario ? dataJson.idUsuario.idUsuarioServicio : null,
					idHoraCita: dataJson.idHoraCita.idHora,
					idHoraCitaFin: dataJson.idHoraCitaFin.idHora,
					medioComunicacion: 'medio 1',
					nombreGenerador: 'asdas',
					nombreUsuario: 'nomas  genial null',
					tecnicoAsignado: null,
					telefonoGenerador: '234325',
					telefonoUsuario: '312',
					ticket: dataJson.ticket,
					tiempoTranscurrido: 'Servicio NO tomado hace: 12 Días, 17 Horas, 27 Minutos',
					tipoServicio: dataJson.idTipoServicio.nombre,
					usuarioCreacion: 49,
					usuarioModificacion: null,
					zonaServicio: 'Luis SAN'
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// get All Manual Technical Data
	const getManualtechnicalData = () => {
		const idService = parseInt(localStorage.getItem('idService'));
		const hashTechnicians = {
			0: (data) => setTechnicians(data),
			1: (data) => setTechnicians2(data),
			2: (data) => setTechnicians3(data)
		};
		RequestUseCase.get(endpoints.services.getManualSearchTechnicals(idService))
			.then((res) => {
				res.forEach((element, index) => {
					hashTechnicians[index](element);
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const consultaStart = () => {
		setTechnicians([]);
		setTechnicians2([]);
		setTechnicians3([]);
		getManualtechnicalData();
	};

	const clickOnRow = (tech) => {
		setTechToAssign(tech.idTecnico);
		setSelectTech(true);
	};

	// Assign technical to the service
	const clickFun = (dataToAssign) => {
		const formatData = {
			idServicioTecnico: null,
			idTecnico: {
				idTecnico: dataToAssign
			},
			idServicio: {
				idServicio: id
			},
			principal: 1
		};

		// Get id of the services
		RequestUseCase.get(endpoints.services.getServiceById(id))
			.then(() => {
				selectServiceToTech(formatData);
			})
			.catch((error) => {
				console.log('error of get service info: ', error);
			});

		// update service
	};

	const selectServiceToTech = (data) => {
		const idService = parseInt(localStorage.getItem('idService'));
		const formatData = {
			idServicioTecnico: null,
			idServicio: {
				idServicio: idService
			},
			idTecnico: {
				idTecnico: data.idTecnico.idTecnico
			},
			estado: 1,
			principal: 1,
			asignaAsesor: 1,
			fechaInicio: `${selectNotificacion.fechaCita}T00:00:00.000+00:00`,
			fechaFin: `${selectNotificacion.fechaCitaFin}T00:00:00.000+00:00`,
			idHoraInicio: {
				idHora: selectNotificacion.idHoraCita
			},
			idHoraFin: {
				idHora: selectNotificacion.idHoraCitaFin
			},
			usuarioCreacion: 1
		};

		RequestUseCase.post(endpoints.services.assignService, formatData)
			.then(() => {
				setSelectTech(false);
				navigate(paths.updateService.replace(':id', idService));
			})
			.catch((error) => {
				console.error('Error:', error);
				handleRequestError(error);
			});
	};

	//Un estilo centrado de color verde
	const styleCenter = {
		textAlign: 'center',
		color: '#0AA48A',
		fontSize: '20px',
		fontWeight: 'bold',
		marginTop: '20px',
		//centrar el div
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: '25px'
	};

	//Un estilo a la izquierda de color verde
	const styleLeft = {
		textAlign: 'left',
		color: '#0AA48A',
		fontSize: '20px',
		fontWeight: 'bold',
		marginLeft: '20px',
		marginRight: '20px',
		//centrar el div
		display: 'flex',
		justifyContent: 'left',
		alignItems: 'left'
	};

	//wrap de dos items en linea
	const styleWrap = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		marginBottom: '20px',
		width: '100%'
	};

	const verifyTechAvailable = (FC, HC) => {
		// Get id of the services
		RequestUseCase.get(endpoints.services.getServiceById(id))
			.then((serviceInfo) => {
				let data = {};
				const serviceData = serviceInfo.servicio;
				const clientSelected = serviceData.idGeneradorCliente;
				const userSelected = serviceData.idGeneradorUsuario;
				const horaID = hours.find((hour) => {
					return hour.hora === HC;
				});
				const TYPESERVICE = serviceData.idTipoServicio.idTipoServicio;
				data = {
					idServicio: null,
					fechaCita: `${selectNotificacion.fechaCita}T10:00:00`,
					idHoraCita: {
						idHora: horaID.idHora
					},
					idDireccion: {
						idDireccion: serviceData.idDireccion.idDireccion
					},
					idTipoServicio: {
						idTipoServicio: TYPESERVICE
					},
					descripcion: serviceData.descripcion,
					idMedioComunicacion: {
						idMedioComunicacion: serviceData.idMedioComunicacion.idMedioComunicacion
					},
					idCanalComunicacion: {
						idCanalComunicacion: serviceData.idCanalComunicacion.idCanalComunicacion
					},
					expediente: serviceData.expediente,
					especial: serviceData.especial,
					idEstadoServicio: {
						idEstadoServicio: serviceData.idEstadoServicio.idEstadoServicio
					},
					usuarioCreacion: serviceData.usuarioCreacion,
					usuarioModificacion: COOKIE_USER,
					idServicioPadre: serviceData.idServicioPadre
				};

				// OPTION 1: GUSER where idGeneradorCliente are null
				if (userSelected !== null) {
					// OPTION 2:HAVE USER SYSTEM Too
					if (serviceData.idUsuario !== null) {
						// Select genUser and Externarl User
						data = {
							...data,
							idGeneradorCliente: null,
							idGeneradorUsuario: serviceData.idGeneradorUsuario.idUsuarioServicio,
							idUsuario: null
						};
					} else {
						// Only select gen system user Option 1 only
						data = {
							...data,
							idGeneradorCliente: null,
							idGeneradorUsuario: serviceData.idGeneradorUsuario.idUsuarioServicio,
							idUsuario: null
						};
					}
				}
				// OPTION 3: GCLIENT + GUSER where idGeneradorUsuario is null
				else if (clientSelected !== null) {
					// OPTION 4:HAVE USER SYSTEM Too
					if (serviceData.idUsuario !== null) {
						// Select geClient and Externarl User
						data = {
							...data,
							idGeneradorCliente: serviceData.idGeneradorCliente,
							idGeneradorUsuario: null,
							idUsuario: null
						};
					} else {
						// Option 3: Only GenClient
						data = {
							...data,
							idGeneradorCliente: serviceData.idGeneradorCliente,
							idGeneradorUsuario: null,
							idUsuario: null
						};
					}
				}

				const DATA_DEFINITIVE = {
					...serviceData,
					fechaCita: `${FC}T10:00:00`,
					idHoraCita: {
						idHora: horaID.idHora
					},
					usuarioModificacion: COOKIE_USER
				};

				generateDateEnd(data, serviceData, DATA_DEFINITIVE);
			})
			.catch((error) => {
				console.log('error of get service info: ', error);
			});
	};

	// generate the end date of the service for validate the agenda
	const generateDateEnd = (DATAUPDATE, COMPLETE_DATA, DU) => {
		customSB(
			'warning',
			'snackbar.warning.validate.tech.exist',
			'Se esta validando que el servicio cumpla con los requisitos para ser creado, por favor espere...'
		);

		RequestUseCase.post(endpoints.services.generateEndDate, DATAUPDATE)
			.then((response) => {
				const ADDRESS = COMPLETE_DATA.idDireccion.idDireccion;
				const SECTOR = COMPLETE_DATA.idDireccion.idSector.idSector;
				const MUNICIPALITY = COMPLETE_DATA.idDireccion.idSector.idMunicipio.idMunicipio;
				const TYPE_SERVICE = COMPLETE_DATA.idTipoServicio.idTipoServicio;
				const CATEGORY_SERVICE =
					COMPLETE_DATA.idTipoServicio.idCategoriaServicio.idCategoriaServicio;
				const idHora = hoursWithValue.find(
					(item) => item.idHora === COMPLETE_DATA.idHoraCita.idHora
				);

				const DATA_VALIDATED = {
					...COMPLETE_DATA,
					idDireccion: {
						idDireccion: ADDRESS,
						idSector: {
							idSector: SECTOR,
							idMunicipio: {
								idMunicipio: MUNICIPALITY
							}
						}
					},
					idTipoServicio: {
						idTipoServicio: TYPE_SERVICE,
						idCategoriaServicio: {
							idCategoriaServicio: CATEGORY_SERVICE
						}
					},
					idHoraCita: {
						idHora: COMPLETE_DATA.idHoraCita.idHora,
						valor: idHora.valor
					},
					fechaCitaFin: response.fechaCitaFin,
					idHoraCitaFin: {
						idHora: response.idHoraCitaFin.idHora,
						valor: response.idHoraCitaFin.valor
					}
				};

				validateServiceAgendaEvent(DATA_VALIDATED, DU);
			})
			.catch((E) => {
				console.log(E);
				errorProcess();
			});
	};

	// Validate if the agenda have horary available
	const validateServiceAgendaEvent = (DATA, DU) => {
		RequestUseCase.post(endpoints.services.validateCreateService, DATA)
			.then((response) => {
				if (response) {
					customSB(
						'success',
						'snackbar.success.validate.tech.exist',
						'Se encontró al menos un técnico disponible para este servicio, puede continuar con el proceso.'
					);
					RequestUseCase.put(endpoints.services.updateService, DU)
						.then(() => {
							updatedItem();
							consultaStart();
						})
						.catch((err) => {
							console.log(err);
						});
				} else {
					customSB(
						'error',
						'snackbar.error.validate.tech.exist',
						'No se encontró un técnico disponible para este servicio, seleccione otro horario o fecha.'
					);
				}
			})
			.catch(() => {
				errorProcess();
			});
	};

	const seeModalDetails = (data) => {
		setSeeDetails(true);
		setCurrentDetail(data);
	};
	return {
		styleCenter,
		styleWrap,
		styleLeft,
		selectNotificacion,
		setOpen,
		handleClose,
		technicians,
		technicians2,
		technicians3,
		titlesTableCostProduct,
		verifyTechAvailable,
		setSelectNotificacion,
		techToAssign,
		selectTech,
		setSelectTech,
		clickFun,
		consultaStart,
		clickOnRow,
		seeModalDetails,
		seeDetails,
		setSeeDetails,
		currentDetail,
		open
	};
}
