import React from 'react';
import { PermissionWrapper } from 'context/PermissionContext';
import CreateMailComponent from './CreateMail.component';

const CreateMail = () => (
	<PermissionWrapper>
		<CreateMailComponent />
	</PermissionWrapper>
);

export default CreateMail;
