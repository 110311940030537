import CustomAlert from 'components/CustomAlert';
import { useAuth } from 'hooks/useAuth';
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';
import { useEffect, useState } from 'react';
import 'styles/loginRecover.css';

export default function ChangePasswordViewModel() {
	const auth = useAuth();

	useEffect(() => {
		getUserInfoById();
	}, []);
	// manage axios hook
	const { fetchData, COOKIE_USER, endpoints, RequestUseCase } = useAxios();

	// passwords states
	const [formData, setFormData] = useState({
		currentPassword: '',
		newPassword: '',
		newPasswordConfirm: ''
	});
	const { formatterText } = useLangv2();
	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [passwordCurrentShown, setPasswordCurrentShown] = useState(false);
	const [passwordNewShown, setPasswordNewShown] = useState(false);
	const [passwordConfirmedShown, setPasswordConfirmedShown] = useState(false);
	const togglePasswordCurrentVisiblity = () => setPasswordCurrentShown(!passwordCurrentShown);
	const togglePasswordNewVisibilty = () => setPasswordNewShown(!passwordNewShown);
	const togglePasswordConfirmedVisibilty = () => setPasswordConfirmedShown(!passwordConfirmedShown);
	const restorePassword = (e) => {
		e.preventDefault();
		setErrorMessage(false);
		if (formData.newPassword === formData.newPasswordConfirm) {
			//  create an expression to validate if the password have at least 1 number, at least 1 letter uppercase and at special character
			const regex =
				/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~^()_+[\]{}|\\:;"'<>,./])[\w@$!%*?&~^()_+[\]{}|\\:;"'<>,./]{8,}$/;
			if (regex.test(formData.newPassword)) {
				const Username = JSON.parse(localStorage.getItem('userData')).usuario;

				setIsLoading(true);

				const DATA = {
					Username,
					OldPassword: formData.currentPassword,
					NewPassword: formData.newPasswordConfirm
				};
				fetchData({
					url: endpoints.auth.changePassword,
					method: 'post',
					body: DATA,
					typeConfig: 'form'
				}).then((data) => {
					const { response, loading } = data;
					setIsLoading(loading);
					if (response) {
						CustomAlert('confirm_msg', {
							icon: 'success',
							title: formatterText('title.user.passwordChanged.success'),
							text: formatterText('title.user.passwordChanged'),
							confirmButtonText: formatterText('title.user.passwordstMatch.log.in'),
							allowOutsideClick: false,
							executeFunction: () => auth.logout()
						});
					} else {
						setIsLoading(false);
						CustomAlert('short_msg', {
							icon: 'error',
							title: 'Error',
							text: formatterText('title.user.currentPasswordMismatch')
						});
					}
				});
				setErrorMessage(false);
			} else {
				setErrorMessage(true);
			}
		} else {
			CustomAlert('short_msg', {
				icon: 'error',
				title: 'Error',
				text: formatterText('title.user.passwordsDoNotMatch')
			});
			setIsLoading(false);
		}
	};

	const getUserInfoById = () => {
		RequestUseCase.get(endpoints.users.getUserById(COOKIE_USER)).catch((err) => {
			console.log('Error', err);
		});
	};

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value.replaceAll(/\s{2,}/gi, ' ')
		});
	};
	return {
		handleChange,
		restorePassword,
		isLoading,
		errorMessage,
		passwordCurrentShown,
		passwordNewShown,
		passwordConfirmedShown,
		togglePasswordCurrentVisiblity,
		togglePasswordNewVisibilty,
		togglePasswordConfirmedVisibilty,
		formatterText
	};
}
