import React from 'react';

// Import Components
import { UpsertForm } from '../UpsetRol/components';

// Import ViewModel
import EditRolViewModel from './EditRol.viewModel';

// Import Styles
import '../rol.css';

export default function EditComponet() {
	const {
		displayLoading,
		formatterText,
		formik,
		handleAddRole,
		handleRemoveRole,
		isLoading,
		navigate,
		setToggleSelector,
		toggleSelector
	} = EditRolViewModel();

	if (isLoading) {
		return displayLoading();
	}

	return (
		<UpsertForm
			edit
			formatterText={formatterText}
			formik={formik}
			handleAddRole={handleAddRole}
			handleRemoveRole={handleRemoveRole}
			navigate={navigate}
			setToggleSelector={setToggleSelector}
			toggleSelector={toggleSelector}
		/>
	);
}
