import React from 'react';
// Impport Components
import SimpleHeader from 'common/SimpleHeader';
// Impport Libs
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
// Impport Styles
import { FormattedMessage } from 'react-intl';
import 'styles/loginRecover.css';

import ChangeFirstPasswordViewModel from './ChangeFirstPassword.viewModel';

export default function ChangeFirstPassword() {
	const {
		isLoading,
		errorMessage,
		passwordShown,
		setNewPassword,
		setNewPasswordConfirm,
		togglePasswordVisiblity,
		restablishPassword,
		formatterText
	} = ChangeFirstPasswordViewModel();
	return (
		<>
			<SimpleHeader />
			<section className="recover-password">
				<form
					className="recover-password-container"
					onSubmit={restablishPassword}
					autoComplete="off"
				>
					<h4 className="recover-password-title">
						<FormattedMessage
							id="ChangeFirstPassword.text1"
							defaultMessage="Cambia tu contraseña"
						/>
					</h4>

					<div className="pass-wrapper">
						<input
							className="input-primary"
							type={passwordShown ? 'text' : 'password'}
							placeholder={formatterText('title.user.newPassword')}
							name="newPassword"
							id="Password"
							onChange={(e) => setNewPassword(e.target.value)}
							maxLength="45"
							required
						/>
						{passwordShown ? (
							<IoMdEye size={30} className="eye-icon" onClick={togglePasswordVisiblity} />
						) : (
							<IoMdEyeOff size={30} className="eye-icon" onClick={togglePasswordVisiblity} />
						)}
					</div>

					<div className="pass-wrapper">
						<input
							className="input-primary"
							type={passwordShown ? 'text' : 'password'}
							placeholder={formatterText('title.user.confirmNewPassword')}
							name="newPasswordConfirm"
							onChange={(e) => setNewPasswordConfirm(e.target.value)}
							maxLength="45"
							required
						/>
						{passwordShown ? (
							<IoMdEye size={30} className="eye-icon" onClick={togglePasswordVisiblity} />
						) : (
							<IoMdEyeOff size={30} className="eye-icon" onClick={togglePasswordVisiblity} />
						)}
					</div>

					<button type="submit" className="btn-primary spacing" disabled={isLoading}>
						{isLoading
							? `${formatterText('title.user.loading')}`
							: `${formatterText('title.user.changePassword')}`}
					</button>
					{errorMessage && (
						<ul className="error-message">
							<li>{formatterText('title.user.passwordRequirements')}</li>
							<li>{formatterText('title.user.passwordRequirement1')}</li>
							<li>{formatterText('title.user.passwordRequirement2')}</li>
							<li>{formatterText('title.user.passwordRequirement3')}</li>
							<li>{formatterText('title.user.passwordRequirement4')}</li>
						</ul>
					)}
				</form>
			</section>
		</>
	);
}
