import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';

// Import Libs
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

// Import Components
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';

// Import Hooks
import useLangv2 from 'hooks/useLangv2';

// Import Utils
import HandleInput from 'common/validators/HandleInput';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';

// Import Paths
import paths from 'services/paths';

// Import Models
import ProviderType from 'models/ProviderType';

// Import Constants
import { CODEREGEX, TEXTREGEXMIN } from 'common/validators/Regex';

export default function UpdateProviderTypeViewModel() {
	const { endpoints, RequestUseCase } = useAxios();
	const navigate = useNavigate();
	// form data
	const [formData, setFormData] = useState(new ProviderType());
	// Toggle state
	const [active, setActive] = useState(true);
	const { formatterText, resourceNotFound, noFilledContent } = useLangv2();
	const { id } = useParams();

	const [errorName, setErrorName] = useState('');
	const [codeError, setCodeError] = useState('');

	useEffect(() => {
		getDataProviderType();
	}, []);

	useEffect(() => {
		getDataToUpdate(id);
	}, [id]);

	const setProviderForm = (data) => {
		setFormData({
			idTipoProveedor: data.idUnidadNegocio,
			nombre: data.nombre.replaceAll(/\s{2,}/gi, ' '),
			descripcion: data.descripcion,
			codigo: data.codigo,
			estado: data.estado,
			fechaRegistro: data.fechaRegistro,
			fechaModificacion: data.fechaModificacion,
			usuarioCreacion: data.usuarioCreacion,
			usuarioModificacion: data.usuarioModificacion
		});
	};

	const getDataProviderType = () => {
		const providerType = JSON.parse(localStorage.getItem('dataUpdate'));

		if (providerType) {
			setProviderForm(providerType);
			setActive(providerType.estado);
		} else {
			navigate(paths.parameters);
		}
	};

	const getDataToUpdate = (id) => {
		RequestUseCase.get(endpoints.providerType.getProviderTypeById(id))
			.then((res) => {
				if (res === null) {
					resourceNotFound();
				} else {
					setProviderForm(res);
					setActive(res.estado);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// uodate the string to set into the form
	const handleText = (e) => {
		HandleInput(e, CODEREGEX, formData, setFormData, true);
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	const handleChange = (e) => {
		const example = e;
		example.target.value = e.target.value.toLowerCase();
		HandleInput(example, TEXTREGEXMIN, formData, setFormData);
	};

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		if (errorName !== '' || codeError !== '' || formData.nombre === '' || formData.codigo === '') {
			noFilledContent();
		} else {
			const data = {
				...formData,
				idTipoProveedor: id,
				nombre: formData.nombre.replaceAll(/\s{2,}/gi, ' '),
				descripcion: formData.descripcion,
				estado: active ? '1' : '0',
				usuarioModificacion: Cookie.get('idUsuario')
			};
			putProviderType(data);
		}
	};

	// update the provider type
	const putProviderType = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.update.general', 'Se va a editar el registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve) => {
					RequestUseCase.put(endpoints.providerType.updateProviderType, data)
						.then(() => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.updated.general',
										'El registro se ha actualizado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => navigate(paths.parameters)
								})
							);
						})
						.catch((err) => {
							if (err.response?.data?.message) {
								HandleOnError(formatterText(err.response?.data?.message));
							} else if (err.response.status === 400) {
								HandleOnError(
									formatterText(
										'alert.message.code.error.general',
										'El código ya existe, por favor ingrese uno distinto'
									)
								);
							} else {
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
						});
				});
			}
		});
	};

	const handleErrorName = () => {
		if (formData.nombre.trim() === '') {
			setErrorName(formatterText('p.label.title.name.is.required'));
		} else {
			setErrorName('');
		}
	};

	const handleErrorCode = () => {
		if (formData.codigo.trim() === '') {
			setCodeError(formatterText('table.title.code.required'));
		} else {
			setCodeError('');
		}
	};

	return {
		active,
		codeError,
		errorName,
		formatterText,
		formData,
		handleChange,
		handleErrorCode,
		handleErrorName,
		handlerTextDescription,
		handleSubmit,
		handleText,
		navigate,
		setActive
	};
}
