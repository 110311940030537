import React from 'react';
// Import libs
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
// Import Components
import DynamicTable from 'common/DynamicTable/DynamicHead';
import Search from 'common/Search';
import ExportJsonFile from 'components/utils/ExportJsonFile';
// Import Paths
import paths from 'services/paths';
//Import Constants
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';
//Import ViewModel
import ThirdPartiesViewModel from './ThirdParties.viewModel';

export default function ThirdPartiesComponent() {
	const {
		formatterText,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		loading,
		permittedActions,
		renderMessage,
		thirdParties,
		titles,
		searchResults
	} = ThirdPartiesViewModel();

	return (
		<>
			{!loading && thirdParties !== null ? (
				<section className="table-container">
					<section className="userOptions">
						{permittedActions.consultar && (
							<Search
								placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
								width="50%"
							/>
						)}
						{permittedActions.crear && (
							<Link to={paths.createThirdParty}>
								<button className="btn-add">
									<FormattedMessage
										id="header.title.third.party.create"
										defaultMessage="Crear terceros"
									/>
								</button>
							</Link>
						)}

						{permittedActions.exportar && (
							<ExportJsonFile
								moduleName={'Tercero'}
								userName={
									JSON.parse(localStorage.getItem('userData')).usuario
										? JSON.parse(localStorage.getItem('userData')).usuario
										: 'ADMIN'
								}
								dataTable={searchResults}
							/>
						)}
					</section>
					{permittedActions.consultar && (
						<FormattedMessage id="table.name.search.thirdParties" defaultMessage="Terceros">
							{() => (
								<DynamicTable
									titles={titles}
									pageName={PAGE_NAMES.Terceras}
									getData={getDataTable}
									handleDeleteItem={handleDeleteItem}
									handleEditStateItem={handleEditStateItem}
									routeToEdit={paths.updateThirdParty}
									canDeleted={permittedActions.eliminar}
									canModify={permittedActions.editar}
								/>
							)}
						</FormattedMessage>
					)}
				</section>
			) : (
				renderMessage()
			)}
		</>
	);
}
