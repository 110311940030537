import React from 'react';

// Import Libs
import { FormattedMessage } from 'react-intl';

// Import Components
import SelectorMulti from 'common/SelectorMulti';

// Import Paths
import paths from 'services/paths';

// Import ViewModel
import UpdateMarketViewModel from './UpdateMarket.viewModel';

export default function UpdateMarket() {
	const {
		active,
		codeError,
		error,
		formatterText,
		formData,
		handleChange,
		handleChangeMulti,
		handleCodeError,
		handleErrorName,
		handlePrefixError,
		handlerTextCode,
		handlerTextDescription,
		handleSubmit,
		handleText,
		loading,
		navigate,
		prefixError,
		selectedMarketAssociate,
		selectedSearchCountries,
		selectValues,
		setActive
	} = UpdateMarketViewModel();

	return (
		<form className="form-responsive" onSubmit={handleSubmit}>
			<section className="grid-container-1c zero-gap">
				<section className="d-flex">
					<span className="text-inline-md">
						<FormattedMessage id="table.title.market.name" defaultMessage="Nombre mercado" />
					</span>
					<section className="w100-container">
						<input
							style={{ marginBottom: '0' }}
							className="input-primary"
							type="text"
							name="nombre"
							value={formData.nombre}
							onChange={handleText}
							onBlur={handleErrorName}
							placeholder={formatterText('table.title.market.name', 'Nombre mercado')}
							maxLength="100"
						/>
						{error && <p style={{ fontSize: '0.7rem', color: 'red' }}>{error}</p>}
					</section>
				</section>
				<section className="d-flex">
					<span className="text-inline-md">
						<FormattedMessage id="table.title.description" defaultMessage="Desripción" />
					</span>
					<section className="w100-container">
						<textarea
							className="input-textarea"
							type="text"
							name="descripcion"
							value={formData.descripcion}
							onChange={handlerTextDescription}
							placeholder={formatterText('table.title.description', 'Descripción')}
							maxLength="200"
						/>
					</section>
				</section>
				<section className="d-flex">
					<span className="text-inline-md">
						<FormattedMessage id="table.title.code" defaultMessage="Código" />
					</span>
					<section className="w100-container">
						<input
							style={{ marginBottom: '0' }}
							className="input-primary"
							type="text"
							name="codigo"
							value={formData.codigo.toLowerCase()}
							onChange={handlerTextCode}
							placeholder={formatterText(
								'input.placeholder.enter.up.characters',
								'Ingresa hasta 45 caracteres'
							)}
							onBlur={handleCodeError}
							maxLength="45"
						/>
						{codeError && <p style={{ fontSize: '0.7rem', color: 'red' }}>{codeError}</p>}
					</section>
				</section>
				<section className="d-flex">
					<span className="text-inline-md">
						<FormattedMessage id="table.title.countryPrefix" defaultMessage="Prefijo" />
					</span>
					<section className="w100-container">
						<input
							style={{ marginBottom: '0' }}
							className="input-primary"
							type="text"
							name="prefijo"
							value={formData.prefijo}
							onChange={handleChange}
							placeholder={formatterText('table.title.countryPrefix', 'Prefijo')}
							onBlur={handlePrefixError}
							maxLength="45"
						/>
						{prefixError && <p style={{ fontSize: '0.7rem', color: 'red' }}>{prefixError}</p>}
					</section>
				</section>
				<section className="d-flex">
					<span className="text-inline-md">
						<FormattedMessage id="p.associated.countries" defaultMessage="Países asociados" />
					</span>
					<section className="w100-container">
						<SelectorMulti
							data={selectedSearchCountries}
							isLoading={loading}
							dataValue={selectValues}
							setterFunction={handleChangeMulti}
							der={selectedMarketAssociate}
							isRequired={false}
						/>
					</section>
				</section>
				<section className="d-flex">
					<span className="text-inline-md">
						<FormattedMessage id="table.title.state" defaultMessage="Estado" />
					</span>
					<section className="w100-container">
						<label className="container-toggle-label">
							<p className="form-responsive-toggle">
								{active
									? formatterText('p.active', 'Activo')
									: formatterText('p.unActive', 'No activo')}
							</p>
							<label className="switch">
								<input
									checked={active ? true : false}
									onChange={() => {
										setActive(!active);
									}}
									type="checkbox"
								/>
								<span className="slider round"></span>
							</label>
						</label>
					</section>
				</section>
			</section>
			<section className="form-responsive-container-buttons">
				<button type="submit" className="btn-primary">
					<FormattedMessage id="btn.save" defaultMessage="Guardar" />
				</button>
				<button className="input-cancel" onClick={() => navigate(paths.parameters)}>
					<FormattedMessage id="alert.button.cancel.general" defaultMessage="Cancelar" />
				</button>
			</section>
		</form>
	);
}
