import React from 'react';
import { SearchWrapper } from 'context/SearchContext';

import CreateActivityComponent from './CreateActivity.component';

export default function CreateActivity() {
	return (
		<SearchWrapper>
			<CreateActivityComponent />
		</SearchWrapper>
	);
}
