import React from 'react';
import DynamicTable from 'components/zones/DynamicTable';
// Import Hooks
import Search from 'common/Search';
import AuditsViewModel from './Audits.viewModel.js';

import SelectorMulti from 'common/SelectorMulti';
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
// Import Libs
import { FormattedMessage } from 'react-intl';

import DateTimePicker from 'components/pickers/DateTimePicker';
import ExportJsonFile from 'components/utils/ExportJsonFile';
import { PAGES } from 'constants/lang/services/services/pages';
import 'styles/tab.css';

const AuditComponent = () => {
	const {
		audits,
		leakedData,
		setLeakedData,
		permissionsData,
		modulesData,
		userData,
		titles,
		formData,
		advancedSearch,
		multiPermissions,
		multiModules,
		multiUsers,
		dates,
		formatterText,
		permittedActions,
		loadingProgress,
		searchAdvance,
		handleChange,
		handleChangeMulti,
		handleChangeModules,
		handleChangeUsers,
		setAdvancedSearch,
		setDates
	} = AuditsViewModel();

	return (
		<div className="table-container">
			{permittedActions.consultar && (
				<section className="advance-search">
					<p
						onClick={(e) => {
							e.preventDefault();
							setAdvancedSearch(!advancedSearch);
						}}
					>
						{advancedSearch ? (
							<MdKeyboardArrowDown size={22} color="gray" cursor="pointer" />
						) : (
							<MdKeyboardArrowRight size={22} color="gray" cursor="pointer" />
						)}
						<FormattedMessage
							id="text.action.show.advanced.search"
							defaultMessage="Búsqueda avanzada"
						/>
					</p>
				</section>
			)}

			{advancedSearch && (
				<>
					<section className="grid-container-3c">
						<label className="d-flex">
							<span className="text-inline">{formatterText('table.title.user')}</span>
							<section className="w100-container">
								<SelectorMulti
									data={userData}
									dataValue={multiUsers}
									setterFunction={handleChangeUsers}
								/>
							</section>
						</label>
						<label className="d-flex">
							<DateTimePicker
								name="fecha_inicio"
								value={dates.fecha_inicio}
								onChange={(data) => setDates({ ...dates, ...data })}
								placeholder={formatterText('title.service.startDate.audit')}
							/>
						</label>
						<label className="d-flex">
							<DateTimePicker
								name="fecha_fin"
								value={dates.fecha_fin}
								onChange={(data) => setDates({ ...dates, ...data })}
								placeholder={formatterText('title.service.endDate')}
							/>
						</label>
						<label className="d-flex">
							<span className="text-inline">
								<FormattedMessage id="Audit.text1" defaultMessage="Permisos" />
							</span>
							<section className="w100-container">
								<SelectorMulti
									data={permissionsData}
									dataValue={multiPermissions}
									setterFunction={handleChangeMulti}
								/>
							</section>
						</label>
						<label className="d-flex">
							<span className="text-inline">
								<FormattedMessage id="Audit.text2" defaultMessage="Módulos" />
							</span>
							<section className="w100-container">
								<SelectorMulti
									data={modulesData}
									dataValue={multiModules}
									setterFunction={handleChangeModules}
								/>
							</section>
						</label>
						<label className="d-flex">
							<input
								type="text"
								className="input-primary"
								name="ip"
								placeholder="IP"
								value={formData.ip}
								onChange={handleChange}
							/>
						</label>
					</section>
					<section
						style={{
							marginBottom: '2rem'
						}}
					>
						<button
							className={`${dates.fecha_inicio == null ? 'disabled' : ''} btn-search`}
							onClick={(e) => searchAdvance(e)}
							disabled={dates.fecha_inicio == null}
						>
							{loadingProgress ? (
								<FormattedMessage id="btn.loading" defaultMessage="Cargando..." />
							) : (
								<FormattedMessage id="btn.search" defaultMessage="Buscar" />
							)}
						</button>
					</section>
				</>
			)}

			{permittedActions.consultar && (
				<>
					<section className="userOptions">
						<Search
							placeholder={formatterText('input.placeholder.search.audit.table')}
							width="50%"
						/>
						{permittedActions.exportar && (
							<ExportJsonFile
								moduleName={'auditorías'}
								userName={
									JSON.parse(localStorage.getItem('userData')).usuario
										? JSON.parse(localStorage.getItem('userData')).usuario
										: 'ADMIN'
								}
								dataTable={leakedData}
							/>
						)}
					</section>
					<DynamicTable
						titles={titles}
						pageName={PAGES.Auditorías}
						data={audits}
						setLeakedData={setLeakedData}
					/>
				</>
			)}
		</div>
	);
};

export default AuditComponent;
