/**
 * @returns {object}
 */
export const getFormikInitialValues = () => {
	return {
		area: '',
		asunto: '',
		destinatario: '',
		contenido: ''
	};
};
