import React, { useContext, useEffect, useRef, useState } from 'react';
// Import Context
import { AppContext } from 'context/AppContext';
import { SearchWrapper, useSeachContext } from 'context/SearchContext';
import DynamicTableServices from '../../DynamicTableServices/DynamicHeadServices';
// Import Hooks
import useAxios from 'hooks/useAxios';
import useGetData from 'hooks/useGetData';
import { useParams } from 'react-router';
// Import Services
import { ACTIVITYSTATUS } from 'constants/activityStatus';
import { ALERT_NOTIFICATIONS } from 'constants/alertNotifications';
import { ACTIVITIES } from 'constants/lang/services/services/activities';
import useLangv2 from 'hooks/useLangv2';
import { MdCircle, MdDownload } from 'react-icons/md';
import endpoints from 'services/api';
import paths from 'services/paths';
import { SERVICE_STATUS } from 'constants/serviceStatus';

const Activities = ({
	getActivitiesActive,
	statusActivity,
	getAllTechnicalsServices,
	getDataToUpdate,
	setIsDeleteTechMain,
	isDeleteTechMain,
	ppTech
}) => {
	return (
		<SearchWrapper>
			<ActivitiesComponent
				getActivitiesActive={getActivitiesActive}
				statusActivity={statusActivity}
				getDataToUpdate={getDataToUpdate}
				setIsDeleteTechMain={setIsDeleteTechMain}
				isDeleteTechMain={isDeleteTechMain}
				ppTech={ppTech}
				getAllTechnicalsServices={getAllTechnicalsServices}
			/>
		</SearchWrapper>
	);
};

const tableTitles = [
	ACTIVITIES.tableTitles.nameActivity,
	ACTIVITIES.tableTitles.serviceStage,
	ACTIVITIES.tableTitles.developmentMinutes,
	ACTIVITIES.tableTitles.activityStatus,
	ACTIVITIES.tableTitles.technicalAspect,
	ACTIVITIES.tableTitles.activityCost,
	ACTIVITIES.tableTitles.formCompleted
];

function ActivitiesComponent({
	statusActivity,
	getDataToUpdate,
	getAllTechnicalsServices,
	setIsDeleteTechMain,
	isDeleteTechMain,
	ppTech
}) {
	const { COOKIE_USER, RequestUseCase } = useAxios();
	// get id url
	const { id } = useParams();
	const { setDataTable } = useSeachContext();
	const { setInitActivity, setListActivities, setIdEstadoServicio, idEstadoServicio } =
		useContext(AppContext);
	const [counterActivities, setCounterActivities] = useState(0);
	const [serviceCost, setServiceCost] = useState(0);
	const [form, setForm] = useState(0);
	// activity ids
	const [activityIds, setActivityIds] = useState([]);
	const [activityYellowAlert, setActivityYellowAlert] = useState(30);
	const [activityOrangeAlert, setActivityOrangeAlert] = useState(10);
	const [activityRedAlert, setActivityRedAlert] = useState(5);
	const [loading] = useState(false);
	const lastUpdateDateService = useRef('');
	const { showDownloadFile, showSB, formatterText, customSB } = useLangv2();

	useEffect(() => {
		const strNotiActi = localStorage.getItem('notificacionActivities');
		if (strNotiActi) {
			const arrNotiActi = JSON.parse(strNotiActi);
			const yellowAlertMinu = arrNotiActi.find(
				(notiActi) =>
					notiActi?.idTipoNotificacion?.tipo == ALERT_NOTIFICATIONS.SEMAFORO_ACTIVIDAD.AMARILLA
			)?.tiempoMinuto;
			const orangeAlertMinu = arrNotiActi.find(
				(notiActi) =>
					notiActi?.idTipoNotificacion?.tipo == ALERT_NOTIFICATIONS.SEMAFORO_ACTIVIDAD.NARANJA
			)?.tiempoMinuto;
			const redAlertMinu = arrNotiActi.find(
				(notiActi) =>
					notiActi?.idTipoNotificacion?.tipo == ALERT_NOTIFICATIONS.SEMAFORO_ACTIVIDAD.ROJA
			)?.tiempoMinuto;
			setActivityYellowAlert(yellowAlertMinu === undefined ? 30 : yellowAlertMinu);
			setActivityOrangeAlert(orangeAlertMinu === undefined ? 10 : orangeAlertMinu);
			setActivityRedAlert(redAlertMinu === undefined ? 0 : redAlertMinu);
		}

		// Luego de manejar los valores de localStorage y los predeterminados, llamamos a getDataTable
		getDataTable();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const TECHNICAL_TYPE = {
		MAIN: 1,
		AUX: 0
	};

	const { toggleLoading, toggleError, handleClick } = useGetData();

	// función para cargar datos
	const getDataTable = async () => {
		// show loading
		toggleLoading(true);
		try {
			const dateUpdate = await RequestUseCase.get(
				endpoints.services.getLastUpdateDateService(id, TECHNICAL_TYPE.MAIN)
			);
			lastUpdateDateService.current = dateUpdate;
			const data = await RequestUseCase.get(
				endpoints.services.getAllActivitiesStateByIdService(id)
			);
			const newArray = [];
			const r = [];
			const forms = [];
			const primerActividad = data.reduce((actividadA, actividadB) => {
				if (actividadB.orden < actividadA.orden) {
					return actividadB;
				}
				return actividadA;
			}, data[0]);
			let totalTime = 0;
			data.forEach((item) => {
				totalTime += item.tiempoActividad;
				handleStructureItems(newArray, item, primerActividad, totalTime);
				r.push(item.valor);
				forms.push(item.cantidadRespuestasFormulario);
			});

			setForm(forms);

			// sumar todos los enteros de r
			const RR = r.reduce((a, b) => a + b, 0);
			setServiceCost(RR);
			const ACTIVITY_START = activityIds.some((id) => {
				return id !== 1;
			});
			// check if activityIds has an id different to 1, if it has, set initActivity to true
			setInitActivity(ACTIVITY_START);
			const dataFinal = sortFunction(newArray, 'asc');
			setListActivities(dataFinal);
			setDataTable(dataFinal);
		} catch (error) {
			toggleError(!error);
			handleClick();
		} finally {
			toggleLoading(false);
		}
	};

	const sortFunction = (newArray, type) => {
		let sortedArray;
		// sort iby the orden field
		if (type === 'asc') {
			sortedArray = newArray.sort((a, b) => {
				return a.orden - b.orden;
			});
		} else {
			sortedArray = newArray.sort((a, b) => {
				return b.orden - a.orden;
			});
		}

		return sortedArray;
	};

	const handleDeleteItem = (rowId) => {
		const deleteItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.users.deleteUser(rowId))
				.then(() => {
					getDataTable();
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return deleteItemPromise;
	};

	const handleDownloadForm = (item, itemId) => {
		showDownloadFile();

		RequestUseCase.get(endpoints.formServices.getFormByIdAnswer(itemId))
			.then((res) => {
				const extensionArchivo = 'pdf';
				const MimeType = 'pdf';
				const decoded = decodeBase64(res);
				DownloadPdf(decoded, `${item.nombre.replace(/ /g, '-')}.${extensionArchivo}`, MimeType);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const decodeBase64 = (base64) => {
		const byteCharacters = atob(base64);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		return byteArray;
	};

	const DownloadPdf = (decoded, fileName, ext) => {
		const blob = new Blob([decoded], { type: `aplication/${ext}` });
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = fileName;
		link.click();
	};

	const formatCurrency = (value) => {
		const parts = value.toString().split('.');
		let formattedValue = `$${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;

		if (parts.length > 1) {
			formattedValue += `,${parts[1]}`;
		}

		return formattedValue;
	};

	const getDateReference = (lastDateUpdateService) => {
		let dateReference = new Date();
		const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;
		if (lastDateUpdateService) {
			const match = lastDateUpdateService.match(regex);
			const [textOriginal, year, month, day, hour, minute] = match;
			const monthValue = Number(month) - 1;
			const dayValue = Number(day);
			dateReference = new Date(year, monthValue, dayValue, hour, minute, 59);
		}
		return dateReference;
	};

	const handleStructureItems = (newArray, item, primerActividad, totalTime) => {
		setCounterActivities((prev) => {
			return parseInt(prev + item.tiempoActividad);
		});

		setActivityIds((prev) => {
			return [...prev, item.idEstadoActividad?.idEstadoActividad];
		});

		let dateUpdate = new Date();
		let isFirtActivity = false;
		let previousActivity;
		const dateUpdateFirstActi = lastUpdateDateService.current;
		dateUpdate =
			dateUpdateFirstActi.length == 0 ? new Date() : getDateReference(dateUpdateFirstActi);
		isFirtActivity = true;
		//notificacionActivities
		const storedIdEstadoServicio = localStorage.getItem('idEstadoServicio');
		let semaforoActividad = 'white';
		if (
			storedIdEstadoServicio == SERVICE_STATUS.EN_CURSO ||
			isFirtActivity ||
			previousActivity?.idEstadoActividad?.idEstadoActividad == ACTIVITYSTATUS.FINALIZADO ||
			previousActivity?.idEstadoActividad?.idEstadoActividad == ACTIVITYSTATUS.PROCESO ||
			previousActivity?.idEstadoActividad?.idEstadoActividad == ACTIVITYSTATUS.INICIAR
		) {
			const fechaActual = previousActivity?.fechaActual ?? item?.fechaActual;
			const dateNow = getDateReference(fechaActual);
			const tiempoActividadMinu =
				previousActivity?.fechaModificacion &&
				previousActivity?.idEstadoActividad?.idEstadoActividad == SERVICE_STATUS.EN_CURSO
					? item?.tiempoActividad
					: totalTime;
			dateUpdate.setTime(dateUpdate.getTime() + tiempoActividadMinu * 60 * 1000);
			const diffTimeMs = dateUpdate - dateNow;
			const difMinutes = Math.floor(diffTimeMs / 60000);
			if (difMinutes >= activityYellowAlert) {
				semaforoActividad = 'yellow';
			} else if (difMinutes > activityRedAlert && difMinutes <= activityYellowAlert) {
				semaforoActividad = 'orange';
			} else if (difMinutes <= activityRedAlert) {
				semaforoActividad = 'red';
			}

			if (item?.idEstadoActividad?.idEstadoActividad == ACTIVITYSTATUS.FINALIZADO) {
				semaforoActividad = 'yellow';
			}
		}

		newArray.push({
			id: item.idServicioActividad,
			nombreP: item.nombre,
			etapaS: (
				<h4>
					<MdCircle size={8} color={semaforoActividad} cursor="pointer" />{' '}
					{item.idEtapaServicio.nombre}
				</h4>
			),
			tiempo: item.tiempoActividad,
			EstadoActividad: item.idEstadoActividad.idEstadoActividad,
			actividadTecnica: item.actividadTecnica === 1 ? 'Si' : 'No',
			valor: formatCurrency(item.valor),
			form:
				item.cantidadRespuestasFormulario !== null && item.cantidadRespuestasFormulario !== 0 ? (
					<MdDownload
						size={25}
						color="gray"
						cursor="pointer"
						disabled={loading}
						onClick={() => handleDownloadForm(item, item.idServicioFormulario)}
					/>
				) : (
					'Sin Formularios'
				),
			objeto: item,
			orden: item.orden
		});
	};

	const handleEditStateItem = (body) => {
		const updateItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.users.updateUser, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return updateItemPromise;
	};

	const convertMinutesToHours = (minutes) => {
		const hours = Math.floor(minutes / 60);
		const minutesLeft = minutes % 60;
		return `${hours} ${formatterText(ACTIVITIES.hours)} ${minutesLeft} ${formatterText(
			ACTIVITIES.minutes
		)}`;
	};

	const endService = (e) => {
		e.preventDefault(e);

		const BODY = {
			idServicio: parseInt(id),
			idEstadoServicio: 9,
			idTecnico: ppTech.idtech,
			idUsuario: COOKIE_USER
		};

		RequestUseCase.put(endpoints.services.updateServiceState, BODY).then((data) => {
			if (data.estado === false) {
				showSB('error', formatterText(data.mensaje));
			} else {
				// success data
				customSB(
					'success',
					'snackbar.success.update.state.service',
					'Estado del servicio actualizado correctamente'
				);
				setIdEstadoServicio(BODY.idEstadoServicio);
				getDataToUpdate();
			}
		});
	};

	return (
		<div>
			<div className="externalElementsContainer">
				<label className="wrapForm__label">
					<h3 className="p-styles primary-green spacing-l1">
						{formatterText(ACTIVITIES.totalActivities)}: {counterActivities} (
						{formatterText(ACTIVITIES.minutes)}) - {formatterText(ACTIVITIES.hours)}:{' '}
						{convertMinutesToHours(counterActivities)}
					</h3>
				</label>
				<label className="wrapForm__label">
					<h3 className="p-styles primary-green spacing-l1">
						{formatterText(ACTIVITIES.costService)}: {formatCurrency(serviceCost)}
					</h3>
				</label>
				<label className="wrapForm__label">
					{idEstadoServicio === 6 && (
						<button className="btn-add" onClick={endService}>
							{formatterText('service.services.editService.activities.endService')}
						</button>
					)}
				</label>
				<label className="wrapForm__label" style={{ marginLeft: '2rem' }}>
					<table>
						<thead>
							<tr>
								<th>Amarillo</th>
								<th>Naranja</th>
								<th>Rojo</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{activityYellowAlert}</td>
								<td>{activityOrangeAlert}</td>
								<td>{activityRedAlert}</td>
							</tr>
						</tbody>
					</table>
				</label>
			</div>
			<div className="tdR thR table-container">
				<DynamicTableServices
					titles={tableTitles}
					pageName={formatterText(ACTIVITIES.title)}
					getData={getDataTable}
					handleDeleteItem={handleDeleteItem}
					handleEditStateItem={handleEditStateItem}
					routeToEdit={paths.updateUser}
					canDeleted={true}
					canModify={true}
					statusActivity={statusActivity}
					getDataToUpdate={getDataToUpdate}
					setCounterActivities={setCounterActivities}
					form={form}
					setIsDeleteTechMain={setIsDeleteTechMain}
					isDeleteTechMain={isDeleteTechMain}
					getAllTechnicalsServices={getAllTechnicalsServices}
				/>
			</div>
		</div>
	);
}

export default Activities;
