import Axios from 'axios';
import endpoints from 'services/api';

const commonHeadersResponse = {
	headers: {
		Accept: '*/*',
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*'
	},
	responseType: 'json'
};
class Api {
	//declare a common header for all requests

	static async get(url, extraHeaders = {}) {
		return this.ExecutePetition(url, 'get', null, extraHeaders);
	}

	static async post(url, data, extraHeaders = {}) {
		return this.ExecutePetition(url, 'post', data, extraHeaders);
	}

	static async put(url, data, extraHeaders = {}) {
		return this.ExecutePetition(url, 'put', data, extraHeaders);
	}

	static async delete(url, extraHeaders = {}) {
		return this.ExecutePetition(url, 'delete', null, extraHeaders);
	}

	static async postFormData(url, data) {
		return this.ExecutePetition(url, 'post', data, {
			'Content-Type': 'multipart/form-data'
		});
	}

	static async postForm(url, data) {
		return this.ExecutePetition(url, 'postForm', data, {});
	}

	// Método para ejecutar peticiones con la posibilidad de ajustar headers
	static async ExecutePetition(url, method, data = null, extraHeaders = {}) {
		// Configura los headers específicos para esta petición
		const config = {
			...commonHeadersResponse,
			headers: {
				...commonHeadersResponse.headers,
				...extraHeaders,
				Authorization: sessionStorage.getItem('token')
			}
		};
		if (data !== null) {
			const res = await Axios[method](url, data, config);
			return res.data;
		} else {
			const res = await Axios[method](url, config);
			return res.data;
		}
	}
}

export default Api;

export { endpoints };
