import { useContext, useEffect, useRef, useState } from 'react';
// Import Contexts
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import Hooks
import useAxios from 'hooks/useAxios';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import { useNavigate, useParams } from 'react-router-dom';
// Import Hooks
import { useForm } from '@formiz/core';
import HandleOnError from 'common/validators/HandleOnError';
import { CCREGEX, EMAILREGEX, NUMBERREGEX, TEXTONLYONESPACEREGEX } from 'common/validators/Regex';
import CustomAlert from 'components/CustomAlert';
import Swal from 'sweetalert2';
// Import Services
import { FilterActive } from 'common/validators/FilterActives';
import { useSeachContext } from 'context/SearchContext';
import _ from 'lodash';
import paths from 'services/paths';
import useGetClientFormOptionsData from '../hooks/useGetClientFormOptionsData';
const MODEL = {
	idTipoDocumentoAsesor: '',
	identificacion: '',
	genero: '',
	nombreAsesor: '',
	idCargo: '',
	emailAsesor: '',
	correoAlternoAsesor: '',
	telefonoAsesor: '',
	telefonoAlternoAsesor: '',
	idMunicipio: '',
	estado: 1
};

export default function UpdateClientViewModel() {
	const { selectData, setSelectData, loadingData, listPrices } = useGetClientFormOptionsData();
	const [open, setOpen] = useState(false);
	// Manage axios requests
	const { fetchData, COOKIE_USER, RequestUseCase, endpoints } = useAxios();
	// files attached
	const [archivos, setCurrentFiles] = useState([]);
	// const [archivosModificados, setArchivosModificados] = useState([]);
	// manage tables
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	// get id client
	const { id } = useParams();
	// set id client
	const [disabledLS, setDisableLS] = useState(true);
	const [idClient, setIdClient] = useState(0);
	// context search

	const { dataTableBusinessUnit, setDataTableBusinessUnit } = useSeachContext(TableMinimalContext);

	const { setDataTable } = useSeachContext();

	const {
		currentDataTable,
		setCurrentDataTable,
		otherCurrentDataTable,
		setOtherCurrentDataTable,
		selectedTable,
		setSelectedTable
	} = useContext(TableMinimalContext);
	// hook to loading data
	const { loading } = useGetData();
	// hook form
	const myForm = useForm();
	// navigation
	const navigate = useNavigate();
	// use Hook of language v2
	const { formatterText, noFilledContent, newItemCreated, customSB } = useLangv2();

	const titlesTableBranchOffice = [
		formatterText('tab.title.direccion'),
		formatterText('tab.title.adiconaldatos'),
		formatterText('tab.title.observaciones'),
		formatterText('table.title.state'),
		formatterText('table.actions')
	];

	const titlesTableAdvisers = [
		formatterText('table.title.advisor.name'),
		formatterText('table.title.advisor.document'),
		formatterText('table.title.advisor.genre'),
		formatterText('table.title.advisor.id'),
		formatterText('table.title.advisor.charge'),
		formatterText('table.title.advisor.mail'),
		formatterText('table.title.advisor.mail.alt'),
		formatterText('table.title.advisor.phone'),
		formatterText('table.title.advisor.phone.alt'),
		formatterText('table.title.state'),
		formatterText('table.actions')
	];

	const titlesTableBusinessUnit = [
		formatterText('tab.title.pricelist.name'),
		formatterText('table.actions')
	];

	const [currentBranchOffice, setCurrentBranchOffice] = useState({
		direccion: '',
		datosAdicionales: '',
		observaciones: '',
		estado: 1,
		idCiudadMunicipio: '',
		idSector: ''
	});
	// business units data selector
	const [units, setUnits] = useState([]);
	// client data save in local storage
	const [printable, setPrintable] = useState(JSON.parse(localStorage.getItem('dataUpdate')));
	const [marketsByCountry, setMarketsByCountry] = useState([]);
	const [error, setError] = useState('');
	const [altErr, setAltErr] = useState('');
	const selectedCountryId = myForm.fields?.idPais?.value;
	// List prices	primary and secondary
	const [selectValues, setSelectValues] = useState([]);
	const [secondaryPrices, setSecondaryPrices] = useState({ label: '', value: 0 });
	// state of the principal list price
	const [auxData, setAuxData] = useState(null);
	// aux val to clear the value of the select of unit business
	const [clearValue, setClearValue] = useState(true);
	// verify if the unit business is required
	const [updateUnitBusiness, setUpdateUnitBusiness] = useState(false);
	const [resetPLP, setResetPLP] = useState(false);
	// Asesores
	const [currentAsesor, setCurrentAsesor] = useState(MODEL);
	const getMarketsByCountry = async (countryId) => {
		await RequestUseCase.get(endpoints.markets.getMarketByCountry(countryId)).then((res) => {
			setMarketsByCountry(FilterActive(res));
		});
	};
	// set client id
	useEffect(() => {
		getDataInit();
	}, [selectData]);

	useEffect(() => {
		if (selectedCountryId) {
			getMarketsByCountry(selectedCountryId);
		}
	}, [selectedCountryId]);

	useEffect(() => {
		getDataCurrentProvider();
	}, []);

	const getDataInit = () => {
		setIdClient(id);
		// set the business units
		setUnits(handleUnits(printable?.idMercado.idMercado));
		getDataCurrentProvider();

		const promises = [
			RequestUseCase.get(endpoints.businessUnit.getUnitBusinessByClient(printable?.idCliente)),
			getAsesors(printable?.idCliente),
			getSucursales(printable?.idCliente),
			getListPricesByClient(printable?.idCliente)
		];
		Promise.all(promises).then((values) => {
			getDataUnitsByClient(values[0]);
			getAsesorsMap(values[1]);
			getSucursalesMap(values[2]);
		});
	};

	// fill business units
	const handleUnits = (idMarket) => {
		if (idMarket !== null) {
			const result = [];
			RequestUseCase.get(endpoints.businessUnit.getBusinessUnitByMarket(idMarket)).then((data) => {
				data.forEach((item) => {
					if (item.estado === 1) {
						result.push({ label: item.nombre, value: item.idUnidadNegocio });
					}
				});
			});
			return result;
		}
	};

	const handleDelete = (item) => {
		const filterBusinessUnit = dataTableBusinessUnit.filter(
			(businessUnit) => businessUnit.idUnidadNegocio != item.idUnidadNegocio
		);
		setDataTable(filterBusinessUnit);
		setDataTableBusinessUnit(filterBusinessUnit);
	};

	// get all listPrices from the client
	const getListPricesByIdClient = (id, LP, OPP) => {
		RequestUseCase.get(endpoints.listPriceClient.getListPriceById(id)).then((data) => {
			console.log(data);
			updateLocalListPrices(data);
			OPP && obtainPrimaryPrice(LP);
		});
	};

	const getListPricesByClient = (id) => {
		RequestUseCase.get(endpoints.listPriceClient.getListPriceById(id)).then((response) => {
			updateLocalListPrices(response);
			obtainPrimaryPrice(response);
		});
		return '';
	};

	// update the local price list to create or update
	const updateLocalListPrices = (data) => {
		localStorage.setItem('ListPrices', JSON.stringify(data));
	};

	const obtainPrimaryPrice = (LP) => {
		const listPricesPrintable = JSON.parse(localStorage.getItem('ListPrices'));
		listPricesPrintable?.forEach((item) => {
			if (item.principal === 1) {
				const idLP = item.idListaPreciosActividad.idListaPreciosActividad;
				setAuxData({
					idListaPrecios: {
						value: idLP,
						label: `${item.idListaPreciosActividad.nombre} - ${item.idListaPreciosActividad.codigo}`
					}
				});

				// fix list prices secondary when the primary have a value
				const filter = LP.filter((item) => item.value !== idLP);
				setSecondaryPrices(filter);

				const BODY = {
					idListaPreciosActividadCliente: null,
					idListaPreciosActividad: {
						idListaPreciosActividad: idLP
					},
					idCliente: idClient,
					principal: 1,
					fechaCreacion: printable?.fechaCreacion,
					usuarioCreacion: printable?.usuarioCreacion,
					usuarioModificacion: COOKIE_USER
				};
				setBodyPrices(BODY);
			} else {
				const newArray = [];
				listPricesPrintable?.forEach((item) => {
					if (item.principal === 0) {
						newArray.push({
							idPojo: item.idListaPreciosActividadCliente,
							label: `${item.idListaPreciosActividad.nombre} - ${item.idListaPreciosActividad.codigo}`,
							value: item.idListaPreciosActividad.idListaPreciosActividad,
							isFixed: true
						});
					}
				});
				setSelectValues(newArray);
			}
		});
	};

	useEffect(() => {
		if (currentBranchOffice.idCiudadMunicipio !== '') {
			AllSectorsForSelect();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentBranchOffice.idCiudadMunicipio]);

	const AllSectorsForSelect = () => {
		RequestUseCase.get(endpoints.zones.getAllSectors).then((data) => {
			// console.log(response);
			//convert to json the current branch office id
			const idMunicipio = JSON.parse(currentBranchOffice.idCiudadMunicipio).value;
			// create new array
			const newArray = [];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1 && item.idMunicipio.idMunicipio === idMunicipio) {
					newArray.push({
						idSector: item.idSector,
						nombre: item.nombre
					});
				}
			});
			// set the values of the select
			setSelectData({
				...selectData,
				sectors: newArray
			});
		});
	};

	const getSucursalesMap = (res) => {
		const newArray = [];
		res.forEach((item) => {
			newArray.push({
				idSucursal: item.idSucursal,
				direccion: item.idDireccion.direccion,
				datosAdicionales: item.idDireccion.datosAdicionales,
				observaciones: item.idDireccion.observacion,
				estado: item.estado,
				idCiudadMunicipio: {
					value: item.idDireccion.idSector.idMunicipio.idMunicipio,
					label: item.idDireccion.idSector.idMunicipio.nombre
				},
				idSector: {
					value: item.idDireccion.idSector.idSector,
					label: item.idDireccion.idSector.nombre
				},
				objeto: item
			});
		});
		setCurrentDataTable(newArray);
	};

	const getSucursales = (id) => {
		return RequestUseCase.get(endpoints.branchOffices.getSucursalClientById(id));
	};

	// Sucursal
	const handleChangeSucursal = (e) => {
		setCurrentBranchOffice({
			...currentBranchOffice,
			[e.target.name]: e.target.value
		});
	};

	// handle select Sucursal
	const handleChangeSelect = (selectedOption) => {
		setCurrentBranchOffice({
			...currentBranchOffice,
			[selectedOption.target.name]: selectedOption.target.value
		});
	};

	// post a new branch office
	const handleAddBranchOffice = () => {
		if (
			currentBranchOffice.idCiudadMunicipio !== '' &&
			currentBranchOffice.idSector !== '' &&
			currentBranchOffice.direccion !== ''
		) {
			if (currentBranchOffice.datosAdicionales === '') {
				setCurrentBranchOffice((prevState) => ({
					...prevState,
					datosAdicionales: ' '
				}));
			}

			if (currentBranchOffice.observaciones === '') {
				setCurrentBranchOffice((prevState) => ({
					...prevState,
					observaciones: ' '
				}));
			}
			const data = {
				idSucursal: null,
				idCliente: {
					idCliente: printable?.idCliente
				},
				idDireccion: {
					idDireccion: null,
					idSector: {
						idSector:
							typeof currentBranchOffice.idSector === 'string'
								? JSON.parse(currentBranchOffice.idSector).value
								: currentBranchOffice.idSector.value
					},
					direccion: currentBranchOffice.direccion,
					datosAdicionales: currentBranchOffice.datosAdicionales,
					observacion: currentBranchOffice.observaciones
				},
				telefono: printable?.telefono,
				sitioWeb: printable?.sitioWeb
			};
			RequestUseCase.post(endpoints.branchOffices.addBranchOffice, data).then(() => {
				newItemCreated();
				getSucursales(id).then((resp) => {
					getSucursalesMap(resp);
				});
				setCurrentDataTable([...currentDataTable, currentBranchOffice]);
				setCurrentBranchOffice({
					direccion: '',
					datosAdicionales: '',
					observaciones: '',
					estado: 1,
					idCiudadMunicipio: currentBranchOffice.idCiudadMunicipio,
					idSector: currentBranchOffice.idSector
				});
			});
		} else {
			noFilledContent();
		}
	};

	const getDataUnitsByClient = (data) => {
		const arrData = data.map((item) => item.idUnidadNegocio);
		setDataTableBusinessUnit(arrData);
		setDataTable(arrData);
	};

	const getAsesorsMap = (data) => {
		const ASESORS = data.map((item) => ({
			idAsesor: item.idAsesor,
			nombreAsesor: item.nombres,
			idTipoDocumentoAsesor: {
				label: `${item.idTipoDocumento.nombre} - ${item.idTipoDocumento.abreviatura}`,
				value: item.idTipoDocumento.idTipoDocumento
			},
			genero: {
				label: `${item.idGenero.abreviatura} - ${item.idGenero.nombre}`,
				value: item.idGenero.idGenero
			},
			identificacion: item.identificacion,
			idCargo: {
				label: `${item.idCargo.nombre} - ${item.idCargo.codigo}`,
				value: item.idCargo.idCargo
			},
			emailAsesor: item.correo,
			correoAlternoAsesor: item.correoAlterno,
			telefonoAsesor: item.telefono,
			telefonoAlternoAsesor: item.telefonoAlterno,
			estado: item.estado,
			idMunicipio: {
				value: item.idMunicipio.idMunicipio,
				label: `${item.idMunicipio.nombre} - ${item.idMunicipio.idDepartamento.nombre}`
			},
			usuarioCreacion: item.usuarioCreacion,
			fechaCreacion: item.fechaCreacion
		}));
		setOtherCurrentDataTable(ASESORS);
	};
	// get asesors by client
	const getAsesors = (id) => {
		return RequestUseCase.get(endpoints.advisers.getAsesorsByClient(id));
	};

	const validateRegex = (e, regex) => {
		const { value = '', name } = e.target;

		const val = value.replaceAll(/\s{2,}/gi, ' ');

		if (val.match(regex)) {
			setCurrentAsesor({
				...currentAsesor,
				[name]: val.replaceAll(/\s{2,}/gi, ' ')
			});
		}
	};

	const handleChangeAsesor = (e) => {
		switch (e.target.name) {
			case 'identificacion':
				validateRegex(e, CCREGEX);
				break;
			case 'nombreAsesor':
				validateRegex(e, TEXTONLYONESPACEREGEX);
				break;
			case 'telefonoAsesor':
				validateRegex(e, NUMBERREGEX);
				break;
			case 'telefonoAlternoAsesor':
				validateRegex(e, NUMBERREGEX);
				break;
			case 'emailAsesor':
				validateRegex(e, EMAILREGEX);
				break;
			default:
				setCurrentAsesor({
					...currentAsesor,
					[e.target.name]: e.target.value.replaceAll(/\s{2,}/gi, ' ')
				});
				break;
		}
	};

	const handleBlurMailAsesor = (e) => {
		if (!error && e.target.value.trim() === '') {
			setError('');
		}
	};

	const handleBlurMailAltAsesor = (e) => {
		if (!altErr && e.target.value.trim() === '') {
			setAltErr('');
		}
	};

	const handleChangeSelectAsesor = (selectedOption) => {
		setCurrentAsesor({
			...currentAsesor,
			[selectedOption.target.name]: selectedOption.target.value
		});
	};

	const idTipoDocumentoAsesorRef = useRef();
	const generoRef = useRef();
	const idCargoRef = useRef();
	const idMunicipioRef = useRef();

	const handleAddAsesor = () => {
		const {
			idTipoDocumentoAsesor,
			identificacion,
			genero,
			nombreAsesor,
			emailAsesor,
			correoAlternoAsesor,
			telefonoAsesor,
			telefonoAlternoAsesor,
			idMunicipio,
			idCargo
		} = currentAsesor;

		if (
			idTipoDocumentoAsesor !== '' &&
			identificacion !== '' &&
			genero !== '' &&
			nombreAsesor !== '' &&
			emailAsesor !== '' &&
			correoAlternoAsesor !== '' &&
			telefonoAsesor !== '' &&
			telefonoAlternoAsesor !== '' &&
			idMunicipio !== '' &&
			idCargo !== '' &&
			!error &&
			!altErr
		) {
			if (emailAsesor === correoAlternoAsesor) {
				customSB('warning', 'warning.alert.same.mails');
				return;
			}
			if (telefonoAsesor === telefonoAlternoAsesor) {
				customSB('warning', 'warning.alert.same.tlf');
				return;
			}
			const DATA = {
				idAsesor: null,
				idCliente: {
					idCliente: idClient
				},
				idTipoDocumento: {
					idTipoDocumento:
						typeof idTipoDocumentoAsesor === 'string'
							? JSON.parse(idTipoDocumentoAsesor).value
							: idTipoDocumentoAsesor.value
				},
				identificacion,
				nombres: nombreAsesor,
				idGenero: {
					idGenero: typeof genero === 'string' ? JSON.parse(genero).value : genero.value
				},
				idCargo: {
					idCargo: typeof idCargo === 'string' ? JSON.parse(idCargo).value : idCargo.value
				},
				idMunicipio: {
					idMunicipio:
						typeof idMunicipio === 'string' ? JSON.parse(idMunicipio).value : idMunicipio.value
				},
				telefono: telefonoAsesor,
				telefonoAlterno: telefonoAlternoAsesor,
				correo: emailAsesor,
				correoAlterno: correoAlternoAsesor
			};

			RequestUseCase.post(endpoints.advisers.addAdviser, DATA)
				.then(() => {
					newItemCreated();
					getAsesors(idClient).then((resp) => {
						getAsesorsMap(resp);
					});
					setCurrentAsesor({
						nombreAsesor: '',
						identificacion: '',
						emailAsesor: '',
						correoAlternoAsesor: '',
						idCargo: '',
						telefonoAsesor: '',
						telefonoAlternoAsesor: ''
					});
					idTipoDocumentoAsesorRef.current.value = '';
					generoRef.current.value = '';
					idCargoRef.current.value = '';
					idMunicipioRef.current.value = '';
				})
				.catch((err) => {
					if (err.response?.data?.message) {
						HandleOnError(formatterText(err.response?.data?.message));
					} else {
						HandleOnError(
							formatterText(
								'snackbar.error.process.failed.general',
								'Error al realizar el proceso. Intentalo en otro momento.'
							)
						);
					}
				});
		} else {
			noFilledContent();
		}
	};
	// create the principal price list when the client dont have any
	const createPrincipalPriceList = () => {
		const BODY = {
			idListaPreciosActividadCliente: null,
			idListaPreciosActividad: {
				idListaPreciosActividad: auxData.idListaPrecioPrimaria.value
			},
			idCliente: idClient,
			principal: 1
		};
		RequestUseCase.post(endpoints.listPriceClient.addListPriceClient, BODY).then(() => {
			customSB(
				'success',
				'snackbar.success.create.principal.price.list',
				'Se ha asociado la lista de precios primaria al cliente'
			);
			updateLocalListPrices(BODY);
		});
	};

	const [bodyPrices, setBodyPrices] = useState();

	// handler for second prices to update the list and values
	const updateSelectPriceLists = (e) => {
		let updatePrimary = true;
		const listPricesPrintable = JSON.parse(localStorage.getItem('ListPrices'));
		// Check the number of secondary prices, to reset them
		if (selectValues.length !== 0) {
			// obtain all Secondary Price List
			const secondaryPricesPrintable = listPricesPrintable?.filter((item) => item.principal === 0);

			const sameList = secondaryPricesPrintable.filter(
				(item) => item.idListaPreciosActividad.idListaPreciosActividad === e?.value
			);

			// If the parent price is found to be equal to any of the children, it is reset and removes this
			if (sameList.length > 0) {
				updatePrimary = false;
			}
		}
		// If any parent value was selected, display available child prices
		if (e !== null && e !== undefined) {
			const filter = listPrices.filter((item) => item.value !== e?.value);

			if (updatePrimary) {
				setSecondaryPrices(filter);
				// update the principal list price
				let idListaPrecio = 0;
				const listPricesPrintable = JSON.parse(localStorage.getItem('ListPrices'));
				setDisableLS(false);
				listPricesPrintable?.forEach((item) => {
					if (item.principal === 1) {
						idListaPrecio = item.idListaPreciosActividadCliente;
					}
				});

				const BODY = {
					idListaPreciosActividadCliente: null,
					idListaPreciosActividad: {
						idListaPreciosActividad: e?.value
					},
					idCliente: idClient,
					principal: 1,
					fechaCreacion: printable?.fechaCreacion,
					usuarioCreacion: printable?.usuarioCreacion,
					usuarioModificacion: COOKIE_USER
				};
				setBodyPrices(BODY);
			} else {
				// obtain primary  Price List
				const listPricesPrintable = JSON.parse(localStorage.getItem('ListPrices'));

				const primaryPriceList = listPricesPrintable?.filter((item) => item.principal === 1);

				const VALUE = primaryPriceList[0];
				setAuxData({
					idListaPrecios: {
						value: VALUE.idListaPreciosActividad.idListaPreciosActividad,
						label: `${VALUE.idListaPreciosActividad.nombre} - ${VALUE.idListaPreciosActividad.codigo}`
					}
				});
				setResetPLP(true);
				customSB(
					'warning',
					'snackbar.warning.list.price.primary.associated',
					'No se puede asociar la lista de precios primaria al cliente porque ya tiene una asociada en la lista de precios secundaria.'
				);
			}
		}
		setTimeout(() => {
			setResetPLP(false);
		}, 100);
	};

	// control when a selector is changed and udpate data
	const handleChangeMulti = async (currentValues) => {
		setSelectValues(currentValues);
		const listPricesPrintable = JSON.parse(localStorage.getItem('ListPrices'));

		const secondaryPricesPrintable = listPricesPrintable?.filter((item) => item.principal === 0);

		// if secondaryPricesPrintable is lower than currentValues is because the user
		// want to create a new secondary list price with the current data
		if (secondaryPricesPrintable?.length < currentValues.length) {
			// get the last id element from the currentValues to create the new secondary list price
			const lastSPLID = currentValues[currentValues.length - 1].value;
			const BODY_SPL = {
				idListaPreciosActividadCliente: null,
				idListaPreciosActividad: {
					idListaPreciosActividad: lastSPLID
				},
				idCliente: idClient,
				principal: 0
			};

			RequestUseCase.post(endpoints.listPriceClient.addListPriceClient, BODY_SPL).then(() => {
				customSB(
					'success',
					'snackbar.success.create.secondary.price.list',
					'Se han asociado la(s) lista(s) de precios secundaria(s) al cliente'
				);
				getListPricesByIdClient(idClient, [], false);
				setSelectValues(currentValues);
			});
		}
		// otherwise delete the value that is not in the currentValues
		else {
			const result = await deleteSecondaryPriceList(secondaryPricesPrintable, currentValues);
			if (result) {
				setSelectValues(currentValues);
			} else {
				let newArray = [];
				newArray = secondaryPricesPrintable.map((item) => {
					return {
						value: item.idListaPreciosActividad.idListaPreciosActividad,
						label: `${item.idListaPreciosActividad.nombre} - ${item.idListaPreciosActividad.codigo}`
					};
				});
				// reset delete the value that is not in the currentValues
				setSelectValues(newArray);
			}
		}
	};

	// determinate which price list need to be delete it, delete only the secondary price list
	// that are not in localSPriceList base on the currentSPriceList
	const deleteSecondaryPriceList = async (localSPriceList, currentSPriceList) => {
		let result = true;

		if (!localSPriceList || !currentSPriceList) {
			// Handle the case where localSPriceList or currentSPriceList is undefined
			return result; // or throw an error, depending on your logic
		}

		// Filter CSPL to obtain the list of secondary prices that need to be deleted
		const LIST_DELETE = localSPriceList?.filter(
			(item) =>
				!currentSPriceList.some(
					(item2) => item2.value === item.idListaPreciosActividad.idListaPreciosActividad
				)
		);

		const PROMISES = LIST_DELETE?.map((item) =>
			RequestUseCase.delete(
				endpoints.listPriceClient.deleteListPriceClient(item.idListaPreciosActividadCliente)
			)
		);

		await Promise.all(PROMISES).then(() => {
			customSB(
				'success',
				'snackbar.success.update.secondary.price.list',
				'Se han actualizado la(s) lista(s) de precios secundaria(s) al cliente'
			);
			getListPricesByIdClient(idClient, [], false);
			result = true;
		});

		return result;
	};

	// upload files
	const uploadNewFile = (file) => {
		RequestUseCase.post(endpoints.UploadFiles.save, {
			idOrigen: printable?.idCliente,
			idTipoOrigenArchivo: 7,
			archivos: [file]
		}).then(() => {
			CustomAlert('confirm_msg', {
				icon: 'success',
				title: formatterText('alert.title.confirm.general'),
				text: formatterText('alert.title.confirm.add.files'),
				confirmButtonText: formatterText('alert.button.continue'),
				allowOutsideClick: false,
				executeFunction: () => getDataCurrentProvider()
			});
		});
	};

	const getDataCurrentProvider = () => {
		RequestUseCase.get(
			endpoints.UploadFiles.findArchivosById({
				idOrigen: printable?.idCliente,
				idTipoOrigenArchivo: 7
			})
		).then((response) => {
			const files = response?.map((file) => {
				return {
					name: file,
					url: file
				};
			});
			setCurrentFiles(files);
		});
	};

	// submit data form the form
	const handleSubmit = (values) => {
		if (dataTableBusinessUnit.length == 0) {
			Swal.fire({
				icon: 'warning',
				title: 'Error',
				text: formatterText('p.label.title.unidadNegocioRequerida')
			});
		} else {
			// check if the previous value is not null
			const idTipoDocumento =
				values.idTipoDocumento === null
					? printable?.idTipoDocumento?.idTipoDocumento
					: values.idTipoDocumento;
			const idGenero = values.idGenero === null ? printable?.idGenero?.idGenero : values.idGenero;
			const idTipoCliente =
				values.idTipoCliente === null
					? printable?.idTipoCliente?.idTipoCliente
					: values.idTipoCliente;
			const idMercado =
				values.idMercado === null ? printable?.idMercado?.idMercado : values.idMercado;
			const idTipoFacturacion =
				values.idTipoFacturacion === null
					? printable?.idTipoFacturacion?.idTipoFacturacion
					: values.idTipoFacturacion;
			const clienteEspecial =
				values.clienteEspecial === null ? printable?.clienteEspecial : values.clienteEspecial;

			// Create structure to update
			const data = {
				clienteDto: {
					idCliente: printable?.idCliente,
					idTipoDocumento: {
						idTipoDocumento
					},
					identificacion: values.identificacion,
					nombreRazonSocial: values.nombreRazonSocial,
					idGenero: {
						idGenero
					},
					idTipoCliente: {
						idTipoCliente
					},
					idMercado: {
						idMercado
					},

					idTipoFacturacion: {
						idTipoFacturacion
					},
					celular: values.celular,
					telefono: values.telefono,
					sitioWeb: values.sitioWeb,
					correo: values.correo,
					clienteEspecial,
					estado: printable?.estado,
					fechaCreacion: printable?.fechaCreacion,
					fechaModificacion: null,
					usuarioCreacion: printable?.usuarioCreacion,
					usuarioModificacion: COOKIE_USER
				},
				unidadNegocios: dataPutUnitBusinessByClient()
			};

			postItem(data);
		}
	};

	const postItem = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.update.general', 'Se va a editar el registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					RequestUseCase.put(endpoints.clients.updateClient, data)
						.then((res) => {
							associatePrices(res.cliente.idCliente, bodyPrices, resolve, reject);
							CustomAlert('confirm_msg', {
								icon: 'success',
								title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
								text: formatterText(
									'alert.message.confirm.updated.general',
									'El registro se ha actualizado correctamente'
								),
								confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
								allowOutsideClick: false,
								executeFunction: () => navigate(paths.clients)
							});
						})
						.catch((err) => {
							if (err.response?.data?.message) {
								HandleOnError(formatterText(err.response?.data?.message));
							} else {
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
						});
				});
			}
		});
	};

	// associate principal price list
	const associatePrices = (id, body, res, err) => {
		RequestUseCase.post(endpoints.listPriceClient.updateListPriceClient, body)
			.then(() => {
				customSB(
					'success',
					'snackbar.success.create.principal.price.list',
					'Se ha asociado la lista de precios principal al cliente'
				);
			})
			.catch(() => {
				err(
					HandleOnError(
						formatterText('alert.message.failed.general', 'Error al asociar la lista de precios.')
					)
				);
			});
	};

	// check if the idMarket updates
	useEffect(() => {
		if (myForm.fields?.idMercado?.value !== null && myForm.fields?.idMercado?.value !== undefined) {
			setClearValue(false);
			const result = [];
			RequestUseCase.get(
				endpoints.businessUnit.getBusinessUnitByMarket(myForm.fields.idMercado.value)
			)
				.then((resp) => {
					resp.forEach((item) => {
						if (item.estado === 1) {
							result.push({ label: item.nombre, value: item.idUnidadNegocio });
						}
					});
					setPrintable({
						...printable,
						idUnidadNegocio: {
							idUnidadNegocio: null
						}
					});
					setUpdateUnitBusiness(true);
					setClearValue(true);
				})
				.catch(() => {
					HandleOnError('No hay unidades asociadas');
				});
			setUnits(result);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [myForm.fields?.idMercado?.value]);

	// reset second prices if change prinicpal price updates
	useEffect(() => {
		// avoid the unnecessary execution of the function when is set tje data
		if (auxData != null && auxData != undefined) {
			// check if the list prices associations are empty
			const listPricesPrintable = JSON.parse(localStorage.getItem('ListPrices'));
			if (listPricesPrintable?.length === 0) {
				createPrincipalPriceList();
			} else {
				updateSelectPriceLists(auxData?.idListaPrecios);
			}
		}
	}, [auxData]);

	const handleAddBusinessUnit = (form) => {
		const value = form.fields?.idUnidadNegocio.value;
		const filter = units
			.map((unit) => ({ nombre: unit.label, idUnidadNegocio: unit.value }))
			.find((item) => value === item.idUnidadNegocio);
		if (filter) {
			const newData = [...dataTableBusinessUnit, filter];
			const dataUniq = _.uniqBy(newData, 'idUnidadNegocio');
			setDataTableBusinessUnit(dataUniq);
			setDataTable(dataUniq);
		}
	};

	const dataPutUnitBusinessByClient = () => {
		const unitsBusinessValue = dataTableBusinessUnit.map((item) => ({
			idUnidadNegocio: item.idUnidadNegocio
		}));

		return unitsBusinessValue;
	};
	return {
		selectData,
		loadingData,
		myForm,
		handleSubmit,
		printable,
		marketsByCountry,
		units,
		loading,
		updateUnitBusiness,
		updateSelectPriceLists,
		resetPLP,
		listPrices,
		secondaryPrices,
		disabledLS,
		selectValues,
		handleAddBusinessUnit,
		dataTableBusinessUnit,
		titlesTableBusinessUnit,
		handleOpen,
		handleDelete,
		getSucursales,
		getSucursalesMap,
		currentDataTable,
		currentBranchOffice,
		handleChangeSelect,
		handleChangeSucursal,
		handleAddBranchOffice,
		otherCurrentDataTable,
		getAsesors,
		getAsesorsMap,
		currentAsesor,
		handleChangeSelectAsesor,
		idTipoDocumentoAsesorRef,
		generoRef,
		idCargoRef,
		handleChangeAsesor,
		handleAddAsesor,
		error,
		setError,
		altErr,
		setAltErr,
		handleBlurMailAsesor,
		handleBlurMailAltAsesor,
		handleChangeMulti,
		clearValue,
		formatterText,
		setSelectedTable,
		auxData,
		titlesTableBranchOffice,
		titlesTableAdvisers,
		setCurrentAsesor,
		idMunicipioRef,
		archivos,
		handleClose,
		navigate,
		selectedTable,
		setCurrentFiles,
		uploadNewFile,
		open
	};
}
