import { useState } from 'react';

export default function DynamicHeadNotificationsViewModel({ setRowsPerPage, setPage }) {
	const [open, setOpen] = useState(false);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};
	return { open, handleChangePage, handleChangeRowsPerPage, handleClose };
}
