// Custom hook to show a message in the snackbar when the error occurs and load the data
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
// libs to show a message in the snackbar
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
/**
 * @description Custom hook to show a message in the snackbar when the error occurs and load the data
 * @param {boolean} initialValue - Initial value of the state
 */
const useGetData = (initialValue = false) => {
	/* Loading Screen status */
	const [loading, setLoading] = useState(initialValue);
	const [error, setError] = useState(initialValue);
	const [success, setSuccess] = useState(initialValue);

	/* Snackbar status */
	const [open, setOpen] = useState(false);

	const handleClick = () => setOpen(true);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	const toggleLoading = (value) => setLoading(value);

	const toggleError = (value) => setError(value);

	const toggleSuccess = (value) => setSuccess(value);

	// display message in the snackbar
	const displayMessage = (typeValue, messageValue, codeLang) => {
		return (
			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={typeValue} sx={{ width: '100%' }}>
					<FormattedMessage id={codeLang} defaultMessage={messageValue} />
				</Alert>
			</Snackbar>
		);
	};

	// function to show loading
	const displayLoading = () => {
		return (
			<Box sx={{ width: '100%' }}>
				<LinearProgress />
			</Box>
		);
	};

	return {
		loading,
		error,
		success,
		toggleLoading,
		toggleError,
		toggleSuccess,
		handleClick,
		displayMessage,
		displayLoading
	};
};

export default useGetData;
