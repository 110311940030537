export const AREA_IDS = {
	'Gestión de usuarios y configuración': 1,
	'Gestión de Compras': 2,
	'Notificaciones y Alertas': 3,
	'Gestión de Servicios': 4
};

export const areaOptions = (formatterText) => [
	{
		value: 'Gestión de usuarios y configuración',
		label: formatterText(
			'select.placeholder.configuration.users.management',
			'Gestión de usuarios y configuración'
		)
	},
	{
		value: 'Gestión de Compras',
		label: formatterText('select.placeholder.purchases.management', 'Gestión de Compras')
	},
	{
		value: 'Gestión de Servicios',
		label: formatterText('select.placeholder.services.management', 'Gestión de Servicios')
	},
	{
		value: 'Notificaciones y Alertas',
		label: formatterText('select.placeholder.alerts.notifications', 'Notificaciones y Alertas')
	}
];
