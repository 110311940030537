import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';
//Import hooks
import useLangv2 from 'hooks/useLangv2';
import { useNavigate } from 'react-router-dom';
//Import services
import { MODULES_NAME } from 'common/validators/PermissionsConstants';
import { usePermissionContext } from 'context/PermissionContext';
import { CreateMailFormViewModel } from './components';
import { AREA_IDS } from 'constants/mails';

export default function CreateMailViewModel() {
	const { endpoints, RequestUseCase } = useAxios();
	const navigate = useNavigate();

	// use Hook of language v2
	const { formatterText } = useLangv2();
	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();

	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleSubmit = (values, actions) => {
		getEmail(values, actions);
	};

	const {
		formik,
		parseDataToFormik,
		formikInitialValues,
		setFormikInitialValues,
		parseDataToSubmit
	} = CreateMailFormViewModel({
		onSubmit: handleSubmit,
		formatterText
	});

	const permissionsAccessGaranted = () => {
		permissionsAccess(MODULES_NAME.mailManagement); //permiso categorias servicio
	};

	useEffect(() => {
		permissionsAccessGaranted();
	}, [permissions]);

	useEffect(() => {
		getEmailData(AREA_IDS[formik.values.area.replaceAll(/\s{2,}/gi, ' ')]);
	}, [formik.values.area]);

	const getEmail = async (values, actions) => {
		actions.setSubmitting(true);
		RequestUseCase.get(endpoints.mail.getAllMails)
			.then((response) => {
				const areaAux = [];
				response.map((user) => {
					areaAux.push(user.area);
				});

				if (areaAux.includes(formik.values.area)) {
					putEmail(values, actions);
				} else {
					HandleOnError('El nombre no existe, solo se pueden crear con las 4 áreas asignadas');
					actions.setSubmitting(false);
				}
			})
			.catch((error) => {
				console.log(error);
				actions.setSubmitting(false);
			});
	};

	/* Crea un nuevo correo */
	const putEmail = (values, actions) => {
		const data = parseDataToSubmit(values);
		RequestUseCase.put(endpoints.mail.updateMail, data)
			.then(() => {
				onSucess('Se actualizó el correo.');
			})
			.catch((error) => {
				console.log(error);
				HandleOnError(formatterText('catch.error.text'));
			})
			.finally(() => {
				actions.setSubmitting(false);
			});
	};

	const onSucess = (sucess) => {
		CustomAlert('short_msg', {
			icon: 'success',
			title: 'Acción realizada correctamente',
			text: sucess
		});
	};

	useEffect(() => {
		getEmailData(1);
	}, []);

	const getEmailData = async (id) => {
		RequestUseCase.get(endpoints.mail.getMailById(id))
			.then((res) => {
				const newFormikInitialValues = parseDataToFormik({
					formikInitialValues,
					dataByServer: { ...res, idCorreoUsuario: id }
				});
				setFormikInitialValues(newFormikInitialValues);
				formik.resetForm({
					values: newFormikInitialValues
				});
				setTimeout(() => {
					formik.submitForm();
					formik.validateForm();
				}, 50);
			})
			.catch(() => {});
	};

	return {
		formatterText,
		formik,
		handleClose,
		handleOpen,
		navigate,
		open,
		permittedActions
	};
}
