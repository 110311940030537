import useAxios from 'hooks/useAxios';
import { useContext, useEffect } from 'react';
// Import Context
import { useSeachContext } from 'context/SearchContext';
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import Hooks
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
//Import Utils
import SortData from 'components/utils/SortData';
// Import services
import endpoints from 'services/api';

export default function ProductCategoryTableViewModel() {
	const { RequestUseCase } = useAxios();

	// hook to loading data
	const {
		loading,
		error,
		toggleLoading,
		toggleError,
		handleClick,
		displayMessage,
		displayLoading
	} = useGetData();

	const { setDataTable } = useSeachContext();
	const { setCurrentDataTable, setResultsTableSearch } = useContext(TableMinimalContext);

	useEffect(() => {
		getDataTable();
	}, []);

	// función para cargar datos
	const getDataTable = () => {
		// show loading
		toggleLoading(true);

		RequestUseCase.get(endpoints.productCategory.getAllProductCategory)
			.then((data) => {
				const newArray = [];
				data.forEach((item) => handleStructureItems(newArray, item));
				const sortedArray = SortData(newArray, 'asc');
				setDataTable(sortedArray);
				// show loading
				toggleLoading(false);
			})
			.catch(() => {
				toggleError(!error);
				handleClick();
			});
	};

	const handleDeleteItem = (rowId) => {
		const deleteItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.productCategory.deleteProductCategory(rowId))
				.then((res) => {
					const newArray = [];
					res.forEach((item) => {
						handleStructureItems(newArray, item);
					});
					const sortedArray = SortData(newArray, 'asc');
					setDataTable(sortedArray);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return deleteItemPromise;
	};

	const handleEditStateItem = (body) => {
		const updateItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.productCategory.updateProductCategory, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return updateItemPromise;
	};

	const handleStructureItems = (newArray, item) => {
		newArray.push({
			id: item.idCategoriaProducto,
			nombre: item.nombre,
			descripcion: item.descripcion,
			codigo: item.codigo,
			estado: item.estado,
			objeto: { ...item }
		});
	};

	// use Hook of language v2
	const { formatterText } = useLangv2();

	const titles = [
		formatterText('table.title.categoryName', 'Nombre categoría'),
		formatterText('table.title.description', 'Descripción'),
		formatterText('table.title.code', 'Código'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];
	return {
		displayLoading,
		displayMessage,
		error,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		loading,
		titles,
		setCurrentDataTable,
		setResultsTableSearch
	};
}
