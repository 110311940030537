import React from 'react';
import 'styles/footer.css';

import useLang from 'hooks/useLang';

export default function Footer() {
	//  Call hook of language
	const { formatterText } = useLang();

	return (
		<footer className="footer">
			<section className="footer-container-row">
				<h2 className="p-styles">{formatterText('label.text.contact')}</h2>
				<p className="spacing-footer">
					{formatterText('label.text.Write')}
					<a href="mailto:hola@sosasistencia.com" className="no-underline email-footer">
						hola@sosasistencia.com
					</a>
				</p>
			</section>
			<section className="footer-container-row">
				<p>Colombia:</p>
				<a href="tel:+57 3009124554">+57 300 912 4554</a>
				<a href="tel:+57 3176595965">+57 317 659 5965</a>
			</section>
			<section className="footer-container-row">
				<p>Chile:</p>
				<a href="tel:+56 232103190">+56 2 3210 3190</a>
				<a href="tel:+56 941511907">+56 9 4151 1907</a>
			</section>
			<section className="footer-container-row">
				<p> {formatterText('label.text.Us')}</p>
				<a href="tel:+1 786 840 0604">+1 786 840 0604</a>
				<a href="tel:+1 786 840 0604">+1 786 840 0604</a>
			</section>
			<section className="footer-container-row">
				<h2 className="p-styles">{formatterText('label.text.page')}</h2>
				<a href="https://www.sosasistencia.com/contacto/">
					https://www.sosasistencia.com/contacto/
				</a>
			</section>
			<section className="footer-container-row">
				<h2 className="p-styles">{formatterText('label.text.Information')}</h2>
				<a className="no-underline" href="https://www.sosasistencia.com/politica-privacidad/">
					{formatterText('label.text.Policy')}
				</a>
			</section>
		</footer>
	);
}
