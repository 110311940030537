import PropTypes from 'prop-types';

export const UpdateModalItemProps = {
	onClose: PropTypes.func.isRequired,
	productCategories: PropTypes.array.isRequired,
	selectValues: PropTypes.object.isRequired,
	setSelectValues: PropTypes.func.isRequired,
	loadingCategories: PropTypes.bool.isRequired,
	getDataFromMinimalTable: PropTypes.func.isRequired,
	id: PropTypes.string.isRequired,
	cat: PropTypes.string.isRequired
};
