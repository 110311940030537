import React from 'react';
// Import Contexts
// Import Hooks
// Import Components
import SelectorGrid from 'common/selects/SelectorGrid';
// Import Libs
import { FormattedMessage } from 'react-intl';
// Import Services
import CreateTimeZone from '../CreateTimeZone';
// Import styles
import styles from './AddTimeZone.module.css';
import { AddTimeZoneProps } from './AddTimeZone.props';
import AddTimeZoneViewModel from './AddTimeZone.viewModel';

export default function AddTimeZone({ getTimeZoneByIdAgenda }) {
	const { show, setShow, selectTimeZone, selectIds, setSelectIds, getAllTimeZone, addTimeZone } =
		AddTimeZoneViewModel({ getTimeZoneByIdAgenda });

	return (
		<section>
			<span className="title-table">
				<FormattedMessage
					id="text.shedule.associate.time.zone.schedule"
					defaultMessage="Asociar franjas horarias a la agenda"
				/>
			</span>
			<section className={styles.wrapForm}>
				<label className={styles.dFlex}>
					<span className={styles.wrapFormText}>
						<FormattedMessage id="text.shedule.time.zone" defaultMessage="Franjas horarias" />
					</span>
					<section className={styles.labelSelect}>
						<SelectorGrid
							name="idFranjaHoraria"
							data={selectTimeZone}
							placeholder={
								<FormattedMessage
									id="input.placeholder.select"
									defaultMessage="Selecciona una opción"
								/>
							}
							dataValue={selectIds.idFranjaHoraria}
							setterFunction={setSelectIds}
						/>
					</section>
					<button className={styles['btn-action-primary']} onClick={(e) => addTimeZone(e)}>
						<FormattedMessage id="text.associate" defaultMessage="Asociar" />
					</button>
					<button
						className={styles['btn-action-primary']}
						onClick={(e) => {
							e.preventDefault();
							setShow(!show);
						}}
					>
						{show ? (
							<FormattedMessage id="text.hide" defaultMessage="Ocultar" />
						) : (
							<FormattedMessage
								id="text.shedule.create.time.zone"
								defaultMessage="Crear franja horaria"
							/>
						)}
					</button>
				</label>
			</section>

			{show && <CreateTimeZone getAllTimeZone={getAllTimeZone} hideComponent={setShow} />}
		</section>
	);
}

AddTimeZone.propTypes = AddTimeZoneProps;
