import React from 'react';
import PropTypes from 'prop-types';

// Import Libs
import { FormikProvider } from 'formik';

// Import Components
import { SelectMultiTag } from 'common/selects/SelectMultiTag';
import { InputCheckbox } from 'common/inputs/InputCheckbox';
import { Input } from 'common/inputs/Input';
import { Button } from 'common/Button';

// Import Paths
import paths from 'services/paths';

// Import ViewModel

export const UpsertSectorForm = ({
	edit = false,
	formik,
	formatterText,
	handleAddRole,
	handleRemoveRole,
	navigate,
	setToggleSelector,
	toggleSelector
}) => {
	const { errors, handleBlur, handleChange, handleSubmit, isSubmitting, isValid, touched, values } =
		formik;

	return (
		<FormikProvider value={formik}>
			<form className="form-responsive margin-top" noValidate onSubmit={handleSubmit}>
				<Input
					error={errors.nombre}
					labelText={formatterText('p.sector')}
					name={'nombre'}
					onBlur={handleBlur}
					placeholder={formatterText('p.sector')}
					onChange={handleChange}
					touched={touched.nombre}
					type={'text'}
					value={values.nombre}
					active="off"
				/>
				<Input
					error={errors.codigoSector}
					labelText={formatterText('input.placeholder.sectorCode')}
					name={'codigoSector'}
					onBlur={handleBlur}
					placeholder={formatterText('input.placeholder.sectorCode')}
					touched={touched.codigoSector}
					type={'text'}
					onChange={handleChange}
					lowerCase
					value={values.codigoSector}
				/>
				{edit && (
					<InputCheckbox
						activeText={formatterText('p.active', 'Activo')}
						labelText={formatterText('table.title.role', 'Rol')}
						name={'active'}
						value={values.active}
						onChange={handleChange}
						unActiveText={formatterText('p.unActive', 'No activo')}
					/>
				)}
				<SelectMultiTag
					error={errors.permissions}
					handleAdd={handleAddRole}
					handleRemove={handleRemoveRole}
					labelText={formatterText('p.asMunicipality', 'Municipio asociado')}
					name={'permissions'}
					setToggleSelector={setToggleSelector}
					textButtonAdd={formatterText('p.addMunicipality', 'Añadir municipio +')}
					toggleSelector={toggleSelector}
					value={values.permissions}
					maxLength={1}
					sector
				/>
				<Input
					error={errors.kilometros}
					labelText={formatterText('p.km.cHToSector')}
					name={'kilometros'}
					onBlur={handleBlur}
					placeholder={formatterText('input.placeholder.km')}
					touched={touched.kilometros}
					type={'text'}
					onChange={handleChange}
					value={values.kilometros}
					active="off"
				/>
				<Input
					area
					error={errors.observaciones}
					labelText={formatterText('p.obser')}
					name={'observaciones'}
					onBlur={handleBlur}
					placeholder={formatterText('input.placeholder.observation')}
					touched={touched.observaciones}
					type={'text'}
					onChange={handleChange}
					value={values.observaciones}
				/>
				<section className="form-responsive-container-buttons">
					<Button
						type="submit"
						className="btn-primary"
						text={formatterText('btn.save.changes', 'Guardar cambios')}
						disabled={!isValid || isSubmitting}
					/>
					<Button
						className="input-cancel"
						text={formatterText('alert.button.cancel.general', 'Cancelar')}
						onClick={() => navigate(paths.sector)}
					/>
				</section>
			</form>
		</FormikProvider>
	);
};

UpsertSectorForm.propTypes = {
	edit: PropTypes.bool,
	formik: PropTypes.object.isRequired,
	formatterText: PropTypes.func,
	handleAddRole: PropTypes.func,
	handleRemoveRole: PropTypes.func,
	navigate: PropTypes.func,
	setToggleSelector: PropTypes.func,
	toggleSelector: PropTypes.bool
};
