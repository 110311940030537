const sortFunction = (newArray, type) => {
	let sortedArray;
	if (type === 'asc') {
		// sort new array by created_at asc
		sortedArray = newArray.sort((a, b) => {
			if (a.objeto.fechaCreacion < b.objeto.fechaCreacion) {
				return -1;
			}
			if (a.objeto.fechaCreacion > b.objeto.fechaCreacion) {
				return 1;
			}
			return 0;
		});
	} else if (type === 'desc') {
		// sort new array by created_at desc
		sortedArray = newArray.sort((a, b) => {
			if (a.objeto.fechaCreacion > b.objeto.fechaCreacion) {
				return -1;
			}
			if (a.objeto.fechaCreacion < b.objeto.fechaCreacion) {
				return 1;
			}
			return 0;
		});
	}
	return sortedArray;
};

export default sortFunction;
