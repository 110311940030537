import PropTypes from 'prop-types';

export const Button = ({
	className = 'btn-primary',
	disabled = false,
	isLoading = false,
	onClick = null,
	text,
	type = 'button',
	iconRight = null
}) => {
	return (
		<button
			type={type}
			className={disabled ? 'btn-disabled' : className}
			disabled={disabled || isLoading}
			onClick={onClick}
		>
			{isLoading ? 'loading' : text}
			{iconRight}
		</button>
	);
};

Button.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	isLoading: PropTypes.bool,
	onClick: PropTypes.func,
	text: PropTypes.string.isRequired,
	type: PropTypes.string
};
