import React from 'react';
import { MdDelete } from 'react-icons/md';

const BtnDelete = ({ item, handleDelete }) => {
	return (
		<td className="minimal-table__actions" align="center">
			<MdDelete size={25} color="gray" cursor="pointer" onClick={() => handleDelete(item)} />
		</td>
	);
};

export default BtnDelete;
