import useAxios from 'hooks/useAxios';
import React, { useContext, useEffect, useState } from 'react';
// Mui Cell row style
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import BtnActions from 'common/minimalTables/BtnActions';
import { TableMinimalContext } from 'context/TableMinimalContext';
import Cookie from 'js-cookie';
// Import services
import endpoints from 'services/api';
//Import Hooks
import { useSeachContext } from 'context/SearchContext';
import useLangv2 from 'hooks/useLangv2';
import { useParams } from 'react-router-dom';

const RowTypeService = ({
	item,
	i, // index
	setDialog,
	closeDialog,
	handleOpen,
	getDataMiniTable
}) => {
	const { RequestUseCase } = useAxios();
	const { setDataTable, searchResults = [] } = useSeachContext();
	const [rowState, setRowState] = useState(item.estado);
	const { currentDataTable, setCurrentDataTable, setResultsTableSearch, setModalData } =
		useContext(TableMinimalContext);
	const { formatterText, customSB } = useLangv2();

	// Manage axios requests
	const { successRemoveItem } = useLangv2();

	// Hooks to get the params
	const { id } = useParams();

	// Helps to re-render the component when the state changes
	useEffect(() => {
		setRowState(item.estado);
	}, [searchResults]);

	const handleDelete = (row) => {
		if (typeof row.idTipoServicio === 'number') {
			RequestUseCase.delete(endpoints.typeService.deleteTypeService(row.idTipoServicio))
				.then(() => {
					const newMinimalRows = currentDataTable.filter((et) => {
						return et.idTipoServicio !== row.idTipoServicio;
					});
					successRemoveItem();
					setCurrentDataTable(newMinimalRows);
					setResultsTableSearch(newMinimalRows);
					getDataMiniTable(id);
				})
				.catch((err) => {
					if (err.response?.data?.message) {
						customSB('error', formatterText(err.response?.data?.message));
					} else {
						customSB(
							formatterText(
								'error',
								'snackbar.error.process.failed.general',
								'Error al realizar el proceso. Intentalo en otro momento.'
							)
						);
					}
				});
		} else {
			const newMinimalRows = currentDataTable.filter((et) => {
				return et.idTipoServicio !== row.idTipoServicio;
			});
			setCurrentDataTable(newMinimalRows);
			setResultsTableSearch(newMinimalRows);
			setDataTable(newMinimalRows);
		}
		closeDialog();
	};

	const handleEdit = (row) => {
		handleOpen();
		setModalData(row);
	};

	const handleToggle = () => {
		const estado = rowState === 1 ? 0 : 1;
		closeDialog();
		if (typeof item.idTipoServicio === 'number') {
			console.log(typeof item.idTipoServicio);
			const BODY = {
				idTipoServicio: item.idTipoServicio,
				idCategoriaServicio: {
					idCategoriaServicio: id
				},
				nombre: item.objeto.nombre,
				descripcion: item.objeto.descripcion,
				codigo: item.objeto.codigo,
				estado,
				fechaRegistro: item.objeto.fechaRegistro,
				fechaModificacion: null,
				usuarioCreacion: item.objeto.usuarioCreacion,
				usuarioModificacion: Cookie.get('idUsuario')
			};

			RequestUseCase.put(endpoints.typeService.updateTypeService, BODY).then((data) => {
				const { error, response } = data;
				getDataMiniTable(id);
				if (error === '200') {
					console.log(response);
					setRowState(estado);
					setCurrentDataTable(
						currentDataTable.map((et) => {
							if (et.idTipoServicio === item.idTipoServicio) {
								return { ...et, estado };
							}
							return et;
						})
					);
				}
			});
		} else {
			setRowState(estado);
			setCurrentDataTable(
				currentDataTable.map((et) => {
					if (et.idTipoServicio === item.idTipoServicio) {
						return { ...et, estado };
					}
					return et;
				})
			);
			setResultsTableSearch(
				currentDataTable.map((et) => {
					if (et.idTipoServicio === item.idTipoServicio) {
						return { ...et, estado };
					}
					return et;
				})
			);
		}
	};

	return (
		<TableRow key={i}>
			<TableCell>{item?.nombre}</TableCell>
			<TableCell>{item?.descripcion}</TableCell>
			<TableCell>{item?.codigo}</TableCell>
			<TableCell>{item?.categorias}</TableCell>
			<TableCell className="enabled" align="center">
				{rowState === 1 ? (
					<div
						className="btn-state pointer"
						onClick={() =>
							setDialog({
								text: `${formatterText('dialog.title.delete.mid.first')} ${
									item.nombre
								} ${formatterText('dialog.title.delete.mid.end.inactive')}`,
								active: true,
								funcion: () => handleToggle()
							})
						}
					>
						{formatterText('btn.active')}
					</div>
				) : (
					<div
						className="btn-inactive_state pointer"
						onClick={() => {
							setDialog({
								text: `${formatterText('dialog.title.delete.mid.first')} ${
									item.nombre
								} ${formatterText('dialog.title.delete.mid.end.active')}`,
								active: true,
								action: 'active',
								funcion: () => handleToggle()
							});
						}}
					>
						{formatterText('btn.inactive')}
					</div>
				)}
			</TableCell>
			<BtnActions
				item={item}
				rowState={rowState}
				setDialog={setDialog}
				handleEdit={handleEdit}
				handleDelete={handleDelete}
			/>
		</TableRow>
	);
};

export default RowTypeService;
