import React from 'react';
// Import Libs
import { InfoWindow, Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import useLangv2 from 'hooks/useLangv2';

const MarkerComponent = ({ i, center, techName, techPhone, techRisk, zoneService }) => {
	const { formatterText } = useLangv2();
	if (zoneService) {
		return <Marker key={i} position={center} />;
	}
	return (
		<InfoWindow position={center}>
			<div>
				<span>
					{formatterText('table.name.search.technical')}: {techName}
				</span>
				<br />
				<span>
					{formatterText('p.movile.phone')}: {techPhone}
				</span>
				{techRisk && (
					<>
						<br />
						<span>
							{formatterText('info.swal.risk.prior.title')}: {hashRisk[techRisk]}
						</span>
					</>
				)}
			</div>
		</InfoWindow>
	);
};

const hashRisk = {
	1: 'Baja',
	2: 'Media',
	3: 'Alta'
};

MarkerComponent.propTypes = {
	i: PropTypes.number,
	center: PropTypes.object,
	techName: PropTypes.string,
	techPhone: PropTypes.string
};

export default MarkerComponent;
