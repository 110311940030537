import React from 'react';
import { SearchWrapper } from 'context/SearchContext';
import { PermissionWrapper } from 'context/PermissionContext';
import AuditComponent from './Audits.component.jsx';

const Audits = () => {
	return (
		<SearchWrapper>
			<PermissionWrapper>
				<AuditComponent />
			</PermissionWrapper>
		</SearchWrapper>
	);
};

export default Audits;
