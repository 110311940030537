import React, { useState } from 'react';
// Mui Cell row style
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import BtnActions from 'common/minimalTables/BtnActions';

const RowUnityBusiness = ({
	item,
	i, // index
	setDialog,
	handleDelete,
	type
}) => {
	const [rowState] = useState(item.estado);

	return (
		<TableRow key={i}>
			<TableCell>{item.unityName}</TableCell>
			<TableCell>{item.code}</TableCell>
			<TableCell>{item.market}</TableCell>
			<TableCell>{item.country}</TableCell>

			<BtnActions
				item={item}
				rowState={rowState}
				setDialog={setDialog}
				handleDelete={handleDelete}
				type={type}
			/>
		</TableRow>
	);
};

export default RowUnityBusiness;
