import useAxios from 'hooks/useAxios';
import { useForm } from '@formiz/core';
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';
import useLangv2 from 'hooks/useLangv2';
import Cargo from 'models/Cargo';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import paths from 'services/paths';
import Swal from 'sweetalert2';

export default function CreatePositionViewModel() {
	const { endpoints, RequestUseCase } = useAxios();
	const navigate = useNavigate();

	// Formiz object
	const myForm = useForm();

	// Datos de un formulario
	const [formData, setFormData] = useState(new Cargo());

	// use Hook of language v2
	const { formatterText } = useLangv2();

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();

		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise(() => {
					RequestUseCase.post(endpoints.cargos.addCargo, formData)
						.then(() => {
							CustomAlert('confirm_msg', {
								icon: 'success',
								title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
								text: formatterText(
									'alert.message.confirm.created.general',
									'El registro se ha creado correctamente'
								),
								confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
								allowOutsideClick: false,
								executeFunction: () => navigate(paths.positions)
							});
						})
						.catch((err) => {
							if (err.response.status === 412) {
								HandleOnError(
									formatterText('alert.message.code.error.general', 'El código ya existe')
								);
							} else {
								HandleOnError(
									formatterText(
										'alert.message.failed.general',
										'Error al crear el cargo, intenta de nuevo.'
									)
								);
							}
						});
				});
			}
		});
	};

	const handleFormChange = (values) => {
		setFormData((prevData) => ({ ...prevData, ...values }));
	};
	return {
		formatterText,
		handleFormChange,
		handleSubmit,
		myForm,
		navigate
	};
}
