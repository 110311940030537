import React from 'react';
import { useNavigate } from 'react-router-dom';
import paths from 'services/paths';

export default function SimpleHeader() {
	const navigate = useNavigate();

	return (
		<nav className="header">
			<section className="header-container-menu">
				<img
					className="header__logo"
					src={require('assets/logo.png')}
					onClick={() => navigate(paths.login)}
				/>
			</section>
		</nav>
	);
}
