import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ProviderAuth } from 'hooks/useAuth';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import './index.css';
import 'styles/formResponsive.css';
import 'styles/wrapForm.css';
import 'styles/grid.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

/**
 * Elimina los consoleLog en produccion
 */
// if (process.env.NODE_ENV === "development") {
//   console.log = () => {};
//   console.error = () => {};
//   console.debug = () => {};
// }

root.render(
	<ProviderAuth>
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<App />
		</MuiPickersUtilsProvider>
	</ProviderAuth>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
