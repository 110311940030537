// import useLang from "hooks/useLang";
import React from 'react';
import paths from 'services/paths';
// Call service
// Picker imports
import hexRgb from 'hex-rgb';
import { SketchPicker } from 'react-color';
import { FormattedMessage } from 'react-intl';
import 'styles/colorPicker.css';
import UpdateStageViewModel from './UpdateStage.viewModel';

export default function UpdateStage() {
	const {
		formatterText,
		handleSubmit,
		colorHexCode,
		colorRGB,
		handleClick,
		displayColorPicker,
		handleClose,
		setColorHexCode,
		setColorRGB,
		navigate,
		formData,
		handleInputCaracter,
		errorName,
		handleErrorCode,
		handleErrorName,
		handlerTextDescription,
		handlerTextCode,
		codeError,
		active,
		setActive
	} = UpdateStageViewModel();

	return (
		<form className="form-responsive" onSubmit={handleSubmit}>
			<section className="form-responsive-container-information">
				<section className="form-responsive-information__option">
					<h3 className="p-styles">
						<FormattedMessage
							id="table.title.service.stage"
							defaultMessage="Nombre etapa de servicio"
						/>
					</h3>
					<input
						className="input-primary"
						type="text"
						name="nombre"
						value={formData.nombre}
						onChange={handleInputCaracter}
						placeholder={formatterText('table.title.service.stage', 'Nombre etapa de servicio')}
						maxLength="100"
						onBlur={handleErrorName}
					/>
				</section>
				{errorName && (
					<div
						style={{ fontSize: '0.7rem', color: 'red', marginTop: '-30px', marginRight: '280px' }}
					>
						{errorName}
					</div>
				)}
				<section className="form-responsive-information__option">
					<h3 className="p-styles">
						<FormattedMessage id="table.title.description" defaultMessage="Descripción" />
					</h3>
					<textarea
						className="input-primary-textarea"
						name="descripcion"
						value={formData.descripcion}
						onChange={handlerTextDescription}
						placeholder="Descripción"
						maxLength="200"
					/>
				</section>
				<section className="form-responsive-information__option">
					<h3 className="p-styles">
						<FormattedMessage id="table.title.code" defaultMessage="Código" />
					</h3>
					<input
						className="input-primary"
						type="text"
						name="codigo"
						value={formData?.codigo?.toLowerCase()}
						onChange={handlerTextCode}
						placeholder={formatterText(
							'input.placeholder.max.45.chars',
							'Ingrese hasta 45 caracteres'
						)}
						maxLength="45"
						onBlur={handleErrorCode}
					/>
				</section>
				{codeError && (
					<div
						style={{ fontSize: '0.7rem', color: 'red', marginTop: '-22px', marginRight: '297px' }}
					>
						{codeError}
					</div>
				)}
				<section className="form-responsive-information__option">
					<h3 className="p-styles">
						<FormattedMessage id="table.title.color" defaultMessage="Color" />
					</h3>

					<div className="color-picker-container">
						<h3 className="color-picker-text">{colorHexCode}</h3>
						<button className="btn-container-container" onClick={handleClick}>
							<div
								style={{
									width: '36px',
									height: '14px',
									borderRadius: '2px',
									background: `rgba(${colorRGB.red}, ${colorRGB.green}, ${colorRGB.blue}, ${colorRGB.alpha})`
								}}
							/>
						</button>
						{displayColorPicker ? (
							<div className="picker-position">
								<div className="picker-position-cover" onClick={handleClose} />
								<SketchPicker
									color={colorHexCode}
									onChange={(e) => {
										setColorHexCode(e.hex);
										setColorRGB(hexRgb(e.hex));
									}}
								/>
							</div>
						) : null}
					</div>
				</section>
				<section className="form-responsive-information__option">
					<h3 className="p-styles">
						<FormattedMessage id="table.title.state" defaultMessage="Estado" />
					</h3>
					<label className="form-responsive-label">
						<p className="form-responsive-toggle">
							{active
								? formatterText('p.active', 'Activo')
								: formatterText('p.unActive', 'No activo')}
						</p>
						<label className="switch">
							<input
								checked={active ? true : false}
								onChange={() => {
									setActive(!active);
								}}
								type="checkbox"
							/>
							<span className="slider round"></span>
						</label>
					</label>
				</section>
			</section>
			<section className="form-responsive-container-buttons">
				<button type="submit" className="btn-primary">
					<FormattedMessage id="btn.save" defaultMessage="Guardar" />
				</button>
				<button className="input-cancel" onClick={() => navigate(paths.services)}>
					<FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
				</button>
			</section>
		</form>
	);
}
