import React from 'react';
// Mui Cell row style
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import BtnAssignSearch from 'common/minimalTables/BtnAssignSearch';
import BtnSeeDetails from 'common/minimalTables/BtnSeeDetails';
// Import services

const RowSearchSearchServicesTech = ({
	item,
	i, // index

	handleDetails,
	handleSelect
}) => {
	const handleColor = (item) => {
		const styles = {
			backgroundColor: 'red',
			textAlign: 'center',
			fontWeight: 'bold'
		};
		switch (item) {
			case 1:
				styles.backgroundColor = 'yellow';
				return <div style={styles}>Baja</div>;
			case 2:
				styles.backgroundColor = 'orange';
				return <div style={styles}>Media</div>;

			default:
				return <div style={styles}>Alta</div>;
		}
	};

	return (
		<TableRow key={i}>
			<TableCell>{item.nombre}</TableCell>
			<TableCell>NA</TableCell>
			<TableCell>{handleColor(item.riesgoIncumplimientoAnterior)}</TableCell>
			<TableCell>{handleColor(item.riesgoIncumplimientoSiguiente)}</TableCell>
			<BtnSeeDetails item={item} handleDetails={handleDetails} />
			<BtnAssignSearch item={item} handleSelect={() => handleSelect(item)} />
		</TableRow>
	);
};

export default RowSearchSearchServicesTech;
