// create a custom hook of a useState to toggle a state
import { useState } from 'react';

/**
 *
 * @param {bool} initialValue false
 * @returns an array with the state and a function to change the state
 */
const useToggle = (initialValue = false) => {
	const [value, setValue] = useState(initialValue);
	const toggle = () => setValue(!value);
	return [value, toggle];
};

export default useToggle;
