import useAxios from 'hooks/useAxios';
import dateFormat from 'dateformat';
import xlsx from 'json-as-xlsx';
import React from 'react';
import endpoints from 'services/api';
import paths from 'services/paths';
// Import libs
import { FormattedMessage } from 'react-intl';

const downloadTable = async ({ moduleName, userName, dataTable, RequestUseCase }) => {
	const newDate = dateFormat(new Date(), 'dd-mm-yyyy-HH.MM.ss');
	const settings = {
		fileName: `${moduleName}-${newDate}-${userName}`, // Name of the resulting spreadsheet
		extraLength: 3, // A bigger number means that columns will be wider
		writeOptions: {
			bookType: 'xlsx'
		} // Style options from https://github.com/SheetJS/sheetjs#writing-options
	};
	let newData = [];
	const columnsNames = [];
	let data = [];
	const columnInfo = [];

	const getElementState = (stateId = 0) => {
		let state = 'No hay estado';

		if (stateId === 0) state = 'Inactivo';
		else if (stateId === 1) state = 'Activo';
		else if (stateId === 2) state = 'Inhabilitado';

		return state;
	};

	const urlToString = (url) => {
		return url.replace('/', '');
	};

	function agendas() {
		dataTable.forEach((item) => {
			item.forEach((elem) => {
				newData.push({
					'Fecha inicio': elem.fechaInicio,
					'Fecha fin': elem.fechaFin,
					'Franjas horarias': elem.nombreFranja,
					'Día semana': elem.diaSemana,
					Tipo: elem.tipoAgenda,
					Técnicos: elem.tecnico
				});
			});
		});
	}
	function rol() {
		dataTable.forEach((element) => {
			newData.push({
				Id: element.id,
				'Nombre del Rol': element.nombre,
				'Descripción del Rol': element.descripcion,
				'Fecha de Creación': element.created_at,
				'Fecha de Actualización': element.updated_at,
				'Creado por': element.created_by || 'No hay creado por',
				'Actualizado Por': element.updated_by || 'No hay usuario actualización',
				Estado: getElementState(Number(element.estado))
			});
		});
	}

	function formulario() {
		newData = [dataTable];
		settings.fileName = `${moduleName}-${newDate}-${userName}`;
	}

	function clientes() {
		newData = dataTable;
		settings.fileName = `${moduleName}-${new Date()}-${userName}`;
	}

	function usuario() {
		dataTable.forEach((element) => {
			newData.push({
				'Id Usuario': element.id,
				'Nombre usuario': element.nombre,
				Rol: element.roles,
				Correo: element.mail,
				'Fecha de Creación': element.created_at,
				Estado: getElementState(Number(element.estado))
			});
		});
	}

	function paises() {
		dataTable.forEach((element) => {
			newData.push({
				'Código País': element.codigoPais,
				'Nombre País': element.nombre,
				Prefijo: element.prefijo,
				Estado: getElementState(Number(element.estado)),
				'Fecha Creación': element.fechaCreacion,
				'Fecha Modificación': element.fechaModificacion
			});
		});
	}

	function departamentos() {
		dataTable.forEach((element) => {
			newData.push({
				'Código Departamento': element.codigoDepartamento,
				'Nombre Departamento': element.nombre,
				'Código País': element.codigoPais,
				Estado: getElementState(Number(element.estado)),
				'Fecha Creación': element.fechaCreacion,
				'Fecha Modificación': element.fechaModificacion
			});
		});
	}

	function municipios() {
		dataTable.forEach((element) => {
			newData.push({
				'Código Municipio': element.codigoMunicipio,
				'Nombre Municipio': element.nombre,
				Prefijo: element.prefijo,
				Estado: getElementState(Number(element.estado)),
				Kilómetros: element.kilometros,
				'Nombre Departamento': element.codigoDepartamento,
				'Nombre País': element.nombrePais,
				'Fecha Creación': element.fechaCreacion,
				'Fecha Modificación': element.fechaModificacion,
				'Zona Horaria': element.zonaHorariaGmt
			});
		});
	}

	function sectores() {
		dataTable.forEach((element) => {
			newData.push({
				'Código Sector': element.codigoSector,
				'Nombre Sector': element.nombre,
				'Código Municipio': element.codigoMunicipio,
				'Nombre Departamento': element.nombreDepartamento,
				'Nombre País': element.nombrePais,
				Estado: getElementState(Number(element.estado)),
				Kilómetros: element.kilometros,
				'Fecha Creación': element.fechaCreacion,
				'Fecha Modificación': element.fechaModificacion
			});
		});
	}

	function auditorias() {
		dataTable.forEach((element) => {
			newData.push({
				'Usuario que realizó la acción': element.usuario,
				'Fecha y hora en que se realizó la acción': element.fechaRegistro,
				'Módulo del sistema donde se realizó la acción': element.modulo,
				'Permiso usado para realizar la acción': element.permiso,
				'Ip desde donde se realizó la acción': element.ip,
				Nota: element.query
			});
		});
	}

	function empleado() {
		dataTable.forEach((element) => {
			newData.push({
				'Id Empleado': element.id,
				'Nombre Empleado': element.nombre,
				Identificación: element.documento,
				Estado: getElementState(Number(element.estado)),
				Municipio: element.municipio,
				Cargo: element.cargo,
				'Tipo Contrato': element.tipoContrato,
				'Fecha de Registro': element.fechaRegistro,
				Email: element.objeto.correo,
				'Fecha de Creación':
					dateFormat(element.objeto.fechaRegistro, 'yyyy-mm-dd - h:MM:ss TT') ||
					'No hay fecha creación',
				'Fecha de Modificación':
					dateFormat(element.objeto.fechaModificacion, 'yyyy-mm-dd - h:MM:ss TT') ||
					'No hay fecha de actualización'
			});
		});
	}

	function tercero() {
		dataTable.forEach((element) => {
			newData.push({
				'Id Tercero': element.id,
				'Nombre Tercero': element.nombre,
				'Tipo Documento': element?.tipo_documento || 'No hay tipo de documento',
				'Número Documento': element.documento,
				Municipio: element.municipio,
				Estado: getElementState(Number(element.estado))
			});
		});
	}

	function proveedores() {
		dataTable.forEach((element) => {
			newData.push({
				'Id Proveedor': element.id,
				'Nombre proveedor': element.nombre,
				Identificación: element.identificacion,
				'Sitio Web': element.sitioWeb,
				Teléfono: element.telefono,
				Correo: element.correo,
				Municipio: element.municipio,
				Dirección: element.direccion,
				Estado: element.estado === 1 ? 'Activo' : 'Inactivo'
			});
		});
	}

	function tecnicos() {
		dataTable.forEach((element) => {
			if (
				(element.vencimientoSoat === undefined || element.vencimientoSoat === null) &&
				(element.vencimientoRunt === undefined || element.vencimientoRunt === null) &&
				(element.vencimientoTecnicoMecanico === undefined ||
					element.vencimientoTecnicoMecanico === null) &&
				(element.fechaCreacion !== undefined || element.fechaCreacion !== null)
			) {
				newData.push({
					'Id Técnico': element.id,
					Documento: element.documento,
					'Nombre Técnico': element.nombre,
					Cargo: element.cargo,
					'Tipo Contrato': element.tipoContrato,
					'Fecha Creación': element.fechaCreacion
				});
			} else {
				newData.push({
					'Id Técnico': element.id,
					Documento: element.documento,
					'Nombre Técnico': element.nombre,
					Cargo: element.cargo,
					'Tipo Contrato': element.tipoContrato,
					'Vencimiento SOAT': element.vencimientoSoat,
					'Vencimiento Licencia': element.vencimientoRunt,
					'Vencimiento Técnico Mecánico': element.vencimientoTecnicoMecanico
				});
			}
		});
	}

	function vehicleTechnical() {
		dataTable.forEach((element) => {
			newData.push({
				'Id Vehículo': element.id,
				Documento: element.documento,
				'Nombre Técnico': element.nombre,
				Cargo: element.cargo,
				'Tipo Contrato': element.tipoContrato,
				RUNT: element.runt,
				'Fecha Vencimiento SOAT': element.vencimientoSoat,
				'Fecha Vencimiento Licencia': element.vencimientoRunt,
				'Fecha Vencimiento Técnico Mecánico': element.vencimientoTecnicoMecanico
			});
		});
	}

	function products() {
		let info = [];
		dataTable.forEach((element) => {
			info = element.objeto.precioProductoProveedor;
			if (info.length > 0) {
				info.forEach((item) => {
					newData.push({
						// 'NOMBRE DEL PRODUCTO': element.nombre,
						SKU: element.sku,
						'TIPO DOCUMENTO PROVEEDOR': item.idProveedor.idTipoDocumento.abreviatura,
						'NÚMERO IDENTIFICACIÓN PROVEEDOR': item.idProveedor.identificacion,
						'ABREVIATURA UNIDAD MEDIDA': item.idUnidadMedida.abreviatura,
						COSTO: item.costo,
						IVA: item.iva,
						'CÓDIGO DE MONEDA': item.idMoneda.codigo
						// Estado: getElementState(Number(element.estado)),
					});
				});
			}
		});
	}

	function activityServices() {
		dataTable.forEach((element) => {
			newData.push({
				'Id Actividad': element.id,
				'Nombre Actividad': element.nombre,
				Descripción: element.descripcion,
				SKU: element.sku,
				iva: element.iva,
				'Tipo De Servicio': element.tipoServicios,
				'Categoría Asociada': element.categoriaAsociada,
				'Tiempo De Desarrollo': element.tiempoDesarrollo,
				'Aspecto Técnico': element.actividadTecnica,
				'Etapa del Servicio': element.etapaServicios,
				Estado: getElementState(Number(element.estado))
			});
		});
	}

	function listPriceServices() {
		dataTable.forEach((element) => {
			newData.push({
				'CÓDIGO LISTA PRECIOS': element.codigo,
				'SKU ACTIVIDAD': element.sku,
				'ETAPA SERVICIO': element.etapaServicio,
				'CÓDIGO DE TIPO SERVICIO': element.tipoServicio,
				'ABREVIATURA (unidad de medida)': element.abreviatura,
				VALOR: element.valor,
				IMPUESTO: element.impuesto
			});
		});
	}

	function servicios() {
		dataTable.forEach((element) => {
			const billNumber = element?.objeto?.conciliacionFactura
				?.map((conciliacion) => {
					return conciliacion.factura;
				})
				.join(', ');

			newData.push({
				'# Ticket del servicio': element.nombre,
				Expediente: element.expediente,
				'Nombre del cliente principal': element.nombreCliente,
				Identificación: element.documentClient,
				'Teléfono del cliente principal': element.telefonoCliente,
				'Nombre de usuario': element.userName,
				'Teléfono del usuario': element.userPhone,
				'Fecha y hora de la cita': element.fechaHoraCita,
				'Técnico asignado': element.techAssing,
				'Actividades asociadas': element.activities,
				Ciudad: element.city,
				Dirección: element.address,
				'Categoría de servicio': element.categoryService,
				'Tipo de servicio': element.typeService,
				'Estado del servicio': element.stateService,
				'Servicio especial': element.special,
				'Fecha y hora de creación del servicio': element.datetimeCreation,
				Conciliaciones: billNumber || 'No tiene facturas asociadas'
			});
		});
	}

	const decisionTree = [
		{ opcion: 'rol', function: rol },
		{ opcion: 'formularios', function: formulario },
		{ opcion: 'cliente', function: clientes },
		{ opcion: 'usuario', function: usuario },
		{ opcion: 'países', function: paises },
		{ opcion: 'departamentos', function: departamentos },
		{ opcion: 'municipios', function: municipios },
		{ opcion: 'sectores', function: sectores },
		{ opcion: 'auditorías', function: auditorias },
		{ opcion: 'empleado', function: empleado },
		{ opcion: 'tercero', function: tercero },
		{ opcion: 'proveedores', function: proveedores },
		{ opcion: urlToString(paths.technical), function: tecnicos },
		{ opcion: urlToString(paths.vehicleTechnical), function: vehicleTechnical },
		{ opcion: urlToString(paths.products), function: products },
		{ opcion: 'gestion actividades', function: activityServices },
		{ opcion: 'agendas', function: agendas },
		{
			opcion: urlToString(paths.listPriceServices),
			function: listPriceServices
		},
		{ opcion: 'servicios', function: servicios }
	];

	const audit = async (bodyAudit) => {
		try {
			await RequestUseCase.post(endpoints.audit.saveAudits, bodyAudit);
		} catch (error) {
			console.log('audit error', error);
		}
	};

	const optionValid = decisionTree.find((elem) => elem.opcion === moduleName.toLowerCase());

	// catalogo de zonas es modulo de paises departamentos, municipios
	if (optionValid) {
		await optionValid.function();
		// endpoint de auditoria  moduleName, EXPORTAR, nota
		const nameAlt =
			optionValid.opcion === 'países' ||
			optionValid.opcion === 'departamentos' ||
			optionValid.opcion === 'municipios' ||
			optionValid.opcion === 'sectores'
				? 'CATALOGO ZONA'
				: undefined;
		const bodyAudit = {
			nombreModulo: nameAlt || moduleName,
			nombrePermiso: 'EXPORTAR',
			nota: `/exportar ${optionValid.opcion}`
		};

		Object.keys(newData[0] || {}).forEach((key) => {
			columnsNames.push(key);
		});

		columnsNames.forEach((element) => {
			columnInfo.push({
				label: element,
				value: element
			});
		});
		data = [
			{
				sheet: moduleName,
				columns: columnInfo,
				content: newData
			}
		];
		await xlsx(data, settings);
		await audit(bodyAudit);
	}
};

const ExportJsonFile = ({ moduleName = 'Default', userName = 'Default', dataTable }) => {
	const { RequestUseCase } = useAxios();
	return (
		<button
			className={`${dataTable.length == 0 ? 'disabled' : ''} btn-export`}
			disabled={dataTable.length == 0}
			onClick={() => downloadTable({ moduleName, userName, dataTable, RequestUseCase })}
		>
			<FormattedMessage id="btn.download.excel" defaultMessage="Descargar Excel" />
		</button>
	);
};

export default ExportJsonFile;
export { downloadTable };
