import useAxios from 'hooks/useAxios';

import { useEffect, useState } from 'react';
// Import Libs
import { useNavigate, useParams } from 'react-router-dom';
import Cookie from 'js-cookie';

import Swal from 'sweetalert2';
// Import Components
import HandleOnError from 'common/validators/HandleOnError';

import CustomAlert from 'components/CustomAlert';

// Import Hooks
import useLangv2 from 'hooks/useLangv2';
//Import Utils
import { validateDuplicateCodeAsync } from 'common/validators/ValidateDuplicates';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';

import HandleInput from 'common/validators/HandleInput';

// Import Paths
import paths from 'services/paths';

// Import Models
import BillingType from 'models/BillingType';

//Import Constants
import { SPACING, TEXTREGEXMIN } from 'common/validators/Regex';

export default function UpdateBillingTypeViewModel() {
	const { RequestUseCase, endpoints } = useAxios();

	const navigate = useNavigate();
	// Example of form data
	const [formData, setFormData] = useState(new BillingType());
	const [currentCode, setCurrentCode] = useState('');
	const { id } = useParams();
	// toggle state
	const [active, setActive] = useState(true);
	const { formatterText, resourceNotFound, noFilledContent } = useLangv2();
	const [errorName, setErrorName] = useState('');
	const [codeError, setCodeError] = useState('');

	useEffect(() => {
		getDataBillingType();
	}, []);

	useEffect(() => {
		getDataToUpdate(id);
	}, [id]);

	const setBillForm = (data) => {
		setFormData({
			idTipoFacturacion: data.idUnidadNegocio,
			nombre: data.nombre.replaceAll(/\s{2,}/gi, ' '),
			descripcion: data.descripcion,
			codigo: data.codigo,
			estado: data.estado,
			fechaRegistro: data.fechaRegistro,
			fechaModificacion: data.fechaModificacion,
			usuarioCreacion: data.usuarioCreacion,
			usuarioModificacion: data.usuarioModificacion
		});
	};

	const getDataBillingType = () => {
		const billingType = JSON.parse(localStorage.getItem('dataUpdate'));
		if (billingType) {
			setCurrentCode(billingType.codigo);
			setBillForm(billingType);
			setActive(billingType.estado);
		}
	};

	const getDataToUpdate = (id) => {
		RequestUseCase.get(endpoints.billingTypes.getBillingTypeById(id))
			.then((res) => {
				if (res === null) {
					resourceNotFound();
				} else {
					setCurrentCode(res.codigo);
					setBillForm(res);
					setActive(res.estado);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	// Update a string to set into the form
	const handleText = (e) => {
		HandleInput(e, SPACING, formData, setFormData, true);
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	// This function sets the form value by the onChange of the input
	const handleChange = (e) => {
		const example = e;
		example.target.value = e.target.value.toLowerCase();
		HandleInput(example, TEXTREGEXMIN, formData, setFormData);
	};

	// This function is executed when the create button is clicked
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (errorName !== '' || codeError !== '' || formData.nombre === '' || formData.codigo === '') {
			noFilledContent();
		} else {
			const errorMsg = formatterText('p.label.title.errorCrearTipoClienteExistenciaCodigo');
			const isDuplicateCode = await validateDuplicateCodeAsync(
				formData.codigo.replaceAll(/\s{2,}/gi, ' ').toLowerCase(),
				errorMsg,
				RequestUseCase.get(endpoints.billingTypes.getAllBillingTypes),
				true,
				currentCode
			);
			if (!isDuplicateCode) {
				const data = {
					...formData,
					idTipoFacturacion: id,
					nombre: formData.nombre.replaceAll(/\s{2,}/gi, ' '),
					estado: active ? '1' : '0',
					codigo: formData.codigo.replaceAll(/\s{2,}/gi, ' ').toLowerCase(),
					usuarioModificacion: Cookie.get('idUsuario')
				};
				updateBillingTypeData(data);
			}
		}
	};

	const updateBillingTypeData = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.update.general', 'Se va a editar el registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					RequestUseCase.put(endpoints.billingTypes.updateBillingType, data)
						.then(() => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.updated.general',
										'El registro se ha actualizado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => navigate(paths.parameters)
								})
							);
						})
						.catch((err) => {
							console.log(err);
							reject(
								HandleOnError(
									formatterText(
										'alert.message.failed.associations.general',
										'Error al crear una de las asociaciones, es posible que el código ya exista'
									)
								)
							);
						});
				});
			}
		});
	};
	const handleErrorName = () => {
		if (formData.nombre.trim() === '') {
			setErrorName(formatterText('p.label.title.name.is.required'));
		} else {
			setErrorName('');
		}
	};

	const handleErrorCode = () => {
		if (formData.codigo.trim() === '') {
			setCodeError(formatterText('table.title.code.required'));
		} else {
			setCodeError('');
		}
	};

	return {
		active,
		codeError,
		errorName,
		formatterText,
		formData,
		handleChange,
		handleErrorCode,
		handleErrorName,
		handlerTextDescription,
		handleSubmit,
		handleText,
		navigate,
		setActive
	};
}
