import { v4 as uuidv4 } from 'uuid';
import Cookie from 'js-cookie';

class CostProductProvider {
	constructor(
		idPrecioProductoProveedor = uuidv4(),
		idProveedor = '',
		idProducto = '',
		idUnidadMedida = '',
		costo = '',
		iva = '',
		usuarioCreacion = Cookie.get('idUsuario'),
		usuarioModificacion = Cookie.get('idUsuario')
	) {
		this.idPrecioProductoProveedor = idPrecioProductoProveedor;
		this.idProveedor = idProveedor;
		this.idProducto = idProducto;
		this.idUnidadMedida = idUnidadMedida;
		this.costo = costo;
		this.iva = iva;
		this.usuarioCreacion = usuarioCreacion;
		this.usuarioModificacion = usuarioModificacion;
	}
}

export default CostProductProvider;
