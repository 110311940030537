import React from 'react';
import paths from 'services/paths';
import SelectorMulti from 'common/SelectorMulti';
import { FormattedMessage } from 'react-intl';
import 'styles/editProfile.css';
import EditProfileViewModel from './EditProfile.viewModel';

export default function Edit() {
	const {
		handleChange,
		handleChangeMulti,
		handleChangeLang,
		uploadImage,
		handleSubmit,
		formData,
		file,
		userDataValue,
		selectedSearchCountries,
		loading,
		selectValues,
		uploadRef,
		labelUpload,
		formatterText,
		navigate
	} = EditProfileViewModel();
	return (
		<form className="edit-profile" onSubmit={handleSubmit}>
			<section className="edit-profile-container-header">
				<section className="edit-profile-header__img">
					<img
						src={
							file ||
							userDataValue?.avatarUrl ||
							'https://png.pngtree.com/png-vector/20190710/ourlarge/pngtree-user-vector-avatar-png-image_1541962.jpg'
						}
					/>
					<button
						onClick={() => uploadRef.current.click()}
						type="button"
						style={{ fontWeight: 'bold' }}
					>
						{formatterText('file.upload.choose.file')}
					</button>
					<label
						id="lbl-file-img-profile"
						htmlFor="file-img-profile"
						style={{ fontWeight: 'bold' }}
					>
						{labelUpload}
					</label>
					<input
						type="file"
						ref={uploadRef}
						onChange={uploadImage}
						style={{ display: 'none' }}
						id="file-img-profile"
						accept="image/png, image/jpeg"
					></input>

					{/* <p>Cambiar foto de perfil</p> */}
				</section>

				<section className="edit-profile-header__name">
					{/* <h2>
            {formData.nombre ? formData.nombre : "No hay nombre asociado"}
          </h2> */}
					<h2>
						{formData.usuario !== null
							? formatterText('table.title.user', 'Usuario: ') + ': ' + formData.usuario
							: 'No hay nombre asociado'}
					</h2>
					{/* <p>Usuario: {formData.usuario}</p> */}
				</section>

				<section className="edit-profile-header__language">
					<h3>{formatterText('p.lang', 'Idioma del Aplicativo')}</h3>
					<select
						name="lang"
						id="lang"
						value={localStorage.getItem('lang')}
						onChange={handleChangeLang}
						className="global-select"
					>
						<option value="en-ES">
							<FormattedMessage id="nav.text.language.spanish" defaultMessage="Español" />
						</option>
						<option value="en-US">
							<FormattedMessage id="nav.text.language.english" defaultMessage="Ingles" />
						</option>
					</select>
				</section>
			</section>

			<section className="edit-profile-container-information">
				<section className="edit-profile-information__option">
					<h3>{formatterText('p.email', 'Correo electrónico')}</h3>
					<input
						className="input-primary"
						type="email"
						name="correo"
						value={formData.correo}
						onChange={handleChange}
					/>
				</section>
				<section className="edit-profile-information__option">
					<h3>{formatterText('p.password', 'Contraseña')}</h3>
					<p onClick={() => navigate(paths.changePassword)}>
						{formatterText('header.title.change.password.principal', 'Cambiar contraseña')}
					</p>
				</section>
				<br></br>
				<section className="edit-profile-information__option">
					<h3>{formatterText('p.notifiassigned', 'Notificaciones Asignadas')}</h3>
					<section className="edit-profile-information__option">
						<SelectorMulti
							data={selectedSearchCountries}
							isLoading={loading}
							dataValue={selectValues}
							setterFunction={handleChangeMulti}
							isRequired={true}
						/>
					</section>
				</section>
			</section>
			<br></br>
			<br></br>
			<section className="edit-profile-container-buttons">
				<button type="submit" className="btn-primary">
					{formatterText('btn.save.changes', 'Guardar cambios')}
				</button>
				<button className="input-cancel" onClick={() => navigate(paths.home)}>
					{formatterText('btn.cancel', 'Cancelar')}
				</button>
			</section>
		</form>
	);
}
