import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import TabScheduleSelectViewModel from './TabScheduleSelect.viewModel';

const TabScheduleSelect = () => {
	const { tabs, permissionsAccess, permittedActions } = TabScheduleSelectViewModel();
	return (
		<section
			className="table-container"
			style={{
				marginTop: '1rem'
			}}
		>
			<Tabs selectedTabClassName="tab-option--selected">
				<TabList className="tab-list">
					{tabs.map((tab, index) => (
						<Tab
							onClick={() => {
								localStorage.setItem('indexTab', index.toString());
								permissionsAccess(tab.idPermiso);
							}}
							key={index}
							className="new-tab-option"
						>
							{tab.nombre}
						</Tab>
					))}
				</TabList>
				{permittedActions.consultar &&
					tabs.map((tab, index) => (
						<TabPanel
							style={{
								border: '1px solid var(--text-secondary)'
							}}
							key={index}
						>
							{tab.componente}
						</TabPanel>
					))}
			</Tabs>
		</section>
	);
};

export default TabScheduleSelect;
