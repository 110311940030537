import * as yup from 'yup';
import { EMAIL_REGEX, ONLY_NUMBERS_REGEX } from 'common/validators/Regex';

export const getFormikValidationSchema = () => {
	return yup.object().shape({
		area: yup.string().required('p.form.error.required'),
		host: yup
			.string()
			.required('p.form.error.required')
			.max(50, 'p.form.error.max.50.chars'),
		usuario: yup
			.string()
			.required('p.form.error.required')
			.email('p.form.error.email.invalid')
			.matches(EMAIL_REGEX, 'p.form.error.email.invalid')
			.max(40, 'p.form.error.max.40.chars'),
		clave: yup
			.string()
			.required('p.form.error.required')
			.max(40, 'p.form.error.max.40.chars'),
		puerto: yup
			.string()
			.required('p.form.error.required')
			.matches(ONLY_NUMBERS_REGEX, 'p.form.error.only.number')
			.max(6, 'p.form.error.max.6.chars')
	});
};
