import React from 'react';

// Imports Libs
import { FormattedMessage } from 'react-intl';

// Imports Components
import DynamicTable from 'common/DynamicTable/DynamicHead';

// Import Paths
import paths from 'services/paths';

// Import Constants
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';

// Import ViewModel
import VehiclesTableViewModel from './VehiclesTable.viewModel';

// Import PropTypes
import { VehiclesTableProps } from './VehiclesTable.props';

const VehiclesTable = ({ props }) => {
	const {
		displayLoading,
		displayMessage,
		error,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		loading,
		titles
	} = VehiclesTableViewModel();

	return (
		<>
			{!loading ? (
				<>
					<FormattedMessage id="table.name.search.vehicle" defaultMessage="Vehículo">
						{() => (
							<DynamicTable
								titles={titles}
								pageName={PAGE_NAMES.Vehículos}
								getData={getDataTable}
								handleDeleteItem={handleDeleteItem}
								handleEditStateItem={handleEditStateItem}
								routeToEdit={paths.updateTechnical}
								canDeleted={props.eliminar}
							/>
						)}
					</FormattedMessage>
				</>
			) : error ? (
				displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			) : (
				displayLoading()
			)}
		</>
	);
};

VehiclesTable.propTypes = VehiclesTableProps;

export default VehiclesTable;
