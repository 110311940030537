const MIME_TYPE = {
	pdf: 'application/pdf',
	mp3: 'video/mp4',
	mp4: 'video/mp4',
	jpg: 'image/jpeg',
	png: 'image/png',
	gif: 'image/gif',
	txt: 'text/plain',
	doc: 'application/msword',
	docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	xls: 'application/vnd.ms-excel',
	xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	ppt: 'application/vnd.ms-powerpoint',
	pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	odt: 'application/vnd.oasis.opendocument.text',
	ods: 'application/vnd.oasis.opendocument.spreadsheet',
	odp: 'application/vnd.oasis.opendocument.presentation',
	zip: 'application/zip',
	rar: 'application/x-rar-compressed'
};

export default MIME_TYPE;
