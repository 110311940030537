import { Formiz, FormizStep } from '@formiz/core';
import { isMaxLength } from '@formiz/validations';
import { InputField } from 'common/InputField';
import React from 'react';
import { QuizzBuilder } from 'react-quizz-spanish';
import 'react-quizz-spanish/lib/assets/antd.css';
import paths from 'services/paths';
import CreateFormViewModel from './CreateForm.viewModel';

export default function CreateForm() {
	const { myForm, handleSubmit, setForm, loading, formatterText, navigate } = CreateFormViewModel();

	return (
		<div className="centered-form">
			<Formiz onValidSubmit={handleSubmit} connect={myForm}>
				<form
					noValidate
					onSubmit={myForm.submit}
					className="container-wrapForm"
					style={{ minHeight: '16rem' }}
				>
					<div className="new-container-wrapForm__tabs">
						{myForm.steps.map((step) => (
							<button
								key={step.name}
								className={`new-tab-option ${
									step.name === myForm.currentStep.name ? 'is-active' : ''
								}`}
								type="button"
								onClick={() => myForm.goToStep(step.name)}
							>
								{formatterText(step.label)}
								{!step.isValid && step.isSubmitted && <small className="mr-2">⚠️</small>}
							</button>
						))}
					</div>

					<div className="container-wrapForm-content">
						<FormizStep name="step1" label="column.name.form.title.step">
							<section className="wrapForm">
								<InputField
									type="text"
									name="nombre"
									labelText={formatterText('column.name.form.name')}
									placeholder={formatterText('column.name.form.name')}
									validateInput="text"
									required={formatterText('column.name.form.required.name.form')}
									validations={[
										{
											rule: isMaxLength(100),
											message: formatterText('p.label.title.ingresa100Caracteres')
										}
									]}
									styleName="input-primary"
								/>
								<InputField
									type="text"
									asArea={true}
									name="descripcion"
									labelText={formatterText('table.title.description')}
									placeholder={formatterText('table.title.description')}
									validateInput="text"
									validations={[
										{
											rule: isMaxLength(200),
											message: 'La descripción debe tener como máximo 200 caracteres'
										}
									]}
									styleName="input-textarea"
								/>

								<InputField
									type="text"
									name="code"
									labelText={formatterText('table.title.code')}
									placeholder={formatterText('table.title.code')}
									validateInput="code-lower-case"
									required={formatterText('table.title.code.required')}
									styleName="input-primary"
								/>
							</section>
						</FormizStep>
						<FormizStep name="step2" label="column.name.form.title.step2">
							<p className="wrap-form-title">{formatterText('column.name.form')}</p>
							{!loading && <QuizzBuilder language="es" onChange={setForm} />}
						</FormizStep>
					</div>
					<div className="demo-form__footer">
						<section className="form-responsive-container-buttons">
							<button type="submit" className="btn-primary" id="submit-form">
								{formatterText('header.title.service.parameter.form.create')}
							</button>

							<button className="input-cancel" onClick={() => navigate(paths.services)}>
								{formatterText('question.swal.title.edit.cancel')}
							</button>
						</section>
					</div>
				</form>
			</Formiz>
		</div>
	);
}
