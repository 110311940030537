import useAxios from 'hooks/useAxios';
import { useContext, useEffect, useState } from 'react';
// Import Context
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import Hooks
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import useProgress from 'hooks/useProgress';
import { useNavigate } from 'react-router-dom';
// Import Components
import { useForm } from '@formiz/core';
import HandleOnError from 'common/validators/HandleOnError';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import CustomAlert from 'components/CustomAlert';
// Import Libs
import SortData from 'components/utils/SortData';
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
// Import Services
import endpoints from 'services/api';
import paths from 'services/paths';

import HandlerNumbers from 'common/validators/HandlerNumbers';
import { useSeachContext } from 'context/SearchContext';
import { isUndefined } from 'lodash';

export default function CreateActivityViewModel() {
	const { RequestUseCase } = useAxios();

	const {
		toggleError,
		error,
		handleClick,
		setSearchResults,
		setDataTable,
		setTable,
		displayMessage,
		setResultFound,
		setActivitiesResults,
		// getAllFormsClientsByTypeServicesActivity,
		activitiesResults = []
	} = useSeachContext();
	// Formiz object
	const myForm = useForm();

	// show or hide the section
	const [show, setShow] = useState(true);
	// toggle state
	const [active, setActive] = useState(true);

	const [actionToggle, setActionToggle] = useState(false);

	// Form information
	const [currentDataForm, setCurrentDataForm] = useState(null);

	// Call context TableMinimalContext
	const { currentDataTable, setCurrentDataTable, setResultsTableSearch } =
		useContext(TableMinimalContext);
	const navigate = useNavigate();
	// useLanguage
	const { formatterText, noFilledContent } = useLangv2();
	// Current data table
	const [currentItemMinimal, setCurrentItemMinimal] = useState({
		tiempoActividad: '',
		descripcion: '',
		idActividad: {
			value: 0
		},
		idEtapaServicio: {
			value: 0
		},
		actividadTecnica: {
			value: 0
		}
	});
	// Value that select use
	const [selectedSearch, setSearchSelected] = useState([
		{
			categoriasServicios: [],
			tipoServicio: [],
			actividadesServicios: [],
			etapaServicios: [],
			typeServicesActivity: [],
			formsAssociate: [],
			clientsAssociate: []
		}
	]);

	// Table 1 tab
	const titlesTableActivites = [
		formatterText('label.text.nameActivity', 'Nombre de la actividad'),
		formatterText('table.title.description', 'Descripción'),
		formatterText('table.title.sku', 'SKU'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];

	// Table 2 tab
	const titlesTableCostProduct = [
		formatterText('column.name.related.activity'),
		formatterText('column.name.service.type'),
		formatterText('column.name.service.stage'),
		formatterText('column.name.manage.by.technician'),
		formatterText('column.name.activity.time'),
		formatterText('column.name.description'),
		formatterText('column.name.actions')
	];

	// Table 3 tab
	const titlesTableTypeServiceActivity = [
		formatterText('column.name.form.name'),
		formatterText('column.name.form.description'),
		formatterText('column.name.form.code'),
		formatterText('column.name.form.client.associated'),
		formatterText('column.name.form.client.identification'),
		formatterText('column.name.actions')
	];

	// Helps to loading select data
	const { loading, toggleLoading } = useGetData();
	// Helps to loading data table
	const { loadingProgress, setLoadingProgress, DisplayProgress } = useProgress();
	// Helps to load Select data
	const [auxData, setAuxData] = useState({
		idTipoServicioActividad: uuidv4(),
		idCategoriaServicio: 0,
		idTipoServicio: 0,
		idActividad: 0,
		idEtapaServicio: 0,
		actividadTecnica: 0,
		idTypeServiceActivity: 0,
		idFormulario: 0,
		idCliente: 0
	});

	// función para cargar datos de la tabla de formularios
	const getAllFormsClientsByTypeServicesActivity = async (idActividad, idCliente) => {
		setLoadingProgress(true);

		try {
			let url = '';
			if (
				(isUndefined(idActividad) && isUndefined(idCliente)) ||
				(idActividad === 0 && idCliente === 0)
			) {
				url = `${endpoints.activityformClient.getFormClientByActivity(-1, -1)}`;
			} else {
				url = `${endpoints.activityformClient.getFormClientByActivity(
					idActividad || 0,
					idCliente || 0
				)}`;
			}
			const response = await RequestUseCase.get(url);

			const data = response.map((element) => ({
				id: element.formularioClientePojo.idActividadFormularioCliente,
				name: element.formularioClientePojo.nombreFormulario,
				description: element.formularioClientePojo.descripcionFormulario,
				code: element.formularioClientePojo.codigoFormulario,
				client: element.formularioClientePojo.nombreRazonSocialCliente,
				clienteId: element.formularioClientePojo.identificacionCliente
			}));

			setActivitiesResults(data);
			setLoadingProgress(false);
			return data;
		} catch (error) {
			setLoadingProgress(false);
			return [];
		}
	};

	// función para cargar datos de la tabla de actividades
	const getDataTable = () => {
		RequestUseCase.get(endpoints.activitiesServices.getAllActivitiesServicesBasic)
			.then((data) => {
				const newArray = [];
				data.forEach((item) => {
					handleStructureItems(newArray, item);
				});
				const sortedArray = SortData(newArray, 'asc');
				setSearchResults(sortedArray);
				setDataTable(newArray);
			})
			.catch((err) => {
				console.log(err);
				// mostrar error
				toggleError(!error);
				handleClick();
			});
	};

	const handleStructureItems = (newArray, item) => {
		newArray.push({
			id: item.idActividad,
			nombre: item.nombre,
			descripcion: item.descripcion,
			sku1: item.sku,
			estado: item.estado,
			objeto: { ...item }
		});
	};

	// Call services to fill in the selects
	useEffect(() => {
		// Tab 1
		getAllCategoriesServices();
		getAllActivitiesServices();
		getAllStageServices();
		// Tab 2
		getAllTypeServicesActivity();
		getAllFormsWithActiveState();
		getAllClientsToAssociate();
		toggleLoading(true);
		getDataTable();
		toggleLoading(false);
	}, []);

	const allGets = () => {
		// Tab 1
		getAllCategoriesServices();
		getAllActivitiesServices();
		getAllStageServices();
		// Tab 2
		getAllTypeServicesActivity();
		getAllFormsWithActiveState();
		getAllClientsToAssociate();
		getAllActivitiesTypeServicesById();
	};

	// Get all type Services Activity by id
	useEffect(() => {
		const updatedResultsTableSearch = getAllFormsClientsByTypeServicesActivity(
			auxData.idTypeServiceActivity.value,
			auxData.idCliente.value
		);
		setResultsTableSearch(updatedResultsTableSearch);
	}, [auxData.idTypeServiceActivity, auxData.idCliente]);

	// // Get the type of service by id category service
	useEffect(() => {
		if (auxData.idTipoServicio !== 0) {
			getAllActivitiesTypeServicesById();
		}
		// this only is call when the id Type Service change
	}, [auxData.idTipoServicio]);

	useEffect(() => {
		if (auxData.idCategoriaServicio !== 0) {
			// this only is call when the id Category Service change, and render the element in the selector
			getAllTypeServicesById(auxData.idCategoriaServicio);
		}
	}, [auxData.idCategoriaServicio]);

	const handleDeleteItem = (rowId) => {
		return new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.activitiesServices.deleteActivitiesBasic(rowId))
				.then(() => {
					getDataTable();
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const handleEditStateItem = (body) => {
		return new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.activitiesServices.updateActivitiesService, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	// Get the data of all category services to the selector
	const getAllCategoriesServices = () => {
		toggleLoading(true);

		RequestUseCase.get(endpoints.serviceCategory.getAllServiceCategory)
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idCategoriaServicio,
							label: `${item.nombre} - ${item.codigo}`,
							isFixed: true
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					categoriasServicios: newArray
				}));
				toggleLoading(false);
			})
			.catch(() => {
				toggleLoading(false);
			});
	};

	// Get the data of all type category Activity to the selector
	const getAllTypeServicesActivity = () => {
		toggleLoading(true);

		RequestUseCase.get(endpoints.typoServiceActivities.getAllTypoServiceActivities)
			.then((res) => {
				// create new array
				const newArray = [
					{
						value: 0,
						label: 'Ninguno'
					}
				];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					newArray.push({
						value: item.idTipoServicioActividad,
						label: `${item.idTipoServicio.nombre} - ${item.idActividad.nombre} - ${item.idEtapaServicio.nombre}`,
						isFixed: true
					});
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					typeServicesActivity: newArray
				}));
				toggleLoading(false);
			})
			.catch((err) => {
				console.log(err);
				toggleLoading(false);
			});
	};

	// Get the data of all forms with only active state to the selector
	const getAllFormsWithActiveState = () => {
		RequestUseCase.get(endpoints.activityformClient.getAllFormWithTrueState)
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					newArray.push({
						value: item.idFormulario,
						label: `${item.nombre} - ${item.codigo}`
					});
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					formsAssociate: newArray
				}));
				setTable(newArray);
				setResultFound(newArray);
			})

			.catch((err) => {
				console.log(err);
			});
	};

	// Get the data of all clients to the selector
	const getAllClientsToAssociate = () => {
		RequestUseCase.get(endpoints.clients.getAllClients)
			.then((res) => {
				// create new array
				const newArray = [
					{
						value: 0,
						label: 'Ninguno'
					}
				];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idCliente,
							label: `${item.nombreRazonSocial} - ${item.identificacion}`
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					clientsAssociate: newArray
				}));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// Get the data of all Activities services to the selector
	const getAllActivitiesServices = () => {
		// loading select
		toggleLoading(true);

		RequestUseCase.get(endpoints.activitiesServices.getAllActivitiesServicesBasic)
			.then((res) => {
				// create new array
				const newArray = [];
				const aux = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idActividad,
							label: `${item.nombre} - ${item.sku}`,
							isFixed: true
						});
						aux.push({
							id: item.idActividad,
							name: item.nombre,
							sku: item.sku
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					actividadesServicios: newArray
				}));
				setCurrentDataForm(aux);

				toggleLoading(false);
			})
			.catch((err) => {
				console.log(err);
				toggleLoading(false);
			});
	};

	// Get the data of all Stage services to the selector
	const getAllStageServices = () => {
		toggleLoading(true);

		RequestUseCase.get(endpoints.stageServices.getAllStageServices)
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idEtapaServicio,
							label: `${item.nombre} - ${item.codigo}`,
							isFixed: true
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					etapaServicios: newArray
				}));
				toggleLoading(false);
			})
			.catch((err) => {
				console.log(err);
				toggleLoading(false);
			});
	};

	// Get the data of all activities type services when have id type service
	const getAllActivitiesTypeServicesById = () => {
		setLoadingProgress(true);
		RequestUseCase.get(
			endpoints.typoServiceActivities.getTypeServiceActivitiesByTypeService(
				auxData.idTipoServicio.value
			)
		)
			.then((res) => {
				// create new array
				const newArray = [];

				res.forEach((item) => {
					newArray.push({
						...item,
						idTipoServicioActividad: item.idTipoServicioActividad,
						idTipoServicio: {
							idTipoServicio: `${item.idTipoServicio.nombre} - ${item.idTipoServicio.codigo}`,
							id: item.idTipoServicio.idTipoServicio
						},
						idActividad: {
							idActividad: `${item.idActividad.nombre} - ${item.idActividad.sku}`,
							id: item.idActividad.idActividad
						},
						idEtapaServicio: {
							idEtapaServicio: `${item.idEtapaServicio.nombre} - ${item.idEtapaServicio.codigo}`,
							id: item.idEtapaServicio.idEtapaServicio
						},
						actividadTecnica: item.actividadTecnica,
						orden: item.orden
					});
				});

				// sort the array by the order field
				newArray.sort((a, b) => a.orden - b.orden);
				setCurrentDataTable(newArray);
				setLoadingProgress(false);
			})
			.catch((err) => {
				console.log(err);
				setLoadingProgress(false);
			});
	};

	// Get all type service by id category service
	const getAllTypeServicesById = (selectValue) => {
		// Set the value of datas into []
		setCurrentDataTable([]);
		setCurrentItemMinimal([]);

		RequestUseCase.get(endpoints.typeService.getTypeServiceByCategory(selectValue.value))
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idTipoServicio,
							label: `${item.nombre} - ${item.codigo}`,
							isFixed: true
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					tipoServicio: newArray
				}));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// Update a string to set into the form
	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, currentItemMinimal, setCurrentItemMinimal);
	};

	// Update a number to set into the form
	const handleNumber = (e) => {
		// create a regex to valid the number
		HandlerNumbers(e, currentItemMinimal, setCurrentItemMinimal);

		// if (e.target.value.match(NUMBERREGEX)) {
		setCurrentItemMinimal({
			...currentItemMinimal,
			idTipoServicio: auxData.idTipoServicio,
			idActividad: auxData.idActividad,
			idEtapaServicio: auxData.idEtapaServicio,
			actividadTecnica: auxData.actividadTecnica,
			[e.target.name]: e.target.value
		});
		// }
	};

	// This function is executed when the create button is clicked
	const handleSubmit = (values) => {
		if (actionToggle) {
			handleSubmitUpdateActivity(values);
		} else {
			const data = {
				nombre: values.nombre.replaceAll(/\s{2,}/gi, ' '),
				descripcion: values.descripcion ? values.descripcion.replaceAll(/\s{2,}/gi, ' ') : '',
				sku: values.sku.replaceAll(/\s{2,}/gi, ' '),
				iva: '0', //values.iva.replaceAll(/\s{2,}/gi, ' '),
				usuarioCreacion: Cookie.get('idUsuario')
			};
			createItem(data);
		}
	};

	// Create an activiy
	const createItem = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve) => {
					// this service create the item
					RequestUseCase.post(endpoints.activitiesServices.addActivitiesService, data)
						.then(() => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.created.general',
										'El registro se ha creado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => {
										getDataTable();
										allGets();
									} // Deuda tecnica (?), posiblemente toque retirarlo
								})
							);
						})
						.catch((err) => {
							if (err.response?.data?.message) {
								HandleOnError(formatterText(err.response?.data?.message));
							} else {
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
						});
				});
			}
		});
	};

	const clean = () => {
		setCurrentItemMinimal({
			tiempoActividad: '',
			descripcion: '',
			idActividad: {
				value: 0
			},
			idEtapaServicio: {
				value: 0
			},
			actividadTecnica: {
				value: 0
			},
			idTipoServicioActividad: uuidv4()
		});
		setAuxData((prevAuxData) => ({
			...prevAuxData,
			idTipoServicioActividad: uuidv4(),
			idActividad: 0,
			idEtapaServicio: 0,
			actividadTecnica: 0,
			idCliente: 0
		}));
	};
	// Associate an activity to a type service
	const associateTipoServiciosActividades = (item, onClean) => {
		// Define the order
		const order = currentDataTable.length + 1;
		// Structure the body
		let data = {
			idTipoServicioActividad: null,
			idTipoServicio: {
				idTipoServicio: item.idTipoServicio.value
			},
			idActividad: {
				idActividad: item.idActividad.value
			},
			idEtapaServicio: {
				idEtapaServicio: item.idEtapaServicio.value
			},
			orden: order,
			tiempoActividad: item.tiempoActividad,
			actividadTecnica: item.actividadTecnica.value,
			descripcion: item.descripcion
		};

		// Call the service
		RequestUseCase.post(endpoints.typoServiceActivities.addTypeServiceActivities, data)
			.then((response) => {
				data = {
					...data,
					idTipoServicio: {
						idTipoServicio: item.idTipoServicio.label
					},
					idActividad: {
						idActividad: item.idActividad.label
					},
					idEtapaServicio: {
						idEtapaServicio: item.idEtapaServicio.label
					},
					idTipoServicioActividad: response.idTipoServicioActividad
				};
				setShow(false);
				setTimeout(() => {
					setShow(true);
				}, 100);
				setCurrentDataTable([...currentDataTable, data]);
				// Set data
				CustomAlert('confirm_msg', {
					icon: 'success',
					title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
					text: formatterText(
						'alert.message.confirm.created.general',
						'El registro se ha creado correctamente'
					),
					confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
					allowOutsideClick: false,
					executeFunction: () => {
						onClean();

						console.log('Se creo correctamente: ', response.idTipoServicioActividad);
						getAllActivitiesTypeServicesById();
					}
				});
			})
			.catch((err) => {
				if (err.response.status === 412) {
					HandleOnError(err.response.data.message);
				} else {
					HandleOnError(
						formatterText(
							'alert.message.failed.general',
							'Error al crear el registro, po favor intente nuevamente.'
						)
					);
					clean();
				}
			});
	};

	// Add new association
	const handleAddItemMinimal = () => {
		if (
			currentItemMinimal.tiempoActividad !== undefined &&
			currentItemMinimal.idActividad.value !== undefined &&
			currentItemMinimal.idEtapaServicio.value !== undefined &&
			currentItemMinimal.actividadTecnica.value !== undefined
		) {
			associateTipoServiciosActividades(currentItemMinimal, clean);
			setCurrentItemMinimal({
				tiempoActividad: '',
				descripcion: '',
				idTipoServicioActividad: uuidv4(),
				idActividad: {
					value: ''
				},
				idEtapaServicio: {
					value: ''
				}
			});
		} else {
			noFilledContent();
		}
	};

	// Create new association in the 3 tab
	const handleAddActivityFormClient = () => {
		console.log('data formulario', auxData);
		const data = {
			idActividadFormularioCliente: null,
			idFormulario: auxData.idFormulario.value,
			idTipoServicioActividad: auxData.idTypeServiceActivity.value,
			idCliente: auxData.idCliente.value,
			usuarioCreacion: parseInt(Cookie.get('idUsuario'))
		};

		RequestUseCase.post(endpoints.activityformClient.addActivityFormClient, data)
			.then(() => {
				CustomAlert('confirm_msg', {
					icon: 'success',
					title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
					text: formatterText(
						'alert.message.confirm.created.general',
						'El registro se ha creado correctamente'
					),
					confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
					allowOutsideClick: false,
					executeFunction: () => {
						getAllFormsClientsByTypeServicesActivity(
							auxData.idTypeServiceActivity.value,
							auxData.idCliente.value
						);
						navigate(paths.services);
					}
				});
				setSearchSelected([]);
			})
			.catch((err) => {
				if (err.response?.data?.message) {
					HandleOnError(formatterText(err.response.data.message));
				} else {
					HandleOnError(formatterText('alert.message.failed.save.form.with.activity.and.client'));
				}
			});
	};

	// Delete an association of the table Activity Form Client
	const handleDelete = (row) => {
		RequestUseCase.delete(endpoints.activityformClient.deleteActivityFormClient(row.id))
			.then(() => {
				getAllFormsClientsByTypeServicesActivity(
					auxData.idTypeServiceActivity.value,
					auxData.idCliente.value
				);
			})
			.catch((error) => {
				console.log(error);
			});
		closeDialog();
	};

	// dialog state
	const [dialog, setDialog] = useState({
		text: '',
		active: false,
		function: null
	});

	// close dialog
	const closeDialog = () => {
		setDialog({ ...dialog, active: false });
	};

	// This function is executed when the create button is clicked
	const handleSubmitUpdateActivity = (values) => {
		const data = {
			idActividad: currentDataForm.idActividad,
			nombre: values.nombre,
			descripcion: values.descripcion ? values.descripcion : '',
			sku: values.sku,
			estado: active ? '1' : '0',
			fechaCreacion: currentDataForm.fechaCreacion,
			usuarioCreacion: currentDataForm.usuarioCreacion,
			usuarioModificacion: Cookie.get('idUsuario')
		};
		putItem(data);
	};

	// Updtae an activity
	const putItem = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.update.general', 'Se va a editar el registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve) => {
					// this service create the item
					RequestUseCase.put(endpoints.activitiesServices.updateActivitiesService, data)
						.then(() => {
							// aqui se llama el servicio para asociar el producto con el proveedor
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.updated.general',
										'El registro se ha actualizado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => {
										setActionToggle(!actionToggle);
										setCurrentDataForm(null);
										getDataTable();
										allGets();
									} // Deuda tecnica (?), posiblemente toque retirarlo
								})
							);
						})
						.catch((err) => {
							if (err.response?.data?.message) {
								HandleOnError(formatterText(err.response?.data?.message));
							} else {
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
						});
				});
			}
		});
	};

	return {
		formatterText,
		myForm,
		auxData,
		setAuxData,
		setCurrentItemMinimal,
		currentItemMinimal,
		selectedSearch,
		loading,
		loadingProgress,
		currentDataTable,
		setCurrentDataForm,
		currentDataForm,
		setActionToggle,
		actionToggle,
		active,
		setActive,
		show,
		handleAddItemMinimal,
		handleAddActivityFormClient,
		activitiesResults,
		handleDelete,
		dialog,
		setDialog,
		closeDialog,
		handleNumber,
		handlerTextDescription,
		handleDeleteItem,
		handleEditStateItem,
		getDataTable,
		navigate,
		error,
		displayMessage,
		handleSubmit,
		titlesTableActivites,
		titlesTableCostProduct,
		DisplayProgress,
		titlesTableTypeServiceActivity
	};
}
