import useAxios from 'hooks/useAxios';

import { useEffect, useState } from 'react';
// Import Libs
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';

import { useNavigate, useParams } from 'react-router-dom';
// Import Components
import HandleOnError from 'common/validators/HandleOnError';

import CustomAlert from 'components/CustomAlert';

// Import Hooks
import useLangv2 from 'hooks/useLangv2';
// Import Utils
import HandleInput from 'common/validators/HandleInput';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';

import { validateDuplicateCode } from 'common/validators/ValidateDuplicates';

// Import Paths
import paths from 'services/paths';
// Import Services

// Import Models
import BillingType from 'models/BillingType';

// Import Constants
import { TEXTREGEX, TEXTREGEXMIN } from 'common/validators/Regex';

export default function UpdateClientTypeViewModel() {
	const { RequestUseCase, endpoints } = useAxios();

	const navigate = useNavigate();
	// Example of form data
	const [formData, setFormData] = useState(new BillingType());
	const [currentCode, setCurrentCode] = useState('');
	// use Hook of language v2
	const { formatterText, resourceNotFound, noFilledContent } = useLangv2();
	const [errorName, setErrorName] = useState('');
	const [codeError, setCodeError] = useState('');
	// toggle state
	const [active, setActive] = useState(true);
	const { id } = useParams();

	useEffect(() => {
		getDataClientType();
	}, []);

	useEffect(() => {
		getDataToUpdate(id);
	}, [id]);

	const setClientTypeForm = (data) => {
		setFormData({
			idTipoCliente: data.idTipoCliente,
			nombre: data.nombre.replaceAll(/\s{2,}/gi, ' '),
			descripcion: data.descripcion,
			codigo: data.codigo,
			estado: data.estado,
			fechaCreacion: data.fechaCreacion,
			fechaModificacion: data.fechaModificacion,
			usuarioCreacion: data.usuarioCreacion,
			usuarioModificacion: data.usuarioModificacion
		});
	};

	const getDataClientType = () => {
		const clientType = JSON.parse(localStorage.getItem('dataUpdate'));
		if (clientType) {
			setCurrentCode(clientType.codigo);
			setClientTypeForm(clientType);
			setActive(clientType.estado);
		}
	};

	const getDataToUpdate = (id) => {
		RequestUseCase.get(endpoints.clientTypes.getClientTypeById(id))
			.then((res) => {
				if (res !== null) {
					const clientType = res;
					setCurrentCode(clientType.codigo);
					setClientTypeForm(clientType);
				} else {
					resourceNotFound();
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	// Update a string to set into the form
	const handleText = (e) => {
		HandleInput(e, TEXTREGEX, formData, setFormData, true);
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	// update the form number
	const handleCode = (e) => {
		const example = e;
		example.target.value = e.target.value.toLowerCase();
		HandleInput(example, TEXTREGEXMIN, formData, setFormData);
	};

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		if (errorName !== '' || codeError !== '' || formData.nombre === '' || formData.codigo === '') {
			noFilledContent();
		} else {
			const errorMsg = formatterText('p.label.title.errorCrearTipoClienteExistenciaCodigo');
			const data = {
				...formData,
				estado: active ? '1' : '0',
				usuarioModificacion: Cookie.get('idUsuario')
			};
			if (
				validateDuplicateCode(
					formData.codigo,
					errorMsg,
					() => RequestUseCase.get(endpoints.clientTypes.getAllClientTypes),
					true,
					currentCode
				)
			) {
				updateBillingTypeData(data);
			}
		}
	};

	const updateBillingTypeData = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.update.general', 'Se va a editar el registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					RequestUseCase.put(endpoints.clientTypes.updateClientType, data)
						.then(() => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.updated.general',
										'El registro se ha actualizado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => navigate(paths.parameters)
								})
							);
						})
						.catch((err) => {
							console.log(err);
							reject(
								HandleOnError(
									formatterText(
										'alert.message.failed.associations.general',
										'Error al crear una de las asociaciones, es posible que el código ya exista'
									)
								)
							);
						});
				});
			}
		});
	};

	const handleErrorName = () => {
		if (formData.nombre.trim() === '') {
			setErrorName(formatterText('p.label.title.name.is.required'));
		} else {
			setErrorName('');
		}
	};

	const handleErrorCode = () => {
		if (formData.codigo.trim() === '') {
			setCodeError(formatterText('table.title.code.required'));
		} else {
			setCodeError('');
		}
	};

	return {
		active,
		codeError,
		errorName,
		formatterText,
		formData,
		handleCode,
		handleErrorCode,
		handleErrorName,
		handlerTextDescription,
		handleSubmit,
		handleText,
		navigate,
		setActive
	};
}
