import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import MensajesIngles from '../lang/en-US.json';
import MensajesEspañol from '../lang/en-ES.json';
import { IntlProvider } from 'react-intl';

const langContext = React.createContext();

const LangProvider = ({ children }) => {
	let localePorDefecto;
	let mensajesPorDefecto;
	const lang = localStorage.getItem('lang');

	useEffect(() => {
		const locationLogin = new URL(window.location.href).hash.includes('login');
		if (locationLogin) {
			establecerMensajes(MensajesEspañol);
			establecerLocale('en-ES');
			localStorage.setItem('lang', 'en-ES');
		}
	}, []);

	if (lang) {
		localePorDefecto = lang;

		if (lang === 'en-US') {
			mensajesPorDefecto = MensajesIngles;
		} else if (lang === 'en-ES') {
			mensajesPorDefecto = MensajesEspañol;
		} else {
			localePorDefecto = 'en-ES';
			mensajesPorDefecto = MensajesEspañol;
		}
	}

	const [mensajes, establecerMensajes] = useState(mensajesPorDefecto);
	const [locale, establecerLocale] = useState(localePorDefecto);

	const establecerLenguaje = (lenguaje) => {
		switch (lenguaje) {
			case 'en-ES':
				establecerMensajes(MensajesEspañol);
				establecerLocale('en-ES');
				localStorage.setItem('lang', 'en-ES');
				break;
			case 'en-US':
				establecerMensajes(MensajesIngles);
				establecerLocale('en-US');
				localStorage.setItem('lang', 'en-US');
				break;
			default:
				establecerMensajes(MensajesEspañol);
				establecerLocale('en-ES');
				localStorage.setItem('lang', 'en-ES');
		}
	};

	return (
		<langContext.Provider value={{ establecerLenguaje: establecerLenguaje }}>
			<IntlProvider locale={locale} messages={mensajes}>
				{children}
			</IntlProvider>
		</langContext.Provider>
	);
};

LangProvider.propTypes = {
	children: PropTypes.node
};

export { LangProvider, langContext };
