import React from 'react';
// Import Components
import Search from 'common/Search';
import ExportJsonFile from 'components/utils/ExportJsonFile';
import UploadExcel from 'components/utils/UploadExcel';

// Import Libs
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ParametersServicesTableViewModel from './ParametersServicesTable.viewModel';

const ServicesTableComponent = () => {
	const {
		formatterText,
		tabs,
		indexTabServices,
		clickTab,
		selectedTab,
		permittedActions,
		selectEndpoint,
		searchResults,
		excelListData
	} = ParametersServicesTableViewModel();

	return (
		<section className="table-container">
			<section className="userOptions">
				{permittedActions.consultar && (
					<Search
						placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
						width="50%"
					/>
				)}
				{permittedActions.crear && (
					<Link style={{ textDecoration: 'none', alignSelf: 'center' }} to={selectedTab.route}>
						<button className="btn-add">{selectedTab.nombreLink}</button>
					</Link>
				)}
				{selectedTab.exportExcel && (
					<ExportJsonFile
						moduleName={selectedTab.id.replace('/', '')}
						userName={
							JSON.parse(localStorage.getItem('userData')).usuario
								? JSON.parse(localStorage.getItem('userData')).usuario
								: 'ADMIN'
						}
						dataTable={
							selectedTab.id.replace('/', '') === 'precios' ? excelListData : searchResults
						}
					/>
				)}
				{selectedTab.uploadExcel && (
					<UploadExcel
						ActionFunction={selectEndpoint}
						currentText={selectedTab.id.replace('/', '')}
						currentRestrict={formatterText('file.structure.price.list')}
					/>
				)}
			</section>
			<Tabs
				defaultIndex={indexTabServices}
				onSelect={(index) => localStorage.setItem('ParametersServicesTable', index)}
				selectedTabClassName="tab-option--selected"
			>
				<TabList className="tab-list">
					{tabs.map((tab, index) => (
						<Tab onClick={() => clickTab(tab, index)} key={`${index + 1}`} className="tab-option">
							{tab.nombre}
						</Tab>
					))}
				</TabList>
				{permittedActions.consultar &&
					tabs.map((tab, index) => (
						<TabPanel key={`tab-${index}:${tab.title}`}>{tab.componente}</TabPanel>
					))}
			</Tabs>
		</section>
	);
};

export default ServicesTableComponent;
