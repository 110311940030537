import React from 'react';
// Import Context
// Import Hooks
// Import Components
import DynamicTable from 'common/DynamicTable/DynamicHead';
import Search from 'common/Search';
import ExportJsonFile from 'components/utils/ExportJsonFile';
// Import libs
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
// Import services
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';
import paths from 'services/paths';
import UsersTableViewModel from './UsersTable.viewModel';

export default function UsersTableComponent() {
	const {
		loading,
		error,
		displayMessage,
		displayLoading,
		searchResults,
		handleDeleteItem,
		handleEditStateItem,
		getDataTable,
		titles,
		permittedActions,
		formatterText
	} = UsersTableViewModel();
	return (
		<>
			{!loading ? (
				<section className="table-container">
					<section className="userOptions">
						{permittedActions.consultar && (
							<Search
								placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
								width="50%"
							/>
						)}
						{permittedActions.crear && (
							<Link to={paths.createUser}>
								<button className="btn-add">
									<FormattedMessage id="btn.create.user" defaultMessage="Crear Usuario" />
								</button>
							</Link>
						)}

						{permittedActions.exportar && (
							<ExportJsonFile
								moduleName="Usuario"
								userName={
									JSON.parse(localStorage.getItem('userData')).usuario
										? JSON.parse(localStorage.getItem('userData')).usuario
										: 'ADMIN'
								}
								dataTable={searchResults}
							/>
						)}
					</section>
					{permittedActions.consultar && (
						<FormattedMessage id="table.name.search.users" defaultMessage="Usuarios">
							{() => (
								<div className="tab-container">
									<DynamicTable
										titles={titles}
										pageName={PAGE_NAMES.Usuarios}
										data={getDataTable}
										handleDeleteItem={handleDeleteItem}
										handleEditStateItem={handleEditStateItem}
										routeToEdit={paths.updateUser}
										canDeleted={permittedActions.eliminar}
										canModify={permittedActions.editar}
									/>
								</div>
							)}
						</FormattedMessage>
					)}
				</section>
			) : error ? (
				displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			) : (
				displayLoading()
			)}
		</>
	);
}
