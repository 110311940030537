import React from 'react';
// Import Hooks
import { Formiz, FormizStep } from '@formiz/core';
import { isEmail, isPattern } from '@formiz/validations';
import { InputFieldResponsive } from 'common/inputs/InputFieldResponsive';
import InputSelectorResponsive from 'common/inputs/InputSelectorResponsive';
import Table from 'common/minimalTables/Table';
import { URLREGEX } from 'common/validators/Regex';
import Attachments from 'components/AttachedFiles/Attachments';
import { Modal } from 'react-responsive-modal';
// Import Libs
import { FormattedMessage } from 'react-intl';
import { FieldMultiSelect } from 'common/selects/enhanced/Fields/FieldMultiSelect';
import { FieldSelect } from 'common/selects/enhanced/Fields/FieldSelect';
import paths from 'services/paths';
import AddAdviser from '../adviser/AddAdviser';
import AddBranchOffice from '../branchOffice/AddBranchOffice';
import HandlerChangeMailAssesor from '../utils/HandlerChangeMailAsesor';

import UpdateClientViewModel from './UpdateClient.viewModel';

function UpdateClientComponents() {
	const {
		selectData,
		loadingData,
		myForm,
		handleSubmit,
		printable,
		marketsByCountry,
		units,
		loading,
		updateUnitBusiness,
		updateSelectPriceLists,
		resetPLP,
		listPrices,
		secondaryPrices,
		disabledLS,
		selectValues,
		handleAddBusinessUnit,
		dataTableBusinessUnit,
		titlesTableBusinessUnit,
		handleOpen,
		handleDelete,
		getSucursales,
		getSucursalesMap,
		currentDataTable,
		currentBranchOffice,
		handleChangeSelect,
		handleChangeSucursal,
		handleAddBranchOffice,
		otherCurrentDataTable,
		getAsesors,
		getAsesorsMap,
		currentAsesor,
		handleChangeSelectAsesor,
		idTipoDocumentoAsesorRef,
		generoRef,
		idCargoRef,
		handleChangeAsesor,
		handleAddAsesor,
		error,
		setError,
		altErr,
		setAltErr,
		handleBlurMailAsesor,
		handleBlurMailAltAsesor,
		handleChangeMulti,
		clearValue,
		formatterText,
		setSelectedTable,
		auxData,
		titlesTableBranchOffice,
		titlesTableAdvisers,
		setCurrentAsesor,
		idMunicipioRef,
		archivos,
		handleClose,
		navigate,
		selectedTable,
		setCurrentFiles,
		uploadNewFile,
		open
	} = UpdateClientViewModel();
	// display the required when idMarket updated
	const unitBusinessInput = () => {
		if (updateUnitBusiness) {
			return (
				<InputSelectorResponsive
					type="text"
					name="idUnidadNegocio"
					labelText={formatterText('p.unit.bussines', 'Unidad de negocio')}
					placeholder={
						<FormattedMessage id="input.placeholder.select" defaultMessage="Selecione una opción" />
					}
					data={units}
					isLoading={loadingData}
				/>
			);
		} else {
			return (
				<InputSelectorResponsive
					type="text"
					name="idUnidadNegocio"
					labelText={formatterText('p.unit.bussines', 'Unidad de negocio')}
					placeholder={
						printable?.idUnidadNegocio?.nombre || (
							<FormattedMessage
								id="input.placeholder.select"
								defaultMessage="Selecione una opción"
							/>
						)
					}
					data={units}
					isLoading={loadingData}
				/>
			);
		}
	};

	return (
		<div className="centered-form">
			<Formiz onValidSubmit={handleSubmit} connect={myForm}>
				<form
					noValidate
					onSubmit={myForm.submit}
					className="container-wrapForm"
					style={{ minHeight: '16rem' }}
				>
					<div className="new-container-wrapForm__tabs">
						{myForm.steps.map((step, index) => (
							<button
								key={step.name}
								className={`new-tab-option ${
									step.name === myForm.currentStep.name ? 'is-active' : ''
								}`}
								type="button"
								onClick={() => {
									setSelectedTable(index);

									myForm.goToStep(step.name);
								}}
							>
								{!step.isValid && step.isSubmitted && <small className="mr-2">⚠️</small>}
								{formatterText(step.label)}
							</button>
						))}
					</div>
					<div className="container-wrapForm-content">
						{/* 1: General customer information */}
						<FormizStep name="step1" label="p.information.customer.general">
							<div className="title-section">
								<span className="circle-form">
									<span>1</span>
								</span>
								<h2>
									<FormattedMessage
										id="p.general.data.customer"
										defaultMessage="Datos generales del cliente"
									/>
								</h2>
							</div>
							<section className="grid-container-3c">
								<InputSelectorResponsive
									type="text"
									name="idTipoDocumento"
									placeholder={
										`${printable?.idTipoDocumento?.nombre} - ${printable?.idTipoDocumento.abreviatura}` ||
										''
									}
									labelText={formatterText('p.document.type', 'Tipo de documento')}
									data={
										!!selectData.documentTypes &&
										selectData.documentTypes.map((item) => {
											const docLabel = `${item.nombre} - ${item.abreviatura}`;
											return {
												label: docLabel,
												value: item.idTipoDocumento
											};
										})
									}
									isLoading={loadingData}
								/>
								<InputFieldResponsive
									type="text"
									name="identificacion"
									labelText={formatterText('p.id.identification', 'Identificación*')}
									placeholder={formatterText('text.ID', 'Identificación')}
									defaultValue={printable?.identificacion || ''}
									validateInput="integer"
									styleName="input-default-3c"
								/>

								<InputSelectorResponsive
									type="text"
									name="idGenero"
									labelText={formatterText('input.placeholder.genre', 'Género')}
									placeholder={
										`${printable?.idGenero?.nombre} - ${printable?.idGenero?.abreviatura}` || ''
									}
									data={
										!!selectData.genres &&
										selectData.genres.map((item) => ({
											label: `${item.nombre} - ${item.abreviatura}`,
											value: item.idGenero
										}))
									}
									isLoading={loadingData}
								/>
								<InputFieldResponsive
									type="text"
									name="nombreRazonSocial"
									labelText={formatterText('p.name.social.reason', 'Nombres o razón social*')}
									placeholder="Nombres o razón social"
									defaultValue={printable?.nombreRazonSocial || ''}
									validateInput="text"
									required={formatterText('p.label.title.nombreRazonSocialRequerido')}
									styleName="input-default-3c"
								/>
								<InputSelectorResponsive
									type="text"
									name="idTipoCliente"
									placeholder={printable?.idTipoCliente?.nombre || ''}
									labelText={formatterText('p.client.type', 'Tipo de Cliente*')}
									data={
										!!selectData.clientTypes &&
										selectData.clientTypes.map((item) => {
											const docLabel = `${item.nombre}`;
											return {
												label: docLabel,
												value: item.idTipoCliente
											};
										})
									}
									isLoading={loadingData}
								/>

								<InputFieldResponsive
									type="email"
									name="correo"
									defaultValue={printable?.correo || ''}
									labelText={formatterText('table.title.email', 'Correo electrónico')}
									placeholder={formatterText('table.title.email', 'Correo electrónico')}
									required={formatterText('p.label.title.correoElectronicoRequerido')}
									validations={[
										{
											rule: isEmail(),
											message: 'El correo no es válido'
										}
									]}
									styleName="input-default-3c"
								/>
								<InputFieldResponsive
									type="text"
									name="celular"
									labelText={formatterText('p.movile.phone', 'Celular*')}
									placeholder="Celular"
									defaultValue={printable?.celular || ''}
									validateInput="integer"
									required={formatterText('p.label.title.celularRequerido')}
									styleName="input-default-3c"
								/>
								<InputFieldResponsive
									type="text"
									name="telefono"
									labelText={formatterText('table.title.phone', 'Teléfono*')}
									placeholder="Teléfono*"
									defaultValue={printable?.telefono || ''}
									validateInput="integer"
									required={formatterText('p.label.title.telefonoRequerido')}
									styleName="input-default-3c"
								/>
								<InputFieldResponsive
									type="text"
									name="sitioWeb"
									labelText={formatterText('table.title.website', 'Sitio web')}
									placeholder="Sitio web"
									defaultValue={printable?.sitioWeb || ''}
									validateInput="web"
									validations={[
										{
											rule: isPattern(URLREGEX),
											message: 'La url no es válida'
										}
									]}
									styleName="input-default-3c"
								/>
								<InputSelectorResponsive
									type="text"
									name="clienteEspecial"
									placeholder={printable?.clienteEspecial === 1 ? 'Si' : 'No' || ''}
									labelText={formatterText('p.especial.customer', 'Cliente especial')}
									data={[
										{
											label: 'Sí',
											value: 1
										},
										{
											label: 'No',
											value: 2
										}
									]}
									isLoading={loading}
								/>
							</section>
						</FormizStep>
						{/* 2: Client associations */}
						<FormizStep name="step2" label="p.client.asociate">
							<div className="title-section">
								<span className="circle-form">
									<span>2</span>
								</span>
								<h2>
									<FormattedMessage
										id="p.client.asociate"
										defaultMessage="Asociaciones del cliente"
									/>
								</h2>
							</div>

							<section className="grid-container-3c">
								<InputSelectorResponsive
									type="text"
									name="idPais"
									labelText={<FormattedMessage id="p.country" defaultMessage="Pais" />}
									placeholder={
										<FormattedMessage
											id="input.placeholder.select.market.no.country"
											defaultMessage="Seleccione un país"
										/>
									}
									data={selectData?.countries?.map(({ nombrePais, idPais }) => ({
										label: nombrePais,
										value: idPais
									}))}
									isLoading={loadingData}
									// defaultValue={currentCountry}
								/>
								<InputSelectorResponsive
									type="text"
									name="idMercado"
									labelText={<FormattedMessage id="p.market" defaultMessage="Mercado" />}
									placeholder={printable?.idMercado?.nombre || ''}
									data={marketsByCountry.map(({ idMercado, nombre }) => ({
										label: nombre,
										value: idMercado
									}))}
									isLoading={loadingData}
								/>
								<InputSelectorResponsive
									type="text"
									name="idTipoFacturacion"
									labelText={formatterText('p.billing.type', 'Tipo de facturación')}
									placeholder={printable?.idTipoFacturacion?.nombre || ''}
									data={
										!!selectData.billingTypes &&
										selectData.billingTypes.map((item) => {
											const docLabel = `${item.nombre}`;
											return {
												label: docLabel,
												value: item.idTipoFacturacion
											};
										})
									}
									isLoading={loadingData}
								/>
								<label className="d-flex">
									<span className="text-inline">
										<FormattedMessage
											id="p.price.list"
											defaultMessage="Lista de precios primaria"
										/>
									</span>
									<section className="w100-container">
										{!resetPLP && (
											<FieldSelect
												// eslint-disable-next-line react/no-array-index-key
												name={'idListaPrecios'}
												label={'idListaPrecios'}
												required="Required"
												options={listPrices}
												data={auxData?.idListaPrecios}
												setterFunction={updateSelectPriceLists}
											/>
										)}
									</section>
								</label>
								<label className="d-flex">
									<span className="text-inline">
										<FormattedMessage
											id="p.price.list.second"
											defaultMessage="Lista de precios secundaria"
										/>
									</span>
									<section className="w100-container">
										<FieldMultiSelect
											name={'idListaPrecioSecundaria'}
											label={'idListaPrecioSecundaria'}
											required="Required"
											options={secondaryPrices}
											isLoading={loading}
											data={selectValues}
											disabled={disabledLS}
											setterFunction={handleChangeMulti}
										/>
									</section>
								</label>
							</section>
							<div className="title-section">
								<span className="circle-form">
									<span>2.1</span>
								</span>
								<h2>
									<FormattedMessage
										id="p.add.bussines.unit"
										defaultMessage="Agregar Unidades de Negocio"
									/>
								</h2>
							</div>
							<div>
								<label>
									<section className="grid-container-2c">
										{clearValue && unitBusinessInput()}
										<input
											onClick={() => handleAddBusinessUnit(myForm)}
											type="button"
											className="btn-primary margin-auto"
											value={formatterText('p.add.bussines.unit')}
										/>
									</section>
									<section className="wrapForm w100-container">
										<p className="wrap-form-title">
											<FormattedMessage
												id="p.associated.bussines"
												defaultMessage="Unidades de negocio asociadas"
											/>
										</p>
										<Table
											titles={titlesTableBusinessUnit}
											data={dataTableBusinessUnit}
											type="businessByClient"
											handleOpen={handleOpen}
											labelTable={formatterText('p.label.title.listaPrecios')}
											canSearch={true}
											isEdit={true}
											handleDelete={handleDelete}
										/>
									</section>
								</label>
							</div>

							<div>
								<div className="title-section">
									<span className="circle-form">
										<span>2.2</span>
									</span>
									<h2>
										<FormattedMessage id="p.add.branch" defaultMessage="Agregar sucursal" />
									</h2>
								</div>

								<section className="wrapForm w100-container">
									{currentDataTable.length > 0 && (
										<>
											<p className="wrap-form-title">
												<FormattedMessage id="p.branch.associated" />
											</p>
											<Table
												titles={titlesTableBranchOffice}
												data={currentDataTable}
												type="sucursales"
												max={true}
												handleOpen={handleOpen}
												isEdit={true}
												getSucursales={getSucursales}
												getSucursalesMap={getSucursalesMap}
											/>
										</>
									)}
									<section
										className="grid-container-2c"
										style={{
											width: '100%'
										}}
									>
										<label className="d-flex">
											<span className="text-inline">
												<FormattedMessage
													id="p.municipiociudad"
													defaultMessage="Municipio o ciudad"
												/>
											</span>
											<section className="w100-container">
												<select
													name="idCiudadMunicipio"
													id="idCiudadMunicipio"
													className="input-label-style"
													onChange={handleChangeSelect}
												>
													<option value="">
														<FormattedMessage
															id="p.select.option"
															defaultMessage="Seleccione una opción"
														/>
													</option>
													{!!selectData.municipalities &&
														selectData.municipalities.map((item) => (
															<option
																key={item.idMunicipio}
																value={JSON.stringify({
																	label: item.nombre,
																	value: item.idMunicipio
																})}
															>
																{item.nombre}
															</option>
														))}
												</select>
											</section>
										</label>
										<label className="d-flex">
											<span className="text-inline">
												<FormattedMessage id="tab.title.sector" defaultMessage="Sector" />
											</span>
											<section className="w100-container">
												<select
													name="idSector"
													id="idSector"
													className="input-label-style"
													onChange={handleChangeSelect}
												>
													<option value="">
														<FormattedMessage
															id="p.select.option"
															defaultMessage="Seleccione una opción"
														/>
													</option>
													{!!selectData.sectors &&
														selectData.sectors.map((item) => (
															<option
																key={item.idSector}
																value={JSON.stringify({
																	label: item.nombre,
																	value: item.idSector
																})}
															>
																{item.nombre}
															</option>
														))}
												</select>
											</section>
										</label>
									</section>
									<label>
										<span className="warpForm-text">
											<FormattedMessage
												id="tab.title.direccion"
												defaultMessage="Direción completa"
											/>
										</span>
										<input
											type="text"
											name="direccion"
											id="direccion"
											placeholder={formatterText('tab.title.direccion', 'Dirección completa')}
											value={currentBranchOffice.direccion}
											required={formatterText('p.label.title.direccionRequerida')}
											className="input-primary-wrap-large"
											onChange={handleChangeSucursal}
										/>
									</label>
									<label>
										<span className="warpForm-text">
											<FormattedMessage
												id="tab.title.adiconaldatos"
												defaultMessage="Datos adicionales"
											/>
										</span>

										<input
											name="datosAdicionales"
											id="datosAdicionales"
											placeholder={formatterText(
												'p.example.name.ofi',
												'Ej: Número de local, oficina, aparatemento, peso, manzana'
											)}
											type="text"
											value={currentBranchOffice.datosAdicionales}
											required={formatterText('p.label.title.datosAdicionalesRequeridos')}
											className="input-primary-wrap-large"
											onChange={handleChangeSucursal}
										/>
									</label>
									<label>
										<span className="warpForm-text">
											<FormattedMessage
												id="tab.title.observaciones"
												defaultMessage="Observaciones"
											/>
										</span>
										<input
											name="observaciones"
											id="observaciones"
											value={currentBranchOffice.observaciones}
											placeholder={formatterText('p.ej.example.observ', 'Ej: Observaciones')}
											type="text"
											required={formatterText('p.label.title.observacionesRequeridas')}
											className="input-primary-wrap-large"
											onChange={handleChangeSucursal}
										/>
									</label>
								</section>
								<section className="form-responsive-container-buttons">
									<input
										onClick={handleAddBranchOffice}
										type="button"
										className="btn-primary margin-auto"
										value={formatterText('p.add.branch', 'Agregar sucursal')}
									/>
								</section>
							</div>
						</FormizStep>
						{/* 3: Client advisors */}
						<FormizStep isEnabled={true} name="step3" label="p.client.adviser">
							<div className="title-section">
								<span className="circle-form">
									<span>3</span>
								</span>
								<h2>
									<FormattedMessage id="p.client.adviser" defaultMessage="Asesores del cliente" />
								</h2>
							</div>

							<section
								style={{
									width: '95%',
									margin: '0 auto'
								}}
							>
								<Table
									type="asesores"
									titles={titlesTableAdvisers}
									data={otherCurrentDataTable}
									max={true}
									handleOpen={handleOpen}
									getAsesors={getAsesors}
									getAsesorsMap={getAsesorsMap}
								/>

								<div className="title-section">
									<span className="circle-form">
										<span></span>
									</span>
									<h2>
										<FormattedMessage id="p.add.client.adviser" defaultMessage="Agregar asesor" />
									</h2>
								</div>
								<section
									className="grid-container-2c sm-gap"
									style={{
										width: '100%'
									}}
								>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage id="p.document.type" defaultMessage="Tipo de documento" />
										</span>
										<section className="w100-container">
											<select
												name="idTipoDocumentoAsesor"
												id="idTipoDocumentoAsesor"
												className="input-label-style"
												onChange={handleChangeSelectAsesor}
												ref={idTipoDocumentoAsesorRef}
											>
												<option value="">
													<FormattedMessage
														id="p.select.option"
														defaultMessage="Seleccione una opción"
													/>
												</option>
												{!!selectData.documentTypes &&
													selectData.documentTypes.map((item) => (
														<option
															key={item.idTipoDocumento}
															value={JSON.stringify({
																label: `${item.nombre} - ${item.abreviatura}`,
																value: item.idTipoDocumento
															})}
														>
															{`${item.nombre} - ${item.abreviatura}`}
														</option>
													))}
											</select>
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage
												id="table.title.client.id"
												defaultMessage="Identificación"
											/>
										</span>
										<section className="w100-container">
											<input
												type="text"
												name="identificacion"
												id="identificacion"
												placeholder="Identificación"
												value={currentAsesor.identificacion}
												required={formatterText('p.label.title.identificacionRequerida')}
												onChange={handleChangeAsesor}
												className="input-default-3c"
											/>
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage id="input.placeholder.genre" defaultMessage="Género" />
										</span>
										<section className="w100-container">
											<select
												name="genero"
												id="genero"
												className="input-label-style"
												onChange={handleChangeSelectAsesor}
												ref={generoRef}
											>
												<option value="">
													<FormattedMessage
														id="p.select.option"
														defaultMessage="Seleccione una opción"
													/>
												</option>
												{!!selectData.genres &&
													selectData.genres.map((item) => (
														<option
															key={item.idGenero}
															value={JSON.stringify({
																label: `${item.nombre} - ${item.abreviatura}`,
																value: item.idGenero
															})}
														>
															{`${item.nombre} - ${item.abreviatura}`}
														</option>
													))}
											</select>
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage
												id="table.title.full.name"
												defaultMessage="Nombre completo"
											/>
										</span>
										<section className="w100-container">
											<input
												type="text"
												name="nombreAsesor"
												id="nombreAsesor"
												placeholder={formatterText('table.title.full.name', 'Nombre completo')}
												value={currentAsesor.nombreAsesor}
												required={formatterText('p.label.title.nombreCompletoRequerido')}
												onChange={handleChangeAsesor}
												className="input-default-3c"
											/>
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage id="table.title.position" defaultMessage="Cargo" />
										</span>
										<section className="w100-container">
											<select
												name="idCargo"
												id="idCargo"
												className="input-label-style"
												onChange={handleChangeSelectAsesor}
												ref={idCargoRef}
											>
												<option value="">
													<FormattedMessage
														id="p.select.option"
														defaultMessage="Seleccione una opción"
													/>
												</option>
												{!!selectData.cargos &&
													selectData.cargos.map((item) => (
														<option
															key={item.idCargo}
															value={JSON.stringify({
																label: item.nombre,
																value: item.idCargo
															})}
														>
															{item.nombre}
														</option>
													))}
											</select>
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage id="p.correo" defaultMessage="Correo" />
										</span>
										<section className="w100-container">
											<input
												type="email"
												name="emailAsesor"
												id="emailAsesor"
												placeholder={formatterText('p.correo')}
												value={currentAsesor.emailAsesor}
												onChange={(e) =>
													HandlerChangeMailAssesor(e, currentAsesor, setCurrentAsesor, setError)
												}
												onBlur={handleBlurMailAsesor}
												className="input-default-3c"
											/>
											{error && <div className="error-msg">{formatterText(error)}</div>}
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage id="p.correoalterno" defaultMessage="Correo alterno" />
										</span>
										<section className="w100-container">
											<input
												type="email"
												name="correoAlternoAsesor"
												id="correoAlternoAsesor"
												placeholder={formatterText('p.correoalterno', 'Correo alterno')}
												value={currentAsesor.correoAlternoAsesor}
												onChange={(e) =>
													HandlerChangeMailAssesor(e, currentAsesor, setCurrentAsesor, setAltErr)
												}
												onBlur={handleBlurMailAltAsesor}
												className="input-default-3c"
											/>
											{altErr && <div className="error-msg">{formatterText(altErr)}</div>}
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage id="table.title.phone" defaultMessage="Teléfono*" />
										</span>
										<section className="w100-container">
											<input
												type="text"
												name="telefonoAsesor"
												id="telefonoAsesor"
												placeholder={formatterText('table.title.phone', 'Teléfono')}
												value={currentAsesor.telefonoAsesor}
												required={formatterText('p.label.title.telefonoRequerido')}
												onChange={handleChangeAsesor}
												className="input-default-3c"
											/>
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage id="p.alt.phone" defaultMessage="Teléfono alterno" />
										</span>
										<section className="w100-container">
											<input
												type="text"
												name="telefonoAlternoAsesor"
												id="telefonoAlternoAsesor"
												placeholder={formatterText('p.alt.phone', 'Teléfono alterno')}
												value={currentAsesor.telefonoAlternoAsesor}
												required={formatterText('p.label.title.telefonoAlternoRequerido')}
												onChange={handleChangeAsesor}
												className="input-default-3c"
											/>
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage
												id="p.city.residence"
												defaultMessage="Ciudad de residencia"
											/>
										</span>
										<section className="w100-container">
											<select
												name="idMunicipio"
												id="idMunicipio"
												className="input-label-style"
												onChange={handleChangeSelectAsesor}
												ref={idMunicipioRef}
											>
												<option value="">
													<FormattedMessage
														id="p.select.option"
														defaultMessage="Seleccione una opción"
													/>
												</option>
												{!!selectData.municipalities &&
													selectData.municipalities.map((item) => (
														<option
															key={item.idMunicipio}
															value={JSON.stringify({
																label: item.nombre,
																value: item.idMunicipio
															})}
														>
															{item.nombre}
														</option>
													))}
											</select>
										</section>
									</label>
								</section>
							</section>

							<section className="form-responsive-container-buttons">
								<input
									onClick={handleAddAsesor}
									type="button"
									className="btn-primary"
									value={formatterText('p.add.client.adviser', 'Agregar asesor')}
								/>
							</section>
						</FormizStep>
						{/* 4: Attachment files */}
						<FormizStep name="step4" label="tab.title.attached.data">
							<Attachments
								currentFiles={archivos}
								setCurrentFiles={setCurrentFiles}
								isEdited={true}
								uploadNewFile={uploadNewFile}
								type={1}
								showParameters={true}
							/>
						</FormizStep>
						{/* btn saves */}
						<section className="form-responsive-container-buttons">
							<button type="submit" className="btn-primary">
								<FormattedMessage
									id="alert.button.confirm.general"
									defaultMessage="Guardar cambios"
								/>
							</button>
							<button className="input-cancel" onClick={() => navigate(paths.clients)}>
								<FormattedMessage id="btn.cancel" defaultMessage="Cancelar" />
							</button>
						</section>
					</div>
				</form>
			</Formiz>
			{/* Modal BranchOffice and adviser */}
			<Modal
				open={open}
				onClose={handleClose}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				{selectedTable === 1 ? (
					<AddBranchOffice onClose={handleClose} />
				) : (
					<AddAdviser onClose={handleClose} reloadData={getAsesorsMap} />
				)}
			</Modal>
		</div>
	);
}

export default UpdateClientComponents;
