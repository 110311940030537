import useAxios from 'hooks/useAxios';

import { useEffect, useState } from 'react';
// Import Libs
import { useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2';
// Import Components
import HandleOnError from 'common/validators/HandleOnError';

import CustomAlert from 'components/CustomAlert';
// Import Hooks
import useGetData from 'hooks/useGetData';

import useLangv2 from 'hooks/useLangv2';
//Import Utils
import HandleInput from 'common/validators/HandleInput';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';

import { validateDuplicateCode } from 'common/validators/ValidateDuplicates';

// Import Paths
import paths from 'services/paths';
// Import Services

// Import Models
import BusinessUnit from 'models/BusinessUnit';

//Import Constants
import { SPACING, TEXTREGEXMIN } from 'common/validators/Regex';

export default function CreateBusinessUnitViewModel() {
	const { RequestUseCase, endpoints } = useAxios();

	const navigate = useNavigate();
	// Example of form data
	const [formData, setFormData] = useState(new BusinessUnit());
	const [selectedSearchCountries, setSearchSelectedCountries] = useState([]);
	// Setea los valores del multiselect de los paises
	const [selectValues, setSelectValues] = useState([]);
	// espera a que cargue los valores del multiselect
	const { loading, toggleLoading } = useGetData();
	// use Hook of language v2
	const { formatterText, noFilledContent } = useLangv2();

	const [errorName, setErrorName] = useState('');
	const [codeError, setCodeError] = useState('');

	useEffect(() => {
		getDataMarkets();
	}, []);

	const getDataMarkets = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.businessUnit.getAllMarketCountry)
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					if (item.mercado.estado === 1) {
						newArray.push({
							value: item.idMercadoPais,
							label: `${item.mercado.nombre} - ${item.pais.nombrePais}`,
							isFixed: true
						});
					}
				});
				setSearchSelectedCountries(newArray);
				toggleLoading(false);
			})
			.catch((err) => {
				console.log(err);
				toggleLoading(false);
			});
	};

	// Update a string to set into the form
	const handleText = (e) => {
		HandleInput(e, SPACING, formData, setFormData, true);
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	const handleCode = (e) => {
		const example = e;
		example.target.value = e.target.value.toLowerCase();
		HandleInput(example, TEXTREGEXMIN, formData, setFormData);
	};

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		if (errorName !== '' || codeError !== '' || selectValues.length === 0) {
			noFilledContent();
		} else {
			if (
				validateDuplicateCode(
					formData.codigo.replaceAll(/\s{2,}/gi, ' ').toLowerCase(),
					formatterText('alert.message.code.error.general'),
					() => RequestUseCase.get(endpoints.businessUnit.getAllBusinessUnits)
				)
			) {
				createBusinessUnit({
					...formData,
					nombre: formData.nombre.replaceAll(/\s{2,}/gi, ' '),
					descripcion: formData.descripcion.replaceAll(/\s{2,}/gi, ' ')
				});
			}
		}
	};

	const createBusinessUnit = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve) => {
					RequestUseCase.post(endpoints.businessUnit.addBusinessUnit, data)
						.then((res) => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.created.general',
										'El registro se ha creado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => associateCountryMarketBusinessUnit(res.idUnidadNegocio)
								})
							);
						})
						.catch((err) => {
							if (err.response?.data?.message) {
								HandleOnError(err.response?.data?.message);
							} else {
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
						});
				});
			}
		});
	};

	const associateCountryMarketBusinessUnit = (idM) => {
		// realizar un if si seleccionaron paisemercadUnidad
		if (selectValues.length > 0) {
			selectValues.forEach((item) => {
				const data = {
					idMercadoPaisUnidadNegocio: null,
					idMercadoPais: {
						idMercadoPais: item.value
					},
					idUnidadNegocio: {
						idUnidadNegocio: idM
					}
				};
				console.log(data);
				RequestUseCase.post(endpoints.businessUnit.addMarketCountryToUnit, data)
					.then((res) => {
						console.log(res);
						CustomAlert('confirm_msg', {
							icon: 'success',
							title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
							text: formatterText(
								'alert.message.associations.general',
								'Las asociaciones se crearon correctamente'
							),
							confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
							allowOutsideClick: false,
							executeFunction: () => navigate(paths.parameters)
						});
					})
					.catch((err) => {
						console.log(err);
						HandleOnError('Ocurrio un error al asociar');
					});
			});
		} else {
			navigate(paths.parameters);
		}
	};

	const handleChangeMulti = (val) => {
		setSelectValues(val);
	};

	const handleErrorName = () => {
		if (formData.nombre.trim() === '') {
			setErrorName(formatterText('p.label.title.name.is.required'));
		} else {
			setErrorName('');
		}
	};

	const handleErrorCode = () => {
		if (formData.codigo.trim() === '') {
			setCodeError(formatterText('table.title.code.required'));
		} else {
			setCodeError('');
		}
	};

	return {
		codeError,
		errorName,
		formatterText,
		formData,
		handleChangeMulti,
		handleCode,
		handleErrorCode,
		handleErrorName,
		handlerTextDescription,
		handleSubmit,
		handleText,
		loading,
		navigate,
		selectedSearchCountries,
		selectValues
	};
}
