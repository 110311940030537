/* eslint-disable no-useless-escape */
/**
 * Convierte un formato moneda a numero
 * @param {*} value
 * @return value formatted
 *
 * @author rfarfan-qvision
 */

const formatCurrencyFromModalOc = (value) => {
	const cleanedValue = value.replace(/\,/g, '');

	const intPart = cleanedValue.substring(0, cleanedValue.length - 2);
	const floatPart = cleanedValue.substring(cleanedValue.length - 2);
	let formattedValue = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

	if (floatPart) {
		formattedValue += `,${floatPart}`;
	} else {
		formattedValue += '00';
	}
	if (floatPart.length > 2) {
		formattedValue = formattedValue.slice(0, -1);
	}

	return formattedValue !== '00' ? formattedValue : '';
};

export default formatCurrencyFromModalOc;
