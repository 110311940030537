import useAxios from 'hooks/useAxios';
import formatCurrencyFromModal from 'common/utils/formatCurrencyFromModal';
import formatCurrencyFromModalOc from 'common/utils/formatCurrencyFromModalOc';
import formatCurrencyFromModalToTable from 'common/utils/formatCurrencyFromModalToTable';
import HandleOnError from 'common/validators/HandleOnError';
import CustomToast from 'components/CustomToast';
import { useSeachContext } from 'context/SearchContext';
import { TableMinimalContext } from 'context/TableMinimalContext';
import useGetData from 'hooks/useGetData';
import useLang from 'hooks/useLang';
import useLangv2 from 'hooks/useLangv2';
import Cookie from 'js-cookie';
import { useContext, useEffect, useState } from 'react';
import endpoints from 'services/api';
import CustomAlert from 'components/CustomAlert';

export default function UpdateModalItemViewModel({ onClose, refetch, setResultsTableSearch }) {
	const { RequestUseCase } = useAxios();

	const [currentItemMinimal, setCurrentItemMinimal] = useState({
		idPrecioAsociado: '',

		idTipoServicio: '',
		label_idTipoServicio: '',

		idActividadAsociada: '',
		label_idActividadAsociada: '',

		idUnidadMedida: '',
		label_idUnidadMedida: '',

		idListPrice: '',
		valor: ''
	});
	// useLanguage
	const { formatterText } = useLang();
	const { updatedItem } = useLangv2();
	const { toggleLoading } = useGetData();
	const { OnMessage } = CustomToast();
	const { modalData, currentDataTable, setCurrentDataTable } = useContext(TableMinimalContext);
	const { dataTable, setDataTable } = useSeachContext();

	// espera a que cargue los valores del multiselect
	const [auxData, setAuxData] = useState({
		idTipoServicio: 0,
		idActividadAsociada: 0,
		idUnidadMedida: 0
	});
	const [valueMinimalPriceModal, setMinimalPriceModal] = useState('');
	const [valueTaxModal, setValueTaxModal] = useState('');
	const [selectedSearch, setSearchSelected] = useState([
		{
			tipoServicio: [],
			actividadAsociada: [],
			unidadMedida: [],
			valor: 0
		}
	]);
	//  create useEffect clean function
	useEffect(() => {
		setCurrentItemMinimal({
			idPrecioAsociado: modalData?.idPrecioAsociado,

			idTipoServicio: modalData?.idTipoServicio.value,
			label_idTipoServicio: modalData.idTipoServicio.label,

			idActividadAsociada: modalData?.idActividadAsociada.value,
			label_idActividadAsociada: modalData.idActividadAsociada.label,

			idUnidadMedida: modalData?.idUnidadMedida.value,
			label_idUnidadMedida: modalData?.idUnidadMedida.label,

			valor: modalData.valor,
			idListPrice: modalData.idListPrice ? modalData.idListPrice : ''
		});
		setAuxData({
			idTipoServicio: {
				value: modalData.idTipoServicio.value,
				label: modalData.idTipoServicio.label
			},
			idActividadAsociada: {
				value: modalData.idActividadAsociada.value,
				label: modalData.idActividadAsociada.label
			},
			idUnidadMedida: {
				value: modalData.idUnidadMedida.value,
				label: modalData.idUnidadMedida.label
			}
		});
		setMinimalPriceModal(modalData?.valor);
		setValueTaxModal(modalData?.impuesto);
	}, []);

	useEffect(() => {
		if (auxData.idTipoServicio !== 0) {
			getAllActivitiesById(auxData.idTipoServicio);
		}
	}, [auxData.idTipoServicio]);

	// Get all type service by id category service
	const getAllActivitiesById = (selectValue) => {
		// Set the value of datas into []
		RequestUseCase.get(
			endpoints.typoServiceActivities.getTypeServiceActivitiesByTypeService(selectValue.value)
		)
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					newArray.push({
						value: `${item.idActividad.idActividad}-${item.idTipoServicioActividad}`,
						label: `${item.idActividad.nombre}`,
						isFixed: true
					});
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					actividadesServicios: newArray
				}));
				toggleLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toggleLoading(false);
			});
	};

	const handleUpdateModalData = (e) => {
		e.preventDefault();
		if (modalData.valor !== '') {
			if (typeof modalData.idPrecioAsociado === 'number') {
				putStructure();
			} else {
				putStructureOnCreate();
			}
		} else {
			console.error('Debes completar todos los campos');
		}
	};

	const putStructureOnCreate = () => {
		const filterId = currentDataTable.filter(
			(item) => item.idActividadAsociada.value !== modalData?.idActividadAsociada.value
		);

		const { idTipoServicio, idUnidadMedida, idActividadAsociada } = auxData;
		const isDuplicate = currentDataTable.some(
			(item) =>
				item.idActividadAsociada.value === idActividadAsociada &&
				item.idUnidadMedida.value === idUnidadMedida
		);
		const updatedItemData = {
			idPrecioAsociado: currentItemMinimal.idPrecioAsociado,
			idTipoServicio: idTipoServicio,
			idUnidadMedida: idUnidadMedida,
			idActividadAsociada: idActividadAsociada,
			valor: `$${formatCurrencyFromModalOc(valueMinimalPriceModal)
				.replace(/\./g, ',')
				.replace('$', '')}`,
			impuesto: formatCurrencyFromModalToTable(valueTaxModal.replace(',', '.'))
		};

		if (!isDuplicate) {
			setDataTable([...filterId, updatedItemData]);
			setCurrentDataTable([...filterId, updatedItemData]);
			setResultsTableSearch([dataTable]);
			updatedItem();
			onClose();
		} else {
			CustomAlert('short_msg', {
				icon: 'error',
				title: 'Oops...',
				text: 'Existe algun valor repetido ya asociado en la tabla, intentelo nuevamente'
			});
		}
	};

	const displayToast = (type, msg) => {
		OnMessage(type, msg);
	};

	const putStructure = () => {
		const dataUpdate = JSON.parse(localStorage.getItem('dataUpdate'));
		const idListaPrecio = dataUpdate.idListaPreciosActividad;
		currentDataTable.forEach((item) => {
			if (item.idPrecioAsociado === modalData.idPrecioAsociado) {
				const ACTIVITY_IDS =
					typeof auxData.idActividadAsociada?.value === 'string'
						? auxData.idActividadAsociada.value.split('-')
						: modalData.idActividadAsociada.value;

				const data = {
					idListaPreciosActividadDetalle: modalData.idPrecioAsociado,
					idListaPreciosActividad: idListaPrecio,
					idTipoServicio: auxData.idTipoServicio.value,
					idActividad: parseInt(ACTIVITY_IDS[0]) || modalData.idActividadAsociada.value,
					idTipoServicioActividad: parseInt(ACTIVITY_IDS[1]) || modalData.idTipoServicioActividad,
					idUnidadMedida: auxData.idUnidadMedida.value,
					valor: parseFloat(
						formatCurrencyFromModal(valueMinimalPriceModal)
							.replace(/\./g, ',')
							.replace('$', '')
							.replace(',', '.')
							.replace('.', '')
							.replace(',', '.')
					).toFixed(2),
					impuesto: formatCurrencyFromModalToTable(valueTaxModal.replace(',', '.')),

					fechaCreacion: new Date(),
					usuarioCreacion: parseInt(Cookie.get('idUsuario')),
					usuarioModificacion: parseInt(Cookie.get('idUsuario'))
				};
				// aqui se llama el servicio para editar
				RequestUseCase.put(endpoints.associatePrices.updateAssociate, data)
					.then((res) => {
						const data2 = {
							idTipoServicio: res.idTipoServicio
						};
						setCurrentDataTable([...currentDataTable, data2]);
						setResultsTableSearch([...currentDataTable, data2]);
						updatedItem();

						refetch && refetch();
						onClose();
						displayToast('success', 'Se actualizó el registro correctamente.');
					})
					.catch((err) => {
						if (err.response.status === 400) {
							toggleLoading(false);
							HandleOnError(formatterText(err.response.data.message));
						} else {
							HandleOnError(formatterText('alert.message.failed.general'));
						}
					});
			}
		});
	};

	// update the form number
	const handleNumber = (event) => {
		const inputCosto = event.target.value;
		const costoFormateado = inputCosto.replace(/[^\d,]/g, '').replace(/(,.*)\,/g, '$1');
		const costoFormateadoUnaComa = costoFormateado.replace(/(,.*)\,/g, '$1');
		const soloUnaComa = costoFormateadoUnaComa.replace(/,/g, (match) =>
			match === ',' ? match : ''
		);
		const regex = /^\d+(,\d{0,2})?$/;
		const esFormatoValido = regex.test(soloUnaComa);
		if (esFormatoValido) {
			setCurrentItemMinimal({
				...currentItemMinimal,
				valor: `$${soloUnaComa}`
			});
		}

		return soloUnaComa;
	};

	const handleIvaChange = (event) => {
		const inputIva = event.target.value;

		const ivaFormateado = inputIva.replace(/[^\d,]/g, '');
		setValueTaxModal(ivaFormateado);

		const ivaDecimal = parseFloat(ivaFormateado.replace(',', '.'));
		const ivaConDosDecimales = ivaDecimal.toLocaleString('es-ES', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});

		setCurrentItemMinimal({
			...currentItemMinimal,
			[event.target.name]: ivaConDosDecimales
		});
	};

	const formatIvaField = (event) => {
		const valor = `${event.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
		event.target.value = valor;
	};

	console.log(valueMinimalPriceModal);

	return {
		handleIvaChange,
		handleNumber,
		formatIvaField,
		handleUpdateModalData,
		setMinimalPriceModal,
		valueMinimalPriceModal,
		valueTaxModal,
		currentItemMinimal,
		modalData,
		selectedSearch,
		setAuxData,
		formatterText,
		auxData
	};
}
