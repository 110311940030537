import React from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import 'styles/minimalTable.css';
import { DynamicScheduleTableProps } from './DynamicScheduleTable.props';
import DynamicScheduleTableViewModel from './DynamicScheduleTable.viewModel';

export default function DynamicScheduleTable({ titles, data, type, max, canSearch }) {
	const {
		handleSearch,
		searchResults = [],
		formatterText,
		searchRef,
		ChooseRow
	} = DynamicScheduleTableViewModel({ data, type });
	return (
		<>
			<div
				className={max ? 'table-minimal-container width-100' : 'table-minimal-container'}
				style={{
					margin: '0 auto',
					width: '96%',
					marginBottom: '20px'
				}}
			>
				{canSearch && (
					<div style={{ width: '50%' }} className="wrap">
						<div className="search">
							<input
								type="text"
								className="searchTerm spacing-b1"
								// placeholder={
								//   // <FormattedMessage
								//   //   id="placeholder.search.multi.items"
								//   //   defaultMessage="Buscar por palabra"
								//   // />
								// }
								placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
								ref={searchRef}
								onChange={handleSearch}
							/>
							<button
								type="button"
								onClick={() => handleSearch({ target: searchRef.current })}
								className="searchButton"
							>
								<img
									src={require('assets/search.png')}
									style={{ height: '20px' }}
									alt="search logo"
								/>
							</button>
						</div>
					</div>
				)}
				<TableContainer component={Paper}>
					<Table className="table-minimal">
						<TableHead>
							<TableRow className="infoo">
								{titles.map((title) => (
									<TableCell align="center" scope="col" key={title}>
										{title}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>{searchResults.map((item, i) => ChooseRow(item, i))}</TableBody>
					</Table>
				</TableContainer>
			</div>
		</>
	);
}

DynamicScheduleTable.propTypes = DynamicScheduleTableProps;
