import { Formiz } from '@formiz/core';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { InputFieldResponsive } from 'common/inputs/InputFieldResponsive';
import InputSelectorResponsive from 'common/inputs/InputSelectorResponsive';
import Table from 'common/minimalTables/TableMinimal';
import Selector from 'common/selects/Selector';
import SelectorMultiCreate from 'common/selects/SelectorMultiCreate';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import paths from 'services/paths';
import UpdateCostAssociateProduct from '../UpdateCostAssociateProduct';
import CreateProductViewModel from './CreateProduct.viewModel';

export default function CreateProduct() {
	const {
		auxData,
		costo,
		currentDataTable,
		formatCurrency,
		formatIvaField,
		formatterText,
		formData,
		handleAddItemMinimal,
		handleChangeMulti,
		handleClose,
		handleCostoChange,
		handleIvaChange,
		handleOpen,
		handlerTextDescription,
		handleSubmit,
		iva,
		loading,
		myForm,
		navigate,
		open,
		selectedMultiSearch,
		selectedSearch,
		selectorMultiValues,
		setAuxData,
		style,
		titlesTableCostProduct,
		formatSelectorMultiValues
	} = CreateProductViewModel();

	return (
		<div className="centered-form">
			<Formiz onValidSubmit={handleSubmit} connect={myForm}>
				<form
					noValidate
					className="container-wrapForm"
					onSubmit={handleSubmit}
					style={{ minHeight: '16rem' }}
				>
					<div className="container-wrap-formB">
						<div className="title-section">
							<p className="wrap-form-title">{formatterText('p.product', 'Producto')}</p>
						</div>
						<section className="grid-container-2c">
							<InputFieldResponsive
								className="input-primary"
								type="text"
								name="nombre"
								placeholder={formatterText('p.product.name', 'Nombre del producto*')}
								required={formatterText('alert.title.name.product')}
								labelText={formatterText('p.product.name', 'Nombre del producto*')}
								maxLength="100"
							/>
							<InputFieldResponsive
								className="input-primary"
								type="text"
								name="sku"
								value={formData.sku}
								placeholder="Sku"
								maxLength="100"
								required={formatterText('p.sku.is.required')}
								validateInput="sku"
								labelText="SKU*"
							/>
						</section>
						<section className="form-responsive-information__option">
							<label className="p-styles">
								{formatterText('table.title.barCode.product', 'Código de barras*')}
							</label>
							<section className="w100-container">
								<SelectorMultiCreate
									data={selectedMultiSearch}
									isLoading={loading}
									dataValue={formatSelectorMultiValues(selectorMultiValues)}
									setterFunction={handleChangeMulti}
									isRequired={true}
								/>
							</section>
						</section>
						<section className="form-responsive-information__option">
							<h3 className="p-styles">
								{formatterText('p.subcategory.of.product', 'Subcategoría del producto')}
							</h3>
							<InputSelectorResponsive
								newForm={true}
								name="idSubCategoriaProducto"
								data={selectedSearch.subCategoriasProducto}
								placeholder={
									<FormattedMessage
										id="input.placeholder.select"
										defaultMessage="Selecione una opción"
									/>
								}
								dataValue={auxData.idSubCategoriaProducto}
								setterFunction={setAuxData}
								isLoading={loading}
								selectValue={auxData.idSubCategoriaProducto}
								required={formatterText(
									'p.subcategory.required.product',
									'La subcategoría es requerida'
								)}
							/>
						</section>
						<section className="form-responsive-information__option">
							<h3 className="p-styles">
								{formatterText('table.title.description', 'Descripción')}
							</h3>
							<textarea
								className="input-primary-textarea"
								name="descripcion"
								value={formData.descripcion}
								onChange={handlerTextDescription}
								placeholder={formatterText(
									'table.title.description.product',
									'Descripción del producto'
								)}
								maxLength="200"
								label={formatterText('table.title.description', 'Descripción')}
							/>
						</section>
						{currentDataTable.length > 0 && (
							<>
								<p className="wrap-form-title">
									{formatterText('p.costs.asociated.products', 'Costos asociados al producto')}
								</p>
								<section className="form-responsive-container-information">
									<Table
										titles={titlesTableCostProduct}
										data={currentDataTable}
										type="costoProductoProveedor"
										handleOpen={handleOpen}
									/>
								</section>
							</>
						)}
						<div className="title-section">
							<p className="wrap-form-title">
								{formatterText('p.costs.assign', '+ Asignar costo')}
							</p>
						</div>
						<section className="wrapForm">
							<label className="wrapForm__label">
								<h3 className="p-styles spacing-r1">
									{formatterText('p.product.unit.meassurement', 'Unidad de medida')}
								</h3>
								<Selector
									newForm={true}
									name="idUnidadMedida"
									data={selectedSearch.unidadMedida}
									placeholder={
										<FormattedMessage
											id="input.placeholder.select"
											defaultMessage="Selecione una opción"
										/>
									}
									dataValue={auxData.idUnidadMedida}
									setterFunction={setAuxData}
									isLoading={loading}
									selectValue={auxData.idUnidadMedida}
									isRequired={false}
								/>
							</label>
							<label className="wrapForm__label">
								<h3 className="p-styles spacing-r1">
									<FormattedMessage id="table.title.provider" defaultMessage="Proveedor" />
								</h3>
								<Selector
									newForm={true}
									name="idProveedor"
									data={selectedSearch.proveedor}
									placeholder={
										<FormattedMessage
											id="input.placeholder.select"
											defaultMessage="Selecione una opción"
										/>
									}
									dataValue={auxData.idProveedor}
									setterFunction={setAuxData}
									isLoading={loading}
									selectValue={auxData.idProveedor}
									isRequired={false}
								/>
							</label>
							<label className="wrapForm__label">
								<h3 className="p-styles spacing-r1">
									<FormattedMessage id="table.title.currency" defaultMessage="Moneda" />
								</h3>
								<Selector
									name="idMoneda"
									data={selectedSearch.tipoMonedas}
									placeholder={
										<FormattedMessage
											id="input.placeholder.select"
											defaultMessage="Selecione una opción"
										/>
									}
									dataValue={auxData.idMoneda}
									setterFunction={setAuxData}
									isLoading={loading}
									selectValue={auxData.idMoneda}
									isRequired={false}
								/>
							</label>
							<label className="wrapForm__label">
								<h3 className="p-styles spacing-r1">IVA %</h3>
								<input
									className="input-primary"
									type="text"
									name="iva"
									value={formatIvaField(iva)}
									onChange={handleIvaChange}
									placeholder="IVA"
									maxLength="6"
								/>
							</label>
							<label className="wrapForm__label">
								<h3 className="p-styles spacing-r1">
									<FormattedMessage id="table.title.cost" defaultMessage="Costo" />
								</h3>
								<input
									className="input-primary"
									type="text"
									name="costo"
									value={formatCurrency(costo)}
									onChange={handleCostoChange}
									placeholder="$0.00"
									maxLength="20"
								/>
							</label>
							{/* fix C-01 */}
						</section>
						<input
							onClick={handleAddItemMinimal}
							type="button"
							className="btn-primary btn-primary-center"
							value={formatterText('p.costs.assign.button', 'Asignar costo')}
						/>
					</div>
					<section className="form-responsive-container-buttons">
						<button type="submit" className="btn-primary">
							{formatterText('p.product.save.button', 'Guardar producto')}
						</button>
						<button className="input-cancel" onClick={() => navigate(paths.products)}>
							{formatterText('btn.cancel', 'Cancelar')}
						</button>
					</section>
				</form>
			</Formiz>

			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box sx={{ ...style, width: '90%' }}>
					<UpdateCostAssociateProduct
						dataUnits={selectedSearch.unidadMedida}
						dataProviders={selectedSearch.proveedor}
						onClose={handleClose}
					/>
				</Box>
			</Modal>
		</div>
	);
}
