import React from 'react';
// Import Contexts
// Import Hooks
// Import Components
import DynamicTable from 'common/DynamicTable/DynamicHead';
import Search from 'common/Search';
// Import Libs
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
// Import Services
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';
import paths from 'services/paths';
import TableAgendaViewModel from './TableAgenda.viewModel';

const TableAgendaComponent = () => {
	const {
		loading,
		agendas,
		getDataTable,
		handleDeleteItem,
		handleDownloadItem,
		permittedActions,
		formatterText,
		titles,
		renderMessage
	} = TableAgendaViewModel();
	return (
		<>
			{!loading && agendas !== null ? (
				<section className="table-container">
					<section className="userOptions">
						<Search
							placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
							width="50%"
						/>

						<Link to={paths.createSchedule}>
							<button className="btn-add">
								{formatterText('header.title.schedule.create', 'Crear agenda')}
							</button>
						</Link>
					</section>

					<FormattedMessage id="table.name.search.service" defaultMessage="Servicios">
						{() => (
							<DynamicTable
								titles={titles}
								pageName={PAGE_NAMES.Agendas}
								getData={getDataTable}
								handleDeleteItem={handleDeleteItem}
								handleDownloadItem={handleDownloadItem}
								routeToEdit={paths.updateSchedule}
								canDeleted={permittedActions.eliminar}
								canModify={permittedActions.editar}
								canDownload={permittedActions.exportar}
							/>
						)}
					</FormattedMessage>
				</section>
			) : (
				renderMessage()
			)}
		</>
	);
};

export default TableAgendaComponent;
