import * as regex from 'common/validators/Regex';
import * as yup from 'yup';

export const getFormikValidationSchema = () => {
	return yup.object().shape({
		usuario: yup
			.string()
			.matches(regex.TEXTREGEXWITHPOINTS, 'el nombre solo puede tener letras')
			.required('p.alert.user.required')
			.max(100, 'p.label.title.ingresa100Caracteres'),
		correo: yup
			.string()
			.email('p.label.title.correoNoValido')
			.required('p.label.title.correoElectronicoRequerido')
			.matches(regex.EMAILREGEX, 'p.label.title.correoNoValido'),

		entidad: yup
			.string()
			.required('select.placeholder.options')
			.oneOf(['tercero', 'empleado', 'cliente'], 'Debes elegir una opcion')
	});
};
