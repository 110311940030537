import { useContext, useEffect, useState } from 'react';
import useGetData from 'hooks/useGetData';
import { ContextUse } from '../ContextAlert';

export default function AlertsViewModel() {
	// hook to loading data
	const { loading, error, displayMessage, displayLoading } = useGetData();

	const { state, _SetDispatch, DeterminateTypeUser } = useContext(ContextUse);
	const [updated, setUpdated] = useState(false);

	const setCurrentPage = (page) => {
		_SetDispatch({ type: 'CURRENT_PAGE_ALERT', payload: page });
	};

	const setRowsPerPage = (rows) => {
		_SetDispatch({ type: 'ROWS_PER_PAGE', payload: rows });
	};

	const renderMessage = () => {
		return error
			? displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			: displayLoading();
	};
	useEffect(() => {
		DeterminateTypeUser();
	}, [updated === true]);

	return {
		loading,
		state,
		setCurrentPage,
		setRowsPerPage,
		setUpdated,
		renderMessage,
		DeterminateTypeUser
	};
}
