import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';
import useLang from 'hooks/useLang';
import customStyles2 from './selects/generic.style.select';

const Selector = ({
	name,
	data,
	placeholder,
	isLoading,
	dataValue,
	setterFunction,
	isRequired
}) => {
	const { formatterText } = useLang();
	const [selectedOption, setSelectedOption] = useState(null);

	useEffect(() => {
		setSelectedOption(dataValue);
	}, [dataValue]);

	return (
		<div className="select-wrapper-container">
			<Select
				styles={customStyles2}
				// defaultValue={selectedOption}
				onChange={(selectedOption) => {
					setSelectedOption(selectedOption);
					setterFunction((prev) => ({
						...prev,
						[name]: selectedOption.value
					}));
				}}
				noOptionsMessage={() => formatterText('select.placeholder.no.options')}
				options={data}
				placeholder={
					isLoading ? (
						<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
					) : (
						placeholder
					)
				}
			/>
			<input
				className="input-required"
				type="text"
				value={dataValue || selectedOption}
				autoComplete="off"
				required={Boolean(isRequired)}
			/>
		</div>
	);
};

Selector.propTypes = {
	name: PropTypes.string,
	data: PropTypes.array,
	selectValue: PropTypes.func,
	placeholder: PropTypes.string,
	isLoading: PropTypes.bool,
	dataValue: PropTypes.string,
	setterFunction: PropTypes.func,
	isRequired: PropTypes.string
};

export default Selector;
