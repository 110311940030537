import React from 'react';
import PropTypes from 'prop-types';

// Import Libs
import { FormikProvider } from 'formik';

// Import Components
import { Input } from 'common/inputs/Input';
import { Button } from 'common/Button';
import { SelectField } from 'common/SelectField';
import { areaOptions } from 'constants/mails';

export const SendMailForm = ({ formatterText, formik, onClose }) => {
	const {
		errors,
		handleBlur,
		handleChange,
		handleSubmit,
		isSubmitting,
		isValid,
		setFieldValue,
		touched,
		values
	} = formik;

	return (
		<FormikProvider value={formik}>
			<form className="form-responsive margin-top" noValidate onSubmit={handleSubmit}>
				<SelectField
					error={errors.area}
					labelText={formatterText('p.area', 'Área')}
					name="area"
					onBlur={handleBlur}
					options={areaOptions(formatterText)}
					placeholder={formatterText('input.placeholder.select', 'Selecione una opción')}
					touched={touched.area}
					value={values.area}
				/>
				<Input
					disabled
					error={errors.asunto}
					labelText={formatterText('p.subject', 'Asunto')}
					name={'asunto'}
					onBlur={handleBlur}
					placeholder={formatterText('input.placeholder.email.subject', 'Asunto del correo')}
					onChange={handleChange}
					touched={touched.asunto}
					type={'text'}
					value={values.asunto}
				/>
				<Input
					error={errors.destinatario}
					labelText={formatterText('p.recipient', 'Destinatario')}
					name={'destinatario'}
					onBlur={handleBlur}
					placeholder={formatterText('p.email', 'Correo electrónico')}
					setFieldValue={setFieldValue}
					touched={touched.destinatario}
					type={'email'}
					onChange={handleChange}
					value={values.destinatario}
				/>
				<Input
					disabled
					error={errors.contenido}
					labelText={formatterText('p.content', 'Contenido')}
					name={'contenido'}
					onBlur={handleBlur}
					placeholder={formatterText('input.placeholder.email.content', 'Contenido del correo')}
					setFieldValue={setFieldValue}
					touched={touched.contenido}
					type={'text'}
					onChange={handleChange}
					value={values.contenido}
				/>

				<section className="edit-profile-container-buttons">
					<Button
						type="submit"
						className="btn-primary"
						text={formatterText('btn.send.test.email', 'Enviar correo')}
						disabled={!isValid || isSubmitting}
					/>
					<Button
						className="input-cancel"
						text={formatterText('btn.discard', 'Descartar')}
						onClick={onClose}
					/>
				</section>
			</form>
		</FormikProvider>
	);
};

SendMailForm.propTypes = {
	formatterText: PropTypes.func,
	formik: PropTypes.any,
	onClose: PropTypes.func
};
