import { useContext, useEffect, useState } from 'react';
// Import Context
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import Hooks
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';
import { useNavigate, useParams } from 'react-router-dom';
// Import Components
import HandleInput from 'common/validators/HandleInput';
import HandleOnError from 'common/validators/HandleOnError';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import { TEXTREGEXMIN } from 'common/validators/Regex';
import CustomAlert from 'components/CustomAlert';
// Import models
import ProductCategory from 'models/ProductCategory';
// Import libs
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
// Import services
import { useSeachContext } from 'context/SearchContext';
import endpoints from 'services/api';
import paths from 'services/paths';
export default function UpdateProductCategoryViewModel() {
	// Manage axios requests
	const { COOKIE_USER, RequestUseCase } = useAxios();
	// Get id from the url
	const { id } = useParams();
	const [open, setOpen] = useState(false);
	const { setDataTable } = useSeachContext();
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const { currentDataTable, setCurrentDataTable, setResultsTableSearch } =
		useContext(TableMinimalContext);

	const { formatterText, resourceNotFound, noFilledContent, newItemCreated } = useLangv2();
	const navigate = useNavigate();
	// Example of form data
	const [formData, setFormData] = useState(new ProductCategory());

	// toggle state
	const [active, setActive] = useState(true);

	const [errorName, setErrorName] = useState('');
	const [codeError, setCodeError] = useState('');

	const [currentProductCategory, setCurrentProductCategory] = useState({
		idSubCategoriaProducto: uuidv4(),
		nombre: '',
		codigo: '',
		estado: 1,
		descripcion: ''
	});

	const titlesTableSubCategories = [
		formatterText('table.title.subCategory', 'Subcategoría'),
		formatterText('table.title.description', 'Descripción'),
		formatterText('table.title.code', 'Código'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];

	useEffect(() => {
		const id = getDataProductCategory();
		getSubCategoriesProductById(id);
	}, []);

	useEffect(() => {
		getCategoryById(id);
		getSubCategoriesProductById(id);
	}, [id]);

	const setCategoryForm = (data) => {
		setFormData({
			idCategoriaProducto: data.idCategoriaProducto,
			nombre: data.nombre,
			descripcion: data.descripcion,
			codigo: data.codigo,
			estado: data.estado,
			fechaRegistro: data.fechaRegistro,
			fechaModificacion: data.fechaModificacion,
			usuarioCreacion: data.usuarioCreacion,
			usuarioModificacion: data.usuarioModificacion
		});
	};
	//get data from LocalStorage
	const getDataProductCategory = () => {
		const productCategory = JSON.parse(localStorage.getItem('dataUpdate'));
		if (productCategory) {
			setCategoryForm(productCategory);
			setActive(productCategory.estado);
			return productCategory.idCategoriaProducto;
		} else {
			navigate(paths.products);
		}
	};
	//get data from api
	const getCategoryById = (id) => {
		RequestUseCase.get(endpoints.productCategory.getCategoryById(id)).then((res) => {
			if (res !== null) {
				setCategoryForm(res);
				setActive(res.estado);
			} else {
				resourceNotFound();
			}
		});
	};

	const getSubCategoriesProductById = (id) => {
		RequestUseCase.get(endpoints.subProductCategory.getAllSubProductCategoryByCategory(id))
			.then((res) => {
				const filterData = res.map((item) => ({
					idSubCategoriaProducto: item.idSubCategoriaProducto,
					idCategoriaProducto: {
						idCategoriaProducto: item.idCategoriaProducto.idCategoriaProducto
					},
					nombre: item.nombre,
					descripcion: item.descripcion,
					codigo: item.codigo,
					estado: item.estado,
					fechaRegistro: item.fechaRegistro,
					fechaModificacion: null,
					usuarioCreacion: item.usuarioCreacion,
					usuarioModificacion: COOKIE_USER
				}));
				setCurrentDataTable(res);
				setResultsTableSearch(res);
				setDataTable(filterData);
				// setCategoryProduct(filterData);
			})
			.catch(() => {
				HandleOnError(
					formatterText(
						'alert.subcategory.error.general',
						'La subcategoria esta asociada a otra categoria'
					)
				);
			});
	};

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		if (formData.nombre === '' || formData.codigo === '') {
			HandleOnError(
				formatterText(
					'p.label.title.debesCompletarTodosLosCampos',
					'Debes completar todos los campos'
				)
			);
		} else {
			const data = {
				...formData,
				estado: active ? '1' : '0'
			};
			putProductCategory(data);
		}
	};

	const putProductCategory = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.update.general', 'Se va a editar el registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve) => {
					RequestUseCase.put(endpoints.productCategory.updateProductCategory, data)
						.then(() => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.updated.general',
										'El registro se ha actualizado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => {
										setResultsTableSearch([]);
										setCurrentDataTable([]);
										navigate(paths.products);
									}
								})
							);
						})
						.catch((err) => {
							if (err.response.status === 400) {
								HandleOnError(
									formatterText(
										'alert.message.code.error.general',
										'El código ya existe, por favor ingrese uno distinto'
									)
								);
							} else if (err.response.status === 412) {
								HandleOnError(formatterText('alert.message.code.error.characters'));
							} else {
								HandleOnError(
									formatterText(
										'alert.message.failed.general',
										'Error al crear el registro, por favor intente nuevamente.'
									)
								);
							}
							console.error(err);
						});
				});
			}
		});
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	const handleCode = (e) => {
		const example = e;
		example.target.value = e.target.value.toLowerCase();
		HandleInput(example, TEXTREGEXMIN, formData, setFormData);
	};

	const handleAddproductCategory = () => {
		if (currentProductCategory.nombre !== '' && currentProductCategory.codigo !== '') {
			const filter = currentDataTable.filter(
				(item) => item.codigo === currentProductCategory.codigo
			);

			if (filter.length > 0) {
				HandleOnError(
					formatterText(
						'alert.subcategory.exists.general',
						'El código ya existe en alguna subcategoría, por favor ingrese uno distinto'
					)
				);
			} else {
				const DATA = {
					idSubCategoriaProducto: null,
					nombre: currentProductCategory.nombre,
					descripcion: currentProductCategory.descripcion,
					codigo: currentProductCategory.codigo,
					idCategoriaProducto: {
						idCategoriaProducto: id
					},
					estado: currentProductCategory.estado,
					fechaRegistro: null,
					fechaModificacion: null,
					usuarioCreacion: COOKIE_USER,
					usuarioModificacion: null
				};

				RequestUseCase.post(endpoints.subProductCategory.addSubProductCategory, DATA)
					.then(() => {
						newItemCreated();
						getSubCategoriesProductById(id);
						setCurrentProductCategory({
							nombre: '',
							codigo: '',
							descripcion: '',
							estado: '',
							idSubCategoriaProducto: uuidv4()
						});
					})
					.catch(() => HandleOnError(formatterText('alert.message.code.error.characters')));
			}
		} else {
			noFilledContent();
		}
	};

	const handleNameTextSubCategory = (e) => {
		if (e.target.value.match(/^[a-zA-Z0-9 \-ñáéíóúÁÉÍÓÚÑ]*$/) !== null) {
			setCurrentProductCategory({ ...currentProductCategory, [e.target.name]: e.target.value });
		}
	};

	const handleChangeSubCategoryCode = (e) => {
		const example = e;
		example.target.value = e.target.value.toLowerCase();
		HandleInput(example, TEXTREGEXMIN, currentProductCategory, setCurrentProductCategory);
	};

	const handlerTextDescriptionSubCategory = (e) => {
		HandlerTextDescription(e, currentProductCategory, setCurrentProductCategory);
	};

	// Actualiza el nombre o un texto del formulario
	const handleNameText = (e) => {
		if (e.target.value.match(/^[a-zA-Z0-9 \-ñáéíóúÁÉÍÓÚÑ]*$/) !== null) {
			setFormData({ ...formData, [e.target.name]: e.target.value });
		}
	};

	const handleErrorName = () => {
		if (formData.nombre.trim() === '') {
			setErrorName(formatterText('p.label.title.name.is.required'));
		} else {
			setErrorName('');
		}
	};

	const handleErrorCode = () => {
		if (formData.codigo.trim() === '') {
			setCodeError(formatterText('table.title.code.required'));
		} else {
			setCodeError('');
		}
	};
	return {
		active,
		codeError,
		currentDataTable,
		currentProductCategory,
		errorName,
		formatterText,
		formData,
		getSubCategoriesProductById,
		handleAddproductCategory,
		handleChangeSubCategoryCode,
		handleClose,
		handleCode,
		handleErrorCode,
		handleErrorName,
		handleNameText,
		handleNameTextSubCategory,
		handleOpen,
		handlerTextDescription,
		handlerTextDescriptionSubCategory,
		handleSubmit,
		id,
		navigate,
		open,
		setActive,
		titlesTableSubCategories
	};
}
