import React from 'react';
// Mui Cell row style
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import BtnAssignSearch from 'common/minimalTables/BtnAssignSearch';
// Import services

const RowSearchServices = ({
	item,
	i, // index
	handleDetails,
	handleSelect
}) => {
	return (
		<TableRow key={i}>
			<TableCell>{item.name}</TableCell>
			<TableCell>{item.prevServ}</TableCell>
			<TableCell>{item.prevRisk}</TableCell>
			<TableCell>{item.newRisk}</TableCell>

			<BtnAssignSearch
				item={item}
				handleDetails={handleDetails}
				handleSelect={() => handleSelect(item)}
			/>
		</TableRow>
	);
};

export default RowSearchServices;
