import Search from 'common/Search';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
// paths to routes
import paths from 'services/paths';
// Hooks
// hook para cargar los datos
// Context de búsqueda
import ExportJsonFile from 'components/utils/ExportJsonFile';
// Llamar a la tabla dinamica
import DynamicTable from 'common/DynamicTable/DynamicHead';
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';

import ProviderPageViewModel from './ProviderPage.viewModel';

export default function ProviderComponent() {
	const {
		formatterText,
		loading,
		clients,
		getDataTable,
		searchResults,
		handleDeleteItem,
		handleEditStateItem,
		renderMessage,
		permittedActions,
		titles
	} = ProviderPageViewModel();

	if (loading && clients === null) {
		return renderMessage();
	}
	return (
		<section className="table-container">
			<section className="userOptions">
				{permittedActions.consultar && (
					<Search
						placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
						width="50%"
					/>
				)}
				{permittedActions.crear && (
					<Link to={paths.createProvider}>
						<button className="btn-add">
							<FormattedMessage
								id="header.title.provider.create"
								defaultMessage="Crear Proveedor"
							/>
						</button>
					</Link>
				)}
				{permittedActions.exportar && (
					<ExportJsonFile
						moduleName="Proveedores"
						userName={JSON.parse(localStorage.getItem('userData')).usuario || 'ADMIN'}
						dataTable={searchResults}
					/>
				)}
			</section>
			<FormattedMessage id="table.name.search.provider" defaultMessage="Proveedores">
				{() =>
					permittedActions.consultar && (
						<DynamicTable
							titles={titles}
							pageName={PAGE_NAMES.Proveedores}
							getData={getDataTable}
							handleDeleteItem={handleDeleteItem}
							handleEditStateItem={handleEditStateItem}
							routeToEdit={paths.updateProvider}
							canDeleted={permittedActions.eliminar}
							canModify={permittedActions.editar}
						/>
					)
				}
			</FormattedMessage>
		</section>
	);
}
