import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
// Import Hooks
import useGetData from 'hooks/useGetData';
// Import libs
import { searchDataTable } from 'common/utils/searchDataTable';
import SortData from 'components/utils/SortData';
import { isEmpty, sortBy } from 'lodash';
import moment from 'moment';

export const SearchContext = createContext();

export const useSeachContext = () => {
	return useContext(SearchContext);
};

export function SearchWrapper({ children }) {
	const [searchUnitBussines, setSearchUnitBussines] = useState([]);
	const [searchCoverage, setSearchCoverage] = useState([]);
	const [searchSucursal, setSearchSucursal] = useState([]);
	const [searchResults, setSearchResults] = useState([]);
	const [rolesResults, setRolesResults] = useState([]);
	const [resultsFound, setResultFound] = useState([]);
	const [activitiesResults, setActivitiesResults] = useState([]);
	const [dataTable, setDataTable] = useState([]);
	const [rolSearch, setRolSearch] = useState([]);
	const [primaryMinimalTable, setPrimaryMinimalTable] = useState([]);
	const [secondMinimalTable, setSecondMinimalTable] = useState([]);
	const [thirdMinimalTable, setThirdMinimalTable] = useState([]);
	const [dataTableTechnical, setDataTableTechnical] = useState([]);
	const [dataTableTechToAssign, setDataTableTechToAssign] = useState([]);
	const [tableTechToAssign, setTableTechToAssign] = useState([]);
	const [unitBussinessResults, setUnitBussinessResults] = useState([]);
	const [clientSucursalResults, setClientSucursalResults] = useState([]);
	const [associateCoverageResults, setAssociateCoverageResults] = useState([]);
	const [prevPrimaryMinimalTable, setPrevPrimaryMinimalTable] = useState([]);
	const [prevSecondMinimalTable, setPrevSecondMinimalTable] = useState([]);
	const [prevThirdMinimalTable, setPrevThirdMinimalTable] = useState([]);
	const [leakedData, setLeakedData] = useState([]);

	// state of the time zone selected in Create and Update Schedule
	const [timeZoneSelected, setTimeZoneSelected] = useState([]);
	const [dataTableBusinessUnit, setDataTableBusinessUnit] = useState([]);

	const [table, setTable] = useState([]);
	const [search, setSearch] = useState('');
	const [sortedAscResults, setSortedAscResults] = useState('');
	const {
		loading,
		error,
		toggleLoading,
		toggleError,
		handleClick,
		displayMessage,
		displayLoading
	} = useGetData();
	const [rowsPerPage, setRowsPerPage] = useState(50);

	const [countriesPage, setCountriesPage] = useState(50);
	const [departmentsPages, setDepartmentsPages] = useState(50);
	const [municipalitisPages, setMnicipalitisPages] = useState(50);
	const [sectorsPages, setSectorsPages] = useState(50);

	const [rowMarket, setRowMarket] = useState(50);
	const [rowBussinesUnit, setRowBussinesUnit] = useState(50);
	const [rowProvider, setRowProvider] = useState(50);
	const [rowClient, setRowClient] = useState(50);
	const [rowBilling, setRowBilling] = useState(50);
	const [rowParams, setRowParams] = useState(50);

	const [rowCategories, setRowCategories] = useState(50);
	const [rowStages, setRowStages] = useState(50);
	const [rowActivities, setRowActivities] = useState(50);
	const [rowPrices, setRowPrices] = useState(50);
	const [rowForms, setRowForms] = useState(50);

	const [rowtechs, setRowTechs] = useState(50);
	const [rowVehicles, setRowVehicles] = useState(50);

	const [RowcategoriesProduct, setRowscategoriesProduct] = useState(50);
	const [RowproductsPage, setRowproductsPage] = useState(50);

	const [page, setPage] = useState(0);
	const [countriesSheet, setCountriesSheet] = useState(0);
	const [departmentSheet, setdepartmentSheet] = useState(0);
	const [municipalitiesSheet, setMunicipalitiesSheet] = useState(0);
	const [sectorsSheet, setSectorsSheet] = useState(0);

	const [marketPage, setMarketPage] = useState(0);
	const [unitBussinesPage, setUnitBussinesPage] = useState(0);
	const [providerPage, setProviderPage] = useState(0);
	const [clientPage, setClientPage] = useState(0);
	const [billingPage, setBillingPage] = useState(0);
	const [thirdPage, setThirdPage] = useState(0);

	const [serviceCatPage, setServiceCatPage] = useState(0);
	const [serviceStagetPage, setServiceStagetPage] = useState(0);
	const [activitiesPage, setActivitiesPage] = useState(0);
	const [priceListPage, setPriceListPage] = useState(0);
	const [formPage, setFormPage] = useState(0);

	const [techsPage, setTechsPage] = useState(0);
	const [vehiclesPage, setVehiclesPage] = useState(0);

	const [categoriesProductPage, setcategoriesProductPage] = useState(0);
	const [productsPage, setProductsPage] = useState(0);

	const searchResultsSorted = useMemo(
		() => (!sortedAscResults ? searchResults : SortData(searchResults, sortedAscResults)),
		[searchResults, sortedAscResults, timeZoneSelected]
	);

	const handleSearch = (e) => {
		const searchTerm = e.target.value.toLowerCase().replaceAll(/\s{2,}/gi, '');

		setSearch(searchTerm);

		if (Array.isArray(rolSearch) && rolSearch.length > 0) {
			const filteredRoles = rolSearch.filter(
				(objeto) =>
					objeto.idRol.toString().includes(searchTerm) ||
					objeto.nombre.toLowerCase().includes(searchTerm)
			);

			setRolesResults(filteredRoles);
		} else {
			let dataToUse = dataTable || tableTechToAssign || dataTableTechnical || timeZoneSelected;

			if (dataToUse.length == 0 && dataTableBusinessUnit) {
				dataToUse = dataTableBusinessUnit;
			}

			let sortedResults = sortBy(
				searchDataTable({
					data: dataToUse,
					query: e.target.value
				}),
				(item) => (item.fc ? moment(item.fc).unix() : item)
			);
			sortedResults = sortedResults.length === 0 ? dataToUse : sortedResults;

			setDataTableBusinessUnit(sortedResults);
			setSearchResults(sortedResults);
			setResultFound(sortedResults);
			setActivitiesResults(sortedResults);
			setTimeZoneSelected(sortedResults);
			setDataTableTechnical(sortedResults);
			//setDataTableTechToAssign(sortedResults);
		}
	};

	// modificacion funciones busqueda Unidad Negocio, cobertura y sucursal Cliente
	const handleSearchAsociations = (e, data, setSearchFunction) => {
		const searchTerm = e.target.value.toLowerCase().replace(/\s{2,}/g, '');
		setSearch(searchTerm);

		if (searchTerm === '') {
			return;
		}

		const search = searchDataTable({
			data,
			query: e.target.value
		});

		if (search.length > 0) {
			setSearchFunction(search);
		} else {
			setSearchFunction('');
		}
	};

	const handleSearchTechnical = (e) => {
		const searchTerm = e.target.value.toLowerCase().replaceAll(/\s{2,}/gi, '');
		setSearch(searchTerm);

		const dataToUse = dataTable || tableTechToAssign || dataTableTechnical || timeZoneSelected;

		const sortedResults = sortBy(
			searchDataTable({
				data: dataToUse,
				query: e.target.value,
				isRecursive: true
			}),
			(item) => (item.fc ? moment(item.fc).unix() : item)
		);

		setDataTableTechToAssign(sortedResults);
		setTableTechToAssign(tableTechToAssign);
	};

	const handleSearchUnityBussines = (e) => {
		handleSearchAsociations(e, primaryMinimalTable, setSearchUnitBussines);
	};

	const handleSearchClientSucursal = (e) => {
		handleSearchAsociations(e, secondMinimalTable, setSearchSucursal);
	};

	const handleSearchModalFranjas = (e) => {
		handleSearchTechnical(e);
	};

	const handleSearchCoverage = (e) => {
		handleSearchAsociations(e, thirdMinimalTable, (search) => {
			const sortedResults = sortBy(search, (item) => (item.fc ? moment(item.fc).unix() : item));
			setSearchCoverage(sortedResults);
		});
	};

	useEffect(() => {
		if (isEmpty(search) || !search) {
			setSearchResults(dataTable);
			setSearchUnitBussines(primaryMinimalTable);
			setSearchSucursal(secondMinimalTable);
			setSearchCoverage(thirdMinimalTable);
			setRolesResults(rolSearch);
		}
	}, [dataTable, search, primaryMinimalTable, secondMinimalTable, thirdMinimalTable]);

	const values = {
		sortedAscResults,
		setSortedAscResults,
		searchResultsSorted,
		searchResults,
		setSearchResults,
		resultsFound,
		setResultFound,
		activitiesResults,
		setActivitiesResults,
		dataTable,
		setDataTable,
		table,
		setTable,
		handleSearch,
		handleSearchUnityBussines,
		search,
		setSearch,
		loading,
		error,
		toggleLoading,
		toggleError,
		handleClick,
		displayMessage,
		displayLoading,
		rowsPerPage,
		setRowsPerPage,
		page,
		setPage,
		countriesSheet,
		setCountriesSheet,
		departmentSheet,
		setdepartmentSheet,
		municipalitiesSheet,
		setMunicipalitiesSheet,
		sectorsSheet,
		setSectorsSheet,
		countriesPage,
		setCountriesPage,
		departmentsPages,
		setDepartmentsPages,
		municipalitisPages,
		setMnicipalitisPages,
		sectorsPages,
		setSectorsPages,
		primaryMinimalTable,
		setPrimaryMinimalTable,
		secondMinimalTable,
		setSecondMinimalTable,
		thirdMinimalTable,
		setThirdMinimalTable,
		unitBussinessResults,
		setUnitBussinessResults,
		clientSucursalResults,
		setClientSucursalResults,
		associateCoverageResults,
		setAssociateCoverageResults,
		dataTableTechnical,
		setDataTableTechnical,
		dataTableTechToAssign,
		setDataTableTechToAssign,
		tableTechToAssign,
		setTableTechToAssign,
		timeZoneSelected,
		setTimeZoneSelected,
		prevPrimaryMinimalTable,
		setPrevPrimaryMinimalTable,
		prevSecondMinimalTable,
		setPrevSecondMinimalTable,
		prevThirdMinimalTable,
		setPrevThirdMinimalTable,
		searchSucursal,
		setSearchSucursal,
		handleSearchClientSucursal,
		searchCoverage,
		setSearchCoverage,
		handleSearchModalFranjas,
		handleSearchCoverage,
		searchUnitBussines,
		setSearchUnitBussines,
		leakedData,
		setLeakedData,
		thirdPage,
		setThirdPage,
		billingPage,
		setBillingPage,
		clientPage,
		setClientPage,
		providerPage,
		setProviderPage,
		unitBussinesPage,
		setUnitBussinesPage,
		marketPage,
		setMarketPage,
		rowMarket,
		setRowMarket,
		rowParams,
		setRowParams,
		rowBilling,
		setRowBilling,
		rowClient,
		setRowClient,
		rowProvider,
		setRowProvider,
		rowBussinesUnit,
		setRowBussinesUnit,
		serviceCatPage,
		setServiceCatPage,
		serviceStagetPage,
		setServiceStagetPage,
		activitiesPage,
		setActivitiesPage,
		priceListPage,
		setPriceListPage,
		formPage,
		setFormPage,
		rowCategories,
		setRowCategories,
		rowStages,
		setRowStages,
		rowActivities,
		setRowActivities,
		rowPrices,
		setRowPrices,
		rowForms,
		setRowForms,
		techsPage,
		setTechsPage,
		vehiclesPage,
		setVehiclesPage,
		rowtechs,
		setRowTechs,
		rowVehicles,
		setRowVehicles,
		categoriesProductPage,
		setcategoriesProductPage,
		productsPage,
		setProductsPage,
		RowcategoriesProduct,
		setRowscategoriesProduct,
		RowproductsPage,
		setRowproductsPage,
		rolSearch,
		setRolSearch,
		rolesResults,
		setRolesResults,
		dataTableBusinessUnit,
		setDataTableBusinessUnit
	};
	return <SearchContext.Provider value={values}>{children}</SearchContext.Provider>;
}

SearchWrapper.propTypes = {
	children: PropTypes.node
};
