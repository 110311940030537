import { useContext, useState } from 'react';
// Import Context
// Import Hooks
import { useAuth } from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';
// Import Components
import CustomAlert from 'components/CustomAlert';
// Import Libs
import Cookie from 'js-cookie';
// Import Services
import { confirmEmail, confirmSession } from 'services/api/auth';
import useAxios from 'hooks/useAxios';
import paths from 'services/paths';
import { langContext } from 'context/langContext';
import 'styles/login.css';
// Import Hooks
import useLang from 'hooks/useLang';

export default function LoginViewModel() {
	const { RequestUseCase, endpoints } = useAxios();
	const auth = useAuth();
	const navigate = useNavigate();
	const [loginAttempts, setLoginAttempts] = useState(1);
	const idioma = useContext(langContext);
	const [isLoading, setIsLoading] = useState(false);
	const [passwordShown, setPasswordShown] = useState(false);
	const togglePasswordVisiblity = () => setPasswordShown(!passwordShown);

	//  Call hook of language
	const { formatterText } = useLang();

	/* useEffect(()=>{
    localStorage.setItem('lang','en-ES');
    idioma.establecerLenguaje('en-ES');
    },[]) */

	const [formData, setFormData] = useState({
		Username: '',
		Password: '',
		Step: 'step1',
		TokenCelular: null,
		TipoAcceso: null,
		Code: ''
	});

	const [formData2, setFormData2] = useState({
		Username: 'Joshe',
		Password: 'qp2lmy2I',
		TokenCelular: null,
		Step: 'step2',
		Code: ''
	});

	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const fetchAndStoreData = () => {
		RequestUseCase.get(endpoints.services.getAllHours)
			.then((res) => {
				localStorage.setItem('hours', JSON.stringify(res));
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setIsLoading(true);

		verifyStepOne();
	};

	const verifyStepOne = () => {
		confirmEmail(formData)
			.then((res) => {
				if (res.message === 'Error_Step_0_UsuarioBloqueado') {
					CustomAlert('short_msg', {
						icon: 'error',
						title: formatterText('alert.error.login'),
						text: formatterText('alert.user.locked')
					});
					setIsLoading(false);
					return;
				}
				if (res.message === 'Ok_Step_1') {
					handleOpen();
					setIsLoading(false);
				} else {
					CustomAlert('short_msg', {
						icon: 'error',
						title: formatterText('alert.message.error.ops'),
						text: formatterText('alert.message.error.mensage')
					});
					setIsLoading(false);
					setLoginAttempts(loginAttempts + 1);
					if (loginAttempts >= 3) {
						handleAttempts();
						setLoginAttempts(1);
					} else {
						CustomAlert('short_msg', {
							icon: 'error',
							title: formatterText('alert.message.error.ops'),
							text: formatterText('alert.message.error.mensage')
						});
					}
				}
			})
			.catch(() => {
				setIsLoading(false);
				CustomAlert('short_msg', {
					icon: 'error',
					title: formatterText('alert.message.error.ops'),
					text: formatterText('text.failed.request')
				});
			});
	};

	const verifyStepTwo = (e) => {
		e.preventDefault();
		handleClose();
		fetchAndStoreData();
		confirmSession(formData2)
			.then((res) => {
				console.log('res :', res);
				if (res.message === 'Ok_Step2_code') {
					const token = res?.token;
					sessionStorage.setItem('token', token);
					// Get user id info
					getUserInfoById(res.userid, res, res.lenguage);
				} else {
					setLoginAttempts(loginAttempts + 1);
					CustomAlert('short_msg', {
						icon: 'error',
						title: 'Ops...',
						text: formatterText('alert.message.code.expired'),
						confirmButtonColor: '#FFC954'
					});
				}
			})
			.catch(() => {
				CustomAlert('short_msg', {
					icon: 'error',
					title: 'Ops...',
					text: 'Hubo un error en la válidación',
					confirmButtonColor: '#FFC954'
				});
			});
	};

	/* Esta función trae los datos del usuario para guardarlos en localstorage */
	const getUserInfoById = (idUser, responseData, lang) => {
		RequestUseCase.get(endpoints.users.getUserById(idUser))
			.then((res) => {
				if (res.estado === 2) {
					CustomAlert('short_msg', {
						icon: 'warning',
						title: 'Atención',
						text: 'El usuario debe cambiar la contraseña por primera vez',
						confirmButtonColor: '#FFC954'
					});
					setIsLoading(false);
				} else {
					Cookie.set('idUsuario', responseData.userid, {
						sameSite: 'None',
						secure: true
					});
					auth.login();
					if (lang?.toLowerCase() == 'es') {
						localStorage.setItem('lang', 'en-ES');
					} else if (lang?.toLowerCase() == 'en') {
						localStorage.setItem('lang', 'en-US');
					}
					idioma.establecerLenguaje(localStorage.getItem('lang'));
					CustomAlert('confirm_msg', {
						icon: 'success',
						title: formatterText('alert.message.confirm.Login', 'Inicio de sesión exitoso'),
						text: formatterText('btn.confirm.session', '"Haz click en el botón para continuar"'),
						confirmButtonText: formatterText('title.service.create.loguin', 'Aceptar'),
						allowOutsideClick: false,
						executeFunction: () => {
							navigate(paths.panelNotifications);
							window.location.reload();
						}
					});
					const USER_DATA = {
						...res,
						clave: null
					};
					localStorage.setItem('userData', JSON.stringify(USER_DATA));
					Cookie.set('rolSize', res.roles.length, {
						sameSite: 'None',
						secure: true
					});
				}
			})
			.catch((err) => {
				console.log('Error', err);
			});
	};

	const handleChange = (e) => {
		setFormData({
			...formData,
			TokenCelular: null,
			TipoAcceso: 1,
			[e.target.name]: e.target.value
		});
		setFormData2({
			...formData,
			[e.target.name]: e.target.value
		});
	};

	const handleChange2 = (e) => {
		setFormData2({
			...formData2,
			TokenCelular: null,
			[e.target.name]: e.target.value,
			Step: 'step2'
		});
	};

	const handleAttempts = () => {
		CustomAlert('short_msg', {
			icon: 'error',
			title: 'Ops...',
			text: formatterText(
				'alert.message.confirm.updated.general',
				'Cuenta bloqueada temporalmente, por favor contacte con un administrador'
			),
			confirmButtonColor: '#FFC954'
		});
		// Agregar servicio de bloqueo de usuario
	};
	return {
		isLoading,
		passwordShown,
		handleChange,
		handleSubmit,
		togglePasswordVisiblity,
		open,
		setOpen,
		handleChange2,
		verifyStepTwo,
		formatterText,
		navigate
	};
}
