import { Formiz, FormizStep } from '@formiz/core';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Attachments from 'components/AttachedFiles/Attachments';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import paths from 'services/paths';
import { FormViewModels } from './Form.viewModels';
export const FormComponents = () => {
	const {
		permittedActions,
		value,
		archivos,
		setCurrentFiles,
		navigate,
		formatterText,
		isLoading,
		handleChange,
		handleSaveAttachedFiles,
		handleSubmit,
		formData
	} = FormViewModels();

	return (
		<>
			{permittedActions.crear && (
				<div className="centered-form">
					<Formiz onValidSubmit={handleSubmit} connect={formData}>
						<form noValidate={true} onSubmit={formData.submit} className="container-wrapForm">
							<div className="new-container-wrapForm__tabs">
								{formData.steps.map((step) => (
									<button
										key={step.name}
										className={`new-tab-option ${
											step.name === formData.currentStep.name ? 'is-active' : ''
										}`}
										type="button"
										onClick={() => {
											formData.goToStep(step.name);
										}}
									>
										{!step.isValid && step.isSubmitted && <small className="mr-2">⚠️</small>}
										{step.label}
									</button>
								))}
							</div>

							<div className="container-wrapForm-content">
								<FormizStep
									name="step-1"
									label={formatterText('nav.parent.3.child.option.10', 'Conciliaciones')}
								>
									<section className="container-wrapForm-content">
										<Attachments
											currentFiles={archivos}
											setCurrentFiles={setCurrentFiles}
											isEdited={false}
											uploadNewFile={handleSaveAttachedFiles}
											type={6} //para facturas
											showParameters={true}
										/>
										<section className="grid-container-3c">
											<div className="d-flex">
												<FormControl className="d-flex">
													<RadioGroup
														aria-labelledby="demo-controlled-radio-buttons-group"
														name="controlled-radio-buttons-group"
														defaultValue={value}
														value={value}
														onChange={handleChange}
													>
														<FormControlLabel
															value="ticket"
															control={<Radio color={'primary'} />}
															label={formatterText(
																'label.text.billing.upload.ticket',
																'Subir por ticket'
															)}
															className="w100-container"
														/>
														<FormControlLabel
															value="expediente"
															control={<Radio />}
															label={formatterText(
																'label.text.billing.upload.exp',
																'Subir por expediente'
															)}
														/>
													</RadioGroup>
												</FormControl>
											</div>
										</section>
									</section>
								</FormizStep>
							</div>
							<div className="demo-form__footer">
								<section className="form-responsive-container-buttons">
									<button disabled={isLoading} type="submit" className="btn-primary">
										{isLoading
											? formatterText('btn.loading', 'Cargando...')
											: formatterText('btn.save', 'Guardar')}
									</button>
									<button
										className="input-cancel"
										onClick={() => navigate(paths.panelNotifications)}
									>
										<FormattedMessage id="alert.button.cancel.general" defaultMessage="Cancelar" />
									</button>
								</section>
							</div>
						</form>
					</Formiz>
				</div>
			)}
		</>
	);
};
