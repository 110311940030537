import DynamicTable from 'components/zones/DynamicTable';
import { PAGES } from 'constants/lang/services/services/pages';
import React from 'react';
import CountriesViewModel from './Countries.viewModel';

const Countries = () => {
	const { loading, error, displayMessage, displayLoading, searchResults, getData, titles } =
		CountriesViewModel();
	return (
		<>
			{!loading ? (
				<div className="tab-container">
					{searchResults.length > 0 && (
						<DynamicTable
							titles={titles}
							data={searchResults}
							getData={getData}
							pageName={PAGES.Paises}
						/>
					)}
				</div>
			) : error ? (
				displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			) : (
				displayLoading()
			)}
		</>
	);
};

export default Countries;
