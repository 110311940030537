import { Button } from 'common/Button';
import { SelectField } from 'common/SelectField';
import { Input } from 'common/inputs/Input';
import { InputCheckbox } from 'common/inputs/InputCheckbox';
import { FormikProvider } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import paths from 'services/paths';

export const EditFileFormComponent = ({
	formatterText,
	formik,
	idCategory,
	navigate,
	selectorData
}) => {
	const {
		errors,
		handleBlur,
		handleChange,
		handleSubmit,
		isSubmitting,
		isValid,
		setFieldValue,
		touched,
		values
	} = formik;

	return (
		<FormikProvider value={formik}>
			<form className="form-responsive margin-top" noValidate onSubmit={handleSubmit}>
				<Input
					error={errors.nombre}
					labelText={formatterText('p.file.name')}
					name={'nombre'}
					onBlur={handleBlur}
					placeholder={formatterText('input.placeholder.attached.file')}
					onChange={handleChange}
					touched={touched.nombre}
					type={'text'}
					value={values.nombre}
				/>

				<SelectField
					error={errors.extension}
					labelText={formatterText('p.file.type', 'Tipo de Archivo')}
					name="extension"
					onBlur={handleBlur}
					options={selectorData}
					placeholder={formatterText('input.placeholder.select', 'Selecione una opción')}
					touched={touched.extension}
					value={values.extension}
				/>

				<Input
					error={errors.pesoMaximoArchivo}
					labelText={formatterText('p.max.weight')}
					name={'pesoMaximoArchivo'}
					onBlur={handleBlur}
					placeholder={formatterText('input.placeholder.file.weight')}
					setFieldValue={setFieldValue}
					touched={touched.pesoMaximoArchivo}
					type={'text'}
					onChange={handleChange}
					value={values.pesoMaximoArchivo}
				/>
				<Input
					error={errors.cantidadMaxima}
					labelText={formatterText('p.file.quantity')}
					name={'cantidadMaxima'}
					onBlur={handleBlur}
					placeholder={formatterText('p.file.quantity')}
					setFieldValue={setFieldValue}
					touched={touched.cantidadMaxima}
					type={'text'}
					onChange={handleChange}
					value={values.cantidadMaxima}
				/>
				<SelectField
					error={errors.categorias}
					labelText={formatterText('p.categories.assosiated', 'Categorias asociadas')}
					name={'categorias'}
					options={idCategory}
					onBlur={handleBlur}
					value={values.categorias}
					touched={touched.categorias}
					isMulti
				/>
				<InputCheckbox
					activeText={formatterText('p.active', 'Activo')}
					labelText={formatterText('table.title.state')}
					name={'active'}
					value={values.active}
					onChange={handleChange}
					unActiveText={formatterText('p.unActive', 'No activo')}
				/>

				<section className="form-responsive-container-buttons">
					<Button
						type="submit"
						className="btn-primary"
						text={formatterText('btn.save.changes', 'Guardar cambios')}
						disabled={!isValid || isSubmitting}
					/>

					<Button
						className="input-cancel"
						text={formatterText('btn.cancel')}
						onClick={() => navigate(paths.files)}
					/>
				</section>
			</form>
		</FormikProvider>
	);
};
EditFileFormComponent.prototype = {
	formatterText: PropTypes.func,
	formik: PropTypes.any,
	idCategory: PropTypes.array,
	navigate: PropTypes.func,
	selectorData: PropTypes.func
};
