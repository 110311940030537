import React, { useContext, useEffect, useState } from 'react';
// Import Contexts
import { AppContext } from 'context/AppContext';
// Import Hooks
import useLangv2 from 'hooks/useLangv2';
import { useNavigate, useParams } from 'react-router-dom';
// Import Components
import CustomToast from 'components/CustomToast';
import WarningDialog from 'components/tableOptions/WarningDialog';
// Mui Cell row style
import TableRow from '@mui/material/TableRow';
import { MdCheckBox, MdDelete, MdDownload, MdEdit } from 'react-icons/md';
// Icons for cell
import TableCell from '@mui/material/TableCell';
// Import Libs
import Cookie from 'js-cookie';
import { FormattedMessage } from 'react-intl';

import { useSeachContext } from 'context/SearchContext';
import useAxios from 'hooks/useAxios';
import { isNull } from 'lodash';

/**
 *
 * @param {titles}  titles of the table , example: ["Name", "Last Name", "Age"]
 * @param {columns} columns of the table, example: [{name: "John", lastName: "Doe", age: 25}]
 * @param {handleDownloadItem} function to download the item
 * @param {handleDeleteItem} function to delete the item
 * @param {handleEditStateItem} function to edit the state of the item
 * @param {noHandleEditStateItem} boolean to know if the item has the function to edit the state
 * @param {routeToEdit} route to edit the item
 * @param {canDeleted} boolean to know if the item can be deleted
 * @param {canDownload} boolean to know if the item can be downloaded
 * @param {canModify} boolean to know if the item can be modified
 * @param {canSelectEU} boolean to know if the item can be selected
 * @param {canSelectSC} boolean to know if the item can be selected
 * @param {canEditPrecaution} boolean to know if the item can be edited
 * @param {onClose} function to close the modal
 * @param {functions} functions to use in the table
 * @returns  DynamicRow
 *
 */

const DynamicRow = ({
	titles,
	columns,
	data,
	handleDownloadItem,
	handleDeleteItem,
	handleEditStateItem,
	routeToEdit,
	canDeleted,
	canDeletedInvoice,
	canDownload,
	canModify,
	canSelectEU,
	canSelectSC,
	canSelectSSU,
	canSelectSBU,
	canEditPrecaution,
	onClose,
	functions,
	getData
}) => {
	const { setDataTable, searchResults = [], setSearch } = useSeachContext();
	// use Hook of language v2
	const { formatterText, newItemCreated, updatedItem, successRemoveItem, handleRequestError } =
		useLangv2();

	const navigate = useNavigate();
	// Set the data of the externalUserSelected and ClientSelected
	const {
		setExternalUserSelected,
		setClientSelected,
		setBillingUserSelected,
		setUserSelected,
		setObjectPrecaution,
		setOpenModalEditPrecaution,
		setIdBillingService
	} = useContext(AppContext);

	const [dateAux] = useState(columns.fechaModificacion);
	const [loading, setLoading] = useState(false);
	const [client] = useState(columns.objeto);
	// Dialog edit
	const [dialog, setDialog] = useState({
		text: '',
		active: false,
		function: null
	});

	// get id from the url
	const { id } = useParams();
	const { COOKIE_USER, RequestUseCase, endpoints } = useAxios();

	// State when edit the state
	const { OnMessage, CustomComponentToast } = CustomToast();

	useEffect(() => {
		// setStateAux(columns.estado);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchResults]);

	useEffect(() => {
		if (functions) {
			functions.myForm.reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [functions?.currentDataForm]);

	const handleEdit = (id) => {
		localStorage.setItem(
			'dataUpdate',
			JSON.stringify({
				...columns.objeto,
				estado: columns.estado
			})
		);
		navigate(routeToEdit.replace(':id', parseInt(id)));
	};

	const handleChangeInformation = (id) => {
		localStorage.setItem(
			'dataUpdate',
			JSON.stringify({
				...columns.objeto
			})
		);

		if (functions.edit === true) {
			navigate(routeToEdit.replace(':id', parseInt(id)));
		} else {
			functions.setActionToggle(true);
		}

		functions.setActive(columns.estado);
		functions.setCurrentDataForm(columns.objeto);
	};

	const handleDelete = (columns) => {
		try {
			handleDeleteItem(columns)
				.then(() => {
					successRemoveItem();
					setSearch('');
				})
				.catch((err) => {
					handleRequestError(err);
				})
				.finally(() => closeDialog());
		} catch (error) {
			console.error('handleDelete error', error);
			closeDialog();
		}
	};

	// Permite el cambio de estado de un registro
	const handleToggle = async (stateValue) => {
		const estado = stateValue === 1 ? 0 : 1;
		closeDialog();
		let newData = {};
		if (routeToEdit.includes('clientes')) {
			let units = [];
			await RequestUseCase.get(
				endpoints.businessUnit.getUnitBusinessByClient(client.idCliente)
			).then((data) => {
				const arrData = data.map((item) => item.idUnidadNegocio);
				units = arrData.map((itemPut) => {
					return {
						idUnidadNegocio: itemPut.idUnidadNegocio
					};
				});
			});

			newData = {
				clienteDto: {
					...client,
					estado: estado,
					usuarioModificacion: parseInt(Cookie.get('idUsuario'))
				},
				unidadNegocios: units
			};
		} else {
			newData = {
				...columns.objeto,
				estado: estado,
				usuarioModificacion: parseInt(Cookie.get('idUsuario'))
			};
		}
		// execute update function
		handleEditStateItem(newData)
			.then(() => {
				updatedItem();
				// set the values of the state of the item of currentDataRow
				const NEW_ARRAY_TABLE = searchResults.map((item) => {
					if (item.id === columns.id) {
						return { ...item, estado };
					}
					return item;
				});
				setDataTable(NEW_ARRAY_TABLE);
				setSearch('');
				data ? data() : getData();
			})
			.catch((err) => {
				if (err.response?.data?.message) {
					displayToast('warning', formatterText(err.response?.data?.message));
				} else {
					displayToast('error', formatterText('toast.error.general'));
				}
			});
	};

	const closeDialog = () => {
		setLoading(false);
		setDialog({ ...dialog, active: false });
	};

	const displayToast = (type, msg) => {
		OnMessage(type, msg);
	};

	const displayColor = (date) => {
		const date1 = new Date(date);
		const date2 = new Date();
		const timeDiff = date1.getTime() - date2.getTime();
		const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
		if (diffDays <= 0) {
			return '#DD3333';
		} else if (diffDays <= 30) {
			return '#F4A64A';
		} else if (diffDays <= 90) {
			return '#FFC954';
		} else {
			return '#FFC954';
		}
	};

	// Sprint 8 Download Form
	const handleDownload = (id) => {
		handleDownloadItem(id);
	};

	// Sprint 8 Select obj Externaluser
	const handleSelectEU = (obj) => {
		setExternalUserSelected({
			nombre: `${obj.primerNombre} ${obj.primerApellido} - ${obj.telefono} - ${obj.correo}`,
			id: obj.idUsuarioServicio
		});
		//No buscaba cuando se seleccionaba el usuario, quedo un camino sin cubrir
		setUserSelected({
			nombre: `${obj.primerNombre} ${obj.primerApellido} - ${obj.telefono} - ${obj.correo}`,
			id: obj.idUsuarioServicio,
			esVip: obj.usuarioEspecial
		});
		onClose();
	};

	// Sprint 8 Select obj System Client
	const handleSelectSC = (obj) => {
		new Promise((resolve) => {
			setClientSelected({
				nombre: `${obj.nombreRazonSocial} - ${obj.identificacion} - ${obj.telefono} - ${obj.correo}`,
				id: obj.idCliente,
				esVip: obj.clienteEspecial
			});
			resolve();
		}).then(() => {
			onClose();
		});
	};

	const handleSelectSBU = (obj) => {
		new Promise((resolve) => {
			if (id) {
				if (!isNull(obj.idCliente)) {
					const data = {
						idServicio: {
							idServicio: id
						},
						idCliente: {
							idCliente: obj.idCliente
						},
						usuarioCreacion: COOKIE_USER
					};
					RequestUseCase.post(endpoints.services.saveBillingUser, data)
						.then((resp) => {
							setBillingUserSelected({
								nombre: `${obj.nombreRazonSocial} - ${obj.identificacion} - ${obj.telefono} - ${obj.correo}`,
								id: obj.idCliente
							});
							newItemCreated();
							setIdBillingService(resp.idServicioFacturacion);
							resolve();
						})
						.catch(console.log);
				}
			} else {
				setBillingUserSelected({
					nombre: `${obj.nombreRazonSocial} - ${obj.identificacion} - ${obj.telefono} - ${obj.correo}`,
					id: obj.idCliente
				});
				resolve();
			}
		}).then(() => {
			onClose();
		});
	};

	// Sprint 8 Select obj System Users
	const handleSelectSSU = (obj) => {
		new Promise((resolve) => {
			setUserSelected({
				nombre: `${obj.primerNombre} ${obj.primerApellido} - ${obj.telefono} - ${obj.correo}`,
				id: obj.idUsuarioServicio,
				esVip: obj.usuarioEspecial
			});
			resolve();
		}).then(() => {
			onClose();
		});
	};

	const changeState = () => {
		if (columns.estado == 1) {
			setDialog({
				text: `${formatterText('dialog.title.delete.mid.first')} ${columns.nombre} ${formatterText(
					'dialog.title.delete.mid.end.inactive'
				)}`,
				active: true,
				action: 'active',
				funcion: () => handleToggle(columns.estado)
			});
		} else {
			setDialog({
				text: `${formatterText('dialog.title.delete.mid.first')} ${columns.nombre} ${formatterText(
					'dialog.title.delete.mid.end.active'
				)}`,
				active: true,
				action: 'active',
				funcion: () => handleToggle(columns.estado)
			});
		}
	};

	const textActions = () => {
		const text = formatterText('table.actions', 'Acciones');
		return text;
	};

	function determinateState(val) {
		switch (parseInt(val)) {
			case 0:
				return (
					<button
						id="Inactivo"
						className="btn-inactive_state"
						disabled={loading}
						onClick={changeState}
						type="button"
					>
						<FormattedMessage id="btn.inactive" defaultMessage="Inactivo" />
					</button>
				);
			case 1:
				return (
					<button
						className="btn-state"
						id="Activo"
						disabled={loading}
						onClick={changeState}
						type="button"
					>
						<FormattedMessage id="btn.active" defaultMessage="Activo" />
					</button>
				);
			case 2:
				return (
					<button className="btn-disabled_state">
						<FormattedMessage id="btn.disabled" defaultMessage="Inhabilitado" />
					</button>
				);
			default:
				return (
					<h5>
						{' '}
						<FormattedMessage id="btn.error.number" defaultMessage="Número inválido revisar data" />
					</h5>
				);
		}
	}

	return (
		<>
			<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
				{Object.keys(columns).map((column, index) => {
					if (
						column === 'id' ||
						column === 'objeto' ||
						column === 'usuarioCreacion' ||
						column === 'fc' ||
						column === 'uc'
					) {
						return null;
					}
					if (column === 'estado') {
						return (
							<TableCell align="center" key={`state_${index + 1}`}>
								{determinateState(columns[column])}
							</TableCell>
						);
					}
					if (column === 'fechaModificacion') {
						return (
							<TableCell align="left" key={`fechaModificacion_${index + 1}`}>
								{dateAux}
							</TableCell>
						);
					}
					if (column === 'extencionArchivo') {
						return (
							<TableCell align="left" key={`extencionArchivo${index + 1}`}>
								{columns[column]}
							</TableCell>
						);
					}
					if (column === 'pesoMaximoArchivo') {
						return (
							<TableCell align="left" key={`nombre_${index + 1}`}>
								{columns[column]}
							</TableCell>
						);
					}
					if (column === 'vencimientoSoat') {
						return (
							<TableCell align="center" key={`vencimientoSoat_${index + 1}`}>
								<button
									style={{
										backgroundColor: displayColor(columns[column]),
										border: 'none',
										color: 'white',
										borderRadius: '15px',
										fontWeight: '700',
										padding: '0.7rem'
									}}
								>
									{columns[column]}
								</button>
							</TableCell>
						);
					}
					if (column === 'vencimientoTecnicoMecanico') {
						return (
							<TableCell align="center" key={`vencimientoTecnicoMecanico_${index + 1}`}>
								<button
									style={{
										backgroundColor: displayColor(columns[column]),
										border: 'none',
										color: 'white',
										borderRadius: '15px',
										fontWeight: '700',
										padding: '0.7rem'
									}}
								>
									{columns[column]}
								</button>
							</TableCell>
						);
					}
					if (column === 'vencimientoRunt') {
						return (
							<TableCell align="center" key={`vencimientoRunt_${index + 1}`}>
								<button
									style={{
										backgroundColor: displayColor(columns[column]),
										border: 'none',
										color: 'white',
										borderRadius: '15px',
										fontWeight: '700',
										padding: '0.7rem'
									}}
								>
									{columns[column]}
								</button>
							</TableCell>
						);
					}
					if (column === 'color') {
						return (
							<TableCell align="center" key={`color_${index + 1}`}>
								<button
									style={{
										backgroundColor: columns[column],
										border: 'none',
										color: 'white',
										borderRadius: '15px',
										fontWeight: '700',
										padding: '0.7rem'
									}}
								></button>
							</TableCell>
						);
					}
					if (column === 'nombre') {
						return <TableCell key={`name_${index + 1}`}>{columns[column]}</TableCell>;
					}
					if (column === 'descripcion') {
						return <TableCell key={`description_${index + 1}`}>{columns[column]}</TableCell>;
					}
					if (column === 'tipoDocumento') {
						return <TableCell key={`type_doc_${index + 1}`}>{columns[column]}</TableCell>;
					}
					if (column === 'municipio') {
						return <TableCell key={`municipe_${index + 1}`}>{columns[column]}</TableCell>;
					}
					if (column === 'created_at') {
						return <TableCell key={`created_at_${index + 1}`}>{columns[column]}</TableCell>;
					}
					if (column === 'userPhone') {
						return (
							<TableCell align="center" key={`userPhone_${index + 1}`}>
								{columns[column] === 'No asignado' ? (
									columns[column]
								) : (
									<a href={`tel:${columns[column]}`}>{columns[column]}</a>
								)}
							</TableCell>
						);
					}

					return (
						<TableCell key={`${index + 1}`} component="th" scope="row" className="enabled">
							{columns[column]}
						</TableCell>
					);
				})}

				{titles.includes(textActions()) && (
					<TableCell className={loading ? 'disabled' : 'enabled'} align="center">
						{canSelectEU && (
							<MdCheckBox
								size={25}
								color="gray"
								cursor="pointer"
								disabled={loading}
								onClick={() => handleSelectEU(columns.objeto)}
							/>
						)}
						{canSelectSC && (
							<MdCheckBox
								size={25}
								color="gray"
								cursor="pointer"
								disabled={loading}
								onClick={() => handleSelectSC(columns.objeto)}
							/>
						)}
						{canSelectSSU && (
							<MdCheckBox
								size={25}
								color="gray"
								cursor="pointer"
								disabled={loading}
								onClick={() => handleSelectSSU(columns.objeto)}
							/>
						)}
						{canSelectSBU && (
							<MdCheckBox
								size={25}
								color="gray"
								cursor="pointer"
								disabled={loading}
								onClick={() => handleSelectSBU(columns.objeto)}
							/>
						)}
						{canDownload && (
							<MdDownload
								size={25}
								color="gray"
								cursor="pointer"
								disabled={loading}
								onClick={() => handleDownload(columns.id)}
							/>
						)}
						{canModify &&
							(functions ? (
								<MdEdit
									size={25}
									color="gray"
									cursor="pointer"
									disabled={loading}
									onClick={() => handleChangeInformation(columns.id)}
								/>
							) : (
								<MdEdit
									size={25}
									color="gray"
									cursor="pointer"
									disabled={loading}
									onClick={() => handleEdit(columns.id)}
								/>
							))}

						{canDeleted && (
							<MdDelete
								size={25}
								color="gray"
								cursor="pointer"
								disabled={loading}
								onClick={() =>
									setDialog({
										text: `${formatterText('title.service.confirm.delete')} ${columns.nombre}`,
										active: true,
										action: 'delete',
										funcion: () => {
											handleDelete(columns.id);
											closeDialog();
										}
									})
								}
							/>
						)}
						{canDeletedInvoice && (
							<MdDelete
								size={25}
								color="gray"
								cursor="pointer"
								disabled={loading}
								onClick={() =>
									setDialog({
										text: `Vas a eliminar ${columns.numeroFactura}`,
										active: true,
										action: 'delete',
										funcion: () => {
											closeDialog();
											console.log('row', columns);
											handleDeleteItem(columns.id);
										}
									})
								}
							/>
						)}

						{canEditPrecaution && (
							<MdEdit
								size={25}
								color="gray"
								cursor="pointer"
								disabled={loading}
								onClick={() => {
									setOpenModalEditPrecaution(true);
									setObjectPrecaution(columns.objeto);
								}}
							/>
						)}
					</TableCell>
				)}
				<WarningDialog dialog={dialog} setDialog={setDialog} />
			</TableRow>
			<CustomComponentToast />
		</>
	);
};

export default DynamicRow;
