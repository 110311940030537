import { deepMatch, deepMatchNoRecursive } from 'common/utils/deepMatch';
import { isEmpty } from 'lodash';

export const searchDataTable = ({
	data = [],
	query = '',
	filterKeys = [/^activo$/i, /^inactivo$/i, /^inhabilitado$/i],
	isRecursive = true
} = {}) => {
	if (isEmpty(query)) return data;

	const getEstado = (estado = 0) => {
		switch (estado) {
			case 0:
				return 'inactivo';
			case 1:
				return 'activo';
			case 2:
				return 'inhabilitado';
			default:
				return 'inactivo';
		}
	};

	const evaluate = (item) => {
		const state = getEstado(item.estado);

		const withState = filterKeys.some(
			(condition) => condition.test(state) && condition.test(query)
		);

		const stateUpdated = withState ? state : undefined;

		const itemUpdated = {
			...item,
			estado: stateUpdated,
			id: undefined,
			objeto: undefined
		};

		return isRecursive ? deepMatch(itemUpdated, query) : deepMatchNoRecursive(itemUpdated, query);
	};

	return data.filter(evaluate);
};
