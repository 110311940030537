import React from 'react';
// Import libs
import { FormattedMessage } from 'react-intl';
// Import Components
import DynamicTable from 'common/DynamicTable/DynamicHead';
// Import services
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';
// Import Paths
import paths from 'services/paths';
import { ProductCategoryTableProps } from './ProductCategoryTable.props';
import ProductCategoryTableViewModel from './ProductCategoryTable.viewModel';

const ProductCategoryTable = ({ permisos }) => {
	const {
		displayLoading,
		displayMessage,
		error,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		loading,
		titles
	} = ProductCategoryTableViewModel();

	return (
		<>
			{!loading ? (
				<FormattedMessage
					id="table.name.search.productCategory"
					defaultMessage="Categoría de producto"
				>
					{() => (
						<DynamicTable
							titles={titles}
							pageName={PAGE_NAMES.CategoríasProductos}
							getData={getDataTable}
							handleDeleteItem={handleDeleteItem}
							handleEditStateItem={handleEditStateItem}
							routeToEdit={paths.updateProductCategory}
							canDeleted={permisos.eliminar}
							canModify={permisos.editar}
						/>
					)}
				</FormattedMessage>
			) : error ? (
				displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			) : (
				displayLoading()
			)}
		</>
	);
};
ProductCategoryTable.prototype = ProductCategoryTableProps;
export default ProductCategoryTable;
