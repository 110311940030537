import React from 'react';
import { SearchWrapper } from 'context/SearchContext';
import UpdateActivityComponent from './UpdateActivity.component';

const UpdateActivity = () => {
	return (
		<SearchWrapper>
			<UpdateActivityComponent />
		</SearchWrapper>
	);
};

export default UpdateActivity;
