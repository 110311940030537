import useRedirect from 'hooks/useRedirect';
import React, { useContext, useEffect } from 'react';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import './Routes.css';
import NotFound from 'screens/NotFound/NotFound';
import Header from 'components/layout/Header';
import paths from 'services/paths';
import { AppContext } from 'context/AppContext';
import { useAuth } from 'hooks/useAuth';
import { LoadMap } from 'screens/services/ExtraTabs/LoadMap';
import { ROUTER_LIST } from './RoutesList';

let valueControl = true;

export default function Mainroutes() {
	const { userInfo } = useContext(AppContext);

	const [userDataValue, setUserData] = userInfo;
	const auth = useAuth();
	useEffect(() => {
		setUserData(JSON.parse(localStorage.getItem('userData')));
		// console.log("delete when the data is correct: ", userDataValue);
	}, []);

	valueControl = useRedirect(auth);

	const ProtectedRoutes = (ScreenProtected) => {
		if (!auth.userAuth || auth.userAuth === 'false') {
			return <Navigate to={paths.login} replace />;
		} else {
			if (!valueControl) {
				return <ScreenProtected />;
			}
		}
	};

	return (
		<HashRouter>
			<Header>
				<Routes>
					{ROUTER_LIST.map((route, index) => {
						return (
							<Route
								key={index}
								path={route.path}
								element={route.private ? ProtectedRoutes(route.element) : <route.element />}
							/>
						);
					})}
					{/* Example screen */}
					<Route path={paths.reviewMap} element={<LoadMap />} />
					{/* Redirect a página 404 */}
					<Route path="*" element={<NotFound />} />
				</Routes>
			</Header>
		</HashRouter>
	);
}
