import React from 'react';
import { SearchWrapper } from 'context/SearchContext';
import UpdateServiceCategoryComponent from './UpdateServiceCategory.component';

export default function UpdateServiceCategory() {
	return (
		<SearchWrapper>
			<UpdateServiceCategoryComponent />
		</SearchWrapper>
	);
}
