import HandleOnError from './HandleOnError';

/**
 * Veriica si el campo nombre de un modulo
 * ya existe
 * @param {string} name
 * @param {string} msg
 * @param {Function} callback -- peticion getALL
 * @returns boolean
 */
const validateDuplicateName = (name, msg, callback) => {
	let validate = [];
	let res = true;
	callback()
		.then((data) => {
			validate = data.filter((elem) => elem.nombre.toLowerCase() === name?.toLowerCase());
			if (validate.length > 0) {
				HandleOnError(msg);
			} else {
				res = !res;
			}
		})
		.catch((error) => {
			HandleOnError(error);
		});
	return res;
};

/**
 * Veriica si el campo codigo de un modulo
 * ya existe
 * @param {string} code
 * @param {string} msg
 * @param {Function} callback -- peticion getALL
 * @param {boolean} isUpdate true si es para actualizar registros
 * @param {string} currentCode
 * @returns boolean
 */
const validateDuplicateCode = (code, msg, callback, isUpdate = false, currentCode = '') => {
	let validate = [];
	let res = true;
	callback()
		.then((data) => {
			if (isUpdate) {
				const newCode = code.toLowerCase().trim();
				validate = data.filter(
					(elem) =>
						elem.codigo.toLowerCase().trim() === newCode &&
						elem.codigo.toLowerCase().trim() !== currentCode.toLocaleLowerCase().trim()
				);
			} else {
				validate = data.filter(
					(elem) => elem.codigo.toLowerCase().trim() === code.toLowerCase().trim()
				);
			}
			if (validate.length > 0) {
				HandleOnError(msg);
			} else {
				res = !res;
			}
		})
		.catch((error) => {
			HandleOnError(error);
		});
	return res;
};

/**
 * Veriica si el campo codigo de un modulo
 * ya existe
 * @param {string} code
 * @param {string} msg
 * @param {Function} callback -- peticion getALL
 * @param {boolean} isUpdate true si es para actualizar registros
 * @param {string} currentCode
 * @returns boolean
 */
const validateDuplicateCodeAsync = async (
	code,
	msg,
	callback,
	isUpdate = false,
	currentCode = ''
) => {
	let validate = [];
	let res = true;
	try {
		const data = await callback;
		if (isUpdate) {
			const newCode = code.toLowerCase().trim();
			validate = data.filter(
				(elem) =>
					elem.codigo.toLowerCase().trim() === newCode &&
					elem.codigo.toLowerCase().trim() !== currentCode.toLocaleLowerCase().trim()
			);
		} else {
			validate = data.filter(
				(elem) => elem.codigo.toLowerCase().trim() === code.toLowerCase().trim()
			);
		}
		if (validate.length > 0) {
			HandleOnError(msg);
		} else {
			res = !res;
		}
	} catch (error) {
		HandleOnError(error);
	}
	return res;
};

/**
 * Verifica si el codigo de sector ya existe
 * @param {*} code codigo
 * @param {*} msg mensaje que sera mostrado en caso de error
 * @param {*} callback peticion GETALL
 * @returns boolean
 */
const validateDuplicateSectorCode = async (code, msg, callback, isUpdate = false, id = null) => {
	let validate = null;
	let res = true;
	await callback()
		.then((data) => {
			if (isUpdate) {
				validate = Object.values(data).some(
					(value) =>
						value.codigoSector.toLowerCase().trim() === code.toLowerCase().trim() &&
						value.idSector !== id
				);
			} else {
				validate = data.some(
					(elem) => elem.codigoSector.toLowerCase().trim() === code.toLowerCase().trim()
				);
			}

			if (validate) {
				HandleOnError(msg);
			} else {
				res = !res;
			}
		})
		.catch((error) => {
			HandleOnError(error);
		});
	return res;
};

/**
 * Verifica si el sector ya existe
 * @param {*} form formulario
 * @param {*} msg mensaje que sera mostrado en caso de error
 * @param {*} callback peticion GETALL
 * @returns boolean
 */
const validateDuplicateSector = async (form, msg, callback, isUpdate = false) => {
	let validate = [];
	let res = true;
	await callback()
		.then((data) => {
			if (isUpdate) {
				validate = Object.values(data).some(
					(value) =>
						value.idMunicipio.idMunicipio === form.idMunicipio.idMunicipio &&
						value.nombre.toLowerCase().trim() === form.nombre.toLowerCase().trim() &&
						value.codigoSector === form.codigoSector
				);
			} else {
				validate = data.filter((elem) => {
					return (
						elem.idMunicipio.idMunicipio === form.idMunicipio.idMunicipio &&
						elem.nombre.toLowerCase().trim() === form.nombre.toLowerCase().trim() &&
						elem.codigoSector === form.codigoSector
					);
				});
			}

			if (validate.length > 0) {
				HandleOnError(msg);
			} else {
				res = !res;
			}
		})
		.catch((error) => {
			HandleOnError(error);
		});
	return res;
};

/**
 * Verifica que la identificacion no este asignada como tecnico
 * @param {number} identificacion
 * @param {Function} callback
 */
const validaetDuplicateTechnical = (identificacion, callback) => {
	let validate = [];
	let res = true;
	callback()
		.then((data) => {
			validate = data.filter((elem) => {
				if (elem.idEmpleado) {
					return elem.idEmpleado.identificacion === identificacion;
				} else {
					return elem.idTercero.identificacion === identificacion;
				}
			});

			if (validate.length > 0) {
				HandleOnError('El empleado/tercero ya registra como técnico');
			} else {
				res = !res;
			}
		})
		.catch((error) => HandleOnError(error));
	return res;
};

export {
	validaetDuplicateTechnical,
	validateDuplicateCode,
	validateDuplicateCodeAsync,
	validateDuplicateName,
	validateDuplicateSector,
	validateDuplicateSectorCode
};
