import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';

// Import Libs
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

// Import Components
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';

// Import Hooks
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';

// Import Utils
import HandleInput from 'common/validators/HandleInput';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';

// Import Paths
import paths from 'services/paths';

// Import Models
import Market from 'models/Market';

// Import Constants
import { CODEREGEX, PREFIXREGEX, SPACING, TEXTREGEX, TEXTREGEXMIN } from 'common/validators/Regex';

export default function UpdateMarketViewModel() {
	const { endpoints, RequestUseCase } = useAxios();
	const navigate = useNavigate();
	const [formData, setFormData] = useState(new Market());
	const [selectedSearchCountries, setSearchSelectedCountries] = useState([]);
	const [selectValues, setSelectValues] = useState([]);
	const { loading, toggleLoading } = useGetData();
	const [active, setActive] = useState(true);
	const [selectedMarketAssociate, setSelectedMarketAssociate] = useState([]);
	const { formatterText, resourceNotFound, noFilledContent } = useLangv2();
	const { id } = useParams();

	const [error, setError] = useState('');
	const [codeError, setCodeError] = useState('');
	const [prefixError, setPrefixError] = useState('');

	useEffect(() => {
		getDataCountries();
		getMarketsAssociated(id);
		getDataMarket();
	}, []);

	useEffect(() => {
		getDataToUpdate(id);
		getDataCountries();
		getMarketsAssociated(id);
	}, [id]);

	const setMaketForm = (data) => {
		setFormData({
			idMercado: data.idUnidadNegocio,
			nombre: data.nombre.replaceAll(/\s{2,}/gi, ' '),
			descripcion: data.descripcion,
			codigo: data.codigo,
			prefijo: data.prefijo,
			estado: data.estado,
			fechaRegistro: data.fechaRegistro,
			fechaModificacion: data.fechaModificacion,
			usuarioCreacion: data.usuarioCreacion,
			usuarioModificacion: data.usuarioModificacion
		});
	};

	const getDataMarket = () => {
		const market = JSON.parse(localStorage.getItem('dataUpdate'));

		if (market) {
			setMaketForm(market);
			setActive(market.estado);
			getMarketsAssociated(id);
		} else {
			navigate(paths.parameters);
		}
	};

	const getDataToUpdate = (id) => {
		RequestUseCase.get(endpoints.markets.findMercadoByIdMercado(id))
			.then((res) => {
				if (res === null) {
					resourceNotFound();
				} else {
					setMaketForm(res);
					setActive(res.estado);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getDataCountries = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.zones.getAllCountries)
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idPais,
							label: item.nombrePais,
							isFixed: true
						});
					}
				});
				setSearchSelectedCountries(newArray);
				toggleLoading(false);
			})
			.catch((err) => {
				console.log(err);
				toggleLoading(false);
			});
	};

	const getMarketsAssociated = (id) => {
		RequestUseCase.get(endpoints.markets.getMarketAssociateByCountry(id))
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					newArray.push({
						value: item.idPais,
						label: item.nombrePais,
						associateId: item.idMercadoPais,
						isFixed: true
					});
				});
				setSelectValues(newArray);
				setSelectedMarketAssociate(newArray);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handlerTextCode = (e) => {
		const example = e;
		example.target.value = e.target.value.toLowerCase();
		HandleInput(example, TEXTREGEXMIN, formData, setFormData);
	};

	// Update a string to set into the form
	const handleText = (e) => {
		HandleInput(e, SPACING, formData, setFormData, true);
	};

	// This function sets the form value by the onChange of the input
	const handleChange = (e) => {
		switch (e.target.name) {
			case 'prefijo':
				HandleInput(e, PREFIXREGEX, formData, setFormData);
				break;
			case 'codigo':
				HandleInput(e, CODEREGEX, formData, setFormData);
				break;

			default:
				HandleInput(e, TEXTREGEX, formData, setFormData);
				break;
		}
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		if (prefixError !== '' || codeError !== '' || error !== '' || selectValues.length === 0) {
			noFilledContent();
		} else {
			const data = {
				...formData,
				idMercado: id,
				estado: active ? '1' : '0',
				usuarioModificacion: Cookie.get('idUsuario')
			};
			updateMarketData(data);
		}
	};

	const updateMarketData = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve) => {
					// this service create the item
					RequestUseCase.put(endpoints.markets.updateMarket(data?.idMercado), data)
						.then((res) => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.updated.general',
										'El registro se ha actualizado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => deleteAssociateCountryMarket(res.idMercado)
								})
							);
						})
						.catch((err) => {
							if (err.response?.data?.message) {
								HandleOnError(formatterText(err.response?.data?.message));
							} else {
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
						});
				});
			}
		});
	};

	const associateCountryMarket = (idM) => {
		// realizar un if si seleccionaron paises
		if (selectValues.length > 0) {
			const data = {
				mercado: {
					idMercado: idM
				},
				pais: []
			};
			selectValues.map((item) => data.pais.push({ idPais: item.value }));
			selectValues.forEach((item) => {
				data.pais.push({
					idPais: item.value
				});
			});

			RequestUseCase.post(endpoints.markets.addMarketCountry, data)
				.then(() => {
					CustomAlert('confirm_msg', {
						icon: 'success',
						title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
						text: formatterText(
							'alert.message.associations.general',
							'Las asociaciones se crearon correctamente'
						),
						confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
						allowOutsideClick: false,
						executeFunction: () => navigate(paths.parameters)
					});
				})
				.catch((err) => {
					if (err.response?.data?.message) {
						HandleOnError(err.response?.data?.message);
					} else {
						HandleOnError(
							formatterText(
								'snackbar.error.process.failed.general',
								'Error al realizar el proceso. Intentalo en otro momento.'
							)
						);
					}
				});
		} else {
			navigate(paths.parameters);
		}
	};

	const deleteAssociateCountryMarket = (idM) => {
		const newArray = [];
		selectedMarketAssociate.map((item) => newArray.push(item.associateId));
		const newArray2 = [];
		selectValues.map((item) => newArray2.push(item.associateId));
		const newArray3 = newArray.filter((item) => !newArray2.includes(item));
		const promisesArray = newArray3.map((item) =>
			RequestUseCase.delete(endpoints.markets.deleteMarketFromCountry(item))
				.then((res) => {
					console.log(res);
				})
				.catch((err) => {
					console.log(err);
				})
		);
		Promise.all(promisesArray)
			.then(() => associateCountryMarket(idM))
			.catch((err) => {
				console.log(err);
			});
	};

	const handleChangeMulti = (val) => {
		setSelectValues(val);
	};

	const handleErrorName = () => {
		if (formData.nombre.trim() === '') {
			setError(formatterText('p.label.title.name.is.required'));
		} else {
			setError('');
		}
	};

	const handleCodeError = () => {
		if (formData.codigo.trim() === '') {
			setCodeError(formatterText('table.title.code.required'));
		} else {
			setCodeError('');
		}
	};

	const handlePrefixError = () => {
		if (formData.prefijo.trim() === '') {
			setPrefixError(formatterText('p.prefix.is.required'));
		} else {
			setPrefixError('');
		}
	};

	return {
		active,
		codeError,
		error,
		formatterText,
		formData,
		handleChange,
		handleChangeMulti,
		handleCodeError,
		handleErrorName,
		handlePrefixError,
		handlerTextCode,
		handlerTextDescription,
		handleSubmit,
		handleText,
		loading,
		navigate,
		prefixError,
		selectedMarketAssociate,
		selectedSearchCountries,
		selectValues,
		setActive
	};
}
