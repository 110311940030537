import React, { useRef } from 'react';
// Import context
import WarningDialog from 'components/tableOptions/WarningDialog';
// Import Hooks
import useLangv2 from 'hooks/useLangv2';
// Import Components
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// Import Libs
// Import Styles
import 'styles/minimalTable.css';
// Services module
import RowTypeServiceActivity from './serviceModule/RowTypeServiceActivity';
// Technical module
import RowAssociateCoverage from './technicalModule/RowAssociateCoverage';
import RowClienteSucursal from './technicalModule/RowClientSucursal';
import RowUnityBusiness from './technicalModule/RowUnityBusiness';
// Agenda module
import { useSeachContext } from 'context/SearchContext';
import RowBusinessUnit from './agenda/RowBusinessUnit';
import RowTechnicalAssigned from './agenda/RowTechnicalAssigned';
import RowTechnicalNoAssign from './agenda/RowTechnicalNoAssign';
import RowTimeZoneSchedule from './agenda/RowTimeZoneSchedule';

export default function MultiTableMinimal({
	titles,
	data,
	type,
	max,
	canSearch,
	handleDelete,
	dialog,
	setDialog,
	closeDialog,
	getAllT
}) {
	const {
		searchUnitBussines = [],
		searchCoverage = [],
		searchResults = [],
		searchSucursal = [],
		activitiesResults,
		handleSearch,
		handleSearchUnityBussines,
		handleSearchClientSucursal,
		handleSearchModalFranjas,
		handleSearchCoverage,
		dataTableTechnical,
		dataTableTechToAssign,
		dataTableBusinessUnit,
		timeZoneSelected
	} = useSeachContext();
	// use Hook of language v2
	const { formatterText } = useLangv2();
	// Choose a row
	const ChooseRow = (item, i) => {
		switch (type) {
			case 'associateBussinesTech':
				return (
					<RowUnityBusiness
						item={item}
						key={i}
						setDialog={setDialog}
						handleDelete={handleDelete}
						closeDialog={closeDialog}
						type={type}
					/>
				);
			case 'clientSucursal':
				return (
					<RowClienteSucursal
						item={item}
						key={i}
						handleDelete={handleDelete}
						setDialog={setDialog}
						closeDialog={closeDialog}
					/>
				);
			case 'associateCoverage':
				return (
					<RowAssociateCoverage
						item={item}
						key={i}
						handleDelete={handleDelete}
						setDialog={setDialog}
						closeDialog={closeDialog}
					/>
				);

			case 'technicalNoAssign': {
				const active = item.status === 1 ? item : {};
				return <RowTechnicalNoAssign item={active} key={i} type={type} />;
			}
			case 'technicalAssign':
				return (
					<RowTechnicalAssigned
						item={item}
						key={i}
						type={type}
						handleDelete={handleDelete}
						getAllT={getAllT}
					/>
				);
			case 'typeServiceActivity':
				return (
					<RowTypeServiceActivity
						item={item}
						key={i}
						handleDelete={handleDelete}
						setDialog={setDialog}
						closeDialog={closeDialog}
					/>
				);
			case 'franjasHorariaSchedule':
				return <RowTimeZoneSchedule item={item} key={i} />;

			case 'buisnessUnit':
				return <RowBusinessUnit item={item} key={i} data={data} />;

			default:
				console.log('No se encontro el tipo de tabla');
				break;
		}
	};
	const searchRef = useRef(null);

	const changeHandles = {
		associateBussinesTech: handleSearchUnityBussines,
		clientSucursal: handleSearchClientSucursal,
		associateCoverage: handleSearchCoverage,
		technicalNoAssign: handleSearchModalFranjas
	};

	const renderBody = (type) => {
		const typeToDataMap = {
			typeServiceActivity: activitiesResults,
			associateBussinesTech: searchUnitBussines,
			clientSucursal: searchSucursal,
			associateCoverage: searchCoverage,
			technicalAssign: dataTableTechnical,
			technicalNoAssign: dataTableTechToAssign,
			franjasHorariaSchedule: timeZoneSelected,
			buisnessUnit: dataTableBusinessUnit
		};

		const dataToRender = typeToDataMap[type] || searchResults;

		return dataToRender.map((item, i) => ChooseRow(item, i));
	};

	return (
		<>
			<div className={max ? 'table-minimal-container width-100' : 'table-minimal-container'}>
				{canSearch && (
					<div style={{ width: '50%' }} className="wrap">
						<div className="search">
							<input
								type="text"
								className="searchTerm spacing-b1"
								placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
								ref={searchRef}
								onChange={changeHandles[type] ? changeHandles[type] : handleSearch}
							/>
							<button
								type="button"
								onClick={(e) =>
									type === 'associateBussinesTech'
										? handleSearchUnityBussines(e)
										: type === 'clientSucursal'
										? handleSearchClientSucursal(e)
										: type === 'associateCoverage'
										? handleSearchCoverage
										: handleSearch(e)
								}
								className="searchButton"
							>
								<img
									src={require('assets/search.png')}
									style={{ height: '20px' }}
									alt="search logo"
								/>
							</button>
						</div>
					</div>
				)}
				<TableContainer component={Paper}>
					<Table className="table-minimal">
						<TableHead>
							<TableRow className="infoo">
								{titles.map((title, index) => (
									<th scope="col" key={index}>
										{title}
									</th>
								))}
							</TableRow>
						</TableHead>
						<TableBody>{renderBody(type)}</TableBody>
					</Table>
				</TableContainer>
			</div>
			<WarningDialog dialog={dialog} setDialog={setDialog} />
		</>
	);
}
