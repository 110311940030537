import React, { useEffect, useState } from 'react';

// Import Libs
import { FormattedMessage } from 'react-intl';

// Import Components
import { MODULES_NAME } from 'common/validators/PermissionsConstants';
import TechnicalsTable from '../technicals/TechnicalsTable';
import VehiclesTable from '../vehicles/VehiclesTable';

// Import Contexts
import { useSeachContext } from 'context/SearchContext';
import { usePermissionContext } from 'context/PermissionContext';

// Import Hooks
import useLangv2 from 'hooks/useLangv2';

// Import Paths
import paths from 'services/paths';

export default function TechnicalTableViewModel() {
	const { formatterText } = useLangv2();
	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();

	useEffect(() => {
		permissionsAccessGaranted();
	}, [permissions]);

	useEffect(() => {
		setSelectedTab(tabs[parseInt(localStorage.getItem('indexTab')) || 0]);
	}, [permittedActions]);

	const permissionsAccessGaranted = () => {
		permissionsAccess(MODULES_NAME.technicians.principal);
	};

	const tabs = [
		{
			id: paths.technical,
			nombre: formatterText('header.title.technical.principal', 'Técnicos'),
			route: paths.createTechnical,
			nombreLink: (
				<FormattedMessage id="header.title.technical.create" defaultMessage="Crear Técnico" />
			),
			componente: <TechnicalsTable />,
			exportExcel: permittedActions.exportar,
			idPermiso: MODULES_NAME.technicians.principal
		},
		{
			id: paths.vehicleTechnical,
			nombre: formatterText('table.name.search.vehicles', 'Vehículos'),
			route: paths.vehicleTechnical,
			componente: <VehiclesTable props={permittedActions} />,
			exportExcel: permittedActions.exportar,
			idPermiso: MODULES_NAME.technicians.vehicules
		}
	];

	const { setSearch, searchResults = [] } = useSeachContext();

	// no pasa nada diferente cuando cambio el 0 a 1
	const [selectedTab, setSelectedTab] = useState(tabs[0]);

	return {
		formatterText,
		permissionsAccess,
		permittedActions,
		searchResults,
		selectedTab,
		setSearch,
		setSelectedTab,
		tabs
	};
}
