import PropTypes from 'prop-types';

export const DynamicHeadNotificationsProps = {
	titles: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	totalData: PropTypes.number.isRequired,
	setRowsPerPage: PropTypes.func.isRequired,
	setPage: PropTypes.func.isRequired,
	page: PropTypes.number,
	rowsPerPage: PropTypes.number,
	updated: PropTypes.bool,
	pageName: PropTypes.string
};
