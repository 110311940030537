import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import React from 'react';

function UserPaginateActions(props) {
	const theme = useTheme();

	const {
		count,
		page,
		RowcategoriesProduct,
		RowproductsPage,
		rowtechs,
		rowVehicles,
		countriesPage,
		departmentsPages,
		municipalitisPages,
		rowsPerPage,
		rowMarket,
		rowParams,
		rowBilling,
		rowClient,
		rowProvider,
		rowBussinesUnit,
		rowForms,
		rowPrices,
		rowActivities,
		rowStages,
		rowCategories,
		onPageChange
	} = props;
	const rows =
		rowsPerPage ||
		RowcategoriesProduct ||
		RowproductsPage ||
		rowtechs ||
		rowVehicles ||
		rowForms ||
		rowPrices ||
		rowActivities ||
		rowStages ||
		rowCategories ||
		rowMarket ||
		rowParams ||
		rowBilling ||
		rowClient ||
		rowProvider ||
		rowBussinesUnit ||
		countriesPage ||
		departmentsPages ||
		municipalitisPages;

	const pages = page;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, pages - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, pages + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rows) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={pages === 0}
				aria-label="Primera página"
			>
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={pages === 0}
				aria-label="Página anterior "
			>
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={pages >= Math.ceil(count / rows) - 1}
				aria-label="Siguiente página"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={pages >= Math.ceil(count / rows) - 1}
				aria-label="Última página"
			>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

export default UserPaginateActions;
