import React from 'react';
import { SearchWrapper } from 'context/SearchContext';
import UpdateListPriceComponent from './UpdateListPrice.component';

export default function UpdateListPrice() {
	return (
		<SearchWrapper>
			<UpdateListPriceComponent />
		</SearchWrapper>
	);
}
