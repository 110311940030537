import React from 'react';
// Import Hooks
import { useField } from '@formiz/core';
// Import Components
import currencyFormat from 'common/utils/currencyFormat';
import rawCurrency from 'common/utils/rawCurrency';
import {
	ADDRESSREGEX,
	IDREGEX,
	NUMBERREGEX,
	PRICEREGEX,
	TEXTONLYONESPACEREGEX,
	TEXTREGEX
} from 'common/validators/Regex';

export const MyField = (props) => {
	const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(props);
	const { type, required, placeholder, name, validateInput } = props;
	const [isTouched, setIsTouched] = React.useState(false);
	const showError = !isValid && (isTouched || isSubmitted);

	const validateRegex = (regex, value) => {
		if (regex === TEXTONLYONESPACEREGEX) {
			if (value.split(' ').length > 2) {
				return;
			}
		}
		if (value.match(regex)) {
			setValue(value);
		}
	};

	const reformatEmail = (value) => {
		// regex for a lleter, number, dot, underscore and dash
		//regex for only one @
		const onlyOneAtRegex = /@{1}/g;
		//regex for only one dot

		const beforeAt = value.split('@')[0];
		const at = value.split('@')[1];
		//if value contain @ and is only one @ setValue
		if (value.contains('@') && value.match(onlyOneAtRegex)) {
			setValue(value);
			return;
		}

		// make a email address with only one @
		if (at) {
			const afterAt = at.split('.')[0];
			const dot = at.split('.')[1];

			console.log('afterAt', afterAt);
			console.log('dot', dot);

			if (dot) {
				const afterDot = dot.split('.')[0];
				const afterAfterDot = dot.split('.')[1];

				console.log('afterDot', afterDot);
				console.log('afterAfterDot', afterAfterDot);

				if (afterAfterDot) {
					const newEmail = `${beforeAt}@${afterAt}.${afterDot}.${afterAfterDot}`;
					console.log('newEmail', newEmail);
					setValue(newEmail);
				} else {
					const newEmail = `${beforeAt}@${afterAt}.${afterDot}`;
					console.log('newEmail', newEmail);
					setValue(newEmail);
				}
			} else {
				const newEmail = `${beforeAt}@${afterAt}`;
				console.log('newEmail', newEmail);
				setValue(newEmail);
			}
		} else {
			const newEmail = `${beforeAt}`;
			console.log('newEmail', newEmail);
			setValue(newEmail);
		}
	};

	const handleChange = (e) => {
		const { value = '' } = e.target;

		const val = value.replaceAll(/\s{2,}/gi, ' ');

		switch (validateInput) {
			case 'number':
				validateRegex(NUMBERREGEX, val);
				break;
			case 'text':
				validateRegex(TEXTREGEX, val);
				break;
			case 'address':
				validateRegex(ADDRESSREGEX, val);
				break;
			case 'price':
				validateRegex(PRICEREGEX, val);
				break;
			case 'identification':
				validateRegex(IDREGEX, val);
				break;
			case 'email':
				reformatEmail(val);
				break;
			case 'text-no-digits-one-space':
				validateRegex(TEXTONLYONESPACEREGEX, val);
				break;
			default:
				setValue(val);
				break;
		}
	};

	return (
		<label style={{ display: 'flex', flexFlow: 'column', alignItems: 'start' }}>
			<input
				id={id}
				type={type || 'text'}
				value={value || ''}
				name={name}
				onChange={handleChange}
				onBlur={() => {
					setIsTouched(true);
					if (validateInput === 'price') {
						setValue(currencyFormat(value));
					}
				}}
				onFocus={() => {
					if (validateInput === 'price') {
						if (value) {
							setValue(rawCurrency(value));
						}
					}
				}}
				placeholder={placeholder}
				className="input-primary"
				aria-invalid={showError}
				aria-required={!!required}
				aria-describedby={showError ? `${id}-error` : null}
				min={props.min}
				max={props.max}
				onKeyDown={props.onkeydown}
			/>
			{showError && (
				<span id={`${id}-error`} className="error-msg">
					{errorMessage}
				</span>
			)}
		</label>
	);
};
