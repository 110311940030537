import useAxios from 'hooks/useAxios';
import { useContext, useEffect, useState } from 'react';
// Import Contexts
import { useSeachContext } from 'context/SearchContext';
import { ContextUse } from '../ContextAlert';
// Import Hooks
import useLangv2 from 'hooks/useLangv2';
// Import Components
import NotificationPlus from 'models/NotificationPlus';

export default function ContainerViewModel(tabs) {
	const { RequestUseCase, endpoints } = useAxios();

	// Call context TableMinimalContext
	// use Hook of language v2
	const { formatterText } = useLangv2();
	// Select tab
	const { _SetDispatch, DeterminateTypeUser, getRecordsByQuery } = useContext(ContextUse);
	const [selectedTab, setSelectedTab] = useState(
		tabs[parseInt(JSON.parse(localStorage.getItem('indexNotification'))) || 0]
	);
	// Index Tab
	const [indexTabServices] = useState(
		parseInt(JSON.parse(localStorage.getItem('indexNotification')) || 0)
	);
	const [show, setShow] = useState(false);
	// data of the table
	const [selectedSearch, setSearchSelected] = useState([
		{
			clientsAssociate: [],
			municipalities: [],
			notifications: []
		}
	]);
	const [formData, setFormData] = useState(new NotificationPlus());

	const [val] = useState({
		id: null,
		label: ''
	});

	const [dates, setDates] = useState({
		rangoFecha: null
	});

	const [selectValues, setSelectValues] = useState({
		nombre_cliente: {
			id: null,
			label: ''
		},

		tipo_notificacion: {
			id: null,
			label: ''
		},

		municipio: {
			id: null,
			label: ''
		},
		fecha: {
			id: null,
			label: ''
		}
	});
	const { search, setSearch } = useSeachContext();
	const handleChange = (e) => {
		const value = e.target.value;
		if (value.match('^[a-zA-Z0-9 ñáéíóúÁÉÍÓÚÑ]*$') != null) {
			setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
		}
	};
	const handleFechaYHora = (date) => {
		setDates((prevState) => ({
			...prevState,
			...date
		}));
	};
	// get all clients to the select
	const getAllClientsToAssociate = () => {
		RequestUseCase.get(endpoints.clients.getAllClients).then((data) => {
			// create new array
			const newArray = [
				{
					id: 0,
					label: 'Ninguno'
				}
			];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						id: item.idCliente,
						label: `${item.nombreRazonSocial}`
					});
				}
			});
			// set the values of the select
			setSearchSelected((prev) => ({
				...prev,
				clientsAssociate: newArray
			}));
		});
	};
	// get all municipalities to the select
	const getAllCity = () => {
		RequestUseCase.get(endpoints.zones.getAllMunicipalities).then((data) => {
			// create new array
			const newArray = [
				{
					id: 0,
					label: 'Ninguno'
				}
			];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						id: item.idMunicipio,
						label: `${item.nombre}`
					});
				}
			});
			// set the values of the select
			setSearchSelected((prev) => ({
				...prev,
				municipalities: newArray
			}));
		});
	};

	const getAllTypeNotification = () => {
		RequestUseCase.get(endpoints.notificationsTable.getAllTypeNotification)
			.then((data) => {
				// create new array
				const newArray = [
					{
						id: null,
						label: 'Ninguno'
					}
				];
				// iterate response and get only the values that are active
				data.forEach((item) => {
					newArray.push({
						id: item.idTipoNotificacion,
						label: `${item.tipo}`
					});
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					notifications: newArray
				}));
			})
			.catch((error) => {
				setSearchSelected((prev) => ({
					...prev,
					notifications: []
				}));
			});
	};

	const searchAdvance = (e) => {
		e.preventDefault();
		const DATA = {
			idAgendaNotificacion: null,
			ticket: formData.ticket === '' ? null : formData.ticket,
			expediente: formData.expediente === '' ? null : formData.expediente,
			idCliente:
				selectValues?.nombre_cliente?.id !== null ? selectValues?.nombre_cliente?.label : null,
			idTipoNotificacion:
				selectValues?.tipo_notificacion?.id !== null ? selectValues?.tipo_notificacion?.id : null,
			idMunicipio: selectValues?.municipio?.id !== null ? selectValues?.municipio?.label : null,
			fechaNotifiacion: dates?.fecha_hora !== null ? dates?.fecha_hora : null
		};
		getRecordsByQuery(DATA);
	};

	const handlerClear = (e) => {
		e.preventDefault();
		DeterminateTypeUser();
		setSelectValues({
			nombre_cliente: null,
			tipo_notificacion: null,
			municipio: null,
			fecha: null
		});
		setFormData(new NotificationPlus());
	};

	const refreshTables = (e) => {
		e.preventDefault();
		DeterminateTypeUser();
	};

	useEffect(() => {
		_SetDispatch({ type: 'TEXT_TO_FILTER', payload: search });
	}, [search]);
	useEffect(() => {
		setSearch('');
	}, [selectedTab]);

	useEffect(() => {
		getAllClientsToAssociate();
		getAllCity();
		getAllTypeNotification();
	}, []);
	return {
		_SetDispatch,
		setSelectedTab,
		indexTabServices,
		show,
		setShow,
		selectedSearch,
		formData,
		val,
		selectValues,
		setSelectValues,
		handlerClear,
		handleChange,
		handleFechaYHora,
		refreshTables,
		searchAdvance,
		formatterText
	};
}
