export const SERVICE_STATUS = {
	COORDINACION: 1,
	ASIGNADO: 2,
	EN_SITIO: 4,
	DESPLAZAMIENTO: 3,
	VISITA_FALLIDA: 5,
	EN_CURSO: 6,
	FINALIZADO: 8,
	FINALIZADO_TECNICO: 9,
	ANULADO: 7
};
