import React from 'react';
// Import Contexts
// Import Hooks
// Import Components
import DynamicTable from 'components/zones/DynamicTable';
// Import Libs
// Import Services
import { PAGES } from 'constants/lang/services/services/pages';
import DepartmentsViewModel from './Departments.viewModel';

const Departments = () => {
	const { loading, error, displayMessage, displayLoading, searchResults, getData, titles } =
		DepartmentsViewModel();
	return (
		<>
			{!loading ? (
				<div className="tab-container">
					{!loading && (
						<DynamicTable
							titles={titles}
							data={searchResults}
							getData={getData}
							pageName={PAGES.Departamentos}
						/>
					)}
				</div>
			) : error ? (
				displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			) : (
				displayLoading()
			)}
		</>
	);
};

export default Departments;
