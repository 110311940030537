import React, { useContext } from 'react';
// Import Contexts
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import  Hooks
import useAxios from 'hooks/useAxios';
import { useParams } from 'react-router-dom';
// Import Libs
import PropTypes from 'prop-types';
import { MdDelete, MdEdit } from 'react-icons/md';
// Import Services
import HandleOnError from 'common/validators/HandleOnError';
import { useSeachContext } from 'context/SearchContext';
import useLangv2 from 'hooks/useLangv2';
import endpoints from 'services/api';

const TableAdviser = ({
	data,
	setDialog,
	loading,
	closeDialog,
	handleOpen,
	getAsesors,
	getAsesorsMap
}) => {
	const { searchResults = [], setDataTable } = useSeachContext();

	const { setModalData, setAuxId, setOtherCurrentDataTable } = useContext(TableMinimalContext);

	const { successRemoveItem, formatterText } = useLangv2();

	// get id from url
	const { id } = useParams();

	const { COOKIE_USER, RequestUseCase } = useAxios();
	// DELETE a sub product category
	const handleDelete = (column, index) => {
		console.log('data: ', data);
		if (column.idAsesor !== undefined) {
			RequestUseCase.delete(endpoints.advisers.deleteAdviser(column.idAsesor)).then(() => {
				setAuxId(null);
				successRemoveItem();
				getAsesors(id).then((resp) => {
					getAsesorsMap(resp);
				});
				closeDialog();
			});
		} else {
			// filter in the array data the index selected and slice it
			setOtherCurrentDataTable(
				data.filter((_, i) => {
					return i !== index;
				})
			);
			closeDialog();
		}
	};

	const handleEdit = (row, index) => {
		handleOpen();
		const CONDITION = typeof row.idAsesor === 'number' ? row.idAsesor : null;
		setAuxId(CONDITION);
		setModalData({ ...row, index: index });
	};

	const handleToggle = (rowState, item) => {
		const estado = rowState === 1 ? 0 : 1;
		closeDialog();
		if (typeof item.idAsesor === 'number') {
			const BODY = {
				idAsesor: item.idAsesor,
				idCliente: {
					idCliente: parseInt(id)
				},
				idTipoDocumento: {
					idTipoDocumento: item.idTipoDocumentoAsesor.value
				},
				identificacion: item.identificacion,
				nombres: item.nombreAsesor,
				idGenero: {
					idGenero: item.genero.value
				},
				idCargo: {
					idCargo: item.idCargo.value
				},
				idMunicipio: {
					idMunicipio: item.idMunicipio.value
				},
				telefono: item.telefonoAsesor,
				telefonoAlterno: item.telefonoAlternoAsesor,
				correo: item.emailAsesor,
				correoAlterno: item.correoAlternoAsesor,
				estado,
				fechaCreacion: item.fechaCreacion,
				fechaModificacion: null,
				usuarioCreacion: item.usuarioCreacion,
				usuarioModificacion: COOKIE_USER
			};

			RequestUseCase.put(endpoints.advisers.updateAdviser(BODY.idAsesor, BODY))
				.then(() => {
					setDataTable(
						searchResults.map((et) => {
							if (et.idAsesor === item.idAsesor) {
								return { ...et, estado };
							}
							return et;
						})
					);
					getAsesors(id).then((resp) => {
						getAsesorsMap(resp);
					});
				})
				.catch((err) => {
					if (err.response?.data?.message) {
						HandleOnError(formatterText(err.response?.data?.message));
					} else {
						HandleOnError(
							formatterText(
								'snackbar.error.process.failed.general',
								'Error al realizar el proceso. Intentalo en otro momento.'
							)
						);
					}
				});
		} else {
			// alert("No se puede cambiar el estado de un registro nuevo");
		}
	};

	return data?.map((item, index) => (
		<tr key={index}>
			<td>{item.nombreAsesor}</td>
			<td>
				{typeof item.idTipoDocumentoAsesor === 'string'
					? JSON.parse(item.idTipoDocumentoAsesor).label
					: item.idTipoDocumentoAsesor.label}
			</td>
			<td>{typeof item.genero === 'string' ? JSON.parse(item.genero).label : item.genero.label}</td>
			<td>{item.identificacion}</td>
			<td>
				{typeof item.idCargo === 'string' ? JSON.parse(item.idCargo).label : item.idCargo.label}
			</td>
			<td>{item.emailAsesor}</td>
			<td>{item.correoAlternoAsesor}</td>
			<td>{item.telefonoAsesor}</td>
			<td>{item.telefonoAlternoAsesor}</td>
			<td>
				{item.estado === 1 ? (
					<div
						className="btn-state pointer"
						onClick={() =>
							setDialog({
								text: `${formatterText('dialog.title.delete.mid.first')} ${
									item.nombreAsesor
								} ${formatterText('dialog.title.delete.mid.end.inactive')}`,
								active: true,
								funcion: () => handleToggle(item.estado, item)
							})
						}
					>
						{formatterText('btn.active')}
					</div>
				) : (
					<div
						className="btn-inactive_state pointer"
						onClick={() => {
							setDialog({
								text: `${formatterText('dialog.title.delete.mid.first')} ${
									item.nombre
								} ${formatterText('dialog.title.delete.mid.end.active')}`,
								active: true,
								action: 'active',
								funcion: () => handleToggle(item.estado, item)
							});
						}}
					>
						{formatterText('btn.inactive')}
					</div>
				)}
			</td>
			<td className="minimal-table__actions">
				<MdEdit
					size={25}
					color="gray"
					cursor="pointer"
					disabled={loading}
					onClick={() => handleEdit(item, index)}
				/>
				<MdDelete
					size={25}
					color="gray"
					cursor="pointer"
					onClick={() =>
						setDialog({
							text: `${formatterText('title.service.confirm.delete.row.category')}`,
							active: true,
							action: 'delete',
							funcion: () => handleDelete(item, index)
						})
					}
					disabled={loading}
				/>
			</td>
		</tr>
	));
};

TableAdviser.propTypes = {
	data: PropTypes.array,
	setDialog: PropTypes.func,
	loading: PropTypes.bool,
	closeDialog: PropTypes.func,
	handleOpen: PropTypes.func
};

export default TableAdviser;
