import React from 'react';

// Import Components
import { SendMailForm } from './components';

// Import ViewModel
import SendMailViewModel from './SendMail.viewModel';

// Import Styles
import 'styles/editProfile.css';

// Import PropTypes
import { SendMailProps } from './SendMail.props';

export default function SendMail({ onClose }) {
	const { displayMessage, error, formik, success, formatterText } = SendMailViewModel();

	return (
		<>
			<SendMailForm formik={formik} onClose={onClose} formatterText={formatterText} />
			{success && displayMessage('success', 'Correo enviado.', 'toast.email.success')}
			{error &&
				displayMessage(
					'error',
					'Ha ocurrido un error, intentalo más tarde.',
					'toast.error.general'
				)}
		</>
	);
}

SendMail.propTypes = SendMailProps;
