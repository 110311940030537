import { useFormik } from 'formik';
import { useState } from 'react';
import { getFormikInitialValues, getFormikValidationSchema, parseDataToSubmit } from '../../utils';

export const FormViewMdel = ({ onSubmit, formatterText }) => {
	const [formikInitialValues, setFormikInitialValues] = useState(getFormikInitialValues());
	const formik = useFormik({
		initialValues: formikInitialValues,
		validationSchema: getFormikValidationSchema(formatterText),
		enableReinitialize: false,
		onSubmit
	});
	return {
		formik,
		parseDataToSubmit,
		formikInitialValues,
		setFormikInitialValues
	};
};
