import useAxios from 'hooks/useAxios';
import { useContext, useEffect, useState } from 'react';
// Import Libs
import Cookie from 'js-cookie';
import { useParams } from 'react-router-dom';
// Import Components
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import Hooks
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
//Import Utils
import currencyFormat from 'common/utils/currencyFormat';
// Import de services
import endpoints from 'services/api';
export default function UpdateCostAssociateProductViewModel({ getDataMiniTable, onClose }) {
	const { RequestUseCase } = useAxios();

	const { modalData, setResultsTableSearch, currentDataTable, setCurrentDataTable } =
		useContext(TableMinimalContext);

	const formatCurrencyFromModal = (value) => {
		const parts = value.toString().split('.');
		let formattedValue = `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;

		if (parts.length > 1) {
			formattedValue += `.${parts[1]}`;
		}

		const splitDecimal = formattedValue.split(',');
		const intPart = splitDecimal[0];
		const floatPart = splitDecimal[1];

		if (floatPart) {
			formattedValue = intPart + ',' + floatPart.substring(0, 2);
		}

		return formattedValue;
	};

	const formatCurrencyFromModalToTable = (value) => {
		const parts = value.toString().split('.');
		let formattedValue = `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '')}`;

		if (parts.length > 1) {
			formattedValue += `.${parts[1].slice(0, 2)}`;
		}

		return formattedValue;
	};
	const { toggleLoading } = useGetData();
	const { formatterText } = useLangv2();

	const [selectedSearch, setSearchSelected] = useState([
		{
			proveedor: [],
			unidadMedida: [],
			subCategoriasProducto: [],
			tipoMonedas: []
		}
	]);
	const [costoModal, setCostoModal] = useState('');
	const [ivaModal, setIvaModal] = useState('');
	const [currectCostProduct, setCurrectCostProduct] = useState({
		idPrecioProductoProveedor: '',
		idProveedor: '',
		label_idProveedor: '',
		idUnidadMedida: '',
		label_idUnidadMedida: '',
		idProducto: '',
		costo: '',
		iva: '',
		idMoneda: '',
		label_idMoneda: ''
	});
	// get id from url
	const { id } = useParams();
	const { errorProcess, customSB } = useLangv2();

	useEffect(() => {
		getMonedas();
		getData(id);
	}, []);

	const getData = (id) => {
		RequestUseCase.get(endpoints.costProductProvider.getCostProductProviderByProduct(id))
			.then((data) => {
				const aux = data.map((item) => {
					return {
						idPrecioProductoProveedor: item.idPrecioProductoProveedor,
						idProveedor: {
							label: `${item.idProveedor.nombreRazonSocial} - ${item.idProveedor.identificacion}`,
							value: item.idProveedor.idProveedor
						},
						idUnidadMedida: {
							label: `${item.idUnidadMedida.descripcion} - ${item.idUnidadMedida.abreviatura}`,
							value: item.idUnidadMedida.idUnidadMedida
						},
						costo: currencyFormat(item.costo),
						idMoneda: item.idMoneda
							? {
									label: `${item.idMoneda.nombre} - ${item.idMoneda.codigo}`,
									value: item.idMoneda.idMoneda
							  }
							: null,
						iva: item.iva
					};
				});
				setCurrentDataTable(aux);
				setResultsTableSearch(aux);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const [auxData, setAuxData] = useState({
		idProveedor: 0,
		idUnidadMedida: 0,
		idMoneda: 0
	});

	useEffect(() => {
		setCurrectCostProduct({
			idPrecioProductoProveedor: modalData.idPrecioProductoProveedor,
			idProveedor: modalData?.idProveedor?.value,
			label_idProveedor: modalData.idProveedor.label,
			idUnidadMedida: modalData.idUnidadMedida.value,
			label_idUnidadMedida: modalData.idUnidadMedida.label,
			costo: modalData.costo,
			idProducto: modalData.idProducto ? modalData.idProducto : '',
			iva: modalData.iva,
			idMoneda: modalData.idMoneda?.label
		});
		setAuxData({
			idProveedor: {
				value: modalData.idProveedor.value,
				label: modalData.idProveedor.label
			},
			idUnidadMedida: {
				value: modalData.idUnidadMedida.value,
				label: modalData.idUnidadMedida.label
			},
			idMoneda: {
				value: modalData.idMoneda.value,
				label: modalData.idMoneda.label
			}
		});
		setCostoModal(modalData.costo.replace('$', '').replace(/\./g, ''));
		setIvaModal(modalData.iva.toString());
	}, []);

	const getMonedas = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.listPrices.getAllMonedas)
			.then((res) => {
				const newArray = [];
				res.map((item) => {
					const data = {
						value: item.idMoneda,
						label: `${item.nombre} - ${item.codigo}`,
						isFixed: true
					};
					newArray.push(data);
				});
				setSearchSelected((prev) => ({
					...prev,
					tipoMonedas: newArray
				}));

				// Set idMoneda in currectCostProduct
				setCurrectCostProduct((prev) => ({
					...prev,
					idMoneda: res[0]?.idMoneda || 0, // Set the first idMoneda as default (if exists)
					label_idMoneda: `${res[0]?.nombre} - ${res[0]?.codigo}` || ''
				}));

				toggleLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toggleLoading(false);
			});
	};

	const handleUpdateModalData = (e) => {
		e.preventDefault();
		if (modalData.costo !== '') {
			if (typeof modalData.idPrecioProductoProveedor === 'number') {
				const data = {
					idPrecioProductoProveedor: currectCostProduct.idPrecioProductoProveedor,
					idProveedor: {
						idProveedor: auxData.idProveedor.value
					},
					idProducto: {
						idProducto: id
					},
					idUnidadMedida: {
						idUnidadMedida: auxData.idUnidadMedida.value
					},
					idMoneda: {
						idMoneda: currectCostProduct.idMoneda
					},
					costo: formatCurrencyFromModalToTable(costoModal.replace(',', '.')),
					iva: formatCurrencyFromModalToTable(ivaModal.replace(',', '.')),
					estado: 1,
					fechaCreacion: new Date(),
					fechaModificacion: new Date(),
					usuarioCreacion: 1,
					usuarioModificacion: Cookie.get('idUsuario')
				};
				RequestUseCase.put(endpoints.costProductProvider.updateCostProductProvider, data)
					.then(() => {
						onClose();
						// Actualizar el currentDataTable con los valores actualizados
						setCurrentDataTable((prevDataTable) => {
							const updatedDataTable = prevDataTable.map((item) => {
								if (
									item.idPrecioProductoProveedor === currectCostProduct.idPrecioProductoProveedor
								) {
									// Actualizar los campos relevantes del objeto correspondiente
									return {
										...item,
										costo: formatCurrencyFromModal(`${currectCostProduct.costo.toString()}`),
										iva: currectCostProduct.iva,
										fechaModificacion: new Date(),
										usuarioModificacion: Cookie.get('idUsuario')
									};
								}
								return item;
							});
							return updatedDataTable;
						});
						setResultsTableSearch((prevDataTable) => {
							const updatedDataTable = prevDataTable.map((item) => {
								if (
									item.idPrecioProductoProveedor === currectCostProduct.idPrecioProductoProveedor
								) {
									// Actualizar los campos relevantes del objeto correspondiente
									return {
										...item,
										costo: formatCurrencyFromModal(`${currectCostProduct.costo.toString()}`),
										iva: currectCostProduct.iva,
										fechaModificacion: new Date(),
										usuarioModificacion: Cookie.get('idUsuario')
									};
								}
								return item;
							});
							return updatedDataTable;
						});
						getDataMiniTable(id);
					})
					.catch((err) => {
						if (err.response.status === 412) {
							customSB(
								'error',
								'alert.message.error.costProduct',
								'Ya se creo un costo con estos parametros'
							);
						} else {
							errorProcess();
						}
						onClose();
					});
			} else {
				updateStructure();
			}
		} else {
			console.log('Debes completar todos los campos');
		}
	};

	// Update the structure of the table after search the item to update
	const updateStructure = () => {
		currentDataTable.forEach((item) => {
			if (item.idPrecioProductoProveedor === modalData.idPrecioProductoProveedor) {
				const dataLeft = currentDataTable.filter((_, i) => {
					return i !== currentDataTable.indexOf(item);
				});
				const newStructure = {
					idPrecioProductoProveedor: currectCostProduct.idPrecioProductoProveedor,
					idProveedor: {
						value: auxData.idProveedor.value,
						label: auxData.idProveedor.label
					},

					idUnidadMedida: {
						value: auxData.idUnidadMedida.value,
						label: auxData.idUnidadMedida.label
					},
					costo: formatCurrencyFromModal(currectCostProduct.costo.toString()),
					iva: currectCostProduct.iva,
					idMoneda: auxData.idMoneda
				};
				setCurrentDataTable([...dataLeft, newStructure]);
				onClose();
			}
		});
	};

	// update the form number
	const handleNumber = (event) => {
		const inputCosto = event.target.value;
		const costoFormateado = inputCosto.replace(/[^\d,]/g, '').replace(/(,.*)\,/g, '$1');

		setCostoModal(costoFormateado);
		const costoDecimal = parseFloat(costoFormateado.replace(',', '.'));
		const costoConDosDecimales = costoDecimal.toLocaleString('es-ES', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});

		setCurrectCostProduct({
			...currectCostProduct,
			[event.target.name]: costoConDosDecimales
		});
	};

	const handleIvaChange = (event) => {
		const inputIva = event.target.value;

		const ivaFormateado = inputIva.replace(/[^\d,]/g, '');
		setIvaModal(ivaFormateado);

		const ivaDecimal = parseFloat(ivaFormateado.replace(',', '.'));
		const ivaConDosDecimales = ivaDecimal.toLocaleString('es-ES', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});

		setCurrectCostProduct({
			...currectCostProduct,
			[event.target.name]: ivaConDosDecimales
		});
	};

	const formatIvaField = (value) => {
		const taxs = value.toString().split('.');
		let taxValue = `${taxs[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;

		if (taxs.length > 1) {
			taxValue += `,${taxs[1]}`;
		}
		const Decimal = taxValue.split(',');
		const intPart = Decimal[0];
		const floatPart = Decimal[1];

		if (floatPart) {
			taxValue = intPart + ',' + floatPart.substring(0, 2);
		}

		return taxValue;
	};

	return {
		auxData,
		costoModal,
		currectCostProduct,
		formatCurrencyFromModal,
		formatIvaField,
		formatterText,
		handleIvaChange,
		handleNumber,
		handleUpdateModalData,
		ivaModal,
		selectedSearch,
		setAuxData
	};
}
