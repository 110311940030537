const customStyles2 = {
	control: (base) => ({
		...base,
		width: '100%',
		margin: '15px 0 0 0',
		borderRadius: '70px',
		boxShadow: '0px 4px 8px rgba(0, 174, 142, 0.2)',
		border: '0px !important',
		padding: '0 6px',
		// "max-width": "20rem",
		minWidth: '150px',
		'max-height': '4rem',
		overflow: 'auto'
	}),
	menu: (styles) => ({
		...styles,
		width: '100%',
		zIndex: '2'
	})
};

export default customStyles2;
