import profileImage from 'assets/profile-default.jpg';
import Cookie from 'js-cookie';

export const parseDataToSubmit = (values) => {
	const dataToSend = {
		idUsuario: null,
		usuario: values.usuario,
		correo: values.correo,
		estado: 2,
		avatarUrl: profileImage,
		idIdioma: {
			idIdioma: 1,
			idioma: 'Español'
		},
		idEmpleado: values.idEmpleado,
		idTercero: values.idTercero,
		idAsesorCliente: values.idAsesorCliente,
		codigo: values.codigo,
		usuarioCreacion: Cookie.get('idUsuario'),
		dobleAutenticacion: 0,
		tipoAcceso: values.tipoAcceso
	};

	return dataToSend;
};
