import React from 'react';
// Import Context
// Import Hooks
// Import Components
import Table from 'common/minimalTables/TableMinimal';
import defaultSelect from 'common/selects/default.select.style';
import { Modal } from 'react-responsive-modal';
import UpdateModalItem from '../UpdateModalItem';
// Import Libs
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';

import paths from 'services/paths';
import { InputFieldResponsive } from 'common/inputs/InputFieldResponsive';
import { Formiz } from '@formiz/core';
import CreateServiceCategoryViewModel from './CreateServiceCategory.viewModel';

export default function CreateServiceCategoryComponent() {
	const {
		myForm,
		handleFormChange,
		handleSubmit,
		formData,
		handlerTextDescription,
		handleAddItemMinimal,
		handleChangeSubCategory,
		handleSelectChange,
		selectValues,
		productCategories,
		currentItemMinimal,
		currentDataTable,
		titlesTableSubCategories,
		handleOpen,
		handleClose,
		open,
		cat,
		navigate,
		formatterText
	} = CreateServiceCategoryViewModel();
	return (
		<>
			<Formiz onChange={handleFormChange} connect={myForm}>
				<form className="form-responsive" onSubmit={handleSubmit}>
					<section className="form-responsive-container-information">
						<p className="wrap-form-title">
							{formatterText('table.title.category.service', 'Categoría de servicio')}
						</p>
						<section className="form-responsive-container-information">
							<section className="form-responsive-information__option">
								{/* <h3 className="p-styles">
                {formatterText('p.name.service.category', '777Nombre de categoría de servicio')}
              </h3>
              <input
                className="input-primary"
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleText}
                placeholder={formatterText(
                  'input.placeholder.service.category',
                  'Nombre de categoría de servicio',
                )}
                maxLength="45"
                required
              /> */}
								<InputFieldResponsive
									type="text"
									name="nombre"
									labelText={formatterText(
										'p.name.service.category',
										'Nombre de categoría de servicio'
									)}
									placeholder={formatterText(
										'input.placeholder.service.category',
										'Nombre de categoría de servicio'
									)}
									validateInput="namestage"
									maxLength="100"
									required={formatterText('p.name.category.servicie.is.required')}
								/>
							</section>
							<section className="form-responsive-information__option">
								<h3 className="p-styles">
									{formatterText('table.title.description', 'Descripción')}
								</h3>
								<textarea
									className="input-primary-textarea"
									name="descripcion"
									value={formData.descripcion}
									onChange={handlerTextDescription}
									placeholder={formatterText('table.title.description', 'Descripción')}
									maxLength="200"
								/>
								{/* <InputFieldResponsive
                    type="text"
                    area = {true}
                    name="descripcion"
                    styleName="input-primary-textarea"
                    labelText={formatterText(
                      'table.title.description',
                      'Descripción',
                    )}
                    placeholder={formatterText(
                      "table.title.description",
                      "Descripción",
                    )}
                    validateInput="text"
                    maxLength="200"
                    required={formatterText('p.description.is.required')}
                    
                  /> */}
							</section>
							<section className="form-responsive-information__option">
								{/* <h3 className="p-styles">{formatterText('table.title.code', 'Código')}</h3>
              <input
                className="input-primary"
                type="text"
                name="codigo"
                value={formData.codigo}
                onChange={handlerTextCode}
                placeholder={formatterText(
                  'input.placeholder.enter.up.characters',
                  'Ingresa hasta 45 caracteres',
                )}
                maxLength="45"
                required
              /> */}
								<InputFieldResponsive
									type="text"
									name="codigo"
									labelText={formatterText('table.title.code', 'Código')}
									placeholder={formatterText(
										'input.placeholder.enter.up.characters',
										'Ingresa hasta 45 caracteres'
									)}
									validateInput="code"
									maxLength="45"
									required={formatterText('table.title.code.required')}
								/>
							</section>
						</section>
						{currentDataTable.length > 0 && (
							<>
								<p className="wrap-form-title">
									{formatterText('p.service.type.associates', 'Tipos de servicio asociados')}
								</p>
								<section className="form-responsive-container-information">
									<Table
										titles={titlesTableSubCategories}
										data={currentDataTable}
										type="typeCategoryService"
										handleOpen={handleOpen}
										labelTable={formatterText('p.service.type.associates.footer')}
										canSearch={true}
									/>
								</section>
							</>
						)}
						<p className="wrap-form-title">
							{formatterText('table.title.service.type', '+ Agregar tipo de servicio')}
						</p>
						<section
							className="grid-container-2c"
							style={{
								width: '87%',
								margin: '0 auto'
							}}
						>
							<label className="d-flex">
								<h3 className="text-inline">
									{formatterText('p.name.type.service', 'Nombre tipo de servicio')}
								</h3>
								<section className="w100-container">
									<input
										className="input-primary"
										type="text"
										name="nombre"
										value={currentItemMinimal.nombre}
										onChange={handleChangeSubCategory}
										placeholder={formatterText('p.name.type.service', 'Nombre tipo de servicio')}
										maxLength="100"
									/>
								</section>
							</label>
							<label className="d-flex">
								<h3 className="text-inline">{formatterText('table.title.code', 'Código')}</h3>
								<section className="w100-container">
									<input
										className="input-primary"
										type="text"
										name="codigo"
										value={currentItemMinimal.codigo.toLowerCase()}
										onChange={handleChangeSubCategory}
										placeholder={formatterText(
											'input.placeholder.enter.up.characters',
											'Ingresa hasta 45 caracteres'
										)}
										maxLength="45"
									/>
								</section>
							</label>
						</section>
						<section className="wrapForm">
							<label className="wrapForm__label">
								<h3 className="p-styles">
									{formatterText('table.title.description', 'Descripción')}
								</h3>
								<textarea
									className="input-primary-textarea width-80"
									type="text"
									name="descripcion"
									value={currentItemMinimal.descripcion}
									onChange={handleChangeSubCategory}
									placeholder={formatterText(
										'p.description.subcategory',
										'Subcategory description'
									)}
									maxLength="200"
								/>
							</label>

							<label className="wrapForm__label">
								<h3 className="text-inline">
									{formatterText('p.product.category', 'Categoria de productos')}
								</h3>
								<section className="w100-container">
									<Select
										name="categoryType"
										id="categoryType"
										isMulti
										styles={defaultSelect}
										options={productCategories}
										value={selectValues}
										onChange={handleSelectChange}
										noOptionsMessage={
											<FormattedMessage
												id="select.placeholder.no.options"
												defaultMessage="No se encontraron opciones"
											/>
										}
										placeholder={
											<FormattedMessage
												id="input.placeholder.select"
												defaultMessage="Selecione una opción"
											/>
										}
									/>
								</section>
							</label>
						</section>
						<input
							onClick={handleAddItemMinimal}
							type="button"
							className="btn-primary btn-primary-center"
							value={formatterText('btn.assign.service.type', 'Asignar tipo de servicio')}
						/>
					</section>
					<section className="form-responsive-container-buttons">
						<button type="submit" className="btn-primary">
							{formatterText('btn.create.service.category', 'Crear categoría de servicio')}
						</button>
						<button className="input-cancel" onClick={() => navigate(paths.services)}>
							{formatterText('btn.cancel', 'Cancelar')}
						</button>
					</section>
				</form>
			</Formiz>
			<Modal
				open={open}
				onClose={handleClose}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<UpdateModalItem productCategories={productCategories} onClose={handleClose} cat={cat} />
			</Modal>
		</>
	);
}
