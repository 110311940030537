import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';

// Import Libs
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

// Import Components
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';

// Import Hooks
import useLangv2 from 'hooks/useLangv2';

// Import Utils
import HandleInput from 'common/validators/HandleInput';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import { validateDuplicateCode } from 'common/validators/ValidateDuplicates';

// Import Paths
import paths from 'services/paths';

// Import Models
import Parameter from 'models/Parameters/Parameter';

// Import Constants
import { TEXTREGEX, TEXTREGEXMIN } from 'common/validators/Regex';

export default function UpdateParameterViewModel() {
	const { endpoints, RequestUseCase } = useAxios();
	const { id } = useParams();
	const navigate = useNavigate();
	// Datos de un formulario
	const [formData, setFormData] = useState(new Parameter());
	// toggle state
	const [active, setActive] = useState(false);

	const { formatterText, noFilledContent } = useLangv2();
	const [errorName, setErrorName] = useState('');
	const [codeError, setCodeError] = useState('');

	useEffect(() => {
		getThirdType();
	}, [id]);

	const getThirdType = () => {
		RequestUseCase.get(endpoints.parameters.getAllThirdTypes)
			.then((data) => {
				const tercero = data.find((third) => third.idTipoTercero.toString() === id);
				setFormData(tercero);
				setActive(tercero.estado.toString() === '1');
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// Update a string to set into the form
	const handleText = (e) => {
		HandleInput(e, TEXTREGEX, formData, setFormData, true);
	};

	const handlerTextCode = (e) => {
		const example = e;
		example.target.value = e.target.value.toLowerCase();
		HandleInput(example, TEXTREGEXMIN, formData, setFormData);
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	const validateCode = () => {
		const errorMsg = formatterText('p.label.title.codigoExistenteElemento');

		const validate = validateDuplicateCode(
			formData.codigo,
			errorMsg,
			() => RequestUseCase.get(endpoints.parameters.getAllThirdTypes),
			true
		);
		return validate;
	};

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		if (errorName !== '' || codeError !== '' || formData.nombre === '' || formData.codigo === '') {
			noFilledContent();
		} else {
			const data = {
				...formData,
				nombre: formData.nombre.replaceAll(/\s{2,}/gi, ' '),
				descripcion: formData.descripcion ? formData.descripcion.replaceAll(/\s{2,}/gi, ' ') : '',
				estado: active ? '1' : '0',
				usuarioModificacion: Cookie.get('idUsuario')
			};

			if (validateCode()) {
				Swal.fire({
					title: formatterText(
						'alert.title.general',
						'Atención, estás seguro de realizar esta acción'
					),
					text: formatterText('alert.description.update.general', 'Se va a editar el registro'),
					icon: 'question',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					showLoaderOnConfirm: true,
					cancelButtonColor: '#d33',
					confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
					allowOutsideClick: false,
					cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
					preConfirm: () => {
						return new Promise(() => {
							RequestUseCase.put(endpoints.parameters.updateThirdType, data)
								.then(() => {
									CustomAlert('confirm_msg', {
										icon: 'success',
										title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
										text: formatterText(
											'alert.message.confirm.updated.general',
											'El registro se ha actualizado correctamente'
										),
										confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
										allowOutsideClick: false,
										executeFunction: () => navigate(paths.parameters)
									});
								})
								.catch((err) => {
									if (err.response?.data?.message) {
										HandleOnError(formatterText(err.response?.data?.message));
									} else if (err.response.status === 500) {
										HandleOnError(
											formatterText(
												'alert.message.code.error.general',
												'El código ya existe, por favor ingrese uno distinto'
											)
										);
									} else {
										HandleOnError(
											formatterText(
												'snackbar.error.process.failed.general',
												'Error al realizar el proceso. Intentalo en otro momento.'
											)
										);
									}
								});
						});
					}
				});
			}
		}
	};

	const handleErrorName = () => {
		if (formData.nombre.trim() === '') {
			setErrorName(formatterText('p.label.title.name.is.required'));
		} else {
			setErrorName('');
		}
	};

	const handleErrorCode = () => {
		if (formData.codigo.trim() === '') {
			setCodeError(formatterText('table.title.code.required'));
		} else {
			setCodeError('');
		}
	};

	return {
		active,
		codeError,
		errorName,
		formatterText,
		formData,
		handleErrorCode,
		handleErrorName,
		handlerTextCode,
		handlerTextDescription,
		handleSubmit,
		handleText,
		navigate,
		setActive
	};
}
