const hours = [
	{
		idHora: 1,
		codigo: '00:00:00',
		hora: '00:00',
		valor: 0.0,
		fechaCreacion: '2022-11-24T22:41:59.927+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 2,
		codigo: '00:01:00',
		hora: '00:01',
		valor: 0.1,
		fechaCreacion: '2022-11-24T22:42:00.490+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 3,
		codigo: '00:02:00',
		hora: '00:02',
		valor: 0.3,
		fechaCreacion: '2022-11-24T22:42:00.762+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 4,
		codigo: '00:03:00',
		hora: '00:03',
		valor: 0.4,
		fechaCreacion: '2022-11-24T22:42:01.036+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 5,
		codigo: '00:04:00',
		hora: '00:04',
		valor: 0.6,
		fechaCreacion: '2022-11-24T22:42:01.307+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 6,
		codigo: '00:05:00',
		hora: '00:05',
		valor: 0.8,
		fechaCreacion: '2022-11-24T22:42:01.577+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 7,
		codigo: '00:06:00',
		hora: '00:06',
		valor: 0.9,
		fechaCreacion: '2022-11-24T22:42:01.851+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 8,
		codigo: '00:07:00',
		hora: '00:07',
		valor: 0.11,
		fechaCreacion: '2022-11-24T22:42:02.116+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 9,
		codigo: '00:08:00',
		hora: '00:08',
		valor: 0.13,
		fechaCreacion: '2022-11-24T22:42:02.412+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 10,
		codigo: '00:09:00',
		hora: '00:09',
		valor: 0.14,
		fechaCreacion: '2022-11-24T22:42:02.688+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 11,
		codigo: '00:10:00',
		hora: '00:10',
		valor: 0.16,
		fechaCreacion: '2022-11-24T22:42:02.960+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 12,
		codigo: '00:11:00',
		hora: '00:11',
		valor: 0.18,
		fechaCreacion: '2022-11-24T22:42:03.233+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 13,
		codigo: '00:12:00',
		hora: '00:12',
		valor: 0.19,
		fechaCreacion: '2022-11-24T22:42:03.501+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 14,
		codigo: '00:13:00',
		hora: '00:13',
		valor: 0.21,
		fechaCreacion: '2022-11-24T22:42:03.780+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 15,
		codigo: '00:14:00',
		hora: '00:14',
		valor: 0.23,
		fechaCreacion: '2022-11-24T22:42:04.053+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 16,
		codigo: '00:15:00',
		hora: '00:15',
		valor: 0.24,
		fechaCreacion: '2022-11-24T22:42:04.329+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 17,
		codigo: '00:16:00',
		hora: '00:16',
		valor: 0.26,
		fechaCreacion: '2022-11-24T22:42:04.597+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 18,
		codigo: '00:17:00',
		hora: '00:17',
		valor: 0.28,
		fechaCreacion: '2022-11-24T22:42:04.863+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 19,
		codigo: '00:18:00',
		hora: '00:18',
		valor: 0.29,
		fechaCreacion: '2022-11-24T22:42:05.132+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 20,
		codigo: '00:19:00',
		hora: '00:19',
		valor: 0.31,
		fechaCreacion: '2022-11-24T22:42:05.409+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 21,
		codigo: '00:20:00',
		hora: '00:20',
		valor: 0.33,
		fechaCreacion: '2022-11-24T22:42:05.679+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 22,
		codigo: '00:21:00',
		hora: '00:21',
		valor: 0.34,
		fechaCreacion: '2022-11-24T22:42:05.949+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 23,
		codigo: '00:20:00',
		hora: '00:20',
		valor: 0.33,
		fechaCreacion: '2022-11-24T22:42:06.223+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 24,
		codigo: '00:23:00',
		hora: '00:23',
		valor: 0.38,
		fechaCreacion: '2022-11-24T22:42:06.506+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 25,
		codigo: '00:24:00',
		hora: '00:24',
		valor: 0.39,
		fechaCreacion: '2022-11-24T22:42:06.774+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 26,
		codigo: '00:25:00',
		hora: '00:25',
		valor: 0.41,
		fechaCreacion: '2022-11-24T22:42:07.043+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 27,
		codigo: '00:26:00',
		hora: '00:26',
		valor: 0.43,
		fechaCreacion: '2022-11-24T22:42:07.314+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 28,
		codigo: '00:27:00',
		hora: '00:27',
		valor: 0.44,
		fechaCreacion: '2022-11-24T22:42:07.593+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 29,
		codigo: '00:28:00',
		hora: '00:28',
		valor: 0.46,
		fechaCreacion: '2022-11-24T22:42:07.872+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 30,
		codigo: '00:29:00',
		hora: '00:29',
		valor: 0.48,
		fechaCreacion: '2022-11-24T22:42:08.146+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 31,
		codigo: '00:30:00',
		hora: '00:30',
		valor: 0.49,
		fechaCreacion: '2022-11-24T22:42:08.405+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 32,
		codigo: '00:31:00',
		hora: '00:31',
		valor: 0.51,
		fechaCreacion: '2022-11-24T22:42:08.690+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 33,
		codigo: '00:32:00',
		hora: '00:32',
		valor: 0.53,
		fechaCreacion: '2022-11-24T22:42:08.963+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 34,
		codigo: '00:33:00',
		hora: '00:33',
		valor: 0.54,
		fechaCreacion: '2022-11-24T22:42:09.228+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 35,
		codigo: '00:34:00',
		hora: '00:34',
		valor: 0.56,
		fechaCreacion: '2022-11-24T22:42:09.492+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 36,
		codigo: '00:35:00',
		hora: '00:35',
		valor: 0.58,
		fechaCreacion: '2022-11-24T22:42:09.770+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 37,
		codigo: '00:36:00',
		hora: '00:36',
		valor: 0.59,
		fechaCreacion: '2022-11-24T22:42:10.037+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 38,
		codigo: '00:37:00',
		hora: '00:37',
		valor: 0.61,
		fechaCreacion: '2022-11-24T22:42:10.307+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 39,
		codigo: '00:38:00',
		hora: '00:38',
		valor: 0.63,
		fechaCreacion: '2022-11-24T22:42:10.575+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 40,
		codigo: '00:39:00',
		hora: '00:39',
		valor: 0.64,
		fechaCreacion: '2022-11-24T22:42:10.845+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 41,
		codigo: '00:40:00',
		hora: '00:40',
		valor: 0.66,
		fechaCreacion: '2022-11-24T22:42:11.115+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 42,
		codigo: '00:41:00',
		hora: '00:41',
		valor: 0.68,
		fechaCreacion: '2022-11-24T22:42:11.386+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 43,
		codigo: '00:42:00',
		hora: '00:42',
		valor: 0.69,
		fechaCreacion: '2022-11-24T22:42:11.656+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 44,
		codigo: '00:43:00',
		hora: '00:43',
		valor: 0.71,
		fechaCreacion: '2022-11-24T22:42:11.926+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 45,
		codigo: '00:44:00',
		hora: '00:44',
		valor: 0.73,
		fechaCreacion: '2022-11-24T22:42:12.193+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 46,
		codigo: '00:45:00',
		hora: '00:45',
		valor: 0.74,
		fechaCreacion: '2022-11-24T22:42:12.463+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 47,
		codigo: '00:46:00',
		hora: '00:46',
		valor: 0.76,
		fechaCreacion: '2022-11-24T22:42:12.738+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 48,
		codigo: '00:47:00',
		hora: '00:47',
		valor: 0.78,
		fechaCreacion: '2022-11-24T22:42:13.012+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 49,
		codigo: '00:48:00',
		hora: '00:48',
		valor: 0.79,
		fechaCreacion: '2022-11-24T22:42:13.284+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 50,
		codigo: '00:49:00',
		hora: '00:49',
		valor: 0.81,
		fechaCreacion: '2022-11-24T22:42:13.555+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 51,
		codigo: '00:50:00',
		hora: '00:50',
		valor: 0.83,
		fechaCreacion: '2022-11-24T22:42:13.830+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 52,
		codigo: '00:51:00',
		hora: '00:51',
		valor: 0.84,
		fechaCreacion: '2022-11-24T22:42:14.106+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 53,
		codigo: '00:52:00',
		hora: '00:52',
		valor: 0.86,
		fechaCreacion: '2022-11-24T22:42:14.386+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 54,
		codigo: '00:53:00',
		hora: '00:53',
		valor: 0.88,
		fechaCreacion: '2022-11-24T22:42:14.651+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 55,
		codigo: '00:54:00',
		hora: '00:54',
		valor: 0.89,
		fechaCreacion: '2022-11-24T22:42:14.922+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 56,
		codigo: '00:55:00',
		hora: '00:55',
		valor: 0.91,
		fechaCreacion: '2022-11-24T22:42:15.196+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 57,
		codigo: '00:56:00',
		hora: '00:56',
		valor: 0.93,
		fechaCreacion: '2022-11-24T22:42:15.467+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 58,
		codigo: '00:57:00',
		hora: '00:57',
		valor: 0.94,
		fechaCreacion: '2022-11-24T22:42:15.736+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 59,
		codigo: '00:58:00',
		hora: '00:58',
		valor: 0.96,
		fechaCreacion: '2022-11-24T22:42:16.007+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 60,
		codigo: '00:59:00',
		hora: '00:59',
		valor: 0.98,
		fechaCreacion: '2022-11-24T22:42:16.275+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 61,
		codigo: '01:00:00',
		hora: '01:00',
		valor: 1.0,
		fechaCreacion: '2022-11-24T22:42:16.548+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 62,
		codigo: '01:01:00',
		hora: '01:01',
		valor: 1.1,
		fechaCreacion: '2022-11-24T22:42:16.818+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 63,
		codigo: '01:02:00',
		hora: '01:02',
		valor: 1.3,
		fechaCreacion: '2022-11-24T22:42:17.091+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 64,
		codigo: '01:03:00',
		hora: '01:03',
		valor: 1.4,
		fechaCreacion: '2022-11-24T22:42:17.379+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 65,
		codigo: '01:04:00',
		hora: '01:04',
		valor: 1.6,
		fechaCreacion: '2022-11-24T22:42:17.655+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 66,
		codigo: '01:05:00',
		hora: '01:05',
		valor: 1.8,
		fechaCreacion: '2022-11-24T22:42:17.927+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 67,
		codigo: '01:06:00',
		hora: '01:06',
		valor: 1.9,
		fechaCreacion: '2022-11-24T22:42:18.215+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 68,
		codigo: '01:07:00',
		hora: '01:07',
		valor: 1.11,
		fechaCreacion: '2022-11-24T22:42:18.511+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 69,
		codigo: '01:08:00',
		hora: '01:08',
		valor: 1.13,
		fechaCreacion: '2022-11-24T22:42:18.787+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 70,
		codigo: '01:09:00',
		hora: '01:09',
		valor: 1.14,
		fechaCreacion: '2022-11-24T22:42:19.057+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 71,
		codigo: '01:10:00',
		hora: '01:10',
		valor: 1.16,
		fechaCreacion: '2022-11-24T22:42:19.340+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 72,
		codigo: '01:11:00',
		hora: '01:11',
		valor: 1.18,
		fechaCreacion: '2022-11-24T22:42:19.611+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 73,
		codigo: '01:12:00',
		hora: '01:12',
		valor: 1.19,
		fechaCreacion: '2022-11-24T22:42:19.891+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 74,
		codigo: '01:13:00',
		hora: '01:13',
		valor: 1.21,
		fechaCreacion: '2022-11-24T22:42:20.157+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 75,
		codigo: '01:14:00',
		hora: '01:14',
		valor: 1.23,
		fechaCreacion: '2022-11-24T22:42:20.435+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 76,
		codigo: '01:15:00',
		hora: '01:15',
		valor: 1.24,
		fechaCreacion: '2022-11-24T22:42:20.705+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 77,
		codigo: '01:16:00',
		hora: '01:16',
		valor: 1.26,
		fechaCreacion: '2022-11-24T22:42:20.981+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 78,
		codigo: '01:17:00',
		hora: '01:17',
		valor: 1.28,
		fechaCreacion: '2022-11-24T22:42:21.269+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 79,
		codigo: '01:18:00',
		hora: '01:18',
		valor: 1.29,
		fechaCreacion: '2022-11-24T22:42:21.536+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 80,
		codigo: '01:19:00',
		hora: '01:19',
		valor: 1.31,
		fechaCreacion: '2022-11-24T22:42:21.808+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 81,
		codigo: '01:20:00',
		hora: '01:20',
		valor: 1.33,
		fechaCreacion: '2022-11-24T22:42:22.073+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 82,
		codigo: '01:21:00',
		hora: '01:21',
		valor: 1.34,
		fechaCreacion: '2022-11-24T22:42:22.340+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 83,
		codigo: '01:20:00',
		hora: '01:20',
		valor: 1.33,
		fechaCreacion: '2022-11-24T22:42:22.608+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 84,
		codigo: '01:23:00',
		hora: '01:23',
		valor: 1.38,
		fechaCreacion: '2022-11-24T22:42:22.880+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 85,
		codigo: '01:24:00',
		hora: '01:24',
		valor: 1.39,
		fechaCreacion: '2022-11-24T22:42:23.151+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 86,
		codigo: '01:25:00',
		hora: '01:25',
		valor: 1.41,
		fechaCreacion: '2022-11-24T22:42:23.428+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 87,
		codigo: '01:26:00',
		hora: '01:26',
		valor: 1.43,
		fechaCreacion: '2022-11-24T22:42:23.716+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 88,
		codigo: '01:27:00',
		hora: '01:27',
		valor: 1.44,
		fechaCreacion: '2022-11-24T22:42:23.991+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 89,
		codigo: '01:28:00',
		hora: '01:28',
		valor: 1.46,
		fechaCreacion: '2022-11-24T22:42:24.263+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 90,
		codigo: '01:29:00',
		hora: '01:29',
		valor: 1.48,
		fechaCreacion: '2022-11-24T22:42:24.534+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 91,
		codigo: '01:30:00',
		hora: '01:30',
		valor: 1.49,
		fechaCreacion: '2022-11-24T22:42:24.796+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 92,
		codigo: '01:31:00',
		hora: '01:31',
		valor: 1.51,
		fechaCreacion: '2022-11-24T22:42:25.075+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 93,
		codigo: '01:32:00',
		hora: '01:32',
		valor: 1.53,
		fechaCreacion: '2022-11-24T22:42:25.341+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 94,
		codigo: '01:33:00',
		hora: '01:33',
		valor: 1.54,
		fechaCreacion: '2022-11-24T22:42:25.619+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 95,
		codigo: '01:34:00',
		hora: '01:34',
		valor: 1.56,
		fechaCreacion: '2022-11-24T22:42:25.885+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 96,
		codigo: '01:35:00',
		hora: '01:35',
		valor: 1.58,
		fechaCreacion: '2022-11-24T22:42:26.165+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 97,
		codigo: '01:36:00',
		hora: '01:36',
		valor: 1.59,
		fechaCreacion: '2022-11-24T22:42:26.433+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 98,
		codigo: '01:37:00',
		hora: '01:37',
		valor: 1.61,
		fechaCreacion: '2022-11-24T22:42:26.705+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 99,
		codigo: '01:38:00',
		hora: '01:38',
		valor: 1.63,
		fechaCreacion: '2022-11-24T22:42:26.970+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 100,
		codigo: '01:39:00',
		hora: '01:39',
		valor: 1.64,
		fechaCreacion: '2022-11-24T22:42:27.237+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 101,
		codigo: '01:40:00',
		hora: '01:40',
		valor: 1.66,
		fechaCreacion: '2022-11-24T22:42:27.513+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 102,
		codigo: '01:41:00',
		hora: '01:41',
		valor: 1.68,
		fechaCreacion: '2022-11-24T22:42:27.781+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 103,
		codigo: '01:42:00',
		hora: '01:42',
		valor: 1.69,
		fechaCreacion: '2022-11-24T22:42:28.050+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 104,
		codigo: '01:43:00',
		hora: '01:43',
		valor: 1.71,
		fechaCreacion: '2022-11-24T22:42:28.318+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 105,
		codigo: '01:44:00',
		hora: '01:44',
		valor: 1.73,
		fechaCreacion: '2022-11-24T22:42:28.586+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 106,
		codigo: '01:45:00',
		hora: '01:45',
		valor: 1.74,
		fechaCreacion: '2022-11-24T22:42:28.860+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 107,
		codigo: '01:46:00',
		hora: '01:46',
		valor: 1.76,
		fechaCreacion: '2022-11-24T22:42:29.132+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 108,
		codigo: '01:47:00',
		hora: '01:47',
		valor: 1.78,
		fechaCreacion: '2022-11-24T22:42:29.421+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 109,
		codigo: '01:48:00',
		hora: '01:48',
		valor: 1.79,
		fechaCreacion: '2022-11-24T22:42:29.690+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 110,
		codigo: '01:49:00',
		hora: '01:49',
		valor: 1.81,
		fechaCreacion: '2022-11-24T22:42:29.962+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 111,
		codigo: '01:50:00',
		hora: '01:50',
		valor: 1.83,
		fechaCreacion: '2022-11-24T22:42:30.227+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 112,
		codigo: '01:51:00',
		hora: '01:51',
		valor: 1.84,
		fechaCreacion: '2022-11-24T22:42:30.499+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 113,
		codigo: '01:52:00',
		hora: '01:52',
		valor: 1.86,
		fechaCreacion: '2022-11-24T22:42:30.761+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 114,
		codigo: '01:53:00',
		hora: '01:53',
		valor: 1.88,
		fechaCreacion: '2022-11-24T22:42:31.035+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 115,
		codigo: '01:54:00',
		hora: '01:54',
		valor: 1.89,
		fechaCreacion: '2022-11-24T22:42:31.320+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 116,
		codigo: '01:55:00',
		hora: '01:55',
		valor: 1.91,
		fechaCreacion: '2022-11-24T22:42:31.587+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 117,
		codigo: '01:56:00',
		hora: '01:56',
		valor: 1.93,
		fechaCreacion: '2022-11-24T22:42:31.862+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 118,
		codigo: '01:57:00',
		hora: '01:57',
		valor: 1.94,
		fechaCreacion: '2022-11-24T22:42:32.136+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 119,
		codigo: '01:58:00',
		hora: '01:58',
		valor: 1.96,
		fechaCreacion: '2022-11-24T22:42:32.406+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 120,
		codigo: '01:59:00',
		hora: '01:59',
		valor: 1.98,
		fechaCreacion: '2022-11-24T22:42:32.672+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 121,
		codigo: '02:00:00',
		hora: '02:00',
		valor: 2.0,
		fechaCreacion: '2022-11-24T22:42:32.954+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 122,
		codigo: '02:01:00',
		hora: '02:01',
		valor: 2.1,
		fechaCreacion: '2022-11-24T22:42:33.222+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 123,
		codigo: '02:02:00',
		hora: '02:02',
		valor: 2.3,
		fechaCreacion: '2022-11-24T22:42:33.496+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 124,
		codigo: '02:03:00',
		hora: '02:03',
		valor: 2.4,
		fechaCreacion: '2022-11-24T22:42:33.774+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 125,
		codigo: '02:04:00',
		hora: '02:04',
		valor: 2.6,
		fechaCreacion: '2022-11-24T22:42:34.032+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 126,
		codigo: '02:05:00',
		hora: '02:05',
		valor: 2.8,
		fechaCreacion: '2022-11-24T22:42:34.303+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 127,
		codigo: '02:06:00',
		hora: '02:06',
		valor: 2.9,
		fechaCreacion: '2022-11-24T22:42:34.568+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 128,
		codigo: '02:07:00',
		hora: '02:07',
		valor: 2.11,
		fechaCreacion: '2022-11-24T22:42:34.841+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 129,
		codigo: '02:08:00',
		hora: '02:08',
		valor: 2.13,
		fechaCreacion: '2022-11-24T22:42:35.113+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 130,
		codigo: '02:09:00',
		hora: '02:09',
		valor: 2.14,
		fechaCreacion: '2022-11-24T22:42:35.389+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 131,
		codigo: '02:10:00',
		hora: '02:10',
		valor: 2.16,
		fechaCreacion: '2022-11-24T22:42:35.664+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 132,
		codigo: '02:11:00',
		hora: '02:11',
		valor: 2.18,
		fechaCreacion: '2022-11-24T22:42:35.933+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 133,
		codigo: '02:12:00',
		hora: '02:12',
		valor: 2.19,
		fechaCreacion: '2022-11-24T22:42:36.198+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 134,
		codigo: '02:13:00',
		hora: '02:13',
		valor: 2.21,
		fechaCreacion: '2022-11-24T22:42:36.460+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 135,
		codigo: '02:14:00',
		hora: '02:14',
		valor: 2.23,
		fechaCreacion: '2022-11-24T22:42:36.725+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 136,
		codigo: '02:15:00',
		hora: '02:15',
		valor: 2.24,
		fechaCreacion: '2022-11-24T22:42:37.000+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 137,
		codigo: '02:16:00',
		hora: '02:16',
		valor: 2.26,
		fechaCreacion: '2022-11-24T22:42:37.268+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 138,
		codigo: '02:17:00',
		hora: '02:17',
		valor: 2.28,
		fechaCreacion: '2022-11-24T22:42:37.531+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 139,
		codigo: '02:18:00',
		hora: '02:18',
		valor: 2.29,
		fechaCreacion: '2022-11-24T22:42:37.809+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 140,
		codigo: '02:19:00',
		hora: '02:19',
		valor: 2.31,
		fechaCreacion: '2022-11-24T22:42:38.077+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 141,
		codigo: '02:20:00',
		hora: '02:20',
		valor: 2.33,
		fechaCreacion: '2022-11-24T22:42:38.347+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 142,
		codigo: '02:21:00',
		hora: '02:21',
		valor: 2.34,
		fechaCreacion: '2022-11-24T22:42:38.616+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 143,
		codigo: '02:20:00',
		hora: '02:20',
		valor: 2.33,
		fechaCreacion: '2022-11-24T22:42:38.886+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 144,
		codigo: '02:23:00',
		hora: '02:23',
		valor: 2.38,
		fechaCreacion: '2022-11-24T22:42:39.155+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 145,
		codigo: '02:24:00',
		hora: '02:24',
		valor: 2.39,
		fechaCreacion: '2022-11-24T22:42:39.421+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 146,
		codigo: '02:25:00',
		hora: '02:25',
		valor: 2.41,
		fechaCreacion: '2022-11-24T22:42:39.699+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 147,
		codigo: '02:26:00',
		hora: '02:26',
		valor: 2.43,
		fechaCreacion: '2022-11-24T22:42:39.971+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 148,
		codigo: '02:27:00',
		hora: '02:27',
		valor: 2.44,
		fechaCreacion: '2022-11-24T22:42:40.248+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 149,
		codigo: '02:28:00',
		hora: '02:28',
		valor: 2.46,
		fechaCreacion: '2022-11-24T22:42:40.517+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 150,
		codigo: '02:29:00',
		hora: '02:29',
		valor: 2.48,
		fechaCreacion: '2022-11-24T22:42:40.785+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 151,
		codigo: '02:30:00',
		hora: '02:30',
		valor: 2.49,
		fechaCreacion: '2022-11-24T22:42:41.061+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 152,
		codigo: '02:31:00',
		hora: '02:31',
		valor: 2.51,
		fechaCreacion: '2022-11-24T22:42:41.323+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 153,
		codigo: '02:32:00',
		hora: '02:32',
		valor: 2.53,
		fechaCreacion: '2022-11-24T22:42:41.592+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 154,
		codigo: '02:33:00',
		hora: '02:33',
		valor: 2.54,
		fechaCreacion: '2022-11-24T22:42:41.855+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 155,
		codigo: '02:34:00',
		hora: '02:34',
		valor: 2.56,
		fechaCreacion: '2022-11-24T22:42:42.137+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 156,
		codigo: '02:35:00',
		hora: '02:35',
		valor: 2.58,
		fechaCreacion: '2022-11-24T22:42:42.403+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 157,
		codigo: '02:36:00',
		hora: '02:36',
		valor: 2.59,
		fechaCreacion: '2022-11-24T22:42:42.672+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 158,
		codigo: '02:37:00',
		hora: '02:37',
		valor: 2.61,
		fechaCreacion: '2022-11-24T22:42:42.958+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 159,
		codigo: '02:38:00',
		hora: '02:38',
		valor: 2.63,
		fechaCreacion: '2022-11-24T22:42:43.222+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 160,
		codigo: '02:39:00',
		hora: '02:39',
		valor: 2.64,
		fechaCreacion: '2022-11-24T22:42:43.493+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 161,
		codigo: '02:40:00',
		hora: '02:40',
		valor: 2.66,
		fechaCreacion: '2022-11-24T22:42:43.769+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 162,
		codigo: '02:41:00',
		hora: '02:41',
		valor: 2.68,
		fechaCreacion: '2022-11-24T22:42:44.028+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 163,
		codigo: '02:42:00',
		hora: '02:42',
		valor: 2.69,
		fechaCreacion: '2022-11-24T22:42:44.293+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 164,
		codigo: '02:43:00',
		hora: '02:43',
		valor: 2.71,
		fechaCreacion: '2022-11-24T22:42:44.553+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 165,
		codigo: '02:44:00',
		hora: '02:44',
		valor: 2.73,
		fechaCreacion: '2022-11-24T22:42:44.818+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 166,
		codigo: '02:45:00',
		hora: '02:45',
		valor: 2.74,
		fechaCreacion: '2022-11-24T22:42:45.111+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 167,
		codigo: '02:46:00',
		hora: '02:46',
		valor: 2.76,
		fechaCreacion: '2022-11-24T22:42:45.380+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 168,
		codigo: '02:47:00',
		hora: '02:47',
		valor: 2.78,
		fechaCreacion: '2022-11-24T22:42:45.651+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 169,
		codigo: '02:48:00',
		hora: '02:48',
		valor: 2.79,
		fechaCreacion: '2022-11-24T22:42:45.915+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 170,
		codigo: '02:49:00',
		hora: '02:49',
		valor: 2.81,
		fechaCreacion: '2022-11-24T22:42:46.205+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 171,
		codigo: '02:50:00',
		hora: '02:50',
		valor: 2.83,
		fechaCreacion: '2022-11-24T22:42:46.468+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 172,
		codigo: '02:51:00',
		hora: '02:51',
		valor: 2.84,
		fechaCreacion: '2022-11-24T22:42:46.733+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 173,
		codigo: '02:52:00',
		hora: '02:52',
		valor: 2.86,
		fechaCreacion: '2022-11-24T22:42:47.009+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 174,
		codigo: '02:53:00',
		hora: '02:53',
		valor: 2.88,
		fechaCreacion: '2022-11-24T22:42:47.275+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 175,
		codigo: '02:54:00',
		hora: '02:54',
		valor: 2.89,
		fechaCreacion: '2022-11-24T22:42:47.559+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 176,
		codigo: '02:55:00',
		hora: '02:55',
		valor: 2.91,
		fechaCreacion: '2022-11-24T22:42:47.831+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 177,
		codigo: '02:56:00',
		hora: '02:56',
		valor: 2.93,
		fechaCreacion: '2022-11-24T22:42:48.095+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 178,
		codigo: '02:57:00',
		hora: '02:57',
		valor: 2.94,
		fechaCreacion: '2022-11-24T22:42:48.365+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 179,
		codigo: '02:58:00',
		hora: '02:58',
		valor: 2.96,
		fechaCreacion: '2022-11-24T22:42:48.643+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 180,
		codigo: '02:59:00',
		hora: '02:59',
		valor: 2.98,
		fechaCreacion: '2022-11-24T22:42:48.905+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 181,
		codigo: '03:00:00',
		hora: '03:00',
		valor: 3.0,
		fechaCreacion: '2022-11-24T22:42:49.182+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 182,
		codigo: '03:01:00',
		hora: '03:01',
		valor: 3.1,
		fechaCreacion: '2022-11-24T22:42:49.451+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 183,
		codigo: '03:02:00',
		hora: '03:02',
		valor: 3.3,
		fechaCreacion: '2022-11-24T22:42:49.726+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 184,
		codigo: '03:03:00',
		hora: '03:03',
		valor: 3.4,
		fechaCreacion: '2022-11-24T22:42:49.995+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 185,
		codigo: '03:04:00',
		hora: '03:04',
		valor: 3.6,
		fechaCreacion: '2022-11-24T22:42:50.274+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 186,
		codigo: '03:05:00',
		hora: '03:05',
		valor: 3.8,
		fechaCreacion: '2022-11-24T22:42:50.549+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 187,
		codigo: '03:06:00',
		hora: '03:06',
		valor: 3.9,
		fechaCreacion: '2022-11-24T22:42:50.815+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 188,
		codigo: '03:07:00',
		hora: '03:07',
		valor: 3.11,
		fechaCreacion: '2022-11-24T22:42:51.087+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 189,
		codigo: '03:08:00',
		hora: '03:08',
		valor: 3.13,
		fechaCreacion: '2022-11-24T22:42:51.359+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 190,
		codigo: '03:09:00',
		hora: '03:09',
		valor: 3.14,
		fechaCreacion: '2022-11-24T22:42:51.644+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 191,
		codigo: '03:10:00',
		hora: '03:10',
		valor: 3.16,
		fechaCreacion: '2022-11-24T22:42:51.930+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 192,
		codigo: '03:11:00',
		hora: '03:11',
		valor: 3.18,
		fechaCreacion: '2022-11-24T22:42:52.205+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 193,
		codigo: '03:12:00',
		hora: '03:12',
		valor: 3.19,
		fechaCreacion: '2022-11-24T22:42:52.497+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 194,
		codigo: '03:13:00',
		hora: '03:13',
		valor: 3.21,
		fechaCreacion: '2022-11-24T22:42:52.765+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 195,
		codigo: '03:14:00',
		hora: '03:14',
		valor: 3.23,
		fechaCreacion: '2022-11-24T22:42:53.031+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 196,
		codigo: '03:15:00',
		hora: '03:15',
		valor: 3.24,
		fechaCreacion: '2022-11-24T22:42:53.301+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 197,
		codigo: '03:16:00',
		hora: '03:16',
		valor: 3.26,
		fechaCreacion: '2022-11-24T22:42:53.576+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 198,
		codigo: '03:17:00',
		hora: '03:17',
		valor: 3.28,
		fechaCreacion: '2022-11-24T22:42:53.847+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 199,
		codigo: '03:18:00',
		hora: '03:18',
		valor: 3.29,
		fechaCreacion: '2022-11-24T22:42:54.107+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 200,
		codigo: '03:19:00',
		hora: '03:19',
		valor: 3.31,
		fechaCreacion: '2022-11-24T22:42:54.372+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 201,
		codigo: '03:20:00',
		hora: '03:20',
		valor: 3.33,
		fechaCreacion: '2022-11-24T22:42:54.640+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 202,
		codigo: '03:21:00',
		hora: '03:21',
		valor: 3.34,
		fechaCreacion: '2022-11-24T22:42:54.906+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 203,
		codigo: '03:20:00',
		hora: '03:20',
		valor: 3.33,
		fechaCreacion: '2022-11-24T22:42:55.177+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 204,
		codigo: '03:23:00',
		hora: '03:23',
		valor: 3.38,
		fechaCreacion: '2022-11-24T22:42:55.440+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 205,
		codigo: '03:24:00',
		hora: '03:24',
		valor: 3.39,
		fechaCreacion: '2022-11-24T22:42:55.702+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 206,
		codigo: '03:25:00',
		hora: '03:25',
		valor: 3.41,
		fechaCreacion: '2022-11-24T22:42:55.981+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 207,
		codigo: '03:26:00',
		hora: '03:26',
		valor: 3.43,
		fechaCreacion: '2022-11-24T22:42:56.267+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 208,
		codigo: '03:27:00',
		hora: '03:27',
		valor: 3.44,
		fechaCreacion: '2022-11-24T22:42:56.546+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 209,
		codigo: '03:28:00',
		hora: '03:28',
		valor: 3.46,
		fechaCreacion: '2022-11-24T22:42:56.816+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 210,
		codigo: '03:29:00',
		hora: '03:29',
		valor: 3.48,
		fechaCreacion: '2022-11-24T22:42:57.095+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 211,
		codigo: '03:30:00',
		hora: '03:30',
		valor: 3.49,
		fechaCreacion: '2022-11-24T22:42:57.357+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 212,
		codigo: '03:31:00',
		hora: '03:31',
		valor: 3.51,
		fechaCreacion: '2022-11-24T22:42:57.637+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 213,
		codigo: '03:32:00',
		hora: '03:32',
		valor: 3.53,
		fechaCreacion: '2022-11-24T22:42:57.920+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 214,
		codigo: '03:33:00',
		hora: '03:33',
		valor: 3.54,
		fechaCreacion: '2022-11-24T22:42:58.192+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 215,
		codigo: '03:34:00',
		hora: '03:34',
		valor: 3.56,
		fechaCreacion: '2022-11-24T22:42:58.467+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 216,
		codigo: '03:35:00',
		hora: '03:35',
		valor: 3.58,
		fechaCreacion: '2022-11-24T22:42:58.737+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 217,
		codigo: '03:36:00',
		hora: '03:36',
		valor: 3.59,
		fechaCreacion: '2022-11-24T22:42:59.007+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 218,
		codigo: '03:37:00',
		hora: '03:37',
		valor: 3.61,
		fechaCreacion: '2022-11-24T22:42:59.283+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 219,
		codigo: '03:38:00',
		hora: '03:38',
		valor: 3.63,
		fechaCreacion: '2022-11-24T22:42:59.552+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 220,
		codigo: '03:39:00',
		hora: '03:39',
		valor: 3.64,
		fechaCreacion: '2022-11-24T22:42:59.824+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 221,
		codigo: '03:40:00',
		hora: '03:40',
		valor: 3.66,
		fechaCreacion: '2022-11-24T22:43:00.087+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 222,
		codigo: '03:41:00',
		hora: '03:41',
		valor: 3.68,
		fechaCreacion: '2022-11-24T22:43:00.358+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 223,
		codigo: '03:42:00',
		hora: '03:42',
		valor: 3.69,
		fechaCreacion: '2022-11-24T22:43:00.630+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 224,
		codigo: '03:43:00',
		hora: '03:43',
		valor: 3.71,
		fechaCreacion: '2022-11-24T22:43:00.898+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 225,
		codigo: '03:44:00',
		hora: '03:44',
		valor: 3.73,
		fechaCreacion: '2022-11-24T22:43:01.177+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 226,
		codigo: '03:45:00',
		hora: '03:45',
		valor: 3.74,
		fechaCreacion: '2022-11-24T22:43:01.452+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 227,
		codigo: '03:46:00',
		hora: '03:46',
		valor: 3.76,
		fechaCreacion: '2022-11-24T22:43:01.730+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 228,
		codigo: '03:47:00',
		hora: '03:47',
		valor: 3.78,
		fechaCreacion: '2022-11-24T22:43:02.004+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 229,
		codigo: '03:48:00',
		hora: '03:48',
		valor: 3.79,
		fechaCreacion: '2022-11-24T22:43:02.275+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 230,
		codigo: '03:49:00',
		hora: '03:49',
		valor: 3.81,
		fechaCreacion: '2022-11-24T22:43:02.555+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 231,
		codigo: '03:50:00',
		hora: '03:50',
		valor: 3.83,
		fechaCreacion: '2022-11-24T22:43:02.824+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 232,
		codigo: '03:51:00',
		hora: '03:51',
		valor: 3.84,
		fechaCreacion: '2022-11-24T22:43:03.090+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 233,
		codigo: '03:52:00',
		hora: '03:52',
		valor: 3.86,
		fechaCreacion: '2022-11-24T22:43:03.366+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 234,
		codigo: '03:53:00',
		hora: '03:53',
		valor: 3.88,
		fechaCreacion: '2022-11-24T22:43:03.651+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 235,
		codigo: '03:54:00',
		hora: '03:54',
		valor: 3.89,
		fechaCreacion: '2022-11-24T22:43:03.926+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 236,
		codigo: '03:55:00',
		hora: '03:55',
		valor: 3.91,
		fechaCreacion: '2022-11-24T22:43:04.198+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 237,
		codigo: '03:56:00',
		hora: '03:56',
		valor: 3.93,
		fechaCreacion: '2022-11-24T22:43:04.463+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 238,
		codigo: '03:57:00',
		hora: '03:57',
		valor: 3.94,
		fechaCreacion: '2022-11-24T22:43:04.738+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 239,
		codigo: '03:58:00',
		hora: '03:58',
		valor: 3.96,
		fechaCreacion: '2022-11-24T22:43:05.021+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 240,
		codigo: '03:59:00',
		hora: '03:59',
		valor: 3.98,
		fechaCreacion: '2022-11-24T22:43:05.310+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 241,
		codigo: '04:00:00',
		hora: '04:00',
		valor: 4.0,
		fechaCreacion: '2022-11-24T22:43:05.575+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 242,
		codigo: '04:01:00',
		hora: '04:01',
		valor: 4.1,
		fechaCreacion: '2022-11-24T22:43:05.851+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 243,
		codigo: '04:02:00',
		hora: '04:02',
		valor: 4.3,
		fechaCreacion: '2022-11-24T22:43:06.121+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 244,
		codigo: '04:03:00',
		hora: '04:03',
		valor: 4.4,
		fechaCreacion: '2022-11-24T22:43:06.400+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 245,
		codigo: '04:04:00',
		hora: '04:04',
		valor: 4.6,
		fechaCreacion: '2022-11-24T22:43:06.661+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 246,
		codigo: '04:05:00',
		hora: '04:05',
		valor: 4.8,
		fechaCreacion: '2022-11-24T22:43:06.925+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 247,
		codigo: '04:06:00',
		hora: '04:06',
		valor: 4.9,
		fechaCreacion: '2022-11-24T22:43:07.190+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 248,
		codigo: '04:07:00',
		hora: '04:07',
		valor: 4.11,
		fechaCreacion: '2022-11-24T22:43:07.455+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 249,
		codigo: '04:08:00',
		hora: '04:08',
		valor: 4.13,
		fechaCreacion: '2022-11-24T22:43:07.734+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 250,
		codigo: '04:09:00',
		hora: '04:09',
		valor: 4.14,
		fechaCreacion: '2022-11-24T22:43:08.001+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 251,
		codigo: '04:10:00',
		hora: '04:10',
		valor: 4.16,
		fechaCreacion: '2022-11-24T22:43:08.277+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 252,
		codigo: '04:11:00',
		hora: '04:11',
		valor: 4.18,
		fechaCreacion: '2022-11-24T22:43:08.540+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 253,
		codigo: '04:12:00',
		hora: '04:12',
		valor: 4.19,
		fechaCreacion: '2022-11-24T22:43:08.811+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 254,
		codigo: '04:13:00',
		hora: '04:13',
		valor: 4.21,
		fechaCreacion: '2022-11-24T22:43:09.086+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 255,
		codigo: '04:14:00',
		hora: '04:14',
		valor: 4.23,
		fechaCreacion: '2022-11-24T22:43:09.366+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 256,
		codigo: '04:15:00',
		hora: '04:15',
		valor: 4.24,
		fechaCreacion: '2022-11-24T22:43:09.636+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 257,
		codigo: '04:16:00',
		hora: '04:16',
		valor: 4.26,
		fechaCreacion: '2022-11-24T22:43:09.911+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 258,
		codigo: '04:17:00',
		hora: '04:17',
		valor: 4.28,
		fechaCreacion: '2022-11-24T22:43:10.203+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 259,
		codigo: '04:18:00',
		hora: '04:18',
		valor: 4.29,
		fechaCreacion: '2022-11-24T22:43:10.487+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 260,
		codigo: '04:19:00',
		hora: '04:19',
		valor: 4.31,
		fechaCreacion: '2022-11-24T22:43:10.766+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 261,
		codigo: '04:20:00',
		hora: '04:20',
		valor: 4.33,
		fechaCreacion: '2022-11-24T22:43:11.038+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 262,
		codigo: '04:21:00',
		hora: '04:21',
		valor: 4.34,
		fechaCreacion: '2022-11-24T22:43:11.301+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 263,
		codigo: '04:20:00',
		hora: '04:20',
		valor: 4.33,
		fechaCreacion: '2022-11-24T22:43:11.573+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 264,
		codigo: '04:23:00',
		hora: '04:23',
		valor: 4.38,
		fechaCreacion: '2022-11-24T22:43:11.838+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 265,
		codigo: '04:24:00',
		hora: '04:24',
		valor: 4.39,
		fechaCreacion: '2022-11-24T22:43:12.112+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 266,
		codigo: '04:25:00',
		hora: '04:25',
		valor: 4.41,
		fechaCreacion: '2022-11-24T22:43:12.383+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 267,
		codigo: '04:26:00',
		hora: '04:26',
		valor: 4.43,
		fechaCreacion: '2022-11-24T22:43:12.647+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 268,
		codigo: '04:27:00',
		hora: '04:27',
		valor: 4.44,
		fechaCreacion: '2022-11-24T22:43:12.923+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 269,
		codigo: '04:28:00',
		hora: '04:28',
		valor: 4.46,
		fechaCreacion: '2022-11-24T22:43:13.204+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 270,
		codigo: '04:29:00',
		hora: '04:29',
		valor: 4.48,
		fechaCreacion: '2022-11-24T22:43:13.467+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 271,
		codigo: '04:30:00',
		hora: '04:30',
		valor: 4.49,
		fechaCreacion: '2022-11-24T22:43:13.721+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 272,
		codigo: '04:31:00',
		hora: '04:31',
		valor: 4.51,
		fechaCreacion: '2022-11-24T22:43:13.993+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 273,
		codigo: '04:32:00',
		hora: '04:32',
		valor: 4.53,
		fechaCreacion: '2022-11-24T22:43:14.262+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 274,
		codigo: '04:33:00',
		hora: '04:33',
		valor: 4.54,
		fechaCreacion: '2022-11-24T22:43:14.532+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 275,
		codigo: '04:34:00',
		hora: '04:34',
		valor: 4.56,
		fechaCreacion: '2022-11-24T22:43:14.796+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 276,
		codigo: '04:35:00',
		hora: '04:35',
		valor: 4.58,
		fechaCreacion: '2022-11-24T22:43:15.066+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 277,
		codigo: '04:36:00',
		hora: '04:36',
		valor: 4.59,
		fechaCreacion: '2022-11-24T22:43:15.328+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 278,
		codigo: '04:37:00',
		hora: '04:37',
		valor: 4.61,
		fechaCreacion: '2022-11-24T22:43:15.598+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 279,
		codigo: '04:38:00',
		hora: '04:38',
		valor: 4.63,
		fechaCreacion: '2022-11-24T22:43:15.861+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 280,
		codigo: '04:39:00',
		hora: '04:39',
		valor: 4.64,
		fechaCreacion: '2022-11-24T22:43:16.132+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 281,
		codigo: '04:40:00',
		hora: '04:40',
		valor: 4.66,
		fechaCreacion: '2022-11-24T22:43:16.401+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 282,
		codigo: '04:41:00',
		hora: '04:41',
		valor: 4.68,
		fechaCreacion: '2022-11-24T22:43:16.667+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 283,
		codigo: '04:42:00',
		hora: '04:42',
		valor: 4.69,
		fechaCreacion: '2022-11-24T22:43:16.947+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 284,
		codigo: '04:43:00',
		hora: '04:43',
		valor: 4.71,
		fechaCreacion: '2022-11-24T22:43:17.214+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 285,
		codigo: '04:44:00',
		hora: '04:44',
		valor: 4.73,
		fechaCreacion: '2022-11-24T22:43:17.478+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 286,
		codigo: '04:45:00',
		hora: '04:45',
		valor: 4.74,
		fechaCreacion: '2022-11-24T22:43:17.750+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 287,
		codigo: '04:46:00',
		hora: '04:46',
		valor: 4.76,
		fechaCreacion: '2022-11-24T22:43:18.023+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 288,
		codigo: '04:47:00',
		hora: '04:47',
		valor: 4.78,
		fechaCreacion: '2022-11-24T22:43:18.292+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 289,
		codigo: '04:48:00',
		hora: '04:48',
		valor: 4.79,
		fechaCreacion: '2022-11-24T22:43:18.580+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 290,
		codigo: '04:49:00',
		hora: '04:49',
		valor: 4.81,
		fechaCreacion: '2022-11-24T22:43:18.843+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 291,
		codigo: '04:50:00',
		hora: '04:50',
		valor: 4.83,
		fechaCreacion: '2022-11-24T22:43:19.117+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 292,
		codigo: '04:51:00',
		hora: '04:51',
		valor: 4.84,
		fechaCreacion: '2022-11-24T22:43:19.390+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 293,
		codigo: '04:52:00',
		hora: '04:52',
		valor: 4.86,
		fechaCreacion: '2022-11-24T22:43:19.655+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 294,
		codigo: '04:53:00',
		hora: '04:53',
		valor: 4.88,
		fechaCreacion: '2022-11-24T22:43:19.920+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 295,
		codigo: '04:54:00',
		hora: '04:54',
		valor: 4.89,
		fechaCreacion: '2022-11-24T22:43:20.186+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 296,
		codigo: '04:55:00',
		hora: '04:55',
		valor: 4.91,
		fechaCreacion: '2022-11-24T22:43:20.455+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 297,
		codigo: '04:56:00',
		hora: '04:56',
		valor: 4.93,
		fechaCreacion: '2022-11-24T22:43:20.731+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 298,
		codigo: '04:57:00',
		hora: '04:57',
		valor: 4.94,
		fechaCreacion: '2022-11-24T22:43:21.010+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 299,
		codigo: '04:58:00',
		hora: '04:58',
		valor: 4.96,
		fechaCreacion: '2022-11-24T22:43:21.277+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 300,
		codigo: '04:59:00',
		hora: '04:59',
		valor: 4.98,
		fechaCreacion: '2022-11-24T22:43:21.547+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 301,
		codigo: '05:00:00',
		hora: '05:00',
		valor: 5.0,
		fechaCreacion: '2022-11-24T22:43:21.811+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 302,
		codigo: '05:01:00',
		hora: '05:01',
		valor: 5.1,
		fechaCreacion: '2022-11-24T22:43:22.089+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 303,
		codigo: '05:02:00',
		hora: '05:02',
		valor: 5.3,
		fechaCreacion: '2022-11-24T22:43:22.362+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 304,
		codigo: '05:03:00',
		hora: '05:03',
		valor: 5.4,
		fechaCreacion: '2022-11-24T22:43:22.640+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 305,
		codigo: '05:04:00',
		hora: '05:04',
		valor: 5.6,
		fechaCreacion: '2022-11-24T22:43:22.921+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 306,
		codigo: '05:05:00',
		hora: '05:05',
		valor: 5.8,
		fechaCreacion: '2022-11-24T22:43:23.205+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 307,
		codigo: '05:06:00',
		hora: '05:06',
		valor: 5.9,
		fechaCreacion: '2022-11-24T22:43:23.476+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 308,
		codigo: '05:07:00',
		hora: '05:07',
		valor: 5.11,
		fechaCreacion: '2022-11-24T22:43:23.751+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 309,
		codigo: '05:08:00',
		hora: '05:08',
		valor: 5.13,
		fechaCreacion: '2022-11-24T22:43:24.016+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 310,
		codigo: '05:09:00',
		hora: '05:09',
		valor: 5.14,
		fechaCreacion: '2022-11-24T22:43:24.281+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 311,
		codigo: '05:10:00',
		hora: '05:10',
		valor: 5.16,
		fechaCreacion: '2022-11-24T22:43:24.580+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 312,
		codigo: '05:11:00',
		hora: '05:11',
		valor: 5.18,
		fechaCreacion: '2022-11-24T22:43:24.881+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 313,
		codigo: '05:12:00',
		hora: '05:12',
		valor: 5.19,
		fechaCreacion: '2022-11-24T22:43:25.151+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 314,
		codigo: '05:13:00',
		hora: '05:13',
		valor: 5.21,
		fechaCreacion: '2022-11-24T22:43:25.415+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 315,
		codigo: '05:14:00',
		hora: '05:14',
		valor: 5.23,
		fechaCreacion: '2022-11-24T22:43:25.677+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 316,
		codigo: '05:15:00',
		hora: '05:15',
		valor: 5.24,
		fechaCreacion: '2022-11-24T22:43:25.940+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 317,
		codigo: '05:16:00',
		hora: '05:16',
		valor: 5.26,
		fechaCreacion: '2022-11-24T22:43:26.207+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 318,
		codigo: '05:17:00',
		hora: '05:17',
		valor: 5.28,
		fechaCreacion: '2022-11-24T22:43:26.472+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 319,
		codigo: '05:18:00',
		hora: '05:18',
		valor: 5.29,
		fechaCreacion: '2022-11-24T22:43:26.746+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 320,
		codigo: '05:19:00',
		hora: '05:19',
		valor: 5.31,
		fechaCreacion: '2022-11-24T22:43:27.006+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 321,
		codigo: '05:20:00',
		hora: '05:20',
		valor: 5.33,
		fechaCreacion: '2022-11-24T22:43:27.286+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 322,
		codigo: '05:21:00',
		hora: '05:21',
		valor: 5.34,
		fechaCreacion: '2022-11-24T22:43:27.551+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 323,
		codigo: '05:20:00',
		hora: '05:20',
		valor: 5.33,
		fechaCreacion: '2022-11-24T22:43:27.817+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 324,
		codigo: '05:23:00',
		hora: '05:23',
		valor: 5.38,
		fechaCreacion: '2022-11-24T22:43:28.086+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 325,
		codigo: '05:24:00',
		hora: '05:24',
		valor: 5.39,
		fechaCreacion: '2022-11-24T22:43:28.357+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 326,
		codigo: '05:25:00',
		hora: '05:25',
		valor: 5.41,
		fechaCreacion: '2022-11-24T22:43:28.635+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 327,
		codigo: '05:26:00',
		hora: '05:26',
		valor: 5.43,
		fechaCreacion: '2022-11-24T22:43:28.906+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 328,
		codigo: '05:27:00',
		hora: '05:27',
		valor: 5.44,
		fechaCreacion: '2022-11-24T22:43:29.182+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 329,
		codigo: '05:28:00',
		hora: '05:28',
		valor: 5.46,
		fechaCreacion: '2022-11-24T22:43:29.456+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 330,
		codigo: '05:29:00',
		hora: '05:29',
		valor: 5.48,
		fechaCreacion: '2022-11-24T22:43:29.731+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 331,
		codigo: '05:30:00',
		hora: '05:30',
		valor: 5.49,
		fechaCreacion: '2022-11-24T22:43:30.018+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 332,
		codigo: '05:31:00',
		hora: '05:31',
		valor: 5.51,
		fechaCreacion: '2022-11-24T22:43:30.287+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 333,
		codigo: '05:32:00',
		hora: '05:32',
		valor: 5.53,
		fechaCreacion: '2022-11-24T22:43:30.552+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 334,
		codigo: '05:33:00',
		hora: '05:33',
		valor: 5.54,
		fechaCreacion: '2022-11-24T22:43:30.827+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 335,
		codigo: '05:34:00',
		hora: '05:34',
		valor: 5.56,
		fechaCreacion: '2022-11-24T22:43:31.104+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 336,
		codigo: '05:35:00',
		hora: '05:35',
		valor: 5.58,
		fechaCreacion: '2022-11-24T22:43:31.378+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 337,
		codigo: '05:36:00',
		hora: '05:36',
		valor: 5.59,
		fechaCreacion: '2022-11-24T22:43:31.648+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 338,
		codigo: '05:37:00',
		hora: '05:37',
		valor: 5.61,
		fechaCreacion: '2022-11-24T22:43:31.918+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 339,
		codigo: '05:38:00',
		hora: '05:38',
		valor: 5.63,
		fechaCreacion: '2022-11-24T22:43:32.188+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 340,
		codigo: '05:39:00',
		hora: '05:39',
		valor: 5.64,
		fechaCreacion: '2022-11-24T22:43:32.450+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 341,
		codigo: '05:40:00',
		hora: '05:40',
		valor: 5.66,
		fechaCreacion: '2022-11-24T22:43:32.717+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 342,
		codigo: '05:41:00',
		hora: '05:41',
		valor: 5.68,
		fechaCreacion: '2022-11-24T22:43:32.990+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 343,
		codigo: '05:42:00',
		hora: '05:42',
		valor: 5.69,
		fechaCreacion: '2022-11-24T22:43:33.259+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 344,
		codigo: '05:43:00',
		hora: '05:43',
		valor: 5.71,
		fechaCreacion: '2022-11-24T22:43:33.527+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 345,
		codigo: '05:44:00',
		hora: '05:44',
		valor: 5.73,
		fechaCreacion: '2022-11-24T22:43:33.798+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 346,
		codigo: '05:45:00',
		hora: '05:45',
		valor: 5.74,
		fechaCreacion: '2022-11-24T22:43:34.063+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 347,
		codigo: '05:46:00',
		hora: '05:46',
		valor: 5.76,
		fechaCreacion: '2022-11-24T22:43:34.333+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 348,
		codigo: '05:47:00',
		hora: '05:47',
		valor: 5.78,
		fechaCreacion: '2022-11-24T22:43:34.602+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 349,
		codigo: '05:48:00',
		hora: '05:48',
		valor: 5.79,
		fechaCreacion: '2022-11-24T22:43:34.869+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 350,
		codigo: '05:49:00',
		hora: '05:49',
		valor: 5.81,
		fechaCreacion: '2022-11-24T22:43:35.137+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 351,
		codigo: '05:50:00',
		hora: '05:50',
		valor: 5.83,
		fechaCreacion: '2022-11-24T22:43:35.413+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 352,
		codigo: '05:51:00',
		hora: '05:51',
		valor: 5.84,
		fechaCreacion: '2022-11-24T22:43:35.680+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 353,
		codigo: '05:52:00',
		hora: '05:52',
		valor: 5.86,
		fechaCreacion: '2022-11-24T22:43:35.945+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 354,
		codigo: '05:53:00',
		hora: '05:53',
		valor: 5.88,
		fechaCreacion: '2022-11-24T22:43:36.210+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 355,
		codigo: '05:54:00',
		hora: '05:54',
		valor: 5.89,
		fechaCreacion: '2022-11-24T22:43:36.480+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 356,
		codigo: '05:55:00',
		hora: '05:55',
		valor: 5.91,
		fechaCreacion: '2022-11-24T22:43:36.745+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 357,
		codigo: '05:56:00',
		hora: '05:56',
		valor: 5.93,
		fechaCreacion: '2022-11-24T22:43:37.026+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 358,
		codigo: '05:57:00',
		hora: '05:57',
		valor: 5.94,
		fechaCreacion: '2022-11-24T22:43:37.299+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 359,
		codigo: '05:58:00',
		hora: '05:58',
		valor: 5.96,
		fechaCreacion: '2022-11-24T22:43:37.569+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 360,
		codigo: '05:59:00',
		hora: '05:59',
		valor: 5.98,
		fechaCreacion: '2022-11-24T22:43:37.848+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 361,
		codigo: '06:00:00',
		hora: '06:00',
		valor: 6.0,
		fechaCreacion: '2022-11-24T22:43:38.112+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 362,
		codigo: '06:01:00',
		hora: '06:01',
		valor: 6.1,
		fechaCreacion: '2022-11-24T22:43:38.375+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 363,
		codigo: '06:02:00',
		hora: '06:02',
		valor: 6.3,
		fechaCreacion: '2022-11-24T22:43:38.651+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 364,
		codigo: '06:03:00',
		hora: '06:03',
		valor: 6.4,
		fechaCreacion: '2022-11-24T22:43:38.910+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 365,
		codigo: '06:04:00',
		hora: '06:04',
		valor: 6.6,
		fechaCreacion: '2022-11-24T22:43:39.197+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 366,
		codigo: '06:05:00',
		hora: '06:05',
		valor: 6.8,
		fechaCreacion: '2022-11-24T22:43:39.471+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 367,
		codigo: '06:06:00',
		hora: '06:06',
		valor: 6.9,
		fechaCreacion: '2022-11-24T22:43:39.745+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 368,
		codigo: '06:07:00',
		hora: '06:07',
		valor: 6.11,
		fechaCreacion: '2022-11-24T22:43:40.004+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 369,
		codigo: '06:08:00',
		hora: '06:08',
		valor: 6.13,
		fechaCreacion: '2022-11-24T22:43:40.271+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 370,
		codigo: '06:09:00',
		hora: '06:09',
		valor: 6.14,
		fechaCreacion: '2022-11-24T22:43:40.536+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 371,
		codigo: '06:10:00',
		hora: '06:10',
		valor: 6.16,
		fechaCreacion: '2022-11-24T22:43:40.806+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 372,
		codigo: '06:11:00',
		hora: '06:11',
		valor: 6.18,
		fechaCreacion: '2022-11-24T22:43:41.076+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 373,
		codigo: '06:12:00',
		hora: '06:12',
		valor: 6.19,
		fechaCreacion: '2022-11-24T22:43:41.343+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 374,
		codigo: '06:13:00',
		hora: '06:13',
		valor: 6.21,
		fechaCreacion: '2022-11-24T22:43:41.612+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 375,
		codigo: '06:14:00',
		hora: '06:14',
		valor: 6.23,
		fechaCreacion: '2022-11-24T22:43:41.887+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 376,
		codigo: '06:15:00',
		hora: '06:15',
		valor: 6.24,
		fechaCreacion: '2022-11-24T22:43:42.156+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 377,
		codigo: '06:16:00',
		hora: '06:16',
		valor: 6.26,
		fechaCreacion: '2022-11-24T22:43:42.436+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 378,
		codigo: '06:17:00',
		hora: '06:17',
		valor: 6.28,
		fechaCreacion: '2022-11-24T22:43:42.704+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 379,
		codigo: '06:18:00',
		hora: '06:18',
		valor: 6.29,
		fechaCreacion: '2022-11-24T22:43:42.980+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 380,
		codigo: '06:19:00',
		hora: '06:19',
		valor: 6.31,
		fechaCreacion: '2022-11-24T22:43:43.256+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 381,
		codigo: '06:20:00',
		hora: '06:20',
		valor: 6.33,
		fechaCreacion: '2022-11-24T22:43:43.533+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 382,
		codigo: '06:21:00',
		hora: '06:21',
		valor: 6.34,
		fechaCreacion: '2022-11-24T22:43:43.800+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 383,
		codigo: '06:20:00',
		hora: '06:20',
		valor: 6.33,
		fechaCreacion: '2022-11-24T22:43:44.067+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 384,
		codigo: '06:23:00',
		hora: '06:23',
		valor: 6.38,
		fechaCreacion: '2022-11-24T22:43:44.343+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 385,
		codigo: '06:24:00',
		hora: '06:24',
		valor: 6.39,
		fechaCreacion: '2022-11-24T22:43:44.628+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 386,
		codigo: '06:25:00',
		hora: '06:25',
		valor: 6.41,
		fechaCreacion: '2022-11-24T22:43:44.907+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 387,
		codigo: '06:26:00',
		hora: '06:26',
		valor: 6.43,
		fechaCreacion: '2022-11-24T22:43:45.169+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 388,
		codigo: '06:27:00',
		hora: '06:27',
		valor: 6.44,
		fechaCreacion: '2022-11-24T22:43:45.437+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 389,
		codigo: '06:28:00',
		hora: '06:28',
		valor: 6.46,
		fechaCreacion: '2022-11-24T22:43:45.716+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 390,
		codigo: '06:29:00',
		hora: '06:29',
		valor: 6.48,
		fechaCreacion: '2022-11-24T22:43:45.988+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 391,
		codigo: '06:30:00',
		hora: '06:30',
		valor: 6.49,
		fechaCreacion: '2022-11-24T22:43:46.275+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 392,
		codigo: '06:31:00',
		hora: '06:31',
		valor: 6.51,
		fechaCreacion: '2022-11-24T22:43:46.560+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 393,
		codigo: '06:32:00',
		hora: '06:32',
		valor: 6.53,
		fechaCreacion: '2022-11-24T22:43:46.827+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 394,
		codigo: '06:33:00',
		hora: '06:33',
		valor: 6.54,
		fechaCreacion: '2022-11-24T22:43:47.092+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 395,
		codigo: '06:34:00',
		hora: '06:34',
		valor: 6.56,
		fechaCreacion: '2022-11-24T22:43:47.377+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 396,
		codigo: '06:35:00',
		hora: '06:35',
		valor: 6.58,
		fechaCreacion: '2022-11-24T22:43:47.641+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 397,
		codigo: '06:36:00',
		hora: '06:36',
		valor: 6.59,
		fechaCreacion: '2022-11-24T22:43:47.922+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 398,
		codigo: '06:37:00',
		hora: '06:37',
		valor: 6.61,
		fechaCreacion: '2022-11-24T22:43:48.206+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 399,
		codigo: '06:38:00',
		hora: '06:38',
		valor: 6.63,
		fechaCreacion: '2022-11-24T22:43:48.485+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 400,
		codigo: '06:39:00',
		hora: '06:39',
		valor: 6.64,
		fechaCreacion: '2022-11-24T22:43:48.747+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 401,
		codigo: '06:40:00',
		hora: '06:40',
		valor: 6.66,
		fechaCreacion: '2022-11-24T22:43:49.012+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 402,
		codigo: '06:41:00',
		hora: '06:41',
		valor: 6.68,
		fechaCreacion: '2022-11-24T22:43:49.288+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 403,
		codigo: '06:42:00',
		hora: '06:42',
		valor: 6.69,
		fechaCreacion: '2022-11-24T22:43:49.554+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 404,
		codigo: '06:43:00',
		hora: '06:43',
		valor: 6.71,
		fechaCreacion: '2022-11-24T22:43:49.826+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 405,
		codigo: '06:44:00',
		hora: '06:44',
		valor: 6.73,
		fechaCreacion: '2022-11-24T22:43:50.114+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 406,
		codigo: '06:45:00',
		hora: '06:45',
		valor: 6.74,
		fechaCreacion: '2022-11-24T22:43:50.377+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 407,
		codigo: '06:46:00',
		hora: '06:46',
		valor: 6.76,
		fechaCreacion: '2022-11-24T22:43:50.642+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 408,
		codigo: '06:47:00',
		hora: '06:47',
		valor: 6.78,
		fechaCreacion: '2022-11-24T22:43:50.912+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 409,
		codigo: '06:48:00',
		hora: '06:48',
		valor: 6.79,
		fechaCreacion: '2022-11-24T22:43:51.191+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 410,
		codigo: '06:49:00',
		hora: '06:49',
		valor: 6.81,
		fechaCreacion: '2022-11-24T22:43:51.478+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 411,
		codigo: '06:50:00',
		hora: '06:50',
		valor: 6.83,
		fechaCreacion: '2022-11-24T22:43:51.753+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 412,
		codigo: '06:51:00',
		hora: '06:51',
		valor: 6.84,
		fechaCreacion: '2022-11-24T22:43:52.017+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 413,
		codigo: '06:52:00',
		hora: '06:52',
		valor: 6.86,
		fechaCreacion: '2022-11-24T22:43:52.281+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 414,
		codigo: '06:53:00',
		hora: '06:53',
		valor: 6.88,
		fechaCreacion: '2022-11-24T22:43:52.558+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 415,
		codigo: '06:54:00',
		hora: '06:54',
		valor: 6.89,
		fechaCreacion: '2022-11-24T22:43:52.833+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 416,
		codigo: '06:55:00',
		hora: '06:55',
		valor: 6.91,
		fechaCreacion: '2022-11-24T22:43:53.118+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 417,
		codigo: '06:56:00',
		hora: '06:56',
		valor: 6.93,
		fechaCreacion: '2022-11-24T22:43:53.391+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 418,
		codigo: '06:57:00',
		hora: '06:57',
		valor: 6.94,
		fechaCreacion: '2022-11-24T22:43:53.656+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 419,
		codigo: '06:58:00',
		hora: '06:58',
		valor: 6.96,
		fechaCreacion: '2022-11-24T22:43:53.921+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 420,
		codigo: '06:59:00',
		hora: '06:59',
		valor: 6.98,
		fechaCreacion: '2022-11-24T22:43:54.187+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 421,
		codigo: '07:00:00',
		hora: '07:00',
		valor: 7.0,
		fechaCreacion: '2022-11-24T22:43:54.464+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 422,
		codigo: '07:01:00',
		hora: '07:01',
		valor: 7.1,
		fechaCreacion: '2022-11-24T22:43:54.723+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 423,
		codigo: '07:02:00',
		hora: '07:02',
		valor: 7.3,
		fechaCreacion: '2022-11-24T22:43:54.990+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 424,
		codigo: '07:03:00',
		hora: '07:03',
		valor: 7.4,
		fechaCreacion: '2022-11-24T22:43:55.259+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 425,
		codigo: '07:04:00',
		hora: '07:04',
		valor: 7.6,
		fechaCreacion: '2022-11-24T22:43:55.526+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 426,
		codigo: '07:05:00',
		hora: '07:05',
		valor: 7.8,
		fechaCreacion: '2022-11-24T22:43:55.791+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 427,
		codigo: '07:06:00',
		hora: '07:06',
		valor: 7.9,
		fechaCreacion: '2022-11-24T22:43:56.056+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 428,
		codigo: '07:07:00',
		hora: '07:07',
		valor: 7.11,
		fechaCreacion: '2022-11-24T22:43:56.320+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 429,
		codigo: '07:08:00',
		hora: '07:08',
		valor: 7.13,
		fechaCreacion: '2022-11-24T22:43:56.585+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 430,
		codigo: '07:09:00',
		hora: '07:09',
		valor: 7.14,
		fechaCreacion: '2022-11-24T22:43:56.855+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 431,
		codigo: '07:10:00',
		hora: '07:10',
		valor: 7.16,
		fechaCreacion: '2022-11-24T22:43:57.121+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 432,
		codigo: '07:11:00',
		hora: '07:11',
		valor: 7.18,
		fechaCreacion: '2022-11-24T22:43:57.404+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 433,
		codigo: '07:12:00',
		hora: '07:12',
		valor: 7.19,
		fechaCreacion: '2022-11-24T22:43:57.676+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 434,
		codigo: '07:13:00',
		hora: '07:13',
		valor: 7.21,
		fechaCreacion: '2022-11-24T22:43:57.941+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 435,
		codigo: '07:14:00',
		hora: '07:14',
		valor: 7.23,
		fechaCreacion: '2022-11-24T22:43:58.211+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 436,
		codigo: '07:15:00',
		hora: '07:15',
		valor: 7.24,
		fechaCreacion: '2022-11-24T22:43:58.480+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 437,
		codigo: '07:16:00',
		hora: '07:16',
		valor: 7.26,
		fechaCreacion: '2022-11-24T22:43:58.751+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 438,
		codigo: '07:17:00',
		hora: '07:17',
		valor: 7.28,
		fechaCreacion: '2022-11-24T22:43:59.014+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 439,
		codigo: '07:18:00',
		hora: '07:18',
		valor: 7.29,
		fechaCreacion: '2022-11-24T22:43:59.287+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 440,
		codigo: '07:19:00',
		hora: '07:19',
		valor: 7.31,
		fechaCreacion: '2022-11-24T22:43:59.552+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 441,
		codigo: '07:20:00',
		hora: '07:20',
		valor: 7.33,
		fechaCreacion: '2022-11-24T22:43:59.827+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 442,
		codigo: '07:21:00',
		hora: '07:21',
		valor: 7.34,
		fechaCreacion: '2022-11-24T22:44:00.090+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 443,
		codigo: '07:20:00',
		hora: '07:20',
		valor: 7.33,
		fechaCreacion: '2022-11-24T22:44:00.366+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 444,
		codigo: '07:23:00',
		hora: '07:23',
		valor: 7.38,
		fechaCreacion: '2022-11-24T22:44:00.651+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 445,
		codigo: '07:24:00',
		hora: '07:24',
		valor: 7.39,
		fechaCreacion: '2022-11-24T22:44:00.916+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 446,
		codigo: '07:25:00',
		hora: '07:25',
		valor: 7.41,
		fechaCreacion: '2022-11-24T22:44:01.181+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 447,
		codigo: '07:26:00',
		hora: '07:26',
		valor: 7.43,
		fechaCreacion: '2022-11-24T22:44:01.460+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 448,
		codigo: '07:27:00',
		hora: '07:27',
		valor: 7.44,
		fechaCreacion: '2022-11-24T22:44:01.731+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 449,
		codigo: '07:28:00',
		hora: '07:28',
		valor: 7.46,
		fechaCreacion: '2022-11-24T22:44:02.001+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 450,
		codigo: '07:29:00',
		hora: '07:29',
		valor: 7.48,
		fechaCreacion: '2022-11-24T22:44:02.277+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 451,
		codigo: '07:30:00',
		hora: '07:30',
		valor: 7.49,
		fechaCreacion: '2022-11-24T22:44:02.551+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 452,
		codigo: '07:31:00',
		hora: '07:31',
		valor: 7.51,
		fechaCreacion: '2022-11-24T22:44:02.831+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 453,
		codigo: '07:32:00',
		hora: '07:32',
		valor: 7.53,
		fechaCreacion: '2022-11-24T22:44:03.109+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 454,
		codigo: '07:33:00',
		hora: '07:33',
		valor: 7.54,
		fechaCreacion: '2022-11-24T22:44:03.392+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 455,
		codigo: '07:34:00',
		hora: '07:34',
		valor: 7.56,
		fechaCreacion: '2022-11-24T22:44:03.666+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 456,
		codigo: '07:35:00',
		hora: '07:35',
		valor: 7.58,
		fechaCreacion: '2022-11-24T22:44:03.931+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 457,
		codigo: '07:36:00',
		hora: '07:36',
		valor: 7.59,
		fechaCreacion: '2022-11-24T22:44:04.209+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 458,
		codigo: '07:37:00',
		hora: '07:37',
		valor: 7.61,
		fechaCreacion: '2022-11-24T22:44:04.484+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 459,
		codigo: '07:38:00',
		hora: '07:38',
		valor: 7.63,
		fechaCreacion: '2022-11-24T22:44:04.761+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 460,
		codigo: '07:39:00',
		hora: '07:39',
		valor: 7.64,
		fechaCreacion: '2022-11-24T22:44:05.026+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 461,
		codigo: '07:40:00',
		hora: '07:40',
		valor: 7.66,
		fechaCreacion: '2022-11-24T22:44:05.290+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 462,
		codigo: '07:41:00',
		hora: '07:41',
		valor: 7.68,
		fechaCreacion: '2022-11-24T22:44:05.550+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 463,
		codigo: '07:42:00',
		hora: '07:42',
		valor: 7.69,
		fechaCreacion: '2022-11-24T22:44:05.817+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 464,
		codigo: '07:43:00',
		hora: '07:43',
		valor: 7.71,
		fechaCreacion: '2022-11-24T22:44:06.091+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 465,
		codigo: '07:44:00',
		hora: '07:44',
		valor: 7.73,
		fechaCreacion: '2022-11-24T22:44:06.379+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 466,
		codigo: '07:45:00',
		hora: '07:45',
		valor: 7.74,
		fechaCreacion: '2022-11-24T22:44:06.641+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 467,
		codigo: '07:46:00',
		hora: '07:46',
		valor: 7.76,
		fechaCreacion: '2022-11-24T22:44:06.906+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 468,
		codigo: '07:47:00',
		hora: '07:47',
		valor: 7.78,
		fechaCreacion: '2022-11-24T22:44:07.180+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 469,
		codigo: '07:48:00',
		hora: '07:48',
		valor: 7.79,
		fechaCreacion: '2022-11-24T22:44:07.454+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 470,
		codigo: '07:49:00',
		hora: '07:49',
		valor: 7.81,
		fechaCreacion: '2022-11-24T22:44:07.731+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 471,
		codigo: '07:50:00',
		hora: '07:50',
		valor: 7.83,
		fechaCreacion: '2022-11-24T22:44:07.996+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 472,
		codigo: '07:51:00',
		hora: '07:51',
		valor: 7.84,
		fechaCreacion: '2022-11-24T22:44:08.266+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 473,
		codigo: '07:52:00',
		hora: '07:52',
		valor: 7.86,
		fechaCreacion: '2022-11-24T22:44:08.530+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 474,
		codigo: '07:53:00',
		hora: '07:53',
		valor: 7.88,
		fechaCreacion: '2022-11-24T22:44:08.796+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 475,
		codigo: '07:54:00',
		hora: '07:54',
		valor: 7.89,
		fechaCreacion: '2022-11-24T22:44:09.076+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 476,
		codigo: '07:55:00',
		hora: '07:55',
		valor: 7.91,
		fechaCreacion: '2022-11-24T22:44:09.358+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 477,
		codigo: '07:56:00',
		hora: '07:56',
		valor: 7.93,
		fechaCreacion: '2022-11-24T22:44:09.625+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 478,
		codigo: '07:57:00',
		hora: '07:57',
		valor: 7.94,
		fechaCreacion: '2022-11-24T22:44:09.902+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 479,
		codigo: '07:58:00',
		hora: '07:58',
		valor: 7.96,
		fechaCreacion: '2022-11-24T22:44:10.171+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 480,
		codigo: '07:59:00',
		hora: '07:59',
		valor: 7.98,
		fechaCreacion: '2022-11-24T22:44:10.436+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 481,
		codigo: '08:00:00',
		hora: '08:00',
		valor: 8.0,
		fechaCreacion: '2022-11-24T22:44:10.721+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 482,
		codigo: '08:01:00',
		hora: '08:01',
		valor: 8.1,
		fechaCreacion: '2022-11-24T22:44:10.987+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 483,
		codigo: '08:02:00',
		hora: '08:02',
		valor: 8.3,
		fechaCreacion: '2022-11-24T22:44:11.256+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 484,
		codigo: '08:03:00',
		hora: '08:03',
		valor: 8.4,
		fechaCreacion: '2022-11-24T22:44:11.526+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 485,
		codigo: '08:04:00',
		hora: '08:04',
		valor: 8.6,
		fechaCreacion: '2022-11-24T22:44:11.797+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 486,
		codigo: '08:05:00',
		hora: '08:05',
		valor: 8.8,
		fechaCreacion: '2022-11-24T22:44:12.060+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 487,
		codigo: '08:06:00',
		hora: '08:06',
		valor: 8.9,
		fechaCreacion: '2022-11-24T22:44:12.337+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 488,
		codigo: '08:07:00',
		hora: '08:07',
		valor: 8.11,
		fechaCreacion: '2022-11-24T22:44:12.604+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 489,
		codigo: '08:08:00',
		hora: '08:08',
		valor: 8.13,
		fechaCreacion: '2022-11-24T22:44:12.877+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 490,
		codigo: '08:09:00',
		hora: '08:09',
		valor: 8.14,
		fechaCreacion: '2022-11-24T22:44:13.148+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 491,
		codigo: '08:10:00',
		hora: '08:10',
		valor: 8.16,
		fechaCreacion: '2022-11-24T22:44:13.421+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 492,
		codigo: '08:11:00',
		hora: '08:11',
		valor: 8.18,
		fechaCreacion: '2022-11-24T22:44:13.696+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 493,
		codigo: '08:12:00',
		hora: '08:12',
		valor: 8.19,
		fechaCreacion: '2022-11-24T22:44:13.966+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 494,
		codigo: '08:13:00',
		hora: '08:13',
		valor: 8.21,
		fechaCreacion: '2022-11-24T22:44:14.234+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 495,
		codigo: '08:14:00',
		hora: '08:14',
		valor: 8.23,
		fechaCreacion: '2022-11-24T22:44:14.517+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 496,
		codigo: '08:15:00',
		hora: '08:15',
		valor: 8.24,
		fechaCreacion: '2022-11-24T22:44:14.797+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 497,
		codigo: '08:16:00',
		hora: '08:16',
		valor: 8.26,
		fechaCreacion: '2022-11-24T22:44:15.066+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 498,
		codigo: '08:17:00',
		hora: '08:17',
		valor: 8.28,
		fechaCreacion: '2022-11-24T22:44:15.344+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 499,
		codigo: '08:18:00',
		hora: '08:18',
		valor: 8.29,
		fechaCreacion: '2022-11-24T22:44:15.620+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 500,
		codigo: '08:19:00',
		hora: '08:19',
		valor: 8.31,
		fechaCreacion: '2022-11-24T22:44:15.889+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 501,
		codigo: '08:20:00',
		hora: '08:20',
		valor: 8.33,
		fechaCreacion: '2022-11-24T22:44:16.169+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 502,
		codigo: '08:21:00',
		hora: '08:21',
		valor: 8.34,
		fechaCreacion: '2022-11-24T22:44:16.440+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 503,
		codigo: '08:20:00',
		hora: '08:20',
		valor: 8.33,
		fechaCreacion: '2022-11-24T22:44:16.714+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 504,
		codigo: '08:23:00',
		hora: '08:23',
		valor: 8.38,
		fechaCreacion: '2022-11-24T22:44:16.988+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 505,
		codigo: '08:24:00',
		hora: '08:24',
		valor: 8.39,
		fechaCreacion: '2022-11-24T22:44:17.257+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 506,
		codigo: '08:25:00',
		hora: '08:25',
		valor: 8.41,
		fechaCreacion: '2022-11-24T22:44:17.563+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 507,
		codigo: '08:26:00',
		hora: '08:26',
		valor: 8.43,
		fechaCreacion: '2022-11-24T22:44:17.843+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 508,
		codigo: '08:27:00',
		hora: '08:27',
		valor: 8.44,
		fechaCreacion: '2022-11-24T22:44:18.126+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 509,
		codigo: '08:28:00',
		hora: '08:28',
		valor: 8.46,
		fechaCreacion: '2022-11-24T22:44:18.397+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 510,
		codigo: '08:29:00',
		hora: '08:29',
		valor: 8.48,
		fechaCreacion: '2022-11-24T22:44:18.677+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 511,
		codigo: '08:30:00',
		hora: '08:30',
		valor: 8.49,
		fechaCreacion: '2022-11-24T22:44:18.942+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 512,
		codigo: '08:31:00',
		hora: '08:31',
		valor: 8.51,
		fechaCreacion: '2022-11-24T22:44:19.212+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 513,
		codigo: '08:32:00',
		hora: '08:32',
		valor: 8.53,
		fechaCreacion: '2022-11-24T22:44:19.490+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 514,
		codigo: '08:33:00',
		hora: '08:33',
		valor: 8.54,
		fechaCreacion: '2022-11-24T22:44:19.761+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 515,
		codigo: '08:34:00',
		hora: '08:34',
		valor: 8.56,
		fechaCreacion: '2022-11-24T22:44:20.038+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 516,
		codigo: '08:35:00',
		hora: '08:35',
		valor: 8.58,
		fechaCreacion: '2022-11-24T22:44:20.307+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 517,
		codigo: '08:36:00',
		hora: '08:36',
		valor: 8.59,
		fechaCreacion: '2022-11-24T22:44:20.575+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 518,
		codigo: '08:37:00',
		hora: '08:37',
		valor: 8.61,
		fechaCreacion: '2022-11-24T22:44:20.842+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 519,
		codigo: '08:38:00',
		hora: '08:38',
		valor: 8.63,
		fechaCreacion: '2022-11-24T22:44:21.111+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 520,
		codigo: '08:39:00',
		hora: '08:39',
		valor: 8.64,
		fechaCreacion: '2022-11-24T22:44:21.397+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 521,
		codigo: '08:40:00',
		hora: '08:40',
		valor: 8.66,
		fechaCreacion: '2022-11-24T22:44:21.676+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 522,
		codigo: '08:41:00',
		hora: '08:41',
		valor: 8.68,
		fechaCreacion: '2022-11-24T22:44:21.948+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 523,
		codigo: '08:42:00',
		hora: '08:42',
		valor: 8.69,
		fechaCreacion: '2022-11-24T22:44:22.210+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 524,
		codigo: '08:43:00',
		hora: '08:43',
		valor: 8.71,
		fechaCreacion: '2022-11-24T22:44:22.477+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 525,
		codigo: '08:44:00',
		hora: '08:44',
		valor: 8.73,
		fechaCreacion: '2022-11-24T22:44:22.752+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 526,
		codigo: '08:45:00',
		hora: '08:45',
		valor: 8.74,
		fechaCreacion: '2022-11-24T22:44:23.096+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 527,
		codigo: '08:46:00',
		hora: '08:46',
		valor: 8.76,
		fechaCreacion: '2022-11-24T22:44:23.367+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 528,
		codigo: '08:47:00',
		hora: '08:47',
		valor: 8.78,
		fechaCreacion: '2022-11-24T22:44:23.633+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 529,
		codigo: '08:48:00',
		hora: '08:48',
		valor: 8.79,
		fechaCreacion: '2022-11-24T22:44:23.896+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 530,
		codigo: '08:49:00',
		hora: '08:49',
		valor: 8.81,
		fechaCreacion: '2022-11-24T22:44:24.165+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 531,
		codigo: '08:50:00',
		hora: '08:50',
		valor: 8.83,
		fechaCreacion: '2022-11-24T22:44:24.453+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 532,
		codigo: '08:51:00',
		hora: '08:51',
		valor: 8.84,
		fechaCreacion: '2022-11-24T22:44:24.723+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 533,
		codigo: '08:52:00',
		hora: '08:52',
		valor: 8.86,
		fechaCreacion: '2022-11-24T22:44:24.986+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 534,
		codigo: '08:53:00',
		hora: '08:53',
		valor: 8.88,
		fechaCreacion: '2022-11-24T22:44:25.253+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 535,
		codigo: '08:54:00',
		hora: '08:54',
		valor: 8.89,
		fechaCreacion: '2022-11-24T22:44:25.520+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 536,
		codigo: '08:55:00',
		hora: '08:55',
		valor: 8.91,
		fechaCreacion: '2022-11-24T22:44:25.790+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 537,
		codigo: '08:56:00',
		hora: '08:56',
		valor: 8.93,
		fechaCreacion: '2022-11-24T22:44:26.050+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 538,
		codigo: '08:57:00',
		hora: '08:57',
		valor: 8.94,
		fechaCreacion: '2022-11-24T22:44:26.316+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 539,
		codigo: '08:58:00',
		hora: '08:58',
		valor: 8.96,
		fechaCreacion: '2022-11-24T22:44:26.587+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 540,
		codigo: '08:59:00',
		hora: '08:59',
		valor: 8.98,
		fechaCreacion: '2022-11-24T22:44:26.855+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 541,
		codigo: '09:00:00',
		hora: '09:00',
		valor: 9.0,
		fechaCreacion: '2022-11-24T22:44:27.123+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 542,
		codigo: '09:01:00',
		hora: '09:01',
		valor: 9.1,
		fechaCreacion: '2022-11-24T22:44:27.396+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 543,
		codigo: '09:02:00',
		hora: '09:02',
		valor: 9.3,
		fechaCreacion: '2022-11-24T22:44:27.668+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 544,
		codigo: '09:03:00',
		hora: '09:03',
		valor: 9.4,
		fechaCreacion: '2022-11-24T22:44:27.932+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 545,
		codigo: '09:04:00',
		hora: '09:04',
		valor: 9.6,
		fechaCreacion: '2022-11-24T22:44:28.230+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 546,
		codigo: '09:05:00',
		hora: '09:05',
		valor: 9.8,
		fechaCreacion: '2022-11-24T22:44:28.490+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 547,
		codigo: '09:06:00',
		hora: '09:06',
		valor: 9.9,
		fechaCreacion: '2022-11-24T22:44:28.756+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 548,
		codigo: '09:07:00',
		hora: '09:07',
		valor: 9.11,
		fechaCreacion: '2022-11-24T22:44:29.034+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 549,
		codigo: '09:08:00',
		hora: '09:08',
		valor: 9.13,
		fechaCreacion: '2022-11-24T22:44:29.309+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 550,
		codigo: '09:09:00',
		hora: '09:09',
		valor: 9.14,
		fechaCreacion: '2022-11-24T22:44:29.590+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 551,
		codigo: '09:10:00',
		hora: '09:10',
		valor: 9.16,
		fechaCreacion: '2022-11-24T22:44:29.855+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 552,
		codigo: '09:11:00',
		hora: '09:11',
		valor: 9.18,
		fechaCreacion: '2022-11-24T22:44:30.122+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 553,
		codigo: '09:12:00',
		hora: '09:12',
		valor: 9.19,
		fechaCreacion: '2022-11-24T22:44:30.390+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 554,
		codigo: '09:13:00',
		hora: '09:13',
		valor: 9.21,
		fechaCreacion: '2022-11-24T22:44:30.667+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 555,
		codigo: '09:14:00',
		hora: '09:14',
		valor: 9.23,
		fechaCreacion: '2022-11-24T22:44:30.931+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 556,
		codigo: '09:15:00',
		hora: '09:15',
		valor: 9.24,
		fechaCreacion: '2022-11-24T22:44:31.213+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 557,
		codigo: '09:16:00',
		hora: '09:16',
		valor: 9.26,
		fechaCreacion: '2022-11-24T22:44:31.484+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 558,
		codigo: '09:17:00',
		hora: '09:17',
		valor: 9.28,
		fechaCreacion: '2022-11-24T22:44:31.756+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 559,
		codigo: '09:18:00',
		hora: '09:18',
		valor: 9.29,
		fechaCreacion: '2022-11-24T22:44:32.030+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 560,
		codigo: '09:19:00',
		hora: '09:19',
		valor: 9.31,
		fechaCreacion: '2022-11-24T22:44:32.295+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 561,
		codigo: '09:20:00',
		hora: '09:20',
		valor: 9.33,
		fechaCreacion: '2022-11-24T22:44:32.592+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 562,
		codigo: '09:21:00',
		hora: '09:21',
		valor: 9.34,
		fechaCreacion: '2022-11-24T22:44:32.859+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 563,
		codigo: '09:20:00',
		hora: '09:20',
		valor: 9.33,
		fechaCreacion: '2022-11-24T22:44:33.139+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 564,
		codigo: '09:23:00',
		hora: '09:23',
		valor: 9.38,
		fechaCreacion: '2022-11-24T22:44:33.405+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 565,
		codigo: '09:24:00',
		hora: '09:24',
		valor: 9.39,
		fechaCreacion: '2022-11-24T22:44:33.676+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 566,
		codigo: '09:25:00',
		hora: '09:25',
		valor: 9.41,
		fechaCreacion: '2022-11-24T22:44:33.940+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 567,
		codigo: '09:26:00',
		hora: '09:26',
		valor: 9.43,
		fechaCreacion: '2022-11-24T22:44:34.202+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 568,
		codigo: '09:27:00',
		hora: '09:27',
		valor: 9.44,
		fechaCreacion: '2022-11-24T22:44:34.466+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 569,
		codigo: '09:28:00',
		hora: '09:28',
		valor: 9.46,
		fechaCreacion: '2022-11-24T22:44:34.735+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 570,
		codigo: '09:29:00',
		hora: '09:29',
		valor: 9.48,
		fechaCreacion: '2022-11-24T22:44:35.006+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 571,
		codigo: '09:30:00',
		hora: '09:30',
		valor: 9.49,
		fechaCreacion: '2022-11-24T22:44:35.289+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 572,
		codigo: '09:31:00',
		hora: '09:31',
		valor: 9.51,
		fechaCreacion: '2022-11-24T22:44:35.562+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 573,
		codigo: '09:32:00',
		hora: '09:32',
		valor: 9.53,
		fechaCreacion: '2022-11-24T22:44:35.836+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 574,
		codigo: '09:33:00',
		hora: '09:33',
		valor: 9.54,
		fechaCreacion: '2022-11-24T22:44:36.106+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 575,
		codigo: '09:34:00',
		hora: '09:34',
		valor: 9.56,
		fechaCreacion: '2022-11-24T22:44:36.376+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 576,
		codigo: '09:35:00',
		hora: '09:35',
		valor: 9.58,
		fechaCreacion: '2022-11-24T22:44:36.645+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 577,
		codigo: '09:36:00',
		hora: '09:36',
		valor: 9.59,
		fechaCreacion: '2022-11-24T22:44:36.917+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 578,
		codigo: '09:37:00',
		hora: '09:37',
		valor: 9.61,
		fechaCreacion: '2022-11-24T22:44:37.192+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 579,
		codigo: '09:38:00',
		hora: '09:38',
		valor: 9.63,
		fechaCreacion: '2022-11-24T22:44:37.484+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 580,
		codigo: '09:39:00',
		hora: '09:39',
		valor: 9.64,
		fechaCreacion: '2022-11-24T22:44:37.757+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 581,
		codigo: '09:40:00',
		hora: '09:40',
		valor: 9.66,
		fechaCreacion: '2022-11-24T22:44:38.027+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 582,
		codigo: '09:41:00',
		hora: '09:41',
		valor: 9.68,
		fechaCreacion: '2022-11-24T22:44:38.293+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 583,
		codigo: '09:42:00',
		hora: '09:42',
		valor: 9.69,
		fechaCreacion: '2022-11-24T22:44:38.577+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 584,
		codigo: '09:43:00',
		hora: '09:43',
		valor: 9.71,
		fechaCreacion: '2022-11-24T22:44:38.846+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 585,
		codigo: '09:44:00',
		hora: '09:44',
		valor: 9.73,
		fechaCreacion: '2022-11-24T22:44:39.122+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 586,
		codigo: '09:45:00',
		hora: '09:45',
		valor: 9.74,
		fechaCreacion: '2022-11-24T22:44:39.391+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 587,
		codigo: '09:46:00',
		hora: '09:46',
		valor: 9.76,
		fechaCreacion: '2022-11-24T22:44:39.667+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 588,
		codigo: '09:47:00',
		hora: '09:47',
		valor: 9.78,
		fechaCreacion: '2022-11-24T22:44:39.940+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 589,
		codigo: '09:48:00',
		hora: '09:48',
		valor: 9.79,
		fechaCreacion: '2022-11-24T22:44:40.228+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 590,
		codigo: '09:49:00',
		hora: '09:49',
		valor: 9.81,
		fechaCreacion: '2022-11-24T22:44:40.501+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 591,
		codigo: '09:50:00',
		hora: '09:50',
		valor: 9.83,
		fechaCreacion: '2022-11-24T22:44:40.780+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 592,
		codigo: '09:51:00',
		hora: '09:51',
		valor: 9.84,
		fechaCreacion: '2022-11-24T22:44:41.051+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 593,
		codigo: '09:52:00',
		hora: '09:52',
		valor: 9.86,
		fechaCreacion: '2022-11-24T22:44:41.320+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 594,
		codigo: '09:53:00',
		hora: '09:53',
		valor: 9.88,
		fechaCreacion: '2022-11-24T22:44:41.590+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 595,
		codigo: '09:54:00',
		hora: '09:54',
		valor: 9.89,
		fechaCreacion: '2022-11-24T22:44:41.870+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 596,
		codigo: '09:55:00',
		hora: '09:55',
		valor: 9.91,
		fechaCreacion: '2022-11-24T22:44:42.146+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 597,
		codigo: '09:56:00',
		hora: '09:56',
		valor: 9.93,
		fechaCreacion: '2022-11-24T22:44:42.411+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 598,
		codigo: '09:57:00',
		hora: '09:57',
		valor: 9.94,
		fechaCreacion: '2022-11-24T22:44:42.681+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 599,
		codigo: '09:58:00',
		hora: '09:58',
		valor: 9.96,
		fechaCreacion: '2022-11-24T22:44:42.947+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 600,
		codigo: '09:59:00',
		hora: '09:59',
		valor: 9.98,
		fechaCreacion: '2022-11-24T22:44:43.225+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 601,
		codigo: '10:00:00',
		hora: '10:00',
		valor: 10.0,
		fechaCreacion: '2022-11-24T22:44:43.489+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 602,
		codigo: '10:01:00',
		hora: '10:01',
		valor: 10.1,
		fechaCreacion: '2022-11-24T22:44:43.757+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 603,
		codigo: '10:02:00',
		hora: '10:02',
		valor: 10.3,
		fechaCreacion: '2022-11-24T22:44:44.030+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 604,
		codigo: '10:03:00',
		hora: '10:03',
		valor: 10.4,
		fechaCreacion: '2022-11-24T22:44:44.296+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 605,
		codigo: '10:04:00',
		hora: '10:04',
		valor: 10.6,
		fechaCreacion: '2022-11-24T22:44:44.562+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 606,
		codigo: '10:05:00',
		hora: '10:05',
		valor: 10.8,
		fechaCreacion: '2022-11-24T22:44:44.822+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 607,
		codigo: '10:06:00',
		hora: '10:06',
		valor: 10.9,
		fechaCreacion: '2022-11-24T22:44:45.106+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 608,
		codigo: '10:07:00',
		hora: '10:07',
		valor: 10.11,
		fechaCreacion: '2022-11-24T22:44:45.380+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 609,
		codigo: '10:08:00',
		hora: '10:08',
		valor: 10.13,
		fechaCreacion: '2022-11-24T22:44:45.655+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 610,
		codigo: '10:09:00',
		hora: '10:09',
		valor: 10.14,
		fechaCreacion: '2022-11-24T22:44:45.920+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 611,
		codigo: '10:10:00',
		hora: '10:10',
		valor: 10.16,
		fechaCreacion: '2022-11-24T22:44:46.203+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 612,
		codigo: '10:11:00',
		hora: '10:11',
		valor: 10.18,
		fechaCreacion: '2022-11-24T22:44:46.486+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 613,
		codigo: '10:12:00',
		hora: '10:12',
		valor: 10.19,
		fechaCreacion: '2022-11-24T22:44:46.758+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 614,
		codigo: '10:13:00',
		hora: '10:13',
		valor: 10.21,
		fechaCreacion: '2022-11-24T22:44:47.027+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 615,
		codigo: '10:14:00',
		hora: '10:14',
		valor: 10.23,
		fechaCreacion: '2022-11-24T22:44:47.302+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 616,
		codigo: '10:15:00',
		hora: '10:15',
		valor: 10.24,
		fechaCreacion: '2022-11-24T22:44:47.566+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 617,
		codigo: '10:16:00',
		hora: '10:16',
		valor: 10.26,
		fechaCreacion: '2022-11-24T22:44:47.832+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 618,
		codigo: '10:17:00',
		hora: '10:17',
		valor: 10.28,
		fechaCreacion: '2022-11-24T22:44:48.103+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 619,
		codigo: '10:18:00',
		hora: '10:18',
		valor: 10.29,
		fechaCreacion: '2022-11-24T22:44:48.370+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 620,
		codigo: '10:19:00',
		hora: '10:19',
		valor: 10.31,
		fechaCreacion: '2022-11-24T22:44:48.635+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 621,
		codigo: '10:20:00',
		hora: '10:20',
		valor: 10.33,
		fechaCreacion: '2022-11-24T22:44:48.906+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 622,
		codigo: '10:21:00',
		hora: '10:21',
		valor: 10.34,
		fechaCreacion: '2022-11-24T22:44:49.186+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 623,
		codigo: '10:20:00',
		hora: '10:20',
		valor: 10.33,
		fechaCreacion: '2022-11-24T22:44:49.465+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 624,
		codigo: '10:23:00',
		hora: '10:23',
		valor: 10.38,
		fechaCreacion: '2022-11-24T22:44:49.732+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 625,
		codigo: '10:24:00',
		hora: '10:24',
		valor: 10.39,
		fechaCreacion: '2022-11-24T22:44:49.995+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 626,
		codigo: '10:25:00',
		hora: '10:25',
		valor: 10.41,
		fechaCreacion: '2022-11-24T22:44:50.260+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 627,
		codigo: '10:26:00',
		hora: '10:26',
		valor: 10.43,
		fechaCreacion: '2022-11-24T22:44:50.528+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 628,
		codigo: '10:27:00',
		hora: '10:27',
		valor: 10.44,
		fechaCreacion: '2022-11-24T22:44:50.799+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 629,
		codigo: '10:28:00',
		hora: '10:28',
		valor: 10.46,
		fechaCreacion: '2022-11-24T22:44:51.061+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 630,
		codigo: '10:29:00',
		hora: '10:29',
		valor: 10.48,
		fechaCreacion: '2022-11-24T22:44:51.326+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 631,
		codigo: '10:30:00',
		hora: '10:30',
		valor: 10.49,
		fechaCreacion: '2022-11-24T22:44:51.610+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 632,
		codigo: '10:31:00',
		hora: '10:31',
		valor: 10.51,
		fechaCreacion: '2022-11-24T22:44:51.889+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 633,
		codigo: '10:32:00',
		hora: '10:32',
		valor: 10.53,
		fechaCreacion: '2022-11-24T22:44:52.151+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 634,
		codigo: '10:33:00',
		hora: '10:33',
		valor: 10.54,
		fechaCreacion: '2022-11-24T22:44:52.417+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 635,
		codigo: '10:34:00',
		hora: '10:34',
		valor: 10.56,
		fechaCreacion: '2022-11-24T22:44:52.729+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 636,
		codigo: '10:35:00',
		hora: '10:35',
		valor: 10.58,
		fechaCreacion: '2022-11-24T22:44:52.992+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 637,
		codigo: '10:36:00',
		hora: '10:36',
		valor: 10.59,
		fechaCreacion: '2022-11-24T22:44:53.264+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 638,
		codigo: '10:37:00',
		hora: '10:37',
		valor: 10.61,
		fechaCreacion: '2022-11-24T22:44:53.554+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 639,
		codigo: '10:38:00',
		hora: '10:38',
		valor: 10.63,
		fechaCreacion: '2022-11-24T22:44:53.829+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 640,
		codigo: '10:39:00',
		hora: '10:39',
		valor: 10.64,
		fechaCreacion: '2022-11-24T22:44:54.101+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 641,
		codigo: '10:40:00',
		hora: '10:40',
		valor: 10.66,
		fechaCreacion: '2022-11-24T22:44:54.367+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 642,
		codigo: '10:41:00',
		hora: '10:41',
		valor: 10.68,
		fechaCreacion: '2022-11-24T22:44:54.638+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 643,
		codigo: '10:42:00',
		hora: '10:42',
		valor: 10.69,
		fechaCreacion: '2022-11-24T22:44:54.907+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 644,
		codigo: '10:43:00',
		hora: '10:43',
		valor: 10.71,
		fechaCreacion: '2022-11-24T22:44:55.184+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 645,
		codigo: '10:44:00',
		hora: '10:44',
		valor: 10.73,
		fechaCreacion: '2022-11-24T22:44:55.463+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 646,
		codigo: '10:45:00',
		hora: '10:45',
		valor: 10.74,
		fechaCreacion: '2022-11-24T22:44:55.733+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 647,
		codigo: '10:46:00',
		hora: '10:46',
		valor: 10.76,
		fechaCreacion: '2022-11-24T22:44:56.005+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 648,
		codigo: '10:47:00',
		hora: '10:47',
		valor: 10.78,
		fechaCreacion: '2022-11-24T22:44:56.273+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 649,
		codigo: '10:48:00',
		hora: '10:48',
		valor: 10.79,
		fechaCreacion: '2022-11-24T22:44:56.549+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 650,
		codigo: '10:49:00',
		hora: '10:49',
		valor: 10.81,
		fechaCreacion: '2022-11-24T22:44:56.826+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 651,
		codigo: '10:50:00',
		hora: '10:50',
		valor: 10.83,
		fechaCreacion: '2022-11-24T22:44:57.109+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 652,
		codigo: '10:51:00',
		hora: '10:51',
		valor: 10.84,
		fechaCreacion: '2022-11-24T22:44:57.377+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 653,
		codigo: '10:52:00',
		hora: '10:52',
		valor: 10.86,
		fechaCreacion: '2022-11-24T22:44:57.650+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 654,
		codigo: '10:53:00',
		hora: '10:53',
		valor: 10.88,
		fechaCreacion: '2022-11-24T22:44:57.912+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 655,
		codigo: '10:54:00',
		hora: '10:54',
		valor: 10.89,
		fechaCreacion: '2022-11-24T22:44:58.182+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 656,
		codigo: '10:55:00',
		hora: '10:55',
		valor: 10.91,
		fechaCreacion: '2022-11-24T22:44:58.455+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 657,
		codigo: '10:56:00',
		hora: '10:56',
		valor: 10.93,
		fechaCreacion: '2022-11-24T22:44:58.731+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 658,
		codigo: '10:57:00',
		hora: '10:57',
		valor: 10.94,
		fechaCreacion: '2022-11-24T22:44:59.011+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 659,
		codigo: '10:58:00',
		hora: '10:58',
		valor: 10.96,
		fechaCreacion: '2022-11-24T22:44:59.282+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 660,
		codigo: '10:59:00',
		hora: '10:59',
		valor: 10.98,
		fechaCreacion: '2022-11-24T22:44:59.547+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 661,
		codigo: '11:00:00',
		hora: '11:00',
		valor: 11.0,
		fechaCreacion: '2022-11-24T22:44:59.830+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 662,
		codigo: '11:01:00',
		hora: '11:01',
		valor: 11.1,
		fechaCreacion: '2022-11-24T22:45:00.097+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 663,
		codigo: '11:02:00',
		hora: '11:02',
		valor: 11.3,
		fechaCreacion: '2022-11-24T22:45:00.397+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 664,
		codigo: '11:03:00',
		hora: '11:03',
		valor: 11.4,
		fechaCreacion: '2022-11-24T22:45:00.665+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 665,
		codigo: '11:04:00',
		hora: '11:04',
		valor: 11.6,
		fechaCreacion: '2022-11-24T22:45:00.940+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 666,
		codigo: '11:05:00',
		hora: '11:05',
		valor: 11.8,
		fechaCreacion: '2022-11-24T22:45:01.213+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 667,
		codigo: '11:06:00',
		hora: '11:06',
		valor: 11.9,
		fechaCreacion: '2022-11-24T22:45:01.505+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 668,
		codigo: '11:07:00',
		hora: '11:07',
		valor: 11.11,
		fechaCreacion: '2022-11-24T22:45:01.779+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 669,
		codigo: '11:08:00',
		hora: '11:08',
		valor: 11.13,
		fechaCreacion: '2022-11-24T22:45:02.057+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 670,
		codigo: '11:09:00',
		hora: '11:09',
		valor: 11.14,
		fechaCreacion: '2022-11-24T22:45:02.334+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 671,
		codigo: '11:10:00',
		hora: '11:10',
		valor: 11.16,
		fechaCreacion: '2022-11-24T22:45:02.610+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 672,
		codigo: '11:11:00',
		hora: '11:11',
		valor: 11.18,
		fechaCreacion: '2022-11-24T22:45:02.880+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 673,
		codigo: '11:12:00',
		hora: '11:12',
		valor: 11.19,
		fechaCreacion: '2022-11-24T22:45:03.152+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 674,
		codigo: '11:13:00',
		hora: '11:13',
		valor: 11.21,
		fechaCreacion: '2022-11-24T22:45:03.433+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 675,
		codigo: '11:14:00',
		hora: '11:14',
		valor: 11.23,
		fechaCreacion: '2022-11-24T22:45:03.711+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 676,
		codigo: '11:15:00',
		hora: '11:15',
		valor: 11.24,
		fechaCreacion: '2022-11-24T22:45:03.982+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 677,
		codigo: '11:16:00',
		hora: '11:16',
		valor: 11.26,
		fechaCreacion: '2022-11-24T22:45:04.268+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 678,
		codigo: '11:17:00',
		hora: '11:17',
		valor: 11.28,
		fechaCreacion: '2022-11-24T22:45:04.543+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 679,
		codigo: '11:18:00',
		hora: '11:18',
		valor: 11.29,
		fechaCreacion: '2022-11-24T22:45:04.822+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 680,
		codigo: '11:19:00',
		hora: '11:19',
		valor: 11.31,
		fechaCreacion: '2022-11-24T22:45:05.115+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 681,
		codigo: '11:20:00',
		hora: '11:20',
		valor: 11.33,
		fechaCreacion: '2022-11-24T22:45:05.392+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 682,
		codigo: '11:21:00',
		hora: '11:21',
		valor: 11.34,
		fechaCreacion: '2022-11-24T22:45:05.682+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 683,
		codigo: '11:20:00',
		hora: '11:20',
		valor: 11.33,
		fechaCreacion: '2022-11-24T22:45:05.952+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 684,
		codigo: '11:23:00',
		hora: '11:23',
		valor: 11.38,
		fechaCreacion: '2022-11-24T22:45:06.230+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 685,
		codigo: '11:24:00',
		hora: '11:24',
		valor: 11.39,
		fechaCreacion: '2022-11-24T22:45:06.492+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 686,
		codigo: '11:25:00',
		hora: '11:25',
		valor: 11.41,
		fechaCreacion: '2022-11-24T22:45:06.767+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 687,
		codigo: '11:26:00',
		hora: '11:26',
		valor: 11.43,
		fechaCreacion: '2022-11-24T22:45:07.053+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 688,
		codigo: '11:27:00',
		hora: '11:27',
		valor: 11.44,
		fechaCreacion: '2022-11-24T22:45:07.327+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 689,
		codigo: '11:28:00',
		hora: '11:28',
		valor: 11.46,
		fechaCreacion: '2022-11-24T22:45:07.610+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 690,
		codigo: '11:29:00',
		hora: '11:29',
		valor: 11.48,
		fechaCreacion: '2022-11-24T22:45:07.887+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 691,
		codigo: '11:30:00',
		hora: '11:30',
		valor: 11.49,
		fechaCreacion: '2022-11-24T22:45:08.151+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 692,
		codigo: '11:31:00',
		hora: '11:31',
		valor: 11.51,
		fechaCreacion: '2022-11-24T22:45:08.423+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 693,
		codigo: '11:32:00',
		hora: '11:32',
		valor: 11.53,
		fechaCreacion: '2022-11-24T22:45:08.697+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 694,
		codigo: '11:33:00',
		hora: '11:33',
		valor: 11.54,
		fechaCreacion: '2022-11-24T22:45:08.967+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 695,
		codigo: '11:34:00',
		hora: '11:34',
		valor: 11.56,
		fechaCreacion: '2022-11-24T22:45:09.240+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 696,
		codigo: '11:35:00',
		hora: '11:35',
		valor: 11.58,
		fechaCreacion: '2022-11-24T22:45:09.507+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 697,
		codigo: '11:36:00',
		hora: '11:36',
		valor: 11.59,
		fechaCreacion: '2022-11-24T22:45:09.782+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 698,
		codigo: '11:37:00',
		hora: '11:37',
		valor: 11.61,
		fechaCreacion: '2022-11-24T22:45:10.060+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 699,
		codigo: '11:38:00',
		hora: '11:38',
		valor: 11.63,
		fechaCreacion: '2022-11-24T22:45:10.334+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 700,
		codigo: '11:39:00',
		hora: '11:39',
		valor: 11.64,
		fechaCreacion: '2022-11-24T22:45:10.600+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 701,
		codigo: '11:40:00',
		hora: '11:40',
		valor: 11.66,
		fechaCreacion: '2022-11-24T22:45:10.872+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 702,
		codigo: '11:41:00',
		hora: '11:41',
		valor: 11.68,
		fechaCreacion: '2022-11-24T22:45:11.144+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 703,
		codigo: '11:42:00',
		hora: '11:42',
		valor: 11.69,
		fechaCreacion: '2022-11-24T22:45:11.411+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 704,
		codigo: '11:43:00',
		hora: '11:43',
		valor: 11.71,
		fechaCreacion: '2022-11-24T22:45:11.676+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 705,
		codigo: '11:44:00',
		hora: '11:44',
		valor: 11.73,
		fechaCreacion: '2022-11-24T22:45:11.942+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 706,
		codigo: '11:45:00',
		hora: '11:45',
		valor: 11.74,
		fechaCreacion: '2022-11-24T22:45:12.221+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 707,
		codigo: '11:46:00',
		hora: '11:46',
		valor: 11.76,
		fechaCreacion: '2022-11-24T22:45:12.488+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 708,
		codigo: '11:47:00',
		hora: '11:47',
		valor: 11.78,
		fechaCreacion: '2022-11-24T22:45:12.765+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 709,
		codigo: '11:48:00',
		hora: '11:48',
		valor: 11.79,
		fechaCreacion: '2022-11-24T22:45:13.039+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 710,
		codigo: '11:49:00',
		hora: '11:49',
		valor: 11.81,
		fechaCreacion: '2022-11-24T22:45:13.307+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 711,
		codigo: '11:50:00',
		hora: '11:50',
		valor: 11.83,
		fechaCreacion: '2022-11-24T22:45:13.588+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 712,
		codigo: '11:51:00',
		hora: '11:51',
		valor: 11.84,
		fechaCreacion: '2022-11-24T22:45:13.863+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 713,
		codigo: '11:52:00',
		hora: '11:52',
		valor: 11.86,
		fechaCreacion: '2022-11-24T22:45:14.134+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 714,
		codigo: '11:53:00',
		hora: '11:53',
		valor: 11.88,
		fechaCreacion: '2022-11-24T22:45:14.411+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 715,
		codigo: '11:54:00',
		hora: '11:54',
		valor: 11.89,
		fechaCreacion: '2022-11-24T22:45:14.694+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 716,
		codigo: '11:55:00',
		hora: '11:55',
		valor: 11.91,
		fechaCreacion: '2022-11-24T22:45:14.957+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 717,
		codigo: '11:56:00',
		hora: '11:56',
		valor: 11.93,
		fechaCreacion: '2022-11-24T22:45:15.224+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 718,
		codigo: '11:57:00',
		hora: '11:57',
		valor: 11.94,
		fechaCreacion: '2022-11-24T22:45:15.499+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 719,
		codigo: '11:58:00',
		hora: '11:58',
		valor: 11.96,
		fechaCreacion: '2022-11-24T22:45:15.771+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 720,
		codigo: '11:59:00',
		hora: '11:59',
		valor: 11.98,
		fechaCreacion: '2022-11-24T22:45:16.041+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 721,
		codigo: '12:00:00',
		hora: '12:00',
		valor: 12.0,
		fechaCreacion: '2022-11-24T22:45:16.305+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 722,
		codigo: '12:01:00',
		hora: '12:01',
		valor: 12.1,
		fechaCreacion: '2022-11-24T22:45:16.588+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 723,
		codigo: '12:02:00',
		hora: '12:02',
		valor: 12.3,
		fechaCreacion: '2022-11-24T22:45:16.851+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 724,
		codigo: '12:03:00',
		hora: '12:03',
		valor: 12.4,
		fechaCreacion: '2022-11-24T22:45:17.128+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 725,
		codigo: '12:04:00',
		hora: '12:04',
		valor: 12.6,
		fechaCreacion: '2022-11-24T22:45:17.397+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 726,
		codigo: '12:05:00',
		hora: '12:05',
		valor: 12.8,
		fechaCreacion: '2022-11-24T22:45:17.664+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 727,
		codigo: '12:06:00',
		hora: '12:06',
		valor: 12.9,
		fechaCreacion: '2022-11-24T22:45:17.931+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 728,
		codigo: '12:07:00',
		hora: '12:07',
		valor: 12.11,
		fechaCreacion: '2022-11-24T22:45:18.199+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 729,
		codigo: '12:08:00',
		hora: '12:08',
		valor: 12.13,
		fechaCreacion: '2022-11-24T22:45:18.473+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 730,
		codigo: '12:09:00',
		hora: '12:09',
		valor: 12.14,
		fechaCreacion: '2022-11-24T22:45:18.737+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 731,
		codigo: '12:10:00',
		hora: '12:10',
		valor: 12.16,
		fechaCreacion: '2022-11-24T22:45:19.005+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 732,
		codigo: '12:11:00',
		hora: '12:11',
		valor: 12.18,
		fechaCreacion: '2022-11-24T22:45:19.283+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 733,
		codigo: '12:12:00',
		hora: '12:12',
		valor: 12.19,
		fechaCreacion: '2022-11-24T22:45:19.562+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 734,
		codigo: '12:13:00',
		hora: '12:13',
		valor: 12.21,
		fechaCreacion: '2022-11-24T22:45:19.834+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 735,
		codigo: '12:14:00',
		hora: '12:14',
		valor: 12.23,
		fechaCreacion: '2022-11-24T22:45:20.116+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 736,
		codigo: '12:15:00',
		hora: '12:15',
		valor: 12.24,
		fechaCreacion: '2022-11-24T22:45:20.395+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 737,
		codigo: '12:16:00',
		hora: '12:16',
		valor: 12.26,
		fechaCreacion: '2022-11-24T22:45:20.662+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 738,
		codigo: '12:17:00',
		hora: '12:17',
		valor: 12.28,
		fechaCreacion: '2022-11-24T22:45:20.944+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 739,
		codigo: '12:18:00',
		hora: '12:18',
		valor: 12.29,
		fechaCreacion: '2022-11-24T22:45:21.224+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 740,
		codigo: '12:19:00',
		hora: '12:19',
		valor: 12.31,
		fechaCreacion: '2022-11-24T22:45:21.500+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 741,
		codigo: '12:20:00',
		hora: '12:20',
		valor: 12.33,
		fechaCreacion: '2022-11-24T22:45:21.771+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 742,
		codigo: '12:21:00',
		hora: '12:21',
		valor: 12.34,
		fechaCreacion: '2022-11-24T22:45:22.044+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 743,
		codigo: '12:20:00',
		hora: '12:20',
		valor: 12.33,
		fechaCreacion: '2022-11-24T22:45:22.316+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 744,
		codigo: '12:23:00',
		hora: '12:23',
		valor: 12.38,
		fechaCreacion: '2022-11-24T22:45:22.582+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 745,
		codigo: '12:24:00',
		hora: '12:24',
		valor: 12.39,
		fechaCreacion: '2022-11-24T22:45:22.863+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 746,
		codigo: '12:25:00',
		hora: '12:25',
		valor: 12.41,
		fechaCreacion: '2022-11-24T22:45:23.131+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 747,
		codigo: '12:26:00',
		hora: '12:26',
		valor: 12.43,
		fechaCreacion: '2022-11-24T22:45:23.397+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 748,
		codigo: '12:27:00',
		hora: '12:27',
		valor: 12.44,
		fechaCreacion: '2022-11-24T22:45:23.663+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 749,
		codigo: '12:28:00',
		hora: '12:28',
		valor: 12.46,
		fechaCreacion: '2022-11-24T22:45:23.937+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 750,
		codigo: '12:29:00',
		hora: '12:29',
		valor: 12.48,
		fechaCreacion: '2022-11-24T22:45:24.224+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 751,
		codigo: '12:30:00',
		hora: '12:30',
		valor: 12.49,
		fechaCreacion: '2022-11-24T22:45:24.489+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 752,
		codigo: '12:31:00',
		hora: '12:31',
		valor: 12.51,
		fechaCreacion: '2022-11-24T22:45:24.771+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 753,
		codigo: '12:32:00',
		hora: '12:32',
		valor: 12.53,
		fechaCreacion: '2022-11-24T22:45:25.047+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 754,
		codigo: '12:33:00',
		hora: '12:33',
		valor: 12.54,
		fechaCreacion: '2022-11-24T22:45:25.319+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 755,
		codigo: '12:34:00',
		hora: '12:34',
		valor: 12.56,
		fechaCreacion: '2022-11-24T22:45:25.602+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 756,
		codigo: '12:35:00',
		hora: '12:35',
		valor: 12.58,
		fechaCreacion: '2022-11-24T22:45:25.888+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 757,
		codigo: '12:36:00',
		hora: '12:36',
		valor: 12.59,
		fechaCreacion: '2022-11-24T22:45:26.155+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 758,
		codigo: '12:37:00',
		hora: '12:37',
		valor: 12.61,
		fechaCreacion: '2022-11-24T22:45:26.435+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 759,
		codigo: '12:38:00',
		hora: '12:38',
		valor: 12.63,
		fechaCreacion: '2022-11-24T22:45:26.697+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 760,
		codigo: '12:39:00',
		hora: '12:39',
		valor: 12.64,
		fechaCreacion: '2022-11-24T22:45:26.965+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 761,
		codigo: '12:40:00',
		hora: '12:40',
		valor: 12.66,
		fechaCreacion: '2022-11-24T22:45:27.252+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 762,
		codigo: '12:41:00',
		hora: '12:41',
		valor: 12.68,
		fechaCreacion: '2022-11-24T22:45:27.532+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 763,
		codigo: '12:42:00',
		hora: '12:42',
		valor: 12.69,
		fechaCreacion: '2022-11-24T22:45:27.801+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 764,
		codigo: '12:43:00',
		hora: '12:43',
		valor: 12.71,
		fechaCreacion: '2022-11-24T22:45:28.078+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 765,
		codigo: '12:44:00',
		hora: '12:44',
		valor: 12.73,
		fechaCreacion: '2022-11-24T22:45:28.356+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 766,
		codigo: '12:45:00',
		hora: '12:45',
		valor: 12.74,
		fechaCreacion: '2022-11-24T22:45:28.647+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 767,
		codigo: '12:46:00',
		hora: '12:46',
		valor: 12.76,
		fechaCreacion: '2022-11-24T22:45:28.918+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 768,
		codigo: '12:47:00',
		hora: '12:47',
		valor: 12.78,
		fechaCreacion: '2022-11-24T22:45:29.198+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 769,
		codigo: '12:48:00',
		hora: '12:48',
		valor: 12.79,
		fechaCreacion: '2022-11-24T22:45:29.465+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 770,
		codigo: '12:49:00',
		hora: '12:49',
		valor: 12.81,
		fechaCreacion: '2022-11-24T22:45:29.739+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 771,
		codigo: '12:50:00',
		hora: '12:50',
		valor: 12.83,
		fechaCreacion: '2022-11-24T22:45:30.018+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 772,
		codigo: '12:51:00',
		hora: '12:51',
		valor: 12.84,
		fechaCreacion: '2022-11-24T22:45:30.296+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 773,
		codigo: '12:52:00',
		hora: '12:52',
		valor: 12.86,
		fechaCreacion: '2022-11-24T22:45:30.569+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 774,
		codigo: '12:53:00',
		hora: '12:53',
		valor: 12.88,
		fechaCreacion: '2022-11-24T22:45:30.841+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 775,
		codigo: '12:54:00',
		hora: '12:54',
		valor: 12.89,
		fechaCreacion: '2022-11-24T22:45:31.104+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 776,
		codigo: '12:55:00',
		hora: '12:55',
		valor: 12.91,
		fechaCreacion: '2022-11-24T22:45:31.391+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 777,
		codigo: '12:56:00',
		hora: '12:56',
		valor: 12.93,
		fechaCreacion: '2022-11-24T22:45:31.655+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 778,
		codigo: '12:57:00',
		hora: '12:57',
		valor: 12.94,
		fechaCreacion: '2022-11-24T22:45:31.930+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 779,
		codigo: '12:58:00',
		hora: '12:58',
		valor: 12.96,
		fechaCreacion: '2022-11-24T22:45:32.195+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 780,
		codigo: '12:59:00',
		hora: '12:59',
		valor: 12.98,
		fechaCreacion: '2022-11-24T22:45:32.476+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 781,
		codigo: '13:00:00',
		hora: '13:00',
		valor: 13.0,
		fechaCreacion: '2022-11-24T22:45:32.751+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 782,
		codigo: '13:01:00',
		hora: '13:01',
		valor: 13.1,
		fechaCreacion: '2022-11-24T22:45:33.039+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 783,
		codigo: '13:02:00',
		hora: '13:02',
		valor: 13.3,
		fechaCreacion: '2022-11-24T22:45:33.305+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 784,
		codigo: '13:03:00',
		hora: '13:03',
		valor: 13.4,
		fechaCreacion: '2022-11-24T22:45:33.572+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 785,
		codigo: '13:04:00',
		hora: '13:04',
		valor: 13.6,
		fechaCreacion: '2022-11-24T22:45:33.935+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 786,
		codigo: '13:05:00',
		hora: '13:05',
		valor: 13.8,
		fechaCreacion: '2022-11-24T22:45:34.300+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 787,
		codigo: '13:06:00',
		hora: '13:06',
		valor: 13.9,
		fechaCreacion: '2022-11-24T22:45:34.661+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 788,
		codigo: '13:07:00',
		hora: '13:07',
		valor: 13.11,
		fechaCreacion: '2022-11-24T22:45:35.015+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 789,
		codigo: '13:08:00',
		hora: '13:08',
		valor: 13.13,
		fechaCreacion: '2022-11-24T22:45:35.370+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 790,
		codigo: '13:09:00',
		hora: '13:09',
		valor: 13.14,
		fechaCreacion: '2022-11-24T22:45:35.739+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 791,
		codigo: '13:10:00',
		hora: '13:10',
		valor: 13.16,
		fechaCreacion: '2022-11-24T22:45:36.098+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 792,
		codigo: '13:11:00',
		hora: '13:11',
		valor: 13.18,
		fechaCreacion: '2022-11-24T22:45:36.456+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 793,
		codigo: '13:12:00',
		hora: '13:12',
		valor: 13.19,
		fechaCreacion: '2022-11-24T22:45:36.810+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 794,
		codigo: '13:13:00',
		hora: '13:13',
		valor: 13.21,
		fechaCreacion: '2022-11-24T22:45:37.180+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 795,
		codigo: '13:14:00',
		hora: '13:14',
		valor: 13.23,
		fechaCreacion: '2022-11-24T22:45:37.534+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 796,
		codigo: '13:15:00',
		hora: '13:15',
		valor: 13.24,
		fechaCreacion: '2022-11-24T22:45:37.896+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 797,
		codigo: '13:16:00',
		hora: '13:16',
		valor: 13.26,
		fechaCreacion: '2022-11-24T22:45:38.245+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 798,
		codigo: '13:17:00',
		hora: '13:17',
		valor: 13.28,
		fechaCreacion: '2022-11-24T22:45:38.604+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 799,
		codigo: '13:18:00',
		hora: '13:18',
		valor: 13.29,
		fechaCreacion: '2022-11-24T22:45:38.973+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 800,
		codigo: '13:19:00',
		hora: '13:19',
		valor: 13.31,
		fechaCreacion: '2022-11-24T22:45:39.321+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 801,
		codigo: '13:20:00',
		hora: '13:20',
		valor: 13.33,
		fechaCreacion: '2022-11-24T22:45:39.682+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 802,
		codigo: '13:21:00',
		hora: '13:21',
		valor: 13.34,
		fechaCreacion: '2022-11-24T22:45:40.041+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 803,
		codigo: '13:20:00',
		hora: '13:20',
		valor: 13.33,
		fechaCreacion: '2022-11-24T22:45:40.396+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 804,
		codigo: '13:23:00',
		hora: '13:23',
		valor: 13.38,
		fechaCreacion: '2022-11-24T22:45:40.757+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 805,
		codigo: '13:24:00',
		hora: '13:24',
		valor: 13.39,
		fechaCreacion: '2022-11-24T22:45:41.112+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 806,
		codigo: '13:25:00',
		hora: '13:25',
		valor: 13.41,
		fechaCreacion: '2022-11-24T22:45:41.469+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 807,
		codigo: '13:26:00',
		hora: '13:26',
		valor: 13.43,
		fechaCreacion: '2022-11-24T22:45:41.819+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 808,
		codigo: '13:27:00',
		hora: '13:27',
		valor: 13.44,
		fechaCreacion: '2022-11-24T22:45:42.180+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 809,
		codigo: '13:28:00',
		hora: '13:28',
		valor: 13.46,
		fechaCreacion: '2022-11-24T22:45:42.541+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 810,
		codigo: '13:29:00',
		hora: '13:29',
		valor: 13.48,
		fechaCreacion: '2022-11-24T22:45:42.902+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 811,
		codigo: '13:30:00',
		hora: '13:30',
		valor: 13.49,
		fechaCreacion: '2022-11-24T22:45:43.256+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 812,
		codigo: '13:31:00',
		hora: '13:31',
		valor: 13.51,
		fechaCreacion: '2022-11-24T22:45:43.631+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 813,
		codigo: '13:32:00',
		hora: '13:32',
		valor: 13.53,
		fechaCreacion: '2022-11-24T22:45:43.986+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 814,
		codigo: '13:33:00',
		hora: '13:33',
		valor: 13.54,
		fechaCreacion: '2022-11-24T22:45:44.341+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 815,
		codigo: '13:34:00',
		hora: '13:34',
		valor: 13.56,
		fechaCreacion: '2022-11-24T22:45:44.700+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 816,
		codigo: '13:35:00',
		hora: '13:35',
		valor: 13.58,
		fechaCreacion: '2022-11-24T22:45:45.060+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 817,
		codigo: '13:36:00',
		hora: '13:36',
		valor: 13.59,
		fechaCreacion: '2022-11-24T22:45:45.421+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 818,
		codigo: '13:37:00',
		hora: '13:37',
		valor: 13.61,
		fechaCreacion: '2022-11-24T22:45:45.790+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 819,
		codigo: '13:38:00',
		hora: '13:38',
		valor: 13.63,
		fechaCreacion: '2022-11-24T22:45:46.130+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 820,
		codigo: '13:39:00',
		hora: '13:39',
		valor: 13.64,
		fechaCreacion: '2022-11-24T22:45:46.495+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 821,
		codigo: '13:40:00',
		hora: '13:40',
		valor: 13.66,
		fechaCreacion: '2022-11-24T22:45:46.846+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 822,
		codigo: '13:41:00',
		hora: '13:41',
		valor: 13.68,
		fechaCreacion: '2022-11-24T22:45:47.195+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 823,
		codigo: '13:42:00',
		hora: '13:42',
		valor: 13.69,
		fechaCreacion: '2022-11-24T22:45:47.553+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 824,
		codigo: '13:43:00',
		hora: '13:43',
		valor: 13.71,
		fechaCreacion: '2022-11-24T22:45:47.912+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 825,
		codigo: '13:44:00',
		hora: '13:44',
		valor: 13.73,
		fechaCreacion: '2022-11-24T22:45:48.271+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 826,
		codigo: '13:45:00',
		hora: '13:45',
		valor: 13.74,
		fechaCreacion: '2022-11-24T22:45:48.626+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 827,
		codigo: '13:46:00',
		hora: '13:46',
		valor: 13.76,
		fechaCreacion: '2022-11-24T22:45:48.982+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 828,
		codigo: '13:47:00',
		hora: '13:47',
		valor: 13.78,
		fechaCreacion: '2022-11-24T22:45:49.337+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 829,
		codigo: '13:48:00',
		hora: '13:48',
		valor: 13.79,
		fechaCreacion: '2022-11-24T22:45:49.702+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 830,
		codigo: '13:49:00',
		hora: '13:49',
		valor: 13.81,
		fechaCreacion: '2022-11-24T22:45:50.071+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 831,
		codigo: '13:50:00',
		hora: '13:50',
		valor: 13.83,
		fechaCreacion: '2022-11-24T22:45:50.431+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 832,
		codigo: '13:51:00',
		hora: '13:51',
		valor: 13.84,
		fechaCreacion: '2022-11-24T22:45:50.827+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 833,
		codigo: '13:52:00',
		hora: '13:52',
		valor: 13.86,
		fechaCreacion: '2022-11-24T22:45:51.187+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 834,
		codigo: '13:53:00',
		hora: '13:53',
		valor: 13.88,
		fechaCreacion: '2022-11-24T22:45:51.549+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 835,
		codigo: '13:54:00',
		hora: '13:54',
		valor: 13.89,
		fechaCreacion: '2022-11-24T22:45:51.901+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 836,
		codigo: '13:55:00',
		hora: '13:55',
		valor: 13.91,
		fechaCreacion: '2022-11-24T22:45:52.264+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 837,
		codigo: '13:56:00',
		hora: '13:56',
		valor: 13.93,
		fechaCreacion: '2022-11-24T22:45:52.651+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 838,
		codigo: '13:57:00',
		hora: '13:57',
		valor: 13.94,
		fechaCreacion: '2022-11-24T22:45:53.013+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 839,
		codigo: '13:58:00',
		hora: '13:58',
		valor: 13.96,
		fechaCreacion: '2022-11-24T22:45:53.371+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 840,
		codigo: '13:59:00',
		hora: '13:59',
		valor: 13.98,
		fechaCreacion: '2022-11-24T22:45:53.731+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 841,
		codigo: '14:00:00',
		hora: '14:00',
		valor: 14.0,
		fechaCreacion: '2022-11-24T22:45:54.077+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 842,
		codigo: '14:01:00',
		hora: '14:01',
		valor: 14.1,
		fechaCreacion: '2022-11-24T22:45:54.427+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 843,
		codigo: '14:02:00',
		hora: '14:02',
		valor: 14.3,
		fechaCreacion: '2022-11-24T22:45:54.780+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 844,
		codigo: '14:03:00',
		hora: '14:03',
		valor: 14.4,
		fechaCreacion: '2022-11-24T22:45:55.132+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 845,
		codigo: '14:04:00',
		hora: '14:04',
		valor: 14.6,
		fechaCreacion: '2022-11-24T22:45:55.487+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 846,
		codigo: '14:05:00',
		hora: '14:05',
		valor: 14.8,
		fechaCreacion: '2022-11-24T22:45:55.847+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 847,
		codigo: '14:06:00',
		hora: '14:06',
		valor: 14.9,
		fechaCreacion: '2022-11-24T22:45:56.194+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 848,
		codigo: '14:07:00',
		hora: '14:07',
		valor: 14.11,
		fechaCreacion: '2022-11-24T22:45:56.549+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 849,
		codigo: '14:08:00',
		hora: '14:08',
		valor: 14.13,
		fechaCreacion: '2022-11-24T22:45:56.910+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 850,
		codigo: '14:09:00',
		hora: '14:09',
		valor: 14.14,
		fechaCreacion: '2022-11-24T22:45:57.272+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 851,
		codigo: '14:10:00',
		hora: '14:10',
		valor: 14.16,
		fechaCreacion: '2022-11-24T22:45:57.631+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 852,
		codigo: '14:11:00',
		hora: '14:11',
		valor: 14.18,
		fechaCreacion: '2022-11-24T22:45:57.988+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 853,
		codigo: '14:12:00',
		hora: '14:12',
		valor: 14.19,
		fechaCreacion: '2022-11-24T22:45:58.335+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 854,
		codigo: '14:13:00',
		hora: '14:13',
		valor: 14.21,
		fechaCreacion: '2022-11-24T22:45:58.690+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 855,
		codigo: '14:14:00',
		hora: '14:14',
		valor: 14.23,
		fechaCreacion: '2022-11-24T22:45:59.049+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 856,
		codigo: '14:15:00',
		hora: '14:15',
		valor: 14.24,
		fechaCreacion: '2022-11-24T22:45:59.410+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 857,
		codigo: '14:16:00',
		hora: '14:16',
		valor: 14.26,
		fechaCreacion: '2022-11-24T22:45:59.765+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 858,
		codigo: '14:17:00',
		hora: '14:17',
		valor: 14.28,
		fechaCreacion: '2022-11-24T22:46:00.129+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 859,
		codigo: '14:18:00',
		hora: '14:18',
		valor: 14.29,
		fechaCreacion: '2022-11-24T22:46:00.486+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 860,
		codigo: '14:19:00',
		hora: '14:19',
		valor: 14.31,
		fechaCreacion: '2022-11-24T22:46:00.854+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 861,
		codigo: '14:20:00',
		hora: '14:20',
		valor: 14.33,
		fechaCreacion: '2022-11-24T22:46:01.206+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 862,
		codigo: '14:21:00',
		hora: '14:21',
		valor: 14.34,
		fechaCreacion: '2022-11-24T22:46:01.580+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 863,
		codigo: '14:20:00',
		hora: '14:20',
		valor: 14.33,
		fechaCreacion: '2022-11-24T22:46:01.942+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 864,
		codigo: '14:23:00',
		hora: '14:23',
		valor: 14.38,
		fechaCreacion: '2022-11-24T22:46:02.296+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 865,
		codigo: '14:24:00',
		hora: '14:24',
		valor: 14.39,
		fechaCreacion: '2022-11-24T22:46:02.691+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 866,
		codigo: '14:25:00',
		hora: '14:25',
		valor: 14.41,
		fechaCreacion: '2022-11-24T22:46:03.064+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 867,
		codigo: '14:26:00',
		hora: '14:26',
		valor: 14.43,
		fechaCreacion: '2022-11-24T22:46:03.421+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 868,
		codigo: '14:27:00',
		hora: '14:27',
		valor: 14.44,
		fechaCreacion: '2022-11-24T22:46:03.776+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 869,
		codigo: '14:28:00',
		hora: '14:28',
		valor: 14.46,
		fechaCreacion: '2022-11-24T22:46:04.145+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 870,
		codigo: '14:29:00',
		hora: '14:29',
		valor: 14.48,
		fechaCreacion: '2022-11-24T22:46:04.510+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 871,
		codigo: '14:30:00',
		hora: '14:30',
		valor: 14.49,
		fechaCreacion: '2022-11-24T22:46:04.857+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 872,
		codigo: '14:31:00',
		hora: '14:31',
		valor: 14.51,
		fechaCreacion: '2022-11-24T22:46:05.217+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 873,
		codigo: '14:32:00',
		hora: '14:32',
		valor: 14.53,
		fechaCreacion: '2022-11-24T22:46:05.576+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 874,
		codigo: '14:33:00',
		hora: '14:33',
		valor: 14.54,
		fechaCreacion: '2022-11-24T22:46:05.956+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 875,
		codigo: '14:34:00',
		hora: '14:34',
		valor: 14.56,
		fechaCreacion: '2022-11-24T22:46:06.314+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 876,
		codigo: '14:35:00',
		hora: '14:35',
		valor: 14.58,
		fechaCreacion: '2022-11-24T22:46:06.672+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 877,
		codigo: '14:36:00',
		hora: '14:36',
		valor: 14.59,
		fechaCreacion: '2022-11-24T22:46:07.041+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 878,
		codigo: '14:37:00',
		hora: '14:37',
		valor: 14.61,
		fechaCreacion: '2022-11-24T22:46:07.414+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 879,
		codigo: '14:38:00',
		hora: '14:38',
		valor: 14.63,
		fechaCreacion: '2022-11-24T22:46:07.775+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 880,
		codigo: '14:39:00',
		hora: '14:39',
		valor: 14.64,
		fechaCreacion: '2022-11-24T22:46:08.135+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 881,
		codigo: '14:40:00',
		hora: '14:40',
		valor: 14.66,
		fechaCreacion: '2022-11-24T22:46:08.487+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 882,
		codigo: '14:41:00',
		hora: '14:41',
		valor: 14.68,
		fechaCreacion: '2022-11-24T22:46:08.842+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 883,
		codigo: '14:42:00',
		hora: '14:42',
		valor: 14.69,
		fechaCreacion: '2022-11-24T22:46:09.199+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 884,
		codigo: '14:43:00',
		hora: '14:43',
		valor: 14.71,
		fechaCreacion: '2022-11-24T22:46:09.556+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 885,
		codigo: '14:44:00',
		hora: '14:44',
		valor: 14.73,
		fechaCreacion: '2022-11-24T22:46:09.917+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 886,
		codigo: '14:45:00',
		hora: '14:45',
		valor: 14.74,
		fechaCreacion: '2022-11-24T22:46:10.276+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 887,
		codigo: '14:46:00',
		hora: '14:46',
		valor: 14.76,
		fechaCreacion: '2022-11-24T22:46:10.632+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 888,
		codigo: '14:47:00',
		hora: '14:47',
		valor: 14.78,
		fechaCreacion: '2022-11-24T22:46:10.985+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 889,
		codigo: '14:48:00',
		hora: '14:48',
		valor: 14.79,
		fechaCreacion: '2022-11-24T22:46:11.350+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 890,
		codigo: '14:49:00',
		hora: '14:49',
		valor: 14.81,
		fechaCreacion: '2022-11-24T22:46:11.716+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 891,
		codigo: '14:50:00',
		hora: '14:50',
		valor: 14.83,
		fechaCreacion: '2022-11-24T22:46:12.075+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 892,
		codigo: '14:51:00',
		hora: '14:51',
		valor: 14.84,
		fechaCreacion: '2022-11-24T22:46:12.429+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 893,
		codigo: '14:52:00',
		hora: '14:52',
		valor: 14.86,
		fechaCreacion: '2022-11-24T22:46:12.792+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 894,
		codigo: '14:53:00',
		hora: '14:53',
		valor: 14.88,
		fechaCreacion: '2022-11-24T22:46:13.175+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 895,
		codigo: '14:54:00',
		hora: '14:54',
		valor: 14.89,
		fechaCreacion: '2022-11-24T22:46:13.535+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 896,
		codigo: '14:55:00',
		hora: '14:55',
		valor: 14.91,
		fechaCreacion: '2022-11-24T22:46:13.899+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 897,
		codigo: '14:56:00',
		hora: '14:56',
		valor: 14.93,
		fechaCreacion: '2022-11-24T22:46:14.262+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 898,
		codigo: '14:57:00',
		hora: '14:57',
		valor: 14.94,
		fechaCreacion: '2022-11-24T22:46:14.615+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 899,
		codigo: '14:58:00',
		hora: '14:58',
		valor: 14.96,
		fechaCreacion: '2022-11-24T22:46:14.975+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 900,
		codigo: '14:59:00',
		hora: '14:59',
		valor: 14.98,
		fechaCreacion: '2022-11-24T22:46:15.334+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 901,
		codigo: '15:00:00',
		hora: '15:00',
		valor: 15.0,
		fechaCreacion: '2022-11-24T22:46:15.686+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 902,
		codigo: '15:01:00',
		hora: '15:01',
		valor: 15.1,
		fechaCreacion: '2022-11-24T22:46:16.040+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 903,
		codigo: '15:02:00',
		hora: '15:02',
		valor: 15.3,
		fechaCreacion: '2022-11-24T22:46:16.395+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 904,
		codigo: '15:03:00',
		hora: '15:03',
		valor: 15.4,
		fechaCreacion: '2022-11-24T22:46:16.751+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 905,
		codigo: '15:04:00',
		hora: '15:04',
		valor: 15.6,
		fechaCreacion: '2022-11-24T22:46:17.132+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 906,
		codigo: '15:05:00',
		hora: '15:05',
		valor: 15.8,
		fechaCreacion: '2022-11-24T22:46:17.498+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 907,
		codigo: '15:06:00',
		hora: '15:06',
		valor: 15.9,
		fechaCreacion: '2022-11-24T22:46:17.857+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 908,
		codigo: '15:07:00',
		hora: '15:07',
		valor: 15.11,
		fechaCreacion: '2022-11-24T22:46:18.220+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 909,
		codigo: '15:08:00',
		hora: '15:08',
		valor: 15.13,
		fechaCreacion: '2022-11-24T22:46:18.587+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 910,
		codigo: '15:09:00',
		hora: '15:09',
		valor: 15.14,
		fechaCreacion: '2022-11-24T22:46:18.941+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 911,
		codigo: '15:10:00',
		hora: '15:10',
		valor: 15.16,
		fechaCreacion: '2022-11-24T22:46:19.335+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 912,
		codigo: '15:11:00',
		hora: '15:11',
		valor: 15.18,
		fechaCreacion: '2022-11-24T22:46:19.696+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 913,
		codigo: '15:12:00',
		hora: '15:12',
		valor: 15.19,
		fechaCreacion: '2022-11-24T22:46:20.053+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 914,
		codigo: '15:13:00',
		hora: '15:13',
		valor: 15.21,
		fechaCreacion: '2022-11-24T22:46:20.400+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 915,
		codigo: '15:14:00',
		hora: '15:14',
		valor: 15.23,
		fechaCreacion: '2022-11-24T22:46:20.760+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 916,
		codigo: '15:15:00',
		hora: '15:15',
		valor: 15.24,
		fechaCreacion: '2022-11-24T22:46:21.107+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 917,
		codigo: '15:16:00',
		hora: '15:16',
		valor: 15.26,
		fechaCreacion: '2022-11-24T22:46:21.467+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 918,
		codigo: '15:17:00',
		hora: '15:17',
		valor: 15.28,
		fechaCreacion: '2022-11-24T22:46:21.824+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 919,
		codigo: '15:18:00',
		hora: '15:18',
		valor: 15.29,
		fechaCreacion: '2022-11-24T22:46:22.203+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 920,
		codigo: '15:19:00',
		hora: '15:19',
		valor: 15.31,
		fechaCreacion: '2022-11-24T22:46:22.551+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 921,
		codigo: '15:20:00',
		hora: '15:20',
		valor: 15.33,
		fechaCreacion: '2022-11-24T22:46:22.916+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 922,
		codigo: '15:21:00',
		hora: '15:21',
		valor: 15.34,
		fechaCreacion: '2022-11-24T22:46:23.274+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 923,
		codigo: '15:20:00',
		hora: '15:20',
		valor: 15.33,
		fechaCreacion: '2022-11-24T22:46:23.632+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 924,
		codigo: '15:23:00',
		hora: '15:23',
		valor: 15.38,
		fechaCreacion: '2022-11-24T22:46:23.992+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 925,
		codigo: '15:24:00',
		hora: '15:24',
		valor: 15.39,
		fechaCreacion: '2022-11-24T22:46:24.348+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 926,
		codigo: '15:25:00',
		hora: '15:25',
		valor: 15.41,
		fechaCreacion: '2022-11-24T22:46:24.701+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 927,
		codigo: '15:26:00',
		hora: '15:26',
		valor: 15.43,
		fechaCreacion: '2022-11-24T22:46:25.075+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 928,
		codigo: '15:27:00',
		hora: '15:27',
		valor: 15.44,
		fechaCreacion: '2022-11-24T22:46:25.432+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 929,
		codigo: '15:28:00',
		hora: '15:28',
		valor: 15.46,
		fechaCreacion: '2022-11-24T22:46:25.790+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 930,
		codigo: '15:29:00',
		hora: '15:29',
		valor: 15.48,
		fechaCreacion: '2022-11-24T22:46:26.145+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 931,
		codigo: '15:30:00',
		hora: '15:30',
		valor: 15.49,
		fechaCreacion: '2022-11-24T22:46:26.506+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 932,
		codigo: '15:31:00',
		hora: '15:31',
		valor: 15.51,
		fechaCreacion: '2022-11-24T22:46:26.869+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 933,
		codigo: '15:32:00',
		hora: '15:32',
		valor: 15.53,
		fechaCreacion: '2022-11-24T22:46:27.237+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 934,
		codigo: '15:33:00',
		hora: '15:33',
		valor: 15.54,
		fechaCreacion: '2022-11-24T22:46:27.599+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 935,
		codigo: '15:34:00',
		hora: '15:34',
		valor: 15.56,
		fechaCreacion: '2022-11-24T22:46:27.955+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 936,
		codigo: '15:35:00',
		hora: '15:35',
		valor: 15.58,
		fechaCreacion: '2022-11-24T22:46:28.310+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 937,
		codigo: '15:36:00',
		hora: '15:36',
		valor: 15.59,
		fechaCreacion: '2022-11-24T22:46:28.684+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 938,
		codigo: '15:37:00',
		hora: '15:37',
		valor: 15.61,
		fechaCreacion: '2022-11-24T22:46:29.057+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 939,
		codigo: '15:38:00',
		hora: '15:38',
		valor: 15.63,
		fechaCreacion: '2022-11-24T22:46:29.414+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 940,
		codigo: '15:39:00',
		hora: '15:39',
		valor: 15.64,
		fechaCreacion: '2022-11-24T22:46:29.774+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 941,
		codigo: '15:40:00',
		hora: '15:40',
		valor: 15.66,
		fechaCreacion: '2022-11-24T22:46:30.131+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 942,
		codigo: '15:41:00',
		hora: '15:41',
		valor: 15.68,
		fechaCreacion: '2022-11-24T22:46:30.487+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 943,
		codigo: '15:42:00',
		hora: '15:42',
		valor: 15.69,
		fechaCreacion: '2022-11-24T22:46:30.852+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 944,
		codigo: '15:43:00',
		hora: '15:43',
		valor: 15.71,
		fechaCreacion: '2022-11-24T22:46:31.215+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 945,
		codigo: '15:44:00',
		hora: '15:44',
		valor: 15.73,
		fechaCreacion: '2022-11-24T22:46:31.567+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 946,
		codigo: '15:45:00',
		hora: '15:45',
		valor: 15.74,
		fechaCreacion: '2022-11-24T22:46:31.938+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 947,
		codigo: '15:46:00',
		hora: '15:46',
		valor: 15.76,
		fechaCreacion: '2022-11-24T22:46:32.286+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 948,
		codigo: '15:47:00',
		hora: '15:47',
		valor: 15.78,
		fechaCreacion: '2022-11-24T22:46:32.638+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 949,
		codigo: '15:48:00',
		hora: '15:48',
		valor: 15.79,
		fechaCreacion: '2022-11-24T22:46:33.000+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 950,
		codigo: '15:49:00',
		hora: '15:49',
		valor: 15.81,
		fechaCreacion: '2022-11-24T22:46:33.354+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 951,
		codigo: '15:50:00',
		hora: '15:50',
		valor: 15.83,
		fechaCreacion: '2022-11-24T22:46:33.712+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 952,
		codigo: '15:51:00',
		hora: '15:51',
		valor: 15.84,
		fechaCreacion: '2022-11-24T22:46:34.077+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 953,
		codigo: '15:52:00',
		hora: '15:52',
		valor: 15.86,
		fechaCreacion: '2022-11-24T22:46:34.429+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 954,
		codigo: '15:53:00',
		hora: '15:53',
		valor: 15.88,
		fechaCreacion: '2022-11-24T22:46:34.782+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 955,
		codigo: '15:54:00',
		hora: '15:54',
		valor: 15.89,
		fechaCreacion: '2022-11-24T22:46:35.134+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 956,
		codigo: '15:55:00',
		hora: '15:55',
		valor: 15.91,
		fechaCreacion: '2022-11-24T22:46:35.486+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 957,
		codigo: '15:56:00',
		hora: '15:56',
		valor: 15.93,
		fechaCreacion: '2022-11-24T22:46:35.840+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 958,
		codigo: '15:57:00',
		hora: '15:57',
		valor: 15.94,
		fechaCreacion: '2022-11-24T22:46:36.194+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 959,
		codigo: '15:58:00',
		hora: '15:58',
		valor: 15.96,
		fechaCreacion: '2022-11-24T22:46:36.553+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 960,
		codigo: '15:59:00',
		hora: '15:59',
		valor: 15.98,
		fechaCreacion: '2022-11-24T22:46:36.912+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 961,
		codigo: '16:00:00',
		hora: '16:00',
		valor: 16.0,
		fechaCreacion: '2022-11-24T22:46:37.292+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 962,
		codigo: '16:01:00',
		hora: '16:01',
		valor: 16.1,
		fechaCreacion: '2022-11-24T22:46:37.647+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 963,
		codigo: '16:02:00',
		hora: '16:02',
		valor: 16.3,
		fechaCreacion: '2022-11-24T22:46:38.018+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 964,
		codigo: '16:03:00',
		hora: '16:03',
		valor: 16.4,
		fechaCreacion: '2022-11-24T22:46:38.383+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 965,
		codigo: '16:04:00',
		hora: '16:04',
		valor: 16.6,
		fechaCreacion: '2022-11-24T22:46:38.761+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 966,
		codigo: '16:05:00',
		hora: '16:05',
		valor: 16.8,
		fechaCreacion: '2022-11-24T22:46:39.152+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 967,
		codigo: '16:06:00',
		hora: '16:06',
		valor: 16.9,
		fechaCreacion: '2022-11-24T22:46:39.507+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 968,
		codigo: '16:07:00',
		hora: '16:07',
		valor: 16.11,
		fechaCreacion: '2022-11-24T22:46:39.868+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 969,
		codigo: '16:08:00',
		hora: '16:08',
		valor: 16.13,
		fechaCreacion: '2022-11-24T22:46:40.219+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 970,
		codigo: '16:09:00',
		hora: '16:09',
		valor: 16.14,
		fechaCreacion: '2022-11-24T22:46:40.591+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 971,
		codigo: '16:10:00',
		hora: '16:10',
		valor: 16.16,
		fechaCreacion: '2022-11-24T22:46:40.951+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 972,
		codigo: '16:11:00',
		hora: '16:11',
		valor: 16.18,
		fechaCreacion: '2022-11-24T22:46:41.303+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 973,
		codigo: '16:12:00',
		hora: '16:12',
		valor: 16.19,
		fechaCreacion: '2022-11-24T22:46:41.663+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 974,
		codigo: '16:13:00',
		hora: '16:13',
		valor: 16.21,
		fechaCreacion: '2022-11-24T22:46:42.026+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 975,
		codigo: '16:14:00',
		hora: '16:14',
		valor: 16.23,
		fechaCreacion: '2022-11-24T22:46:42.386+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 976,
		codigo: '16:15:00',
		hora: '16:15',
		valor: 16.24,
		fechaCreacion: '2022-11-24T22:46:42.754+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 977,
		codigo: '16:16:00',
		hora: '16:16',
		valor: 16.26,
		fechaCreacion: '2022-11-24T22:46:43.133+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 978,
		codigo: '16:17:00',
		hora: '16:17',
		valor: 16.28,
		fechaCreacion: '2022-11-24T22:46:43.502+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 979,
		codigo: '16:18:00',
		hora: '16:18',
		valor: 16.29,
		fechaCreacion: '2022-11-24T22:46:43.876+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 980,
		codigo: '16:19:00',
		hora: '16:19',
		valor: 16.31,
		fechaCreacion: '2022-11-24T22:46:44.222+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 981,
		codigo: '16:20:00',
		hora: '16:20',
		valor: 16.33,
		fechaCreacion: '2022-11-24T22:46:44.581+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 982,
		codigo: '16:21:00',
		hora: '16:21',
		valor: 16.34,
		fechaCreacion: '2022-11-24T22:46:44.946+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 983,
		codigo: '16:20:00',
		hora: '16:20',
		valor: 16.33,
		fechaCreacion: '2022-11-24T22:46:45.309+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 984,
		codigo: '16:23:00',
		hora: '16:23',
		valor: 16.38,
		fechaCreacion: '2022-11-24T22:46:45.665+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 985,
		codigo: '16:24:00',
		hora: '16:24',
		valor: 16.39,
		fechaCreacion: '2022-11-24T22:46:46.023+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 986,
		codigo: '16:25:00',
		hora: '16:25',
		valor: 16.41,
		fechaCreacion: '2022-11-24T22:46:46.389+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 987,
		codigo: '16:26:00',
		hora: '16:26',
		valor: 16.43,
		fechaCreacion: '2022-11-24T22:46:46.751+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 988,
		codigo: '16:27:00',
		hora: '16:27',
		valor: 16.44,
		fechaCreacion: '2022-11-24T22:46:47.116+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 989,
		codigo: '16:28:00',
		hora: '16:28',
		valor: 16.46,
		fechaCreacion: '2022-11-24T22:46:47.468+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 990,
		codigo: '16:29:00',
		hora: '16:29',
		valor: 16.48,
		fechaCreacion: '2022-11-24T22:46:47.819+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 991,
		codigo: '16:30:00',
		hora: '16:30',
		valor: 16.49,
		fechaCreacion: '2022-11-24T22:46:48.170+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 992,
		codigo: '16:31:00',
		hora: '16:31',
		valor: 16.51,
		fechaCreacion: '2022-11-24T22:46:48.530+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 993,
		codigo: '16:32:00',
		hora: '16:32',
		valor: 16.53,
		fechaCreacion: '2022-11-24T22:46:48.886+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 994,
		codigo: '16:33:00',
		hora: '16:33',
		valor: 16.54,
		fechaCreacion: '2022-11-24T22:46:49.247+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 995,
		codigo: '16:34:00',
		hora: '16:34',
		valor: 16.56,
		fechaCreacion: '2022-11-24T22:46:49.615+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 996,
		codigo: '16:35:00',
		hora: '16:35',
		valor: 16.58,
		fechaCreacion: '2022-11-24T22:46:49.992+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 997,
		codigo: '16:36:00',
		hora: '16:36',
		valor: 16.59,
		fechaCreacion: '2022-11-24T22:46:50.360+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 998,
		codigo: '16:37:00',
		hora: '16:37',
		valor: 16.61,
		fechaCreacion: '2022-11-24T22:46:50.725+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 999,
		codigo: '16:38:00',
		hora: '16:38',
		valor: 16.63,
		fechaCreacion: '2022-11-24T22:46:51.077+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1000,
		codigo: '16:39:00',
		hora: '16:39',
		valor: 16.64,
		fechaCreacion: '2022-11-24T22:46:51.437+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1001,
		codigo: '16:40:00',
		hora: '16:40',
		valor: 16.66,
		fechaCreacion: '2022-11-24T22:46:51.796+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1002,
		codigo: '16:41:00',
		hora: '16:41',
		valor: 16.68,
		fechaCreacion: '2022-11-24T22:46:52.154+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1003,
		codigo: '16:42:00',
		hora: '16:42',
		valor: 16.69,
		fechaCreacion: '2022-11-24T22:46:52.515+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1004,
		codigo: '16:43:00',
		hora: '16:43',
		valor: 16.71,
		fechaCreacion: '2022-11-24T22:46:52.867+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1005,
		codigo: '16:44:00',
		hora: '16:44',
		valor: 16.73,
		fechaCreacion: '2022-11-24T22:46:53.229+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1006,
		codigo: '16:45:00',
		hora: '16:45',
		valor: 16.74,
		fechaCreacion: '2022-11-24T22:46:53.587+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1007,
		codigo: '16:46:00',
		hora: '16:46',
		valor: 16.76,
		fechaCreacion: '2022-11-24T22:46:53.957+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1008,
		codigo: '16:47:00',
		hora: '16:47',
		valor: 16.78,
		fechaCreacion: '2022-11-24T22:46:54.318+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1009,
		codigo: '16:48:00',
		hora: '16:48',
		valor: 16.79,
		fechaCreacion: '2022-11-24T22:46:54.667+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1010,
		codigo: '16:49:00',
		hora: '16:49',
		valor: 16.81,
		fechaCreacion: '2022-11-24T22:46:55.022+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1011,
		codigo: '16:50:00',
		hora: '16:50',
		valor: 16.83,
		fechaCreacion: '2022-11-24T22:46:55.392+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1012,
		codigo: '16:51:00',
		hora: '16:51',
		valor: 16.84,
		fechaCreacion: '2022-11-24T22:46:55.747+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1013,
		codigo: '16:52:00',
		hora: '16:52',
		valor: 16.86,
		fechaCreacion: '2022-11-24T22:46:56.145+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1014,
		codigo: '16:53:00',
		hora: '16:53',
		valor: 16.88,
		fechaCreacion: '2022-11-24T22:46:56.512+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1015,
		codigo: '16:54:00',
		hora: '16:54',
		valor: 16.89,
		fechaCreacion: '2022-11-24T22:46:56.875+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1016,
		codigo: '16:55:00',
		hora: '16:55',
		valor: 16.91,
		fechaCreacion: '2022-11-24T22:46:57.229+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1017,
		codigo: '16:56:00',
		hora: '16:56',
		valor: 16.93,
		fechaCreacion: '2022-11-24T22:46:57.591+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1018,
		codigo: '16:57:00',
		hora: '16:57',
		valor: 16.94,
		fechaCreacion: '2022-11-24T22:46:57.954+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1019,
		codigo: '16:58:00',
		hora: '16:58',
		valor: 16.96,
		fechaCreacion: '2022-11-24T22:46:58.319+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1020,
		codigo: '16:59:00',
		hora: '16:59',
		valor: 16.98,
		fechaCreacion: '2022-11-24T22:46:58.659+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1021,
		codigo: '17:00:00',
		hora: '17:00',
		valor: 17.0,
		fechaCreacion: '2022-11-24T22:46:59.028+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1022,
		codigo: '17:01:00',
		hora: '17:01',
		valor: 17.1,
		fechaCreacion: '2022-11-24T22:46:59.380+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1023,
		codigo: '17:02:00',
		hora: '17:02',
		valor: 17.3,
		fechaCreacion: '2022-11-24T22:46:59.749+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1024,
		codigo: '17:03:00',
		hora: '17:03',
		valor: 17.4,
		fechaCreacion: '2022-11-24T22:47:00.109+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1025,
		codigo: '17:04:00',
		hora: '17:04',
		valor: 17.6,
		fechaCreacion: '2022-11-24T22:47:00.470+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1026,
		codigo: '17:05:00',
		hora: '17:05',
		valor: 17.8,
		fechaCreacion: '2022-11-24T22:47:00.838+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1027,
		codigo: '17:06:00',
		hora: '17:06',
		valor: 17.9,
		fechaCreacion: '2022-11-24T22:47:01.194+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1028,
		codigo: '17:07:00',
		hora: '17:07',
		valor: 17.11,
		fechaCreacion: '2022-11-24T22:47:01.547+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1029,
		codigo: '17:08:00',
		hora: '17:08',
		valor: 17.13,
		fechaCreacion: '2022-11-24T22:47:01.904+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1030,
		codigo: '17:09:00',
		hora: '17:09',
		valor: 17.14,
		fechaCreacion: '2022-11-24T22:47:02.260+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1031,
		codigo: '17:10:00',
		hora: '17:10',
		valor: 17.16,
		fechaCreacion: '2022-11-24T22:47:02.630+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1032,
		codigo: '17:11:00',
		hora: '17:11',
		valor: 17.18,
		fechaCreacion: '2022-11-24T22:47:03.001+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1033,
		codigo: '17:12:00',
		hora: '17:12',
		valor: 17.19,
		fechaCreacion: '2022-11-24T22:47:03.361+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1034,
		codigo: '17:13:00',
		hora: '17:13',
		valor: 17.21,
		fechaCreacion: '2022-11-24T22:47:03.715+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1035,
		codigo: '17:14:00',
		hora: '17:14',
		valor: 17.23,
		fechaCreacion: '2022-11-24T22:47:04.067+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1036,
		codigo: '17:15:00',
		hora: '17:15',
		valor: 17.24,
		fechaCreacion: '2022-11-24T22:47:04.427+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1037,
		codigo: '17:16:00',
		hora: '17:16',
		valor: 17.26,
		fechaCreacion: '2022-11-24T22:47:04.798+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1038,
		codigo: '17:17:00',
		hora: '17:17',
		valor: 17.28,
		fechaCreacion: '2022-11-24T22:47:05.147+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1039,
		codigo: '17:18:00',
		hora: '17:18',
		valor: 17.29,
		fechaCreacion: '2022-11-24T22:47:05.505+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1040,
		codigo: '17:19:00',
		hora: '17:19',
		valor: 17.31,
		fechaCreacion: '2022-11-24T22:47:05.851+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1041,
		codigo: '17:20:00',
		hora: '17:20',
		valor: 17.33,
		fechaCreacion: '2022-11-24T22:47:06.210+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1042,
		codigo: '17:21:00',
		hora: '17:21',
		valor: 17.34,
		fechaCreacion: '2022-11-24T22:47:06.566+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1043,
		codigo: '17:20:00',
		hora: '17:20',
		valor: 17.33,
		fechaCreacion: '2022-11-24T22:47:06.923+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1044,
		codigo: '17:23:00',
		hora: '17:23',
		valor: 17.38,
		fechaCreacion: '2022-11-24T22:47:07.289+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1045,
		codigo: '17:24:00',
		hora: '17:24',
		valor: 17.39,
		fechaCreacion: '2022-11-24T22:47:07.636+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1046,
		codigo: '17:25:00',
		hora: '17:25',
		valor: 17.41,
		fechaCreacion: '2022-11-24T22:47:07.990+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1047,
		codigo: '17:26:00',
		hora: '17:26',
		valor: 17.43,
		fechaCreacion: '2022-11-24T22:47:08.350+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1048,
		codigo: '17:27:00',
		hora: '17:27',
		valor: 17.44,
		fechaCreacion: '2022-11-24T22:47:08.715+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1049,
		codigo: '17:28:00',
		hora: '17:28',
		valor: 17.46,
		fechaCreacion: '2022-11-24T22:47:09.077+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1050,
		codigo: '17:29:00',
		hora: '17:29',
		valor: 17.48,
		fechaCreacion: '2022-11-24T22:47:09.421+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1051,
		codigo: '17:30:00',
		hora: '17:30',
		valor: 17.49,
		fechaCreacion: '2022-11-24T22:47:09.776+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1052,
		codigo: '17:31:00',
		hora: '17:31',
		valor: 17.51,
		fechaCreacion: '2022-11-24T22:47:10.145+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1053,
		codigo: '17:32:00',
		hora: '17:32',
		valor: 17.53,
		fechaCreacion: '2022-11-24T22:47:10.509+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1054,
		codigo: '17:33:00',
		hora: '17:33',
		valor: 17.54,
		fechaCreacion: '2022-11-24T22:47:10.865+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1055,
		codigo: '17:34:00',
		hora: '17:34',
		valor: 17.56,
		fechaCreacion: '2022-11-24T22:47:11.225+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1056,
		codigo: '17:35:00',
		hora: '17:35',
		valor: 17.58,
		fechaCreacion: '2022-11-24T22:47:11.573+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1057,
		codigo: '17:36:00',
		hora: '17:36',
		valor: 17.59,
		fechaCreacion: '2022-11-24T22:47:11.939+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1058,
		codigo: '17:37:00',
		hora: '17:37',
		valor: 17.61,
		fechaCreacion: '2022-11-24T22:47:12.289+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1059,
		codigo: '17:38:00',
		hora: '17:38',
		valor: 17.63,
		fechaCreacion: '2022-11-24T22:47:12.645+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1060,
		codigo: '17:39:00',
		hora: '17:39',
		valor: 17.64,
		fechaCreacion: '2022-11-24T22:47:12.993+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1061,
		codigo: '17:40:00',
		hora: '17:40',
		valor: 17.66,
		fechaCreacion: '2022-11-24T22:47:13.352+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1062,
		codigo: '17:41:00',
		hora: '17:41',
		valor: 17.68,
		fechaCreacion: '2022-11-24T22:47:13.726+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1063,
		codigo: '17:42:00',
		hora: '17:42',
		valor: 17.69,
		fechaCreacion: '2022-11-24T22:47:14.105+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1064,
		codigo: '17:43:00',
		hora: '17:43',
		valor: 17.71,
		fechaCreacion: '2022-11-24T22:47:14.455+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1065,
		codigo: '17:44:00',
		hora: '17:44',
		valor: 17.73,
		fechaCreacion: '2022-11-24T22:47:14.829+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1066,
		codigo: '17:45:00',
		hora: '17:45',
		valor: 17.74,
		fechaCreacion: '2022-11-24T22:47:15.189+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1067,
		codigo: '17:46:00',
		hora: '17:46',
		valor: 17.76,
		fechaCreacion: '2022-11-24T22:47:15.550+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1068,
		codigo: '17:47:00',
		hora: '17:47',
		valor: 17.78,
		fechaCreacion: '2022-11-24T22:47:15.904+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1069,
		codigo: '17:48:00',
		hora: '17:48',
		valor: 17.79,
		fechaCreacion: '2022-11-24T22:47:16.259+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1070,
		codigo: '17:49:00',
		hora: '17:49',
		valor: 17.81,
		fechaCreacion: '2022-11-24T22:47:16.620+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1071,
		codigo: '17:50:00',
		hora: '17:50',
		valor: 17.83,
		fechaCreacion: '2022-11-24T22:47:16.984+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1072,
		codigo: '17:51:00',
		hora: '17:51',
		valor: 17.84,
		fechaCreacion: '2022-11-24T22:47:17.340+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1073,
		codigo: '17:52:00',
		hora: '17:52',
		valor: 17.86,
		fechaCreacion: '2022-11-24T22:47:17.705+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1074,
		codigo: '17:53:00',
		hora: '17:53',
		valor: 17.88,
		fechaCreacion: '2022-11-24T22:47:18.066+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1075,
		codigo: '17:54:00',
		hora: '17:54',
		valor: 17.89,
		fechaCreacion: '2022-11-24T22:47:18.426+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1076,
		codigo: '17:55:00',
		hora: '17:55',
		valor: 17.91,
		fechaCreacion: '2022-11-24T22:47:18.784+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1077,
		codigo: '17:56:00',
		hora: '17:56',
		valor: 17.93,
		fechaCreacion: '2022-11-24T22:47:19.174+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1078,
		codigo: '17:57:00',
		hora: '17:57',
		valor: 17.94,
		fechaCreacion: '2022-11-24T22:47:19.525+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1079,
		codigo: '17:58:00',
		hora: '17:58',
		valor: 17.96,
		fechaCreacion: '2022-11-24T22:47:19.882+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1080,
		codigo: '17:59:00',
		hora: '17:59',
		valor: 17.98,
		fechaCreacion: '2022-11-24T22:47:20.240+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1081,
		codigo: '18:00:00',
		hora: '18:00',
		valor: 18.0,
		fechaCreacion: '2022-11-24T22:47:20.594+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1082,
		codigo: '18:01:00',
		hora: '18:01',
		valor: 18.1,
		fechaCreacion: '2022-11-24T22:47:20.960+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1083,
		codigo: '18:02:00',
		hora: '18:02',
		valor: 18.3,
		fechaCreacion: '2022-11-24T22:47:21.329+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1084,
		codigo: '18:03:00',
		hora: '18:03',
		valor: 18.4,
		fechaCreacion: '2022-11-24T22:47:21.684+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1085,
		codigo: '18:04:00',
		hora: '18:04',
		valor: 18.6,
		fechaCreacion: '2022-11-24T22:47:22.052+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1086,
		codigo: '18:05:00',
		hora: '18:05',
		valor: 18.8,
		fechaCreacion: '2022-11-24T22:47:22.394+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1087,
		codigo: '18:06:00',
		hora: '18:06',
		valor: 18.9,
		fechaCreacion: '2022-11-24T22:47:22.756+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1088,
		codigo: '18:07:00',
		hora: '18:07',
		valor: 18.11,
		fechaCreacion: '2022-11-24T22:47:23.110+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1089,
		codigo: '18:08:00',
		hora: '18:08',
		valor: 18.13,
		fechaCreacion: '2022-11-24T22:47:23.465+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1090,
		codigo: '18:09:00',
		hora: '18:09',
		valor: 18.14,
		fechaCreacion: '2022-11-24T22:47:23.826+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1091,
		codigo: '18:10:00',
		hora: '18:10',
		valor: 18.16,
		fechaCreacion: '2022-11-24T22:47:24.183+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1092,
		codigo: '18:11:00',
		hora: '18:11',
		valor: 18.18,
		fechaCreacion: '2022-11-24T22:47:24.544+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1093,
		codigo: '18:12:00',
		hora: '18:12',
		valor: 18.19,
		fechaCreacion: '2022-11-24T22:47:24.932+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1094,
		codigo: '18:13:00',
		hora: '18:13',
		valor: 18.21,
		fechaCreacion: '2022-11-24T22:47:25.294+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1095,
		codigo: '18:14:00',
		hora: '18:14',
		valor: 18.23,
		fechaCreacion: '2022-11-24T22:47:25.657+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1096,
		codigo: '18:15:00',
		hora: '18:15',
		valor: 18.24,
		fechaCreacion: '2022-11-24T22:47:26.006+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1097,
		codigo: '18:16:00',
		hora: '18:16',
		valor: 18.26,
		fechaCreacion: '2022-11-24T22:47:26.366+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1098,
		codigo: '18:17:00',
		hora: '18:17',
		valor: 18.28,
		fechaCreacion: '2022-11-24T22:47:26.721+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1099,
		codigo: '18:18:00',
		hora: '18:18',
		valor: 18.29,
		fechaCreacion: '2022-11-24T22:47:27.086+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1100,
		codigo: '18:19:00',
		hora: '18:19',
		valor: 18.31,
		fechaCreacion: '2022-11-24T22:47:27.441+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1101,
		codigo: '18:20:00',
		hora: '18:20',
		valor: 18.33,
		fechaCreacion: '2022-11-24T22:47:27.804+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1102,
		codigo: '18:21:00',
		hora: '18:21',
		valor: 18.34,
		fechaCreacion: '2022-11-24T22:47:28.159+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1103,
		codigo: '18:20:00',
		hora: '18:20',
		valor: 18.33,
		fechaCreacion: '2022-11-24T22:47:28.511+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1104,
		codigo: '18:23:00',
		hora: '18:23',
		valor: 18.38,
		fechaCreacion: '2022-11-24T22:47:28.877+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1105,
		codigo: '18:24:00',
		hora: '18:24',
		valor: 18.39,
		fechaCreacion: '2022-11-24T22:47:29.245+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1106,
		codigo: '18:25:00',
		hora: '18:25',
		valor: 18.41,
		fechaCreacion: '2022-11-24T22:47:29.605+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1107,
		codigo: '18:26:00',
		hora: '18:26',
		valor: 18.43,
		fechaCreacion: '2022-11-24T22:47:29.983+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1108,
		codigo: '18:27:00',
		hora: '18:27',
		valor: 18.44,
		fechaCreacion: '2022-11-24T22:47:30.360+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1109,
		codigo: '18:28:00',
		hora: '18:28',
		valor: 18.46,
		fechaCreacion: '2022-11-24T22:47:30.731+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1110,
		codigo: '18:29:00',
		hora: '18:29',
		valor: 18.48,
		fechaCreacion: '2022-11-24T22:47:31.116+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1111,
		codigo: '18:30:00',
		hora: '18:30',
		valor: 18.49,
		fechaCreacion: '2022-11-24T22:47:31.492+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1112,
		codigo: '18:31:00',
		hora: '18:31',
		valor: 18.51,
		fechaCreacion: '2022-11-24T22:47:31.878+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1113,
		codigo: '18:32:00',
		hora: '18:32',
		valor: 18.53,
		fechaCreacion: '2022-11-24T22:47:32.231+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1114,
		codigo: '18:33:00',
		hora: '18:33',
		valor: 18.54,
		fechaCreacion: '2022-11-24T22:47:32.597+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1115,
		codigo: '18:34:00',
		hora: '18:34',
		valor: 18.56,
		fechaCreacion: '2022-11-24T22:47:32.966+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1116,
		codigo: '18:35:00',
		hora: '18:35',
		valor: 18.58,
		fechaCreacion: '2022-11-24T22:47:33.355+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1117,
		codigo: '18:36:00',
		hora: '18:36',
		valor: 18.59,
		fechaCreacion: '2022-11-24T22:47:33.716+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1118,
		codigo: '18:37:00',
		hora: '18:37',
		valor: 18.61,
		fechaCreacion: '2022-11-24T22:47:34.075+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1119,
		codigo: '18:38:00',
		hora: '18:38',
		valor: 18.63,
		fechaCreacion: '2022-11-24T22:47:34.432+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1120,
		codigo: '18:39:00',
		hora: '18:39',
		valor: 18.64,
		fechaCreacion: '2022-11-24T22:47:34.790+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1121,
		codigo: '18:40:00',
		hora: '18:40',
		valor: 18.66,
		fechaCreacion: '2022-11-24T22:47:35.164+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1122,
		codigo: '18:41:00',
		hora: '18:41',
		valor: 18.68,
		fechaCreacion: '2022-11-24T22:47:35.521+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1123,
		codigo: '18:42:00',
		hora: '18:42',
		valor: 18.69,
		fechaCreacion: '2022-11-24T22:47:35.890+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1124,
		codigo: '18:43:00',
		hora: '18:43',
		valor: 18.71,
		fechaCreacion: '2022-11-24T22:47:36.246+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1125,
		codigo: '18:44:00',
		hora: '18:44',
		valor: 18.73,
		fechaCreacion: '2022-11-24T22:47:36.602+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1126,
		codigo: '18:45:00',
		hora: '18:45',
		valor: 18.74,
		fechaCreacion: '2022-11-24T22:47:36.956+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1127,
		codigo: '18:46:00',
		hora: '18:46',
		valor: 18.76,
		fechaCreacion: '2022-11-24T22:47:37.311+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1128,
		codigo: '18:47:00',
		hora: '18:47',
		valor: 18.78,
		fechaCreacion: '2022-11-24T22:47:37.684+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1129,
		codigo: '18:48:00',
		hora: '18:48',
		valor: 18.79,
		fechaCreacion: '2022-11-24T22:47:38.030+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1130,
		codigo: '18:49:00',
		hora: '18:49',
		valor: 18.81,
		fechaCreacion: '2022-11-24T22:47:38.390+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1131,
		codigo: '18:50:00',
		hora: '18:50',
		valor: 18.83,
		fechaCreacion: '2022-11-24T22:47:38.741+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1132,
		codigo: '18:51:00',
		hora: '18:51',
		valor: 18.84,
		fechaCreacion: '2022-11-24T22:47:39.101+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1133,
		codigo: '18:52:00',
		hora: '18:52',
		valor: 18.86,
		fechaCreacion: '2022-11-24T22:47:39.450+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1134,
		codigo: '18:53:00',
		hora: '18:53',
		valor: 18.88,
		fechaCreacion: '2022-11-24T22:47:39.810+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1135,
		codigo: '18:54:00',
		hora: '18:54',
		valor: 18.89,
		fechaCreacion: '2022-11-24T22:47:40.174+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1136,
		codigo: '18:55:00',
		hora: '18:55',
		valor: 18.91,
		fechaCreacion: '2022-11-24T22:47:40.535+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1137,
		codigo: '18:56:00',
		hora: '18:56',
		valor: 18.93,
		fechaCreacion: '2022-11-24T22:47:40.902+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1138,
		codigo: '18:57:00',
		hora: '18:57',
		valor: 18.94,
		fechaCreacion: '2022-11-24T22:47:41.249+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1139,
		codigo: '18:58:00',
		hora: '18:58',
		valor: 18.96,
		fechaCreacion: '2022-11-24T22:47:41.620+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1140,
		codigo: '18:59:00',
		hora: '18:59',
		valor: 18.98,
		fechaCreacion: '2022-11-24T22:47:41.974+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1141,
		codigo: '19:00:00',
		hora: '19:00',
		valor: 19.0,
		fechaCreacion: '2022-11-24T22:47:42.344+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1142,
		codigo: '19:01:00',
		hora: '19:01',
		valor: 19.1,
		fechaCreacion: '2022-11-24T22:47:42.715+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1143,
		codigo: '19:02:00',
		hora: '19:02',
		valor: 19.3,
		fechaCreacion: '2022-11-24T22:47:43.066+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1144,
		codigo: '19:03:00',
		hora: '19:03',
		valor: 19.4,
		fechaCreacion: '2022-11-24T22:47:43.425+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1145,
		codigo: '19:04:00',
		hora: '19:04',
		valor: 19.6,
		fechaCreacion: '2022-11-24T22:47:43.784+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1146,
		codigo: '19:05:00',
		hora: '19:05',
		valor: 19.8,
		fechaCreacion: '2022-11-24T22:47:44.153+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1147,
		codigo: '19:06:00',
		hora: '19:06',
		valor: 19.9,
		fechaCreacion: '2022-11-24T22:47:44.509+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1148,
		codigo: '19:07:00',
		hora: '19:07',
		valor: 19.11,
		fechaCreacion: '2022-11-24T22:47:44.880+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1149,
		codigo: '19:08:00',
		hora: '19:08',
		valor: 19.13,
		fechaCreacion: '2022-11-24T22:47:45.232+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1150,
		codigo: '19:09:00',
		hora: '19:09',
		valor: 19.14,
		fechaCreacion: '2022-11-24T22:47:45.592+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1151,
		codigo: '19:10:00',
		hora: '19:10',
		valor: 19.16,
		fechaCreacion: '2022-11-24T22:47:45.966+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1152,
		codigo: '19:11:00',
		hora: '19:11',
		valor: 19.18,
		fechaCreacion: '2022-11-24T22:47:46.326+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1153,
		codigo: '19:12:00',
		hora: '19:12',
		valor: 19.19,
		fechaCreacion: '2022-11-24T22:47:46.688+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1154,
		codigo: '19:13:00',
		hora: '19:13',
		valor: 19.21,
		fechaCreacion: '2022-11-24T22:47:47.038+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1155,
		codigo: '19:14:00',
		hora: '19:14',
		valor: 19.23,
		fechaCreacion: '2022-11-24T22:47:47.392+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1156,
		codigo: '19:15:00',
		hora: '19:15',
		valor: 19.24,
		fechaCreacion: '2022-11-24T22:47:47.744+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1157,
		codigo: '19:16:00',
		hora: '19:16',
		valor: 19.26,
		fechaCreacion: '2022-11-24T22:47:48.104+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1158,
		codigo: '19:17:00',
		hora: '19:17',
		valor: 19.28,
		fechaCreacion: '2022-11-24T22:47:48.469+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1159,
		codigo: '19:18:00',
		hora: '19:18',
		valor: 19.29,
		fechaCreacion: '2022-11-24T22:47:48.831+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1160,
		codigo: '19:19:00',
		hora: '19:19',
		valor: 19.31,
		fechaCreacion: '2022-11-24T22:47:49.180+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1161,
		codigo: '19:20:00',
		hora: '19:20',
		valor: 19.33,
		fechaCreacion: '2022-11-24T22:47:49.551+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1162,
		codigo: '19:21:00',
		hora: '19:21',
		valor: 19.34,
		fechaCreacion: '2022-11-24T22:47:49.901+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1163,
		codigo: '19:20:00',
		hora: '19:20',
		valor: 19.33,
		fechaCreacion: '2022-11-24T22:47:50.256+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1164,
		codigo: '19:23:00',
		hora: '19:23',
		valor: 19.38,
		fechaCreacion: '2022-11-24T22:47:50.609+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1165,
		codigo: '19:24:00',
		hora: '19:24',
		valor: 19.39,
		fechaCreacion: '2022-11-24T22:47:50.956+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1166,
		codigo: '19:25:00',
		hora: '19:25',
		valor: 19.41,
		fechaCreacion: '2022-11-24T22:47:51.309+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1167,
		codigo: '19:26:00',
		hora: '19:26',
		valor: 19.43,
		fechaCreacion: '2022-11-24T22:47:51.675+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1168,
		codigo: '19:27:00',
		hora: '19:27',
		valor: 19.44,
		fechaCreacion: '2022-11-24T22:47:52.030+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1169,
		codigo: '19:28:00',
		hora: '19:28',
		valor: 19.46,
		fechaCreacion: '2022-11-24T22:47:52.381+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1170,
		codigo: '19:29:00',
		hora: '19:29',
		valor: 19.48,
		fechaCreacion: '2022-11-24T22:47:52.735+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1171,
		codigo: '19:30:00',
		hora: '19:30',
		valor: 19.49,
		fechaCreacion: '2022-11-24T22:47:53.085+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1172,
		codigo: '19:31:00',
		hora: '19:31',
		valor: 19.51,
		fechaCreacion: '2022-11-24T22:47:53.441+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1173,
		codigo: '19:32:00',
		hora: '19:32',
		valor: 19.53,
		fechaCreacion: '2022-11-24T22:47:53.819+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1174,
		codigo: '19:33:00',
		hora: '19:33',
		valor: 19.54,
		fechaCreacion: '2022-11-24T22:47:54.203+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1175,
		codigo: '19:34:00',
		hora: '19:34',
		valor: 19.56,
		fechaCreacion: '2022-11-24T22:47:54.561+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1176,
		codigo: '19:35:00',
		hora: '19:35',
		valor: 19.58,
		fechaCreacion: '2022-11-24T22:47:54.915+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1177,
		codigo: '19:36:00',
		hora: '19:36',
		valor: 19.59,
		fechaCreacion: '2022-11-24T22:47:55.277+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1178,
		codigo: '19:37:00',
		hora: '19:37',
		valor: 19.61,
		fechaCreacion: '2022-11-24T22:47:55.631+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1179,
		codigo: '19:38:00',
		hora: '19:38',
		valor: 19.63,
		fechaCreacion: '2022-11-24T22:47:55.986+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1180,
		codigo: '19:39:00',
		hora: '19:39',
		valor: 19.64,
		fechaCreacion: '2022-11-24T22:47:56.345+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1181,
		codigo: '19:40:00',
		hora: '19:40',
		valor: 19.66,
		fechaCreacion: '2022-11-24T22:47:56.697+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1182,
		codigo: '19:41:00',
		hora: '19:41',
		valor: 19.68,
		fechaCreacion: '2022-11-24T22:47:57.066+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1183,
		codigo: '19:42:00',
		hora: '19:42',
		valor: 19.69,
		fechaCreacion: '2022-11-24T22:47:57.424+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1184,
		codigo: '19:43:00',
		hora: '19:43',
		valor: 19.71,
		fechaCreacion: '2022-11-24T22:47:57.790+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1185,
		codigo: '19:44:00',
		hora: '19:44',
		valor: 19.73,
		fechaCreacion: '2022-11-24T22:47:58.141+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1186,
		codigo: '19:45:00',
		hora: '19:45',
		valor: 19.74,
		fechaCreacion: '2022-11-24T22:47:58.486+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1187,
		codigo: '19:46:00',
		hora: '19:46',
		valor: 19.76,
		fechaCreacion: '2022-11-24T22:47:58.841+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1188,
		codigo: '19:47:00',
		hora: '19:47',
		valor: 19.78,
		fechaCreacion: '2022-11-24T22:47:59.225+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1189,
		codigo: '19:48:00',
		hora: '19:48',
		valor: 19.79,
		fechaCreacion: '2022-11-24T22:47:59.581+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1190,
		codigo: '19:49:00',
		hora: '19:49',
		valor: 19.81,
		fechaCreacion: '2022-11-24T22:47:59.936+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1191,
		codigo: '19:50:00',
		hora: '19:50',
		valor: 19.83,
		fechaCreacion: '2022-11-24T22:48:00.291+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1192,
		codigo: '19:51:00',
		hora: '19:51',
		valor: 19.84,
		fechaCreacion: '2022-11-24T22:48:00.650+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1193,
		codigo: '19:52:00',
		hora: '19:52',
		valor: 19.86,
		fechaCreacion: '2022-11-24T22:48:01.007+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1194,
		codigo: '19:53:00',
		hora: '19:53',
		valor: 19.88,
		fechaCreacion: '2022-11-24T22:48:01.380+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1195,
		codigo: '19:54:00',
		hora: '19:54',
		valor: 19.89,
		fechaCreacion: '2022-11-24T22:48:01.746+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1196,
		codigo: '19:55:00',
		hora: '19:55',
		valor: 19.91,
		fechaCreacion: '2022-11-24T22:48:02.108+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1197,
		codigo: '19:56:00',
		hora: '19:56',
		valor: 19.93,
		fechaCreacion: '2022-11-24T22:48:02.474+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1198,
		codigo: '19:57:00',
		hora: '19:57',
		valor: 19.94,
		fechaCreacion: '2022-11-24T22:48:02.837+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1199,
		codigo: '19:58:00',
		hora: '19:58',
		valor: 19.96,
		fechaCreacion: '2022-11-24T22:48:03.199+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1200,
		codigo: '19:59:00',
		hora: '19:59',
		valor: 19.98,
		fechaCreacion: '2022-11-24T22:48:03.550+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1201,
		codigo: '20:00:00',
		hora: '20:00',
		valor: 20.0,
		fechaCreacion: '2022-11-24T22:48:03.930+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1202,
		codigo: '20:01:00',
		hora: '20:01',
		valor: 20.1,
		fechaCreacion: '2022-11-24T22:48:04.305+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1203,
		codigo: '20:02:00',
		hora: '20:02',
		valor: 20.3,
		fechaCreacion: '2022-11-24T22:48:04.665+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1204,
		codigo: '20:03:00',
		hora: '20:03',
		valor: 20.4,
		fechaCreacion: '2022-11-24T22:48:05.033+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1205,
		codigo: '20:04:00',
		hora: '20:04',
		valor: 20.6,
		fechaCreacion: '2022-11-24T22:48:05.386+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1206,
		codigo: '20:05:00',
		hora: '20:05',
		valor: 20.8,
		fechaCreacion: '2022-11-24T22:48:05.746+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1207,
		codigo: '20:06:00',
		hora: '20:06',
		valor: 20.9,
		fechaCreacion: '2022-11-24T22:48:06.135+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1208,
		codigo: '20:07:00',
		hora: '20:07',
		valor: 20.11,
		fechaCreacion: '2022-11-24T22:48:06.498+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1209,
		codigo: '20:08:00',
		hora: '20:08',
		valor: 20.13,
		fechaCreacion: '2022-11-24T22:48:06.852+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1210,
		codigo: '20:09:00',
		hora: '20:09',
		valor: 20.14,
		fechaCreacion: '2022-11-24T22:48:07.219+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1211,
		codigo: '20:10:00',
		hora: '20:10',
		valor: 20.16,
		fechaCreacion: '2022-11-24T22:48:07.570+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1212,
		codigo: '20:11:00',
		hora: '20:11',
		valor: 20.18,
		fechaCreacion: '2022-11-24T22:48:07.927+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1213,
		codigo: '20:12:00',
		hora: '20:12',
		valor: 20.19,
		fechaCreacion: '2022-11-24T22:48:08.293+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1214,
		codigo: '20:13:00',
		hora: '20:13',
		valor: 20.21,
		fechaCreacion: '2022-11-24T22:48:08.671+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1215,
		codigo: '20:14:00',
		hora: '20:14',
		valor: 20.23,
		fechaCreacion: '2022-11-24T22:48:09.026+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1216,
		codigo: '20:15:00',
		hora: '20:15',
		valor: 20.24,
		fechaCreacion: '2022-11-24T22:48:09.400+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1217,
		codigo: '20:16:00',
		hora: '20:16',
		valor: 20.26,
		fechaCreacion: '2022-11-24T22:48:09.760+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1218,
		codigo: '20:17:00',
		hora: '20:17',
		valor: 20.28,
		fechaCreacion: '2022-11-24T22:48:10.114+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1219,
		codigo: '20:18:00',
		hora: '20:18',
		valor: 20.29,
		fechaCreacion: '2022-11-24T22:48:10.470+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1220,
		codigo: '20:19:00',
		hora: '20:19',
		valor: 20.31,
		fechaCreacion: '2022-11-24T22:48:10.861+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1221,
		codigo: '20:20:00',
		hora: '20:20',
		valor: 20.33,
		fechaCreacion: '2022-11-24T22:48:11.212+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1222,
		codigo: '20:21:00',
		hora: '20:21',
		valor: 20.34,
		fechaCreacion: '2022-11-24T22:48:11.575+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1223,
		codigo: '20:20:00',
		hora: '20:20',
		valor: 20.33,
		fechaCreacion: '2022-11-24T22:48:11.927+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1224,
		codigo: '20:23:00',
		hora: '20:23',
		valor: 20.38,
		fechaCreacion: '2022-11-24T22:48:12.286+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1225,
		codigo: '20:24:00',
		hora: '20:24',
		valor: 20.39,
		fechaCreacion: '2022-11-24T22:48:12.641+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1226,
		codigo: '20:25:00',
		hora: '20:25',
		valor: 20.41,
		fechaCreacion: '2022-11-24T22:48:12.987+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1227,
		codigo: '20:26:00',
		hora: '20:26',
		valor: 20.43,
		fechaCreacion: '2022-11-24T22:48:13.338+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1228,
		codigo: '20:27:00',
		hora: '20:27',
		valor: 20.44,
		fechaCreacion: '2022-11-24T22:48:13.702+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1229,
		codigo: '20:28:00',
		hora: '20:28',
		valor: 20.46,
		fechaCreacion: '2022-11-24T22:48:14.081+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1230,
		codigo: '20:29:00',
		hora: '20:29',
		valor: 20.48,
		fechaCreacion: '2022-11-24T22:48:14.444+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1231,
		codigo: '20:30:00',
		hora: '20:30',
		valor: 20.49,
		fechaCreacion: '2022-11-24T22:48:14.800+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1232,
		codigo: '20:31:00',
		hora: '20:31',
		valor: 20.51,
		fechaCreacion: '2022-11-24T22:48:15.156+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1233,
		codigo: '20:32:00',
		hora: '20:32',
		valor: 20.53,
		fechaCreacion: '2022-11-24T22:48:15.512+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1234,
		codigo: '20:33:00',
		hora: '20:33',
		valor: 20.54,
		fechaCreacion: '2022-11-24T22:48:15.882+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1235,
		codigo: '20:34:00',
		hora: '20:34',
		valor: 20.56,
		fechaCreacion: '2022-11-24T22:48:16.230+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1236,
		codigo: '20:35:00',
		hora: '20:35',
		valor: 20.58,
		fechaCreacion: '2022-11-24T22:48:16.589+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1237,
		codigo: '20:36:00',
		hora: '20:36',
		valor: 20.59,
		fechaCreacion: '2022-11-24T22:48:16.940+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1238,
		codigo: '20:37:00',
		hora: '20:37',
		valor: 20.61,
		fechaCreacion: '2022-11-24T22:48:17.296+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1239,
		codigo: '20:38:00',
		hora: '20:38',
		valor: 20.63,
		fechaCreacion: '2022-11-24T22:48:17.656+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1240,
		codigo: '20:39:00',
		hora: '20:39',
		valor: 20.64,
		fechaCreacion: '2022-11-24T22:48:18.018+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1241,
		codigo: '20:40:00',
		hora: '20:40',
		valor: 20.66,
		fechaCreacion: '2022-11-24T22:48:18.375+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1242,
		codigo: '20:41:00',
		hora: '20:41',
		valor: 20.68,
		fechaCreacion: '2022-11-24T22:48:18.725+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1243,
		codigo: '20:42:00',
		hora: '20:42',
		valor: 20.69,
		fechaCreacion: '2022-11-24T22:48:19.089+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1244,
		codigo: '20:43:00',
		hora: '20:43',
		valor: 20.71,
		fechaCreacion: '2022-11-24T22:48:19.445+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1245,
		codigo: '20:44:00',
		hora: '20:44',
		valor: 20.73,
		fechaCreacion: '2022-11-24T22:48:19.798+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1246,
		codigo: '20:45:00',
		hora: '20:45',
		valor: 20.74,
		fechaCreacion: '2022-11-24T22:48:20.172+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1247,
		codigo: '20:46:00',
		hora: '20:46',
		valor: 20.76,
		fechaCreacion: '2022-11-24T22:48:20.531+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1248,
		codigo: '20:47:00',
		hora: '20:47',
		valor: 20.78,
		fechaCreacion: '2022-11-24T22:48:20.881+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1249,
		codigo: '20:48:00',
		hora: '20:48',
		valor: 20.79,
		fechaCreacion: '2022-11-24T22:48:21.265+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1250,
		codigo: '20:49:00',
		hora: '20:49',
		valor: 20.81,
		fechaCreacion: '2022-11-24T22:48:21.627+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1251,
		codigo: '20:50:00',
		hora: '20:50',
		valor: 20.83,
		fechaCreacion: '2022-11-24T22:48:21.984+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1252,
		codigo: '20:51:00',
		hora: '20:51',
		valor: 20.84,
		fechaCreacion: '2022-11-24T22:48:22.345+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1253,
		codigo: '20:52:00',
		hora: '20:52',
		valor: 20.86,
		fechaCreacion: '2022-11-24T22:48:22.711+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1254,
		codigo: '20:53:00',
		hora: '20:53',
		valor: 20.88,
		fechaCreacion: '2022-11-24T22:48:23.081+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1255,
		codigo: '20:54:00',
		hora: '20:54',
		valor: 20.89,
		fechaCreacion: '2022-11-24T22:48:23.441+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1256,
		codigo: '20:55:00',
		hora: '20:55',
		valor: 20.91,
		fechaCreacion: '2022-11-24T22:48:23.811+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1257,
		codigo: '20:56:00',
		hora: '20:56',
		valor: 20.93,
		fechaCreacion: '2022-11-24T22:48:24.165+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1258,
		codigo: '20:57:00',
		hora: '20:57',
		valor: 20.94,
		fechaCreacion: '2022-11-24T22:48:24.530+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1259,
		codigo: '20:58:00',
		hora: '20:58',
		valor: 20.96,
		fechaCreacion: '2022-11-24T22:48:24.906+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1260,
		codigo: '20:59:00',
		hora: '20:59',
		valor: 20.98,
		fechaCreacion: '2022-11-24T22:48:25.261+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1261,
		codigo: '21:00:00',
		hora: '21:00',
		valor: 21.0,
		fechaCreacion: '2022-11-24T22:48:25.619+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1262,
		codigo: '21:01:00',
		hora: '21:01',
		valor: 21.1,
		fechaCreacion: '2022-11-24T22:48:25.994+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1263,
		codigo: '21:02:00',
		hora: '21:02',
		valor: 21.3,
		fechaCreacion: '2022-11-24T22:48:26.354+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1264,
		codigo: '21:03:00',
		hora: '21:03',
		valor: 21.4,
		fechaCreacion: '2022-11-24T22:48:26.713+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1265,
		codigo: '21:04:00',
		hora: '21:04',
		valor: 21.6,
		fechaCreacion: '2022-11-24T22:48:27.087+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1266,
		codigo: '21:05:00',
		hora: '21:05',
		valor: 21.8,
		fechaCreacion: '2022-11-24T22:48:27.441+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1267,
		codigo: '21:06:00',
		hora: '21:06',
		valor: 21.9,
		fechaCreacion: '2022-11-24T22:48:27.810+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1268,
		codigo: '21:07:00',
		hora: '21:07',
		valor: 21.11,
		fechaCreacion: '2022-11-24T22:48:28.169+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1269,
		codigo: '21:08:00',
		hora: '21:08',
		valor: 21.13,
		fechaCreacion: '2022-11-24T22:48:28.521+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1270,
		codigo: '21:09:00',
		hora: '21:09',
		valor: 21.14,
		fechaCreacion: '2022-11-24T22:48:28.874+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1271,
		codigo: '21:10:00',
		hora: '21:10',
		valor: 21.16,
		fechaCreacion: '2022-11-24T22:48:29.248+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1272,
		codigo: '21:11:00',
		hora: '21:11',
		valor: 21.18,
		fechaCreacion: '2022-11-24T22:48:29.605+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1273,
		codigo: '21:12:00',
		hora: '21:12',
		valor: 21.19,
		fechaCreacion: '2022-11-24T22:48:29.958+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1274,
		codigo: '21:13:00',
		hora: '21:13',
		valor: 21.21,
		fechaCreacion: '2022-11-24T22:48:30.314+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1275,
		codigo: '21:14:00',
		hora: '21:14',
		valor: 21.23,
		fechaCreacion: '2022-11-24T22:48:30.680+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1276,
		codigo: '21:15:00',
		hora: '21:15',
		valor: 21.24,
		fechaCreacion: '2022-11-24T22:48:31.039+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1277,
		codigo: '21:16:00',
		hora: '21:16',
		valor: 21.26,
		fechaCreacion: '2022-11-24T22:48:31.422+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1278,
		codigo: '21:17:00',
		hora: '21:17',
		valor: 21.28,
		fechaCreacion: '2022-11-24T22:48:31.774+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1279,
		codigo: '21:18:00',
		hora: '21:18',
		valor: 21.29,
		fechaCreacion: '2022-11-24T22:48:32.140+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1280,
		codigo: '21:19:00',
		hora: '21:19',
		valor: 21.31,
		fechaCreacion: '2022-11-24T22:48:32.497+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1281,
		codigo: '21:20:00',
		hora: '21:20',
		valor: 21.33,
		fechaCreacion: '2022-11-24T22:48:32.849+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1282,
		codigo: '21:21:00',
		hora: '21:21',
		valor: 21.34,
		fechaCreacion: '2022-11-24T22:48:33.210+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1283,
		codigo: '21:20:00',
		hora: '21:20',
		valor: 21.33,
		fechaCreacion: '2022-11-24T22:48:33.584+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1284,
		codigo: '21:23:00',
		hora: '21:23',
		valor: 21.38,
		fechaCreacion: '2022-11-24T22:48:33.944+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1285,
		codigo: '21:24:00',
		hora: '21:24',
		valor: 21.39,
		fechaCreacion: '2022-11-24T22:48:34.318+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1286,
		codigo: '21:25:00',
		hora: '21:25',
		valor: 21.41,
		fechaCreacion: '2022-11-24T22:48:34.667+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1287,
		codigo: '21:26:00',
		hora: '21:26',
		valor: 21.43,
		fechaCreacion: '2022-11-24T22:48:35.035+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1288,
		codigo: '21:27:00',
		hora: '21:27',
		valor: 21.44,
		fechaCreacion: '2022-11-24T22:48:35.400+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1289,
		codigo: '21:28:00',
		hora: '21:28',
		valor: 21.46,
		fechaCreacion: '2022-11-24T22:48:35.766+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1290,
		codigo: '21:29:00',
		hora: '21:29',
		valor: 21.48,
		fechaCreacion: '2022-11-24T22:48:36.125+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1291,
		codigo: '21:30:00',
		hora: '21:30',
		valor: 21.49,
		fechaCreacion: '2022-11-24T22:48:36.480+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1292,
		codigo: '21:31:00',
		hora: '21:31',
		valor: 21.51,
		fechaCreacion: '2022-11-24T22:48:36.835+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1293,
		codigo: '21:32:00',
		hora: '21:32',
		valor: 21.53,
		fechaCreacion: '2022-11-24T22:48:37.194+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1294,
		codigo: '21:33:00',
		hora: '21:33',
		valor: 21.54,
		fechaCreacion: '2022-11-24T22:48:37.550+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1295,
		codigo: '21:34:00',
		hora: '21:34',
		valor: 21.56,
		fechaCreacion: '2022-11-24T22:48:37.904+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1296,
		codigo: '21:35:00',
		hora: '21:35',
		valor: 21.58,
		fechaCreacion: '2022-11-24T22:48:38.266+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1297,
		codigo: '21:36:00',
		hora: '21:36',
		valor: 21.59,
		fechaCreacion: '2022-11-24T22:48:38.629+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1298,
		codigo: '21:37:00',
		hora: '21:37',
		valor: 21.61,
		fechaCreacion: '2022-11-24T22:48:38.980+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1299,
		codigo: '21:38:00',
		hora: '21:38',
		valor: 21.63,
		fechaCreacion: '2022-11-24T22:48:39.346+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1300,
		codigo: '21:39:00',
		hora: '21:39',
		valor: 21.64,
		fechaCreacion: '2022-11-24T22:48:39.702+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1301,
		codigo: '21:40:00',
		hora: '21:40',
		valor: 21.66,
		fechaCreacion: '2022-11-24T22:48:40.074+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1302,
		codigo: '21:41:00',
		hora: '21:41',
		valor: 21.68,
		fechaCreacion: '2022-11-24T22:48:40.438+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1303,
		codigo: '21:42:00',
		hora: '21:42',
		valor: 21.69,
		fechaCreacion: '2022-11-24T22:48:40.785+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1304,
		codigo: '21:43:00',
		hora: '21:43',
		valor: 21.71,
		fechaCreacion: '2022-11-24T22:48:41.141+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1305,
		codigo: '21:44:00',
		hora: '21:44',
		valor: 21.73,
		fechaCreacion: '2022-11-24T22:48:41.491+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1306,
		codigo: '21:45:00',
		hora: '21:45',
		valor: 21.74,
		fechaCreacion: '2022-11-24T22:48:41.845+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1307,
		codigo: '21:46:00',
		hora: '21:46',
		valor: 21.76,
		fechaCreacion: '2022-11-24T22:48:42.200+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1308,
		codigo: '21:47:00',
		hora: '21:47',
		valor: 21.78,
		fechaCreacion: '2022-11-24T22:48:42.554+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1309,
		codigo: '21:48:00',
		hora: '21:48',
		valor: 21.79,
		fechaCreacion: '2022-11-24T22:48:42.906+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1310,
		codigo: '21:49:00',
		hora: '21:49',
		valor: 21.81,
		fechaCreacion: '2022-11-24T22:48:43.279+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1311,
		codigo: '21:50:00',
		hora: '21:50',
		valor: 21.83,
		fechaCreacion: '2022-11-24T22:48:43.626+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1312,
		codigo: '21:51:00',
		hora: '21:51',
		valor: 21.84,
		fechaCreacion: '2022-11-24T22:48:43.985+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1313,
		codigo: '21:52:00',
		hora: '21:52',
		valor: 21.86,
		fechaCreacion: '2022-11-24T22:48:44.345+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1314,
		codigo: '21:53:00',
		hora: '21:53',
		valor: 21.88,
		fechaCreacion: '2022-11-24T22:48:44.724+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1315,
		codigo: '21:54:00',
		hora: '21:54',
		valor: 21.89,
		fechaCreacion: '2022-11-24T22:48:45.075+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1316,
		codigo: '21:55:00',
		hora: '21:55',
		valor: 21.91,
		fechaCreacion: '2022-11-24T22:48:45.439+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1317,
		codigo: '21:56:00',
		hora: '21:56',
		valor: 21.93,
		fechaCreacion: '2022-11-24T22:48:45.796+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1318,
		codigo: '21:57:00',
		hora: '21:57',
		valor: 21.94,
		fechaCreacion: '2022-11-24T22:48:46.170+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1319,
		codigo: '21:58:00',
		hora: '21:58',
		valor: 21.96,
		fechaCreacion: '2022-11-24T22:48:46.529+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1320,
		codigo: '21:59:00',
		hora: '21:59',
		valor: 21.98,
		fechaCreacion: '2022-11-24T22:48:46.884+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1321,
		codigo: '22:00:00',
		hora: '22:00',
		valor: 22.0,
		fechaCreacion: '2022-11-24T22:48:47.250+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1322,
		codigo: '22:01:00',
		hora: '22:01',
		valor: 22.1,
		fechaCreacion: '2022-11-24T22:48:47.611+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1323,
		codigo: '22:02:00',
		hora: '22:02',
		valor: 22.3,
		fechaCreacion: '2022-11-24T22:48:47.991+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1324,
		codigo: '22:03:00',
		hora: '22:03',
		valor: 22.4,
		fechaCreacion: '2022-11-24T22:48:48.342+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1325,
		codigo: '22:04:00',
		hora: '22:04',
		valor: 22.6,
		fechaCreacion: '2022-11-24T22:48:48.704+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1326,
		codigo: '22:05:00',
		hora: '22:05',
		valor: 22.8,
		fechaCreacion: '2022-11-24T22:48:49.070+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1327,
		codigo: '22:06:00',
		hora: '22:06',
		valor: 22.9,
		fechaCreacion: '2022-11-24T22:48:49.439+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1328,
		codigo: '22:07:00',
		hora: '22:07',
		valor: 22.11,
		fechaCreacion: '2022-11-24T22:48:49.806+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1329,
		codigo: '22:08:00',
		hora: '22:08',
		valor: 22.13,
		fechaCreacion: '2022-11-24T22:48:50.175+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1330,
		codigo: '22:09:00',
		hora: '22:09',
		valor: 22.14,
		fechaCreacion: '2022-11-24T22:48:50.538+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1331,
		codigo: '22:10:00',
		hora: '22:10',
		valor: 22.16,
		fechaCreacion: '2022-11-24T22:48:50.898+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1332,
		codigo: '22:11:00',
		hora: '22:11',
		valor: 22.18,
		fechaCreacion: '2022-11-24T22:48:51.255+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1333,
		codigo: '22:12:00',
		hora: '22:12',
		valor: 22.19,
		fechaCreacion: '2022-11-24T22:48:51.610+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1334,
		codigo: '22:13:00',
		hora: '22:13',
		valor: 22.21,
		fechaCreacion: '2022-11-24T22:48:51.971+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1335,
		codigo: '22:14:00',
		hora: '22:14',
		valor: 22.23,
		fechaCreacion: '2022-11-24T22:48:52.335+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1336,
		codigo: '22:15:00',
		hora: '22:15',
		valor: 22.24,
		fechaCreacion: '2022-11-24T22:48:52.724+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1337,
		codigo: '22:16:00',
		hora: '22:16',
		valor: 22.26,
		fechaCreacion: '2022-11-24T22:48:53.094+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1338,
		codigo: '22:17:00',
		hora: '22:17',
		valor: 22.28,
		fechaCreacion: '2022-11-24T22:48:53.446+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1339,
		codigo: '22:18:00',
		hora: '22:18',
		valor: 22.29,
		fechaCreacion: '2022-11-24T22:48:53.800+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1340,
		codigo: '22:19:00',
		hora: '22:19',
		valor: 22.31,
		fechaCreacion: '2022-11-24T22:48:54.145+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1341,
		codigo: '22:20:00',
		hora: '22:20',
		valor: 22.33,
		fechaCreacion: '2022-11-24T22:48:54.507+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1342,
		codigo: '22:21:00',
		hora: '22:21',
		valor: 22.34,
		fechaCreacion: '2022-11-24T22:48:54.861+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1343,
		codigo: '22:20:00',
		hora: '22:20',
		valor: 22.33,
		fechaCreacion: '2022-11-24T22:48:55.221+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1344,
		codigo: '22:23:00',
		hora: '22:23',
		valor: 22.38,
		fechaCreacion: '2022-11-24T22:48:55.583+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1345,
		codigo: '22:24:00',
		hora: '22:24',
		valor: 22.39,
		fechaCreacion: '2022-11-24T22:48:55.945+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1346,
		codigo: '22:25:00',
		hora: '22:25',
		valor: 22.41,
		fechaCreacion: '2022-11-24T22:48:56.314+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1347,
		codigo: '22:26:00',
		hora: '22:26',
		valor: 22.43,
		fechaCreacion: '2022-11-24T22:48:56.676+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1348,
		codigo: '22:27:00',
		hora: '22:27',
		valor: 22.44,
		fechaCreacion: '2022-11-24T22:48:57.035+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1349,
		codigo: '22:28:00',
		hora: '22:28',
		valor: 22.46,
		fechaCreacion: '2022-11-24T22:48:57.390+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1350,
		codigo: '22:29:00',
		hora: '22:29',
		valor: 22.48,
		fechaCreacion: '2022-11-24T22:48:57.747+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1351,
		codigo: '22:30:00',
		hora: '22:30',
		valor: 22.49,
		fechaCreacion: '2022-11-24T22:48:58.116+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1352,
		codigo: '22:31:00',
		hora: '22:31',
		valor: 22.51,
		fechaCreacion: '2022-11-24T22:48:58.472+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1353,
		codigo: '22:32:00',
		hora: '22:32',
		valor: 22.53,
		fechaCreacion: '2022-11-24T22:48:58.820+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1354,
		codigo: '22:33:00',
		hora: '22:33',
		valor: 22.54,
		fechaCreacion: '2022-11-24T22:48:59.186+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1355,
		codigo: '22:34:00',
		hora: '22:34',
		valor: 22.56,
		fechaCreacion: '2022-11-24T22:48:59.555+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1356,
		codigo: '22:35:00',
		hora: '22:35',
		valor: 22.58,
		fechaCreacion: '2022-11-24T22:48:59.913+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1357,
		codigo: '22:36:00',
		hora: '22:36',
		valor: 22.59,
		fechaCreacion: '2022-11-24T22:49:00.273+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1358,
		codigo: '22:37:00',
		hora: '22:37',
		valor: 22.61,
		fechaCreacion: '2022-11-24T22:49:00.636+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1359,
		codigo: '22:38:00',
		hora: '22:38',
		valor: 22.63,
		fechaCreacion: '2022-11-24T22:49:00.993+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1360,
		codigo: '22:39:00',
		hora: '22:39',
		valor: 22.64,
		fechaCreacion: '2022-11-24T22:49:01.370+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1361,
		codigo: '22:40:00',
		hora: '22:40',
		valor: 22.66,
		fechaCreacion: '2022-11-24T22:49:01.739+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1362,
		codigo: '22:41:00',
		hora: '22:41',
		valor: 22.68,
		fechaCreacion: '2022-11-24T22:49:02.095+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1363,
		codigo: '22:42:00',
		hora: '22:42',
		valor: 22.69,
		fechaCreacion: '2022-11-24T22:49:02.453+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1364,
		codigo: '22:43:00',
		hora: '22:43',
		valor: 22.71,
		fechaCreacion: '2022-11-24T22:49:02.830+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1365,
		codigo: '22:44:00',
		hora: '22:44',
		valor: 22.73,
		fechaCreacion: '2022-11-24T22:49:03.195+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1366,
		codigo: '22:45:00',
		hora: '22:45',
		valor: 22.74,
		fechaCreacion: '2022-11-24T22:49:03.549+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1367,
		codigo: '22:46:00',
		hora: '22:46',
		valor: 22.76,
		fechaCreacion: '2022-11-24T22:49:03.908+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1368,
		codigo: '22:47:00',
		hora: '22:47',
		valor: 22.78,
		fechaCreacion: '2022-11-24T22:49:04.274+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1369,
		codigo: '22:48:00',
		hora: '22:48',
		valor: 22.79,
		fechaCreacion: '2022-11-24T22:49:04.635+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1370,
		codigo: '22:49:00',
		hora: '22:49',
		valor: 22.81,
		fechaCreacion: '2022-11-24T22:49:04.991+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1371,
		codigo: '22:50:00',
		hora: '22:50',
		valor: 22.83,
		fechaCreacion: '2022-11-24T22:49:05.342+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1372,
		codigo: '22:51:00',
		hora: '22:51',
		valor: 22.84,
		fechaCreacion: '2022-11-24T22:49:05.696+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1373,
		codigo: '22:52:00',
		hora: '22:52',
		valor: 22.86,
		fechaCreacion: '2022-11-24T22:49:06.049+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1374,
		codigo: '22:53:00',
		hora: '22:53',
		valor: 22.88,
		fechaCreacion: '2022-11-24T22:49:06.410+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1375,
		codigo: '22:54:00',
		hora: '22:54',
		valor: 22.89,
		fechaCreacion: '2022-11-24T22:49:06.770+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1376,
		codigo: '22:55:00',
		hora: '22:55',
		valor: 22.91,
		fechaCreacion: '2022-11-24T22:49:07.128+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1377,
		codigo: '22:56:00',
		hora: '22:56',
		valor: 22.93,
		fechaCreacion: '2022-11-24T22:49:07.475+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1378,
		codigo: '22:57:00',
		hora: '22:57',
		valor: 22.94,
		fechaCreacion: '2022-11-24T22:49:07.824+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1379,
		codigo: '22:58:00',
		hora: '22:58',
		valor: 22.96,
		fechaCreacion: '2022-11-24T22:49:08.178+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1380,
		codigo: '22:59:00',
		hora: '22:59',
		valor: 22.98,
		fechaCreacion: '2022-11-24T22:49:08.555+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1381,
		codigo: '23:00:00',
		hora: '23:00',
		valor: 23.0,
		fechaCreacion: '2022-11-24T22:49:09.529+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1382,
		codigo: '23:01:00',
		hora: '23:01',
		valor: 23.1,
		fechaCreacion: '2022-11-24T22:49:09.878+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1383,
		codigo: '23:02:00',
		hora: '23:02',
		valor: 23.3,
		fechaCreacion: '2022-11-24T22:49:10.241+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1384,
		codigo: '23:03:00',
		hora: '23:03',
		valor: 23.4,
		fechaCreacion: '2022-11-24T22:49:10.624+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1385,
		codigo: '23:04:00',
		hora: '23:04',
		valor: 23.6,
		fechaCreacion: '2022-11-24T22:49:10.974+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1386,
		codigo: '23:05:00',
		hora: '23:05',
		valor: 23.8,
		fechaCreacion: '2022-11-24T22:49:11.345+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1387,
		codigo: '23:06:00',
		hora: '23:06',
		valor: 23.9,
		fechaCreacion: '2022-11-24T22:49:11.705+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1388,
		codigo: '23:07:00',
		hora: '23:07',
		valor: 23.11,
		fechaCreacion: '2022-11-24T22:49:12.055+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1389,
		codigo: '23:08:00',
		hora: '23:08',
		valor: 23.13,
		fechaCreacion: '2022-11-24T22:49:12.419+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1390,
		codigo: '23:09:00',
		hora: '23:09',
		valor: 23.14,
		fechaCreacion: '2022-11-24T22:49:12.778+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1391,
		codigo: '23:10:00',
		hora: '23:10',
		valor: 23.16,
		fechaCreacion: '2022-11-24T22:49:13.146+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1392,
		codigo: '23:11:00',
		hora: '23:11',
		valor: 23.18,
		fechaCreacion: '2022-11-24T22:49:13.494+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1393,
		codigo: '23:12:00',
		hora: '23:12',
		valor: 23.19,
		fechaCreacion: '2022-11-24T22:49:13.851+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1394,
		codigo: '23:13:00',
		hora: '23:13',
		valor: 23.21,
		fechaCreacion: '2022-11-24T22:49:14.220+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1395,
		codigo: '23:14:00',
		hora: '23:14',
		valor: 23.23,
		fechaCreacion: '2022-11-24T22:49:14.575+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1396,
		codigo: '23:15:00',
		hora: '23:15',
		valor: 23.24,
		fechaCreacion: '2022-11-24T22:49:14.937+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1397,
		codigo: '23:16:00',
		hora: '23:16',
		valor: 23.26,
		fechaCreacion: '2022-11-24T22:49:15.291+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1398,
		codigo: '23:17:00',
		hora: '23:17',
		valor: 23.28,
		fechaCreacion: '2022-11-24T22:49:15.664+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1399,
		codigo: '23:18:00',
		hora: '23:18',
		valor: 23.29,
		fechaCreacion: '2022-11-24T22:49:16.026+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1400,
		codigo: '23:19:00',
		hora: '23:19',
		valor: 23.31,
		fechaCreacion: '2022-11-24T22:49:16.386+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1401,
		codigo: '23:20:00',
		hora: '23:20',
		valor: 23.33,
		fechaCreacion: '2022-11-24T22:49:16.745+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1402,
		codigo: '23:21:00',
		hora: '23:21',
		valor: 23.34,
		fechaCreacion: '2022-11-24T22:49:17.105+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1403,
		codigo: '23:20:00',
		hora: '23:20',
		valor: 23.33,
		fechaCreacion: '2022-11-24T22:49:17.464+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1404,
		codigo: '23:23:00',
		hora: '23:23',
		valor: 23.38,
		fechaCreacion: '2022-11-24T22:49:17.836+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1405,
		codigo: '23:24:00',
		hora: '23:24',
		valor: 23.39,
		fechaCreacion: '2022-11-24T22:49:18.204+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1406,
		codigo: '23:25:00',
		hora: '23:25',
		valor: 23.41,
		fechaCreacion: '2022-11-24T22:49:18.568+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1407,
		codigo: '23:26:00',
		hora: '23:26',
		valor: 23.43,
		fechaCreacion: '2022-11-24T22:49:18.920+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1408,
		codigo: '23:27:00',
		hora: '23:27',
		valor: 23.44,
		fechaCreacion: '2022-11-24T22:49:19.292+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1409,
		codigo: '23:28:00',
		hora: '23:28',
		valor: 23.46,
		fechaCreacion: '2022-11-24T22:49:19.654+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1410,
		codigo: '23:29:00',
		hora: '23:29',
		valor: 23.48,
		fechaCreacion: '2022-11-24T22:49:20.014+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1411,
		codigo: '23:30:00',
		hora: '23:30',
		valor: 23.49,
		fechaCreacion: '2022-11-24T22:49:20.379+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1412,
		codigo: '23:31:00',
		hora: '23:31',
		valor: 23.51,
		fechaCreacion: '2022-11-24T22:49:20.740+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1413,
		codigo: '23:32:00',
		hora: '23:32',
		valor: 23.53,
		fechaCreacion: '2022-11-24T22:49:21.092+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1414,
		codigo: '23:33:00',
		hora: '23:33',
		valor: 23.54,
		fechaCreacion: '2022-11-24T22:49:21.450+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1415,
		codigo: '23:34:00',
		hora: '23:34',
		valor: 23.56,
		fechaCreacion: '2022-11-24T22:49:21.806+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1416,
		codigo: '23:35:00',
		hora: '23:35',
		valor: 23.58,
		fechaCreacion: '2022-11-24T22:49:22.161+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1417,
		codigo: '23:36:00',
		hora: '23:36',
		valor: 23.59,
		fechaCreacion: '2022-11-24T22:49:22.539+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1418,
		codigo: '23:37:00',
		hora: '23:37',
		valor: 23.61,
		fechaCreacion: '2022-11-24T22:49:23.091+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1419,
		codigo: '23:38:00',
		hora: '23:38',
		valor: 23.63,
		fechaCreacion: '2022-11-24T22:49:23.465+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1420,
		codigo: '23:39:00',
		hora: '23:39',
		valor: 23.64,
		fechaCreacion: '2022-11-24T22:49:23.826+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1421,
		codigo: '23:40:00',
		hora: '23:40',
		valor: 23.66,
		fechaCreacion: '2022-11-24T22:49:24.202+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1422,
		codigo: '23:41:00',
		hora: '23:41',
		valor: 23.68,
		fechaCreacion: '2022-11-24T22:49:24.553+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1423,
		codigo: '23:42:00',
		hora: '23:42',
		valor: 23.69,
		fechaCreacion: '2022-11-24T22:49:24.916+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1424,
		codigo: '23:43:00',
		hora: '23:43',
		valor: 23.71,
		fechaCreacion: '2022-11-24T22:49:25.292+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1425,
		codigo: '23:44:00',
		hora: '23:44',
		valor: 23.73,
		fechaCreacion: '2022-11-24T22:49:25.648+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1426,
		codigo: '23:45:00',
		hora: '23:45',
		valor: 23.74,
		fechaCreacion: '2022-11-24T22:49:26.009+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1427,
		codigo: '23:46:00',
		hora: '23:46',
		valor: 23.76,
		fechaCreacion: '2022-11-24T22:49:26.364+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1428,
		codigo: '23:47:00',
		hora: '23:47',
		valor: 23.78,
		fechaCreacion: '2022-11-24T22:49:26.729+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1429,
		codigo: '23:48:00',
		hora: '23:48',
		valor: 23.79,
		fechaCreacion: '2022-11-24T22:49:27.084+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1430,
		codigo: '23:49:00',
		hora: '23:49',
		valor: 23.81,
		fechaCreacion: '2022-11-24T22:49:27.455+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1431,
		codigo: '23:50:00',
		hora: '23:50',
		valor: 23.83,
		fechaCreacion: '2022-11-24T22:49:27.824+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1432,
		codigo: '23:51:00',
		hora: '23:51',
		valor: 23.84,
		fechaCreacion: '2022-11-24T22:49:28.186+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1433,
		codigo: '23:52:00',
		hora: '23:52',
		valor: 23.86,
		fechaCreacion: '2022-11-24T22:49:28.544+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1434,
		codigo: '23:53:00',
		hora: '23:53',
		valor: 23.88,
		fechaCreacion: '2022-11-24T22:49:28.902+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1435,
		codigo: '23:54:00',
		hora: '23:54',
		valor: 23.89,
		fechaCreacion: '2022-11-24T22:49:29.261+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1436,
		codigo: '23:55:00',
		hora: '23:55',
		valor: 23.91,
		fechaCreacion: '2022-11-24T22:49:29.612+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1437,
		codigo: '23:56:00',
		hora: '23:56',
		valor: 23.93,
		fechaCreacion: '2022-11-24T22:49:29.970+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1438,
		codigo: '23:57:00',
		hora: '23:57',
		valor: 23.94,
		fechaCreacion: '2022-11-24T22:49:30.329+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1439,
		codigo: '23:58:00',
		hora: '23:58',
		valor: 23.96,
		fechaCreacion: '2022-11-24T22:49:30.681+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	},
	{
		idHora: 1440,
		codigo: '23:59:00',
		hora: '23:59',
		valor: 23.98,
		fechaCreacion: '2022-11-24T22:49:31.046+00:00',
		fechaModificacion: null,
		usuarioCreacion: 1,
		usuarioModificacion: null
	}
];

export default hours;
