import profileImage from 'assets/profile-default.jpg';
import { FilterActive } from 'common/validators/FilterActives';
import { useSeachContext } from 'context/SearchContext';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import Cookie from 'js-cookie';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useAxios from 'hooks/useAxios';

export const editUserViewModels = () => {
	const { RequestUseCase, endpoints } = useAxios();
	const { formatterText } = useLangv2();
	const { id } = useParams();
	const { loading, toggleLoading } = useGetData();
	const [selectorData, setSelectorData] = useState([]);
	const [roles, setRoles] = useState([]);
	const { setSearch, setRolSearch } = useSeachContext();
	const [listUsers, setListUsers] = useState([]);
	const [originalUsername, setOriginalUsername] = useState('');
	const [auxPlaceholder, setAuxPlaceholder] = useState('');
	const [entityPlaceholder, setEntityPlaceholder] = useState('');
	const [selectedRoles, setSelectedRoles] = useState([]);
	const [originalRoles, setOriginalRoles] = useState([]);
	const [acces, setAcces] = useState({
		mobile: false,
		web: false
	});
	const [entity, setEntity] = useState({
		entidad: null,
		entidadId: 0
	});
	const [formData, setFormData] = useState({
		idUsuario: 999999999,
		usuario: '',
		correo: '',
		estado: 2,
		avatarUrl: profileImage,
		idIdioma: {
			idIdioma: 1,
			idioma: 'Español'
		},
		idEmpleado: null,
		idTercero: null,
		codigo: null,
		usuarioCreacion: Cookie.get('idUsuario'),
		dobleAutenticacion: 0,
		tipoAcceso: ''
	});

	const getRoles = () => {
		RequestUseCase.get(endpoints.roles.getAllRolesActivos)
			.then((data) => {
				setRoles(FilterActive(data));
				setRolSearch(FilterActive(data));
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getListUsers = () => {
		RequestUseCase.get(endpoints.users.getAllUsers)
			.then((ans) => {
				const res = ans;
				const newArray = [];
				res.map((item) => {
					return newArray.push({
						idUser: item.idUsuario,
						username: item.usuario,
						idEmpleado: item.idEmpleado?.idEmpleado,
						idAsesorCliente: item.idAsesorCliente?.idAsesor,
						idTercero: item.idTercero?.idTercero,
						usuario: item.usuario
					});
				});
				setListUsers(newArray);
				getUser(id);
			})
			.catch(() => {});
	};

	const getUser = (id) => {
		RequestUseCase.get(endpoints.users.getUserById(id))
			.then((data) => {
				setFormData({
					idUsuario: data.idUsuario,
					usuario: data.usuario,
					correo: data.correo,
					clave: data.clave,
					estado: data.estado,
					dobleAutenticacion: data.dobleAutenticacion,
					fechaRegistro: data.fechaRegistro,
					fechaModificacion: data.fechaModificacion,
					tipoAcceso: data.tipoAcceso,
					avatarUrl: data.avatarUrl,
					idIdioma: data.idIdioma,
					usuarioCreacion: data.usuarioCreacion,
					usuarioModificacion: data.usuarioModificacion,
					roles: data.roles
				});
				setOriginalUsername(data.usuario);

				if (data.tipoAcceso === 1) {
					setAcces({ ...acces, web: true });
				} else if (data.tipoAcceso === 2) {
					setAcces({ ...acces, mobile: true });
				} else if (data.tipoAcceso === 3) {
					setAcces({ web: true, mobile: true });
				}

				// llena la información si es tercero o empleado
				if (data.idEmpleado !== null) {
					setEntity({
						entidad: 'empleado',
						entidadId: data.idEmpleado.idEmpleado
					});
					getEmployees();
					const userInfoEntity = `${data.idEmpleado.primerNombre} ${data.idEmpleado.segundoNombre} - ${data.idEmpleado.identificacion}`;
					setAuxPlaceholder(userInfoEntity);
					setEntityPlaceholder('Empleado');
				} else if (data.idTercero !== null) {
					setEntity({
						entidad: 'tercero',
						entidadId: data.idTercero.idTercero
					});
					getThirdParties();
					const userInfoEntity = `${data.idTercero.nombre} - ${data.idTercero.identificacion}`;
					setAuxPlaceholder(userInfoEntity);
					setEntityPlaceholder('Tercero');
				} else if (data.idAsesorCliente !== null) {
					setEntity({
						entidad: 'cliente',
						entidadId: data.idAsesorCliente.idAsesor
					});
					const userInfoEntity = `${data.idAsesorCliente.nombres} - ${data.idAsesorCliente.identificacion}`;
					setAuxPlaceholder(userInfoEntity);
					setEntityPlaceholder('Asesor de cliente');
				}

				RequestUseCase.get(endpoints.roles.getAllRolesActivos)
					.then((roles) => {
						const newRoles = roles.filter((rol) =>
							data.roles.find((element) => element.idRol === rol.idRol)
						);
						const filter = FilterActive(newRoles);
						setRoles(filter);
						setSelectedRoles(data.roles);
						setOriginalRoles(data.roles);
					})
					.catch((error) => {
						console.log(error);
					});
			})
			.catch((err) => console.log(err));
	};

	const getEmployees = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.users.empleadoGetByUserAsing)
			.then((res) => {
				const newArray = [];

				res.forEach((item) => {
					const newLabel = `${item.primerNombre} ${item.primerApellido} - ${item.identificacion}`;
					newArray.push({
						value: item.idEmpleado,
						label: newLabel
					});
				});
				setSelectorData(newArray);
				toggleLoading(false);
			})
			.catch(() => {
				toggleLoading(false);
			});
	};

	const getThirdParties = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.users.tercerosGetByUserAsing)
			.then((res) => {
				const newArray = [];
				res.map((item) => {
					const newLabel = `${item.nombre} - ${item.identificacion}`;
					newArray.push({
						value: item.idTercero,
						label: newLabel
					});
				});
				setSelectorData(newArray);
				toggleLoading(false);
			})
			.catch((err) => {
				console.log(err);
				toggleLoading(false);
			});
	};

	const getAdvisors = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.advisers.getAllAdvisers)
			.then((ans) => {
				const res = ans;
				const newArray = [];
				res.map((item) => {
					//verify if the user is already assigned to an adviser or not
					const isAssigned = listUsers.find((user) => user.idAsesorCliente === item.idAsesor);
					if (isAssigned) {
						return;
					}
					const newLabel = `${item.nombres}`;
					return newArray.push({
						value: item.idAsesor,
						label: newLabel
					});
				});
				setSelectorData(newArray);
				toggleLoading(false);
			})
			.catch(() => {
				toggleLoading(false);
			});
	};

	useEffect(() => {
		getRoles();
		getListUsers();
		setSearch([]);
	}, []);

	useEffect(() => {
		getListUsers();
		getUser(id);
	}, [id]);

	useEffect(() => {
		setSelectorData([]);
		setAuxPlaceholder(formatterText('p.select.option'));
		switch (entity.entidad) {
			case 'tercero':
				getThirdParties();
				break;
			case 'empleado':
				getEmployees();
				break;
			case 'cliente':
				getAdvisors();
				break;
			default:
		}
	}, [entity.entidad]);

	return {
		id,
		loading,
		toggleLoading,
		selectorData,
		setSelectorData,
		roles,
		setRoles,
		setSearch,
		setRolSearch,
		listUsers,
		setListUsers,
		originalUsername,
		setOriginalUsername,
		auxPlaceholder,
		setAuxPlaceholder,
		entityPlaceholder,
		setEntityPlaceholder,
		selectedRoles,
		setSelectedRoles,
		originalRoles,
		setOriginalRoles,
		acces,
		setAcces,
		entity,
		setEntity,
		formData,
		setFormData,
		getRoles
	};
};
