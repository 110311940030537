const defaultValue = {
	id: 0,
	label: ''
};
class SelectedFieldModel {
	constructor(
		idTecnico = defaultValue,
		idCargo = defaultValue,
		idPais = defaultValue,
		idDepartamento = defaultValue,
		idMunicipio = defaultValue,
		idTipoDocumento = defaultValue
	) {
		this.idTecnico = idTecnico;
		this.idCargo = idCargo;
		this.idPais = idPais;
		this.idDepartamento = idDepartamento;
		this.idMunicipio = idMunicipio;
		this.idTipoDocumento = idTipoDocumento;
	}
}

export { SelectedFieldModel };
