import React from 'react';
// Import Contexts
// Import Hooks
// Import Components
// Mui Cell row style
import TableRow from '@mui/material/TableRow';
import {
	MdCheckBox,
	MdDelete,
	MdDownload,
	MdEdit,
	MdEmail,
	MdLoyalty,
	MdRemoveRedEye
} from 'react-icons/md';
// Icons for cell
import TableCell from '@mui/material/TableCell';
// Import Libs
import { Modal } from 'react-responsive-modal';
// import Screens
import UpdateDatimeAlert from 'screens/services/Modals/UpdateDatimeAlert';
// import Services
// import Styles
import WarningDialog from 'components/tableOptions/WarningDialog';
import styles from 'screens/services/ExtraTabs/Tab.module.css';
import { DynamicRowServicesProps } from './DynamicRowServices.props';
import DynamicRowServicesViewModel from './DynamicRowServices.viewModel';

const DynamicRowServices = ({
	titles,
	columns,
	getData,
	handleDownloadItem,
	handleDeleteItem,
	handleEditStateItem,
	noHandleEditStateItem,
	routeToEdit,
	canDeleted,
	canDownload,
	canViewDetails,
	canChangeData,
	canModify,
	canSelectEU,
	canSelectSC,
	onClose,
	statusActivity,
	sendMail,
	setSendMail,
	getDataToUpdate,
	setCounterActivities,
	setIsDeleteTechMain,
	isDeleteTechMain
}) => {
	const {
		loading,
		stateAux,
		dateAux,
		displayColor,
		changeStateOfProduct,
		handleChangeSendMail,
		setDialog,
		handleToggle,
		formatterText,
		updateDeliveryState,
		updateActivityServiceState,
		handleSelectEU,
		handleSelectSC,
		handleDownload,
		handleDetails,
		setFormData,
		onOpenChangeData,
		handleEdit,
		handleDelete,
		handleSendMail,
		dialog,
		openDetails,
		onCloseDetails,
		loadingAux,
		dataProvider,
		formatCurrency,
		selectProvider,
		openChangeData,
		onCloseChangeData,
		formData,
		permissions,
		handleUpdatePCO,
		showPermissionDenied,
		loadingModal,
		changeDateTime,
		onCloseChangeDateTime,
		flagStatus,
		setFlagStatus,
		servicioActividad,
		estadoActividad,
		orden
	} = DynamicRowServicesViewModel({
		columns,
		getData,
		handleDownloadItem,
		handleDeleteItem,
		handleEditStateItem,
		routeToEdit,
		onClose,
		sendMail,
		setSendMail,
		getDataToUpdate,
		setCounterActivities,
		setIsDeleteTechMain,
		isDeleteTechMain
	});

	return (
		<>
			<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
				{Object.keys(columns).map((column, index) => {
					if (
						column === 'id' ||
						column === 'objeto' ||
						column === 'usuarioCreacion' ||
						column === 'fc' ||
						column === 'uc'
					) {
						return null;
					}
					if (column === 'estado') {
						return (
							<TableCell className={loading ? 'disabled' : 'enabled'} align="center" key={index}>
								{stateAux === 1 ? (
									<button
										className="btn-state"
										disabled={loading}
										onClick={() => {
											if (!noHandleEditStateItem) {
												setDialog({
													text: `dialog.title.delete.mid.first ${columns.nombre} dialog.title.delete.mid.end.inactive`,
													active: true,
													funcion: () => handleToggle()
												});
											}
										}}
									>
										{formatterText('title.service.status.active')}
									</button>
								) : stateAux === 0 ? (
									<button
										className="btn-inactive_state"
										disabled={loading}
										onClick={() => {
											if (!noHandleEditStateItem) {
												setDialog({
													text: `dialog.title.delete.mid.first ${columns.nombre} dialog.title.delete.mid.end.active`,
													active: true,
													action: 'active',
													funcion: () => handleToggle()
												});
											}
										}}
									>
										{formatterText('title.service.status.inactive')}
									</button>
								) : stateAux === 2 ? (
									<button className="btn-disabled_state">
										{formatterText('title.service.status.disabled')}
									</button>
								) : (
									<h5> </h5>
								)}
							</TableCell>
						);
					}
					if (column === 'productState') {
						return (
							<TableCell align="center" key={index}>
								{columns[column] === 0 ? (
									/*  <button
                    className="btn-action-ps"
                    onClick={(e) => e.preventDefault()}
                  >
                    Sin gestionar
                  </button> */

									<button
										className="btn-action-ps"
										disabled={loading}
										onClick={(e) => {
											e.preventDefault();
											if (!noHandleEditStateItem) {
												setDialog({
													text: `${formatterText('title.service.confirm.changeStatusTo')} ${
														columns.nombre
													} ${formatterText('title.service.status.managed')}`,
													active: true,
													action: 'active',
													funcion: () => {
														changeStateOfProduct(1, columns);
														handleChangeSendMail();
													}
												});
											}
										}}
									>
										{formatterText('title.service.status.managed')}
									</button>
								) : columns[column] === 1 ? (
									<button
										className="btn-action-ps"
										disabled={loading}
										onClick={(e) => {
											e.preventDefault();
											if (!noHandleEditStateItem) {
												setDialog({
													text: `${formatterText('title.service.confirm.changeStatusTo')} ${
														columns.nombre
													} ${formatterText('title.service.status.unmanaged')}`,
													active: true,
													action: 'active',
													funcion: () => {
														changeStateOfProduct(0, columns);
														handleChangeSendMail();
													}
												});
											}
										}}
									>
										{formatterText('title.service.status.unmanaged')}
									</button>
								) : columns[column] === 2 ? (
									<button className="btn-disabled_state">
										{formatterText('title.service.status.otherManagementType')}
									</button>
								) : (
									<h5>{formatterText('title.service.error.idIncorrect')}</h5>
								)}
							</TableCell>
						);
					}
					if (column === 'deliveryType') {
						return (
							<TableCell align="center" key={index}>
								<select
									className="select-type"
									defaultValue={columns[column]}
									value={columns[column]}
									onChange={(e) => {
										e.preventDefault();
										updateDeliveryState(e.target.value, columns.objeto);
									}}
								>
									<option value="">{formatterText('title.service.select.option')}</option>
									<option value="1">{formatterText('title.service.search.store')}</option>
									<option value="2">{formatterText('title.service.awaiting.product')}</option>
								</select>
							</TableCell>
						);
					}
					if (column === 'EstadoActividad') {
						return (
							<TableCell align="center" key={index}>
								<select
									className="select-type"
									defaultValue={columns[column].toString()}
									value={columns[column].toString()}
									disabled={
										statusActivity === 9
											? columns['actividadTecnica'] === 'Si'
												? true
												: false
											: false
									}
									onChange={(e) => {
										e.preventDefault();
										updateActivityServiceState(e.target.value, columns.objeto);
									}}
								>
									<option value="">{formatterText('title.service.select.option')}</option>
									<option value="1">{formatterText('title.service.status.notStarted')}</option>
									<option value="2">{formatterText('title.service.status.inProgress')}</option>
									<option value="3">{formatterText('title.service.status.completed')}</option>
									<option value="4">{formatterText('title.service.status.paused')}</option>
								</select>
							</TableCell>
						);
					}
					if (column === 'fechaModificacion') {
						return (
							<TableCell align="left" key={index}>
								{dateAux}
							</TableCell>
						);
					}
					if (column === 'vencimientoSoat') {
						return (
							<TableCell align="center" key={index}>
								<button
									style={{
										backgroundColor: displayColor(columns[column]),
										border: 'none',
										color: 'white',
										borderRadius: '15px',
										fontWeight: '700',
										padding: '0.7rem'
									}}
								>
									{columns[column]}
								</button>
							</TableCell>
						);
					}
					if (column === 'vencimientoTecnicoMecanico') {
						return (
							<TableCell align="center" key={index}>
								<button
									style={{
										backgroundColor: displayColor(columns[column]),
										border: 'none',
										color: 'white',
										borderRadius: '15px',
										fontWeight: '700',
										padding: '0.7rem'
									}}
								>
									{columns[column]}
								</button>
							</TableCell>
						);
					}
					if (column === 'vencimientoRunt') {
						return (
							<TableCell align="center" key={index}>
								<button
									style={{
										backgroundColor: displayColor(columns[column]),
										border: 'none',
										color: 'white',
										borderRadius: '15px',
										fontWeight: '700',
										padding: '0.7rem'
									}}
								>
									{columns[column]}
								</button>
							</TableCell>
						);
					}
					if (column === 'color') {
						return (
							<TableCell align="center" key={index}>
								<button
									style={{
										backgroundColor: columns[column],
										border: 'none',
										color: 'white',
										borderRadius: '15px',
										fontWeight: '700',
										padding: '0.7rem'
									}}
								></button>
							</TableCell>
						);
					}
					return (
						<TableCell key={index} component="th" scope="row" className="enabled">
							{columns[column]}
						</TableCell>
					);
				})}

				{titles.includes(formatterText('title.service.create.product.actions')) && (
					<TableCell className={loading ? 'disabled' : 'enabled'} align="center" width="120px">
						{canSelectEU && (
							<MdCheckBox
								size={25}
								color="gray"
								cursor="pointer"
								disabled={loading}
								onClick={() => handleSelectEU(columns.objeto)}
							/>
						)}
						{canSelectSC && (
							<MdCheckBox
								size={25}
								color="gray"
								cursor="pointer"
								disabled={loading}
								onClick={() => handleSelectSC(columns.objeto)}
							/>
						)}
						{canDownload && (
							<MdDownload
								size={25}
								color="gray"
								cursor="pointer"
								disabled={loading}
								onClick={() => handleDownload(columns.id)}
							/>
						)}
						{canViewDetails && (
							<MdRemoveRedEye
								size={25}
								color="gray"
								cursor="pointer"
								disabled={loading}
								onClick={() => handleDetails(columns.objeto)}
							/>
						)}
						{canChangeData && (
							<MdLoyalty
								size={25}
								color="gray"
								cursor="pointer"
								disabled={loading}
								onClick={() => {
									// set the data in the fields and then open the modal
									setFormData({
										cantidadSolicitada: columns.objeto.cantidadSolicitada,
										cantidadAprobada: columns.objeto.cantidadAprobada,
										observacion: columns.objeto.observacion,
										costo: columns.objeto.costo?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
									});

									onOpenChangeData();
								}}
							/>
						)}
						{canModify && (
							<MdEdit
								size={25}
								color="gray"
								cursor="pointer"
								disabled={loading}
								onClick={() => handleEdit(columns.id)}
							/>
						)}

						{canDeleted && (
							<MdDelete
								size={25}
								color="gray"
								cursor="pointer"
								disabled={loading}
								onClick={() =>
									setDialog({
										text: `${formatterText('title.service.confirm.delete')} ${columns.nombre}`,
										active: true,
										action: 'delete',
										funcion: () => handleDelete(columns.id)
									})
								}
							/>
						)}
						{sendMail && (
							<MdEmail
								size={25}
								color="gray"
								cursor="pointer"
								disabled={loading}
								onClick={() =>
									setDialog({
										text: `${formatterText('title.service.confirm.sendPurchaseOrderTo')}  ${
											columns.proveeAProb
										}`,
										active: true,
										funcion: () => handleSendMail(columns)
									})
								}
							/>
						)}
					</TableCell>
				)}
				{titles.includes('Eliminar') && (
					<TableCell className={loading ? 'disabled' : 'enabled'} align="center">
						<MdDelete
							size={25}
							color="gray"
							cursor="pointer"
							onClick={() =>
								setDialog({
									text: `Vas a eliminar ${columns.nombre}`,
									active: true,
									action: 'delete',
									funcion: () => handleDelete(columns)
								})
							}
							disabled={loading}
						/>
					</TableCell>
				)}
				{titles.includes('Acciones.') && (
					<TableCell className={loading ? 'disabled' : 'enabled'} align="center">
						<MdDelete
							size={25}
							color="gray"
							cursor="pointer"
							onClick={() =>
								setDialog({
									text: `Vas a eliminar ${columns.nombre}`,
									active: true,
									action: 'delete',
									funcion: () => handleDelete(columns)
								})
							}
							disabled={loading}
						/>
					</TableCell>
				)}
				<WarningDialog dialog={dialog} setDialog={setDialog} />
			</TableRow>
			<Modal
				open={openDetails}
				onClose={onCloseDetails}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				{loadingAux &&
					dataProvider.map((data, index) => (
						<div key={`${index}+${data.nombreRazonSocial}`}>
							<h3>
								{formatterText('title.service.create.supplier')}: {index + 1}{' '}
							</h3>
							<p>
								{formatterText('title.service.create.nameOrLegalEntity')}: {data.nombreRazonSocial}
							</p>
							<p>
								{formatterText('title.service.create.identification')}: {data.identificacion}
							</p>
							<p>
								{formatterText('title.service.create.city')}: {data.nombreMunicipio}
							</p>
							<p>
								{formatterText('title.service.create.address')}: {data.direccion}
							</p>
							<p>
								{formatterText('title.service.create.phone')}: {data.telefono}
							</p>
							<p>
								{formatterText('title.service.create.email')}: {data.correo}
							</p>
							<p>
								{formatterText('title.service.create.cost')}: {formatCurrency(data.costo)} (
								{formatterText('title.service.create.unitOfMeasure')}: {data.nombreUnidadMedida})
							</p>
							<button
								className="btn-primary"
								onClick={(e) => {
									e.preventDefault();
									selectProvider(data, columns.objeto);
								}}
							>
								{formatterText('title.service.create.select')}
							</button>
						</div>
					))}
			</Modal>
			<Modal
				open={openChangeData}
				onClose={onCloseChangeData}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal-90'
				}}
			>
				<section className={styles['container-ps-modal-ud']}>
					<h3 className="title-modal">{formatterText('title.service.create.updateData')}</h3>
					<label>
						<span>{formatterText('title.service.create.approvedQuantity')}</span>
						<input
							type="text"
							className="input-default"
							name="cantidadSolicitada"
							placeholder="Cantidad Solicitada"
							value={formData.cantidadSolicitada}
							onChange={(e) => setFormData({ ...formData, cantidadSolicitada: e.target.value })}
						/>
					</label>
					<label>
						<span>{formatterText('title.service.create.approvedQuantity2')}</span>
						<input
							type="text"
							className="input-default"
							name="cantidadAprobada"
							placeholder="Cantidad Aprobada"
							value={formData.cantidadAprobada}
							onChange={(e) => setFormData({ ...formData, cantidadAprobada: e.target.value })}
						/>
					</label>
					<label>
						<span>{formatterText('title.service.create.comments')}</span>
						<input
							type="text"
							className="input-default"
							name="observacion"
							placeholder={formatterText('title.service.create.comments')}
							value={formData.observacion}
							onChange={(e) => setFormData({ ...formData, observacion: e.target.value })}
						/>
					</label>
					{formData.costo !== null && (
						<label>
							<span>{formatterText('title.service.create.cost2')}</span>
							<input
								type="text"
								className="input-default"
								name="costo"
								placeholder={formatterText('title.service.create.cost2')}
								value={formData.costo}
								onChange={(e) => {
									const inputValue = e.target.value.replace(/\D/g, ''); // Eliminar no dígitos
									const costo = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
									setFormData({ ...formData, costo: costo });
								}}
							/>
						</label>
					)}

					<button
						className="btn-primary"
						onClick={() => {
							if (formData.costo != null) {
								const access = permissions.filter(
									(item) => item.idModulo === 22 && item.nombrePermiso === 'EDITAR'
								);

								if (access.length > 0) {
									handleUpdatePCO(columns.objeto);
								} else {
									showPermissionDenied();
								}
							} else {
								handleUpdatePCO(columns.objeto);
							}
						}}
						disabled={loadingModal}
					>
						{loadingModal ? 'Cargando...' : formatterText('title.service.create.update')}
					</button>
				</section>
			</Modal>
			{/* Activity change dateTime */}
			<Modal
				open={changeDateTime}
				onClose={onCloseChangeDateTime}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<section>
					<UpdateDatimeAlert
						onClose={onCloseChangeDateTime}
						flagStatus={flagStatus}
						setFlagStatus={setFlagStatus}
						getDataToUpdate={getDataToUpdate}
						setCounterActivities={setCounterActivities}
						getData={getData}
						servicioActividad={servicioActividad}
						estadoActividad={estadoActividad}
						orden={orden}
					/>
				</section>
			</Modal>
		</>
	);
};

DynamicRowServices.propTypes = DynamicRowServicesProps;

export default DynamicRowServices;
