import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
import useLangv2 from 'hooks/useLangv2';
// Import Components
import HandleOnError from 'common/validators/HandleOnError';
import HandlerEmail from 'common/validators/HandlerEmail';
import HandlerNumbers from 'common/validators/HandlerNumbers';
import HandlerText from 'common/validators/HandlerText';
import CustomAlert from 'components/CustomAlert';
// Import libs
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
// Import Services
import endpoints from 'services/api';
import { useNavigate } from 'react-router-dom';
import paths from 'services/paths';

export default function CreateExternalUserViewModel({ setIsOpenExternalUser }) {
	const { RequestUseCase } = useAxios();

	const [listPrices, setListPrices] = useState({});
	const [selectorValues] = useState([]);
	const [secondaryPrices, setSecondaryPrices] = useState({});
	const [units, setUnits] = useState();
	const [selectUnits, setSelectUnits] = useState([]);
	const [selectValues, setSelectValues] = useState([]);
	const [auxData, setAuxData] = useState({
		idListaPrecios: 0
	});

	const handleChangeMulti = (val) => {
		setSelectValues(val);
	};

	const handleChangeUnits = (e) => {
		// console.log(e);
		setSelectUnits(e);
	};
	//Resetea los precios secundarios si se modifica el precio principal
	useEffect(() => {
		getSecondaryPrices();
	}, [auxData.idListaPrecios]);

	const getSecondaryPrices = () => {
		//Comprueba la cantidad de precios secundarios, para resetearlos
		if (selectValues.length !== 0) {
			const sameList = selectValues.filter((item) => item === auxData.idListaPrecios);
			const exceptList = selectValues.filter((item) => item !== auxData.idListaPrecios);
			//Si se encuentra que el precio principal es igual a alguno de los secundarios, se resetea elimina este
			if (sameList.length >= 1) {
				setSecondaryPrices(exceptList);
				setSelectValues(exceptList);
			}
		}
		//Si se selecciono algun valor principal, muestra los precios secundarios disponibles
		if (auxData.idListaPrecios !== 0) {
			const filter = listPrices.filter((item) => item !== auxData.idListaPrecios);
			setSecondaryPrices(filter);
		}
	};

	// useLanguage
	const { formatterText } = useLangv2();
	// State of form
	const [formData, setFormData] = useState({
		primerNombre: '',
		segundoNombre: '',
		primerApellido: '',
		segundoApellido: '',
		correo: '',
		telefono: '',
		idGenero: '',
		usuarioEspecial: ''
	});

	const [loading, setLoading] = useState(true);

	const navigate = useNavigate();

	// const [data, setData] = useState([]);

	useEffect(() => {
		getAllBusinessUnits3();
		// getAllBusinessUnits();
		getListPrices();
	}, []);

	const closeModal = () => {
		if (setIsOpenExternalUser instanceof Function) setIsOpenExternalUser(false);
	};

	//Obtener los datos de internet 1 vez
	const getListPrices = () => {
		RequestUseCase.get(endpoints.listPrices.getAllListPrices)
			.then((res) => {
				const newArray = [];
				res.map((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idListaPreciosActividad,
							label: `${item.nombre} - ${item.codigo}`,
							isFixed: true
						});
					}
				});
				setListPrices(newArray);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllBusinessUnits3 = () => {
		setLoading(true);
		RequestUseCase.get(endpoints.businessUnit.getAllBusinessUnits).then((res) => {
			const newArray = [];
			res.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						value: item.idUnidadNegocio,
						label: item.nombre
					});
				}
			});
			setUnits(newArray);
			setLoading(false);
		});
	};

	// Create a new external user structure
	const handleSubmit = () => {
		if (
			formData.primerNombre === '' ||
			formData.primerApellido === '' ||
			formData.correo === '' ||
			formData.telefono === '' ||
			formData.idGenero === '' ||
			formData.usuarioEspecial === '' ||
			selectUnits.length == 0
		) {
			Swal.fire({
				icon: 'warning',
				title: 'Error',
				text: 'Faltan campos por llenar'
			});
		} else {
			const data = {
				usuarioServicioDto: {
					...formData,
					idGenero: {
						idGenero: parseInt(formData.idGenero)
					},
					usuarioEspecial: parseInt(formData.usuarioEspecial),
					usuarioCreacion: parseInt(Cookie.get('idUsuario'))
				},
				unidadNegocios: selectUnits.map((item) => ({
					idUnidadNegocio: item.value
				}))
			};
			postItem(data);
		}
	};

	// Update a string to set into the form
	const handleText = (e) => {
		HandlerText(e, formData, setFormData);
	};

	// Update a number to set into the form
	const handleNumber = (e) => {
		HandlerNumbers(e, formData, setFormData);
	};

	// Update a email to set into the form
	const handleEmail = (e) => {
		HandlerEmail(e, formData, setFormData);
	};

	// Create new external user
	const postItem = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					RequestUseCase.post(endpoints.services.createExternalUser, data)
						.then((res) => {
							AssociatePrices(res.usuarioServicio.idUsuarioServicio, resolve, reject);
						})
						.catch((err) => {
							if (err.response.status === 400 || err.response.status === 420) {
								reject(
									HandleOnError(
										formatterText(
											'alert.message.code.error.externalUser',
											'El número de teléfono ya existe'
										)
									)
								);
							} else {
								reject(
									HandleOnError(
										formatterText(
											'alert.message.failed.general',
											'Error al crear el registro, por favor intente nuevamente.'
										)
									)
								);
							}
						})
						.finally(() => closeModal());
					CustomAlert('confirm_msg', {
						icon: 'success',
						title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
						text: formatterText(
							'alert.message.confirm.updated.general',
							'El registro se ha actualizado correctamente'
						),
						confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
						allowOutsideClick: false,
						executeFunction: () => navigate(paths.createService)
					});
					// associatePrices(response, values, resolve, reject);
				});
			}
		});
	};

	const AssociatePrices = (id, res, err) => {
		const body = {
			idListaPreciosActividadCliente: null,
			idListaPreciosActividad: {
				idListaPreciosActividad: auxData.idListaPrecios.value
			},
			principal: 1,
			idUsuarioServicio: id
			// idUsuarioServicio: id,
		};
		RequestUseCase.post(endpoints.listPriceClient.addListPriceClient, body)
			.then(() => {
				associateSecondaryPrices(id, res, err);
			})
			.catch(() => {
				err(
					HandleOnError(
						formatterText(
							'alert.message.failed.general',
							'Error al asociar la lista de precios principal.'
						)
					)
				);
			});
	};

	const associateSecondaryPrices = (id, resolve, reject) => {
		if (selectValues.length !== 0) {
			const promesas = selectValues.map(
				(item) =>
					new Promise((resl, rej) => {
						const data = {
							idListaPreciosActividadCliente: null,
							idListaPreciosActividad: { idListaPreciosActividad: item.value },

							idUsuarioServicio: id,
							principal: 0

							//idUsuarioServicio: id,
						};
						RequestUseCase.post(endpoints.listPriceClient.addListPriceClient, data)
							.then((res) => {
								resl(res);
							})
							.catch((err) => {
								rej(err);
							});
					})
			);
			Promise.all(promesas)
				.then(() => {
					resolve(
						CustomAlert('confirm_msg', {
							icon: 'success',
							title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
							text: formatterText(
								'alert.message.confirm.created.general',
								'El registro se ha creado correctamente'
							),
							confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
							allowOutsideClick: false,
							executeFunction: () => console.log('User created', true)
						})
					);
				})
				.catch(() => {
					reject(
						HandleOnError(
							formatterText(
								'alert.message.failed.general',
								'Error al crear el registro, por favor intente nuevamente.'
							)
						)
					);
				});
		} else {
			resolve(
				CustomAlert('confirm_msg', {
					icon: 'success',
					title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
					text: formatterText(
						'alert.message.confirm.created.general',
						'El registro se ha creado correctamente'
					),
					confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
					allowOutsideClick: false,
					executeFunction: () => console.log('User created', true)
				})
			);
		}
	};

	return {
		formData,
		handleText,
		handleNumber,
		handleEmail,
		handleSubmit,
		units,
		loading,
		selectUnits,
		handleChangeUnits,
		listPrices,
		secondaryPrices,
		selectValues,
		selectorValues,
		auxData,
		setAuxData,
		handleChangeMulti,
		closeModal
	};
}
