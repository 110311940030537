import Cookie from 'js-cookie';

class Cargo {
	constructor(
		nombre = '',
		descripcion = '',
		codigo = '',
		estado = '1',
		fecha_registro = '',
		fecha_modificacion = '',
		usuario_creacion = Cookie.get('idUsuario')
	) {
		this.nombre = nombre;
		this.descripcion = descripcion;
		this.codigo = codigo;
		this.estado = estado;
		this.fecha_creacion = fecha_registro;
		this.fecha_modificacion = fecha_modificacion;
		this.usuario_creacion = usuario_creacion;
	}

	getNombre() {
		return this.nombre;
	}

	// create a function to set each property of the object individually,
	// the function will have paraments a key and a value
	setPosition(key, value) {
		this[key] = value;
	}
}

export default Cargo;
