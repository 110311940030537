const paths = {
	home: '/',
	example: '/ejemplo',
	update: '/editar',
	createExample: '/crear/ejemplo',
	createExamplejj: '/crear/ejemplojj',
	editExample: '/editar/ejemplo/:id',
	login: '/login',
	recover: '/login/recover',
	changeFirstPassword: '/login/updateFirstPassword/:secret',
	restablishPassword: '/login/formEmailUserPassword/:secret',
	users: '/usuarios',
	createUser: '/usuarios/crear',
	updateUser: '/usuarios/editar/:id',
	roles: '/permisos-roles',
	createRol: '/permisos-roles/crear',
	updateRol: '/permisos-roles/editar/:id',
	updateProfile: '/editar/perfil',
	changePassword: '/cambiar/password',
	createMail: '/configuracion-correo/crear',
	sendMail: '/correo/enviar',
	zones: '/catalogo-zonas',
	sector: '/catalogo-zonas',
	createSector: '/catalogo-zonas/sector/crear',
	updateSector: '/catalogo-zonas/sector/editar/:id',
	audits: '/auditorias-de-registros',
	files: '/tipo-archivos-adjuntos',
	createFile: '/tipo-archivos-adjuntos/crear',
	updateFile: '/tipo-archivos-adjuntos/editar/:id',
	positions: '/cargos',
	createPositions: '/cargos/crear',
	updatePositions: '/cargos/editar/:id',
	contracts: '/tipo-contratos',
	createContract: '/tipo-contratos/crear',
	updateContract: '/tipo-contratos/editar/:id',
	employees: '/empleados',
	createEmployee: '/empleados/crear',
	updateEmployee: '/empleados/editar/:id',
	parameters: '/parametros-de-cliente',
	createMarket: '/parametros-de-cliente/mercados/crear',
	updateMarket: '/parametros-de-cliente/mercados/editar/:id',
	createBusinessUnit: '/parametros-de-cliente/unidad-negocio/crear',
	updateBusinessUnit: '/parametros-de-cliente/unidad-negocio/editar/:id',
	createProviderType: '/parametros-de-cliente/tipo-proveedor/crear',
	updateProviderType: '/parametros-de-cliente/tipo-proveedor/editar/:id',
	createClientType: '/parametros-de-cliente/tipo-cliente/crear',
	updateClientType: '/parametros-de-cliente/tipo-cliente/editar/:id',
	createBillingType: '/parametros-de-cliente/tipo-facturacion/crear',
	updateBillingType: '/parametros-de-cliente/tipo-facturacion/editar/:id',
	createParameter: '/parametros-de-cliente/tipo-tercero/crear',
	updateParameter: '/parametros-de-cliente/tipo-tercero/editar/:id',
	thirdParties: '/terceros',
	createThirdParty: '/terceros/crear',
	updateThirdParty: '/terceros/editar/:id',
	technical: '/tecnicos',
	createTechnical: '/tecnicos/crear',
	updateTechnical: '/tecnicos/editar/:id',
	technicalOperators: '/operadores-tecnico',
	vehicleTechnical: '/vehiculos-tecnico',
	createTechnicalOperator: '/crear/operador-tecnico',
	//updateTechnicalOperator: "/editar/operador-tecnico/:id",
	clients: '/clientes',
	createClient: '/clientes/crear',
	updateClient: '/clientes/editar/:id',
	productCategory: '/productos/categorias-producto',
	createProductCategory: '/productos/categorias-producto/crear',
	updateProductCategory: '/productos/categorias-producto/editar/:id',
	products: '/productos',
	createProduct: '/productos/crear',
	updateProduct: '/productos/editar/:id',
	providers: '/proveedores',
	createProvider: '/proveedores/crear',
	updateProvider: '/proveedores/editar/:id',
	services: '/parametros-de-servicios',
	categoryServices: '/categorias-servicio',
	createCategoryService: '/parametros-de-servicios/crear',
	updateCategoryService: '/parametros-de-servicios/editar/:id',
	activityServices: '/actividades-servicio',
	createActivityService: '/parametros-de-servicios/actividad-servicio/crear',
	updateActivityService: '/parametros-de-servicios/actividad-servicio/editar/:id',
	listPriceServices: '/precios',
	createListPriceService: '/parametros-de-servicios/listado-precio-servicio/crear',
	updateListPriceService: '/parametros-de-servicios/listado-precio-servicio/editar/:id',
	communicationServices: '/servicios-comunicacion',
	createCommunicationService: '/crear/comunicacion-servicio',
	updateCommunicationService: '/editar/comunicacion-servicio/:id',
	nqrServices: '/servicios-nqr',
	createNqrService: '/crear/nqr-servicio',
	updateNqrService: '/editar/nqr-servicio/:id',
	stagesServices: '/etapas-servicio',
	createStageService: '/parametros-de-servicios/etapas-servicio/crear',
	updateStageService: '/parametros-de-servicios/etapas-servicio/editar/:id',
	formServices: '/formularios-servicio',
	createFormService: '/parametros-de-servicios/formulario-servicio/crear',
	updateFormService: '/parametros-de-servicios/formulario-servicio/editar/:id',
	schedule: '/agenda',
	createSchedule: '/agenda/crear',
	duplicateSchedule: '/agenda/duplicar',
	updateSchedule: '/agenda/editar/:id',
	searchTechnicians: '/buscar-tecnicos/:id',
	searchPrincialTechnical: '/buscar-tecnico-principal/editar/:id',
	auxTech: '/asignar/auxiliar-tecnico',
	serviceModule: '/servicios',
	createService: '/servicios/crear',
	updateService: '/servicios/editar/:id',
	duplicateService: '/servicios/duplicar',
	formDiligence: '/formularioDiligenciado',
	externalUsers: '/usuarios-externos',
	createExternalUser: '/crear/usuario-externo',
	updateExternalUser: '/editar/usuario-externo/:id',
	reviewMap: '/revisar-mapa',
	calendarServices: '/calendario/servicios',
	ganttServices: '/gestion-de-homeview',
	panelNotifications: '/panel-notificaciones',
	invoice: '/conciliacion-facturas'
};

export default paths;
