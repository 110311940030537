import useAxios from 'hooks/useAxios';
import HandleOnError from 'common/validators/HandleOnError';
import HandlerNumbers from 'common/validators/HandlerNumbers';
import HandlerText from 'common/validators/HandlerText';
import CustomAlert from 'components/CustomAlert';
import useLang from 'hooks/useLang';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import endpoints from 'services/api';
// Import libs
import Swal from 'sweetalert2';

const EditPrecaution = ({ onClose, exeFun, dataFromContext }) => {
	const { RequestUseCase, COOKIE_USER } = useAxios();
	const { id } = useParams();
	const { formatterText } = useLang();
	const [loading, setLoading] = React.useState(true);
	const [selectValues, setSelectValues] = useState({});
	const [selectPayment, setSelectPayment] = useState({});
	const [formData, setFormData] = useState({
		...dataFromContext
	});
	const [show, setShow] = useState(false);

	useEffect(() => {
		if (dataFromContext.idEstadoRecaudo.idEstadoRecaudo !== 2) {
			setShow(true);
		}
		getSelectData();
	}, []);

	const getSelectData = async () => {
		const promise1 = RequestUseCase.get(endpoints.precautionsServices.getAllPrecautionsState)
			.then((response) => {
				console.log(response);
				setSelectValues(response);
			})
			.catch((err) => {
				console.log(err);
			});

		const promise2 = RequestUseCase.get(endpoints.precautionsServices.getAllPaymentMethods)
			.then((response) => {
				console.log(response);
				setSelectPayment(response);
			})
			.catch((err) => {
				console.log(err);
			});

		Promise.all([promise1, promise2])
			.then(() => {
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// Updtae an activity
	const putItem = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general'),
			text: formatterText('alert.description.update.general'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					// this service create the item
					RequestUseCase.put(
						endpoints.precautionsServices.updatePrecaution(dataFromContext.idRecaudoServicio),
						data
					)
						.then(() => {
							// aqui se llama el servicio para asociar el producto con el proveedor
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general'),
									text: formatterText('alert.message.confirm.updated.general'),
									confirmButtonText: formatterText('alert.button.continue'),
									allowOutsideClick: false,
									executeFunction: () => exeFun()
								})
							);
						})
						.catch((err) => {
							reject(HandleOnError(formatterText('alert.message.failed.general')));
							console.log(err);
						});
				});
			}
		});
	};

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		onClose();

		let data = {};

		if (show) {
			data = {
				idRecaudoServicio: null, //dataFromContext.idRecaudoServicio,
				idMedioPago: formData.idMedioPago?.idMedioPago
					? formData.idMedioPago?.idMedioPago
					: parseInt(formData.idMedioPago),
				idServicio: parseInt(id),
				idEstadoRecaudo: formData.idEstadoRecaudo?.idEstadoRecaudo
					? formData.idEstadoRecaudo?.idEstadoRecaudo
					: parseInt(formData.idEstadoRecaudo),
				valor: formData.valor,
				descripcion: formData.descripcion,
				archivoAdjunto: 'url',
				fechaCreacion: dataFromContext.fechaCreacion,
				fechaModificacion: null,
				usuarioCreacion: dataFromContext.usuarioCreacion,
				usuarioModificacion: COOKIE_USER
			};

			console.log(data);

			putItem(data);
		} else {
			console.log('No tengo ese endpoint ');
		}
	};

	// Update a string to set into the form
	const handleText = (e) => {
		HandlerText(e, formData, setFormData);
	};

	const handleChangeSelect = (e) => {
		if (e.target.value === 2) {
			setShow(false);
			console.log('ocuktar');
		} else {
			setShow(true);
			console.log('mostrar');
		}

		HandlerText(e, formData, setFormData);
	};

	// Update a number to set into the form
	const handleNumber = (e) => {
		HandlerNumbers(e, formData, setFormData);
	};

	return (
		<>
			{!loading ? (
				<form className="w100-container">
					<section className="">
						<p className="title-modal">Editar recaudo</p>

						<label className="label-large-1r">
							<h3 className="text-inline">Tipo de transacción</h3>
							<select
								className="input-default text-inline"
								name="idEstadoRecaudo"
								value={formData.idEstadoRecaudo.idEstadoRecaudo}
								onChange={handleChangeSelect}
								required
							>
								<option value="">Seleccione un tipo de transacción</option>
								{selectValues.map((item, index) => {
									return (
										<option key={index} value={item.idEstadoRecaudo}>
											{item.nombre}
										</option>
									);
								})}
							</select>
						</label>

						{show && (
							<label className="label-large-1r">
								<h3 className="text-inline">Medio de pago</h3>
								<select
									className="input-default text-inline"
									name="idMedioPago"
									value={formData.idMedioPago.idMedioPago}
									onChange={handleText}
									required
								>
									<option value="">Seleccione un medio de pago</option>
									{selectPayment.map((item, index) => {
										return (
											<option key={index} value={item.idMedioPago}>
												{item.nombre}
											</option>
										);
									})}
								</select>
							</label>
						)}
						<label className="label-large-1r">
							<span className="text-inline span-label">Valor</span>
							<input
								type="number"
								value={formData.valor}
								name="valor"
								onChange={handleNumber}
								placeholder=""
								className="input-default w100-container"
								required
							/>
						</label>
						<label className="label-large-1r">
							<span className="text-inline span-label">Observación</span>
							<input
								type="text"
								value={formData.descripcion}
								name="descripcion"
								onChange={handleText}
								placeholder=""
								className="input-default w100-container"
								required
							/>
						</label>

						<section className="footer-grid-buttons">
							<input
								type="submit"
								className="btn-action-primary"
								value="Guardar cambios"
								onClick={(e) => handleSubmit(e)}
							/>
							<button
								className="btn-action-cancel"
								onClick={(e) => {
									e.preventDefault();
									onClose();
								}}
							>
								Cancelar
							</button>
						</section>
					</section>
				</form>
			) : (
				<div>Loading</div>
			)}
		</>
	);
};

export default EditPrecaution;
