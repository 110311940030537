import useAxios from 'hooks/useAxios';
import { useForm } from '@formiz/core';
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';
import useLangv2 from 'hooks/useLangv2';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import endpoints from 'services/api';
import paths from 'services/paths';
import 'styles/colorPicker.css';
import Swal from 'sweetalert2';

export default function CreateStageViewModel() {
	const { RequestUseCase, COOKIE_USER } = useAxios();

	const navigate = useNavigate();
	// useLanguage (updated to v2)
	// const { formatterText } = useLang();
	// Formiz object
	const myForm = useForm();
	// use Hook of language v2
	const { formatterText } = useLangv2();
	// Example of form data
	const [formData, setFormData] = useState([]);
	// Stage of the picker
	const [colorHexCode, setColorHexCode] = useState('#000000');
	// RBG color
	const [colorRGB, setColorRGB] = useState({
		red: 0,
		green: 0,
		blue: 0,
		alpha: 1
	});
	// Stage of the container picker
	const [displayColorPicker, setDisplayColorPicker] = useState(false);

	// Handle to show the color picker
	const handleClick = (e) => {
		e.preventDefault();
		setDisplayColorPicker(!displayColorPicker);
	};

	// Handle to close the color picker
	const handleClose = () => {
		setDisplayColorPicker(false);
	};

	// This function is executed when the create button is clicked
	const handleSubmit = () => {
		const data = {
			...formData,
			descripcion: formData.descripcion || '',
			estado: 1,
			color: colorHexCode,
			usuarioCreacion: COOKIE_USER,
			codigo: formData.codigo.toLowerCase()
		};
		postItem(data);
	};

	const handleFormChange = (values) => {
		setFormData((prevData) => ({ ...prevData, ...values }));
	};

	const postItem = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general'),
			text: formatterText('alert.description.create.general'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general'),
			preConfirm: () => {
				return new Promise((resolve) => {
					RequestUseCase.post(endpoints.stageServices.addStageService, data)
						.then(() => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general'),
									text: formatterText('alert.message.confirm.created.general'),
									confirmButtonText: formatterText('alert.button.continue'),
									allowOutsideClick: false,
									executeFunction: () => navigate(paths.services)
								})
							);
						})
						.catch((err) => {
							if (err.response?.data?.message) {
								HandleOnError(formatterText(err.response?.data?.message));
							} else {
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
						});
				});
			}
		});
	};
	return {
		formatterText,
		handleFormChange,
		handleSubmit,
		myForm,
		colorHexCode,
		colorRGB,
		handleClick,
		displayColorPicker,
		handleClose,
		setColorHexCode,
		setColorRGB,
		navigate
	};
}
