import useAxios from 'hooks/useAxios';
import HandlerText from 'common/validators/HandlerText';
import CustomAlert from 'components/CustomAlert';
import { AppContext } from 'context/AppContext';
import { useSeachContext } from 'context/SearchContext';
import dateFormat from 'dateformat';
import useLangv2 from 'hooks/useLangv2';
import useProgress from 'hooks/useProgress';
import Agenda from 'models/Agenda';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import endpoints from 'services/api';
import paths from 'services/paths';
import Swal from 'sweetalert2';
import HandleOnError from 'common/validators/HandleOnError';

export default function CloneScheduleViewModel() {
	const { RequestUseCase, COOKIE_USER } = useAxios();

	const { timeZoneSelected, setTimeZoneSelected } = useSeachContext();
	const {
		openModalTechnicalToAssign,
		openModalTechnicalToReview,
		setOpenModalTechnicalToAssign,
		setOpenModalTechnicalToReview,
		setTechnicalsToAssing,
		agendaCloneData,
		techSelectToAssign,
		setTechSelectToAssign
	} = useContext(AppContext);

	// Helps to loading data table
	const { loadingProgress, setLoadingProgress, DisplayProgress } = useProgress();

	const [idAgendaToClone, setIdAgendaToClone] = useState(null);
	const [idTimeZones, setIdTimeZones] = useState([]);
	const [body, setBody] = useState([]);
	const [dataReady, setDataReady] = useState(false);

	// clear context time zones
	useEffect(() => {
		const { agendaData, timeZones, technicals } = agendaCloneData;
		if (agendaData === undefined || timeZones === undefined || technicals === undefined) {
			navigate(paths.schedule);
		} else {
			setFormData({
				idUsuario: agendaData.idUsuario,
				nombre: '',
				fechaInicio: agendaData.fechaInicio,
				fechaFin: agendaData.fechaFin,
				concepto: agendaData.concepto,
				estado: agendaData.estado
			});
			// show loading
			setLoadingProgress(true);

			setTimeZoneSelected(timeZoneSelected);
			setOpenModalTechnicalToAssign(openModalTechnicalToAssign);
			setTechSelectToAssign(techSelectToAssign);
		}

		const idAgendaLocal = JSON.parse(localStorage.getItem('dataUpdate')).idAgenda;

		setIdAgendaToClone(idAgendaLocal);

		const timeZonesData = localStorage.getItem('timeZones');

		if (timeZonesData) {
			const timeZonesArray = JSON.parse(timeZonesData);
			const extractedIds = timeZonesArray.map((item) => item.idFranjaHoraria);
			setIdTimeZones(extractedIds);
			setTimeZoneSelected(timeZonesArray);
			setDataReady(true);
			setTimeZoneSelected(timeZonesArray);
		}
	}, []);

	useEffect(() => {
		if (dataReady) {
			fetchTechForAllIdFranjaHoraria();
		}
	}, [dataReady]);

	// get all technicals by time zones
	const fetchTechForAllIdFranjaHoraria = async () => {
		const fetchDataPromises = idTimeZones.map((idFranjaHoraria) =>
			RequestUseCase.get(
				endpoints.agenda.getAllFranjaHorariaByIdAgenda(idFranjaHoraria, idAgendaToClone)
			)
		);

		try {
			const allData = await Promise.all(fetchDataPromises);
			const flattenedData = allData.flat();

			const data = flattenedData.map((element) => ({
				idFranjaHorariaAgendaTecnico: null,
				idAgenda: {
					idAgenda: element.idAgenda
				},
				idFranjaHoraria: {
					idFranjaHoraria: element.idFranjaHoraria
				},
				idTecnico: {
					idTecnico: element.idTecnico.idTecnico
				},
				idTipoAgenda: {
					idTipoAgenda: element.idTipoAgenda.idTipoAgenda
				},
				usuarioCreacion: element.usuarioCreacion
			}));

			setBody(data);
		} catch (error) {
			console.error('Error fetching data for idFranjaHoraria array:', error);
			// Handle error if needed
		}
	};

	const fetchDataForIdFranjaHoraria = (idTimeZones, idAgenda) => {
		const updatedBody = body.map((item) => {
			return {
				idFranjaHorariaAgendaTecnico: null,
				idTipoAgenda: {
					idTipoAgenda: item.idTipoAgenda.idTipoAgenda
				},
				idTecnico: {
					idTecnico: item.idTecnico.idTecnico
				},
				idAgenda: {
					idAgenda: idAgenda
				},
				idFranjaHoraria: {
					idFranjaHoraria: item.idFranjaHoraria.idFranjaHoraria.idFranjaHoraria
				},
				usuarioCreacion: COOKIE_USER
			};
		});

		const addFranjasPromises = idTimeZones.map((idFranjaHoraria) => {
			const matchingItems = updatedBody.filter(
				(item) => item.idFranjaHoraria.idFranjaHoraria === idFranjaHoraria
			);

			return RequestUseCase.post(endpoints.agenda.addFranjas, matchingItems);
		});

		Promise.all(addFranjasPromises)
			.then(() => {
				setBody(updatedBody);
			})
			.catch((error) => {
				console.error('Error adding Franjas:', error);
			});
	};

	// use Hook of language v2
	const { formatterText, noFilledContent, newItemCreated, errorProcess, customSB } = useLangv2();

	const titlesTableTimeZone = [
		formatterText('text.shedule.time.zone', 'Franjas horarias'),
		formatterText('table.shedule.view.technical', 'Técnicos asociados'),
		formatterText('table.actions', 'Acciones')
	];

	// Modal config
	const onCloseTechnicalToAssign = () => setOpenModalTechnicalToAssign(false);
	const onCloseTechnicalToReview = () => setOpenModalTechnicalToReview(false);
	// useNavigate
	const navigate = useNavigate();
	// useState for the form
	const [formData, setFormData] = useState(new Agenda());

	const handleNameText = (e) => {
		HandlerText(e, formData, setFormData);
		if (e.target.name === 'nombre') {
			setFormData((prevValue) => ({
				...prevValue,
				[e.target.name]: `${e.target.value}`
			}));
		}
	};

	// Create an agenda
	const handleSubmit = (e) => {
		e.preventDefault();

		const conditionalCreateAgenda =
			formData.nombre === '' || formData.fechaInicio === '' || formData.fechaFin === '';

		if (conditionalCreateAgenda) {
			noFilledContent();
		} else {
			const DATA = {
				nombre: formData.nombre,
				fechaInicio: dateFormat(formData.fechaInicio, 'isoDateTime'),
				fechaFin: dateFormat(formData.fechaFin, 'isoDateTime'),
				idCoordinadorCreador: {
					idUsuario: COOKIE_USER
				},
				usuarioCreacion: COOKIE_USER
			};

			// is possible to timeZoneSelected have new time zones so we need to validate
			if (timeZoneSelected.length > 0) {
				createItem(DATA);
			} else {
				customSB(
					'warning',
					'snackbar.error.time.zone.required',
					'Es necesario asociar al menos una franja horaria a la agenda.'
				);
			}
		}
	};

	// Create new agenda
	const createItem = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve) => {
					//  Here we call the service to create the item
					RequestUseCase.post(endpoints.agenda.addAgenda, data)
						.then((res) => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.created.general',
										'El registro se ha creado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => associateAgendaToTimeZone(res.idAgenda)
								})
							);
							fetchDataForIdFranjaHoraria(idTimeZones, res.idAgenda);
						})
						.catch((err) => {
							if (err.response?.data?.message) {
								HandleOnError(formatterText(err.response?.data?.message));
							} else {
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
						});
				});
			}
		});
	};

	// Associate a time zone to an agenda
	const associateAgendaToTimeZone = async (idAgenda) => {
		await timeZoneSelected.forEach(async (franja) => {
			const DATA = {
				idFranjaHorariaAgenda: null,
				idAgenda: {
					idAgenda: parseInt(idAgenda)
				},
				idFranjaHoraria: {
					idFranjaHoraria: franja.idFranjaHoraria
				},
				estado: 1,
				usuarioCreacion: COOKIE_USER
			};
			await RequestUseCase.post(endpoints.agenda.addAssociationTimezoneSchedule, DATA)
				.then(() => {
					newItemCreated();
				})
				.catch(() => {
					errorProcess();
				});
		});
		// navigate(paths.schedule);
		navigate(paths.updateSchedule.replace(':id', parseInt(idAgenda)));
	};

	const [dialog, setDialog] = useState({
		text: '',
		active: false,
		function: null
	});

	const closeDialog = () => {
		setDialog({ ...dialog, active: false });
	};
	return {
		timeZoneSelected,
		setFormData,
		formData,
		handleSubmit,
		handleNameText,
		titlesTableTimeZone,
		dialog,
		closeDialog,
		loadingProgress,
		DisplayProgress,
		openModalTechnicalToAssign,
		onCloseTechnicalToAssign,
		openModalTechnicalToReview,
		onCloseTechnicalToReview,
		setTechnicalsToAssing,
		idAgendaToClone,
		setIdAgendaToClone,
		body,
		setBody,
		formatterText,
		navigate
	};
}
