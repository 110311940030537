export const PAGE_NAMES = {
	Mercados: 'title.page.name.markets',
	UnidadNegocio: 'title.page.name.business.unit',
	ProveedorTipo: 'title.page.name.provider.type',
	ClienteTipo: 'title.page.name.client.type',
	Facturacion: 'title.page.name.billing',
	Terceros: 'title.page.name.third.parties',
	Categorías: 'title.page.name.categories',
	Etapas: 'title.page.name.stages',
	Actividades: 'title.page.name.activities',
	Precios: 'title.page.name.prices',
	Formularios: 'title.page.name.forms',
	Técnicos: 'title.page.name.technicians',
	Vehículos: 'title.page.name.vehicles',
	CategoríasProductos: 'title.page.name.product.categories',
	Productos: 'title.page.name.products',
	Servicios: 'title.page.name.services',
	Agendas: 'title.page.name.agendas',
	Usuarios: 'title.page.name.users',
	Roles: 'title.page.name.roles',
	Archivos: 'title.page.name.files',
	Cargos: 'title.page.name.jobs',
	Contratos: 'title.page.name.contracts',
	Empleados: 'title.page.name.employees',
	Terceras: 'title.page.name.third',
	Clientes: 'title.page.name.clients',
	Proveedores: 'title.page.name.providers',
	Actividad: 'title.page.name.activity',
	Recaudos: 'title.page.name.collections'
};
