import { useEffect, useState } from 'react';

// Import Libs
import { useForm } from '@formiz/core';
import dateFormat from 'dateformat';
import Cookie from 'js-cookie';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

// Import Components
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';

// Import Contexts
import { useSeachContext } from 'context/SearchContext';

// Import Hooks
import useAxios from 'hooks/useAxios';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';

// Import Paths
import paths from 'services/paths';

// Import Constants
import { CARREGEX, MODELCARREGEX } from 'common/validators/Regex';

const MODEL = {
	placa: '',
	idMarcaVehiculo: '',
	modelo: '',
	fechaTecnicoMecanica: '',
	fechaSoap: '',
	fechaRunt: '',
	runt: '',
	idVehiculo: ''
};

export default function UpdateTechnicalViewModel() {
	// show or hide the section
	const [show, setShow] = useState(true);

	const { loading, toggleLoading } = useGetData();
	const navigate = useNavigate();
	const myForm = useForm();
	const { fetchData, COOKIE_USER, RequestUseCase, endpoints: endpoints } = useAxios();

	// use Hook of language v2
	const {
		formatterText,
		noFilledContent,
		newItemCreated,
		successRemoveItem,
		customSB,
		resourceNotFound
	} = useLangv2();

	const TITLE_TABLE = [
		formatterText('technicians.table.title.license.vehicle'),
		formatterText('p.vehicle.model'),
		formatterText('p.date.soat'),
		formatterText('p.date.runt'),
		formatterText('p.date.technicians.revision'),
		formatterText('table.title.runt.date'),
		formatterText('table.actions')
	];

	const {
		primaryMinimalTable,
		setPrimaryMinimalTable,
		prevPrimaryMinimalTable,
		setPrevPrimaryMinimalTable,
		secondMinimalTable,
		setSecondMinimalTable,
		prevSecondMinimalTable,
		setPrevSecondMinimalTable,
		thirdMinimalTable,
		setThirdMinimalTable,
		prevThirdMinimalTable,
		setPrevThirdMinimalTable,
		setSearchUnitBussines,
		setSearchSucursal,
		setSearchCoverage
	} = useSeachContext();

	const [archivos, setCurrentFiles] = useState([]);
	const [formData, setFormData] = useState(null);
	const [categoryLicense, setCategoryLicense] = useState([]);
	const [messageError, setMessageError] = useState('');
	const [messageErrorPlaca, setMessageErrorPlaca] = useState('');

	// get url id
	const { id } = useParams();

	const [vehicles, setVehicles] = useState([]);

	const [currentVehicle, setCurrentVehicle] = useState(MODEL);

	const [vehiclesBrand, setVehiclesBrand] = useState([]);

	const getCategoryService = () => {
		RequestUseCase.get(endpoints.serviceCategory.getAllServiceCategory)
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idCategoriaServicio,
							label: `${item.nombre} - ${item.codigo}`,
							isFixed: true
						});
					}
				});
				setCategoryService(newArray);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleAddFamily = () => {
		if (
			currentVehicle.placa !== '' &&
			currentVehicle.idMarcaVehiculo !== '' &&
			currentVehicle.modelo !== '' &&
			currentVehicle.fechaTecnicoMecanica !== '' &&
			currentVehicle.fechaSoap !== '' &&
			currentVehicle.fechaRunt !== '' &&
			currentVehicle.runt !== ''
		) {
			// find in vehicles arr if the placa is already saved
			const findPlaca = vehicles.find((item) => item.placa === currentVehicle.placa);
			if (findPlaca) {
				customSB(
					'warning',
					'snackbar.warning.repeated.vehicle',
					'La placa ya existe, por favor ingrese una diferente.'
				);
			} else {
				saveNewVehicles(currentVehicle);
			}
		} else {
			noFilledContent();
		}
	};

	// save new vehicles
	const saveNewVehicles = (vehicle) => {
		const DATA = {
			idVehiculo: null,
			placa: vehicle.placa,
			idMarcaVehiculo: {
				idMarcaVehiculo: vehicle.idMarcaVehiculo.idMarcaVehiculo
			},
			idTecnico: {
				idTecnico: parseInt(id)
			},
			modelo: vehicle.modelo,
			fechaTecnicoMecanica: dateFormat(vehicle.fechaTecnicoMecanica, 'isoDateTime'),
			fechaSoap: dateFormat(vehicle.fechaSoap, 'isoDateTime'),
			fechaRunt: dateFormat(vehicle.fechaRunt, 'isoDateTime'),
			runt: vehicle.runt,
			estado: 1,
			fechaCreacion: null,
			fechaModificacion: null,
			usuarioCreacion: COOKIE_USER,
			usuarioModificacion: null
		};
		fetchData({ url: endpoints.institutions.saveVehicle, method: 'post', body: DATA }).then(
			(data) => {
				const { error } = data;
				if (error === '200') {
					newItemCreated();
					setCurrentVehicle(MODEL);
					setShow(false);
					setTimeout(() => {
						setShow(true);
					}, 100);
					getDataVehicles(id);
				}
			}
		);
	};

	const handleChangeRelative = (e) => {
		if (e.target.name !== 'placa') {
			setCurrentVehicle({ ...currentVehicle, [e.target.name]: e.target.value });
		} else {
			if (e.target.value.match(CARREGEX)) {
				setMessageErrorPlaca('');
				setCurrentVehicle({
					...currentVehicle,
					[e.target.name]: e.target.value
				});
			} else {
				if (e.target.value === null || e.target.value === '') {
					setMessageErrorPlaca('');
				} else {
					setMessageErrorPlaca(formatterText('p.label.title.placaFormatoIncorrecto'));
				}
			}
		}
	};

	const handleChangeModel = (e) => {
		if (e.target.value.match(MODELCARREGEX)) {
			setMessageError('');

			setCurrentVehicle({ ...currentVehicle, [e.target.name]: e.target.value });
		} else {
			if (e.target.value === null || e.target.value === '') {
				setMessageError('');
			} else {
				setMessageError(formatterText('p.label.title.modeloFormatoIncorrecto'));
			}
		}
	};

	const handleChangeSelect = (selectedOption) => {
		setCurrentVehicle({
			...currentVehicle,
			[selectedOption.target.name]:
				selectedOption.target.value === ''
					? selectedOption.target.value
					: JSON.parse(selectedOption.target.value)
		});
	};

	const getMarcasVehiculos = () => {
		RequestUseCase.get(endpoints.institutions.brandVehicles)
			.then((res) => {
				setVehiclesBrand(res);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteCategoryAssociated = (resolve, reject) => {
		const deletedCategories = prevSelectValues.filter((item) => {
			return !selectValues.includes(item);
		});
		if (deletedCategories.length > 0) {
			// eslint-disable-next-line array-callback-return
			const promises = deletedCategories.map((item) => {
				RequestUseCase.delete(endpoints.associateDataTechnical.deleteCategoryByid(item.idAssociate))
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			});

			Promise.all(promises)
				.then(() => {
					postCategoryService(resolve, reject);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			postCategoryService(resolve, reject);
		}
	};

	const postCategoryService = (resolve, reject) => {
		const newCategories = selectValues.filter((item) => {
			return !prevSelectValues.includes(item);
		});
		const promises = newCategories.map(
			(item) =>
				new Promise((resolve, reject) => {
					const data = {
						idTecnico: formData.idTecnico,
						idCategoriaServicio: item.value
					};
					RequestUseCase.post(endpoints.associateDataTechnical.addCategoryService, data)
						.then((res) => {
							resolve(res);
						})
						.catch((err) => {
							reject(err);
						});
				})
		);
		Promise.all(promises)
			.then(() => {
				deleteAssociateUnityBussiness(resolve, reject);
			})
			.catch(() => {
				reject((err) => {
					if (err.response.status === 412) {
						HandleOnError(err.response.data);
					} else {
						HandleOnError(formatterText('p.label.title.tecnicoVehiculosAsociadosSinCategorias'));
					}
				});
			});
	};

	// Sprint 8 , crear associaciones
	const deleteAssociateUnityBussiness = (resolve, reject) => {
		const data = getDiference(primaryMinimalTable, prevPrimaryMinimalTable);
		if (data.length > 0) {
			// eslint-disable-next-line array-callback-return
			const promises = data.map((item) => {
				RequestUseCase.delete(
					endpoints.associateDataTechnical.deleteUnityBusiness(item.idAssociate)
				)
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			});

			Promise.all(promises)
				.then(() => {
					deleteAssociateSucursal(resolve, reject);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			deleteAssociateSucursal(resolve, reject);
		}
	};

	const deleteAssociateSucursal = (resolve, reject) => {
		const data = getDiference(secondMinimalTable, prevSecondMinimalTable);
		if (data.length > 0) {
			// eslint-disable-next-line array-callback-return
			const promises = data.map((item) => {
				RequestUseCase.delete(endpoints.associateDataTechnical.deleteSucursal(item.idAssociate))
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			});

			Promise.all(promises)
				.then(() => {
					deleteCoverage(resolve, reject);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			deleteCoverage(resolve, reject);
		}
	};

	const deleteCoverage = (resolve, reject) => {
		const data = getDiference(thirdMinimalTable, prevThirdMinimalTable);
		if (data.length > 0) {
			// eslint-disable-next-line array-callback-return
			const promises = data.map((item) => {
				RequestUseCase.delete(endpoints.associateDataTechnical.deleteCoverage(item.id))
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			});

			Promise.all(promises)
				.then(() => {
					postAssociateUnityBussiness(resolve, reject);
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			postAssociateUnityBussiness(resolve, reject);
		}
	};

	const postAssociateUnityBussiness = (resolve, reject) => {
		const data = getNewData(primaryMinimalTable, prevPrimaryMinimalTable);
		if (data.length > 0) {
			const promises = data.map(
				(item) =>
					new Promise((resolve, reject) => {
						const data = {
							idUsuario: formData.idUsuario,
							idUnidadNegocio: item.id
						};
						RequestUseCase.post(endpoints.associateDataTechnical.addUnityBusiness, data)
							.then((res) => {
								resolve(res);
							})
							.catch((err) => {
								reject(err);
							});
					})
			);
			Promise.all(promises)
				.then(() => {
					postAssociateSucursal(resolve, reject);
				})
				.catch(() => {
					reject((err) => {
						if (err.response.status === 412) {
							HandleOnError(err.response.data);
						} else {
							HandleOnError(formatterText('p.label.title.tecnicoVehiculosAsociadosSinCategorias'));
						}
						console.log(err);
					});
				});
		} else {
			postAssociateSucursal(resolve, reject);
		}
	};

	const postAssociateSucursal = (resolve, reject) => {
		const data = getNewData(secondMinimalTable, prevSecondMinimalTable);
		if (data.length > 0) {
			const promises = data.map(
				(item) =>
					new Promise((resolve, reject) => {
						const data = {
							idUsuario: formData.idUsuario,
							idSucursal: item.id
						};
						RequestUseCase.post(endpoints.associateDataTechnical.addSucursal, data)
							.then((res) => {
								resolve(res);
							})
							.catch((err) => {
								reject(err);
							});
					})
			);
			Promise.all(promises)
				.then(() => {
					postCoverage(resolve, reject);
				})
				.catch(() => {
					reject((err) => {
						if (err.response.status === 412) {
							HandleOnError(err.response.data);
						} else {
							HandleOnError(formatterText('p.label.title.tecnicoVehiculosAsociadosSinCategorias'));
						}
						console.log(err);
					});
				});
		} else {
			postCoverage(resolve, reject);
		}
	};

	const postCoverage = (resolve, reject) => {
		const data = getNewData(thirdMinimalTable, prevThirdMinimalTable);
		if (data.length > 0) {
			const promises = data.map(
				(item) =>
					new Promise((resolve, reject) => {
						const data = {
							idMunicipio: item.citySucursal.value,
							idTecnico: formData.idTecnico
						};
						RequestUseCase.post(endpoints.associateDataTechnical.addCoverage, data)
							.then((res) => {
								resolve(res);
							})
							.catch((err) => {
								reject(err);
							});
					})
			);
			Promise.all(promises)
				.then(() => {
					resolve(
						CustomAlert('confirm_msg', {
							icon: 'success',
							title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
							text: formatterText(
								'p.label.title.tecnicoVehiculosCategoriasAsociadasCorrectamente2'
							),
							confirmButtonText: 'Continuar',
							allowOutsideClick: false,
							executeFunction: () => navigate(paths.technical)
						})
					);
				})
				.catch(() => {
					reject((err) => {
						if (err.response.status === 412) {
							HandleOnError(err.response.data);
						} else {
							HandleOnError(formatterText('p.label.title.tecnicoVehiculosAsociadosSinCategorias'));
						}
						console.log(err);
					});
				});
		} else {
			resolve(
				CustomAlert('confirm_msg', {
					icon: 'success',
					title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
					text: formatterText('p.label.title.tecnicoVehiculosCategoriasAsociadasCorrectamente'),
					confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
					allowOutsideClick: false,
					executeFunction: () => navigate(paths.technical)
				})
			);
		}
	};

	const getDataVehicles = (id) => {
		RequestUseCase.get(endpoints.institutions.getTechnicalVehiclesById(id))
			.then((res) => {
				const allvehicles = res.map((vehicle) => ({
					placa: vehicle.placa,
					idMarcaVehiculo: { ...vehicle.idMarcaVehiculo },
					modelo: vehicle.modelo,
					fechaTecnicoMecanica: moment(vehicle.fechaTecnicoMecanica.split('T')[0]),
					fechaSoap: moment(vehicle.fechaSoap.split('T')[0]),
					fechaRunt: moment(vehicle.fechaRunt.split('T')[0]),
					runt: vehicle.runt,
					idVehiculo: vehicle.idVehiculo
				}));
				setVehicles(allvehicles);
			})
			.catch((err) => {
				console.log(err, 'no se logro obtener los vehiculos del tecnico');
			});
	};

	useEffect(() => {
		getDataTechnicalOperator();
		getCategoryService();
		getEmployees();
		getThirdParties();
		getAllcategoryLicenses();
		getMarcasVehiculos();
	}, []);

	useEffect(() => {
		getDataToUpdate(id);
	}, [id]);

	const getEmployees = () => {
		toggleLoading(true);
		// getAllEmployees() switch
		RequestUseCase.get(endpoints.employees.getAllSelectEmployees)
			.then((response) => {
				const ArrayMunicipios = [];
				response.forEach((item) => {
					const valueText = `${item.primerNombre} ${item.primerApellido} - ${item.identificacion}`;
					ArrayMunicipios.push({
						label: valueText,
						value: item.idEmpleado,
						objeto: { ...item }
					});
				});
				toggleLoading(false);
			})
			.catch((error) => {
				console.log(error);
				toggleLoading(false);
			});
	};

	const getThirdParties = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.thirdParties.getAllThirdParties)
			.then((response) => {
				const newArray = [];
				response.forEach((item) => {
					const valueText = `${item.nombre} - ${item.identificacion}`;
					newArray.push({
						label: valueText,
						value: item.idTercero,
						objeto: { ...item }
					});
				});
				toggleLoading(false);
			})
			.catch((error) => {
				console.log(error);
				toggleLoading(false);
			});
	};

	const getAllcategoryLicenses = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.institutions.categoryLicenses)
			.then((response) => {
				const ArrayMunicipios = [];
				response.forEach((item) => {
					ArrayMunicipios.push({
						label: item.nombre,
						value: item.idCategoriaLicenciaConduccion
					});
				});
				setCategoryLicense(ArrayMunicipios);
				toggleLoading(false);
			})
			.catch((error) => {
				console.log(error);
				toggleLoading(false);
			});
	};

	const getData = (obj, url) => {
		getDataAttachedFiles(obj.idTecnico);
		if (obj?.idEmpleado !== null) {
			RequestUseCase.get(url(obj?.idEmpleado?.idEmpleado))
				.then((res) => {
					getAllAssociated(res.idUsuario);
					setFormData({
						idTecnico: obj.idTecnico,
						idEmpleado: obj.idEmpleado,
						idUsuario: res.idUsuario,
						idTercero: null,
						primerNombre: obj.idEmpleado.primerNombre,
						segundoNombre: obj.idEmpleado.segundoNombre,
						primerApellido: obj.idEmpleado.primerApellido,
						segundoApellido: obj.idEmpleado.segundoApellido,
						identificacion: obj.idEmpleado.identificacion,
						genero: obj.idEmpleado.genero.abreviatura,
						idTipoDocumento: obj.idEmpleado.idTipoDocumento.nombre,
						idAbreviaturaDocumento: obj.idEmpleado.idTipoDocumento.abreviatura,
						idCategoriaLicenciaConduccion: obj.idCategoriaLicenciaConduccion,
						restriccionLicencia: obj.restriccionLicencia,
						empleado: obj.idEmpleado,
						fechaCreacion: obj.fechaCreacion,
						licenciaFechaExpiracion: obj.licenciaFechaExpiracion
					});
				})
				.catch(() => {
					setFormData({
						idTecnico: obj.idTecnico,
						idEmpleado: obj.idEmpleado,
						idUsuario: null,
						idTercero: null,
						primerNombre: obj.idEmpleado.primerNombre,
						segundoNombre: obj.idEmpleado.segundoNombre,
						primerApellido: obj.idEmpleado.primerApellido,
						segundoApellido: obj.idEmpleado.segundoApellido,
						identificacion: obj.idEmpleado.identificacion,
						genero: obj.idEmpleado.genero.abreviatura,
						idTipoDocumento: obj.idEmpleado.idTipoDocumento.nombre,
						idAbreviaturaDocumento: obj.idEmpleado.idTipoDocumento.abreviatura,
						idCategoriaLicenciaConduccion: obj.idCategoriaLicenciaConduccion,
						restriccionLicencia: obj.restriccionLicencia,
						empleado: obj.idEmpleado,
						fechaCreacion: obj.fechaCreacion,
						licenciaFechaExpiracion: obj.licenciaFechaExpiracion
					});
				});
		} else {
			RequestUseCase.get(
				endpoints.associateDataTechnical.getUserIdbyIdTercero(obj.idTercero.idTercero)
			)
				.then((res) => {
					getAllAssociated(res.idUsuario);
					setFormData({
						idTecnico: obj.idTecnico,
						idEmpleado: null,
						idTercero: obj.idTercero,
						idUsuario: res.idUsuario,
						primerNombre: obj.idTercero.nombre,
						segundoNombre: obj.idTercero.segundoNombre || '',
						primerApellido: obj.idTercero.primerApellido || '',
						segundoApellido: obj.idTercero.segundoApellido || '',
						identificacion: obj.idTercero.identificacion,
						idTipoDocumento: obj.idTercero.idTipoDocumento.nombre,
						idAbreviaturaDocumento: obj.idTercero.idTipoDocumento.abreviatura,
						idCategoriaLicenciaConduccion: obj.idCategoriaLicenciaConduccion,
						restriccionLicencia: obj.restriccionLicencia,
						empleado: obj.idTercero,
						fechaCreacion: obj.fechaCreacion,
						licenciaFechaExpiracion: obj.licenciaFechaExpiracion
					});
				})
				.catch(() => {
					setFormData({
						idTecnico: obj.idTecnico,
						idEmpleado: null,
						idTercero: obj.idTercero,
						idUsuario: null,
						primerNombre: obj.idTercero.nombre,
						segundoNombre: obj.idTercero.segundoNombre || '',
						primerApellido: obj.idTercero.primerApellido || '',
						segundoApellido: obj.idTercero.segundoApellido || '',
						identificacion: obj.idTercero.identificacion,
						idTipoDocumento: obj.idTercero.idTipoDocumento.nombre,
						idAbreviaturaDocumento: obj.idTercero.idTipoDocumento.abreviatura,
						idCategoriaLicenciaConduccion: obj.idCategoriaLicenciaConduccion,
						restriccionLicencia: obj.restriccionLicencia,
						empleado: obj.idTercero,
						fechaCreacion: obj.fechaCreacion,
						licenciaFechaExpiracion: obj.licenciaFechaExpiracion
					});
				});
		}
		getCategoriasAsociadas(obj.idTecnico);
		getDataVehicles(obj.idTecnico);
	};

	//get data from localStorage
	const getDataTechnicalOperator = () => {
		const item = JSON.parse(localStorage.getItem('dataUpdate'));
		const url = endpoints.associateDataTechnical.getUserIdbyIdEmployee;
		getData(item, url);
	};

	//get data from api
	const getDataToUpdate = (id) => {
		RequestUseCase.get(endpoints.technical.getTechicalById(id))
			.then((res) => {
				if (res !== null) {
					const item = res;
					const url = endpoints.associateDataTechnical.getUserIdbyIdEmployee;
					getData(item, url);
				} else {
					resourceNotFound();
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const getCategoriasAsociadas = (id) => {
		RequestUseCase.get(endpoints.associateDataTechnical.getCategoryById(id))
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					newArray.push({
						value: item.idCategoriaServicio,
						label: `${item.nombre} - ${item.codigo}`,
						isFixed: true,
						idAssociate: item.idTecnicoCategoriaServicio
					});
				});
				setSelectValues(newArray);
				setPrevSelectValues(newArray);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// update Technical data
	const handleSubmit = (values) => {
		console.log(values.idCategoriaLicenciaConduccion);
		let data = {};
		// determinate if the technical is employee
		if (formData.idEmpleado !== null) {
			data = {
				idTecnico: formData.idTecnico,
				idVehiculo: null,
				idTercero: null,
				restriccionLicencia: values.restriccionLicencia,
				licenciaFechaExpiracion: dateFormat(values.licenciaFechaExpiracion, 'isoDateTime'),
				idCategoriaLicenciaConduccion: values.idCategoriaLicenciaConduccion.value,
				idEmpleado: { ...formData.empleado },
				estado: 1,
				fechaCreacion: formData.fechaCreacion,
				fechaModificacion: null,
				usuarioCreacion: Cookie.get('idUsuario'),
				usuarioModificacion: Cookie.get('idUsuario')
			};
		} else {
			data = {
				idTecnico: formData.idTecnico,
				idVehiculo: null,
				idTercero: { ...formData.empleado },
				restriccionLicencia: values.restriccionLicencia,
				licenciaFechaExpiracion: dateFormat(values.licenciaFechaExpiracion, 'isoDateTime'),
				idCategoriaLicenciaConduccion: values.idCategoriaLicenciaConduccion.value,

				idEmpleado: null,
				estado: 1,
				fechaCreacion: formData.fechaCreacion,
				fechaModificacion: null,
				usuarioCreacion: Cookie.get('idUsuario'),
				usuarioModificacion: Cookie.get('idUsuario')
			};
		}

		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.update.general', 'Se va a editar el registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					RequestUseCase.put(endpoints.technical.updateTechnical, data)
						.then(() => {
							deleteCategoryAssociated(resolve, reject);
						})
						.catch((err) => {
							if (err.response.status === 412) {
								HandleOnError(err.response.data);
								console.log(err.response.data);
							} else {
								console.log('error');
								HandleOnError(
									formatterText(
										'alert.message.failed.general',
										'Error al crear el registro, por favor intente nuevamente.'
									)
								);
							}
						});
				});
			}
		});
	};

	const [selectValues, setSelectValues] = useState([]);
	const [prevSelectValues, setPrevSelectValues] = useState([]);
	const [categoryService, setCategoryService] = useState({});

	const handleChangeMulti = (val) => {
		setSelectValues(val);
	};

	const structurePrimaryMinimalTable = (data, array) => {
		data.forEach((item) => {
			array.push({
				idAssociate: item.idUsuarioUnidadNegocio,
				id: item.idUnidadNegocio,
				unityName: item.nombreUnidadNegocio,
				code: item.codigoUnidadNegocio,
				market: item.nombreMercado !== null ? item.nombreMercado : 'Sin mercado asociado',
				country: item.nombrePais !== null ? item.nombrePais : 'Sin país asociado'
			});
		});
		return array;
	};

	const structureSecondaryMinimalTable = (data, array) => {
		data.forEach((item) => {
			array.push({
				idAssociate: item.idUsuarioSucursal,
				id: item.idSucursal,
				nombreCliente: item.nombreRazonSocial,
				tipoId: {
					value: '',
					label: item.abreviaturaTipoDocumento
				},
				numberId: item.identificacionCliente,
				citySucursal: {
					value: '',
					label: item.nombreMunicipio
				},
				departamento: {
					value: '',
					label: item.nombreDepartamento
				},
				pais: {
					value: '',
					label: item.nombrePais
				}
			});
		});
		return array;
	};

	const structureThirdMinimalTable = (data, array) => {
		data.forEach((item) => {
			array.push({
				id: item.idCoberturaTecnico,
				citySucursal: {
					value: item.idMunicipio,
					label: item.nombreMunicipio
				},
				departamento: {
					value: item.idDepartamento,
					label: item.nombreDepartamento
				},
				pais: {
					value: item.idPais,
					label: item.nombrePais
				},
				fecha: dateFormat(item.fechaRegistro, 'yyyy/mm/dd - h:MM:ss TT')
			});
		});
		return array;
	};

	const getAllAssociated = (idUser) => {
		if (idUser !== null) {
			RequestUseCase.get(endpoints.associateDataTechnical.getUnityBusinessById(idUser)).then(
				(res) => {
					const newArray = [];
					if (res.length > 0) {
						const dataPrimary = structurePrimaryMinimalTable(res, newArray);
						setPrimaryMinimalTable(dataPrimary);
						setPrevPrimaryMinimalTable(dataPrimary);
						setSearchUnitBussines(dataPrimary);
					}
				}
			);
			RequestUseCase.get(endpoints.associateDataTechnical.getSucursalById(idUser)).then((res) => {
				const newArray = [];
				if (res.length > 0) {
					const dataPrimary = structureSecondaryMinimalTable(res, newArray);
					setSecondMinimalTable(dataPrimary);
					setPrevSecondMinimalTable(dataPrimary);
					setSearchSucursal(dataPrimary);
				}
			});
			RequestUseCase.get(endpoints.associateDataTechnical.getCoverageById(id)).then((res) => {
				const newArray = [];
				if (res.length > 0) {
					const dataPrimary = structureThirdMinimalTable(res, newArray);
					setThirdMinimalTable(dataPrimary);
					setPrevThirdMinimalTable(dataPrimary);
					setSearchCoverage(dataPrimary);
				}
			});
		}
	};

	// funcion que devuelva los datos que no se encuentran en un array buscando por un id
	const getDiference = (array2, array) => {
		const newArray = [];
		array.forEach((item) => {
			const found = array2.find((element) => element.id === item.id);
			if (found === undefined) {
				newArray.push(item);
			}
		});
		return newArray;
	};

	// funcion que devuelva los datos nuevos que se encuentran en un array buscando por un id y que no se encuentran en el otro array
	const getNewData = (array2, array) => {
		const newArray = [];
		array2.forEach((item) => {
			const found = array.find((element) => element.id === item.id);
			if (found === undefined) {
				newArray.push(item);
			}
		});
		return newArray;
	};

	const uploadNewFile = async (file) => {
		fetchData({
			url: endpoints.UploadFiles.save,
			method: 'post',
			body: {
				idOrigen: formData.idTecnico,
				idTipoOrigenArchivo: 6, // 6 = Tecnico
				archivos: [file]
			}
		}).then(() => {
			CustomAlert('confirm_msg', {
				icon: 'success',
				title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
				text: formatterText('alert.title.confirm.add.files'),
				confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
				allowOutsideClick: false,
				executeFunction: () => getDataAttachedFiles(id)
			});
		});
	};

	const getDataAttachedFiles = async (id) => {
		RequestUseCase.get(endpoints.UploadFiles.findArchivosById({
				idOrigen: id,
				idTipoOrigenArchivo: 6
			})
		).then((response) => {
			const files = response.map((file) => {
				return {
					name: file,
					url: file
				};
			});
			setCurrentFiles(files);
		});
	};

	// delete vehicule asociated
	const deleteVehicule = (val) => {
		fetchData({ url: endpoints.institutions.deleteVehicle(val.idVehiculo), method: 'delete' }).then(
			(data) => {
				const { error } = data;
				if (error === '200') {
					successRemoveItem();
					getDataVehicles(id);
				}
			}
		);
	};

	return {
		archivos,
		categoryLicense,
		categoryService,
		deleteVehicule,
		formatterText,
		formData,
		handleAddFamily,
		handleChangeModel,
		handleChangeMulti,
		handleChangeRelative,
		handleChangeSelect,
		handleSubmit,
		loading,
		messageError,
		messageErrorPlaca,
		myForm,
		navigate,
		primaryMinimalTable,
		secondMinimalTable,
		selectValues,
		setCurrentFiles,
		setPrimaryMinimalTable,
		setSecondMinimalTable,
		setThirdMinimalTable,
		show,
		thirdMinimalTable,
		TITLE_TABLE,
		uploadNewFile,
		vehicles,
		vehiclesBrand
	};
}
