import { useContext, useEffect, useState } from 'react';
// Import Contexts
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import Hooks
import useAxios from 'hooks/useAxios';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
// Import Components
import { FilterActive } from 'common/validators/FilterActives';
import HandleInput from 'common/validators/HandleInput';
import HandleOnError from 'common/validators/HandleOnError';
import {
	ADDRESSREGEX,
	CCREGEX,
	NUMBERREGEX,
	TEXTREGEX,
	TEXTONLYONESPACEREGEX
} from 'common/validators/Regex';
import CustomAlert from 'components/CustomAlert';
// Import Libs
import { useForm } from '@formiz/core';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
// Import Services
import { useSeachContext } from 'context/SearchContext';
import paths from 'services/paths';
import useGetClientFormOptionsData from '../hooks/useGetClientFormOptionsData';

const MODEL = {
	idTipoDocumentoAsesor: '',
	identificacion: '',
	genero: '',
	nombreAsesor: '',
	idCargo: '',
	emailAsesor: '',
	correoAlternoAsesor: '',
	telefonoAsesor: '',
	telefonoAlternoAsesor: '',
	idMunicipio: '',
	estado: 1
};

export default function CreateClientViewModel({ setIsOpenCreateClient }) {
	const [open, setOpen] = useState(false);
	const { COOKIE_USER, RequestUseCase, endpoints } = useAxios();
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const [dialog, setDialog] = useState({
		text: '',
		active: false,
		function: null
	});
	const closeDialog = () => {
		setDialog({ ...dialog, active: false });
	};

	const { dataTableBusinessUnit, setDataTableBusinessUnit } = useSeachContext();

	const { setDataTable } = useSeachContext();

	const {
		currentDataTable,
		setCurrentDataTable,
		otherCurrentDataTable,
		setOtherCurrentDataTable,
		selectedTable,
		setSelectedTable,
		setAuxId
	} = useContext(TableMinimalContext);

	const { loading } = useGetData();
	const myForm = useForm();
	const navigate = useNavigate();
	const { formatterText, noFilledContent, newItemCreated, customSB } = useLangv2();

	const { selectData, setSelectData } = useGetClientFormOptionsData();
	const [archivos, setCurrentFiles] = useState([]);
	const [currentBranchOffice, setCurrentBranchOffice] = useState({
		direccion: '',
		datosAdicionales: '',
		observaciones: '',
		estado: 1,
		idCiudadMunicipio: '',
		idSector: ''
	});

	const [loadingData, setLoadingData] = useState(true);

	const [error, setError] = useState('');
	const [altErr, setAltErr] = useState('');

	const titlesTableBranchOffice = [
		formatterText('tab.title.direccion'),
		formatterText('tab.title.adiconaldatos'),
		formatterText('tab.title.observaciones'),
		formatterText('table.title.state'),
		formatterText('table.actions')
	];

	const titlesTableAdvisers = [
		formatterText('table.title.advisor.name'),
		formatterText('table.title.advisor.document'),
		formatterText('table.title.advisor.genre'),
		formatterText('table.title.advisor.id'),
		formatterText('table.title.advisor.charge'),
		formatterText('table.title.advisor.mail'),
		formatterText('table.title.advisor.mail.alt'),
		formatterText('table.title.advisor.phone'),
		formatterText('table.title.advisor.phone.alt'),
		formatterText('table.title.state'),
		formatterText('table.actions')
	];

	const titlesTableBusinessUnit = [
		formatterText('tab.title.pricelist.name'),
		formatterText('table.actions')
	];

	useEffect(() => {
		getData();
		getListPrices();
		setAuxId(null);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleAddBusinessUnit = (form) => {
		//Obtener el valor de la unidad de negocio del formulario
		const value = form.fields?.idUnidadNegocio.value;

		//Verificar si value tiene un valor antes de continuar
		if (!value) {
			return;
		}

		//Verificar si la unidad de negocio ya existe en dataTableBusinessUnit
		const exist = dataTableBusinessUnit.some((current) => current.idUnidadNegocio === value);

		//Buscar la unidad de negocio en units
		if (!exist) {
			const unitBusiness = units.find((current) => current.value === value);

			//Agregar la unidad de negocio a dataTableBusinessUnit
			if (unitBusiness) {
				const newDataRow = {
					nombre: unitBusiness.label,
					idUnidadNegocio: unitBusiness.value
				};
				// setDataTableBusinessUnit([...dataTableBusinessUnit, newDataRow]);
				setDataTable([...dataTableBusinessUnit, newDataRow]);
				setDataTableBusinessUnit((prevData) => [
					...prevData,
					{ nombre: unitBusiness.label, idUnidadNegocio: unitBusiness.value }
				]);
			}
		}
	};

	const handleDelete = (item) => {
		const filterBusinessUnit = dataTableBusinessUnit.filter(
			(businessUnit) => businessUnit.idUnidadNegocio != item.idUnidadNegocio
		);
		setDataTable(filterBusinessUnit);
		setDataTableBusinessUnit(filterBusinessUnit);
	};

	const closeModal = () => {
		if (setIsOpenCreateClient instanceof Function) setIsOpenCreateClient(false);
		else navigate(paths.clients);
	};

	const handleSubmit = (values) => {
		if (dataTableBusinessUnit.length == 0) {
			Swal.fire({
				icon: 'warning',
				title: 'Error',
				text: formatterText('p.label.title.unidadNegocioRequerida')
			});
		} else if (!auxData.idListaPrecios) {
			Swal.fire({
				icon: 'warning',
				title: 'Error',
				text: formatterText('p.price.list.is.requared')
			});
		} else {
			const data = {
				clienteDto: {
					idCliente: null,
					idTipoDocumento: {
						idTipoDocumento: values.idTipoDocumento
					},
					identificacion: values.identificacion.replaceAll(/\s{2,}/gi, ' '),
					nombreRazonSocial: values.nombreRazonSocial.replaceAll(/\s{2,}/gi, ' '),
					idGenero: {
						idGenero: values.idGenero
					},
					idTipoCliente: {
						idTipoCliente: values.idTipoCliente
					},
					idMercado: {
						idMercado: values.idMercado
					},

					idTipoFacturacion: {
						idTipoFacturacion: values.idTipoFacturacion
					},
					celular: values.celular.replaceAll(/\s{2,}/gi, ' '),
					telefono: values.telefono.replaceAll(/\s{2,}/gi, ' '),
					sitioWeb: values.sitioWeb?.replaceAll(/\s{2,}/gi, ' ') || '',
					estado: 1,
					correo: values.correo.replaceAll(/\s{2,}/gi, ' '),
					usuarioCreacion: COOKIE_USER,
					clienteEspecial: values.clienteEspecial
				},
				unidadNegocios: dataTableBusinessUnit.map((item) => ({
					idUnidadNegocio: item.idUnidadNegocio
				}))
			};

			postItem(data, values);
		}
	};

	const postItem = (data, values) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					RequestUseCase.post(endpoints.clients.addClient, data)
						.then((res) => {
							associatePrices(res.cliente.idCliente, resolve, reject);
							createBranchOffice(res.cliente, values, resolve, reject);
							CustomAlert('confirm_msg', {
								icon: 'success',
								title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
								text: formatterText(
									'alert.message.confirm.updated.general',
									'El registro se ha actualizado correctamente'
								),
								confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
								allowOutsideClick: false,
								executeFunction: () => navigate(paths.clients)
							});
						})
						.catch((err) => {
							if (err.response?.data?.message) {
								HandleOnError(formatterText(err.response?.data?.message));
							} else {
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
						})
						.finally(() => closeModal());
				});
			}
		});
	};

	// associate principal price list
	const associatePrices = (id, res, err) => {
		const BODY = {
			idListaPreciosActividadCliente: null,
			idListaPreciosActividad: {
				idListaPreciosActividad: auxData.idListaPrecios.value
			},
			principal: 1,
			idCliente: id,
			usuarioCreacion: COOKIE_USER
		};

		RequestUseCase.post(endpoints.listPriceClient.addListPriceClient, BODY)
			.then(() => {
				customSB(
					'success',
					'snackbar.success.create.principal.price.list',
					'Se ha asociado la lista de precios principal al cliente'
				);
				associateSecondaryPrices(id, res);
			})
			.catch(() => {
				err(
					HandleOnError(
						formatterText('alert.message.failed.general', 'Error al asociar la lista de precios.')
					)
				);
			});
	};

	// associates secondary price lists
	const associateSecondaryPrices = (id, resolve) => {
		if (selectValues.length !== 0) {
			const promesas = selectValues.map(
				(item) =>
					new Promise((resl, rej) => {
						const data = {
							idListaPreciosActividadCliente: null,
							idListaPreciosActividad: { idListaPreciosActividad: item.value },
							idCliente: id,
							principal: 0,
							usuarioCreacion: COOKIE_USER
						};
						RequestUseCase.post(endpoints.listPriceClient.addListPriceClient, data)
							.then((res) => {
								resl(res);
							})
							.catch((err) => {
								rej(err);
							});
					})
			);
			Promise.all(promesas)
				.then(() => {
					customSB(
						'success',
						'snackbar.success.create.secondary.price.list',
						'Se han asociado la(s) lista(s) de precios secundaria(s) al cliente'
					);
					resolve(
						CustomAlert('confirm_msg', {
							icon: 'success',
							title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
							text: formatterText(
								'alert.message.confirm.created.general',
								'El registro se ha creado correctamente'
							),
							confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
							allowOutsideClick: false,
							executeFunction: () => navigate(paths.clients)
						})
					);
					uploadFiles(id);
				})
				.catch((err) => {
					if (err.response.status === 412 || err.response.status === 400) {
						HandleOnError(err.response.data);
					} else {
						HandleOnError(
							formatterText(
								'alert.message.failed.general',
								'Error al crear el registro, por favor intente nuevamente.'
							)
						);
					}
				});
		} else {
			uploadFiles(id);
			resolve(
				CustomAlert('confirm_msg', {
					icon: 'success',
					title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
					text: formatterText(
						'alert.message.confirm.created.general',
						'El registro se ha creado correctamente'
					),
					confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
					allowOutsideClick: false,
					executeFunction: () => console.log('User created', true)
				})
			);
		}
	};

	const createBranchOffice = (res, values, resolve, reject) => {
		// TODO: verify associations
		if (currentDataTable.length > 0) {
			const promises = currentDataTable.map((item) => {
				const data = {
					idSucursal: null,
					idCliente: {
						idCliente: res.idCliente
					},
					idDireccion: {
						idDireccion: null,
						idSector: {
							idSector:
								typeof item.idSector === 'string'
									? JSON.parse(item.idSector).value
									: item.idSector.value
						},
						direccion: item.direccion,
						datosAdicionales: item.datosAdicionales,
						observacion: item.observaciones
					},
					telefono: values.telefono,
					sitioWeb: values.sitioWeb
				};

				return RequestUseCase.post(endpoints.branchOffices.addBranchOffice, data);
			});

			Promise.all(promises)
				.then(() => {
					createAdvisor(res, resolve, reject);
					resolve(true);
				})
				.catch(() => {
					reject(HandleOnError('Error al crear el cliente, por favor intente nuevamente'));
				});
		} else {
			resolve(newItemCreated());
		}
	};

	const createAdvisor = (res, resolve, reject) => {
		const promises = otherCurrentDataTable.map((item) => {
			const data = {
				idAsesor: null,
				idCliente: {
					idCliente: res.idCliente
				},
				idTipoDocumento: {
					idTipoDocumento:
						typeof item.idTipoDocumentoAsesor === 'string'
							? JSON.parse(item.idTipoDocumentoAsesor).value
							: item.idTipoDocumentoAsesor.value
				},
				identificacion: item.identificacion,
				nombres: item.nombreAsesor,
				idGenero: {
					idGenero:
						typeof item.genero === 'string' ? JSON.parse(item.genero).value : item.genero.value
				},
				idCargo: {
					idCargo:
						typeof item.idCargo === 'string' ? JSON.parse(item.idCargo).value : item.idCargo.value
				},
				idMunicipio: {
					idMunicipio:
						typeof item.idMunicipio === 'string'
							? JSON.parse(item.idMunicipio).value
							: item.idMunicipio.value
				},
				telefono: item.telefonoAsesor,
				telefonoAlterno: item.telefonoAlternoAsesor,
				correo: item.emailAsesor,
				correoAlterno: item.correoAlternoAsesor
			};

			return RequestUseCase.post(endpoints.advisers.addAdviser, data);
		});

		Promise.all(promises)
			.then((resp) => {
				resolve(true);
				console.log('estos son clientes asesores', resp);
			})
			.catch(() => {
				reject(HandleOnError('Error al crear el cliente, por favor intente nuevamente'));
			});
	};

	const getData = () => {
		setLoadingData(true);

		const promesas = [
			RequestUseCase.get(endpoints.markets.getAllMarkets),
			RequestUseCase.get(endpoints.institutions.getAllTypeDocument),
			RequestUseCase.get(endpoints.institutions.getAllGenres),
			RequestUseCase.get(endpoints.billingTypes.getAllBillingTypes),
			RequestUseCase.get(endpoints.institutions.getAllTypeAccounts),
			RequestUseCase.get(endpoints.zones.getAllMunicipalities),
			RequestUseCase.get(endpoints.clientTypes.getAllClientTypes),
			RequestUseCase.get(endpoints.businessUnit.getAllBusinessUnits),
			RequestUseCase.get(endpoints.zones.getAllSectors),
			RequestUseCase.get(endpoints.cargos.getAllCargos),
			RequestUseCase.get(endpoints.zones.getAllCountries)
		];

		Promise.all(promesas).then((values) => {
			setLoadingData(false);
			setSelectData({
				...selectData,
				documentTypes: values[1],
				markets: [], //FilterActives(values[0]),
				genres: values[2],
				billingTypes: FilterActive(values[3]),
				accountTypes: values[4],
				municipalities: FilterActive(values[5]),
				clientTypes: values[6],
				businessUnits: FilterActive(values[7]),
				cargos: FilterActive(values[9]),
				countries: FilterActive(values[10])
			});
		});
	};

	const getMarketByIdCountry = (idCountry) => {
		RequestUseCase.get(endpoints.markets.getMarketByCountry(idCountry)).then((res) =>
			setSelectData({
				...selectData,
				markets: FilterActive(res)
			})
		);
	};

	const AllSectorsForSelect = () => {
		RequestUseCase.get(endpoints.zones.getAllSectors).then((data) => {
			//convert to json the current branch office id
			const idMunicipio = JSON.parse(currentBranchOffice.idCiudadMunicipio).value;
			// create new array
			const newArray = [];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1 && item.idMunicipio.idMunicipio == idMunicipio) {
					newArray.push({
						idSector: item.idSector,
						nombre: item.nombre
					});
				}
			});
			// set the values of the select
			setSelectData({
				...selectData,
				sectors: newArray
			});
		});
	};

	useEffect(() => {
		if (currentBranchOffice.idCiudadMunicipio !== '') {
			AllSectorsForSelect();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentBranchOffice.idCiudadMunicipio]);

	useEffect(() => {
		if (myForm.fields?.idPais?.value !== null && myForm.fields?.idPais?.value !== undefined) {
			getMarketByIdCountry(myForm.fields?.idPais?.value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [myForm.fields?.idPais?.value]);

	//////////////////////////SURCURSAL

	const handleChangeSucursal = (e) => {
		switch (e.target.name) {
			case 'direccion':
				HandleInput(e, ADDRESSREGEX, currentBranchOffice, setCurrentBranchOffice);
				break;
			case 'observaciones':
				HandleInput(e, ADDRESSREGEX, currentBranchOffice, setCurrentBranchOffice);
				break;
			case 'datosAdicionales':
				HandleInput(e, ADDRESSREGEX, currentBranchOffice, setCurrentBranchOffice);
				break;

			default:
				HandleInput(e, TEXTREGEX, currentBranchOffice, setCurrentBranchOffice);
				break;
		}
	};

	const handleChangeSelect = (selectedOption) => {
		setCurrentBranchOffice({
			...currentBranchOffice,
			[selectedOption.target.name]: selectedOption.target.value
		});
	};

	const handleAddBranchOffice = () => {
		if (
			currentBranchOffice.idCiudadMunicipio !== '' &&
			currentBranchOffice.idSector !== '' &&
			currentBranchOffice.direccion !== ''
		) {
			if (currentBranchOffice.datosAdicionales === '') {
				setCurrentBranchOffice((prevState) => ({
					...prevState,
					datosAdicionales: ' '
				}));
			}

			if (currentBranchOffice.observaciones === '') {
				setCurrentBranchOffice((prevState) => ({
					...prevState,
					observaciones: ' '
				}));
			}

			setCurrentDataTable([...currentDataTable, currentBranchOffice]);
			setDataTable([...currentDataTable]);

			setCurrentBranchOffice({
				direccion: '',
				datosAdicionales: '',
				observaciones: '',
				estado: 1,
				idCiudadMunicipio: currentBranchOffice.idCiudadMunicipio,
				idSector: currentBranchOffice.idSector
			});
		} else {
			HandleOnError(formatterText('p.label.title.debesCompletarTodosLosCampos'));
		}
	};

	const [currentAsesor, setCurrentAsesor] = useState(MODEL);

	const validateRegex = (e, regex) => {
		const { value = '', name } = e.target;

		const val = value.replaceAll(/\s{2,}/gi, ' ');

		if (val.match(regex)) {
			setCurrentAsesor({
				...currentAsesor,
				[name]: val
			});
		}
	};
	const handleChangeAsesor = (e) => {
		//REGEX QUE SOLO PERMITA LETRAS Y ESPACIOS
		switch (e.target.name) {
			case 'identificacion':
				validateRegex(e, CCREGEX);
				break;
			case 'nombreAsesor':
				validateRegex(e, TEXTONLYONESPACEREGEX);
				break;
			case 'telefonoAsesor':
				validateRegex(e, NUMBERREGEX);
				break;
			case 'telefonoAlternoAsesor':
				validateRegex(e, NUMBERREGEX);
				break;

			default:
				setCurrentAsesor({
					...currentAsesor,
					[e.target.name]: e.target.value.replaceAll(/\s{2,}/gi, ' ')
				});
				break;
		}
	};

	const handleBlurMailAsesor = (e) => {
		if (!error && e.target.value.trim() === '') {
			setError('');
		}
	};

	const handleBlurMailAltAsesor = (e) => {
		if (!altErr && e.target.value.trim() === '') {
			setAltErr('');
		}
	};

	const handleChangeSelectAsesor = (selectedOption) => {
		setCurrentAsesor({
			...currentAsesor,
			[selectedOption.target.name]: selectedOption.target.value
		});
	};

	const handleAddAsesor = () => {
		if (
			currentAsesor.idTipoDocumentoAsesor !== '' &&
			currentAsesor.identificacion !== '' &&
			currentAsesor.genero !== '' &&
			currentAsesor.nombreAsesor !== '' &&
			currentAsesor.emailAsesor !== '' &&
			currentAsesor.correoAlternoAsesor !== '' &&
			currentAsesor.telefonoAsesor !== '' &&
			currentAsesor.telefonoAlternoAsesor !== '' &&
			currentAsesor.idMunicipio !== '' &&
			currentAsesor.idCargo !== '' &&
			!error &&
			!altErr
		) {
			if (currentAsesor.emailAsesor === currentAsesor.correoAlternoAsesor) {
				customSB('warning', 'warning.alert.same.mails');
				return;
			}
			if (currentAsesor.telefonoAsesor === currentAsesor.telefonoAlternoAsesor) {
				customSB('warning', 'warning.alert.same.tlf');
				return;
			}
			setOtherCurrentDataTable([...otherCurrentDataTable, currentAsesor]);
			setCurrentAsesor(MODEL);
		} else {
			noFilledContent();
		}
	};

	const [selectValues, setSelectValues] = useState([]);
	const [selectorValues] = useState({});
	const [listPrices, setListPrices] = useState({});
	const [secondaryPrices, setSecondaryPrices] = useState({});
	const [auxData, setAuxData] = useState({
		idListaPrecios: 0
	});

	//Resetea los precios secundarios si se modifica el precio principal
	useEffect(() => {
		getSecondaryPrices();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auxData.idListaPrecios]);

	// handler for second prices to update the list and values
	const getSecondaryPrices = () => {
		// Check the number of secondary prices, to reset them
		if (selectValues.length !== 0) {
			const sameList = selectValues.filter((item) => item === auxData.idListaPrecios);
			const exceptList = selectValues.filter((item) => item !== auxData.idListaPrecios);
			// If the parent price is found to be equal to any of the children, it is reset and removes this
			if (sameList.length >= 1) {
				setSecondaryPrices(exceptList);
				setSelectValues(exceptList);
			}
		}
		// If any parent value was selected, display available child prices
		if (auxData.idListaPrecios !== 0) {
			const filter = listPrices.filter((item) => item !== auxData.idListaPrecios);
			setSecondaryPrices(filter);
		}
	};

	// get list prices
	const getListPrices = () => {
		RequestUseCase.get(endpoints.listPrices.getAllListPrices).then((data) => {
			const newArray = [];
			data.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						value: item.idListaPreciosActividad,
						label: `${item.nombre} - ${item.codigo}`,
						isFixed: true
					});
				}
			});
			setListPrices(newArray);
		});
	};

	const handleChangeMulti = (val) => {
		setSelectValues(val);
	};

	const uploadFiles = (id) => {
		return new Promise((resolve, reject) => {
			if (archivos.length > 0) {
				RequestUseCase.post(endpoints.UploadFiles.save, {
					idOrigen: id,
					idTipoOrigenArchivo: 7, // 7 = Clientes
					archivos
				})
					.then(() => {
						resolve(
							CustomAlert('confirm_msg', {
								icon: 'success',
								title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
								text: formatterText(
									'alert.message.associations.general',
									'Las asociaciones se crearon correctamente'
								),
								confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
								allowOutsideClick: false,
								executeFunction: () => navigate(paths.clients)
							})
						);
					})
					.catch((err) => {
						reject(err);
					});
			} else {
				resolve(
					CustomAlert('confirm_msg', {
						icon: 'success',
						title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
						text: formatterText(
							'alert.message.associations.general',
							'Las asociaciones se crearon correctamente'
						),
						confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
						allowOutsideClick: false,
						executeFunction: () => navigate(paths.clients)
					})
				);
			}
		});
	};

	const [units, setUnits] = useState([]);

	useEffect(() => {
		if (myForm.fields?.idMercado?.value !== null && myForm.fields?.idMercado?.value !== undefined) {
			setClearValue(false);
			const result = [];
			RequestUseCase.get(
				endpoints.businessUnit.getBusinessUnitByMarket(myForm.fields.idMercado.value)
			)
				.then((resp) => {
					resp.map((item) => result.push({ label: item.nombre, value: item.idUnidadNegocio }));
					setClearValue(true);
				})
				.catch(() => {
					HandleOnError('No hay unidades asociadas');
				});
			setUnits(result);
		}
	}, [myForm.fields?.idMercado?.value]);

	// aux val to clear the value of the select
	const [clearValue, setClearValue] = useState(true);

	console.log('auxData:', auxData.idListaPrecios);

	return {
		myForm,
		loading,
		loadingData,
		selectData,
		listPrices,
		secondaryPrices,
		units,
		dataTableBusinessUnit,
		titlesTableBusinessUnit,
		dialog,
		closeDialog,
		handleDelete,
		handleOpen,
		currentDataTable,
		handleAddBusinessUnit,
		clearValue,
		auxData,
		setAuxData,
		setSelectedTable,
		selectValues,
		selectorValues,
		handleChangeMulti,
		handleAddBranchOffice,
		handleAddAsesor,
		currentBranchOffice,
		currentAsesor,
		setCurrentAsesor,
		otherCurrentDataTable,
		handleChangeSelect,
		handleChangeSucursal,
		handleChangeSelectAsesor,
		handleBlurMailAsesor,
		handleBlurMailAltAsesor,
		error,
		setError,
		altErr,
		setAltErr,
		archivos,
		setCurrentFiles,
		handleSubmit,
		formatterText,
		titlesTableBranchOffice,
		titlesTableAdvisers,
		handleChangeAsesor,
		closeModal,
		handleClose,
		selectedTable,
		open
	};
}
