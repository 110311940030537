export const parseDataToSubmit = (values) => {
	const dataToSend = {
		...values,
		estado: Number(values.active),
		codigoSector: values.codigoSector.toUpperCase(),
		idMunicipio: values.permissions[0]
	};

	return dataToSend;
};
