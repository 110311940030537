import useLangv2 from 'hooks/useLangv2';
import PropTypes from 'prop-types';

export const Input = ({
	active = 'on',
	area = false,
	className = '',
	disabled = false,
	error,
	labelText,
	lowerCase = false,
	name,
	onBlur,
	onChange,
	placeholder,
	styleName,
	touched,
	type,
	value
}) => {
	const { formatterText } = useLangv2();

	const _onChange = ({ target, ...e }) => {
		const val = lowerCase ? target?.value?.toLowerCase() : target?.value;
		onChange({
			...e,
			target: {
				...target,
				name: target?.name,
				value: (val || '').replaceAll(/\s{2,}/gi, ' ')
			}
		});
	};

	return (
		<section className="d-flex flex-start margin-top">
			<span className="text-inline-md" htmlFor={name}>
				{labelText}
			</span>
			<section className="w100-container">
				{!area ? (
					<input
						id={name}
						name={name}
						type={type}
						onChange={_onChange}
						placeholder={placeholder}
						onBlur={onBlur}
						value={value}
						className={`input-default-3c ${className} ${disabled ? 'disabled' : ''}`}
						disabled={disabled}
						autoComplete={active}
					/>
				) : (
					<textarea
						id={name}
						value={value || ''}
						name={name}
						onChange={_onChange}
						onBlur={onBlur}
						placeholder={placeholder}
						className={`${styleName || 'input-textarea-sm'}  ${disabled ? 'disabled' : ''}`}
						style={{
							resize: 'none',
							borderRadius: '5px',
							boxShadow: '0px 4px 8px rgba(0, 174, 142, 0.2)',
							padding: '1rem',
							display: 'block',
							marginTop: '10px',
							width: '100%'
						}}
						disabled={disabled}
					/>
				)}
				{typeof error === 'string' && touched && (
					<div style={{ marginTop: '3px' }}>
						<span id={'email-error'} className="error-msg">
							{formatterText(error)}
						</span>
					</div>
				)}
			</section>
		</section>
	);
};

Input.propTypes = {
	active: PropTypes.string,
	area: PropTypes.bool,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	error: PropTypes.string,
	labelText: PropTypes.string,
	lowerCase: PropTypes.bool,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	styleName: PropTypes.string,
	touched: PropTypes.bool,
	type: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
