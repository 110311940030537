import useAxios from 'hooks/useAxios';
import { useContext, useEffect, useState } from 'react';
import { TableMinimalContext } from 'context/TableMinimalContext';
import useGetData from 'hooks/useGetData';
import useLang from 'hooks/useLang';
import { useNavigate } from 'react-router-dom';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import { validateDuplicateCode } from 'common/validators/ValidateDuplicates';
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import { useForm } from '@formiz/core';
import { useSeachContext } from 'context/SearchContext';
import paths from 'services/paths';

export default function CreateListPriceViewModel() {
	const { RequestUseCase, endpoints } = useAxios();

	// Modal config
	const [open, setOpen] = useState(false);
	const [valueMinimalPrice, setMinimalPrice] = useState('');
	const [valueTax, setValueTax] = useState('');

	// Formiz object
	const myForm = useForm();

	// Call context TableMinimalContext
	const {
		currentDataTable = [],
		setCurrentDataTable,
		setResultsTableSearch
	} = useContext(TableMinimalContext);

	const { dataTable, setDataTable, setSearchResults } = useSeachContext();

	const navigate = useNavigate();
	// useLanguage
	const { formatterText } = useLang();
	const titlesTableCostProduct = [
		formatterText('p.label.title.tipoServicio'),
		formatterText('p.label.title.actividadAsociada'),
		formatterText('p.label.title.unidadMedida'),
		formatterText('p.label.title.valor'),
		formatterText('p.label.title.impuesto'),
		formatterText('p.label.title.acciones')
	];
	// Example of form data
	const [formData, setFormData] = useState({
		nombre: '',
		codigo: ''
	});
	const [currentItemMinimal, setCurrentItemMinimal] = useState({
		idPrecioAsociado: uuidv4(),
		idTipoServicio: 0,
		idActividadAsociada: 0,
		valor: 0,
		impuesto: 0,
		idUnidadMedida: 0
	});
	// Los valores que usará el select
	const [selectedSearch, setSearchSelected] = useState([
		{
			tipoServicio: [],
			actividadAsociada: [],
			unidadMedida: [],
			valor: 0,
			actividadesServicios: []
		}
	]);

	const [auxData, setAuxData] = useState({
		idTipoServicio: 0,
		idActividadAsociada: 0,
		valor: 0,
		idUnidadMedida: 0,
		idMoneda: 0
	});

	// espera a que cargue los valores del multiselect
	const { loading, error, toggleLoading, displayMessage, displayLoading } = useGetData();

	const [selectedOption, setSelectedOption] = useState({
		idUnidadMedida: {
			value: null,
			label: formatterText('p.label.title.seleccioneUnidadMedida')
		},
		idTipoServicio: {
			value: null,
			label: formatterText('p.label.title.seleccioneTipoServicio')
		},
		idActividadAsociada: {
			value: null,
			label: formatterText('p.label.title.seleccioneActividadAsociada')
		}
	});

	const [service, setService] = useState([]);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		getMonedas();
		getUnitMeasure();
		getTypeService();
	}, []);

	const getMonedas = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.listPrices.getAllMonedas)
			.then((res) => {
				const newArray = [];
				// iterate response and get only the values that are active
				res.map((item) => {
					const data = {
						value: item.idMoneda,
						label: `${item.nombre} - ${item.codigo}`,
						isFixed: true
					};
					return newArray.push(data);
				});
				setSearchSelected((prev) => ({
					...prev,
					tipoMonedas: newArray
				}));
				toggleLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toggleLoading(false);
			});
	};
	const getUnitMeasure = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.institutions.getUnidadMedida)
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idUnidadMedida,
							label: `${item.descripcion} - ${item.abreviatura}`,
							isFixed: true
						});
					}
				});
				setSearchSelected((prev) => ({
					...prev,
					unidadMedida: newArray
				}));
				toggleLoading(false);
			})
			.catch(() => {
				toggleLoading(false);
			});
	};

	const getTypeService = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.typeService.getAllTypeService)
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idTipoServicio,
							label: `${item.nombre}`,
							isFixed: true
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					tipoServicio: newArray
				}));
				setService((prev) => ({
					...prev,
					tipoServicio: newArray
				}));
				toggleLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toggleLoading(false);
			});
	};

	const formatCurrencyToRequest = (value) => {
		const parts = value.toString().split('.');
		let formattedValue = `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '')}`;

		if (parts.length > 1) {
			formattedValue += `.${parts[1].slice(0, 2)}`;
		}

		return formattedValue;
	};

	const formatIvaToRequest = (value) => {
		const roundedValue = Number(value).toFixed(2);
		const parts = roundedValue.toString().split('.');
		let formattedValue = `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '')}`;

		if (parts.length > 1) {
			formattedValue += `.${parts[1]}`;
		}
		return formattedValue;
	};

	const getAllActivitiesById = (selectValue) => {
		// Set the value of datas into []
		RequestUseCase.get(
			endpoints.typoServiceActivities.getTypeServiceActivitiesByTypeService(selectValue.value)
		)
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					newArray.push({
						value: `${item.idActividad.idActividad}-${item.idTipoServicioActividad}`,
						label: `${item.idActividad.nombre} - ${item.idEtapaServicio.nombre}`,
						isFixed: true
					});
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					actividadesServicios: newArray
				}));
				toggleLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toggleLoading(false);
			});
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	const handleCostoChange = (event) => {
		const inputCosto = event.target.value;

		const costoFormateado = inputCosto.replace(/[^\d,]/g, '').replace(/(,.*)\,/g, '$1');

		setMinimalPrice(costoFormateado);

		const costoDecimal = parseFloat(costoFormateado.replace(',', '.'));
		const costoConDosDecimales = costoDecimal.toLocaleString('es-ES', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});

		setCurrentItemMinimal({
			...currentItemMinimal,
			[event.target.name]: `$${costoConDosDecimales}`
		});
	};

	const handleIvaChange = (event) => {
		const inputIva = event.target.value;

		const ivaFormateado = inputIva.replace(/[^\d,]/g, '');
		setValueTax(ivaFormateado);

		const ivaDecimal = parseFloat(ivaFormateado.replace(',', '.'));
		const ivaConDosDecimales = ivaDecimal.toLocaleString('es-ES', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});

		setCurrentItemMinimal({
			...currentItemMinimal,
			[event.target.name]: ivaConDosDecimales
		});
	};

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		const data = {
			...formData,
			nombre: myForm.fields?.nombre.value,
			idMoneda: auxData.idMoneda.value,
			usuarioCreacion: Cookie.get('idUsuario')
		};
		if (
			validateDuplicateCode(
				formData.codigo,
				formatterText('p.label.title.codigoExistenteElemento'),
				() => RequestUseCase.get(endpoints.listPrices.getAllListPrices)
			)
		) {
			createProduct(data);
		}
	};

	const handleFormChange = (values) => {
		setFormData((prevData) => ({ ...prevData, ...values }));
	};

	const createProduct = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general'),
			text: formatterText('alert.description.create.general'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					// this service create the item
					RequestUseCase.post(endpoints.listPrices.addListPrice, data)
						.then((res) => {
							// aqui se llama el servicio para asociar el producto con el proveedor
							associateSinglePrice(res.idListaPreciosActividad);
							resolve();
						})
						.catch((err) => {
							reject(HandleOnError(formatterText('alert.message.failed.general')));
							console.error(err);
						});
				});
			}
		});
	};

	const associateSinglePrice = (id) => {
		// FIX: associations
		if (dataTable.length >= 1) {
			const promesas = dataTable.map(
				(item) =>
					new Promise((resl, rej) => {
						const ACTIVITY_IDS = item.idActividadAsociada?.value.split('-');
						const data = {
							idListaPreciosActividadDetalle: null,
							idListaPreciosActividad: id,
							idTipoServicio: item.idTipoServicio.value,
							idActividad: parseInt(ACTIVITY_IDS[0]),
							idTipoServicioActividad: parseInt(ACTIVITY_IDS[1]),
							idUnidadMedida: item.idUnidadMedida.value,
							valor: parseFloat(
								formatCurrencyToRequest(
									item.valor.replace('$', '').replace(/,/g, '').slice(0, -2)
								) +
									'.' +
									parseFloat(formatCurrencyToRequest(item.valor.replace('$', '').slice(-2)))
							),
							impuesto: Number(formatIvaToRequest(item.impuesto.replace(',', '.')))
						};
						RequestUseCase.post(endpoints.associatePrices.addAssociate, data)
							.then((res) => {
								setCurrentItemMinimal({
									idTipoServicio: currentItemMinimal.idTipoServicio,
									idUnidadMedida: currentItemMinimal.idUnidadMedida,
									idActividadAsociada: currentItemMinimal.idActividadAsociada,
									idPrecioAsociado: res.idListaPreciosActividadDetalle
								});
								setCurrentDataTable([...currentDataTable, data]);
								setResultsTableSearch([...currentDataTable, data]);
								setMinimalPrice('');
								resl(res);
								toggleLoading(false);
							})
							.catch((err) => {
								rej(err);
							});
					})
			);
			Promise.all(promesas)
				.then(() => {
					CustomAlert('confirm_msg', {
						icon: 'success',
						title: formatterText('alert.title.confirm.general'),
						text: formatterText('alert.message.associations.general'),
						confirmButtonText: formatterText('alert.button.continue'),
						allowOutsideClick: false,
						executeFunction: () => navigate(paths.services)
					});
				})
				.catch((err) => {
					if (err.response.status === 412 || err.response.status === 400) {
						HandleOnError(err.response.data);
					} else {
						HandleOnError(formatterText('alert.message.failed.general'));
					}
					console.error(err);
				});
		} else {
			CustomAlert('confirm_msg', {
				icon: 'success',
				title: formatterText('alert.title.confirm.general'),
				text: formatterText('alert.message.associations.general'),
				confirmButtonText: formatterText('alert.button.continue'),
				allowOutsideClick: false,
				executeFunction: () => navigate(paths.services)
			});
		}
	};

	const clearFields = () => {
		const defaultOptionUnitMedida = {
			value: 0,
			label: formatterText('p.label.title.seleccioneUnidadMedida')
		};

		const defaultOptionServicio = {
			value: 0,
			label: formatterText('p.label.title.seleccioneTipoServicio')
		};

		const defaultOptionActivity = {
			value: 0,
			label: formatterText('p.label.title.seleccioneActividadAsociada')
		};

		setSelectedOption({
			idUnidadMedida: defaultOptionUnitMedida,
			idTipoServicio: defaultOptionServicio,
			idActividadAsociada: defaultOptionActivity
		});
		setValueTax('');
		setMinimalPrice('');
	};

	const updateDataTables = (newDataRow, newItemMinimal) => {
		setCurrentDataTable((prevDataTable) => [...prevDataTable, newDataRow]);

		setCurrentItemMinimal(newItemMinimal);

		setResultsTableSearch({ ...newDataRow, idPrecioAsociado: uuidv4() });
		setDataTable([...dataTable, newDataRow]);
		setSearchResults([...currentDataTable, { ...newItemMinimal, ...newDataRow }]);
	};
	const handleAddItemMinimal = () => {
		const { idTipoServicio, idUnidadMedida, idActividadAsociada } = auxData;
		const { valor: currentItemValor, impuesto: currentItemImpuesto } = currentItemMinimal;

		const isValidInput =
			currentItemValor &&
			currentItemImpuesto &&
			idTipoServicio &&
			idUnidadMedida &&
			idActividadAsociada;

		if (isValidInput) {
			clearFields();
			const isDuplicate = dataTable.some(
				(item) =>
					item.idActividadAsociada.value === idActividadAsociada.value &&
					item.idUnidadMedida.value === idUnidadMedida.value &&
					item.idTipoServicio.value === idTipoServicio.value
			);

			if (isDuplicate) {
				CustomAlert('short_msg', {
					icon: 'error',
					title: 'Oops...',
					text: formatterText('p.label.title.valorRepetido')
				});
			} else {
				const formattedValue = `${formatCurrencyToRequest(currentItemValor.replace('.', ','))}`;
				const formattedTax = formatIvaToRequest(valueTax.replace(',', '.'));

				const newDataRow = {
					idTipoServicio,
					idActividadAsociada,
					idUnidadMedida,
					valor: formattedValue,
					impuesto: formattedTax
				};

				const newItemMinimal = {
					...currentItemMinimal,
					idTipoServicio,
					idUnidadMedida,
					idActividadAsociada,
					idPrecioAsociado: uuidv4(),
					valor: formattedValue
				};

				updateDataTables(newDataRow, newItemMinimal);
			}
		} else {
			CustomAlert('short_msg', {
				icon: 'error',
				title: 'Oops...',
				text: formatterText('p.label.title.ingreseTodosLosDatos')
			});
		}
	};

	//FIX Sprint 7
	useEffect(() => {
		if (auxData.idTipoServicio !== 0) {
			setCurrentItemMinimal((prev) => ({
				...prev,
				idActividadAsociada: 0
			}));
			setSearchSelected((prev) => ({
				...prev,
				actividadesServicios: []
			}));
			setAuxData((prev) => ({
				...prev,
				idActividadAsociada: 0
			}));
			getAllActivitiesById(auxData.idTipoServicio);
		}
	}, [auxData.idTipoServicio]);

	const formatIvaField = (value) => {
		const parts = value.toString().split('.');
		let formattedValue = `${value.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;

		if (parts.length > 1) {
			formattedValue += `.${parts[1]}`;
		}
		const splitDecimal = formattedValue.split(',');
		const intPart = splitDecimal[0];
		const floatPart = splitDecimal[1];

		if (floatPart) {
			formattedValue = intPart + ',' + floatPart.substring(0, 2);
		}

		return formattedValue;
	};

	const formatCurrency = (value) => {
		const parts = value.toString().split('.');
		let formattedValue = `$${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;

		if (parts.length > 1) {
			formattedValue += `.${parts[1]}`;
		}
		const splitDecimal = formattedValue.split(',');
		const intPart = splitDecimal[0];
		const floatPart = splitDecimal[1];

		if (floatPart) {
			formattedValue = intPart + ',' + floatPart.substring(0, 2);
		}

		return formattedValue;
	};
	return {
		myForm,
		handleFormChange,
		handleSubmit,
		formatterText,
		handleOpen,
		handleClose,
		handleAddItemMinimal,
		handleCostoChange,
		handleIvaChange,
		selectedSearch,
		auxData,
		loading,
		setAuxData,
		formData,
		handlerTextDescription,
		dataTable,
		titlesTableCostProduct,
		error,
		displayLoading,
		displayMessage,
		setSelectedOption,
		selectedOption,
		formatCurrency,
		valueMinimalPrice,
		formatIvaField,
		valueTax,
		setResultsTableSearch,
		service,
		navigate,
		open
	};
}
