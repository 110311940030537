import React, { useContext, useEffect, useState } from 'react';
// import Hooks
import { TimePicker } from '@material-ui/pickers';
import dateformat from 'dateformat';
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';
import { useParams } from 'react-router';
// import Services
import { FormattedMessage } from 'react-intl';
import endpoints from 'services/api';
// import Styles
import { getValueHour } from 'common/utils/HoursHelper';
import { AppContext } from 'context/AppContext';
import Modal from 'react-responsive-modal';
import { GenerateReport } from 'screens/services/ExtraTabs/Reports';
import styles from 'screens/services/ExtraTabs/Tab.module.css';
import Swal from 'sweetalert2';

const UpdateDatimeAlert = ({
	onClose,
	WayChange = false,
	getDataToUpdate,
	//getAllTechnicalsServices,
	flagStatus = false,
	setFlagStatus,
	idTecnico,
	setCounterActivities,
	getData,
	servicioActividad,
	estadoActividad,
	orden
}) => {
	const { id } = useParams();
	const { fetchData, COOKIE_USER, RequestUseCase } = useAxios();
	const now = new Date();
	const [eventData, setEventData] = useState({
		fechaCita: '',
		idHoraCita: ''
	});
	const [dateValidate, setDateValidate] = useState({
		idHoraCita: ''
	});
	// Stare of date picker
	const [time, setTime] = useState(new Date('0000-01-01T00:00'));
	const { noScheduleTech, updatedItem, customSB, handleRequestError, formatterText } = useLangv2();
	const [open, setOpen] = useState(false);
	const [dataCommon, setDataCommon] = useState();
	const { readyUpdate } = useContext(AppContext);
	// This function is executed when the create button is clicked
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (WayChange === true) {
			await updateService();
		} else if (!WayChange) {
			await updateState();
		}
	};
	const handleReport = async () => {
		setOpen(true);
	};

	useEffect(() => {
		console.log('useEffect', readyUpdate);
		updateServiceData();
		processAndUpdateData(dataCommon);
		if (!WayChange) getData();
		getDataToUpdate();
	}, [readyUpdate]);

	const updateService = () => {
		fetchData({ url: endpoints.services.getServiceById(id) }).then((data) => {
			const { response } = data;
			const SERVICE_FULL = prepareServiceData(response.servicio);
			if (validateServiceData(SERVICE_FULL)) {
				customSB(
					'warning',
					'snackbar.warning.validate.tech.exist.update',
					'Se está validando que el servicio cumpla con los requisitos para ser actualizado, por favor espere...'
				);
				fetchAndProcessServiceData(SERVICE_FULL);
			} else {
				customSB(
					'warning',
					'snackbar.warning.complete.field.date',
					'Ingresa Fecha y/o la Hora de la cita'
				);
			}
		});
	};

	const updateState = () => {
		fetchData({ url: endpoints.services.getServiceById(id) }).then((data) => {
			const { response } = data;
			const SERVICE_FULL = prepareServiceData(response.servicio);
			if (validateServiceData(SERVICE_FULL)) {
				customSB(
					'warning',
					'snackbar.warning.validate.tech.exist.update',
					'Se está validando que el servicio cumpla con los requisitos para ser actualizado, por favor espere...'
				);
				fetchAndProcessServiceDataToUpdateStatus(SERVICE_FULL);
				getDataToUpdate();
			} else {
				customSB(
					'warning',
					'snackbar.warning.complete.field.date',
					'Ingresa Fecha y/o la Hora de la cita'
				);
			}
		});
	};

	const fetchAndProcessServiceData = (SERVICE_FULL) => {
		fetchData({
			url: endpoints.services.generateEndDate,
			method: 'post',
			body: SERVICE_FULL
		})
			.then((DATA) => {
				const { response } = DATA;
				if (dateValidate.idHoraCita?.hora) {
					const dataToSend = {
						idServicio: { ...response },
						idTecnico: { idTecnico: idTecnico }
					};
					fetchData({
						url: endpoints.services.updateCitaAuxTech,
						method: 'put',
						body: dataToSend
					})
						.then(() => {
							updatedItem();
							onClose();
							getDataToUpdate();
						})
						.catch(handleRequestError);
				}
			})
			.catch(() => {
				customSB('error', 'Error al obtener los datos, consulte a su administrador');
			});
	};

	const fetchAndProcessServiceDataToUpdateStatus = async (SERVICE_FULL) => {
		fetchData({
			url: endpoints.services.generateEndDate,
			method: 'post',
			body: SERVICE_FULL
		})
			.then((DATA) => {
				const { response } = DATA;
				if (dateValidate.idHoraCita?.hora) {
					const dataToSend = {
						...response,
						idHoraCita: {
							...response.idHoraCita,
							hora: dateValidate.idHoraCita.hora,
							valor: dateValidate.idHoraCita.valor
						}
					};
					setDataCommon(dataToSend);
					fetchData({
						url: endpoints.services.validatePrincipalTech,
						method: 'post',
						body: dataToSend
					})
						.then((d) => {
							const ans = d.response;
							if (ans) {
								RequestUseCase.post(endpoints.services.calculateServiceRisk, dataToSend).then(
									(answ) => {
										if (answ) {
											const risk = answ[0];
											const START_AVAILABILITY_TIME =
												ans.tiempoDisponibilidadInicio === -1
													? formatterText('info.swal.previous.services', 'No hay servicio previo')
													: ans.tiempoDisponibilidadInicio.toFixed(2) +
													  ' ' +
													  formatterText('service.services.editService.activities.minutes');
											const END_AVAILABILITY_TIME =
												ans.tiempoDisponibilidadFin === -1
													? formatterText('info.swal.post.services', 'No hay servicio posterior')
													: ans.tiempoDisponibilidadFin.toFixed(2) +
													  ' ' +
													  formatterText('service.services.editService.activities.minutes');
											const low = formatterText('info.swal.low.risk', 'BAJO');
											const medium = formatterText('info.swal.medium.risk', 'MEDIO');
											const hight = formatterText('info.swal.hight.risk', 'ALTO');
											const PREVIOUS_BREACH_RISK =
												risk.riesgoIncumplimientoAnterior === 1
													? `<span style="color: #0aa48a; font-weight: bold;">${low}</span>`
													: risk.riesgoIncumplimientoAnterior === 2
													? `<span style="color: #f4bf4a; font-weight: bold;">${medium}</span>`
													: `<span style="color: #e21e26; font-weight: bold;">${hight}</span>`;

											const FOLLOWING_BREACH_RISK =
												risk.riesgoIncumplimientoSiguiente === 1
													? `<span style="color: #0aa48a; font-weight: bold;">${low}</span>`
													: risk.riesgoIncumplimientoSiguiente === 2
													? `<span style="color: #f4bf4a; font-weight: bold;">${medium}</span>`
													: `<span style="color: #e21e26; font-weight: bold;">${hight}</span>`;

											const alertText = `
                <ul style="padding: 0 3rem; text-align: start; font-size: 0.9rem">
                  <li>${formatterText(
										'info.swal.start.availability.time',
										'Tiempo disponible al inicio'
									)}: ${START_AVAILABILITY_TIME}</li>
                  <li>${formatterText(
										'info.swal.end.availability.time',
										'Tiempo disponible al final'
									)}: ${END_AVAILABILITY_TIME}</li>
                  <li>${formatterText(
										'info.swal.risk.prior.default',
										'Riesgo de incumplimiento previo'
									)}: ${PREVIOUS_BREACH_RISK}</li>
                  <li>${formatterText(
										'info.swal.risk.next.default',
										'Riesgo de incumplimiento siguiente'
									)}: ${FOLLOWING_BREACH_RISK}</li>
                </ul>
                `;
											const textLowRisk = `      
                  <p style="padding: 0 3rem; text-align: justify-content; font-size: 0.9rem"> 
                  ${formatterText(
										'info.swal.title.no.previous.service',
										'No hay servicios previos y/o posteriores que puedan verse afectados con este cambio.'
									)}</p><br/>
                  <ul style="padding: 0 3rem; text-align: start; font-size: 0.9rem">
                    <li>${formatterText(
											'info.swal.start.availability.time',
											'Tiempo disponible al inicio'
										)}: ${START_AVAILABILITY_TIME}</li>
                    <li>${formatterText(
											'info.swal.end.availability.time',
											'Tiempo disponible al final'
										)}: ${END_AVAILABILITY_TIME}</li>
                    <li>${formatterText(
											'info.swal.risk.prior.default',
											'Riesgo de incumplimiento servicio previo'
										)}: ${PREVIOUS_BREACH_RISK}</li>
                    <li>${formatterText(
											'info.swal.risk.next.default',
											'Riesgo de incumplimiento servicio siguiente'
										)}: ${FOLLOWING_BREACH_RISK}</li>
                  </ul>
                `;

											const titleTextDnger = `${formatterText(
												'info.swal.title.risk.hight',
												'Riesgo Alto'
											)} <br/> <p style="font-size: 1rem"> ${formatterText(
												'info.swal.title.previous.service',

												'Antes de continuar, verifica riesgos asociados al cumplimiento de tus servicios previos y/o posteriores'
											)}</p>`;
											const titleTextWarn = `${formatterText(
												'info.swal.title.risk.medium.hight',
												'Riesgo Medio - Alto'
											)} <br/> <p style="font-size: 1rem"> ${formatterText(
												'info.swal.title.previous.service',

												'Antes de continuar, verifica riesgos asociados al cumplimiento de tus servicios previos y/o posteriores'
											)}</p>`;
											const titlelOWrISK = `${formatterText(
												'info.swal.title.risk.low',
												'Riesgo Bajo'
											)} <br/>`;
											Swal.fire({
												icon:
													risk.riesgoIncumplimientoAnterior > 1 ||
													risk.riesgoIncumplimientoSiguiente > 1
														? 'warning'
														: 'success',
												title:
													risk.riesgoIncumplimientoAnterior === 3 ||
													risk.riesgoIncumplimientoSiguiente === 3
														? titleTextDnger
														: risk.riesgoIncumplimientoAnterior === 2 ||
														  risk.riesgoIncumplimientoSiguiente === 2
														? titleTextWarn
														: titlelOWrISK,
												html:
													risk.riesgoIncumplimientoAnterior > 1 ||
													risk.riesgoIncumplimientoSiguiente > 1
														? alertText
														: textLowRisk,
												confirmButtonColor: '#09a288',
												confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
												showCancelButton: true,
												allowOutsideClick: true,
												cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar')
											}).then(async (result) => {
												if (result.isConfirmed) {
													await Promise.all([handleReport()]);
												} //if (result.isConfirmed)
											}); //then( async(result)
										} // if (answ)
									}
								); //.then((answ)
							} else {
								noScheduleTech();
							}
						})
						.catch((err) => {
							handleRequestError(err);
						});
				} // if (dateValidate.idHoraCita?.hora)
			})
			.catch(() => {
				customSB('error', 'Error al obtener los datos, consulte a su administrador');
			});
	};

	const prepareServiceData = (serviceData) => {
		const SERVICE_FULL = { ...serviceData };
		delete SERVICE_FULL.fechaCita;
		delete SERVICE_FULL.idHoraCita;
		delete SERVICE_FULL.fechaCitaFin;
		delete SERVICE_FULL.idHoraCitaFin;
		SERVICE_FULL.fechaCita = eventData.fechaCita;
		SERVICE_FULL.idHoraCita = eventData.idHoraCita;
		return SERVICE_FULL;
	};

	const validateServiceData = (serviceData) => {
		return (
			serviceData.idHoraCita !== '' &&
			serviceData.idHoraCita !== null &&
			serviceData.fechaCita !== '' &&
			serviceData.fechaCita !== null
		);
	};

	useEffect(() => {
		if (flagStatus) {
			console.log('setFlag cambia a true');
		}
	}, [flagStatus]);

	const handleDate = (e) => {
		if (e.target) {
			setEventData({
				...eventData,
				[e.target.name]: e.target.value
			});
		} else {
			setTime(e);
			const HOUR_ID = searchHour(dateformat(e, 'HH:MM'));
			const NAME_VALUE = 'idHoraCita';
			const HOUR_VALUE = getValueHour(HOUR_ID);

			setEventData({
				...eventData,
				[NAME_VALUE]: {
					idHora: HOUR_ID
				}
			});
			setDateValidate((prevDateValidate) => {
				if (prevDateValidate.idHoraCita) {
					return {
						...prevDateValidate,
						[NAME_VALUE]: {
							...prevDateValidate.idHoraCita,
							hora: dateformat(e, 'HH:MM'),
							valor: HOUR_VALUE
						}
					};
				} else {
					return {
						...prevDateValidate,
						[NAME_VALUE]: {
							idHora: HOUR_ID,
							hora: dateformat(e, 'HH:MM'),
							valor: HOUR_VALUE
						}
					};
				}
			});
		}
	};

	const searchHour = (hour) => {
		const HOURS = JSON.parse(localStorage.getItem('hours'));
		const idHora = HOURS?.find((item) => item.hora === hour);
		return idHora?.idHora;
	};

	const getReports = () => {
		RequestUseCase.get(endpoints.reports.getReportsByService(id)).then((res) => {
			const formattedChat = res.map((item) => {
				return {
					name: item.nombreUsuarioCreacion,
					id: item.idServicioReporte,
					color: item.usuarioCreacion == COOKIE_USER ? '#E0F6CA' : 'lightgray',
					own: item.usuarioCreacion === COOKIE_USER,
					text: item.descripcion,
					//convert fecha creacion to Febrero 12, 2021
					fecha: new Date(item.fechaCreacion).toLocaleDateString('es-ES', {
						year: 'numeric',
						month: 'long',
						day: 'numeric',
						hour: 'numeric',
						minute: 'numeric'
					}),
					tipoReporte: item.idTipoReporte.nombre,
					attachedsFiles: item.urlsArchivos,
					isPublic: item.publico,
					isWeb: Boolean(item?.reporteWeb)
				};
			});
			//order revert the array
			formattedChat.reverse();
		});
	};

	const updateServiceData = async () => {
		const DATA = {
			idServicioActividad: servicioActividad,
			idEstadoActividad: { idEstadoActividad: parseInt(estadoActividad) },
			idServicio: { idServicio: id },
			fechaCreacion: new Date().toISOString(),
			usuarioCreacion: COOKIE_USER,
			usuarioModificacion: COOKIE_USER,
			orden: orden
		};
		if (readyUpdate) {
			try {
				const res = await RequestUseCase.put(endpoints.services.updateStateActivityService, DATA);

				if (res) {
					setCounterActivities(0);
				}
			} catch (err) {
				handleRequestError(err);
			}
		}
	};

	const processAndUpdateData = async (dataToSend) => {
		try {
			const res2 = await fetchData({
				url: endpoints.services.updateDate,
				method: 'post',
				body: dataToSend
			});

			if (res2.response && res2.response.codigo === 2) {
				customSB('error', 'Error, el Servicio debe estar en Curso');
			} else {
				updatedItem();
				setFlagStatus(true);
				getDataToUpdate();
			}
		} catch (error) {
			console.error('Error al procesar y actualizar datos:', error);
		}
	};

	console.log('open:', open);
	return (
		<>
			<form className={styles['container-ps-modal-ud']}>
				<p className="title-modal">{formatterText('title.service.updateDateTime')}</p>
				<h4>{formatterText('title.service.newDateTime')}</h4>
				<label>
					<span>{formatterText('title.service.appointmentDate')}</span>
					<input
						className="input-date"
						type="date"
						name="fechaCita"
						value={eventData.fechaCita}
						onChange={handleDate}
						min={dateformat(
							new Date(now.getFullYear(), now.getMonth(), now.getDate() - 60),
							'yyyy-mm-dd'
						)}
						style={{ width: '60%', margin: '1rem 0rem' }}
					/>
				</label>
				<label>
					<span>{formatterText('title.service.appointmentTime')}</span>
					<TimePicker
						name="idHoraCita"
						ampm={false}
						placeholder={
							<FormattedMessage
								id="input.placeholder.select"
								defaultMessage="Selecciona una opción"
							/>
						}
						value={time}
						onChange={handleDate}
						style={{ width: '60%', margin: '1rem 0rem' }}
					/>
				</label>

				<section className="footer-grid-buttons">
					<input
						type="submit"
						className="btn-action-primary"
						value={formatterText('title.service.update')}
						onClick={(e) => handleSubmit(e)}
					/>
					<button
						className="btn-action-cancel"
						onClick={(e) => {
							e.preventDefault();
							onClose();
						}}
					>
						{formatterText('title.service.return')}
					</button>
				</section>
			</form>
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
				styles={{
					modal: {
						width: '80%',
						height: '80%',
						borderRadius: '10px'
					}
				}}
			>
				<GenerateReport
					fetchData={fetchData}
					COOKIE_USER={COOKIE_USER}
					getReports={getReports}
					setOpen={setOpen}
					setFatherOpen={onClose}
				/>
			</Modal>
		</>
	);
};

export default UpdateDatimeAlert;
