import Cookie from 'js-cookie';

class CategoryService {
	constructor(
		idCategoriaServicio = null,
		nombre = '',
		descripcion = '',
		codigo = '',
		idCategoriaProducto = null,
		idTipoServicio = null,
		idCategoriaProductoTipoServicio = null,
		usuarioCreacion = Cookie.get('idUsuario'),
		usuarioModificacion = Cookie.get('idUsuario')
	) {
		this.idCategoriaServicio = idCategoriaServicio;
		this.nombre = nombre;
		this.descripcion = descripcion;
		this.codigo = codigo;
		this.idCategoriaProducto = idCategoriaProducto;
		this.idTipoServicio = idTipoServicio;
		this.idCategoriaProductoTipoServicio = idCategoriaProductoTipoServicio;
		this.usuarioCreacion = usuarioCreacion;
		this.usuarioModificacion = usuarioModificacion;
	}
}

export default CategoryService;
