import HandleOnError from 'common/validators/HandleOnError';
import React, { useContext, useEffect, useState } from 'react';
import { MdDelete, MdDownload } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
// Import Context
import { useParams } from 'react-router';
// Import Services
import MIME_TYPE from 'common/utils/MimeType';
import { MODULES_NAME } from 'common/validators/PermissionsConstants';
import { ALERT } from 'constants/alerts';
import { BROWSER_MEDIA } from 'constants/browserMedia';
import { FIELDS } from 'constants/fields';
import { REPORTS } from 'constants/lang/services/services/reports';
import { SNACKBAR } from 'constants/snackbars';
import { AppContext } from 'context/AppContext';
import { PermissionWrapper, usePermissionContext } from 'context/PermissionContext';
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';
import Modal from 'react-responsive-modal';
import endpoints from 'services/api';
import CircularProgress from '@mui/material/CircularProgress';

const Reports = () => (
	<PermissionWrapper>
		<ReportsComponent />;
	</PermissionWrapper>
);

function ReportsComponent() {
	const { id } = useParams();
	const { fetchData, COOKIE_USER, RequestUseCase } = useAxios();
	const [data, setData] = React.useState([]);
	const [open, setOpen] = useState(false);
	const [isAsesor, setIsAsesor] = useState(false);
	const { formatterText } = useLangv2();

	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();
	const permissionsAccessGaranted = () => {
		permissionsAccess(MODULES_NAME.service.report);
	};

	useEffect(() => {
		getReports();
		const userData = JSON.parse(localStorage.getItem('userData'));
		setIsAsesor(userData.idAsesorCliente !== null);
	}, []);

	useEffect(() => {
		getReports();
		const userData = JSON.parse(localStorage.getItem('userData'));
		setIsAsesor(userData.idAsesorCliente !== null);
	}, [id]);

	useEffect(() => {
		permissionsAccessGaranted();
	}, [permissions]);

	const getReports = () => {
		RequestUseCase.get(endpoints.reports.getReportsByService(id)).then((res) => {
			const formattedChat = res.map((item) => {
				return {
					name: item.nombreUsuarioCreacion,
					id: item.idServicioReporte,
					color: item.usuarioCreacion == COOKIE_USER ? '#E0F6CA' : 'lightgray',
					own: item.usuarioCreacion === COOKIE_USER,
					text: item.descripcion,
					//convert fecha creacion to Febrero 12, 2021
					fecha: new Date(item.fechaCreacion).toLocaleDateString('es-ES', {
						year: 'numeric',
						month: 'long',
						day: 'numeric',
						hour: 'numeric',
						minute: 'numeric'
					}),
					tipoReporte: item.idTipoReporte.nombre,
					attachedsFiles: item.urlsArchivos,
					isPublic: item.publico,
					isWeb: Boolean(item?.reporteWeb)
				};
			});
			//order revert the array
			formattedChat.reverse();
			setData(formattedChat);
		});
	};

	return (
		<>
			{permittedActions.crear && (
				<button
					onClick={(e) => {
						e.preventDefault();
						setOpen(true);
					}}
					style={{
						width: 'fit-content',
						margin: '0 auto',
						justifyContent: 'center',
						alignItems: 'center',
						display: 'flex',
						marginBottom: '10px'
					}}
					className="btn-primary"
				>
					{formatterText(REPORTS.generateReport)}
				</button>
			)}
			<div
				style={{
					maxHeight: 'calc(100vh - 200px)',
					overflowY: 'auto'
				}}
			>
				<div
					style={{
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center'
					}}
				>
					{data.map((item) => {
						if (item.isPublic !== 0 && !isAsesor) {
							return (
								<ReportItem
									key={item.id}
									item={item}
									canDelete={permittedActions.eliminar}
									getReports={getReports}
									isAsesor={isAsesor}
								/>
							);
						} else if (item.isPublic === 0 && !isAsesor) {
							return (
								<ReportItem
									key={item.id}
									item={item}
									canDelete={permittedActions.eliminar}
									getReports={getReports}
									isAsesor={isAsesor}
								/>
							);
						} else if (item.isPublic === 1 && isAsesor) {
							return (
								<ReportItem
									key={item.id}
									item={item}
									canDelete={permittedActions.eliminar}
									getReports={getReports}
									isAsesor={isAsesor}
								/>
							);
						}
					})}
				</div>
			</div>
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
				styles={{
					modal: {
						width: '80%',
						height: '80%',
						borderRadius: '10px'
					}
				}}
			>
				<GenerateReport
					fetchData={fetchData}
					COOKIE_USER={COOKIE_USER}
					getReports={getReports}
					setOpen={setOpen}
				/>
			</Modal>
		</>
	);
}

const ReportItem = ({ item, canDelete, getReports }) => {
	const { customSB, successRemoveItem, formatterText } = useLangv2();
	const [fullFiles] = useState(item.attachedsFiles);
	const [fileData, setFileData] = useState({});
	const { fetchData, RequestUseCase } = useAxios();

	const showIt = async (file) => {
		const extensionArchivo = file.substring(file.lastIndexOf('.') + 1);
		if (hasSoundExtension(file) || hasVideoExtension(file) || hasImageExtension(file)) {
			if (!fileData[file]) {
				setFileData((prev) => {
					return {
						...prev,
						[file]: {
							file,
							base64: null,
							extension: extensionArchivo
						}
					};
				});
			}
			return fetchData({
				url: endpoints.UploadFiles.download,
				method: 'post',
				body: {
					file: file
				}
			}).then((res) => {
				if (res.response === null) {
					return;
				}

				setFileData((prev) => {
					return {
						...prev,
						[file]: {
							file,
							base64: `data:${MIME_TYPE[extensionArchivo]};base64,${res.response}`,
							extension: extensionArchivo
						}
					};
				});
			});
		}
	};

	const downloadIt = (path) => {
		const extensionArchivo =
			path.lastIndexOf('.') > -1 ? path.substring(path.lastIndexOf('.') + 1, path.length) : '';
		if (fileData[path]?.base64Plain) {
			const decoded = decodeBase64(fileData[path].base64Plain);
			functionToDownload(decoded, path, extensionArchivo);
			return;
		}
		fetchData({
			url: endpoints.UploadFiles.download,
			method: 'post',
			body: {
				file: path
			}
		}).then((res) => {
			if (res.response === null) {
				return;
			}
			const decoded = decodeBase64(res.response);
			functionToDownload(decoded, path, extensionArchivo);
			setFileData((prev) => {
				return {
					...prev,
					[path]: {
						file: path,
						base64: `data:${MIME_TYPE[extensionArchivo]};base64,${res.response}`,
						base64Plain: res.response,
						extension: extensionArchivo
					}
				};
			});
		});
	};

	const decodeBase64 = (base64) => {
		const byteCharacters = atob(base64);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		return byteArray;
	};

	const functionToDownload = (decoded, fileName, ext) => {
		const blob = new Blob([decoded], { type: `aplication/${ext}` });
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = fileName;
		link.click();
	};

	const deleteReports = (id) => {
		RequestUseCase.delete(endpoints.reports.delete(id))
			.then(() => {
				successRemoveItem();
				getReports();
			})
			.catch(() => {
				customSB(
					'error',
					`${formatterText(SNACKBAR.reportDeleteError)}`,
					`${formatterText(SNACKBAR.reportDeleteError)}`
				);
			});
	};

	const listExtension = {
		video: ['mp4', 'webm', 'avi', 'mkv', 'flv', 'vob', 'ogv'],
		image: ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp', 'svg', 'ico', 'tiff', 'tif'],
		audio: ['mp3', 'wav', 'ogg', 'm4a', 'flac', 'aac', 'wma', 'aiff', 'alac', 'opus', 'webm', 'amr']
	};

	function hasSoundExtension(fileName) {
		return listExtension.audio.filter((extension) => fileName.includes(extension)).length > 0;
	}

	function hasImageExtension(fileName) {
		return listExtension.image.filter((extension) => fileName.includes(extension)).length > 0;
	}

	function hasVideoExtension(fileName) {
		return listExtension.video.filter((extension) => fileName.includes(extension)).length > 0;
	}

	useEffect(() => {
		Promise.all(fullFiles.map(showIt));
	}, [fullFiles]);

	return (
		<div
			style={{
				backgroundColor: item.color,
				alignSelf: item.isWeb ? 'flex-end' : 'flex-start',
				width: '45%',
				margin: 10,
				padding: 10,
				borderBottomRightRadius: item.own ? 0 : 16,
				borderBottomLeftRadius: item.own ? 16 : 0,
				borderTopRightRadius: 16,
				borderTopLeftRadius: 16,
				overflow: 'auto'
			}}
		>
			{canDelete && (
				<MdDelete
					size={20}
					style={{
						color: 'rgb(153, 153, 153)',
						textAlign: 'right',
						marginLeft: 'auto',
						marginRight: '7px',
						fontSize: '10px',
						display: 'block',
						cursor: 'pointer'
					}}
					onClick={() => {
						deleteReports(item.id);
					}}
				/>
			)}

			<div style={{ flex: 1, flexDirection: 'column' }}>
				<div style={{ fontWeight: 'bold', margin: '7px' }}>{item.name}</div>
				<div
					style={{
						fontSize: 10,
						color: '#999999',
						marginLeft: '7px'
					}}
				>
					{item.tipoReporte}
				</div>
				<div
					style={{
						flexWrap: 'wrap',
						flex: 1,
						marginLeft: '7px'
					}}
				>
					{item.text}
					{fullFiles.map((file) => {
						if (hasSoundExtension(file) || hasVideoExtension(file) || hasImageExtension(file)) {
							return <></>;
						}
						return (
							<div
								key={file.idArchivoS3}
								style={{
									flex: 1,
									flexDirection: 'row'
								}}
							>
								<div
									style={{
										flex: 1,
										flexDirection: 'row'
									}}
								>
									<div
										style={{
											textDecorationLine: 'underline',
											display: 'flex',
											'flex-wrap': 'wrap',
											maxWidth: '90%'
										}}
									>
										{file.replace(/(\.\w+)+$/, '$1')}
									</div>
								</div>

								<MdDownload
									size={20}
									style={{
										marginLeft: 7,
										cursor: 'pointer'
									}}
									onClick={() => {
										downloadIt(file);
									}}
								/>
							</div>
						);
					})}
					{Object.values(fileData)
						.filter((item) => hasImageExtension(item.file) || hasVideoExtension(item.file))
						.map((fileObj) => {
							if (hasImageExtension(fileObj.file)) {
								return (
									<div key={fileObj.file}>
										<img
											src={fileObj.base64}
											style={{
												width: '50%',
												height: 'auto'
											}}
											alt={`Media ${fileObj.file}`}
										/>
										{fileObj.base64 === null && <CircularProgress />}
									</div>
								);
							} else {
								return (
									<div key={fileObj.file}>
										<video controls width="80%" src={fileObj.base64}>
											<track kind="captions" />
											{formatterText(BROWSER_MEDIA.browserNotSupportVideoPlayback)}
										</video>
										{fileObj.base64 === null && <CircularProgress />}
									</div>
								);
							}
						})}
					{Object.values(fileData)
						.filter((item) => hasSoundExtension(item.file))
						.map((fileObj) => {
							return (
								<div key={fileObj.file}>
									<audio controls width="80%" src={fileObj.base64}>
										<track kind="captions" />
									</audio>
									{fileObj.base64 === null && <CircularProgress />}
								</div>
							);
						})}
				</div>
				<div
					style={{
						//alinear el texto a la derecha
						textAlign: 'right',
						marginLeft: 'auto',
						marginRight: '7px',
						fontSize: 10,
						color: '#999999'
					}}
				>
					{item.fecha}
				</div>
			</div>
		</div>
	);
};

export const GenerateReport = ({
	fetchData,
	COOKIE_USER,
	getReports,
	setOpen,
	isAsesor,
	setFatherOpen
}) => {
	const { RequestUseCase } = useAxios();
	const fileInput = React.useRef(null);
	const reader = new FileReader();
	const [typesReports, setTypesReports] = useState([]);
	const [typeSelected, setTypeSelected] = useState(null);
	const [filesAttached, setFilesAttached] = useState([]);
	const [isPublic, setIsPublic] = useState(true);
	const [text, setText] = useState('');
	const { formatterText } = useLangv2();
	const [buttonActivate, setButtonActivate] = useState(true);

	const { setReadyUpdate } = useContext(AppContext);

	useEffect(() => {
		RequestUseCase.get(endpoints.reports.getAllTypes).then((res) => {
			setTypesReports(res);
		});
	}, []);

	const { id } = useParams();

	const uploadFile = (e) => {
		e.preventDefault();
		const selectedFile = e.target.files[0];
		if (selectedFile.size > 5000000) {
			HandleOnError(`${formatterText(ALERT.fileLarge)}`);
			return;
		} // png, jpg, tiff
		if (
			selectedFile.type !== 'image/jpg' &&
			selectedFile.type !== 'image/png' &&
			selectedFile.type !== 'image/jpeg' &&
			selectedFile.type !== 'image/tiff' &&
			selectedFile.type !== 'video/mp4'
		) {
			HandleOnError(`${formatterText(ALERT.fileNotValidMediaPngJpgTiffMp4)}`);
			return;
		}

		reader.readAsDataURL(selectedFile);
		reader.onload = () => {
			const archivo = reader.result.split('base64,')[1];
			//obtener el tipo del archivo
			const extensionArchivo =
				selectedFile.name.lastIndexOf('.') > -1
					? selectedFile.name.substring(
							selectedFile.name.lastIndexOf('.') + 1,
							selectedFile.name.length
					  )
					: '';
			const name = selectedFile.name;
			setFilesAttached((filesAttached) => [
				...filesAttached,
				{ name, extensionArchivo: `.${extensionArchivo}`, archivo }
			]);
		};
	};
	const deleteFile = (e, item) => {
		e.preventDefault();
		if (filesAttached.length === 1) {
			setFilesAttached([]);
		} else {
			const index = filesAttached.indexOf(item);
			filesAttached.splice(index, 1);
			setFilesAttached([...filesAttached]);
		}
	};

	const handleSubmit = () => {
		setButtonActivate(false);
		const userData = JSON.parse(localStorage.getItem('userData'));
		//verify if text is !== "" and typeSelected !== null
		if (text === '' || typeSelected === null) {
			HandleOnError(`${formatterText(ALERT.requiredFields)}`);
			setButtonActivate(true);
			return;
		}
		const data = {
			idServicioReporte: null,
			idServicio: parseInt(id),
			idTipoReporte: parseInt(typeSelected),
			descripcion: text,
			notificacionAsesor: 0,
			publico: isPublic ? 1 : 0,
			fechaCreacion: new Date().toISOString(),
			usuarioCreacion: isAsesor ? parseInt(userData.idAsesorCliente) : parseInt(COOKIE_USER),
			urlsArchivos: null,
			reporteWeb: 1
		};
		fetchData({ url: endpoints.reports.create, method: 'post', body: data }).then(async (res) => {
			await fetchData({
				url: endpoints.UploadFiles.save,
				method: 'post',
				body: {
					idOrigen: res.response.idServicioReporte,
					idTipoOrigenArchivo: 2,
					archivos: filesAttached
				}
			});
			getReports(id);
			setOpen(false);
			setReadyUpdate(true);
			if (setFatherOpen) {
				setFatherOpen();
			}
		});
	};

	return (
		<section className="wrapForm">
			<div className="selectsContainer">
				<label className="wrapForm__label">
					<h3 className="p-styles spacing-l1 primary-green">{formatterText(REPORTS.reportType)}</h3>
					<select
						mode="multiple"
						className="input-edit-fix"
						name="typeSelected"
						onChange={(e) => setTypeSelected(e.target.value)}
						required
					>
						<option value="">{formatterText(FIELDS.selectOption)}</option>
						{typesReports.map((tipo) => (
							<option key={tipo.idTipoReporte} value={tipo.idTipoReporte}>
								{tipo.nombre}
							</option>
						))}
					</select>
				</label>
			</div>
			<div className="edit&btnAContainer">
				<div className="editor">
					<div>
						<h3 className="p-styles spacing-l1 primary-green">
							{formatterText(FIELDS.title.description)}
						</h3>
					</div>
					<div>
						<textarea
							className="input-default-textarea"
							name="descripcion"
							style={{
								minHeight: '150px'
							}}
							id=""
							cols="30"
							rows="10"
							onChange={(e) => setText(e.target.value)}
						/>
					</div>
				</div>
				<div className="wrapForm__label">
					<h3 className="p-styles spacing-l1 primary-green">
						{formatterText(REPORTS.publicReport)}
					</h3>

					<input
						type="checkbox"
						name="publico"
						checked={isPublic}
						onChange={() => setIsPublic((isPublic) => !isPublic)}
					/>
				</div>

				<div className="btnAdjuntarContainer">
					<label
						style={{
							width: '80%',
							background: '#EFEEEE',
							boder: '1px',
							borderStyle: 'dashed',
							borderColor: '#747474',
							margin: '15px auto',
							minHeight: '150px',
							borderRadius: '10px'
						}}
						className="custom-file-upload"
					>
						<input
							id="file-input"
							style={{ display: 'none' }}
							accept=".png , .jpg , .tiff , .mp4"
							type="file"
							name="files"
							value=""
							onChange={uploadFile}
							ref={fileInput}
						/>
						<div
							style={{
								display: 'flex',
								flexFlow: 'row wrap'
							}}
						>
							{filesAttached.map((item, index) => (
								<div
									key={`${index + 1}`}
									style={{
										backgroundColor: '#FFFFFF',
										borderRadius: '10px',
										boder: '1px',
										borderStyle: 'solid',
										borderColor: '#D9D9D9',
										padding: '5px',
										paddingRight: '10px',
										marginRight: '20px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between'
									}}
								>
									<p
										style={{
											cursor: 'pointer'
										}}
										onClick={(e) => {
											e.preventDefault();
										}}
									>
										{item.name}
									</p>
									<MdDelete
										size={25}
										color="red"
										style={{
											marginLeft: '10px',
											zIndex: '999'
										}}
										cursor="pointer"
										onClick={(e) => {
											e.preventDefault();
											deleteFile(e, item);
										}}
									/>
								</div>
							))}
						</div>
					</label>
				</div>
				<div
					style={{
						width: '80%',
						display: 'flex',
						margin: '0 auto',
						justifyContent: 'flex-end',
						marginTop: '25px',
						marginBottom: '25px'
					}}
				>
					<button
						onClick={(e) => {
							e.preventDefault();
							fileInput.current.click();
						}}
						style={{ width: 'fit-content' }}
						className="btn-primary"
					>
						<FormattedMessage id="btn.upload.files" defaultMessage="Subir archivos" />
					</button>
				</div>
			</div>
			<div className="form-responsive-container-buttons footer-grid-buttons NQRSaveBtn">
				{buttonActivate && (
					<input
						type="submit"
						className="btn-action-primary"
						value={formatterText(FIELDS.input.save)}
						onClick={(e) => {
							e.preventDefault();
							handleSubmit();
						}}
					/>
				)}
			</div>
		</section>
	);
};

export default Reports;
