import PropTypes from 'prop-types';

export const DynamicRowServicesProps = {
	titles: PropTypes.array.isRequired,
	columns: PropTypes.object.isRequired,
	getData: PropTypes.func.isRequired,
	handleDownloadItem: PropTypes.func.isRequired,
	handleDeleteItem: PropTypes.func.isRequired,
	handleEditStateItem: PropTypes.func.isRequired,
	noHandleEditStateItem: PropTypes.func.isRequired,
	routeToEdit: PropTypes.func.isRequired,
	canDeleted: PropTypes.bool.isRequired,
	canDownload: PropTypes.bool.isRequired,
	canViewDetails: PropTypes.bool.isRequired,
	canChangeData: PropTypes.bool.isRequired,
	canModify: PropTypes.bool.isRequired,
	canSelectEU: PropTypes.bool.isRequired,
	canSelectSC: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	statusActivity: PropTypes.string.isRequired,
	sendMail: PropTypes.func.isRequired,
	setSendMail: PropTypes.func.isRequired,
	getDataToUpdate: PropTypes.func.isRequired,
	setCounterActivities: PropTypes.func.isRequired,
	setIsDeleteTechMain: PropTypes.func.isRequired,
	isDeleteTechMain: PropTypes.bool.isRequired,
	getAllTechnicalsServices: PropTypes.func.isRequired
};
