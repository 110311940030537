/**
 * Convierte un numero en formato moneda
 * @param {int} value
 * @return value
 *
 * @author lfernandez-qvision
 */
const currencyFormat = (value) => {
	//only allow numbers and the first dot, and delete the rest
	//only if hace more than 1 point, only get the last one
	try {
		value = value.toString();
		value = value.replace(/[^0-9.]/g, '').replace(/(\...*)\./g, '$1');
		value = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
		//if value contain "," convert in a decimal number
		if (value.includes(',')) {
			value = value.replace(',', '.');
		}
	} catch (e) {
		console.log(e);
	}

	//convert formatted value to string
	const formatter = new Intl.NumberFormat('es-CO', {
		style: 'currency',
		currency: 'COP',
		minimumFractionDigits: 0,
		maximumFractionDigits: 2
	});
	const res = formatter.format(value);
	return res;
};

export const dateTimeFormat = () => {
	const formatter = new Intl.DateTimeFormat('es-CO', {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric'
	});
	const res = formatter.format();
	return res;
};

export default currencyFormat;
