import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PERMISSION_NAMES } from 'common/validators/PermissionsConstants';
import usePermission from 'hooks/usePermission';

export const PermissionContext = createContext();

export const usePermissionContext = () => useContext(PermissionContext);
// falta completar y usar en todos los modulos

/**
 * Contexto de permisos
 * @param {children} children
 * @returns
 */
export const PermissionWrapper = ({ children }) => {
	const { permissions, permissionPerModule } = usePermission();
	const [access, setAccess] = useState([]);
	const [permittedActions, setPermittedActions] = useState({});

	useEffect(() => {
		_validationData();
	}, [access]);

	const permissionsAccess = (idModule) => {
		if (permissions) {
			const accessGaranted = permissionPerModule(idModule);
			setAccess(accessGaranted);
		}
	};

	// Función para verificar si un permiso existe en el arreglo de objetos
	const _permisoExiste = (permiso) => {
		return access.some((objeto) => objeto.nombrePermiso === permiso);
	};

	const _validationData = () => {
		const valido = {};
		// Construye un objeto con los permisos y su estado de validez
		PERMISSION_NAMES.forEach((permiso) => {
			const key = permiso.toLowerCase().replace(/\s+/g, '_');
			valido[key] = _permisoExiste(permiso);
		});

		setPermittedActions(valido);
	};

	return (
		<PermissionContext.Provider
			value={{
				permissionsAccess,
				access,
				setAccess,
				permittedActions,
				permissions,
				_validationData
			}}
		>
			{children}
		</PermissionContext.Provider>
	);
};

PermissionWrapper.propTypes = {
	children: PropTypes.node
};
