import useAxios from 'hooks/useAxios';
import HandleInput from 'common/validators/HandleInput';
import HandleOnError from 'common/validators/HandleOnError';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import { TEXTREGEX } from 'common/validators/Regex';
import CustomAlert from 'components/CustomAlert';
import useLangv2 from 'hooks/useLangv2';
import Cookie from 'js-cookie';
import Cargo from 'models/Cargo';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import paths from 'services/paths';
import Swal from 'sweetalert2';

export default function UpdatePositionViewModel() {
	const { endpoints, RequestUseCase } = useAxios();
	const { id } = useParams();
	const navigate = useNavigate();
	// Datos de un formulario
	const [formData, setFormData] = useState(new Cargo());
	// toggle state
	const [active, setActive] = useState(false);
	// use Hook of language v2
	const { formatterText, noFilledContent } = useLangv2();
	const [errorName, setErrorName] = useState('');
	const [codeError, setCodeError] = useState('');
	const [descriptionError, setdescriptionError] = useState('');

	useEffect(() => {
		getDataCargoById();
	}, []);

	const getDataCargoById = () => {
		RequestUseCase.get(endpoints.cargos.getCargoById(id))
			.then((res) => {
				setFormData(res);
				setActive(res.estado === 1);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleText = (e) => {
		HandleInput(e, TEXTREGEX, formData, setFormData);
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	const handleCode = (e) => {
		const example = e;
		example.target.value = e.target.value.toLowerCase();
		HandleInput(example, TEXTREGEX, formData, setFormData);
	};

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		if (
			errorName !== '' ||
			codeError !== '' ||
			descriptionError !== '' ||
			formData.nombre === '' ||
			formData.codigo === '' ||
			formData.descripcion === ''
		) {
			noFilledContent();
		} else {
			const data = {
				...formData,
				estado: active ? '1' : '0',
				usuarioModificacion: Cookie.get('idUsuario')
			};
			Swal.fire({
				title: formatterText(
					'alert.title.general',
					'Atención, estás seguro de realizar esta acción'
				),
				text: formatterText('alert.description.update.general', 'Se va a editar el registro'),
				icon: 'question',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				showLoaderOnConfirm: true,
				cancelButtonColor: '#d33',
				confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
				allowOutsideClick: false,
				cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
				preConfirm: () => {
					return new Promise(() => {
						RequestUseCase.put(endpoints.cargos.updateCargo, data)
							.then(() => {
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.updated.general',
										'El registro se ha actualizado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => navigate(paths.positions)
								});
							})
							.catch((err) => {
								if (err.response.status === 412) {
									HandleOnError(
										formatterText(
											'alert.message.code.error.general',
											'El código ya existe, por favor ingrese uno distinto'
										)
									);

									console.log(err.response.data);
								} else {
									console.log('error');
									HandleOnError(
										formatterText(
											'snackbar.error.process.failed.general',
											'Error al realizar el proceso. Intentalo en otro momento.'
										)
									);
								}
								console.log(err);
							});
					});
				}
			});
		}
	};

	const handleErrorName = () => {
		if (formData.nombre.trim() === '') {
			setErrorName(formatterText('p.position.is.required'));
		} else {
			setErrorName('');
		}
	};

	const handleErrorCode = () => {
		if (formData.codigo.trim() === '') {
			setCodeError(formatterText('table.title.code.required'));
		} else {
			setCodeError('');
		}
	};

	const handleErrordescripcion = () => {
		if (formData.descripcion.trim() === '') {
			setdescriptionError(formatterText('p.description.is.required'));
		} else {
			setdescriptionError('');
		}
	};
	return {
		active,
		codeError,
		descriptionError,
		errorName,
		formatterText,
		formData,
		handleCode,
		handleErrorCode,
		handleErrordescripcion,
		handleErrorName,
		handlerTextDescription,
		handleSubmit,
		handleText,
		navigate,
		setActive
	};
}
