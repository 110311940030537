import { useSeachContext } from 'context/SearchContext';
import { useEffect, useState } from 'react';
import dateFormat from 'dateformat';
import useProgress from 'hooks/useProgress';
// Import Components
// Import services
// hook para cargar los datos
import { MODULES_NAME } from 'common/validators/PermissionsConstants';
import { usePermissionContext } from 'context/PermissionContext';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import useAxios from 'hooks/useAxios';

const AuditsViewModel = () => {
	const { RequestUseCase, endpoints } = useAxios();
	// hook to loading data
	const { toggleLoading } = useGetData();
	const [audits, setAudits] = useState([]);
	// const [leakedData, setLeakedData] = useState([]);
	const { setDataTable, leakedData, setLeakedData, rowsPerPage, page } = useSeachContext();
	// Estados de los permisosModulos

	// Helps to loading data table
	const { loadingProgress, setLoadingProgress } = useProgress();
	const [permissionsData, setPermissions] = useState([]);
	const [modulesData, setModules] = useState([]);
	const [userData, setUserData] = useState([]);

	// use Hook of language v2
	const { formatterText } = useLangv2();
	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();

	const titles = [
		formatterText('table.title.userPerform', 'Usuario que realizó la acción'),
		formatterText('table.title.date&TimePerform', 'Fecha y hora en que se realizó la acción'),
		formatterText('table.title.modulePerform', 'Módulo del sistema donde se realizó la acción'),
		formatterText('table.title.permissionPerform', 'Permiso usado para realizar la acción'),
		formatterText('table.title.ipPerform', 'Ip desde donde se realizó la acción'),
		formatterText('table.title.sqlExePerform', 'Nota')
	];

	const [formData, setFormData] = useState({
		ip: ''
	});

	const [advancedSearch, setAdvancedSearch] = useState(false);
	const [multiPermissions, setMultiPermissions] = useState([]);
	const [multiModules, setMultiModules] = useState([]);
	const [multiQueries] = useState([]);
	const [multiUsers, setMultiUsers] = useState([]);
	const [dates, setDates] = useState({
		fecha_inicio: null,
		fecha_fin: null,
		fecha_registro: null
	});

	useEffect(() => {
		permissionsAccess(MODULES_NAME.audit);
	}, [permissions]);

	const getAllUsers = () => {
		RequestUseCase.get(endpoints.users.getAllUsers).then((res) => {
			const newArray = [];
			res.forEach((item) => {
				newArray.push({
					id: item.idUsuario,
					label: `${item.usuario}`
				});
			});
			setUserData(newArray);
		});
	};

	const getAllPermissions = () => {
		RequestUseCase.get(endpoints.audit.getPermissions).then((res) => {
			const newArray = [];
			res.forEach((item) => {
				newArray.push({
					id: item.idpermiso,
					label: `${item.nombre}`
				});
			});
			setPermissions(newArray);
		});
	};

	const getAllModules = () => {
		RequestUseCase.get(endpoints.audit.getModules).then((res) => {
			const newArray = [];
			res.forEach((item) => {
				newArray.push({
					id: item.idModulo,
					label: `${item.nombre}`
				});
			});
			setModules(newArray);
		});
	};

	const searchAdvance = (e) => {
		e.preventDefault();
		setLoadingProgress(true);

		const timeZone = 'America/Bogota';

		// Users
		const USR = multiUsers.map((item) => {
			return item.id;
		});

		// Dates
		const FR_FI = dates.fecha_inicio;
		const FR_FF = dates.fecha_fin;
		let RANGE_DATE = '';

		if (FR_FI === null && FR_FF === null) {
			RANGE_DATE = null;
		} else {
			RANGE_DATE = {
				fecha_inicio: dateFormat(FR_FI, 'isoDate'),
				hora_inicio: dateFormat(FR_FI, 'isoTime'),
				fecha_fin: dateFormat(FR_FF, 'isoDate'),
				hora_fin: dateFormat(FR_FF, 'isoTime')
			};
		}

		// Permissions
		const PERM = multiPermissions.map((item) => {
			return item.id;
		});

		// Modules
		const MOD = multiModules.map((item) => {
			console.log(item);

			return item.id;
		});

		// Queries
		const QUERY = multiQueries.map((item) => {
			return { query: item.query };
		});

		const BODY = {
			usuarios: USR.length === 0 ? null : USR,
			fecha_registro: dates.fecha_registro,
			fechaInicio: `${RANGE_DATE?.fecha_inicio} ${RANGE_DATE?.hora_inicio}`,
			fechaFin: `${RANGE_DATE?.fecha_fin} ${RANGE_DATE?.hora_fin}`,
			permisos: PERM.length === 0 ? null : PERM,
			modulos: MOD.length === 0 ? null : MOD,
			ip: formData.ip === '' ? null : formData.ip,
			query: QUERY.length === 0 ? null : QUERY,
			pageNumber: page,
			rowsPerPage: rowsPerPage
		};
		// show loading
		toggleLoading(true);
		RequestUseCase.post(endpoints.audit.getAuditsQuery, BODY)
			.then((response) => {
				const newArray = [];
				// Math.max(0, Math.ceil(count / rowsPerPage) - 1))
				// setPage(pages);
				response.datosAuditoria.forEach((item) =>
					newArray.push({
						// usuario: item.idUsuario.usuario,
						usuario: item.usuario,
						fechaRegistro: dateFormat(item.fechaRegistro, 'yyyy/mm/dd - HH:MM TT', { timeZone }),
						// modulo: item.idModulo.nombre,
						modulo: item.nombreModulo,
						// permiso: item.idPermiso.nombre,
						permiso: item.nombrePermiso,
						ip: item.ip,
						query: item.nota ? item.nota : 'No hay nota'
					})
				);
				newArray.reverse();
				setDataTable(newArray);
				// show loading
				setLoadingProgress(false);
				toggleLoading(false);
				setAudits(newArray);
			})
			.catch(() => setLoadingProgress(false));
	};

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleChangeMulti = (val) => {
		setMultiPermissions(val);
	};

	const handleChangeModules = (val) => {
		setMultiModules(val);
	};

	const handleChangeUsers = (val) => {
		setMultiUsers(val);
	};

	useEffect(() => {
		getAllUsers();
		getAllPermissions();
		getAllModules();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		audits,
		leakedData,
		setLeakedData,
		permissionsData,
		modulesData,
		userData,
		titles,
		formData,
		advancedSearch,
		multiPermissions,
		multiModules,
		multiUsers,
		dates,
		formatterText,
		permittedActions,
		loadingProgress,
		searchAdvance,
		handleChange,
		handleChangeMulti,
		handleChangeModules,
		handleChangeUsers,
		setAdvancedSearch,
		setDates
	};
};

export default AuditsViewModel;
