import React, { useEffect, useState } from 'react';
// Import Components
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MdRemoveCircleOutline } from 'react-icons/md';
// Import Libs
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker as MaterialDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import LANGS from '../../lang/define';
// Import Styles
import styles from './DatePicker.module.css';

export default function DateTimePicker({
	placeholder,
	name,
	value: _value,
	onChange,
	showMdRemove
}) {
	const [value, setValue] = useState(_value || null);
	const [inputValue, setInputValue] = useState('');
	useEffect(() => {
		setLangState(LANGS[localStorage.getItem('lang')]);
	}, [localStorage.getItem('lang')]);
	// state of the language
	const [langState, setLangState] = useState(LANGS[localStorage.getItem('lang')]);

	const handleChange = (newValue) => {
		setValue(newValue);
		if (newValue) onChange({ [name]: newValue });
		else onChange({ [name]: null });
	};

	return (
		<>
			<LocalizationProvider adapterLocale={langState} dateAdapter={AdapterDateFns}>
				<MaterialDateTimePicker
					label={placeholder}
					id={name}
					value={value}
					onChange={handleChange}
					className={styles.container}
					sx={{ padding: 6, margin: 0 }}
					classes={{
						option: styles.menuItem,
						listbox: styles.menuList,
						noOptions: styles.noOptions,
						groupLabel: styles.headerItem,
						notchedOutline: styles.notchedOutline,
						input: styles.inputData
					}}
					inputValue={inputValue}
					onInputChange={(event, newInputValue) => {
						setInputValue(newInputValue);
					}}
					renderInput={(params) => <TextField {...params} />}
				/>
			</LocalizationProvider>
			{value && showMdRemove !== false && (
				<MdRemoveCircleOutline
					size={25}
					color="gray"
					cursor="pointer"
					style={{ marginLeft: '10px' }}
					onClick={(e) => {
						e.preventDefault();
						setValue(null);
						onChange({ [name]: null });
					}}
				/>
			)}
		</>
	);
}
