import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const TableMinimalContext = createContext();

export function TableMinimalWrapper({ children }) {
	const [currentDataTable, setCurrentDataTable] = useState([]);

	const [otherCurrentDataTable, setOtherCurrentDataTable] = useState([]);

	const [modalData, setModalData] = useState([]);

	const [selectedTable, setSelectedTable] = useState(1);
	const [resultsTableSearch, setResultsTableSearch] = useState([]);

	const [dataTableUnits, setDataTableUnits] = useState([]);

	// save an auxiliary id
	const [auxId, setAuxId] = useState(null);

	return (
		<TableMinimalContext.Provider
			value={{
				currentDataTable,
				setCurrentDataTable,
				modalData,
				setModalData,
				otherCurrentDataTable,
				setOtherCurrentDataTable,
				selectedTable,
				setSelectedTable,
				resultsTableSearch,
				setResultsTableSearch,
				auxId,
				setAuxId,
				dataTableUnits,
				setDataTableUnits
			}}
		>
			{children}
		</TableMinimalContext.Provider>
	);
}

TableMinimalWrapper.propTypes = {
	children: PropTypes.node
};
