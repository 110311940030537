import useAxios from 'hooks/useAxios';
import HandleInput from 'common/validators/HandleInput';
import HandleOnError from 'common/validators/HandleOnError';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import { CODEREGEX, SPACING } from 'common/validators/Regex';
import CustomAlert from 'components/CustomAlert';
import hexRgb from 'hex-rgb';
import useLangv2 from 'hooks/useLangv2';
import Cookie from 'js-cookie';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import endpoints from 'services/api';
import paths from 'services/paths';
import Swal from 'sweetalert2';

export default function UpdateStageViewModel() {
	const { RequestUseCase } = useAxios();

	const navigate = useNavigate();

	// toggle state
	const [active, setActive] = useState(true);
	// useLanguage (update to v2)
	// const { formatterText } = useLang();

	// use Hook of language v2
	const { formatterText, resourceNotFound, noFilledContent } = useLangv2();
	const [errorName, setErrorName] = useState('');
	const [codeError, setCodeError] = useState('');
	// Example of form data
	const [formData, setFormData] = useState([]);
	// Stage of the picker
	const [colorHexCode, setColorHexCode] = useState('#000000');
	// RBG color
	const [colorRGB, setColorRGB] = useState({
		red: 0,
		green: 0,
		blue: 0,
		alpha: 1
	});
	// Stage of the container picker
	const [displayColorPicker, setDisplayColorPicker] = useState(false);
	const { id } = useParams();

	useEffect(() => {
		getStageById(id);
	}, [id]);

	useEffect(() => {
		getDataToUpdate();
	}, []);

	const getDataToUpdate = () => {
		const dataUpdate = JSON.parse(localStorage.getItem('dataUpdate'));

		if (dataUpdate) {
			setFormData({
				...dataUpdate,
				id: dataUpdate.id,
				nombre: dataUpdate.nombre,
				descripcion: dataUpdate.descripcion,
				codigo: dataUpdate.codigo,
				estado: dataUpdate.estado
			});
			setColorHexCode(dataUpdate.color);
			setActive(dataUpdate.estado);
			setColorRGB(hexRgb(dataUpdate.color));
		} else {
			navigate(paths.services);
		}
	};

	const getStageById = (id) => {
		RequestUseCase.get(endpoints.stageServices.getStageServiceById(id))
			.then((res) => {
				if (res === null) {
					setFormData({
						id: '',
						nombre: '',
						descripcion: '',
						codigo: '',
						estado: ''
					});
					setColorHexCode('');
					setActive('');
					resourceNotFound();
				} else {
					setFormData({
						...res,
						id: res.id,
						nombre: res.nombre,
						descripcion: res.descripcion,
						codigo: res.codigo,
						estado: res.estado
					});
					setColorHexCode(res.color);
					setActive(res.estado);
					setColorRGB(hexRgb(res.color));
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// Handle to show the color picker
	const handleClick = (e) => {
		e.preventDefault();
		setDisplayColorPicker(!displayColorPicker);
	};

	// Handle to close the color picker
	const handleClose = () => {
		setDisplayColorPicker(false);
	};

	const handlerTextCode = (e) => {
		HandleInput(e, CODEREGEX, formData, setFormData);
	};

	const handleInputCaracter = (e) => {
		HandleInput(e, SPACING, formData, setFormData);
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		if (errorName !== '' || codeError !== '' || formData.nombre === '' || formData.codigo === '') {
			noFilledContent();
		} else {
			const data = {
				...formData,
				estado: active ? '1' : '0',
				color: colorHexCode,
				usuarioModificacion: Cookie.get('idUsuario'),
				codigo: (formData.codigo ?? '').toLowerCase()
			};
			putItem(data);
		}
	};

	const putItem = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general'),
			text: formatterText('alert.description.create.general'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general'),
			preConfirm: () => {
				return new Promise((resolve) => {
					RequestUseCase.put(endpoints.stageServices.updateStageService, data)
						.then(() => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general'),
									text: formatterText('alert.message.confirm.created.general'),
									confirmButtonText: formatterText('alert.button.continue'),
									allowOutsideClick: false,
									executeFunction: () => navigate(paths.services)
								})
							);
						})
						.catch((err) => {
							if (err.response?.data?.message) {
								HandleOnError(formatterText(err.response?.data?.message));
							} else {
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
						});
				});
			}
		});
	};

	const handleErrorName = () => {
		if (formData.nombre.trim() === '') {
			setErrorName(formatterText('p.label.title.name.is.required'));
		} else {
			setErrorName('');
		}
	};

	const handleErrorCode = () => {
		if (formData.codigo.trim() === '') {
			setCodeError(formatterText('table.title.code.required'));
		} else {
			setCodeError('');
		}
	};
	return {
		formatterText,
		handleSubmit,
		colorHexCode,
		colorRGB,
		handleClick,
		displayColorPicker,
		handleClose,
		setColorHexCode,
		setColorRGB,
		navigate,
		formData,
		handleInputCaracter,
		errorName,
		handleErrorCode,
		handleErrorName,
		handlerTextDescription,
		handlerTextCode,
		codeError,
		active,
		setActive
	};
}
