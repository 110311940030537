import React from 'react';
import { SearchWrapper } from 'context/SearchContext';
import { PermissionWrapper } from 'context/PermissionContext';
import ProviderComponent from './ProviderPage.component';

const ProviderPage = () => {
	return (
		<SearchWrapper>
			<PermissionWrapper>
				<ProviderComponent />
			</PermissionWrapper>
		</SearchWrapper>
	);
};

export default ProviderPage;
