import React from 'react';

// Import libs
import dateFormat from 'dateformat';
import { FormattedMessage } from 'react-intl';
import { Formiz, FormizStep } from '@formiz/core';

// Import Components
import { MyField } from '../inputs/MyField';
import InputSelectorResponsive from 'common/inputs/InputSelectorResponsive';

// Import Paths
import paths from 'services/paths';

// Import Utils
import HandleShortDate from 'common/validators/HandleShortDate';
import Attachments from 'components/AttachedFiles/Attachments';
import { TIPOS_ARCHIVOS_PERMITIDOS_USUARIO } from 'constants/archivosAdjuntosUsuario';

//Import ViewModel
import CreateEmployeeViewModel from './CreateEmployee.viewModel';

// Import styles
import 'react-tabs/style/react-tabs.css';

const CreateEmployeeComponent = () => {
	const {
		archivos,
		currentRelative,
		familiares,
		formatterText,
		handleAddFamily,
		handleChangeNumberRelative,
		handleChangeRelative,
		handleChangeSelect,
		handleSubmit,
		isLoading,
		loadingData,
		myForm,
		navigate,
		now,
		selectData,
		setCurrentFiles,
		setFamiliares,
		showError
	} = CreateEmployeeViewModel();

	return (
		<div className="centered-form">
			<Formiz onValidSubmit={handleSubmit} connect={myForm}>
				<form
					noValidate
					onSubmit={myForm.submit}
					className="container-wrapForm"
					style={{ minHeight: '16rem' }}
				>
					<div className="new-container-wrapForm__tabs">
						{myForm.steps.map((step) => (
							<button
								key={step.name}
								className={`new-tab-option ${
									step.name === myForm.currentStep.name ? 'is-active' : ''
								}`}
								type="button"
								onClick={() => myForm.goToStep(step.name)}
							>
								{!step.isValid && step.isSubmitted && <small className="mr-2">⚠️</small>}
								{formatterText(step.label)}
							</button>
						))}
					</div>

					<div className="container-wrapForm-content">
						<FormizStep name="step1" label="p.general.information">
							<div className="title-section">
								<span className="circle-form">
									<span>1</span>
								</span>
								<h2>
									<FormattedMessage
										id="p.employee.personal.data"
										defaultMessage="Datos personales del empleado"
									/>
								</h2>
							</div>

							<section className="grid-container-2c">
								<InputSelectorResponsive
									type="text"
									name="idTipoDocumento"
									placeholder={formatterText('p.document.type', 'Tipo de documento')}
									labelText={formatterText('p.document.type', 'Tipo de documento*')}
									required={formatterText('p.label.title.tipoDocumentoRequerido')}
									data={
										!!selectData.documentTypes &&
										selectData.documentTypes.map((item) => {
											const docLabel = `${item.nombre} - ${item.abreviatura}`;
											return {
												label: docLabel,
												value: item.idTipoDocumento
											};
										})
									}
									isLoading={loadingData}
									styleName="input-primary"
								/>
								<label className="d-flex">
									<span className="text-inline">
										<FormattedMessage id="text.ID" defaultMessage="Identificación" />
									</span>
									<section className="w100-container">
										<MyField
											name="identificacion"
											placeholder={formatterText(
												'input.placeholder.enter.id',
												'Ingresa la identificación'
											)}
											type="text"
											required={formatterText('p.label.title.identificacionRequerida')}
											validateInput="identification"
										/>
									</section>
								</label>
								<InputSelectorResponsive
									type="text"
									name="genero"
									labelText={formatterText('input.placeholder.genre', 'Género')}
									placeholder={formatterText('input.placeholder.genre', 'Género')}
									required={formatterText('p.label.title.generoRequerido')}
									data={
										!!selectData.genres &&
										selectData.genres.map((item) => ({
											label: `${item.nombre} - ${item.abreviatura}`,
											value: item.idGenero
										}))
									}
									isLoading={loadingData}
								/>

								<label className="d-flex">
									<span className="text-inline">
										<FormattedMessage id="p.first.name" defaultMessage="Primer nombre" />
									</span>
									<section className="w100-container">
										<MyField
											name="primerNombre"
											placeholder={formatterText(
												'input.placeholder.enter.first.name',
												'Ingresa el primer nombre'
											)}
											type="text"
											required={formatterText('p.label.title.primerNombreRequerido')}
											validateInput="text-no-digits-one-space"
										/>
									</section>
								</label>
								<label className="d-flex">
									<span className="text-inline">
										<FormattedMessage id="p.second.name" defaultMessage="Segundo nombre" />
									</span>
									<section className="w100-container">
										<MyField
											name="segundoNombre"
											placeholder={formatterText(
												'input.placeholder.enter.second.name',
												'Ingresa el segundo nombre'
											)}
											type="text"
											validateInput="text-no-digits-one-space"
										/>
									</section>
								</label>
								<label className="d-flex">
									<span className="text-inline">
										<FormattedMessage id="p.first.lastname" defaultMessage="Primer apellido" />
									</span>
									<section className="w100-container">
										<MyField
											name="primerApellido"
											placeholder={formatterText(
												'input.placeholder.enter.first.lastname',
												'Ingresa el primer apellido'
											)}
											type="text"
											required={formatterText('p.label.title.primerApellidoRequerido')}
											validateInput="text-no-digits-one-space"
										/>
									</section>
								</label>
								<label className="d-flex">
									<span className="text-inline">
										<FormattedMessage id="p.second.lastname" defaultMessage="Segundo apellido" />
									</span>
									<section className="w100-container">
										<MyField
											name="segundoApellido"
											placeholder={formatterText(
												'input.placeholder.enter.second.lastname',
												'Ingresa el segundo apellido'
											)}
											type="text"
											validateInput="text-no-digits-one-space"
										/>
									</section>
								</label>
								<label className="d-flex">
									<span className="text-inline">
										<FormattedMessage id="p.date.birth" defaultMessage="Fecha de nacimiento" />
									</span>
									<section className="w100-container">
										<MyField
											name="fechaNacimiento"
											label="Name"
											type="date"
											required={formatterText('p.label.title.fechaNacimientoRequerida')}
											min={HandleShortDate(-80)}
											max={HandleShortDate(-18)}
											onkeydown={(e) => {
												e.target.value = '';
											}}
										/>
									</section>
								</label>
								<label className="d-flex" style={{ position: 'sticky' }}>
									<span className="text-inline">
										<FormattedMessage id="table.title.email" defaultMessage="Correo electrónico" />
									</span>
									<section className="w100-container">
										<MyField
											name="correo"
											placeholder={formatterText(
												'input.placeholder.enter.email',
												'Ingresa el correo electrónico'
											)}
											type="email"
											required={formatterText('p.label.title.correoRequerido')}
										/>
										{showError && (
											<div
												style={{
													//position just down of the MyField
													position: 'absolute',
													top: '100%',
													padding: '0 10px',
													boxSizing: 'border-box',
													color: 'red'
												}}
											>
												<span className="error-msg">
													{formatterText('p.label.title.correoNoValido')}
												</span>
											</div>
										)}
									</section>
								</label>
								<label className="d-flex">
									<span className="text-inline">
										<FormattedMessage id="p.employee.phone" defaultMessage="Teléfono empleado" />
									</span>
									<section className="w100-container">
										<MyField
											name="telefono"
											placeholder={formatterText(
												'input.placeholder.enter.phone',
												'Ingresa el teléfono'
											)}
											type="text"
											required={formatterText('p.label.title.telefonoRequerido')}
											validateInput="number"
										/>
									</section>
								</label>
							</section>

							<div className="title-section">
								<span className="circle-form">
									<span>1.1</span>
								</span>
								<h2>
									<FormattedMessage id="p.place.residence" defaultMessage="Lugar de residencia" />
								</h2>
							</div>

							<section
								className="grid-container-2c"
								style={{
									width: '95%',
									margin: '0 auto'
								}}
							>
								<InputSelectorResponsive
									type="text"
									name="idMunicipio"
									labelText={formatterText('p.city.residence', 'Ciudad de residencia')}
									placeholder={formatterText('p.city.residence', 'Ciudad de residencia')}
									required={formatterText('p.label.title.ciudadResidenciaRequerida')}
									data={
										!!selectData.municipalities &&
										selectData.municipalities.map((item) => ({
											label: `${item.nombre} - ${item.idDepartamento.nombre} - ${item.idDepartamento.idPais.nombrePais} `,
											value: item.idMunicipio
										}))
									}
									isLoading={loadingData}
								/>
								<label className="d-flex">
									<span className="text-inline">
										<FormattedMessage id="table.title.address" defaultMessage="Dirección" />
									</span>
									<section className="w100-container">
										<MyField
											name="direccion"
											label="direccion"
											placeholder={formatterText(
												'input.placeholder.enter.address',
												'Ingresa el dirección'
											)}
											type="text"
											required={formatterText('p.label.title.direccionRequerida')}
											validateInput="address"
										/>
									</section>
								</label>
							</section>
						</FormizStep>
						<FormizStep name="step2" label="p.labor.information">
							<div className="title-section">
								<span className="circle-form">
									<span>2</span>
								</span>
								<h2>
									<FormattedMessage
										id="p.employee.labor.data"
										defaultMessage="Datos laborales del empleado"
									/>
								</h2>
							</div>

							<section className="grid-container-3c">
								<label className="d-flex">
									<span className="text-inline">
										<FormattedMessage
											id="table.title.entry.date"
											defaultMessage="Fecha de ingreso"
										/>
									</span>
									<MyField
										name="fechaIngreso"
										type="date"
										required={formatterText('p.label.title.fechaIngresoRequerida')}
										className="input-primary"
										min={dateFormat(
											new Date(
												now.getFullYear() - 50,
												now.getMonth(),
												now.getDate(),
												now.getHours(),
												now.getMinutes(),
												now.getSeconds()
											),
											'yyyy-mm-dd'
										)}
										max={dateFormat(
											new Date(
												now.getFullYear(),
												now.getMonth(),
												now.getDate(),
												now.getMonth(),
												now.getDate(),
												now.getHours(),
												now.getMinutes(),
												now.getSeconds()
											),
											'yyyy-mm-dd'
										)}
									/>
								</label>
								<InputSelectorResponsive
									type="text"
									name="tipoContrato"
									labelText={formatterText('table.title.contract.type', 'Tipo de contrato')}
									placeholder={formatterText('table.title.contract.type', 'Tipo de contrato')}
									required={formatterText('p.label.title.tipoContratoRequerido')}
									data={
										!!selectData.contracts &&
										selectData.contracts.map((item) => ({
											label: item.nombre,
											value: item.idTipoContrato
										}))
									}
									isLoading={loadingData}
								/>
								<InputSelectorResponsive
									type="text"
									name="cargo"
									labelText={formatterText('table.title.position', 'Cargo')}
									placeholder={formatterText('table.title.position', 'Cargo')}
									required={formatterText('p.label.title.cargoRequerido')}
									data={
										!!selectData.positions &&
										selectData.positions.map(({ nombre, idCargo }) => ({
											label: nombre,
											value: idCargo
										}))
									}
									isLoading={loadingData}
								/>
								<label className="d-flex">
									<span className="text-inline">
										<FormattedMessage id="p.employee.salary" defaultMessage="Salario empleado" />
									</span>

									<MyField
										name="salario"
										placeholder={formatterText(
											'input.placeholder.enter.employee.salary',
											'Ingresa el salario del empleado'
										)}
										type="text"
										required={formatterText('p.label.title.salarioRequerido')}
										validateInput="price"
									/>
								</label>
								<label className="d-flex">
									<span className="text-inline">
										<FormattedMessage id="p.bearing.help" defaultMessage="Auxilio de rodamiento" />
									</span>

									<MyField
										name="rodamiento"
										placeholder={formatterText(
											'input.placeholder.enter.bearing.help',
											'Ingresa el auxilio de rodamiento'
										)}
										type="text"
										validateInput="price"
									/>
								</label>
								<label className="d-flex">
									<span className="text-inline">
										<FormattedMessage id="p.observation" defaultMessage="Observación" />
									</span>

									<MyField
										name="observacion"
										placeholder={formatterText(
											'input.placeholder.enter.observation',
											'Ingresa la observación'
										)}
										type="text"
										validateInput="text"
									/>
								</label>
							</section>

							<div className="title-section">
								<span className="circle-form">
									<span>2.1</span>
								</span>
								<h2>
									<FormattedMessage
										id="p.affiliations.banking.information"
										defaultMessage="Afiliaciones e información bancaria"
									/>
								</h2>
							</div>

							<section
								className="grid-container-2c"
								style={{
									width: '95%',
									margin: '0 auto'
								}}
							>
								<label className="d-flex">
									<span className="text-inline">
										<FormattedMessage
											id="p.lra.affiliation.date"
											defaultMessage="Fecha de afiliación ARL"
										/>
									</span>

									<MyField
										name="fechaAfiliacionArl"
										type="date"
										required={formatterText('p.label.title.fechaAfiliacionARLRequerida')}
										min={HandleShortDate(-80)}
										max={HandleShortDate()}
									/>
								</label>

								<InputSelectorResponsive
									type="text"
									name="entidadSalud"
									labelText={formatterText('p.health.entity', 'Entidad de salud')}
									placeholder={formatterText('p.health.entity', 'Entidad de salud')}
									required={formatterText('p.label.title.entidadSaludRequerida')}
									data={
										!!selectData.healthEntities &&
										selectData.healthEntities.map(({ idEntidadSalud, nombre }) => ({
											label: nombre,
											value: idEntidadSalud
										}))
									}
									isLoading={loadingData}
								/>
								<InputSelectorResponsive
									type="text"
									name="banco"
									labelText={formatterText('p.bank', 'Banco')}
									placeholder={formatterText(
										'input.placeholder.employee.bank',
										'Banco del empleado'
									)}
									required={formatterText('p.label.title.bancoEmpleadoRequerido')}
									data={
										!!selectData.banks &&
										selectData.banks.map(({ idBanco, nombre, abreviatura }) => ({
											label: `${nombre} - ${abreviatura}`,
											value: idBanco
										}))
									}
									isLoading={loadingData}
								/>
								<InputSelectorResponsive
									type="text"
									name="tipoCuenta"
									labelText={formatterText('p.account.type', 'Tipo de cuenta')}
									placeholder={formatterText('p.account.type', 'Tipo de cuenta')}
									required={formatterText('p.label.title.tipoCuentaRequerido')}
									data={
										!!selectData.typeAccount &&
										selectData.typeAccount.map(({ idTipoCuenta, nombre, abreviatura }) => ({
											label: `${nombre} - ${abreviatura}`,
											value: idTipoCuenta
										}))
									}
									isLoading={loadingData}
								/>
								<label className="d-flex">
									<span className="text-inline">
										<FormattedMessage id="p.account.number" defaultMessage="Número de cuenta" />
									</span>
									<MyField
										name="numeroCuenta"
										placeholder={formatterText(
											'input.placeholder.enter.account.number',
											'Ingresa el número de cuenta'
										)}
										type="text"
										required={formatterText('p.label.title.numeroCuentaRequerido')}
										validateInput="number"
									/>
								</label>
							</section>
						</FormizStep>
						<FormizStep name="step3" label="p.contact.information">
							<div className="title-section">
								<span className="circle-form">
									<span>3</span>
								</span>
								<h2>
									<FormattedMessage
										id="p.emergency.contact.data"
										defaultMessage="Datos de contacto de emergencia"
									/>
								</h2>
							</div>

							<section
								className="grid-container-2c"
								style={{
									width: '95%',
									margin: '0 auto'
								}}
							>
								<label className="d-flex">
									<span className="text-inline">
										<FormattedMessage
											id="p.emergency.contact.name"
											defaultMessage="Nombre de contacto de emergencia"
										/>
									</span>
									<MyField
										name="nombreFamiliar"
										placeholder={formatterText(
											'input.placeholder.enter.emergency.contact.name',
											'Ingresa el nombre del contacto de emergencia'
										)}
										type="text"
										validateInput="text"
									/>
								</label>
								<label className="d-flex">
									<span className="text-inline">
										<FormattedMessage
											id="p.emergency.contact.phone"
											defaultMessage="Teléfono contacto de emergencia"
										/>
									</span>
									<MyField
										name="telefonoFamiliar"
										placeholder={formatterText(
											'input.placeholder.enter.emergency.contact.phone',
											'Ingresa el teléfono del contacto de emergencia'
										)}
										type="text"
										validateInput="number"
									/>
								</label>
							</section>

							{selectData.kins && selectData.genres && (
								<div>
									<div className="title-section">
										<span className="circle-form">
											<span>3.1</span>
										</span>
										<h2>
											<FormattedMessage id="p.add.familiar" defaultMessage="Agregar familiar" />
										</h2>
									</div>

									{familiares.map((familiar, index) => (
										<section key={`${index + 1}`} className="wrapForm">
											<div className="relative-circle-container">
												<div className="relative-circle" />
												<span style={{ fontWeight: 'bold' }}>
													{formatterText('title.user.familyMember')} {index + 1}:
												</span>
											</div>

											<span>{familiar.nombreCompleto}</span>
											<span>{familiar.idParentesco.nombre}</span>
											<span>{familiar.idGenero.abreviatura}</span>
											<span>{familiar.edad}</span>
											<a
												href="_"
												className="delete-relative"
												onClick={(e) => {
													e.preventDefault();
													const index = familiares.indexOf(familiar);
													const array = [...familiares];
													if (index > -1) {
														// only splice array when item is found
														array.splice(index, 1);
														setFamiliares(array); // 2nd parameter means remove one item only
													}
												}}
											>
												{formatterText('title.user.deleteFamilyMember')}
											</a>
										</section>
									))}

									<section
										className="grid-container-2c"
										style={{
											width: '95%',
											margin: '0 auto'
										}}
									>
										<label className="d-flex">
											<span className="text-inline">
												<FormattedMessage id="p.relationship" defaultMessage="Parentesco" />
											</span>
											<select
												name="idParentesco"
												id="idParentesco"
												className="input-label-style"
												onChange={handleChangeSelect}
											>
												<option value="">
													<FormattedMessage
														id="input.placeholder.select.relationship"
														defaultMessage="Seleccione un parentesco"
													/>
												</option>
												{selectData.kins.map((item) => (
													<option
														key={item.idParentesco}
														value={JSON.stringify({
															idParentesco: item.idParentesco,
															nombre: item.nombre
														})}
													>
														{item.nombre}
													</option>
												))}
											</select>
										</label>
										<label className="d-flex">
											<span className="text-inline">
												<FormattedMessage id="input.placeholder.genre" defaultMessage="Género" />
											</span>
											<select
												name="idGenero"
												id="idGenero"
												className="input-label-style"
												onChange={handleChangeSelect}
											>
												<option value="">
													<FormattedMessage
														id="input.placeholder.select.genre"
														defaultMessage={formatterText(
															'input.placeholder.select.genre',
															'Seleccione un género'
														)}
													/>
												</option>
												{selectData.genres.map((item) => (
													<option
														key={item.idGenero}
														value={JSON.stringify({
															idGenero: item.idGenero,
															nombre: item.nombre,
															abreviatura: item.abreviatura
														})}
													>
														{item.nombre} - {item.abreviatura}
													</option>
												))}
											</select>
										</label>
										<label className="d-flex">
											<span className="text-inline">
												<FormattedMessage id="p.age" defaultMessage="Edad" />
											</span>
											<input
												name="edad"
												placeholder={formatterText(
													'input.placeholder.enter.familiar.age',
													'Ingrese la edad del familiar'
												)}
												type="text"
												required={formatterText('p.label.title.edadFamiliarRequerida')}
												className="input-primary"
												value={currentRelative.edad}
												onChange={handleChangeNumberRelative}
											/>
										</label>
										<label className="d-flex">
											<span className="text-inline">
												<FormattedMessage
													id="table.title.full.name"
													defaultMessage="Nombre completo"
												/>
											</span>
											<input
												name="nombreCompleto"
												placeholder={formatterText(
													'input.placeholder.enter.fullname',
													'Ingrese el nombre completo'
												)}
												type="text"
												required={formatterText('p.label.title.nombreCompletoFamiliarRequerido')}
												className="input-primary"
												value={currentRelative.nombreCompleto}
												onChange={(e) => handleChangeRelative(e)}
											/>
										</label>
									</section>
									<section className="form-responsive-container-buttons">
										<input
											onClick={handleAddFamily}
											type="button"
											className="btn-primary"
											value={formatterText('p.add.familiar', 'Agregar familiar')}
										/>
									</section>
								</div>
							)}
						</FormizStep>
						<FormizStep name="step4" label="tab.title.attached.data">
							<Attachments
								currentFiles={archivos}
								setCurrentFiles={setCurrentFiles}
								isEdited={false}
								type={TIPOS_ARCHIVOS_PERMITIDOS_USUARIO.EMPLEADO}
								showParameters={true}
							/>
						</FormizStep>

						<div className="demo-form__footer">
							<section className="form-responsive-container-buttons">
								<button
									/*  disabled={
                    isLoading || (!myForm.isValid && myForm.isSubmitted)
                  } */
									type="submit"
									className="btn-primary"
								>
									{isLoading
										? 'Loading...'
										: formatterText('header.title.employee.create', 'Crear empleado')}
								</button>
								<button className="input-cancel" onClick={() => navigate(paths.employees)}>
									<FormattedMessage id="alert.button.cancel.general" defaultMessage="Cancelar" />
								</button>
							</section>
						</div>
					</div>
				</form>
			</Formiz>
		</div>
	);
};

export default CreateEmployeeComponent;
