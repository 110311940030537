import React from 'react';
import CreateFileViewModel from './CreateFile.viewModel';
import { FormComponent } from './components';

export default function CreateFile() {
	const {
		formatterText,
		formik,
		formikInitialValues,
		handleChangeMulti,
		handleFormChage,
		handleSubmit,
		idCategory,
		navigate,
		parseDataToSubmit,
		selectorData,
		selectValues
	} = CreateFileViewModel();
	return (
		<FormComponent
			formatterText={formatterText}
			formik={formik}
			formikInitialValues={formikInitialValues}
			handleChangeMulti={handleChangeMulti}
			handleFormChage={handleFormChage}
			handleSubmit={handleSubmit}
			idCategory={idCategory}
			navigate={navigate}
			parseDataToSubmit={parseDataToSubmit}
			selectorData={selectorData}
			selectValues={selectValues}
		/>
	);
}
