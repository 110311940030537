import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';

// Import Libs
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

// Import Components
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';

// Import Hooks
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';

// Import Utils
import HandleInput from 'common/validators/HandleInput';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';

// Import Paths
import paths from 'services/paths';

// Import Models
import Market from 'models/Market';

// Import Constants
import { CODEREGEX, PREFIXREGEX, SPACING } from 'common/validators/Regex';

export default function CreateMarketViewModel() {
	const { endpoints, RequestUseCase } = useAxios();
	const navigate = useNavigate();
	// Example of form data
	const [formData, setFormData] = useState(new Market());
	// Llena todo el multiselect con los paises
	const [selectedSearchCountries, setSearchSelectedCountries] = useState([]);
	// Setea los valores del multiselect de los paises
	const [selectValues, setSelectValues] = useState([]);
	// espera a que cargue los valores del multiselect
	const { loading, toggleLoading } = useGetData();
	// use Hook of language v2
	const { formatterText, noFilledContent } = useLangv2();

	const [error, setError] = useState('');
	const [codeError, setCodeError] = useState('');
	const [prefixError, setPrefixError] = useState('');

	useEffect(() => {
		getDataCountries();
	}, []);

	const getDataCountries = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.zones.getAllCountries)
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idPais,
							label: item.nombrePais,
							isFixed: true
						});
					}
				});
				setSearchSelectedCountries(newArray);
				toggleLoading(false);
			})
			.catch((err) => {
				console.log(err);
				toggleLoading(false);
			});
	};

	// Update a string to set into the form
	const handleText = (e) => {
		HandleInput(e, SPACING, formData, setFormData, true);
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	const handlerTextCode = (e) => {
		HandleInput(e, CODEREGEX, formData, setFormData);
	};

	// This function sets the form value by the onChange of the input
	const handleChange = (e) => {
		if (e.target.name.toLowerCase() === 'prefijo') {
			HandleInput(e, PREFIXREGEX, formData, setFormData);
		} else {
			HandleInput(e, CODEREGEX, formData, setFormData);
		}
	};

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		if (prefixError !== '' || codeError !== '' || error !== '' || selectValues.length === 0) {
			noFilledContent();
		} else {
			createMarket({
				...formData,
				...formData.codigo.toLowerCase(),
				nombre: formData.nombre.replaceAll(/\s{2,}/gi, ' '),
				descripcion: formData.descripcion.replaceAll(/\s{2,}/gi, ' '),
				codigo: formData.codigo.replaceAll(/\s{2,}/gi, ' ').toLowerCase()
			});
		}
	};

	const createMarket = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve) => {
					// this service create the item
					RequestUseCase.post(endpoints.markets.addMarket, data)
						.then((res) => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.created.general',
										'El registro se ha creado correctamente'
									),
									confirmButtonText: 'Continuar',
									allowOutsideClick: false,
									executeFunction: () => associateCountryMarket(res.idMercado)
								})
							);
						})
						.catch((err) => {
							if (err.response?.data?.message) {
								HandleOnError(formatterText(err.response?.data?.message));
							} else {
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
						});
				});
			}
		});
	};

	const associateCountryMarket = (idM) => {
		// realizar un if si seleccionaron paises
		if (selectValues.length > 0) {
			const data = {
				mercado: {
					idMercado: idM
				},
				pais: []
			};
			selectValues.map((item) => data.pais.push({ idPais: item.value }));
			RequestUseCase.post(endpoints.markets.addMarketCountry, data)
				.then((res) => {
					console.log(res);
					CustomAlert('confirm_msg', {
						icon: 'success',
						title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
						text: formatterText(
							'alert.message.associations.general',
							'Las asociaciones se crearon correctamente'
						),
						confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
						allowOutsideClick: false,
						executeFunction: () => navigate(paths.parameters)
					});
				})
				.catch((err) => {
					if (err.response?.data?.message) {
						HandleOnError(err.response?.data?.message);
					} else {
						HandleOnError(
							formatterText(
								'snackbar.error.process.failed.general',
								'Error al realizar el proceso. Intentalo en otro momento.'
							)
						);
					}
				});
		} else {
			navigate(paths.parameters);
		}
	};

	const handleChangeMulti = (val) => {
		setSelectValues(val);
	};

	const handleErrorName = () => {
		if (formData.nombre.trim() === '') {
			setError(formatterText('p.label.title.name.is.required'));
		} else {
			setError('');
		}
	};

	const handleCodeError = () => {
		if (formData.codigo.trim() === '') {
			setCodeError(formatterText('table.title.code.required'));
		} else {
			setCodeError('');
		}
	};

	const handlePrefixError = () => {
		if (formData.prefijo.trim() === '') {
			setPrefixError(formatterText('p.prefix.is.required'));
		} else {
			setPrefixError('');
		}
	};

	return {
		codeError,
		error,
		formatterText,
		formData,
		handleChange,
		handleChangeMulti,
		handleCodeError,
		handleErrorName,
		handlePrefixError,
		handlerTextCode,
		handlerTextDescription,
		handleSubmit,
		handleText,
		loading,
		navigate,
		prefixError,
		selectedSearchCountries,
		selectValues
	};
}
