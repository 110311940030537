import { formatterText } from 'hooks/useLangv2';
import DynamicTableServices from '../DynamicTableServices/DynamicHeadServices';

function SeguimientoCitas({ getDataTable }) {
	const tableTitles = [
		formatterText('title.service.technician'),
		formatterText('title.service.startDate'),
		formatterText('title.service.startTime'),
		formatterText('title.service.endDate'),
		formatterText('title.service.endTime')
	];
	return (
		<div>
			<div className="tdR thR table-container">
				<DynamicTableServices
					titles={tableTitles}
					pageName={formatterText('title.service.appointments')}
					getData={getDataTable}
				/>
			</div>
		</div>
	);
}

export default SeguimientoCitas;
