import useAxios from 'hooks/useAxios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
// Import Contexts
import { useSeachContext } from 'context/SearchContext';
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import Hooks
import useLangv2 from 'hooks/useLangv2';
// Import Components
// Import Services
import paths from 'services/paths';
// Import Screens
import { MODULES_NAME } from 'common/validators/PermissionsConstants';
import { usePermissionContext } from 'context/PermissionContext';
import { FormattedMessage } from 'react-intl';
import ActivityTable from 'screens/parameterServices/activities/ActivityTable';
import FormTable from 'screens/parameterServices/form/FormTable';
import ListPricesTable from 'screens/parameterServices/listPrices/ListPricesTable';
import ServiceCategoryTable from 'screens/parameterServices/serviceCategory/ServiceCategoryTable';
import ServiceStageTable from 'screens/parameterServices/stageService/ServiceStageTable';

export default function ParametersServicesTableViewModel() {
	const { RequestUseCase, endpoints } = useAxios();

	// use Hook of language v2
	const { formatterText } = useLangv2();
	// Call context TableMinimalContext
	const { setCurrentDataTable } = useContext(TableMinimalContext);

	const { setSearch, searchResults = [] } = useSeachContext();

	const [excelListData, setExcelList] = useState([]);

	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();

	const permissionsAccessGaranted = () => {
		permissionsAccess(MODULES_NAME.serviceParameters.principal);
	};

	const tabs = [
		{
			id: paths.categoryServices,
			nombre: (
				<FormattedMessage id="text.category.services" defaultMessage="Categoría de servicios" />
			),
			route: paths.createCategoryService,
			nombreLink: (
				<FormattedMessage
					id="btn.create.category.service"
					defaultMessage="Crear categoría de servicio"
				/>
			),
			componente: <ServiceCategoryTable permisos={permittedActions} />,
			exportExcel: useCallback(() => permittedActions.exportar, [permittedActions]),
			uploadExcel: permittedActions.importar,
			diligenceForm: null,
			idPermiso: MODULES_NAME.serviceParameters.serviceCategory
		},
		{
			id: paths.stagesServices,
			nombre: <FormattedMessage id="text.services.stage" defaultMessage="Etapas de servicios" />,
			route: paths.createStageService,
			nombreLink: (
				<FormattedMessage
					id="header.title.service.parameter.service.stage.create"
					defaultMessage="Crear etapa de servicio"
				/>
			),
			componente: <ServiceStageTable permisos={permittedActions} />,
			exportExcel: useCallback(() => permittedActions.exportar, [permittedActions]),
			uploadExcel: permittedActions.importar,
			diligenceForm: null,
			idPermiso: MODULES_NAME.serviceParameters.serviceStages
		},
		{
			id: 'Gestion Actividades',
			nombre: (
				<FormattedMessage
					id="header.title.service.parameter.activity.update"
					defaultMessage="Gestión de Actividades"
				/>
			),
			route: paths.createActivityService,
			nombreLink: (
				<FormattedMessage
					id="header.title.service.parameter.activity.create"
					defaultMessage="Gestionar actividad"
				/>
			),
			componente: <ActivityTable permisos={permittedActions} />,
			exportExcel: useCallback(() => permittedActions.exportar, [permittedActions]),
			uploadExcel: null,
			diligenceForm: null,
			idPermiso: MODULES_NAME.serviceParameters.manageActivities
		},
		{
			id: paths.listPriceServices,
			nombre: <FormattedMessage id="text.pricelist" defaultMessage="Listado de precios" />,
			route: paths.createListPriceService,
			nombreLink: (
				<FormattedMessage
					id="header.title.service.parameter.price.list.create"
					defaultMessage="Crear listado de precios"
				/>
			),
			componente: <ListPricesTable permisos={permittedActions} />,
			exportExcel: useCallback(() => permittedActions.exportar, [permittedActions]),
			uploadExcel: useCallback(() => permittedActions.importar, [permittedActions]),
			diligenceForm: null,
			idPermiso: MODULES_NAME.serviceParameters.listPrices
		},
		{
			id: paths.formServices,
			nombre: <FormattedMessage id="text.title.forms" defaultMessage="Formularios" />,
			route: paths.createFormService,
			nombreLink: (
				<FormattedMessage
					id="header.title.service.parameter.form.create"
					defaultMessage="Crear formulario"
				/>
			),
			componente: <FormTable permisos={permittedActions} />,
			exportExcel: null,
			uploadExcel: null,
			diligenceForm: permittedActions.crear,
			idPermiso: MODULES_NAME.serviceParameters.forms
		}
	];

	// Select tab
	const [selectedTab, setSelectedTab] = useState(tabs[0]);
	// Index Tab
	const [indexTabServices, setIndexTabServices] = useState(
		Number(localStorage.getItem('ParametersServicesTable')) || 0
	);

	//Reemplace la logica para poder reutilizarla segun los tabs, para que apunte al endpoint correspondiente
	const selectEndpoint = (body) => {
		if (selectedTab.nombre.props.defaultMessage === 'Listado de precios') {
			const ItemPromise = new Promise((resolve, reject) => {
				RequestUseCase.postFormData(endpoints.listPrices.uploadFileListPrice, body)
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			});
			return ItemPromise;
		} else {
			const ItemPromise = new Promise((resolve, reject) => {
				RequestUseCase.postFormData(endpoints.costProductProvider.uploadFileCostProvider, body)
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			});
			return ItemPromise;
		}
	};

	const getExcelListPriceData = () => {
		const dataTableList = [];
		RequestUseCase.get(endpoints.listPriceClient.downloadExcelListPriceClient).then((data) => {
			data.forEach((element) => {
				dataTableList.push({
					codigo: element.idListaPreciosActividad.codigo,
					sku: element.idTipoServicioActividad.idActividad.sku,
					etapaServicio: element.idTipoServicioActividad.idEtapaServicio.codigo,
					tipoServicio: element.idTipoServicioActividad.idTipoServicio.codigo,
					abreviatura: element.idUnidadMedida.abreviatura,
					valor: element.valor,
					impuesto: element.impuesto
				});
				setExcelList(dataTableList);
			});
		});
	};

	useEffect(() => {
		setCurrentDataTable([]);
		getExcelListPriceData();
		localStorage.setItem('indexTabServices', 0);
		localStorage.setItem('ClientParametersTable', 0);
		const index = Number(localStorage.getItem('ParametersServicesTable'));
		setIndexTabServices(index);
	}, []);

	const clickTab = (tab, index) => {
		localStorage.setItem('indexTabServices', index.toString());
		setSelectedTab(tab);
		permissionsAccess(tab.idPermiso);
	};

	useEffect(() => {
		setSearch('');
	}, [selectedTab]);

	useEffect(() => {
		permissionsAccessGaranted();
	}, [permissions]);

	useEffect(() => {
		setSelectedTab(tabs[parseInt(localStorage.getItem('ParametersServicesTable')) || 0]);
	}, [permittedActions]);
	return {
		formatterText,
		tabs,
		indexTabServices,
		clickTab,
		selectedTab,
		permittedActions,
		selectEndpoint,
		searchResults,
		excelListData
	};
}
