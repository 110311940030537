import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
// Import Context
import { useSeachContext } from 'context/SearchContext';
// Import Hooks
import { usePermissionContext } from 'context/PermissionContext';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
// Import services
//Import Constants
import { MODULES_NAME } from 'common/validators/PermissionsConstants';
export default function ThirdPartiesViewModel() {
	const { RequestUseCase, endpoints } = useAxios();

	// hook to loading data
	const {
		loading,
		error,
		toggleLoading,
		toggleError,
		handleClick,
		displayMessage,
		displayLoading
	} = useGetData();
	// useContext de búsqueda
	const { searchResults, setDataTable } = useSeachContext();
	const [thirdParties, setThirdParties] = useState(null);
	// use Hook of language v2
	const { formatterText } = useLangv2();
	// titulos de la tabla
	const titles = [
		formatterText('table.title.full.name', 'Nombre completo'),
		formatterText('p.document.type', 'Tipo de documento'),
		formatterText('table.title.document', 'Documento'),
		formatterText('table.title.municipality', 'Municipio'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];

	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();
	const permissionsAccessGaranted = () => {
		permissionsAccess(MODULES_NAME.thirdParties);
	};

	useEffect(() => {
		permissionsAccessGaranted();
	}, [permissions]);

	useEffect(() => {
		getDataTable();
	}, []);

	// función para cargar datos
	const getDataTable = () => {
		// show loading
		toggleLoading(true);
		RequestUseCase.get(endpoints.thirdParties.getAllThirdParties)
			.then((data) => {
				const newArray = [];
				data.forEach((item) => handleStructureItems(newArray, item));
				setDataTable(newArray);
				// show loading
				toggleLoading(false);
				setThirdParties(newArray);
			})
			.catch(() => {
				// mostrar error
				toggleError(!error);
				handleClick();
			});
	};

	const handleDeleteItem = (rowId) => {
		return new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.thirdParties.deleteThirdParty(rowId))
				.then((res) => {
					const newArray = [];
					res.forEach((item) => handleStructureItems(newArray, item));
					setThirdParties(newArray);
					setDataTable(newArray);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const handleEditStateItem = (body) => {
		return new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.thirdParties.updateThirdParty, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const handleStructureItems = (newArray, item) => {
		const docType = `${item.idTipoDocumento.nombre} - ${item.idTipoDocumento.abreviatura}`;
		newArray.push({
			id: item.idTercero,
			nombre: item.nombre,
			tipo_documento: docType,
			documento: item.identificacion,
			municipio: item.idMunicipio.nombre,
			estado: item.estado,
			objeto: { ...item }
		});
	};

	const renderMessage = () =>
		error
			? displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			: displayLoading();
	return {
		formatterText,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		loading,
		permittedActions,
		renderMessage,
		thirdParties,
		titles,
		searchResults
	};
}
