import useAxios from 'hooks/useAxios';
import { TableMinimalContext } from 'context/TableMinimalContext';
import React, { useContext, useRef } from 'react';
// Import components
import { MdDelete } from 'react-icons/md';
// Import libs
import Cookie from 'js-cookie';
import PropTypes from 'prop-types';
// Import services
import useLangv2 from 'hooks/useLangv2';
import endpoints from 'services/api';

const TableAssociationActivities = ({ data, setDialog, closeDialog }) => {
	const { RequestUseCase } = useAxios();
	const dragItem = useRef();
	const dragOverItem = useRef();
	const dragStart = (e, position) => {
		dragItem.current = position;
		e.dataTransfer.setData('text', e.target.id);
	};

	const { formatterText } = useLangv2();

	const dragEnter = (e, position) => {
		dragOverItem.current = position;
	};

	const drop = () => {
		const copyListItems = [...data];
		const dragItemContent = copyListItems[dragItem.current];
		copyListItems.splice(dragItem.current, 1);
		copyListItems.splice(dragOverItem.current, 0, dragItemContent);

		const ArraysToUpdate = [];
		const aux1 = { ...copyListItems[dragItem.current], orden: dragItem.current };
		ArraysToUpdate.push(aux1);

		const aux2 = {
			...copyListItems[dragOverItem.current],
			orden: dragOverItem.current
		};

		ArraysToUpdate.push(aux2);

		updateOrder(ArraysToUpdate);
		dragItem.current = null;
		dragOverItem.current = null;
		setCurrentDataTable(copyListItems);
	};

	const updateOrder = (updateData) => {
		updateData.forEach((element) => {
			const newData = {
				idTipoServicioActividad: element.idTipoServicioActividad,
				idTipoServicio: {
					idTipoServicio: element.idTipoServicio.id
				},
				idActividad: {
					idActividad: element.idActividad.id
				},
				idEtapaServicio: {
					idEtapaServicio: element.idEtapaServicio.id
				},
				orden: element.orden,
				tiempoActividad: element.tiempoActividad,
				actividadTecnica: element.actividadTecnica,
				descripcion: element.descripcion,
				fechaCreacion: element.fechaCreacion,
				fechaModificacion: element.fechaModificacion,
				usuarioModificacion: parseInt(Cookie.get('idUsuario')),
				usuarioCreacion: parseInt(element.usuarioCreacion)
			};

			RequestUseCase.put(endpoints.typoServiceActivities.updateTypeServiceActivities, newData)
				.then(() => {
					console.log('update order');
				})
				.catch((error) => {
					console.log(error);
				});
		});
	};

	const { setCurrentDataTable } = useContext(TableMinimalContext);

	// DELETE a Table Association Activities
	const handleDelete = (row) => {
		if (typeof row.idTipoServicioActividad === 'number') {
			console.log(
				'Se elimnar del servicio de TableAssociationActivities',
				row.idTipoServicioActividad
			);
			RequestUseCase.delete(
				endpoints.typoServiceActivities.deleteTypoServiceActivities(row.idTipoServicioActividad)
			)
				.then(() => {
					const newMinimalRows = data.filter((et) => {
						return et.idTipoServicioActividad !== row.idTipoServicioActividad;
					});
					setCurrentDataTable(newMinimalRows);
				})
				.catch((error) => {
					console.log(error);
				});
		} else {
			/// filter in the array data the id selected and slice it
			const newMinimalRows = data.filter((et) => {
				return et.idTipoServicioActividad !== row.idTipoServicioActividad;
			});
			setCurrentDataTable(newMinimalRows);
		}
		closeDialog();
	};

	return data.map((item, index) => (
		<tr
			key={`${index + 1}`}
			onDragStart={(e) => dragStart(e, index)}
			onDragEnter={(e) => dragEnter(e, index)}
			onDragEnd={drop}
			draggable
			className="table-minimal-dnd"
			id="drag"
		>
			<td>{item?.idActividad?.idActividad}</td>
			<td>{item?.idTipoServicio?.idTipoServicio}</td>
			<td>{item?.idEtapaServicio?.idEtapaServicio}</td>
			<td>{item?.actividadTecnica === 1 ? 'Sí' : 'No'}</td>
			<td>{item?.tiempoActividad}</td>
			<td>{item?.descripcion}</td>
			<td className="minimal-table__actions">
				<MdDelete
					size={25}
					color="gray"
					cursor="pointer"
					onClick={() => {
						setDialog({
							text: `${formatterText('title.service.confirm.delete.row.category')}`,
							active: true,
							action: 'delete',
							funcion: () => handleDelete(item)
						});
					}}
				/>
			</td>
		</tr>
	));
};

TableAssociationActivities.propTypes = {
	data: PropTypes.array,
	setDialog: PropTypes.func,
	closeDialog: PropTypes.func
};

export default TableAssociationActivities;
