import useAxios from 'hooks/useAxios';
import DynamicTable from 'common/DynamicTable/DynamicHead';
import Search from 'common/Search';
import currencyFormat from 'common/utils/currencyFormat';
import { MODULES_NAME } from 'common/validators/PermissionsConstants';
import { PermissionWrapper, usePermissionContext } from 'context/PermissionContext';
import { SearchWrapper, useSeachContext } from 'context/SearchContext';
import useLangv2 from 'hooks/useLangv2';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import endpoints from 'services/api';

const InvoiceTable = ({ ticket, expedient }) => {
	return (
		<SearchWrapper>
			<PermissionWrapper>
				<InvoiceTableComponent ticket={ticket} expedient={expedient} />
			</PermissionWrapper>
		</SearchWrapper>
	);
};

const InvoiceTableComponent = ({ ticket, expedient }) => {
	const { RequestUseCase } = useAxios();
	// use Hook of language v2
	const { formatterText, successRemoveItem, handleRequestError } = useLangv2();
	const titles = [
		formatterText('title.service.invoiceNumber'),
		formatterText('title.service.ticket'),
		formatterText('title.service.file'),
		formatterText('title.service.invoiceDate'),
		formatterText('title.service.subtotal'),
		formatterText('title.service.tax'),
		formatterText('title.service.actions')
	];
	const { id } = useParams();
	const placeholder = 'Facturas';
	const [show, setShow] = useState(false);

	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();
	// useContext de búsqueda
	const { setDataTable } = useSeachContext();

	const permissionsAccessGaranted = () => {
		permissionsAccess(MODULES_NAME.service.invoice);
	};

	const handleStructureItems = (item, array) => {
		array.push({
			id: item.idConciliacionFactura,
			numeroFactura: item.factura,
			ticket: item.ticket || 'N/A',
			numTicket: item.expediente || 'N/A',
			fecha: item.fechaFactura.split('T')[0],
			subtotal: currencyFormat(item.subtotalFactura),
			impuesto: currencyFormat(item.impuestoFactura)
		});
	};

	const getDataTable = () => {
		const params = {
			numTicket: ticket || 0,
			numExpedient: expedient || 0
		};
		const url = endpoints.services.getBillingByService(params);
		RequestUseCase.get(url)
			.then((data) => {
				setShow(true);
				const array = [];
				data.forEach((element) => handleStructureItems(element, array));
				setDataTable(array);
			})
			.catch(() => {
				setShow(false);
			});
	};
	const handleDeleteItem = (rowId) => {
		RequestUseCase.delete(endpoints.services.deleteInvoiceReconciliation(rowId))
			.then(() => {
				getDataTable();
				successRemoveItem();
			})
			.catch((err) => {
				const error = err.response.data
					? err
					: {
							response: {
								data: { message: 'Error Desconocido, por favor intente en unos minutos' }
							}
					  };
				console.log('error ', err);
				handleRequestError(error);
			});
	};

	useEffect(() => {
		getDataTable();
		permissionsAccessGaranted();
	}, [ticket, expedient]);

	useEffect(() => {
		getDataTable();
		permissionsAccessGaranted();
	}, [id]);

	useEffect(() => {
		permissionsAccessGaranted();
	}, [permissions]);

	return (
		<section className="table-container">
			{show && permittedActions.consultar ? (
				<section className="userOptions">
					<Search
						placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
						width="50%"
					/>
				</section>
			) : (
				''
			)}
			{show && permittedActions.consultar ? (
				<DynamicTable
					titles={titles}
					pageName={placeholder}
					getData={getDataTable}
					handleDeleteItem={handleDeleteItem}
					canDeletedInvoice={permittedActions.eliminar}
				/>
			) : (
				formatterText('error.billing.does.not.exist.by.service')
			)}
		</section>
	);
};

export default InvoiceTable;
