// Se definen las expresiones regulares a utilizar
// /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

// TEXTREGEX: This regex validates a text string containing alphanumeric characters, including spaces, letters with diacritics and the letter "ñ".
export const TEXTREGEX = /^(\d|[a-zA-Z]|\s)*$/;
export const TEXTREGEXMIN = /^(\d|[a-z0-9])*$/;
// CODEREGEX: This regex validates a string containing only word characters. Word characters are defined as alphanumeric characters (letters and digits) and underscores.
export const CODEREGEX = /^(\d|[a-zA-Z])*$/;
export const CODEREGEXBARCODE = /^[a-zA-Z0-9-]+$/;
// NUMBERREGEX: This regex validates a string containing only digits.
export const NUMBERREGEX = /^\d*$/;
export const PRICEREGEX = /^[$]?[\s{1}]?[\d+(,.)?]*$/; ///^[$]?[\s{1}]?[\d+(\.})?]*$/;
// eslint-disable-next-line no-useless-escape
export const ADDRESSREGEX = /^[\w\#\-\s]*$/;
export const SKUREGEX = /^(\w-?)*$/;
export const IDREGEX = /^(\w{0,40})?$/;
export const COMPOUNDNAMEREGEX = /^([a-zA-Z0-9]+[\s{1}]?)*$/;
export const URLREGEX = /^[https?://www.]?[\w*(-/=#)?]*[.\w{2,}]*$/;
export const CARREGEX = /^[A-Za-z]{1,}[\d]{1,}[a-zA-z]{0,}$/;
export const MODELCARREGEX = /^[\d]{4,4}$/;
export const STRINGREGEX = /^[a-zA-Z áéíóúÁÉÍÓÚñÑ]*$/;
export const PREFIXREGEX = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9]+(?: [a-zA-ZáéíóúÁÉÍÓÚñÑ0-9]+)*$/;
export const SPACING = '^[a-zA-Z0-9 ñáéíóúÁÉÍÓÚÑ]*$';
export const ALPHANUMERICREGEX = /^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑ]*$/;
export const ONLY_NUMBERS_REGEX = /^[0-9]+$/;
export const TEXTONLYONESPACEREGEX = /^([a-zA-Z]+\s)*([a-zA-Z]+)?$/;
export const NOSPACEREGEX = /^[a-zA-Z0-9]*$/;
export const TEXTREGEXWITHPOINTS = /^[a-zA-Z.-]*$/;
export const EMAILREGEX = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const REGEX_CODE = /[^a-zA-Z0-9\s]/g;
export const CCREGEX = /^(\d{1,10})?$/;
export const NAMEROLE_REGEX = /^[a-zA-Z0-9]*(?: [a-zA-Z0-9]*)*$/;
