import { useForm } from '@formiz/core';

export default function UserExternalInfoViewModel() {
	// Formiz object
	const myForm = useForm();

	// This function is executed when the create button is clicked
	const handleSubmit = () => {};

	return {
		myForm,
		handleSubmit
	};
}
