import useAxios from 'hooks/useAxios';
//Import hooks
import useGetData from 'hooks/useGetData';
//Import services
import { formatterText } from 'hooks/useLangv2';
import 'styles/editProfile.css';
import { SendMailFormViewModel } from './components';

export default function SendMailViewModel() {
	const { endpoints, RequestUseCase } = useAxios();

	const { error, success, toggleError, toggleSuccess, handleClick, displayMessage } = useGetData();

	const onSubmit = (values, actions) => {
		sendEmailTest(values, actions);
	};

	const { formik, parseDataToSubmit } = SendMailFormViewModel({
		onSubmit,
		formatterText
	});

	const sendEmailTest = async (values, actions) => {
		actions.setSubmitting(true);

		const FIX_formData = parseDataToSubmit(values);
		RequestUseCase.post(endpoints.mail.sendMailTest, FIX_formData)
			.then(() => {
				toggleSuccess(true);
			})
			.catch(() => {
				toggleError(true);
			})
			.finally(() => {
				actions.setSubmitting(false);
				handleClick();
			});
	};
	return {
		displayMessage,
		error,
		formik,
		success,
		formatterText
	};
}
