import React from 'react';

// Import Libs
import { FormattedMessage } from 'react-intl';

// Import Paths
import paths from 'services/paths';

// Import ViewModel
import UpdateClientTypeViewModel from './UpdateClientType.viewModel';

export default function UpdateClientType() {
	const {
		active,
		codeError,
		errorName,
		formatterText,
		formData,
		handleCode,
		handleErrorCode,
		handleErrorName,
		handlerTextDescription,
		handleSubmit,
		handleText,
		navigate,
		setActive
	} = UpdateClientTypeViewModel();

	return (
		<form className="form-responsive" onSubmit={handleSubmit}>
			<section className="form-responsive-container-information">
				<section className="form-responsive-information__option">
					<span className="warpForm-text">
						<FormattedMessage id="table.title.client.type" defaultMessage="Nombre tipo cliente" />
					</span>
					<input
						className={(formData.nombre ? '' : 'input-error ') + 'input-primary'}
						type="text"
						name="nombre"
						value={formData.nombre}
						onChange={handleText}
						placeholder={formatterText('table.title.client.type', 'Nombre tipo cliente')}
						maxLength="100"
						onBlur={handleErrorName}
					/>
				</section>
				{errorName && (
					<div
						style={{ fontSize: '0.7rem', color: 'red', marginTop: '-20px', marginRight: '500px' }}
					>
						{errorName}
					</div>
				)}
				<section className="form-responsive-information__option">
					<span className="p-styles">
						<FormattedMessage id="table.title.description" defaultMessage="Descripción" />
					</span>
					<textarea
						className="input-primary-textarea"
						type="text"
						name="descripcion"
						value={formData.descripcion}
						onChange={handlerTextDescription}
						placeholder={formatterText('table.title.description', 'Descripción')}
						maxLength="200"
					/>
				</section>
				<section className="form-responsive-information__option">
					<span className="p-styles">
						<FormattedMessage id="table.title.code" defaultMessage="Código" />
					</span>
					<input
						className={(formData.codigo ? '' : 'input-error ') + 'input-primary'}
						//pattern="^\S+(?: \S+)*$"
						type="text"
						name="codigo"
						value={formData.codigo}
						onChange={handleCode}
						placeholder={formatterText(
							'input.placeholder.max.45.chars',
							'Ingrese hasta 45 caracteres'
						)}
						maxLength="45"
						onBlur={handleErrorCode}
					/>
				</section>
				{codeError && (
					<div
						style={{ fontSize: '0.7rem', color: 'red', marginTop: '-20px', marginRight: '500px' }}
					>
						{codeError}
					</div>
				)}
				<section className="form-responsive-information__option">
					<span className="p-styles">
						<FormattedMessage id="table.title.state" defaultMessage="Estado" />
					</span>
					<label className="form-responsive-label">
						<p className="form-responsive-toggle">
							{active
								? formatterText('p.active', 'Activo')
								: formatterText('p.unActive', 'No activo')}
						</p>
						<label className="switch">
							<input
								checked={!!active}
								onChange={() => {
									setActive(!active);
								}}
								type="checkbox"
							/>
							<span className="slider round"></span>
						</label>
					</label>
				</section>
			</section>
			<section className="form-responsive-container-buttons">
				<button type="submit" className="btn-primary">
					<FormattedMessage id="btn.save.changes" defaultMessage="Guardar cambios" />
				</button>
				<button className="input-cancel" onClick={() => navigate(paths.parameters)}>
					<FormattedMessage id="btn.cancel" defaultMessage="Cancelar" />
				</button>
			</section>
		</form>
	);
}
