import useAxios from 'hooks/useAxios';
import { useContext, useEffect, useState } from 'react';
import { TableMinimalContext } from 'context/TableMinimalContext';
import useGetData from 'hooks/useGetData';
import useLang from 'hooks/useLang';
import useLangv2 from 'hooks/useLangv2';
import { useNavigate, useParams } from 'react-router-dom';
import HandleInput from 'common/validators/HandleInput';
import HandleOnError from 'common/validators/HandleOnError';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import { SPACING, TEXTREGEXMIN } from 'common/validators/Regex';
import CustomAlert from 'components/CustomAlert';
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
import { useSeachContext } from 'context/SearchContext';
import paths from 'services/paths';

export default function UpdateListPriceViewModel() {
	const { RequestUseCase, endpoints } = useAxios();

	const { loading, error, toggleLoading, displayMessage, displayLoading } = useGetData();

	// Modal config
	const [open, setOpen] = useState(false);
	const [valueMinimalPrice, setMinimalPrice] = useState('');
	const [valueTax, setValueTax] = useState('');
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	// Call context TableMinimalContext
	const { currentDataTable, setCurrentDataTable, setResultsTableSearch } =
		useContext(TableMinimalContext);
	const navigate = useNavigate();
	// useLanguage
	const { formatterText } = useLang();
	const { noFilledContent } = useLangv2();
	const [errorName, setErrorName] = useState('');
	const [codeError, setCodeError] = useState('');

	const titlesTableCostProduct = [
		formatterText('p.label.title.tipoServicio'),
		formatterText('p.label.title.actividadAsociada'),
		formatterText('p.label.title.unidadMedida'),
		formatterText('p.label.title.valor'),
		formatterText('p.label.title.impuesto'),
		formatterText('p.label.title.acciones')
	];
	const { newItemCreated, resourceNotFound, handleRequestError } = useLangv2();
	const [service, setService] = useState([]);
	// Example of form data
	const [formData, setFormData] = useState([]);
	const [currentItemMinimal, setCurrentItemMinimal] = useState({
		idPrecioAsociado: 0,
		idTipoServicio: 1,
		idActividadAsociada: 0,
		valor: 0,
		impuesto: 0,
		idUnidadMedida: 0
	});
	const { id } = useParams();
	// Los valores que usará el select
	const [selectedSearch, setSearchSelected] = useState([
		{
			tipoServicio: [],
			actividadAsociada: [],
			unidadMedida: [],
			valor: 0
		}
	]);
	// espera a que cargue los valores del multiselect
	const [auxData, setAuxData] = useState({
		idActividadAsociada: 0,
		idTipoServicio: 0,
		idUnidadMedida: 0
	});

	const { setDataTable } = useSeachContext();
	// toggle state
	const [active, setActive] = useState(true);

	useEffect(() => {
		getMonedas();
		getUnitMeasure();
		getTypoService();
		getDataToUpdate();
		getMinimalTable();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getPriceListById(id);
		getMinimalTableFromApi();
	}, [id]);

	//Cuando todo termine de cargar, se agrega el valor de la moneda al AuxData
	useEffect(() => {
		monedaDefault();
	}, [loading]);
	//No borrar, es para una respuetsa visual al usuario (imposibildiad de seleccionar con el select por comando)
	const printMoneda = JSON.parse(localStorage.getItem('dataUpdate'));
	//Guardar en el auxData el valor de la moneda que esta en localSotorage, pues esta sera la que se usara
	//para poder hacer post
	const monedaDefault = () => {
		const dataUpdate = JSON.parse(localStorage.getItem('dataUpdate'));
		setAuxData((prev) => ({
			...prev,
			idMoneda: {
				value: dataUpdate.idMoneda.idMoneda,
				label: `${dataUpdate.idMoneda.nombre} (${dataUpdate.idMoneda.codigo})`,
				isFixed: true
			}
		}));
	};

	const getMonedas = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.listPrices.getAllMonedas)
			.then((res) => {
				const newArray = [];
				// iterate response and get only the values that are active
				res.map((item) => {
					if (item.estado === 1) {
						const data = {
							value: item.idMoneda,
							label: `${item.nombre} - ${item.codigo}`,
							isFixed: true
						};
						newArray.push(data);
					}
				});
				setSearchSelected((prev) => ({
					...prev,
					tipoMonedas: newArray
				}));
				toggleLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toggleLoading(false);
			});
	};

	const getDataToUpdate = () => {
		const dataUpdate = JSON.parse(localStorage.getItem('dataUpdate'));
		if (dataUpdate) {
			setActive(dataUpdate.estado);
			setFormData(dataUpdate);
		} else {
			navigate(paths.services);
		}
	};

	const getPriceListById = (id) => {
		RequestUseCase.get(endpoints.listPrices.getListPriceById(id))
			.then((res) => {
				if (res === null) {
					setFormData({
						nombre: '',
						moneda: '',
						codigo: '',
						descripcion: '',
						estado: 0
					});
					resourceNotFound();
				} else {
					setActive(res.estado);
					setFormData(res);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getTypoService = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.typeService.getAllTypeService)
			.then((res) => {
				// create new array
				const newArray = [];

				// iterate response and get only the values that are active
				res?.forEach((item) => {
					if (item?.estado === 1) {
						newArray.push({
							value: item?.idTipoServicio,
							label: item?.nombre,
							isFixed: true
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					tipoServicio: newArray
				}));
				setService((prev) => ({
					...prev,
					tipoServicio: newArray
				}));

				toggleLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toggleLoading(false);
			});
	};

	const formatCurrencyToRequest = (value) => {
		const parts = value.toString().split('.');
		let formattedValue = `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '')}`;

		if (parts.length > 1) {
			formattedValue += `.${parts[1].slice(0, 2)}`;
		}

		return formattedValue;
	};

	const formatIvaToRequest = (value) => {
		const roundedValue = Number(value).toFixed(2);
		const parts = roundedValue.toString().split('.');
		let formattedValue = `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '')}`;

		if (parts.length > 1) {
			formattedValue += `.${parts[1]}`;
		}

		return formattedValue;
	};

	const getDataToMinimlTable = async (idList) => {
		toggleLoading(true);

		try {
			const res = await RequestUseCase.get(
				endpoints.associatePrices.getAssociatePricesByListPrice(idList)
			);

			const newArray = [];
			console.log(res);
			// iterate response and get only the values that are active
			res?.map((item) => {
				const data = {
					idTipoServicio: {
						value: item.idTipoServicioActividad.idTipoServicio.idTipoServicio,
						label: `${item.idTipoServicioActividad.idTipoServicio.nombre}`
					},
					idUnidadMedida: {
						value: item.idUnidadMedida.idUnidadMedida,
						label: `${item.idUnidadMedida.descripcion} - ${item.idUnidadMedida.abreviatura}`
					},
					idActividadAsociada: {
						value: item.idTipoServicioActividad.idActividad.idActividad,
						label: `${item.idTipoServicioActividad.idActividad.nombre} - ${item.idTipoServicioActividad.idActividad.sku}`
					},
					idPrecioAsociado: item.idListaPreciosActividadDetalle,
					valor: formatCurrency(item.valor.toString()),
					impuesto: formatIvaToTable(item?.impuesto?.toString()),
					idTipoServicioActividad: item.idTipoServicioActividad.idTipoServicioActividad
				};

				newArray.push(data);
			});

			setCurrentDataTable(newArray);
			setResultsTableSearch(newArray);
			toggleLoading(false);
			setDataTable(newArray);
		} catch (err) {
			console.error(err);
			toggleLoading(false);
		}
	};

	const getMinimalTable = async () => {
		const dataUpdate = JSON.parse(localStorage.getItem('dataUpdate'));
		const idListaPrecio = dataUpdate.idListaPreciosActividad;
		getDataToMinimlTable(idListaPrecio);
	};

	const getMinimalTableFromApi = async () => {
		getDataToMinimlTable(id);
	};

	const getUnitMeasure = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.institutions.getUnidadMedida)
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idUnidadMedida,
							label: `${item.descripcion} - ${item.abreviatura}`,
							isFixed: true
						});
					}
				});
				setSearchSelected((prev) => ({
					...prev,
					unidadMedida: newArray
				}));
				toggleLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toggleLoading(false);
			});
	};

	const handleInputCarac = (e) => {
		HandleInput(e, SPACING, formData, setFormData);
	};

	const handleCode = (e) => {
		const example = e;
		example.target.value = e.target.value.toLowerCase();
		HandleInput(example, TEXTREGEXMIN, formData, setFormData);
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	const handleCostoChange = (event) => {
		const inputCosto = event.target.value;

		const costoFormateado = inputCosto.replace(/[^\d,]/g, '').replace(/(,.*)\,/g, '$1');

		setMinimalPrice(costoFormateado);

		const costoDecimal = parseFloat(costoFormateado.replace(',', '.'));
		const costoConDosDecimales = costoDecimal.toLocaleString('es-ES', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});

		setCurrentItemMinimal({
			...currentItemMinimal,
			[event.target.name]: `$${costoConDosDecimales}`
		});
	};

	const handleIvaChange = (event) => {
		const inputIva = event.target.value;

		const ivaFormateado = inputIva.replace(/[^\d,]/g, '');
		setValueTax(ivaFormateado);

		const ivaDecimal = parseFloat(ivaFormateado.replace(',', '.'));
		const ivaConDosDecimales = ivaDecimal.toLocaleString('es-ES', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});

		setCurrentItemMinimal({
			...currentItemMinimal,
			[event.target.name]: ivaConDosDecimales
		});
	};

	const formatIvaToTable = (value) => {
		const parts = value.toString().split('.');
		let formattedValue = `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;

		if (parts.length > 1) {
			formattedValue += `,${parts[1]}`;
		}

		return formattedValue;
	};

	// This function is executed when the create button is clicked
	const handleSubmit = async (e) => {
		let state = 0;
		if (active) {
			state = 1;
		}
		e.preventDefault();
		if (errorName !== '' || codeError !== '' || formData.nombre === '' || formData.codigo === '') {
			noFilledContent();
		} else {
			const data = {
				...formData,
				idMoneda: auxData.idMoneda.value,
				usuarioModificacion: parseInt(Cookie.get('idUsuario')),
				estado: state
			};
			updateProduct(data);
		}
	};
	const updateProduct = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general'),
			text: formatterText('alert.description.create.general'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general'),
			preConfirm: () => {
				return new Promise(() => {
					// this service create the item
					RequestUseCase.put(endpoints.listPrices.updateListPrice, data)
						.then(() => {
							CustomAlert('confirm_msg', {
								icon: 'success',
								title: formatterText('alert.title.confirm.general'),
								text: formatterText('alert.message.associations.general'),
								confirmButtonText: formatterText('alert.button.continue'),
								allowOutsideClick: false,
								executeFunction: () => navigate(paths.services)
							});
						})
						.catch((err) => {
							console.log(err);
							if (err === 412) {
								HandleOnError(formatterText('El código está repetido'));
							} else {
								HandleOnError(formatterText(err.response.data.message));
							}
						});
				});
			}
		});
	};

	const handleAddItemMinimal = (e) => {
		e.preventDefault();
		toggleLoading(true);
		const dataUpdate = JSON.parse(localStorage.getItem('dataUpdate'));
		const idListaPrecio = dataUpdate.idListaPreciosActividad;
		// Comprobar con current SI EL DATO NO ESTA NULL O 0 Para poder asociar
		if (
			!(
				currentItemMinimal.valor === '$NaN' ||
				currentItemMinimal.valor === 0 ||
				typeof currentItemMinimal.valor === 'undefined'
			) &&
			!Number.isNaN(currentItemMinimal.impuesto) &&
			auxData.idTipoServicio !== 0 &&
			!(
				auxData.idUnidadMedida.value === 0 || typeof auxData.idUnidadMedida.value === 'undefined'
			) &&
			!(
				auxData.idActividadAsociada.value === 0 ||
				typeof auxData.idActividadAsociada.value === 'undefined'
			)
		) {
			const filter = currentDataTable.filter(
				(item) =>
					item.idActividadAsociada.value === auxData.idActividadAsociada.value &&
					item.idUnidadMedida.value === auxData.idUnidadMedida.value
			);
			if (filter.length >= 1) {
				toggleLoading(false);
				CustomAlert('short_msg', {
					icon: 'error',
					title: 'Oops...',
					text: formatterText('p.label.title.valorRepetido')
				});
			} else {
				sendPut(idListaPrecio);
			}
		} else {
			toggleLoading(false);
			CustomAlert('short_msg', {
				icon: 'error',
				title: 'Oops...',
				text: formatterText('p.label.title.ingreseTodosLosDatos')
			});
		}
	};

	const sendPut = (idListaPrecio) => {
		//Envio del primer dato, con auxData para evitar bug de version anterior
		const ACTIVITY_IDS = auxData.idActividadAsociada?.value.split('-');
		const data1 = {
			idListaPreciosActividadDetalle: null,
			idListaPreciosActividad: idListaPrecio,
			idTipoServicio: auxData.idTipoServicio.value,
			idActividad: parseInt(ACTIVITY_IDS[0]),
			idTipoServicioActividad: parseInt(ACTIVITY_IDS[1]),
			idUnidadMedida: auxData.idUnidadMedida.value,
			valor: Number(formatCurrencyToRequest(valueMinimalPrice.replace(',', '.'))),
			impuesto: formatIvaToRequest(valueTax.replace(',', '.'))
		};

		RequestUseCase.post(endpoints.associatePrices.addAssociate, data1)
			.then((res) => {
				setCurrentItemMinimal({
					idTipoServicio: currentItemMinimal.idTipoServicio,
					idUnidadMedida: currentItemMinimal.idUnidadMedida,
					idActividadAsociada: currentItemMinimal.idActividadAsociada,
					idPrecioAsociado: res.idListaPreciosActividadDetalle
				});
				const data2 = {
					idTipoServicio: auxData.idTipoServicio,
					idUnidadMedida: auxData.idUnidadMedida,
					idActividadAsociada: auxData.idActividadAsociada,
					valor: `$${formatCurrencyToRequest(valueMinimalPrice.replace('.', ','))}`,
					impuesto: currentItemMinimal.impuesto,
					idPrecioAsociado: res.idListaPreciosActividadDetalle
				};

				getMinimalTable();
				setCurrentDataTable([...currentDataTable, data2]);
				setResultsTableSearch([...currentDataTable, data2]);
				setMinimalPrice('');
				toggleLoading(false);
				setSelectedOption({
					idUnidadMedida: {
						value: 0,
						label: 'Seleccione una unidad de medida'
					},
					idTipoServicio: {
						value: 0,
						label: 'Seleccione un tipo de servicio'
					},
					idActividadAsociada: {
						value: 0,
						label: 'Seleccione una actividad asociada'
					}
				});
				setValueTax('');
				newItemCreated();
			})
			.catch((err) => {
				toggleLoading(false);
				handleRequestError(err);
			});
	};

	//FIX ERROR SPRINT 7
	useEffect(() => {
		if (auxData.idTipoServicio !== 0) {
			setCurrentItemMinimal((prev) => ({
				...prev,
				idActividadAsociada: 0
			}));
			setSearchSelected((prev) => ({
				...prev,
				actividadesServicios: []
			}));
			setAuxData((prev) => ({
				...prev,
				idActividadAsociada: 0
			}));
			getAllActivitiesById(auxData.idTipoServicio);
		}
	}, [auxData.idTipoServicio]);

	// Get all type service by id category service
	const getAllActivitiesById = (selectValue) => {
		// Set the value of datas into []
		RequestUseCase.get(
			endpoints.typoServiceActivities.getTypeServiceActivitiesByTypeService(selectValue.value)
		)
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					newArray.push({
						value: `${item.idActividad.idActividad}-${item.idTipoServicioActividad}`,
						label: `${item.idActividad.nombre} - ${item.idEtapaServicio.nombre}`,
						isFixed: true
					});
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					actividadesServicios: newArray
				}));
				toggleLoading(false);
			})
			.catch((err) => {
				console.error(err);
				toggleLoading(false);
			});
	};

	//CUSTOM STYLES
	const customStyles = {
		control: (base) => ({
			...base,
			border: '0px !important',
			'font-family': 'inherit',
			'font-size': '14px',
			height: '40px',
			padding: '0 6px',
			'max-width': '100%',
			'min-width': '225px',
			'box-shadow': '0px 4px 8px rgba(0, 174, 142, 0.2)',
			'border-radius': '70px !important'
		})
	};
	const [selectedOption, setSelectedOption] = useState({
		idUnidadMedida: {
			value: null,
			label: 'Seleccione una unidad de medida'
		},
		idTipoServicio: {
			value: null,
			label: 'Seleccione un tipo de servicio'
		},
		idActividadAsociada: {
			value: null,
			label: 'Seleccione una actividad asociada'
		},

		impuesto: {
			value: null,
			label: 'Seleccione un impuesto asociado'
		}
	});

	const formatIvaField = (value) => {
		const parts = value.toString().split('.');
		let formattedValue = `${value.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;

		if (parts.length > 1) {
			formattedValue += `.${parts[1]}`;
		}
		const splitDecimal = formattedValue.split(',');
		const intPart = splitDecimal[0];
		const floatPart = splitDecimal[1];

		if (floatPart) {
			formattedValue = intPart + ',' + floatPart.substring(0, 2);
		}

		return formattedValue;
	};

	const formatCurrency = (value) => {
		const parts = value.toString().split('.');
		let formattedValue = `$${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;

		if (parts.length > 1) {
			formattedValue += `.${parts[1]}`;
		}
		const splitDecimal = formattedValue.split(',');
		const intPart = splitDecimal[0];
		const floatPart = splitDecimal[1];

		if (floatPart) {
			formattedValue = intPart + ',' + floatPart.substring(0, 2);
		}

		return formattedValue;
	};

	const handleErrorName = () => {
		if (formData.nombre.trim() === '') {
			setErrorName(formatterText('p.label.title.name.is.required'));
		} else {
			setErrorName('');
		}
	};

	const handleErrorCode = () => {
		if (formData.codigo.trim() === '') {
			setCodeError(formatterText('table.title.code.required'));
		} else {
			setCodeError('');
		}
	};
	return {
		auxData,
		error,
		errorName,
		active,
		codeError,
		formData,
		loading,
		open,
		selectedOption,
		service,
		valueMinimalPrice,
		valueTax,
		currentDataTable,
		selectedSearch,
		customStyles,
		titlesTableCostProduct,
		displayLoading,
		displayMessage,
		formatterText,
		formatCurrency,
		formatIvaField,
		getMinimalTable,
		handleAddItemMinimal,
		handleCode,
		handleCostoChange,
		handleErrorCode,
		handleErrorName,
		handleIvaChange,
		handleInputCarac,
		handleOpen,
		handleClose,
		handlerTextDescription,
		handleSubmit,
		setActive,
		setAuxData,
		setSelectedOption,
		setResultsTableSearch,
		printMoneda,
		navigate
	};
}
