import React from 'react';
// Import Hooks
import { Formiz } from '@formiz/core';
// Import Components
import { InputFieldResponsive } from 'common/inputs/InputFieldResponsive';
import InputSelectorResponsive from 'common/inputs/InputSelectorResponsive';
import { FormattedMessage } from 'react-intl';
// Import Libs
// Import Services
import HandlerChangeMailAssesor from '../../utils/ValidateMailAssesor';
import { AddAdviserProps } from './AddAdviser.props';
import AddAdviserViewModel from './AddAdviser.viewModel';

export default function AddAdviser({ onClose, reloadData }) {
	const {
		selectData,
		modalData,
		myForm,
		currentAsesor,
		setCurrentAsesor,
		error,
		setError,
		altErr,
		setAltErr,
		loadingData,
		handleBlurMailAsesor,
		handleBlurMailAltAsesor,
		handleSubmit,
		formatterText
	} = AddAdviserViewModel({ onClose, reloadData });
	return (
		<Formiz onSubmit={handleSubmit} className="form-responsive" connect={myForm}>
			<form className="container-wrapForm" noValidate onSubmit={myForm.submit}>
				<section className="grid-container-1c zero-gap">
					<label className="d-flex">
						<span className="text-inline-md">
							<FormattedMessage id="p.document.type" defaultMessage="Tipo de documento" />
						</span>
						<InputSelectorResponsive
							type="text"
							name="idTipoDocumentoAsesor"
							id="idTipoDocumentoAsesor"
							className="input-select-small"
							placeholder={
								<FormattedMessage
									id="input.placeholder.select"
									defaultMessage="Selecione una opción"
								/>
							}
							required="Municipio es requerido"
							defaultValue={{
								label:
									typeof modalData.idTipoDocumentoAsesor === 'string'
										? JSON.parse(modalData.idTipoDocumentoAsesor).label
										: modalData.idTipoDocumentoAsesor.label,
								value:
									typeof modalData.idTipoDocumentoAsesor === 'string'
										? JSON.parse(modalData.idTipoDocumentoAsesor).value
										: modalData.idTipoDocumentoAsesor.value
							}}
							data={
								!!selectData.documentTypes &&
								selectData.documentTypes.map((item) => {
									const docLabel = `${item.nombre} - ${item.abreviatura}`;
									return {
										label: docLabel,
										value: JSON.stringify({
											label: `${item.nombre} - ${item.abreviatura}`,
											value: JSON.stringify({
												label: `${item.nombre} - ${item.abreviatura}`,
												value: item.idTipoDocumento
											})
										})
									};
								})
							}
							isLoading={loadingData}
						/>
					</label>

					<label className="d-flex">
						<span className="text-inline-md">
							<FormattedMessage id="text.ID" defaultMessage="Identificación" />
						</span>
						<InputFieldResponsive
							type="integer"
							name="identificacion"
							id="identificacion"
							placeholder="Identificación"
							validateInput="integer"
							required="La identificacion es requerida"
							className="input-primary width-50"
							defaultValue={modalData.identificacion}
						/>
					</label>
					<label className="d-flex">
						<span className="text-inline-md">
							<FormattedMessage id="p.genero" defaultMessage="Género" />
						</span>
						<InputSelectorResponsive
							type="text"
							name="genero"
							id="genero"
							className="input-select-small"
							placeholder={
								<FormattedMessage
									id="input.placeholder.select"
									defaultMessage="Selecione una opción"
								/>
							}
							required="Género es requerido"
							defaultValue={{
								label:
									typeof modalData.genero === 'string'
										? JSON.parse(modalData.genero).label
										: modalData.genero.label,
								value:
									typeof modalData.genero === 'string'
										? JSON.parse(modalData.genero).value
										: modalData.genero.value
							}}
							data={
								!!selectData.genres &&
								selectData.genres.map((item) => {
									const docLabel = `${item.nombre} - ${item.abreviatura}`;
									return {
										label: docLabel,
										value: JSON.stringify({
											label: docLabel,
											value: item.idGenero
										})
									};
								})
							}
							isLoading={loadingData}
						/>
					</label>

					<label className="d-flex">
						<span className="text-inline-md">
							<FormattedMessage id="table.title.full.name" defaultMessage="Nombre completo" />
						</span>
						<InputFieldResponsive
							type="text"
							name="nombreAsesor"
							id="nombreAsesor"
							placeholder="Nombre completo"
							validateInput="nombreAsesor"
							required="El Nombre completo es requerido"
							className="input-primary width-50"
							defaultValue={modalData.nombreAsesor}
						/>
					</label>

					<label className="d-flex">
						<span className="text-inline-md">
							<FormattedMessage id="table.title.position" defaultMessage="Cargo" />
						</span>
						<InputSelectorResponsive
							type="text"
							name="idCargo"
							id="idCargo"
							className="input-select-small"
							placeholder={
								<FormattedMessage
									id="input.placeholder.select"
									defaultMessage="Selecione una opción"
								/>
							}
							required="El cargo es requerido"
							defaultValue={{
								label:
									typeof modalData.idCargo === 'string'
										? JSON.parse(modalData.idCargo).label
										: modalData.idCargo.label,
								value:
									typeof modalData.idCargo === 'string'
										? JSON.parse(modalData.idCargo).value
										: modalData.idCargo.value
							}}
							data={
								!!selectData.cargos &&
								selectData.cargos.map((item) => {
									const docLabel = `${item.nombre}`;
									return {
										label: docLabel,
										value: JSON.stringify({
											label: item.nombre,
											value: JSON.stringify({
												label: item.nombre,
												value: item.idCargo
											})
										})
									};
								})
							}
							isLoading={loadingData}
						/>
					</label>
					<label className="d-flex">
						<span className="text-inline-md">
							<FormattedMessage id="p.correo" defaultMessage="Correo" />
						</span>
						<input
							type="email"
							name="emailAsesor"
							id="emailAsesor"
							placeholder="Correo"
							value={currentAsesor.emailAsesor}
							onChange={(e) =>
								HandlerChangeMailAssesor(e, currentAsesor, setCurrentAsesor, setError)
							}
							onBlur={handleBlurMailAsesor}
							className="input-default-3c"
						/>
						{error && <div className="error-msg">{error}</div>}
					</label>
					<label className="d-flex">
						<span className="text-inline-md">
							<FormattedMessage id="p.correoalterno" defaultMessage="Correo alterno" />
						</span>
						<input
							type="email"
							name="correoAlternoAsesor"
							id="correoAlternoAsesor"
							placeholder="Correo alterno"
							value={currentAsesor.correoAlternoAsesor}
							onChange={(e) =>
								HandlerChangeMailAssesor(e, currentAsesor, setCurrentAsesor, setAltErr)
							}
							onBlur={handleBlurMailAltAsesor}
							className="input-default-3c"
						/>
						{altErr && <div className="error-msg">{altErr}</div>}
					</label>

					<label className="d-flex">
						<span className="text-inline-md">
							<FormattedMessage id="table.title.phone" defaultMessage="Teléfono" />
						</span>
						<InputFieldResponsive
							name="telefonoAsesor"
							id="telefonoAsesor"
							placeholder="Teléfono"
							type="number"
							required="El Teléfono es requerido"
							className="input-primary width-50"
							validateInput="o_number"
							defaultValue={modalData.telefonoAsesor}
						/>
					</label>

					<label className="d-flex">
						<span className="text-inline-md">
							<FormattedMessage id="p.alt.phone" defaultMessage="Teléfono alterno" />
						</span>
						<InputFieldResponsive
							name="telefonoAlternoAsesor"
							id="telefonoAlternoAsesor"
							placeholder="Teléfono alterno"
							type="number"
							required="El Teléfono alterno es requerido"
							className="input-primary width-50"
							validateInput="o_number"
							defaultValue={modalData.telefonoAlternoAsesor}
						/>
					</label>

					<label className="d-flex">
						<span className="text-inline-md">
							<FormattedMessage id="p.city.residence" defaultMessage="Ciudad de residencia" />
						</span>
						<InputSelectorResponsive
							type="text"
							name="idMunicipio"
							id="idMunicipio"
							className="input-select-small"
							placeholder={
								<FormattedMessage
									id="input.placeholder.select"
									defaultMessage="Selecione una opción"
								/>
							}
							required="La ciudad de residencia es requerida"
							defaultValue={{
								label:
									typeof modalData.idMunicipio === 'string'
										? JSON.parse(modalData.idMunicipio).label
										: modalData.idMunicipio.label,
								value:
									typeof modalData.idMunicipio === 'string'
										? JSON.parse(modalData.idMunicipio).value
										: modalData.idMunicipio.value
							}}
							data={
								!!selectData.municipalities &&
								selectData.municipalities.map((item) => {
									const docLabel = `${item.nombre}`;
									return {
										label: docLabel,
										value: JSON.stringify({
											label: item.nombre,
											value: JSON.stringify({
												label: item.nombre,
												value: item.idMunicipio
											})
										})
									};
								})
							}
							isLoading={loadingData}
						/>
					</label>
				</section>
				<section className="form-responsive-container-buttons">
					<input
						type="submit"
						className="width-100-fix"
						value={formatterText('btn.update.adviser')}
					/>
					<input
						type="button"
						className="width-100-fix input-cancel"
						value={formatterText('btn.cancel')}
						onClick={onClose}
					/>
				</section>
			</form>
		</Formiz>
	);
}

AddAdviser.propTypes = AddAdviserProps;
