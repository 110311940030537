import Cookie from 'js-cookie';

class BusinessUnit {
	constructor(
		nombre = '',
		descripcion = '',
		idMercado = '',
		codigo = '',
		estado = 1,
		fecha_registro = '',
		usuario_creacion = Cookie.get('idUsuario')
	) {
		this.nombre = nombre;
		this.descripcion = descripcion;
		this.idMercado = idMercado;
		this.codigo = codigo;
		this.estado = estado;
		this.fecha_registro = fecha_registro;
		this.usuario_creacion = usuario_creacion;
	}
}

export default BusinessUnit;
