import RolSelector from 'common/RolSelector';
import { FormattedMessage } from 'react-intl';
import paths from 'services/paths';
import './createUser.css';

import { Button } from 'common/Button';
import { SelectField } from 'common/SelectField';
import { Input } from 'common/inputs/Input';
import { FormikProvider } from 'formik';
import PropTypes from 'prop-types';

export const FormComponents = ({
	formatterText,
	formik,
	setToggleSelector,
	toggleSelector,
	setSearch,
	selectedRoles,
	handleRemoveRole,
	handleAddRole,
	acces,
	setAcces,
	selectorData,
	navigate
}) => {
	const { errors, handleBlur, handleChange, handleSubmit, touched, values } = formik;

	const userOptions = [
		{
			label: formatterText('input.placeholder.third', 'Tercero'),
			value: 'tercero'
		},
		{
			label: formatterText('input.placeholder.employee', 'Empleado'),
			value: 'empleado'
		},
		{
			label: formatterText('input.placeholder.clientAssesor', 'Asesor Cliente'),
			value: 'cliente'
		}
	];
	return (
		<FormikProvider value={formik}>
			<form className="form-responsive margin-top" noValidate onSubmit={handleSubmit}>
				<Input
					type="text"
					name="usuario"
					labelText={formatterText('input.placeholder.userName', 'Ingrese el nombre de usuario')}
					placeholder={formatterText('input.placeholder.userName')}
					touched={touched.usuario}
					value={values.usuario}
					onBlur={handleBlur}
					error={errors.usuario}
					onChange={handleChange}
				/>

				<Input
					type="text"
					name="correo"
					labelText={formatterText('table.title.email', 'Correo electrónico')}
					placeholder={formatterText('table.title.email', 'Correo electrónico')}
					touched={touched.correo}
					value={values.correo}
					onBlur={handleBlur}
					error={errors.correo}
					onChange={handleChange}
				/>

				<section
					className="d-flex"
					style={{
						height: 'auto'
					}}
				>
					<span className="text-inline-md">
						<FormattedMessage id="table.name.search.rols" defaultMessage="Roles" />
					</span>
					<div style={{ width: '100%' }}>
						<div
							id="rol"
							name="rol"
							style={{
								display: 'flex',
								flexWrap: 'wrap',
								width: 'inherit'
							}}
							className="input-edit inputRole"
						>
							<button
								onClick={(e) => {
									e.preventDefault();
									setToggleSelector(!toggleSelector);
									setSearch('');
								}}
								className="add-role"
							>
								<FormattedMessage id="p.addRole" defaultMessage="Añadir rol +" />
							</button>
							{selectedRoles.map((rol, index) => (
								<button
									key={`${index + 1}`}
									onClick={(e) => handleRemoveRole(e, rol)}
									className="role-item"
								>
									{rol.nombre} {rol.estado === 1 ? '(Activo)' : '(Inactivo)'}
									<div></div>
								</button>
							))}
						</div>
						{toggleSelector && <RolSelector handleAdd={handleAddRole} />}
					</div>
				</section>
				<section className="d-flex">
					<span className="text-inline-md">
						<FormattedMessage id="p.accesType" defaultMessage="Tipo de acceso" />
					</span>
					<div
						id="acceso"
						name="acceso"
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'space-evenly'
						}}
					>
						<button
							style={{
								marginRight: '20px'
							}}
							className={acces.mobile ? 'create-user-button button-selected' : 'create-user-button'}
							onClick={(e) => {
								e.preventDefault();
								setAcces({ ...acces, mobile: !acces.mobile });
							}}
						>
							<FormattedMessage id="p.mobile" defaultMessage="Móvil" />
						</button>
						<button
							className={acces.web ? 'create-user-button button-selected' : 'create-user-button'}
							onClick={(e) => {
								e.preventDefault();
								setAcces({ ...acces, web: !acces.web });
							}}
						>
							<FormattedMessage id="p.web" defaultMessage="Web" />
						</button>
					</div>
				</section>

				<SelectField
					labelText={formatterText('p.entity')}
					name="entidad"
					options={userOptions}
					placeholder={formatterText('input.placeholder.select', 'Selecione una opción')}
					onBlur={handleBlur}
					touched={touched.entidad}
					error={errors.entidad}
					value={values.entidad}
				/>

				{selectorData.length > 0 && (
					<SelectField
						labelText={formatterText('p.assigned.to')}
						name="entidadId"
						options={selectorData}
						placeholder={formatterText('input.placeholder.select', 'Selecione una opción')}
						onChange={handleChange}
						value={values.entidadId}
					/>
				)}

				<div className="form-responsive-container-buttons">
					<Button
						type="submit"
						className="btn-primary"
						text={formatterText('btn.regUser', 'Registrar usuario')}
					/>
					<Button
						className="input-cancel"
						onClick={() => navigate(paths.users)}
						text={formatterText('btn.cancel', 'Cancelar')}
					/>
				</div>
			</form>
		</FormikProvider>
	);
};

FormComponents.prototype = {
	formatterText: PropTypes.func,
	navigate: PropTypes.func,
	permittedActions: PropTypes.object,
	formik: PropTypes.object.isRequired
};
