import useAxios from 'hooks/useAxios';
import React, { useState } from 'react';
// Import Contexts
import { SearchWrapper, useSeachContext } from 'context/SearchContext';
// Import Hooks
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
// Import Components
import DynamicTable from 'common/DynamicTable/DynamicHead';
import Search from 'common/Search';
import HandlerNumbers from 'common/validators/HandlerNumbers';
import HandlerText from 'common/validators/HandlerText';
import SortData from 'components/utils/SortData';
// Import libs
// import Swal from "sweetalert2";
// Import de services
import endpoints from 'services/api';
import paths from 'services/paths';

const ModalUserSystemSearch = ({ onClose, exeFun }) => {
	return (
		<SearchWrapper>
			<ModalUserSystemSearchModalComponent onClose={onClose} exeFun={exeFun} />
		</SearchWrapper>
	);
};

function ModalUserSystemSearchModalComponent({ onClose }) {
	const { RequestUseCase } = useAxios();

	// use Hook of language v2
	const { formatterText } = useLangv2();
	// hook to loading data
	const { error, toggleLoading, toggleError, handleClick } = useGetData();
	// useContext de búsqueda
	const { setDataTable } = useSeachContext();

	const handleStructureItems = (newArray, item) => {
		if (item.estado === 1) {
			const nombre = `${item.primerNombre || 'None'} ${item.primerApellido}`;
			newArray.push({
				id: item.idUsuarioServicio,
				nombre,
				telefono: item.telefono,
				correo: item.correo ? item.correo : '',
				objeto: { ...item }
			});
		}
	};

	const [userExternalSearch, setUserExternalSearch] = useState({
		primerNombre: '',
		telefono: ''
	});

	const findExternalUsers = () => {
		const jsonToSend = {
			primerNombre:
				userExternalSearch.primerNombre !== ''
					? userExternalSearch.primerNombre.replaceAll(/\s{2,}/gi, ' ')
					: null,
			telefono:
				userExternalSearch.telefono !== ''
					? userExternalSearch.telefono.replaceAll(/\s{2,}/gi, ' ')
					: null
		};
		RequestUseCase.post(endpoints.services.getAllExternalUsersByQuery, jsonToSend)
			.then((data) => {
				const newArray = [];
				data.forEach((item) => handleStructureItems(newArray, item));
				const sortedArray = SortData(newArray, 'asc');
				setDataTable(sortedArray);
				// show loading
				toggleLoading(false);
			})
			.catch((err) => {
				console.log(err);
				// mostrar error
				toggleError(!error);
				handleClick();
			});
	};

	// Update a string to set into the form
	const handleText = (e) => {
		HandlerText(e, userExternalSearch, setUserExternalSearch);
	};

	// Update a number to set into the form
	const handleNumber = (e) => {
		if (e.target.value.match(/^[0-9]*$/)) {
			HandlerNumbers(e, userExternalSearch, setUserExternalSearch);
		}
	};

	return (
		<>
			<section className="container-search-modals">
				<section className="box-40p">
					<p className="p-text-primary">{formatterText('title.user.name.table.search')}</p>
					<input
						type="text"
						name="primerNombre"
						placeholder="Digite el nombre del cliente"
						value={userExternalSearch.primerNombre}
						onChange={(e) => handleText(e)}
						className="input-default"
					/>
				</section>
				<section className="box-40p">
					<p className="p-text-primary">{formatterText('title.service.phone')}</p>
					<input
						type="text"
						name="telefono"
						placeholder="Digite el teléfono del cliente"
						value={userExternalSearch.telefono}
						onChange={(e) => handleNumber(e)}
						className="input-default"
					/>
				</section>
				<button
					className="btn-search"
					onClick={(e) => {
						e.preventDefault();
						findExternalUsers();
					}}
				>
					{formatterText('title.service.search')}
				</button>
			</section>
			<section className="w100-container">
				<section className="table-container">
					<p className="p-text-primary">{formatterText('title.service.foundUsers')}</p>
					<section className="boxTable">
						<Search
							placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
							width="50%"
						/>
						<button
							className="btn-add"
							onClick={() => window.open(`#${paths.createExternalUser}`, '_blank')}
						>
							{formatterText('title.service.phone.user.create')}
						</button>
					</section>
					<DynamicTable
						titles={['Nombre usuario', 'Teléfono', 'Correo', 'Acciones']}
						pageName="Usuarios"
						getData={findExternalUsers}
						canSelectSSU={true}
						onClose={onClose}
					/>
				</section>
			</section>
		</>
	);
}

export default ModalUserSystemSearch;
