import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
// Import Hooks
import useLang from 'hooks/useLang';
import useProgress from 'hooks/useProgress';
import { useNavigate } from 'react-router-dom';
import paths from 'services/paths';
//import Components
import { useForm } from '@formiz/core';
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';
import Swal from 'sweetalert2';
//import libs
import 'react-quizz-spanish/lib/assets/antd.css';
import { defaultMessages } from 'react-quizz-spanish/lib/translations/TranslatedText';
import en_text from '../utils/en-US.json';
import es_text from '../utils/es-ES.json';
//impotr services
import useLangv2 from 'hooks/useLangv2';
import Cookie from 'js-cookie';
import endpoints from 'services/api';

export default function UpdateFormViewModel() {
	const { RequestUseCase } = useAxios();
	const [prevForm, setPrevForm] = useState([]);

	// Formiz object
	const myForm = useForm();
	// Modal config
	const navigate = useNavigate();
	// useLanguage
	const { formatterText } = useLang();
	const { noAnswerRequired, atLeastOneQuestion } = useLangv2();
	let lang = localStorage.getItem('lang').slice(-2);
	const language = {
		ES: es_text,
		US: en_text
	};
	const outAux = language[lang];
	const [selectLang, setSelectLang] = useState(outAux);
	defaultMessages['es'] = selectLang;
	const timeOutProcess = () => {
		setTimeout(() => {
			lang = localStorage.getItem('lang').slice(-2);
			setSelectLang(outAux);
			setLoadingProgress(false);
		}, 0);
	};
	const firstChange = () => {
		setLoadingProgress(true);
		timeOutProcess();
	};
	useEffect(() => {
		setLoadingProgress(true);
		timeOutProcess();
	}, [lang]);

	useEffect(() => {
		if (prevForm.length > 0) {
			const atLeastOne = prevForm.some((question) => question.required === true);
			setIsRequired(atLeastOne);
		} else {
			setIsRequired(false);
		}
	}, [prevForm]);

	const [form, setForm] = useState([
		{
			id: '1',
			element: 'RadioButtons',
			options: [
				{
					value: '1',
					text: { es: 'Respuesta 1 ...' }
				}
			],
			questions: { es: 'Ingrese una pregunta por defecto' },
			required: false
		}
	]);
	const [isRequired, setIsRequired] = useState(false);

	const { loadingProgress, setLoadingProgress, DisplayProgress } = useProgress();

	const [formToCompare, setFormToCompare] = useState([]);
	const [active, setActive] = useState(true);
	useEffect(() => {
		getQuestionsById();
		setActive(printable.estado);
	}, []);
	useEffect(() => {
		if (prevForm.length > 1) {
			setLoadingProgress(false);
		}
	}, [prevForm || formToCompare]);

	const printable = JSON.parse(localStorage.getItem('dataUpdate'));

	const getQuestionsById = () => {
		setLoadingProgress(true);
		const id = printable.idFormulario;
		RequestUseCase.get(endpoints.formServices.getAllFormWithQuestionsById(id))
			.then((res) => {
				const datos = res[0].preguntasFormulario;
				const arrayQuestions = [];
				const arrayAnswers = [];
				const arrayType = [];
				datos.map((item) => {
					const answers = item.respuestasEncuesta;
					let saveAnswers = [];
					let saveType = [];
					answers.map((answer) => {
						saveAnswers.push({
							id: answer.idRespuestaEncuesta,
							orden: answer.ordenRespuesta,
							value: answer.idRespuestaEncuesta,
							text: { es: answer.descripcionRespuesta }
						});
						saveType.push(answer.tipoObjetoFormulario.nombreTipoObjetoFormulario);
					});
					saveAnswers.sort((a, b) => a.orden - b.orden);
					arrayAnswers.push(saveAnswers);
					arrayType.push(saveType);
					saveType = [];
					saveAnswers = [];
				});

				datos.map((item, index) => {
					const data = {
						orden: item.ordenPregunta,
						id: item.idPreguntaFormulario.toString(),
						idPreguntaFormulario: item.idPreguntaFormulario,
						element: arrayType[index][0] || 'MultiLineInput',
						options: arrayAnswers[index],
						questions: { es: item.tituloPregunta },
						required: item.obligatorioPregunta === 1 ? true : false
					};
					arrayQuestions.push(data);
				});
				//Ordenar el array en orden ascendente
				arrayQuestions.sort((a, b) => a.orden - b.orden);
				setPrevForm(arrayQuestions);
				setForm(arrayQuestions);
				setFormToCompare(arrayQuestions);
				setLoadingProgress(false);
				firstChange();
			})
			.catch(() => {
				setLoadingProgress(false);
			});
	};
	const compare = (arr1, arr2) => {
		const result = [];
		arr1.forEach((item1) => {
			let found = false;
			arr2.forEach((item2) => {
				if (item1.id === item2.id) {
					found = true;
				}
			});
			if (!found) {
				result.push(item1);
			}
		});
		return result;
	};

	const compareQuestions = (arr1, arr2) => {
		const result = [];
		arr1.forEach((item1) => {
			item1.options.forEach((item01) => {
				let found = false;
				arr2.forEach((item2) => {
					item2?.options?.forEach((item02) => {
						if (item01.id === item02.id) {
							found = true;
						}
					});
				});
				if (!found) {
					result.push(item01);
				}
			});
		});
		return result;
	};

	const handleSubmit = (values) => {
		if (prevForm.length === 0) {
			atLeastOneQuestion();
			return;
		}
		if (prevForm.length > 0 && !isRequired) {
			noAnswerRequired();
		} else {
			if (
				values.nombre !== printable.nombreFormulario ||
				values.descripcion !== printable.descripcionFormulario ||
				values.code !== printable.codigoFormulario ||
				active !== printable.estado
			) {
				const data = {
					idFormulario: printable.idFormulario,
					nombre: values.nombre,
					descripcion: values.descripcion,
					codigo: values.code.toLowerCase(),
					fechaModificacion: null,
					fechaRegistro: '2022-09-07T21:38:35.539+00:00',
					estado: active,
					usuarioCreacion: null,
					usuarioModificacion: parseInt(Cookie.get('idUsuario'))
				};

				Swal.fire({
					title: formatterText('alert.title.general'),
					text: formatterText('alert.description.create.general'),
					icon: 'question',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					showLoaderOnConfirm: true,
					cancelButtonColor: '#d33',
					confirmButtonText: formatterText('alert.button.confirm.general'),
					allowOutsideClick: false,
					cancelButtonText: formatterText('alert.button.cancel.general'),
					preConfirm: () => {
						return new Promise((resolve, reject) => {
							RequestUseCase.put(endpoints.formServices.updateFormService, data)
								.then(() => {
									if (formToCompare !== prevForm) {
										const compareResult = compare(formToCompare, prevForm);
										if (compareResult.length > 0) {
											const compareQuestionResult = compareQuestions(formToCompare, prevForm);
											deleteOptionsQuestion(compareQuestionResult, compareResult, resolve, reject);
										} else {
											editAllForm(resolve, reject);
										}
									} else {
										CustomAlert('confirm_msg', {
											icon: 'success',
											title: formatterText('alert.title.confirm.general'),
											text: formatterText('column.alert.name.form.update.succes'),
											confirmButtonText: formatterText('alert.button.continue'),
											allowOutsideClick: false,
											executeFunction: () => navigate(paths.services)
											//
										});
									}
								})
								.catch((err) => {
									if (err.response?.data?.message) {
										HandleOnError(formatterText(err.response?.data?.message));
									} else {
										HandleOnError(
											formatterText(
												'snackbar.error.process.failed.general',
												'Error al realizar el proceso. Intentalo en otro momento.'
											)
										);
									}
								});
						});
					}
				});
			} else if (formToCompare !== prevForm) {
				const compareQuestionResult = compareQuestions(formToCompare, prevForm);
				const compareResult = compare(formToCompare, prevForm);
				if (compareQuestionResult.length > 0) {
					Swal.fire({
						title: formatterText('alert.title.general'),
						text: formatterText('alert.description.create.general'),
						icon: 'question',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						showLoaderOnConfirm: true,
						cancelButtonColor: '#d33',
						confirmButtonText: formatterText('alert.button.confirm.general'),
						allowOutsideClick: false,
						cancelButtonText: formatterText('alert.button.cancel.general'),
						preConfirm: () => {
							return new Promise((resolve, reject) => {
								deleteOptionsQuestion(compareQuestionResult, compareResult, resolve, reject);
							});
						}
					});
				} else {
					Swal.fire({
						title: formatterText('alert.title.general'),
						text: formatterText('alert.description.create.general'),
						icon: 'question',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						showLoaderOnConfirm: true,
						cancelButtonColor: '#d33',
						confirmButtonText: formatterText('alert.button.confirm.general'),
						allowOutsideClick: false,
						cancelButtonText: formatterText('alert.button.cancel.general'),
						preConfirm: () => {
							return new Promise((resolve, reject) => {
								editAllForm(resolve, reject);
							});
						}
					});
				}
			} else {
				navigate(paths.services);
			}
		}
	};
	const deleteOptionsQuestion = (data, data2, resolve, reject) => {
		const promises = data.map((options) => {
			RequestUseCase.delete(endpoints.formServices.deleteOptionsQuestion(options.id)).then(
				(res) => {
					resolve(res);
				}
			);
		});
		Promise.all(promises)
			.then(() => {
				deleteQuestion(data2, resolve, reject);
			})
			.catch(() => {
				reject(HandleOnError('Error al actualizar el formulario, por favor intente nuevamente'));
			});
	};
	const deleteQuestion = (data, resolve, reject) => {
		const promises = data.map((item) => {
			RequestUseCase.delete(endpoints.formServices.deleteQuestionForm(item.id)).then((res) => {
				resolve(res);
			});
		});

		Promise.all(promises)
			.then(() => {
				editAllForm(resolve, reject);
			})
			.catch(() => {
				reject(HandleOnError('Error al actualizar el formulario, por favor intente nuevamente'));
			});
	};
	const addNewQuestion = (data, resolve, reject) => {
		const promises = data.map((item, index) => {
			const question = {
				idFormulario: printable.idFormulario,
				orden: index + 1,
				titulo: item.questions.es,
				descripcion: item.questions.es,
				obligatoria: item.required === true ? 1 : 0
			};
			RequestUseCase.post(endpoints.formServices.addQuestionForm, question)
				.then((res) => {
					if (item.options !== undefined) {
						item.options.map((options, index) => {
							const optionsQuestion = {
								idTipoObjetoFormulario: getIdByElement(item.element),
								idPreguntaFormulario: res.idPreguntaFormulario,
								orden: index + 1,
								descripcion: options.text.es
							};
							return RequestUseCase.post(
								endpoints.formServices.addOptionsQuestion,
								optionsQuestion
							).then((resl) => {
								resolve(resl);
							});
						});
					} else {
						const optionsQuestion = {
							idTipoObjetoFormulario: getIdByElement(item.element),
							idPreguntaFormulario: res.idPreguntaFormulario,
							orden: index + 1,
							descripcion: 'W/O OPTIONS'
						};
						return RequestUseCase.post(
							endpoints.formServices.addOptionsQuestion,
							optionsQuestion
						).then((resl) => {
							resolve(resl);
						});
					}
				})
				.catch((err) => {
					console.log(err);
				});
		});
		Promise.all(promises)
			.then(() => {
				const hasOldData = compareReverse(prevForm, formToCompare);
				editAllQuestionsAndOptions(hasOldData, resolve, reject);
			})
			.catch(() => {
				reject(HandleOnError('Error al crear el formulario, por favor intente nuevamente'));
			});
	};
	const editAllQuestionsAndOptions = (data, resolve, reject) => {
		const promises = data.map((item, index) => {
			const question = {
				idPreguntaFormulario: parseInt(item.id),
				idFormulario: printable.idFormulario,
				orden: index + 1,
				titulo: item.questions.es,
				descripcion: item.questions.es,
				obligatoria: item.required === true ? 1 : 0,
				fechaRegistro: '2022-09-08T00:30:06.936+00:00',
				fechaModificacion: null,
				usuarioCreacion: null,
				usuarioModificacion: parseInt(Cookie.get('idUsuario'))
			};

			RequestUseCase.put(endpoints.formServices.updateQuestionForm, question)
				.then(() => {
					item.options.map((options, index) => {
						if (Object.keys(options).length > 0) {
							const optionsQuestion = {
								idRespuestaEncuesta: options.id,
								idTipoObjetoFormulario: getIdByElement(item.element),
								idPreguntaFormulario: item.id,
								orden: index + 1,
								descripcion: options.text.es,
								fechaRegistro: '2022-09-09T00:00:51.383+00:00',
								fechaModificacion: null,
								usuarioCreacion: null,
								usuarioModificacion: parseInt(Cookie.get('idUsuario'))
							};
							return RequestUseCase.put(
								endpoints.formServices.updateOptionsQuestion,
								optionsQuestion
							)
								.then((resl) => {
									resolve(resl);
								})
								.catch((err) => {
									console.log(err);
								});
						}
					});
				})
				.catch((err) => {
					console.log(err);
				});
		});
		Promise.all(promises)
			.then(() => {
				CustomAlert('confirm_msg', {
					icon: 'success',
					title: formatterText('alert.title.confirm.general'),
					text: formatterText('column.alert.name.form.update.succes'),
					confirmButtonText: formatterText('alert.button.continue'),
					allowOutsideClick: false,
					executeFunction: () => navigate(paths.services)
					//
				});
			})
			.catch(() => {
				reject(HandleOnError('Error al actualizar el formulario, por favor intente nuevamente'));
			});
	};
	const editAllForm = (resolve, reject) => {
		const hasNewData = compare(prevForm, formToCompare);
		if (hasNewData.length > 0) {
			addNewQuestion(hasNewData, resolve, reject);
		} else {
			const hasOldData = compareReverse(prevForm, formToCompare);
			editAllQuestionsAndOptions(hasOldData, resolve, reject);
		}
	};

	const compareReverse = (arr1, arr2) => {
		const result = [];
		arr1.forEach((item1) => {
			let found = false;
			arr2.forEach((item2) => {
				if (item1.id === item2.id) {
					found = true;
				}
			});
			if (found) {
				result.push(item1);
			}
		});
		return result;
	};
	const getIdByElement = (element) => {
		switch (element) {
			case 'Select':
				return 1;
			case 'Checkboxes':
				return 2;
			case 'RadioButtons':
				return 3;
			case 'MultiLineInput':
				return 4;
			case 'DatePicker':
				return 6;
		}
	};

	return {
		myForm,
		handleSubmit,
		formatterText,
		navigate,
		printable,
		active,
		setActive,
		loadingProgress,
		prevForm,
		setPrevForm,
		DisplayProgress,
		form
	};
}
