import useAxios from 'hooks/useAxios';
import React, { useEffect } from 'react';
// Import Context
import DynamicTableServices from '../DynamicTableServices/DynamicHeadServices';
// Import Services
import endpoints from 'services/api';
// Import Hooks
import { useSeachContext } from 'context/SearchContext';
import { formatterText } from 'hooks/useLangv2';
import { useParams } from 'react-router';

function CambioEstadoSeguimiento({ getDataTable }) {
	const { RequestUseCase } = useAxios();

	// activity ids
	const { id } = useParams();
	const { setDataTable } = useSeachContext();

	const tableTitles = [
		formatterText('title.service.actor'),
		formatterText('title.service.name'),
		formatterText('title.service.serviceStatus'),
		formatterText('title.service.statusChangeDate')
	];

	useEffect(() => {
		getFirstData();
	}, []);

	const getFirstData = () => {
		RequestUseCase.get(endpoints.services.getTrazabilidad(id))
			.then((data) => {
				const datos = StructureItemsStates(data);
				setDataTable(datos);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const StructureItemsStates = (data) => {
		const hashDesition = {
			null: 'Asesor'
		};

		const hashTech = {
			1: 'Tecnico Principal',
			0: 'Tecnico Auxiliar'
		};

		const DeterminaType = data.map((item) => {
			return {
				...item,
				actor: hashDesition[item.idTecnico] || hashTech[item.principal],
				isTech: !(hashDesition[item.idTecnico] || hashTech[item.principal] === 'Asesor')
			};
		});
		const FinalData = DeterminaType.map((item) => {
			return {
				actor: item.actor,
				name: item?.isTech
					? item.idTecnico?.idEmpleado?.primerNombre !== undefined
						? `${item.idTecnico.idEmpleado.primerNombre} ${item.idTecnico.idEmpleado.primerApellido} `
						: `${item.idTecnico.idTercero.nombre}`
					: 'Asesor',
				state: item.idEstadoServicio.nombre,
				date:
					item.fechaCreacion.split('T')[0] + ' ' + item.fechaCreacion.split('T')[1].split('.')[0]
			};
		});


		return FinalData;
	};

	return (
		<div>
			{getDataTable && (
				<div className="tdR thR table-container">
					<DynamicTableServices
						titles={tableTitles}
						pageName={formatterText('title.service.statusChanges')}
						getData={getDataTable}
					/>
				</div>
			)}
		</div>
	);
}

export default CambioEstadoSeguimiento;
