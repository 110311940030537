import React from 'react';
// Import Components
import DynamicHeadNotifications from '../DynamicTableNotificacion/DynamicHeadNotifications';
// Import libs
import { FormattedMessage } from 'react-intl';
// Import services
import { ALERTS_PAGENAME } from 'constants/alertsPageName';
import AlertsViewModel from './Alerts.viewModel';

const titles = [
	<FormattedMessage key="tab.title.dates" id="tab.title.dates" defaultMessage="Fecha / Hora" />,
	<FormattedMessage key="title.service.file" id="title.service.file" defaultMessage="Expediente" />,
	<FormattedMessage key="tab.title.category" id="tab.title.category" defaultMessage="Categoria" />,
	<FormattedMessage key="tab.title.message" id="tab.title.message" defaultMessage="Mensaje" />,
	<FormattedMessage
		key="table.name.search.client"
		id="table.name.search.client"
		defaultMessage="Cliente"
	/>,
	<FormattedMessage key="table.title.city" id="table.title.city" defaultMessage="Ciudad" />,
	<FormattedMessage
		key="tab.title.date.markasread"
		id="tab.title.date.markasread"
		defaultMessage="Leído el"
	/>,
	<FormattedMessage
		key="tab.title.markasread"
		id="tab.title.markasread"
		defaultMessage="Marcar como leida"
	/>
];

const Alerts = () => {
	const {
		loading,
		state,
		setCurrentPage,
		setRowsPerPage,
		setUpdated,
		renderMessage,
		DeterminateTypeUser
	} = AlertsViewModel();

	return (
		<>
			{!loading ? (
				<FormattedMessage id="tab.title.notifications">
					{() => (
						<DynamicHeadNotifications
							titles={titles}
							getData={DeterminateTypeUser}
							page={state.currentPageAlert}
							rowsPerPage={state.rowsPerPage}
							setPage={setCurrentPage}
							setRowsPerPage={setRowsPerPage}
							data={state.filteredAlert}
							totalData={state.currentCountAlert}
							updated={setUpdated}
							pageName={ALERTS_PAGENAME.alertas}
						/>
					)}
				</FormattedMessage>
			) : (
				renderMessage()
			)}
		</>
	);
};

export default Alerts;
