import React from 'react';
// Import Contexts
// Import Components
import Table from 'common/minimalTables/TableMinimal';
import Selector from 'common/selects/Selector';
import defaultSelect from 'common/selects/default.select.style';
// Import Libs
import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-responsive-modal';
import Select from 'react-select';
import paths from 'services/paths';
import UpdateModalItem from '../UpdateModalItem';
import UpdateListPriceViewModel from './UpdateListPrice.viewModel';

export default function UpdateListPriceComponent() {
	const {
		auxData,
		error,
		errorName,
		active,
		codeError,
		formData,
		loading,
		open,
		selectedOption,
		service,
		valueMinimalPrice,
		valueTax,
		currentDataTable,
		selectedSearch,
		customStyles,
		titlesTableCostProduct,
		displayLoading,
		displayMessage,
		formatterText,
		formatCurrency,
		formatIvaField,
		getMinimalTable,
		handleAddItemMinimal,
		handleCode,
		handleCostoChange,
		handleErrorCode,
		handleErrorName,
		handleIvaChange,
		handleInputCarac,
		handleOpen,
		handleClose,
		handlerTextDescription,
		handleSubmit,
		setActive,
		setAuxData,
		setSelectedOption,
		setResultsTableSearch,
		printMoneda,
		navigate
	} = UpdateListPriceViewModel();
	return (
		<>
			<form onSubmit={handleSubmit}>
				<section className="form-responsive container-border-wrapForm">
					<p className="wrap-form-title">{formatterText('p.label.title.price.list')}</p>
					<section className="form-responsive-container-information">
						<section className="form-responsive-information__option">
							<h3 className="p-styles">{formatterText('p.label.title.price.list.name')}</h3>
							<input
								className="input-primary"
								type="text"
								name="nombre"
								value={formData.nombre}
								onChange={handleInputCarac}
								placeholder={formatterText('p.label.title.price.list.name')}
								maxLength="100"
								onBlur={handleErrorName}
							/>
						</section>
						{errorName && (
							<div
								style={{
									fontSize: '0.7rem',
									color: 'red',
									marginTop: '-20px',
									marginRight: '500px'
								}}
							>
								{errorName}
							</div>
						)}
						<section className="form-responsive-information__option">
							<h3 className="p-styles">{formatterText('table.title.currency')}</h3>
							<Selector
								customStyles={customStyles}
								name="idMoneda"
								data={selectedSearch.tipoMonedas}
								placeholder={`${printMoneda?.idMoneda?.nombre} - ${printMoneda?.idMoneda?.codigo}`}
								dataValue={auxData.idMoneda}
								setterFunction={setAuxData}
								isLoading={loading}
								selectValue={auxData.idMoneda}
								isRequired={false}
							/>
						</section>
						<section className="form-responsive-information__option">
							<h3 className="p-styles">{formatterText('p.label.title.descripcion')}</h3>
							<textarea
								className="input-primary-textarea"
								name="descripcion"
								value={formData.descripcion}
								onChange={handlerTextDescription}
								placeholder={formatterText('p.label.title.descripcionProducto')}
								maxLength="200"
							/>
						</section>
						<section className="form-responsive-information__option">
							<h3 className="p-styles">{formatterText('p.label.title.codigo')}</h3>
							<input
								className="input-primary"
								type="text"
								name="codigo"
								value={formData?.codigo?.toLowerCase()}
								onChange={handleCode}
								placeholder={formatterText('p.label.title.ingresaCaracteres')}
								maxLength="45"
								onBlur={handleErrorCode}
							/>
						</section>
						{codeError && (
							<div
								style={{
									fontSize: '0.7rem',
									color: 'red',
									marginTop: '-20px',
									marginRight: '500px'
								}}
							>
								{codeError}
							</div>
						)}
						<section className="form-responsive-information__option">
							<h3 className="p-styles">{formatterText('table.title.state')}</h3>
							<label className="form-responsive-label">
								<p className="form-responsive-toggle">{active ? 'Activo' : 'No activo'}</p>
								<label className="switch">
									<input
										checked={active ? true : false}
										onChange={() => {
											setActive(!active);
										}}
										type="checkbox"
									/>
									<span className="slider round"></span>
								</label>
							</label>
						</section>
					</section>
					{!loading
						? currentDataTable.length > 0 && (
								<>
									<p className="wrap-form-title">
										{formatterText('p.label.title.preciosAsociados')}
									</p>
									<section className="form-responsive-container-information">
										<Table
											titles={titlesTableCostProduct}
											data={currentDataTable}
											type="associatePrices"
											handleOpen={handleOpen}
											labelTable={formatterText('p.label.title.listaPrecios')}
											canSearch={true}
											isEdit={true}
										/>
									</section>
								</>
						  )
						: error
						? displayMessage(
								'error',
								'Ha ocurrido un error, intentalo más tarde.',
								'toast.error.general'
						  )
						: displayLoading()}
					<p className="wrap-form-title">{formatterText('p.label.title.asociarPrecio')}</p>
					<section
						className="grid-container-2c"
						style={{
							width: '95%',
							margin: '0 auto'
						}}
					>
						<label className="d-flex">
							<span className="text-inline">{formatterText('p.label.title.tipoServicio')}</span>
							<section className="w100-container">
								<Select
									styles={defaultSelect}
									onChange={(selectedOption) => {
										setSelectedOption((prev) => ({
											...prev,
											idTipoServicio: selectedOption
										}));
										setAuxData((prev) => ({
											...prev,
											idTipoServicio: selectedOption
										}));
									}}
									value={selectedOption.idTipoServicio}
									noOptionsMessage={() => formatterText('select.placeholder.no.options')}
									options={selectedSearch.tipoServicio}
									placeholder={
										loading ? (
											<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
										) : (
											<FormattedMessage
												id="input.placeholder.select"
												defaultMessage="Selecione una opción"
											/>
										)
									}
								/>
							</section>
						</label>
						<label className="d-flex">
							<span className="text-inline">
								{formatterText('p.label.title.actividadAsociada')}
							</span>
							<section className="w100-container">
								<Select
									styles={defaultSelect}
									onChange={(selectedOption) => {
										setSelectedOption((prev) => ({
											...prev,
											idActividadAsociada: selectedOption
										}));
										setAuxData((prev) => ({
											...prev,
											idActividadAsociada: selectedOption
										}));
									}}
									value={selectedOption.idActividadAsociada}
									noOptionsMessage={() => formatterText('select.placeholder.no.options')}
									options={selectedSearch.actividadesServicios}
									placeholder={
										loading ? (
											<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
										) : (
											<FormattedMessage
												id="input.placeholder.select"
												defaultMessage="Selecione una opción"
											/>
										)
									}
								/>
							</section>
						</label>
						<label className="d-flex">
							<span className="text-inline">{formatterText('p.label.title.unidadMedida')}</span>
							<section className="w100-container">
								<Select
									styles={defaultSelect}
									onChange={(selectedOption) => {
										setSelectedOption((prev) => ({
											...prev,
											idUnidadMedida: selectedOption
										}));
										setAuxData((prev) => ({
											...prev,
											idUnidadMedida: selectedOption
										}));
									}}
									value={selectedOption.idUnidadMedida}
									noOptionsMessage={() => formatterText('select.placeholder.no.options')}
									options={selectedSearch.unidadMedida}
									placeholder={
										loading ? (
											<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
										) : (
											<FormattedMessage
												id="input.placeholder.select"
												defaultMessage="Selecione una opción"
											/>
										)
									}
								/>
							</section>
						</label>
						<label className="d-flex">
							<span className="text-inline">{formatterText('p.label.title.valor')}</span>
							<section className="w100-container">
								<input
									name="valor"
									defaultValue={valueMinimalPrice}
									className="input-default-3c"
									type="text"
									value={formatCurrency(valueMinimalPrice)}
									onChange={handleCostoChange}
									placeholder={formatterText('p.label.title.valor')}
									maxLength="20"
								/>
							</section>
						</label>
						<label className="d-flex">
							<span className="text-inline">{formatterText('p.label.title.impuesto')}</span>
							<section className="w100-container">
								<input
									name="impuesto"
									defaultValue={valueTax}
									className="input-default-3c"
									type="text"
									value={formatIvaField(valueTax)}
									onChange={handleIvaChange}
									placeholder={formatterText('p.label.title.impuesto')}
									maxLength="20"
								/>
							</section>
						</label>
					</section>
					<section className="form-responsive-container-buttons">
						<input
							onClick={handleAddItemMinimal}
							type="button"
							className="btn-primary btn-primary-center"
							value="Asociar precio"
						/>
					</section>
				</section>
				<section className="form-responsive-container-buttons">
					<button type="submit" className="btn-primary">
						{formatterText('p.label.title.actualizarListaPrecios')}
					</button>
					<button className="input-cancel" type="cancel" onClick={() => navigate(paths.services)}>
						{formatterText('p.label.title.cancelar')}
					</button>
				</section>
			</form>
			<Modal
				open={open}
				onClose={handleClose}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<UpdateModalItem
					dataTypeService={selectedSearch.tipoServicio}
					typeService={service}
					dataUnits={selectedSearch.unidadMedida}
					onClose={handleClose}
					refetch={getMinimalTable}
					valueTax={valueTax}
					setResultsTableSearch={setResultsTableSearch}
					auxData={auxData}
					setAuxData={setAuxData}
				/>
			</Modal>
		</>
	);
}
