import { useSnackbar } from 'notistack';

/**
 * @description doc... in progress
 * @returns {object}
 */
const useSB = () => {
	// reference
	const { enqueueSnackbar } = useSnackbar();

	const handleClickSB = (variant, message) => {
		enqueueSnackbar(message, { variant });
	};

	//   return the function to
	return handleClickSB;
};

export default useSB;
