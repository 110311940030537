import React from 'react';
import EditFileViewModel from './EditFile.viewModel';
import { EditFileFormComponent } from './componentes';

export default function EditFileComponent() {
	const { active, formatterText, formik, idCategory, loading, navigate, selectorData, setActive } =
		EditFileViewModel();
	return (
		<EditFileFormComponent
			active={active}
			formatterText={formatterText}
			formik={formik}
			idCategory={idCategory}
			loading={loading}
			navigate={navigate}
			selectorData={selectorData}
			setActive={setActive}
		/>
	);
}
