import React, { useContext, useEffect, useState } from 'react';
// Import Contexts
import { AppContext } from 'context/AppContext';
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';
// Import Components
import HandleInput from 'common/validators/HandleInput';
import HandleOnError from 'common/validators/HandleOnError';
import HandlerEmail from 'common/validators/HandlerEmail';
import HandlerText from 'common/validators/HandlerText';
import { ADDRESSREGEX } from 'common/validators/Regex';
// Import libs
import Swal from 'sweetalert2';
// Import Services
import endpoints from 'services/api';

export default function AddressModal({ onClose, exeFun }) {
	// Manage axios requests
	const { COOKIE_USER, RequestUseCase } = useAxios();
	// getData from the client or exteralUser select
	const { clientSelected, externalUserSelected, userSelected } = useContext(AppContext);
	//  Call hook of language
	const { formatterText, customSB } = useLangv2();
	// Example of form data
	const [formData, setFormData] = useState({
		idMunicipio: 0,
		idSector: '',
		direccion: '',
		datosAdicionales: '',
		observaciones: '',
		telefono: '',
		sitioWeb: ''
	});
	const [selectValues, setSelectValues] = useState({
		municipalitiesOptions: [],
		sectorsOptions: []
	});

	useEffect(() => {
		AllMunicipalitiesForSelect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (formData.idMunicipio !== 0) {
			AllSectorsForSelect();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData.idMunicipio]);

	const AllMunicipalitiesForSelect = () => {
		RequestUseCase.get(endpoints.zones.getAllMunicipalities).then((data) => {
			// create new array
			const newArray = [];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						idMunicipio: item.idMunicipio,
						label: `${item.nombre} - ${item.codigoMunicipio}`
					});
				}
			});
			// set the values of the select
			setSelectValues((prev) => ({
				...prev,
				municipalitiesOptions: newArray
			}));
		});
	};

	// get sector by id municipality
	const AllSectorsForSelect = () => {
		RequestUseCase.get(endpoints.zones.getAllSectors).then((data) => {
			// create new array
			const newArray = [];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1 && item.idMunicipio.idMunicipio === parseInt(formData.idMunicipio)) {
					newArray.push({
						idSector: item.idSector,
						label: `${item.nombre} - ${item.codigoSector}`
					});
				}
			});
			// set the values of the select
			setSelectValues((prev) => ({
				...prev,
				sectorsOptions: newArray
			}));
		});
	};

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();

		let data = {};

		if (externalUserSelected.id !== null) {
			data = {
				idDireccionUsuarioServicio: null,
				idUsuarioServicio: {
					idUsuarioServicio: externalUserSelected.id
				},
				idDireccion: {
					idDireccion: null,
					idSector: {
						idSector: parseInt(formData.idSector)
					},
					direccion: formData.direccion,
					datosAdicionales: formData.datosAdicionales,
					observacion: formData.observaciones,
					estado: 1,
					fechaCreacion: new Date(),
					usuarioCreacion: COOKIE_USER
				},
				usuarioCreacion: COOKIE_USER
			};
			postItem(data, endpoints.services.saveAddressExternalUsers);
		} else if (clientSelected.id !== null && externalUserSelected.id === null) {
			data = {
				idSucursal: null,
				idCliente: {
					idCliente: clientSelected.id
				},
				idDireccion: {
					idDireccion: null,
					idSector: {
						idSector: parseInt(formData.idSector)
					},
					direccion: formData.direccion,
					datosAdicionales: formData.datosAdicionales,
					observacion: formData.observaciones
				},
				telefono: formData.telefono,
				sitioWeb: formData.sitioWeb,
				usuarioCreacion: COOKIE_USER
			};
			postItem(data, endpoints.branchOffices.addBranchOffice);
		} else if (userSelected.id !== null && externalUserSelected.id === null) {
			data = {
				idDireccionUsuarioServicio: null,
				idUsuarioServicio: {
					idUsuarioServicio: userSelected.id
				},
				idDireccion: {
					idDireccion: null,
					idSector: {
						idSector: parseInt(formData.idSector)
					},
					direccion: formData.direccion,
					datosAdicionales: formData.datosAdicionales,
					observacion: formData.observaciones,
					estado: 1,
					fechaCreacion: new Date(),
					usuarioCreacion: COOKIE_USER
				},
				usuarioCreacion: COOKIE_USER
			};
			postItem(data, endpoints.services.saveAddressExternalUsers);
		} else {
			HandleOnError('Debe seleccionar un usuario primero');
		}
	};

	const postItem = (BODY, route) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve) => {
					RequestUseCase.post(route, BODY).then((data) => {
						customSB(
							'success',
							'snackbar.success.new.address',
							'Se agregó correctamente una nueva dirección.'
						);
						onClose();
						resolve(exeFun(data));
					});
				});
			}
		});
	};

	// Update a string to set into the form
	const handleText = (e) => {
		HandlerText(e, formData, setFormData);
	};

	const handleAddress = (e) => {
		HandleInput(e, ADDRESSREGEX, formData, setFormData);
		// if (e.target.value.match(/[\w\-\#]?/)) {
		//   setFormData({ ...formData, [e.target.name]: e.target.value });
		// }
	};

	// Update a email to set into the form
	const handleEmail = (e) => {
		HandlerEmail(e, formData, setFormData);
	};

	return (
		<>
			<form className="w100-container" onSubmit={(e) => handleSubmit(e)}>
				<section>
					<p className="title-modal">{formatterText('title.service.create.address.add')}</p>
					<section className="grid-container-2c">
						<section className="d-flex">
							<h3 className="text-inline">
								{formatterText('title.service.create.cityOrMunicipality')}
							</h3>
							<select
								className="input-label-style"
								name="idMunicipio"
								value={formData.idMunicipio}
								onChange={handleText}
								required
							>
								<option value="">
									{formatterText('title.service.create.selectCityOrMunicipality')}
								</option>
								{selectValues.municipalitiesOptions.map((item, index) => {
									return (
										<option key={index} value={item.idMunicipio}>
											{item.label}
										</option>
									);
								})}
							</select>
						</section>
						<section className="d-flex">
							<h3 className="text-inline">{formatterText('title.service.create.sector')}</h3>
							<select
								className="input-label-style"
								name="idSector"
								value={formData.idSector}
								onChange={handleText}
								required
							>
								<option value="">{formatterText('title.service.create.selectSector')}</option>
								{selectValues.sectorsOptions.map((item, i) => {
									return (
										<option key={i} value={item.idSector}>
											{item.label}
										</option>
									);
								})}
							</select>
						</section>
					</section>
					<label className="label-large-1r">
						<span className="text-inline span-label">
							{formatterText('title.service.create.address.services')}
						</span>
						<input
							type="text"
							value={formData.direccion}
							name="direccion"
							onChange={handleAddress}
							placeholder={formatterText('title.service.create.fullAddress')}
							className="input-default w100-container"
							required
						/>
					</label>
					<label className="label-large-1r">
						<span className="text-inline span-label">
							{formatterText('title.service.create.additionalData')}
						</span>
						<input
							type="text"
							value={formData.datosAdicionales}
							name="datosAdicionales"
							onChange={handleAddress}
							placeholder={formatterText('title.service.create.additionalDataExample')}
							className="input-default w100-container"
						/>
					</label>
					<label className="label-large-1r">
						<span className="text-inline span-label">
							{formatterText('title.service.create.comments.services')}
						</span>
						<input
							type="text"
							value={formData.observaciones}
							name="observaciones"
							onChange={handleAddress}
							placeholder={formatterText('title.service.create.commentsExample')}
							className="input-default w100-container"
						/>
					</label>
					<label className="label-large-1r">
						<span className="text-inline span-label">
							{formatterText('title.service.create.phone.services')}
						</span>
						<input
							type="text"
							value={formData.telefono}
							name="telefono"
							onChange={(e) => {
								// only allow numbers
								const REGEX_NUMBER = /^[0-9]*$/;
								console.log(REGEX_NUMBER.test(e.target.value));
								if (REGEX_NUMBER.test(e.target.value)) {
									setFormData({ ...formData, [e.target.name]: e.target.value });
								}
							}}
							placeholder={formatterText('title.service.create.phone.services')}
							className="input-default w100-container"
							maxLength="20"
						/>
					</label>
					<label className="label-large-1r">
						<span className="text-inline span-label">
							{formatterText('title.service.create.website')}
						</span>
						<input
							type="text"
							value={formData.sitioWeb}
							name="sitioWeb"
							onChange={handleEmail}
							placeholder={formatterText('title.service.create.website')}
							className="input-default w100-container"
						/>
					</label>

					<section className="footer-grid-buttons">
						<input
							type="submit"
							className="btn-action-primary"
							value={formatterText('title.service.create.saveChanges')}
						/>
						<button
							className="btn-action-cancel"
							onClick={(e) => {
								e.preventDefault();
								onClose();
							}}
						>
							{formatterText('title.service.create.cancel')}
						</button>
					</section>
				</section>
			</form>
		</>
	);
}
