const hours = [];
let contador = 1;
for (let h = 0; h < 24; h++) {
	for (let m = 0; m < 60; m++) {
		hours.push({
			idHora: contador,
			codigo: `${h}`.padStart(2, '0') + ':' + `${m}`.padStart(2, '0') + ':00',
			hora: `${h}`.padStart(2, '0') + ':' + `${m}`.padStart(2, '0')
		});
		contador = contador + 1;
	}
}

export default hours;
