import React from 'react';
// Import Context
// Import Components
import DynamicTable from 'common/DynamicTable/DynamicHead';
// Import libs
import { FormattedMessage } from 'react-intl';
// Import services
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';
import paths from 'services/paths';
import { FormTableProps } from './FormTable.props';
import FormTableViewModel from './FormTable.viewModel';

const FormTable = ({ permisos }) => {
	const {
		loading,
		error,
		promiseGetDataTable,
		handleDownloadItem,
		handleDeleteItem,
		handleEditStateItem,
		displayMessage,
		displayLoading,
		titles
	} = FormTableViewModel();

	if (loading) {
		if (error) {
			return displayMessage(
				'error',
				'Ha ocurrido un error, intentalo más tarde.',
				'toast.error.general'
			);
		}
		return displayLoading();
	}

	return (
		<FormattedMessage id="table.name.search.product" defaultMessage="Categoría de producto">
			{() => (
				<DynamicTable
					titles={titles}
					pageName={PAGE_NAMES.Formularios}
					data={promiseGetDataTable}
					handleDownloadItem={handleDownloadItem}
					handleDeleteItem={handleDeleteItem}
					handleEditStateItem={handleEditStateItem}
					routeToEdit={paths.updateFormService}
					canDeleted={permisos.eliminar}
					canModify={permisos.editar}
					canDownload={permisos.exportar}
				/>
			)}
		</FormattedMessage>
	);
};

FormTable.propTypes = FormTableProps;

export default FormTable;
