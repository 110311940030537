import useAxios from 'hooks/useAxios';
import HandleInput from 'common/validators/HandleInput';
import HandleOnError from 'common/validators/HandleOnError';
import { TEXTREGEX } from 'common/validators/Regex';
import CustomAlert from 'components/CustomAlert';
import { AppContext } from 'context/AppContext';
import { useSeachContext } from 'context/SearchContext';
import dateFormat from 'dateformat';
import useLangv2 from 'hooks/useLangv2';
import Agenda from 'models/Agenda';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import endpoints from 'services/api';
import paths from 'services/paths';
import Swal from 'sweetalert2';

export default function CreateScheduleViewModel() {
	const { RequestUseCase, COOKIE_USER } = useAxios();

	const { timeZoneSelected } = useSeachContext();
	const {
		openModalTechnicalToAssign,
		openModalTechnicalToReview,
		setOpenModalTechnicalToAssign,
		setOpenModalTechnicalToReview,
		setTechnicalsToAssing
	} = useContext(AppContext);

	const { formatterText, noFilledContent, newItemCreated, errorProcess, customSB } = useLangv2();

	const titlesTableTimeZone = [
		formatterText('text.shedule.time.zone', 'Franjas horarias'),
		formatterText('table.shedule.view.technical', 'Técnicos asociados'),
		formatterText('table.actions', 'Acciones')
	];

	// Modal config
	const onCloseTechnicalToAssign = () => setOpenModalTechnicalToAssign(false);
	const onCloseTechnicalToReview = () => setOpenModalTechnicalToReview(false);
	// useNavigate
	const navigate = useNavigate();
	// useState for the form
	const [formData, setFormData] = useState(new Agenda());

	// Update a string to set into the form
	const handleText = (e) => {
		HandleInput(e, TEXTREGEX, formData, setFormData);
	};

	// Create an agenda
	const handleSubmit = (e) => {
		e.preventDefault();

		const conditionalCreateAgenda =
			formData.nombre === '' || formData.fechaInicio === '' || formData.fechaFin === '';

		if (conditionalCreateAgenda) {
			noFilledContent();
		} else {
			const DATA = {
				nombre: formData.nombre,
				fechaInicio: dateFormat(formData.fechaInicio, 'isoDateTime'),
				fechaFin: dateFormat(formData.fechaFin, 'isoDateTime'),
				idCoordinadorCreador: {
					idUsuario: COOKIE_USER
				},
				usuarioCreacion: COOKIE_USER
			};

			// is possible to searchResults have new time zones so we need to validate
			if (timeZoneSelected.length > 0) {
				createItem(DATA);
			} else {
				customSB(
					'warning',
					'snackbar.error.time.zone.required',
					'Es necesario asociar al menos una franja horaria a la agenda.'
				);
			}
		}
	};

	// Create new agenda
	const createItem = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve) => {
					//  Here we call the service to create the item
					RequestUseCase.post(endpoints.agenda.addAgenda, data)
						.then((res) => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.created.general',
										'El registro se ha creado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => associateAgendaToTimeZone(res.idAgenda)
								})
							);
						})
						.catch((err) => {
							if (err.response?.data?.message) {
								HandleOnError(formatterText(err.response?.data?.message));
							} else {
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
						});
				});
			}
		});
	};

	// Associate a time zone to an agenda
	const associateAgendaToTimeZone = async (idAgenda) => {
		await timeZoneSelected.forEach(async (franja) => {
			const TYPE_ID = typeof franja.idFranjaHorariaAgenda === 'string';
			if (TYPE_ID) {
				const DATA = {
					idFranjaHorariaAgenda: null,
					idAgenda: {
						idAgenda: parseInt(idAgenda)
					},
					idFranjaHoraria: {
						idFranjaHoraria: franja.idFranjaHoraria
					},
					estado: 1,
					// "fechaCreacion": DATE_CREATED_AT,
					usuarioCreacion: COOKIE_USER
				};
				await RequestUseCase.post(endpoints.agenda.addAssociationTimezoneSchedule, DATA)
					.then(() => {
						newItemCreated();
						navigate(paths.schedule);
					})
					.catch(() => {
						errorProcess();
					});
			}
		});

		// getTimeZoneByIdAgenda(idAgenda);
	};

	const [dialog, setDialog] = useState({
		text: '',
		active: false,
		function: null
	});

	const closeDialog = () => {
		setDialog({ ...dialog, active: false });
	};

	return {
		timeZoneSelected,
		setFormData,
		formData,
		handleSubmit,
		handleText,
		titlesTableTimeZone,
		dialog,
		closeDialog,
		openModalTechnicalToAssign,
		onCloseTechnicalToAssign,
		openModalTechnicalToReview,
		onCloseTechnicalToReview,
		setTechnicalsToAssing,
		formatterText,
		navigate
	};
}
