import useAxios from 'hooks/useAxios';
import { useSeachContext } from 'context/SearchContext';
import dateFormat from 'dateformat';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import { useEffect } from 'react';

export default function MunicipalitiesViewModel() {
	const { endpoints, RequestUseCase } = useAxios();
	const { setDataTable, searchResults = [], setSearchResults } = useSeachContext();
	const {
		loading,
		error,
		toggleLoading,
		toggleError,
		handleClick,
		displayMessage,
		displayLoading
	} = useGetData();

	useEffect(() => {
		(async () => {
			toggleLoading(true);
			await getData();
			toggleLoading(false);
		})();
	}, []);

	const getData = () => {
		RequestUseCase.get(endpoints.zones.getAllMunicipalities)
			.then((data) => {
				const newArray = [];
				data.forEach((item) =>
					newArray.push({
						codigoMunicipio: item.codigoMunicipio,
						nombre: item.nombre,
						prefijo: item.prefijo,
						estado: item.estado,
						kilometros: item.kilometros,
						codigoDepartamento: item.idDepartamento.codigoDepartamento,
						nombrePais: item.idDepartamento.idPais.nombrePais,
						fechaCreacion: dateFormat(item.fechaCreacion, 'yyyy/mm/dd - h:MM:ss TT'),
						fechaModificacion: dateFormat(item.fechaModificacion, 'yyyy/mm/dd - h:MM:ss TT'),
						id: item.idMunicipio,
						objeto: item.idDepartamento,
						usuarioCreacion: item.usuarioCreacion,
						fc: item.fechaCreacion,
						uc: item.usuarioCreacion,
						zonaHorariaGmt: parseInt(item.zonaHorariaGmt)
					})
				);
				setDataTable(newArray);
				setSearchResults(newArray);
			})
			.catch((err) => {
				toggleError(!error);
				console.log(err);
				handleClick();
			});
	};

	// use Hook of language v2
	const { formatterText } = useLangv2();

	const titles = [
		formatterText('table.title.muniCode', 'Código municipio'),
		formatterText('table.title.muniName', 'Nombre municipio'),
		formatterText('table.title.countryPrefix', 'Prefijo'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.title.km', 'Kilómetros'),
		formatterText('table.title.departmentCode', 'Código departamento'),
		formatterText('table.title.countryName', 'Nombre país'),
		formatterText('table.title.creationD', 'Fecha creación'),
		formatterText('table.title.modificationDate', 'Fecha modificación')
	];
	return {
		loading,
		error,
		displayMessage,
		displayLoading,
		searchResults,
		getData,
		titles
	};
}
