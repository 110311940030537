import React, { useEffect, useState } from 'react';

// Import Context
import { useSeachContext } from 'context/SearchContext';
import { usePermissionContext } from 'context/PermissionContext';

// Import Hooks
import useLangv2 from 'hooks/useLangv2';

// Import Paths
import paths from 'services/paths';

// Import Screens
import BillingTypesTable from 'screens/parameters/billingTypes/BillingTypesTable';
import BusinessUnitsTable from 'screens/parameters/businessUnits/BusinessUnitsTable';
import ClientTypeTable from 'screens/parameters/clientType/ClientTypeTable';
import MarketTable from 'screens/parameters/market/MarketTable';
import ProviderTypeTable from 'screens/parameters/providerType/ProviderTypeTable';
import Parameters from 'screens/parameters/thirdType/Parameters';

//Import Constants
import { MODULES_NAME } from 'common/validators/PermissionsConstants';

export default function ClientParametersTableViewModel() {
	// use Hook of language v2
	const { formatterText } = useLangv2();

	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();
	const permissionsAccessGaranted = () => {
		permissionsAccess(MODULES_NAME.clientParameters.principal);
	};

	useEffect(() => {
		permissionsAccessGaranted();
	}, [permissions]);

	const tabs = [
		{
			nombre: formatterText('table.name.search.market', 'Mercados'),
			route: paths.createMarket,
			nombreLink: formatterText('btn.create.market', 'Crear mercado'),
			componente: <MarketTable permisos={permittedActions} />,
			idPermiso: MODULES_NAME.clientParameters.market
		},
		{
			nombre: formatterText('table.name.search.businessUnits', 'Unidades de negocio'),
			route: paths.createBusinessUnit,
			nombreLink: formatterText('btn.create.business.unit', 'Crear unidad de negocio'),
			componente: <BusinessUnitsTable permisos={permittedActions} />,
			idPermiso: MODULES_NAME.clientParameters.unitBusiness
		},
		{
			nombre: formatterText('p.provider.type', 'Tipo de proveedor'),
			route: paths.createProviderType,
			nombreLink: formatterText('btn.create.provider.type', 'Crear tipo de proveedor'),
			componente: <ProviderTypeTable permisos={permittedActions} />,
			idPermiso: MODULES_NAME.clientParameters.providerType
		},
		{
			nombre: formatterText('table.name.search.clientType', 'Tipos de clientes'),
			route: paths.createClientType,
			nombreLink: formatterText('btn.create.cliente.type', 'Crear tipo de cliente'),
			componente: <ClientTypeTable permisos={permittedActions} />,
			idPermiso: MODULES_NAME.clientParameters.clientType
		},
		{
			nombre: formatterText('table.name.search.billingType', 'Tipos de facturación'),
			route: paths.createBillingType,
			nombreLink: formatterText('btn.create.billingType', 'Crear tipo de facturacion'),
			componente: <BillingTypesTable permisos={permittedActions} />,
			idPermiso: MODULES_NAME.clientParameters.invoiceType
		},
		{
			nombre: formatterText('tab.title.third.types', 'Tipos de terceros'),
			route: paths.createParameter,
			nombreLink: formatterText('btn.create.thirdType', 'Crear tipo de tercero'),
			componente: <Parameters permisos={permittedActions} />,
			idPermiso: MODULES_NAME.clientParameters.thirdPartiesType
		}
	];

	const { setSearch } = useSeachContext();

	// Index Tab
	const [indexTabClient, setIndexTabClient] = useState(
		Number(localStorage.getItem('ClientParametersTable')) || 0
	);

	// Select tab
	const [selectedTab, setSelectedTab] = useState(tabs[indexTabClient]);

	useEffect(() => {
		setSearch('');
		localStorage.setItem('ParametersServicesTable', 0);
		const index = Number(localStorage.getItem('ClientParametersTable'));
		setIndexTabClient(index);
	}, []);

	return {
		formatterText,
		indexTabClient,
		permissionsAccess,
		permittedActions,
		selectedTab,
		setSearch,
		setSelectedTab,
		tabs
	};
}
