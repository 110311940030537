import React, { useContext } from 'react';
// Import Contexts
import { AppContext } from 'context/AppContext';
// Import Services
import paths from 'services/paths';
// Import Styles
import styles from './minimalTables.modals.module.css';
import useLangv2 from 'hooks/useLangv2';

export default function MoreTimeZoneInformation() {
	const { MoreInfoTimeZone } = useContext(AppContext);

	const reviewInfo = (idAgenda) => {
		window.open(`#${paths.updateSchedule.replace(':id', idAgenda)}`, '_blank');
	};

	const { formatterText } = useLangv2();

	const timeZoneDetail = () => {
		const newArray = [];
		// [1] - iterate of the array, split to get the 4 position and save in the new array
		MoreInfoTimeZone.forEach((item, i) => {
			const haveN = item.split(';;')[0];
			if (!haveN.includes('N')) {
				let color = '';
				const D_T = {
					N: 'white',
					I: '#F1684E',
					A: '#85EB4E'
				};
				const splitData = item.split(';;');
				const agendaName = splitData[1];
				// [2] - set the color
				if (splitData[0].includes('N')) {
					color = D_T['N'];
				} else if (splitData[0].includes('I')) {
					color = D_T['I'];
				} else if (splitData[0].includes('A')) {
					color = D_T['A'];
				}

				const idAgenda = splitData[2];
				const FN = splitData[3];
				newArray.push({ idAgenda, FN, color, agendaName });
			} else {
				newArray.push({ idAgenda: `N${i}`, FN: 0 });
			}
		});

		return newArray.map((item, i) => {
			return (
				<div key={`${item.color}_${i + 1}`}>
					{item.FN !== 0 && (
						<section className={styles.container}>
							<div
								style={{
									width: '250px'
								}}
							>
								<p
									style={{
										backgroundColor: item.color,
										width: '30px',
										height: '30px',
										borderRadius: '50%'
									}}
								>
									{'   '}
								</p>
							</div>
							<p
								style={{
									width: '250px'
								}}
							>
								{i}:00
							</p>
							<p
								style={{
									width: '250px'
								}}
							>
								{item.FN}
							</p>
							<button
								className="btn-search"
								type="button"
								onClick={() => reviewInfo(item.idAgenda)}
								style={{
									width: '250px'
								}}
							>
								{formatterText('agenda.column.check')}
							</button>
						</section>
					)}
				</div>
			);
		});
	};

	return (
		<div>
			<h3>{formatterText('agenda.column.time.slot.details')}</h3>
			<section className={styles.container}>
				<p className={styles.title}>{formatterText('agenda.column.availability.type')}</p>
				<p className={styles.title}>{formatterText('agenda.column.hour.number')}</p>
				<p className={styles.title}>{formatterText('agenda.column.time.slot.name')}</p>
				<p className={styles.title}>{formatterText('agenda.column.actions')}</p>
			</section>
			{timeZoneDetail()}
			{/* <section></section> */}
		</div>
	);
}
