import React from 'react';
import { SearchWrapper } from 'context/SearchContext';
import CreateRolComponent from './CreateRol.component';

export default function CreateRol() {
	return (
		<SearchWrapper>
			<CreateRolComponent />
		</SearchWrapper>
	);
}
