import useAxios from 'hooks/useAxios';
import { useSeachContext } from 'context/SearchContext';
import useLangv2 from 'hooks/useLangv2';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import endpoints from 'services/api';
import { v4 as uuidv4 } from 'uuid';

export default function AddTimeZoneViewModel({ getTimeZoneByIdAgenda }) {
	const { RequestUseCase, COOKIE_USER } = useAxios();

	const { timeZoneSelected, setTimeZoneSelected } = useSeachContext();
	// get the id from the url
	const { id } = useParams();
	// use Hook of language v2
	const { noFilledContent, errorDataRecovery, newItemCreated, errorProcess, elementRepeated } =
		useLangv2();

	const [show, setShow] = useState(false);
	// Value that select use
	const [selectTimeZone, setSelectTimeZone] = useState([]);
	// selects ids state
	const [selectIds, setSelectIds] = useState({
		idFranjaHoraria: {
			value: 0,
			label: ''
		}
	});

	useEffect(() => {
		getAllTimeZone();
	}, []);

	// Get all timeZones for select
	const getAllTimeZone = () => {
		RequestUseCase.get(endpoints.agenda.getAllTimezone)
			.then((response) => {
				// variable empty to save the data
				const data = [];
				response.forEach((element) => {
					// special keys to the table
					data.push({
						value: element.idFranjaHoraria,
						label: `${element.nombre} - ${element.idDiaSemana.dia} ${element.idHoraInicio.hora} -${element.idHoraFin.hora} `
					});
				});
				// set the data to the table
				setSelectTimeZone(data);
			})
			.catch(() => {
				errorDataRecovery();
			});
	};

	// add timeZone to the schedule without save
	const addTimeZone = (e) => {
		e.preventDefault();
		let REPEATED_TIME_ZONE = timeZoneSelected?.some(repeatTimeZone);
		if (selectIds.idFranjaHoraria.value === 0) {
			noFilledContent();
		} else {
			if (id) {
				REPEATED_TIME_ZONE = timeZoneSelected?.some(repeatTimeZone);
				if (REPEATED_TIME_ZONE) {
					elementRepeated();
				} else {
					const DATA = {
						idFranjaHorariaAgenda: null,
						idAgenda: {
							idAgenda: parseInt(id)
						},
						idFranjaHoraria: {
							idFranjaHoraria: selectIds.idFranjaHoraria.value
						},
						estado: 1,
						usuarioCreacion: COOKIE_USER
					};
					associateAgendaToTimeZone(DATA);
				}
			} else {
				REPEATED_TIME_ZONE = timeZoneSelected?.some(repeatTimeZone);
				if (REPEATED_TIME_ZONE) {
					elementRepeated();
				} else {
					setTimeZoneSelected((prevTimeZoneSelected) => [
						...(prevTimeZoneSelected || []),
						{
							timeZone: selectIds.idFranjaHoraria.label,
							idFranjaHoraria: selectIds.idFranjaHoraria.value,
							idFranjaHorariaAgenda: uuidv4()
						}
					]);
				}
			}
		}
	};

	// Associate a time zone to an agenda
	const associateAgendaToTimeZone = (DATA) => {
		RequestUseCase.post(endpoints.agenda.addAssociationTimezoneSchedule, DATA)
			.then(() => {
				newItemCreated();
				getTimeZoneByIdAgenda();
			})
			.catch(() => {
				errorProcess();
			});
	};

	// find if the timeZone is repeated
	const repeatTimeZone = (el) => {
		return el.idFranjaHoraria === selectIds.idFranjaHoraria.value;
	};
	return { show, setShow, selectTimeZone, selectIds, setSelectIds, getAllTimeZone, addTimeZone };
}
