import React from 'react';
// Import Libs
import { Formiz, FormizStep } from '@formiz/core';
import { isEmail, isPattern } from '@formiz/validations';
import { FormattedMessage } from 'react-intl';
// Import Components
import { InputFieldResponsive } from 'common/inputs/InputFieldResponsive';
import InputSelectorResponsive from 'common/inputs/InputSelectorResponsive';
import Attachments from 'components/AttachedFiles/Attachments';
// Import Paths
import paths from 'services/paths';
//Import Constants
import { URLREGEX } from 'common/validators/Regex';
//Import ViewModel
import UpdateThirdPartiesViewModel from './UpdateThirdParties.viewModel';

export default function UpdateThirdParties() {
	const {
		archivos,
		currentThird,
		formatterText,
		handleSubmit,
		loading,
		myForm,
		navigate,
		regimen,
		searchResults,
		setCurrentFiles,
		typeDocument,
		typeThirdParties,
		uploadNewFile
	} = UpdateThirdPartiesViewModel();

	return (
		<div className="centered-form">
			{currentThird ? (
				<Formiz onValidSubmit={handleSubmit} connect={myForm}>
					<form noValidate onSubmit={myForm.submit} className="container-wrapForm">
						<div className="new-container-wrapForm__tabs">
							{myForm.steps.map((step) => (
								<button
									key={step.name}
									className={`new-tab-option ${
										step.name === myForm.currentStep.name ? 'is-active' : ''
									}`}
									type="button"
									onClick={() => myForm.goToStep(step.name)}
								>
									{!step.isValid && step.isSubmitted && (
										<small className="mr-2" aria-label="warning">
											⚠️
										</small>
									)}
									{formatterText(step.label)}
								</button>
							))}
						</div>
						<div className="container-wrapForm-content">
							<FormizStep name="step1" label="p.general.information">
								<div className="title-section">
									<span className="circle-form">
										<span>1</span>
									</span>
									<h2>
										<FormattedMessage
											id="p.general.information"
											defaultMessage="Información general"
										/>
									</h2>
								</div>

								<section className="grid-container-3c">
									<InputSelectorResponsive
										type="text"
										name="tipoDocumento"
										labelText={formatterText('p.document.type', 'Tipo de documento')}
										placeholder={
											<FormattedMessage
												id="input.placeholder.select"
												defaultMessage="Selecione una opción"
											/>
										}
										required={formatterText('p.document.type.is.required')}
										defaultValue={{
											label: currentThird.idTipoDocumento.abreviatura,
											value: currentThird.idTipoDocumento.idTipoDocumento
										}}
										data={typeDocument}
										isLoading={loading}
									/>
									<InputFieldResponsive
										type="text"
										name="identificacion"
										labelText={formatterText('text.ID', 'Identificación')}
										placeholder={formatterText('text.ID', 'Identificación')}
										validateInput="integer"
										required={formatterText('p.id.is.required')}
										defaultValue={currentThird.identificacion}
									/>
									<InputFieldResponsive
										type="text"
										name="nombre"
										labelText={formatterText('p.name.compName', 'Nombre o razón social')}
										placeholder={formatterText('p.name.compName', 'Nombre o razón social')}
										validateInput="text"
										required={formatterText('p.name.or.compName.is.required')}
										defaultValue={currentThird.nombre}
									/>
									<InputSelectorResponsive
										type="text"
										name="regimen"
										labelText={formatterText('p.regime', 'Régimen')}
										placeholder={
											<FormattedMessage
												id="input.placeholder.select"
												defaultMessage="Selecione una opción"
											/>
										}
										required={formatterText('p.is.required')}
										data={regimen}
										defaultValue={{
											label: currentThird.regimen,
											value: currentThird.regimen
										}}
										isLoading={loading}
									/>
									<InputFieldResponsive
										type="text"
										name="telefono"
										labelText={formatterText('table.title.phone', 'Teléfono')}
										placeholder={formatterText('table.title.phone', 'Teléfono')}
										validateInput="integer"
										required={formatterText('p.number.telephone.is.required')}
										defaultValue={currentThird.telefono}
									/>
									<InputFieldResponsive
										type="text"
										name="telefonoAlterno"
										labelText={formatterText('p.alt.phone', 'Teléfono alterno')}
										placeholder={formatterText('p.alt.phone', 'Teléfono alterno')}
										validateInput="integer"
										required={formatterText('p.number.alternate.is.required')}
										defaultValue={currentThird.telefonoAlterno}
									/>
									<InputFieldResponsive
										type="text"
										name="sitioWeb"
										labelText={formatterText('table.title.website', 'Sitio web')}
										placeholder={formatterText('table.title.website', 'Sitio web')}
										validateInput="web"
										required={formatterText('p.website.is.required')}
										defaultValue={currentThird.sitioWeb}
										validations={[
											{
												rule: isPattern(URLREGEX),
												message: 'La url no es válida'
											}
										]}
									/>
									<InputFieldResponsive
										type="email"
										name="correo"
										labelText={formatterText('table.title.email', 'Correo electrónico')}
										placeholder={formatterText('table.title.email', 'Correo electrónico')}
										required={formatterText('p.email.is.required')}
										defaultValue={currentThird.correo}
										validations={[
											{
												rule: isEmail(),
												message: 'snackbar.warning.input.mail.no.valid'
											}
										]}
									/>
									<InputSelectorResponsive
										type="text"
										name="idTipoTercero"
										labelText={formatterText('p.third.type', 'Tipo de tercero')}
										placeholder={
											<FormattedMessage
												id="input.placeholder.select"
												defaultMessage="Selecione una opción"
											/>
										}
										defaultValue={{
											label: currentThird.idTipoTercero.nombre,
											value: currentThird.idTipoTercero.idTipoTercero
										}}
										required={formatterText(
											'p.third.type.required',
											'El Tipo de tercero es requerido'
										)}
										data={typeThirdParties}
										isLoading={loading}
									/>
								</section>
								<div className="title-section">
									<span className="circle-form">
										<span>1.1</span>
									</span>
									<h2>
										<FormattedMessage id="p.place.residence" defaultMessage="Lugar de residencia" />
									</h2>
								</div>
								<section
									className="grid-container-2c"
									style={{
										width: '100%'
									}}
								>
									<InputSelectorResponsive
										type="text"
										name="idMunicipio"
										labelText={formatterText('p.city.residence', 'Ciudad de residencia')}
										placeholder={
											<FormattedMessage
												id="input.placeholder.select"
												defaultMessage="Selecione una opción"
											/>
										}
										defaultValue={{
											label: currentThird.idMunicipio.nombre,
											value: currentThird.idMunicipio.idMunicipio
										}}
										selectedOption={currentThird.idMunicipio}
										required={formatterText(
											'p.label.title.ciudadResidenciaRequerida',
											'La ciudad de residencia es requerida'
										)}
										data={searchResults}
										isLoading={loading}
									/>
									<InputFieldResponsive
										type="text"
										name="direccion"
										labelText={formatterText('table.title.address', 'Dirección')}
										placeholder={formatterText('table.title.address', 'Dirección')}
										required={formatterText('p.adress.is.required')}
										defaultValue={currentThird.direccion}
										validateInput="address"
									/>
								</section>
							</FormizStep>

							<FormizStep name="step2" label="p.load.attachment.files">
								<Attachments
									currentFiles={archivos}
									setCurrentFiles={setCurrentFiles}
									isEdited={true}
									uploadNewFile={uploadNewFile}
									type={2}
									showParameters={true}
								/>
							</FormizStep>
							<section className="form-responsive-container-buttons">
								<button type="submit" className="btn-primary">
									<FormattedMessage
										id="alert.button.confirm.general"
										defaultMessage="Guardar cambios"
									/>
								</button>
								<button className="input-cancel" onClick={() => navigate(paths.thirdParties)}>
									<FormattedMessage id="alert.button.cancel.general" defaultMessage="Cancelar" />
								</button>
							</section>
						</div>
					</form>
				</Formiz>
			) : (
				<div>Cargando...</div>
			)}
		</div>
	);
}
