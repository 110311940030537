import React, { useRef, useState } from 'react';
// Import Contexts
// Import Hooks
import useLangv2 from 'hooks/useLangv2';
// Import Components
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import HandleOnError from 'common/validators/HandleOnError';
// Import Libs
import { useSeachContext } from 'context/SearchContext';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'react-responsive-modal';
import Swal from 'sweetalert2';
// Import Services

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UploadExcel = ({ ActionFunction, currentText, currentRestrict = '' }) => {
	// Modal config
	const [errorList, setErrorList] = useState(false);
	const onOpenErrorList = () => setErrorList(true);
	const onCloseErrorList = () => setErrorList(false);
	// load error list data
	const [errorData, setErrorData] = useState([]);
	const { formatterText, errorDataRecovery } = useLangv2();
	const inputFile = useRef(null);
	const { setSearch } = useSeachContext();

	const showRestrictions = () => {
		let lastRestrict = formatterText('file.upload.structure.product');
		if (currentRestrict !== '') {
			lastRestrict = currentRestrict;
		}
		const restrictions = `
    ${formatterText('file.upload.system.update.info')}\n
    ${formatterText('file.upload.system.update.info.of')} ${currentText} ${formatterText(
			'file.upload.system.update.info.create'
		)}.\n
    ${formatterText('restrictions.title')} \n
    ${formatterText('file.upload.restriction.1.generic')} \n
    ${formatterText('file.upload.restriction.2.generic')} \n
    3). ${lastRestrict} \n
    `;

		Swal.fire({
			title: formatterText('file.upload.attention'),
			icon: 'question',
			showCancelButton: true,
			html: `
        <p>${restrictions}</p>
        <br/>
        <button onclick="document.getElementById('file-product').click();" >${formatterText(
					'file.upload.choose.file'
				)}</button>
        <label id="lbl-file-product" for="file-product">
          ${formatterText('file.upload.not.choose.file')}
        </label>
        <br/>
        <input type="file" 
              onchange="document.getElementById('lbl-file-product').innerHTML = this.files.length > 0 ? this.files[0]?.name : '${formatterText(
								'file.upload.not.choose.file'
							)}';"
              style="display: none" 
              id="file-product" 
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
      `,
			confirmButtonText: formatterText('file.upload.upload.file'),
			cancelButtonText: formatterText('file.upload.cancel'),
			allowOutsideClick: false
		}).then((result) => {
			if (result.value) {
				const fileSelected = document.getElementById('file-product').files[0];
				uploadExcel(fileSelected);
			} else {
				OnMessage('warning', formatterText('file.upload.cancel.upload'));
			}
		});
	};

	const uploadExcel = (file) => {
		const TYPE_FILE = {
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': true
		};
		const fileSize = file.size;
		// limita el tamaño del archivo a 5MB
		if (fileSize < 5000000) {
			const typeFile = file.type;
			if (TYPE_FILE[typeFile]) {
				// send the file to the upload function
				const formData = new FormData();
				formData.append('file', file);
				setIsLoading(true);

				OnMessage('info', formatterText('file.upload.uploading'));

				postUpload(formData);
			} else {
				HandleOnError(formatterText('file.upload.invalid.file.type.generic'));
			}
		} else {
			HandleOnError(formatterText('file.upload.file.too.large.generic'));
		}
	};

	const postUpload = (body) => {
		ActionFunction(body)
			.then((res) => {
				if (res.length === 0) {
					OnMessage('success', formatterText('file.upload.success'));
					setSearch('');
				} else {
					showErrorList(res);
				}

				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				showError(err.request);
			});
	};

	const showErrorList = (response) => {
		OnMessage('error', formatterText('file.upload.error'));
		setErrorData(response);
		onOpenErrorList();
	};

	const LIST_ERROR = {
		1: formatterText('error.alert.excel.case.1', 'Caracteres especiales en el nombre'),
		2: formatterText('error.alert.excel.case.2', 'Daño general en el archivo'),
		3: formatterText('error.alert.excel.case.3', 'No existe el País'),
		4: formatterText('error.alert.excel.case.4', 'No existe el Departamento'),
		5: formatterText('error.alert.excel.case.5', 'No existe el Municipio'),
		6: formatterText('error.alert.excel.case.6', 'No existe el producto con el SKU definido'),
		7: formatterText(
			'error.alert.excel.case.7',
			'No existe proveedor con el tipo y número de identificación definido'
		),
		8: formatterText(
			'error.alert.excel.case.8',
			'No se encuentra Unidad Medida con abreviatura definida'
		),
		9: formatterText(
			'error.alert.excel.case.9',
			'No existe Lista de precios como el CÓDIGO definido'
		),
		10: formatterText(
			'error.alert.excel.case.10',
			'No existe la Actividad con el SKU, la etapa y del tipo de servicio definido'
		),
		11: formatterText('error.alert.excel.case.11', 'No existe unidad de medida')
	};

	const showError = (response) => {
		if (response.status === 409 || response.status === 404) {
			OnMessage('error', `${formatterText('file.upload.error')} ${response.statusText}`);
		} else {
			errorDataRecovery();
		}
	};

	const OnMessage = (typeAlert, messageAlert) => {
		setType(typeAlert);
		setMessage(messageAlert);
		handleClick();
	};

	/* Snackbar status */
	const [open, setOpen] = useState(false);
	const [type, setType] = useState('success');
	const [message, setMessage] = useState('Default');
	const [isLoading, setIsLoading] = useState(false);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	return (
		<>
			<button
				onClick={showRestrictions}
				className={isLoading ? 'btn-upload btn-loading' : 'btn-upload'}
			>
				{isLoading
					? formatterText('text.uploading')
					: `${formatterText('upload.excel.title')} ${currentText}`}

				<input
					type="file"
					title=" "
					className="disabledInput"
					onChange={uploadExcel}
					ref={inputFile}
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					id="file"
				/>
			</button>

			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
					{message}
				</Alert>
			</Snackbar>
			{/* Error list modal */}
			<Modal
				open={errorList}
				onClose={onCloseErrorList}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				{errorData.map((el, i) => {
					return (
						<section key={i} className="d-flex">
							<span className="text-inline primary-green">
								<FormattedMessage id="text.row.zone" defaultMessage="Fila" />:{el.fila}
							</span>
							<p>{LIST_ERROR[el.idError] || 'NOT FOUND'}</p>
							<span>{`: ${el.error}`}</span>
						</section>
					);
				})}
			</Modal>
		</>
	);
};

export default UploadExcel;
