import useAxios from 'hooks/useAxios';
import { useState } from 'react';
// Import Contexts
// Import Hooks
import useLangv2 from 'hooks/useLangv2';
import endpoints from 'services/api';
// Icons for cell
import { useSeachContext } from 'context/SearchContext';

export default function DynamicRowNotificationsViewModel({ columns, setUpdated }) {
	const { RequestUseCase } = useAxios();

	// Modal config
	const [changeState] = useState(columns.leido);

	// use Hook of language v2
	const { formatterText } = useLangv2();

	useSeachContext();

	const [loading, setLoading] = useState(false);
	// Dialog edit
	const [dialog, setDialog] = useState({
		text: '',
		active: false,
		function: null
	});

	const closeDialog = () => {
		setLoading(false);
		setDialog({ ...dialog, active: false });
	};

	const handleMarkAsRead = async (columns) => {
		RequestUseCase.get(endpoints.notificationsTable.markAsRead(columns.idAgendaNotificacion)).then(
			() => {
				// setChangeState(true);
				setUpdated(true);
				setTimeout(() => {
					setUpdated(false);
				}, 1000);
				closeDialog();
			}
		);
	};
	return { changeState, handleMarkAsRead, dialog, setDialog, loading, formatterText };
}
