export const FIELDS = {
	selectOption: 'field.selectOption',
	title: {
		description: 'field.title.description',
		answers: 'field.title.answers',
		addAnswer: 'field.title.addAnswer',
		attachedFiles: 'field.title.attachedFiles',
		emailSendingType: 'field.title.emailSendingType',
		sendCustomerEmail: 'field.title.sendCustomerEmail',
		sendManualEmail: 'field.title.sendManualEmail'
	},
	input: {
		save: 'field.input.save',
		update: 'field.input.update'
	},
	placeholder: {
		email: 'palceholder.email'
	}
};
