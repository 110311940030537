import React from 'react';
import CreateSectorViewModel from './CreateSector.viewModel';
import { UpsertSectorForm } from '../UpsertSector/components';

const CreateSectorComponent = () => {
	const {
		formatterText,
		formik,
		handleAddRole,
		handleRemoveRole,
		navigate,
		setToggleSelector,
		toggleSelector
	} = CreateSectorViewModel();

	return (
		<UpsertSectorForm
			formatterText={formatterText}
			formik={formik}
			handleAddRole={handleAddRole}
			handleRemoveRole={handleRemoveRole}
			navigate={navigate}
			setToggleSelector={setToggleSelector}
			toggleSelector={toggleSelector}
		/>
	);
};

export default CreateSectorComponent;
