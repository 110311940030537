import React from 'react';
// Import Context
import { formatterText } from 'hooks/useLangv2';
import DynamicTableServices from '../DynamicTableServices/DynamicHeadServices';

function SeguimientoActividad({ getDataTable }) {
	const tableTitles = [
		formatterText('title.service.activity'),
		formatterText('title.service.activityStatus'),
		formatterText('title.service.activityStatusChangeDate')
	];

	return (
		<div>
			<div className="tdR thR table-container">
				<DynamicTableServices
					titles={tableTitles}
					pageName={formatterText('title.service.activity.page.name')}
					getData={getDataTable}
				/>
			</div>
		</div>
	);
}

export default SeguimientoActividad;
