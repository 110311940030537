import React from 'react';

// Import Libs
import { Formiz } from '@formiz/core';
import { FormattedMessage } from 'react-intl';

// Import Components
import { InputFieldResponsive } from 'common/inputs/InputFieldResponsive';

// Import Services
import paths from 'services/paths';

//Import ViewModel
import CreateContractViewModel from './CreateContract.viewModel';

export default function CreateContract() {
	const { myForm, handleSubmit, handleFormChange, formatterText, navigate } =
		CreateContractViewModel();

	return (
		<Formiz onChange={handleFormChange} connect={myForm}>
			<form className="form-responsive" onSubmit={handleSubmit}>
				<section className="form-responsive-container-information">
					<section className="form-responsive-information__option">
						<InputFieldResponsive
							type="text"
							name="nombre"
							labelText={formatterText('table.title.contract.type.name')}
							placeholder={formatterText(
								'table.title.contract.type.name',
								'Nombre del tipo de contrato'
							)}
							validateInput="namestage"
							maxLength="100"
							required={formatterText('p.label.title.tipoContratoRequerido')}
						/>
					</section>
					<section className="form-responsive-information__option">
						<InputFieldResponsive
							type="text"
							area={true}
							name="descripcion"
							styleName="input-primary-textarea"
							labelText={formatterText('table.title.description', 'Descripción')}
							placeholder={formatterText('table.title.description', 'Descripción')}
							validateInput="text"
							maxLength="200"
							required={formatterText('p.description.is.required')}
						/>
					</section>
					<section className="form-responsive-information__option">
						<InputFieldResponsive
							type="text"
							name="codigo"
							labelText={formatterText('p.code', 'codigo')}
							placeholder={formatterText(
								'input.placeholder.contract.type.code',
								'Código del tipo de contrato'
							)}
							validateInput="code"
							maxLength="45"
							required={formatterText('table.title.code.required')}
						/>
					</section>
				</section>
				<section className="form-responsive-container-buttons">
					<button type="submit" className="btn-primary">
						<FormattedMessage id="btn.save.changes" defaultMessage="Guardar cambios" />
					</button>
					<button className="input-cancel" onClick={() => navigate(paths.contracts)}>
						<FormattedMessage id="btn.cancel" defaultMessage="Cancelar" />
					</button>
				</section>
			</form>
		</Formiz>
	);
}
