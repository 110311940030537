import React, { useState } from 'react';
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';
import endpoints from 'services/api';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

const ModalSendMail = ({ onClose, idService }) => {
	const { RequestUseCase } = useAxios();
	const [formData, setFormData] = useState({ mail: '' });
	const [option, setOption] = useState(true);
	const [value, setValue] = useState('current');
	const { customSB, formatterText } = useLangv2();

	const handleChange = (e) => {
		setOption(!option);
		setValue(e.target.value);
	};

	const handleText = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleSubmit = () => {
		const body = {
			idServicio: idService,
			destinatario: 'qvrafael@yopmail.com'
		};

		postSend(body);
	};

	const postSend = (body) => {
		return new Promise((resolve) => {
			RequestUseCase.post(endpoints.serviceProduct.sendMail, body)
				.then(() => {
					customSB('success', 'toast.email.success', 'El correo se envio correctamente');
					onClose();
					resolve();
				})
				.catch(() => {
					customSB('error', 'toast.email.error', 'El correo no pudo ser enviado.');
				});
		});
	};
	return (
		<>
			<form className="w100-container" onSubmit={() => handleSubmit()}>
				<section className="grid-container-3c">
					<div className="d-flex">
						<FormControl className="d-flex">
							<RadioGroup
								aria-labelledby="demo-controlled-radio-buttons-group"
								name="controlled-radio-buttons-group"
								defaultValue={value}
								value={value}
								onChange={handleChange}
							>
								<FormControlLabel
									value="current"
									control={<Radio color={'primary'} />}
									label="Usar correo actual"
									className="w100-container"
								/>
								<FormControlLabel value="other" control={<Radio />} label="Usar otro correo" />
							</RadioGroup>
						</FormControl>
					</div>
				</section>
				<section className="grid-container-3c">
					<label className="d-flex">
						<span className="text-inline span-label mr-4">
							Correo {value !== 'current' ? 'nuevo' : 'actual'}
						</span>
						<input
							type="text"
							value={formData.mail}
							name="mail"
							onChange={handleText}
							placeholder={formatterText(
								'input.placeholder.email',
								'Ingrese el correo electrónico'
							)}
							className="input-default w100-container"
						/>
					</label>
				</section>

				<section className="footer-grid-buttons">
					<input
						type="submit"
						className="btn-action-primary"
						value={formatterText('btn.send.email', 'Enviar correo')}
					/>
					<button
						className="btn-action-cancel"
						onClick={(e) => {
							e.preventDefault();
							onClose();
						}}
					>
						Cancelar
					</button>
				</section>
			</form>
		</>
	);
};

export default ModalSendMail;
