import useAxios from 'hooks/useAxios';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import endpoints from 'services/api';
import paths from 'services/paths';
import { EditFileFormViewModel } from './componentes/form/form.viewModel';

export default function EditFileViewModel() {
	const navigate = useNavigate();
	const { id } = useParams();
	const { formatterText } = useLangv2();
	const [selectValues, setSelectValues] = useState([]);
	const [selectValuesInitial, setSelectValuesInitial] = useState([]);

	// Ayuda al toggle
	const [active, setActive] = useState(true);
	const { COOKIE_USER, RequestUseCase } = useAxios();
	const { updatedItem, resourceNotFound } = useLangv2();
	const { loading } = useGetData();

	useEffect(() => {
		RequestUseCase.get(endpoints.attachedFiles.getById(id))
			.then((res) => {
				if (res === null) resourceNotFound();
				RequestUseCase.get(endpoints.attachedFiles.getAssociates(id)).then((resp) => {
					const newFormikInitialValues = parseDataToFormik({
						formikInitialValues,
						dataByServer: { ...res, idTipoArchivoAdjunto: id },
						resp
					});
					setFormikInitialValues(newFormikInitialValues);
					formik.resetForm({
						values: newFormikInitialValues
					});
					const allSelects = resp.map((item) => {
						return {
							value: item.tipoUsuario.idTipoUsuario,
							label: item.tipoUsuario.tipoUsuario,
							idTipoAdjuntoUsuario: item.idTipoAdjuntoUsuario
						};
					});
					setSelectValuesInitial(allSelects);
					setSelectValues(allSelects);
				});
				setActive(res.estado);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [id]);

	const handleSubmit = async (values, actions) => {
		actions.setSubmitting(true);

		const data = parseDataToSubmit(values);
		delete data.categorias;

		const valueDelete = selectValuesInitial.filter(
			(valueInitial) => !selectValues.includes(valueInitial)
		);
		const deleteValue = valueDelete.map((item) =>
			RequestUseCase.delete(endpoints.attachedFiles.deleteAssociates(item.idTipoAdjuntoUsuario))
		);
		RequestUseCase.put(endpoints.attachedFiles.updateAttachedFile, data)
			.then((res) => {
				// Actualiza las asociaciones relatedUsers
				const createValue = add.map((item) => {
					const asoc = {
						tipoArchivoAdjunto: { idTipoArchivoAdjunto: res.idTipoArchivoAdjunto },
						tipoUsuario: { idTipoUsuario: item.value },
						usuarioCreacion: COOKIE_USER,
						cantidad: data.cantidadMaxima
					};
					return RequestUseCase.post(endpoints.attachedFiles.relatedUsers, asoc);
				});

				Promise.all([...deleteValue, ...createValue])
					.then(() => {
						updatedItem();
						navigate(paths.files);
					})
					.catch((error) => {
						console.error(error);
					});
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				actions.setSubmitting(false);
			});
	};

	const {
		formik,
		formikInitialValues,
		parseDataToFormik,
		parseDataToSubmit,
		setFormikInitialValues
	} = EditFileFormViewModel({
		onSubmit: handleSubmit
	});

	const selectorData = [
		{ value: null, label: 'Seleccione un tipo de documento' },
		{ value: '.png', label: 'PNG' },
		{ value: '.jpg', label: 'JPG' },
		{ value: '.pdf', label: 'PDF' },
		{ value: '.doc', label: 'DOC' },
		{ value: '.docx', label: 'DOCX' },
		{ value: '.xls', label: 'XLS' },
		{ value: '.xlsx', label: 'XLSX' },
		{ value: '.ppt', label: 'PPT' },
		{ value: '.pptx', label: 'PPTX' },
		{ value: '.txt', label: 'TXT' },
		{ value: '.zip', label: 'ZIP' },
		{ value: '.rar', label: 'RAR' },
		{ value: '.7z', label: '7Z' },
		{ value: '.mp3', label: 'MP3' },
		{ value: '.mp4', label: 'MP4' },
		{ value: '.avi', label: 'AVI' },
		{ value: '.mov', label: 'MOV' },
		{ value: '.wmv', label: 'WMV' },
		{ value: '.flv', label: 'FLV' },
		{ value: '.mkv', label: 'MKV' },
		{ value: '.mpg', label: 'MPG' },
		{ value: '.mpeg', label: 'MPEG' },
		{ value: '.3gp', label: '3GP' },
		{ value: '.3g2', label: '3G2' },
		{ value: '.gif', label: 'GIF' },
		{ value: '.svg', label: 'SVG' }
	];

	const idCategory = [
		{ value: 1, label: formatterText('p.label.title.categoriaCliente') },
		{ value: 2, label: formatterText('p.label.title.categoriaTercero') },
		{ value: 3, label: formatterText('p.label.title.categoriaProveedor') },
		{ value: 4, label: formatterText('p.label.title.categoriaEmpleado') }
	];

	const [add, setAdd] = useState([]);

	useEffect(() => {
		const array = formik.values.categorias;
		const newArray = selectValuesInitial.filter((item) => array.includes(item.value));
		setSelectValues(newArray);
		const addItems = array.filter(
			(value) => !selectValuesInitial.some((item) => item.value === value)
		);
		const obj = idCategory.filter((item) => addItems.includes(item.value));
		setAdd(obj);
	}, [formik.values.categorias]);

	return {
		active,
		formatterText,
		formik,
		idCategory,
		loading,
		navigate,
		selectorData,
		setActive
	};
}
