export const useStorage = () => {
	const getItem = (item) => {
		try {
			const itemObj = localStorage.getItem(item);
			if (itemObj) {
				return JSON.parse(itemObj);
			}
		} catch (e) {
			console.log('Error', e);
		}
	};

	return { getItem };
};
