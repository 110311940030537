import useAxios from 'hooks/useAxios';
import { useContext, useEffect, useState } from 'react';
// Import Context
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import Hooks
import { useParams } from 'react-router-dom';
// Import Libs
import Cookie from 'js-cookie';
// Import Services
import HandleInput from 'common/validators/HandleInput';
import HandleOnError from 'common/validators/HandleOnError';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import { TEXTREGEXMIN } from 'common/validators/Regex';
import useLangv2 from 'hooks/useLangv2';
import endpoints from 'services/api';
export default function UpdateSubCategoryViewModel({ onClose, getFun, update }) {
	const { RequestUseCase } = useAxios();

	const [currentSubProductCategory, setCurrentSubProductCategory] = useState({
		idSubCategoriaProducto: '',
		nombre: '',
		codigo: '',
		estado: '',
		descripcion: '',
		idCategoriaProducto: ''
	});

	const { modalData, currentDataTable, setCurrentDataTable, setResultsTableSearch } =
		useContext(TableMinimalContext);
	// get id in the url
	const { id } = useParams();
	const { formatterText } = useLangv2();

	//  create useEffect clean function
	useEffect(() => {
		setCurrentSubProductCategory({
			idSubCategoriaProducto: modalData.idSubCategoriaProducto,
			nombre: modalData.nombre,
			codigo: modalData.codigo,
			estado: modalData.estado,
			descripcion: modalData.descripcion,
			idCategoriaProducto: modalData.idCategoriaProducto
				? modalData.idCategoriaProducto.idCategoriaProducto
				: ''
		});
	}, []);

	// handle to subCategoryProduct, permite setear la información del texto
	const handleNameTextSubCategory = (e) => {
		if (e.target.value.match(/^[a-zA-Z0-9 \-ñáéíóúÁÉÍÓÚÑ]*$/) !== null) {
			setCurrentSubProductCategory({
				...currentSubProductCategory,
				[e.target.name]: e.target.value
			});
		}
	};
	const handleChangeSubCategoryCode = (e) => {
		const example = e;
		example.target.value = e.target.value.toLowerCase();
		HandleInput(example, TEXTREGEXMIN, currentSubProductCategory, setCurrentSubProductCategory);
	};

	const handleUpdateProductCategory = (e) => {
		e.preventDefault();
		if (currentSubProductCategory.nombre !== '' && currentSubProductCategory.codigo !== '') {
			if (modalData.idSubCategoriaProducto !== '') {
				if (typeof modalData.idSubCategoriaProducto === 'number') {
					const isCodeDuplicated = currentDataTable.some(
						(item) =>
							item.codigo === currentSubProductCategory.codigo &&
							item.idSubCategoriaProducto !== currentSubProductCategory.idSubCategoriaProducto
					);

					const isDifferentNameSameCode = currentDataTable.some(
						(item) =>
							item.codigo === currentSubProductCategory.codigo &&
							item.nombre !== currentSubProductCategory.nombre
					);

					if (isDifferentNameSameCode) {
						const data = {
							idSubCategoriaProducto: currentSubProductCategory.idSubCategoriaProducto,
							nombre: currentSubProductCategory.nombre,
							descripcion: currentSubProductCategory.descripcion,
							idCategoriaProducto: {
								idCategoriaProducto: currentSubProductCategory.idCategoriaProducto
									? currentSubProductCategory.idCategoriaProducto
									: '1'
							},
							codigo: currentSubProductCategory.codigo,
							estado: currentSubProductCategory.estado,
							usuarioModificacion: Cookie.get('idUsuario'),
							fechaRegistro: '2022-08-09T05:00:00.000+00:00',
							fechaModificacion: new Date(),
							usuarioCreacion: Cookie.get('idUsuario')
						};
						RequestUseCase.put(endpoints.subProductCategory.updateSubProductCategory, data)
							.then(() => {
								onClose();
								getFun(id);
								update(id);
							})
							.catch((err) => {
								if (err.response?.data?.message) {
									HandleOnError(formatterText(err.response?.data?.message));
								}
							});
					} else if (isCodeDuplicated) {
						HandleOnError(
							formatterText(
								'alert.message.code.error.general',
								'El código ya está en uso por otra subcategoría, ingrese uno diferente'
							)
						);
					} else {
						const data = {
							idSubCategoriaProducto: currentSubProductCategory.idSubCategoriaProducto,
							nombre: currentSubProductCategory.nombre,
							descripcion: currentSubProductCategory.descripcion,
							idCategoriaProducto: {
								idCategoriaProducto: currentSubProductCategory.idCategoriaProducto
									? currentSubProductCategory.idCategoriaProducto
									: '1'
							},
							codigo: currentSubProductCategory.codigo,
							estado: currentSubProductCategory.estado,
							usuarioModificacion: Cookie.get('idUsuario'),
							fechaRegistro: '2022-08-09T05:00:00.000+00:00',
							fechaModificacion: new Date(),
							usuarioCreacion: Cookie.get('idUsuario')
						};
						RequestUseCase.put(endpoints.subProductCategory.updateSubProductCategory, data)
							.then(() => {
								onClose();
								getFun(id);
								update(id);
							})
							.catch((err) => {
								if (err.response?.data?.message) {
									HandleOnError(formatterText(err.response?.data?.message));
								}
							});
					}
				} else {
					// find the index of the SubCategoriaProducto to update
					const index = currentDataTable.findIndex(
						(item) => item.idSubCategoriaProducto === modalData.idSubCategoriaProducto
					);

					// update the SubCategoriaProducto
					const data = {
						idSubCategoriaProducto: currentSubProductCategory.idSubCategoriaProducto,
						nombre: currentSubProductCategory.nombre,
						codigo: currentSubProductCategory.codigo,
						descripcion: currentSubProductCategory.descripcion,
						estado: currentSubProductCategory.estado
					};

					// delete the SubCategoriaProducto from the currentDataTable
					const dataLeft = currentDataTable.filter((_, i) => {
						return i !== index;
					});

					setCurrentDataTable([...dataLeft, data]);
					setResultsTableSearch([...dataLeft, data]);
					onClose();
				}
			} else {
				updateStructure();
				onClose();
			}
		} else {
			HandleOnError(
				formatterText(
					'p.label.title.debesCompletarTodosLosCampos',
					'Debes completar todos los campos'
				)
			);
		}
	};

	// Update the structure of the table after search the item to update
	const updateStructure = () => {
		currentDataTable.forEach((item) => {
			if (item.codigo === modalData.codigo) {
				const dataLeft = currentDataTable.filter((_, i) => {
					return i !== currentDataTable.indexOf(item);
				});
				setCurrentDataTable([...dataLeft, currentSubProductCategory]);
				onClose();
			}
		});
	};

	const handleChangeSubCategoryDescription = (e) => {
		HandlerTextDescription(e, currentSubProductCategory, setCurrentSubProductCategory);
	};
	return {
		currentSubProductCategory,
		formatterText,
		handleChangeSubCategoryCode,
		handleChangeSubCategoryDescription,
		handleNameTextSubCategory,
		handleUpdateProductCategory
	};
}
