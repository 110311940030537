import React from 'react';
// Import Components
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Table from 'common/minimalTables/TableMinimal';
import UpdateModalItem from '../UpdateModalItem';
import SelectorMulti from 'common/selects/SelectorMultiCreate';
import paths from 'services/paths';
import UpdateServiceCategoryViewModel from './UpdateServiceCategory.viewModel';

const style = {
	position: 'absolute',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	height: 100,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3
};

export default function UpdateServiceCategoryComponent() {
	const {
		formatterText,
		handleSubmit,
		formData,
		handleText,
		handleErrorName,
		errorName,
		codeError,
		active,
		setActive,
		dataTable,
		titlesTableSubCategories,
		handleOpen,
		handleClose,
		open,
		currentItemMinimal,
		handleChangeSubCategory,
		handleChangeSubCategoryCode,
		handlerTextDescriptionSubcategory,
		handleSelectChange,
		handleAddItemMinimal,
		getDataFromMinimalTable,
		productCategories,
		loading,
		setLoading,
		selectValues,
		setSelectValues,
		id,
		categories,
		navigate,
		handlerTextDescription,
		handlerTextCode,
		handleErrorCode
	} = UpdateServiceCategoryViewModel();
	return (
		<>
			<form className="form-responsive" onSubmit={handleSubmit}>
				<section className="form-responsive container-border-wrapForm">
					<p className="wrap-form-title">
						{formatterText('table.title.category.service', 'Categoría de servicio')}
					</p>
					<section className="form-responsive-container-information">
						<section className="form-responsive-information__option">
							<h3 className="p-styles">
								{formatterText('p.name.service.category', 'Nombre de categoría de servicio')}
							</h3>
							<input
								className="input-primary"
								type="text"
								name="nombre"
								value={formData.nombre}
								onChange={handleText}
								placeholder={formatterText(
									'input.placeholder.service.category',
									'Nombre de categoría de servicio'
								)}
								onBlur={handleErrorName}
								maxLength="100"
								required
							/>
						</section>
						{errorName && (
							<div
								style={{
									fontSize: '0.7rem',
									color: 'red',
									marginTop: '-20px',
									marginRight: '500px',
									marginLeft: '100px'
								}}
							>
								{errorName}
							</div>
						)}
						<section className="form-responsive-information__option">
							<h3 className="p-styles">
								{formatterText('table.title.description', 'Descripción')}
							</h3>
							<textarea
								className="input-primary-textarea"
								name="descripcion"
								value={formData.descripcion}
								onChange={handlerTextDescription}
								placeholder="Descripción"
								maxLength="200"
							/>
						</section>
						<section className="form-responsive-information__option">
							<h3 className="p-styles">{formatterText('table.title.code', 'Código')}</h3>
							<input
								className="input-primary"
								type="text"
								name="codigo"
								value={formData.codigo.toLowerCase()}
								onChange={handlerTextCode}
								placeholder="Ingresa hasta 45 caracteres"
								maxLength="45"
								onBlur={handleErrorCode}
								required
							/>
						</section>
						{codeError && (
							<div
								style={{
									fontSize: '0.7rem',
									color: 'red',
									marginTop: '-20px',
									marginRight: '500px',
									marginLeft: '100px'
								}}
							>
								{codeError}
							</div>
						)}
						<section className="form-responsive-information__option">
							<h3 className="p-styles">{formatterText('table.title.state', 'Estado')}</h3>
							<label className="form-responsive-label">
								<p className="form-responsive-toggle">
									{active
										? formatterText('p.active', 'Activo')
										: formatterText('p.unActive', 'No activo')}
								</p>
								<label className="switch">
									<input
										checked={active}
										onChange={() => {
											setActive(!active);
										}}
										type="checkbox"
									/>
									<span className="slider round"></span>
								</label>
							</label>
						</section>
					</section>
					{dataTable.length > 0 && (
						<>
							<p className="wrap-form-title">
								{formatterText('p.service.type.associates', 'Tipos de servicio asociados')}
							</p>
							<section className="form-responsive-container-information">
								<Table
									titles={titlesTableSubCategories}
									data={dataTable}
									type="typeCategoryService"
									handleOpen={handleOpen}
									labelTable={formatterText('p.service.type.associates.footer')}
									canSearch={true}
									isEdit={true}
									categories={categories}
									getDataMiniTable={getDataFromMinimalTable}
								/>
							</section>
						</>
					)}
					<p className="wrap-form-title">
						{formatterText('table.title.service.type', '+ Agregar tipo de servicio')}
					</p>
					<section className="wrapForm">
						<label className="wrapForm__label">
							<h3 className="p-styles">
								{formatterText('p.name.type.service', 'Nombre tipo de servicio')}
							</h3>
							<input
								className="input-primary width-60"
								type="text"
								name="nombre"
								value={currentItemMinimal.nombre}
								onChange={handleChangeSubCategory}
								placeholder={formatterText('p.name.type.service', 'Nombre tipo de servicio')}
								maxLength="100"
							/>
						</label>
						<label className="wrapForm__label">
							<h3 className="p-styles">{formatterText('table.title.code', 'Código')}</h3>
							<input
								className="input-primary width-50"
								type="text"
								name="codigo"
								value={currentItemMinimal.codigo.toLowerCase()}
								onChange={handleChangeSubCategoryCode}
								placeholder={formatterText(
									'input.placeholder.enter.up.characters',
									'Ingresa hasta 45 caracteres'
								)}
								maxLength="45"
								onBlur={handleErrorCode}
							/>
						</label>
						<label className="wrapForm__label">
							<h3 className="p-styles">
								{formatterText('table.title.description', 'Descripción')}
							</h3>
							<textarea
								className="input-primary-textarea width-80"
								type="text"
								name="descripcion"
								value={currentItemMinimal.descripcion}
								onChange={handlerTextDescriptionSubcategory}
								placeholder={formatterText('p.description.subcategory', 'Subcategory description')}
								maxLength="200"
							/>
						</label>
						<label className="wrapForm__label">
							<h3 className="p-styles">
								{formatterText('p.product.category', 'Categoria de productos')}
							</h3>
							<SelectorMulti
								name="categoryType"
								id="categoryType"
								data={productCategories}
								dataValue={selectValues}
								setterFunction={handleSelectChange}
							/>
						</label>
					</section>
					<input
						onClick={handleAddItemMinimal}
						type="button"
						className="btn-primary btn-primary-center"
						value={formatterText('btn.assign.service.type', 'Asignar tipo de servicio')}
					/>
				</section>
				<section className="form-responsive-container-buttons">
					<button type="submit" className="btn-primary">
						{formatterText('btn.save.changes', 'Guardar cambios')}
					</button>
					<button className="input-cancel" onClick={() => navigate(paths.services)}>
						{formatterText('btn.cancel', 'Cancelar')}
					</button>
				</section>
			</form>

			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box sx={{ ...style, width: '90%', height: '80%' }}>
					<UpdateModalItem
						productCategories={productCategories}
						onClose={handleClose}
						loadingCategories={loading}
						setLoadingCategories={setLoading}
						selectValues={selectValues}
						setSelectValues={setSelectValues}
						getDataFromMinimalTable={getDataFromMinimalTable}
						id={id}
					/>
				</Box>
			</Modal>
		</>
	);
}
