import { useForm } from '@formiz/core';
import { FilterActive } from 'common/validators/FilterActives';
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';
import Cookie from 'js-cookie';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import paths from 'services/paths';
import Swal from 'sweetalert2';

export default function UpdateProviderViewModel() {
	const navigate = useNavigate();
	const { fetchData, RequestUseCase, endpoints } = useAxios();
	const { resourceNotFound, formatterText } = useLangv2();

	const myForm = useForm();
	const [isLoading] = useState(false);
	const [loadingData, setLoadingData] = useState(false);
	const [selectData, setSelectData] = useState({});
	const [archivos, setCurrentFiles] = useState([]);
	const [currentProvider, setCurrentProvider] = useState(null);
	const { id } = useParams();

	useEffect(() => {
		getData();
		getProviderById(id);
	}, []);

	const getData = () => {
		setLoadingData(true);

		const promesas = [
			RequestUseCase.get(endpoints.institutions.getAllTypeDocument),
			RequestUseCase.get(endpoints.providerType.getAllProviderTypes),
			RequestUseCase.get(endpoints.zones.getAllMunicipalities)
		];

		Promise.all(promesas).then((values) => {
			setLoadingData(false);
			setSelectData({
				...selectData,
				documentTypes: values[0],
				genres: FilterActive(values[1]),
				municipalities: FilterActive(values[2])
			});
		});
	};

	const handleSubmit = (values) => {
		const data = {
			idProveedor: currentProvider.idProveedor,
			idTipoProveedor: {
				idTipoProveedor: values.idTipoProveedor.value
			},
			idTipoDocumento: {
				idTipoDocumento: values.idTipoDocumento.value
			},
			identificacion: values.identificacion,
			nombreRazonSocial: values.nombreRazonSocial,
			idMunicipio: {
				idMunicipio: values.idMunicipio.value
			},
			estado: currentProvider.estado, //TODO: actualizar estado
			direccion: values.direccion,
			telefono: values.telefono,
			telefonoAlterno: values.telefonoAlterno ? values.telefonoAlterno : '',
			sitioWeb: values.sitioWeb,
			correo: values.correo,
			fechaCreacion: currentProvider.fechaCreacion,
			fechaModificacion: null, //TODO: actualizar fechaModificacion comprobar si cambia en el backend
			usuarioCreacion: currentProvider.usuarioCreacion, //TODO: revisar si se le manda el usuario de creacion con el cookie en crear
			usuarioModificacion: Cookie.get('idUsuario')
		};

		handleSaveProvider(data);
	};

	const handleSaveProvider = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.update.general', 'Se va a editar el registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve) => {
					RequestUseCase.put(endpoints.providers.updateProvider, data)
						.then(() => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.updated.general',
										'El registro se ha actualizado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => navigate(paths.providers)
								})
							);
						})
						.catch((err) => {
							if (err.response?.data?.message) {
								HandleOnError(formatterText(err.response?.data?.message));
							} else {
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
						});
				});
			}
		});
	};
	//get data from api
	const getProviderById = (id) => {
		RequestUseCase.get(endpoints.providers.getProviderById(id))
			.then((res) => {
				if (res !== null) {
					const provider = res;
					setCurrentProvider(provider);
					RequestUseCase.get(endpoints.UploadFiles.findArchivosById({
							idOrigen: provider.idProveedor,
							idTipoOrigenArchivo: 9
						}),
					).then((response) => {
						const files = response.map((file) => {
							return {
								name: file,
								url: file
							};
						});
						setCurrentFiles(files);
					});
				} else {
					resourceNotFound();
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const uploadNewFile = (file) => {
		// return new Promise((resolve, reject) => {
		fetchData({
			url: endpoints.UploadFiles.save,
			method: 'post',
			body: {
				idOrigen: currentProvider.idProveedor,
				idTipoOrigenArchivo: 9,
				archivos: [file]
			}
		})
			.then(() => {
				// resolve(
				CustomAlert('confirm_msg', {
					icon: 'success',
					title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
					text: formatterText(
						'alert.title.confirm.add.files',
						'Se agregó el adjunto correctamente'
					),
					confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
					allowOutsideClick: false,
					executeFunction: () => getProviderById(id)
				});
				// );
			})
			.catch(() => {
				// reject(err);
			});
		// });
	};

	return {
		formatterText,
		isLoading,
		loadingData,
		selectData,
		archivos,
		setCurrentFiles,
		currentProvider,
		handleSubmit,
		myForm,
		uploadNewFile,
		navigate
	};
}
