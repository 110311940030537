import CustomAlert from 'components/CustomAlert';
import { AppWrapper } from 'context/AppContext';
import { PermissionWrapper } from 'context/PermissionContext';
import { SearchWrapper } from 'context/SearchContext';
import { TableMinimalWrapper } from 'context/TableMinimalContext';
import { useAuth } from 'hooks/useAuth';
import 'moment/locale/en-gb.js'; // import english language
import 'moment/locale/es.js'; // import spanish language
import 'moment/locale/fr.js'; // import french language
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useIdleTimer } from 'react-idle-timer';
import 'styles/App.css';
import 'styles/userTable.css';
import 'styles/utils.css';
import { LangProvider } from './context/langContext';
import Routes from './routes/Routes';

function SnackBarCloseButton(props) {
	const { closeSnackbar } = useSnackbar();
	return (
		<MdClose
			size={25}
			color="white"
			cursor="pointer"
			onClick={() => closeSnackbar(props.snackbarKey)}
		/>
	);
}

export default function App() {
	const timeout = process.env.REACT_APP_INACTIVITY_TIME_MS;
	const [isIdle, setIsIdle] = useState(false);
	const auth = useAuth();

	const handleOnActive = () => setIsIdle(!isIdle);

	const handleOnIdle = () => {
		const locationLogin = new URL(window.location.href).hash.includes('login');
		if (!locationLogin) {
			handleOnActive();
			// Close session
			auth.logout();
			CustomAlert('short_msg', {
				icon: 'warning',
				title: 'Atención',
				text: 'Su sesión se ha cerrado por inactividad, vuelve a iniciar sesión.'
			});
		}
	};

	useIdleTimer({
		timeout,
		onActive: handleOnActive,
		onIdle: handleOnIdle
	});

	return (
		<AppWrapper>
			<LangProvider>
				<TableMinimalWrapper>
					<SnackbarProvider
						action={(key) => <SnackBarCloseButton snackbarKey={key} />}
						maxSnack={3}
					>
						<SearchWrapper>
							<PermissionWrapper>
								<Routes />
							</PermissionWrapper>
						</SearchWrapper>
					</SnackbarProvider>
				</TableMinimalWrapper>
			</LangProvider>
		</AppWrapper>
	);
}
