/**
 * @returns {object}
 */
export const getFormikInitialValues = () => {
	return {
		active: true,
		nombre: '',
		observaciones: '',
		permissions: []
	};
};
