import PropTypes from 'prop-types';

export const UpdateCostAssociateProductProps = {
	costoTabla: PropTypes.func,
	data: PropTypes.func,
	dataProviders: PropTypes.func,
	dataUnits: PropTypes.func,
	getDataMiniTable: PropTypes.func,
	onClose: PropTypes.func
};
