import useAxios from 'hooks/useAxios';
import React, { useEffect, useState } from 'react';
// Import Components
import ProductCategoryTable from 'screens/product/productCategory/ProductCategoryTable';
import ProductTable from 'screens/product/products/ProductTable';
// Import Context
import { usePermissionContext } from 'context/PermissionContext';
import { useSeachContext } from 'context/SearchContext';
// Import Hooks
import useLangv2 from 'hooks/useLangv2';
// Import Paths
import paths from 'services/paths';
// Import services
import endpoints from 'services/api';
//Import Constants
import { MODULES_NAME } from 'common/validators/PermissionsConstants';

export default function ProductsTableViewModel() {
	const { RequestUseCase } = useAxios();

	// use Hook of language v2
	const { formatterText } = useLangv2();

	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();
	const permissionsAccessGaranted = () => {
		permissionsAccess(MODULES_NAME.products.principal);
	};

	const { setSearch, searchResults = [] } = useSeachContext();

	// Index Tab
	const [indexTabProduct] = useState(
		parseInt(JSON.parse(localStorage.getItem('indexTabProduct')) || 0)
	);

	useEffect(() => {
		setSelectedTab(tabs[indexTabProduct || 0]);
		permissionsAccess(selectedTab.idPermiso);
	}, []);

	useEffect(() => {
		const selectedTab = tabs[parseInt(JSON.parse(localStorage.getItem('indexTabProduct'))) || 0];
		setSelectedTab(selectedTab);
	}, [permittedActions]);

	useEffect(() => {
		permissionsAccessGaranted();
	}, [permissions]);

	useEffect(() => {
		const currentUrl = window.location.href;
		const products = currentUrl.includes('/productos');
		if (!products) setSelectedTab(0);
	}, [indexTabProduct]);

	const tabs = [
		{
			id: paths.productCategory,
			nombre: formatterText('table.name.search.productCategory', 'Categoría de producto'),
			route: paths.createProductCategory,
			nombreLink: formatterText(
				'header.title.product.category.create',
				'Crear categoría de producto'
			),
			componente: <ProductCategoryTable permisos={permittedActions} />,
			exportExcel: null,
			uploadExcel: null,
			idPermiso: MODULES_NAME.products.categories
		},
		{
			id: paths.products,
			nombre: formatterText('nav.parent.3.child.option.3', 'Productos'),
			route: paths.createProduct,
			nombreLink: formatterText('header.title.product.create.product', 'Crear producto'),
			componente: <ProductTable permisos={permittedActions} />,
			exportExcel: permittedActions.exportar,
			uploadExcel: permittedActions.importar,
			idPermiso: MODULES_NAME.products.products
		}
	];

	// Select tab
	const [selectedTab, setSelectedTab] = useState(tabs[indexTabProduct || 0]);

	const auxemaple = (body) => {
		const ItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.postFormData(endpoints.costProductProvider.uploadFileCostProvider, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return ItemPromise;
	};

	return {
		auxemaple,
		formatterText,
		indexTabProduct,
		permissionsAccess,
		permittedActions,
		searchResults,
		selectedTab,
		setSearch,
		setSelectedTab,
		tabs
	};
}
