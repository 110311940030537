export const parseDataToSubmit = (values) => {
	const dataToSend = {
		...values,
		host: values.host.trim(),
		usuario: values.usuario.trim(),
		puerto: values.puerto,
		clave: values.clave.trim(),
		smtpAuth: values.smtpAuth.toString(),
		smtpSecure: values.smtpSecure.toString()
	};

	return dataToSend;
};
