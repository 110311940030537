import { useForm } from '@formiz/core';
import HandleOnError from 'common/validators/HandleOnError';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import CustomAlert from 'components/CustomAlert';
import { AppContext } from 'context/AppContext';
import { TableMinimalContext } from 'context/TableMinimalContext';
import dateFormat from 'dateformat';
import useAxios from 'hooks/useAxios';
import usePermission from 'hooks/usePermission';
import { isNull } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import { useNavigate } from 'react-router-dom';
import endpoints from 'services/api';
import Swal from 'sweetalert2';
import useLangv2 from '../../../hooks/useLangv2';
import hours from '../hours';
import hoursWithValue from '../hoursWithValue';

export default function CreateServiceViewModel() {
	// Manage axios requests
	const { fetchData, COOKIE_USER, RequestUseCase } = useAxios();

	const navigate = useNavigate();
	// getData from the client or exteralUser select
	const {
		clientSelected,
		userSelected,
		externalUserSelected,
		setExternalUserSelected,
		billingUserSelected,
		setBillingUserSelected,
		setClientSelected,
		setUserSelected
	} = useContext(AppContext);
	// useLanguage
	const { formatterText, errorProcess, customSB } = useLangv2();
	// Value that select use
	const [selectedSearch, setSearchSelected] = useState([
		{
			clients: [],
			users: [],
			typeService: [],
			communicationMedia: [],
			timeMeeting: [],
			communicationChannel: []
		}
	]);

	const [addressAux, setAddressAux] = useState([
		{
			value: 0,
			label: 'Nonthing'
		}
	]);

	const [listPriceAux, setListAux] = useState([
		{
			value: 0,
			label: 'Nonthing'
		}
	]);

	const myForm = useForm();

	// checkouts states
	const [checkoutValidations, setCheckoutValidations] = useState({
		equalsExp: false,
		specialService: false
	});

	// Stare of date picker
	const [time, setTime] = useState(new Date());

	// State loader
	const [loadingValidation, setLoadingValidation] = useState(false);

	// Modal config
	const [openClient, setOpenClient] = useState(false);
	const [openExternalUser, setOpenExternalUser] = useState(false);
	const [openAddress, setOpenAddress] = useState(false);
	const [openSearch, setOpenSearch] = useState(false);
	const [openSystemUser, setOpenSystemUser] = useState(false);
	const [openBillingUser, setOpenBillingUser] = useState(false);

	const [show, setShow] = useState(false);
	const fileInput = React.useRef(null);
	const reader = new FileReader();
	const [typeGenerate, setTypeGenerate] = useState(true);
	const [filesOnCreate, setFilesOnCreate] = useState([]);

	const onOpenClient = () => setOpenClient(true);
	const onCloseClient = () => setOpenClient(false);

	const onOpenExternalUser = () => setOpenExternalUser(true);
	const onCloseExternalUser = () => setOpenExternalUser(false);
	const onOpenSystemUser = () => setOpenSystemUser(true);
	const onCloseSystemUser = () => setOpenSystemUser(false);

	const onOpenAddress = () => setOpenAddress(true);
	const onCloseAddress = () => setOpenAddress(false);
	const onToggleBillingUser = () => setOpenBillingUser(!openBillingUser);

	const onOpenSearch = () => setOpenSearch(true);
	const onCloseSearch = () => setOpenSearch(false);

	const [access, setAccess] = useState([]);
	const [searchAutomatic, setSearchAutomatic] = useState(false);
	const [manualSearch, setManualSearch] = useState(false);
	const { setSelectedTable } = useContext(TableMinimalContext);
	const { permissionPerModule, permissions } = usePermission();

	useEffect(() => {
		validationData();
	}, [access]);

	useEffect(() => {
		permissionsAccess();
	}, [permissions]);

	const permissionsAccess = () => {
		const flterAcces = permissionPerModule(17);
		setAccess(flterAcces);
	};

	const validationData = () => {
		access.forEach((element) => {
			switch (element.nombrePermiso) {
				case 'BUSQUEDA_MANUAL':
					setManualSearch(true);
					break;
				case 'BUSQUEDA_AUTOMATICA':
					setSearchAutomatic(true);
					break;
				default:
					break;
			}
		});
	};

	const now = new Date();
	// Helps to save the ids and labels of the selects data
	const [selectIds, setSelectIds] = useState({
		idGeneradorCliente: {
			value: 0,
			label: ''
		},
		idUsuario: {
			value: 0,
			label: ''
		},
		idDireccion: '',
		idListaPrecios: '',
		idTipoServicio: {
			value: 0,
			label: ''
		},
		idMedioComunicacion: {
			value: 0,
			label: ''
		},
		idHoraCita: {
			value: 0,
			label: ''
		},
		idCanalComunicacion: {
			value: 0,
			label: ''
		}
	});

	// Call services to fill in the selects
	useEffect(() => {
		// news
		getAllClientsServices();
		getAllUsersServices();
		getAllTypeServicesServices();
		getAllCommunicationMediaServices();
		getAllCommunicationChannelServices();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// display the address of the client, gen user or external user
		showAddress();
		getListPrice();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clientSelected.id, externalUserSelected.id, userSelected.id]);

	const showAddress = (addressCreated = null) => {
		// OPTION 1: GUSER where idGeneradorCliente are null
		if (userSelected.id !== null) {
			// OPTION 2:HAVE USER SYSTEM Too
			if (externalUserSelected.id !== null) {
				// Select genUser and Externarl User
				getAddressExternalUser(addressCreated);
			} else {
				// Only select gen system user Option 1 only
				getAddressSystemUser(addressCreated);
			}
		}
		// OPTION 3: GCLIENT + GUSER where idGeneradorUsuario is null
		else if (clientSelected.id !== null) {
			// OPTION 4:HAVE USER SYSTEM Too
			if (externalUserSelected.id !== null) {
				// Select geClient and Externarl User
				getAddressExternalUser(addressCreated);
			} else {
				// Option 3: Only GenClient
				getAddressesClient(addressCreated);
			}
		} else {
			setAddressAux([]);
		}
	};

	const getListPrice = () => {
		// OPTION 1: GUSER where idGeneradorCliente are null
		if (userSelected.id !== null) {
			// OPTION 2:HAVE USER SYSTEM Too
			if (externalUserSelected.id !== null) {
				// Select genUser and Externarl User
				getListPriceExternalUser();
			} else {
				// Only select gen system user Option 1 only
				getListPriceSystemUser();
			}
		}
		// OPTION 3: GCLIENT + GUSER where idGeneradorUsuario is null
		else if (clientSelected.id !== null) {
			// OPTION 4:HAVE USER SYSTEM Too
			if (externalUserSelected.id !== null) {
				// Select geClient and Externarl User
				getListPriceExternalUser();
			} else {
				// Option 3: Only GenClient

				getListPriceClient();
			}
		} else {
			setListAux([]);
		}
	};

	const getListPriceExternalUser = () => {
		RequestUseCase.get(
			endpoints.listPriceClient.getListPriceByIdUsuarioServicio(externalUserSelected.id)
		).then((data) => {
			// create new array
			const newArray = [];
			let principalId = '';
			// iterate response and get only the values that are active
			data.forEach((item) => {
				newArray.push({
					value: item.idListaPreciosActividad.idListaPreciosActividad, //idListaPreciosActividad
					label: item.idListaPreciosActividad.nombre
				});
				//if item.principal === 1 save the id
				if (item.principal === 1) {
					principalId = item.idListaPreciosActividad.idListaPreciosActividad;
				}
			});
			setListAux(newArray);
			setSelectIds({
				...selectIds,
				idListaPrecios: principalId
			});
		});
	};

	const getListPriceSystemUser = () => {
		RequestUseCase.get(
			endpoints.listPriceClient.getListPriceByIdUsuarioServicio(userSelected.id)
		).then((data) => {
			// create new array
			const newArray = [];
			let principalId = '';
			// iterate response and get only the values that are active
			data.forEach((item) => {
				newArray.push({
					value: item.idListaPreciosActividad.idListaPreciosActividad, //idListaPreciosActividad
					label: item.idListaPreciosActividad.nombre
				});
				//if item.principal === 1 save the id
				if (item.principal === 1) {
					principalId = item.idListaPreciosActividad.idListaPreciosActividad;
				}
			});
			setListAux(newArray);
			setSelectIds({
				...selectIds,
				idListaPrecios: principalId
			});
		});
	};

	const getListPriceClient = () => {
		RequestUseCase.get(endpoints.listPriceClient.getListPriceById(clientSelected.id)).then(
			(data) => {
				// create new array
				const newArray = [];
				let principalId = '';
				// iterate response and get only the values that are active
				data.forEach((item) => {
					newArray.push({
						value: item.idListaPreciosActividad.idListaPreciosActividad, //idListaPreciosActividad
						label: item.idListaPreciosActividad.nombre
					});
					//if item.principal === 1 save the id
					if (item.principal === 1) {
						principalId = item.idListaPreciosActividad.idListaPreciosActividad;
					}
				});
				setListAux(newArray);
				setSelectIds({
					...selectIds,
					idListaPrecios: principalId
				});
			}
		);
	};

	// getAllExternalUser
	const getAddressExternalUser = (address = null) => {
		RequestUseCase.get(
			endpoints.services.getAllAddressExternalUsersById(externalUserSelected.id)
		).then((data) => {
			// create new array
			const newArray = [];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						value: `${item.idDireccion.idDireccion}-${item.idDireccion.idSector.idSector}-${item.idDireccion.idSector.idMunicipio}`,
						label: `${item.idDireccion.direccion} - ${item.idDireccion.datosAdicionales}`
					});
				}
			});
			setAddressAux(newArray);
			// set the values of the select
			if (address !== null) {
				setSelectIds({
					...selectIds,
					idDireccion: `${address.idDireccion.idDireccion}-${address.idDireccion.idSector.idSector}-${address.idDireccion.idSector.idMunicipio}`
				});
			}
		});
	};

	// get All Address System User
	const getAddressSystemUser = (address = null) => {
		RequestUseCase.get(endpoints.services.getAllAddressExternalUsersById(userSelected.id)).then(
			(data) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				data.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: `${item.idDireccion.idDireccion}-${item.idDireccion.idSector.idSector}-${item.idDireccion.idSector.idMunicipio}`,
							label: `${item.idDireccion.direccion} - ${item.idDireccion.datosAdicionales}`
						});
					}
				});
				setAddressAux(newArray);
				// set the values of the select
				if (address !== null) {
					setSelectIds({
						...selectIds,
						idDireccion: `${address.idDireccion.idDireccion}-${address.idDireccion.idSector.idSector}-${address.idDireccion.idSector.idMunicipio}`
					});
				}
			}
		);
	};

	const validateFields = () => {
		if (selectIds.idTipoServicio.value == 0) {
			return customSB('warning', 'snackbar.error.validate.serviceType');
		}
		if (selectIds.idDireccion === '') {
			return customSB('warning', 'snackbar.error.validate.address');
		}
		if (selectIds.idCanalComunicacion.value === 0) {
			return customSB('warning', 'snackbar.error.validate.channel');
		}
		if (selectIds.idMedioComunicacion.value === 0) {
			return customSB('warning', 'snackbar.error.validate.media');
		}
		if (eventData.idHoraCita === '') {
			return customSB('warning', 'snackbar.error.validate.service.hour');
		}
		return true;
	};

	// Get the address for the SelectorGrid
	const getAddressesClient = (address = null) => {
		RequestUseCase.get(endpoints.branchOffices.getSucursalClientById(clientSelected.id)).then(
			(data) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				data.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: `${item.idDireccion.idDireccion}-${item.idDireccion.idSector.idSector}-${item.idDireccion.idSector.idMunicipio.idMunicipio}`,
							label: `${item.idDireccion.direccion} - ${item.idDireccion.datosAdicionales}`
						});
					}
				});
				// set the values of the select
				setAddressAux(newArray);
				if (address !== null) {
					setSelectIds({
						...selectIds,
						idDireccion: `${address.idDireccion.idDireccion}-${address.idDireccion.idSector.idSector}-${address.idDireccion.idSector.idMunicipio}`
					});
				}
			}
		);
	};

	const handleSubmit = () => {
		const valid = validateFields();
		if (valid) {
			let especial = 0;

			if (clientSelected.esVip === 1) {
				especial = clientSelected.esVip === 1 ? 1 : 0;
			} else if (userSelected.esVip === 1) {
				especial = userSelected.esVip === 1 ? 1 : 0;
			}

			const { idDireccion: direction = '', idTipoServicio: serviceType = {} } = selectIds;
			const [directionId = ''] = direction.split('-');
			const serviceTypeId = Number(serviceType.value.split('-')[0] || '0');

			let data = {
				idServicio: null,
				idDireccion: {
					idDireccion: Number(directionId)
				},

				idTipoServicio: {
					idTipoServicio: serviceTypeId
				},
				descripcion: formItem.descripcion ? formItem.descripcion : '',
				idMedioComunicacion: {
					idMedioComunicacion: selectIds.idMedioComunicacion.value
				},
				idCanalComunicacion: {
					idCanalComunicacion: selectIds.idCanalComunicacion.value
				},
				fechaCita: `${eventData.fechaCita}T10:00:00.000Z`,
				idHoraCita: {
					idHora: eventData.idHoraCita
				},
				expediente: checkoutValidations.equalsExp ? null : formItem.expediente,
				especial,
				idEstadoServicio: {
					idEstadoServicio: 1
				},
				usuarioCreacion: COOKIE_USER,
				idServicioPadre: null
			};

			// OPTION 1: GUSER where idGeneradorCliente are null
			if (userSelected.id !== null) {
				// OPTION 2:HAVE USER SYSTEM Too
				if (externalUserSelected.id !== null) {
					// Select genUser and Externarl User
					data = {
						...data,
						idGeneradorCliente: null,
						idGeneradorUsuario: {
							idUsuarioServicio: Number(userSelected.id)
						},
						idUsuario: {
							idUsuarioServicio: parseInt(externalUserSelected.id)
						}
					};
				} else {
					// Only select gen system user Option 1 only
					data = {
						...data,
						idGeneradorCliente: null,
						idGeneradorUsuario: {
							idUsuarioServicio: parseInt(userSelected.id)
						},
						idUsuario: null
					};
				}
			}
			// OPTION 3: GCLIENT + GUSER where idGeneradorUsuario is null
			if (clientSelected.id !== null) {
				// OPTION 4:HAVE USER SYSTEM Too
				if (externalUserSelected.id !== null) {
					// Select geClient and Externarl User
					data = {
						...data,
						idGeneradorCliente: {
							idCliente: parseInt(clientSelected.id)
						},
						idGeneradorUsuario: null,
						idUsuario: {
							idUsuarioServicio: parseInt(externalUserSelected.id)
						}
					};
				} else {
					// Option 3: Only GenClient
					data = {
						...data,
						idGeneradorCliente: {
							idCliente: clientSelected.id
						},
						idGeneradorUsuario: null,
						idUsuario: null
					};
				}
			} else {
				alert('Error to create service: notify admin');
			}
			generateDateEnd(data);
		}
	};

	// generate the end date of the service for validate the agenda
	const generateDateEnd = (data) => {
		setLoadingValidation(true);
		customSB(
			'warning',
			'snackbar.warning.validate.tech.exist',
			'Se esta validando que el servicio cumpla con los requisitos para ser creado, por favor espere...'
		);
		RequestUseCase.post(endpoints.services.generateEndDate, data)
			.then((response) => {
				const { idDireccion: direction = '', idTipoServicio: serviceType = {} } = selectIds;
				const [address = '', sector = '', town = ''] = direction.split('-');
				const [serviceTypeId = '', serviceTypeCategory = ''] = (serviceType?.value || '').split(
					'-'
				);

				const ADDRESS = parseInt(address);
				const SECTOR = parseInt(sector);
				const MUNICIPALITY = parseInt(town);
				const TYPE_SERVICE = parseInt(serviceTypeId);
				const CATEGORY_SERVICE = parseInt(serviceTypeCategory);

				const idHora = hoursWithValue.find((item) => item.idHora === eventData.idHoraCita);

				const DATA_ORIGINAL = data;

				const DATA_VALIDATED = {
					...data,
					idDireccion: {
						idDireccion: ADDRESS,
						idSector: {
							idSector: SECTOR,
							idMunicipio: {
								idMunicipio: MUNICIPALITY
							}
						}
					},
					idTipoServicio: {
						idTipoServicio: TYPE_SERVICE,
						idCategoriaServicio: {
							idCategoriaServicio: CATEGORY_SERVICE
						}
					},
					idHoraCita: {
						idHora: eventData.idHoraCita,
						valor: idHora.valor
					},
					fechaCitaFin: response.fechaCitaFin.split('T')[0],
					idHoraCitaFin: {
						idHora: response.idHoraCitaFin.idHora,
						valor: response.idHoraCitaFin.valor
					}
				};

				validateServiceAgendaEvent(DATA_VALIDATED, DATA_ORIGINAL);
			})
			.catch((error) => {
				errorProcess();
				setLoadingValidation(false);
				console.error(error);
			});
	};

	// Validate if the agenda have horary available
	const validateServiceAgendaEvent = (DATA, DATA_ORIGINAL) => {
		RequestUseCase.post(endpoints.services.validateCreateService, DATA)
			.then((response) => {
				if (response) {
					createItem(DATA_ORIGINAL);
					setLoadingValidation(false);
					customSB(
						'success',
						'snackbar.success.validate.tech.exist',
						'Se encontró al menos un técnico disponible para este servicio, puede continuar con el proceso.'
					);
				} else {
					setLoadingValidation(false);
					customSB(
						'error',
						'snackbar.error.validate.tech.exist',
						'No se encontró un técnico disponible para este servicio, seleccione otro horario o fecha.'
					);
				}
			})
			.catch((error) => {
				errorProcess();
				setLoadingValidation(false);
				console.error(error);
			});
	};

	// Create new service
	const createItem = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					// this service create the item
					RequestUseCase.post(endpoints.services.addService, {
						...data,
						idListaPreciosActividad: parseInt(selectIds.idListaPrecios),
						idClienteFactura: null
					})
						.then((response) => {
							uploadReports(response.idServicio);
							saveBillingUser(response.idServicio);
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.created.general',
										'El registro se ha creado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => {
										setIdService(response.idServicio);
										onOpenSearch();
										setExternalUserSelected({
											nombre: 'Seleccione un usuario (Opcional)',
											id: null
										});

										setClientSelected({
											nombre: formatterText('input.placeholder.select.client'),
											id: null,
											esVip: 2
										});

										setUserSelected({
											nombre: 'Seleccione un usuario generador',
											id: null,
											esVip: 2
										});
									}
								})
							);
						})
						.catch((error) => {
							console.error('Error al crear el item:', error);
							reject(HandleOnError(formatterText(error)));
							setLoadingValidation(false);
						});
				});
			}
		});
	};

	// Get the clients for the SelectorGrid
	const getAllClientsServices = () => {
		RequestUseCase.get(endpoints.clients.getAllClients).then((data) => {
			// create new array
			const newArray = [
				{
					value: -1,
					label: 'Ninguno'
				}
			];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						value: item.idCliente,
						label: `${item.nombreRazonSocial} - ${item.identificacion}`
					});
				}
			});
			// set the values of the select
			setSearchSelected((prev) => ({
				...prev,
				clients: newArray
			}));
		});
	};

	// Get the users for the SelectorGrid
	const getAllUsersServices = () => {
		RequestUseCase.get(endpoints.services.getAllExternalUsers).then((data) => {
			// create new array
			const newArray = [
				{
					value: -1,
					label: 'Ninguno'
				}
			];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						value: item.idUsuarioServicio,
						label: `${item.primerNombre} ${item.primerApellido} - ${item.telefono}`
					});
				}
			});
			// set the values of the select
			setSearchSelected((prev) => ({
				...prev,
				users: newArray
			}));
		});
	};

	// Get the typeServices for the SelectorGrid
	const getAllTypeServicesServices = () => {
		RequestUseCase.get(endpoints.typeService.getAllTypeService).then((data) => {
			// create new array
			const newArray = [];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						value: `${item.idTipoServicio}-${item.idCategoriaServicio.idCategoriaServicio}`,
						label: `${item.nombre} - ${item.idCategoriaServicio.nombre}`
					});
				}
			});

			// set the values of the select
			setSearchSelected((prev) => ({
				...prev,
				typeService: newArray
			}));
		});
	};

	// Get the communicationMediaServices for the SelectorGrid
	const getAllCommunicationMediaServices = () => {
		RequestUseCase.get(endpoints.services.getAllMedia).then((data) => {
			// create new array
			const newArray = [];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						value: item.idMedioComunicacion,
						label: `${item.nombre} - ${item.codigo}`
					});
				}
			});
			// set the values of the select
			setSearchSelected((prev) => ({
				...prev,
				communicationMedia: newArray
			}));
		});
	};

	// Get the communicationChannel for the SelectorGrid
	const getAllCommunicationChannelServices = () => {
		RequestUseCase.get(endpoints.services.getAllChannels).then((data) => {
			// create new array
			const newArray = [];
			// iterate response and get only the values that are active
			data.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						value: item.idCanalComunicacion,
						label: `${item.nombre} - ${item.codigo}`
					});
				}
			});
			// set the values of the select
			setSearchSelected((prev) => ({
				...prev,
				communicationChannel: newArray
			}));
		});
	};
	const saveBillingUser = (idServ) => {
		if (!isNull(billingUserSelected.id)) {
			const data = {
				idServicio: {
					idServicio: idServ
				},
				idCliente: {
					idCliente: billingUserSelected.id
				},
				usuarioCreacion: COOKIE_USER
			};
			RequestUseCase.post(endpoints.services.saveBillingUser, data).then(() => {
				setBillingUserSelected({
					nombre: 'Seleccione un usuario (Opcional)',
					id: null
				});
			});
		}
	};

	const handleDate = (e) => {
		if (e.target) {
			setEventData({
				...eventData,
				[e.target.name]: e.target.value
			});
		} else {
			setTime(e);
			const HOUR_ID = searchHour(dateFormat(e, 'HH:MM'));
			setEventData({
				...eventData,
				idHoraCita: HOUR_ID
			});
		}
	};

	const searchHour = (hour) => {
		// search in hours array the idHora that matches with the hour
		const idHora = hours.find((item) => item.hora === hour);
		return idHora.idHora;
	};

	const [eventData, setEventData] = useState({
		fechaCita: '',
		idHoraCita: ''
	});

	const [formItem, setFormItem] = useState([
		{
			descripcion: ''
		}
	]);

	const [idService, setIdService] = useState(71);

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formItem, setFormItem);
	};

	const uploadFromCreate = (e) => {
		e.preventDefault();
		const selectedFile = e.target.files[0];
		if (selectedFile.size > 5000000) {
			HandleOnError(formatterText('id.error.file.large'));
			return;
		} // png, jpg, tiff
		if (
			selectedFile.type !== 'image/jpg' &&
			selectedFile.type !== 'image/png' &&
			selectedFile.type !== 'image/jpeg' &&
			selectedFile.type !== 'image/tiff' &&
			selectedFile.type !== 'video/mp4' &&
			selectedFile.type !== 'application/pdf'
		) {
			HandleOnError(formatterText('id.error.file.no.valid'));
			return;
		}

		if (filesOnCreate.length >= 3) {
			HandleOnError(formatterText('id.error.three.files'));
			return;
		}

		reader.readAsDataURL(selectedFile);
		reader.onload = () => {
			const archivo = reader.result.split('base64,')[1];
			//obtener el tipo del archivo
			const extensionArchivo =
				selectedFile.name.lastIndexOf('.') > -1
					? selectedFile.name.substring(
							selectedFile.name.lastIndexOf('.') + 1,
							selectedFile.name.length
					  )
					: '';
			const name = selectedFile.name;
			setFilesOnCreate((filesOnCreate) => [
				...filesOnCreate,
				{ name, extensionArchivo: `.${extensionArchivo}`, archivo }
			]);
		};
	};

	const deleteFile = (e, item) => {
		e.preventDefault();
		if (filesOnCreate.length === 1) {
			setFilesOnCreate([]);
		} else {
			const index = filesOnCreate.indexOf(item);
			filesOnCreate.splice(index, 1);
			setFilesOnCreate([...filesOnCreate]);
		}
	};

	const uploadReports = (id) => {
		if (filesOnCreate.length > 0) {
			const userData = JSON.parse(localStorage.getItem('userData'));
			const data = {
				idServicioReporte: null,
				idServicio: id,
				idTipoReporte: parseInt(4),
				descripcion: 'Archivos Adjuntos del servicio',
				notificacionAsesor: 0,
				publico: parseInt(1),
				fechaCreacion: new Date().toISOString(),
				usuarioCreacion: parseInt(COOKIE_USER),
				urlsArchivos: null
			};

			fetchData({ url: endpoints.reports.create, method: 'post', body: data })
				.then(async (res) => {
					await fetchData({
						url: endpoints.UploadFiles.save,
						method: 'post',
						body: {
							idOrigen: res.response.idServicioReporte,
							idTipoOrigenArchivo: 2,
							archivos: filesOnCreate
						}
					});
				})
				.catch((error) => {
					console.error('Error en la carga de archivos:', error);
					return Promise.reject(error);
				});
		}
	};

	return {
		formItem,
		setFormItem,
		selectedSearch,
		selectIds,
		setSelectIds,
		eventData,
		time,
		handleDate,
		show,
		setShow,
		myForm,
		handleSubmit,
		setSelectedTable,
		clientSelected,
		setClientSelected,
		userSelected,
		setUserSelected,
		externalUserSelected,
		setExternalUserSelected,
		billingUserSelected,
		setBillingUserSelected,
		openClient,
		onOpenClient,
		onCloseClient,
		openExternalUser,
		onOpenExternalUser,
		onCloseExternalUser,
		openBillingUser,
		onToggleBillingUser,
		openSystemUser,
		onOpenSystemUser,
		onCloseSystemUser,
		openAddress,
		onOpenAddress,
		onCloseAddress,
		openSearch,
		onCloseSearch,
		fileInput,
		filesOnCreate,
		uploadFromCreate,
		deleteFile,
		loadingValidation,
		searchAutomatic,
		manualSearch,
		idService,
		addressAux,
		listPriceAux,
		formatterText,
		getAllUsersServices,
		showAddress,
		checkoutValidations,
		setCheckoutValidations,
		now,
		setTypeGenerate,
		typeGenerate,
		navigate,
		handlerTextDescription
	};
}
