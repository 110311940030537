// Import Hooks
import useSB from 'hooks/useSB';
import { useIntl } from 'react-intl';

export const formatterText = (idLang, defaultMessage) => {
	const intl = useIntl();
	// return plaint text using idLang and a default message
	const plainText = intl.formatMessage({ id: idLang }, { defaultMessage });

	return plainText;
};

/**
 * @description Custom hook that return the string of a specific id language v2
 * @returns {object}
 */
const useLangv2 = () => {
	// use Hook of snack bar
	const handleClickSB = useSB();
	// get the intl object
	const intl = useIntl();

	// return the string of the language by the id, now we pass the default message to use in the case that the id is not
	// found
	const formatterText = (idLang, defaultMessage) => {
		// return plaint text using idLang and a default message
		const plainText = intl.formatMessage({ id: idLang }, { defaultMessage });

		return plainText;
	};

	const noFilledContent = () => {
		handleClickSB(
			'warning',
			formatterText(
				'snackbar.warning.input.no.filled',
				'Por favor llene todos los campos necesarios.'
			)
		);
	};

	const fillAtLeastOne = () => {
		handleClickSB(
			'warning',
			formatterText('snackbar.warning.input.no.fill.once', 'Por favor llene al menos un campo.')
		);
	};

	const newItemCreated = () => {
		handleClickSB(
			'success',
			formatterText('snackbar.success.create.new.item', 'Se ha creado un nuevo registro.')
		);
	};

	const sendSuccess = () => {
		handleClickSB(
			'success',
			formatterText('snackbar.success.mail.send.success', 'Mensaje enviado correctamente.')
		);
	};

	const updatedItem = () => {
		handleClickSB(
			'success',
			formatterText('snackbar.success.update.item', 'Se ha actualizado un registro correctamente.')
		);
	};

	const errorDataRecovery = () => {
		handleClickSB(
			'error',
			formatterText(
				'snackbar.error.data.failed.general',
				'Error al obtener los datos. Intentalo en otro momento.'
			)
		);
	};

	const errorProcess = () => {
		handleClickSB(
			'error',
			formatterText(
				'snackbar.error.process.failed.general',
				'Error al realizar el proceso. Intentalo en otro momento.'
			)
		);
	};

	const errorRestriction = () => {
		handleClickSB(
			'error',
			formatterText(
				'snackbar.error.restricion.failed.general',
				'Ocurrió un error al eliminar el registro debido a una restricción.'
			)
		);
	};

	const errorRelation = () => {
		handleClickSB(
			'error',
			formatterText(
				'snackbar.error.relation.failed.general',
				'Error al realizar el proceso, es posible que tenga relaciones con otros registros.'
			)
		);
	};

	const errorUsed = () => {
		handleClickSB(
			'error',
			formatterText(
				'snackbar.error.used.failed.general',
				'Es posible que el registro está siendo utilizado.'
			)
		);
	};

	const resourceNotFound = () => {
		handleClickSB(
			'error',
			formatterText('snackbar.error.resource.not.found', 'No se encontró el recurso.')
		);
	};

	const successRemoveItem = () => {
		handleClickSB(
			'success',
			formatterText('snackbar.success.delete.item', 'Se ha eliminado el registro.')
		);
	};

	const elementRepeated = () => {
		handleClickSB(
			'warning',
			formatterText('snackbar.warning.element.repeated', 'El registro ya existe.')
		);
	};

	const showPermissionDenied = () => {
		handleClickSB(
			'warning',
			formatterText(
				'snackbar.warning.permission.denied',
				'No tienes permiso para realizar esta acción.'
			)
		);
	};

	const showDownloadFile = () => {
		handleClickSB(
			'warning',
			formatterText(
				'snackbar.warning.download.file',
				'Se está descargando el archivo, espere un momento.'
			)
		);
	};

	const techAlreadyAssigned = () => {
		handleClickSB(
			'warning',
			formatterText(
				'snackbar.warning.tech.assigned',
				'El técnico ya tiene un servicio asignado o no ha sido asignado un tecnico principal'
			)
		);
	};

	const noScheduleTech = () => {
		handleClickSB(
			'error',
			formatterText(
				'snackbar.warning.tech.busy',
				'El técnico no está disponible en la fecha seleccionada'
			)
		);
	};

	const customSB = (type, idLang, defaultMessage) => {
		handleClickSB(type, formatterText(idLang, defaultMessage));
	};

	const atLeastOneQuestion = () => {
		handleClickSB(
			'error',
			formatterText(
				'snackbar.warning.answer.one.required',
				'El formulario debe tener al menos una pregunta'
			)
		);
	};

	const noAnswerRequired = () => {
		handleClickSB(
			'error',
			formatterText(
				'snackbar.warning.answer.required',
				'Al menos una respuesta del formulario debe ser requerida'
			)
		);
	};

	const showSB = (type, message) => {
		handleClickSB(type, message);
	};

	const handleRequestError = (error) => {
		if (error.response?.data?.message) {
			handleClickSB(
				'error',
				formatterText(error.response.data.message, error.response.data.message)
			);
		} else {
			errorProcess();
		}
	};

	const handleRequestWarning = (error) => {
		if (error.response?.data?.message) {
			handleClickSB(
				'warning',
				formatterText(error.response.data.message, error.response.data.message)
			);
		}
	};

	const formatDate = (d) => {
		const date = d ? new Date(d) : new Date();
		const year = date.getFullYear();
		let day = `${date.getDate()}`;
		const monthNames = [
			formatterText('id.month.january'),
			formatterText('id.month.february'),
			formatterText('id.month.march'),
			formatterText('id.month.april'),
			formatterText('id.month.may'),
			formatterText('id.month.june'),
			formatterText('id.month.july'),
			formatterText('id.month.august'),
			formatterText('id.month.september'),
			formatterText('id.month.october'),
			formatterText('id.month.november'),
			formatterText('id.month.december')
		];
		const monthString = monthNames[`${date.getMonth()}`];
		const dayNames = [
			formatterText('id.day.sunday'),
			formatterText('id.day.monday'),
			formatterText('id.day.tuesday'),
			formatterText('id.day.wednesday'),
			formatterText('id.day.thursday'),
			formatterText('id.day.friday'),
			formatterText('id.day.saturday')
		];
		const dayName = dayNames[date.getDay()];
		day = day.length < 2 ? `0${day}` : day;

		return [dayName, day, monthString, year].join(' ');
	};

	// return the function to format the text
	return {
		formatterText,
		noFilledContent,
		fillAtLeastOne,
		newItemCreated,
		updatedItem,
		errorDataRecovery,
		errorProcess,
		errorRestriction,
		errorRelation,
		errorUsed,
		resourceNotFound,
		successRemoveItem,
		elementRepeated,
		showPermissionDenied,
		showDownloadFile,
		customSB,
		showSB,
		handleRequestError,
		techAlreadyAssigned,
		noScheduleTech,
		handleRequestWarning,
		sendSuccess,
		formatDate,
		noAnswerRequired,
		atLeastOneQuestion
	};
};

export default useLangv2;
