import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import 'styles/editProfile.css';
import SendMail from '../SendMail';
import CreateMailViewModel from './CreateMail.viewModel';
import { CreateMailForm } from './components';

const style = {
	position: 'absolute',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3
};

/* este es update mail no crear */
function CreateMailComponent() {
	const { formatterText, formik, handleClose, handleOpen, navigate, open, permittedActions } =
		CreateMailViewModel();

	if (!permittedActions.consultar) {
		return null;
	}

	return (
		<>
			<CreateMailForm
				formatterText={formatterText}
				formik={formik}
				navigate={navigate}
				permittedActions={permittedActions}
			/>
			{permittedActions.consultar && (
				<section className="edit-profile-container-buttons">
					<button className="btn-primary" onClick={handleOpen}>
						<FormattedMessage id="btn.test.emails" defaultMessage="Probar correos" />
					</button>
				</section>
			)}

			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box sx={{ ...style, width: '90%' }}>
					<SendMail onClose={handleClose} />
				</Box>
			</Modal>
		</>
	);
}

export default CreateMailComponent;
