import { formatterText } from 'hooks/useLangv2';

export const uppercaseFirstLetter = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getRandomColor = () => {
	return `hsl(${360 * Math.random()}, ${25 + 70 * Math.random()}%, ${60 + 10 * Math.random()}%)`;
};

export const addOpacityToHSL = (str) => {
	return str.slice(0, str.length - 1) + ', .5' + str.slice(str.length - 1);
};

export const valueSetter = (text) => {
	let result = text;
	if (text.includes('placeholder')) {
		result = formatterText(text);
	}

	return result;
};
