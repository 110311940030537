import { useState } from 'react';
// Import Hooks
import useAxios from 'hooks/useAxios';
import { useNavigate } from 'react-router-dom';
// Import Components
// Import Models
// Import Services
import endpoints from 'services/api';
import paths from 'services/paths';

import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';
import useLangv2 from 'hooks/useLangv2';
import Swal from 'sweetalert2';
import { FormVIewModel } from './components/form/form.viewModel';

export default function CreateFileViewModel() {
	const navigate = useNavigate();
	const [selectValues, setSelectValues] = useState([]);
	// Ayuda al toggle
	const { COOKIE_USER, RequestUseCase } = useAxios();
	const { formatterText } = useLangv2();

	const selectorData = [
		{ value: null, label: formatterText('alert.title.select.file.type') },
		{ value: '.png', label: 'PNG' },
		{ value: '.jpg', label: 'JPG' },
		{ value: '.pdf', label: 'PDF' },
		{ value: '.doc', label: 'DOC' },
		{ value: '.docx', label: 'DOCX' },
		{ value: '.xls', label: 'XLS' },
		{ value: '.xlsx', label: 'XLSX' },
		{ value: '.ppt', label: 'PPT' },
		{ value: '.pptx', label: 'PPTX' },
		{ value: '.txt', label: 'TXT' },
		{ value: '.zip', label: 'ZIP' },
		{ value: '.rar', label: 'RAR' },
		{ value: '.7z', label: '7Z' },
		{ value: '.mp3', label: 'MP3' },
		{ value: '.mp4', label: 'MP4' },
		{ value: '.avi', label: 'AVI' },
		{ value: '.mov', label: 'MOV' },
		{ value: '.wmv', label: 'WMV' },
		{ value: '.flv', label: 'FLV' },
		{ value: '.mkv', label: 'MKV' },
		{ value: '.mpg', label: 'MPG' },
		{ value: '.mpeg', label: 'MPEG' },
		{ value: '.3gp', label: '3GP' },
		{ value: '.3g2', label: '3G2' },
		{ value: '.gif', label: 'GIF' },
		{ value: '.svg', label: 'SVG' }
	];

	const idCategory = [
		{ value: 1, label: formatterText('p.label.title.categoriaCliente') },
		{ value: 2, label: formatterText('p.label.title.categoriaTercero') },
		{ value: 3, label: formatterText('p.label.title.categoriaProveedor') },
		{ value: 4, label: formatterText('p.label.title.categoriaEmpleado') }
	];

	const handleChangeMulti = (val) => {
		setSelectValues(val);
	};

	// Esta función se ejecuta al hacer click en el botón de crear
	const handleSubmit = (values, actions) => {
		actions.setSubmitting(true);
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					const data = parseDataToSubmit(values);
					RequestUseCase.post(endpoints.attachedFiles.saveAttachedFile, data).then((response) => {
						relatedUsersToid(response.idTipoArchivoAdjunto, resolve, reject, values, actions);
					});
				});
			}
		}).then((result) => {
			if (result.isDismissed) {
				actions.setSubmitting(false);
			}
		});
	};

	const relatedUsersToid = (id, resolve, reject, values, actions) => {
		const promises = values.categorias.map(
			(item) =>
				new Promise((reso, reje) => {
					const data = {
						tipoArchivoAdjunto: { idTipoArchivoAdjunto: id },
						tipoUsuario: { idTipoUsuario: item },
						usuarioCreacion: COOKIE_USER,
						cantidad: values.cantidadMaxima
					};
					RequestUseCase.post(endpoints.attachedFiles.relatedUsers, data)
						.then((res) => {
							reso(res);
						})
						.catch((err) => {
							reje(err);
						});
				})
		);
		Promise.all(promises)
			.then((res) => {
				resolve(
					CustomAlert('confirm_msg', {
						icon: 'success',
						title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
						text: formatterText(
							'alert.message.confirm.created.general',
							'El registro se ha creado correctamente'
						),
						confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
						allowOutsideClick: false,
						executeFunction: () => navigate(paths.files)
					})
				);
			})
			.catch((err) => {
				reject(HandleOnError(formatterText('alert.title.associations.creation.failure')));
				console.error(err);
			})
			.finally(() => actions.setSubmitting(false));
	};

	const handleFormChage = (values) => setFormikInitialValues((prev) => ({ ...prev, ...values }));

	const { formik, formikInitialValues, parseDataToSubmit, setFormikInitialValues } = FormVIewModel({
		formatterText,
		handleChangeMulti,
		idCategory,
		onSubmit: handleSubmit,
		selectorData,
		selectValues,
		setSelectValues
	});

	return {
		formatterText,
		formik,
		formikInitialValues,
		handleChangeMulti,
		handleFormChage,
		handleSubmit,
		idCategory,
		navigate,
		parseDataToSubmit,
		selectorData,
		selectValues
	};
}
