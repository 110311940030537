import { useEffect, useState } from 'react';

// Import Libs
import Cookie from 'js-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

// Import Components
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';

// Import Contexts
import { useSeachContext } from 'context/SearchContext';

// Import Hooks
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';

// Import Paths
import paths from 'services/paths';

// Import Services
import useAxios from 'hooks/useAxios';

// Import ViewModel
import { UpsertFormViewModel } from '../UpsetRol/components';

export default function EditRolViewModel() {
	const { RequestUseCase, endpoints } = useAxios();
	const { setDataTable, setSearch } = useSeachContext();
	const { displayLoading } = useGetData();
	const navigate = useNavigate();
	const [nameRoleInput, setNameRoleInput] = useState('');
	// permisosModulos state
	const [permisosModulos, setPermisosModulos] = useState([]);
	const [toggleSelector, setToggleSelector] = useState(false);
	const [selectedPermisosModulos, setSelectedPermisosModulos] = useState([]);
	const [allPermisos, setAllPermisos] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	// use Hook of language v2
	const { formatterText, successRemoveItem } = useLangv2();

	const { id } = useParams();

	//Validaciones y llama al post
	const getAllRoles = (values, actions) => {
		actions.setSubmitting(true);
		if (nameRoleInput === values.nombre) {
			updateRoleData(values, actions);
		} else {
			RequestUseCase.get(endpoints.roles.getAllRoles)
				.then((res) => {
					const rolesNames = [];
					res.map((role) => {
						rolesNames.push(role.nombre.toLowerCase());
					});
					if (rolesNames.includes(values.nombre.toUpperCase())) {
						CustomAlert('short_msg', {
							icon: 'warning',
							title: 'Ops...',
							text: formatterText('alert.title.role.name.exists'),
							confirmButtonColor: '#FFC954'
						});
						actions.setSubmitting(true);
					} else {
						updateRoleData(values, actions);
					}
				})
				.catch((err) => console.error(err));
		}
	};

	const {
		formik,
		parseDataToFormik,
		formikInitialValues,
		setFormikInitialValues,
		parseDataToSubmit
	} = UpsertFormViewModel({
		onSubmit: getAllRoles,
		formatterText
	});

	const updateRoleData = (values, { setSubmitting }) => {
		const data = parseDataToSubmit({ ...values, fechaModificacion: null }, formatterText);

		Swal.fire({
			title: `${formatterText('question.swal.title.edit')}`,
			text: `${formatterText('question.swal.title.edit.role')}`,
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: `${formatterText('question.swal.title.edit.confirm')}`,
			allowOutsideClick: false,
			cancelButtonText: `${formatterText('question.swal.title.edit.cancel')}`,
			preConfirm: async () => {
				await RequestUseCase.put(endpoints.roles.updateRole, data)
					.then(() => {
						//Se actualizo el rol
						asociatePermissionModule();
					})
					.catch((err) => {
						HandleOnError(err.response.data.message);
						console.log(err);
					})
					.finally(() => setSubmitting(false));
			}
		}).then((result) => {
			if (result.isDismissed) {
				setSubmitting(false);
			}
		});
	};
	const [permisosAsociados, setPermisosAsociados] = useState([]);
	const getAsociatedPermissionModule = (id, dataRol) => {
		RequestUseCase.get(endpoints.roles.getAllRolPermisosModulosByRol(id))
			.then((res) => {
				RequestUseCase.get(endpoints.roles.getAllPermisosModulos)
					.then((allPermisosModulos) => {
						const formatedPermisosModulos = res.map((element) => ({
							idRolPermisoModulo: element.idRolPermisoModulo,
							idPermiso: {
								nombre: element.nombrePermiso,
								idpermiso: element.idPermiso
							},
							idModulo: {
								nombre: element.nombreModulo,
								idModulo: element.idModulo
							}
						}));

						sortData(formatedPermisosModulos);
						setPermisosAsociados(formatedPermisosModulos);
						setSelectedPermisosModulos(formatedPermisosModulos);

						const valueInfo = allPermisosModulos.filter(
							(permiso) =>
								!res.find(
									(element) =>
										element.idPermiso === permiso.idPermiso.idpermiso &&
										element.idModulo === permiso.idModulo.idModulo
								)
						);

						setPermisosModulos(valueInfo);
						setDataTable(valueInfo);
						setAllPermisos(allPermisosModulos);
						const newFormikInitialValues = parseDataToFormik({
							formikInitialValues,
							dataByServer: { ...dataRol, permissions: formatedPermisosModulos }
						});
						setFormikInitialValues(newFormikInitialValues);
						formik.resetForm({
							values: newFormikInitialValues
						});
						setIsLoading(false);
					})
					.catch((err) => {
						console.error(err);
					});
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const asociatePermissionModule = () => {
		const valueInfo = selectedPermisosModulos.filter(
			(permiso) => !permisosAsociados.includes(permiso)
		);

		const promesas = valueInfo.map((permissionInfo) => {
			const body = {
				idRolPermisoModulo: null,
				idRol: {
					idRol: id
				},
				idPermisoModulo: {
					idPermisoModulo: permissionInfo.idPermisoModulo
				},
				usuarioCreacion: Cookie.get('idUsuario'),
				fechaCreacion: '2022-04-06T00:41:29.488+00:00',
				usuario_modificacion: Cookie.get('idUsuario'),
				fechaModificacion: null
			};

			return new Promise((resolve, reject) => {
				RequestUseCase.post(endpoints.roles.addRolePermisoModulo, body)
					.then((res) => {
						resolve(res);
					})
					.catch(() => {
						reject('El permiso esta en uso');
					});
			});
		});

		Promise.all(promesas)
			.then(() => {
				CustomAlert('confirm_msg', {
					icon: 'success',
					title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
					text: formatterText(
						'alert.message.confirm.updated.general',
						'El registro se ha actualizado correctamente'
					),
					confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
					allowOutsideClick: false,
					executeFunction: () => {
						navigate(paths.roles);
						window.location.reload();
					}
				});
			})
			.catch((error) => {
				HandleOnError(error);
			});
	};

	const getRole = (id) => {
		setIsLoading(true);
		RequestUseCase.get(endpoints.roles.getRoleById(id))
			.then((data) => {
				setNameRoleInput(data.nombre);
				getAsociatedPermissionModule(id, data);
			})
			.catch((err) => console.error(err));
	};

	const handleRemoveRole = (e, permiso) => {
		e.preventDefault();
		const selected = selectedPermisosModulos.filter((currentPermisosModulo) => {
			const primero = currentPermisosModulo.idPermiso.idpermiso;
			const segundo = permiso.idPermiso.idpermiso;
			const tercero = currentPermisosModulo.idModulo.idModulo;
			const cuarto = permiso.idModulo.idModulo;

			//compare 4 numbers to see if one of them is different from the other in another way
			return primero !== segundo || tercero !== cuarto;
		});

		RequestUseCase.delete(endpoints.roles.deletePermisoRol(permiso.idRolPermisoModulo))
			.then((res) => {
				if (res) successRemoveItem();
				return res;
			})
			.catch((err) => {
				console.error(err);
			});

		setSelectedPermisosModulos(selected);
		sortData(selected);
		setPermisosModulos([...permisosModulos, permiso]);
		setDataTable([...permisosModulos, permiso]);
	};

	const handleAddRole = (permiso) => {
		const newPermisosModulos = permisosModulos.filter((currentRol) => {
			const primero = currentRol.idPermiso.idpermiso;
			const segundo = permiso.idPermiso.idpermiso;
			const tercero = currentRol.idModulo.idModulo;
			const cuarto = permiso.idModulo.idModulo;

			//compare 4 numbers to see if one of them is different from the other in another way
			return primero !== segundo || tercero !== cuarto;
		});

		setDataTable(newPermisosModulos);
		setPermisosModulos(newPermisosModulos);
		const selected = [...selectedPermisosModulos, permiso];
		sortData(selected);
		setSelectedPermisosModulos(selected);
		setToggleSelector(!toggleSelector);
		setSearch('');
	};

	const sortData = (selected) => {
		return selected.sort((a, b) => {
			const nombreA = a.idModulo.nombre.toUpperCase();
			const nombreB = b.idModulo.nombre.toUpperCase();
			if (nombreA < nombreB) {
				return -1;
			}
			if (nombreA > nombreB) {
				return 1;
			}
			return 0;
		});
	};

	useEffect(() => {
		getRole(id);
	}, [id]);

	return {
		displayLoading,
		formatterText,
		formik,
		handleAddRole,
		handleRemoveRole,
		isLoading,
		navigate,
		setToggleSelector,
		toggleSelector
	};
}
