import React from 'react';

// Import Libs
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// Import Components
import DynamicTable from 'common/DynamicTable/DynamicHead';
import ExportJsonFile from 'components/utils/ExportJsonFile';
import Search from 'common/Search';

// Import Paths
import paths from 'services/paths';

// Import Constants
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';

//Import ViewModel
import EmployeeViewModel from './Employee.viewModel';

function EmployeeComponent() {
	const {
		loading,
		searchResults,
		employee,
		permittedActions,
		handleDeleteItem,
		handleEditStateItem,
		titles,
		renderMessage,
		formatterText,
		getDataTable
	} = EmployeeViewModel();

	return (
		<>
			{!loading && employee !== null ? (
				<section className="table-container">
					<section className="userOptions">
						{permittedActions.consultar && (
							<Search
								placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
								width="50%"
							/>
						)}
						{permittedActions.crear && (
							<Link to={paths.createEmployee}>
								<button className="btn-add">
									<FormattedMessage
										id="header.title.employee.create"
										defaultMessage="Crear Empleados"
									/>
								</button>
							</Link>
						)}

						{permittedActions.exportar && (
							<ExportJsonFile
								moduleName={'Empleado'}
								userName={JSON.parse(localStorage.getItem('userData')).usuario || 'ADMIN'}
								dataTable={searchResults}
							/>
						)}
					</section>
					<FormattedMessage id="table.name.search.employee" defaultMessage="Empleado">
						{() =>
							permittedActions.consultar && (
								<DynamicTable
									titles={titles}
									pageName={PAGE_NAMES.Empleados}
									getData={getDataTable}
									handleDeleteItem={handleDeleteItem}
									handleEditStateItem={handleEditStateItem}
									routeToEdit={paths.updateEmployee}
									canDeleted={permittedActions.eliminar}
									canModify={permittedActions.editar}
								/>
							)
						}
					</FormattedMessage>
				</section>
			) : (
				renderMessage()
			)}
		</>
	);
}

export default EmployeeComponent;
