import React from 'react';
// Mui Cell row style
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import BtnAssignAgenda from 'common/minimalTables/actions/BtnAssignAgenda';
// Import Libs
import PropTypes from 'prop-types';

const RowTechnicalNoAssign = ({ item, key, type }) => {
	return item.tech ? (
		<TableRow key={key}>
			<TableCell>{item.tech}</TableCell>
			<TableCell>
				<BtnAssignAgenda item={item} type={type} i={item.idTecnico} />
			</TableCell>
		</TableRow>
	) : (
		''
	);
};

RowTechnicalNoAssign.propTypes = {
	item: PropTypes.object,
	key: PropTypes.number,
	type: PropTypes.string
};

export default RowTechnicalNoAssign;
