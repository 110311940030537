import React from 'react';
import { FormattedMessage } from 'react-intl';
import MultiTableMinimal from 'common/minimalTables/MultiTableMinimal';
import ModalTechnicalTZViewModel from './ModalTechnicalTZ.viewModel';

export default function ModalTechnicalTZComponent() {
	const {
		dataTableTechnical,
		titlesTableTimeZone,
		dialog,
		closeDialog,
		loadingProgress,
		DisplayProgress,
		getAllTechiniansByTimeZone
	} = ModalTechnicalTZViewModel();
	return (
		<div>
			<section className="form-responsive-container-information">
				<span className="title-table">
					<FormattedMessage
						id="text.shedule.title.technical.associated"
						defaultMessage="Técnicos asociados a la franja horaria"
					/>
				</span>
				{loadingProgress ? (
					<DisplayProgress />
				) : (
					<MultiTableMinimal
						titles={titlesTableTimeZone}
						data={dataTableTechnical}
						type="technicalAssign"
						dialog={dialog}
						closeDialog={closeDialog}
						canSearch={true}
						getAllT={getAllTechiniansByTimeZone}
					/>
				)}
			</section>
		</div>
	);
}
