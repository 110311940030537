import React from 'react';
// Mui table styles
import MuiAlert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
// Pagination actions
import TablePagination from '@mui/material/TablePagination';
import UserPaginateActions from 'components/tableOptions/UserPaginateActions';
// Dynamic cell
import { formatterText } from 'hooks/useLangv2';
import DynamicRowNotifications from '../DynamicRowNotifications';
import { DynamicHeadNotificationsProps } from './DynamicHeadNotifications.props';
import DynamicHeadNotificationsViewModel from './DynamicHeadNotifications.viewModel';

//Import Components

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DynamicHeadNotifications = ({
	titles,
	data,
	totalData,
	setRowsPerPage,
	setPage,
	page = 0,
	rowsPerPage = 50,
	updated,
	pageName
}) => {
	const { open, handleChangePage, handleChangeRowsPerPage, handleClose } =
		DynamicHeadNotificationsViewModel({ setRowsPerPage, setPage });

	return (
		<>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 250 }} aria-label="Tabla de informacion">
					<TableHead>
						<TableRow>
							{titles.map((title, index) => (
								<TableCell
									key={`${index + 1}`}
									sx={{
										fontWeight: 'bold'
									}}
									align="left"
								>
									{title}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{data.map((item, index) => (
							<DynamicRowNotifications
								titles={titles}
								columns={item}
								key={`${index + 1}`}
								setUpdated={updated}
							/>
						))}
					</TableBody>
					<TableFooter
						sx={{
							display: 'flex',
							justifyContent: 'flex-end', // Use 'flex-end' to align to the right
							justifyItems: 'center',
							mt: 2,
							mb: 2
						}}
					>
						<TablePagination
							sx={{
								boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
								marginTop: '25px',
								borderRadius: '10px'
							}}
							rowsPerPageOptions={[
								10,
								25,
								50,
								100,
								{ label: formatterText('input.placeholder.all.bill'), value: totalData }
							]}
							colSpan={3}
							count={totalData}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={UserPaginateActions}
							labelRowsPerPage={formatterText(pageName) + formatterText('title.per.page')}
							align="center"
						/>
					</TableFooter>
				</Table>
			</TableContainer>
			<Stack spacing={2} sx={{ width: '100%' }}>
				<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
					<Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
						{formatterText('alert.update')}
					</Alert>
				</Snackbar>
			</Stack>
		</>
	);
};

DynamicHeadNotifications.propTypes = DynamicHeadNotificationsProps;

export default DynamicHeadNotifications;
