import React from 'react';

// Import Libs
import { FormattedMessage } from 'react-intl';

// Import Paths
import paths from 'services/paths';

// Import ViewModel
import CreateParameterViewModel from './CreateParameter.viewModel';

export default function CreatePosition() {
	const {
		codeError,
		errorName,
		formatterText,
		formData,
		handleErrorCode,
		handleErrorName,
		handlerTextCode,
		handlerTextDescription,
		handleSubmit,
		handleText,
		navigate
	} = CreateParameterViewModel();

	return (
		<form className="form-responsive" onSubmit={handleSubmit}>
			<section className="form-responsive-container-information">
				<section className="form-responsive-information__option">
					<span className="warpForm-text">
						<FormattedMessage id="p.third.type.name" defaultMessage="Nombre tipo de tercero" />
					</span>
					<input
						className="input-primary"
						type="text"
						name="nombre"
						value={formData.nombre}
						onChange={handleText}
						placeholder={formatterText('p.third.type.name', 'Nombre tipo de tercero')}
						maxLength="100"
						onBlur={handleErrorName}
					/>
				</section>
				{errorName && (
					<div
						style={{ fontSize: '0.7rem', color: 'red', marginTop: '-20px', marginRight: '500px' }}
					>
						{errorName}
					</div>
				)}
				<section className="form-responsive-information__option">
					<span className="p-styles">
						<FormattedMessage id="table.title.description" defaultMessage="Descripción" />
					</span>
					<textarea
						className="input-primary-textarea"
						type="text"
						name="descripcion"
						value={formData.descripcion}
						onChange={handlerTextDescription}
						placeholder={formatterText(
							'input.placeholder.type.third.desc',
							'Descripción del tipo de tercero'
						)}
						maxLength="200"
					/>
				</section>
				<section className="form-responsive-information__option">
					<span className="p-styles">
						<FormattedMessage id="table.title.code" defaultMessage="Código" />
					</span>
					<input
						className={(formData.codigo ? '' : 'input-error ') + 'input-primary'}
						type="text"
						name="codigo"
						//pattern="^\S+(?: \S+)*$"
						value={formData.codigo.toLowerCase()}
						onChange={handlerTextCode}
						placeholder={formatterText(
							'input.placeholder.type.third.code',
							'Código del tipo de tercero'
						)}
						maxLength="45"
						onBlur={handleErrorCode}
					/>
				</section>
				{codeError && (
					<div
						style={{ fontSize: '0.7rem', color: 'red', marginTop: '-20px', marginRight: '500px' }}
					>
						{codeError}
					</div>
				)}
			</section>
			<section className="form-responsive-container-buttons">
				<button type="submit" className="btn-primary">
					<FormattedMessage id="btn.save.changes" defaultMessage="Guardar cambios" />
				</button>
				<button className="input-cancel" onClick={() => navigate(paths.parameters)}>
					<FormattedMessage id="btn.cancel" defaultMessage="Cancel" />
				</button>
			</section>
		</form>
	);
}
