import * as yup from 'yup';
import { NAMEROLE_REGEX } from 'common/validators/Regex';

export const getFormikValidationSchema = () => {
	return yup.object().shape({
		nombre: yup
			.string()
			.required('p.form.error.required')
			.matches(NAMEROLE_REGEX, 'alert.title.role.name.invalid')
			.max(100, 'p.label.title.ingresa100Caracteres'),
		description: yup.string().max(200, 'p.form.error.max.200.chars'),
		permissions: yup
			.array()
			.required('p.form.error.required')
			.min(1, 'alert.title.add.permissions.modules')
	});
};
