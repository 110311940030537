import Cookie from 'js-cookie';

export const parseDataToFormik = ({ formikInitialValues, dataByServer }) => {
	const newFormikData = { ...dataByServer };

	if (dataByServer?.idSector) {
		newFormikData.idSector = dataByServer.idSector;
		newFormikData.usuarioModificacion = Number(Cookie.get('idUsuario'));
	}

	newFormikData.active = Boolean(dataByServer?.estado);

	return {
		...formikInitialValues,
		...newFormikData
	};
};
