import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import useLangv2 from 'hooks/useLangv2';

import PermissionSelector from 'common/PermissionSelector';

export const SelectMultiTag = ({
	error,
	handleAdd,
	handleRemove,
	labelText,
	maxLength = null,
	name,
	onBlur,
	sector = false,
	setToggleSelector,
	textButtonAdd,
	toggleSelector,
	value
}) => {
	const { formatterText } = useLangv2();

	const onShowSelector = (e) => {
		e.preventDefault();
		setToggleSelector((state) => !state);
	};

	return (
		<section className="d-flex flex-start">
			<span className="text-inline-md margin-top">{labelText}</span>
			<section className="w100-container">
				<FieldArray
					name={name}
					onBlur={onBlur}
					render={(arrayHelpers) => (
						<>
							<div
								style={{ width: '100%', display: 'flex', flexWrap: 'wrap', minHeight: '60px' }}
								className="input-edit-fix"
							>
								{maxLength !== value?.length && (
									<button onClick={onShowSelector} className="add-role">
										{textButtonAdd}
									</button>
								)}
								{Boolean(value?.length) &&
									value.map((item, index) => (
										<button
											key={index}
											onClick={(e) => {
												arrayHelpers.remove(index);
												handleRemove(e, item);
											}}
											className="role-item"
										>
											{sector
												? `${item.codigoMunicipio} - ${item.nombre} - ${item.idDepartamento.nombre}`
												: `${item.idModulo.nombre} - ${item.idPermiso.nombre}`}
											<div></div>
										</button>
									))}
							</div>
							{toggleSelector && (
								<PermissionSelector
									handleAdd={(e) => {
										arrayHelpers.insert(value.length + 1, e);
										handleAdd(e);
									}}
									sector={sector}
								/>
							)}
						</>
					)}
				/>
				{typeof error === 'string' && (
					<div style={{ marginTop: '3px' }}>
						<span id={'email-error'} className="error-msg">
							{formatterText(error)}
						</span>
					</div>
				)}
			</section>
		</section>
	);
};

SelectMultiTag.propTypes = {
	error: PropTypes.any,
	handleAdd: PropTypes.func.isRequired,
	handleRemove: PropTypes.func.isRequired,
	labelText: PropTypes.string.isRequired,
	maxLength: PropTypes.number,
	name: PropTypes.string.isRequired,
	onBlur: PropTypes.func,
	sector: PropTypes.bool,
	setToggleSelector: PropTypes.func.isRequired,
	textButtonAdd: PropTypes.string.isRequired,
	toggleSelector: PropTypes.bool.isRequired,
	value: PropTypes.array.isRequired
};
