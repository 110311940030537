import profileImage from 'assets/profile-default.jpg';
class User {
	constructor(
		idUsuario = 1,
		usuario = 'Admin',
		correo = 'example@example.com',
		clave = '123',
		estado = 2,
		dobleAutenticacion = 0,
		fechaRegistro = '2022-06-17T16=38=12.627+00=00',
		fechaModificacion = '2022-06-17T22=55=36.728+00=00',
		tipoAcceso = 2,
		avatarUrl = profileImage,
		idIdioma = {
			idIdioma: 2,
			idioma: 'Ingles'
		},
		usuarioCreacion = '1',
		usuario_modificacion = null,
		roles = [],
		codigo = null
	) {
		this.idUsuario = idUsuario;
		this.usuario = usuario;
		this.correo = correo;
		this.clave = clave;
		this.estado = estado;
		this.dobleAutenticacion = dobleAutenticacion;
		this.fechaRegistro = fechaRegistro;
		this.fechaModificacion = fechaModificacion;
		this.tipoAcceso = tipoAcceso;
		this.avatarUrl = avatarUrl;
		this.idIdioma = idIdioma;
		this.usuarioCreacion = usuarioCreacion;
		this.usuario_modificacion = usuario_modificacion;
		this.roles = roles;
		this.codigo = codigo;
	}
}

export default User;
