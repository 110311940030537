import React from 'react';

// Import Libs
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// Import Components
import DynamicTable from 'common/DynamicTable/DynamicHead';
import Search from 'common/Search';

import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';
import paths from 'services/paths';

import ContractTypeViewModel from './ContractType.viewModel';

export function ContractComponent() {
	const {
		cargos,
		displayLoading,
		displayMessage,
		error,
		formatterText,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		loading,
		permittedActions,
		titles
	} = ContractTypeViewModel();

	return (
		<>
			{!loading && cargos !== null ? (
				<section className="table-container">
					<section className="userOptions">
						{permittedActions.consultar && (
							<Search
								placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
								width="50%"
							/>
						)}
						{permittedActions.crear && (
							<Link to={paths.createContract}>
								<button className="btn-add">
									<FormattedMessage
										id="header.title.contract.type.create"
										defaultMessage="Crear tipo de contrato"
									/>
								</button>
							</Link>
						)}
					</section>
					<FormattedMessage id="table.name.search.positions" defaultMessage="Cargos">
						{permittedActions.consultar &&
							(() => (
								<DynamicTable
									titles={titles}
									pageName={PAGE_NAMES.Contratos}
									getData={getDataTable}
									handleDeleteItem={handleDeleteItem}
									handleEditStateItem={handleEditStateItem}
									routeToEdit={paths.updateContract}
									canDeleted={permittedActions.eliminar}
									canModify={permittedActions.editar}
								/>
							))}
					</FormattedMessage>
				</section>
			) : error ? (
				displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			) : (
				displayLoading()
			)}
		</>
	);
}
