import React from 'react';
import { SearchWrapper } from 'context/SearchContext';
import CreateSectorComponent from './CreateSector.component';

const CreateSector = () => (
	<SearchWrapper>
		<CreateSectorComponent />
	</SearchWrapper>
);

export default CreateSector;
