/**
 * Convierte un formato moneda a numero
 * @param {*} formattedValue
 * @return value
 *
 * @author lfernandez-qvision
 */
const rawCurrency = (formattedValue) => {
	try {
		//if hace more than 1 point, only get the last one
		if (formattedValue.includes(',')) {
			formattedValue = formattedValue.replace(',', '&');
		}
		formattedValue = formattedValue //  except the & character
			.replace(/[^0-9&]/g, '')
			.replace(/(\...*)\./g, '$1');

		try {
			//convert formatted value to string
			if (formattedValue !== undefined && formattedValue !== null) {
				formattedValue = formattedValue.toString();
				//return formattedValue like a price with $ and .
				let res = formattedValue.replace(/[$\s.]/g, '').replace('&', '.');
				if (formattedValue.includes(',')) {
					res = res.replace(',', '.');
				}
				return res;
			}
		} catch (e) {
			// FIXME: fix this error
			console.log(e); // lfernandez-qvision cuidado que esto esta generando errores, me toco agregarlo para que no se rompiera el codigo
		}
	} catch (e) {
		// avoid error in create employee
	}
};

export default rawCurrency;
