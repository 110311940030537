import { useState } from 'react';
// Import Material UI Components
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// Import Libs
import { isEmpty } from 'lodash';
// Import Styles
import styles from './SelectComboBox.module.css';

export default function SelectComboBox({
  name,
  selectValue,
  setterFunction,
  data,
  placeholder,
  auto = 'off'
}) {
  const [inputValue, setInputValue] = useState('');

  return (
    <Autocomplete
      disablePortal
      id={name}
      options={data}
      className={styles.container}
      sx={{ padding: 0, margin: 0 }}
      value={selectValue}
      onChange={(event, newValue) => {       
        if (!isEmpty(newValue)) setterFunction(newValue);
        else setterFunction({ id: 0, label: '' });
      }}
      filterOptions={(options, { inputValue }) => {
        const normalizedInput = inputValue.toLowerCase();
        if (normalizedInput.length < 3) {
          return options; 
        }
        const regex = new RegExp(`\\b${normalizedInput}\\w*`, 'i');
        return options.filter((option) => regex.test(option.label.toLowerCase()));
      }}
      
      classes={{
        option: styles.menuItem,
        listbox: styles.menuList,
        noOptions: styles.noOptions,
        groupLabel: styles.headerItem,
        notchedOutline: styles.notchedOutline,
        input: styles.inputData,
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => <TextField {...params}  label={placeholder} />}
      autoComplete={auto}
    />
  );
}
