import React from 'react';
// Import Contexts
// Import Components
import Search from 'common/Search';
import { MdAutorenew, MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
// Import Libs
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
// Import Screens
import SelectComboBox from 'common/selects/SelectComboBox';
import DateTimePicker from 'components/pickers/DateTimePicker';
import { FormattedMessage } from 'react-intl';
import Alerts from 'screens/notificationsTable/Alerts';
import Notifications from 'screens/notificationsTable/Notifications';
import grid from 'screens/services/Service.module.css';
import ContainerViewModel from './container.viewModel';

const tabs = [
	{
		nombre: <FormattedMessage id="tab.title.alerts" defaultMessage="Alertas" />,
		componente: <Alerts />,
		reduce: 'Alert'
	},
	{
		nombre: <FormattedMessage id="tab.title.notifications" />,
		componente: <Notifications />,
		reduce: 'Notification'
	}
];

const PanelNotificationsComponent = () => {
	const {
		_SetDispatch,

		setSelectedTab,
		indexTabServices,
		show,
		setShow,
		selectedSearch,

		formData,

		val,
		selectValues,
		setSelectValues,
		handlerClear,
		handleChange,
		handleFechaYHora,
		refreshTables,
		searchAdvance,
		formatterText
	} = ContainerViewModel(tabs);

	return (
		<section className="table-container">
			<section className="userOptions">
				<Search
					placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
					width="50%"
				/>
				<button
					type="submit"
					className="searchButton"
					style={{
						borderRadius: '5px',
						//rotate 90deg
						transform: 'rotate(90deg)'
					}}
				>
					<MdAutorenew className="icon-refresh" onClick={(e) => refreshTables(e)} />
				</button>
			</section>
			<section className="advance-search">
				<p
					onClick={(e) => {
						e.preventDefault();
						setShow(!show);
					}}
				>
					{show ? (
						<MdKeyboardArrowDown size={22} color="gray" cursor="pointer" />
					) : (
						<MdKeyboardArrowRight size={22} color="gray" cursor="pointer" />
					)}
					<FormattedMessage
						id="text.action.show.advanced.search"
						defaultMessage="Búsqueda avanzada"
					/>
				</p>
			</section>
			{show && (
				<form style={{ marginBottom: '30px', padding: '0' }}>
					<section className={grid.grid}>
						<label>
							<input
								style={{ marginLeft: '10px' }}
								className="input-primary"
								type="text"
								name="ticket"
								placeholder={formatterText('text.ticket', 'Ticket')}
								maxLength="45"
								onChange={handleChange}
								value={formData.ticket}
							/>
						</label>
						<label>
							<input
								style={{ marginLeft: '10px' }}
								className="input-primary"
								type="text"
								name="expediente"
								placeholder={formatterText('text.expedient', 'Expediente')}
								maxLength="45"
								onChange={handleChange}
								value={formData.expediente}
							/>
						</label>
						<SelectComboBox
							name="nombre_cliente"
							selectValue={selectValues?.nombre_cliente}
							setterFunction={(nombre_cliente) =>
								setSelectValues({ ...selectValues, nombre_cliente })
							}
							val={val}
							data={selectedSearch.clientsAssociate}
							placeholder={formatterText('text.client.name')}
						/>

						<SelectComboBox
							name="tipo_notificacion"
							selectValue={selectValues?.tipo_notificacion}
							setterFunction={(tipo_notificacion) =>
								setSelectValues({ ...selectValues, tipo_notificacion })
							}
							val={val}
							data={selectedSearch.notifications} //TODO: CAMBIAR A tipo_notification cuando se tenga el endpoint
							placeholder={formatterText('table.title.notification.type')}
							disable={true}
						/>
						<SelectComboBox
							name="municipio"
							selectValue={selectValues?.municipio}
							setterFunction={(municipio) => setSelectValues({ ...selectValues, municipio })}
							val={val}
							data={selectedSearch.municipalities}
							placeholder={formatterText('table.title.muniName')}
						/>
						<label>
							<DateTimePicker
								name="fecha_hora"
								placeholder={formatterText('tab.title.dates')}
								value={selectValues.fecha}
								onChange={(data) => handleFechaYHora(data)}
							/>
						</label>
					</section>
					<section className={grid['display-btn-advanced-search']}>
						<button className="btn-search" onClick={(e) => searchAdvance(e)}>
							<FormattedMessage id="btn.search" defaultMessage="Buscar" />
						</button>
						<button className="btn-clear-form" onClick={(e) => handlerClear(e)}>
							<FormattedMessage id="btn.clean" defaultMessage="Limpiar" />
						</button>
					</section>
				</form>
			)}

			<Tabs defaultIndex={indexTabServices} selectedTabClassName="tab-option--selected">
				<TabList className="tab-list">
					{tabs.map((tab, index) => (
						<Tab
							onClick={() => {
								setSelectedTab(tab);
								_SetDispatch({ type: 'TEXT_TO_FILTER', payload: '' });
								_SetDispatch({ payload: tab.reduce, type: 'TAB_SELECTED' });
								//_SetDispatch({ type: 'ROWS_PER_PAGE', payload: 25 });
								localStorage.setItem('indexNotification', index.toString());
							}}
							key={`${index + 1}`}
							className="new-tab-option"
						>
							{tab.nombre}
						</Tab>
					))}
				</TabList>
				{tabs.map((tab, index) => (
					<TabPanel key={`${index + 0}`}>{tab.componente}</TabPanel>
				))}
			</Tabs>
		</section>
	);
};

export default PanelNotificationsComponent;
