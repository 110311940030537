import React from 'react';

// Import Libs
import { FormattedMessage } from 'react-intl';

// Import Components
import DynamicTable from 'common/DynamicTable/DynamicHead';

// Import Paths
import paths from 'services/paths';

//Import Constants
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';

//Import ViewModel
import ClientTypeTableViewModel from './ClientTypeTable.viewModel';

// Import PropTypes
import { ClientTypeTableProps } from './ClientTypeTable.props';

const ClientTypeTable = ({ permisos }) => {
	const {
		displayLoading,
		displayMessage,
		error,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		loading,
		parameters,
		titles
	} = ClientTypeTableViewModel();
	return (
		<>
			{!loading && parameters !== null ? (
				<FormattedMessage id="table.name.search.clientType" defaultMessage="Tipos de clientes">
					{() => (
						<DynamicTable
							titles={titles}
							pageName={PAGE_NAMES.ClienteTipo}
							data={getDataTable}
							handleDeleteItem={handleDeleteItem}
							handleEditStateItem={handleEditStateItem}
							routeToEdit={paths.updateClientType}
							canDeleted={permisos.eliminar}
							canModify={permisos.editar}
						/>
					)}
				</FormattedMessage>
			) : error ? (
				displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			) : (
				displayLoading()
			)}
		</>
	);
};

ClientTypeTable.propTypes = ClientTypeTableProps;

export default ClientTypeTable;
