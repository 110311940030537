
export const parseDataToSubmit = (values) => {
	const dataToSend = {
		...values,
		idTipoArchivoAdjunto: values.idTipoArchivoAdjunto,
		nombre: values.nombre,
		extension: values.extension,
		estado: Number(values.active),
		descripcion: '',
		pesoMaximoArchivo: values.pesoMaximoArchivo,
		cantidadMaxima: values.cantidadMaxima || 1,
		categorias: values.categorias,
		
	};
	return dataToSend;
};
