import React, { useContext, useEffect, useState } from 'react';
// Impoprt Contexts
import { AppContext } from 'context/AppContext';
// Impoprt Hooks
import { useAuth } from 'hooks/useAuth';
import { useLocation, useNavigate } from 'react-router-dom';
// Import Components
import SideNav from 'common/SideNav';
import AlertIcon from 'components/info/AlertIcon';
import HeaderList from 'components/layout/HeaderList';
// Import Libs
import { AiOutlineMenu } from 'react-icons/ai';
import { IoMdContact, IoMdExit } from 'react-icons/io';
// Import Services
import paths from 'services/paths';
// Import Styles
import profileDefault from 'assets/profile-default.jpg';
import MIME_TYPE from 'common/utils/MimeType';
import useLangv2 from 'hooks/useLangv2';
import { getProfileImage } from 'services/api/users';
import 'styles/headerStyle.css';

const Header = ({ children }) => {
	const navigate = useNavigate();
	const auth = useAuth();
	const { formatterText } = useLangv2();

	const STORAGE_AUX = {
		usuario: 'Admin'
	};
	const STORAGE_LOCAL = JSON.parse(localStorage.getItem('userData')) || STORAGE_AUX;

	const storageLocalUsername = STORAGE_LOCAL.usuario;

	const { imagUrlValue, setImgUrlValue } = useContext(AppContext);
	const [isOpen, setIsOpen] = useState(false);
	const [showAvatar, setShowAvatar] = useState(false);

	const openNav = () => {
		document.getElementById('mySidenav').style.width = '250px';
		document.getElementById('main').style.marginLeft = '250px';
	};

	const closeNav = () => {
		document.getElementById('mySidenav').style.width = '0';
		document.getElementById('main').style.marginLeft = '0';
	};

	// hook to get the current location
	const location = useLocation();

	const getProfileImageUser = () => {
		getProfileImage().then((resp) => {
			const img =
				Object.keys(resp).length !== 0
					? `data:${MIME_TYPE[resp.ext]};base64,${resp.image}`
					: profileDefault;
			setImgUrlValue(img);
		});
	};

	useEffect(() => {
		if (window.location.hash !== '#/login') {
			getProfileImageUser();
		} else {
			setShowAvatar(!showAvatar);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imagUrlValue]);

	// Loaout function
	const handleLogout = (e) => {
		e.preventDefault();
		setShowAvatar(!showAvatar);
		auth.logout();
	};

	const determinateUpdateTitle = () => {
		try {
			// check if location.pathname is an update path
			const TRIM_PATH = location.pathname.split('/');
			const ID_INDEX = TRIM_PATH.length - 1;
			const NUM = TRIM_PATH[ID_INDEX];
			const FULL_UPDATE_PATH = location.pathname.replace(NUM, ':id');
			return HeaderList(FULL_UPDATE_PATH);
		} catch (error) {
			return '404: Not Found';
		}
	};

	const determinatTitle = () => {
		try {
			return HeaderList(location.pathname);
		} catch (error) {
			return '404: Not Found';
		}
	};

	return (
		<>
			{
				// isError ? (
				!location.pathname.includes(paths.login) ? (
					<>
						<nav className="header">
							<section className="header-container-menu">
								<AiOutlineMenu
									className="header__menu"
									color="#747474"
									size={35}
									onClick={() => {
										if (isOpen) {
											closeNav();
											setIsOpen(!isOpen);
										} else {
											openNav();
											setIsOpen(!isOpen);
											setShowAvatar(false);
										}
									}}
								/>
								<img
									className="header__logo"
									src={require('assets/logo.png')}
									onClick={() => navigate(paths.home)}
									alt="Logo"
								/>
							</section>
							<section>
								<h1 className="header__title">
									Coral -{' '}
									{!location.pathname.includes(paths.update) ||
									location.pathname === paths.updateProfile
										? determinatTitle()
										: determinateUpdateTitle()}
								</h1>
							</section>
							<section className="header__options">
								<AlertIcon />
								<h3>{storageLocalUsername}</h3>
								<img
									className="header-avatar"
									src={imagUrlValue}
									alt="Avatar del usuario"
									onClick={() => setShowAvatar(!showAvatar)}
								/>
								{showAvatar && (
									<section className="header-profile">
										<button
											className="btn-profile"
											onClick={() => {
												setShowAvatar(!showAvatar);
												navigate(paths.updateProfile);
											}}
										>
											<IoMdContact size={30} color={'#d3d3d3'} />
											<p>{formatterText('header.title.edit.profile')}</p>
										</button>
										<button type="submit" className="btn-profile" onClick={handleLogout}>
											<IoMdExit size={30} color={'#d3d3d3'} />
											<p>{formatterText('header.title.log.out')}</p>
										</button>
									</section>
								)}
							</section>
						</nav>

						<div id="main">
							<SideNav />
							{children}
						</div>
					</>
				) : (
					<div id="main">{children}</div>
				)
				/* ) : (
        <NotFound />
      ) */
			}
		</>
	);
};

export default Header;
