import React from 'react';

const BtnSeeDetails = ({ item, handleDetails }) => {
	return (
		<td className="minimal-table__actions" align="center">
			<div className="btn-action-select-tech" onClick={() => handleDetails(item)}>
				<p>Ver Detalles</p>
			</div>
		</td>
	);
};

export default BtnSeeDetails;
