import Selector from 'common/selects/Selector';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import paths from 'services/paths';
import CreateCommunicationViewModel from './CreateCommunication.viewModel';

export default function CreateCommunication() {
	const { formData, handleText, handleChange, handleCode, handleSubmit, formatterText, navigate } =
		CreateCommunicationViewModel();

	return (
		<form className="form-responsive" onSubmit={handleSubmit}>
			<section className="form-responsive-container-information">
				<section className="form-responsive-information__option">
					<h3>Nombre de la comunicación</h3>
					<input
						className="input-primary"
						type="text"
						name="nombre"
						value={formData.nombre}
						onChange={handleText}
						placeholder="Nombre de la comunicación"
						maxLength="100"
						required
					/>
				</section>
				<section className="form-responsive-information__option">
					<h3>Descripción</h3>
					<input
						className="input-textarea-sm"
						type="text"
						name="descripcion"
						value={formData.descripcion}
						onChange={handleChange}
						placeholder={formatterText('table.title.description', 'Descripción')}
						maxLength="200"
					/>
				</section>
				<label className="form-responsive-information__option">
					<h3>Tipo de comunicación</h3>
					<Selector
						name="idProveedor"
						data={[]}
						placeholder={
							<FormattedMessage
								id="input.placeholder.select"
								defaultMessage="Selecione una opción"
							/>
						}
						// isLoading={loading}
						isRequired={true}
					/>
				</label>
				<section className="form-responsive-information__option">
					<h3>Código</h3>
					<input
						className="input-primary"
						type="text"
						name="codigo"
						value={formData.codigo}
						onChange={handleCode}
						placeholder="Ingresa hasta 45 caracteres"
						maxLength="45"
						required
					/>
				</section>
			</section>
			<section className="form-responsive-container-buttons">
				<button type="submit" className="btn-primary">
					Crear comunicación
				</button>
				<button className="input-cancel" onClick={() => navigate(paths.services)}>
					Cancelar
				</button>
			</section>
		</form>
	);
}
