import customStyles from 'common/selects/default.select.style';
import { useField } from 'formik';
import useLangv2 from 'hooks/useLangv2';
import PropTypes from 'prop-types';
import Select from 'react-select';

export const SelectField = ({
	error,
	isMulti = false,
	labelText,
	name,
	onBlur,
	options,
	placeholder = '',
	touched,
	value
}) => {
	const { formatterText } = useLangv2();
	const [field, state, { setValue }] = useField(name);
	const onChange = (values) => {
		setValue(isMulti ? values.map((item) => item.value) : values.value);
	};

	const getValue = () => {
		if (options && value) {
			return isMulti
				? options.filter((option) => value.indexOf(option.value) >= 0)
				: options.find((option) => option.value === value.toString());
		} else {
			return isMulti ? [] : '';
		}
	};

	return (
		<section className="d-flex flex-start margin-top">
			<span className="text-inline-md" htmlFor={name}>
				{labelText}
			</span>
			<section className="w100-container">
				<Select
					styles={customStyles}
					name={name}
					id={name}
					isMulti={isMulti}
					inputId={name}
					placeholder={placeholder}
					onBlur={onBlur}
					options={options}
					value={getValue()}
					onChange={onChange}
				/>
				{typeof error === 'string' && touched && (
					<div className="error-msg">{formatterText(error)}</div>
				)}
			</section>
		</section>
	);
};

SelectField.propTypes = {
	name: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	placeholder: PropTypes.string,
	error: PropTypes.string,
	touched: PropTypes.bool,
	onBlur: PropTypes.func,
	isMulti: PropTypes.bool
};
