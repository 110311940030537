import React from 'react';
// Import Contexts
// Import Hooks
// Import Components
import SelectorGrid from 'common/selects/SelectorGrid';
import {
	MdDelete,
	MdKeyboardArrowDown,
	MdKeyboardArrowRight,
	MdOutlineAddCircleOutline,
	MdOutlineRemoveCircleOutline
} from 'react-icons/md';
import { Modal } from 'react-responsive-modal';
import AddressModal from '../Modals/AddressModal';
import ExternalUserModal from '../Modals/ExternalUserModal';
import ClientModal from '../Modals/ModalClientSearch';
import ModalSearch from '../Modals/ModalSearch';
import ModalUserSystemSearch from '../Modals/ModalUserSystemSearch';
// Import libs
import { Formiz, FormizStep } from '@formiz/core';
import dateFormat from 'dateformat';
import { FormattedMessage } from 'react-intl';
// Import services
import paths from 'services/paths';
// Import Styles
import { TimePicker } from '@material-ui/pickers';
import 'react-responsive-modal/styles.css';
import ModalBillingUser from '../Modals/ModalBillingUser';
import { valueSetter } from '../utils/string';
import CreateServiceViewModel from './CreateService.viewModel';

const CreateService = () => {
	const {
		formItem,
		setFormItem,
		selectedSearch,
		selectIds,
		setSelectIds,
		eventData,
		time,
		handleDate,
		show,
		setShow,
		myForm,
		handleSubmit,
		setSelectedTable,
		clientSelected,
		setClientSelected,
		userSelected,
		setUserSelected,
		externalUserSelected,
		setExternalUserSelected,
		billingUserSelected,
		setBillingUserSelected,
		openClient,
		onOpenClient,
		onCloseClient,
		openExternalUser,
		onOpenExternalUser,
		onCloseExternalUser,
		openBillingUser,
		onToggleBillingUser,
		openSystemUser,
		onOpenSystemUser,
		onCloseSystemUser,
		openAddress,
		onOpenAddress,
		onCloseAddress,
		openSearch,
		onCloseSearch,
		fileInput,
		filesOnCreate,
		uploadFromCreate,
		deleteFile,
		loadingValidation,
		searchAutomatic,
		manualSearch,
		idService,
		addressAux,
		listPriceAux,
		formatterText,
		getAllUsersServices,
		showAddress,
		checkoutValidations,
		setCheckoutValidations,
		now,
		setTypeGenerate,
		typeGenerate,
		navigate,
		handlerTextDescription
	} = CreateServiceViewModel();
	return (
		<>
			<div className="centered-form">
				<Formiz onValidSubmit={handleSubmit} connect={myForm}>
					<form className="container-wrapForm" style={{ minHeight: '16rem' }}>
						<div className="new-container-wrapForm__tabs">
							{myForm.steps.map((step, index) => (
								<button
									key={step.name}
									className={`new-tab-option ${
										step.name === myForm.currentStep.name ? 'is-active' : ''
									}`}
									type="button"
									onClick={() => {
										setSelectedTable(index);

										myForm.goToStep(step.name);
									}}
								>
									{!step.isValid && step.isSubmitted && <small className="mr-2">⚠️</small>}
									{formatterText(step.label)}
								</button>
							))}
						</div>
						<div className="container-wrapForm-content" style={{ padding: '1rem' }}>
							<FormizStep name="step1" label="p.information.service.general">
								<section className="advance-search">
									<p
										onClick={(e) => {
											e.preventDefault();
											setShow(!show);
										}}
									>
										{show ? (
											<MdKeyboardArrowDown size={22} color="gray" cursor="pointer" />
										) : (
											<MdKeyboardArrowRight size={22} color="gray" cursor="pointer" />
										)}
										<FormattedMessage
											id="text.action.show.service.generating.ask"
											defaultMessage="¿Quién genera el servicio?"
										/>
									</p>
								</section>
								{/* advanced Seacrh */}
								{show && (
									<section className="form-responsive-container-buttons">
										<button
											className="btn-primary"
											type="button"
											onClick={() => {
												setTypeGenerate(true);
												setClientSelected({
													nombre: formatterText('input.placeholder.select.client'),
													id: null,
													esVip: 2
												});

												setUserSelected({
													nombre: 'Seleccione un usuario generador',
													id: null,
													esVip: 2
												});
											}}
										>
											{formatterText('table.name.search.client')}
										</button>
										<button
											className="btn-primary"
											type="button"
											onClick={() => {
												setTypeGenerate(false);
												setClientSelected({
													nombre: formatterText('input.placeholder.select.client'),
													id: null,
													esVip: 2
												});

												setUserSelected({
													nombre: 'Seleccione un usuario generador',
													id: null,
													esVip: 2
												});
											}}
										>
											{formatterText('table.title.user')}
										</button>
									</section>
								)}
								<section className="w100-container">
									<section className="padding-all-1">
										{/* gen, user and direction */}
										<section className="grid-container-2c">
											{typeGenerate ? (
												<label className="d-flex">
													<section className="box-text-container">
														<p className="text-inline">
															<FormattedMessage
																id="p.generate.Client"
																defaultMessage="Generado / Cliente"
															/>
														</p>
														{/* open modal of external user */}
														<p
															onClick={(e) => {
																e.preventDefault();
																onOpenClient();
															}}
															className="p-review"
														>
															{formatterText('text.details.view.more')}
														</p>
													</section>
													<div className="wrap-input">
														<div className="input-label-icon">
															<input
																type="text"
																name="idGeneradorCliente"
																className="input-label-style"
																value={valueSetter(clientSelected.nombre)}
																disabled
															/>
															<div className="btn-action-style">
																{clientSelected.id ? (
																	<MdOutlineRemoveCircleOutline
																		size={25}
																		color="gray"
																		cursor="pointer"
																		onClick={(e) => {
																			new Promise((resolve) => {
																				e.preventDefault();
																				resolve();
																			}).then(() => {
																				setClientSelected({
																					nombre: `${formatterText(
																						'input.placeholder.select.client'
																					)}`,
																					id: null,
																					esVip: 2
																				});
																			});
																		}}
																	/>
																) : (
																	<MdOutlineAddCircleOutline
																		size={25}
																		color="gray"
																		cursor="pointer"
																		onClick={(e) => {
																			new Promise((resolve) => {
																				e.preventDefault();
																				resolve();
																			}).then(() => {
																				onOpenClient();
																			});
																		}}
																	/>
																)}
															</div>
														</div>
														<label
															style={{
																position: 'relative',
																top: ' 0.5rem'
															}}
														></label>
													</div>
												</label>
											) : (
												<label className="d-flex">
													<section className="box-text-container">
														<p className="text-inline">
															{formatterText('p.generate.user', 'Usuario')}
														</p>
														{/* open modal of generater user */}
														<p
															onClick={(e) => {
																e.preventDefault();
																onOpenSystemUser();
															}}
															className="p-review"
														>
															{formatterText('text.details.view.more')}
														</p>
													</section>
													<div className="wrap-input">
														<div className="input-label-icon">
															<input
																type="text"
																name="idUsuario"
																className="input-label-style"
																value={valueSetter(userSelected.nombre)}
																required
																disabled
															/>
															<div className="btn-action-style">
																{userSelected.id ? (
																	<MdOutlineRemoveCircleOutline
																		size={25}
																		color="gray"
																		cursor="pointer"
																		onClick={(e) => {
																			new Promise((resolve) => {
																				e.preventDefault();
																				resolve();
																			}).then(() => {
																				setUserSelected({
																					nombre: 'Seleccione un usuario generador',
																					id: null,
																					esVip: 2
																				});
																			});
																		}}
																	/>
																) : (
																	<MdOutlineAddCircleOutline
																		size={25}
																		color="gray"
																		cursor="pointer"
																		onClick={(e) => {
																			new Promise((resolve) => {
																				e.preventDefault();
																				resolve();
																			}).then(() => {
																				onOpenSystemUser();
																			});
																		}}
																	/>
																)}
															</div>
														</div>
													</div>
												</label>
											)}

											<label className="d-flex">
												<section className="box-text-container">
													<p className="text-inline">
														{formatterText('p.generate.user', 'Usuario')}
													</p>
													{/* open modal of external user */}
													<p
														onClick={(e) => {
															e.preventDefault();
															onOpenExternalUser();
														}}
														className="p-review"
													>
														{formatterText('text.details.view.more')}
													</p>
												</section>

												<div className="wrap-input">
													<div className="input-label-icon">
														<input
															type="text"
															name="idUsuario"
															className="input-label-style"
															value={valueSetter(externalUserSelected.nombre)}
															required
															disabled
														/>
														<div className="btn-action-style">
															{externalUserSelected.id ? (
																<MdOutlineRemoveCircleOutline
																	size={25}
																	color="gray"
																	cursor="pointer"
																	onClick={(e) => {
																		new Promise((resolve) => {
																			e.preventDefault();
																			resolve();
																		}).then(() => {
																			setExternalUserSelected({
																				nombre: 'Seleccione un usuario (Opcional)',
																				id: null
																			});
																		});
																	}}
																/>
															) : (
																<MdOutlineAddCircleOutline
																	size={25}
																	color="gray"
																	cursor="pointer"
																	onClick={(e) => {
																		new Promise((resolve) => {
																			e.preventDefault();
																			resolve();
																		}).then(() => {
																			onOpenExternalUser();
																		});
																	}}
																/>
															)}
														</div>
													</div>
												</div>
											</label>

											<label className="d-flex">
												<section className="box-text-container">
													<p className="text-inline">{formatterText('p.label.direction')}</p>
													{/* open modal of address */}
													<p
														onClick={(e) => {
															e.preventDefault();
															onOpenAddress();
														}}
														className="p-review"
													>
														{formatterText('text.details.view.more')}
													</p>
												</section>
												<select
													name="idDireccion"
													className="input-label-style"
													value={selectIds.idDireccion}
													onChange={(e) => {
														if (e.target.value !== '') {
															setSelectIds({
																...selectIds,
																idDireccion: e.target.value
															});
														}
													}}
												>
													<option value="">
														<FormattedMessage
															id="input.placeholder.select"
															defaultMessage="Selecciona una opción"
														/>
													</option>
													{addressAux.map((item) => (
														<option key={item.value} value={item.value}>
															{item.label}
														</option>
													))}
												</select>
											</label>
											<label className="d-flex">
												<section className="box-text-container">
													<p className="text-inline">{formatterText('p.label.price.list')}</p>
												</section>
												<select
													name="idListaPrecios"
													className="input-label-style"
													value={selectIds.idListaPrecios}
													onChange={(e) => {
														if (e.target.value !== '') {
															setSelectIds({
																...selectIds,
																idListaPrecios: e.target.value
															});
														}
													}}
												>
													<option value="">
														<FormattedMessage
															id="input.placeholder.select"
															defaultMessage="Selecciona una opción"
														/>
													</option>
													{listPriceAux.map((item) => (
														<option key={item.value} value={item.value}>
															{item.label}
														</option>
													))}
												</select>
											</label>
										</section>
										{/* exped num and type service */}
										<section className="grid-container-2c">
											<label className="d-flex custom-margin-0100-s">
												<p className="text-inline margin-bottom-1">
													{formatterText('p.label.expedient')}
												</p>
												<section className="w100-container">
													<input
														className={
															checkoutValidations.equalsExp
																? 'input-default input-default-disable'
																: 'input-default'
														}
														style={{ width: '100%' }}
														type="text"
														name="expediente"
														value={formItem.expediente}
														onChange={(e) => {
															if (e.target.value.match(/^\w*$/)) {
																setFormItem({
																	...formItem,
																	[e.target.name]: e.target.value
																});
															}
														}}
														placeholder={formatterText('p.label.expedient')}
														disabled={checkoutValidations.equalsExp}
													/>

													<label className="label-position">
														<input
															type="checkbox"
															name="equalsExp"
															className=""
															onChange={(e) => {
																setCheckoutValidations((prev) => {
																	if (e.target.checked) {
																		setFormItem({
																			...formItem,
																			expediente: ''
																		});
																	}

																	return {
																		...prev,
																		[e.target.name]: e.target.checked
																	};
																});
															}}
														/>
														<p className="text-checkout">{formatterText('p.label.no.record')}</p>
													</label>
												</section>
											</label>

											<label className="d-flex">
												<p className="text-inline margin-bottom-1">
													{formatterText('table.title.type.service')}
												</p>
												<section className="w100-container">
													<SelectorGrid
														name="idTipoServicio"
														data={selectedSearch.typeService}
														placeholder={
															<FormattedMessage
																id="input.placeholder.select"
																defaultMessage="Selecciona una opción"
															/>
														}
														dataValue={selectIds.idTipoServicio}
														setterFunction={setSelectIds}
													/>
													<label className="label-position">
														{clientSelected.esVip === 1 && (
															<>
																<input
																	type="checkbox"
																	name="specialService"
																	className=""
																	onChange={() => {
																		setCheckoutValidations((prev) => {
																			return {
																				...prev,
																				specialService: true
																			};
																		});
																	}}
																	checked={true}
																/>
																<p className="text-checkout">
																	{formatterText('title.service.isSpecialService')}
																</p>
															</>
														)}
													</label>
													<label className="label-position">
														{userSelected.esVip === 1 && (
															<>
																<input
																	type="checkbox"
																	name="specialService"
																	className=""
																	onChange={() => {
																		setCheckoutValidations((prev) => {
																			return {
																				...prev,
																				specialService: true
																			};
																		});
																	}}
																	checked={true}
																/>
																<p className="text-checkout">
																	{formatterText('title.service.isSpecialService')}
																</p>
															</>
														)}
													</label>
												</section>
											</label>
										</section>
										{/* description */}
										<section
											className=""
											style={{
												display: 'flex',
												flexDirection: 'row',
												flexWrap: 'wrap',
												fontFamily: 'inherit',
												justifyContent: 'space-between',
												alignItems: 'center',
												width: '100%'
											}}
										>
											<section className="container-description">
												<p
													className="p-styles"
													style={{
														textDecoration: 'none',
														fontSize: '1rem',
														fontWeight: 600,
														color: 'var(--dark-gray)'
													}}
												>
													{formatterText('table.title.description')}
												</p>
												<textarea
													className="input-default-textarea"
													name="descripcion"
													value={formItem.descripcion}
													onChange={handlerTextDescription}
													placeholder={formatterText('table.title.description')}
													maxLength="200"
													required
												/>
											</section>
										</section>

										<section className="grid-container-2c">
											<label className="d-flex">
												<p className="text-inline">{formatterText('p.label.comunication.media')}</p>
												<SelectorGrid
													name="idMedioComunicacion"
													data={selectedSearch.communicationMedia}
													placeholder={
														<FormattedMessage
															id="input.placeholder.select"
															defaultMessage="Selecciona una opción"
														/>
													}
													dataValue={selectIds.idMedioComunicacion}
													setterFunction={setSelectIds}
													isRequired={true}
												/>
											</label>

											<label className="d-flex">
												<p className="text-inline">
													{formatterText('p.label.comunication.channel')}
												</p>
												<SelectorGrid
													name="idCanalComunicacion"
													data={selectedSearch.communicationChannel}
													placeholder={
														<FormattedMessage
															id="input.placeholder.select"
															defaultMessage="Selecciona una opción"
														/>
													}
													dataValue={selectIds.idCanalComunicacion}
													setterFunction={setSelectIds}
													isRequired={true}
												/>
											</label>

											<label className="d-flex">
												<p className="text-inline">{formatterText('p.label.date.appointment')}</p>
												<input
													className="input-date"
													type="date"
													name="fechaCita"
													value={eventData.fechaCita}
													onChange={handleDate}
													min={dateFormat(
														new Date(now.getFullYear(), now.getMonth(), now.getDate() - 60),
														'yyyy-mm-dd'
													)}
												/>
											</label>
											<label className="d-flex">
												<p className="text-inline">{formatterText('p.label.hour.appointment')}</p>
												<TimePicker
													name="idHoraCita"
													ampm={false}
													placeholder={
														<FormattedMessage
															id="input.placeholder.select"
															defaultMessage="Selecciona una opción"
														/>
													}
													value={time}
													onChange={handleDate}
												/>
											</label>
										</section>

										<section className="grid-container-2c">
											<label className="d-flex">
												<section className="box-text-container">
													<p className="text-inline">{formatterText('p.label.billing.user')}</p>
													{/* open modal of billing user */}
													<p
														onClick={(e) => {
															e.preventDefault();
															onToggleBillingUser();
														}}
														className="p-review"
													>
														{formatterText('text.details.view.more')}
													</p>
												</section>

												<div className="wrap-input">
													<div className="input-label-icon">
														<input
															type="text"
															name="idUsuarioFactura"
															className="input-label-style"
															value={valueSetter(billingUserSelected.nombre)}
															required
															disabled
														/>
														<div className="btn-action-style">
															{billingUserSelected.id ? (
																<MdOutlineRemoveCircleOutline
																	size={25}
																	color="gray"
																	cursor="pointer"
																	onClick={(e) => {
																		new Promise((resolve) => {
																			e.preventDefault();
																			resolve();
																		}).then(() => {
																			setBillingUserSelected({
																				nombre: 'Seleccione un usuario (Opcional)',
																				id: null
																			});
																		});
																	}}
																/>
															) : (
																<MdOutlineAddCircleOutline
																	size={25}
																	color="gray"
																	cursor="pointer"
																	onClick={(e) => {
																		new Promise((resolve) => {
																			e.preventDefault();
																			resolve();
																		}).then(() => {
																			onToggleBillingUser();
																		});
																	}}
																/>
															)}
														</div>
													</div>
												</div>
											</label>
										</section>
									</section>
								</section>
							</FormizStep>
							{/* 2: Attachment files */}
							<FormizStep name="step2" label="tab.title.attached.data">
								<div className="edit&btnAContainer"></div>
								<div>
									<div>
										<h3 className="p-styles spacing-l1 primary-green">
											{formatterText('p.label.attachment.files')}{' '}
										</h3>
									</div>
									<div className="btnAdjuntarContainer">
										<label
											style={{
												display: 'block',
												background: '#EFEEEE',
												boder: '1px',
												borderStyle: 'dashed',
												borderColor: '#747474',
												margin: '15px auto',
												minHeight: '150px',
												borderRadius: '10px',
												maxWidth: '800px'
											}}
											className="custom-file-upload"
										>
											<input
												id="file-input"
												style={{ display: 'none' }}
												accept=".png , .jpg , .tiff , .mp4, .pdf"
												type="file"
												name="files"
												value=""
												onChange={uploadFromCreate}
												ref={fileInput}
											/>
											<div
												style={{
													display: 'flex',
													flexFlow: 'row wrap'
												}}
											>
												{filesOnCreate.map((item, index) => (
													<div
														key={`${index + 1}`}
														style={{
															backgroundColor: '#FFFFFF',
															borderRadius: '10px',
															boder: '1px',
															borderStyle: 'solid',
															borderColor: '#D9D9D9',
															padding: '5px',
															paddingRight: '10px',
															marginRight: '20px',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'space-between'
														}}
													>
														<p
															style={{
																cursor: 'pointer'
															}}
															onClick={(e) => {
																e.preventDefault();
															}}
														>
															{item.name}
														</p>
														<MdDelete
															size={25}
															color="red"
															style={{
																marginLeft: '10px',
																zIndex: '999'
															}}
															cursor="pointer"
															onClick={(e) => {
																e.preventDefault();
																deleteFile(e, item);
															}}
														/>
													</div>
												))}
											</div>
										</label>
									</div>
									<div
										style={{
											width: '80%',
											display: 'flex',
											margin: '0 auto',
											justifyContent: 'flex-end',
											marginTop: '25px',
											marginBottom: '25px'
										}}
									>
										<button
											onClick={(e) => {
												e.preventDefault();
												fileInput.current.click();
											}}
											style={{ width: 'fit-content' }}
											className="btn-primary"
										>
											<FormattedMessage id="btn.upload.files" defaultMessage="Subir archivos" />
										</button>
									</div>
								</div>
							</FormizStep>
						</div>
					</form>
				</Formiz>
				<div className="demo-form__footer">
					<section className="form-responsive-container-buttons">
						<button
							style={{ padding: '0px' }}
							onClick={(e) => {
								e.preventDefault();
								handleSubmit();
							}}
							className="btn-primary"
							disabled={loadingValidation}
						>
							<label className="btn-wrap-add">
								{loadingValidation ? 'Comprobando...' : formatterText('text.create.service')}
							</label>
						</button>
						<button className="input-cancel" onClick={() => navigate(paths.serviceModule)}>
							{formatterText('btn.cancel')}
						</button>
					</section>
				</div>
			</div>
			{/* Client system modal */}
			<Modal
				Modal
				open={openClient}
				onClose={onCloseClient}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<ClientModal onClose={onCloseClient} exeFun={getAllUsersServices} />
			</Modal>
			{/* External external user modal */}
			<Modal
				open={openExternalUser}
				onClose={onCloseExternalUser}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<ExternalUserModal onClose={onCloseExternalUser} exeFun={getAllUsersServices} />
			</Modal>
			{/* Billing user modal */}
			<Modal
				open={openBillingUser}
				onClose={onToggleBillingUser}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<ModalBillingUser onClose={onToggleBillingUser} exeFun={getAllUsersServices} />
			</Modal>
			{/* User system modal */}
			<Modal
				open={openSystemUser}
				onClose={onCloseSystemUser}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<ModalUserSystemSearch onClose={onCloseSystemUser} exeFun={getAllUsersServices} />
			</Modal>
			{/* Address modal */}
			<Modal
				open={openAddress}
				onClose={onCloseAddress}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<AddressModal onClose={onCloseAddress} exeFun={showAddress} />
			</Modal>
			{/* Search data modal */}
			<Modal
				open={openSearch}
				onClose={onCloseSearch}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<ModalSearch
					onClose={onCloseSearch}
					exeFun={getAllUsersServices}
					serviceId={idService}
					permissions={{
						automatic: searchAutomatic,
						manual: manualSearch
					}}
				/>
			</Modal>
		</>
	);
};

export default CreateService;
