import useAxios from 'hooks/useAxios';
import React, { useEffect, useState } from 'react';
// Import Hooks
import useLangv2, { formatterText } from 'hooks/useLangv2';
import { useNavigate, useParams } from 'react-router-dom';
// Import Services
import endpoints from 'services/api';
import paths from 'services/paths';

// Import Libs
import { Modal } from 'react-responsive-modal';
import ModalSearch from '../../Modals/ModalSearch';
// import Screens
import UpdateDatimeAlert from 'screens/services/Modals/UpdateDatimeAlert';

export default function TechnicalAssign({
	serviceState,
	setPpTech,
	ppTech,
	getDataToUpdate,
	onOpenSearch,
	searchAutomatic = false,
	manualSearch = false,
	isDeleteTechMain = false
}) {
	const { RequestUseCase } = useAxios();

	const navigate = useNavigate();
	// get id from the url
	const { id } = useParams();
	// getall technicals services
	useEffect(() => {
		getAllTechnicalsServices();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getAllTechnicalsServices();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	// use Hook of language v2
	const { successRemoveItem, errorProcess, customSB } = useLangv2();
	const [changeDateTime, setChangeDateTime] = useState(false);

	const [openSearch, setOpenSearch] = useState(false);
	const onOpenSearchModal = () => setOpenSearch(true);
	const onCloseSearchModal = () => setOpenSearch(false);

	const onCloseChangeDateTime = () => setChangeDateTime(false);

	const [infoToChange, setInfoToChange] = useState({
		principal: false,
		idTecnico: -1,
		idCitaServicioTecnico: -1,
		idServicio: -1
	});

	const [auxTech, setAuxTech] = useState([]);

	const [listTechs, setListTechs] = useState([]);
	const [auxLoading, setAuxLoading] = useState(false);

	const canModifyDate = {
		2: true, //asignado
		3: true, // en desplazamiento
		4: true // en sitio
	};

	const getAllTechnicalsServices = () => {
		RequestUseCase.get(endpoints.technicalServices.getTechnicalsAssignedByService(id))
			.then((data) => {
				const auxArrayTech = [];
				const listTechs = [];
				if (data.length !== 0) {
					const promises = [];

					data.forEach((item) => {
						let idtech;
						let label;

						if (item.idTecnico.idEmpleado !== null) {
							label = `${item.idTecnico.idEmpleado.primerNombre} - ${item.idTecnico.idEmpleado.primerApellido}`;
							idtech = item.idTecnico.idTecnico;
						} else {
							idtech = item.idTecnico.idTecnico;
							label = `${item.idTecnico.idTercero.nombre} - ${item.idTecnico.idTercero.identificacion}`;
						}

						if (item.principal === 1) {
							setPpTech({
								idtech,
								label,
								idServicioTecnico: item.idServicioTecnico
							});
							listTechs.push(item.idTecnico.idTecnico);
						} else {
							const idFin = item.idServicioTecnico;
							const idCita = item.idCitaServicioTecnico;

							const promise = RequestUseCase.post(endpoints.services.getStatusAux, {
								id_servicio: id,
								id_tecnico: item.idTecnico.idTecnico
							}).then((data) => {
								const newAuxTech = {
									idFin,
									idCita,
									idtech, // Usa la variable local idtech
									statusOnServ: data?.idEstadoServicio?.idEstadoServicio,
									label: label,
									date: new Date(item.idServicio.fechaCita).toISOString().split('T')[0],
									hour: item?.idServicio?.idHoraCita?.hora || null,
									idServicioTecnico: data?.idServicioTecnico?.idServicioTecnico
								};
								auxArrayTech.push(newAuxTech);
								listTechs.push(item.idTecnico.idTecnico);
							});

							promises.push(promise);
						}
					});

					Promise.all(promises)
						.then(() => {
							setAuxTech(auxArrayTech);
							setListTechs(listTechs.join(','));
							setAuxLoading(true);
						})
						.catch((err) => {
							console.log(err);
						});
				} else {
					const idtech = -1;
					const label = '';
					setPpTech({
						idtech,
						label
					});
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const [techAux, setTechAux] = useState(false);

	const delteTech = ({ idServicioTecnico, esPrincipal = false }) => {
		RequestUseCase.delete(endpoints.services.deleteAuxTechnician(idServicioTecnico))
			.then(() => {
				successRemoveItem();
				if (esPrincipal) setTechAux(false);
				if (esPrincipal) onOpenSearchModal();
				getAllTechnicalsServices();
			})
			.catch(() => {
				errorProcess();
			});
	};

	const renderButonDelete = (idServTec, principal = false) => {
		return (
			<button
				className="p-styles"
				style={{
					color: '#58585B',
					fontSize: '0.9rem',
					textDecoration: 'underline',
					cursor: 'pointer',
					border: 'none',
					background: 'none',
					marginLeft: '1.5rem'
				}}
				onClick={(e) => {
					e.preventDefault();
					delteTech({ idServicioTecnico: idServTec, esPrincipal: principal });
				}}
			>
				{formatterText('btn.delete')}
			</button>
		);
	};

	return (
		<section className="container-tech-assign">
			<button
				className="btn-action-style-s"
				onClick={(e) => {
					e.preventDefault();
					if (ppTech.idtech === -1) {
						localStorage.setItem('idService', id);
						navigate(paths.searchPrincialTechnical.replace(':id', id));
					} else {
						const STATE_SERVICE_ALLOWED = {
							2: true,
							3: true,
							4: true,
							6: true
						};

						const res = STATE_SERVICE_ALLOWED[serviceState] || false;

						if (res) {
							setTechAux(true);
							onOpenSearchModal();
						} else {
							// in S09 HU01 only open AuxTech if the service is in state 2,3,4,6
							customSB(
								'warning',
								'snackbar.warning.aux.state.service',
								'No se puede asignar un técnico auxiliar con el actual estado del servicio.'
							);
						}
					}
				}}
			>
				{formatterText('btn.service.edit.find.tech')}
			</button>
			{serviceState === 1 && (
				<button
					className="btn-action-style-s"
					style={{ marginLeft: '1rem' }}
					onClick={(e) => {
						e.preventDefault();
						window.open(`#${paths.reviewMap}?idService=${id}&assigned=false`, '_blank');
					}}
				>
					{formatterText('btn.service.view.map')}
				</button>
			)}
			{ppTech.idtech === -1 && auxTech.length === 0 ? (
				<p>{formatterText('title.service.no.techs.available')}</p>
			) : (
				ppTech.idtech !== -1 && (
					<div className="assign-table-divs">
						<p className="p-styles" style={{ color: '#58585B' }}>
							{ppTech.label} (Principal)
						</p>
						<button
							className="btn-action-style-s"
							style={{ marginLeft: '1rem' }}
							onClick={(e) => {
								e.preventDefault();
								window.open(
									`#${paths.reviewMap}?idService=${id}&assigned=true&list=${listTechs}`,
									'_blank'
								);
							}}
						>
							{formatterText('btn.service.view.map')}
						</button>
						{isDeleteTechMain && renderButonDelete(ppTech.idServicioTecnico, true)}
					</div>
				)
			)}
			{auxLoading &&
				auxTech.map((item, index) => {
					// if the prev item, its the same idtech then dont show the label
					if (item.idtech === auxTech[index - 1]?.idtech) {
						return null;
					}
					return (
						<div className="assign-table-divs" style={{ alignItems: 'center' }} key={item.label}>
							<p className="p-styles" style={{ color: '#58585B' }}>
								{item.label} ({formatterText('label.service.edit.tech.role')})
							</p>
							{canModifyDate[item.statusOnServ] && item.date !== null && (
								<>
									<p
										className="p-styles"
										style={{
											color: '#58585B',
											fontSize: '0.9rem',
											padding: '0rem 1.5rem'
										}}
									>
										{item.date.replace(/-/g, '/')}{' '}
										{formatterText('text.shedule.at.time.zone.schedule')} {item.hour}:00
									</p>

									<button
										className="p-styles"
										style={{
											color: '#58585B',
											fontSize: '0.9rem',
											textDecoration: 'underline',
											cursor: 'pointer',
											background: 'none',
											border: 'none'
										}}
										onClick={(e) => {
											e.preventDefault();
											setInfoToChange({
												principal: false,
												idTecnico: item.idtech,
												idCitaServicioTecnico: item.idCita,
												idServicio: item.idServicioTecnico
											});
											setChangeDateTime(true);
										}}
									>
										{formatterText('btn.link.change.date.service')}
									</button>
									{renderButonDelete(item.idServicioTecnico)}
								</>
							)}
						</div>
					);
				})}
			<Modal
				open={changeDateTime}
				onClose={onCloseChangeDateTime}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<section>
					<UpdateDatimeAlert
						onClose={onCloseChangeDateTime}
						infoToChange={infoToChange}
						WayChange={true}
						getDataToUpdate={getDataToUpdate}
						getAllTechnicalsServices={getAllTechnicalsServices}
						OpenSearch={onOpenSearch}
						idTecnico={infoToChange.idTecnico}
						idCitaServicioTecnico={infoToChange.idCitaServicioTecnico}
					/>
				</section>
			</Modal>
			<Modal
				open={openSearch}
				onClose={onCloseSearchModal}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				<ModalSearch
					onClose={onCloseSearchModal}
					//exeFun={getAllUsersServices}
					serviceId={id}
					permissions={{
						automatic: searchAutomatic,
						manual: manualSearch
					}}
					auxTechnical={techAux}
				/>
			</Modal>
		</section>
	);
}
