import React from 'react';
// Import Context
// Import Components
import DynamicTable from 'common/DynamicTable/DynamicHead';
// Import libs
import { FormattedMessage } from 'react-intl';
// Import services

import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';
import paths from 'services/paths';

import ActivityTableViewModel from './ActivityTable.viewModel';

const titles = [
	<FormattedMessage
		key={'categoria_asociada'}
		id="tab.title.assciated.category"
		defaultMessage="Categoría asociada"
	/>,
	<FormattedMessage
		key={'tipo_servicio'}
		id="table.title.type.service"
		defaultMessage="Tipo de servicio"
	/>,
	<FormattedMessage
		key={'etapa_servicio'}
		id="text.service.stage"
		defaultMessage="Etapa del servicio"
	/>,
	<FormattedMessage
		key={'nombre_actividad'}
		id="tab.title.activityname"
		defaultMessage="Nombre Actividad"
	/>,
	<FormattedMessage
		key={'descripcion'}
		id="table.title.description"
		defaultMessage="Descripción"
	/>,
	'SKU',
	/*  "IVA", */
	<FormattedMessage
		key={'minutos_desarrollo'}
		id="tab.title.developtime"
		defaultMessage="Minutos de desarrollo"
	/>,
	<FormattedMessage
		key={'aspectos_tecnicos'}
		id="tab.title.technicalaspects"
		defaultMessage="Aspectos técnico"
	/>,
	<FormattedMessage key={'estado'} id="table.title.state" defaultMessage="Estado" />
];

const ActivityTable = () => {
	const {
		loading,
		error,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		displayMessage,
		displayLoading
	} = ActivityTableViewModel();
	if (loading) {
		if (error) {
			return displayMessage(
				'error',
				'Ha ocurrido un error, intentalo más tarde.',
				'toast.error.general'
			);
		}
		return displayLoading();
	}

	return (
		<FormattedMessage id="table.name.search.product" defaultMessage="Categoría de producto">
			{() => (
				<DynamicTable
					titles={titles}
					pageName={PAGE_NAMES.Actividades}
					getData={getDataTable}
					handleDeleteItem={handleDeleteItem}
					handleEditStateItem={handleEditStateItem}
					routeToEdit={paths.updateActivityService}
				/>
			)}
		</FormattedMessage>
	);
};

export default ActivityTable;
