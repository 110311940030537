import React from 'react';

// Import Libs
import { FormattedMessage } from 'react-intl';

// Import Paths
import paths from 'services/paths';

//Import ViewModel
import UpdateContractViewModal from './UpdateContract.viewModal';

import styles from './UpdateContract.module.css';

export default function UpdateContract() {
	const {
		active,
		codeError,
		descriptionError,
		errorName,
		formatterText,
		formData,
		handleChange,
		handleErrorCode,
		handleErrordescripcion,
		handleErrorName,
		handlerTextDescription,
		handleSubmit,
		handleText,
		navigate,
		setActive
	} = UpdateContractViewModal();

	return (
		<form className="form-responsive" onSubmit={handleSubmit}>
			<section className="form-responsive-container-information">
				<section className="form-responsive-information__option">
					<span className="warpForm-text">
						<FormattedMessage
							id="table.title.contract.type.name"
							defaultMessage="Nombre del tipo de contrato"
						/>
					</span>
					<input
						className="input-primary"
						type="text"
						name="nombre"
						value={formData.nombre}
						onChange={handleText}
						placeholder={formatterText(
							'table.title.contract.type.name',
							'Nombre del tipo de contrato'
						)}
						maxLength="100"
						onBlur={handleErrorName}
					/>
				</section>
				{errorName && <div className={styles.errorName}>{errorName}</div>}
				<section className="form-responsive-information__option">
					<span className="p-styles">
						<FormattedMessage id="table.title.description" defaultMessage="Descripción" />
					</span>
					<textarea
						className="input-textarea-sm"
						name="descripcion"
						value={formData.descripcion}
						onChange={handlerTextDescription}
						placeholder={formatterText('table.title.description', 'Descripción')}
						maxLength="200"
						onBlur={handleErrordescripcion}
					/>
				</section>
				{descriptionError && <div className={styles.errorDescription}>{descriptionError}</div>}
				<section className="form-responsive-information__option">
					<span className="p-styles">
						<FormattedMessage id="table.title.code" defaultMessage="Código" />
					</span>
					<input
						className="input-primary"
						type="text"
						name="codigo"
						value={formData.codigo}
						onChange={handleChange}
						placeholder={formatterText(
							'input.placeholder.contract.type.code',
							'Código del tipo de contrato'
						)}
						maxLength="45"
						onBlur={handleErrorCode}
					/>
				</section>
				{codeError && <div className={styles.errorCode}>{codeError}</div>}
				<section className="form-responsive-information__option">
					<span className="p-styles">
						<FormattedMessage id="table.title.state" defaultMessage="Estado" />
					</span>
					<label className="form-responsive-label">
						<p className="form-responsive-toggle">
							{active
								? formatterText('p.active', 'Activo')
								: formatterText('p.unActive', 'No activo')}
						</p>
						<label className="switch">
							<input
								checked={active ? true : false}
								onChange={() => {
									setActive(!active);
								}}
								type="checkbox"
							/>
							<span className="slider round"></span>
						</label>
					</label>
				</section>
			</section>
			<section className="form-responsive-container-buttons">
				<button type="submit" className="btn-primary">
					<FormattedMessage id="btn.save.changes" defaultMessage="Guardar cambios" />
				</button>
				<button className="input-cancel" onClick={() => navigate(paths.contracts)}>
					<FormattedMessage id="btn.cancel" defaultMessage="Cancelar" />
				</button>
			</section>
		</form>
	);
}
