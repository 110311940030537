import React from 'react';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';
import 'styles/loginRecover.css';
import ChangePasswordViewModel from './ChangePassword.viewModel';

export default function ChangePassword() {
	const {
		handleChange,
		restorePassword,
		isLoading,
		errorMessage,
		passwordCurrentShown,
		passwordNewShown,
		passwordConfirmedShown,
		togglePasswordCurrentVisiblity,
		togglePasswordNewVisibilty,
		togglePasswordConfirmedVisibilty,
		formatterText
	} = ChangePasswordViewModel();
	return (
		<section className="recover-password">
			<form className="recover-password-container" onSubmit={restorePassword} autoComplete="off">
				<h5 className="recover-password-title">
					<FormattedMessage id="ChangeFirstPassword.text1" defaultMessage="Cambia tu contraseña" />
				</h5>

				<div className="pass-wrapper">
					<input
						className="input-passwords"
						type={passwordCurrentShown ? 'text' : 'password'}
						placeholder={formatterText('title.user.currentPassword')}
						name="currentPassword"
						onChange={handleChange}
						maxLength="50"
						required
					/>
					{passwordCurrentShown ? (
						<IoMdEye size={30} className="eye-icon" onClick={togglePasswordCurrentVisiblity} />
					) : (
						<IoMdEyeOff size={30} className="eye-icon" onClick={togglePasswordCurrentVisiblity} />
					)}
				</div>
				<div className="pass-wrapper">
					<input
						className="input-passwords"
						type={passwordNewShown ? 'text' : 'password'}
						placeholder={formatterText('title.user.newPassword')}
						name="newPassword"
						onChange={handleChange}
						maxLength="50"
						required
					/>
					{passwordNewShown ? (
						<IoMdEye size={30} className="eye-icon" onClick={togglePasswordNewVisibilty} />
					) : (
						<IoMdEyeOff size={30} className="eye-icon" onClick={togglePasswordNewVisibilty} />
					)}
				</div>
				<div className="pass-wrapper mb-5">
					<input
						className="input-passwords"
						type={passwordConfirmedShown ? 'text' : 'password'}
						placeholder={formatterText('title.user.confirmNewPassword')}
						name="newPasswordConfirm"
						onChange={handleChange}
						maxLength="50"
						required
					/>
					{passwordConfirmedShown ? (
						<IoMdEye size={30} className="eye-icon" onClick={togglePasswordConfirmedVisibilty} />
					) : (
						<IoMdEyeOff size={30} className="eye-icon" onClick={togglePasswordConfirmedVisibilty} />
					)}
				</div>

				<button type="submit" className="btn-primary" disabled={isLoading}>
					{isLoading
						? `${formatterText('title.user.loading')}`
						: `${formatterText('title.user.changePassword')}`}
				</button>
				{errorMessage && (
					<ul className="error-message">
						<li>{formatterText('title.user.passwordRequirements')}</li>
						<li>{formatterText('title.user.passwordRequirement1')}</li>
						<li>{formatterText('title.user.passwordRequirement2')}</li>
						<li>{formatterText('title.user.passwordRequirement3')}</li>
						<li>{formatterText('title.user.passwordRequirement4')}</li>
					</ul>
				)}
			</form>
		</section>
	);
}
