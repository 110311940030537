import { AppContext } from 'context/AppContext';
import dateFormat from 'dateformat';
import useLangv2 from 'hooks/useLangv2';
import React, { useContext, useState } from 'react';

const NOW = new Date();

export default function ModalSearch({ onClose, setterFun, verifyTechAvailable }) {
	const [formData, setFormData] = useState([]);
	const { customSB } = useLangv2();
	const handleDate = (e) => {
		setFormData({ ...formData, [e.target.name]: new Date(e.target.value) });
		setDate({ ...formData, [e.target.name]: new Date(e.target.value) });
	};
	const { setDate, date } = useContext(AppContext);

	console.log(date);

	const buildFormData = (formData) => {
		if (Array.isArray(formData)) {
			customSB(
				'error',
				'snackbar.warning.complete.field.date',
				'Ingresa la fecha y/o Hora de la cita'
			);
			return;
		}
		const hora = dateFormat(formData.fechaFin, 'HH:MM');
		const fecha = dateFormat(formData.fechaFin, 'yyyy-mm-dd');

		setterFun((prev) => ({ ...prev, fechaCita: fecha, horaCita: hora }));
		onClose();
		verifyTechAvailable(fecha, hora);
	};

	return (
		<>
			<label className="center-container-modal">
				<span
					style={{
						textAlign: 'center'
					}}
					className="title-table"
				>
					Cambiar la fecha y hora de la cita
				</span>
				<input
					className="input-primary-wrap"
					name="fechaFin"
					label="Fecha de Servicio"
					type="dateTime-local"
					min={dateFormat(
						new Date(NOW.getFullYear() - 5, NOW.getMonth(), NOW.getDate()),
						'yyyy-mm-dd'
					)}
					onChange={handleDate}
					required
				/>
			</label>
			<section className="form-responsive-container-buttons">
				<button className="btn-action-primary" onClick={() => buildFormData(formData)}>
					Aceptar
				</button>
				<button className="btn-action-cancel" onClick={onClose}>
					Cancelar
				</button>
			</section>
		</>
	);
}
