import React from 'react';
// Import Components
import Selector from 'common/selects/Selector';
//Import ViewModel
import { UpdateCostAssociateProductProps } from './UpdateCostAssociateProduct.props';
import UpdateCostAssociateProductViewModel from './UpdateCostAssociateProduct.viewModel';

export default function UpdateCostAssociateProduct({
	costoTabla,
	data,
	dataProviders,
	dataUnits,
	getDataMiniTable,
	onClose
}) {
	const {
		handleUpdateModalData,
		formatterText,
		currectCostProduct,
		auxData,
		setAuxData,
		formatCurrencyFromModal,
		costoModal,
		handleNumber,
		selectedSearch,
		handleIvaChange,
		formatIvaField,
		ivaModal
	} = UpdateCostAssociateProductViewModel({
		costoTabla,
		data,
		dataProviders,
		dataUnits,
		getDataMiniTable,
		onClose
	});

	return (
		<form className="form-responsive" onSubmit={handleUpdateModalData}>
			<section className="form-responsive-container-information">
				<label className="form-responsive-information__option">
					<h3>{formatterText('p.product.unit.meassurement', 'Unidad de medida')}</h3>
					<Selector
						name="idUnidadMedida"
						data={dataUnits}
						placeholder={currectCostProduct.label_idUnidadMedida}
						dataValue={auxData}
						setterFunction={setAuxData}
						selectValue={auxData.idUnidadMedida}
						isRequired={false}
					/>
				</label>
				<label className="form-responsive-information__option">
					<h3>{formatterText('table.title.provider', 'Proveedor')}</h3>
					<Selector
						name="idProveedor"
						data={dataProviders}
						placeholder={currectCostProduct.label_idProveedor}
						dataValue={auxData}
						setterFunction={setAuxData}
						selectValue={auxData.idProveedor}
						isRequired={false}
					/>
				</label>
				<label className="form-responsive-information__option">
					<h3>{formatterText('table.title.cost', 'Costo')}</h3>
					<input
						className="input-primary"
						type="text"
						name="costo"
						value={formatCurrencyFromModal(costoModal)}
						onChange={handleNumber}
						placeholder="Costo"
						maxLength="20"
					/>
				</label>
				<label className="form-responsive-information__option">
					<h3>{formatterText('table.title.currency', 'Moneda')}</h3>
					<Selector
						className="input-primary"
						type="text"
						name="idMoneda"
						data={selectedSearch.tipoMonedas}
						selectValue={auxData.idMoneda}
						placeholder={currectCostProduct.label_idMoneda}
						setterFunction={setAuxData}
						maxLength="20"
					/>
				</label>

				<label className="form-responsive-information__option">
					<h3>{formatterText('table.title.iva', 'Iva(%)')}</h3>
					<input
						className="input-primary"
						type="text"
						name="iva"
						onChange={handleIvaChange}
						maxLength="6"
						value={formatIvaField(ivaModal)}
					/>
				</label>

				<input
					type="submit"
					className="width-100-fix"
					value={formatterText('p.product.update.cost.button', 'Actualizar Costo producto')}
					onClick={handleUpdateModalData}
				/>
				<input
					type="button"
					className="width-100-fix input-cancel"
					value={formatterText('btn.cancel', 'Cancelar')}
					onClick={onClose}
				/>
			</section>
		</form>
	);
}

UpdateCostAssociateProduct.prototype = UpdateCostAssociateProductProps;
