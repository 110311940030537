import React from 'react';

// Import Libs
import { FormattedMessage } from 'react-intl';

// Import Components
import DynamicTable from 'common/DynamicTable/DynamicHead';

import paths from 'services/paths';

// Import Constants
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';

// Import PropTypes
import { ParametersProps } from './Parameters.props';

// Import ViewModel
import ParametersViewModel from './Parameters.viewModel';

const Parameters = ({ permisos }) => {
	const {
		displayLoading,
		displayMessage,
		error,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		loading,
		parameters,
		titles
	} = ParametersViewModel();

	return (
		<>
			{!loading && parameters !== null ? (
				<FormattedMessage id="table.name.search.parameter" defaultMessage="Parámetros">
					{() => (
						<DynamicTable
							titles={titles}
							pageName={PAGE_NAMES.Terceros}
							data={getDataTable}
							handleDeleteItem={handleDeleteItem}
							handleEditStateItem={handleEditStateItem}
							routeToEdit={paths.updateParameter}
							canDeleted={permisos.eliminar}
							canModify={permisos.editar}
						/>
					)}
				</FormattedMessage>
			) : error ? (
				displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			) : (
				displayLoading()
			)}
		</>
	);
};

Parameters.propTypes = ParametersProps;

export default Parameters;
