import React, { useState } from 'react';
// Import Libs
import PropTypes from 'prop-types';
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md';

const SideNavButton = ({ children, title }) => {
	const [checked, setChecked] = useState(false);

	return (
		<div>
			<nav className="dropdown">
				<label
					onClick={() => {
						setChecked(!checked);
					}}
					style={{
						width: '100%',
						height: '48px',
						display: 'flex',
						alignItems: 'center'
					}}
					htmlFor="touch"
				>
					<span className="dropdown__title">
						{checked ? (
							<MdKeyboardArrowDown size={25} color="white" cursor="pointer" />
						) : (
							<MdKeyboardArrowRight size={25} color="white" cursor="pointer" />
						)}

						{title}
					</span>
				</label>

				<ul className={checked ? 'close__button slide' : 'open__button slide'}>{children}</ul>
			</nav>
		</div>
	);
};

SideNavButton.propTypes = {
	children: PropTypes.node,
	title: PropTypes.object
};

export default SideNavButton;
