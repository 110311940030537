import useAxios from 'hooks/useAxios';
import { MODULES_NAME } from 'common/validators/PermissionsConstants';
import { usePermissionContext } from 'context/PermissionContext';
import { useSeachContext } from 'context/SearchContext';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import { useEffect, useState } from 'react';

export default function ProviderPageViewModel() {
	const { RequestUseCase, endpoints } = useAxios();

	const { loading, error, toggleLoading, displayMessage, displayLoading } = useGetData();
	const { setDataTable, searchResults = [] } = useSeachContext();
	const { formatterText } = useLangv2();
	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();
	const [clients, setClients] = useState(null);

	// titulos de la tabla
	const titles = [
		formatterText('table.title.provider.name', 'Nombre proveedor'),
		formatterText('text.ID', 'Identificación'),
		formatterText('table.title.website', 'Sitio web'),
		formatterText('table.title.phone', 'Teléfono'),
		formatterText('table.title.email', 'Correo electrónico'),
		formatterText('table.title.municipality', 'Municipio'),
		formatterText('table.title.address', 'Dirección'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];

	const permissionsAccessGaranted = () => {
		permissionsAccess(MODULES_NAME.providers);
	};

	useEffect(() => {
		permissionsAccessGaranted();
	}, [permissions]);

	useEffect(() => {
		getDataTable();
	}, []);

	// función para cargar datos
	const getDataTable = () => {
		// show loading
		toggleLoading(true);
		RequestUseCase.get(endpoints.providers.getAllProviders).then((data) => {
			const newArray = [];
			data.forEach((item) => handleStructureItems(newArray, item));
			setDataTable(newArray);
			// show loading
			toggleLoading(false);
			setClients(newArray);
		});
	};

	const handleDeleteItem = (rowId) => {
		return new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.providers.deleteProvider(rowId))
				.then((res) => {
					const newArray = [];
					res.forEach((item) => handleStructureItems(newArray, item));
					setClients(newArray);
					setDataTable(newArray);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const handleEditStateItem = (body) => {
		return new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.providers.updateProvider, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const handleStructureItems = (newArray, item) => {
		newArray.push({
			id: item.idProveedor,
			nombre: item.nombreRazonSocial,
			identificacion: item.identificacion,
			sitioWeb: item.sitioWeb,
			telefono: item.telefono,
			correo: item.correo,
			municipio: item.idMunicipio.nombre,
			direccion: item.direccion,
			estado: item.estado,
			objeto: { ...item }
		});
	};

	const renderMessage = () => {
		return error
			? displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			: displayLoading();
	};
	return {
		formatterText,
		loading,
		clients,
		getDataTable,
		searchResults,
		handleDeleteItem,
		handleEditStateItem,
		renderMessage,
		permittedActions,
		titles
	};
}
