import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@formiz/core';

export const InputFieldDV = (props) => {
	const { errorMessage, id, isValid, isSubmitted, setValue } = useField(props);
	const { type, name, required, placeholder, disabled, values, labelText } = props;
	const [isTouched, setIsTouched] = React.useState(false);
	const showError = !isValid && (isTouched || isSubmitted);

	useEffect(() => {
		setValue(values);
	}, [values, setValue]);

	return (
		<label className="d-flex">
			<span className="text-inline">{labelText}</span>
			<section className="w100-container">
				<input
					id={id}
					type={type || 'text'}
					value={values || ''}
					name={name}
					onChange={(e) => {
						setValue(e.target.value);
					}}
					onBlur={() => setIsTouched(true)}
					placeholder={placeholder}
					className={disabled ? 'input-primary disable-input' : 'input-primary'}
					aria-invalid={showError}
					aria-required={!!required}
					aria-describedby={showError ? `${id}-error` : null}
					disabled={disabled}
				/>
				{showError && (
					<span id={`${id}-error`} className="error-msg">
						{errorMessage}
					</span>
				)}
			</section>
		</label>
	);
};

InputFieldDV.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string,
	required: PropTypes.string,
	placeholder: PropTypes.string,
	disabled: PropTypes.bool,
	values: PropTypes.string,
	labelText: PropTypes.string
};
