import MultiTableMinimal from 'common/minimalTables/MultiTableMinimal';
import Selector from 'common/selects/Selector';
import React, { useEffect, useState } from 'react';
// // Import services
import { useSeachContext } from 'context/SearchContext';
import useLangv2 from 'hooks/useLangv2';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import useAxios from 'hooks/useAxios';

const customStyles = {
	control: (base) => ({
		...base,
		width: '19em',
		margin: '15px 0 0 0',
		'border-radius': '70px',
		'box-shadow': '0px 4px 8px rgba(0, 174, 142, 0.2)',
		border: '0px !important',
		padding: '0 6px',
		'max-width': '20rem',
		'min-width': '225px'
	}),
	menu: (styles) => ({
		...styles,
		width: '19em'
	})
};
const AssociateCoverage = ({ data, setterFunction }) => {
	const { RequestUseCase, endpoints } = useAxios();
	const datos = data;
	const { formatterText, customSB } = useLangv2();
	const titlesTableUnityBusiness = [
		formatterText('table.title.municipality'),
		formatterText('title.table.customer.and.branches-city.state'),
		formatterText('p.country'),
		formatterText('title.table.date.register'),
		formatterText('table.actions')
	];
	const [auxData, setAuxData] = useState();
	const [selectedSearch, setSearchSelected] = useState();
	const { handleSearchCoverage } = useSeachContext();
	useEffect(() => {
		getDataTable();
	}, []);

	const getDataTable = () => {
		RequestUseCase.get(endpoints.zones.getAllMunicipalities)
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					if (item.estado === 1) {
						const data = {
							...item,
							value: item.idMunicipio,
							label: `${item.nombre} - ${item.idDepartamento.nombre} - ${item.idDepartamento.idPais.nombrePais}`,
							isFixed: true
						};
						newArray.push(data);
					}
				});
				setSearchSelected(newArray);
				setInitialSearch(newArray);
			})
			.catch(() => {});
	};
	const handleDelete = (row) => {
		const newMinimalRows = data.filter((et) => {
			return et.citySucursal.value !== row.citySucursal.value;
		});
		closeDialog();
		setterFunction(newMinimalRows);
	};

	const [dialog, setDialog] = useState({
		text: '',
		active: false,
		function: null
	});

	const closeDialog = () => {
		setDialog({ ...dialog, active: false });
	};

	const clickTable = () => {
		if (auxData !== undefined) {
			const comprobarExistencia = datos.find(
				(item) => item.citySucursal.value === auxData.idUnityBusiness.idMunicipio
			);
			if (comprobarExistencia !== undefined) {
				resetSelector();
				customSB(
					'warning',
					'snack.warning.coverage.already',
					'Ya existe la cobertura seleccionada'
				);
			} else {
				const data = auxData.idUnityBusiness;
				const newData = {
					id: 1,
					citySucursal: {
						value: data.idMunicipio,
						label: data.nombre
					},
					departamento: {
						value: data.idDepartamento.idDepartamento,
						label: data.idDepartamento.nombre
					},
					pais: {
						value: data.idDepartamento.idPais.idPais,
						label: data.idDepartamento.idPais.nombrePais
					},
					fecha: moment().format('DD/MM/YYYY HH:mm') //Falta fecha
				};
				setterFunction((prev) => [...prev, newData]);
			}
		} else {
			customSB('warning', 'snack.warning.coverage.blank', 'Por favor, selecciona una cobertura');
		}
	};

	const [initialSearch, setInitialSearch] = useState();

	const getSelects = () => {
		const newArray = [];
		if (initialSearch) {
			initialSearch.forEach((item) => {
				let flag = true;
				data.forEach((item2) => {
					if (item.idMunicipio === item2.citySucursal.value) {
						flag = false;
					}
				});
				if (flag) {
					newArray.push(item);
				}
			});
		}
		return newArray;
	};

	const resetSelector = () => {
		const print = getSelects();
		setSearchSelected(print);
	};

	useEffect(() => {
		if (data.length > 0) {
			const print = getSelects();
			setSearchSelected(print);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<>
			<div className="title-section">
				<span className="circle-form">
					<span>5</span>
				</span>
				<h2>
					<FormattedMessage id="p.assing.cobert" defaultMessage="Asignación de cobertura" />
				</h2>
			</div>
			<div className="container-technical-association">
				<span>
					<FormattedMessage id="tab.title.municipalities" defaultMessage="Municipios" />
				</span>
				<Selector
					customStyles={customStyles}
					name="idUnityBusiness"
					data={selectedSearch}
					placeholder={formatterText('p.select.option', 'Selecione una opción')}
					dataValue={auxData}
					setterFunction={setAuxData}
					isRequired={false}
					fullWidth={true}
				/>
				<button
					onClick={(e) => {
						e.preventDefault();
						clickTable();
					}}
					className="btn-secondary"
				>
					<FormattedMessage id="p.add" defaultMessage="Agregar" />
				</button>
			</div>
			{data.length > 0 && (
				<section className="form-responsive-container-information">
					<MultiTableMinimal
						titles={titlesTableUnityBusiness}
						data={data}
						type="associateCoverage"
						handleDelete={handleDelete}
						dialog={dialog}
						setDialog={setDialog}
						closeDialog={closeDialog}
						canSearch={true}
						handleSearch={handleSearchCoverage}
					/>
				</section>
			)}
		</>
	);
};

export default AssociateCoverage;
