import { useSeachContext } from 'context/SearchContext';
import { formatterText } from 'hooks/useLangv2';
import React, { useEffect } from 'react';
import DynamicTableServices from '../DynamicTableServices/DynamicHeadServices';

function SeguimientoProductos({ getDataTable }) {
	const tableTitles = [
		formatterText('p.product'),
		formatterText('usuario.modificacion.products'),
		formatterText('message.action.table'),
		formatterText('fecha.modificacion')
	];

	const { setDataTable } = useSeachContext();

	useEffect(() => {
		setDataTable([]);
	}, []);

	return (
		<div>
			{getDataTable && (
				<div className="tdR thR table-container">
					<DynamicTableServices
						titles={tableTitles}
						pageName={formatterText('title.product.traceability.page.name')}
						getData={getDataTable}
					/>
				</div>
			)}
		</div>
	);
}

export default SeguimientoProductos;
