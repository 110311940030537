import useAxios from 'hooks/useAxios';
import { useSeachContext } from 'context/SearchContext';
import { TableMinimalContext } from 'context/TableMinimalContext';
import useGetData from 'hooks/useGetData';
import React, { useContext } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import endpoints from 'services/api';

const TableAssociatePrices = ({ data, setDialog, closeDialog, handleOpen }) => {
	const { RequestUseCase } = useAxios();
	const { setDataTable } = useSeachContext();
	const { setModalData } = useContext(TableMinimalContext);
	const { loading, error, toggleLoading, displayMessage, displayLoading } = useGetData();
	// DELETE associatePrices
	const handleDelete = (column, index) => {
		toggleLoading(true);
		if (typeof column.idPrecioAsociado === 'number') {
			RequestUseCase.delete(endpoints.associatePrices.deleteAssociate(column.idPrecioAsociado))
				.then(() => {
					setDataTable(
						data.filter((_, i) => {
							return i !== index;
						})
					);
					toggleLoading(false);
				})
				.catch((err) => {
					console.log(err);
					toggleLoading(false);
				});
		} else {
			// filter in the array data the index selected and slice it
			setDataTable(
				data.filter((_, i) => {
					return i !== index;
				})
			);
			toggleLoading(false);
		}
		closeDialog();
	};

	const handleEdit = (column) => {
		handleOpen();
		setModalData(column);
	};

	return data.map((item, index) => (
		<>
			{!loading ? (
				<>
					<tr key={index}>
						<td>{item.valor}</td>
						<td>{item.idActividadAsociada.label}</td>
						<td>{item.idUnidadMedida.label}</td>
						<td className="minimal-table__actions">
							<MdEdit
								size={25}
								color="gray"
								cursor="pointer"
								disabled={loading}
								onClick={() => handleEdit(item)}
							/>
							<MdDelete
								size={25}
								color="gray"
								cursor="pointer"
								onClick={() =>
									setDialog({
										text: 'Vas a eliminar este registro. ¿Estás seguro?',
										active: true,
										action: 'delete',
										funcion: () => handleDelete(item, index)
									})
								}
							/>
						</td>
					</tr>
				</>
			) : error ? (
				displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			) : (
				displayLoading()
			)}
		</>
	));
};

export default TableAssociatePrices;
