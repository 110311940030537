import { AppContext } from 'context/AppContext';
import { useSeachContext } from 'context/SearchContext';
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';
import useProgress from 'hooks/useProgress';
import useToggle from 'hooks/useToggle';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import endpoints from 'services/api';

export default function ModalAssignTechnicalViewModel() {
	const { RequestUseCase } = useAxios();
	// Value that select use
	const [selectedSearch, setSearchSelected] = useState([
		{
			countries: [],
			departments: [],
			municipalities: [],
			typeDocument: []
		}
	]);
	// useToggle
	const [value, toggle] = useToggle();

	// Data from advance search inputs
	const [formFilters, setFormFilters] = useState({
		nombreTecnico: ''
	});

	// Data from advance search inputs
	const [documentForm, setDocumentForm] = useState({
		numeroDocumento: ''
	});

	// State of the labels and ids of the selectors
	const [selectValues, setSelectValues] = useState({
		idCategory: {
			id: 0,
			label: ''
		},
		idCargo: {
			id: 0,
			label: ''
		},
		idPais: {
			id: 0,
			label: ''
		},
		idDepartamento: {
			id: 0,
			label: ''
		},
		idMunicipio: {
			id: 0,
			label: ''
		}
	});

	const { dataTableTechToAssign, setDataTableTechToAssign, setTableTechToAssign, setDataTable } =
		useSeachContext();
	const {
		selectTimeZoneId,
		techSelectToAssign,
		setOpenModalTechnicalToAssign,
		setOpenModalTechnicalToReview
	} = useContext(AppContext);
	// use Hook of language v2
	const { formatterText, errorDataRecovery, customSB, handleRequestError } = useLangv2();

	const { id } = useParams();

	const titlesTableTimeZone = [
		formatterText('table.shedule.view.technical', 'Técnicos asociados'),
		formatterText('table.actions', 'Acciones')
	];

	const [dialog, setDialog] = useState({
		text: '',
		active: false,
		function: null
	});

	useEffect(() => {
		getAllCountriesServices();
		getAllDeparmentServices();
		getAllMunicipalitieservices();
		getAllCategoriesServices();
		getInitialData();
	}, []);

	// Helps to loading data table
	const { loadingProgress, setLoadingProgress, DisplayProgress } = useProgress();

	// Get the categories for the selector
	const getAllCategoriesServices = () => {
		RequestUseCase.get(endpoints.serviceCategory.getAllServiceCategory)
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							id: item.idCategoriaServicio,
							label: item.nombre
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					categories: newArray
				}));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// Get the country for the selector
	const getAllCountriesServices = () => {
		RequestUseCase.get(endpoints.zones.getAllCountries)
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							id: item.idPais,
							label: item.nombrePais
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					countries: newArray
				}));
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Get the departments for the selector
	const getAllDeparmentServices = () => {
		RequestUseCase.get(endpoints.zones.getAllDepartments)
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							id: item.idDepartamento,
							label: `${item.nombre}`
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					departments: newArray
				}));
			})
			.catch((err) => {
				console.log(err);
			});
	};
	// Get the municipalities for the selector
	const getAllMunicipalitieservices = () => {
		RequestUseCase.get(endpoints.zones.getAllMunicipalities)
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							id: item.idMunicipio,
							label: item.nombre
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					municipalities: newArray
				}));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const closeDialog = () => {
		setDialog({ ...dialog, active: false });
	};

	// Handle to search technicals by filters
	function getSafeId(value) {
		return value === 0 || value === undefined ? null : value;
	}

	function getSafeValue(value) {
		return value === '' || value === undefined ? null : value;
	}

	const handlerFilters = (e) => {
		setLoadingProgress(true);
		e.preventDefault();

		const body = {
			idCategoriaServicio: getSafeId(
				selectValues?.idCategory?.id === 0 ? null : selectValues?.idCategory
			),
			idPais: getSafeId(selectValues?.idPais?.id === 0 ? null : selectValues?.idPais),
			idDepartamento: getSafeId(
				selectValues?.idDepartamento?.id === 0 ? null : selectValues?.idDepartamento
			),
			idMunicipio: getSafeId(
				selectValues?.idMunicipio?.id === 0 ? null : selectValues?.idMunicipio
			),
			identificacion: getSafeValue(documentForm?.numeroDocumento),
			nombre: getSafeValue(formFilters?.nombreTecnico)
		};
		RequestUseCase.post(endpoints.technical.getTechniciansByOptions, body)
			.then((response) => {
				const data = [];
				response.forEach((element) => {
					let techNameId = '';
					if (element.idEmpleado === null) {
						techNameId = element.idTercero.nombre + ' - ' + element.idTercero.identificacion;
					} else {
						techNameId =
							element.idEmpleado.primerNombre +
							' ' +
							element.idEmpleado.primerApellido +
							' - ' +
							element.idEmpleado.identificacion;
					}

					data.push({
						id: null,
						// tech: RemoveSpaces(element.nombre),
						tech: techNameId,
						status: element.estado,
						idTecnico: element.idTecnico,
						isSelected: false,
						obj: element
					});
				});
				setDataTable(data);
				setDataTableTechToAssign(data);
				setTableTechToAssign(data);
				setLoadingProgress(false);
			})
			.catch(() => {
				setLoadingProgress(false);
				errorDataRecovery();
			});
	};

	// Handle to search technicals by filters
	const getInitialData = () => {
		setLoadingProgress(true);
		RequestUseCase.get(endpoints.agenda.getTechnicalsNoAssignToTimezone(id, selectTimeZoneId))
			.then((response) => {
				const data = [];
				response.forEach((element) => {
					let techNameId = '';
					if (element.idEmpleado === null) {
						techNameId = element.idTercero.nombre + ' - ' + element.idTercero.identificacion;
					} else {
						techNameId =
							element.idEmpleado.primerNombre +
							' ' +
							element.idEmpleado.primerApellido +
							' - ' +
							element.idEmpleado.identificacion;
					}

					data.push({
						id: null,
						// tech: RemoveSpaces(element.nombre),
						tech: techNameId,
						status: element.estado,
						idTecnico: element.idTecnico,
						isSelected: false,
						obj: element
					});
				});
				setDataTable(data);
				setDataTableTechToAssign(data);
				setTableTechToAssign(data);
				setLoadingProgress(false);
			})
			.catch(() => {
				setLoadingProgress(false);
				errorDataRecovery();
			});
	};

	const handleTextFilters = (e) => {
		setFormFilters({
			...formFilters,
			[e.target.name]: e.target.value
		});
	};

	const handlerDocumentFilter = (e) => {
		setDocumentForm({
			...documentForm,
			[e.target.name]: e.target.value
		});
	};

	const handleSaveTechnicalsToTimeZone = (e) => {
		e.preventDefault();

		if (techSelectToAssign.length === 0) {
			customSB(
				'warning',
				'snackbar.warning.time.zone.technical.selected',
				'Es necesario asociar al menos un técnico a la franja horaria.'
			);
		} else {
			RequestUseCase.post(endpoints.agenda.addFranjas, techSelectToAssign)
				.then(() => {
					customSB(
						'success',
						'snackbar.success.time.zone.technical.selected',
						'Los técnicos se han asociado correctamente a la franja horaria.'
					);
					setOpenModalTechnicalToReview(true);
					setOpenModalTechnicalToAssign(false);
				})
				.catch((err) => {
					handleRequestError(err);
				});
		}
	};

	const clearFilters = (e) => {
		e.preventDefault();
		// Clear inputs
		setFormFilters({
			nombreTecnico: ''
		});
		setDocumentForm({
			numeroDocumento: ''
		});
		// Clear selects
		setSelectValues({
			idCategory: {
				id: 0,
				label: ''
			},

			idCargo: {
				id: 0,
				label: ''
			},
			idPais: {
				id: 0,
				label: ''
			},
			idDepartamento: {
				id: 0,
				label: ''
			},
			idMunicipio: {
				id: 0,
				label: ''
			}
		});
	};

	return {
		clearFilters,
		closeDialog,
		dataTableTechToAssign,
		dialog,
		DisplayProgress,
		documentForm,
		formatterText,
		formFilters,
		handlerDocumentFilter,
		handlerFilters,
		handleSaveTechnicalsToTimeZone,
		handleTextFilters,
		loadingProgress,
		selectedSearch,
		setSearchSelected,
		selectValues,
		setDocumentForm,
		setFormFilters,
		setSelectValues,
		titlesTableTimeZone,
		toggle,
		value
	};
}
