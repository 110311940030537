import React from 'react';
import { SearchWrapper } from 'context/SearchContext';
import CreateProductComponent from './CreateListPrice.component';

export default function CreateProduct() {
	return (
		<SearchWrapper>
			<CreateProductComponent />
		</SearchWrapper>
	);
}
