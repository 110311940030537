import useAxios from 'hooks/useAxios';
import HandleInput from 'common/validators/HandleInput';
// Import Hooks
import useLangv2 from 'hooks/useLangv2';
import HandleOnError from 'common/validators/HandleOnError';
import { CODEREGEX, TEXTREGEX } from 'common/validators/Regex';
import CustomAlert from 'components/CustomAlert';
import ProviderType from 'models/ProviderType';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import paths from 'services/paths';
import Swal from 'sweetalert2';

export default function CreateCommunicationViewModel() {
	const { endpoints, RequestUseCase } = useAxios();
	// useLanguage
	const { formatterText } = useLangv2();
	const navigate = useNavigate();
	// Example of form data
	const [formData, setFormData] = useState(new ProviderType());

	// Update a string to set into the form
	const handleText = (e) => {
		// if (e.target.value.match("^[a-zA-Z0-9 ñáéíóúÁÉÍÓÚÑ]*$") != null) {
		//   setFormData({ ...formData, [e.target.name]: e.target.value });
		// }
		HandleInput(e, TEXTREGEX, formData, setFormData);
	};

	// This function sets the form value by the onChange of the input
	const handleChange = (e) => {
		HandleInput(e, TEXTREGEX, formData, setFormData);
		// setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleCode = (e) => {
		HandleInput(e, CODEREGEX, formData, setFormData);
	};
	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		createProviderType(formData);
	};

	const createProviderType = (data) => {
		Swal.fire({
			title: 'Atención, estás seguro de realizar esta acción',
			text: 'Se va a crear un nuevo registro',
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: 'Guardar cambios',
			allowOutsideClick: false,
			cancelButtonText: 'Cancelar',
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					RequestUseCase.post(endpoints.providerType.addProviderType, data)
						.then(() => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: 'Operación exitosa',
									text: 'Se creo el tipo de proveedor correctamente',
									confirmButtonText: 'Continuar',
									allowOutsideClick: false,
									executeFunction: () => navigate(paths.parameters)
								})
							);
						})
						.catch((err) => {
							reject(
								HandleOnError(
									'No se pudo crear el tipo de proveedor, es posible que el código ya exista'
								)
							);
							console.log(err);
						});
				});
			}
		});
	};
	return { formData, handleText, handleChange, handleCode, handleSubmit, formatterText, navigate };
}
