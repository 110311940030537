import React, { useState } from 'react';
// Import context
// Import Components
import TableContainer from '@mui/material/TableContainer';
import UserPaginateActions from 'components/tableOptions/UserPaginateActions';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import 'styles/minimalTable.css';
// Technical module
import RowVehiclesAssign from './technicalModule/RowVehiclesAssign';
// Services module
import RowSearchServices from './searchServices/RowSearchServices';
import RowSearchSearchServicesTech from './searchServices/RowSearchSearchServicesTech';
import RowAssignTech from './searchServices/RowAssignTech';

export default function TableMinimalNoContext({
	titles,
	data,
	type,
	max,
	handleDetails,
	handleSelect,
	handleDelete,
	labelTable,
	setDialog,
	closeDialog
}) {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(15);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	// Choose a row
	const ChooseRow = (item, i) => {
		if (item !== null) {
			switch (type) {
				case 'searchServices':
					return (
						<RowSearchServices
							item={item}
							key={i}
							setDialog={setDialog}
							closeDialog={closeDialog}
							handleDetails={handleDetails}
							handleSelect={handleSelect}
						/>
					);
				case 'searchServicesTech':
					return (
						<RowSearchSearchServicesTech
							item={item}
							key={i}
							setDialog={setDialog}
							closeDialog={closeDialog}
							handleDetails={handleDetails}
							handleSelect={handleSelect}
						/>
					);
				case 'technicalAssignToService':
					return (
						<RowAssignTech
							item={item}
							key={i}
							setDialog={setDialog}
							closeDialog={closeDialog}
							handleDetails={handleDetails}
							handleSelect={handleSelect}
						/>
					);
				case 'vehiclesAssign':
					return (
						<RowVehiclesAssign
							item={item}
							key={i}
							setDialog={setDialog}
							closeDialog={closeDialog}
							handleDelete={handleDelete}
						/>
					);

				default:
					console.log('No se encontro el tipo de tabla');
					break;
			}
		}
	};

	return (
		<>
			<div className={max ? 'table-minimal-container width-100' : 'table-minimal-container'}>
				<TableContainer component={Paper}>
					<Table className="table-minimal">
						<TableHead>
							<TableRow className="infoo">
								{titles.map((title, index) => (
									<th scope="col" key={index}>
										{title}
									</th>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{(rowsPerPage > 0
								? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								: data
							).map((item, i) => ChooseRow(item, i))}
						</TableBody>
					</Table>
				</TableContainer>
				<TableFooter
					sx={{
						display: 'flex',
						justifyItems: 'center',
						justifyContent: 'right',
						mt: 2,
						mb: 2
					}}
				>
					<TablePagination
						sx={{
							marginTop: '25px',
							borderRadius: '10px'
						}}
						rowsPerPageOptions={[15, 30, 50, { label: 'Todos', value: data.length }]}
						colSpan={3}
						count={data.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						ActionsComponent={UserPaginateActions}
						labelRowsPerPage={labelTable}
						align="center"
					/>
				</TableFooter>
			</div>
		</>
	);
}
