import React from 'react';
// Import Context
// Import Hooks
// Import Components
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Footer from 'components/layout/Footer';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { FormattedMessage } from 'react-intl';
import paths from 'services/paths';
// Import Assets
import logo from 'assets/logo.png';
import 'styles/login.css';
import LoginViewModel from './Login.viewModel';
import style from './Login.style';

function Login() {
	const {
		isLoading,
		passwordShown,
		handleChange,
		handleSubmit,
		togglePasswordVisiblity,
		open,
		setOpen,
		handleChange2,
		verifyStepTwo,
		formatterText,
		navigate
	} = LoginViewModel();
	return (
		<>
			<main className="o-container-login">
				{/*  <div className="language-change">
        <p className="language">
          <FormattedMessage
            id="nav.text.language"
            defaultMessage="Cambiar idioma:"
          />
        </p>
        <select
          name="lang"
          id="lang"
          value={localStorage.getItem("lang")}
          onChange={handleLang}
          className="global-select"
        >
          <option value="en-ES">
            <FormattedMessage
              id="nav.text.language.spanish"
              defaultMessage="Español"
            />
          </option>
          <option value="en-US">
            <FormattedMessage
              id="nav.text.language.english"
              defaultMessage="Ingles"
            />
          </option>
        </select>
      </div>   */}
				<section className="o-enter-logo">
					<img src={logo} alt="Logo coral" />
				</section>
				<section className="o-enter-user">
					<section className="o-card-login">
						<form className="o-login-form" onSubmit={handleSubmit}>
							<h3>
								<FormattedMessage id="menu.home" defaultMessage="Bienvenido" />
							</h3>
							<p>
								<FormattedMessage
									id="menu.text"
									defaultMessage="Ingresa tu nombre de usuario y contraseña para continuar"
								/>
							</p>
							<input
								type="username"
								className="input-primary spacing"
								placeholder={formatterText('text.user.name', 'Nombre de usuario')}
								name="Username"
								id="Username"
								onChange={(e) => handleChange(e)}
								autoComplete="username"
								maxLength={25}
								required
							/>
							<div className="pass-wrapper">
								<input
									type={passwordShown ? 'text' : 'password'}
									className="input-primary spacing"
									placeholder={formatterText('p.password', 'Contraseña')}
									name="Password"
									id="Password"
									onChange={(e) => handleChange(e)}
									autoComplete="off"
									maxLength={25}
									required
								/>
								{passwordShown ? (
									<IoMdEye size={30} className="eye-icon" onClick={togglePasswordVisiblity} />
								) : (
									<IoMdEyeOff size={30} className="eye-icon" onClick={togglePasswordVisiblity} />
								)}
							</div>

							<p className="o-forget-password" onClick={() => navigate(paths.recover)}>
								<FormattedMessage
									id="menu.forgotpassword"
									defaultMessage="¿Olvidaste tu contraseña?"
								/>
							</p>
							<button type="submit" className="btn-primary" disabled={isLoading}>
								{formatterText('btn.login.session')}
							</button>
						</form>
					</section>
				</section>
				<Modal
					open={open}
					aria-labelledby="parent-modal-title"
					aria-describedby="parent-modal-description"
				>
					<Box sx={{ ...style, width: '70vw' }}>
						<h2 id="parent-modal-title">
							<FormattedMessage id="menu.textcode" defaultMessage="Ingresa el código" />
						</h2>
						<p id="parent-modal-description">
							<FormattedMessage
								id="menu.textcode2"
								defaultMessage="Ingresa el código que te enviamos a tu correo para continuar."
							/>
						</p>
						<input
							className="input-edit"
							type="type"
							placeholder={formatterText('table.title.code', 'Código')}
							name="Code"
							id="Code"
							maxLength="8"
							onChange={(e) => handleChange2(e)}
							required
						/>
						<button type="submit" className="btn-primary" onClick={verifyStepTwo}>
							<FormattedMessage id="menu.verify" defaultMessage="Verificar código" />
						</button>
						<button type="submit" className="input-cancel spacing-1" onClick={() => setOpen(false)}>
							<FormattedMessage id="btn.cancel" defaultMessage="Cancelar" />
						</button>
					</Box>
				</Modal>
			</main>
			<Footer />
		</>
	);
}

export default Login;
