import { SearchWrapper } from 'context/SearchContext';
import React from 'react';
import UpdateProductCategoryComponent from './UpdateProductCategory.component';

export default function UpdateProductCategory() {
	return (
		<SearchWrapper>
			<UpdateProductCategoryComponent />
		</SearchWrapper>
	);
}
