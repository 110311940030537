import React from 'react';
// Import Contexts
import { SearchWrapper } from 'context/SearchContext';
// Import Components
import CreateProductCategoryComponent from './CreateProductCategory.component';

export default function CreateProductCategory() {
	return (
		<SearchWrapper>
			<CreateProductCategoryComponent />
		</SearchWrapper>
	);
}
