import React from 'react';
// Import Context
// Import Hooks
// Import Components
import DynamicTable from 'common/DynamicTable/DynamicHead';
import Search from 'common/Search';
import ExportJsonFile from 'components/utils/ExportJsonFile';
// Import libs
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
// Import services
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';
import paths from 'services/paths';
import RolsTableViewModel from './RolsTable.viewModel';

export default function RolsTableComponent() {
	const {
		loading,
		error,
		displayMessage,
		displayLoading,
		getDataTable,
		searchResults,
		handleDeleteItem,
		handleEditStateItem,
		permittedActions,
		formatterText,
		titles
	} = RolsTableViewModel();

	if (loading) {
		if (error) {
			return displayMessage(
				'error',
				'Ha ocurrido un error, intentalo más tarde.',
				'toast.error.general'
			);
		}
		return displayLoading();
	}
	return (
		<section className="table-container">
			<section className="userOptions">
				<Search
					placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
					width="50%"
				/>
				{permittedActions.crear && (
					<Link to={paths.createRol}>
						<button className="btn-add">
							<FormattedMessage id="btn.create.role" defaultMessage="Crear Rol" />
						</button>
					</Link>
				)}

				{permittedActions.exportar && (
					<ExportJsonFile
						moduleName={'Rol'}
						userName={
							JSON.parse(localStorage.getItem('userData')).usuario
								? JSON.parse(localStorage.getItem('userData')).usuario
								: 'ADMIN'
						}
						dataTable={searchResults}
					/>
				)}
			</section>
			{permittedActions.consultar && (
				<FormattedMessage id="table.name.search.rols" defaultMessage="Roles">
					{() => (
						<div className="tab-container">
							<DynamicTable
								titles={titles}
								pageName={PAGE_NAMES.Roles}
								data={getDataTable}
								handleDeleteItem={handleDeleteItem}
								handleEditStateItem={handleEditStateItem}
								routeToEdit={paths.updateRol}
								canDeleted={permittedActions.eliminar}
								canModify={permittedActions.editar}
							/>
						</div>
					)}
				</FormattedMessage>
			)}
		</section>
	);
}
