import useAxios from 'hooks/useAxios';
import { useState } from 'react';

// Import Libs
import Swal from 'sweetalert2';

// Import Components
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';

// Import Hooks
import useLangv2 from 'hooks/useLangv2';
import { useNavigate } from 'react-router-dom';

//Import Utils
import HandleInput from 'common/validators/HandleInput';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';

// Import Paths
import paths from 'services/paths';

// Import Services
import { validateDuplicateCode } from 'common/validators/ValidateDuplicates';

//Import Constants
import { SPACING, TEXTREGEXMIN } from 'common/validators/Regex';

// Import Models
import ClientType from 'models/ClientType';

export default function CreateClientTypeViewModel() {
	const { endpoints, RequestUseCase } = useAxios();
	const navigate = useNavigate();

	const [formData, setFormData] = useState(new ClientType());

	// use Hook of language v2
	const { formatterText, noFilledContent } = useLangv2();
	const [errorName, setErrorName] = useState('');
	const [codeError, setCodeError] = useState('');
	// Update a string to set into the form
	const handleText = (e) => {
		HandleInput(e, SPACING, formData, setFormData, true);
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	// update the form number
	const handleNumber = (e) => {
		const example = e;
		example.target.value = e.target.value.toLowerCase();
		HandleInput(example, TEXTREGEXMIN, formData, setFormData);
	};

	// This function is executed when the create button is clicked
	const handleSubmit = (e) => {
		e.preventDefault();
		if (errorName !== '' || codeError !== '' || formData.nombre === '' || formData.codigo === '') {
			noFilledContent();
		} else {
			const errorMsg = formatterText('p.label.title.errorCrearTipoClienteExistenciaCodigo');
			const validate = validateDuplicateCode(formData.codigo, errorMsg, () =>
				RequestUseCase.get(endpoints.clientTypes.getAllClientTypes)
			);
			if (validate) {
				createMarket({
					...formData,
					nombre: formData.nombre.replaceAll(/\s{2,}/gi, ' '),
					descripcion: formData.descripcion.replaceAll(/\s{2,}/gi, ' ')
				});
			}
		}
	};

	const createMarket = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					// this service create the item
					RequestUseCase.post(endpoints.clientTypes.addClientType, data)
						.then(() => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.created.general',
										'El registro se ha creado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => navigate(paths.parameters)
								})
							);
						})
						.catch((err) => {
							console.log(err);
							reject(
								HandleOnError(
									formatterText(
										'alert.message.failed.associations.general',
										'Error al crear una de las asociaciones, es posible que el código ya exista'
									)
								)
							);
						});
				});
			}
		});
	};

	const handleErrorName = () => {
		if (formData.nombre.trim() === '') {
			setErrorName(formatterText('p.label.title.name.is.required'));
		} else {
			setErrorName('');
		}
	};

	const handleErrorCode = () => {
		if (formData.codigo.trim() === '') {
			setCodeError(formatterText('table.title.code.required'));
		} else {
			setCodeError('');
		}
	};

	return {
		codeError,
		errorName,
		formatterText,
		formData,
		handleErrorCode,
		handleErrorName,
		handleNumber,
		handlerTextDescription,
		handleSubmit,
		handleText,
		navigate
	};
}
