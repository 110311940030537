import React from 'react';

// Import Contexts
import { SearchWrapper } from 'context/SearchContext';

// Import Components
import EditEmployeeComponent from './EditEmployee.component';

const EditEmployee = () => (
	<SearchWrapper>
		<EditEmployeeComponent />
	</SearchWrapper>
);

export default EditEmployee;
