import useAxios from 'hooks/useAxios';
import { useEffect } from 'react';

// Imports Libs
import dateFormat from 'dateformat';
import moment from 'moment';

// Imports Contexts
import { useSeachContext } from 'context/SearchContext';

// Imports Hooks
import useLangv2 from 'hooks/useLangv2';
import useGetData from 'hooks/useGetData';

export default function VehiclesTableViewModel() {
	const { endpoints, RequestUseCase } = useAxios();
	// hook to loading data
	const {
		loading,
		error,
		toggleLoading,
		toggleError,
		handleClick,
		displayMessage,
		displayLoading
	} = useGetData();

	const { setDataTable } = useSeachContext();

	useEffect(() => {
		getDataTable();
	}, []);

	const { formatterText } = useLangv2();

	// función para cargar datos
	const getDataTable = () => {
		// show loading
		toggleLoading(true);

		RequestUseCase.get(endpoints.technical.getVehiclesByTechnical)
			.then((data) => {
				const newArray = [];
				data.forEach((item) => {
					handleStructureItems(newArray, item);
				});

				setDataTable(newArray);
				// show loading
				toggleLoading(false);
			})
			.catch(() => {
				// mostrar error
				toggleError(!error);
				handleClick();
			});
	};

	const handleDeleteItem = (column) => {
		const deleteItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.technical.deleteVehicleFromTechnical(column.id))
				.then((res) => {
					const newArray = [];
					res.forEach((item) => {
						handleStructureItems(newArray, item);
					});

					setDataTable(newArray);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return deleteItemPromise;
	};

	const handleEditStateItem = (body) => {
		const updateItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.technical.updateTechnical, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return updateItemPromise;
	};

	const handleStructureItems = (newArray, item) => {
		let completeName;
		let document;
		let position;
		let typeOfContract;

		if (item.idTecnico.idEmpleado === null) {
			completeName = `${item.idTecnico.idTercero.nombre}`;
			document = item.idTecnico.idTercero
				? item.idTecnico.idTercero.identificacion
				: 'No tiene un valor';
			position = item.idTecnico.idTercero.cargo
				? item.idTecnico.idTercero.cargo.nombre
				: 'No tiene un valor asignado';
			typeOfContract = item.idTecnico.idTercero.tipoContrato
				? item.idTecnico.idTercero.tipoContrato.nombre
				: 'No tiene un valor asignado';
		} else {
			completeName = `${item.idTecnico.idEmpleado.primerNombre} ${item.idTecnico.idEmpleado.segundoNombre} ${item.idTecnico.idEmpleado.primerApellido} ${item.idTecnico.idEmpleado.segundoApellido}`;
			document = item.idTecnico.idEmpleado.identificacion || 'No tiene un valor';
			position = item.idEmpleado ? item.idEmpleado.cargo.nombre : 'No tiene un valor';
			typeOfContract = item.idEmpleado ? item.idEmpleado.tipoContrato.nombre : 'No tiene un valor';
		}

		newArray.push({
			id: item.idVehiculo,
			documento: document,
			nombre: completeName,
			cargo: position,
			tipoContrato: typeOfContract,
			runt: item.runt || 'No tiene un valor',
			vencimientoSoat: item.fechaSoap
				? dateFormat(moment(item.fechaSoap.split('T')[0]), 'yyyy-mm-dd')
				: 'No tiene un valor',
			vencimientoRunt: item.idTecnico.licenciaFechaExpiracion
				? dateFormat(moment(item.idTecnico.licenciaFechaExpiracion.split('T')[0]), 'yyyy-mm-dd')
				: 'No tiene un valor',
			vencimientoTecnicoMecanico: item.fechaTecnicoMecanica
				? dateFormat(moment(item.fechaTecnicoMecanica.split('T')[0]), 'yyyy-mm-dd')
				: 'No tiene un valor',
			objeto: { ...item }
		});
	};

	// titulos de la tabla
	const titles = [
		formatterText('table.title.document', 'Documento'),
		formatterText('table.title.technician.name', 'Nombre del Técnico'),
		formatterText('table.title.position', 'Cargo'),
		formatterText('table.title.contract.type', 'Tipo de contrato'),
		formatterText('table.title.runt.date', 'Código RUNT'),
		formatterText('table.title.soat.expiration', 'Vencimiento SOAT'),
		formatterText('table.title.license.expiration', 'Vencimiento LICENCIA'),
		formatterText('table.title.tech.expiration', 'Vencimiento Tecnicomecánico'),
		formatterText('btn.delete', 'Eliminar')
	];

	return {
		displayLoading,
		displayMessage,
		error,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		loading,
		titles
	};
}
