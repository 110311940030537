import useAxios from 'hooks/useAxios';
import { TableMinimalContext } from 'context/TableMinimalContext';
import { useContext, useEffect, useState } from 'react';

export default function useGetClientFormOptionsData() {
	const { RequestUseCase, endpoints } = useAxios();

	const [selectData, setSelectData] = useState({});
	const [loadingData, setLoadingData] = useState(false);
	const [listPrices, setListPrices] = useState([]);
	const { setAuxId } = useContext(TableMinimalContext);

	const getData = () => {
		setLoadingData(true);
		const promises = [
			RequestUseCase.get(endpoints.zones.getAllCountries),
			RequestUseCase.get(endpoints.institutions.getAllTypeDocument),
			RequestUseCase.get(endpoints.institutions.getAllGenres),
			RequestUseCase.get(endpoints.billingTypes.getAllBillingTypes),
			RequestUseCase.get(endpoints.institutions.getAllTypeAccounts),
			RequestUseCase.get(endpoints.zones.getAllMunicipalities),
			RequestUseCase.get(endpoints.clientTypes.getAllClientTypes),
			RequestUseCase.get(endpoints.businessUnit.getAllBusinessUnits),
			RequestUseCase.get(endpoints.zones.getAllSectors),
			RequestUseCase.get(endpoints.cargos.getAllCargos)
		];

		Promise.all(promises).then((values) => {
			setLoadingData(true);
			setSelectData({
				...selectData,
				countries: values[0].filter((pais) => pais.estado === 1),
				documentTypes: values[1],
				genres: values[2],
				billingTypes: values[3],
				accountTypes: values[4],
				municipalities: values[5],
				clientTypes: values[6],
				businessUnits: values[7],
				cargos: values[9]
			});
			setLoadingData(false);
		});
	};
	const getListPrices = () => {
		RequestUseCase.get(endpoints.listPrices.getAllListPrices).then((data) => {
			const newArray = [];
			data.forEach((item) => {
				if (item.estado === 1) {
					newArray.push({
						value: item.idListaPreciosActividad,
						label: `${item.nombre} - ${item.codigo}`,
						isFixed: true
					});
				}
			});
			setListPrices(newArray);
		});
	};

	useEffect(() => {
		getData();
		getListPrices();
		setAuxId(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		selectData,
		setSelectData,
		loadingData,
		listPrices,
		getListPrices
	};
}
