import PropTypes from 'prop-types';

export const ServiceActivityTableProps = {
	setActive: PropTypes.func,
	setCurrentDataForm: PropTypes.func,
	myForm: PropTypes.object,
	currentDataForm: PropTypes.object,
	setActionToggle: PropTypes.func,
	edit: PropTypes.bool
};
