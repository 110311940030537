import soundUrlO1 from 'assets/sounds/opcion_1.wav';
import { AppContext } from 'context/AppContext';
import useAxios from 'hooks/useAxios';
import useInteraction from 'hooks/useInteraction';
import React, { useContext, useEffect, useState } from 'react';
import { BiBell, BiMessageRoundedError } from 'react-icons/bi';
import { IoMdInformationCircle } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { alertsByUserIcon, getCountAlertIcon, markAllAlertsAsRead } from 'services/api/alerts';
import {
	getAllNotifications,
	getCountNotificationsIcon,
	getTiempoNotificacionActivities,
	markAllAsRead
} from 'services/api/notifications';
import _ from 'lodash';

import useLangv2 from 'hooks/useLangv2';
import { refresh } from 'hooks/useRedirect';
import { DateTime } from 'luxon';
import 'styles/alertIcon.css';

export default function AlertIcon() {
	const ALERT_TIME_MS = process.env.REACT_APP_ALERT_TIME_MS;
	//SOUND ALERTS
	const hasInteracted = useInteraction();
	const audio = new Audio(soundUrlO1);
	const { COOKIE_USER, RequestUseCase, endpoints } = useAxios();

	const { formatterText } = useLangv2();

	const { setSelectNotificacion } = useContext(AppContext);
	const [showNotification, setShowNotification] = useState(false);
	const [showAlerts, setShowAlerts] = useState(false);
	const [notificacionesValue, setNotificacionesValue] = useState('');
	const [alertasValue, setAlertasValue] = useState('');
	const [notificacionesList, setNotificacionesList] = useState([{}]);
	const [renderAlert, setRenderAlert] = useState([{}]);

	const getDataByType = () => {
		getCountNotificationsIcon().then((data) => {
			setNotificacionesValue((prev) => {
				if (prev !== data) {
					audio.play();
					setNotificacionesValue(data);
				} else {
					setNotificacionesValue(data);
				}
			});
		});
		getCountAlertIcon().then((data) => {
			setAlertasValue((prev) => {
				if (prev !== data) {
					audio.play();
					setAlertasValue(data);
				} else {
					setAlertasValue(data);
				}
			});
		});
	};

	const getTiempoNotificacion = () => {
		getTiempoNotificacionActivities()
			.then((notifications) => {
				localStorage.setItem('notificacionActivities', JSON.stringify(notifications));
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const DeterminateTypeUser = () => {
		let idEmpleado = JSON.parse(localStorage.getItem('userData'))?.idEmpleado?.idEmpleado || '';
		let idAsesorCliente =
			JSON.parse(localStorage.getItem('userData'))?.idAsesorCliente?.idAsesor || '';
		if (idEmpleado !== '') {
			idEmpleado = parseInt(idEmpleado);
		}
		if (idAsesorCliente !== '') {
			idAsesorCliente = parseInt(idAsesorCliente);
		}
		//Determinate with a hash if the user is an employee or a client advisor
		if (idEmpleado !== '' && idAsesorCliente === '') {
			getDataByType();
		}
		if (idEmpleado === '' && idAsesorCliente !== '') {
			getDataByType();
		}
	};

	const searchNotificationsCallback = (cb) => {
		const timeout = setTimeout(() => {
			getAllNotifications();
			cb();
			DeterminateTypeUser();
			getAlert();
		}, ALERT_TIME_MS);
		const locationLogin = new URL(window.location.href).hash.includes('login');
		if (locationLogin) {
			clearTimeout(timeout);
		}
	};

	const searchNotifications = () => {
		const timeout = setTimeout(() => {
			searchNotificationsCallback(searchNotifications);
			DeterminateTypeUser();
			getAlert();
		}, ALERT_TIME_MS);

		const locationLogin = new URL(window.location.href).hash.includes('login');
		if (locationLogin) {
			clearTimeout(timeout);
		}
	};

	const setNotification = () => {
		getAllNotifications().then((res) => {
			const data = res.map((item) => ({
				idAgendaNotificacion: item.idAgendaNotificacion,
				ticket: item.idServicio.ticket,
				expediente: item.idServicio.expediente,
				tiempoTranscurrido: tiempoTranscurridoMin(item.fechaHoraNotificacion),
				fecha: item.fechaHoraNotificacion.split('T')[0], //2024-01-09T18:26:10.000+00:00,
				hora: item.fechaHoraNotificacion.split('T')[1].split('.')[0],
				idServicio: item.idServicio.idServicio,
				status: item.idTipoNotificacion.tipo,
				ciudad: item.nombreCiudadServicio,
				nombreTecnico: item.nombreTecnico
			}));
			searchNotificationsCallback(searchNotifications);
			getAlert();
			setNotificacionesList(_.sortBy(data, 'tiempoTranscurrido'));
			DeterminateTypeUser();
		});
	};

	useEffect(() => {
		getTiempoNotificacion();
		if (!hasInteracted) {
			return;
		}
		setNotification();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasInteracted]);

	const tiempoTranscurridoMin = (fechaItem) => {
		const now = DateTime.now().setZone(process.env.REACT_APP_GTM);
		const inicial = DateTime.fromISO(fechaItem.split('+')[0]);
		const diferencia = now.diff(inicial);

		// Formatear la diferencia de tiempo en HH:MM:SS
		const tiempoFormateado = diferencia.toFormat('hh:mm:ss');

		return tiempoFormateado;
	};

	const getAlert = () => {
		alertsByUserIcon({
			idUser: COOKIE_USER,
			currentPage: 0,
			registersPerPage: 25
		})
			.then((res) => {
				const result = res.map((elem) => ({
					idAgendaNotificacion: elem.idAgendaNotificacion,
					cliente: elem.nombreCliente,
					ciudad: elem.nombreCiudadServicio,
					status: elem.idTipoNotificacion.tipo,
					ticket: elem.idServicio.ticket,
					expediente: elem.idServicio.expediente,
					idServicio: elem.idServicio.idServicio,
					fecha: elem.fechaHoraNotificacion,
					tiempoTranscurrido: tiempoTranscurridoMin(elem.fechaHoraNotificacion),
					nombreTecnico: elem.nombreTecnico
				}));
				setRenderAlert(_.sortBy(result, 'tiempoTranscurrido'));
			})
			.catch((err) => console.error(err));
	};

	const clearAlerts = () => {
		markAllAlertsAsRead();
		setShowAlerts(false);
		setAlertasValue(0);
		setRenderAlert([]);
	};

	const clearAlertsNotification = () => {
		markAllAsRead();
		setShowNotification(false);
		setNotificacionesValue(0);
		setNotificacionesList([]);
	};

	const handleClickNotification = (notificacion) => {
		localStorage.setItem('idService', notificacion.idServicio);
		RequestUseCase.get(endpoints.notificationsTable.markAsRead(notificacion.idAgendaNotificacion));
		setNotificacionesValue(notificacionesValue - 1);
		setSelectNotificacion(notificacion);
		refresh(`/servicios/editar/${notificacion.idServicio}`);
	};

	const textNotficaciones = (notificacion) => {
		switch (notificacion?.status.toLowerCase()) {
			case 'en sitio':
				return (
					<>
						<p className="alert-icon-description">
							{formatterText('notification.technician', 'El técnico')} {notificacion.nombreTecnico}{' '}
							{formatterText(
								'notification.on.site.for.service',
								'se marcó en sitio para el servicio #'
							)}{' '}
							{notificacion.expediente}{' '}
							{`${formatterText('notification.time.at')} 
              ${notificacion.hora} ${formatterText('notification.hours', 'hrs')}`}
						</p>
						<p className="alert-icon-time">
							{formatterText('notification.elapsed.time', 'Tiempo transcurrido')}{' '}
							{notificacion.tiempoTranscurrido} {formatterText('notification.hours', 'hrs')}
						</p>
					</>
				);
			case 'notificar asesor':
				return (
					<>
						<p className="alert-icon-description">
							{formatterText('notification.report.new', 'Se ha registrado un nuevo reporte para')}{' '}
							{notificacion.expediente}{' '}
						</p>
						<p className="alert-icon-time">
							{formatterText('notification.elapsed.time', 'Tiempo transcurrido')}{' '}
							{notificacion.tiempoTranscurrido} {formatterText('notification.hours', 'hrs')}
						</p>
					</>
				);
			case 'asignado':
				return (
					<>
						<p className="alert-icon-description">
							{formatterText('notification.marked.as.asigned', 'Se ha asignado el servicio #')}{' '}
							{notificacion.expediente}{' '}
							{formatterText('notification.in.the.city.of', 'en la ciudad de')}{' '}
							{notificacion.ciudad}
						</p>
						<p className="alert-icon-time">
							{formatterText('notification.elapsed.time', 'Tiempo transcurrido')}{' '}
							{notificacion.tiempoTranscurrido} {formatterText('notification.hours', 'hrs')}
						</p>
					</>
				);

			case 'nuevo servicio':
				return (
					<>
						<p className="alert-icon-description">
							{formatterText(
								'notification.new.service.created',
								'Se ha creado un nuevo servicio #'
							)}{' '}
							{notificacion.expediente}{' '}
							{formatterText('notification.in.the.city.of', 'en la ciudad de')}{' '}
							{notificacion.ciudad}
						</p>
						<p className="alert-icon-time">
							{formatterText('notification.elapsed.time', 'Tiempo transcurrido')}{' '}
							{notificacion.tiempoTranscurrido} {formatterText('notification.hours', 'hrs')}
						</p>
					</>
				);

			default:
				return (
					<>
						<p className="alert-icon-description">
							{formatterText('notification.service.with.expedient.number')} {notificacion.expediente}{' '}
							{formatterText('notification.of.the.client', 'del cliente')} {notificacion.cliente}{' '}
							{formatterText('notification.of')} {notificacion.ciudad}{' '}
							{formatterText('notification.marked.as', 'se marcó como')} {notificacion.status}
						</p>
						<p className="alert-icon-time">
							{formatterText('notification.elapsed.time', 'Tiempo transcurrido')}{' '}
							{notificacion.tiempoTranscurrido} {formatterText('notification.hours', 'hrs')}
						</p>
					</>
				);
		}
	};

	const renderAlertDescription = (alerta) => {
		switch (alerta?.status?.toLowerCase()) {
			case 'retraso en sitio':
				return (
					<>
						<p className="alert-icon-description">
							{formatterText('notification.technician', 'El técnico')} {alerta.nombreTecnico}{' '}
							{formatterText(
								'notification.tech.has.not.startet.activities',
								'no ha iniciado actividades para el servicio #'
							)}{' '}
							{alerta.expediente}{' '}
						</p>
						<p className="alert-icon-time">
							{formatterText('notification.elapsed.time', 'Tiempo transcurrido')}{' '}
							{alerta.tiempoTranscurrido} {formatterText('notification.hours', 'hrs')}
						</p>
					</>
				);
			case 'visita fallida':
				return (
					<>
						<p className="alert-icon-description">
							{formatterText('notification.service.with.expedient.number')} {alerta.expediente}{' '}
							{formatterText('notification.marked.as.failed')}
						</p>
						<p className="alert-icon-time">
							{formatterText('notification.elapsed.time', 'Tiempo transcurrido')}{' '}
							{alerta.tiempoTranscurrido} {formatterText('notification.hours', 'hrs')}
						</p>
					</>
				);
			case 'no asignado':
				return (
					<>
						<p className="alert-icon-description">
							{formatterText('notification.service', 'El servicio')} {alerta.expediente}{' '}
							{formatterText('notification.of.the.client', 'del cliente')} {alerta.cliente}{' '}
							{formatterText('notification.of')} {alerta.ciudad}{' '}
							{formatterText('notification.does.not.marked.as.asigned', 'no ha sido tomado')}
						</p>
						<p className="alert-icon-time">
							{formatterText('notification.elapsed.time', 'Tiempo transcurrido')}{' '}
							{alerta.tiempoTranscurrido} {formatterText('notification.hours', 'hrs')}
						</p>
					</>
				);
			default:
				return (
					<>
						<p className="alert-icon-description">
							{formatterText('notification.service', 'El servicio')} {alerta.expediente}{' '}
							{formatterText('notification.of.the.client', 'del cliente')} {alerta.cliente}{' '}
							{formatterText('notification.of')} {alerta.ciudad}{' '}
							{formatterText('notification.marked.as', 'se marcó como')} {alerta.status}
						</p>
						<p className="alert-icon-time">
							{formatterText('notification.elapsed.time', 'Tiempo transcurrido')}{' '}
							{alerta.tiempoTranscurrido} {formatterText('notification.hours', 'hrs')}
						</p>
					</>
				);
		}
	};

	const handleAlertClick = (alert) => {
		RequestUseCase.get(endpoints.notificationsTable.markAsRead(alert.idAgendaNotificacion));
		setAlertasValue(alertasValue - 1);
		refresh(`/servicios/editar/${alert.idServicio}`);
		setShowAlerts(false);
	};

	return (
		<>
			<section
				className="alert-icon-container"
				onClick={() => {
					setShowAlerts(!showAlerts);
					setShowNotification(false);
				}}
			>
				<BiMessageRoundedError size={30} color={'#58585B'} cursor="pointer" />
				<p className={alertasValue > 0 ? 'icon-bubble' : 'icon-bubble-disable'}>
					{alertasValue || 0}
				</p>
			</section>
			<section
				className="alert-icon-container"
				onClick={() => {
					setShowNotification(!showNotification);
					setShowAlerts(false);
					if (!showNotification) setNotification();
				}}
			>
				<BiBell size={30} color={'#58585B'} cursor="pointer" />
				<p className={notificacionesValue > 0 ? 'icon-bubble' : 'icon-bubble-disable'}>
					{notificacionesValue}
				</p>
			</section>
			{showAlerts && alertasValue > 0 && (
				<section className="alert-icon">
					<section className="alert-icon-container-header">
						<Link
							to="/panel-notificaciones"
							onClick={() => {
								localStorage.setItem('indexNotification', 0);
								setShowAlerts(false);
							}}
						>
							<p className="message-to-read">
								{formatterText('p.view.all.alerts', 'Ver todas las alertas')} {alertasValue}
							</p>
						</Link>
						<Link
							to="/panel-notificaciones"
							onClick={() => {
								clearAlerts();
							}}
						>
							<p className="message-to-read">
								{formatterText('p.mark.as.read.alerts', 'Marcar todas como leídas')}
							</p>
						</Link>
					</section>
					<section className="alert-icon-container-body">
						{renderAlert.map((alerta, index) => (
							<section className="alert-icon-container-message" key={`${index + 1}`}>
								<IoMdInformationCircle size={25} color={'#58585B'} />
								<section className="alert-icon-container-info">
									<p
										className="alert-icon-description"
										style={{
											textAlign: 'left'
										}}
										onClick={() => handleAlertClick(alerta)}
									>
										{renderAlertDescription(alerta)}
									</p>
								</section>
							</section>
						))}
					</section>
				</section>
			)}
			{showNotification && notificacionesValue > 0 && (
				<section className="alert-icon">
					<section className="alert-icon-container-header">
						<Link
							to="/panel-notificaciones"
							onClick={() => {
								localStorage.setItem('indexNotification', 1);
								setShowNotification(false);
							}}
						>
							<p className="message-to-read">
								{formatterText(
									'notification.view.all.notifications',
									'Ver todas las notificaciones'
								)}{' '}
								{notificacionesValue}
							</p>
						</Link>
						<p className="message-to-read" onClick={clearAlertsNotification}>
							{formatterText('notification.mark.all.as.read', 'Marcar todas como leídas')}
						</p>
					</section>
					<section className="alert-icon-container-body">
						{notificacionesList.map((notificacion, index) => (
							<a
								key={index}
								style={{
									textAlign: 'left'
								}}
								onClick={() => {
									handleClickNotification(notificacion);
									setShowNotification(false);
								}}
							>
								<section className="alert-icon-container-message">
									<IoMdInformationCircle size={25} color={'#58585B'} />
									<section className="alert-icon-container-info">
										{textNotficaciones(notificacion)}
									</section>
								</section>
							</a>
						))}
					</section>
				</section>
			)}
		</>
	);
}
