import React from 'react';
import PropTypes from 'prop-types';
import Search from './Search';

import { useSeachContext } from 'context/SearchContext';
import '../styles/rolSelector.css';

const RolSelector = ({ handleAdd }) => {
	const { rolesResults = [] } = useSeachContext();
	return (
		<div className="searchRol">
			<Search width={'100%'} />
			<table className="scroll">
				<tbody>
					{rolesResults.map((rol, index) => (
						<tr key={index}>
							<td>
								<button
									onClick={(e) => {
										e.preventDefault();
										handleAdd(rol);
									}}
								>
									{rol.nombre}
								</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

RolSelector.propTypes = {
	handleAdd: PropTypes.func
};

export default RolSelector;
