import React from 'react';
// Import Contexts
// Import Hooks
// Import Components
import Search from 'common/Search';
import SelectComboBox from 'common/selects/SelectComboBox';
import DateTimePicker from 'components/pickers/DateTimePicker';
import ExportJsonFile from 'components/utils/ExportJsonFile';
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
// Import Libs
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// Import Models
// Import Services
import paths from 'services/paths';
// Import Styles
import grid from '../Service.module.css';
import ServiceTableViewModel from './ServiceTable.viewModel';

const ServiceTableComponent = () => {
	const {
		permittedActions,
		services,
		loading,
		searchResults,
		show,
		setShow,
		formData,
		handleChange,
		selectedSearch,
		selectValues,
		setSelectValues,
		val,
		allStateService,
		filterBill,
		setFilterBill,
		loadingProgress,
		searchAdvance,
		handlerClear,
		handleFechaCitaInicioChange,
		handleFechaCitaFinChange,
		handleFechaCreacionInicial,
		handleFechaCreacionFinal,
		listIdentification,
		showAutocomplete,
		inputValue,
		handleAutocompleteSelect,
		formatterText,
		dataTable,
		DynamicTable,
		titlesTable,
		PAGE_NAMES,
		getDataTable,
		error,
		displayMessage,
		DisplayProgress
	} = ServiceTableViewModel();

	// this function show if the table has data or not within the last 24 hours
	const determinateData = () => {
		if (dataTable.length > 0) {
			return (
				<FormattedMessage id="table.name.search.service" defaultMessage="Servicios">
					{() =>
						permittedActions.consultar && (
							<DynamicTable
								titles={titlesTable}
								pageName={PAGE_NAMES.Servicios}
								getData={getDataTable}
								routeToEdit={paths.updateService}
								canModify={permittedActions.editar}
							/>
						)
					}
				</FormattedMessage>
			);
		} else {
			return (
				<>
					{permittedActions.consultar && (
						<FormattedMessage
							id="text.services.no.data"
							defaultMessage="No hay servicios en las últimas 24 horas o no se encontraron coincidencias en la búsqueda."
						/>
					)}
				</>
			);
		}
	};

	// show loader or error
	const determinateLoader = () => {
		if (error) {
			return displayMessage(
				'error',
				'Ha ocurrido un error, intentalo más tarde.',
				'toast.error.general'
			);
		} else {
			return <DisplayProgress />;
		}
	};

	return (
		<>
			<section className="table-container">
				<section className="userOptions">
					{permittedActions.consultar && (
						<Search
							placeholder={formatterText('input.placeholder.search.service.table')}
							width="50%"
						/>
					)}

					{permittedActions.crear && (
						<Link to={paths.createService}>
							<button className="btn-add">
								<FormattedMessage id="text.create.service" defaultMessage="Crear Servicio" />
							</button>
						</Link>
					)}

					{permittedActions.exportar && (
						<ExportJsonFile
							moduleName={'Servicios'}
							userName={
								JSON.parse(localStorage.getItem('userData')).usuario
									? JSON.parse(localStorage.getItem('userData')).usuario
									: 'ADMIN'
							}
							dataTable={searchResults}
						/>
					)}
				</section>

				<section className="advance-search">
					{permittedActions.consultar && (
						<p
							onClick={(e) => {
								e.preventDefault();
								setShow(!show);
							}}
						>
							{show ? (
								<MdKeyboardArrowDown size={22} color="gray" cursor="pointer" />
							) : (
								<MdKeyboardArrowRight size={22} color="gray" cursor="pointer" />
							)}
							<FormattedMessage
								id="text.action.show.advanced.search"
								defaultMessage="Búsqueda avanzada"
							/>
						</p>
					)}
				</section>
				{/* advanced Seacrh */}
				{show && permittedActions.consultar ? (
					<form style={{ marginBottom: '30px', padding: '0' }}>
						<section className={grid.grid}>
							{/* Ticket input */}
							<label>
								<input
									style={{ marginLeft: '10px' }}
									className="input-primary"
									type="text"
									name="ticket"
									placeholder={formatterText('text.ticket', 'Ticket')}
									maxLength="45"
									onChange={handleChange}
									value={formData.ticket}
								/>
							</label>
							{/* Expendient input */}
							<label>
								<input
									style={{ marginLeft: '10px' }}
									className="input-primary"
									type="text"
									name="expediente"
									placeholder={formatterText('text.expedient', 'Expediente')}
									maxLength="45"
									onChange={handleChange}
									value={formData.expediente}
								/>
							</label>
							{/* Phone input */}
							<label>
								<input
									style={{ marginLeft: '10px' }}
									className="input-primary"
									type="text"
									name="telefono_usuario"
									placeholder={formatterText('text.phone', 'Teléfono del usuario')}
									maxLength="45"
									onChange={handleChange}
									value={formData.telefono_usuario}
								/>
							</label>
							{/* ID input */}
							<div>
								<label>
									<input
										style={{ marginLeft: '10px' }}
										className="input-primary"
										type="text"
										name="identificacion"
										placeholder={formatterText('table.title.client.id')}
										maxLength="45"
										onChange={handleChange}
										value={formData.identificacion}
										autoComplete="off"
									/>
								</label>
								{showAutocomplete && (
									<div>
										{listIdentification.identification
											.filter((opcion) => opcion.includes(inputValue))
											.map((opcion) => (
												<div key={opcion} onClick={() => handleAutocompleteSelect(opcion)}>
													{opcion}
												</div>
											))}
									</div>
								)}
							</div>

							<SelectComboBox
								name="nombre_usuario"
								selectValue={selectValues.nombre_usuario}
								setterFunction={(nombre_usuario) =>
									setSelectValues({ ...selectValues, nombre_usuario })
								}
								val={val}
								data={selectedSearch.users}
								placeholder={formatterText('input.placeholder.user.name')}
							/>
							<SelectComboBox
								name="nombre_cliente"
								selectValue={selectValues.nombre_cliente}
								setterFunction={(nombre_cliente) =>
									setSelectValues({ ...selectValues, nombre_cliente })
								}
								val={val}
								data={selectedSearch.clientsAssociate}
								placeholder={formatterText('text.client.name')}
							/>
							{/* Username technical */}

							<SelectComboBox
								name="idTecnico"
								selectValue={selectValues.idTecnico}
								setterFunction={(idTecnico) => setSelectValues({ ...selectValues, idTecnico })}
								val={val}
								data={selectedSearch.techinicals}
								placeholder={formatterText('input.placeholder.principal.tech')}
							/>
							{/* Username technical */}
							<SelectComboBox
								name="idTipoServicio"
								selectValue={selectValues.idTipoServicio}
								setterFunction={(idTipoServicio) =>
									setSelectValues({ ...selectValues, idTipoServicio })
								}
								data={selectedSearch.typeService}
								placeholder={formatterText('input.placeholder.service.type')}
							/>
							{/* State service */}
							<SelectComboBox
								name="idEstadoServicio"
								selectValue={selectValues.idEstadoServicio}
								setterFunction={(idEstadoServicio) =>
									setSelectValues({ ...selectValues, idEstadoServicio })
								}
								data={allStateService}
								placeholder={formatterText('input.placeholder.service.state')}
							/>

							<span
								style={{
									fontSize: '0.9rem',
									color: 'var(--text-primary)',
									fontWeight: 'bold',
									alignSelf: 'center',
									marginLeft: '10px'
								}}
							>
								{formatterText('label.text.appointment.date')}
							</span>
							{/* Date start input */}
							<label>
								<DateTimePicker
									name="rangoFechaCitaInicio"
									placeholder={formatterText('text.start')}
									value={selectValues.rangoFechaCitaInicio}
									onChange={(data) => handleFechaCitaInicioChange(data)}
								/>
							</label>
							<label>
								<DateTimePicker
									name="rangoFechaCitaFin"
									placeholder={formatterText('text.end')}
									value={selectValues.rangoFechaCitaFin}
									onChange={(data) => handleFechaCitaFinChange(data)}
								/>
							</label>
							<span
								style={{
									fontSize: '0.9rem',
									color: 'var(--text-primary)',
									fontWeight: 'bold',
									alignSelf: 'center',
									marginLeft: '10px'
								}}
							>
								{formatterText('label.text.appointment.creation')}
							</span>
							{/* Date start input */}
							<label>
								<DateTimePicker
									name="rangoFechaCreacionInicial"
									placeholder={formatterText('text.start')}
									value={selectValues?.rangoFechaCreacionInicial ?? ''}
									onChange={(data) => handleFechaCreacionInicial(data)}
								/>
							</label>
							<label>
								<DateTimePicker
									name="rangoFechaCreacionFinal"
									placeholder={formatterText('text.end')}
									value={selectValues?.rangoFechaCreacionFinal ?? ''}
									onChange={(data) => handleFechaCreacionFinal(data)}
								/>
							</label>
							<span
								style={{
									fontSize: '0.9rem',
									color: 'var(--text-primary)',
									fontWeight: 'bold',
									alignSelf: 'center',
									marginLeft: '10px'
								}}
							>
								{formatterText('label.text.billing.type')}
							</span>
							<label
								style={{
									marginTop: '10px'
								}}
							>
								<SelectComboBox
									name="billId"
									data={[
										{
											label: formatterText('input.placeholder.all.bill'),
											value: 0
										},
										{
											label: formatterText('input.placeholder.with.bill'),
											value: 1
										},
										{
											label: formatterText('input.placeholder.without.bill'),
											value: 2
										}
									]}
									placeholder={
										<FormattedMessage
											id="input.placeholder.select"
											defaultMessage="Selecione una opción"
										/>
									}
									dataValue={filterBill}
									setterFunction={setFilterBill}
									isRequired={false}
								/>
							</label>
						</section>
						<section className={grid['display-btn-advanced-search']}>
							<button
								className="btn-search"
								onClick={(e) => searchAdvance(e)}
								disabled={loadingProgress}
							>
								{loadingProgress ? (
									<FormattedMessage id="btn.loading" defaultMessage="Cargando..." />
								) : (
									<FormattedMessage id="btn.search" defaultMessage="Buscar" />
								)}
							</button>
							<button className="btn-clear-form" onClick={(e) => handlerClear(e)}>
								<FormattedMessage id="btn.clean" defaultMessage="Limpiar" />
							</button>
						</section>
					</form>
				) : null}

				{!loading && services !== null ? determinateData() : determinateLoader()}
			</section>
		</>
	);
};

export default ServiceTableComponent;
