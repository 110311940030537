import React from 'react';
// Import Hooks
// Import Components
import DynamicTable from 'common/DynamicTable/DynamicHead';
import Search from 'common/Search';
// Import libs
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// Import services
import { PAGE_NAMES } from 'constants/lang/services/services/pagenames';
import paths from 'services/paths';
import ClientViewModel from './Client.viewModel';

function ClientsComponent() {
	const {
		titles,
		loading,
		clients,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		handleDownloadItem,
		permittedActions,
		formatterText,
		render
	} = ClientViewModel();

	return (
		<>
			{!loading && clients !== null ? (
				<section className="table-container">
					<section className="userOptions">
						{permittedActions.consultar && (
							<Search
								placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
								width="50%"
							/>
						)}
						{permittedActions.crear && (
							<Link to={paths.createClient}>
								<button className="btn-add">
									{formatterText('header.title.client.create', 'Crear Cliente')}
								</button>
							</Link>
						)}

						{permittedActions.exportar && (
							<button className="btn-export" onClick={handleDownloadItem}>
								<FormattedMessage id="btn.downloadfile" defaultMessage="Descargar Excel" />
							</button>
						)}
					</section>
					{permittedActions.consultar && (
						<FormattedMessage id="table.name.search.client" defaultMessage="Cliente">
							{() => (
								<DynamicTable
									titles={titles}
									pageName={PAGE_NAMES.Clientes}
									getData={getDataTable}
									handleDeleteItem={handleDeleteItem}
									handleEditStateItem={handleEditStateItem}
									routeToEdit={paths.updateClient}
									canDeleted={permittedActions.eliminar}
									canModify={permittedActions.editar}
								/>
							)}
						</FormattedMessage>
					)}
				</section>
			) : (
				render()
			)}
		</>
	);
}

export default ClientsComponent;
