import { useSeachContext } from 'context/SearchContext';
import { useState } from 'react';

export default function DynamicHeadServicesViewModel() {
	// context de búsqueda
	const { searchResults = [] } = useSeachContext();
	// Pagination state
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(50);
	// Alerts
	const [open, setOpen] = useState(false);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};
	return {
		searchResults,
		rowsPerPage,
		page,
		handleChangePage,
		handleChangeRowsPerPage,
		handleClose,
		open
	};
}
