import { EMAIL_REGEX } from 'common/validators/Regex';
import validateMailAssesor from './ValidateMailAssesor';
import validateMailOptionAssesor from './ValidateMailOptionAssesor';

const HandlerChangeMailAssesor = (e, currentValue, setCurrentValue, setError) => {
	if (e.target.name === 'emailAsesor') {
		validateMailAssesor(e, EMAIL_REGEX, setError);
	}

	if (e.target.name === 'correoAlternoAsesor') {
		validateMailOptionAssesor(e, EMAIL_REGEX, setError);
	}

	setCurrentValue({
		...currentValue,
		[e.target.name]: e.target.value.replaceAll(/\s{2,}/gi, ' ')
	});
};

export default HandlerChangeMailAssesor;
