import React from 'react';

// Mui Cell row style
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
// Import Components
import BtnDelete from 'common/minimalTables/actions/BtnDelete';
// Import Libs
import dateFormat from 'dateformat';
// Import Services

const RowVehiclesAssign = ({ item, i, handleDelete }) => {
	return (
		<TableRow key={i}>
			<TableCell>{item.placa}</TableCell>
			<TableCell>{item.modelo}</TableCell>
			<TableCell>{dateFormat(item.fechaSoap, 'yyyy/mm/dd')}</TableCell>
			<TableCell>{dateFormat(item.fechaRunt, 'yyyy/mm/dd')}</TableCell>
			<TableCell>{dateFormat(item.fechaTecnicoMecanica, 'yyyy/mm/dd')}</TableCell>
			<TableCell>{item.runt}</TableCell>
			<BtnDelete item={item} handleDelete={handleDelete} />
		</TableRow>
	);
};

export default RowVehiclesAssign;
