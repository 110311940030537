import React from 'react';
// Import Contexts
import { SearchWrapper } from 'context/SearchContext';
import { PermissionWrapper } from 'context/PermissionContext';
import ServicesTableComponent from './ParametersServicesTable.component';

const ServicesTable = () => {
	return (
		<SearchWrapper>
			<PermissionWrapper>
				<ServicesTableComponent />
			</PermissionWrapper>
		</SearchWrapper>
	);
};

export default ServicesTable;
