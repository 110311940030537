import PropTypes from 'prop-types';

export const InputCheckbox = ({
	name,
	activeText,
	labelText,
	value: isChecked,
	unActiveText,
	onChange
}) => {
	return (
		<section className="d-flex flex-start margin-top">
			<span className="text-inline-md" htmlFor={name}>
				{labelText}
			</span>
			<section className="w100-container">
				<section
					style={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'center',
						color: '#747474'
					}}
				>
					<label className="switch">
						<input id={name} name={name} checked={isChecked} onChange={onChange} type="checkbox" />
						<span className="slider round"></span>
					</label>
					<p
						style={{
							fontSize: 16,
							fontWeight: 'bold',
							padding: 0,
							paddingLeft: 8,
							textDecoration: 'none',
							color: 'var(--dark-gray)'
						}}
					>
						{isChecked ? activeText : unActiveText}
					</p>
				</section>
			</section>
		</section>
	);
};

InputCheckbox.propTypes = {
	activeText: PropTypes.string,
	labelText: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.bool,
	unActiveText: PropTypes.string,
	onChange: PropTypes.func
};
