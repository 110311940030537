import React from 'react';
import PropTypes from 'prop-types';

// Import Libs
import { FormikProvider } from 'formik';

// Import Components
import { InputCheckbox } from 'common/inputs/InputCheckbox';
import { Input } from 'common/inputs/Input';
import { Button } from 'common/Button';
import { SelectField } from 'common/SelectField';

// Import Paths
import paths from 'services/paths';
import { areaOptions } from 'constants/mails';

export const CreateMailForm = ({ formatterText, formik, navigate, permittedActions }) => {
	const {
		errors,
		handleBlur,
		handleChange,
		handleSubmit,
		isSubmitting,
		isValid,
		touched,
		values
	} = formik;

	return (
		<FormikProvider value={formik}>
			<form className="form-responsive margin-top" noValidate onSubmit={handleSubmit}>
				<SelectField
					error={errors.area}
					labelText={formatterText('p.area', 'Área')}
					name="area"
					onBlur={handleBlur}
					options={areaOptions(formatterText)}
					placeholder={formatterText('input.placeholder.select', 'Selecione una opción')}
					touched={touched.area}
					value={values.area}
				/>
				<Input
					error={errors.host}
					labelText={'Host'}
					name={'host'}
					onBlur={handleBlur}
					placeholder={formatterText('input.placeholder.host', 'Host del servicio')}
					onChange={handleChange}
					touched={touched.host}
					type={'text'}
					value={values.host}
				/>
				<Input
					error={errors.usuario}
					labelText={formatterText('p.associate.email', 'Correo asociado')}
					name={'usuario'}
					onBlur={handleBlur}
					placeholder={formatterText('input.placeholder.mail.associated', 'Correo asociado')}
					touched={touched.usuario}
					type={'email'}
					onChange={handleChange}
					value={values.usuario}
				/>
				<Input
					error={errors.clave}
					labelText={formatterText('p.key', 'Clave')}
					name={'clave'}
					onBlur={handleBlur}
					placeholder={formatterText('input.placeholder.key', 'Clave')}
					touched={touched.clave}
					type={'password'}
					onChange={handleChange}
					value={values.clave}
				/>
				<Input
					error={errors.puerto}
					labelText={formatterText('p.port', 'Puerto')}
					name={'puerto'}
					onBlur={handleBlur}
					placeholder={formatterText('input.port.service', 'Puerto del servicio')}
					touched={touched.puerto}
					type={'text'}
					onChange={handleChange}
					value={values.puerto}
				/>
				<InputCheckbox
					activeText={formatterText('p.active', 'Activo')}
					labelText={formatterText('p.smtp.secure', 'Seguridad SMTP')}
					name={'smtpSecure'}
					value={values.smtpSecure}
					onChange={handleChange}
					unActiveText={formatterText('p.unActive', 'No activo')}
				/>
				<InputCheckbox
					activeText={formatterText('p.active', 'Activo')}
					labelText={formatterText('p.smtp.auth', 'Autenticación SMTP')}
					name={'smtpAuth'}
					value={values.smtpAuth}
					onChange={handleChange}
					unActiveText={formatterText('p.unActive', 'No activo')}
				/>
				<section className="form-responsive-container-buttons">
					{(permittedActions.editar || permittedActions.crear) && (
						<Button
							type="submit"
							className="btn-primary"
							text={formatterText('btn.save.changes', 'Guardar cambios')}
							disabled={!isValid || isSubmitting}
						/>
					)}
					<Button
						className="input-cancel"
						text={formatterText('btn.cancel', 'Cancelar')}
						onClick={() => navigate(paths.home)}
					/>
				</section>
			</form>
		</FormikProvider>
	);
};

CreateMailForm.propTypes = {
	formatterText: PropTypes.func,
	navigate: PropTypes.func,
	permittedActions: PropTypes.object,
	formik: PropTypes.any
};
