import { Formiz } from '@formiz/core';
import { InputFieldResponsive } from 'common/inputs/InputFieldResponsive';
import InputSelectorResponsive from 'common/inputs/InputSelectorResponsive';
import { formatterText } from 'hooks/useLangv2';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import AddBranchOfficeViewModel from './AddBranchOffice.viewModel';
import { AddBranchOfficeProps } from './AddBranchOffice.props';

export default function AddBranchOffice({ onClose }) {
	const { selectData, loadingData, myForm, modalData, handleSubmit } = AddBranchOfficeViewModel({
		onClose
	});
	return (
		<Formiz onSubmit={handleSubmit} className="form-responsive" connect={myForm}>
			<form className="container-wrapForm" noValidate onSubmit={myForm.submit}>
				<section className="grid-container-1c zero-gap">
					<label className="d-flex">
						<span className="text-inline-md">
							<FormattedMessage id="p.municipiociudad" defaultMessage="Municipio o ciudad" />
						</span>
						<InputSelectorResponsive
							type="text"
							name="idCiudadMunicipio"
							id="idCiudadMunicipio"
							className="input-select-small"
							placeholder={
								<FormattedMessage
									id="input.placeholder.select"
									defaultMessage="Selecione una opción"
								/>
							}
							required="Municipio es requerido"
							defaultValue={{
								label:
									typeof modalData.idCiudadMunicipio === 'string'
										? JSON.parse(modalData.idCiudadMunicipio).label
										: modalData.idCiudadMunicipio.label,
								value:
									typeof modalData.idCiudadMunicipio === 'string'
										? JSON.parse(modalData.idCiudadMunicipio).value
										: modalData.idCiudadMunicipio.value
							}}
							data={
								!!selectData.municipalities &&
								selectData.municipalities.map((item) => {
									const docLabel = `${item.nombre}`;
									return {
										label: docLabel,
										value: JSON.stringify({
											label: item.nombre,
											value: item.idMunicipio
										})
									};
								})
							}
							isLoading={loadingData}
						/>
					</label>
					<label className="d-flex">
						<span className="text-inline-md">
							<FormattedMessage id="tab.title.sector" defaultMessage="Sector" />
						</span>
						<InputSelectorResponsive
							type="text"
							name="idSector"
							id="idSector"
							className="input-select-small"
							placeholder={
								<FormattedMessage
									id="input.placeholder.select"
									defaultMessage="Selecione una opción"
								/>
							}
							required="Sector es requerido"
							defaultValue={{
								label:
									typeof modalData.idSector === 'string'
										? JSON.parse(modalData.idSector).label
										: modalData.idSector.label,
								value:
									typeof modalData.idSector === 'string'
										? JSON.parse(modalData.idSector).value
										: modalData.idSector.value
							}}
							data={
								!!selectData.sectors &&
								selectData.sectors.map((item) => {
									const docLabel = `${item.nombre}`;
									return {
										label: docLabel,
										value: JSON.stringify({
											label: item.nombre,
											value: item.idSector
										})
									};
								})
							}
							isLoading={loadingData}
						/>
					</label>

					<label className="d-flex">
						<span className="text-inline-md">
							<FormattedMessage id="tab.title.direccion" defaultMessage="Dirección completa" />
						</span>
						<InputFieldResponsive
							type="text"
							name="direccion"
							id="direccion"
							placeholder="Dirección completa"
							validateInput="text"
							required="La direción es requerida"
							className="input-primary width-50"
							defaultValue={modalData.direccion}
						/>
					</label>
					<label className="d-flex">
						<span className="text-inline-md">
							<FormattedMessage id="tab.title.adiconaldatos" defaultMessage="Datos adicionales" />
						</span>
						<InputFieldResponsive
							type="text"
							name="datosAdicionales"
							id="datosAdicionales"
							placeholder="Ej: Número de local, oficina, aparatemento, peso, manzana"
							validateInput="text"
							required="Los datos adiciones son requeridos"
							className="input-primary width-50"
							defaultValue={modalData.datosAdicionales}
						/>
					</label>
					<label className="d-flex">
						<span className="text-inline-md">
							<FormattedMessage id="tab.title.observaciones" defaultMessage="Observaciones" />
						</span>
						<InputFieldResponsive
							type="text"
							name="observaciones"
							id="observaciones"
							placeholder="Ej: Observaciones"
							required="Las observaciones son requeridos"
							className="input-primary width-50"
							validateInput="text"
							defaultValue={modalData.observaciones}
						/>
					</label>
				</section>
				<section className="form-responsive-container-buttons">
					<input
						type="submit"
						className="width-100-fix"
						value={formatterText('btn.update.branch')}
					/>
					<input
						type="button"
						className="width-100-fix input-cancel"
						value={formatterText('btn.cancel')}
						onClick={onClose}
					/>
				</section>
			</form>
		</Formiz>
	);
}

AddBranchOffice.propTypes = AddBranchOfficeProps;
