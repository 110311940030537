export const parseDataToFormik = ({
	formikInitialValues,
	dataByServer: {
		idTipoArchivoAdjunto,
		cantidadMaxima,
		descripcion,
		estado = '',
		extension,
		fechaCreacion,
		fechaModificacion,
		nombre,
		pesoMaximoArchivo,
		usuarioCreacion,
		usuarioModificacion,
		categorias
	},
	resp
}) => {
	const newFormikData = {};

	if (idTipoArchivoAdjunto) {
		newFormikData.idTipoArchivoAdjunto = idTipoArchivoAdjunto;
	}

	if (cantidadMaxima) {
		newFormikData.cantidadMaxima = cantidadMaxima;
	}

	if (descripcion) {
		newFormikData.descripcion = descripcion;
	}

	if (typeof estado === 'number') {
		newFormikData.active = Boolean(estado);
	}

	if (extension) {
		newFormikData.extension = extension;
	}

	if (fechaCreacion) {
		newFormikData.fechaCreacion = fechaCreacion;
	}

	if (fechaModificacion) {
		newFormikData.fechaModificacion = fechaModificacion;
	}

	if (nombre) {
		newFormikData.nombre = nombre;
	}

	if (pesoMaximoArchivo) {
		newFormikData.pesoMaximoArchivo = pesoMaximoArchivo;
	}

	if (usuarioCreacion) {
		newFormikData.usuarioCreacion = usuarioCreacion;
	}

	if (usuarioModificacion) {
		newFormikData.usuarioModificacion = usuarioModificacion;
	}

	if (resp) {
		categorias = resp.map((item) => {
			return {
				value: item.tipoUsuario.idTipoUsuario,
				label: item.tipoUsuario.tipoUsuario,
				idTipoAdjuntoUsuario: item.idTipoAdjuntoUsuario
			};
		});

		newFormikData.categorias = categorias.map((item) => item.value);
	}

	return {
		...formikInitialValues,
		...newFormikData
	};
};
