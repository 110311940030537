import React from 'react';
// Import Contexts
import TableMinimalNoContext from 'common/minimalTables/TableMinimalNoContext';
// Import Hooks
import { formatterText } from 'hooks/useLangv2';
// Import Components
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { FormattedMessage } from 'react-intl';
import ModalDetails from '../ModalDetails';
import ModalConfirm from '../modalSearch/ModalConfirm';
import ModalSearch from '../modalSearch/ModalSearch';
import SearchTechniciansAuxViewModel from './SearchTechniciansAux.viewModel';
// Import Libs
// Import Services
// Import Styles
import 'styles/colorPicker.css';

const style = {
	position: 'absolute',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3
};
export default function SearchTechniciansAux() {
	const {
		styleCenter,
		styleWrap,
		styleLeft,
		setOpen,
		handleClose,
		technicians,
		technicians2,
		technicians3,
		titlesTableCostProduct,
		verifyTechAvailable,
		techToAssign,
		selectTech,
		setSelectTech,
		clickFun,
		consultaStart,
		clickOnRow,
		seeModalDetails,
		seeDetails,
		setSeeDetails,
		currentDetail,
		loadingTech,
		techAssignService,
		titlesTableAssignTech,
		servicesData,
		open
	} = SearchTechniciansAuxViewModel();
	return (
		<>
			<div style={styleCenter}>
				{`#${servicesData.ticket} - ${servicesData.categoriaServicio} - ${servicesData.tipoServicio}`}
			</div>

			<div style={styleWrap}>
				<div style={styleLeft}>{formatterText('title.service.search.manual.techs')}</div>
				<button
					className="btn-action-primary custom-margin-0001"
					onClick={(e) => {
						e.preventDefault();
						setOpen(true);
					}}
				>
					{formatterText('service.search.manual.button.modify.service')}
				</button>
				{/* <buttassignServiceAux */}
				<button className="btn-action-primary custom-margin-0001" onClick={() => consultaStart()}>
					{formatterText('btn.update')}
				</button>
			</div>

			{/* PRIMERA TABLA */}
			<p className="wrap-form-title">
				{formatterText('title.service.search.manual.table.techs.assign')}
			</p>
			{techAssignService.length > 0 && (
				<>
					<section className="form-responsive-container-information">
						<TableMinimalNoContext
							titles={titlesTableAssignTech}
							data={techAssignService}
							type="technicalAssignToService"
							labelTable={formatterText('title.service.search.manual.page.techPerPage')}
							handleSelect={(x) => clickOnRow(x)}
							handleDetails={(x) => seeModalDetails(x)}
						/>
					</section>
				</>
			)}

			{/* PRIMERA TABLA */}
			<p className="wrap-form-title">
				{formatterText('title.service.search.manual.table.techs.associated.client')}
			</p>
			{technicians.length > 0 ? (
				<>
					<section className="form-responsive-container-information">
						<TableMinimalNoContext
							titles={titlesTableCostProduct}
							data={technicians}
							type="searchServicesTech"
							labelTable={formatterText('title.service.search.manual.page.manualTechSearch')}
							handleSelect={(x) => clickOnRow(x)}
							handleDetails={(x) => seeModalDetails(x)}
						/>
					</section>
				</>
			) : (
				<>
					<section className="form-responsive-container-information">
						<FormattedMessage
							id="text.tech.no.found.auto"
							defaultMessage="No se encontraron técnicos asociados al cliente."
						/>
					</section>
				</>
			)}

			{/* SEGUNDA TABLA */}
			<p className="wrap-form-title">
				{formatterText('title.service.search.manual.table.techs.associated.unit.bussines')}
			</p>
			{technicians2.length > 0 ? (
				<>
					<section className="form-responsive-container-information">
						<TableMinimalNoContext
							titles={titlesTableCostProduct}
							type="searchServicesTech"
							data={technicians2}
							labelTable={formatterText('title.service.search.manual.page.manualTechSearch')}
							handleSelect={(x) => clickOnRow(x)}
							handleDetails={(x) => seeModalDetails(x)}
						/>
					</section>
				</>
			) : (
				<>
					<section className="form-responsive-container-information">
						<FormattedMessage
							id="text.tech.no.found.auto"
							defaultMessage="No se encontraron técnicos asociados a la unidad de negocio."
						/>
					</section>
				</>
			)}
			{/* TERCERA TABLA */}
			<p className="wrap-form-title">
				{formatterText('title.service.search.manual.table.techs.not.associated')}
			</p>
			{technicians3.length > 0 ? (
				<>
					<section className="form-responsive-container-information">
						<TableMinimalNoContext
							titles={titlesTableCostProduct}
							type="searchServicesTech"
							data={technicians3}
							labelTable={formatterText('title.service.search.manual.page.manualTechSearch')}
							handleSelect={(x) => clickOnRow(x)}
							handleDetails={(x) => seeModalDetails(x)}
						/>
					</section>
				</>
			) : (
				<>
					<section className="form-responsive-container-information">
						<FormattedMessage
							id="text.tech.no.found.auto"
							defaultMessage="No se encontraron técnicos."
						/>
					</section>
				</>
			)}
			<Modal
				open={open}
				onClose={() => handleClose(setOpen)}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box sx={{ ...style, width: '35%' }}>
					<ModalSearch
						onClose={() => handleClose(setOpen)}
						setterFun={() => {
							console.log('setterFun');
						}}
						verifyTechAvailable={verifyTechAvailable}
					/>
				</Box>
			</Modal>

			<Modal
				open={selectTech}
				onClose={() => handleClose(setSelectTech)}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box sx={{ ...style, width: '35%' }}>
					<ModalConfirm
						onClose={() => handleClose(setSelectTech)}
						clickFun={clickFun}
						dataToAssign={techToAssign}
						loadingTech={loadingTech}
					/>
				</Box>
			</Modal>
			<Modal
				open={seeDetails}
				onClose={() => handleClose(setSeeDetails)}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box sx={{ ...style, width: '45%' }}>
					<ModalDetails item={currentDetail} />
				</Box>
			</Modal>
		</>
	);
}
