import PropTypes from 'prop-types';

/* { titles, data, type, max, canSearch } */
export const DynamicScheduleTableProps = {
	titles: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	type: PropTypes.string.isRequired,
	max: PropTypes.bool,
	canSearch: PropTypes.bool
};
