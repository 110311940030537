import useAxios from 'hooks/useAxios';
import Axios from 'axios';
import { MODULES_NAME } from 'common/validators/PermissionsConstants';
import { usePermissionContext } from 'context/PermissionContext';
import { useSeachContext } from 'context/SearchContext';
import dateFormat from 'dateformat';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import { useEffect } from 'react';
import endpoints from 'services/api';

export default function UsersTableViewModel() {
	const { RequestUseCase } = useAxios();

	// hook to loading data
	const {
		loading,
		error,
		toggleLoading,
		toggleError,
		handleClick,
		displayMessage,
		displayLoading
	} = useGetData();
	// useContext de búsqueda
	const { searchResults = [], setDataTable } = useSeachContext();
	// hook useLocation

	// use Hook of language v2
	const { formatterText } = useLangv2();

	// titulos de la tabla
	const titles = [
		formatterText('table.title.user', 'Usuario'),
		formatterText('table.title.role', 'Rol(es)'),
		formatterText('table.title.email', 'Correo electrónico'),
		formatterText('table.title.creationD', 'Fecha de creación'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];

	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();
	const permissionsAccessUsers = () => {
		permissionsAccess(MODULES_NAME.user);
	};

	// función para cargar datos
	const getDataTable = async () => {
		// show loading
		toggleLoading(true);

		return RequestUseCase.get(endpoints.users.getAllUsers)
			.then((data) => {
				const newArray = [];
				data.forEach((item) => {
					handleStructureItems(newArray, item);
				});
				setDataTable(newArray);
				// show loading
				toggleLoading(false);
			})
			.catch((err) => {
				if (Axios.isCancel(error)) {
					console.log('axios request cancelled', error.message);
				} else {
					console.log(err);
					// mostrar error
					toggleError(!error);
					handleClick();
				}
			});
	};

	const handleDeleteItem = (rowId) => {
		const deletePromise = new Promise((res, reject) => {
			RequestUseCase.delete(endpoints.users.deleteUser(rowId))
				.then(async () => {
					await getDataTable();
					res(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return deletePromise;
	};

	const handleStructureItems = (newArray, item) => {
		let roles = '';

		if (item.roles.length > 0) {
			item.roles.forEach((role) => {
				roles += role.nombre + ', ';
			});
		} else {
			roles = '';
		}

		const created_at =
			item.fechaRegistro !== ''
				? dateFormat(item.fechaRegistro, 'yyyy/mm/dd - h:MM:ss TT')
				: 'Sin fecha';

		const mail = item.correo !== '' ? item.correo : 'Sin correo';
		newArray.push({
			id: item.idUsuario,
			nombre: item.usuario !== '' ? item.usuario : 'Sin nombre',
			roles: roles !== '' ? roles.slice(0, -2) : 'Sin rol(es)',
			mail,
			created_at,
			estado: item.estado,
			objeto: { ...item }
		});
	};

	const handleEditStateItem = (body) => {
		return new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.users.updateUser, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	useEffect(() => {
		getDataTable();
	}, []);

	useEffect(() => {
		permissionsAccessUsers();
	}, [permissions]);
	return {
		loading,
		error,
		displayMessage,
		displayLoading,
		searchResults,
		handleDeleteItem,
		handleEditStateItem,
		getDataTable,
		titles,
		permittedActions,
		formatterText
	};
}
