import { useContext, useEffect, useState } from 'react';
// Import Context
// Import Hooks
import useGetData from 'hooks/useGetData';
// Import Components
import { ContextUse } from '../ContextAlert';

export default function NotificationsViewModel() {
	// hook to loading data
	const { loading, error, displayMessage, displayLoading } = useGetData();
	const { state, _SetDispatch, DeterminateTypeUser } = useContext(ContextUse);
	const [updated, setUpdated] = useState(false);

	const setCurrentPage = (page) => {
		_SetDispatch({ type: 'CURRENT_PAGE_NOTIFICATION', payload: page });
	};

	const setRowsPerPage = (rows) => {
		_SetDispatch({ type: 'NOT_PER_PAGE', payload: rows });
	};

	useEffect(() => {
		DeterminateTypeUser();
	}, [updated === true]);
	return {
		loading,
		error,
		displayMessage,
		displayLoading,
		setCurrentPage,
		setRowsPerPage,
		setUpdated,
		state,
		DeterminateTypeUser
	};
}
