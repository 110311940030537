import React from 'react';

// Import services
import { SearchWrapper } from 'context/SearchContext';
import EditComponet from './EditRol.component';

export default function Edit() {
	return (
		<SearchWrapper>
			<EditComponet />
		</SearchWrapper>
	);
}
