/**
 * @returns{object}
 */
export const getFormikInitialValues = () => {
	return {
		usuario: '',
		correo: '',
		rol: '',
		acceso: '',
		entidad: '',
		entidadId: ''
	};
};
