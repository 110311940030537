import Typography from '@mui/material/Typography';
import RolSelector from 'common/RolSelector';
import Selector from 'common/Selector';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import paths from 'services/paths';
import './editUser.css';
import { formViewModel } from './form.viewModels';

export const FormComponents = () => {
	const {
		loading,
		selectorData,
		setSearch,
		auxPlaceholder,
		entityPlaceholder,
		selectedRoles,
		acces,
		setAcces,
		entity,
		setEntity,
		formData,
		setFormData,
		formatterText,
		navigate,
		toggleSelector,
		setToggleSelector,
		selectValue,
		handleRemoveRole,
		handleSubmit,
		handleAddRole,
		handleChange,
		handleChangeMail
	} = formViewModel();

	return (
		<>
			<section className="container-profile">
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div>
						<img
							src={formData.avatarUrl}
							alt="profile image"
							width="200"
							height="200"
							style={{ borderRadius: '50%' }}
						/>
						<p
							style={{
								marginTop: '8px',
								fontWeight: 'bold',
								color: '#747474',
								textDecoration: 'underline'
							}}
						>
							<FormattedMessage id="p.change.profile.pic" defaultMessage="Editar foto de perfil" />
						</p>
					</div>

					<div style={{ textAlign: 'left', marginLeft: '30px' }}>
						<Typography
							variant="h1"
							sx={{
								fontSize: '1.6rem',
								fontWeight: 'bold',
								color: '#8B909C'
							}}
						>
							<FormattedMessage id="p.user.name" defaultMessage="Nombre del usuario" />
						</Typography>
						<Typography
							variant="h1"
							sx={{
								fontSize: '1rem',
								fontWeight: 'bold',
								color: '#0ED6B5'
							}}
						>
							{formatterText('table.title.user', 'Usuario') + ': ' + formData.usuario}
						</Typography>
					</div>
				</div>

				<Typography
					variant="h1"
					sx={{
						fontSize: '1.2rem',
						fontWeight: 'bold',
						color: '#0ED6B5'
					}}
				>
					{formData.estado === 1
						? formatterText('p.active', 'Activo')
						: formData.estado === 0
						? formatterText('p.unActive', 'No activo')
						: ''}{' '}
					{formData.estado === 2 && 'Inhabilitado'}
					<label className="switch">
						<input
							checked={formData.estado === 1}
							onChange={() => {
								if (formData.estado === 1) {
									setFormData({ ...formData, estado: 0 });
								} else {
									setFormData({ ...formData, estado: 1 });
								}
							}}
							type="checkbox"
						/>
						<span className="slider round"></span>
					</label>
				</Typography>
			</section>

			<form className="form-responsive" onSubmit={handleSubmit}>
				<section className="form-responsive-container-information">
					<section className="form-responsive-information__option">
						<h3 className="p-styles">
							<FormattedMessage id="table.title.user" defaultMessage="Usuario" />
						</h3>

						<input
							className="input-edit"
							type="text"
							placeholder={formatterText(
								'input.placeholder.userName',
								'Ingrese el nombre de usuario'
							)}
							name="usuario"
							id="usuario"
							value={formData.usuario}
							onChange={handleChange}
							maxLength="100"
							required
						/>
					</section>

					<section className="form-responsive-information__option">
						<h3 className="p-styles">
							<FormattedMessage id="table.title.email" defaultMessage="Correo electrónico" />
						</h3>

						<input
							className="input-edit"
							type="email"
							placeholder={formatterText(
								'input.placeholder.email',
								'Ingrese el correo electrónico'
							)}
							name="correo"
							value={formData.correo}
							id="correo"
							onChange={handleChangeMail}
							maxLength="40"
							required
						/>
					</section>

					{formData.estado === 1 ? (
						<section className="form-responsive-information__option">
							<h3 className="p-styles">
								<FormattedMessage id="table.name.search.rols" defaultMessage="Roles" />
							</h3>
							<div>
								<div style={{ display: 'flex', flexWrap: 'wrap' }} className="input-edit inputRole">
									<button
										onClick={(e) => {
											e.preventDefault();
											setToggleSelector(!toggleSelector);
											setSearch('');
										}}
										className="add-role"
									>
										<FormattedMessage id="p.addRole" defaultMessage="Añadir rol +" />
									</button>
									{selectedRoles.map((rol, index) => (
										<button
											key={index}
											onClick={(e) => handleRemoveRole(e, rol)}
											className="role-item"
										>
											{rol.nombre} {rol.estado === 1 ? '(Activo)' : '(Inactivo)'}
											<div></div>
										</button>
									))}
								</div>
								{toggleSelector && <RolSelector handleAdd={handleAddRole} />}
							</div>
						</section>
					) : null}

					<section className="form-responsive-information__option">
						<h3 className="p-styles">
							<FormattedMessage id="p.accesType" defaultMessage="Tipo de acceso" />
						</h3>

						<div>
							<button
								style={{ marginRight: '20px' }}
								className={
									acces.mobile ? 'create-user-button button-selected' : 'create-user-button'
								}
								onClick={(e) => {
									e.preventDefault();
									setAcces({ ...acces, mobile: !acces.mobile });
								}}
							>
								<FormattedMessage id="p.mobile" defaultMessage="Móvil" />
							</button>
							<button
								className={acces.web ? 'create-user-button button-selected' : 'create-user-button'}
								onClick={(e) => {
									e.preventDefault();
									setAcces({ ...acces, web: !acces.web });
								}}
							>
								<FormattedMessage id="p.web" defaultMessage="Web" />
							</button>
						</div>
					</section>
					{/* selector entity */}
					<section className="form-responsive-information__option spacing-t1">
						<h3 className="p-styles">
							<FormattedMessage id="p.entity" defaultMessage="Entidad" />
						</h3>
						<Selector
							name="entidad"
							data={[
								{
									label: formatterText('input.placeholder.third', 'Tercero'),
									value: 'tercero'
								},
								{
									label: formatterText('input.placeholder.employee', 'Empleado'),
									value: 'empleado'
								},
								{
									label: formatterText('input.placeholder.asesor', 'Asesor Cliente'),
									value: 'cliente'
								}
							]}
							placeholder={entityPlaceholder}
							dataValue={entity}
							setterFunction={setEntity}
							isRequired={true}
						/>
						{selectorData.length > 0 && (
							<Selector
								name="entidadId"
								data={selectorData}
								placeholder={auxPlaceholder}
								dataValue={entity}
								setterFunction={setEntity}
								isLoading={loading}
								selectValue={selectValue}
								isRequired={true}
							/>
						)}
					</section>
				</section>
				<div className="form-responsive-container-buttons">
					<button type="submit" className="btn-primary">
						<FormattedMessage id="btn.edit.user" defaultMessage="Editar usuario" />
					</button>
					<button className="input-cancel" onClick={() => navigate(paths.users)}>
						<FormattedMessage id="btn.cancel" defaultMessage="Cancelar" />
					</button>
				</div>
			</form>
		</>
	);
};
