import CustomAlert from 'components/CustomAlert';

/**
 * Muestra una alerta indicando error
 * @param {string} error - message
 */
const HandleOnError = (error) => {
	// verificar si este mensaje lo arroja el back
	CustomAlert('short_msg', {
		icon: 'error',
		title: 'Oops...',
		text: error
	});
};

export default HandleOnError;
