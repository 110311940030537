import React from 'react';
import { TableMinimalWrapper } from 'context/TableMinimalContext';
import { SearchWrapper } from 'context/SearchContext';
import CreateClientComponents from './CreateClient.component';
import { CreateClientProps } from './CreateClient.props';

const CreateClient = ({ setIsOpenCreateClient }) => {
	return (
		<SearchWrapper>
			<TableMinimalWrapper>
				<CreateClientComponents setIsOpenCreateClient={setIsOpenCreateClient} />
			</TableMinimalWrapper>
		</SearchWrapper>
	);
};

CreateClient.propTypes = CreateClientProps;

export default CreateClient;
