/**
 * @returns {object}
 */
export const getFormikInitialValues = () => {
	return {
		nombre: '',
		extension: '',
		pesoMaximoArchivo: '',
		cantidadMaxima: '',
		categorias: []
	};
};
