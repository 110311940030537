export const COLLECTIONS = {
	title: 'service.services.editService.collections',
	registerCollection: 'service.services.editService.collections.registerCollection',
	income: 'service.services.editService.collections.income',
	returns: 'service.services.editService.collections.returns',
	totalValueCollected: 'service.services.editService.collections.totalValueCollected',
	tableTitles: {
		halfPayment: 'service.services.editService.collections.halfPayment',
		collectionStatus: 'service.services.editService.collections.collectionStatus',
		cost: 'service.services.editService.collections.cost',
		description: 'service.services.editService.collections.description',
		user: 'service.services.editService.collections.user',
		creationDate: 'service.services.editService.collections.creationDate'
	}
};
