import useAxios from 'hooks/useAxios';
import { useContext, useEffect, useState } from 'react';
// Import Context
import { TableMinimalContext } from 'context/TableMinimalContext';
// Import Hooks
import useGetData from 'hooks/useGetData';
import useLang from 'hooks/useLang';
import useProgress from 'hooks/useProgress';
import { useNavigate } from 'react-router-dom';
// Import Components
import { useForm } from '@formiz/core';
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';
// Import libs
import HandlerText from 'common/validators/HandlerText';
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
// Import services
import endpoints from 'services/api';
import paths from 'services/paths';

//TABLE CONTEXT
import { useSeachContext } from 'context/SearchContext';
import { isUndefined } from 'lodash';

export default function UpdateActivityViewModel() {
	const { RequestUseCase } = useAxios();
	const { searchResults = [], setDataTable } = useSeachContext();

	// Formiz object
	const myForm = useForm();

	// Call context TableMinimalContext
	const { currentDataTable, setCurrentDataTable } = useContext(TableMinimalContext);
	const navigate = useNavigate();
	// useLanguage
	const { formatterText } = useLang();
	// Form information
	const [currentDataForm, setCurrentDataForm] = useState(null);
	// Current data table
	const [currentItemMinimal, setCurrentItemMinimal] = useState([]);
	// Value that select use
	const [selectedSearch, setSearchSelected] = useState([
		{
			categoriasServicios: [],
			tipoServicio: [],
			actividadesServicios: [],
			etapaServicios: [],
			typeServicesActivity: [],
			formsAssociate: [],
			clientsAssociate: []
		}
	]);

	// Helps to loading select data
	const { loading, toggleLoading } = useGetData();
	// Helps to loading data table
	const { loadingProgress, setLoadingProgress, DisplayProgress } = useProgress();
	// Helps to load Select data
	const [auxData, setAuxData] = useState({
		idTipoServicioActividad: uuidv4(),
		idCategoriaServicio: 0,
		idTipoServicio: 0,
		idActividad: 0,
		idEtapaServicio: 0,
		actividadTecnica: 0,
		idTypeServiceActivity: 0,
		idFormulario: 0,
		idCliente: 0
	});
	// toggle state
	const [active, setActive] = useState(true);

	// Call local storage to get data to update
	useEffect(() => {
		getDataToUpdate();
		// Tab 1
		getAllCategoriesServices();
		getAllActivitiesServices();
		getAllStageServices();
		// Tab 2
		getAllTypeServicesActivity();
		getAllFormsWithActiveState();
		getAllClientsToAssociate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Get all type Services Activity by id
	useEffect(() => {
		getAllFormsClientsByTypeServicesActivity(
			auxData.idTypeServiceActivity.value,
			auxData.idCliente.value
		);
	}, [auxData.idTypeServiceActivity, auxData.idCliente]);

	// Get the activities by id type service
	useEffect(() => {
		if (selectedSearch.tipoServicio !== 0) {
			getAllActivitiesTypeServicesById();
		}
		// this only is call when the id Type Service change
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auxData.idTipoServicio]);

	// Get the type of service by id category service
	useEffect(() => {
		if (auxData.idCategoriaServicio !== 0) {
			// this only is call when the id Category Service change, and render the element in the selector
			getAllTypeServicesById(auxData.idCategoriaServicio);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auxData.idCategoriaServicio]);

	// get data from the localstorage
	const getDataToUpdate = () => {
		const dataUpdate = JSON.parse(localStorage.getItem('dataUpdate'));

		if (dataUpdate) {
			// set state
			setActive(dataUpdate.estado);
			// set form
			setCurrentDataForm(dataUpdate);
		} else {
			navigate(paths.services);
		}
	};

	// Get the data of all category services to the selector
	const getAllCategoriesServices = () => {
		toggleLoading(true);

		RequestUseCase.get(endpoints.serviceCategory.getAllServiceCategory)
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idCategoriaServicio,
							label: `${item.nombre} - ${item.codigo}`,
							isFixed: true
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					categoriasServicios: newArray
				}));
				toggleLoading(false);
			})
			.catch((err) => {
				console.log(err);
				toggleLoading(false);
			});
	};

	// Get the data of all type category Activity to the selector
	const getAllTypeServicesActivity = () => {
		toggleLoading(true);

		RequestUseCase.get(endpoints.typoServiceActivities.getAllTypoServiceActivities)
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					newArray.push({
						value: item.idTipoServicioActividad,
						label: `${item.idTipoServicio.nombre} - ${item.idActividad.nombre} - ${item.idEtapaServicio.nombre}`,
						isFixed: true
					});
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					typeServicesActivity: newArray
				}));
				toggleLoading(false);
			})
			.catch((err) => {
				console.log(err);
				toggleLoading(false);
			});
	};

	// Get the data of all forms with only active state to the selector
	const getAllFormsWithActiveState = () => {
		RequestUseCase.get(endpoints.activityformClient.getAllFormWithTrueState)
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					newArray.push({
						value: item.idFormulario,
						label: `${item.nombre} - ${item.codigo}`
					});
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					formsAssociate: newArray
				}));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// Get the data of all clients to the selector
	const getAllClientsToAssociate = () => {
		RequestUseCase.get(endpoints.clients.getAllClients)
			.then((res) => {
				// create new array
				const newArray = [
					{
						value: 0,
						label: 'Ninguno'
					}
				];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idCliente,
							label: `${item.nombreRazonSocial} - ${item.identificacion}`
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					clientsAssociate: newArray
				}));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// Get the data of all Activities services to the selector
	const getAllActivitiesServices = () => {
		// loading select
		toggleLoading(true);

		RequestUseCase.get(endpoints.activitiesServices.getAllActivitiesServicesBasic)
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idActividad,
							label: `${item.nombre} - ${item.sku}`,
							isFixed: true
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					actividadesServicios: newArray
				}));
				toggleLoading(false);
			})
			.catch((err) => {
				console.log(err);
				toggleLoading(false);
			});
	};

	// Get the data of all Stage services to the selector
	const getAllStageServices = () => {
		toggleLoading(true);

		RequestUseCase.get(endpoints.stageServices.getAllStageServices)
			.then((res) => {
				// console.log(res);
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idEtapaServicio,
							label: `${item.nombre} - ${item.codigo}`,
							isFixed: true
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					etapaServicios: newArray
				}));
				toggleLoading(false);
			})
			.catch((err) => {
				console.log(err);
				toggleLoading(false);
			});
	};

	// Get the data of all activities type services when have id type service
	const getAllActivitiesTypeServicesById = () => {
		setLoadingProgress(true);
		console.log(auxData);
		RequestUseCase.get(
			endpoints.typoServiceActivities.getTypeServiceActivitiesByTypeService(auxData.idTipoServicio)
		)
			.then((res) => {
				// create new array
				const newArray = [];

				res.forEach((item) => {
					newArray.push({
						...item,
						idTipoServicioActividad: item.idTipoServicioActividad,
						idTipoServicio: {
							idTipoServicio: `${item.idTipoServicio.nombre} - ${item.idTipoServicio.codigo}`,
							id: item.idTipoServicio.idTipoServicio
						},
						idActividad: {
							idActividad: `${item.idActividad.nombre} - ${item.idActividad.sku}`,
							id: item.idActividad.idActividad
						},
						idEtapaServicio: {
							idEtapaServicio: `${item.idEtapaServicio.nombre} - ${item.idEtapaServicio.codigo}`,
							id: item.idEtapaServicio.idEtapaServicio
						},
						actividadTecnica: item.actividadTecnica,
						orden: item.orden
					});
				});

				// sort the array by the order field
				newArray.sort((a, b) => a.orden - b.orden);
				setCurrentDataTable(newArray);
				setLoadingProgress(false);
			})
			.catch((err) => {
				console.log(err);
				setLoadingProgress(false);
			});
	};

	// Get all type service by id category service
	const getAllTypeServicesById = (selectValue) => {
		// Set the value of datas into []
		setCurrentDataTable([]);
		setCurrentItemMinimal([]);

		RequestUseCase.get(endpoints.typeService.getTypeServiceByCategory(selectValue.value))
			.then((res) => {
				// create new array
				const newArray = [];
				// iterate response and get only the values that are active
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idTipoServicio,
							label: `${item.nombre} - ${item.codigo}`,
							isFixed: true
						});
					}
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					tipoServicio: newArray
				}));
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// Update a string to set into the form
	const handleText = (e) => {
		HandlerText(e, currentItemMinimal, setCurrentItemMinimal);
	};

	// Update a number to set into the form
	const handleNumber = (e) => {
		// create a regex to valid the number
		const regex = /^[0-9]*$/;
		if (e.target.value.match(regex) !== null) {
			setCurrentItemMinimal({
				...currentItemMinimal,
				idTipoServicio: auxData.idTipoServicio,
				idActividad: auxData.idActividad,
				idEtapaServicio: auxData.idEtapaServicio,
				actividadTecnica: auxData.actividadTecnica,
				[e.target.name]: e.target.value
			});
		}
	};

	// This function is executed when the create button is clicked
	const handleSubmit = (values) => {
		const data = {
			idActividad: currentDataForm.idActividad,
			nombre: values.nombre,
			descripcion: values.descripcion ? values.descripcion : '',
			sku: values.sku,
			estado: active ? '1' : '0',
			fechaCreacion: currentDataForm.fechaCreacion,
			usuarioCreacion: currentDataForm.usuarioCreacion,
			usuarioModificacion: Cookie.get('idUsuario')
		};
		putItem(data);
	};

	// Updtae an activity
	const putItem = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general'),
			text: formatterText('alert.description.update.general'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general'),
			preConfirm: async () => {
				try {
					await RequestUseCase.put(endpoints.activitiesServices.updateActivitiesService, data);

					CustomAlert('confirm_msg', {
						icon: 'success',
						title: formatterText('alert.title.confirm.general'),
						text: formatterText('alert.message.confirm.updated.general'),
						confirmButtonText: formatterText('alert.button.continue'),
						allowOutsideClick: false,
						executeFunction: () => console.log() // Deuda tecnica (?), posiblemente toque retirarlo
					});
				} catch (error) {
					HandleOnError(formatterText('alert.message.failed.general'));
					console.log(error);
				}
			}
		});
	};

	// Associate an activity to a type service
	const associateTipoServiciosActividades = (item) => {
		// Define the order
		const order = currentDataTable.length + 1;
		// Structure the body
		let data = {
			idTipoServicioActividad: null,
			idTipoServicio: {
				idTipoServicio: item.idTipoServicio.value
			},
			idActividad: {
				idActividad: item.idActividad.value
			},
			idEtapaServicio: {
				idEtapaServicio: item.idEtapaServicio.value
			},
			orden: order,
			tiempoActividad: item.tiempoActividad,
			actividadTecnica: item.actividadTecnica.value,
			descripcion: item.descripcion
		};

		// Call the service
		RequestUseCase.post(endpoints.typoServiceActivities.addTypeServiceActivities, data)
			.then((response) => {
				data = {
					...data,
					idTipoServicio: {
						idTipoServicio: item.idTipoServicio.label
					},
					idActividad: {
						idActividad: item.idActividad.label
					},
					idEtapaServicio: {
						idEtapaServicio: item.idEtapaServicio.label
					},
					idTipoServicioActividad: response.idTipoServicioActividad
				};
				setCurrentDataTable([...currentDataTable, data]);

				CustomAlert('confirm_msg', {
					icon: 'success',
					title: formatterText('alert.title.confirm.general'),
					text: formatterText('alert.message.confirm.created.general'),
					confirmButtonText: formatterText('alert.button.continue'),
					allowOutsideClick: false,
					executeFunction: () =>
						console.log('Se creo correctamente: ', response.idTipoServicioActividad)
				});
			})
			.catch((err) => {
				if (err.response.status === 412) {
					HandleOnError('Intenta con otros datos');
				} else {
					HandleOnError(formatterText('alert.message.failed.general'));
				}
				console.log(err);
			});
	};

	// Add new association
	const handleAddItemMinimal = () => {
		if (currentItemMinimal.tiempoActividad !== '' && currentDataTable.length >= 1) {
			associateTipoServiciosActividades(currentItemMinimal);
			// Set data
			setCurrentItemMinimal({
				tiempoActividad: '',
				descripcion: '',
				idTipoServicioActividad: uuidv4()
			});
		} else {
			HandleOnError(formatterText('alert.message.failed.data'));
		}
	};

	// Create new association in the 3 tab
	const handleAddActivityFormClient = () => {
		const data = {
			idActividadFormularioCliente: null,
			idFormulario: auxData.idFormulario.value,
			idCliente: auxData.idCliente.value === 0 ? null : auxData.idCliente.value,
			idTipoServicioActividad: auxData.idTypeServiceActivity.value,
			usuarioCreacion: parseInt(Cookie.get('idUsuario'))
		};

		RequestUseCase.post(endpoints.activityformClient.addActivityFormClient, data)
			.then(() => {
				CustomAlert('confirm_msg', {
					icon: 'success',
					title: formatterText('alert.title.confirm.general'),
					text: formatterText('alert.message.confirm.created.general'),
					confirmButtonText: formatterText('alert.button.continue'),
					allowOutsideClick: false,
					executeFunction: () =>
						getAllFormsClientsByTypeServicesActivity(
							auxData.idTypeServiceActivity.value,
							auxData.idCliente.value
						)
				});
			})
			.catch((err) => {
				if (err.response.status === 412 || err.response.status === 400) {
					HandleOnError(formatterText('alert.message.failed.repeat.value.general'));
				} else {
					HandleOnError(formatterText('alert.message.failed.general'));
				}
				console.log(err);
			});
	};

	// Get all Forms Clients By Activity
	const getAllFormsClientsByTypeServicesActivity = (idActividad, idCliente) => {
		setLoadingProgress(true);
		// Call the service
		let url = '';
		if (
			(isUndefined(idActividad) && isUndefined(idCliente)) ||
			(idActividad === 0 && idCliente === 0)
		) {
			url = endpoints.activityformClient.getFormClientByActivity(-1, -1);
		} else {
			url = endpoints.activityformClient.getFormClientByActivity(idActividad || 0, idCliente || 0);
		}
		RequestUseCase.get(url)
			.then((response) => {
				const data = [];
				response.forEach((element) => {
					data.push({
						id: element.idActividadFormularioCliente,
						name: element.nombreFormulario,
						description: element.descripcionFormulario,
						code: element.codigoFormulario,
						client: element.nombreRazonSocialCliente,
						clienteId: element.identificacionCliente
					});
				});
				setDataTable(data);
				setCurrentDataTable(data);
				setLoadingProgress(false);
			})
			.catch((err) => {
				console.log(err);
				setLoadingProgress(false);
			});
	};

	// Delete an association of the table Activity Form Client
	const handleDelete = (row) => {
		RequestUseCase.delete(endpoints.activityformClient.deleteActivityFormClient(row.id))
			.then(() => {
				getAllFormsClientsByTypeServicesActivity(
					auxData.idTypeServiceActivity.value,
					auxData.idCliente.value
				);
			})
			.catch((error) => {
				console.log(error);
			});
		closeDialog();
	};

	// dialog state
	const [dialog, setDialog] = useState({
		text: '',
		active: false,
		function: null
	});

	// close dialog
	const closeDialog = () => {
		setDialog({ ...dialog, active: false });
	};

	// Display errors
	return {
		myForm,
		currentDataForm,
		setCurrentDataForm,
		active,
		setActive,
		auxData,
		setAuxData,
		currentItemMinimal,
		currentDataTable,
		selectedSearch,
		loading,
		loadingProgress,
		searchResults,
		handleDelete,
		dialog,
		setDialog,
		closeDialog,
		handleAddActivityFormClient,
		handleAddItemMinimal,
		handleText,
		handleNumber,
		handleSubmit,
		navigate,
		DisplayProgress
	};
}
