import React, { useEffect, useState } from 'react';
// Import Componentns
import Marker from 'components/map/Marker';
import useAxios from 'hooks/useAxios';
import { useSearchParams } from 'react-router-dom';
import endpoints from 'services/api';
// Import Libs
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

export const LoadMap = () => {
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyCn70QQcc0DhIjYmW1_C-tZBZ3M3MiwKac'
	});

	//TODO: LA API KEY NO FUNCIONA, LA VARIABLE DE ENTORNO PARECE NO SER RECONOCIDA
	//TODO: SE DEBE REVISAR EL ARCHIVO .ENV Y EL .ENV.EXAMPLE
	// TAMBIEN SE DEBE CAMBIAR LA LOGICA QUE TENIAN ANTERIORMENTE, ESTE MAPA FUNCIONA ADECUADAMENTE
	//MEMORIZAR SI ES NECESARIO PARA EVITAR RE PETICIONES
	//Prueben quemando la api key, y deberia funcionar (probe con una api key propia y si se renderizax)

	if (!isLoaded) return <div>Loading...</div>;
	return <Map />;
};

const Map = () => {
	const { fetchData } = useAxios();
	const [searchParams] = useSearchParams();

	const [technicians, setTechnicians] = useState([]);
	const [center, setCenter] = useState({ lat: 66, lng: 10 });

	useEffect(() => {
		const assigned = searchParams.get('assigned');
		if (assigned === 'true') {
			const list = searchParams
				.getAll('list')[0]
				.split(',')
				.map((item) => ({ idTecnico: item, risk: null }));
			getManualSearchTechs(list);
			getCenter();
		} else {
			const idService = searchParams.get('idService');
			fetchData({
				url: endpoints.services.getManualSearchTechnicals(idService),
				method: 'get'
			}).then((res) => {
				const idTecnicos = [
					...new Set(
						res.response
							.map((item) =>
								item.map((item) => ({
									idTecnico: item.idTecnico,
									risk: item.riesgoInicio
								}))
							)
							.flat()
					)
				].map((item) => ({
					idTecnico: item.idTecnico,
					risk: item.risk
				}));
				//make unique array of objects
				const uniqueArray = idTecnicos.filter(
					(thing, index, self) => index === self.findIndex((t) => t.idTecnico === thing.idTecnico)
				);
				getManualSearchTechs(uniqueArray);
			});
		}
	}, []);

	const getCenter = () => {
		//extract to localStorages dataUpdate
		const dataUpdate = JSON.parse(localStorage.getItem('dataUpdate'));
		const addressData = dataUpdate?.idDireccion;
		const dataToObtainCoords = {
			city: addressData?.idSector?.idMunicipio?.nombre,
			direccion: addressData?.direccion,
			datosAdicionales: addressData?.datosAdicionales
		};
		// make a reverse search to get the coordinates using google.maps.Geocoder
		const geocoder = new window.google.maps.Geocoder();

		const address = `${dataToObtainCoords.direccion} ${dataToObtainCoords.city}`;
		geocoder.geocode({ address: address }, (results) => {
			if (results !== null) {
				setCenter({
					lat: results[0].geometry.location.lat(),
					lng: results[0].geometry.location.lng()
				});
				const zoneService = {
					zoneService: true,
					position: {
						lat: parseFloat(results[0].geometry.location.lat()),
						lng: parseFloat(results[0].geometry.location.lng())
					},
					risk: null
				};
				setTechnicians((prevTechnicians) => [...prevTechnicians, zoneService]);
			} else {
				getCityCoords(dataToObtainCoords.city);
			}
		});
	};

	const getCityCoords = (city) => {
		const geocoder = new window.google.maps.Geocoder();
		const coordsObtein = [];
		geocoder
			.geocode({ address: city }, (results) => {
				coordsObtein.push({
					lat: results[0].geometry.location.lat(),
					lng: results[0].geometry.location.lng()
				});
			})
			.then(() => {
				setCenter({ lat: coordsObtein[0].lat, lng: coordsObtein[0].lng });
				const zoneService = {
					zoneService: true,
					position: {
						lat: parseFloat(coordsObtein[0].lat),
						lng: parseFloat(coordsObtein[0].lng)
					},
					risk: null
				};
				setTechnicians((prevTechnicians) => [...prevTechnicians, zoneService]);
			});
	};

	const formmattedCoordsTechs = (array, risks = null) => {
		array.forEach((element) => {
			//set the risk if exists in the array
			if (risks) {
				const risk = risks.find((item) => item.idTecnico === element.idtecnico);
				if (risk) {
					element.risk = risk.risk;
				}
			}
			// Extract the phone and name of the technician
			const phone = element.telefono;
			const name = element.nombre;
			const lat = element.latitud;
			const lng = element.longitud;

			// Parse the coordinates using the geo-coordinates-parser library
			// Save the phone and name in a variable

			const technician = {
				phone,
				name,
				position: {
					lat: parseFloat(lat),
					lng: parseFloat(lng)
				},
				risk: element.risk
			};

			// Add the technician to the state
			setTechnicians((prevTechnicians) => [...prevTechnicians, technician]);
		});
	};

	const getManualSearchTechs = (array) => {
		fetchData({
			url: endpoints.mapTech.findSomeTechs,
			method: 'post',
			body: {
				tecnicos: array
			}
		})
			.then((res) => {
				formmattedCoordsTechs(res.response, array);
				getCenter();
			})
			.catch((err) => {
				console.log('err', err);
				getCenter();
			});
	};

	return (
		<div
			style={{
				height: '88vh',
				width: '100%',
				overflowY: 'auto'
			}}
		>
			<GoogleMap
				zoom={13}
				options={{
					//only allow navigate in 15 km radius
					restriction: {
						latLngBounds: {
							north: center.lat + 0.15,
							south: center.lat - 0.15,
							west: center.lng - 0.15,
							east: center.lng + 0.15
						},
						strictBounds: false
					}
				}}
				center={center}
				mapContainerClassName="map-container"
			>
				{technicians.map((item, i) => {
					return (
						<Marker
							key={i}
							center={item.position}
							techName={item.name}
							techPhone={item.phone}
							techRisk={item?.risk ? item.risk : null}
							zoneService={item.zoneService ? true : false}
						/>
					);
				})}
			</GoogleMap>
		</div>
	);
};
