import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';

// Import Libs
import dateFormat from 'dateformat';

// Import Contexts
import { useSeachContext } from 'context/SearchContext';
import { usePermissionContext } from 'context/PermissionContext';

// Import Hooks
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';

// Import Services
import { MODULES_NAME } from 'common/validators/PermissionsConstants';

export default function EmployeeViewModel() {
	const { RequestUseCase, endpoints } = useAxios();

	// hook to loading data
	const {
		loading,
		error,
		toggleLoading,
		toggleError,
		handleClick,
		displayMessage,
		displayLoading
	} = useGetData();
	// useContext de search
	const { setDataTable, searchResults = [] } = useSeachContext();

	const [employee, setEmployee] = useState(null);

	// use Hook of language v2
	const { formatterText } = useLangv2();
	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();
	const permissionsAccessGaranted = () => {
		permissionsAccess(MODULES_NAME.employee);
	};

	useEffect(() => {
		permissionsAccessGaranted();
	}, [permissions]);

	useEffect(() => {
		getDataTable();
	}, []);

	// función para cargar datos
	const getDataTable = () => {
		// show loading
		toggleLoading(true);

		RequestUseCase.get(endpoints.employees.getAllEmployees)
			.then((data) => {
				const newArray = [];
				data.forEach((item) => handleStructureItems(newArray, item));

				setDataTable(newArray);
				// show loading
				toggleLoading(false);
				setEmployee(newArray);
			})
			.catch(() => {
				// mostrar error
				toggleError(!error);
				handleClick();
			});
	};

	const handleDeleteItem = (column) => {
		return new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.employees.deleteEmployee(column))
				.then((res) => {
					const newArray = [];
					res.forEach((item) => {
						handleStructureItems(newArray, item);
					});
					setDataTable(newArray);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const handleEditStateItem = (body) => {
		return new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.employees.updateEmployee(body.idEmpleado, body))
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const handleStructureItems = (newArray, item) => {
		const completeName = `${item.primerNombre} ${item.segundoNombre} ${item.primerApellido} ${item.segundoApellido}`;
		const formatedDate = item.fechaIngreso
			? dateFormat(item.fechaIngreso, 'yyyy-mm-dd')
			: 'No tiene un valor';
		const typeDocument = `${item.idTipoDocumento.nombre} - ${item.idTipoDocumento.abreviatura}`;

		newArray.push({
			id: item.idEmpleado || 'No tiene un valor',
			nombre: completeName || 'No tiene un valor',
			tipoDocumento: typeDocument,
			documento: item.identificacion || 'No tiene un valor',
			municipio: item.idMunicipio.nombre || 'No tiene un valor',
			estado: item.estado,
			cargo: item.cargo.nombre || 'No tiene un valor',
			tipoContrato: item.tipoContrato.nombre || 'No tiene un valor',
			fechaRegistro: formatedDate,
			objeto: { ...item }
		});
	};

	// titulos de la tabla
	const titles = [
		formatterText('table.title.full.name', 'Nombre completo'),
		formatterText('p.document.type', 'Tipo de documento'),
		formatterText('table.title.document', 'Documento'),
		formatterText('table.title.municipality', 'Municipio'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.title.position', 'Cargo'),
		formatterText('table.title.contract.type', 'Tipo de contrato'),
		formatterText('table.title.entry.date', 'Fecha de ingreso'),
		formatterText('table.actions', 'Acciones')
	];

	const renderMessage = () => {
		return error
			? displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			: displayLoading();
	};

	return {
		loading,
		searchResults,
		employee,
		permittedActions,
		handleDeleteItem,
		handleEditStateItem,
		titles,
		renderMessage,
		formatterText,
		getDataTable
	};
}
