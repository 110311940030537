import useAxios from 'hooks/useAxios';
import React, { useContext, useEffect, useState } from 'react';
// Import Contexts
import { AppContext } from 'context/AppContext';
// Import Hooks
import useLangv2 from 'hooks/useLangv2';
// Import Components
import { MdCheckBox, MdDelete, MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
// Import Libs
import PropTypes from 'prop-types';
// Import services
import { useSeachContext } from 'context/SearchContext';
import { useParams } from 'react-router-dom';
import endpoints from 'services/api';

const BtnAssignAgenda = ({ item, type, i }) => {
	const { RequestUseCase, COOKIE_USER } = useAxios();
	const {
		technicalsToAssing,
		techSelectToAssign,
		setTechSelectToAssign,
		selectTimeZoneId,
		setDataTableTechnicalAssigned
	} = useContext(AppContext);

	const {
		dataTableTechnical,
		dataTableTechToAssign,
		setDataTableTechnical,
		setDataTableTechToAssign
	} = useSeachContext();

	const { id } = useParams();

	// use Hook of language v2
	const { errorDataRecovery, errorProcess, successRemoveItem } = useLangv2();
	// loading
	const [loading, setLoading] = useState(false);

	// state to control de status of the tech
	const [status, setStatus] = useState(item.status);
	// state to control the assign of the tech
	const [assign, setAssign] = useState(false);

	// Delete the tech assigned in the modal table
	const deleteTechAssigned = (e) => {
		e.preventDefault();
		RequestUseCase.delete(
			endpoints.agenda.deleteFranjaHorariaAgendaTechnical(item.obj.idFranjaHorariaAgendaTecnico)
		)
			.then(() => {
				getAllTintTimeZone();
				successRemoveItem();
			})
			.catch(() => {
				errorProcess();
			});
	};

	const getAllTintTimeZone = () => {
		RequestUseCase.get(endpoints.agenda.getAllFranjaHorariaByIdAgenda(selectTimeZoneId, id))
			.then((response) => {
				// variable empty to save the data
				const data = [];
				response.forEach((element) => {
					const IS_TECH = element.idTecnico.idTercero === null;
					if (IS_TECH) {
						data.push({
							id: element.idFranjaHorariaTecnico,
							tech: `${element.idTecnico.idEmpleado.primerNombre} ${element.idTecnico.idEmpleado.primerApellido} - ${element.idTecnico.idEmpleado.identificacion}`,
							status: element.idTipoAgenda.idTipoAgenda,
							obj: element
						});
					} else {
						data.push({
							id: element.idFranjaHorariaTecnico,
							tech: `${element.idTecnico.idTercero.nombre} - ${element.idTecnico.idTercero.correo} - ${element.idTecnico.idTercero.identificacion}`,
							status: element.idTipoAgenda.idTipoAgenda,
							obj: element
						});
					}
				});
				// set the data to the table
				setDataTableTechnical(data);
				setDataTableTechnicalAssigned(data);
			})
			.catch(() => {
				errorDataRecovery();
			});
	};

	const assignTech = (e) => {
		e.preventDefault();
		handleAssign();
	};

	// Update the status of the tech
	const updateStatus = (e) => {
		e.preventDefault();
		setLoading(true);
		// TODO: if the tech is not assing so make the status to control
		if (type === 'technicalAssign') {
			const DATA = {
				idFranjaHorariaAgendaTecnico: item.id,
				idTipoAgenda: {
					idTipoAgenda: status === 1 ? 2 : 1
				},
				idTecnico: {
					idTecnico: item.idTecnico
				},
				idAgenda: {
					idAgenda: id
				},
				idFranjaHoraria: {
					idFranjaHoraria: item.idFranjaHoraria
				},
				estado: item.status,
				fechaCreacion: item.fechaCreacion,
				usuarioCreacion: item.usuarioCreacion,
				usuarioModificacion: COOKIE_USER
			};
			RequestUseCase.put(endpoints.agenda.updateFranjaHorariaAgendaTecnico, DATA)
				.then(() => {
					setStatus(status === 1 ? 2 : 1);
					setLoading(false);
				})
				.catch(() => {
					errorDataRecovery();
					setLoading(false);
				});
		} else {
			// which is the completx of the funcion below? O(n)
			const value = technicalsToAssing.find((tech) => tech.obj.idUsuario === item.obj.idUsuario);

			if (value) {
				value.status = status === 1 ? 2 : 1;
				setStatus(status === 1 ? 2 : 1);
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		const updatedDataTable = dataTableTechToAssign.map((element) => {
			if (techSelectToAssign.some((item) => item.idTecnico.idTecnico === element.obj.idTecnico)) {
				return {
					...element,
					isSelected: true
				};
			} else {
				return {
					...element,
					isSelected: false
				};
			}
		});
		setDataTableTechToAssign(updatedDataTable);
	}, [assign]);

	// Assign a tech to the time zone
	const handleAssign = () => {
		const SAME_ID = techSelectToAssign.some(
			(tech) => tech.idTecnico.idTecnico === item.obj.idTecnico
		);
		if (SAME_ID) {
			//  remove the tech from the array
			setTechSelectToAssign(
				techSelectToAssign.filter((tech) => tech.idTecnico.idTecnico !== item.obj.idTecnico)
			);
			setAssign(!assign);
		} else {
			setAssign(!assign);

			//////////////////////////////////////////////////////////
			const DATA = {
				idFranjaHorariaAgendaTecnico: null,
				idTipoAgenda: {
					idTipoAgenda: status
				},
				// idTecnico: [],
				idTecnico: {
					idTecnico: item?.obj?.idTecnico
				},
				idAgenda: {
					idAgenda: id
				},
				idFranjaHoraria: {
					idFranjaHoraria: selectTimeZoneId
				},

				usuarioCreacion: COOKIE_USER
			};

			setTechSelectToAssign((prev) => [...prev, DATA]);
		}
	};

	return (
		<section
			style={{
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-evenly'
			}}
		>
			{loading ? (
				<MdRadioButtonUnchecked size={25} color="gray" cursor="pointer" />
			) : (
				<MdRadioButtonChecked
					size={25}
					color={status === 1 ? 'green' : 'red'}
					cursor="pointer"
					onClick={(e) => updateStatus(e)}
				/>
			)}
			{type === 'technicalAssign' ? (
				<MdDelete size={25} color="gray" cursor="pointer" onClick={(e) => deleteTechAssigned(e)} />
			) : (
				<MdCheckBox
					size={25}
					color={item.isSelected ? 'green' : 'gray'}
					cursor="pointer"
					onClick={(e) => assignTech(e)}
				/>
			)}
		</section>
	);
};

BtnAssignAgenda.propTypes = {
	item: PropTypes.object,
	type: PropTypes.string
};

export default BtnAssignAgenda;
