import React from 'react';
// Import Contexts
import TableMinimalNoContext from 'common/minimalTables/TableMinimalNoContext';
// Import Hooks
// Import Components
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ModalConfirm from '../modalSearch/ModalConfirm';
import ModalSearch from '../modalSearch/ModalSearch';
// Import Libs
// Import Services
// Import Styles
import { formatterText } from 'hooks/useLangv2';
import SearchTechniciansViewModel from './SearchTechnicians.viewModel';

const style = {
	position: 'absolute',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3
};

// DATOS QUEMADOS PARA PROBAR
const noData = [
	{
		id: 'N/A',
		name: 'N/A',
		prevServ: 'N/A',
		prevRisk: 'N/A',
		newRisk: 'N/A'
	}
];

export default function SearchTechnicians() {
	const {
		styleCenter,
		styleWrap,
		styleLeft,
		selectNotificacion,
		setOpen,
		handleClose,
		technicians,
		technicians2,
		technicians3,
		titlesTableCostProduct,
		setSelectNotificacion,
		techToAssign,
		selectTech,
		setSelectTech,
		clickFun,
		consultaStart,
		clickOnRow,
		open
	} = SearchTechniciansViewModel();
	return (
		<>
			<div style={styleCenter}>
				{`${selectNotificacion.ticket} - ${selectNotificacion.categoriaServicio} - ${selectNotificacion.tipoServicio}`}
			</div>

			<div style={styleWrap}>
				<div style={styleLeft}>{formatterText('title.service.search.manual.techs')}</div>
				<button className="btn-action-primary custom-margin-0001" onClick={() => setOpen(true)}>
					{formatterText('service.search.manual.button.modify.service')}
				</button>
				<button className="btn-action-primary custom-margin-0001" onClick={() => consultaStart()}>
					{formatterText('btn.update')}
				</button>
			</div>

			{/* PRIMERA TABLA */}
			<p className="wrap-form-title">
				{formatterText('title.service.search.manual.table.techs.associated.client')}
			</p>
			{technicians.length > 0 ? (
				<>
					<section className="form-responsive-container-information">
						<TableMinimalNoContext
							titles={titlesTableCostProduct}
							data={technicians}
							type="searchServices"
							labelTable="Busqueda de tecnicos Manual"
							handleSelect={(x) => clickOnRow(x)}
							handleDetails={() => alert('Detalle')}
						/>
					</section>
				</>
			) : (
				<>
					<section className="form-responsive-container-information">
						<TableMinimalNoContext
							titles={titlesTableCostProduct}
							data={noData}
							type="searchServices"
							labelTable="Busqueda de tecnicos Manual"
							handleSelect={() => alert('Seleccionar')}
							handleDetails={() => alert('Detalle')}
						/>
					</section>
				</>
			)}

			{/* SEGUNDA TABLA */}
			<p className="wrap-form-title">
				{formatterText('title.service.search.manual.table.techs.associated.unit.bussines')}
			</p>
			{technicians2.length > 0 ? (
				<>
					<section className="form-responsive-container-information">
						<TableMinimalNoContext
							titles={titlesTableCostProduct}
							type="searchServices"
							data={technicians2}
							labelTable="Busqueda de tecnicos Manual"
							handleSelect={(x) => clickOnRow(x)}
							handleDetails={() => alert('Detalle')}
						/>
					</section>
				</>
			) : (
				<>
					<section className="form-responsive-container-information">
						<TableMinimalNoContext
							titles={titlesTableCostProduct}
							type="searchServices"
							data={noData}
							labelTable="Busqueda de tecnicos Manual"
							handleSelect={() => alert('Seleccionar')}
							handleDetails={() => alert('Detalle')}
						/>
					</section>
				</>
			)}
			{/* TERCERA TABLA */}
			<p className="wrap-form-title">
				{formatterText('title.service.search.manual.table.techs.not.associated')}
			</p>
			{technicians3.length > 0 ? (
				<>
					<section className="form-responsive-container-information">
						<TableMinimalNoContext
							titles={titlesTableCostProduct}
							type="searchServices"
							data={technicians3}
							labelTable="Busqueda de tecnicos Manual"
							handleSelect={(x) => clickOnRow(x)}
							handleDetails={(x) => alert(x)}
						/>
					</section>
				</>
			) : (
				<>
					<section className="form-responsive-container-information">
						<TableMinimalNoContext
							titles={titlesTableCostProduct}
							type="searchServices"
							data={noData}
							labelTable="Busqueda de tecnicos Manual"
							handleSelect={() => alert('Seleccionar')}
							handleDetails={() => alert('Detalle')}
						/>
					</section>
				</>
			)}
			<Modal
				open={open}
				onClose={() => handleClose(setOpen)}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box sx={{ ...style, width: '35%' }}>
					<ModalSearch onClose={() => handleClose(setOpen)} setterFun={setSelectNotificacion} />
				</Box>
			</Modal>

			<Modal
				open={selectTech}
				onClose={() => handleClose(setSelectTech)}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
			>
				<Box sx={{ ...style, width: '35%' }}>
					<ModalConfirm
						onClose={() => handleClose(setSelectTech)}
						clickFun={clickFun}
						dataToAssign={techToAssign}
					/>
				</Box>
			</Modal>
		</>
	);
}
