import { useField } from '@formiz/core';
import customStyles2 from 'common/selects/generic.style.select';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';

export const FieldSelect = (props) => {
	const { id, isValid, isSubmitted, resetKey, setValue, value, otherProps } = useField(props);
	const { setterFunction, data, noOptionsMessage, options = [], placeholder } = otherProps;
	const [isTouched, setIsTouched] = useState(false);
	const showError = !isValid && (isTouched || isSubmitted);

	useEffect(() => {
		setIsTouched(false);
	}, [resetKey]);

	useEffect(() => {
		if (data) {
			setValue(data);
		}
	}, [data]);

	const handleData = (e) => {
		setterFunction(e);
		setValue(e);
	};

	return (
		<Select
			id={id}
			value={value}
			onBlur={() => setIsTouched(true)}
			aria-invalid={showError}
			aria-describedby={!isValid ? `${id}-error` : undefined}
			placeholder={placeholder}
			onChange={handleData}
			styles={customStyles2}
			noOptionsMessage={noOptionsMessage}
			// isDisabled={!!disabled}
			options={options}
		/>
	);
};

{
	/* <FieldSelect
    // eslint-disable-next-line react/no-array-index-key
    name={'idListaPrecios'}
    label={'idListaPrecios'}
    required="Required"
    options={listPrices}
    data={auxData.idListaPrecios}
    setterFunction={setAuxData}
/> */
}
