import Cookies from 'js-cookie';

export const parseDataToSubmit = (values) => {
	const dataToSend = {
		...values,
		idTipoArchivoAdjunto: null,
		nombre: values.nombre,
		extension: values.extension,
		estado: '1',
		descripcion: '',
		fecha_registro: new Date(),
		pesoMaximoArchivo: values.pesoMaximoArchivo,
		cantidadMaxima: values.cantidadMaxima,
		fecha_modificacion: '',
		categorias: values.categorias,
		usuario_creacion: parseInt(Cookies.get('idUsuario'))
	};
	return dataToSend;
};
