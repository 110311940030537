import useAxios from 'hooks/useAxios';
import React, { useContext } from 'react';
// Import Contexts
import { AppContext } from 'context/AppContext';
// Import Hooks
import useLangv2 from 'hooks/useLangv2';
// Import Components
import { MdCheckBox, MdDelete } from 'react-icons/md';
// Import Libs
import PropTypes from 'prop-types';
// Import Services
import { useSeachContext } from 'context/SearchContext';
import { useParams } from 'react-router-dom';
import endpoints from 'services/api';

const BtnSelectAgendaInfo = ({ item }) => {
	const { RequestUseCase } = useAxios();
	const { timeZoneSelected, setTimeZoneSelected } = useSeachContext();
	const { setOpenModalTechnicalToAssign, setTechSelectToAssign, setSelectTimeZoneId } =
		useContext(AppContext);

	// use Hook of language v2
	const { errorProcess, successRemoveItem, customSB } = useLangv2();

	const { id } = useParams();

	const deleteTimeZoneSchedule = (e) => {
		e.preventDefault();
		const TYPE = typeof item.idFranjaHorariaAgenda;
		if (TYPE === 'string') {
			removeElement();
		} else {
			RequestUseCase.delete(endpoints.agenda.deleteTimeZoneSchedule(item.idFranjaHorariaAgenda))
				.then(() => {
					successRemoveItem();
					removeElement();
				})
				.catch(() => {
					errorProcess();
				});
		}
	};

	const removeElement = () => {
		setTimeZoneSelected((prev) =>
			prev.filter((prevItem) => prevItem.idFranjaHorariaAgenda !== item.idFranjaHorariaAgenda)
		);
	};

	const openModal = (e) => {
		e.preventDefault();
		const HAVE_A_STRING_ID = timeZoneSelected.some(findStringId);
		if (HAVE_A_STRING_ID || id === undefined || id === null) {
			customSB(
				'warning',
				'snackbar.error.time.zone.need.associate',
				'Es necesario guardar la agenda para poder asignar técnicos a la franja horaria.'
			);
		} else {
			// setTechnial to assign
			setTechSelectToAssign([]);
			setSelectTimeZoneId(item.idFranjaHoraria);
			setOpenModalTechnicalToAssign(true);
		}
	};

	// find if the idFranjaHorariaAgenda is a string or a number
	const findStringId = (el) => {
		return typeof el.idFranjaHorariaAgenda === 'string';
	};

	return (
		<td className="minimal-table__actions" align="center">
			<MdCheckBox size={25} color="gray" cursor="pointer" onClick={(e) => openModal(e)} />
			<MdDelete
				size={25}
				color="gray"
				cursor="pointer"
				onClick={(e) => deleteTimeZoneSchedule(e)}
			/>
		</td>
	);
};

BtnSelectAgendaInfo.propTypes = {
	item: PropTypes.any
};

export default BtnSelectAgendaInfo;
