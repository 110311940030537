import { ACTIVITYSTATUS } from 'constants/activityStatus';
import { AppContext } from 'context/AppContext';
import { useSeachContext } from 'context/SearchContext';
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';
import usePermission from 'hooks/usePermission';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import endpoints from 'services/api';

export default function DynamicRowServicesViewModel({
	columns,
	getData,
	handleDownloadItem,
	handleDeleteItem,
	handleEditStateItem,
	routeToEdit,
	onClose,
	sendMail,
	setSendMail,
	getDataToUpdate,
	setCounterActivities,
	setIsDeleteTechMain,
	isDeleteTechMain
}) {
	// Modal config
	const [changeDateTime, setChangeDateTime] = useState(false);
	const onOpenChangeDateTime = () => setChangeDateTime(true);
	const onCloseChangeDateTime = () => setChangeDateTime(false);
	// Manage axios requests
	const { fetchData, COOKIE_USER, RequestUseCase } = useAxios();
	const { permissions } = usePermission();
	// use Hook of language v2
	const {
		updatedItem,
		successRemoveItem,
		noFilledContent,
		errorRestriction,
		errorRelation,
		showPermissionDenied,
		customSB,
		handleRequestError,
		formatterText
	} = useLangv2();

	const { searchResults = [] } = useSeachContext();
	const navigate = useNavigate();
	// Set the data of the externalUserSelected and ClientSelected
	const { setExternalUserSelected, setClientSelected, idEstadoServicio, listActivities } =
		useContext(AppContext);

	const [stateAux, setStateAux] = useState(columns.estado);
	const [loadingAux, setLoadingAux] = useState(false);

	const [dateAux] = useState(columns.fechaModificacion);
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({});
	// Modal config
	const [openDetails, setOpenDetails] = useState(false);
	const [openChangeData, setOpenChangeData] = useState(false);
	const onCloseDetails = () => setOpenDetails(false);
	const onOpenDetails = () => setOpenDetails(true);
	const onCloseChangeData = () => setOpenChangeData(false);
	const onOpenChangeData = () => setOpenChangeData(true);

	const [loadingModal, setLoadingModal] = useState(false);
	const [flagStatus, setFlagStatus] = useState(false);
	// const [sendMail, setSendMail] = useState(false);

	// get the id of the services
	const { id } = useParams();
	// Dialog edit
	const [dialog, setDialog] = useState({
		text: '',
		active: false,
		function: null
	});

	//
	const [dataProvider, setDataProvider] = useState([
		{
			nombreRazonSocial: '',
			identificacion: '',
			telefono: '',
			correo: ''
		}
	]);

	useEffect(() => {
		setStateAux(columns.estado);
		// setDateAux(columns.fechaModificacion);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchResults]);

	const handleEdit = (id) => {
		localStorage.setItem(
			'dataUpdate',
			JSON.stringify({
				...columns.objeto,
				estado: stateAux
			})
		);
		navigate(routeToEdit.replace(':id', parseInt(id)));
	};

	const handleChangeSendMail = () => setSendMail(!sendMail);
	const handleDelete = (columns) => {
		handleDeleteItem(columns)
			.then((res) => {
				if (res) {
					successRemoveItem();
					closeDialog();
				}
			})
			.catch((err) => {
				if (err.response.status === 500) {
					errorRelation();
				} else {
					errorRestriction();
				}
				closeDialog();
			});
	};

	// Permite el cambio de estado de un registro
	const handleToggle = () => {
		const estado = stateAux === 1 ? 0 : 1;
		closeDialog();
		const newData = {
			...columns.objeto,
			estado: estado,
			usuarioModificacion: COOKIE_USER
		};
		// execute update function
		handleEditStateItem(newData)
			.then(() => {
				successRemoveItem();
				// set the values of the state of the item of currentDataRow
				setStateAux(estado);
			})
			.catch((err) => {
				handleRequestError(err);
			});
	};

	const closeDialog = () => {
		setLoading(false);
		setDialog({ ...dialog, active: false });
	};

	const displayColor = (date) => {
		const date1 = new Date(date);
		const date2 = new Date();
		const timeDiff = date1.getTime() - date2.getTime();
		const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
		if (diffDays >= 90 && diffDays > 30) {
			return '#FFC954';
		} else if (diffDays <= 30 && diffDays > 0) {
			return '#F4A64A';
		} else if (diffDays <= 0) {
			return '#DD3333';
		} else {
			return 'gray';
		}
	};

	const formatCurrency = (value) => {
		if (value) {
			const parts = value.toString().split('.');
			let formattedValue = `$${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
			if (parts.length > 1) {
				formattedValue += `,${parts[1]}`;
			}
			return formattedValue;
		} else {
			return value;
		}
	};
	// Sprint 8 Download Form
	const handleDownload = (id) => {
		handleDownloadItem(id);
	};

	// Sprint 9 View Details HU10
	const handleDetails = (value) => {
		RequestUseCase.get(
			endpoints.serviceProduct.getAlllProviderByIdServiceProduct(value.idServicioProducto)
		)
			.then((res) => {
				setLoadingAux(true);
				setDataProvider(res);
				onOpenDetails();
			})
			.catch((err) => {
				console.log('err ', err);
			});
	};

	// Update data of a product service S9 HU10
	const handleUpdatePCO = (obj) => {
		//  check if the fields are empty
		if (
			formData.cantidadSolicitada === '' ||
			formData.cantidadAprobada === '' ||
			formData.observacion === ''
		) {
			noFilledContent();
		} else {
			setLoadingModal(true);
			const data = {
				idServicioProducto: obj.idServicioProducto,
				idProducto: obj.idProducto,
				idServicio: parseInt(id),
				idProveedor: obj.idProveedor,
				idTipoEntrega: obj.idTipoEntrega,
				idUnidadMedida: obj.idUnidadmedida,
				gestionado: obj.gestionado,
				cantidadSolicitada: parseInt(formData.cantidadSolicitada),
				cantidadAprobada: parseInt(formData.cantidadAprobada),
				costo: formData.costo.replaceAll('.', ''),
				observacion: formData.observacion,
				fechaRegistro: obj.fechaRegistro,
				fechaModificacion: null,
				usuarioCreacion: obj.usuarioCreacion,
				usuarioModificacion: COOKIE_USER,
				nombreProducto: obj.nombreProducto,
				idCategoriaProducto: obj.idCategoriaProducto,
				sku: obj.sku,
				nombreCategoriaProducto: obj.nombreCategoriaProducto,
				iva: 19,
				idMoneda: {
					idMoneda: 1
				}
			};
			RequestUseCase.put(endpoints.serviceProduct.updateServiceProduct, data)
				.then((res) => {
					if (res) {
						updatedItem();
						getData();
						onCloseChangeData();
					}
					setLoadingModal(false);
				})
				.catch((err) => {
					handleRequestError(err);
					closeDialog();
					setLoadingModal(false);
				});
		}
	};

	// Sprint 8 Select obj Externaluser
	const handleSelectEU = (obj) => {
		new Promise((resolve) => {
			setExternalUserSelected({
				nombre: `${obj.primerNombre} ${obj.primerApellido} - ${obj.telefono} - ${obj.correo}`,
				id: obj.idUsuarioServicio
			});
			resolve();
		}).then(() => {
			onClose();
		});
	};

	// Sprint 8 Select obj System Client
	const handleSelectSC = (obj) => {
		new Promise((resolve) => {
			setClientSelected({
				nombre: `${obj.nombreRazonSocial} - ${obj.identificacion} - ${obj.telefono} - ${obj.correo}`,
				id: obj.idCliente
			});
			resolve();
		}).then(() => {
			onClose();
		});
	};

	// Update the state of the delivery
	const updateDeliveryState = (value, obj) => {
		if (value !== '') {
			const data = {
				idServicioProducto: obj.idServicioProducto,
				idProducto: obj.idProducto,
				idServicio: parseInt(id),
				idProveedor: obj.idProveedor,
				idTipoEntrega: parseInt(value),
				idUnidadMedida: obj.idUnidadmedida,
				gestionado: obj.gestionado,
				cantidadSolicitada: obj.cantidadSolicitada,
				cantidadAprobada: obj.cantidadAprobada,
				costo: obj.costo,
				observacion: obj.observacion,
				fechaRegistro: obj.fechaRegistro,
				fechaModificacion: null,
				usuarioCreacion: obj.usuarioCreacion,
				usuarioModificacion: COOKIE_USER,
				iva: 19,
				idMoneda: {
					idMoneda: 1
				}
			};

			RequestUseCase.put(endpoints.serviceProduct.updateServiceProduct, data)
				.then((res) => {
					if (res) {
						updatedItem();
						getData();
						closeDialog();
					}
				})
				.catch((err) => {
					console.log(err);
					handleRequestError(err);
					closeDialog();
				});
		}
	};

	const [estadoActividad, setEstadoActividad] = useState(0);
	const [servicioActividad, setServicioActividad] = useState(0);
	const [orden, setOrden] = useState(0);

	// Update the state of the activity service state
	const updateActivityServiceState = (value, obj) => {
		console.log('listActivities', listActivities);
		// iterate the list of activities to extract the index, then verify if the values above have the
		// idEstadoActividad !== 1, if so, the activity is not updated showing a message to the user
		const VALUE_I = listActivities.filter((item) => item.id === obj.idServicioActividad).length;
		// aux variable to verify if the activity can be updated
		let canUpdate = true;
		// iterate the list of activities to verify if the activity can be updated
		for (let index = 0; index <= VALUE_I - 1; index++) {
			const element = listActivities[index];
		}
		const activityProcess = listActivities.find((activity) => {
			activity?.objeto?.idEstadoActividad?.idEstadoActividad == ACTIVITYSTATUS.PROCESO &&
				activity.objeto.idServicioActividad !== obj.idServicioActividad;
		});

		if (activityProcess) {
			canUpdate = false;
			customSB(
				'warning',
				'snackbar.warning.activity.service.in.progress',
				'Hay actividades en proceso, por favor actualice el estado de las actividades anteriores.'
			);
		}

		if (canUpdate) {
			if (value !== '') {
				const DATA = {
					idServicioActividad: obj.idServicioActividad,
					idEstadoActividad: { idEstadoActividad: parseInt(value) },
					idServicio: { idServicio: obj.idServicio.idServicio },
					fechaCreacion: new Date().toISOString(),
					usuarioCreacion: COOKIE_USER,
					usuarioModificacion: COOKIE_USER,
					orden: columns.orden
				};
				if (parseInt(value) === 4) {
					setServicioActividad(DATA.idServicioActividad);
					setEstadoActividad(4);
					setOrden(columns.orden);
					onOpenChangeDateTime();
					if (flagStatus) {
						RequestUseCase.put(endpoints.services.updateStateActivityService, DATA)
							.then((res) => {
								if (res) {
									updatedItem();
									setCounterActivities(0);
									getData();
									getDataToUpdate();
								}
							})
							.catch((err) => {
								handleRequestError(err);
								setCounterActivities(0);
								getData();
							});
						setFlagStatus(false);
					}
					setFlagStatus(false);
				} else {
					RequestUseCase.put(endpoints.services.updateStateActivityService, DATA)
						.then((res) => {
							if (res) {
								updatedItem();
								setCounterActivities(0);
								setIsDeleteTechMain(!isDeleteTechMain);
								getData();
							}
						})
						.catch((err) => {
							handleRequestError(err);
							setCounterActivities(0);
							getData();
						});
				}
			}
		}
	};

	const changeStateOfProduct = (state, columns) => {
		// manage the state of the product S9 HU10 C6
		const access = permissions.filter(
			(item) => item.idModulo === 22 && item.nombrePermiso === 'CAMBIAR_ESTADO'
		);
		if (state === 1) {
			if (access.length > 0) {
				handlerChangeStateOfProduct(state, columns);
			} else {
				showPermissionDenied();
			}
		} else {
			if (idEstadoServicio === 6) {
				if (access.length > 0) {
					handlerChangeStateOfProduct(state, columns);
				} else {
					showPermissionDenied();
				}
			} else {
				customSB(
					'warning',
					'snackbar.warning.update.state.product.progress',
					'No se puede actualizar el estado del producto, debido a que el servicio no esta en estado "En Curso".'
				);
			}
		}
	};

	const handlerChangeStateOfProduct = (state, columns) => {
		closeDialog();
		const newData = {
			idServicioProducto: columns.objeto.idServicioProducto,
			idProducto: columns.objeto.idProducto,
			idServicio: parseInt(id),
			idProveedor: columns.objeto.idProveedor,
			idTipoEntrega: columns.objeto.idTipoEntrega,
			idUnidadMedida: columns.objeto.idUnidadmedida,
			gestionado: state,
			cantidadSolicitada: columns.objeto.cantidadSolicitada,
			cantidadAprobada: columns.objeto.cantidadAprobada,
			costo: columns.objeto.costo,
			observacion: columns.objeto.observacion,
			fechaRegistro: columns.objeto.fechaRegistro,
			fechaModificacion: null,
			usuarioCreacion: columns.objeto.usuarioCreacion,
			usuarioModificacion: COOKIE_USER,
			nombreProducto: columns.objeto.nombreProducto,
			idCategoriaProducto: columns.objeto.idCategoriaProducto,
			sku: columns.objeto.sku,
			nombreCategoriaProducto: columns.objeto.nombreCategoriaProducto
		};

		handleEditStateItem(newData)
			.then(() => {
				getData();
				updatedItem();
				setStateAux(state);
			})
			.catch((err) => {
				handleRequestError(err);
			});
	};

	const handleSendMail = (columns) => {
		const body = {
			idServicioProducto: columns.objeto.idServicioProducto
		};
		RequestUseCase.post(endpoints.serviceProduct.sendMail, body)
			.then((data) => {
				customSB('success', 'toast.email.success', data.message);
				closeDialog();
			})
			.catch(() => {
				customSB('error', 'toast.email.error', 'El correo no pudo ser enviado.');
				closeDialog();
			});
	};
	const selectProvider = (providerData, serviceProduct) => {
		const newUpdate = {
			idServicioProducto: serviceProduct.idServicioProducto,
			idProducto: serviceProduct.idProducto,
			idServicio: parseInt(id),
			idProveedor: providerData.idproveedor,
			costo: providerData.costo,
			idTipoEntrega: serviceProduct.idTipoEntrega,
			idUnidadMedida: serviceProduct.idUnidadmedida,
			gestionado: serviceProduct.gestionado,
			cantidadSolicitada: parseInt(serviceProduct.cantidadSolicitada),
			cantidadAprobada: parseInt(serviceProduct.cantidadAprobada),
			observacion: serviceProduct.observacion,
			fechaRegistro: serviceProduct.fechaRegistro,
			fechaModificacion: serviceProduct.fechaModificacion,
			usuarioCreacion: serviceProduct.usuarioCreacion,
			usuarioModificacion: COOKIE_USER,
			nombreProducto: serviceProduct.nombreProducto,
			idCategoriaProducto: serviceProduct.idCategoriaProducto,
			sku: serviceProduct.sku,
			nombreCategoriaProducto: serviceProduct.nombreCategoriaProducto,
			iva: 19,
			idMoneda: {
				idMoneda: 1
			}
		};

		fetchData({
			url: endpoints.serviceProduct.updateServiceProduct,
			method: 'put',
			body: newUpdate
		})
			.then((data) => {
				const { error } = data;
				if (error === '200') {
					updatedItem();
					getData();
					handleChangeSendMail();
					onCloseDetails();
				}
			})
			.catch((err) => {
				console.log(err);
				handleRequestError(err);
			});
	};
	return {
		loading,
		stateAux,
		dateAux,
		displayColor,
		changeStateOfProduct,
		handleChangeSendMail,
		setDialog,
		handleToggle,
		formatterText,
		updateDeliveryState,
		updateActivityServiceState,
		handleSelectEU,
		handleSelectSC,
		handleDownload,
		handleDetails,
		setFormData,
		onOpenChangeData,
		handleEdit,
		handleDelete,
		handleSendMail,
		dialog,
		openDetails,
		onCloseDetails,
		loadingAux,
		dataProvider,
		formatCurrency,
		selectProvider,
		openChangeData,
		onCloseChangeData,
		formData,
		permissions,
		handleUpdatePCO,
		showPermissionDenied,
		loadingModal,
		changeDateTime,
		onCloseChangeDateTime,
		flagStatus,
		setFlagStatus,
		servicioActividad,
		estadoActividad,
		orden
	};
}
