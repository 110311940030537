import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
// Import Hooks
import useLangv2 from 'hooks/useLangv2';
// paths to routes
// hook para cargar los datos
import useGetData from 'hooks/useGetData';
// Context de búsqueda
import { useSeachContext } from 'context/SearchContext';
// Llamar a la API
// Llamar a la tabla dinamica
import { MODULES_NAME } from 'common/validators/PermissionsConstants';
import { usePermissionContext } from 'context/PermissionContext';
import endpoints from 'services/api';

export default function FileViewModel() {
	const { RequestUseCase } = useAxios();

	// use Hook of language v2
	const { formatterText, successRemoveItem, handleRequestError } = useLangv2();
	// hook to loading data
	const { toggleLoading, displayLoading } = useGetData();

	// useContext de búsqueda
	const { setDataTable, setSearchResults } = useSeachContext();
	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();

	const [file, setFile] = useState(null);
	const [loading, setLoading] = useState(true);

	const permissionsAccessGaranted = () => {
		permissionsAccess(MODULES_NAME.attachmentType);
	};

	useEffect(() => {
		permissionsAccessGaranted();
	}, [permissions]);

	useEffect(() => {
		getDataTable();
	}, []);

	// función para cargar datos
	const getDataTable = () => {
		RequestUseCase.get(endpoints.attachedFiles.getAllAttachedFiles).then((data) => {
			const newArray = [];
			data.forEach((item) =>
				newArray.push({
					id: item.idTipoArchivoAdjunto,
					nombre: item.nombre,
					extencionArchivo: item.extension,
					pesoMaximoArchivo: `${item.pesoMaximoArchivo} MB`,
					cantidad: item.cantidadMaxima,
					estado: item.estado,
					objeto: item
				})
			);
			setDataTable(newArray);
			setSearchResults(newArray);
			// show loading
			toggleLoading(false);
			setFile(newArray);
			setLoading(false);
		});
	};

	const handleDeleteItem = (id) => {
		RequestUseCase.delete(endpoints.attachedFiles.deleteAttachedFile(id))
			.then(() => {
				successRemoveItem();
				getDataTable();
				toggleLoading(false);

				setLoading(false);
			})
			.catch((err) => {
				handleRequestError(err);
			});
	};

	const handleEditStateItem = (body) => {
		const updateItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.attachedFiles.updateAttachedFile, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return updateItemPromise;
	};

	// titulos de la tabla
	const titles = [
		formatterText('table.title.filename', 'Nombre de archivo adjunto'),
		formatterText('table.title.filetype', 'Tipo de archivo adjunto'),
		formatterText('table.title.size', 'Peso máximo del archivo'),
		formatterText('table.title.amount', 'Cantidad de archivos adjuntos'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];
	return {
		file,
		loading,
		getDataTable,
		handleEditStateItem,
		handleDeleteItem,
		displayLoading,
		permittedActions,
		formatterText,
		titles
	};
}
