import React from 'react';
// Import Hooks
// Import Components
import SelectorMulti from 'common/SelectorMulti';
import { InputFieldResponsive } from 'common/inputs/InputFieldResponsive';
import InputSelectorResponsive from 'common/inputs/InputSelectorResponsive';
import Table from 'common/minimalTables/Table';
import SelectorSearch from 'common/selects/SelectorSearch';
import { URLREGEX } from 'common/validators/Regex';
import Attachments from 'components/AttachedFiles/Attachments';

import { Modal } from 'react-responsive-modal';
import AddAdviser from '../adviser/AddAdviser';
import AddBranchOffice from '../branchOffice/AddBranchOffice';
// Import Libs
import { Formiz, FormizStep } from '@formiz/core';
import { isEmail, isPattern } from '@formiz/validations';
import { FormattedMessage } from 'react-intl';
// Import Services
import HandlerChangeMailAssesor from '../utils/HandlerChangeMailAsesor';
import { TIPOS_ARCHIVOS_PERMITIDOS_USUARIO } from 'constants/archivosAdjuntosUsuario';
import CreateClientViewModel from './CreateClient.viewModel';
import { CreateClientProps } from './CreateClient.props';

function CreateClientComponents({ setIsOpenCreateClient }) {
	const {
		myForm,
		loading,
		loadingData,
		selectData,
		listPrices,
		secondaryPrices,
		units,
		dataTableBusinessUnit,
		titlesTableBusinessUnit,
		dialog,
		closeDialog,
		handleDelete,
		handleOpen,
		currentDataTable,
		handleAddBusinessUnit,
		clearValue,
		auxData,
		setAuxData,
		setSelectedTable,
		selectValues,
		selectorValues,
		handleChangeMulti,
		handleAddBranchOffice,
		handleAddAsesor,
		currentBranchOffice,
		currentAsesor,
		setCurrentAsesor,
		otherCurrentDataTable,
		handleChangeSelect,
		handleChangeSucursal,
		handleChangeSelectAsesor,
		handleBlurMailAsesor,
		handleBlurMailAltAsesor,
		error,
		setError,
		altErr,
		setAltErr,
		archivos,
		setCurrentFiles,
		handleSubmit,
		formatterText,
		titlesTableBranchOffice,
		titlesTableAdvisers,
		handleChangeAsesor,
		closeModal,
		handleClose,
		selectedTable,
		open
	} = CreateClientViewModel({ setIsOpenCreateClient });
	return (
		<div className="centered-form">
			<Formiz onValidSubmit={handleSubmit} connect={myForm}>
				<form
					noValidate
					onSubmit={myForm.submit}
					className="container-wrapForm"
					style={{ minHeight: '16rem' }}
				>
					<div className="new-container-wrapForm__tabs">
						{myForm.steps.map((step, index) => (
							<button
								key={step.name}
								className={`new-tab-option ${
									step.name === myForm.currentStep.name ? 'is-active' : ''
								}`}
								type="button"
								onClick={() => {
									setSelectedTable(index);

									myForm.goToStep(step.name);
								}}
							>
								{!step.isValid && step.isSubmitted && <small className="mr-2">⚠️</small>}
								{formatterText(step.label)}
							</button>
						))}
					</div>
					<div className="container-wrapForm-content">
						{/* 1: General customer information */}
						<FormizStep name="step1" label="p.information.customer.general">
							<div className="title-section">
								<span className="circle-form">
									<span>1</span>
								</span>
								<h2>
									<FormattedMessage
										id="p.general.data.customer"
										defaultMessage="Datos generales del cliente"
									/>
								</h2>
							</div>
							<section className="grid-container-2c">
								<InputSelectorResponsive
									type="text"
									name="idTipoDocumento"
									placeholder={formatterText('p.document.type', 'Tipo de documento')}
									labelText={formatterText('p.document.type', 'Tipo de documento')}
									required={formatterText('p.label.title.tipoDocumentoRequerido')}
									data={
										!!selectData.documentTypes &&
										selectData.documentTypes.map((item) => {
											const docLabel = `${item.nombre} - ${item.abreviatura}`;
											return {
												label: docLabel,
												value: item.idTipoDocumento
											};
										})
									}
									isLoading={loadingData}
									styleName="input-default-3c"
								/>
								<InputFieldResponsive
									type="text"
									name="identificacion"
									labelText={formatterText('p.id.identification', 'Identificación*')}
									placeholder={formatterText('text.ID', 'Identificación')}
									validateInput="integer"
									required={formatterText('p.label.title.identificacionRequerida')}
									styleName="input-default-3c"
								/>
								<InputSelectorResponsive
									type="text"
									name="idGenero"
									labelText={formatterText('input.placeholder.genre', 'Género')}
									placeholder={formatterText('input.placeholder.genre', 'Género')}
									required={formatterText('p.label.title.generoRequerido')}
									data={
										!!selectData.genres &&
										selectData.genres.map((item) => ({
											label: `${item.nombre} - ${item.abreviatura}`,
											value: item.idGenero
										}))
									}
									isLoading={loadingData}
									styleName="input-default-3c"
								/>
								<InputFieldResponsive
									type="text"
									name="nombreRazonSocial"
									labelText={formatterText('p.name.social.reason', 'Nombres o razón social*')}
									placeholder={formatterText('p.name.social.reason', 'Nombres o razón social*')}
									validateInput="text"
									required={formatterText('p.label.title.nombreRazonSocialRequerido')}
									styleName="input-default-3c"
								/>
								<InputSelectorResponsive
									type="text"
									name="idTipoCliente"
									placeholder={formatterText('p.client.type', 'Tipo de Cliente*')}
									labelText={formatterText('p.client.type', 'Tipo de Cliente*')}
									required={formatterText('p.label.title.tipoClienteRequerido')}
									data={
										!!selectData.clientTypes &&
										selectData.clientTypes.map((item) => {
											const docLabel = `${item.nombre}`;
											return {
												label: docLabel,
												value: item.idTipoCliente
											};
										})
									}
									isLoading={loadingData}
									styleName="input-default-3c"
								/>
								<InputFieldResponsive
									type="text"
									name="correo"
									labelText={formatterText('table.title.email', 'Correo electrónico')}
									placeholder={formatterText('table.title.email', 'Correo electrónico')}
									validateInput="mail"
									required={formatterText('p.label.title.correoElectronicoRequerido')}
									validations={[
										{
											rule: isEmail(),
											message: formatterText('p.email.invalid')
										}
									]}
									styleName="input-default-3c"
								/>
								<InputFieldResponsive
									type="text"
									name="celular"
									labelText={formatterText('p.movile.phone', 'Celular*')}
									placeholder={formatterText('p.movile.phone', 'Celular*')}
									validateInput="integer"
									required={formatterText('p.label.title.celularRequerido')}
									styleName="input-default-3c"
								/>
								<InputFieldResponsive
									type="text"
									name="telefono"
									labelText={formatterText('table.title.phone', 'Teléfono*')}
									placeholder={formatterText('table.title.phone', 'Teléfono*')}
									validateInput="integer"
									required={formatterText('p.label.title.telefonoRequerido')}
									styleName="input-default-3c"
								/>
								<InputFieldResponsive
									type="text"
									name="sitioWeb"
									labelText={formatterText('table.title.website', 'Sitio web')}
									placeholder={formatterText('table.title.website', 'Sitio web')}
									validateInput="web"
									styleName="input-default-3c"
									validations={[
										{
											rule: isPattern(URLREGEX),
											message: 'La url no es válida'
										}
									]}
								/>
								<InputSelectorResponsive
									type="text"
									name="clienteEspecial"
									placeholder={formatterText('p.especial.customer', 'Cliente especial')}
									labelText={formatterText('p.especial.customer', 'Cliente especial')}
									required={formatterText('p.label.title.tipoClienteRequerido2')}
									data={[
										{
											label: 'Sí',
											value: 1
										},
										{
											label: 'No',
											value: 2
										}
									]}
									isLoading={loading}
									styleName="input-default-3c"
								/>
							</section>
						</FormizStep>
						{/* 2: Client associations */}
						<FormizStep name="step2" label="p.client.asociate">
							<div className="title-section">
								<span className="circle-form">
									<span>2</span>
								</span>
								<h2>
									<FormattedMessage
										id="p.client.asociate"
										defaultMessage="Asociaciones del cliente"
									/>
								</h2>
							</div>

							<div>
								<section className="grid-container-2c">
									<InputSelectorResponsive
										type="text"
										name="idPais"
										labelText={<FormattedMessage id="p.country" defaultMessage="Pais" />}
										placeholder={
											<FormattedMessage
												id="input.placeholder.select"
												defaultMessage="Selecione una opción"
											/>
										}
										data={
											selectData?.countries?.map(({ nombrePais, idPais }) => ({
												label: nombrePais,
												value: idPais
											})) ?? []
										}
										isLoading={loadingData}
									/>

									<InputSelectorResponsive
										type="text"
										name="idMercado"
										labelText={<FormattedMessage id="p.market" defaultMessage="Mercado" />}
										placeholder={
											<FormattedMessage
												id="input.placeholder.select"
												defaultMessage="Selecione una opción"
											/>
										}
										required={formatterText('p.market.required')}
										data={
											!!selectData.markets &&
											selectData?.markets.map((item) => {
												const docLabel = `${item.nombre}`;
												return {
													label: docLabel,
													value: item.idMercado
												};
											})
										}
										isLoading={loadingData}
									/>
									<InputSelectorResponsive
										type="text"
										name="idTipoFacturacion"
										labelText={formatterText('p.billing.type', 'Tipo de facturación')}
										placeholder={
											<FormattedMessage
												id="input.placeholder.select"
												defaultMessage="Selecione una opción"
											/>
										}
										required={formatterText('p.billing.required')}
										data={
											!!selectData.billingTypes &&
											selectData.billingTypes.map((item) => {
												const docLabel = `${item.nombre}`;
												return {
													label: docLabel,
													value: item.idTipoFacturacion
												};
											})
										}
										isLoading={loadingData}
									/>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage
												id="p.price.list"
												defaultMessage="Lista de precios primaria"
											/>
										</span>
										<section className="w100-container">
											<SelectorSearch
												name="idListaPrecios"
												data={listPrices}
												placeholder={
													<FormattedMessage
														id="input.placeholder.select"
														defaultMessage="Selecione una opción"
													/>
												}
												dataValue={auxData.idListaPrecios}
												setterFunction={setAuxData}
												isLoading={loading}
												selectValue={selectorValues}
												isRequired={true}
											/>
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage
												id="p.price.list.second"
												defaultMessage="Lista de precios secundaria"
											/>
										</span>
										<section className="w100-container">
											<SelectorMulti
												disable={!auxData?.idListaPrecios?.value}
												data={secondaryPrices}
												isLoading={loading}
												dataValue={selectValues}
												setterFunction={handleChangeMulti}
											/>
										</section>
									</label>
								</section>
							</div>
							<div className="title-section">
								<span className="circle-form">
									<span>2.1</span>
								</span>
								<h2>
									<FormattedMessage
										id="p.add.bussines.unit"
										defaultMessage="Agregar Unidades de Negocio"
									/>
								</h2>
							</div>
							<div>
								<label>
									<section className="form-responsive-container-buttons">
										{clearValue && (
											<InputSelectorResponsive
												type="text"
												name="idUnidadNegocio"
												labelText={formatterText('p.unit.bussines', 'Unidad de negocio')}
												placeholder={
													<FormattedMessage
														id="input.placeholder.select"
														defaultMessage="Selecione una opción"
													/>
												}
												required={formatterText('p.label.title.unidadNegocioRequerida')}
												data={units}
												isLoading={loadingData}
											/>
										)}
										<label className="wrapForm w100-container">
											<input
												onClick={() => handleAddBusinessUnit(myForm)}
												type="button"
												className="btn-primary margin-auto"
												value={formatterText(
													'p.add.bussines.unit.single',
													'Agregar Unidad de negocio'
												)}
											/>
										</label>
									</section>
									<section>
										<label className="wrapForm w100-container">
											<span className="wrap-form-title">
												<FormattedMessage
													id="p.associated.bussines"
													defaultMessage="Unidades de negocio asociadas"
												/>
											</span>
											<Table
												titles={titlesTableBusinessUnit}
												data={dataTableBusinessUnit}
												type="businessByClient"
												dialog={dialog}
												closeDialog={closeDialog}
												canSearch={true}
												handleDelete={handleDelete}
											/>
										</label>
									</section>
								</label>
							</div>

							<div>
								<div className="title-section">
									<span className="circle-form">
										<span>2.2</span>
									</span>
									<h2>
										<FormattedMessage id="p.add.branch" defaultMessage="Agregar sucursal" />
									</h2>
								</div>
								<section className="wrapForm w100-container">
									<Table
										titles={titlesTableBranchOffice}
										data={currentDataTable}
										type="sucursales"
										max={true}
										handleOpen={handleOpen}
									/>
									<section
										className="grid-container-2c"
										style={{
											width: '100%'
										}}
									>
										<label className="d-flex">
											<span className="text-inline">
												<FormattedMessage
													id="p.municipiociudad"
													defaultMessage="Municipio o ciudad"
												/>
											</span>
											<section className="w100-container">
												<select
													name="idCiudadMunicipio"
													id="idCiudadMunicipio"
													className="input-label-style"
													onChange={handleChangeSelect}
												>
													<option value="">
														<FormattedMessage
															id="p.select.option"
															defaultMessage="Seleccione una opción"
														/>
													</option>
													{!!selectData.municipalities &&
														selectData.municipalities.map((item) => (
															<option
																key={item.idMunicipio}
																value={JSON.stringify({
																	label: item.nombre,
																	value: item.idMunicipio
																})}
															>
																{item.nombre}
															</option>
														))}
												</select>
											</section>
										</label>
										<label className="d-flex">
											<span className="text-inline">
												<FormattedMessage id="tab.title.sector" defaultMessage="Sector" />
											</span>
											<section className="w100-container">
												<select
													name="idSector"
													id="idSector"
													className="input-label-style"
													onChange={handleChangeSelect}
												>
													<option value="">
														<FormattedMessage
															id="p.select.option"
															defaultMessage="Seleccione una opción"
														/>
													</option>
													{!!selectData.sectors &&
														selectData.sectors.map((item) => (
															<option
																key={item.idSector}
																value={JSON.stringify({
																	label: item.nombre,
																	value: item.idSector
																})}
															>
																{item.nombre}
															</option>
														))}
												</select>
											</section>
										</label>
									</section>
								</section>
								<section className="wrapForm w100-container">
									<section
										className="grid-container-2c"
										style={{
											width: '100%'
										}}
									>
										<label className="d-flex">
											<span className="warpForm-text">
												<FormattedMessage
													id="tab.title.direccion"
													defaultMessage="Dirección completa"
												/>
											</span>
											<section className="w100-container">
												<input
													type="text"
													name="direccion"
													id="direccion"
													placeholder={formatterText('tab.title.direccion', 'Dirección completa')}
													value={currentBranchOffice.direccion || ''}
													required={formatterText('p.label.title.direccionRequerida')}
													className="input-default-3c"
													onChange={handleChangeSucursal}
												/>
											</section>
										</label>
										<label className="d-flex">
											<span className="warpForm-text">
												<FormattedMessage
													id="tab.title.adiconaldatos"
													defaultMessage="Datos adicionales"
												/>
											</span>
											<section className="w100-container">
												<input
													name="datosAdicionales"
													id="datosAdicionales"
													placeholder={formatterText(
														'p.example.name.ofi',
														'Ej: Número de local, oficina, aparatemento, peso, manzana'
													)}
													type="text"
													value={currentBranchOffice.datosAdicionales || ''}
													required={formatterText('p.label.title.datosAdicionalesRequeridos')}
													className="input-default-3c"
													onChange={handleChangeSucursal}
												/>
											</section>
										</label>
										<label className="d-flex">
											<span className="warpForm-text">
												<FormattedMessage
													id="tab.title.observaciones"
													defaultMessage="Observaciones"
												/>
											</span>
											<input
												name="observaciones"
												id="observaciones"
												value={currentBranchOffice.observaciones || ''}
												placeholder={formatterText('p.ej.example.observ', 'Ej: Observaciones')}
												type="text"
												required={formatterText('p.label.title.observacionesRequeridas')}
												className="input-default-3c"
												onChange={handleChangeSucursal}
											/>
										</label>
									</section>
									<section className="form-responsive-container-buttons">
										<input
											onClick={handleAddBranchOffice}
											type="button"
											className="btn-primary margin-auto"
											value={formatterText('p.add.branch', 'Agregar sucursal')}
										/>
									</section>
								</section>
							</div>
						</FormizStep>
						{/* 3: Client advisors */}
						<FormizStep isEnabled={true} name="step3" label="p.client.adviser">
							<div className="title-section">
								<span className="circle-form">
									<span>3</span>
								</span>
								<h2>
									<FormattedMessage id="p.client.adviser" defaultMessage="Asesores del cliente" />
								</h2>
							</div>

							<section
								style={{
									width: '95%',
									margin: '0 auto'
								}}
							>
								<Table
									type="asesores"
									titles={titlesTableAdvisers}
									data={otherCurrentDataTable}
									max={true}
									handleOpen={handleOpen}
								/>
								<div className="title-section">
									<span className="circle-form">
										<span></span>
									</span>
									<h2>
										<FormattedMessage id="p.add.client.adviser" defaultMessage="Agregar asesor" />
									</h2>
								</div>

								<section
									className="grid-container-2c sm-gap"
									style={{
										width: '100%'
									}}
								>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage id="p.document.type" defaultMessage="Tipo de documento" />
										</span>
										<section className="w100-container">
											<select
												name="idTipoDocumentoAsesor"
												id="idTipoDocumentoAsesor"
												className="input-label-style"
												onChange={handleChangeSelectAsesor}
											>
												<option value="">
													<FormattedMessage
														id="p.select.option"
														defaultMessage="Seleccione una opción"
													/>
												</option>
												{!!selectData.documentTypes &&
													selectData.documentTypes.map((item) => (
														<option
															key={item.idTipoDocumento}
															value={JSON.stringify({
																label: `${item.nombre} - ${item.abreviatura}`,
																value: item.idTipoDocumento
															})}
														>
															{`${item.nombre} - ${item.abreviatura}`}
														</option>
													))}
											</select>
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage id="text.ID" defaultMessage="Identificación" />
										</span>
										<section className="w100-container">
											<input
												type="text"
												name="identificacion"
												id="identificacion"
												placeholder={formatterText('text.ID', 'Identificación')}
												value={currentAsesor.identificacion || ''}
												required={formatterText('p.label.title.identificacionRequerida')}
												onChange={handleChangeAsesor}
												className="input-default-3c"
											/>
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage id="input.placeholder.genre" defaultMessage="Género" />
										</span>
										<section className="w100-container">
											<select
												name="genero"
												id="genero"
												className="input-label-style"
												onChange={handleChangeSelectAsesor}
											>
												<option value="">
													<FormattedMessage
														id="p.select.option"
														defaultMessage="Seleccione una opción"
													/>
												</option>
												{!!selectData.genres &&
													selectData.genres.map((item) => (
														<option
															key={item.idGenero}
															value={JSON.stringify({
																label: `${item.nombre} - ${item.abreviatura}`,
																value: item.idGenero
															})}
														>
															{`${item.nombre} - ${item.abreviatura}`}
														</option>
													))}
											</select>
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage
												id="table.title.full.name"
												defaultMessage="Nombre completo"
											/>
										</span>
										<section className="w100-container">
											<input
												type="text"
												name="nombreAsesor"
												id="nombreAsesor"
												placeholder={formatterText('table.title.full.name', 'Nombre completo')}
												value={currentAsesor.nombreAsesor || ''}
												required={formatterText('p.label.title.nombreCompletoRequerido')}
												onChange={handleChangeAsesor}
												className="input-default-3c"
											/>
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage id="table.title.position" defaultMessage="Cargo" />
										</span>
										<section className="w100-container">
											<select
												name="idCargo"
												id="idCargo"
												className="input-label-style"
												onChange={handleChangeSelectAsesor}
											>
												<option value="">
													<FormattedMessage
														id="p.select.option"
														defaultMessage="Seleccione una opción"
													/>
												</option>
												{!!selectData.cargos &&
													selectData.cargos.map((item) => (
														<option
															key={item.idCargo}
															value={JSON.stringify({
																label: item.nombre,
																value: item.idCargo
															})}
														>
															{item.nombre}
														</option>
													))}
											</select>
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage id="p.correo" defaultMessage="Correo" />
										</span>
										<section className="w100-container">
											<input
												type="email"
												name="emailAsesor"
												id="emailAsesor"
												placeholder={formatterText('p.correo')}
												value={currentAsesor.emailAsesor || ''}
												onChange={(e) =>
													HandlerChangeMailAssesor(e, currentAsesor, setCurrentAsesor, setError)
												}
												onBlur={handleBlurMailAsesor}
												className="input-default-3c"
											/>
											{error && <div className="error-msg">{formatterText(error)}</div>}
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage id="p.correoalterno" defaultMessage="Correo alterno" />
										</span>
										<section className="w100-container">
											<input
												type="email"
												name="correoAlternoAsesor"
												id="correoAlternoAsesor"
												placeholder={formatterText('p.correoalterno', 'Correo alterno')}
												value={currentAsesor.correoAlternoAsesor || ''}
												onChange={(e) =>
													HandlerChangeMailAssesor(e, currentAsesor, setCurrentAsesor, setAltErr)
												}
												onBlur={handleBlurMailAltAsesor}
												className="input-default-3c"
											/>
											{altErr && <div className="error-msg">{formatterText(altErr)}</div>}
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage id="table.title.phone" defaultMessage="Teléfono*" />
										</span>
										<section className="w100-container">
											<input
												type="text"
												name="telefonoAsesor"
												id="telefonoAsesor"
												placeholder={formatterText('table.title.phone', 'Teléfono')}
												value={currentAsesor.telefonoAsesor || ''}
												required={formatterText('p.label.title.telefonoRequerido')}
												onChange={handleChangeAsesor}
												className="input-default-3c"
											/>
										</section>
									</label>

									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage id="p.alt.phone" defaultMessage="Teléfono alterno" />
										</span>
										<section className="w100-container">
											<input
												type="text"
												name="telefonoAlternoAsesor"
												id="telefonoAlternoAsesor"
												placeholder={formatterText('p.alt.phone', 'Teléfono alterno')}
												value={currentAsesor.telefonoAlternoAsesor || ''}
												required={formatterText('p.label.title.telefonoAlternoRequerido')}
												onChange={handleChangeAsesor}
												className="input-default-3c"
											/>
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage
												id="p.city.residence"
												defaultMessage="Ciudad de residencia"
											/>
										</span>
										<section className="w100-container">
											<select
												name="idMunicipio"
												id="idMunicipio"
												className="input-label-style"
												onChange={handleChangeSelectAsesor}
											>
												<option value="">
													<FormattedMessage
														id="p.select.option"
														defaultMessage="Seleccione una opción"
													/>
												</option>
												{!!selectData.municipalities &&
													selectData.municipalities.map((item) => (
														<option
															key={item.idMunicipio}
															value={JSON.stringify({
																label: item.nombre,
																value: item.idMunicipio
															})}
														>
															{item.nombre}
														</option>
													))}
											</select>
										</section>
									</label>
								</section>
							</section>

							<section className="form-responsive-container-buttons">
								<input
									onClick={handleAddAsesor}
									type="button"
									className="btn-primary"
									value={formatterText('p.add.client.adviser', 'Agregar asesor')}
								/>
							</section>
						</FormizStep>
						{/* 4: Attachment files */}
						<FormizStep name="step4" label="tab.title.attached.data">
							<Attachments
								currentFiles={archivos}
								setCurrentFiles={setCurrentFiles}
								isEdited={false}
								type={TIPOS_ARCHIVOS_PERMITIDOS_USUARIO.CLIENTE}
								showParameters={true}
							/>
						</FormizStep>
						{/* btn saves */}
						<section className="form-responsive-container-buttons">
							<button type="submit" className="btn-primary">
								<FormattedMessage
									id="alert.button.confirm.general"
									defaultMessage="Guardar cambios"
								/>
							</button>
							<button className="input-cancel" onClick={() => closeModal()}>
								{formatterText('btn.cancel')}
							</button>
						</section>
					</div>
				</form>
			</Formiz>
			{/* Modal BranchOffice */}
			<Modal
				open={open}
				onClose={handleClose}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal'
				}}
			>
				{selectedTable === 1 ? (
					<AddBranchOffice onClose={handleClose} />
				) : (
					<AddAdviser onClose={handleClose} />
				)}
			</Modal>
		</div>
	);
}

CreateClientComponents.propTypes = CreateClientProps;
export default CreateClientComponents;
