// Custom hook to show a progress circle when the data is loading
import React, { useState } from 'react';
// libs to show a message in the snackbar
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

/**
 * @description Custom hook to show a circular progress when the data is loading
 * @param {boolean} initialValue - Initial value of the state
 */
const useProgress = (initialValue = false) => {
	/* Loading Screen status */
	const [loadingProgress, setLoadingProgress] = useState(initialValue);

	// display progress loading
	const DisplayProgress = () => {
		return (
			<Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
				<CircularProgress />
			</Box>
		);
	};

	const DisplayFullProgress = () => {
		return (
			<Box sx={{ position: 'fixed', display: 'flex', inset: 0, background: 'rgba(255, 255, 255, 0.8)', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', zIndex: 999999 }}>
				<CircularProgress />
				<span style={{marginTop: '8px'}}>Cargando...</span>
			</Box>
		);
	};

	return {
		loadingProgress,
		setLoadingProgress,
		DisplayProgress,
		DisplayFullProgress
	};
};

export default useProgress;
