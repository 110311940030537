import { useEffect, useState } from 'react';

// Import Libs
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

// Import Components
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';

// Import Contexts
import { useSeachContext } from 'context/SearchContext';

// Import Hooks
import useLangv2 from 'hooks/useLangv2';

// Import Paths
import paths from 'services/paths';

// Import Services
import useAxios from 'hooks/useAxios';

// Import ViewModel
import { UpsertFormViewModel } from '../UpsetRol/components';

export default function CreateRolViewModel() {
	const { RequestUseCase, endpoints } = useAxios();

	const navigate = useNavigate();
	const { setDataTable, setSearch } = useSeachContext();
	// permisosModulos state
	const [permisosModulos, setPermisosModulos] = useState([]);
	const [toggleSelector, setToggleSelector] = useState(false);
	const [selectedPermisosModulos, setSelectedPermisosModulos] = useState([]);
	const [allPermisos, setAllPermisos] = useState([]);

	// use Hook of language v2
	const { formatterText } = useLangv2();

	useEffect(() => {
		getAsociatedPermissionModule();
	}, []);

	//Validaciones y llama al post
	const postRoleData = (values, actions) => {
		RequestUseCase.get(endpoints.roles.getAllRoles)
			.then((res) => {
				const rolesNames = [];
				res.map((role) => {
					if (role.nombre !== null) {
						rolesNames.push(role.nombre.toLowerCase());
					}
				});

				if (rolesNames.includes(values.nombre.toLowerCase())) {
					CustomAlert('short_msg', {
						icon: 'warning',
						title: 'Ops...',
						text: formatterText('alert.title.role.name.exists'),
						confirmButtonColor: '#FFC954'
					});
					actions.setSubmitting(false);
				} else {
					postRole(values, actions);
				}
			})
			.catch((err) => console.log(err));
	};

	const { formik, parseDataToSubmit } = UpsertFormViewModel({
		onSubmit: postRoleData,
		formatterText
	});

	const getAsociatedPermissionModule = () => {
		Axios.get(endpoints.roles.getAllPermisosModulos, {
			headers: {
				Authorization: sessionStorage.getItem('token')
			}
		})
			.then((allPermisosModulos) => {
				setPermisosModulos(allPermisosModulos.data);
				setDataTable(allPermisosModulos.data);
				setAllPermisos(allPermisosModulos.data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const postRole = (values, actions) => {
		const data = parseDataToSubmit(values, formatterText);

		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					RequestUseCase.post(endpoints.roles.addRole, data)
						.then((res) => {
							asociatePermissionModule(res, resolve, reject);
						})
						.catch(() => {
							reject(
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								)
							);
						})
						.finally(() => actions.setSubmitting(false));
				});
			}
		}).then((result) => {
			if (result.isDismissed) {
				actions.setSubmitting(false);
			}
		});
	};

	const asociatePermissionModule = (res, resolve, reject) => {
		const valueInfo = allPermisos.filter((permiso) =>
			selectedPermisosModulos.find(
				(element) =>
					element.idPermiso.idpermiso === permiso.idPermiso.idpermiso &&
					element.idModulo.idModulo === permiso.idModulo.idModulo
			)
		);

		const promesas = valueInfo.map((permissionInfo) => {
			const body = {
				idRolPermisoModulo: null,
				idRol: {
					idRol: res.idRol
				},
				idPermisoModulo: {
					idPermisoModulo: permissionInfo.idPermisoModulo
				},
				usuarioCreacion: 1,
				fechaCreacion: null,
				usuarioModificacion: null,
				fechaModificacion: null
			};

			return new Promise((resolve, reject) => {
				RequestUseCase.post(endpoints.roles.addRolePermisoModulo, body)
					.then((res) => {
						resolve(res);
					})
					.catch(() => {
						reject(formatterText('alert.title.permission.in.use'));
					});
			});
		});

		Promise.all(promesas)
			.then(() => {
				resolve(
					CustomAlert('confirm_msg', {
						icon: 'success',
						title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
						text: formatterText(
							'alert.message.confirm.created.general',
							'El registro se ha creado correctamente'
						),
						confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
						allowOutsideClick: false,
						executeFunction: () => {
							navigate(paths.roles);
							window.location.reload();
						}
					})
				);
			})
			.catch((error) => {
				reject(HandleOnError(error));
			});
	};

	const handleRemoveRole = (e, permiso) => {
		e.preventDefault();

		const selected = selectedPermisosModulos.filter((currentPermisosModulo) => {
			const primero = currentPermisosModulo.idPermiso.idpermiso;
			const segundo = permiso.idPermiso.idpermiso;
			const tercero = currentPermisosModulo.idModulo.idModulo;
			const cuarto = permiso.idModulo.idModulo;

			//compare 4 numbers to see if one of them is different from the other in another way

			return primero !== segundo || tercero !== cuarto;
		});
		sortData(selected);
		setSelectedPermisosModulos(selected);
		setPermisosModulos([...permisosModulos, permiso]);
		setDataTable([...permisosModulos, permiso]);
	};

	const handleAddRole = (permiso) => {
		const newPermisosModulos = permisosModulos.filter((currentRol) => {
			const primero = currentRol.idPermiso.idpermiso;
			const segundo = permiso.idPermiso.idpermiso;
			const tercero = currentRol.idModulo.idModulo;
			const cuarto = permiso.idModulo.idModulo;

			//compare 4 numbers to see if one of them is different from the other in another way

			return primero !== segundo || tercero !== cuarto;
		});

		setDataTable(newPermisosModulos);
		setPermisosModulos(newPermisosModulos);
		const selected = [...selectedPermisosModulos, permiso];
		sortData(selected);
		setSelectedPermisosModulos(selected);
		setToggleSelector(!toggleSelector);
		setSearch('');
	};

	const sortData = (selected) => {
		return selected.sort((a, b) => {
			const nombreA = a.idModulo.nombre.toUpperCase();
			const nombreB = b.idModulo.nombre.toUpperCase();
			if (nombreA < nombreB) {
				return -1;
			}
			if (nombreA > nombreB) {
				return 1;
			}
			return 0;
		});
	};

	return {
		formatterText,
		formik,
		handleAddRole,
		handleRemoveRole,
		navigate,
		postRoleData,
		setToggleSelector,
		toggleSelector
	};
}
