import MultiTableMinimal from 'common/minimalTables/MultiTableMinimal';
import Selector from 'common/selects/Selector';
import React, { useEffect, useState } from 'react';
// // Import services
import { useSeachContext } from 'context/SearchContext';
import useLangv2 from 'hooks/useLangv2';
import { isUndefined } from 'lodash';
import { FormattedMessage } from 'react-intl';
import useAxios from 'hooks/useAxios';

const customStyles = {
	control: (base) => ({
		...base,
		width: '19em',
		margin: '15px 0 0 0',
		'border-radius': '70px',
		'box-shadow': '0px 4px 8px rgba(0, 174, 142, 0.2)',
		border: '0px !important',
		padding: '0 6px',
		'max-width': '20rem',
		'min-width': '225px'
	}),
	menu: (styles) => ({
		...styles,
		width: '19em'
	})
};

const UnityBusiness = ({ data, setterFunction, type }) => {
	const { RequestUseCase, endpoints } = useAxios();
	const [auxData, setAuxData] = useState();
	const [selectedSearch, setSearchSelected] = useState();
	const [initialSearch, setInitialSearch] = useState();
	const [loading, setLoading] = useState(false);
	const { formatterText, customSB } = useLangv2();
	const [dataLoaded, setDataLoaded] = useState(false);

	const titlesTableUnityBusiness = [
		formatterText('title.table.unit.bussines.name'),
		formatterText('table.title.code'),
		formatterText('table.name.search.market'),
		formatterText('p.country'),
		formatterText('table.actions')
	];

	const { primaryMinimalTable, handleSearchUnityBussines } = useSeachContext();

	useEffect(() => {
		getSelector();
	}, []);

	const getSelector = async () => {
		setLoading(true);

		try {
			const res = await RequestUseCase.get(endpoints.businessUnit.getAllBusinessUnits);
			const newArray = [];

			await Promise.all(
				res.map(async (item) => {
					if (item.estado === 1) {
						const ans = await RequestUseCase.get(
							endpoints.businessUnit.getMarketCountryByUnit(item.idUnidadNegocio)
						);
						const markets = ans.map((countryInfo) => countryInfo.idMercadoPais.mercado.nombre);
						const countries = ans.map((countryInfo) => countryInfo.idMercadoPais.pais.nombrePais);
						const market = markets.join(', ');
						const data = {
							value: item.idUnidadNegocio,
							label: `${item.nombre} ${market !== undefined ? ' ' + market : ''}`,
							code: item.codigo,
							name: item.nombre,
							isFixed: true,
							markets: market,
							country: countries[0]
						};
						newArray.push(data);
					}
				})
			);

			setSearchSelected(newArray);
			setInitialSearch(newArray);
			setLoading(false);
			setDataLoaded(true);
		} catch (err) {
			console.error(err);
		}
	};

	const handleDelete = (row) => {
		const newMinimalRows = data.filter((et) => {
			return et.code !== row.code;
		});
		closeDialog();
		setterFunction(newMinimalRows);
	};

	const [dialog, setDialog] = useState({
		text: '',
		active: false,
		function: null
	});

	const closeDialog = () => {
		setDialog({ ...dialog, active: false });
	};

	const resetSelector = () => {
		const print = getSelects();
		setSearchSelected(print);
	};
	const clickTable = (e) => {
		e.preventDefault();
		if (auxData !== undefined) {
			const table = primaryMinimalTable.filter((element) => {
				return element.id === auxData.idUnityBusiness.value;
			});
			if (table.length > 0) {
				customSB(
					'warning',
					'snack.warning.bussines.unit.already',
					'Ya existe la Unidad de Negocio seleccionada'
				);
				return;
			}

			const values = auxData.idUnityBusiness;
			const newData = {
				id: values?.value,
				unityName: values.name,
				code: values.code,
				market: values.markets || 'Sin mercado asociado',
				country: values.country || 'Sin país asociado'
			};
			if (type === 'create' || isUndefined(type)) {
				setterFunction((prev) => [...prev, newData]);
			} else {
				resetSelector();
			}
		} else {
			customSB(
				'warning',
				'snack.warning.bussines.unit.blank',
				'"Por favor, selecciona una Unidad de Negocio'
			);
		}
	};

	useEffect(() => {
		if (data.length > 0) {
			const print = getSelects();
			setSearchSelected(print);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const getSelects = () => {
		const newArray = [];
		if (initialSearch) {
			initialSearch.forEach((item) => {
				let flag = true;
				data.forEach((item2) => {
					if (item.value === item2.id) {
						flag = false;
					}
				});
				if (flag) {
					newArray.push(item);
				}
			});
		}
		return newArray;
	};

	return (
		<>
			<div className="title-section">
				<span className="circle-form">
					<span>3</span>
				</span>
				<h2>
					<FormattedMessage id="p.neg.unity" defaultMessage="Unidades de negocio asociadas" />
				</h2>
			</div>
			<div className="container-technical-association">
				<span>
					<FormattedMessage id="p.bussines.units" defaultMessage="Unidades de negocio" />
				</span>
				{dataLoaded && (
					<Selector
						customStyles={customStyles}
						name="idUnityBusiness"
						data={selectedSearch}
						placeholder={formatterText('p.select.option', 'Selecione una opción')}
						dataValue={auxData}
						setterFunction={(e) => {
							setAuxData(e);
							getSelector();
						}}
						isRequired={false}
						isLoading={loading}
						fullWidth={true}
					/>
				)}
				<button onClick={clickTable} className="btn-secondary">
					<FormattedMessage id="p.add" defaultMessage="Agregar" />
				</button>
			</div>
			{primaryMinimalTable.length > 0 && (
				<section className="form-responsive-container-information">
					<MultiTableMinimal
						titles={titlesTableUnityBusiness}
						data={primaryMinimalTable}
						type="associateBussinesTech"
						handleDelete={handleDelete}
						dialog={dialog}
						setDialog={setDialog}
						closeDialog={closeDialog}
						canSearch={true}
						handleSearch={handleSearchUnityBussines}
					/>
				</section>
			)}
		</>
	);
};

export default UnityBusiness;
