import React from 'react';
import { Day } from '../GanttDiagram/Calendar';
// Import Hooks
import { FormattedMessage } from 'react-intl';
//Import Services
// Import Libs
//Import Utils
import styles from '../CalendarService.module.css';
//Import Components
import SelectComboBox from 'common/selects/SelectComboBox';
import DatePicker from 'components/pickers/DatePicker';
import { MdAutorenew } from 'react-icons/md';
import GanttServiceViewModel from './GanttService.viewModel';

const GanttService = () => {
	const {
		cargos,
		filters,
		rangoFechaServicioInicial,
		rangoFechaServicioFinal,
		setFilters,
		setRangoFechaCitaInicio,
		setRangoFechaServicioFinal,
		setTechnicianName,
		handleButtonFilter,
		handleButtonRefresh,
		getDayPropStart,
		getDayPropEnd,
		tecnicos,
		week,
		filteredServices,
		formatterText,
		loadingProgress,
		DisplayFullProgress
	} = GanttServiceViewModel();
	return (
		<>
			<div className="spacing-1">
				<section className={styles.filtersContainer}>
					<div className="grid-container-1c-2g">
						<h3
							style={{
								color: '#31D7B9',
								fontWeight: 'bold'
							}}
						>
							<FormattedMessage id="text.filter.date" defaultMessage="Filtrar por fecha" />
						</h3>
						<div>
							<DatePicker
								id="rangoFechaServicioInicial"
								name="rangoFechaServicioInicial"
								placeholder={formatterText(
									'input.placeholder.start.date',
									'Rango de fecha Inicial'
								)}
								selectValue={rangoFechaServicioInicial}
								setterFunction={setRangoFechaCitaInicio}
								showMdRemove={false}
								noIcon={true}
								refresh={true}
							/>
						</div>
						<div>
							<DatePicker
								id="rangoFechaServicioFinal"
								name="rangoFechaServicioFinal"
								placeholder={formatterText('input.placeholder.end.date', 'Rango de fecha Final')}
								selectValue={rangoFechaServicioFinal}
								setterFunction={setRangoFechaServicioFinal}
							/>
						</div>
					</div>
					<div className="grid-container-1c-2g">
						<h3
							style={{
								color: '#31D7B9',
								fontWeight: 'bold'
							}}
						>
							<FormattedMessage
								id="text.filter.nameOrJobTitle"
								defaultMessage="Filtrar por nombre o cargo"
							/>
						</h3>
						<SelectComboBox
							id="technicianName"
							selectValue={filters.technicianName}
							setterFunction={(selectedTechnician) => {
								setFilters({
									...filters,
									technicianName: selectedTechnician
								});
								setTechnicianName(selectedTechnician);
							}}
							data={tecnicos}
							placeholder={
								<FormattedMessage
									id="input.placeholder.technicianName"
									defaultMessage="Nombre del técnico"
								/>
							}
						/>
						<SelectComboBox
							name="cargo"
							selectValue={filters.cargo}
							setterFunction={(cargo) => setFilters({ ...filters, cargo })}
							data={cargos}
							placeholder={
								<FormattedMessage
									id="input.placeholder.agenda.select.position"
									defaultMessage="Filtrar por cargo"
								/>
							}
						/>
					</div>
				</section>
				<section className={styles.btnFiltersContainer}>
					<button
						onClick={handleButtonFilter}
						className="btn-add"
						style={{
							boxShadow: '100px'
						}}
					>
						<FormattedMessage id="btn.filter" defaultMessage="Filtrar" />
					</button>
					<button
						onClick={handleButtonRefresh}
						className="btn-action-primary custom-margin-0001"
						style={{
							fontSize: '13px',
							color: '#ffff',
							fontWeight: '600',
							boxShadow: '10px',
							background: '#F9B233'
						}}
					>
						<MdAutorenew className="icon-refresh" />
					</button>
				</section>
				<div style={{ display: 'flex', marginTop: 50, gap: 20 }}>
					{loadingProgress ? (
						<DisplayFullProgress />
					) : (
						<Day
							technicians={filteredServices}
							today={new Date()}
							day={new Date(getDayPropStart())}
							dateEnd={new Date(getDayPropEnd())}
						/>

					)}
				</div>
			</div>
		</>
	);
};
export default GanttService;
