import { MODULES_NAME } from 'common/validators/PermissionsConstants';
import { usePermissionContext } from 'context/PermissionContext';
import React, { useEffect } from 'react';
import useLangv2 from '../../../hooks/useLangv2';
import ListSchedule from '../ListSchedule';
import TableAgeda from '../TableAgenda';

export default function TabScheduleSelectViewModel() {
	const { formatterText } = useLangv2();

	const tabs = [
		{
			nombre: formatterText('nav.parent.3.child.option.6', 'Agendas'),
			componente: <TableAgeda />,
			idPermiso: MODULES_NAME.schedule.schedule
		},
		{
			nombre: formatterText('snackbar.coral.availability.tech', 'Disponibilidad de técnicos'),
			componente: <ListSchedule />,
			idPermiso: MODULES_NAME.schedule.availability
		}
	];

	// no pasa nada diferente cuando cambio el 0 a 1
	const { permissionsAccess, permittedActions, permissions } = usePermissionContext();
	const permissionsAccessGaranted = () => {
		permissionsAccess(MODULES_NAME.schedule.principal);
	};

	useEffect(() => {
		permissionsAccessGaranted();
	}, [permissions]);

	return { tabs, permissionsAccess, permittedActions };
}
