import useAxios from 'hooks/useAxios';
import { useEffect, useState } from 'react';
// import Hooks
import useLangv2 from 'hooks/useLangv2';
// Import Components
// Import Libs
import HandlerText from 'common/validators/HandlerText';
// Import Services
import endpoints from 'services/api';
// Import styles
// Import styles

export default function CreateTimeZoneViewModel({ getAllTimeZone, hideComponent }) {
	const { RequestUseCase, COOKIE_USER, DATE_CREATED_AT } = useAxios();

	// use Hook of language v2
	const { formatterText, noFilledContent, newItemCreated, errorDataRecovery, customSB } =
		useLangv2();
	// Form data state
	const [formData, setFormData] = useState({
		nombre: ''
	});

	useEffect(() => {
		getAllDayWeek();
		getAllHoursDay();
	}, []);

	// Value that select use
	const [selectedSearch, setSearchSelected] = useState([
		{
			dayWeek: [],
			startHour: [],
			endHour: []
		}
	]);

	// selects ids state
	const [selectIds, setSelectIds] = useState({
		idDiaSemana: {
			value: 0
		},
		idHoraInicio: {
			value: 0
		},
		idHoraFin: {
			value: 0
		}
	});

	// Get all day week for select
	const getAllDayWeek = () => {
		RequestUseCase.get(endpoints.agenda.getAllHoraDiaAgenda)
			.then((res) => {
				// variable empty to save the data
				const data = [];
				res.forEach((element) => {
					// special keys to the table
					data.push({
						value: element.idHoraDia,
						label: element.hora
					});
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					startHour: data
				}));
				setSearchSelected((prev) => ({
					...prev,
					endHour: data
				}));
			})
			.catch(() => {
				errorDataRecovery();
			});
	};

	// Get all day week for select
	const getAllHoursDay = () => {
		RequestUseCase.get(endpoints.agenda.getAllDiaSemanaAgenda)
			.then((res) => {
				const data = [];
				res.forEach((element) => {
					// special keys to the table
					data.push({
						value: element.idDiaSemana,
						label: element.dia
					});
				});
				// set the values of the select
				setSearchSelected((prev) => ({
					...prev,
					dayWeek: data
				}));
			})
			.catch(() => {
				errorDataRecovery();
			});
	};

	// const create time zone
	const createTimeZone = (e) => {
		e.preventDefault();

		if (
			formData.nombre === '' ||
			selectIds.idDiaSemana.value === 0 ||
			selectIds.idHoraInicio.value === 0 ||
			selectIds.idHoraFin.value === 0
		) {
			noFilledContent();
			return;
		}
		if (selectIds.idHoraInicio.value === selectIds.idHoraFin.value) {
			customSB(
				'warning',
				'snackbar.warning.input.same.data.agenda.hours',
				'La hora de inicio no puede ser igual a la hora de fin.'
			);
			return;
		}

		if (selectIds.idHoraInicio.value > selectIds.idHoraFin.value) {
			customSB(
				'warning',
				'snackbar.warning.input.same.data.agenda.hours',
				'La hora de final no puede ser menor a la hora de fin.'
			);
			return;
		}

		const DATA = [
			{
				idFranjaHoraria: null,
				nombre: formData.nombre.replaceAll(/\s{2,}/gi, ' '),
				idDiaSemana: {
					idDiaSemana: selectIds.idDiaSemana.value
				},
				idHoraInicio: {
					idHoraDia: selectIds.idHoraInicio.value
				},
				idHoraFin: {
					idHoraDia: selectIds.idHoraFin.value
				},
				estado: 1,
				fechaCreacion: DATE_CREATED_AT,
				usuarioCreacion: COOKIE_USER
			}
		];

		RequestUseCase.post(endpoints.agenda.addTimeZone, DATA)
			.then(() => {
				newItemCreated();
				getAllTimeZone();
				hideComponent(false);
			})
			.catch(() => {
				customSB(
					'error',
					'snackbar.error.data.time.zone.validation',
					'Error: Es posible que ya exista el nombre del registro o fallo en realizar el proceso. Intentalo en otro momento.'
				);
			});
	};

	// Update a string to set into the form
	const handleText = (e) => {
		HandlerText(e, formData, setFormData);
	};

	return {
		handleText,
		createTimeZone,
		formData,
		selectedSearch,
		selectIds,
		setSelectIds,
		formatterText
	};
}
