import React, { useEffect, useState } from 'react';
// Import Context
// Import Hooks
import useAxios from 'hooks/useAxios';
import useGetData from 'hooks/useGetData';
// Import services
import HandleOnError from 'common/validators/HandleOnError';
import Attachments from 'components/AttachedFiles/Attachments';
import { MdDelete } from 'react-icons/md';
import { Modal } from 'react-responsive-modal';
import endpoints from 'services/api';
// Import Hooks
import { ALERT } from 'constants/alerts';
import { FIELDS } from 'constants/fields';
import { NQR_CONST } from 'constants/lang/services/services/nqr';
import useLangv2, { formatterText } from 'hooks/useLangv2';
import { useParams } from 'react-router';

const NQR = ({ changeStatusNQR, canDeleteNQR, canDeleteAnsNQR }) => {
	const { RequestUseCase } = useAxios();
	const { errorDataRecovery, newItemCreated, errorProcess, noFilledContent, formatterText } =
		useLangv2();
	const [nqrState, setNqrState] = useState([]);
	const [nqrType, setNqrType] = useState([]);
	const [nqrCategory, setNqrCategory] = useState([]);
	const [allNQR, setAllNQR] = useState([]);
	const [archivos, setCurrentFiles] = useState([]);
	// hook to loading data
	const { error, toggleLoading, toggleError, handleClick } = useGetData();
	const { id } = useParams();

	useEffect(() => {
		getNqrState();
		getDataTable();
	}, []);

	useEffect(() => {
		getNqrState();
		getDataTable();
	}, [id]);

	// función para cargar datos
	const getDataTable = () => {
		// show loading
		toggleLoading(true);

		RequestUseCase.get(endpoints.nqrService.finNqrByService(id))
			.then((data) => {
				const newArray = [];
				data.forEach((item) => {
					handleStructureItems(newArray, item);
				});
				setAllNQR(newArray);
				// show loading
				toggleLoading(false);
			})
			.catch((err) => {
				console.log(err);
				// mostrar error
				toggleError(!error);
				handleClick();
			});
	};

	const handleStructureItems = (newArray, item) => {
		newArray.push({
			...item,
			tipoNqr: item.descripcionTipoNqr,
			categoriaNqr: item.descripcionCategoriaNqr,
			estadoNqr: item.descripcionEstadoNqr,
			descNqr: item.descripcionNqr
		});
	};

	const getNqrState = () => {
		RequestUseCase.get(endpoints.nqrService.getAllEstadoNqr)
			.then((res) => {
				setNqrState(res);
			})
			.catch(() => {
				errorDataRecovery();
			});
	};

	useEffect(() => {
		getNQRType();
	}, []);

	const getNQRType = () => {
		RequestUseCase.get(endpoints.nqrService.getAllTipoNQR)
			.then((res) => {
				setNqrType(res);
			})
			.catch(() => {
				errorDataRecovery();
			});
	};

	useEffect(() => {
		getNqrCategory();
	}, []);

	const getNqrCategory = () => {
		RequestUseCase.get(endpoints.nqrService.getAllCategoriaNQR)
			.then((res) => {
				setNqrCategory(res);
			})
			.catch(() => {
				errorDataRecovery();
			});
	};

	const [dataNqr, setDataNqr] = useState({
		descripcion: '',
		idTipoNqr: '',
		idEstadoNqr: '1',
		idCategoriaNqr: ''
	});

	const handleChange = (e) => {
		setDataNqr({
			...dataNqr,
			[e.target.name]: e.target.value
		});
	};

	const handleSubmit = () => {
		if (
			dataNqr.descripcion === '' ||
			dataNqr.idTipoNqr === '' ||
			dataNqr.idEstadoNqr === '' ||
			dataNqr.idCategoriaNqr === ''
		) {
			noFilledContent();
		} else {
			// delete caracteres especiales of description
			const DATA = {
				descripcion: dataNqr.descripcion,
				idServicio: parseInt(id),
				idTipoNqr: {
					idTipoNqr: parseInt(dataNqr.idTipoNqr)
				},
				idEstadoNqr: {
					idEstadoNqr: parseInt(dataNqr.idEstadoNqr)
				},
				idCategoriaNqr: {
					idCategoriaNqr: parseInt(dataNqr.idCategoriaNqr)
				}
			};
			RequestUseCase.post(endpoints.nqrService.saveNqrService, DATA)
				.then((response) => {
					uploadFiles(response.idNqrServicio);
					getDataTable();
					setCurrentFiles([]);
					cleanNqr();
				})
				.catch(() => {
					errorProcess();
				});
		}
	};

	const cleanNqr = () => {
		setDataNqr({
			descripcion: '',
			idTipoNqr: '',
			idEstadoNqr: '1',
			idCategoriaNqr: ''
		});
	};

	const uploadFiles = (id) => {
		return new Promise((resolve, reject) => {
			if (archivos.length > 0) {
				const body = {
					idOrigen: id,
					idTipoOrigenArchivo: 1,
					archivos
				};

				const options = {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(body)
				};
				fetch(endpoints.UploadFiles.save, options)
					.then(() => {
						newItemCreated();
					})
					.catch((err) => {
						reject(err);
					});
			} else {
				resolve(newItemCreated());
			}
		});
	};

	return (
		<>
			<section
				className="grid-container-2c sm-gap"
				style={{
					width: '90%',
					margin: '0 auto'
				}}
			>
				<label className="d-flex">
					<span className="text-inline primary-green">{formatterText(NQR_CONST.NQRType)}</span>
					<select
						mode="multiple"
						className="input-label-style"
						name="idTipoNqr"
						onChange={(e) => handleChange(e)}
						value={dataNqr.idTipoNqr}
						required
					>
						<option value="">{formatterText(FIELDS.selectOption)}</option>
						{nqrType.map((tipo) => (
							<option key={tipo.idTipoNqr} value={tipo.idTipoNqr}>
								{tipo.descripcion}
							</option>
						))}
					</select>
				</label>
				<label className="d-flex">
					<span className="text-inline primary-green">{formatterText(NQR_CONST.NQRCategory)}</span>
					<select
						className="input-label-style"
						name="idCategoriaNqr"
						onChange={(e) => handleChange(e)}
						value={dataNqr.idCategoriaNqr}
						required
					>
						<option value="">{formatterText(FIELDS.selectOption)}</option>
						{nqrCategory.map((categoria) => (
							<option key={categoria.idCategoriaNqr} value={categoria.idCategoriaNqr}>
								{categoria.descripcion}
							</option>
						))}
					</select>
				</label>
			</section>
			<section
				className="grid-container-1c"
				style={{
					width: '90%',
					margin: '0 auto',
					marginTop: '1rem'
				}}
			>
				<section className="d-flex d-ajc">
					<span className="text-inline primary-green">
						{formatterText(FIELDS.title.description)}
					</span>
					<textarea
						className="input-default-textarea"
						name="descripcion"
						id=""
						cols="30"
						rows="10"
						value={dataNqr.descripcion}
						onChange={(e) => handleChange(e)}
					/>
				</section>
			</section>
			{/* files */}
			<section className="wrapForm w100-container">
				<section className="edit&btnAContainer w100-container">
					<section
						className="btnAdjuntarContainer"
						style={{ marginTop: '20px', marginBottom: '20px' }}
					>
						<Attachments
							currentFiles={archivos}
							setCurrentFiles={setCurrentFiles}
							isEdited={false}
							quantityByName={5}
							type={14}
							showParameters={false}
						/>
					</section>
				</section>
			</section>

			{/* actions */}
			<section className="form-responsive-container-buttons">
				<input
					type="submit"
					className="btn-action-primary"
					value={formatterText(FIELDS.input.save)}
					onClick={(e) => {
						e.preventDefault();
						handleSubmit();
					}}
				/>
			</section>
			{/* line */}
			<div
				style={{
					//make a line in the middle of the page
					width: '100%',
					height: '1px',
					backgroundColor: '#747474',
					margin: '0 auto',
					marginTop: '25px',
					marginBottom: '25px'
				}}
			/>

			<>
				<label className="wrapForm__label">
					<h2
						className="primary-green spacing-l1"
						style={{
							marginLeft: '5%'
						}}
					>
						{formatterText(NQR_CONST.advisorManagement)}
					</h2>
				</label>
				<PrecautionsComponent
					allNQR={allNQR}
					getDataTable={getDataTable}
					nqrState={nqrState}
					canDeleteNQR={canDeleteNQR}
					changeStatusNQR={changeStatusNQR}
					canDeleteAnsNQR={canDeleteAnsNQR}
				/>
			</>
		</>
	);
};

function PrecautionsComponent({
	allNQR,
	getDataTable,
	nqrState,
	canDeleteNQR,
	changeStatusNQR,
	canDeleteAnsNQR
}) {
	const [openModal, setOpenModal] = useState(false);
	const [modalData, setModalData] = useState({});
	const { successRemoveItem, formatterText } = useLangv2();
	const setCloseModal = () => {
		setOpenModal(false);
	};

	return (
		<>
			<div
				style={{
					display: 'flex',
					//columns for the cards
					flexFlow: 'row wrap',
					width: '100%',
					margin: '0 auto',
					marginTop: '25px',
					marginBottom: '25px'
				}}
			>
				{allNQR.map(
					(item, index) =>
						item.idNqrServicio !== null && (
							<ListNQR
								key={index}
								item={item}
								setOpenModal={setOpenModal}
								setModalData={setModalData}
								getDataTable={getDataTable}
								canDeleteNQR={canDeleteNQR}
								successRemoveItem={successRemoveItem}
								formatterText={formatterText}
							/>
						)
				)}
				<Modal
					open={openModal}
					onClose={setCloseModal}
					center
					classNames={{
						overlay: 'customOverlay',
						modal: 'customModal'
					}}
					styles={{
						modal: {
							width: '80%',
							height: '80%',
							borderRadius: '10px'
						}
					}}
				>
					<NQRdetailAndAnswer
						modalData={modalData}
						getDataTable={getDataTable}
						setCloseModal={setCloseModal}
						nqrState={nqrState}
						changeStatusNQR={changeStatusNQR}
						canDeleteAnsNQR={canDeleteAnsNQR}
					/>
				</Modal>
			</div>
		</>
	);
}

const ListNQR = ({
	item,
	setOpenModal,
	setModalData,
	getDataTable,
	canDeleteNQR,
	successRemoveItem,
	formatterText
}) => {
	const { RequestUseCase } = useAxios();
	return (
		<div
			style={{
				width: '90%',
				margin: '0 auto',
				minHeight: '50px',
				borderColor: '#0aa48a',
				borderWidth: '2px',
				borderStyle: 'solid',
				display: 'grid',
				gridTemplateColumns: '1fr 1fr',
				gridTemplateRows: '1fr 1fr',
				boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
				marginTop: '30px',
				marginBottom: '30px'
			}}
		>
			<div
				style={{
					gridColumn: '1/2',
					gridRow: '1/2',
					marginTop: '10px',
					marginLeft: '5%',
					color: 'black',
					fontWeight: 'bold'
				}}
			>
				{item.tipoNqr}
			</div>
			<div
				style={{
					gridColumn: '1/2',
					gridRow: '2/2',
					marginTop: '10px',
					marginLeft: '5%'
				}}
			>
				{/* just 30 chars of descNqr and add ... to the end  */}
				{item.descNqr?.length > 30 ? item.descNqr.slice(0, 30) + '...' : item.descNqr}
			</div>
			<div
				style={{
					//ubique this div in the second column , ocupping two rows and align to the right
					gridColumn: '2/2',
					gridRow: '1/3',
					display: 'flex',
					justifyContent: 'flex-end',
					right: '30px',
					alignItems: 'center'
				}}
			>
				<button
					style={{
						//make a button
						width: '40%',
						height: '60%',
						borderColor: '#0aa48a',
						borderWidth: '2px',
						borderStyle: 'solid',
						color: '#0aa48a',
						backgroundColor: 'white',
						margin: '0 auto',
						marginTop: '25px',
						marginBottom: '25px'
					}}
					onClick={(e) => {
						e.preventDefault();
						setOpenModal(true);
						setModalData(item);
					}}
				>
					<p
						style={{
							color: 'black',
							fontSize: '14px'
						}}
					>
						{formatterText('id.service.view.detail')}
					</p>
				</button>
				<MdDelete
					style={{
						//ubique this div in the second column , ocupping two rows and align to the right
						gridColumn: canDeleteNQR ? '2/2' : '',
						gridRow: canDeleteNQR ? '1/3' : '',
						display: canDeleteNQR ? 'flex' : 'none',
						justifyContent: 'flex-end',
						marginRight: '10%',
						alignItems: 'center',
						color: 'red',
						fontSize: '30px',
						cursor: 'pointer'
					}}
					onClick={(e) => {
						e.preventDefault();
						RequestUseCase.delete(endpoints.nqrService.deleteNqrService(item.idNqrServicio))
							.then(() => {
								getDataTable();
								successRemoveItem();
							})
							.catch((err) => {
								HandleOnError(formatterText(err.response.data.message));
							});
					}}
				/>
			</div>
		</div>
	);
};

const NQRdetailAndAnswer = ({
	modalData,
	getDataTable,
	setCloseModal,
	nqrState,
	changeStatusNQR,
	canDeleteAnsNQR
}) => {
	const [text, setText] = useState('');
	const [nqrStateSelected, setNqrStateSelected] = useState(0);
	const [sendOption, setSendOption] = useState('');
	const [emailValue, setEmailValue] = useState('');
	const [archivos, setCurrentFiles] = useState([]);
	const { newItemCreated, errorProcess, sendSuccess, successRemoveItem } = useLangv2();
	const { fetchData, RequestUseCase, COOKIE_USER } = useAxios();

	useEffect(() => {
		(async () => {
			await getDataAttachedFiles();
		})();
	}, []);

	const handleOptionChange = (event) => {
		setSendOption(event.target.value);
	};

	const handleEmailChange = (event) => {
		setEmailValue(event.target.value);
	};

	const formatEmail = (id, destinatario) => {
		const DATA = {
			// idServicio: modalData.idNqrServicio
			idNqrServicio: modalData.idNqrServicio,
			destinatario: destinatario
		};
		RequestUseCase.post(endpoints.nqrService.sendEmailNqr, DATA)
			.then(() => {
				sendSuccess();
				getDataTable();
			})
			.catch(() => {
				errorProcess();
			});
	};

	const sendEmail = () => {
		if (sendOption === '') {
			alert(`${formatterText(ALERT.selectShippingOption)}`);
			return;
		}
		if (sendOption === 'enviar_manual') {
			formatEmail(parseInt(id), emailValue.trim());
		} else if (sendOption === 'enviar_servicio_cliente') {
			formatEmail(parseInt(id), null);
		} else if (sendOption === 'enviar_cliente') {
			formatEmail(parseInt(id), null);
		}
	};

	const inputRef = React.useRef(null);

	const saveAnswer = () => {
		if (text === '') {
			return;
		}
		const data = {
			idNqrServicio: { idNqrServicio: modalData.idNqrServicio },
			descripcion: text.replace(/[^a-zA-Z0-9 ]/g, ''),
			fechaModificacion: new Date()
		};
		RequestUseCase.post(endpoints.nqrService.saveAnswerNqr, data).then(() => {
			setText('');
			inputRef.current.value = '';
			getDataTable();
			setCloseModal();
			newItemCreated();
		});
	};

	const getIDtoDelete = (text) => {
		RequestUseCase.get(endpoints.nqrService.findAnswerNqrByNqr(modalData.idNqrServicio)).then(
			(res) => {
				const id = res.filter((item) => item.descripcion === text);
				RequestUseCase.delete(endpoints.nqrService.deleteAnswerNqr(id[0].idRespuestaNqr))
					.then(() => {
						successRemoveItem();
						getDataTable();
						setCloseModal();
					})
					.catch(() => {
						HandleOnError(`${formatterText(ALERT.whileDeletingResponse)}`);
					});
			}
		);
	};

	const { id } = useParams();

	const updateStatus = () => {
		if (nqrStateSelected === 0) {
			return;
		}
		RequestUseCase.get(endpoints.nqrService.getDataNQR(modalData.idNqrServicio)).then((res) => {
			const data = {
				idNqrServicio: modalData.idNqrServicio,
				idServicio: parseInt(id),
				idTipoNqr: { idTipoNqr: res.idTipoNqr.idTipoNqr },
				idCategoriaNqr: { idCategoriaNqr: res.idCategoriaNqr.idCategoriaNqr },
				descripcion: res.descripcion,
				idEstadoNqr: { idEstadoNqr: parseInt(nqrStateSelected) },
				fechaModificacion: new Date().toISOString(),
				fechaCreacion: res.fechaCreacion,
				usuarioCreacion: res.usuarioCreacion,
				usuarioModificacion: parseInt(COOKIE_USER)
			};

			RequestUseCase.put(endpoints.nqrService.updateNqrService, data)
				.then(() => {
					getDataTable();
					setCloseModal();
				})
				.catch(() => {
					HandleOnError('Ocurrio un error al actualizar el estado');
				});
		});
	};


	const hashId = {
		'En Proceso': 1,
		'No procedente': 2,
		Procedente: 3
	};

	// upload files
	const uploadNewFile = (file) => {
		return new Promise((resolve, reject) => {
			const body = {
				idOrigen: modalData.idNqrServicio,
				idTipoOrigenArchivo: 1,
				archivos: [file]
			};

			const options = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			};
			fetch(endpoints.UploadFiles.save, options)
				.then(() => {
					newItemCreated();
				})
				.catch((err) => {
					reject(err);
				});
		});
	};

	const getDataAttachedFiles = async () => {
		const obj = {
			idOrigen: modalData.idNqrServicio,
			idTipoOrigenArchivo: 1,
		};
		fetchData({
			url: endpoints.UploadFiles.findArchivosById(obj),
			method: 'get',
			typeConfig: 'form'
		}).then((response) => {
			const files = response.response.map((file) => {
				return {
					name: file,
					url: file
				};
			});
			setCurrentFiles(files);
		});
	};

	return (
		<>
			<div
				style={{
					justifyContent: 'center',
					alignContent: 'center',
					alignSelf: 'center',
					display: 'flex'
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						width: '80%',
						height: '10%',
						backgroundColor: '#0aa48a',
						color: 'white',
						padding: '10px',
						textAlign: 'center',
						justifyContent: 'center',
						borderRadius: '20px'
					}}
				>
					{modalData.tipoNqr}
				</div>
			</div>
			<div
				style={{
					//make a style of too much text
					width: '80%',
					minHeight: '10%',
					margin: '0 auto',
					marginTop: '25px',
					marginBottom: '10px',
					overflow: 'auto',
					textAlign: 'justify'
				}}
			>
				{formatterText(FIELDS.title.description)}: {modalData.descNqr}
			</div>
			{/* make a selector to change status */}
			{!changeStatusNQR && (
				<div
					style={{
						//make a style of too much text
						width: '80%',
						minHeight: '10%',
						margin: '0 auto',
						marginTop: '25px',
						marginBottom: '25px',
						overflow: 'auto',
						textAlign: 'justify',
						// wrap the content
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<h3 className="p-styles spacing-l1 primary-green">
						{formatterText(NQR_CONST.NQRStatus)}
					</h3>
					<div
						style={{
							width: '100%',
							height: '40px',
							//centrar el texto
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						{modalData.descripcionEstadoNqr}
					</div>
				</div>
			)}
			<div
				style={{
					//make a style of too much text
					width: '80%',
					minHeight: '10%',
					margin: '0 auto',
					marginTop: '10px',
					marginBottom: '10px',
					overflow: 'auto',
					textAlign: 'justify',
					// wrap the content
					//display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					display: !changeStatusNQR && 'none'
				}}
			>
				<h3 className="p-styles spacing-l1 primary-green">{formatterText(NQR_CONST.NQRStatus)}</h3>
				<div
					className="form-responsive-container-buttons NQRSaveBtn"
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: '20px',
						justifyContent: 'flex-start'
					}}
				>
					<select
						className="input-edit"
						name="idEstadoNqr"
						onChange={(e) => setNqrStateSelected(e.target.value)}
						defaultValue={hashId[modalData.descripcionEstadoNqr]}
					>
						{nqrState.map((estado) => (
							<option key={estado.idEstadoNqr} value={estado.idEstadoNqr}>
								{estado.descripcion}
							</option>
						))}
					</select>
					<input
						type="submit"
						className="btn-action-primary"
						value={formatterText(FIELDS.input.update)}
						onClick={(e) => {
							e.preventDefault();
							updateStatus();
						}}
					/>
				</div>
			</div>
			{modalData.descripcionRespuestaNqr[0] !== null && (
				<>
					<div
						style={{
							justifyContent: 'center',
							alignContent: 'center',
							alignSelf: 'center',
							display: 'flex'
						}}
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								width: '80%',
								height: '10%',
								backgroundColor: '#0aa48a',
								color: 'white',
								padding: '10px',
								textAlign: 'center',
								justifyContent: 'center'
							}}
						>
							{formatterText(FIELDS.title.answers)}
						</div>
					</div>

					{modalData.descripcionRespuestaNqr.map((item, index) => (
						<NQRmapAnswer
							key={index}
							item={item}
							getIDtoDelete={getIDtoDelete}
							canDeleteAnsNQR={canDeleteAnsNQR}
							stausProp={modalData.descripcionEstadoNqr}
						/>
					))}
				</>
			)}

			<div
				style={{
					width: '80%',
					margin: '0 auto',
					marginTop: '25px',
					marginBottom: '25px'
				}}
			>
				<div
					style={{
						//display: 'flex',
						alignItems: 'center',
						width: '100%',
						height: '10%',
						backgroundColor: '#0aa48a',
						color: 'white',
						padding: '10px',
						textAlign: 'center',
						justifyContent: 'center',
						borderRadius: '20px',
						display: hashId[modalData.descripcionEstadoNqr] !== 1 && 'none'
					}}
				>
					{formatterText(FIELDS.title.addAnswer)}
				</div>
				<div
					style={{
						width: '100%',
						minHeight: '10%',
						margin: '0 auto',
						marginTop: '25px',
						marginBottom: '25px',
						overflow: 'auto',
						textAlign: 'justify',
						display: hashId[modalData.descripcionEstadoNqr] !== 1 && 'none'
					}}
				>
					<textarea
						className="input-default-textarea"
						style={{
							width: '100%',
							minHeight: '100px',
							borderColor: '#0aa48a',
							borderWidth: '2px',
							borderStyle: 'solid'
						}}
						ref={inputRef}
						name="descripcion"
						id=""
						cols="30"
						rows="10"
						onChange={(e) => setText(e.target.value)}
					/>
				</div>
				<div
					style={{
						//display: 'flex',
						alignItems: 'center',
						width: '100%',
						height: '10%',
						backgroundColor: '#0aa48a',
						color: 'white',
						padding: '10px',
						textAlign: 'center',
						justifyContent: 'center',
						borderRadius: '20px',
						marginBottom: '20px',
						display: hashId[modalData.descripcionEstadoNqr] !== 1 && 'none'
					}}
				>
					{formatterText(FIELDS.title.attachedFiles)}
				</div>
				<div
					style={{
						display: hashId[modalData.descripcionEstadoNqr] !== 1 && 'none'
					}}
				>
					<Attachments
						currentFiles={archivos}
						setCurrentFiles={setCurrentFiles}
						isEdited={true}
						uploadNewFile={uploadNewFile}
						type={14}
						showParameters={false}
					/>
				</div>

				{/* make a button  to send the Answer */}
				<div
					className="form-responsive-container-buttons NQRSaveBtn"
					style={{ marginTop: '20px', marginBottom: '20px' }}
				>
					<input
						type="submit"
						className="btn-action-primary"
						value={formatterText(FIELDS.input.save)}
						onClick={(e) => {
							e.preventDefault();
							saveAnswer();
						}}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						width: '100%',
						height: '10%',
						backgroundColor: '#0aa48a',
						color: 'white',
						padding: '10px',
						textAlign: 'center',
						justifyContent: 'center',
						borderRadius: '20px'
					}}
				>
					{formatterText(FIELDS.title.emailSendingType)}
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<label style={{ margin: '0 10px' }}>
						<input
							type="radio"
							value="enviar_cliente"
							checked={sendOption === 'enviar_cliente'}
							onChange={handleOptionChange}
						/>
						{formatterText(FIELDS.title.sendCustomerEmail)}
					</label>
					<label style={{ margin: '0 10px' }}>
						<input
							type="radio"
							value="enviar_manual"
							checked={sendOption === 'enviar_manual'}
							onChange={handleOptionChange}
						/>
						{formatterText(FIELDS.title.sendManualEmail)}
					</label>
				</div>
				{sendOption === 'enviar_manual' && (
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<input
							type="email"
							className="input-edit"
							placeholder={formatterText(FIELDS.placeholder.email)}
							value={emailValue}
							onChange={handleEmailChange}
							style={{ marginRight: '10px' }}
						/>
						<button className="btn-action-primary" onClick={sendEmail}>
							{formatterText('btn.send.email')}
						</button>
					</div>
				)}
				{sendOption !== 'enviar_manual' && (
					<div style={{ textAlign: 'center', marginTop: '10px' }}>
						<button className="btn-action-primary" onClick={sendEmail}>
							{formatterText('btn.send.email')}
						</button>
					</div>
				)}
			</div>
		</>
	);
};

const NQRmapAnswer = ({ item, getIDtoDelete, canDeleteAnsNQR, stausProp }) => (
	<div
		style={{
			width: '80%',
			margin: '0 auto',
			minHeight: '50px',
			borderColor: '#0aa48a',
			borderWidth: '2px',
			borderStyle: 'solid',
			display: 'grid',
			gridTemplateColumns: '1fr 1fr',
			gridTemplateRows: '1fr 1fr',
			boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
			marginTop: '30px',
			marginBottom: '30px'
		}}
	>
		<div
			style={{
				gridColumn: '1/2',
				gridRow: '1/2',
				width: '90%',
				marginTop: '10px',
				marginLeft: '5%',
				color: 'black'
			}}
		>
			{item}
		</div>
		{stausProp === 'En Proceso' && (
			<MdDelete
				style={{
					gridColumn: canDeleteAnsNQR ? '2/2' : '',
					gridRow: canDeleteAnsNQR ? '1/3' : '',
					alignSelf: 'center',
					width: '10%',
					display: canDeleteAnsNQR ? 'flex' : 'none',
					right: '10%',
					alignItems: 'center',
					color: 'grey',
					position: 'absolute',
					fontSize: '20px',
					cursor: 'pointer'
				}}
				onClick={(e) => {
					e.preventDefault();
					getIDtoDelete(item);
				}}
			/>
		)}
	</div>
);

export default NQR;
