import dateFormat from 'dateformat';

/**
 * Archivo para manipular las fechas (prototipo)
 * error actual se carga 4 veces y deja de funcionar el selector de fecha
 * @param {int} years añade o quita años para las fechas que requieren
 * minimo y maximo de edad
 *
 * @author Luis Fernandez - Qvision
 */
const HandleShortDate = (years = 0) => {
	const now = new Date();
	return dateFormat(
		new Date(now.getFullYear() + years, now.getMonth(), now.getDate()),
		'yyyy-mm-dd'
	);
};

export default HandleShortDate;
