import React from 'react';
// Context de búsqueda
import { SearchWrapper } from 'context/SearchContext';
import { PermissionWrapper } from 'context/PermissionContext';
import FileComponent from './File.component';

const File = () => {
	return (
		<SearchWrapper>
			<PermissionWrapper>
				<FileComponent />
			</PermissionWrapper>
		</SearchWrapper>
	);
};

export default File;
