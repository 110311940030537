import { useField } from '@formiz/core';
import PropTypes from 'prop-types';
import currencyFormat from 'common/utils/currencyFormat';
import rawCurrency from 'common/utils/rawCurrency';
import {
	ADDRESSREGEX,
	ALPHANUMERICREGEX,
	NOSPACEREGEX,
	NUMBERREGEX,
	ONLY_NUMBERS_REGEX,
	PRICEREGEX,
	SKUREGEX,
	TEXTREGEX,
	URLREGEX,
	CODEREGEX
} from 'common/validators/Regex';
import React from 'react';

export const InputField = (props) => {
	const { errorMessage, id, isValid, isSubmitted, setValue, value } = useField(props);
	const {
		type,
		name,
		labelText,
		placeholder,
		validateInput,
		disabled,
		required,
		styleName,
		asArea = false
	} = props;
	const [isTouched, setIsTouched] = React.useState(false);
	const showError = !isValid && (isTouched || isSubmitted);

	const validateRegex = (e, regex) => {
		const { value = '' } = e.target;
		if (value && regex === CODEREGEX && value.match(regex)) {
			setValue(value.toLowerCase());
		} else {
			if (value.match(regex)) setValue(value);
		}
	};

	const handleChange = (e) => {
		const { name = '', value = '' } = e.target;

		switch (validateInput) {
			case 'o_number':
				validateRegex(e, ONLY_NUMBERS_REGEX);
				break;
			case 'number':
				validateRegex(e, PRICEREGEX);
				break;
			case 'text':
				if (/sku/gi.test(name)) validateRegex(e, SKUREGEX);
				else if (/descripcion/gi.test(name)) setValue(value);
				else validateRegex(e, TEXTREGEX);

				break;
			case 'web':
				validateRegex(e, URLREGEX);
				break;
			case 'integer':
				validateRegex(e, NUMBERREGEX);
				break;
			case 'address':
				validateRegex(e, ADDRESSREGEX);
				break;
			case 'alphanumber':
				validateRegex(e, ALPHANUMERICREGEX);
				break;
			case 'text-no-space':
				validateRegex(e, NOSPACEREGEX);
				break;
			case 'code-lower-case':
				validateRegex(e, CODEREGEX);
				break;
			default:
				setValue(value);
				break;
		}
	};

	return (
		<label className="wrapForm__label">
			<span className="warpForm-text">{labelText}</span>
			<section className="container-wrapForm__label">
				{asArea || /area/gi.test(type) ? (
					<textarea
						id={id}
						type={type || 'text'}
						value={value || ''}
						name={name}
						onChange={handleChange}
						onBlur={() => {
							const oldVal = value?.replaceAll(/\s{2,}/gi, ' ');
							setValue(oldVal);
							setIsTouched(true);
						}}
						placeholder={placeholder}
						className={styleName || 'input-default-3c'}
						aria-invalid={showError}
						aria-required={!!required}
						aria-describedby={showError ? `${id}-error` : null}
						disabled={disabled}
					/>
				) : (
					<input
						id={id}
						type={type || 'text'}
						value={value || ''}
						name={name}
						onChange={handleChange}
						onBlur={() => {
							const oldVal = value?.replaceAll(/\s{2,}/gi, ' ');

							setIsTouched(true);

							if (validateInput === 'number') {
								setValue(currencyFormat(oldVal));
							} else setValue(oldVal);
						}}
						onFocus={() => {
							const oldVal = value?.replaceAll(/\s{2,}/gi, ' ');

							if (validateInput === 'number' && oldVal) {
								setValue(rawCurrency(oldVal));
							} else setValue(oldVal);
						}}
						placeholder={placeholder}
						className={styleName || 'input-default-3c'}
						aria-invalid={showError}
						aria-required={!!required}
						aria-describedby={showError ? `${id}-error` : null}
						disabled={disabled}
						min={props.min}
					/>
				)}
				{showError && (
					<span id={`${id}-error`} className="error-msg">
						{errorMessage}
					</span>
				)}
			</section>
		</label>
	);
};

InputField.propTypes = {
	asArea: PropTypes.bool,
	data: PropTypes.array,
	defaultValue: PropTypes.string,
	disabled: PropTypes.bool,
	isLoading: PropTypes.bool,
	labelText: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	required: PropTypes.string,
	styleName: PropTypes.string,
	type: PropTypes.string,
	validateInput: PropTypes.string,
	valueInput: PropTypes.string,
	min: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
