import React from 'react';

// Import Libs
import { Formiz, FormizStep } from '@formiz/core';
import dateFormat from 'dateformat';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';

// Import Components
import { InputFieldDV } from 'common/InputFieldDV';
import SelectorMulti from 'common/SelectorMulti';
import { InputFieldResponsive } from 'common/inputs/InputFieldResponsive';
import InputSelectorResponsive from 'common/inputs/InputSelectorResponsive';
import AssociateCoverage from 'common/multiTableType/AssociateCoverage';
import ClienteSucursal from 'common/multiTableType/ClienteSucursal';
import UnityBusiness from 'common/multiTableType/UnityBusiness';
import TableMinimalNoContext from 'common/minimalTables/TableMinimalNoContext';
import Attachments from 'components/AttachedFiles/Attachments';

// Import Utils
import HandleShortDate from 'common/validators/HandleShortDate';

// Import Paths
import paths from 'services/paths';

// Import Styles
import customStyles from 'common/selects/generic.style.select';

// Import ViewModel
import CreateTechnicalViewModel from './CreateTechnical.viewModel';
import { CARREGEX, MODELCARREGEX } from 'common/validators/Regex';

function CreateTechnicalCompnent() {
	const {
		archivos,
		categoryLicense,
		categoryService,
		deleteVehicule,
		formatterText,
		formData,
		groupedOptions,
		handleAddFamily,
		handleChangeModel,
		handleChangeMulti,
		handleChangeRelative,
		handleChangeSelect,
		handleSubmit,
		loading,
		myForm,
		navigate,
		now,
		primaryMinimalTable,
		secondMinimalTable,
		selectedOption,
		selectValues,
		setCurrentFiles,
		setPrimaryMinimalTable,
		setSecondMinimalTable,
		setSelectedOption,
		setTechnicalInfo,
		setThirdMinimalTable,
		show,
		thirdMinimalTable,
		TITLE_TABLE,
		vehicles,
		vehiclesBrand,
	} = CreateTechnicalViewModel();

	return (
		<div className="centered-form">
			{/* Quitar la S para conectar el formulario, al formulario de pruebas */}
			<Formiz onValidSubmit={handleSubmit} connect={myForm}>
				<form
					noValidate
					onSubmit={myForm.submit}
					className="container-wrapForm"
					style={{ minHeight: '16rem' }}
				>
					<div className="new-container-wrapForm__tabs">
						{myForm.steps.map((step) => (
							<button
								key={step.name}
								className={`new-tab-option ${
									step.name === myForm.currentStep.name ? 'is-active' : ''
								}`}
								type="button"
								onClick={() => myForm.goToStep(step.name)}
							>
								{!step.isValid && step.isSubmitted && <small className="mr-2">⚠️</small>}
								{formatterText(step.label)}
							</button>
						))}
					</div>
					<div className="container-wrapForm-content">
						<section>
							{/*info technical */}
							<FormizStep name="step1" label="p.general.information">
								<div className="title-section">
									<span className="circle-form">
										<span>1</span>
									</span>
									<h2>
										<FormattedMessage
											id="p.search.employes.third"
											defaultMessage="Buscar empleado o tercero"
										/>
									</h2>
								</div>
								<section className="grid-container-3c">
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage id="p.employed.third" defaultMessage="Empleado o tercero" />
										</span>
										<section className="w100-container">
											<Select
												styles={customStyles}
												onChange={(selectedOption) => {
													// helps to set the value of the input below
													setSelectedOption(selectedOption.label);
													// set the information in the front
													setTechnicalInfo(selectedOption);
												}}
												options={groupedOptions}
												placeholder={
													loading ? (
														<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
													) : (
														<FormattedMessage
															id="input.placeholder.select"
															defaultMessage="Selecione una opción"
														/>
													)
												}
											/>
											<input
												className="input-required"
												type="text"
												value={selectedOption || ''}
												name="idEmpleado"
											/>
										</section>
									</label>
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage
												id="p.service.category.per"
												defaultMessage="Categoría de servicio a la que pertenece"
											/>
										</span>
										<section className="w100-container">
											<SelectorMulti
												data={categoryService}
												dataValue={selectValues}
												setterFunction={handleChangeMulti}
											/>
										</section>
									</label>
									<InputFieldDV
										type="text"
										name="tipoDocumento"
										labelText={formatterText('p.document.type', 'Tipo de documento')}
										placeholder={formatterText('p.document.type', 'Tipo de documento')}
										validateInput="text"
										values={formData.tipoDocumento}
										disabled={true}
										required={formatterText('p.label.title.tipoDocumentoRequerido')}
									/>
									<InputFieldDV
										type="text"
										name="genero"
										labelText={formatterText('p.genero', 'Género')}
										placeholder={formatterText('p.genero', 'Género')}
										validateInput="text"
										values={formData.genero || ''}
										disabled={true}
									/>
									<InputFieldDV
										type="text"
										name="identificacion"
										labelText={formatterText('text.ID', 'Identificación')}
										placeholder={formatterText('text.ID', 'Identificación')}
										validateInput="number"
										values={formData.identificacionEmpleado || ''}
										disabled={true}
										required={formatterText('p.label.title.identificacionRequerida')}
									/>
									<InputFieldDV
										type="text"
										name="primerNombre"
										labelText={formatterText('p.first.name', 'Primer nombre')}
										placeholder={formatterText('p.first.name', 'Primer nombre')}
										validateInput="text"
										values={formData.primerNombre || ''}
										disabled={true}
										required={formatterText('p.label.title.primerNombreRequerido')}
									/>
									<InputFieldDV
										type="text"
										name="segundoNombre"
										labelText={formatterText('p.second.name', 'Segundo nombre')}
										placeholder={formatterText('p.second.name', 'Segundo nombre')}
										validateInput="text"
										values={formData.segundoNombre || ''}
										disabled={true}
									/>
									<InputFieldDV
										type="text"
										name="primerApellido"
										labelText={formatterText('p.first.lastname', 'Primer Apellido')}
										placeholder={formatterText('p.first.lastname', 'Primer Apellido')}
										validateInput="text"
										values={formData.primerApellido || ''}
										disabled={true}
									/>
									<InputFieldDV
										type="text"
										name="segundoApellido"
										labelText={formatterText('p.second.lastname', 'Segundo Apellido')}
										placeholder={formatterText('p.second.lastname', 'Segundo Apellido')}
										validateInput="text"
										values={formData.segundoApellido || ''}
										disabled={true}
									/>
								</section>
							</FormizStep>
							{/* info tech continue */}
							<FormizStep name="step2" label="p.operators.technicians">
								<div className="title-section">
									<span className="circle-form">
										<span>2</span>
									</span>
									<h2>
										<FormattedMessage
											id="p.operators.technicians"
											defaultMessage="Información Operarios/Técnicos"
										/>
									</h2>
								</div>
								<section className="grid-container-2c" style={{ marginLeft: '10px' }}>
									<InputFieldResponsive
										type="date"
										name="licenciaFechaExpiracion"
										labelText={formatterText(
											'p.date.expire.license',
											'Fecha de expiración de la licencia de conducción'
										)}
										placeholder={formatterText(
											'p.date.expire.license',
											'Fecha de expiración de la licencia de conducción'
										)}
										required={formatterText('p.label.title.fechaExpiracionRequerida')}
										min={dateFormat(
											new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1),
											'yyyy-mm-dd'
										)}
									/>
									<InputSelectorResponsive
										type="text"
										name="idCategoriaLicenciaConduccion"
										labelText={formatterText(
											'p.category.license',
											'Categoría de licencia de conducción'
										)}
										placeholder={
											<FormattedMessage
												id="input.placeholder.select"
												defaultMessage="Selecione una opción"
											/>
										}
										required={formatterText('p.label.title.categoriaLicenciaRequerida')}
										data={categoryLicense}
										isLoading={loading}
									/>
								</section>
								<label
									className="d-flex"
									style={{
										marginTop: '40px',
										marginLeft: '10px'
									}}
								>
									<InputFieldResponsive
										type="text"
										name="restriccionLicencia"
										labelText={formatterText(
											'p.restricted.license',
											'Restricciones de licencia de conducción'
										)}
										placeholder={formatterText(
											'p.restricted.license',
											'Restricciones de licencia de conducción'
										)}
										validateInput="text"
										required={formatterText('alert.textarea.show.error.required')}
										area={true}
										styleName={'input-default-1c'}
										rows="5"
										cols="100"
									/>
								</label>

								{vehiclesBrand && (
									<div>
										<div className="title-section">
											<span className="circle-form">
												<span>2.1</span>
											</span>
											<h2>
												<FormattedMessage id="p.add.vehicle" defaultMessage="Agregar vehículo" />
											</h2>
										</div>
										{vehicles.length > 0 && (
											<>
												<section className="form-responsive-container-information">
													<TableMinimalNoContext
														titles={TITLE_TABLE}
														type="vehiclesAssign"
														data={vehicles}
														labelTable="Vehículos por página"
														handleDelete={(x) => deleteVehicule(x)}
													/>
												</section>
											</>
										)}
										{show && (
											<>
												<section className="grid-container-3c">
													<InputFieldResponsive
														name="placa"
														labelText={formatterText(
															'technicians.label.text.vehicle.license',
															'Placa del vehiculo'
														)}
														placeholder={formatterText(
															'p.enter.license.vehicle',
															'Ingrese la placa del vehiculo'
														)}
														type="text"
														className="input-primary"
														onChangeLocalValue={handleChangeRelative}
														validations={[
															{
																rule: (value) => value ? value?.match(CARREGEX) : true,
																message: 'p.label.title.placaFormatoIncorrecto'
															}
														]}
													/>

													<InputFieldResponsive
														name="modelo"
														labelText={formatterText('p.vehicle.model', 'Modelo')}
														placeholder={formatterText(
															'p.add.vehicle.model',
															'Ingrese el modelo del vehiculo'
														)}
														type="text"
														className="input-primary"
														onChangeLocalValue={handleChangeModel}
														validations={[
															{
																rule: (value) => value ? value?.match(MODELCARREGEX) : true,
																message: 'p.label.title.modeloFormatoIncorrecto'
															}
														]}
													/>
													<label className="d-flex">
														<span className="text-inline">
															<FormattedMessage id="p.vehicle.brand" defaultMessage="Marca" />
														</span>
														<select
															name="idMarcaVehiculo"
															id="idMarcaVehiculo"
															className="input-label-style"
															onChange={handleChangeSelect}
														>
															<option value="">
																<FormattedMessage
																	id="p.selection.brand"
																	defaultMessage="Seleccione una marca"
																/>
															</option>
															{vehiclesBrand.map((item) => (
																<option
																	key={item.idMarcaVehiculo}
																	value={JSON.stringify({ ...item })}
																>
																	{item.nombre} - {item.codigo}
																</option>
															))}
														</select>
													</label>

													<InputFieldResponsive
														name="fechaTecnicoMecanica"
														labelText={formatterText(
															'p.date.technicians.revision',
															'Fecha revisión técnico mecánica'
														)}
														placeholder="Ingrese la revisión técnico mecánica"
														type="date"
														className="input-default-3c"
														onChangeLocalValue={handleChangeRelative}
														min={HandleShortDate()}
														max={HandleShortDate(50)}
													/>
													<InputFieldResponsive
														name="fechaSoap"
														labelText={formatterText('p.date.soat', 'Fecha Soat')}
														placeholder={formatterText(
															'p.add.date.soat',
															'Ingrese la fecha del soat'
														)}
														type="date"
														className="input-primary"
														onChangeLocalValue={handleChangeRelative}
														min={HandleShortDate()}
														max={HandleShortDate(50)}
													/>

													<InputFieldResponsive
														name="fechaRunt"
														labelText={formatterText('p.date.runt', 'Fecha RUNT')}
														placeholder={formatterText('p.add.date.runt', 'Ingrese la fecha runt')}
														type="date"
														className="input-primary"
														onChangeLocalValue={handleChangeRelative}
														max={HandleShortDate()}
														min={HandleShortDate(-50)}
													/>

													<InputFieldResponsive
														name="runt"
														labelText={formatterText('p.code.runt', 'Código RUNT')}
														placeholder={formatterText('p.add.code.runt', 'Ingrese el codigo runt')}
														type="text"
														className="input-primary"
														onChangeLocalValue={handleChangeRelative}
														maxLength="100"
													/>
												</section>
												<section className="form-responsive-container-buttons">
													<input
														onClick={handleAddFamily}
														type="button"
														className="btn-primary"
														value={formatterText('p.add.vehicle')}
													/>
												</section>
											</>
										)}
									</div>
								)}
							</FormizStep>
							<FormizStep name="step3" label="p.associations">
								<UnityBusiness
									data={primaryMinimalTable}
									setterFunction={setPrimaryMinimalTable}
									// type="create"
								/>
								<ClienteSucursal data={secondMinimalTable} setterFunction={setSecondMinimalTable} />
								<AssociateCoverage data={thirdMinimalTable} setterFunction={setThirdMinimalTable} />
							</FormizStep>
							<FormizStep name="step4" label="p.charge.archvie">
								<Attachments
									currentFiles={archivos}
									setCurrentFiles={setCurrentFiles}
									isEdited={false}
									showParameters={true}
								/>
							</FormizStep>
						</section>
						<section className="form-responsive-container-buttons">
							<button type="submit" className="btn-primary">
								<FormattedMessage
									id="alert.button.confirm.general"
									defaultMessage="Guardar cambios"
								/>
							</button>
							<button className="input-cancel" onClick={() => navigate(paths.technical)}>
								<FormattedMessage id="btn.cancel" defaultMessage="Cancelar" />
							</button>
						</section>
					</div>
				</form>
			</Formiz>
		</div>
	);
}
export default CreateTechnicalCompnent;
