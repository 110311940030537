import React from 'react';

// Import Contexts
import { SearchWrapper } from 'context/SearchContext';

// Import Components
import CreateEmployeeComponent from './CreateEmployee.component';

const CreateEmployee = () => {
	return (
		<SearchWrapper>
			<CreateEmployeeComponent />
		</SearchWrapper>
	);
};

export default CreateEmployee;
