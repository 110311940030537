import CustomAlert from 'components/CustomAlert';
import Cookie from 'js-cookie';
import { formatterText } from './useLangv2';

export default function useRedirect(auth) {
	const locationLogin = new URL(window.location.href).hash.includes('login');

	if (!locationLogin) {
		if (sessionStorage.getItem('token')) {
			const userAux = {
				estado: -1,
				TipoAcceso: -1
			};
			const userData = JSON.parse(localStorage.getItem('userData')) || userAux;
			const state = userData.estado;
			const accessType = userData.tipoAcceso;
			return _isActiveUser(state, accessType, auth);
		} else {
			window.location.href = '#/login';
			// console.log("Redirect al login");
			return true;
		}
	}
}

function CustomMessage(descriptionLabel, auth) {
	CustomAlert('confirm_msg', {
		icon: 'warning',
		title: 'Atención',
		text: descriptionLabel,
		confirmButtonText: 'Regresar',
		allowOutsideClick: false,
		executeFunction: () => auth.logout()
	});
}

/**
 * @private
 * Verifica el tipo de aceso que tiene el usuario
 * @param {*} accessType
 * @param {*} auth
 * @returns boolean
 */
function _typeAccess(accessType, auth) {
	if (parseInt(accessType) === 1 || parseInt(accessType) === 3) {
		return false;
	} else {
		CustomMessage(formatterText('p.alert.do.not.have.access'), auth);
		return true;
	}
}

/**
 * @private
 * Compprueba si existen roles antes de
 * comprobar el tipo de acceso
 * @param {*} accessType
 * @param {*} auth
 * @returns boolean
 */
function _roles(accessType, auth) {
	if (parseInt(Cookie.get('rolSize')) > 0) {
		return _typeAccess(accessType, auth);
	} else {
		CustomMessage(formatterText('p.alert.do.not.have.access'), auth);
		return true;
	}
}

/**
 * @private
 * Verifica si el usuario esta activo
 * antes de confirmar roles
 * @param {*} state
 * @param {*} accessType
 * @param {*} auth
 * @return boolean
 */
function _isActiveUser(state, accessType, auth) {
	if (parseInt(state) === 1) {
		return _roles(accessType, auth);
	} else {
		CustomMessage(formatterText('p.alert.user.inactive'), auth);
		return true;
	}
}

export const refresh = (path) => {
	window.location.href = `#${path}`;
};
