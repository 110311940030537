import useAxios from 'hooks/useAxios';
import SortData from 'components/utils/SortData';
import { useSeachContext } from 'context/SearchContext';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import { useEffect, useState } from 'react';
export default function ProductTableViewModel() {
	const { RequestUseCase, endpoints } = useAxios();

	const {
		loading,
		error,
		toggleLoading,
		toggleError,
		handleClick,
		displayMessage,
		displayLoading
	} = useGetData();

	const { setSearchResults, setDataTable } = useSeachContext();

	const [product, setProducts] = useState(null);
	// use Hook of language v2
	const { formatterText } = useLangv2();

	const titles = [
		formatterText('table.title.productName', 'Nombre producto'),
		'Sku',
		formatterText('table.title.barCode', 'Código de barras'),
		formatterText('table.title.description', 'Descripción'),
		formatterText('table.title.subCategory', 'Subcategoría'),
		formatterText('table.title.provider', 'Proveedor'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.actions', 'Acciones')
	];

	const handleDeleteItem = (rowId) => {
		const deleteItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.delete(endpoints.products.deleteProduct(rowId))
				.then((res) => {
					const newArray = [];
					res.forEach((item) => {
						handleStructureItems(newArray, item);
					});
					const sortedArray = SortData(newArray, 'asc');
					setProducts(sortedArray);
					setSearchResults(sortedArray);
					setDataTable(sortedArray);
					resolve(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return deleteItemPromise;
	};

	const handleEditStateItem = (body) => {
		const updateItemPromise = new Promise((resolve, reject) => {
			RequestUseCase.put(endpoints.products.updateProduct, body)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
		return updateItemPromise;
	};

	const handleStructureItems = (newArray, item) => {
		// iterate item to get the the items who have codigoBarras and put a comma
		// each time the item have a codigoBarras aditional
		let codigoBarras = '';
		let arrayProviderName = '';
		if (item.codigoBarras) {
			item.codigoBarras.forEach((barcode) => {
				codigoBarras += barcode.codigo + ', ';
			});
		}
		if (item.precioProductoProveedor.length > 0) {
			item.precioProductoProveedor.forEach((elem) => {
				arrayProviderName += `${elem.idProveedor.nombreRazonSocial}, `;
			});
		}

		newArray.push({
			id: item.idProducto,
			nombre: item.nombre,
			sku: item.sku,
			codigoBarras: codigoBarras.slice(0, -2),
			descripcion: item.descripcion,
			subCategoriaProducto: item.subCategoriaProducto?.nombre,
			provedor: arrayProviderName,
			estado: item.estado,
			objeto: { ...item }
		});
	};

	// función para cargar datos
	const getDataTable = () => {
		// show loading
		toggleLoading(true);

		RequestUseCase.get(endpoints.products.getAllProduct)
			.then((data) => {
				const newArray = [];
				data.forEach((item) => {
					handleStructureItems(newArray, item);
				});
				const sortedArray = SortData(newArray, 'asc');
				// console.log(sortedArray);
				setProducts(sortedArray);
				setSearchResults(sortedArray);
				setDataTable(sortedArray);
				// show loading
				toggleLoading(false);
			})
			.catch((err) => {
				console.log(err);
				// mostrar error
				toggleError(!error);
				handleClick();
			});
	};

	useEffect(() => {
		getDataTable();
	}, []);
	return {
		displayLoading,
		displayMessage,
		error,
		getDataTable,
		handleDeleteItem,
		handleEditStateItem,
		loading,
		product,
		titles
	};
}
