import React, { createContext, useContext } from 'react';
import { ContextAlertProps } from './ContextAlert.props';
import ContextAlertViewModel from './ContextAlert.viewModel';

export const ContextUse = createContext();

export const useAlertContext = () => {
	return useContext(ContextUse);
};
export function ContextAlert({ children }) {
	const { state, _SetDispatch, DeterminateTypeUser, getRecordsByQuery } = ContextAlertViewModel();
	return (
		<ContextUse.Provider
			value={{
				state,
				_SetDispatch,
				DeterminateTypeUser,
				getRecordsByQuery
			}}
		>
			{children}
		</ContextUse.Provider>
	);
}

ContextAlert.propTypes = ContextAlertProps;
