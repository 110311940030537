import React from 'react';

import { SearchWrapper } from 'context/SearchContext';
import EditSector from './EditSector.component';

export const EditSectorWithSearch = (props) => (
	<SearchWrapper>
		<EditSector {...props} />
	</SearchWrapper>
);

export default EditSectorWithSearch;
