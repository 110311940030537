import React from 'react';
// Import Libs
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

export default function SideNavBtn({ pathToGo, idLang, defaultMessage }) {
	const handleAuxClick = (e) => {
		if (e.button === 1) {
			e.preventDefault();
			window.open(`#${pathToGo}`, '_blank');
		}
	};
	return (
		<>
			<li className="dropdown__option">
				<NavLink
					to={pathToGo}
					className={({ isActive }) => (isActive ? 'active' : 'notActive')}
					onAuxClick={(e) => handleAuxClick(e)}
					onScrollCapture={(e) => e.preventDefault()}
				>
					<FormattedMessage id={idLang} defaultMessage={defaultMessage} />
				</NavLink>
			</li>
			<hr className="sline" />
		</>
	);
}

SideNavBtn.propTypes = {
	pathToGo: PropTypes.string.isRequired,
	idLang: PropTypes.string.isRequired,
	defaultMessage: PropTypes.string.isRequired
};
