import React from 'react';
// Import Contexts
// Import Hooks
// Import Components
import MultiTableMinimal from 'common/minimalTables/MultiTableMinimal';
import DatePicker from 'components/pickers/DatePicker';
import { Modal } from 'react-responsive-modal';
import AddTimeZone from '../actions/AddTimeZone';
import ModalAssignTechnical from '../modals/ModalAssignTechnical';
import ModalTechnicalTZ from '../modals/ModalTechnicalTZ';
// Import libs
import { FormattedMessage } from 'react-intl';
// Import Services
import paths from 'services/paths';
// import Models
// import Styles
import 'react-tabs/style/react-tabs.css';
import CloneScheduleViewModel from './CloneSchedule.viewModel';

const CloneScheduleComponent = () => {
	const {
		timeZoneSelected,
		setFormData,
		formData,
		handleSubmit,
		handleNameText,
		titlesTableTimeZone,
		dialog,
		closeDialog,
		loadingProgress,
		DisplayProgress,
		openModalTechnicalToAssign,
		onCloseTechnicalToAssign,
		openModalTechnicalToReview,
		onCloseTechnicalToReview,
		setTechnicalsToAssing,
		idAgendaToClone,
		setIdAgendaToClone,
		body,
		setBody,
		formatterText,
		navigate
	} = CloneScheduleViewModel();
	return (
		<>
			{loadingProgress ? (
				<form onSubmit={handleSubmit} className="container-wrapForm-content-no-border">
					<section className="wrapForm w100-container-complete">
						<label className="wrapForm__label">
							<span className="warpForm-text">
								<FormattedMessage id="text.shedule.create.name" defaultMessage="Nombre agenda" />
							</span>
							<input
								className="input-primary"
								type="text"
								name="nombre"
								placeholder={formatterText('text.shedule.create.name', 'Nombre agenda')}
								onChange={handleNameText}
								// value={`Clone ${formData.nombre}`}
								maxLength="100"
							/>
						</label>
						<label>
							<span className="warpForm-text">
								<FormattedMessage
									id="text.shedule.create.startdate"
									defaultMessage={formatterText('text.shedule.create.startdate', 'Fecha de inicio')}
								/>
							</span>
							<DatePicker
								name="fechaInicio"
								selectValue={formData.fechaInicio}
								setterFunction={setFormData}
								placeholder={formatterText('text.shedule.create.startdate', 'Fecha de inicio')}
								noIcon={true}
							/>
						</label>

						<label>
							<span className="warpForm-text">
								<FormattedMessage
									id="text.shedule.create.enddate"
									defaultMessage="Fecha de finalización"
								/>
							</span>
							<DatePicker
								name="fechaFin"
								selectValue={formData.fechaFin}
								setterFunction={setFormData}
								placeholder={formatterText('text.shedule.create.enddate', 'Fecha de finalización')}
								noIcon={true}
								min={formData.fechaInicio}
							/>
						</label>
					</section>

					{/* Franjas horaras config */}
					<AddTimeZone />

					<section className="form-responsive-container-information">
						<span className="title-table">
							<FormattedMessage
								id="text.shedule.create.timezone.associated"
								defaultMessage="Franjas horarias del día"
							/>
						</span>
						<MultiTableMinimal
							titles={titlesTableTimeZone}
							data={timeZoneSelected}
							type="franjasHorariaSchedule"
							dialog={dialog}
							closeDialog={closeDialog}
							canSearch={true}
						/>
					</section>

					<section className="form-responsive-container-buttons">
						<button type="submit" className="btn-primary">
							<FormattedMessage id="btn.save" defaultMessage="Guardar" />
						</button>
						<button className="input-cancel" onClick={() => navigate(paths.schedule)}>
							<FormattedMessage id="btn.cancel" defaultMessage="Cancelar" />
						</button>
					</section>
				</form>
			) : (
				<DisplayProgress />
			)}
			{/* techinical to assign */}
			<Modal
				open={openModalTechnicalToAssign}
				onClose={onCloseTechnicalToAssign}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal-90'
				}}
			>
				<ModalAssignTechnical />
			</Modal>
			{/* techinical to review */}
			<Modal
				open={openModalTechnicalToReview}
				onClose={() => {
					onCloseTechnicalToReview();
					setTechnicalsToAssing([]);
				}}
				center
				classNames={{
					overlay: 'customOverlay',
					modal: 'customModal-90'
				}}
			>
				<ModalTechnicalTZ
					idAgendaToClone={idAgendaToClone}
					setIdAgendaToClone={setIdAgendaToClone}
					body={body}
					setBody={setBody}
				/>
			</Modal>
		</>
	);
};

export default CloneScheduleComponent;
