import React from 'react';

// Import Libs
import { Link } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

// Import Components
import ExportJsonFile from 'components/utils/ExportJsonFile';
import Search from 'common/Search';

// Import Paths
import paths from 'services/paths';

// Import ViewModel
import TechnicalTableViewModel from './TechnicalTable.viewModel';

const ProductsTableComponent = () => {
	const {
		formatterText,
		permissionsAccess,
		permittedActions,
		searchResults,
		selectedTab,
		setSearch,
		setSelectedTab,
		tabs
	} = TechnicalTableViewModel();

	return (
		<section className="table-container">
			<section className="userOptions">
				{permittedActions.consultar && (
					<Search
						placeholder={formatterText('placeholder.search.multi.items', 'Buscar por palabra')}
						width="50%"
					/>
				)}
				{permittedActions.crear && (
					<Link style={{ textDecoration: 'none', alignSelf: 'center' }} to={selectedTab.route}>
						<button className="btn-add">{selectedTab.nombreLink}</button>
					</Link>
				)}
				{selectedTab.exportExcel && (
					<>
						{selectedTab.id === paths.vehicleTechnical ? (
							<ExportJsonFile
								moduleName={'TECNICOS'}
								userName={localStorage.getItem('userData').usuario || 'ADMIN'}
								dataTable={searchResults}
							/>
						) : (
							<ExportJsonFile
								moduleName={selectedTab.id.replace('/', '')}
								userName={JSON.parse(localStorage.getItem('userData')).usuario || 'ADMIN'}
								dataTable={searchResults}
							/>
						)}
					</>
				)}
			</section>
			<Tabs selectedTabClassName="tab-option--selected">
				<TabList className="tab-list">
					{tabs.map((tab, index) => (
						<Tab
							onClick={() => {
								setSelectedTab(tab);
								setSearch('');
								localStorage.setItem('indexTab', index.toString());
								permissionsAccess(tab.idPermiso);
							}}
							key={index}
							className="new-tab-option"
						>
							{tab.nombre}
						</Tab>
					))}
				</TabList>
				{permittedActions.consultar &&
					tabs.map((tab, index) => <TabPanel key={index}>{tab.componente}</TabPanel>)}
			</Tabs>
		</section>
	);
};

export default ProductsTableComponent;
