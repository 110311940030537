import { useEffect, useState } from 'react';

// Import Libs
import { useForm } from '@formiz/core';
import { useNavigate } from 'react-router-dom';
import dateFormat from 'dateformat';
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';

// Import Components
import CustomAlert from 'components/CustomAlert';
import HandleOnError from 'common/validators/HandleOnError';

// Import Contexts
import { useSeachContext } from 'context/SearchContext';

// Import Hooks
import useAxios from 'hooks/useAxios';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';

// Import Paths
import paths from 'services/paths';

// Import Constants
import { CARREGEX, MODELCARREGEX } from 'common/validators/Regex';

const MODEL = {
	placa: '',
	idMarcaVehiculo: '',
	modelo: '',
	fechaTecnicoMecanica: '',
	fechaSoap: '',
	fechaRunt: '',
	runt: ''
};

export default function CreateTechnicalViewModel() {
	// use Hook of language v2
	const { formatterText, noFilledContent, customSB } = useLangv2();
	// title table
	const TITLE_TABLE = [
		formatterText('technicians.table.title.license.vehicle'),
		formatterText('p.vehicle.model'),
		formatterText('p.date.soat'),
		formatterText('p.date.runt'),
		formatterText('p.date.technicians.revision'),
		formatterText('table.title.runt.date'),
		formatterText('table.actions')
	];

	// show or hide the section
	const [show, setShow] = useState(true);
	const { loading, toggleLoading } = useGetData();
	const { fetchData, RequestUseCase, endpoints } = useAxios();
	const navigate = useNavigate();
	const [archivos, setCurrentFiles] = useState([]);
	const [formData, setFormData] = useState([]);
	const [employees, setEmployees] = useState([]);
	const [thirdParties, setThirdParties] = useState([]);
	const [categoryLicense, setCategoryLicense] = useState([]);
	const [idEmployeeAux, setIdEmployeeAux] = useState(0);
	const [idThirdPartyAux, setIdThirdPartyAux] = useState(0);
	const [idUser, setIdUser] = useState(0);

	const [vehicles, setVehicles] = useState([]);
	const [currentVehicle, setCurrentVehicle] = useState(MODEL);
	const [vehiclesBrand, setVehiclesBrand] = useState([]);
	const [messageError, setMessageError] = useState('');
	const [messageErrorPlaca, setMessageErrorPlaca] = useState('');
	const now = new Date();
	const myForm = useForm();

	const {
		secondMinimalTable,
		setSecondMinimalTable,
		thirdMinimalTable,
		setThirdMinimalTable,
		primaryMinimalTable,
		setPrimaryMinimalTable
	} = useSeachContext();

	const handleAddFamily = () => {
		if (
			currentVehicle.placa !== '' &&
			currentVehicle.idMarcaVehiculo !== '' &&
			currentVehicle.modelo !== '' &&
			currentVehicle.fechaTecnicoMecanica !== '' &&
			currentVehicle.fechaSoap !== '' &&
			currentVehicle.fechaRunt !== '' &&
			currentVehicle.runt !== ''
		) {
			// find in vehicles arr if the placa is already saved
			const findPlaca = vehicles.find((item) => item.placa === currentVehicle.placa);
			if (findPlaca) {
				customSB(
					'warning',
					'snackbar.warning.repeated.vehicle',
					'La placa ya existe, por favor ingrese una diferente.'
				);
			} else {
				setVehicles([...vehicles, currentVehicle]);
				setCurrentVehicle(MODEL);
				setShow(false);
				setTimeout(() => {
					setShow(true);
				}, 100);
			}
		} else {
			noFilledContent();
		}
	};

	const handleChangeRelative = (e) => {
		if (e.target.name !== 'placa') {
			setCurrentVehicle({ ...currentVehicle, [e.target.name]: e.target.value });
		} else {
			console.log(e.target.value.match(CARREGEX));
			if (e.target.value.match(CARREGEX)) {
				setCurrentVehicle({
					...currentVehicle,
					[e.target.name]: e.target.value
				});
			}
		}
	};

	const handleChangeModel = (e) => {
		if (e.target.value.match(MODELCARREGEX)) {
			setCurrentVehicle({ ...currentVehicle, [e.target.name]: e.target.value });
		}
	};

	const handleChangeSelect = (selectedOption) => {
		setCurrentVehicle({
			...currentVehicle,
			[selectedOption.target.name]:
				selectedOption.target.value === ''
					? selectedOption.target.value
					: JSON.parse(selectedOption.target.value)
		});
	};

	const getMarcasVehiculos = () => {
		RequestUseCase.get(endpoints.institutions.brandVehicles)
			.then((res) => {
				setVehiclesBrand(res);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const saveVehicles = (tecnicoInfo, resolve, reject) => {
		if (vehicles.length > 0) {
			const vehiclesPromises = vehicles.map(
				(vehicle) =>
					new Promise((resolve, reject) => {
						const data = {
							idVehiculo: null,
							placa: vehicle.placa,
							idMarcaVehiculo: {
								...vehicle.idMarcaVehiculo
							},
							idTecnico: {
								...tecnicoInfo
							},
							modelo: vehicle.modelo,
							fechaTecnicoMecanica: dateFormat(vehicle.fechaTecnicoMecanica, 'isoDateTime'),
							fechaSoap: dateFormat(vehicle.fechaSoap, 'isoDateTime'),
							fechaRunt: dateFormat(vehicle.fechaRunt, 'isoDateTime'),
							runt: vehicle.runt,
							estado: 1,
							fechaCreacion: null,
							fechaModificacion: null,
							usuarioCreacion: Cookie.get('userId'),
							usuarioModificacion: null
						};
						RequestUseCase.post(endpoints.institutions.saveVehicle, data)
							.then((res) => {
								resolve(res);
							})
							.catch((err) => {
								reject(err);
							});
					})
			);
			Promise.all(vehiclesPromises)
				.then(() => {
					postCategoryService(tecnicoInfo.idTecnico, resolve, reject);
				})
				.catch(() => {
					reject((err) => {
						if (err.response.status === 412) {
							HandleOnError(err.response.data);
							console.log(err.response.data);
						} else {
							console.log('error');
							HandleOnError(formatterText('p.label.title.tecnicoCreadoSinVehiculosAsociados'));
						}
						console.log(err);
					});
				});
		} else {
			console.log('No hay vehiculos');
			postCategoryService(tecnicoInfo.idTecnico, resolve, reject);
		}
	};

	const postCategoryService = (id, resolve, reject) => {
		const promises = selectValues.map(
			(item) =>
				new Promise((resolve, reject) => {
					fetchData({
						url: endpoints.associateDataTechnical.addCategoryService,
						method: 'post',
						body: {
							idTecnico: id,
							idCategoriaServicio: item.value,
							fechaRegistro: new Date(),
							usuarioCreacion: Cookie.get('idUsuario')
						}
					})
						.then((res) => {
							resolve(res);
						})
						.catch((err) => {
							reject(err);
						});
				})
		);

		Promise.all(promises)
			.then(() => {
				associateUnityBusiness(id, resolve, reject);
			})
			.catch(() => {
				reject((err) => {
					if (err.response.status === 412) {
						HandleOnError(err.response.data);
						console.log(err.response.data);
					} else {
						console.log('error');
						HandleOnError(formatterText('p.label.title.tecnicoCreadoSinAsociaciones'));
					}
					console.log(err);
				});
			});
	};

	//SPRINT 8 -- TODAS LAS ASOCIACIONES POST ASOCIACIONES PREVIAS
	const associateUnityBusiness = (id, resolve, reject) => {
		if (primaryMinimalTable.length > 0) {
			// eslint-disable-next-line array-callback-return
			const promises = primaryMinimalTable.map((item) => {
				const data = {
					idUsuario: idUser,
					idUnidadNegocio: item.id
				};
				RequestUseCase.post(endpoints.associateDataTechnical.addUnityBusiness, data)
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			});
			Promise.all(promises)
				.then(() => {
					associateSucursal(id, resolve, reject);
				})
				.catch(() => {
					reject((err) => {
						if (err.response.status === 412) {
							HandleOnError(err.response.data);
							console.log(err.response.data);
						} else {
							console.log('error');
							HandleOnError(formatterText('p.label.title.tecnicoCreadoSinAsociaciones'));
						}
						console.log(err);
					});
				});
		} else {
			associateSucursal(id, resolve, reject);
		}
	};

	const associateSucursal = (id, resolve, reject) => {
		if (secondMinimalTable.length > 0) {
			// eslint-disable-next-line array-callback-return
			const promises = secondMinimalTable.map((item) => {
				const data = {
					idUsuario: idUser,
					idSucursal: item.id
				};
				RequestUseCase.post(endpoints.associateDataTechnical.addSucursal, data)
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			});
			Promise.all(promises)
				.then(() => {
					postCoverage(id, resolve, reject);
				})
				.catch(() => {
					reject((err) => {
						if (err.response.status === 412) {
							HandleOnError(err.response.data);
							console.log(err.response.data);
						} else {
							console.log('error');
							HandleOnError(formatterText('p.label.title.tecnicoCreadoSinAsociaciones'));
						}
						console.log(err);
					});
				});
		} else {
			postCoverage(id, resolve, reject);
		}
	};

	const postCoverage = (id, resolve, reject) => {
		const data = thirdMinimalTable;
		if (data.length > 0) {
			const promises = data.map(
				(item) =>
					new Promise((resolve, reject) => {
						const data = {
							idMunicipio: item.citySucursal.value,
							idTecnico: id
						};
						RequestUseCase.post(endpoints.associateDataTechnical.addCoverage, data)
							.then((res) => {
								resolve(res);
							})
							.catch((err) => {
								reject(err);
							});
					})
			);
			Promise.all(promises)
				.then(() => {
					resolve(uploadFiles(id));
				})
				.catch(() => {
					reject((err) => {
						if (err.response.status === 412) {
							HandleOnError(err.response.data);
							console.log(err.response.data);
						} else {
							console.log('error');
							HandleOnError(formatterText('p.label.title.tecnicoCreadoConVehiculosSinCategorias'));
						}
						console.log(err);
					});
				});
		} else {
			resolve(uploadFiles(id));
		}
	};

	useEffect(() => {
		getEmployeesThirdParties();
		getAllcategoryLicenses();
		getMarcasVehiculos();
	}, []);

	const getEmployeesThirdParties = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.technical.getEmployeesThirdPartiesNA).then((data) => {
			const arrayEmployee = [];
			const arrayThirdParties = [];

			data.forEach((item) => {
				let valueText = '';
				if (item.tipoEntidad === 1) {
					valueText = `${item.primerNombre} ${item.primerApellido} - ${item.identificacion}`;
					arrayEmployee.push({
						label: valueText,
						value: parseInt(item.id),
						idUser: parseInt(item.idUsuario),
						objeto: { ...item }
					});
				} else {
					valueText = `${item.primerNombre} - ${item.identificacion}`;
					arrayThirdParties.push({
						label: valueText,
						value: parseInt(item.id),
						idUser: parseInt(item.idUsuario),
						objeto: { ...item }
					});
				}
			});

			setThirdParties(arrayThirdParties);
			setEmployees(arrayEmployee);
			toggleLoading(false);
		});
	};

	const getAllcategoryLicenses = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.institutions.categoryLicenses)
			.then((response) => {
				const ArrayMunicipios = [];
				response.forEach((item) => {
					ArrayMunicipios.push({
						label: item.nombre,
						value: item.idCategoriaLicenciaConduccion
					});
				});
				setCategoryLicense(ArrayMunicipios);
				toggleLoading(false);
			})
			.catch((error) => {
				console.log(error);
				toggleLoading(false);
			});
	};


	const handleSubmit = (values) => {
		let data = {
			idVehiculo: null,
			restriccionLicencia: values.restriccionLicencia,
			licenciaFechaExpiracion: dateFormat(values.licenciaFechaExpiracion, 'isoDateTime'),
			idCategoriaLicenciaConduccion: {
				idCategoriaLicenciaConduccion: values.idCategoriaLicenciaConduccion
			},
			idTercero: null,
			idEmpleado: null,
			estado: 1,
			fechaCreacion: null,
			fechaModificacion: null,
			usuarioCreacion: Cookie.get('userId'),
			usuarioModificacion: null
		};

		if (idThirdPartyAux !== 0) {
			data = {
				...data,
				idTercero: {
					idTercero: idThirdPartyAux
				}
			};
		} else {
			data = {
				...data,
				idEmpleado: {
					idEmpleado: idEmployeeAux
				}
			};
		}

		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.create.general', 'Se va a crear un nuevo registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					RequestUseCase.post(endpoints.technical.addTechnical, data)
						.then((res) => {
							saveVehicles(res, resolve, reject);
						})
						.catch((err) => {
							if (err.response.status === 412) {
								HandleOnError(err.response.data);
								console.log(err.response.data);
							} else {
								console.log('error');
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
							console.log(err);
						});
				});
			}
		});
	};

	const [selectedOption, setSelectedOption] = useState(null);

	const setTechnicalInfo = (selectedOption) => {
		console.log(selectedOption);
		if (selectedOption.objeto.tipoEntidad === 1) {
			setFormData({
				idEmpleado: parseInt(selectedOption.objeto.id),
				primerNombre: selectedOption.objeto.primerNombre,
				segundoNombre: selectedOption.objeto.segundoNombre,
				primerApellido: selectedOption.objeto.primerApellido,
				segundoApellido: selectedOption.objeto.segundoApellido,
				// tipoDocumento: `${selectedOption.objeto.idTipoDocumento.abreviatura} - ${selectedOption.objeto.idTipoDocumento.nombre}`,
				tipoDocumento: selectedOption.objeto.tipoDocumento,
				identificacionEmpleado: selectedOption.objeto.identificacion,
				genero: selectedOption.objeto.genero
			});
			// save the id of the employee
			setIdEmployeeAux(selectedOption.value);
			setIdUser(selectedOption.idUser);
			setIdThirdPartyAux(0);
		} else {
			setFormData({
				idEmpleado: parseInt(selectedOption.objeto.id),
				primerNombre: selectedOption.objeto.primerNombre,
				segundoNombre: '',
				primerApellido: '',
				segundoApellido: '',
				identificacionEmpleado: selectedOption.objeto.identificacion,
				// tipoDocumento: `${selectedOption.objeto.idTipoDocumento.abreviatura} - ${selectedOption.objeto.idTipoDocumento.nombre}`,
				tipoDocumento: selectedOption.objeto.tipoDocumento,
				genero: ''
			});
			// save the id of the third party
			setIdThirdPartyAux(selectedOption.value);
			setIdUser(selectedOption.idUser);
			setIdEmployeeAux(0);
		}
	};

	/* Group select */
	const groupedOptions = [
		{
			label: 'Empleados',
			options: employees
		},
		{
			label: 'Terceros',
			options: thirdParties
		}
	];

	const [selectValues, setSelectValues] = useState([]);
	const [categoryService, setCategoryService] = useState({});
	useEffect(() => {
		getCategoryService();
		setPrimaryMinimalTable([]);
		setSecondMinimalTable([]);
		setThirdMinimalTable([]);
	}, []);

	//Obtener los datos de internet 1 vez
	const getCategoryService = () => {
		RequestUseCase.get(endpoints.serviceCategory.getAllServiceCategory)
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					if (item.estado === 1) {
						newArray.push({
							value: item.idCategoriaServicio,
							label: `${item.nombre} - ${item.codigo}`,
							isFixed: true
						});
					}
				});
				setCategoryService(newArray);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleChangeMulti = (val) => {
		setSelectValues(val);
	};
	const uploadFiles = (id) => {
		return new Promise((resolve, reject) => {
			if (archivos.length > 0) {
				fetchData({
					url: endpoints.UploadFiles.save,
					method: 'post',
					body: {
						idOrigen: id,
						idTipoOrigenArchivo: 6, // 6 = Tecnico
						archivos
					}
				})
					.then(() => {
						resolve(
							CustomAlert('confirm_msg', {
								icon: 'success',
								title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
								text: formatterText(
									'alert.message.confirm.created.general',
									'El registro se ha creado correctamente'
								),
								confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
								allowOutsideClick: false,
								executeFunction: () => navigate(paths.technical)
							})
						);
					})
					.catch((err) => {
						reject(err);
					});
			} else {
				resolve(
					CustomAlert('confirm_msg', {
						icon: 'success',
						title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
						text: formatterText(
							'alert.message.confirm.created.general',
							'El registro se ha creado correctamente'
						),
						confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
						allowOutsideClick: false,
						executeFunction: () => navigate(paths.technical)
					})
				);
			}
		});
	};

	const deleteVehicule = (vehicle) => {
		const index = vehicles.indexOf(vehicle);
		const array = [...vehicles];
		if (index > -1) {
			// only splice array when item is found
			array.splice(index, 1);
			setVehicles(array); // 2nd parameter means remove one item only
		}
	};

	return {
		archivos,
		categoryLicense,
		categoryService,
		deleteVehicule,
		formatterText,
		formData,
		groupedOptions,
		handleAddFamily,
		handleChangeModel,
		handleChangeMulti,
		handleChangeRelative,
		handleChangeSelect,
		handleSubmit,
		loading,
		messageError,
		messageErrorPlaca,
		myForm,
		navigate,
		now,
		primaryMinimalTable,
		secondMinimalTable,
		selectedOption,
		selectValues,
		setCurrentFiles,
		setPrimaryMinimalTable,
		setSecondMinimalTable,
		setSelectedOption,
		setTechnicalInfo,
		setThirdMinimalTable,
		show,
		thirdMinimalTable,
		TITLE_TABLE,
		vehicles,
		vehiclesBrand
	};
}
