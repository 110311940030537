import dateFormat from 'dateformat';
import useAxios from 'hooks/useAxios';
import _ from 'lodash';
import { createContext, useEffect, useReducer, useState } from 'react';
import endpoints from 'services/api';
import { alertsByUser, alertsOptional, getCountAlertPanel } from 'services/api/alerts';
import { countNotificationsByUser, notificationsByUser } from 'services/api/notifications';

export const ContextUse = createContext();

export default function ContextAlertViewModel() {
	const { COOKIE_USER, RequestUseCase } = useAxios();

	const [indexTabServices] = useState(
		parseInt(JSON.parse(localStorage.getItem('indexNotification')) || 0)
	);
	const [timeRefresh, setTimeRefesh] = useState(-1);
	const getTimeToAutoRefresh = () => {
		RequestUseCase.get(endpoints.notificationsTable.getTimeToAutoRefresh)
			.then((data) => {
				setTimeRefesh(data);
			})
			.catch((err) => {
				clearInterval(timeRefresh);
				console.log(err);
			});
	};
	useEffect(() => {
		getTimeToAutoRefresh();
	}, []);
	useEffect(() => {
		if (timeRefresh > -1) {
			setInterval(DeterminateTypeUser, timeRefresh * 1000 * 60); // timeResfresh esta parametrizado por minutos
		}
	}, [timeRefresh]);

	const hashIndexTabServices = {
		0: 'Alert',
		1: 'Notification'
	};

	const initialState = {
		currentNotification: [],
		currentAlert: [],
		filteredNotification: [],
		filteredAlert: [],
		textToFilter: '',
		currentCountNotification: 0,
		currentCountAlert: 0,
		currentPageNotification: 0,
		currentPageAlert: 0,
		tabSelected: hashIndexTabServices[indexTabServices],
		rowsPerPage: 25,
		notificationPerPage: 25
	};

	const hashReducer = {
		CURRENT_NOTIFICATION: 'currentNotification',
		CURRENT_ALERT: 'currentAlert',
		CURRENT_COUNT_NOTIFICATION: 'currentCountNotification',
		CURRENT_COUNT_ALERT: 'currentCountAlert',
		CURRENT_PAGE_NOTIFICATION: 'currentPageNotification',
		CURRENT_PAGE_ALERT: 'currentPageAlert',
		TAB_SELECTED: 'tabSelected',
		ROWS_PER_PAGE: 'rowsPerPage',
		NOT_PER_PAGE: 'notificationPerPage',
		TEXT_TO_FILTER: 'textToFilter',
		FILTERED_NOTIFICATION: 'filteredNotification',
		FILTERED_ALERT: 'filteredAlert'
	};

	const reducer = (state, action) => {
		return { ...state, [hashReducer[action.type]]: action.payload };
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	const _SetDispatch = (data) => {
		dispatch({
			type: data.type,
			payload: data.payload
		});
	};

	const DeterminateTypeUser = () => {
		getDataByType();
	};

	const getTotalRecords = () => {
		hashCount[state.tabSelected]({
			idUser: `${COOKIE_USER}`,
			type: state.tabSelected
		}).then((data) => {
			_SetDispatch({
				payload: data,
				type: hashCurrentCount[state.tabSelected]
			});
		});
	};

	const getRecords = () => {
		hashEndpoint[state.tabSelected]({
			idUser: `${COOKIE_USER}`,
			registersPerPage: `${
				state.tabSelected === 'Alert' ? state.rowsPerPage : state.notificationPerPage
			}`,
			currentPage: `${hashCurrentPage[state.tabSelected]}`
		})
			.then((data) => {
				filter(FormmatedTable(data));
				_SetDispatch({
					payload: FormmatedTable(data),
					type: hashTabSave[state.tabSelected]
				});
			})
			.catch((err) => console.log(err));
	};

	const getRecordsByQuery = (DATA) => {
		alertsOptional(
			{
				idUser: `${COOKIE_USER}`,
				currentPage: `${hashCurrentPage[state.tabSelected]}`,
				registersPerPage: `${state.rowsPerPage ? state.rowsPerPage : state.notificationPerPage}`
			},
			DATA
		)
			.then((resp) => {
				filter(FormmatedTable(resp));
				_SetDispatch({
					payload: FormmatedTable(resp),
					type: hashTabSave[state.tabSelected]
				});
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const getDataByType = () => {
		getTotalRecords();
		getRecords();
	};

	const FormmatedTable = (datos) => {
		const formmatedData = [];
		datos.forEach((item) => {
			const [fecha, hora] = item.fechaHoraNotificacion.split('T');
			formmatedData.push({
				idAgendaNotificacion: item.idAgendaNotificacion,
				idServicio: item.idServicio,
				// format the date to show in the table  with the format dd/mm/yyyy hh:mm:ss , the date is in the format yyyy-mm-ddThh:mm:ss-GMT
				'Fecha / Hora': `${fecha} ${hora.split('+')[0]}`,
				expediente: item.idServicio.expediente,
				Categoria: item.idTipoNotificacion.tipo,
				Mensaje: item.descripcion,
				Cliente: item.nombreCliente || 'No disponible',
				Ciudad: item.nombreCiudadServicio || 'No disponible',
				fechaLeido: `${dateFormat(item.fechaHoraLeidoPanel, 'isoDate')} ${dateFormat(
					item.fechaHoraLeido,
					'isoTime'
				)}`,
				leido: item.fechaHoraLeidoPanel
			});
		});
		const sortedData = _.orderBy(formmatedData, 'Fecha / Hora', 'asc');
		return sortedData;
	};

	const hashEndpoint = {
		Notification: notificationsByUser,
		Alert: alertsByUser
	};

	const hashCount = {
		Notification: countNotificationsByUser,
		Alert: getCountAlertPanel
	};

	const hashTabSave = {
		Notification: 'CURRENT_NOTIFICATION',
		Alert: 'CURRENT_ALERT'
	};

	const hashCurrentCount = {
		Notification: 'CURRENT_COUNT_NOTIFICATION',
		Alert: 'CURRENT_COUNT_ALERT'
	};

	const hashCurrentPage = {
		Notification: state.currentPageNotification,
		Alert: state.currentPageAlert
	};

	const hashDataToFilter = {
		Notification: state.currentNotification,
		Alert: state.currentAlert
	};

	useEffect(() => {
		DeterminateTypeUser();
	}, [
		state.tabSelected,
		state.rowsPerPage,
		state.notificationPerPage,
		state.currentPageNotification,
		state.currentPageAlert
	]);

	useEffect(() => {
		filter();
	}, [state.textToFilter]);

	const filter = (dataGetted = null) => {
		//filtrar por texto y por hashIndexTabServices[indexTabServices] (alert o notification)
		const data = [];
		if (state.textToFilter === '' || state.textToFilter.length < 3) {
			if (dataGetted !== null) {
				_SetDispatch({
					payload: dataGetted,
					type: `FILTERED_${state.tabSelected.toUpperCase()}`
				});
				return;
			}
		}
		const testLowerToSearch = state.textToFilter.toLowerCase();
		hashDataToFilter[state.tabSelected].forEach((item) => {
			//make a filter by text using item.Categoria , item.Mensaje , item.Cliente , item.Ciudad only
			if (
				item['Categoria'].toLowerCase().includes(testLowerToSearch) ||
				item['Mensaje'].toLowerCase().includes(testLowerToSearch) ||
				item['Cliente'].toLowerCase().includes(testLowerToSearch) ||
				item['Ciudad'].toLowerCase().includes(testLowerToSearch) ||
				item['fechaLeido'].toLowerCase().includes(testLowerToSearch) ||
				item['Fecha / Hora'].toLowerCase().includes(testLowerToSearch) ||
				item['ticket'].toLowerCase().includes(testLowerToSearch) ||
				item['expediente'].toLowerCase().includes(testLowerToSearch)
			) {
				data.push(item);
			}
		});
		_SetDispatch({
			payload: data,
			type: `FILTERED_${state.tabSelected.toUpperCase()}`
		});
	};

	return { state, _SetDispatch, DeterminateTypeUser, getRecordsByQuery };
}
