import DynamicTable from 'components/zones/DynamicTable';
import { PAGES } from 'constants/lang/services/services/pages';
import React from 'react';
import MunicipalitiesViewModel from './Municipalities.viewModel';

const Municipalities = () => {
	const { loading, error, displayMessage, displayLoading, searchResults, getData, titles } =
		MunicipalitiesViewModel();
	return (
		<>
			{!loading ? (
				<div className="tab-container">
					{!loading && (
						<DynamicTable
							titles={titles}
							data={searchResults}
							getData={getData}
							pageName={PAGES.Municipalidades}
						/>
					)}
				</div>
			) : error ? (
				displayMessage('error', 'Ha ocurrido un error, intentalo más tarde.', 'toast.error.general')
			) : (
				displayLoading()
			)}
		</>
	);
};

export default Municipalities;
