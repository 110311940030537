import React from 'react';
import { FormComponents } from './components';
import { CreateUserViewModels } from './createUser.viewModels';

export default function CreateUser() {
	const {
		formatterText,
		formik,
		roles,
		setRoles,
		listUsers,
		setSearch,
		setRolSearch,
		loading,
		selectorData,
		setSelectorData,
		entity,
		setEntity,
		selectValue,
		setSelectValue,
		selectedRoles,
		acces,
		setAcces,
		setToggleSelector,
		toggleSelector,
		handleAddRole,
		handleRemoveRole,
		navigate
	} = CreateUserViewModels();
	return (
		<FormComponents
			formatterText={formatterText}
			formik={formik}
			roles={roles}
			setRoles={setRoles}
			listUsers={listUsers}
			setSearch={setSearch}
			setRolSearch={setRolSearch}
			loading={loading}
			selectorData={selectorData}
			setSelectorData={setSelectorData}
			entity={entity}
			setEntity={setEntity}
			selectValue={selectValue}
			setSelectValue={setSelectValue}
			selectedRoles={selectedRoles}
			acces={acces}
			setAcces={setAcces}
			toggleSelector={toggleSelector}
			setToggleSelector={setToggleSelector}
			handleAddRole={handleAddRole}
			handleRemoveRole={handleRemoveRole}
			navigate={navigate}
		/>
	);
}
