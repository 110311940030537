import useLangv2 from 'hooks/useLangv2';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { BsFillBellFill } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import { addOpacityToHSL } from 'screens/services/utils/string';
import paths from 'services/paths';
import styles from './styles.module.css';

const FINALIZADO_TECNICO = 'Finalizado Técnico';

const DayEvent = ({ service, height = 100, color = '', isTooltipVisible, setTooltipVisible , key }) => {
  const { formatterText } = useLangv2();

  const handleComponentClick = () => {
    setTooltipVisible( service.idOnly   );
  };

  const handleCloseTooltip = () => {
    setTooltipVisible(null);
  };

  const handleEscapeKey = (event) => {
    if (event.key === 'Escape') {
      handleCloseTooltip();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  const tooltipStyle = {
    opacity: isTooltipVisible === service.idOnly ? 1 : 0,
    pointerEvents: isTooltipVisible  === service.idOnly ? 'all' : 'none',
    position: 'absolute',
    left: 0,
    right: 0,
    width: 400,
    background: '#fff',
    zIndex: 99,
    padding: 15,
    top: -5,
    boxShadow: '3px 2px 5px rgba(0, 0, 0, 0.5)',
    borderRadius: 6,
    transition: 'all 0.2s ease',
  };

  const startDate = useMemo(
    () => moment(new Date(service.service_date_start)),
    [service]
  );

  const endDate = useMemo(
    () => moment(new Date(service.service_date_end)),
    [service]
  );

	const duration = useMemo(() => {
		return startDate.diff(endDate, 'minutes');
	}, [startDate, endDate]);

	const width = useMemo(() => {
		const result = (duration * 100) / 60;

		if (result < 0) return result * -1;

    return result;
  }, [duration]);

	const isLate = useMemo(
		() => endDate <= moment() && service.service_status === 'Asignado',

		[endDate]
	);

	const handleAuxClick = (e, pathToGo) => {
		if (e.button === 1) {
			e.preventDefault();
			window.open(`#${pathToGo}`, '_blank');
		}
	};

  return (
      <div
        key={key}
        style={{
          width: `${width}%`,
          background: isLate
            ? 'rgba(255, 63, 63, 1.0)'
            : `${addOpacityToHSL(color)}`,
          minHeight: `${height}%`,
          left: `${service.service_time.slice(3)}%`,
          cursor: 'pointer'
        }}
        className={styles.day__event_container}
        onClick={handleComponentClick}
      >
        <span className={styles.day__event_progress_alert}>
          {service.notifications ? <BsFillBellFill /> : ''}
        </span>
        <div
          className={styles.day__event_progress}
          style={{
            width: `${isLate ? 100 : service.percentage}%`,
            background: isLate ? 'rgba(0, 0, 0, 0.0)' : color,
            height: service.percentage > 100 ? `${height / 2}%` : `${height}%`,
            position:'absolute',
            display: service.service_status === FINALIZADO_TECNICO ? 'none' : 'block',
          }}
         />
        <div className="day__tooltip" style={tooltipStyle}>
          <div
            className={styles.day__event_progress}
            style={{
              width: `${service.percentage}%`,
              background: color,
              height: '5px',
              borderRadius: 6,
            }}
          />
          <div>
            <NavLink
              to={paths.updateService.replace(':id', parseInt(service.serviceId))}
              onAuxClick={(e) => handleAuxClick(e, paths.updateService.replace(':id', parseInt(service.serviceId)))}
              onScrollCapture={(e) => e.preventDefault()}
              className={styles.ul_nav}>
              <li>
                {formatterText('id.day.event.service.id')} <span>{service.serviceId}</span>
              </li>
            </NavLink>
            <li>
              Expediente: <span>{service.expediente}</span>
            </li>
            <li>
              {formatterText('id.day.event.service.type')} <span>{service.service_type}</span>
            </li>
            <li>
              {formatterText('id.day.event.service.state')} <span>{service.service_status}</span>
            </li>
            <li>
              {formatterText('id.day.event.service.duration')} <span>{service.service_real_duration}{' '}{formatterText('service.services.editService.activities.hours')}</span>
            </li>
            {(service.notifications !== 0 && service.service_status === FINALIZADO_TECNICO) && (
              <NavLink
                to={paths.panelNotifications}
                onAuxClick={(e) => handleAuxClick(e, paths.panelNotifications)}
                onScrollCapture={(e) => e.preventDefault()}
                >
                <li>
                  {formatterText('id.day.event.service.notifications')} <span>{service.notifications}</span>
                </li>
              </NavLink>
            )}
          </div>
        </div>
      </div >
  );
};

export default DayEvent;
