import useAxios from 'hooks/useAxios';

import { useEffect, useState } from 'react';
// Import Libs
import dateFormat from 'dateformat';
import Cookie from 'js-cookie';
import Swal from 'sweetalert2';

import { useNavigate, useParams } from 'react-router-dom';
// Import Components
import HandleOnError from 'common/validators/HandleOnError';

import CustomAlert from 'components/CustomAlert';
// Import Hooks
import useGetData from 'hooks/useGetData';

import useLangv2 from 'hooks/useLangv2';
//Import Utils
import HandleInput from 'common/validators/HandleInput';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';

import { validateDuplicateCodeAsync } from 'common/validators/ValidateDuplicates';

// Import Paths
import paths from 'services/paths';
// Import Services

// Import Models
import BusinessUnit from 'models/BusinessUnit';

//Import Constants
import { TEXTREGEXMIN, SPACING } from 'common/validators/Regex';

export default function UpdateBusinessUnitViewModel() {
	const { RequestUseCase, endpoints } = useAxios();

	const navigate = useNavigate();
	// Example of form data
	const [formData, setFormData] = useState(new BusinessUnit());
	const [selectedSearchCountries, setselectedSearchCountries] = useState([]);
	// Setea los valores del multiselect de los paises
	const [selectValues, setSelectValues] = useState([]);
	// espera a que cargue los valores del multiselect
	const { loading, toggleLoading } = useGetData();
	// toggle state
	const [active, setActive] = useState(true);
	// use Hook of language v2
	const { formatterText, resourceNotFound, noFilledContent } = useLangv2();
	const { id } = useParams();
	const [currentCode, setCurrentCode] = useState('');

	const [errorName, setErrorName] = useState('');
	const [codeError, setCodeError] = useState('');

	// Select current market associate
	const [selectedMarketUnitAssociate, setSelectedMarketUnitAssociate] = useState([]);

	useEffect(() => {
		getDataMarkets();
		getDataToUpdate(id);
	}, [id]);

	useEffect(() => {
		getDataMarkets();
		getDataBusinessUnit();
		getDataToUpdate(id);
	}, []);

	const getDataMarkets = () => {
		toggleLoading(true);
		RequestUseCase.get(endpoints.businessUnit.getAllMarketCountry)
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					if (item.mercado.estado === 1) {
						newArray.push({
							value: item.idMercadoPais,
							label: `${item.mercado.nombre} - ${item.pais.nombrePais}`,
							isFixed: true
						});
					}
				});
				setselectedSearchCountries(newArray);
				toggleLoading(false);
			})
			.catch((err) => {
				console.log(err);
				toggleLoading(false);
			});
	};

	const getDataBusinessUnit = () => {
		const businessUnit = JSON.parse(localStorage.getItem('dataUpdate'));

		if (businessUnit) {
			setCurrentCode(businessUnit.codigo);
			setFormData({
				idUnidadNegocio: businessUnit.idUnidadNegocio,
				nombre: businessUnit?.nombre.replaceAll(/\s{2,}/gi, ' '),
				descripcion: businessUnit.descripcion,
				codigo: businessUnit.codigo,
				estado: businessUnit.estado,
				fechaRegistro: businessUnit.fechaRegistro,
				fechaModificacion: businessUnit.fechaModificacion,
				usuarioCreacion: businessUnit.usuarioCreacion,
				usuarioModificacion: businessUnit.usuarioModificacion
			});
			setActive(Boolean(businessUnit.estado));
			getSelectCurrentValues(businessUnit.idUnidadNegocio);
		} else {
			navigate(paths.parameters);
		}
	};

	const getDataToUpdate = (id) => {
		RequestUseCase.get(endpoints.businessUnit.getMarketCountryByUnit(id))
			.then((res) => {
				if (res.length === null || res === null || res === undefined) {
					resourceNotFound();
					navigate(paths.parameters);
				}
				const countries = [];
				res.forEach((item) => {
					setFormData({
						idUnidadNegocio: item.idUnidadNegocio.idUnidadNegocio,
						nombre: item.idUnidadNegocio.nombre,
						descripcion: item.idUnidadNegocio.descripcion,
						codigo: item.idUnidadNegocio.codigo,
						estado: item.idUnidadNegocio.estado,
						fechaRegistro: item.idUnidadNegocio.fechaRegistro,
						fechaModificacion: item.idUnidadNegocio.fechaModificacion,
						usuarioCreacion: item.idUnidadNegocio.usuarioCreacion,
						usuarioModificacion: item.idUnidadNegocio.usuarioModificacion
					});
					countries.push({
						value: item.idMercadoPais.idMercadoPais,
						label: `${item.idMercadoPais.mercado.nombre} - ${item.idMercadoPais.pais.nombrePais}`,
						associateId: item.idMercadoPaisUnidadNegocio,
						isFixed: true
					});
				});
				setSelectValues(countries);
				setSelectedMarketUnitAssociate(countries);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getSelectCurrentValues = (id) => {
		RequestUseCase.get(endpoints.businessUnit.getMarketCountryByUnit(id))
			.then((res) => {
				const newArray = [];
				res.forEach((item) => {
					newArray.push({
						value: item.idMercadoPais.idMercadoPais,
						label: `${item.idMercadoPais.mercado.nombre} - ${item.idMercadoPais.pais.nombrePais}`,
						associateId: item.idMercadoPaisUnidadNegocio,
						isFixed: true
					});
				});
				setSelectValues(newArray);
				setSelectedMarketUnitAssociate(newArray);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	// Update a string to set into the form
	const handleText = (e) => {
		HandleInput(e, SPACING, formData, setFormData, true);
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, formData, setFormData);
	};

	const handlerTextCode = (e) => {
		const example = e;
		example.target.value = e.target.value.toLowerCase();
		HandleInput(example, TEXTREGEXMIN, formData, setFormData);
	};

	// This function is executed when the create button is clicked
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (errorName !== '' || codeError !== '' || selectValues.length === 0) {
			noFilledContent();
		} else {
			const data = {
				...formData,
				nombre: formData.nombre.replaceAll(/\s{2,}/gi, ' '),
				estado: active ? '1' : '0',
				usuarioModificacion: Cookie.get('idUsuario')
			};
			const isDuplicateCode = await validateDuplicateCodeAsync(
				formData.codigo.replaceAll(/\s{2,}/gi, ' ').toLowerCase(),
				formatterText('alert.message.code.error.general'),
				RequestUseCase.get(endpoints.businessUnit.getAllBusinessUnits),
				true,
				currentCode
			);

			if (!isDuplicateCode) putBusinessUnit(data);
		}
	};

	const putBusinessUnit = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.update.general', 'Se va a editar el registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve) => {
					RequestUseCase.put(endpoints.businessUnit.updateBusinessUnit, data)
						.then((res) => {
							resolve(
								CustomAlert('confirm_msg', {
									icon: 'success',
									title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
									text: formatterText(
										'alert.message.confirm.updated.general',
										'El registro se ha actualizado correctamente'
									),
									confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
									allowOutsideClick: false,
									executeFunction: () => {
										checkAssociateCountryMarketUnit(res.idUnidadNegocio);
									}
								})
							);
						})
						.catch((err) => {
							if (err.response?.data?.message) {
								HandleOnError(formatterText(err.response?.data?.message));
							} else if (err.response.status === 412) {
								HandleOnError(
									formatterText(
										'alert.message.code.error.general',
										'El código ya existe, por favor ingrese uno distinto'
									)
								);
							} else {
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
						});
				});
			}
		});
	};

	const checkAssociateCountryMarketUnit = (idBU) => {
		const newArray = [];
		selectedMarketUnitAssociate.forEach((item) => {
			newArray.push(item.associateId);
		});
		const newArray2 = [];
		selectValues.forEach((item) => {
			newArray2.push(item.associateId);
		});
		const newArray3 = newArray.filter((item) => !newArray2.includes(item));

		const newArray4 = [];
		selectValues.forEach((item) => {
			if (!selectedMarketUnitAssociate.includes(item)) {
				newArray4.push(item);
			}
		});

		const lengthAssociate = selectedMarketUnitAssociate.length;
		const lengthCurrentSelect = selectValues.length;

		// if para determinar si se eliminaron los valores seleccionados
		if (lengthAssociate > lengthCurrentSelect) {
			newArray3.forEach((item) => {
				console.log('elimnino:', item);
				deleteAssociateCountryMarketUnit(item);
			});
			// if para determinar si se agregaron valores seleccionados
			if (newArray4.length > 0) {
				newArray4.forEach((item) => {
					console.log('agregando:', item);
					associateCountryMarketBusinessUnit(item.value, idBU);
				});
			}
			// if para determinar si se actualizaron los valores seleccionados
		} else if (lengthAssociate === lengthCurrentSelect) {
			if (newArray3.length === 0) {
				navigate(paths.parameters);
			} else {
				newArray3.forEach((item, i) => {
					console.log('editando:', item);
					updateAssociateCountryMarketUnit(item, idBU, newArray4[i].value);
				});
			}

			// if para determinar si se agregaron valores seleccionados
		} else if (lengthAssociate < lengthCurrentSelect) {
			newArray4.forEach((item) => {
				console.log('agregando:', item);
				associateCountryMarketBusinessUnit(item.value, idBU);
			});
			console.log('ir a la tabla principal');
		} else {
			console.log('ir a la tabla');
		}
	};

	const updateAssociateCountryMarketUnit = (idAssociate, idBU, idMP) => {
		console.log('actualizando:', idAssociate);
		console.log('actualizando:', idBU);

		const data = {
			idMercadoPaisUnidadNegocio: parseInt(idAssociate),
			idMercadoPais: {
				idMercadoPais: parseInt(idMP)
			},
			idUnidadNegocio: {
				idUnidadNegocio: parseInt(idBU)
			},
			estado: 1,
			fechaCreacion: dateFormat(new Date(), 'isoDateTime'),
			fechaModificacion: dateFormat(new Date(), 'isoDateTime'),
			usuarioCreacion: parseInt(Cookie.get('idUsuario')),
			usuarioModificacion: parseInt(Cookie.get('idUsuario'))
		};

		RequestUseCase.put(endpoints.businessUnit.updateMarketCountryToUnit, data)
			.then((res) => {
				console.log(res);
				CustomAlert('confirm_msg', {
					icon: 'success',
					title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
					text: formatterText('alert.title.confirm.updates', 'Se actualizaron los registros'),
					confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
					allowOutsideClick: false,
					executeFunction: () => navigate(paths.parameters)
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const deleteAssociateCountryMarketUnit = (idBU) => {
		RequestUseCase.delete(endpoints.businessUnit.deleteMarketCountryFromUnit(idBU))
			.then((res) => {
				console.log(res);
				CustomAlert('confirm_msg', {
					icon: 'success',
					title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
					text: formatterText('alert.title.confirm.deleted', 'Se eliminaron los registros'),
					confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
					allowOutsideClick: false,
					executeFunction: () => navigate(paths.parameters)
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const associateCountryMarketBusinessUnit = (idAssociate, idBU) => {
		const data = {
			idMercadoPaisUnidadNegocio: null,
			idMercadoPais: {
				idMercadoPais: idAssociate
			},
			idUnidadNegocio: {
				idUnidadNegocio: idBU
			}
		};
		RequestUseCase.post(endpoints.businessUnit.addMarketCountryToUnit, data)
			.then((res) => {
				console.log(res);
				CustomAlert('confirm_msg', {
					icon: 'success',
					title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
					text: formatterText('alert.title.confirm.add', 'Se agregaron nuevos registros'),
					confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
					allowOutsideClick: false,
					executeFunction: () => navigate(paths.parameters)
				});
			})
			.catch((err) => {
				console.log(err);
				HandleOnError('Ocurrio un error al asociar');
			});
	};

	const handleChangeMulti = (val) => {
		setSelectValues(val);
	};

	const handleErrorName = () => {
		if (formData.nombre.trim() === '') {
			setErrorName(formatterText('p.label.title.name.is.required'));
		} else {
			setErrorName('');
		}
	};

	const handleErrorCode = () => {
		if (formData.codigo.trim() === '') {
			setCodeError(formatterText('table.title.code.required'));
		} else {
			setCodeError('');
		}
	};

	return {
		active,
		codeError,
		errorName,
		formatterText,
		formData,
		handleChangeMulti,
		handleErrorCode,
		handleErrorName,
		handlerTextCode,
		handlerTextDescription,
		handleSubmit,
		handleText,
		loading,
		navigate,
		selectedSearchCountries,
		selectValues,
		setActive
	};
}
