import useAxios from 'hooks/useAxios';
import { useContext, useEffect, useState } from 'react';
import { TableMinimalContext } from 'context/TableMinimalContext';
import useLang from 'hooks/useLang';
import useLangv2 from 'hooks/useLangv2';
import CustomAlert from 'components/CustomAlert';
import Cookie from 'js-cookie';
import HandleInput from 'common/validators/HandleInput';
import HandleOnError from 'common/validators/HandleOnError';
import HandlerTextDescription from 'common/validators/HandlerTextDescription';
import { TEXTREGEX, TEXTREGEXMIN } from 'common/validators/Regex';
import { useSeachContext } from 'context/SearchContext';
import endpoints from 'services/api';

export default function UpdateModalItemViewModel({
	onClose,
	productCategories,
	loadingCategories,
	getDataFromMinimalTable,
	id
}) {
	const { RequestUseCase } = useAxios();

	const [currentItemMinimal, setCurrentItemMinimal] = useState({
		idTipoCategoria: '',
		nombre: '',
		codigo: '',
		categorias: [],
		estado: 1,
		descripcion: '',
		idCategoriaServicio: '',
		idTipoServicio: ''
	});

	const [loading, setLoading] = useState(true);
	// useLanguage
	const { formatterText } = useLang();
	const { noFilledContent, updatedItem } = useLangv2();
	const [products, setProducts] = useState([]);

	const { modalData, setModalData, currentDataTable, setCurrentDataTable, setResultsTableSearch } =
		useContext(TableMinimalContext);

	const { dataTable, setDataTable } = useSeachContext();

	//  create useEffect clean function
	useEffect(() => {
		setCurrentItemMinimal(modalData);
		dataProductCategories(id);
	}, [modalData]);

	useEffect(() => {
		if (!loadingCategories && productCategories.length > 0) {
			setLoading(false);
		}
	}, [loadingCategories, productCategories]);

	const handleChangeInput = (e) => {
		HandleInput(e, TEXTREGEX, currentItemMinimal, setCurrentItemMinimal);
	};

	const handlerTextDescription = (e) => {
		HandlerTextDescription(e, currentItemMinimal, setCurrentItemMinimal);
	};

	const handleChangeInputCode = (e) => {
		const example = e;
		example.target.value = e.target.value.toLowerCase();
		HandleInput(example, TEXTREGEXMIN, currentItemMinimal, setCurrentItemMinimal);
	};

	const handleUpdateMinimalModal = (e) => {
		e.preventDefault();
		if (modalData.nombre !== '' && modalData.codigo !== '' && products.length > 0) {
			if (typeof currentItemMinimal.idTipoServicio === 'number') {
				const data = {
					idCategoriaServicio: parseInt(id),
					idTipoServicio: currentItemMinimal.idTipoServicio,
					idUsuario: parseInt(Cookie.get('idUsuario')),
					nombre: currentItemMinimal.nombre,
					descripcion: currentItemMinimal.descripcion,
					codigo: currentItemMinimal.codigo,
					idCategoriaProducto: products.map((item) => item.value)
				};
				RequestUseCase.put(endpoints.typeService.updateTypeServiceProductCategory, data)
					.then(() => {
						onClose();
						CustomAlert('confirm_msg', {
							icon: 'success',
							title: formatterText('alert.title.confirm.general'),
							text: formatterText('alert.message.confirm.updated.general'),
							confirmButtonText: formatterText('alert.button.continue'),
							allowOutsideClick: false,
							executeFunction: () => updateTable()
						});
						updatedItem();
					})
					.catch((err) => {
						HandleOnError('No se pudo actualizar el elemento porque el codigo ya existe');
						console.error(err);
						onClose();
					});
			} else {
				updateStructure();
			}
		} else {
			noFilledContent();
		}
	};

	// Update the structure of the table after search the item to update
	const updateStructure = () => {
		// Filter if the current modal data have the same id
		const filterId = currentDataTable.filter(
			(item) => item.idTipoServicio !== modalData.idTipoServicio
		);
		// Filter if the current modal data have the same code
		const filterCode = currentDataTable.filter(
			(item) => item.codigo === modalData.codigo && item.idTipoServicio !== modalData.idTipoServicio
		);

		if (filterCode.length > 0) {
			onClose();
			HandleOnError(formatterText('p.label.title.codigoExistenteElemento'));
		} else {
			const categorias = products.map((cat) => ({
				label: cat.label.toString(),
				value: cat.value.toString()
			}));

			let categoriasArray = [];

			if (id) {
				categoriasArray = categorias.map(({ label }) => label);
			} else {
				categoriasArray = categorias.map(({ label, value }) => `${label} - ${value.toString()}`);
			}
			const categoriasString = categoriasArray.join(', ');

			const data = {
				idTipoServicio: currentItemMinimal.idTipoServicio,
				nombre: currentItemMinimal.nombre,
				descripcion: currentItemMinimal.descripcion,
				codigo: currentItemMinimal.codigo,
				estado: 1,
				categorias: [categoriasString]
			};

			setDataTable([...filterId, data]);
			setModalData(dataTable);
			setResultsTableSearch([...filterId, data]);
			onClose();
		}
	};

	const updateTable = () => {
		currentDataTable.forEach((item) => {
			if (item.idTipoServicio === modalData.idTipoServicio) {
				let dataLeft = currentDataTable.filter((item) => {
					return item.idTipoServicio !== modalData.idTipoServicio;
				});

				dataLeft = dataLeft.map((item) => {
					if (item.idTipoServicio === currentItemMinimal.idTipoServicio) {
						return {
							...item,
							categorias: currentDataTable.categorias
						};
					}
					return item;
				});

				setCurrentDataTable(dataLeft);
				setResultsTableSearch(dataLeft);
				setCurrentItemMinimal({
					categorias: []
				});
			}
			getDataFromMinimalTable(id);
			onClose();
			setCurrentItemMinimal({
				categorias: []
			});
		});
	};

	const dataProductCategories = (id) => {
		if (id) {
			RequestUseCase.get(endpoints.typeService.newGetTypeServiceByCategory(id))
				.then((res) => {
					const categories = res?.find(
						(item) => item.tipoServicio.idTipoServicio === modalData.idTipoServicio
					)?.categoriaProducto;
					if (categories && categories.length > 0) {
						setProducts(
							categories.map((category) => ({
								label: category?.nombre,
								value: category?.idCategoriaProducto
							}))
						);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		} else {
			const categories = modalData.categorias;
			const categoryArray = categories[0].split(',').map((category) => {
				const [label, value] = category.trim().split(' - ');
				return { value: value.trim(), label: label.trim() };
			});
			setProducts(categoryArray);
		}
	};
	return {
		currentItemMinimal,
		loading,
		products,
		setProducts,
		handleChangeInput,
		handleChangeInputCode,
		handlerTextDescription,
		handleUpdateMinimalModal
	};
}
