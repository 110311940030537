/**
 * Convierte un formato moneda a numero
 * @param {*} value
 * @return value formatted
 *
 * @author rfarfan-qvision
 */

const formatCurrencyFromModal = (value) => {
	const parts = value.toString().split(',');

	let formattedValue = `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`;
	if (parts.length > 1) {
		formattedValue += `,${parts[1]}`;
	}

	const splitDecimal = formattedValue.split(',');
	const intPart = splitDecimal[0];
	const floatPart = splitDecimal[1];

	if (floatPart) {
		console.log(floatPart);
		formattedValue = intPart + ',' + floatPart.substring(0, 2);
	}

	return formattedValue;
};

export default formatCurrencyFromModal;
