import React from 'react';

// Import Libs
import { FormattedMessage } from 'react-intl';

// Import Components
import SelectorMulti from 'common/SelectorMulti';

// Import Paths
import paths from 'services/paths';

//Import ViewModel
import UpdateBusinessUnitViewModel from './UpdateBusinessUnit.viewModel';

export default function UpdateBusinessUnit() {
	const {
		active,
		codeError,
		errorName,
		formatterText,
		formData,
		handleChangeMulti,
		handleErrorCode,
		handleErrorName,
		handlerTextCode,
		handlerTextDescription,
		handleSubmit,
		handleText,
		loading,
		navigate,
		selectedSearchCountries,
		selectValues,
		setActive
	} = UpdateBusinessUnitViewModel();

	return (
		<form className="form-responsive" onSubmit={handleSubmit}>
			<section className="grid-container-1c zero-gap">
				<section className="d-flex">
					<span className="text-inline-md">
						<FormattedMessage
							id="table.title.business.unit.name"
							defaultMessage="Nombre unidad de negocio"
						/>
					</span>
					<section className="w100-container">
						<input
							style={{ marginBottom: '0' }}
							className="input-default-3c"
							type="text"
							name="nombre"
							value={formData.nombre}
							onChange={handleText}
							onBlur={handleErrorName}
							placeholder={formatterText('input.placeholder.unit.name', 'Nombre unidad')}
							maxLength="100"
						/>
						{errorName && <p style={{ fontSize: '0.7rem', color: 'red' }}>{errorName}</p>}
					</section>
				</section>
				<section className="d-flex">
					<span className="text-inline-md">
						<FormattedMessage id="table.title.description" defaultMessage="Desripción" />
					</span>
					<section className="w100-container">
						<textarea
							className="input-textarea"
							type="text"
							name="descripcion"
							value={formData.descripcion}
							onChange={handlerTextDescription}
							placeholder={formatterText('table.title.description', 'Descripción')}
							maxLength="200"
						/>
					</section>
				</section>
				<section className="d-flex">
					<span className="text-inline-md">
						<FormattedMessage id="table.title.code" defaultMessage="Código" />
					</span>
					<section className="w100-container">
						<input
							style={{ marginBottom: '0' }}
							className="input-default-3c"
							type="text"
							name="codigo"
							value={formData.codigo}
							onChange={handlerTextCode}
							placeholder={formatterText(
								'input.placeholder.enter.up.characters',
								'Ingresa hasta 45 caracteres'
							)}
							onBlur={handleErrorCode}
							maxLength="45"
						/>
						{codeError && <p style={{ fontSize: '0.7rem', color: 'red' }}>{codeError}</p>}
					</section>
				</section>
				<section className="d-flex">
					<span className="text-inline-md">
						<FormattedMessage id="p.country.market" defaultMessage="Mercado país" />
					</span>
					<section className="w100-container">
						<SelectorMulti
							data={selectedSearchCountries}
							isLoading={loading}
							dataValue={selectValues}
							setterFunction={handleChangeMulti}
							// isRequired={true}
						/>
					</section>
				</section>
				<section className="d-flex">
					<span className="text-inline-md">
						<FormattedMessage id="table.title.state" defaultMessage="Estado" />
					</span>
					<section className="w100-container">
						<label className="container-toggle-label">
							<p className="form-responsive-toggle">
								{active
									? formatterText('p.active', 'Activo')
									: formatterText('p.unActive', 'No activo')}
							</p>
							<label className="switch">
								<input checked={active} onChange={() => setActive(!active)} type="checkbox" />
								<span className="slider round"></span>
							</label>
						</label>
					</section>
				</section>
			</section>
			<section className="form-responsive-container-buttons">
				<button type="submit" className="btn-primary">
					<FormattedMessage id="btn.save.changes" defaultMessage="Guardar cambios" />
				</button>
				<button className="input-cancel" onClick={() => navigate(paths.parameters)}>
					<FormattedMessage id="alert.button.cancel.general" defaultMessage="Cancelar" />
				</button>
			</section>
		</form>
	);
}
