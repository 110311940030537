import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FormattedMessage } from 'react-intl';
import useLang from 'hooks/useLang';
import customStyles2 from './generic.style.select';

const SelectorSearch = ({
	name,
	data,
	placeholder,
	isLoading,
	dataValue,
	setterFunction,
	isRequired,
	disabled,
	newForm,
	fullWidth
}) => {
	const { formatterText } = useLang();
	const [selectedOption, setSelectedOption] = useState(null);

	useEffect(() => {
		if (newForm) {
			if (dataValue.label !== '') {
				setSelectedOption(dataValue);
			}
		} else {
			setSelectedOption(dataValue);
		}
		// console.log("dataValue", dataValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataValue]);

	return (
		<div className={fullWidth ? 'select-wrapper-selects' : 'select-wrapper-container'}>
			<Select
				styles={customStyles2}
				onChange={(selectedOption) => {
					setSelectedOption(selectedOption);
					setterFunction((prev) => ({
						...prev,
						[name]: selectedOption
					}));
				}}
				noOptionsMessage={() => formatterText('select.placeholder.no.options')}
				options={data}
				placeholder={
					isLoading ? (
						<FormattedMessage id="input.loading" defaultMessage="Cargando..." />
					) : (
						placeholder
					)
				}
				isDisabled={disabled ? true : false}
			/>
			<input
				className="input-required"
				type="text"
				value={selectedOption || ''}
				autoComplete="off"
				required={isRequired === true ? true : false}
				style={{ visibility: 'hidden' }}
			/>
		</div>
	);
};

export default SelectorSearch;
