import { formatterText } from 'hooks/useLangv2';

export default function ModalDetailsViewModel(item) {
	const styleTittle = {
		fontWeight: 'bold',
		color: '#0AA48A'
	};

	const hashTittles = [
		formatterText('title.service.search.manual.modal.tech.name'),
		formatterText('title.service.search.manual.modal.startOfPreviousService'),
		formatterText('title.service.search.manual.modal.endOfPreviousService'),
		formatterText('title.service.search.manual.modal.sectorOfPreviousService'),
		formatterText('title.service.search.manual.modal.startOfNextService'),
		formatterText('title.service.search.manual.modal.endOfNextService'),
		formatterText('title.service.search.manual.modal.sectorOfNextService'),
		formatterText('title.service.search.manual.modal.marginOfPreviousService'),
		formatterText('title.service.search.manual.modal.marginOfNextService'),
		formatterText('title.service.search.manual.modal.riskOfNonCompliancePreviousService'),
		formatterText('title.service.search.manual.modal.riskOfNonComplianceNextService')
	];

	const hashRisk = {
		1: 'Bajo',
		2: 'Medio',
		3: 'Alto',
		4: 'No Disponible'
	};

	const hashValues = {
		0: item.nombre,
		1:
			item?.servicioPrevio?.fechaCita !== undefined
				? `${item?.servicioPrevio?.fechaCita.split('T')[0]} a las ${
						item?.servicioPrevio?.idHoraCita?.hora
				  }`
				: 'N/A',
		2:
			item?.servicioPrevio?.fechaCitaFin !== undefined
				? `${item?.servicioPrevio?.fechaCitaFin.split('T')[0]} a las ${
						item?.servicioPrevio?.idHoraCitaFin?.hora
				  }`
				: 'N/A',
		3:
			item?.servicioPrevio?.idDireccion?.idSector?.nombre !== undefined
				? item?.servicioPrevio?.idDireccion?.idSector?.nombre
				: 'N/A',
		4:
			item?.servicioPosterior?.fechaCita !== undefined
				? `${item?.servicioPosterior?.fechaCita.split('T')[0]} a las ${
						item?.servicioPosterior?.idHoraCita?.hora
				  }`
				: 'N/A',
		5:
			item?.servicioPosterior?.fechaCitaFin !== undefined
				? `${item?.servicioPosterior?.fechaCitaFin.split('T')[0]} a las ${
						item?.servicioPosterior?.idHoraCitaFin?.hora
				  }`
				: 'N/A',
		6:
			item?.servicioPosterior?.idDireccion?.idSector?.nombre !== undefined
				? item?.servicioPosterior?.idDireccion?.idSector?.nombre
				: 'N/A',
		7:
			item?.tiempoDisponibilidadInicio !== undefined && item?.tiempoDisponibilidadInicio != -1
				? `${item.tiempoDisponibilidadInicio.toString().split('.')[0]} minutos`
				: 'N/A',
		8:
			item?.tiempoDisponibilidadFin !== undefined && item?.tiempoDisponibilidadFin !== -1
				? `${item.tiempoDisponibilidadFin.toString().split('.')[0]} minutos`
				: 'N/A',
		9: hashRisk[item.riesgoIncumplimientoAnterior],
		10: hashRisk[item.riesgoIncumplimientoSiguiente]
	};
	return {
		styleTittle,
		hashTittles,
		hashRisk,
		hashValues
	};
}
