import React from 'react';

// Import Libs
import { Formiz, FormizStep } from '@formiz/core';
import dateFormat from 'dateformat';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

// Import Components
import { InputFieldDV } from 'common/InputFieldDV';
import SelectorMulti from 'common/SelectorMulti';
import { InputFieldResponsive } from 'common/inputs/InputFieldResponsive';
import InputSelectorResponsive from 'common/inputs/InputSelectorResponsive';
import TableMinimalNoContext from 'common/minimalTables/TableMinimalNoContext';
import AssociateCoverage from 'common/multiTableType/AssociateCoverage';
import ClienteSucursal from 'common/multiTableType/ClienteSucursal';
import UnityBusiness from 'common/multiTableType/UnityBusiness';
import Attachments from 'components/AttachedFiles/Attachments';

// Import Utils
import HandleShortDate from 'common/validators/HandleShortDate';

// Import Paths
import paths from 'services/paths';

// Import Constants
import UpdateTechnicalViewModel from './UpdateTechnical.viewModel';

function UpdateTechnicalComponent() {
	const {
		archivos,
		categoryLicense,
		categoryService,
		deleteVehicule,
		formatterText,
		formData,
		handleAddFamily,
		handleChangeModel,
		handleChangeMulti,
		handleChangeRelative,
		handleChangeSelect,
		handleSubmit,
		loading,
		messageError,
		messageErrorPlaca,
		myForm,
		navigate,
		primaryMinimalTable,
		secondMinimalTable,
		selectValues,
		setCurrentFiles,
		setPrimaryMinimalTable,
		setSecondMinimalTable,
		setThirdMinimalTable,
		show,
		thirdMinimalTable,
		TITLE_TABLE,
		uploadNewFile,
		vehicles,
		vehiclesBrand
	} = UpdateTechnicalViewModel();

	return (
		<div className="centered-form">
			{formData && (
				<Formiz onValidSubmit={handleSubmit} connect={myForm}>
					<form
						noValidate
						onSubmit={myForm.submit}
						className="container-wrapForm"
						style={{ minHeight: '16rem' }}
					>
						<div className="new-container-wrapForm__tabs">
							{myForm?.steps.map((step) => (
								<button
									key={step.name}
									className={`new-tab-option ${
										step.name === myForm?.currentStep.name ? 'is-active' : ''
									}`}
									type="button"
									onClick={() => myForm.goToStep(step.name)}
								>
									{!step.isValid && step.isSubmitted && <small className="mr-2">⚠️</small>}
									{formatterText(step.label)}
								</button>
							))}
						</div>
						<div className="container-wrapForm-content">
							{/*info technical */}
							<FormizStep name="step1" label="p.general.information">
								<div className="title-section">
									<span className="circle-form">
										<span>1</span>
									</span>
									<h2>
										<FormattedMessage id="technicians.title.general.information" />{' '}
										{` ${formData.primerNombre} ${formData.identificacion}`}
									</h2>
								</div>
								<section className="grid-container-3c">
									<label className="d-flex">
										<span className="text-inline">
											<FormattedMessage
												id="p.service.category.per"
												defaultMessage="Categoría de servicio a la que pertenece"
											/>
										</span>
										<section className="w100-container">
											<SelectorMulti
												data={categoryService}
												dataValue={selectValues}
												setterFunction={handleChangeMulti}
											/>
										</section>
									</label>
									<InputFieldDV
										type="text"
										name="tipoDocumento"
										labelText={formatterText('p.document.type', 'Tipo de documento')}
										placeholder={formData.idAbreviaturaDocumento + '-' + formData.idTipoDocumento}
										validateInput="text"
										defaultValue={formData.idAbreviaturaDocumento + '-' + formData.idTipoDocumento}
										disabled={true}
									/>
									<InputFieldDV
										type="text"
										name="genero"
										labelText={formatterText('p.genero', 'Género')}
										placeholder="Género"
										validateInput="text"
										values={formData.genero}
										disabled={true}
									/>
									<InputFieldDV
										type="text"
										name="identificacion"
										labelText={formatterText('text.ID', 'Identificación')}
										placeholder="Identificación"
										validateInput="number"
										values={formData.identificacion}
										disabled={true}
									/>
									<InputFieldDV
										type="text"
										name="primerNombre"
										labelText={formatterText('p.first.name', 'Primer nombre')}
										placeholder="Primer nombre"
										validateInput="text"
										values={formData.primerNombre}
										disabled={true}
									/>
									<InputFieldDV
										type="text"
										name="segundoNombre"
										labelText={formatterText('p.second.name', 'Segundo nombre')}
										placeholder="Segundo nombre"
										validateInput="text"
										values={formData.segundoNombre}
										disabled={true}
									/>
									<InputFieldDV
										type="text"
										name="primerApellido"
										labelText={formatterText('p.first.lastname', 'Primer Apellido')}
										placeholder="Primer Apellido"
										validateInput="text"
										values={formData.primerApellido}
										disabled={true}
									/>
									<InputFieldDV
										type="text"
										name="segundoApellido"
										labelText={formatterText('p.second.lastname', 'Segundo Apellido')}
										placeholder="Segundo Apellido"
										validateInput="text"
										values={formData.segundoApellido}
										disabled={true}
									/>
								</section>
							</FormizStep>
							{/* info tech continue */}
							<FormizStep name="step2" label="p.operators.technicians">
								<div className="title-section">
									<span className="circle-form">
										<span>2</span>
									</span>
									<h2>
										<FormattedMessage
											id="p.operators.technicians"
											defaultMessage="Información Operarios/Técnicos"
										/>
									</h2>
								</div>
								<section
									className="grid-container-2c"
									style={{
										marginLeft: '10px'
									}}
								>
									<InputFieldResponsive
										type="date"
										name="licenciaFechaExpiracion"
										labelText={formatterText(
											'p.date.expire.license',
											'Fecha de expiración de la licencia de conducción'
										)}
										placeholder="Fecha de expiración de la licencia de conducción"
										required="La fecha de expiración es requerida"
										defaultValue={dateFormat(
											moment(formData.licenciaFechaExpiracion.split('T')[0]),
											'yyyy-mm-dd'
										)}
									/>
									<InputSelectorResponsive
										type="text"
										name="idCategoriaLicenciaConduccion"
										labelText={formatterText(
											'p.category.license',
											'Categoría de licencia de conducción'
										)}
										placeholder={
											<FormattedMessage
												id="input.placeholder.select"
												defaultMessage="Selecione una opción"
											/>
										}
										required={formatterText('p.label.title.categoriaLicenciaRequerida')}
										data={categoryLicense}
										isLoading={loading}
									/>
								</section>
								<label
									className="d-flex"
									style={{
										marginTop: '40px',
										marginLeft: '10px'
									}}
								>
									<section className="grid-container-1c">
										<InputFieldResponsive
											type="text"
											name="restriccionLicencia"
											placeholder="Restricciones de licencia de conducción"
											labelText={formatterText(
												'p.restricted.license',
												'Restricciones de licencia de conducción'
											)}
											validateInput="text"
											defaultValue={formData.restriccionLicencia}
											required={formatterText('alert.textarea.show.error.required')}
											area={true}
											styleName={'input-default-1c'}
											rows="5"
											cols="100"
										/>
									</section>
								</label>

								{vehiclesBrand && (
									<div>
										<div className="title-section">
											<span className="circle-form">
												<span>2.1</span>
											</span>
											<h2>
												<FormattedMessage id="p.add.vehicle" defaultMessage="Agregar vehículo" />
											</h2>
										</div>
										{vehicles.length > 0 && (
											<>
												<section className="form-responsive-container-information">
													<TableMinimalNoContext
														titles={TITLE_TABLE}
														type="vehiclesAssign"
														data={vehicles}
														labelTable="Vehículos por página"
														handleDelete={(x) => deleteVehicule(x)}
													/>
												</section>
											</>
										)}
										{show && (
											<>
												<section className="grid-container-3c">
													<label className="d-flex">
														<span className="text-inline">
															{<FormattedMessage id="technicians.label.text.vehicle.license" />}
														</span>
														<section className="w100-container">
															<input
																name="placa"
																placeholder={formatterText(
																	'p.enter.license.vehicle',
																	'Ingrese la placa del vehiculo'
																)}
																type="text"
																required={formatterText('p.label.title.placaVehiculoRequerida')}
																className="input-default-3c"
																onChange={handleChangeRelative}
															/>
															<span className="error-msg">{messageErrorPlaca}</span>
														</section>
													</label>
													<label className="d-flex">
														<span className="text-inline">
															<FormattedMessage id="p.vehicle.model" defaultMessage="Modelo" />
														</span>
														<section className="w100-container">
															<input
																name="modelo"
																placeholder={formatterText(
																	'p.add.vehicle.model',
																	'Ingrese el modelo del vehiculo'
																)}
																type="text"
																required={formatterText('p.label.title.modeloVehiculoRequerido')}
																className="input-default-3c"
																onChange={handleChangeModel}
																maxLength="4"
															/>
															<span className="error-msg">{messageError}</span>
														</section>
													</label>
													<label className="d-flex">
														<span className="text-inline">
															<FormattedMessage id="p.vehicle.brand" defaultMessage="Marca" />
														</span>
														<select
															name="idMarcaVehiculo"
															id="idMarcaVehiculo"
															className="input-label-style"
															onChange={handleChangeSelect}
														>
															<option value="">
																<FormattedMessage
																	id="p.selection.brand"
																	defaultMessage="Seleccione una marca"
																/>
															</option>
															{vehiclesBrand.map((item) => (
																<option
																	key={item.idMarcaVehiculo}
																	value={JSON.stringify({ ...item })}
																>
																	{item.nombre} - {item.codigo}
																</option>
															))}
														</select>
													</label>
													<label className="d-flex">
														<span className="text-inline">
															<FormattedMessage
																id="p.date.technicians.revision"
																defaultMessage="Fecha revisión técnico mecánica"
															/>
														</span>
														<section className="w100-container">
															<input
																name="fechaTecnicoMecanica"
																placeholder="Ingrese la revisión técnico mecánica"
																type="date"
																required={formatterText(
																	'p.label.title.fechaRevisionTecnicoMecanicaRequerida'
																)}
																className="input-default-3c"
																onChange={handleChangeRelative}
																min={HandleShortDate()}
																max={HandleShortDate(50)}
															/>
														</section>
													</label>
													<label className="d-flex">
														<span className="text-inline">
															<FormattedMessage id="p.date.soat" defaultMessage="Fecha Soat" />
														</span>
														<section className="w100-container">
															<input
																name="fechaSoap"
																placeholder="Ingrese la fecha del soat"
																type="date"
																required={formatterText('p.label.title.fechaSoatRequerida')}
																className="input-default-3c"
																onChange={handleChangeRelative}
																min={HandleShortDate()}
																max={HandleShortDate(50)}
															/>
														</section>
													</label>
													<label className="d-flex">
														<span className="text-inline">
															<FormattedMessage id="p.date.runt" defaultMessage="Fecha RUNT" />
														</span>
														<section className="w100-container">
															<input
																name="fechaRunt"
																placeholder="Ingrese la fecha runt"
																type="date"
																required={formatterText('p.label.title.fechaRuntRequerida')}
																className="input-default-3c"
																onChange={handleChangeRelative}
																max={HandleShortDate()}
																min={HandleShortDate(-50)}
															/>
														</section>
													</label>
													<label className="d-flex">
														<span className="text-inline">
															<FormattedMessage id="p.code.runt" defaultMessage="Código RUNT" />
														</span>
														<section className="w100-container">
															<input
																name="runt"
																placeholder={formatterText(
																	'p.add.code.runt',
																	'Ingrese el codigo runt'
																)}
																type="text"
																required={formatterText('p.label.title.codigoRuntRequerido')}
																className="input-default-3c"
																onChange={handleChangeRelative}
																maxLength="100"
															/>
														</section>
													</label>
												</section>
												<section className="form-responsive-container-buttons">
													<input
														onClick={handleAddFamily}
														type="button"
														className="btn-primary"
														value={formatterText('p.add.vehicle')}
													/>
												</section>
											</>
										)}
									</div>
								)}
							</FormizStep>
							{formData.idUsuario !== null && (
								<FormizStep name="step3" label="p.associations">
									<form>
										<UnityBusiness
											data={primaryMinimalTable}
											setterFunction={setPrimaryMinimalTable}
										/>
										<ClienteSucursal
											data={secondMinimalTable}
											setterFunction={setSecondMinimalTable}
										/>
										<AssociateCoverage
											data={thirdMinimalTable}
											setterFunction={setThirdMinimalTable}
										/>
									</form>
								</FormizStep>
							)}
							<FormizStep name="step4" label="p.charge.archvie">
								<Attachments
									currentFiles={archivos}
									setCurrentFiles={setCurrentFiles}
									isEdited={true}
									uploadNewFile={uploadNewFile}
									showParameters={true}
								/>
							</FormizStep>
							<section className="form-responsive-container-buttons">
								<button type="submit" className="btn-primary">
									<FormattedMessage
										id="alert.button.confirm.general"
										defaultMessage="Guardar cambios"
									/>
								</button>
								<button className="input-cancel" onClick={() => navigate(paths.technical)}>
									<FormattedMessage id="btn.cancel" defaultMessage="Cancelar" />
								</button>
							</section>
						</div>
					</form>
				</Formiz>
			)}
		</div>
	);
}

export default UpdateTechnicalComponent;
