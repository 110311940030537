import { useEffect, useState } from 'react';

// Import libs
import { useForm } from '@formiz/core';
import dateFormat from 'dateformat';
import Cookie from 'js-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

// Import Components
import HandleOnError from 'common/validators/HandleOnError';
import CustomAlert from 'components/CustomAlert';

// Import Hooks
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';
import useProgress from 'hooks/useProgress';

//Import Utils
import { FilterActive } from 'common/validators/FilterActives';

// Import Paths
import paths from 'services/paths';

// Import Contexts
import { useSeachContext } from 'context/SearchContext';

export default function EditEmployeeViewModel() {
	const navigate = useNavigate();
	const myForm = useForm();
	const { fetchData, RequestUseCase, endpoints } = useAxios();
	const [archivos, setCurrentFiles] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [showError, setShowError] = useState(false);
	const [loadingData, setLoadingData] = useState(false);
	const [currentRelative, setCurrentRelative] = useState({
		idParentesco: '',
		idGenero: '',
		edad: '',
		nombreCompleto: ''
	});
	// get url id
	const { id } = useParams();
	const [familiares, setFamiliares] = useState([]);
	const [selectData, setSelectData] = useState({});

	const [currentEmployee, setCurrentEmployee] = useState(null);
	const [currentFamiliares, setCurrentFamiliares] = useState([]);

	const { primaryMinimalTable, setPrimaryMinimalTable, secondMinimalTable, setSecondMinimalTable } =
		useSeachContext();
	const now = new Date();
	// useLanguage
	const { formatterText, noFilledContent, newItemCreated, successRemoveItem, resourceNotFound } =
		useLangv2();
	// Helps to loading data table
	const { DisplayProgress } = useProgress();

	useEffect(() => {
		getData();
		getEmployeeFromApi(id);
	}, []);

	useEffect(() => {
		getData();
		getEmployeeFromApi(id);
	}, [id]);

	const getData = () => {
		setLoadingData(true);

		const promesas = [
			RequestUseCase.get(endpoints.institutions.getAllKin),
			RequestUseCase.get(endpoints.institutions.getAllTypeDocument),
			RequestUseCase.get(endpoints.institutions.getAllGenres),
			RequestUseCase.get(endpoints.institutions.getAllHalthEntities),
			RequestUseCase.get(endpoints.institutions.getAllbanks),
			RequestUseCase.get(endpoints.institutions.getAllTypeAccounts),
			RequestUseCase.get(endpoints.zones.getAllMunicipalities),
			RequestUseCase.get(endpoints.cargos.getAllCargos),
			RequestUseCase.get(endpoints.contracts.getAllContracts)
		];

		Promise.all(promesas)
			.then((values) => {
				setSelectData({
					...selectData,
					documentTypes: values[1],
					genres: values[2],
					municipalities: values[6],
					contracts: FilterActive(values[8]),
					positions: FilterActive(values[7]),
					healthEntities: values[3],
					banks: values[4],
					typeAccount: values[5],
					kins: values[0]
				});
			})
			.finally(() => setLoadingData(false));
	};
	//get employee's data from api
	const getEmployeeFromApi = (idEmployee) => {
		RequestUseCase.get(endpoints.employees.getEmployeeById(idEmployee))
			.then((res) => {
				if (res !== null) {
					getDataEmployee(res);
				} else {
					resourceNotFound();
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const getDataEmployee = (obj) => {
		getDataAttachedFiles(obj.idEmpleado);
		if (obj) {
			RequestUseCase.get(endpoints.associateDataTechnical.getUserIdbyIdEmployee(obj.idEmpleado))
				.then((res) => {
					getAllAssociated(res.idUsuario);
					const data = {
						...obj,
						idTipoDocumento: obj.idTipoDocumento,
						identificacion: obj.identificacion,
						idUsuario: res.idUsuario,
						salario: parseInt(obj.salario),
						rodamiento: parseInt(obj.rodamiento)
					};
					setCurrentEmployee(data);
				})
				.catch(() => {
					const data = {
						...obj,
						idUsuario: null
					};
					setCurrentEmployee(data);
				});
		}

		RequestUseCase.get(endpoints.employees.getFamiltybyEmployee(obj.idEmpleado))
			.then((res) => {
				const familiaresAll = res.map((item) => {
					return {
						edad: item.edad,
						nombreCompleto: item.nombre,
						idGenero: {
							idGenero: item.genero.idGenero,
							nombre: item.genero.nombre,
							abreviatura: item.genero.abreviatura
						},
						idParentesco: {
							idParentesco: item.parentesco.idParentesco,
							nombre: item.parentesco.nombre
						},
						idFamiliares: item.idFamiliares
					};
				});

				setFamiliares(familiaresAll);
				setCurrentFamiliares(familiaresAll);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllFamilies = (idFam) => {
		RequestUseCase.get(endpoints.employees.getFamiltybyEmployee(idFam))
			.then((res) => {
				const familiaresAll = res.map((item) => {
					return {
						edad: item.edad,
						nombreCompleto: item.nombre,
						idGenero: {
							idGenero: item.genero.idGenero,
							nombre: item.genero.nombre,
							abreviatura: item.genero.abreviatura
						},
						idParentesco: {
							idParentesco: item.parentesco.idParentesco,
							nombre: item.parentesco.nombre
						},
						idFamiliares: item.idFamiliares
					};
				});

				setFamiliares(familiaresAll);
				setCurrentFamiliares(familiaresAll);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleSubmit = (values) => {
		if (showError) {
			return;
		}
		const data = {
			idEmpleado: currentEmployee.idEmpleado,
			idTipoDocumento: {
				idTipoDocumento:
					typeof values.idTipoDocumento === 'object'
						? values.idTipoDocumento.value
						: values.idTipoDocumento
			},
			primerNombre: values.primerNombre,
			segundoNombre: values.segundoNombre,
			primerApellido: values.primerApellido,
			segundoApellido: values.segundoApellido,
			identificacion: values.identificacion,
			fechaNacimiento: dateFormat(values.fechaNacimiento, 'isoDateTime'),
			idMunicipio: { idMunicipio: values.idMunicipio.value },
			direccion: values.direccion,
			telefono: values.telefono,
			correo: values.correo,
			genero: { idGenero: values.genero.value },
			fechaIngreso: dateFormat(values.fechaIngreso, 'isoDateTime'),
			fechaAfiliacionArl: dateFormat(values.fechaAfiliacionArl, 'isoDateTime'),
			observacion: values.observacion,
			cargo: { idCargo: values.cargo.value },
			tipoContrato: { idTipoContrato: values.tipoContrato.value },
			entidadSalud: { idEntidadSalud: values.entidadSalud.value },
			banco: { idBanco: values.banco.value },
			numeroCuenta: values.numeroCuenta,
			tipoCuenta: { idTipoCuenta: values.tipoCuenta.value },
			estado: 1,
			nombreFamiliar: values.nombreFamiliar,
			telefonoFamiliar: values.telefonoFamiliar,
			salario: convertToNumber(values?.salario || 0),
			rodamiento: convertToNumber(values?.rodamiento || 0),
			fechaRegistro: currentEmployee.fechaRegistro,
			fechaModificacion: null,
			usuarioCreacion: Cookie.get('idUsuario'),
			usuarioModificacion: Cookie.get('idUsuario')
		};

		handleSaveEmployee(data);
	};

	const convertToNumber = (value) => {
		//convert a $1.234.56 to 123456
		//fix the error use consise character class syntax '\d' instead of '[0-9]'
		try {
			return parseInt(value.replace(/[^0-9]/g, ''));
		} catch (error) {
			return parseInt(value);
		}
	};

	const handleAddFamily = () => {
		if (
			currentRelative.nombreCompleto !== '' &&
			currentRelative.edad !== '' &&
			currentRelative.idParentesco !== '' &&
			currentRelative.idGenero !== ''
		) {
			const DATA = {
				idFamiliares: null,
				genero: {
					idGenero: currentRelative.idGenero.idGenero
				},
				empleado: {
					idEmpleado: parseInt(id)
				},
				parentesco: {
					idParentesco: currentRelative.idParentesco.idParentesco
				},
				nombre: currentRelative.nombreCompleto,
				edad: currentRelative.edad
			};

			RequestUseCase.post(endpoints.employees.addFamily, DATA)
				.then(() => {
					newItemCreated();
					getAllFamilies(parseInt(id));
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			noFilledContent();
		}
	};

	const handleChangeRelative = (e) => {
		const NAME_REGEX = /^[a-zA-ZÀ-ÿ\u00f1\u00d1 ]*$/;
		if (e.target.value.match(NAME_REGEX)) {
			setCurrentRelative({
				...currentRelative,
				[e.target.name]: e.target.value
			});
		}
	};

	const handleChangeNumberRelative = (e) => {
		const NUMBERREGEX = /^[0-9]*$/;
		if (e.target.value.match(NUMBERREGEX)) {
			setCurrentRelative({
				...currentRelative,
				[e.target.name]: e.target.value
			});
		}
	};

	const handleChangeSelect = (selectedOption) => {
		setCurrentRelative({
			...currentRelative,
			[selectedOption.target.name]:
				selectedOption.target.value === ''
					? selectedOption.target.value
					: JSON.parse(selectedOption.target.value)
		});
	};

	const handleSaveEmployee = (data) => {
		Swal.fire({
			title: formatterText('alert.title.general', 'Atención, estás seguro de realizar esta acción'),
			text: formatterText('alert.description.update.general', 'Se va a editar el registro'),
			icon: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			showLoaderOnConfirm: true,
			cancelButtonColor: '#d33',
			confirmButtonText: formatterText('alert.button.confirm.general', 'Guardar cambios'),
			allowOutsideClick: false,
			cancelButtonText: formatterText('alert.button.cancel.general', 'Cancelar'),
			preConfirm: () => {
				return new Promise((resolve, reject) => {
					RequestUseCase.put(endpoints.employees.updateEmployee(data.idEmpleado), data)
						.then(() => {
							handleDeleteAssociation(resolve, reject);

							CustomAlert('confirm_msg', {
								icon: 'success',
								title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
								text: formatterText(
									'alert.message.confirm.updated.general',
									'El registro se ha actualizado correctamente'
								),
								confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
								allowOutsideClick: false,
								executeFunction: () => navigate(paths.employees)
							});
						})
						.catch((err) => {
							if (err.response?.data?.message) {
								HandleOnError(formatterText(err.response?.data?.message));
							} else {
								HandleOnError(
									formatterText(
										'snackbar.error.process.failed.general',
										'Error al realizar el proceso. Intentalo en otro momento.'
									)
								);
							}
						});
				});
			}
		});
	};

	//Sprint 8 Asociaciones
	const handleDeleteAssociation = (resolve, reject) => {
		const dataToDelete = getDiference(primaryMinimalTable, prevPrimaryMinimalTable);
		if (dataToDelete.length > 0) {
			const promesas = dataToDelete.forEach((item) => {
				RequestUseCase.delete(
					endpoints.associateDataTechnical.deleteUnityBusiness(item.idAssociate)
				)
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			});
			Promise.all(promesas)
				.then(() => {
					deleteAssociateSucursal(resolve, reject);
				})
				.catch((err) => {
					if (err.response.status === 412) {
						reject(HandleOnError(err.response.data));
					} 
				});
		} else {
			deleteAssociateSucursal(resolve, reject);
		}
	};

	const deleteAssociateSucursal = (resolve, reject) => {
		const data = getDiference(secondMinimalTable, prevSecondMinimalTable);
		if (data.length > 0) {
			const promises = data.forEach((item) => {
				RequestUseCase.delete(endpoints.associateDataTechnical.deleteSucursal(item.idAssociate))
					.then((res) => {
						resolve(res);
					})
					.catch((err) => {
						reject(err);
					});
			});

			Promise.all(promises)
				.then(() => {
					handleAddAssociation();
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			handleAddAssociation();
		}
	};

	const handleAddAssociation = () => {
		const newDataToAdd = getNewData(primaryMinimalTable, prevPrimaryMinimalTable);
		if (newDataToAdd.length > 0) {
			const promesas = newDataToAdd.forEach((item) => {
				const data = {
					idUsuario: currentEmployee.idUsuario,
					idUnidadNegocio: item.id
				};
				return RequestUseCase.post(endpoints.associateDataTechnical.addUnityBusiness, data);
			});
			Promise.all(promesas)
				.then(() => {
					postAssociateSucursal();
				})
				.catch((err) => {
					if (err.response && err.response.status === 412) {
						console.error('Error 412:', err);
						HandleOnError(err.response.data);
					} else {
						console.error('Error:', err);
						HandleOnError(formatterText('alert.title.employee.edit.deleted.error'));
					}
				});
		} else {
			postAssociateSucursal();
		}
	};

	const postAssociateSucursal = () => {
		const data = getNewData(secondMinimalTable, prevSecondMinimalTable);
		if (data.length > 0) {
			const promises = data.map((item) => {
				const data = {
					idUsuario: currentEmployee.idUsuario,
					idSucursal: item.id
				};
				return RequestUseCase.post(endpoints.associateDataTechnical.addSucursal, data);
			});
			Promise.all(promises)
				.then(() => {
					CustomAlert('confirm_msg', {
						icon: 'success',
						title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
						text: formatterText(
							'alert.message.confirm.updated.general',
							'El registro se ha actualizado correctamente'
						),
						confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
						allowOutsideClick: false,
						executeFunction: () => navigate(paths.employees)
					});
				})
				.catch((err) => {
					if (err.response && err.response.status === 412) {
						console.error('Error 412:', err);
						HandleOnError(err.response.data);
					} else {
						console.error('Error:', err);
						HandleOnError(formatterText('alert.title.technician.creation.incomplete'));
					}
				});
		} else {
			CustomAlert('confirm_msg', {
				icon: 'success',
				title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
				text: formatterText(
					'alert.message.confirm.updated.general',
					'El registro se ha actualizado correctamente'
				),
				confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
				allowOutsideClick: false,
				executeFunction: () => navigate(paths.employees)
			});
		}
	};

	const [prevPrimaryMinimalTable, setPrevPrimaryMinimalTable] = useState([]);
	const [prevSecondMinimalTable, setPrevSecondMinimalTable] = useState([]);

	useEffect(() => {
		if (
			myForm?.values?.correo !== '' &&
			myForm?.values?.correo !== undefined &&
			myForm?.values?.correo !== null
		) {
			if (!myForm.values?.correo.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
				setShowError(true);
			} else {
				setShowError(false);
			}
		}
	}, [myForm?.values?.correo]);

	// funcion que devuelva los datos que no se encuentran en un array buscando por un id
	const getDiference = (array2, array) => {
		const newArray = [];
		array.forEach((item) => {
			const found = array2.find((element) => element.id === item.id);
			if (found === undefined) {
				newArray.push(item);
			}
		});
		return newArray;
	};

	// funcion que devuelva los datos nuevos que se encuentran en un array buscando por un id y que no se encuentran en el otro array
	const getNewData = (array2, array) => {
		const newArray = [];
		array2.forEach((item) => {
			const found = array.find((element) => element.id === item.id);
			if (found === undefined) {
				newArray.push(item);
			}
		});
		return newArray;
	};

	const getAllAssociated = (idUser) => {
		if (idUser !== null) {
			RequestUseCase.get(endpoints.associateDataTechnical.getUnityBusinessById(idUser)).then(
				(res) => {
					const newArray = [];
					if (res.length > 0) {
						const data = structurePrimaryMinimalTable(res, newArray);
						setPrimaryMinimalTable(data);
						setPrevPrimaryMinimalTable(data);
					}
				}
			);
			RequestUseCase.get(endpoints.associateDataTechnical.getSucursalById(idUser)).then((res) => {
				const newArray = [];
				if (res.length > 0) {
					const data = structureSecondaryMinimalTable(res, newArray);
					setSecondMinimalTable(data);
					setPrevSecondMinimalTable(data);
				}
			});
		}
	};

	const structurePrimaryMinimalTable = (data, array) => {
		data.forEach((item) => {
			array.push({
				idAssociate: item.idUsuarioUnidadNegocio,
				id: item.idUnidadNegocio,
				unityName: item.nombreUnidadNegocio,
				code: item.codigoUnidadNegocio,
				market: item.nombreMercado !== null ? item.nombreMercado : 'Sin mercado asociado',
				country: item.nombrePais !== null ? item.nombrePais : 'Sin país asociado'
			});
		});
		return array;
	};

	const structureSecondaryMinimalTable = (data, array) => {
		data.forEach((item) => {
			array.push({
				idAssociate: item.idUsuarioSucursal,
				id: item.idSucursal,
				nombreCliente: item.nombreRazonSocial,
				tipoId: {
					value: '',
					label: item.abreviaturaTipoDocumento
				},
				numberId: item.identificacionCliente,
				citySucursal: {
					value: '',
					label: item.nombreMunicipio
				},
				departamento: {
					value: '',
					label: item.nombreDepartamento
				},
				pais: {
					value: '',
					label: item.nombrePais
				}
			});
		});
		return array;
	};

	const uploadNewFile = async (file) => {
		fetchData({
			url: endpoints.UploadFiles.save,
			method: 'post',
			body: {
				idOrigen: currentEmployee.idEmpleado,
				idTipoOrigenArchivo: 5,
				archivos: [file]
			}
		}).then(() => {
			CustomAlert('confirm_msg', {
				icon: 'success',
				title: formatterText('alert.title.confirm.general', 'Operación exitosa'),
				text: formatterText('alert.title.confirm.add.files'),
				confirmButtonText: formatterText('alert.button.continue', 'Continuar'),
				allowOutsideClick: false,
				executeFunction: () => getDataAttachedFiles(id)
			});
		});
	};

	const getDataAttachedFiles = async (id) => {
		RequestUseCase.get(endpoints.UploadFiles.findArchivosById({
				idOrigen: id,
				idTipoOrigenArchivo: 5
			}),
		).then((response) => {
			const files = response.map((file) => {
				return {
					name: file,
					url: file
				};
			});
			setCurrentFiles(files);
		});
	};

	return {
		archivos,
		currentEmployee,
		currentFamiliares,
		DisplayProgress,
		familiares,
		formatterText,
		getAllFamilies,
		handleAddFamily,
		handleChangeNumberRelative,
		handleChangeRelative,
		handleChangeSelect,
		handleSubmit,
		id,
		isLoading,
		loadingData,
		myForm,
		navigate,
		now,
		primaryMinimalTable,
		secondMinimalTable,
		selectData,
		setCurrentFiles,
		setIsLoading,
		setPrimaryMinimalTable,
		setSecondMinimalTable,
		showError,
		successRemoveItem,
		uploadNewFile
	};
}
