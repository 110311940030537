import React, { useEffect, useState } from 'react';
// Import Components
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MdRemoveCircleOutline } from 'react-icons/md';
// Import Libs
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import LANGS from '../../lang/define';
// Import Styles
import styles from './DatePicker.module.css';

export default function DatePicker({
	placeholder,
	name,
	selectValue,
	setterFunction,
	noIcon,
	min = null,
	max = null,
	refresh
}) {
	const [value, setValue] = useState(selectValue || null);

	useEffect(() => {
		setValue(selectValue);
		setLangState(LANGS[localStorage.getItem('lang')]);
	}, [localStorage.getItem('lang')]);
	// state of the language
	const [langState, setLangState] = useState(LANGS[localStorage.getItem('lang')]);

	useEffect(() => {
		if (refresh) {
			setValue(selectValue);
		}
	}, [selectValue]);

	const handleChange = (newValue) => {
		setValue(newValue);
		if (newValue === null) {
			setterFunction((prev) => ({
				...prev,
				[name]: null
			}));
		} else {
			setterFunction((prev) => ({
				...prev,
				[name]: newValue
			}));
		}
	};

	return (
		<>
			<LocalizationProvider adapterLocale={langState} dateAdapter={AdapterDateFns}>
				<DesktopDatePicker
					label={placeholder}
					id={name}
					className={styles.container}
					sx={{ padding: 6, margin: 0 }}
					classes={{
						option: styles.menuItem,
						listbox: styles.menuList,
						noOptions: styles.noOptions,
						groupLabel: styles.headerItem,
						notchedOutline: styles.notchedOutline,
						input: styles.inputData
					}}
					value={value}
					onChange={handleChange}
					renderInput={(params) => <TextField {...params} />}
					minDate={min}
					maxDate={max}
				/>
			</LocalizationProvider>
			{!noIcon && value && (
				<MdRemoveCircleOutline
					size={25}
					color="gray"
					cursor="pointer"
					style={{ marginLeft: '10px' }}
					onClick={(e) => {
						e.preventDefault();
						setValue(null);
						setterFunction((prev) => ({
							...prev,
							[name]: null
						}));
					}}
				/>
			)}
		</>
	);
}
