import { Formiz, FormizStep } from '@formiz/core';
import { isEmail, isMaxLength, isNotEmptyString, isPattern } from '@formiz/validations';
import { InputFieldResponsive } from 'common/inputs/InputFieldResponsive';
import InputSelectorResponsive from 'common/inputs/InputSelectorResponsive';
import { URLREGEX } from 'common/validators/Regex';
import React from 'react';
import paths from 'services/paths';
import Attachments from '../../../components/AttachedFiles/Attachments';
import UpdateProviderViewModel from './UpdateProvider.viewModel';

const UpdateProvider = () => {
	const {
		formatterText,
		isLoading,
		loadingData,
		selectData,
		archivos,
		setCurrentFiles,
		currentProvider,
		handleSubmit,
		myForm,
		uploadNewFile,
		navigate
	} = UpdateProviderViewModel();
	return (
		<div className="centered-form">
			<Formiz onValidSubmit={handleSubmit} connect={myForm}>
				<form
					noValidate
					onSubmit={myForm.submit}
					className="container-wrapForm"
					style={{ minHeight: '16rem' }}
				>
					{currentProvider && (
						<>
							<div className="new-container-wrapForm__tabs">
								{myForm.steps.map((step) => (
									<button
										key={step.name}
										className={`new-tab-option ${
											step.name === myForm.currentStep.name ? 'is-active' : ''
										}`}
										type="button"
										onClick={() => myForm.goToStep(step.name)}
									>
										{!step.isValid && step.isSubmitted && <small className="mr-2">⚠️</small>}
										{formatterText(step.label)}
									</button>
								))}
							</div>

							<div className="container-wrapForm-content">
								<FormizStep name="step1" label="tab.title.provider.general.info">
									<div className="title-section">
										<span className="circle-form">
											<span>1</span>
										</span>
										<h2>{formatterText('p.provider.general.data')}</h2>
									</div>

									<section className="grid-container-3c">
										<InputSelectorResponsive
											type="text"
											name="idTipoDocumento"
											placeholder="Tipo de documento"
											labelText={formatterText('p.document.type')}
											required={formatterText('p.document.type.is.required')}
											defaultValue={{
												label: `${currentProvider.idTipoDocumento.nombre} - ${currentProvider.idTipoDocumento.abreviatura}`,
												value: currentProvider.idTipoDocumento.idTipoDocumento
											}}
											data={
												!!selectData.documentTypes &&
												selectData.documentTypes.map((item) => {
													const docLabel = `${item.nombre} - ${item.abreviatura}`;
													return {
														label: docLabel,
														value: { value: item.idTipoDocumento }
													};
												})
											}
											isLoading={loadingData}
										/>

										<InputFieldResponsive
											type="integer"
											name="identificacion"
											labelText={formatterText('p.id.identification')}
											placeholder="Identificación"
											validateInput="integer"
											required={formatterText('p.id.is.required')}
											defaultValue={currentProvider ? currentProvider.identificacion : ''}
											validations={[
												{
													rule: isMaxLength(15),
													message: `${formatterText('p.id.max.chars')}`
												},
												{
													rule: isPattern(/^[0-9]*$/),
													message: `${formatterText('p.only.numbers.allowed')}`
												}
											]}
										/>

										<InputFieldResponsive
											type="text"
											name="nombreRazonSocial"
											labelText={formatterText('p.name.social.reason')}
											placeholder="Nombres o razón social"
											validateInput="text"
											required={formatterText('p.name.or.compName.is.required')}
											defaultValue={currentProvider ? currentProvider.nombreRazonSocial : ''}
										/>

										<InputFieldResponsive
											type="text"
											name="sitioWeb"
											labelText={formatterText('table.title.website')}
											placeholder={formatterText('table.title.website')}
											defaultValue={currentProvider ? currentProvider.sitioWeb : ''}
											validations={[
												{
													rule: isPattern(URLREGEX),
													message: `${formatterText('p.url.not.valid')}`
												}
											]}
										/>

										<InputFieldResponsive
											type="number"
											name="telefono"
											labelText={formatterText('p.tlf.label')}
											placeholder="Teléfono*"
											validateInput="text"
											required={formatterText('p.phone.is.required')}
											defaultValue={currentProvider ? currentProvider.telefono : ''}
											validations={[
												{
													rule: isMaxLength(12),
													message: `${formatterText('p.phone.max.chars')}`
												},
												{
													rule: isPattern(/^[0-9]*$/),
													message: `${formatterText('p.only.numbers.allowed')}`
												}
											]}
										/>
										<InputFieldResponsive
											type="number"
											name="telefonoAlterno"
											labelText={formatterText('p.alt.phone')}
											placeholder={formatterText('input.placeholder.alt.phone')}
											validateInput="text"
											defaultValue={currentProvider ? currentProvider.telefonoAlterno : ''}
											validations={[
												{
													rule: isMaxLength(12),
													message: `${formatterText('p.phone.max.chars')}`
												},
												{
													rule: isPattern(/^[0-9]*$/),
													message: `${formatterText('p.only.numbers.allowed')}`
												}
											]}
										/>
										<InputFieldResponsive
											type="email"
											name="correo"
											labelText={formatterText('p.email.required')}
											placeholder="Ingresa el correo"
											required={formatterText('p.email.is.required')}
											defaultValue={currentProvider ? currentProvider.correo : ''}
											validateInput="email"
											validations={[
												{
													rule: isEmail(),
													message: `${formatterText('p.email.invalid')}`
												}
											]}
										/>
										<InputSelectorResponsive
											type="text"
											name="idTipoProveedor"
											labelText={formatterText('p.provider.type.required')}
											placeholder="Selecciona el tipo de proveedor"
											required={formatterText('p.provider.type.is.required')}
											defaultValue={{
												label: `${currentProvider.idTipoProveedor.nombre}`,
												value: currentProvider.idTipoProveedor.idTipoProveedor
											}}
											data={
												!!selectData.genres &&
												selectData.genres.map((item) => ({
													label: `${item.nombre}`,
													value: {
														value: item.idTipoProveedor
													}
												}))
											}
											isLoading={loadingData}
										/>
									</section>

									<div className="title-section">
										<span className="circle-form">
											<span>1.1</span>
										</span>
										<h2>{formatterText('p.provider.location.data')}</h2>
									</div>

									<section
										className="grid-container-2c"
										style={{
											width: '100%'
										}}
									>
										<InputSelectorResponsive
											type="text"
											name="idMunicipio"
											labelText={formatterText('p.municipality.or.city.required')}
											placeholder="Ciudad o municipio"
											required={formatterText('p.city.or.municipality.is.required')}
											defaultValue={{
												label: `${currentProvider.idMunicipio.nombre} - ${currentProvider.idMunicipio.idDepartamento.nombre} - ${currentProvider.idMunicipio.idDepartamento.idPais.nombrePais}`,
												value: currentProvider.idMunicipio.idMunicipio
											}}
											data={
												!!selectData.municipalities &&
												selectData.municipalities.map((item) => ({
													label: `${item.nombre} - ${item.idDepartamento.nombre} - ${item.idDepartamento.idPais.nombrePais} `,
													value: { value: item.idMunicipio }
												}))
											}
											isLoading={loadingData}
										/>

										<InputFieldResponsive
											type="text"
											name="direccion"
											labelText={formatterText('table.title.address.required')}
											placeholder="Dirección completa"
											// validateInput="text"
											required={formatterText('p.adress.is.required')}
											styleName="input-default-3c"
											defaultValue={currentProvider ? currentProvider.direccion : ''}
											validations={[
												{
													rule: isNotEmptyString(),
													message: `${formatterText('p.adress.not.empty')}`
												}
											]}
										/>
									</section>
									<h5 style={{ marginLeft: '3rem' }}>{formatterText('p.required.fields')}</h5>
								</FormizStep>
								<FormizStep name="step2" label="tab.title.attached.data">
									<Attachments
										currentFiles={archivos}
										setCurrentFiles={setCurrentFiles}
										isEdited={true}
										uploadNewFile={uploadNewFile}
										type={3}
										showParameters={true}
									/>
								</FormizStep>
								<input type="submit" id="submit-form" style={{ visibility: 'hidden' }} />
							</div>
						</>
					)}
				</form>
			</Formiz>

			<div className="demo-form__footer">
				<section className="form-responsive-container-buttons">
					<button style={{ padding: '0px' }} className="btn-primary">
						<label className="btn-wrap-add" htmlFor="submit-form" tabIndex="0">
							{isLoading ? 'Loading...' : formatterText('btn.save.changes')}
						</label>
					</button>

					<button className="input-cancel" onClick={() => navigate(paths.providers)}>
						{formatterText('btn.cancel')}
					</button>
				</section>
			</div>
		</div>
	);
};

export default UpdateProvider;
