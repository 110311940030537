import { formatterText } from 'hooks/useLangv2';
import React from 'react';
import { ModalDetailsProps } from './ModalDetails.props';
import ModalDetailsViewModel from './ModalDetails.viewModel';

const ModalDetails = ({ item }) => {
	const { styleTittle, hashTittles, hashValues } = ModalDetailsViewModel(item);

	return (
		<div className="modal-detail-container">
			<div>
				<h2>{formatterText('title.service.search.manual.table.modal.details.details')}</h2>
			</div>
			<div>
				{hashTittles.map((tittle, i) => {
					return (
						<div
							key={i}
							style={{
								display: 'flex'
							}}
						>
							<p style={styleTittle}>{tittle} : </p>
							<p
								style={{
									marginLeft: '10px'
								}}
							>
								{hashValues[i]}
							</p>
						</div>
					);
				})}
			</div>
		</div>
	);
};

ModalDetails.propTypes = ModalDetailsProps;

export default ModalDetails;
