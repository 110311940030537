import React from 'react';
// Import Context
import MultiTableMinimal from 'common/minimalTables/MultiTableMinimal';
// Import Components
import { Formiz, FormizStep } from '@formiz/core';
import { isMaxLength } from '@formiz/validations';
import { InputField } from 'common/InputField';
import Table from 'common/minimalTables/Table';
import Selector from 'common/selects/Selector';
import { FormattedMessage } from 'react-intl';
import paths from 'services/paths';
import ServiceActivityTable from '../ServiceActivityTable';
import UpdateActivityViewModel from './UpdateActivity.viewModel';

// Table 2 tab
const titlesTableCostProduct = [
	'Actividad relacionada',
	'Tipo de servicio',
	'Etapa de servicio',
	'Gestionar por técnico',
	'Tiempo de actividad',
	'Descripción',
	'Acciones'
];

// Table 3 tab
const titlesTableTypeServiceActivity = [
	'Nombre formulario',
	'Descripción',
	'Código formulario',
	'Cliente asociado',
	'Identificación cliente',
	'Acciones'
];

function UpdateActivityComponent() {
	const {
		myForm,
		currentDataForm,
		setCurrentDataForm,
		active,
		setActive,
		auxData,
		setAuxData,
		currentItemMinimal,
		currentDataTable,
		selectedSearch,
		loading,
		loadingProgress,
		searchResults,
		handleDelete,
		dialog,
		setDialog,
		closeDialog,
		handleAddActivityFormClient,
		handleAddItemMinimal,
		handleText,
		handleNumber,
		handleSubmit,
		navigate,
		DisplayProgress
	} = UpdateActivityViewModel();

	return (
		<div className="centered-form">
			<Formiz onValidSubmit={handleSubmit} connect={myForm}>
				<form
					noValidate
					onSubmit={myForm.submit}
					className="container-wrapForm"
					style={{ minHeight: '16rem' }}
				>
					{currentDataForm && (
						<>
							<div className="new-container-wrapForm__tabs">
								{myForm.steps.map((step) => (
									<button
										key={step.name}
										className={`new-tab-option ${
											step.name === myForm.currentStep.name ? 'is-active' : ''
										}`}
										type="button"
										onClick={() => myForm.goToStep(step.name)}
									>
										{step.label}
										{!step.isValid && step.isSubmitted && <small className="mr-2">⚠️</small>}
									</button>
								))}
							</div>

							<div className="container-wrapForm-content">
								<FormizStep name="step1" label="Información de la actividad">
									<section className="form-responsive-container-information">
										<InputField
											type="text"
											name="nombre"
											labelText="Nombre de la actividad"
											placeholder="Nombre de la actividad"
											validateInput="namestage"
											required="El nombre de la actividad es requerida"
											defaultValue={currentDataForm ? currentDataForm.nombre : ''}
											validations={[
												{
													rule: isMaxLength(45),
													message: 'El nombre de la actividad debe tener como máximo 45 caracteres'
												}
											]}
											styleName="input-primary-wrap-large"
										/>
										<InputField
											type="text"
											name="descripcion"
											labelText="Descripción"
											placeholder="Descripción"
											validateInput="text"
											defaultValue={currentDataForm ? currentDataForm.descripcion : ''}
											validations={[
												{
													rule: isMaxLength(200),
													message:
														'La descripción de la actividad debe tener como máximo 200 caracteres'
												}
											]}
											styleName="input-primary-wrap-large"
										/>
										<InputField
											type="text"
											name="sku"
											labelText="Sku"
											placeholder="Sku"
											validateInput="text"
											required="El Sku es requerido"
											defaultValue={currentDataForm ? currentDataForm.sku : ''}
											styleName="input-primary-wrap-large"
										/>

										<label className="wrapForm__label">
											<span className="warpForm-text">Estado</span>
											<label className="form-responsive-label input-primary-wrap-toggle">
												<p className="form-responsive-toggle">{active ? 'Activo' : 'No activo'}</p>
												<label className="switch">
													<input
														checked={active ? true : false}
														onChange={() => {
															setActive(!active);
														}}
														type="checkbox"
													/>
													<span className="slider round"></span>
												</label>
											</label>
										</label>

										<div className="demo-form__footer">
											<section className="form-responsive-container-buttons">
												<button type="submit" style={{ padding: '0px' }} className="btn-primary">
													Guardar cambios
												</button>
												<button className="input-cancel" onClick={() => navigate(paths.services)}>
													Cancelar
												</button>
											</section>
										</div>

										<div style={{ width: '90%', marginTop: '15px' }}>
											<ServiceActivityTable
												setActive={setActive}
												setCurrentDataForm={setCurrentDataForm}
												myForm={myForm}
												currentDataForm={currentDataForm}
												edit={true}
												setActionToggle={null}
											/>
										</div>
									</section>
								</FormizStep>
								<FormizStep name="step2" label="Asignar actividades a servicios">
									<form>
										<p className="wrap-form-title">Actividad</p>
										<section className="form-responsive-container-information">
											<section className="form-responsive-information__option">
												<h3>Categoría de servicio</h3>
												<Selector
													name="idCategoriaServicio"
													data={selectedSearch.categoriasServicios}
													placeholder={
														<FormattedMessage
															id="input.placeholder.select"
															defaultMessage="Selecione una opción"
														/>
													}
													dataValue={auxData.idCategoriaServicio}
													setterFunction={setAuxData}
													isLoading={loading}
													isRequired={true}
												/>
											</section>
											<section className="form-responsive-information__option">
												<h3>Tipo de servicio</h3>
												<Selector
													name="idTipoServicio"
													data={selectedSearch.tipoServicio}
													placeholder={
														<FormattedMessage
															id="input.placeholder.select"
															defaultMessage="Selecione una opción"
														/>
													}
													dataValue={auxData.idTipoServicio}
													setterFunction={setAuxData}
													isLoading={loading}
													isRequired={true}
												/>
											</section>
										</section>
										{currentDataTable.length > 0 ? (
											<>
												<p className="wrap-form-title">Actividades asociadas</p>
												<section className="form-responsive-container-information">
													<Table
														data={currentDataTable}
														titles={titlesTableCostProduct}
														type="associationActivities"
													/>
												</section>
											</>
										) : (
											loadingProgress && <DisplayProgress />
										)}
										<p className="wrap-form-title">+ Asignar actividad</p>
										<section className="wrapForm w-100">
											<label className="wrapForm__label">
												<h3 className="spacing-r1">Actividad relacionada</h3>
												<Selector
													name="idActividad"
													data={selectedSearch.actividadesServicios}
													placeholder={
														<FormattedMessage
															id="input.placeholder.select"
															defaultMessage="Selecione una opción"
														/>
													}
													dataValue={auxData}
													setterFunction={setAuxData}
													isLoading={loading}
													isRequired={false}
												/>
											</label>
											<label className="wrapForm__label">
												<h3 className="spacing-r1">Etapa del servicio</h3>
												<Selector
													name="idEtapaServicio"
													data={selectedSearch.etapaServicios}
													placeholder={
														<FormattedMessage
															id="input.placeholder.select"
															defaultMessage="Selecione una opción"
														/>
													}
													dataValue={auxData}
													setterFunction={setAuxData}
													isLoading={loading}
													isRequired={false}
												/>
											</label>
											<label className="wrapForm__label">
												<h3 className="spacing-r1">Gestionar por técnico</h3>
												<Selector
													name="actividadTecnica"
													data={[
														{
															label: 'No',
															value: 0
														},
														{
															label: 'Sí',
															value: 1
														}
													]}
													placeholder={
														<FormattedMessage
															id="input.placeholder.select"
															defaultMessage="Selecione una opción"
														/>
													}
													dataValue={auxData}
													setterFunction={setAuxData}
													isLoading={loading}
													isRequired={false}
												/>
											</label>
											<label className="wrapForm__label">
												<h3 className="spacing-r1">Tiempo para el desarrollo de la actividad</h3>
												<input
													className="input-primary"
													type="text"
													name="tiempoActividad"
													value={currentItemMinimal.tiempoActividad}
													onChange={handleNumber}
													placeholder="Tiempo para el desarrollo de la actividad"
													maxLength="20"
												/>
											</label>
											<label className="wrapForm__label">
												<h3 className="spacing-r1">Descripción</h3>
												<input
													className="input-primary"
													type="text"
													name="descripcion"
													value={currentItemMinimal.descripcion}
													onChange={handleText}
													placeholder="Descripción"
													maxLength="200"
												/>
											</label>
										</section>
										<input
											onClick={handleAddItemMinimal}
											type="button"
											className="btn-primary btn-primary-center"
											value="Agregar actividad"
										/>
									</form>
								</FormizStep>
								<FormizStep name="step3" label="Formularios asociados">
									<form>
										<section className="form-responsive-container-information">
											<section className="form-responsive-information__option">
												<h3>Actividad</h3>
												<Selector
													name="idCategoriaServicio"
													data={selectedSearch.categoriasServicios}
													placeholder={
														<FormattedMessage
															id="input.placeholder.select"
															defaultMessage="Selecione una opción"
														/>
													}
													dataValue={auxData.idCategoriaServicio}
													setterFunction={setAuxData}
													isLoading={loading}
													isRequired={true}
													disabled={true}
												/>
											</section>
											<section className="form-responsive-information__option">
												<h3>Tipo de servicio</h3>
												<Selector
													name="idTipoServicio"
													data={selectedSearch.tipoServicio}
													placeholder={
														<FormattedMessage
															id="input.placeholder.select"
															defaultMessage="Selecione una opción"
														/>
													}
													dataValue={auxData.idTipoServicio}
													setterFunction={setAuxData}
													isLoading={loading}
													isRequired={true}
													disabled={true}
												/>
											</section>
										</section>
										{searchResults.length > 0 ? (
											<section className="form-responsive-container-information">
												<span className="title-table">Formulario asociados</span>
												<MultiTableMinimal
													titles={titlesTableTypeServiceActivity}
													data={searchResults}
													type="typeServiceActivity"
													handleDelete={handleDelete}
													dialog={dialog}
													setDialog={setDialog}
													closeDialog={closeDialog}
													canSearch={true}
												/>
											</section>
										) : (
											loadingProgress && <DisplayProgress />
										)}
										<p className="wrap-form-title">+ Asignar formulario</p>
										<section className="form-responsive-container-information">
											<section className="form-responsive-information__option">
												<h3>Tipo servicio actividad</h3>
												<Selector
													name="idTypeServiceActivity"
													data={selectedSearch.typeServicesActivity}
													placeholder={
														<FormattedMessage
															id="input.placeholder.select"
															defaultMessage="Selecione una opción"
														/>
													}
													dataValue={auxData}
													setterFunction={setAuxData}
													isLoading={loading}
													isRequired={false}
												/>
											</section>
											<section className="form-responsive-information__option">
												<h3>Formulario</h3>
												<Selector
													name="idFormulario"
													data={selectedSearch.formsAssociate}
													placeholder={
														<FormattedMessage
															id="input.placeholder.select"
															defaultMessage="Selecione una opción"
														/>
													}
													dataValue={auxData}
													setterFunction={setAuxData}
													isLoading={loading}
													isRequired={false}
												/>
											</section>
											<section className="form-responsive-information__option">
												<h3>Cliente al que está asociado</h3>
												<Selector
													name="idCliente"
													data={selectedSearch.clientsAssociate}
													placeholder={
														<FormattedMessage
															id="input.placeholder.select"
															defaultMessage="Selecione una opción"
														/>
													}
													dataValue={auxData}
													setterFunction={setAuxData}
													isLoading={loading}
													isRequired={false}
												/>
											</section>
										</section>
										<input
											onClick={handleAddActivityFormClient}
											type="button"
											className="btn-primary btn-primary-center"
											value="Agregar formulario"
										/>
									</form>
								</FormizStep>
								<input type="submit" id="submit-form" style={{ visibility: 'hidden' }} />
							</div>
						</>
					)}
				</form>
			</Formiz>

			{/*     <div className="demo-form__footer">
          <section className="form-responsive-container-buttons">
            <button style={{ padding: "0px" }} className="btn-primary">
              <label className="btn-wrap-add" for="submit-form" tabindex="0">
                Guardar cambios
              </label>
            </button>

            <button
              className="input-cancel"
              onClick={() => navigate(paths.services)}
            >
              Cancelar
            </button>
          </section>
        </div> */}
		</div>
	);
}

export default UpdateActivityComponent;
