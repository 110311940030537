import React from 'react';

// Import Components
import CreateTechnicalCompnent from './CreateTechnical.component';

// Import Contexts
import { SearchWrapper } from 'context/SearchContext';

export default function CreateTechnical() {
	return (
		<SearchWrapper>
			<CreateTechnicalCompnent />
		</SearchWrapper>
	);
}
