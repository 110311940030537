import { fileExtAmount, maxSize, validExt } from 'common/validators/AttachmentsValidate';
import HandleOnError from 'common/validators/HandleOnError';
import { TIPOS_ARCHIVOS_PERMITIDOS_USUARIO } from 'constants/archivosAdjuntosUsuario';
import useAxios from 'hooks/useAxios';
import useLangv2 from 'hooks/useLangv2';
import React, { useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import endpoints from 'services/api';

/**  Componente para subir archivos
 * @param {object} currentFiles - Archivos actuales, se deben declarar en el
 componente padre (state)
 * @param {function} setCurrentFiles - Función para actualizar los archivos actuales,
 se deben declarar en el componente padre (setState)
 * @param {boolean} isEdited - Bandera para saber si el componente está en modo edición,
 para ejecutar la función de subir archivos directamente
 * @param {function} uploadNewFile - Función para subir archivos, se deben declarar en
 el componente padre, llamando al servicio de subir archivos
 * @returns {JSX.Element} Componente Attachments
 **/

const Attachments = ({
	showParameters,
	currentFiles,
	setCurrentFiles,
	isEdited,
	uploadNewFile,
	type = TIPOS_ARCHIVOS_PERMITIDOS_USUARIO.EMPLEADO
}) => {
	// use Hook of language v2
	const { fetchData, RequestUseCase } = useAxios();
	const reader = new FileReader();
	const ref = React.useRef(null);
	const { formatterText } = useLangv2();
	const [dataAux, setDataAux] = useState(null);
	const [filesActivate, setFilesActivate] = useState([]);

	const [dataToUpdate, setDataToUpdate] = useState(
		type === 6
			? [
					{
						idTipoUsuario: 6,
						tipoUsuario: 'servicio',
						tipoArchivoAdjuntos: [
							{
								idTipoArchivoAdjunto: 1,
								descripcion: 'edicion quemada',
								extension: '.xlsx',
								nombre: 'EXCEL',
								cantidadMaxima: 1,
								pesoMaximoArchivo: 3,
								estado: 1,
								fechaCreacion: '2023-02-03T03:51:34.544+00:00',
								fechaModificacion: '2023-06-26T10:24:52.147+00:00',
								usuarioCreacion: 58,
								usuarioModificacion: 1
							}
						]
					}
			  ]
			: type === 14 && [
					{
						idTipoUsuario: 13,
						tipoUsuario: 'servicio',
						tipoArchivoAdjuntos: [
							{
								idTipoArchivoAdjunto: 1,
								descripcion: 'edicion quemada',
								extension: '.jpg',
								nombre: 'JPG',
								cantidadMaxima: 3,
								pesoMaximoArchivo: 5,
								estado: 1,
								fechaCreacion: '2023-02-03T03:51:34.544+00:00',
								fechaModificacion: '2023-06-26T10:24:52.147+00:00',
								usuarioCreacion: 58,
								usuarioModificacion: 1
							},
							{
								idTipoArchivoAdjunto: 1,
								descripcion: 'edicion quemada',
								extension: '.tiff',
								nombre: 'TIFF',
								cantidadMaxima: 3,
								pesoMaximoArchivo: 5,
								estado: 1,
								fechaCreacion: '2023-02-03T03:51:34.544+00:00',
								fechaModificacion: '2023-06-26T10:24:52.147+00:00',
								usuarioCreacion: 58,
								usuarioModificacion: 1
							},
							{
								idTipoArchivoAdjunto: 1,
								descripcion: 'edicion quemada',
								extension: '.png',
								nombre: 'PNG',
								cantidadMaxima: 3,
								pesoMaximoArchivo: 5,
								estado: 1,
								fechaCreacion: '2023-02-03T03:51:34.544+00:00',
								fechaModificacion: '2023-06-26T10:24:52.147+00:00',
								usuarioCreacion: 58,
								usuarioModificacion: 1
							},
							{
								idTipoArchivoAdjunto: 1,
								descripcion: 'edicion quemada',
								extension: '.pdf',
								nombre: 'PDF',
								cantidadMaxima: 3,
								pesoMaximoArchivo: 5,
								estado: 1,
								fechaCreacion: '2023-02-03T03:51:34.544+00:00',
								fechaModificacion: '2023-06-26T10:24:52.147+00:00',
								usuarioCreacion: 58,
								usuarioModificacion: 1
							}
						]
					}
			  ]
	);
	const [filesPerExtension, setFilesPerExtension] = React.useState([]);
	const [namesSplitted, setNamesSplitted] = React.useState('');
	const [onlyAllowedFiles, setOnlyAllowedFiles] = React.useState('');

	const renderFiles = dataToUpdate[0]?.tipoArchivoAdjuntos;

	const quantityFilesPerExtension = () => {
		//a group renderFiles by extension, and count the quantity of files per extension
		const filesPerExtension = renderFiles.reduce((acc, file) => {
			const extension = file.extension.toLowerCase();
			acc[extension] = (acc[extension] || 0) + file.cantidadMaxima;
			return acc;
		}, {});
		setFilesPerExtension(filesPerExtension);
		const extensions = Object.keys(filesPerExtension).join(', ');
		setOnlyAllowedFiles(extensions);
	};

	const agropedQuantityByName = () => {
		const filesPerName = renderFiles.reduce((acc, file) => {
			const name = file.nombre;
			const extension = file.extension.toLowerCase();
			const pesoMaximoArchivo = file.pesoMaximoArchivo;
			acc[name] = {
				quantity: (acc[name] || 0) + file.cantidadMaxima,
				extension,
				pesoMaximoArchivo
			};
			return acc;
		}, {});
		// agroup the name like `name (quantity , extension) , name (quantity , extension) , name (quantity , extension)`,  a string
		const namesSplitted = Object.keys(filesPerName).reduce((acc, name) => {
			const { quantity, extension, pesoMaximoArchivo } = filesPerName[name];
			acc += `${name} (${formatterText('file.upload.max.amount')}:  ${quantity} , ${formatterText(
				'file.upload.extension.type'
			)}: ${extension.toLowerCase()} , ${formatterText(
				'file.upload.max.size'
			)}: ${pesoMaximoArchivo} mb) , `;
			return acc;
		}, '');
		//remove the last comma
		setNamesSplitted(namesSplitted.slice(0, -2));
	};

	useEffect(() => {
		RequestUseCase.get(endpoints.attachedFiles.getDatosToUpdate(type)).then((response) => {
			// response array original
			const filesActivate = response.map((tipoUsuario) => {
				// Filtrar solo los archivos con estado 1
				const filterFiles = tipoUsuario.tipoArchivoAdjuntos.filter(
					(archivo) => archivo.estado === 1
				);

				//Retornar un nuevo objeto con los aarchivos filtrados
				return {
					...tipoUsuario,
					tipoArchivoAdjuntos: filterFiles
				};
			});
			if (type === 14 || type === 6) {
				setDataToUpdate(dataToUpdate);
				setDataAux(dataToUpdate);
				setFilesActivate(dataToUpdate);
			} else {
				setDataToUpdate(filesActivate);
				setDataAux(filesActivate);
				setFilesActivate(filesActivate);
			}
		});
	}, []);

	useEffect(() => {
		if (dataToUpdate.length > 0) {
			quantityFilesPerExtension();
			agropedQuantityByName();
		}
	}, [dataToUpdate, dataAux]);

	const getExtension = (file) => {
		const onlyExtension = `.${
			file.name.lastIndexOf('.') > -1
				? file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length)
				: ''
		}`;
		return onlyExtension;
	};

	const upload = async (e) => {
		// Convert the FileList into an array and iterate
		const selectedFile = e.target.files[0];
		if (!selectedFile) return;
		const onlyExtension = getExtension(selectedFile);
		const msgB = formatterText('file.upload.size.message');

		if (selectedFile.size > 5000000) {
			HandleOnError(msgB);
			return;
		}

		const isExtValidate = validExt(
			filesPerExtension,
			onlyExtension,
			`${formatterText('file.upload.extension.message.generic')} ${onlyAllowedFiles}`
		);

		if (!isExtValidate) return;

		const extensionArchivoQnt = fileExtAmount(currentFiles, onlyExtension);

		//verify if the quantity of files is less than the quantity allowed per extension on filesPerExtension
		if (filesPerExtension[onlyExtension] <= extensionArchivoQnt) {
			HandleOnError(formatterText('file.upload.extension.message'));
			return;
		}
		reader.readAsDataURL(selectedFile);
		reader.onload = () => {
			const archivo = reader.result.split('base64,')[1];
			//obtener el tipo del archivo
			const extensionArchivo = getExtension(selectedFile);
			const name = selectedFile.name.substring(0, selectedFile.name.lastIndexOf('.'));
			setCurrentFiles([
				...currentFiles,
				{
					archivo,
					extensionArchivo: `${extensionArchivo.toLowerCase()}`,
					name
				}
			]);
		};
	};

	const uploadNewFileFn = async (e) => {
		const selectedFile = e.target.files[0];
		const onlyExtension = getExtension(selectedFile);
		maxSize(selectedFile, formatterText('file.upload.size.message'));
		const msg = `${formatterText('file.upload.extension.message.generic')} ${onlyAllowedFiles}`;
		const msgB = formatterText('file.upload.size.message');
		const extLast = onlyExtension.toLowerCase();

		if (selectedFile.size > 5000000) {
			HandleOnError(msgB);
			return;
		}

		if (!Object.keys(filesPerExtension).includes(extLast)) {
			HandleOnError(msg);
			// );'));
			return;
		}

		const currentFilesModified = currentFiles.map((item) => {
			const ext = getExtension(item);
			return { ...item, extensionArchivo: ext.toLocaleLowerCase() };
		});

		const extensionArchivoQnt = fileExtAmount(currentFilesModified, extLast);
		//verify if the quantity of files is less than the quantity allowed per extension on filesPerExtension
		if (filesPerExtension[extLast] <= extensionArchivoQnt) {
			HandleOnError(formatterText('file.upload.extension.message'));
			return;
		}

		reader.readAsDataURL(selectedFile);
		reader.onload = () => {
			const archivo = reader.result.split('base64,')[1];
			const extensionArchivo = getExtension(selectedFile);
			//quitarle la extension al selectedFile.name
			const name = selectedFile.name.substring(0, selectedFile.name.lastIndexOf('.'));

			uploadNewFile({
				archivo,
				extensionArchivo: `${extensionArchivo.toLowerCase()}`,
				name
			});

			setCurrentFiles([
				...currentFiles,
				{
					archivo,
					extensionArchivo: `${extensionArchivo.toLowerCase()}`,
					name
				}
			]);
		};
	};

	const pressButtonNotEditWay = (e, item) => {
		e.preventDefault();
		if (currentFiles.length === 1) {
			setCurrentFiles([]);
		} else {
			const index = currentFiles.indexOf(item);
			currentFiles.splice(index, 1);
			setCurrentFiles([...currentFiles]);
		}
	};

	const downloadFile = async (e, item) => {
		e.preventDefault();
		fetchData({
			url: endpoints.UploadFiles.download,
			method: 'post',
			body: { file: item.url }
		}).then((res) => {
			console.log(res);

			const extensionArchivo =
				item.url.lastIndexOf('.') > -1
					? item.url.substring(item.url.lastIndexOf('.') + 1, item.url.length)
					: '';
			const decoded = decodeBase64(res.response);
			functionToDownload(decoded, item.url, extensionArchivo.toLowerCase());
		});
	};

	const downloadFileOnCreate = (e, item) => {
		const fileName = item.name;
		const extension = item.extensionArchivo;
		const url = fileName + extension;

		const extensionArchivo =
			url.lastIndexOf('.') > -1 ? url.substring(url.lastIndexOf('.') + 1, url.length) : '';
		const decoded = decodeBase64(item.archivo);
		functionToDownload(decoded, url, extensionArchivo.toLowerCase());
	};

	const decodeBase64 = (base64) => {
		const byteCharacters = atob(base64);
		const byteNumbers = new Array(byteCharacters.length);
		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		return byteArray;
	};

	const functionToDownload = (decoded, fileName, ext) => {
		const blob = new Blob([decoded], { type: `aplication/${ext}` });
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = fileName;
		link.click();
	};

	const deleteFile = async (e, item) => {
		e.preventDefault();
		fetchData({
			url: endpoints.UploadFiles.delete,
			method: 'post',
			body: {
				urlsArchivos: [item.url]
			}
		}).then(() => {
			if (currentFiles.length === 1) {
				setCurrentFiles([]);
			} else {
				const index = currentFiles.indexOf(item);
				currentFiles.splice(index, 1);
				setCurrentFiles([...currentFiles]);
			}
		});
	};

	return (
		<div style={{ width: '100%', display: 'flex', flexFlow: 'column' }}>
			{showParameters && (
				<div
					style={{
						width: '90%',
						margin: '0px auto',
						marginTop: '25px',
						marginBottom: '25px'
					}}
				>
					<div style={{ color: '#0AA48A', fontWeight: 'bold' }}>
						<FormattedMessage id="p.information.general" defaultMessage="Información" />
					</div>
					<div
						style={{
							width: '100%',
							display: 'flex',
							flexFlow: 'row',
							justifyContent: 'space-between'
						}}
					>
						<span style={{ paddingRight: '9rem' }}>
							<b>{formatterText('p.files.expected')}</b>
							{filesActivate.some((tipoUsuario) =>
								tipoUsuario.tipoArchivoAdjuntos.some((archivo) => archivo.estado === 1)
							)
								? namesSplitted
								: formatterText('p.message.there.are.not.files')}
						</span>
					</div>
					<div style={{ color: '#0AA48A', fontWeight: 'bold' }}>
						<FormattedMessage id="p.attached.files" defaultMessage="Archivos adjuntos subidos" />
					</div>
				</div>
			)}
			{/* {dataToUpdate.length > 0 && dataAux !== null && */}
			{filesActivate.some((tipoUsuario) =>
				tipoUsuario.tipoArchivoAdjuntos.some((archivo) => archivo.estado === 1)
			) ? (
				<>
					<label
						style={{
							width: '80%',
							background: '#EFEEEE',
							boder: '1px',
							borderStyle: 'dashed',
							borderColor: '#747474',
							margin: '0px auto',
							minHeight: '150px',
							borderRadius: '10px'
						}}
						className="custom-file-upload"
					>
						<input
							style={{ display: 'none' }}
							onChange={isEdited ? uploadNewFileFn : upload}
							accept={onlyAllowedFiles}
							type="file"
							ref={ref}
							multiple
						/>

						<div
							style={{
								display: 'flex',
								flexFlow: 'row wrap'
							}}
						>
							{currentFiles.map((item, index) => (
								<div
									key={`${item.name} ${index}`}
									style={{
										backgroundColor: '#FFFFFF',
										borderRadius: '10px',
										boder: '1px',
										borderStyle: 'solid',
										borderColor: '#D9D9D9',
										padding: '5px',
										paddingRight: '10px',
										marginRight: '20px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between'
									}}
								>
									<p
										style={{
											cursor: 'pointer'
										}}
										onClick={(e) => {
											e.preventDefault();
											isEdited && downloadFile(e, item);
											!isEdited && downloadFileOnCreate(e, item);
										}}
									>
										{item.name}
									</p>
									<MdDelete
										size={25}
										color="red"
										style={{
											marginLeft: '10px',
											zIndex: '999'
										}}
										cursor="pointer"
										onClick={(e) => {
											e.preventDefault();
											isEdited ? deleteFile(e, item) : pressButtonNotEditWay(e, item);
										}}
									/>
								</div>
							))}
						</div>
					</label>
					{isEdited && (
						<div
							style={{
								width: '80%',
								display: 'flex',
								margin: '0 auto',
								justifyContent: 'flex-end',
								marginTop: '25px',
								marginBottom: '25px'
							}}
						>
							<button
								onClick={(e) => {
									e.preventDefault();
									ref.current.click();
								}}
								style={{ width: 'fit-content' }}
								className="btn-primary"
							>
								<FormattedMessage id="btn.upload.files" defaultMessage="Subir archivos" />
							</button>
						</div>
					)}
				</>
			) : null}
		</div>
	);
};

export default Attachments;
