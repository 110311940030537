import useAxios from 'hooks/useAxios';
import { useSeachContext } from 'context/SearchContext';
import dateFormat from 'dateformat';
import useGetData from 'hooks/useGetData';
import useLangv2 from 'hooks/useLangv2';
import { useEffect } from 'react';

export default function CountriesViewModel() {
	const { endpoints, RequestUseCase } = useAxios();
	const { setDataTable, searchResults = [], setSearchResults } = useSeachContext();
	const {
		loading,
		error,
		toggleLoading,
		toggleError,
		handleClick,
		displayMessage,
		displayLoading
	} = useGetData();

	const getData = async () => {
		const countries = await RequestUseCase.get(endpoints.zones.getAllCountries);

		try {
			let collection = [];

			for (const country of countries) {
				collection = [
					...collection,
					{
						codigoPais: country.codigoPais,
						nombre: country.nombrePais,
						prefijo: country.prefijo,
						estado: country.estado,
						fechaCreacion: dateFormat(country.fechaCreacion, 'yyyy/mm/dd - h:MM:ss TT'),
						fechaModificacion:
							country.fechaModificacion !== null
								? dateFormat(country.fechaModificacion, 'yyyy/mm/dd - h:MM:ss TT')
								: dateFormat(country.fechaCreacion, 'yyyy/mm/dd - h:MM:ss TT'),
						id: country.idPais,
						fc: country.fechaCreacion,
						uc: country.usuarioCreacion
					}
				];

				setDataTable(collection);
				setSearchResults(collection);
			}
		} catch (error) {
			toggleError(!error);
			console.log(error);
			handleClick();
		}
	};

	// use Hook of language v2
	const { formatterText } = useLangv2();

	const titles = [
		formatterText('table.title.countryCode', 'Código país'),
		formatterText('table.title.countryName', 'Nombre país'),
		formatterText('table.title.countryPrefix', 'Prefijo'),
		formatterText('table.title.state', 'Estado'),
		formatterText('table.title.creationD', 'Fecha creación'),
		formatterText('table.title.modificationDate', 'Fecha modificación')
	];

	useEffect(() => {
		(async () => {
			toggleLoading(true);
			await getData();
			toggleLoading(false);
		})();
	}, []);

	return {
		loading,
		error,
		displayMessage,
		displayLoading,
		searchResults,
		getData,
		titles
	};
}
