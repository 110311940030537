import React, { useState } from 'react';
// libs to show a message in the snackbar
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * @description Custom hook to show a message custom message in the snackbar
 * Need to invoke the CustomComponentToast and execute the function
 * onMessage passing the typeAlert (such as success, error, warning) and the custom message
 * @returns {JSX.Element}
 */

export default function CustomToast() {
	// Toast alerts
	const OnMessage = (typeAlert, messageAlert) => {
		setType(typeAlert);
		setMessage(messageAlert);
		handleClick();
	};

	// Snackbar status to show the message by default
	const [open, setOpen] = useState(false);
	const [type, setType] = useState('success');
	const [message, setMessage] = useState('Default');

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	// Render the component of the toast
	const CustomComponentToast = () => {
		return (
			<Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
					{message}
				</Alert>
			</Snackbar>
		);
	};

	return {
		OnMessage,
		CustomComponentToast
	};
}
