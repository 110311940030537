import React from 'react';
import { SearchWrapper } from 'context/SearchContext';
import UpdateScheduleComponent from './UpdateSchedule.component';

const UpdateSchedule = () => (
	<SearchWrapper>
		<UpdateScheduleComponent />
	</SearchWrapper>
);

export default UpdateSchedule;
