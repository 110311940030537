import Cookie from 'js-cookie';

class ProductCategory {
	constructor(
		idCategoriaProducto = '',
		nombre = '',
		descripcion = '',
		codigo = '',
		estado = 1,
		fechaRegistro = '',
		fechaModificacion = '',
		usuarioCreacion = Cookie.get('idUsuario'),
		usuarioModificacion = Cookie.get('idUsuario')
	) {
		this.idCategoriaProducto = idCategoriaProducto;
		this.nombre = nombre;
		this.descripcion = descripcion;
		this.codigo = codigo;
		this.estado = estado;
		this.fechaRegistro = fechaRegistro;
		this.fechaModificacion = fechaModificacion;
		this.usuarioCreacion = usuarioCreacion;
		this.usuarioModificacion = usuarioModificacion;
	}
}

export default ProductCategory;
