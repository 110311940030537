import React from 'react';
// Import Components
import ThirdPartiesComponent from './ThirdParties.component';
// Import Context
import { PermissionWrapper } from 'context/PermissionContext';
import { SearchWrapper } from 'context/SearchContext';

export default function ThirdParties() {
	return (
		<SearchWrapper>
			<PermissionWrapper>
				<ThirdPartiesComponent />
			</PermissionWrapper>
		</SearchWrapper>
	);
}
