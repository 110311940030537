import { SPACING } from 'common/validators/Regex';

/**
 * Valida la expxresion regular utilizada para
 * un campo
 * @param {Event} e -- evento change
 * @param {Regex} regex -- expresion regular
 * @param {Form} form -- formulario
 * @param {Funtion} setForm -- funcion set del formulario
 */
const HandleInput = (e, regex, form, setForm, spacing = false) => {
	const { name = '', value = '' } = e.target;

	/// const value = val.replaceAll(/\s{2,}/gi, " ");

	if (spacing) {
		if (value.match(SPACING)) {
			const RESULT = /\s{2}/.test(value);
			if (!RESULT || value === '') {
				setForm({ ...form, [name]: value });
			}
		}
	} else {
		if (value.match(regex) || value === '') {
			setForm({ ...form, [name]: value });
		}
	}
};

export default HandleInput;
